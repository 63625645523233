import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Grid, InputLabel, Switch } from '@material-ui/core';

import { getApiPaymentMethodIframe } from '../../../utils/functions/api';
import translation from '../../../translation/translation';

const useStyles = makeStyles(() => ({
  root: {
    textAlign: 'center'
  },
  loaderContainer: {
    margin: 'auto',
    position: 'absolute',
    left: 0,
    right: 0,
    top: '50%'
  },
  loaderWrapper: {
    padding: 0
  },
  label: {
    cursor: 'pointer'
  }
}));

function CreatePaymentMethod(props) {
  const classes = useStyles();
  const { enqueueSnackbar, onSuccess, resourceId } = props;
  const [checked, setChecked] = useState(false);

  function handleChange(event) {
    setChecked(event.target.checked);
    getPaymentForm(event.target.checked);
  }

  function handleCheckUser() {
    setChecked(false);
    getPaymentForm(false);
  }

  function handleCheckProvider() {
    setChecked(true);
    getPaymentForm(true);
  }

  useEffect(() => {
    getPaymentForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getPaymentForm(wayReceive) {
    if (!resourceId) return;

    getApiPaymentMethodIframe(
      `payers/${resourceId}/payment-methods/form`,
      wayReceive ? 'receive' : 'pay',
      () => {
        onSuccess();
        enqueueSnackbar(
          translation().core.list.dialogs.custom_create_actions.create_payment_method.callbacks
            .success,
          { variant: 'success' }
        );
      },
      () => {
        enqueueSnackbar(
          translation().core.list.dialogs.custom_create_actions.create_payment_method.callbacks
            .error,
          { variant: 'error' }
        );
      }
    );
  }

  return (
    <div className={classes.root}>
      <Grid container alignItems="center" spacing={0.5} justify="center">
        <Grid item>
          <InputLabel className={classes.label} onClick={handleCheckUser}>
            {translation().core.list.dialogs.custom_create_actions.create_payment_method.way.pay}
          </InputLabel>
        </Grid>
        <Grid item>
          <Switch checked={checked} onChange={handleChange} />
        </Grid>
        <Grid item>
          <InputLabel className={classes.label} onClick={handleCheckProvider}>
            {
              translation().core.list.dialogs.custom_create_actions.create_payment_method.way
                .receive
            }
          </InputLabel>
        </Grid>
      </Grid>
      <div id="payment-frame-container" />
      <div className={classes.loaderContainer} id="frame-payment-loader">
        <div className={classNames('loader-wpr', classes.loaderWrapper)}>
          <CircularProgress size={35} />
          <p>
            {
              translation().core.list.dialogs.custom_create_actions.create_payment_method
                .loading_text
            }
          </p>
        </div>
      </div>
    </div>
  );
}

CreatePaymentMethod.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  resourceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default CreatePaymentMethod;
