/**
 * Api resources route mapping
 * Define all routes here
 * Icons must exist in material-ui icons lib
 */

/**
 * WARNING: Every line on this file is required, you can edit one but never delete one key/line... without a lead validation.
 */

/**
 * FUNCTIONS
 */
import { getApiUrl } from '../utils/functions/api';
import { toUnix } from '../utils/functions/dates';
import translation from '../translation/translation';

/**
 * CORE CONTAINERS
 */
import CreateContainer from '../containers/core/create/CreateContainer';
import ListContainer from '../containers/core/list/ListContainer';
import UpdateContainer from '../containers/core/update/UpdateContainer';

/**
 * CUSTOM CONTAINERS
 */
import AdminizePaymentMethodContainer from '../containers/core/customListActions/AdminizePaymentMethodContainer';
import ApiHealthMonitorContainer from '../containers/monitoring/ApiHealthMonitorContainer';
import ApplicationSecretContainer from '../containers/core/customListActions/ApplicationSecretContainer';
import BlockPaymentMethodContainer from '../containers/core/customListActions/BlockPaymentMethodContainer';
import CancelInvoiceLineContainer from '../containers/core/customListActions/CancelInvoiceLineContainer';
import CompanyViewContainer from '../containers/companies/CompanyViewContainer';
import CoreMetricsContainer from '../containers/metrics/CoreMetricsContainer';
import CreateOrderProductsHostingExtensionsContainer from '../containers/orderProducts/CreateOrderProductsHostingExtensionsContainer';
import CreatePaymentMethodContainer from '../containers/core/customCreateActions/CreatePaymentMethodContainer';
import LinkCompanyToCompanyContainer from '../containers/core/customListActions/LinkCompanyToCompanyContainer';
import LinkUserToCompanyContainer from '../containers/core/customListActions/LinkUserToCompanyContainer';
import ManageCompanyFlagContainer from '../containers/core/customListActions/ManageCompanyFlagContainer';
import ModerationContainer from '../containers/moderation/ModerationContainer';
import OrdersCreateContainer from '../containers/orders/create/OrdersCreateContainer';
import OrderProductModerationContainer from '../containers/moderation/OrderProductContainer';
import OrderProductsListContainer from '../containers/orderProducts/OrderProductsListContainer';
import OrderProductsOverdueContainer from '../containers/orderProductsOverdue/OrderProductsOverdueContainer';
import OrderProductsUndispatchedContainer from '../containers/orderProductsUndispatched/OrderProductsUndispatchedContainer';
import OrdersListContainer from '../containers/orders/list/OrdersListContainer';
import OrdersUpdateContainer from '../containers/orders/Update/OrdersUpdateContainer';
import PayInvoiceContainer from '../containers/core/customListActions/PayInvoiceContainer';
import PayerBalanceContainer from '../containers/core/customListActions/PayerBalanceContainer';
import ProviderFactoryContainer from '../containers/providers/factory/ProviderFactoryContainer';
import ProviderViewContainer from '../containers/providers/ProviderViewContainer';
import ProviderHashContainer from '../containers/core/customListActions/ProviderHashContainer';
import ProvidersMapContainer from '../containers/providers/ProvidersMapContainer';
import ProvidersSlotsCheckContainer from '../containers/providers/ProvidersSlotsCheckContainer';
import PayerBridgeContainer from '../containers/core/customListActions/PayerBridgeContainer';
import PreviousDayContainer from '../containers/previousDay/PreviousDayContainer';
import ProductViewContainer from '../containers/products/ProductViewContainer';
import ProviderScheduleContainer from '../containers/core/customListActions/ProviderScheduleContainer';
import UserPositionsContainer from '../containers/core/customListActions/UserPositionsContainer';
import UserViewContainer from '../containers/users/UserViewContainer';

/**
 * CUSTOM VIEWS
 */
import Production from '../views/production/Production';

/**
 * METRICS
 */
import applicationIdMetrics from './metrics/applicationIdMetrics';
import iamMetrics from './metrics/iamMetrics';
import invoicerIdMetrics from './metrics/invoicerIdMetrics';
import companyIdMetrics from './metrics/companyIdMetrics';
import gatewayMetrics from './metrics/gatewayMetrics';
import productIdMetrics from './metrics/productIdMetrics';
import providerIdMetrics from './metrics/providerIdMetrics';
import serviceIdMetrics from './metrics/serviceIdMetrics';
import serviceIdVersionIdMetrics from './metrics/serviceIdVersionIdMetrics';
import serviceIdVersionIdEndpointIdMetrics from './metrics/serviceIdVersionIdEndpointIdMetrics';
import shopMetrics from './metrics/shopMetrics';
import userIdMetrics from './metrics/userIdMetrics';

/**
 * MAIN OBJECT
 */
export default {
  shop: {
    nav_icon: 'local_mall',
    nav_name: 'Shop',
    resources: {
      orders: {
        nav_name: 'Orders',
        nav_uri: '/orders',
        is_show_in_nav: true,
        routes: {
          orders: {
            component: OrdersListContainer
          },
          'orders/create': {
            component: OrdersCreateContainer
          },
          'orders/:id/update': {
            component: OrdersUpdateContainer
          }
        }
      },
      'orders/all/products': {
        nav_name: 'Order products',
        nav_uri: '/orders/all/products',
        is_show_in_nav: true,
        routes: {
          'orders/all/products': {
            component: OrderProductsListContainer
          }
        }
      },
      'hosting-extensions': {
        nav_name: 'Hosting extensions',
        nav_uri: '/orders/:id/products/:id/hosting-extensions',
        is_show_in_nav: false,
        routes: {
          'orders/:id/products/:id/hosting-extensions': {
            component: ListContainer,
            props: {
              isDeletable: false,
              isUpdatable: false,
              columns: [
                { name: 'id' },
                { name: 'nb_months' },
                { name: 'extend_from' },
                { name: 'extend_until' },
                { name: 'amount_formatted' },
                { name: 'amount_tax_excluded_formatted' },
                { name: 'user' },
                { name: 'created' },
                { name: 'updated' }
              ],
              metadataUri: 'orders/1/products/1/hosting-extensions'
            }
          },
          'orders/:order_id/products/:order_product_id/hosting-extensions/create': {
            component: CreateOrderProductsHostingExtensionsContainer,
            props: {
              is_modal: true
            }
          }
        }
      },
      carts: {
        nav_name: 'Carts',
        nav_uri: '/carts',
        is_show_in_nav: true,
        routes: {
          carts: {
            component: ListContainer,
            props: {
              columns: [
                { name: 'id' },
                { name: 'user' },
                { name: 'property_address' },
                { name: 'total_tax_excluded_formatted' },
                { name: 'total_formatted' },
                { name: 'created' },
                { name: 'updated' },
                { name: 'expire' }
              ],
              metadataUri: 'carts'
            }
          },
          'carts/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'carts'
            }
          },
          'carts/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'carts'
            }
          }
        }
      },
      'delivery-statuses': {
        nav_name: 'Delivery statuses',
        nav_uri: '/delivery-statuses',
        is_show_in_nav: true,
        routes: {
          'delivery-statuses': {
            component: ListContainer,
            props: {
              columns: [
                { name: 'id' },
                { name: 'name' },
                { name: 'shortcode' },
                { name: 'description' },
                { name: 'is_planned' },
                { name: 'is_done' },
                { name: 'is_received' },
                { name: 'is_moderated' },
                { name: 'is_delivered' },
                { name: 'created' },
                { name: 'updated' }
              ],
              metadataUri: 'delivery-statuses'
            }
          },
          'delivery-statuses/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'delivery-statuses'
            }
          },
          'delivery-statuses/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'delivery-statuses'
            }
          }
        }
      },
      discounts: {
        nav_name: 'Discounts',
        nav_uri: '/discounts',
        is_show_in_nav: true,
        routes: {
          discounts: {
            component: ListContainer,
            props: {
              isDuplicable: true,
              columns: [
                { name: 'id' },
                { name: 'name' },
                { name: 'code' },
                { name: 'description' },
                { name: 'start' },
                { name: 'end' },
                { name: 'quantity' },
                { name: 'quantity_remaining' },
                { name: 'percentage' },
                { name: 'amount' },
                { name: 'active' },
                { name: 'created' },
                { name: 'updated' }
              ],
              metadataUri: 'discounts'
            }
          },
          'discounts/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'discounts'
            }
          },
          'discounts/:id/duplicate': {
            component: CreateContainer,
            props: {
              metadataUri: 'discounts'
            }
          },
          'discounts/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'discounts'
            }
          }
        }
      },
      commissions: {
        nav_name: 'Commissions',
        nav_uri: '/commissions',
        is_show_in_nav: true,
        routes: {
          commissions: {
            component: ListContainer,
            props: {
              isDuplicable: true,
              metadataUri: 'commissions'
            }
          },
          'commissions/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'commissions'
            }
          },
          'commissions/:id/duplicate': {
            component: CreateContainer,
            props: {
              metadataUri: 'commissions'
            }
          },
          'commissions/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'commissions'
            }
          }
        }
      },
      'media-groups': {
        nav_name: 'Media groups',
        nav_uri: '/media-groups',
        is_show_in_nav: true,
        routes: {
          'media-groups': {
            component: ListContainer,
            props: {
              metadataUri: 'media-groups',
              sub_routes: {
                'media-groups/:id/thumbnails': {
                  name: translation().actions.custom_list_actions.media_thumbnails,
                  icon: 'iso',
                  is_show_in_table: true,
                  is_route: true,
                  is_modal: false
                }
              }
            }
          },
          'media-groups/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'media-groups'
            }
          },
          'media-groups/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'media-groups',
              sub_routes: {
                '/media-groups/:id/thumbnails': {
                  name: translation().actions.custom_list_actions.media_thumbnails,
                  icon: 'iso',
                  is_show_in_table: true,
                  is_route: true,
                  is_modal: false
                }
              }
            }
          },
          'media-groups/:id/thumbnails': {
            component: ListContainer,
            props: {
              metadataUri: 'media-groups/1/thumbnails'
            }
          },
          'media-groups/:id/thumbnails/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'media-groups/1/thumbnails'
            }
          },
          'media-groups/:id/thumbnails/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'media-groups/1/thumbnails'
            }
          }
        }
      },
      'media-types': {
        nav_name: 'Media types',
        nav_uri: '/media-types',
        is_show_in_nav: true,
        routes: {
          'media-types': {
            component: ListContainer,
            props: {
              isDuplicable: true,
              columns: [
                { name: 'id' },
                { name: 'name' },
                { name: 'shortcode' },
                { name: 'type' },
                { name: 'description' },
                { name: 'has_templates' },
                { name: 'has_floor' },
                { name: 'private' },
                { name: 'created' },
                { name: 'updated' }
              ],
              metadataUri: 'media-types',
              sub_routes: {
                'media-types/:id/templates': {
                  name: translation().actions.custom_list_actions.media_templates,
                  icon: 'web',
                  is_show_in_table: true,
                  is_route: true,
                  is_modal: false
                }
              }
            }
          },
          'media-types/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'media-types'
            }
          },
          'media-types/:id/duplicate': {
            component: CreateContainer,
            props: {
              metadataUri: 'media-types'
            }
          },
          'media-types/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'media-types',
              sub_routes: {
                '/media-types/:id/templates': {
                  name: translation().actions.custom_list_actions.media_templates,
                  icon: 'web',
                  is_show_in_table: true,
                  is_route: true,
                  is_modal: false
                }
              }
            }
          },
          'media-types/:id/templates': {
            component: ListContainer,
            props: {
              metadataUri: 'media-types/1/templates',
              columns: 22
            }
          },
          'media-types/:id/templates/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'media-types/1/templates'
            }
          },
          'media-types/:id/templates/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'media-types/1/templates'
            }
          }
        }
      },
      'product-groups': {
        nav_name: 'Product groups',
        nav_uri: '/product-groups',
        is_show_in_nav: true,
        routes: {
          'product-groups': {
            component: ListContainer,
            props: {
              columns: [
                { name: 'id' },
                { name: 'name' },
                { name: 'shortcode' },
                { name: 'description' },
                { name: 'active' },
                { name: 'orderable' },
                { name: 'created' },
                { name: 'updated' }
              ],
              metadataUri: 'product-groups'
            }
          },
          'product-groups/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'product-groups'
            }
          },
          'product-groups/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'product-groups'
            }
          }
        }
      },
      products: {
        nav_name: 'Products',
        nav_uri: '/products',
        is_show_in_nav: true,
        routes: {
          products: {
            component: ListContainer,
            props: {
              isDuplicable: true,
              columns: [
                { name: 'id' },
                { name: 'name' },
                { name: 'shortcode' },
                { name: 'description' },
                { name: 'duration_min' },
                { name: 'duration_max' },
                { name: 'delivery_time' },
                { name: 'bundle' },
                { name: 'require_trip' },
                { name: 'require_planning' },
                { name: 'active' },
                { name: 'orderable' },
                { name: 'created' },
                { name: 'updated' }
              ],
              metadataUri: 'products',
              sub_routes: {
                'products/:id/product-view': {
                  name: translation().actions.custom_list_actions.product_view,
                  icon: 'view_quilt',
                  is_show_in_table: true,
                  is_route: true
                }
              }
            }
          },
          'products/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'products'
            }
          },
          'products/:id/duplicate': {
            component: CreateContainer,
            props: {
              metadataUri: 'products'
            }
          },
          'products/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'products',
              sub_routes: {
                '/products/:id/product-view': {
                  name: translation().actions.custom_list_actions.product_view,
                  icon: 'view_quilt',
                  is_show_in_table: true,
                  is_route: true
                },
                '/products/:id/options': {
                  name: translation().actions.custom_list_actions.options,
                  icon: 'check_circle_outline',
                  is_show_in_table: true,
                  is_route: true
                },
                '/products/:id/hosting-plans': {
                  name: translation().actions.custom_list_actions.hosting_plans,
                  icon: 'settings_system_daydream',
                  is_show_in_table: true,
                  is_route: true
                },
                '/products/:id/metrics': {
                  name: translation().actions.custom_list_actions.metrics,
                  icon: 'multiline_chart',
                  is_show_in_table: true,
                  is_route: true
                }
              }
            }
          },
          'products/:id/product-view': {
            component: ProductViewContainer,
            props: {
              metadataUri: 'products',
              sub_routes: {
                '/products/:id/media-types': {
                  name: 'Media types',
                  icon: 'picture_as_pdf',
                  is_show_in_table: true,
                  is_route: true
                },
                '/products/:id/options': {
                  name: translation().actions.custom_list_actions.options,
                  icon: 'check_circle_outline',
                  is_show_in_table: true,
                  is_route: true
                },
                '/products/:id/hosting-plans': {
                  name: translation().actions.custom_list_actions.hosting_plans,
                  icon: 'settings_system_daydream',
                  is_show_in_table: true,
                  is_route: true
                },
                '/products/:id/metrics': {
                  name: translation().actions.custom_list_actions.metrics,
                  icon: 'multiline_chart',
                  is_show_in_table: true,
                  is_route: true
                }
              }
            }
          },
          'products/:id/media-types': {
            component: ListContainer,
            props: {
              isDuplicable: true,
              metadataUri: 'products/1/media-types',
              columns: [
                { name: 'id' },
                { name: 'media_type.name' },
                { name: 'media_type.shortcode' },
                { name: 'media_type.type' },
                { name: 'quantity_min' },
                { name: 'quantity_max' },
                { name: 'media_type.type' },
                { name: 'media_type.has_templates' },
                { name: 'media_type.has_floor' },
                { name: 'media_type.private' },
                { name: 'created' },
                { name: 'updated' }
              ]
            }
          },
          'products/:id/media-types/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'products/1/media-types'
            }
          },
          'products/:id/media-types/:id/duplicate': {
            component: CreateContainer,
            props: {
              metadataUri: 'products/1/media-types'
            }
          },
          'products/:id/media-types/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'products/1/media-types'
            }
          },
          'products/:id/options': {
            component: ListContainer,
            props: {
              isDuplicable: true,
              metadataUri: 'products/1/options',
              columns: [
                { name: 'id' },
                { name: 'name' },
                { name: 'shortcode' },
                { name: 'description' },
                { name: 'duration_min' },
                { name: 'duration_max' },
                { name: 'active' },
                { name: 'orderable' },
                { name: 'created' },
                { name: 'updated' }
              ]
            }
          },
          'products/:id/options/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'products/1/options'
            }
          },
          'products/:id/options/:id/duplicate': {
            component: CreateContainer,
            props: {
              metadataUri: 'products/1/options'
            }
          },
          'products/:id/options/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'products/1/options'
            }
          },
          'products/:id/hosting-plans': {
            component: ListContainer,
            props: {
              isDuplicable: true,
              metadataUri: 'products/1/hosting-plans',
              columns: [
                { name: 'id' },
                { name: 'shortcode' },
                { name: 'nb_months' },
                { name: 'price_fixed' },
                { name: 'active' },
                { name: 'orderable' },
                { name: 'created' },
                { name: 'updated' }
              ]
            }
          },
          'products/:id/hosting-plans/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'products/1/hosting-plans'
            }
          },
          'products/:id/hosting-plans/:id/duplicate': {
            component: CreateContainer,
            props: {
              metadataUri: 'products/1/hosting-plans'
            }
          },
          'products/:id/hosting-plans/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'products/1/hosting-plans'
            }
          },
          'products/:id/metrics': {
            component: CoreMetricsContainer,
            props: {
              mapping: productIdMetrics
            }
          }
        }
      },
      providers: {
        nav_name: 'Providers',
        nav_uri: '/providers',
        is_show_in_nav: true,
        routes: {
          providers: {
            component: ListContainer,
            props: {
              columns: [
                { name: 'id' },
                { name: 'name' },
                { name: 'user' },
                { name: 'bio' },
                { name: 'ghost_start_time' },
                { name: 'ghost_end_time' },
                { name: 'ghost' },
                { name: 'accept_trip' },
                { name: 'active' },
                { name: 'created' },
                { name: 'updated' }
              ],
              metadataUri: 'providers',
              sub_routes: {
                'providers/:id/provider-view': {
                  name: translation().actions.custom_list_actions.provider_view,
                  icon: 'view_quilt',
                  is_show_in_table: true,
                  is_route: true
                }
              },
              actions: {
                'providers/provider-factory': {
                  name: 'Factory',
                  icon: 'precision_manufacturing',
                  is_route: true
                }
              }
            }
          },
          'providers/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'providers'
            }
          },
          'providers/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'providers',
              sub_routes: {
                '/providers/:id/schedule': {
                  name: translation().actions.custom_list_actions.schedule,
                  icon: 'event_note',
                  is_show_in_table: true,
                  is_route: true
                },
                '/providers/:id/provider-view': {
                  name: translation().actions.custom_list_actions.provider_view,
                  icon: 'view_quilt',
                  is_show_in_table: true,
                  is_route: true
                },
                '/providers/:id/availabilities': {
                  name: translation().actions.custom_list_actions.availabilities,
                  icon: 'alarm_on',
                  is_show_in_table: true,
                  is_route: true,
                  is_modal: false
                },
                '/providers/:id/unavailabilities': {
                  name: translation().actions.custom_list_actions.unavailabilities,
                  icon: 'alarm_off',
                  is_show_in_table: true,
                  is_route: true,
                  is_modal: false
                },
                '/providers/:id/invoices': {
                  name: translation().actions.custom_list_actions.invoices,
                  icon: 'receipt',
                  is_show_in_table: true,
                  is_route: true,
                  is_modal: false
                },
                '/providers/:id/hash': {
                  name: translation().actions.custom_list_actions.hash,
                  icon: 'select_all',
                  is_show_in_table: true,
                  is_route: false,
                  is_modal: true,
                  is_link: false,
                  props: {
                    title: translation().core.list.dialogs.custom_list_actions.provider_hash.title
                  },
                  container: ProviderHashContainer
                },
                '/providers/:id/metrics': {
                  name: translation().actions.custom_list_actions.metrics,
                  icon: 'multiline_chart',
                  is_show_in_table: true,
                  is_route: true
                }
              },
              collections_columns: {
                products: {
                  name: 'products',
                  columns: [
                    { name: 'id' },
                    { name: 'product.name' },
                    { name: 'boost_factor' },
                    { name: 'radius' },
                    { name: 'reversion_hourly_rate' },
                    { name: 'reversion_percentage' },
                    { name: 'reversion_amount' },
                    { name: 'moderate' }
                  ]
                }
              }
            }
          },
          'providers/:id/schedule': {
            component: ProviderScheduleContainer
          },
          'providers/:id/availabilities': {
            component: ListContainer,
            props: {
              metadataUri: 'providers/1/availabilities'
            }
          },
          'providers/:id/availabilities/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'providers/1/availabilities'
            }
          },
          'providers/:id/availabilities/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'providers/1/availabilities'
            }
          },
          'providers/:id/unavailabilities': {
            component: ListContainer,
            props: {
              metadataUri: 'providers/1/unavailabilities'
            }
          },
          'providers/:id/unavailabilities/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'providers/1/unavailabilities'
            }
          },
          'providers/:id/unavailabilities/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'providers/1/unavailabilities'
            }
          },
          'providers/:id/provider-view': {
            component: ProviderViewContainer,
            props: {
              metadataUri: 'providers',
              sub_routes: {
                '/providers/:id/schedule': {
                  name: translation().actions.custom_list_actions.schedule,
                  icon: 'event_note',
                  is_show_in_table: true,
                  is_route: true
                },
                '/providers/:id/availabilities': {
                  name: translation().actions.custom_list_actions.availabilities,
                  icon: 'alarm_on',
                  is_show_in_table: true,
                  is_route: true,
                  is_modal: false
                },
                '/providers/:id/unavailabilities': {
                  name: translation().actions.custom_list_actions.unavailabilities,
                  icon: 'alarm_off',
                  is_show_in_table: true,
                  is_route: true,
                  is_modal: false
                },
                '/providers/:id/invoices': {
                  name: translation().actions.custom_list_actions.invoices,
                  icon: 'receipt',
                  is_show_in_table: true,
                  is_route: true,
                  is_modal: false
                },
                '/providers/:id/hash': {
                  name: translation().actions.custom_list_actions.hash,
                  icon: 'select_all',
                  is_show_in_table: true,
                  is_route: false,
                  is_modal: true,
                  is_link: false,
                  props: {
                    title: translation().core.list.dialogs.custom_list_actions.provider_hash.title
                  },
                  container: ProviderHashContainer
                },
                '/providers/:id/metrics': {
                  name: translation().actions.custom_list_actions.metrics,
                  icon: 'multiline_chart',
                  is_show_in_table: true,
                  is_route: true
                }
              }
            }
          },
          'providers/provider-factory': {
            component: ProviderFactoryContainer
          },
          'providers/:id/invoices': {
            component: ListContainer,
            props: {
              isDeletable: false,
              isCreatable: false,
              metadataUri: 'invoices',
              columns: [
                { name: 'id' },
                { name: 'number_formatted' },
                { name: 'date' },
                { name: 'client_name' },
                { name: 'client_address' },
                { name: 'type' },
                { name: 'vendor_name' },
                { name: 'total_formatted' },
                { name: 'payment_status.name' },
                { name: 'created' },
                { name: 'updated' }
              ],
              sub_routes: {
                'providers/:id/invoices/:id/download': {
                  name: translation().actions.custom_list_actions.download,
                  icon: 'cloud_download',
                  is_show_in_table: true,
                  is_route: false,
                  is_link: true,
                  is_modal: false,
                  props: {
                    link_url: getApiUrl('invoices/:id/download', {
                      mode: 'inline',
                      v: toUnix()
                    })
                  },
                  container: null
                },
                'providers/:id/invoices/:id/pay': {
                  name: translation().actions.custom_list_actions.pay,
                  icon: 'monetization_on',
                  is_show_in_table: true,
                  is_route: false,
                  is_modal: true,
                  is_link: false,
                  props: {
                    title: translation().core.list.dialogs.custom_list_actions.pay_invoice.title,
                    description:
                      translation().core.list.dialogs.custom_list_actions.pay_invoice.content
                  },
                  container: PayInvoiceContainer
                }
              }
            }
          },
          'providers/:id/invoices/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'invoices',
              sub_routes: {
                '/providers/:id/invoices/:id/download': {
                  name: translation().actions.custom_list_actions.download,
                  icon: 'cloud_download',
                  is_show_in_table: true,
                  is_route: false,
                  is_link: true,
                  is_modal: false,
                  props: {
                    link_url: getApiUrl('invoices/:id/download', {
                      mode: 'inline',
                      v: toUnix()
                    })
                  },
                  container: null
                },
                '/providers/:id/invoices/:id/pay': {
                  name: translation().actions.custom_list_actions.pay,
                  icon: 'monetization_on',
                  is_show_in_table: true,
                  is_route: false,
                  is_modal: true,
                  is_link: false,
                  props: {
                    title: translation().core.list.dialogs.custom_list_actions.pay_invoice.title,
                    description:
                      translation().core.list.dialogs.custom_list_actions.pay_invoice.content
                  },
                  container: PayInvoiceContainer
                }
              }
            }
          },
          'providers/:id/invoice-queue': {
            component: ListContainer,
            props: {
              isDeletable: false,
              isCreatable: false,
              metadataUri: 'invoice-queue',
              columns: [
                { name: 'id' },
                { name: 'client_name' },
                { name: 'client_address' },
                { name: 'type' },
                { name: 'reference' },
                { name: 'description' },
                { name: 'created' },
                { name: 'updated' }
              ]
            }
          },
          'providers/:id/invoice-queue/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'invoice-queue'
            }
          },
          'providers/:id/metrics': {
            component: CoreMetricsContainer,
            props: {
              mapping: providerIdMetrics
            }
          }
        }
      },
      restrictions: {
        nav_name: 'Restrictions',
        nav_uri: '/restrictions',
        is_show_in_nav: true,
        routes: {
          restrictions: {
            component: ListContainer,
            props: {
              columns: [
                { name: 'id' },
                { name: 'name' },
                { name: 'type' },
                { name: 'business' },
                { name: 'country_isocode' },
                { name: 'state_isocode' },
                { name: 'iam_application_id' },
                { name: 'iam_parent_company_id' },
                { name: 'iam_company_id' },
                { name: 'iam_referrer_user_id' },
                { name: 'iam_user_id' },
                { name: 'created' },
                { name: 'updated' }
              ],
              metadataUri: 'restrictions'
            }
          },
          'restrictions/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'restrictions'
            }
          },
          'restrictions/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'restrictions'
            }
          }
        }
      },
      'shop-metrics': {
        nav_name: 'Metrics',
        nav_uri: '/shop-metrics',
        is_show_in_nav: true,
        routes: {
          'shop-metrics': {
            component: CoreMetricsContainer,
            props: {
              mapping: shopMetrics
            }
          }
        }
      }
    }
  },
  providers: {
    nav_icon: 'business_center',
    nav_name: 'Providers',
    resources: {
      providers: {
        nav_name: 'Providers',
        nav_uri: '/providers',
        is_show_in_nav: true
      },
      'providers-map': {
        nav_name: 'Providers map',
        nav_uri: '/providers-map',
        is_show_in_nav: true,
        routes: {
          'providers-map': {
            component: ProvidersMapContainer
          }
        }
      },
      'providers-slots-check': {
        nav_name: 'Providers slots check',
        nav_uri: '/providers-slots-check',
        is_show_in_nav: true,
        routes: {
          'providers-slots-check': {
            component: ProvidersSlotsCheckContainer
          }
        }
      },
      'order-products-undispatched': {
        nav_name: 'Providers ghosts',
        nav_uri: '/order-products-undispatched',
        is_show_in_nav: true,
        routes: {
          'order-products-undispatched': {
            component: OrderProductsUndispatchedContainer
          }
        }
      },
      'provider-factory': {
        nav_name: 'Providers factory',
        nav_uri: '/providers/provider-factory',
        is_show_in_nav: true
      }
    }
  },
  production: {
    nav_icon: 'perm_media',
    nav_name: 'Production',
    resources: {
      moderation: {
        nav_name: 'Moderation',
        nav_uri: '/production/moderation',
        is_show_in_nav: true,
        routes: {
          production: {
            component: Production
          },
          'production/moderation': {
            component: ModerationContainer
          },
          'production/moderation/order-product/:id': {
            component: OrderProductModerationContainer
          }
        }
      },
      'previous-day': {
        nav_name: 'Previous day',
        nav_uri: '/production/previous-day',
        is_show_in_nav: true,
        routes: {
          'production/previous-day': {
            component: PreviousDayContainer
          }
        }
      },
      'order-products-overdue': {
        nav_name: 'Order products overdue',
        nav_uri: '/production/order-products-overdue',
        is_show_in_nav: true,
        routes: {
          'production/order-products-overdue': {
            component: OrderProductsOverdueContainer
          }
        }
      }
    }
  },
  estate: {
    nav_icon: 'domain',
    nav_name: 'Estate',
    resources: {
      properties: {
        nav_name: 'Properties',
        nav_uri: '/properties',
        is_show_in_nav: true,
        routes: {
          properties: {
            component: ListContainer,
            props: {
              metadataUri: 'properties',
              isDuplicable: true,
              sub_routes: {
                'properties/:id/rooms': {
                  name: translation().actions.custom_list_actions.rooms,
                  icon: 'meeting_room',
                  is_show_in_table: true,
                  is_route: true,
                  is_modal: false
                }
              }
            }
          },
          'properties/:id/duplicate': {
            component: CreateContainer,
            props: {
              metadataUri: 'properties'
            }
          },
          'properties/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'properties'
            }
          },
          'properties/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'properties',
              sub_routes: {
                '/properties/:id/rooms': {
                  name: translation().actions.custom_list_actions.rooms,
                  icon: 'meeting_room',
                  is_show_in_table: true,
                  is_route: true,
                  is_modal: false
                }
              }
            }
          },
          'properties/:id/rooms': {
            component: ListContainer,
            props: {
              isDuplicable: true,
              metadataUri: 'properties/1/rooms',
              columns: [
                { name: 'id' },
                { name: 'name' },
                { name: 'active' },
                { name: 'size' },
                { name: 'created' },
                { name: 'updated' }
              ]
            }
          },
          'properties/:id/rooms/:id/duplicate': {
            component: CreateContainer,
            props: {
              metadataUri: 'properties/1/rooms'
            }
          },
          'properties/:id/rooms/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'properties/1/rooms'
            }
          },
          'properties/:id/rooms/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'properties/1/rooms'
            }
          }
        }
      },
      'property-fields': {
        nav_name: 'Property fields',
        nav_uri: '/property-fields',
        is_show_in_nav: true,
        routes: {
          'property-fields': {
            component: ListContainer,
            props: {
              metadataUri: 'property-fields',
              sub_routes: {
                'property-fields/:id/choices': {
                  name: translation().actions.custom_list_actions.choices,
                  icon: 'style',
                  is_show_in_table: true,
                  is_route: true,
                  is_modal: false
                },
                'property-fields/:id/aliases': {
                  name: translation().actions.custom_list_actions.aliases,
                  icon: 'font_download',
                  is_show_in_table: true,
                  is_route: true,
                  is_modal: false
                }
              }
            }
          },
          'property-fields/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'property-fields'
            }
          },
          'property-fields/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'property-fields',
              sub_routes: {
                '/property-fields/:id/choices': {
                  name: translation().actions.custom_list_actions.choices,
                  icon: 'style',
                  is_show_in_table: true,
                  is_route: true,
                  is_modal: false
                },
                '/property-fields/:id/aliases': {
                  name: translation().actions.custom_list_actions.aliases,
                  icon: 'font_download',
                  is_show_in_table: true,
                  is_route: true,
                  is_modal: false
                }
              }
            }
          },
          'property-fields/:id/aliases': {
            component: ListContainer,
            props: {
              metadataUri: 'property-fields/1/aliases'
            }
          },
          'property-fields/:id/aliases/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'property-fields/1/aliases'
            }
          },
          'property-fields/:id/aliases/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'property-fields/1/aliases'
            }
          },
          'property-fields/:id/choices': {
            component: ListContainer,
            props: {
              metadataUri: 'property-fields/1/choices'
            }
          },
          'property-fields/:id/choices/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'property-fields/1/choices'
            }
          },
          'property-fields/:id/choices/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'property-fields/1/choices'
            }
          }
        }
      },
      'property-field-groups': {
        nav_name: 'Property field groups',
        nav_uri: '/property-field-groups',
        is_show_in_nav: true,
        routes: {
          'property-field-groups': {
            component: ListContainer,
            props: {
              metadataUri: 'property-field-groups'
            }
          },
          'property-field-groups/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'property-field-groups'
            }
          },
          'property-field-groups/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'property-field-groups'
            }
          }
        }
      }
    }
  },
  Iam: {
    nav_icon: 'supervised_user_circle',
    nav_name: 'Iam',
    resources: {
      applications: {
        nav_name: 'Applications',
        nav_uri: '/applications',
        is_show_in_nav: true,
        routes: {
          applications: {
            component: ListContainer,
            props: {
              columns: [
                { name: 'id' },
                { name: 'name' },
                { name: 'namespace' },
                { name: 'auth_mode' },
                { name: 'api_key' },
                { name: 'base_url' },
                { name: 'callback_url' },
                { name: 'notify_users' },
                { name: 'invoice_users' },
                { name: 'sandbox_mode' },
                { name: 'trusted' },
                { name: 'created' },
                { name: 'updated' }
              ],
              metadataUri: 'applications',
              sub_routes: {
                'applications/:id/webhooks': {
                  name: translation().actions.custom_list_actions.web_hooks,
                  icon: 'device_hub',
                  is_show_in_table: true,
                  is_route: true,
                  is_modal: false
                },
                'applications/:id/secret': {
                  name: translation().actions.custom_list_actions.secret,
                  icon: 'vpn_key',
                  is_show_in_table: true,
                  is_route: false,
                  is_modal: true,
                  is_link: false,
                  props: {
                    title:
                      translation().core.list.dialogs.custom_list_actions.application_secret.title
                  },
                  container: ApplicationSecretContainer
                },
                'applications/:id/metrics': {
                  name: translation().actions.custom_list_actions.metrics,
                  icon: 'multiline_chart',
                  is_show_in_table: true,
                  is_route: true
                }
              }
            }
          },
          'applications/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'applications'
            }
          },
          'applications/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'applications',
              sub_routes: {
                '/applications/:id/webhooks': {
                  name: translation().actions.custom_list_actions.web_hooks,
                  icon: 'device_hub',
                  is_show_in_table: true,
                  is_route: true,
                  is_modal: false
                },
                '/applications/:id/secret': {
                  name: translation().actions.custom_list_actions.secret,
                  icon: 'vpn_key',
                  is_show_in_table: true,
                  is_route: false,
                  is_modal: true,
                  is_link: false,
                  props: {
                    title:
                      translation().core.list.dialogs.custom_list_actions.application_secret.title
                  },
                  container: ApplicationSecretContainer
                },
                '/applications/:id/metrics': {
                  name: translation().actions.custom_list_actions.metrics,
                  icon: 'multiline_chart',
                  is_show_in_table: true,
                  is_route: true
                }
              }
            }
          },
          'applications/:id/webhooks': {
            component: ListContainer,
            props: {
              metadataUri: 'applications/1/webhooks',
              columns: [
                { name: 'id' },
                { name: 'event.shortcode' },
                { name: 'url' },
                { name: 'auth' },
                { name: 'client_id' },
                { name: 'format' },
                { name: 'active' },
                { name: 'created' },
                { name: 'updated' }
              ]
            }
          },
          'applications/:id/webhooks/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'applications/1/webhooks'
            }
          },
          'applications/:id/webhooks/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'applications/1/webhooks'
            }
          },
          'applications/:id/metrics': {
            component: CoreMetricsContainer,
            props: {
              mapping: applicationIdMetrics
            }
          }
        }
      },
      companies: {
        nav_name: 'Companies',
        nav_uri: '/companies',
        is_show_in_nav: true,
        routes: {
          companies: {
            component: ListContainer,
            props: {
              isRouteFlagged: true,
              columns: [
                { name: 'id' },
                { name: 'flag', hide: true },
                { name: 'commercial_name' },
                { name: 'legal_name' },
                { name: 'legal_id' },
                { name: 'legal_identifier' },
                { name: 'type' },
                { name: 'phone' },
                { name: 'email' },
                { name: 'link_code' },
                { name: 'active' },
                { name: 'created' },
                { name: 'updated' }
              ],
              metadataUri: 'companies',
              sub_routes: {
                'companies/:id/company-view': {
                  name: translation().actions.custom_list_actions.company_view,
                  icon: 'view_quilt',
                  is_show_in_table: true,
                  is_route: true
                }
              }
            }
          },
          'companies/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'companies'
            }
          },
          'companies/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'companies',
              sub_routes: {
                '/companies/:id/company-view': {
                  name: translation().actions.custom_list_actions.company_view,
                  icon: 'view_quilt',
                  is_show_in_table: true,
                  is_route: true
                },
                '/companies/:id/metrics': {
                  name: translation().actions.custom_list_actions.metrics,
                  icon: 'multiline_chart',
                  is_show_in_table: true,
                  is_route: true
                },
                '/companies/:id/invoices-emitted': {
                  name: translation().actions.custom_list_actions.invoice_emitted,
                  icon: 'next_week',
                  is_show_in_table: true,
                  is_route: true,
                  is_modal: false
                },
                '/companies/:id/invoices': {
                  name: translation().actions.custom_list_actions.invoices,
                  icon: 'receipt',
                  is_show_in_table: true,
                  is_route: true,
                  is_modal: false
                },
                '/payers-companies-bridge': {
                  name: translation().actions.custom_list_actions.payer,
                  icon: 'card_travel',
                  is_show_in_table: true,
                  is_route: false,
                  is_modal: true,
                  is_link: false,
                  props: {
                    title: translation().core.list.dialogs.custom_list_actions.payer_bridge.title,
                    resourceUri: 'companies'
                  },
                  container: PayerBridgeContainer
                },
                '/companies/:id/payment-methods': {
                  name: translation().actions.custom_list_actions.payment_methods,
                  icon: 'payment',
                  is_show_in_table: true,
                  is_route: true,
                  is_modal: false
                },
                '/companies/:id/users': {
                  name: translation().actions.custom_list_actions.users,
                  icon: 'people',
                  is_show_in_table: true,
                  is_route: true,
                  is_modal: false
                },
                '/companies/:id/flag': {
                  name: translation().actions.custom_list_actions.flag,
                  icon: 'flag',
                  is_show_in_table: true,
                  is_route: false,
                  is_modal: true,
                  is_link: false,
                  props: {
                    title:
                      translation().core.list.dialogs.custom_list_actions.manage_company_flag.title,
                    resourceUri: 'companies'
                  },
                  container: ManageCompanyFlagContainer
                },
                '/companies/:id/companies/:id/link': {
                  name: translation().actions.custom_list_actions.link_to_c,
                  icon: 'link',
                  is_show_in_table: true,
                  is_route: false,
                  is_modal: true,
                  is_link: false,
                  props: {
                    title:
                      translation().core.list.dialogs.custom_list_actions.link_company_to_company
                        .title
                  },
                  container: LinkCompanyToCompanyContainer
                }
              }
            }
          },
          'companies/:id/users': {
            component: ListContainer,
            props: {
              isDeletable: false,
              isUpdatable: false,
              isCreatable: false,
              columns: [
                { name: 'id' },
                { name: 'first_name' },
                { name: 'last_name' },
                { name: 'role' },
                { name: 'mobile' },
                { name: 'email' },
                { name: 'date_of_birth' },
                { name: 'gender' },
                { name: 'company_admin' },
                { name: 'active' },
                { name: 'created' },
                { name: 'updated' }
              ],
              metadataUri: 'companies',
              sub_routes: {
                'companies/:id/users/:id/link': {
                  name: translation().actions.custom_list_actions.link_to_c,
                  icon: 'link',
                  is_show_in_table: true,
                  is_route: false,
                  is_modal: true,
                  is_link: false,
                  props: {
                    title:
                      translation().core.list.dialogs.custom_list_actions.link_user_to_company.title
                  },
                  container: LinkUserToCompanyContainer
                },
                '/users/:id/user-view': {
                  name: translation().actions.custom_list_actions.user_view,
                  icon: 'view_quilt',
                  is_show_in_table: true,
                  is_route: true
                }
              }
            }
          },
          'companies/:id/payment-methods': {
            component: ListContainer,
            props: {
              columns: [
                { name: 'id' },
                { name: 'type' },
                { name: 'name' },
                { name: 'owner_name' },
                { name: 'currency_isocode' },
                { name: 'expire' },
                { name: 'blocked' },
                { name: 'active' },
                { name: 'created' }
              ],
              isUpdatable: false,
              isCreatable: false,
              metadataUri: 'payers/1/payment-methods',
              sub_routes: {
                'companies/:id/payment-methods/:id/block': {
                  name: translation().actions.custom_list_actions.block,
                  icon: 'block',
                  is_show_in_table: true,
                  is_route: false,
                  is_modal: true,
                  is_link: false,
                  props: {
                    title:
                      translation().core.list.dialogs.custom_list_actions.block_payment_method
                        .title,
                    description:
                      translation().core.list.dialogs.custom_list_actions.block_payment_method
                        .content,
                    resourceUri: 'companies'
                  },
                  container: BlockPaymentMethodContainer
                },
                'companies/:id/payment-methods/:id/adminize': {
                  name: translation().actions.custom_list_actions.permissions,
                  icon: 'lock',
                  is_show_in_table: true,
                  is_route: false,
                  is_modal: true,
                  is_link: false,
                  props: {
                    title:
                      translation().core.list.dialogs.custom_list_actions.adminize_payment_method
                        .title
                  },
                  container: AdminizePaymentMethodContainer
                }
              }
            }
          },
          'companies/:id/invoices/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'invoices',
              sub_routes: {
                '/companies/:id/invoices/:id/download': {
                  name: translation().actions.custom_list_actions.download,
                  icon: 'cloud_download',
                  is_show_in_table: true,
                  is_route: false,
                  is_link: true,
                  is_modal: false,
                  props: {
                    link_url: getApiUrl('invoices/:id/download', {
                      mode: 'inline',
                      v: toUnix()
                    })
                  },
                  container: null
                },
                '/companies/:id/invoices/:id/pay': {
                  name: translation().actions.custom_list_actions.pay,
                  icon: 'monetization_on',
                  is_show_in_table: true,
                  is_route: false,
                  is_modal: true,
                  is_link: false,
                  props: {
                    title: translation().core.list.dialogs.custom_list_actions.pay_invoice.title,
                    description:
                      translation().core.list.dialogs.custom_list_actions.pay_invoice.content
                  },
                  container: PayInvoiceContainer
                }
              }
            }
          },
          'companies/:id/invoices-emitted/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'invoices'
            }
          },
          'companies/:id/invoices': {
            component: ListContainer,
            props: {
              isDeletable: false,
              isCreatable: false,
              metadataUri: 'invoices',
              columns: [
                { name: 'id' },
                { name: 'number_formatted' },
                { name: 'date' },
                { name: 'client_name' },
                { name: 'client_address' },
                { name: 'type' },
                { name: 'vendor_name' },
                { name: 'total_formatted' },
                { name: 'payment_status.name' },
                { name: 'created' },
                { name: 'updated' }
              ],
              sub_routes: {
                'companies/:id/invoices/:id/download': {
                  name: translation().actions.custom_list_actions.download,
                  icon: 'cloud_download',
                  is_show_in_table: true,
                  is_route: false,
                  is_link: true,
                  is_modal: false,
                  props: {
                    link_url: getApiUrl('invoices/:id/download', {
                      mode: 'inline',
                      v: toUnix()
                    })
                  },
                  container: null
                },
                'companies/:id/invoices/:id/pay': {
                  name: translation().actions.custom_list_actions.pay,
                  icon: 'monetization_on',
                  is_show_in_table: true,
                  is_route: false,
                  is_modal: true,
                  is_link: false,
                  props: {
                    title: translation().core.list.dialogs.custom_list_actions.pay_invoice.title,
                    description:
                      translation().core.list.dialogs.custom_list_actions.pay_invoice.content
                  },
                  container: PayInvoiceContainer
                }
              }
            }
          },
          'companies/:id/invoices-emitted': {
            component: ListContainer,
            props: {
              isDeletable: false,
              isCreatable: false,
              metadataUri: 'invoices',
              columns: [
                { name: 'id' },
                { name: 'number_formatted' },
                { name: 'date' },
                { name: 'client_name' },
                { name: 'client_address' },
                { name: 'type' },
                { name: 'vendor_name' },
                { name: 'total_formatted' },
                { name: 'payment_status.name' },
                { name: 'created' },
                { name: 'updated' }
              ],
              sub_routes: {
                'companies/:id/invoices-emitted/:id/download': {
                  name: translation().actions.custom_list_actions.download,
                  icon: 'cloud_download',
                  is_show_in_table: true,
                  is_route: false,
                  is_link: true,
                  is_modal: false,
                  props: {
                    link_url: getApiUrl('invoices/:id/download', {
                      mode: 'inline',
                      v: toUnix()
                    })
                  },
                  container: null
                },
                'companies/:id/invoices-emitted/:id/pay': {
                  name: translation().actions.custom_list_actions.pay,
                  icon: 'monetization_on',
                  is_show_in_table: true,
                  is_route: false,
                  is_modal: true,
                  is_link: false,
                  props: {
                    title: translation().core.list.dialogs.custom_list_actions.pay_invoice.title,
                    description:
                      translation().core.list.dialogs.custom_list_actions.pay_invoice.content
                  },
                  container: PayInvoiceContainer
                }
              }
            }
          },
          'companies/:id/invoice-queue': {
            component: ListContainer,
            props: {
              isDeletable: false,
              isCreatable: false,
              metadataUri: 'invoice-queue',
              columns: [
                { name: 'id' },
                { name: 'client_name' },
                { name: 'client_address' },
                { name: 'type' },
                { name: 'reference' },
                { name: 'description' },
                { name: 'created' },
                { name: 'updated' }
              ]
            }
          },
          'companies/:id/invoice-queue/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'invoice-queue'
            }
          },
          'companies/:id/metrics': {
            component: CoreMetricsContainer,
            props: {
              mapping: companyIdMetrics
            }
          },
          'companies/:id/company-view': {
            component: CompanyViewContainer,
            props: {
              metadataUri: 'companies',
              sub_routes: {
                '/companies/:id/companies/:id/link': {
                  name: translation().actions.custom_list_actions.link_to_c,
                  icon: 'link',
                  is_show_in_table: true,
                  is_route: false,
                  is_modal: true,
                  is_link: false,
                  props: {
                    title:
                      translation().core.list.dialogs.custom_list_actions.link_company_to_company
                        .title
                  },
                  container: LinkCompanyToCompanyContainer
                },
                '/companies/:id/flag': {
                  name: translation().actions.custom_list_actions.flag,
                  icon: 'flag',
                  is_show_in_table: true,
                  is_route: false,
                  is_modal: true,
                  is_link: false,
                  props: {
                    title:
                      translation().core.list.dialogs.custom_list_actions.manage_company_flag.title,
                    resourceUri: 'companies'
                  },
                  container: ManageCompanyFlagContainer
                },
                '/companies/:id/users': {
                  name: translation().actions.custom_list_actions.users,
                  icon: 'people',
                  is_show_in_table: true,
                  is_route: true,
                  is_modal: false
                },
                '/companies/:id/payment-methods': {
                  name: translation().actions.custom_list_actions.payment_methods,
                  icon: 'payment',
                  is_show_in_table: true,
                  is_route: true,
                  is_modal: false
                },
                '/payers-companies-bridge': {
                  name: translation().actions.custom_list_actions.payer,
                  icon: 'card_travel',
                  is_show_in_table: true,
                  is_route: false,
                  is_modal: true,
                  is_link: false,
                  props: {
                    title: translation().core.list.dialogs.custom_list_actions.payer_bridge.title,
                    resourceUri: 'companies'
                  },
                  container: PayerBridgeContainer
                },
                '/companies/:id/invoices': {
                  name: translation().actions.custom_list_actions.invoices,
                  icon: 'receipt',
                  is_show_in_table: true,
                  is_route: true,
                  is_modal: false
                },
                '/companies/:id/invoices-emitted': {
                  name: translation().actions.custom_list_actions.invoice_emitted,
                  icon: 'next_week',
                  is_show_in_table: true,
                  is_route: true,
                  is_modal: false
                },
                '/companies/:id/metrics': {
                  name: translation().actions.custom_list_actions.metrics,
                  icon: 'multiline_chart',
                  is_show_in_table: true,
                  is_route: true
                }
              }
            }
          }
        }
      },
      teams: {
        nav_name: 'Teams',
        nav_uri: '/teams',
        is_show_in_nav: true,
        routes: {
          teams: {
            component: ListContainer,
            props: {
              metadataUri: 'teams'
            }
          },
          'teams/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'teams'
            }
          },
          'teams/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'teams'
            }
          }
        }
      },
      users: {
        nav_name: 'Users',
        nav_uri: '/users',
        is_show_in_nav: true,
        routes: {
          users: {
            component: ListContainer,
            props: {
              columns: [
                { name: 'id' },
                { name: 'first_name' },
                { name: 'last_name' },
                { name: 'role' },
                { name: 'mobile' },
                { name: 'email' },
                { name: 'date_of_birth' },
                { name: 'gender' },
                { name: 'company_admin' },
                { name: 'active' },
                { name: 'created' },
                { name: 'updated' }
              ],
              isDuplicable: true,
              metadataUri: 'users',
              sub_routes: {
                'users/:id/user-view': {
                  name: translation().actions.custom_list_actions.user_view,
                  icon: 'view_quilt',
                  is_show_in_table: true,
                  is_route: true
                }
              }
            }
          },
          'users/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'users'
            }
          },
          'users/:id/duplicate': {
            component: CreateContainer,
            props: {
              metadataUri: 'users'
            }
          },
          'users/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'users',
              sub_routes: {
                '/users/:id/user-view': {
                  name: translation().actions.custom_list_actions.user_view,
                  icon: 'view_quilt',
                  is_show_in_table: true,
                  is_route: true
                },
                '/companies/:id/users/:id/link': {
                  name: translation().actions.custom_list_actions.link_to_c,
                  icon: 'link',
                  is_show_in_table: true,
                  is_route: false,
                  is_modal: true,
                  is_link: false,
                  props: {
                    title:
                      translation().core.list.dialogs.custom_list_actions.link_user_to_company.title
                  },
                  container: LinkUserToCompanyContainer
                },
                '/users/:id/payment-methods': {
                  name: translation().actions.custom_list_actions.payment_methods,
                  icon: 'payment',
                  is_show_in_table: true,
                  is_route: true,
                  is_modal: false
                },
                '/payers-users-bridge': {
                  name: translation().actions.custom_list_actions.payer,
                  icon: 'card_travel',
                  is_show_in_table: true,
                  is_route: false,
                  is_modal: true,
                  is_link: false,
                  props: {
                    title: translation().core.list.dialogs.custom_list_actions.payer_bridge.title,
                    resourceUri: 'users'
                  },
                  container: PayerBridgeContainer
                },
                '/users/:id/devices': {
                  name: translation().actions.custom_list_actions.devices,
                  icon: 'devices_other',
                  is_show_in_table: true,
                  is_route: true,
                  is_modal: false
                },
                '/users/:id/invoices': {
                  name: translation().actions.custom_list_actions.invoices,
                  icon: 'receipt',
                  is_show_in_table: true,
                  is_route: true,
                  is_modal: false
                },
                '/users/:id/invoices-emitted': {
                  name: translation().actions.custom_list_actions.invoice_emitted,
                  icon: 'next_week',
                  is_show_in_table: true,
                  is_route: true,
                  is_modal: false
                },
                '/users/:id/positions': {
                  name: translation().actions.custom_list_actions.user_positions,
                  icon: 'room',
                  is_show_in_table: true,
                  is_route: false,
                  is_modal: true,
                  props: {
                    title: translation().core.list.dialogs.custom_list_actions.user_positions.title,
                    resourceUri: 'users',
                    dialog: {
                      fullWidth: true,
                      maxWidth: 'md'
                    }
                  },
                  container: UserPositionsContainer
                },
                '/users/:id/metrics': {
                  name: translation().actions.custom_list_actions.metrics,
                  icon: 'multiline_chart',
                  is_show_in_table: true,
                  is_route: true
                }
              }
            }
          },
          'users/:id/payment-methods': {
            component: ListContainer,
            props: {
              columns: [
                { name: 'id' },
                { name: 'type' },
                { name: 'name' },
                { name: 'owner_name' },
                { name: 'currency_isocode' },
                { name: 'expire' },
                { name: 'blocked' },
                { name: 'active' },
                { name: 'created' }
              ],
              isUpdatable: false,
              isCreatable: false,
              metadataUri: 'payers/1/payment-methods',
              sub_routes: {
                'users/:id/payment-methods/:id/block': {
                  name: translation().actions.custom_list_actions.block,
                  icon: 'block',
                  is_show_in_table: true,
                  is_route: false,
                  is_modal: true,
                  is_link: false,
                  props: {
                    title:
                      translation().core.list.dialogs.custom_list_actions.block_payment_method
                        .title,
                    description:
                      translation().core.list.dialogs.custom_list_actions.block_payment_method
                        .content,
                    resourceUri: 'users'
                  },
                  container: BlockPaymentMethodContainer
                }
              }
            }
          },
          'users/:id/devices': {
            component: ListContainer,
            props: {
              isUpdatable: false,
              isDeletable: false,
              isCreatable: false,
              metadataUri: 'users/1/devices'
            }
          },
          'users/:id/invoices/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'invoices',
              sub_routes: {
                '/users/:id/invoices/:id/download': {
                  name: translation().actions.custom_list_actions.download,
                  icon: 'cloud_download',
                  is_show_in_table: true,
                  is_route: false,
                  is_link: true,
                  is_modal: false,
                  props: {
                    link_url: getApiUrl('invoices/:id/download', {
                      mode: 'inline',
                      v: toUnix()
                    })
                  },
                  container: null
                },
                '/users/:id/invoices/:id/pay': {
                  name: translation().actions.custom_list_actions.pay,
                  icon: 'monetization_on',
                  is_show_in_table: true,
                  is_route: false,
                  is_modal: true,
                  is_link: false,
                  props: {
                    title: translation().core.list.dialogs.custom_list_actions.pay_invoice.title,
                    description:
                      translation().core.list.dialogs.custom_list_actions.pay_invoice.content
                  },
                  container: PayInvoiceContainer
                }
              }
            }
          },
          'users/:id/invoices-emitted/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'invoices',
              sub_routes: {
                '/users/:id/invoices-emitted/:id/download': {
                  name: translation().actions.custom_list_actions.download,
                  icon: 'cloud_download',
                  is_show_in_table: true,
                  is_route: false,
                  is_link: true,
                  is_modal: false,
                  props: {
                    link_url: getApiUrl('invoices/:id/download', {
                      mode: 'inline',
                      v: toUnix()
                    })
                  },
                  container: null
                },
                '/users/:id/invoices-emitted/:id/pay': {
                  name: translation().actions.custom_list_actions.pay,
                  icon: 'monetization_on',
                  is_show_in_table: true,
                  is_route: false,
                  is_modal: true,
                  is_link: false,
                  props: {
                    title: translation().core.list.dialogs.custom_list_actions.pay_invoice.title,
                    description:
                      translation().core.list.dialogs.custom_list_actions.pay_invoice.content
                  },
                  container: PayInvoiceContainer
                }
              }
            }
          },
          'users/:id/invoices': {
            component: ListContainer,
            props: {
              isDeletable: false,
              isCreatable: false,
              metadataUri: 'invoices',
              columns: [
                { name: 'id' },
                { name: 'number_formatted' },
                { name: 'date' },
                { name: 'client_name' },
                { name: 'client_address' },
                { name: 'type' },
                { name: 'vendor_name' },
                { name: 'total_formatted' },
                { name: 'payment_status.name' },
                { name: 'created' },
                { name: 'updated' }
              ],
              sub_routes: {
                'users/:id/invoices/:id/download': {
                  name: translation().actions.custom_list_actions.download,
                  icon: 'cloud_download',
                  is_show_in_table: true,
                  is_route: false,
                  is_link: true,
                  is_modal: false,
                  props: {
                    link_url: getApiUrl('invoices/:id/download', {
                      mode: 'inline',
                      v: toUnix()
                    })
                  },
                  container: null
                },
                'users/:id/invoices/:id/pay': {
                  name: translation().actions.custom_list_actions.pay,
                  icon: 'monetization_on',
                  is_show_in_table: true,
                  is_route: false,
                  is_modal: true,
                  is_link: false,
                  props: {
                    title: translation().core.list.dialogs.custom_list_actions.pay_invoice.title,
                    description:
                      translation().core.list.dialogs.custom_list_actions.pay_invoice.content
                  },
                  container: PayInvoiceContainer
                }
              }
            }
          },
          'users/:id/invoices-emitted': {
            component: ListContainer,
            props: {
              isDeletable: false,
              isCreatable: false,
              metadataUri: 'invoices',
              columns: [
                { name: 'id' },
                { name: 'number_formatted' },
                { name: 'date' },
                { name: 'client_name' },
                { name: 'client_address' },
                { name: 'type' },
                { name: 'vendor_name' },
                { name: 'total_formatted' },
                { name: 'payment_status.name' },
                { name: 'created' },
                { name: 'updated' }
              ],
              sub_routes: {
                'users/:id/invoices-emitted/:id/download': {
                  name: translation().actions.custom_list_actions.download,
                  icon: 'cloud_download',
                  is_show_in_table: true,
                  is_route: false,
                  is_link: true,
                  is_modal: false,
                  props: {
                    link_url: getApiUrl('invoices/:id/download', {
                      mode: 'inline',
                      v: toUnix()
                    })
                  },
                  container: null
                },
                'users/:id/invoices-emitted/:id/pay': {
                  name: translation().actions.custom_list_actions.pay,
                  icon: 'monetization_on',
                  is_show_in_table: true,
                  is_route: false,
                  is_modal: true,
                  is_link: false,
                  props: {
                    title: translation().core.list.dialogs.custom_list_actions.pay_invoice.title,
                    description:
                      translation().core.list.dialogs.custom_list_actions.pay_invoice.content
                  },
                  container: PayInvoiceContainer
                }
              }
            }
          },
          'users/:id/metrics': {
            component: CoreMetricsContainer,
            props: {
              mapping: userIdMetrics
            }
          },
          'users/:id/user-view': {
            component: UserViewContainer,
            props: {
              metadataUri: 'users',
              sub_routes: {
                '/companies/:id/users/:id/link': {
                  name: translation().actions.custom_list_actions.link_to_c,
                  icon: 'link',
                  is_show_in_table: true,
                  is_route: false,
                  is_modal: true,
                  is_link: false,
                  props: {
                    title:
                      translation().core.list.dialogs.custom_list_actions.link_user_to_company.title
                  },
                  container: LinkUserToCompanyContainer
                },
                '/users/:id/payment-methods': {
                  name: translation().actions.custom_list_actions.payment_methods,
                  icon: 'payment',
                  is_show_in_table: true,
                  is_route: true,
                  is_modal: false
                },
                '/payers-users-bridge': {
                  name: translation().actions.custom_list_actions.payer,
                  icon: 'card_travel',
                  is_show_in_table: true,
                  is_route: false,
                  is_modal: true,
                  is_link: false,
                  props: {
                    title: translation().core.list.dialogs.custom_list_actions.payer_bridge.title,
                    resourceUri: 'users'
                  },
                  container: PayerBridgeContainer
                },
                '/users/:id/devices': {
                  name: translation().actions.custom_list_actions.devices,
                  icon: 'devices_other',
                  is_show_in_table: true,
                  is_route: true,
                  is_modal: false
                },
                '/users/:id/invoices': {
                  name: translation().actions.custom_list_actions.invoices,
                  icon: 'receipt',
                  is_show_in_table: true,
                  is_route: true,
                  is_modal: false
                },
                '/users/:id/invoices-emitted': {
                  name: translation().actions.custom_list_actions.invoice_emitted,
                  icon: 'next_week',
                  is_show_in_table: true,
                  is_route: true,
                  is_modal: false
                },
                '/users/:id/positions': {
                  name: translation().actions.custom_list_actions.user_positions,
                  icon: 'room',
                  is_show_in_table: true,
                  is_route: false,
                  is_modal: true,
                  props: {
                    title: translation().core.list.dialogs.custom_list_actions.user_positions.title,
                    resourceUri: 'users',
                    dialog: {
                      fullWidth: true,
                      maxWidth: 'md'
                    }
                  },
                  container: UserPositionsContainer
                },
                '/users/:id/metrics': {
                  name: translation().actions.custom_list_actions.metrics,
                  icon: 'multiline_chart',
                  is_show_in_table: true,
                  is_route: true
                }
              }
            }
          }
        }
      },
      'user-levels': {
        nav_name: 'User levels',
        nav_uri: '/user-levels',
        is_show_in_nav: true,
        routes: {
          'user-levels': {
            component: ListContainer,
            props: {
              columns: [
                { name: 'id' },
                { name: 'name' },
                { name: 'shortcode' },
                { name: 'power' },
                { name: 'is_default' },
                { name: 'is_admin' },
                { name: 'active' },
                { name: 'created' },
                { name: 'updated' }
              ],
              metadataUri: 'user-levels'
            }
          },
          'user-levels/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'user-levels'
            }
          },
          'user-levels/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'user-levels'
            }
          }
        }
      },
      'user-types': {
        nav_name: 'User types',
        nav_uri: '/user-types',
        is_show_in_nav: true,
        routes: {
          'user-types': {
            component: ListContainer,
            props: {
              columns: 8,
              metadataUri: 'user-types'
            }
          },
          'user-types/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'user-types'
            }
          },
          'user-types/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'user-types'
            }
          }
        }
      },
      'iam-metrics': {
        nav_name: 'Metrics',
        nav_uri: '/iam-metrics',
        is_show_in_nav: true,
        routes: {
          'iam-metrics': {
            component: CoreMetricsContainer,
            props: {
              mapping: iamMetrics
            }
          }
        }
      }
    }
  },
  gateway: {
    nav_icon: 'router',
    nav_name: 'Gateway',
    resources: {
      calls: {
        nav_name: 'Calls',
        nav_uri: '/calls',
        is_show_in_nav: true,
        routes: {
          calls: {
            component: ListContainer,
            props: {
              columns: [
                { name: 'id' },
                { name: 'method' },
                { name: 'url' },
                { name: 'route' },
                { name: 'format' },
                { name: 'ip' },
                { name: 'domain' },
                { name: 'status_code' },
                { name: 'service_id' },
                { name: 'execution_time' },
                { name: 'secure' },
                { name: 'created' }
              ],
              metadataUri: 'calls'
            }
          },
          'calls/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'calls'
            }
          },
          'calls/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'calls'
            }
          }
        }
      },
      services: {
        nav_name: 'Services',
        nav_uri: '/services',
        is_show_in_nav: true,
        routes: {
          services: {
            component: ListContainer,
            props: {
              columns: [
                { name: 'id' },
                { name: 'name' },
                { name: 'api_key' },
                { name: 'base_url' },
                { name: 'callback_url' },
                { name: 'documentation_url' },
                { name: 'active' },
                { name: 'created' },
                { name: 'updated' }
              ],
              metadataUri: 'services',
              sub_routes: {
                'services/:id/versions': {
                  name: translation().actions.custom_list_actions.versions,
                  icon: 'layers',
                  is_show_in_table: true,
                  is_route: true,
                  is_modal: false
                },
                'services/:id/webhooks': {
                  name: translation().actions.custom_list_actions.web_hooks,
                  icon: 'device_hub',
                  is_show_in_table: true,
                  is_route: true,
                  is_modal: false
                },
                'services/:id/metrics': {
                  name: translation().actions.custom_list_actions.metrics,
                  icon: 'multiline_chart',
                  is_show_in_table: true,
                  is_route: true
                }
              }
            }
          },
          'services/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'services'
            }
          },
          'services/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'services',
              sub_routes: {
                '/services/:id/versions': {
                  name: translation().actions.custom_list_actions.versions,
                  icon: 'layers',
                  is_show_in_table: true,
                  is_route: true,
                  is_modal: false
                },
                '/services/:id/webhooks': {
                  name: translation().actions.custom_list_actions.web_hooks,
                  icon: 'device_hub',
                  is_show_in_table: true,
                  is_route: true,
                  is_modal: false
                },
                '/services/:id/metrics': {
                  name: translation().actions.custom_list_actions.metrics,
                  icon: 'multiline_chart',
                  is_show_in_table: true,
                  is_route: true
                }
              }
            }
          },
          'services/:id/versions': {
            component: ListContainer,
            props: {
              metadataUri: 'services/1/versions',
              sub_routes: {
                'versions/:id/endpoints': {
                  name: translation().actions.custom_list_actions.endpoints,
                  icon: 'linear_scale',
                  is_show_in_table: true,
                  is_route: true,
                  is_modal: false
                },
                'versions/:id/metrics': {
                  name: translation().actions.custom_list_actions.metrics,
                  icon: 'multiline_chart',
                  is_show_in_table: true,
                  is_route: true
                }
              }
            }
          },
          'services/:id/metrics': {
            component: CoreMetricsContainer,
            props: {
              mapping: serviceIdMetrics
            }
          },
          'services/:id/versions/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'services/1/versions'
            }
          },
          'services/:id/versions/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'services/1/versions',
              sub_routes: {
                endpoints: {
                  name: translation().actions.custom_list_actions.endpoints,
                  icon: 'linear_scale',
                  is_show_in_table: true,
                  is_route: true,
                  is_modal: false
                },
                metrics: {
                  name: translation().actions.custom_list_actions.metrics,
                  icon: 'multiline_chart',
                  is_show_in_table: true,
                  is_route: true
                }
              }
            }
          },
          'services/:service_id/versions/:version_id/metrics': {
            component: CoreMetricsContainer,
            props: {
              mapping: serviceIdVersionIdMetrics
            }
          },
          'services/:service_id/versions/:version_id/endpoints/:endpoint_id/metrics': {
            component: CoreMetricsContainer,
            props: {
              mapping: serviceIdVersionIdEndpointIdMetrics
            }
          },
          'services/:id/webhooks': {
            component: ListContainer,
            props: {
              metadataUri: 'services/1/webhooks',
              columns: [
                { name: 'id' },
                { name: 'event.shortcode' },
                { name: 'url' },
                { name: 'auth' },
                { name: 'client_id' },
                { name: 'format' },
                { name: 'active' },
                { name: 'created' },
                { name: 'updated' }
              ]
            }
          },
          'services/:id/webhooks/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'services/1/webhooks'
            }
          },
          'services/:id/webhooks/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'services/1/webhooks'
            }
          },
          'services/:id/versions/:id/endpoints': {
            component: ListContainer,
            props: {
              metadataUri: 'services/1/versions/1/endpoints',
              sub_routes: {
                'endpoints/:id/metrics': {
                  name: translation().actions.custom_list_actions.metrics,
                  icon: 'multiline_chart',
                  is_show_in_table: true,
                  is_route: true
                }
              }
            }
          },
          'services/:id/versions/:id/endpoints/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'services/1/versions/1/endpoints'
            }
          },
          'services/:id/versions/:id/endpoints/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'services/1/versions/1/endpoints',
              sub_routes: {
                metrics: {
                  name: translation().actions.custom_list_actions.metrics,
                  icon: 'multiline_chart',
                  is_show_in_table: true,
                  is_route: true
                }
              }
            }
          }
        }
      },
      'gateway-metrics': {
        nav_name: 'Metrics',
        nav_uri: '/gateway-metrics',
        is_show_in_nav: true,
        routes: {
          'gateway-metrics': {
            component: CoreMetricsContainer,
            props: {
              mapping: gatewayMetrics
            }
          }
        }
      },
      'api-health-monitor': {
        nav_name: 'Health monitor',
        nav_uri: '/api-health-monitor',
        is_show_in_nav: true,
        routes: {
          'api-health-monitor': {
            component: ApiHealthMonitorContainer
          }
        }
      }
    }
  },
  geo: {
    nav_icon: 'public',
    nav_name: 'Geo',
    resources: {
      countries: {
        nav_name: 'Countries',
        nav_uri: '/countries',
        is_show_in_nav: true,
        routes: {
          countries: {
            component: ListContainer,
            props: {
              columns: [
                { name: 'id' },
                { name: 'name' },
                { name: 'name_intl' },
                { name: 'isocode' },
                { name: 'timezone_code' },
                { name: 'phone_prefix' },
                { name: 'phone_format' },
                { name: 'has_states' },
                { name: 'active' },
                { name: 'created' },
                { name: 'updated' }
              ],
              metadataUri: 'countries'
            }
          },
          'countries/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'countries'
            }
          },
          'countries/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'countries'
            }
          }
        }
      },
      currencies: {
        nav_name: 'Currencies',
        nav_uri: '/currencies',
        is_show_in_nav: true,
        routes: {
          currencies: {
            component: ListContainer,
            props: {
              columns: [
                { name: 'id' },
                { name: 'name' },
                { name: 'name_intl' },
                { name: 'isocode' },
                { name: 'symbol' },
                { name: 'exchange_rate' },
                { name: 'base' },
                { name: 'active' },
                { name: 'created' },
                { name: 'updated' }
              ],
              metadataUri: 'currencies'
            }
          },
          'currencies/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'currencies'
            }
          },
          'currencies/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'currencies'
            }
          }
        }
      },
      languages: {
        nav_name: 'Languages',
        nav_uri: '/languages',
        is_show_in_nav: true,
        routes: {
          languages: {
            component: ListContainer,
            props: {
              columns: [
                { name: 'id' },
                { name: 'name' },
                { name: 'name_intl' },
                { name: 'locale' },
                { name: 'alphabet' },
                { name: 'icon_url' },
                { name: 'rtl' },
                { name: 'active' },
                { name: 'created' },
                { name: 'updated' }
              ],
              metadataUri: 'languages'
            }
          },
          'languages/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'languages'
            }
          },
          'languages/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'languages'
            }
          }
        }
      },
      taxes: {
        nav_name: 'Taxes',
        nav_uri: '/taxes',
        is_show_in_nav: true,
        routes: {
          taxes: {
            component: ListContainer,
            props: {
              columns: 7,
              metadataUri: 'taxes'
            }
          },
          'taxes/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'taxes'
            }
          },
          'taxes/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'taxes'
            }
          }
        }
      },
      'tax-rules': {
        nav_name: 'Tax rules',
        nav_uri: '/tax-rules',
        is_show_in_nav: true,
        routes: {
          'tax-rules': {
            component: ListContainer,
            props: {
              metadataUri: 'tax-rules',
              sub_routes: {
                'tax-rules/:id/taxes': {
                  name: translation().actions.custom_list_actions.taxes,
                  icon: 'account_balance',
                  is_show_in_table: true,
                  is_route: true,
                  is_modal: false
                }
              }
            }
          },
          'tax-rules/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'tax-rules'
            }
          },
          'tax-rules/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'tax-rules',
              sub_routes: {
                '/tax-rules/:id/taxes': {
                  name: translation().actions.custom_list_actions.taxes,
                  icon: 'account_balance',
                  is_show_in_table: true,
                  is_route: true,
                  is_modal: false
                }
              }
            }
          },
          'tax-rules/:id/taxes': {
            component: ListContainer,
            props: {
              metadataUri: 'tax-rules/1/taxes',
              columns: [
                { name: 'id' },
                { name: 'country.name' },
                { name: 'state' },
                { name: 'consumer_tax.name' },
                { name: 'business_tax.rate' },
                { name: 'consumer_tax.name' },
                { name: 'consumer_tax.rate' },
                { name: 'created' },
                { name: 'updated' }
              ]
            }
          },
          'tax-rules/:id/taxes/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'tax-rules/1/taxes'
            }
          },
          'tax-rules/:id/taxes/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'tax-rules/1/taxes'
            }
          }
        }
      },
      transportations: {
        nav_name: 'Transportations',
        nav_uri: '/transportations',
        is_show_in_nav: true,
        routes: {
          transportations: {
            component: ListContainer,
            props: {
              metadataUri: 'transportations'
            }
          },
          'transportations/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'transportations'
            }
          },
          'transportations/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'transportations'
            }
          }
        }
      },
      units: {
        nav_name: 'Units',
        nav_uri: '/units',
        is_show_in_nav: true,
        routes: {
          units: {
            component: ListContainer,
            props: {
              metadataUri: 'units'
            }
          },
          'units/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'units'
            }
          },
          'units/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'units'
            }
          }
        }
      },
      zones: {
        nav_name: 'Zones',
        nav_uri: '/zones',
        is_show_in_nav: true,
        routes: {
          zones: {
            component: ListContainer,
            props: {
              metadataUri: 'zones'
            }
          },
          'zones/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'zones'
            }
          },
          'zones/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'zones'
            }
          }
        }
      }
    }
  },
  History: {
    nav_icon: 'watch_later',
    nav_name: 'History',
    resources: {
      errors: {
        nav_name: 'Errors',
        nav_uri: '/errors',
        is_show_in_nav: true,
        routes: {
          errors: {
            component: ListContainer,
            props: {
              columns: 7,
              metadataUri: 'errors'
            }
          },
          'errors/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'errors'
            }
          },
          'errors/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'errors'
            }
          }
        }
      },
      logs: {
        nav_name: 'Logs',
        nav_uri: '/logs',
        is_show_in_nav: true,
        routes: {
          logs: {
            component: ListContainer,
            props: {
              columns: 7,
              metadataUri: 'logs'
            }
          },
          'logs/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'logs'
            }
          },
          'logs/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'logs'
            }
          }
        }
      },
      operations: {
        nav_name: 'Operations',
        nav_uri: '/operations',
        is_show_in_nav: true,
        routes: {
          operations: {
            component: ListContainer,
            props: {
              columns: 7,
              metadataUri: 'operations'
            }
          },
          'operations/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'operations'
            }
          },
          'operations/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'operations'
            }
          }
        }
      }
    }
  },
  Hook: {
    nav_icon: 'device_hub',
    nav_name: 'Hook',
    resources: {
      'hook-events': {
        nav_name: 'Events',
        nav_uri: '/hook-events',
        is_show_in_nav: true,
        routes: {
          'hook-events': {
            component: ListContainer,
            props: {
              columns: [
                { name: 'id' },
                { name: 'name' },
                { name: 'shortcode' },
                { name: 'active' },
                { name: 'created' },
                { name: 'updated' }
              ],
              metadataUri: 'hook-events'
            }
          },
          'hook-events/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'hook-events'
            }
          },
          'hook-events/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'hook-events'
            }
          }
        }
      }
    }
  },
  Notify: {
    nav_icon: 'notification_important',
    nav_name: 'Notify',
    resources: {
      notifications: {
        nav_name: 'Notifications',
        nav_uri: '/notifications',
        is_show_in_nav: true,
        routes: {
          notifications: {
            component: ListContainer,
            props: {
              columns: 9,
              metadataUri: 'notifications'
            }
          },
          'notifications/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'notifications'
            }
          },
          'notifications/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'notifications'
            }
          }
        }
      },
      'notification-templates': {
        nav_name: 'Notification templates',
        nav_uri: '/notification-templates',
        is_show_in_nav: true,
        routes: {
          'notification-templates': {
            component: ListContainer,
            props: {
              metadataUri: 'notification-templates'
            }
          },
          'notification-templates/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'notification-templates'
            }
          },
          'notification-templates/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'notification-templates'
            }
          }
        }
      }
    }
  },
  Pay: {
    nav_icon: 'euro_symbol',
    nav_name: 'Pay',
    resources: {
      Payers: {
        nav_name: 'Payers',
        nav_uri: '/payers',
        is_show_in_nav: true,
        routes: {
          payers: {
            component: ListContainer,
            props: {
              metadataUri: 'payers',
              sub_routes: {
                'payers/:id/payment-methods': {
                  name: translation().actions.custom_list_actions.payment_methods,
                  icon: 'payment',
                  is_show_in_table: true,
                  is_route: true,
                  is_modal: false
                },
                'payers/:id/balance': {
                  name: translation().actions.custom_list_actions.balance,
                  icon: 'account_balance_wallet',
                  is_show_in_table: true,
                  is_route: false,
                  is_modal: true,
                  is_link: false,
                  props: {
                    title: translation().core.list.dialogs.custom_list_actions.pay_withdrawal.title,
                    dialog: {
                      fullWidth: true,
                      maxWidth: 'md'
                    }
                  },
                  container: PayerBalanceContainer
                }
              }
            }
          },
          'payers/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'payers'
            }
          },
          'payers/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'payers',
              sub_routes: {
                '/payers/:id/payment-methods': {
                  name: translation().actions.custom_list_actions.payment_methods,
                  icon: 'payment',
                  is_show_in_table: true,
                  is_route: true,
                  is_modal: false
                },
                '/payers/:id/balance': {
                  name: translation().actions.custom_list_actions.balance,
                  icon: 'account_balance_wallet',
                  is_show_in_table: true,
                  is_route: false,
                  is_modal: true,
                  is_link: false,
                  props: {
                    title: translation().core.list.dialogs.custom_list_actions.pay_withdrawal.title,
                    dialog: {
                      fullWidth: true,
                      maxWidth: 'md'
                    }
                  },
                  container: PayerBalanceContainer
                }
              }
            }
          },
          'payers/:id/payment-methods': {
            component: ListContainer,
            props: {
              customCreateContainer: {
                title:
                  translation().core.list.dialogs.custom_create_actions.create_payment_method.title,
                dialog: {
                  fullWidth: true
                },
                container: CreatePaymentMethodContainer
              },
              metadataUri: 'payers/1/payment-methods'
            }
          },
          'payers/:id/payment-methods/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'payers/1/payment-methods'
            }
          }
        }
      },
      'Payment modes': {
        nav_name: 'Payment modes',
        nav_uri: '/payment-modes',
        is_show_in_nav: true,
        routes: {
          'payment-modes': {
            component: ListContainer,
            props: {
              metadataUri: 'payment-modes'
            }
          },
          'payment-modes/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'payment-modes'
            }
          },
          'payment-modes/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'payment-modes'
            }
          }
        }
      },
      transactions: {
        nav_name: 'Transactions',
        nav_uri: '/transactions',
        is_show_in_nav: true,
        routes: {
          transactions: {
            component: ListContainer,
            props: {
              isExportable: true,
              columns: [
                { name: 'id' },
                { name: 'type' },
                { name: 'reference' },
                { name: 'amount' },
                { name: 'fees' },
                { name: 'currency_isocode' },
                { name: 'system_name' },
                { name: 'system_id' },
                { name: 'status' },
                { name: 'executed' },
                { name: 'execute_on' },
                { name: 'created' }
              ],
              metadataUri: 'transactions'
            }
          },
          'transactions/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'transactions'
            }
          },
          'transactions/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'transactions'
            }
          }
        }
      }
    }
  },
  Invoice: {
    nav_icon: 'receipt',
    nav_name: 'Invoice',
    resources: {
      invoices: {
        nav_name: 'Invoices',
        nav_uri: '/invoices',
        is_show_in_nav: true,
        routes: {
          invoices: {
            component: ListContainer,
            props: {
              isDeletable: false,
              isExportable: true,
              columns: [
                { name: 'id' },
                { name: 'number_formatted' },
                { name: 'date' },
                { name: 'client_name' },
                { name: 'client_address' },
                { name: 'type' },
                { name: 'vendor_name' },
                { name: 'total_formatted' },
                { name: 'payment_status.name' },
                { name: 'created' },
                { name: 'updated' }
              ],
              metadataUri: 'invoices',
              sub_routes: {
                'invoices/:id/download': {
                  name: translation().actions.custom_list_actions.download,
                  icon: 'cloud_download',
                  is_show_in_table: true,
                  is_route: false,
                  is_modal: false,
                  is_link: true,
                  props: {
                    link_url: getApiUrl('invoices/:id/download', {
                      mode: 'inline',
                      v: toUnix()
                    })
                  }
                },
                'invoices/:id/pay': {
                  name: translation().actions.custom_list_actions.pay,
                  icon: 'monetization_on',
                  is_show_in_table: true,
                  is_route: false,
                  is_modal: true,
                  is_link: false,
                  props: {
                    title: translation().core.list.dialogs.custom_list_actions.pay_invoice.title,
                    description:
                      translation().core.list.dialogs.custom_list_actions.pay_invoice.content
                  },
                  container: PayInvoiceContainer
                }
              }
            }
          },
          'invoices/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'invoices'
            }
          },
          'invoices/:id/update': {
            component: UpdateContainer,
            props: {
              sub_routes: {
                '/invoices/:id/download': {
                  name: translation().actions.custom_list_actions.download,
                  icon: 'cloud_download',
                  is_show_in_table: true,
                  is_route: false,
                  is_modal: false,
                  is_link: true,
                  props: {
                    link_url: getApiUrl('invoices/:id/download', {
                      mode: 'inline',
                      v: toUnix()
                    })
                  }
                },
                '/invoices/:id/pay': {
                  name: translation().actions.custom_list_actions.pay,
                  icon: 'monetization_on',
                  is_show_in_table: true,
                  is_route: false,
                  is_modal: true,
                  is_link: false,
                  props: {
                    title: translation().core.list.dialogs.custom_list_actions.pay_invoice.title,
                    description:
                      translation().core.list.dialogs.custom_list_actions.pay_invoice.content
                  },
                  container: PayInvoiceContainer
                }
              },
              collections_columns: {
                lines: {
                  name: 'lines',
                  columns: [
                    { name: 'id' },
                    { name: 'reference' },
                    { name: 'description' },
                    { name: 'total_tax_excluded_formatted' },
                    { name: 'total_formatted' }
                  ],
                  sub_routes: {
                    'invoices/:id/lines/:id/cancel': {
                      name: translation().actions.custom_list_actions.cancel_line,
                      icon: 'undo',
                      is_show_in_table: true,
                      is_route: false,
                      is_modal: true,
                      is_link: false,
                      props: {
                        title:
                          translation().core.list.dialogs.custom_list_actions.cancel_invoice_line
                            .title,
                        description:
                          translation().core.list.dialogs.custom_list_actions.cancel_invoice_line
                            .content
                      },
                      container: CancelInvoiceLineContainer
                    }
                  }
                },
                payments: {
                  name: 'payments',
                  columns: [
                    { name: 'id' },
                    { name: 'transaction_reference' },
                    { name: 'payment_method_description' },
                    { name: 'currency_isocode' },
                    { name: 'status' },
                    { name: 'amount_formatted' },
                    { name: 'scheduled' },
                    { name: 'executed' }
                  ]
                }
              },
              metadataUri: 'invoices'
            }
          }
        }
      },
      invoicers: {
        nav_name: 'Invoicers',
        nav_uri: '/invoicers',
        is_show_in_nav: true,
        routes: {
          invoicers: {
            component: ListContainer,
            props: {
              columns: [
                { name: 'id' },
                { name: 'iam_user_id' },
                { name: 'iam_company_id' },
                { name: 'invoice_frequency' },
                { name: 'next_invoice_date' },
                { name: 'next_invoice_number' },
                { name: 'is_default_client' },
                { name: 'is_default_vendor' },
                { name: 'auto_pay' },
                { name: 'active' },
                { name: 'created' },
                { name: 'updated' }
              ],
              metadataUri: 'invoicers',
              sub_routes: {
                'invoicers/:id/metrics': {
                  name: translation().actions.custom_list_actions.metrics,
                  icon: 'multiline_chart',
                  is_show_in_table: true,
                  is_route: true
                }
              }
            }
          },
          'invoicers/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'invoicers'
            }
          },
          'invoicers/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'invoicers',
              sub_routes: {
                '/invoicers/:id/metrics': {
                  name: translation().actions.custom_list_actions.metrics,
                  icon: 'multiline_chart',
                  is_show_in_table: true,
                  is_route: true
                }
              }
            }
          },
          'invoicers/:id/metrics': {
            component: CoreMetricsContainer,
            props: {
              mapping: invoicerIdMetrics
            }
          }
        }
      },
      'invoice-queue': {
        nav_name: 'Invoice queue',
        nav_uri: '/invoice-queue',
        is_show_in_nav: true,
        routes: {
          'invoice-queue': {
            component: ListContainer,
            props: {
              columns: [
                { name: 'id' },
                { name: 'date' },
                { name: 'client_name' },
                { name: 'client_address' },
                { name: 'vendor_name' },
                { name: 'reference' },
                { name: 'description' },
                { name: 'quantity' },
                { name: 'total_tax_excluded_formatted' },
                { name: 'total_formatted' },
                { name: 'created' },
                { name: 'updated' }
              ],
              metadataUri: 'invoice-queue'
            }
          },
          'invoice-queue/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'invoice-queue'
            }
          },
          'invoice-queue/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'invoice-queue'
            }
          }
        }
      },
      'payment-statuses': {
        nav_name: 'Payment statuses',
        nav_uri: '/payment-statuses',
        is_show_in_nav: true,
        routes: {
          'payment-statuses': {
            component: ListContainer,
            props: {
              columns: [
                { name: 'id' },
                { name: 'name' },
                { name: 'shortcode' },
                { name: 'description' },
                { name: 'is_dispute' },
                { name: 'is_paid' },
                { name: 'is_partial' },
                { name: 'is_pending' },
                { name: 'is_refunded' },
                { name: 'is_rejected' },
                { name: 'is_submitted' },
                { name: 'created' },
                { name: 'updated' }
              ],
              metadataUri: 'payment-statuses'
            }
          },
          'payment-statuses/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'payment-statuses'
            }
          },
          'payment-statuses/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'payment-statuses'
            }
          }
        }
      }
    }
  },
  tasks: {
    nav_icon: 'assignment_turned_in',
    nav_name: 'Mission',
    resources: {
      completion_status: {
        nav_name: 'Completion statuses',
        nav_uri: '/completion-statuses',
        is_show_in_nav: true,
        routes: {
          'completion-statuses': {
            component: ListContainer,
            props: {
              isCreatable: true,
              isDeletable: true,
              metadataUri: 'completion-statuses'
            }
          },
          'completion-statuses/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'completion-statuses'
            }
          },
          'completion-statuses/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'completion-statuses'
            }
          }
        }
      },
      tasks: {
        nav_name: 'Tasks',
        nav_uri: '/tasks',
        is_show_in_nav: true,
        routes: {
          tasks: {
            component: ListContainer,
            props: {
              isCreatable: true,
              isDeletable: true,
              metadataUri: 'tasks'
            }
          },
          'tasks/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'tasks'
            }
          },
          'tasks/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'tasks'
            }
          }
        }
      },
      tasks_templates: {
        nav_name: 'Task templates',
        nav_uri: '/task-templates',
        is_show_in_nav: true,
        routes: {
          'task-templates': {
            component: ListContainer,
            props: {
              isCreatable: true,
              isDeletable: true,
              metadataUri: 'task-templates',
              columns: [
                { name: 'id' },
                { name: 'event.shortcode' },
                { name: 'name' },
                { name: 'action' },
                { name: 'conditions' },
                { name: 'filters' },
                { name: 'position' },
                { name: 'created' }
              ]
            }
          },
          'task-templates/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'task-templates'
            }
          },
          'task-templates/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'task-templates'
            }
          }
        }
      },
      tasks_views: {
        nav_name: 'Task views',
        nav_uri: '/task-views',
        is_show_in_nav: true,
        routes: {
          'task-views': {
            component: ListContainer,
            props: {
              isCreatable: true,
              isDeletable: true,
              metadataUri: 'task-views'
            }
          },
          'task-views/create': {
            component: CreateContainer,
            props: {
              metadataUri: 'task-views'
            }
          },
          'task-views/:id/update': {
            component: UpdateContainer,
            props: {
              metadataUri: 'task-views'
            }
          }
        }
      }
    }
  }
};
