import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

import Alert from '../../../../components/alert/Alert';
import FormApiAutocompleteField from '../../../../components/forms/fields/FormApiAutocompleteField';
import translation from '../../../../translation/translation';

function UserStep(props) {
  const { onSelect, user, addresses, addressAvailable, paymentAvailable, paymentMethods } = props;

  function handleChange(user) {
    onSelect(user);
  }

  return (
    <div>
      <FormApiAutocompleteField
        autoFocus
        name="user"
        label={translation().views.orders.create.informations.client}
        links={[{ href: 'users', rel: 'list' }]}
        getFullObject
        autoComplete="off"
        defaultValue={user}
        onSelectValue={handleChange}
        required
        fullWidth
      />
      {(addresses.isLoading || paymentMethods.isLoading) && (
        <CircularProgress size={30} color="secondary" />
      )}
      {user && !paymentAvailable && !paymentMethods.isLoading && (
        <Alert
          type="warning"
          style={{ marginTop: 10 }}
          status={translation().commons.alerts.warning}
          text={translation().views.order_products.hosting_extensions.create.no_payment_methods}
        />
      )}
      {user && !addressAvailable && !addresses.isLoading && (
        <Alert
          type="warning"
          style={{ marginTop: 10 }}
          status={translation().commons.alerts.warning}
          text={translation().views.order_products.hosting_extensions.create.no_addresses}
        />
      )}
    </div>
  );
}

UserStep.defaultProps = {
  addressAvailable: false,
  paymentAvailable: false
};

UserStep.propTypes = {
  onSelect: PropTypes.func.isRequired,
  user: PropTypes.shape(),
  addresses: PropTypes.shape({
    isLoading: PropTypes.bool
  }).isRequired,
  addressAvailable: PropTypes.bool,
  paymentMethods: PropTypes.shape({
    isLoading: PropTypes.bool
  }).isRequired,
  paymentAvailable: PropTypes.bool
};

export default UserStep;
