import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

import Alert from '../../../../../components/alert/Alert';
import FormSelectField from '../../../../../components/forms/fields/FormSelectField';
import GooglePlaceField from '../../../../../components/forms/fields/customs/GooglePlaceField';
import MissingFieldsForm from '../../../../orders/create/components/MissingFieldsForm';
import MultipleSelectField from '../../../../../components/forms/fields/customs/MultipleSelectField';
import SizeInput from '../../../../orders/components/fields/SizeInput';
import { convertUnitSystem } from '../../../../../utils/functions/utils';
import { fetchApi } from '../../../../../utils/functions/api';
import translation from '../../../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2)
  },
  submitWrapper: {
    marginTop: 24,
    textAlign: 'right'
  },
  marginTop: {
    marginTop: 24
  }
}));

function Simulator(props) {
  const classes = useStyles();

  const { groupId, productId, user } = props;

  const [isAddressFailed, setIsAddressFailed] = useState(null);
  const [isProductLoading, setProductLoading] = useState(false);
  const [isProductLoaded, setProductLoaded] = useState(false);
  const [isOptionsLoading, setOptionsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [missingFields, setMissingFields] = useState(null);
  const [missingFieldsValues, setMissingFieldsValues] = useState(null);
  const [product, setProduct] = useState(null);
  const [options, setOptions] = useState(null);
  const [optionsSelected, setOptionsSelected] = useState('');
  const [address, setAddress] = useState('');
  const [size, setSize] = useState('');
  const [type, setType] = useState('');

  const currency = user.currency && user.currency.isocode ? user.currency.isocode : 'EUR';
  const currencySymbol = user.currency && user.currency.symbol ? user.currency.symbol : '€';
  const unit_system = user.unit_system ? user.unit_system : 'metric';

  function handleChangeSize(value) {
    setSize(value);
  }

  function handleChangeType(value) {
    setType(value);
  }

  function handleChangeAddress(value) {
    setAddress(value);
  }

  function handleSelectAddress(value, suggestion) {
    if (value) {
      if (!value.postal_code || !value.locality || !value.route) {
        setIsAddressFailed(true);
        setAddress('');
      } else {
        setIsAddressFailed(false);
        setAddress(suggestion.description);
      }
    } else {
      setIsAddressFailed(true);
      setAddress('');
    }
  }

  function handleSubmitProperty() {
    if (!groupId || !productId) return;

    setProduct(null);
    setIsError(false);
    setOptions(null);
    setOptionsSelected('');
    setMissingFieldsValues(null);
    setMissingFields(null);
    getProduct();
  }

  function getProduct(values = {}) {
    setProductLoading(true);
    setProductLoaded(false);

    fetchApi(
      'get',
      'products/orderable',
      {
        filters: `product_group_id|eq|${groupId}, product_id|eq|${productId}`,
        currency,
        unit_system,
        property: {
          address,
          size,
          property_type: type,
          ...missingFieldsValues,
          ...values
        }
      },
      null,
      null,
      (data) => {
        setProductLoaded(true);
        setProductLoading(false);
        setMissingFields(null);

        for (let i = 0; i < data.length; i++) {
          if (data[i].id === productId) {
            setProduct(data[i]);
            getOptions({
              currency,
              unit_system,
              property: {
                address,
                size,
                property_type: type,
                ...missingFieldsValues,
                ...values
              }
            });
            break;
          }
        }
      },
      (error) => {
        if (error && error.response && error.response.data) {
          if (
            error.response.data.status_code &&
            error.response.data.status_code === 422 &&
            error.response.data.errors &&
            error.response.data.errors.length > 0
          ) {
            setMissingFields(error.response.data.errors);
          } else {
            setIsError(true);
          }
        } else {
          setIsError(true);
        }

        setProductLoading(false);
      }
    );
  }

  function getOptions(query) {
    if (!groupId || !productId) return;

    setOptionsLoading(true);
    setOptions(null);
    setOptionsSelected('');

    fetchApi(
      'get',
      `products/${productId}/options/orderable`,
      query,
      null,
      null,
      (data) => {
        setOptionsLoading(false);
        setOptions(data);

        if (data && data.length > 0) {
          const optionsAdvised = [];

          for (let i = 0; i < data.length; i++) {
            if (data[i].advised) {
              optionsAdvised.push(data[i]);
            }
          }

          setOptionsSelected(optionsAdvised);
        }

        setMissingFieldsValues(null);
        setMissingFields(null);
      },
      (error) => {
        if (error && error.response && error.response.data) {
          if (
            error.response.data.status_code &&
            error.response.data.status_code === 422 &&
            error.response.data.errors &&
            error.response.data.errors.length > 0
          ) {
            setMissingFields(error.response.data.errors);
          } else {
            setIsError(true);
          }
        } else {
          setIsError(true);
        }
        setOptionsLoading(false);
      }
    );
  }

  function handleChangeOptions(values) {
    setOptionsSelected(values);
  }

  function handleChangeMissingFields(values) {
    setMissingFieldsValues(values);
    getProduct(values);
  }

  function calcPriceTaxExcluded(productPrice, optionsSelected) {
    let optionsPrice = 0;
    let totalPrice = 0;

    totalPrice += productPrice;

    for (let i = 0; i < optionsSelected.length; i++) {
      optionsPrice += optionsSelected[i].price_tax_excluded;
    }

    totalPrice += optionsPrice;

    return totalPrice;
  }

  function calcPriceTaxIncluded(productPrice, optionsSelected) {
    let optionsPrice = 0;
    let totalPrice = 0;

    totalPrice += productPrice;

    for (let i = 0; i < optionsSelected.length; i++) {
      optionsPrice += optionsSelected[i].price;
    }

    totalPrice += optionsPrice;

    return totalPrice;
  }

  return (
    <div className={classes.root}>
      <Typography variant="subtitle1" gutterBottom className={classes.title}>
        {translation().views.orders.create.informations.title}
      </Typography>
      <GooglePlaceField
        type="text"
        name="address"
        label={translation().views.orders.create.informations.address}
        isResultMapped
        onChange={handleChangeAddress}
        onSelectAddress={handleSelectAddress}
        value={address}
        required
        autoComplete="off"
        error={isAddressFailed ? translation().views.orders.create.informations.error_property : ''}
      />
      <SizeInput
        id="property_size"
        name="size"
        label={translation().views.orders.create.informations.size}
        onChange={handleChangeSize}
        value={size}
        required
        autoComplete="off"
        adornment={convertUnitSystem(
          user.data && user.data.unit_system ? user.data.unit_system : null
        )}
      />
      <FormSelectField
        id="property_type"
        name="property_type"
        label={translation().views.orders.create.informations.type}
        onChange={handleChangeType}
        value={type}>
        <MenuItem value="apartment">
          {translation().views.orders.create.informations.types.apartment || 'apartment'}
        </MenuItem>
        <MenuItem value="house">
          {translation().views.orders.create.informations.types.house || 'house'}
        </MenuItem>
        <MenuItem value="office">
          {translation().views.orders.create.informations.types.office || 'office'}
        </MenuItem>
        <MenuItem value="other">
          {translation().views.orders.create.informations.types.other || 'other'}
        </MenuItem>
      </FormSelectField>
      {missingFields && missingFields.length > 0 ? (
        <div className={classes.marginTop}>
          <Typography variant="subtitle1" gutterBottom className={classes.title}>
            {translation().views.orders.create.products.missingFields.title}
          </Typography>
          <MissingFieldsForm
            fields={missingFields}
            buttonLabel={translation().actions.confirm}
            isFormLoading={missingFields.isLoading}
            onSubmit={handleChangeMissingFields}
          />
        </div>
      ) : (
        false
      )}
      {isProductLoading && (
        <div className="loader-wpr">
          <CircularProgress size={28} color="secondary" />
          <p>{translation().views.orders.create.products.product.loading_text}</p>
        </div>
      )}
      {isOptionsLoading && (
        <div className="loader-wpr">
          <CircularProgress size={28} color="secondary" />
          <p>{translation().views.orders.create.products.options.loading_text}</p>
        </div>
      )}
      {options && options.length > 0 ? (
        <MultipleSelectField
          id="options"
          name="options"
          label={translation().views.orders.create.products.options.label}
          onChange={handleChangeOptions}
          defaultValue={optionsSelected || []}>
          {options.map((option, index) => {
            return (
              <MenuItem key={index} value={option}>
                {option.name +
                  ' (' +
                  translation().commons.pricing.tax_excluded +
                  ': ' +
                  option.price_tax_excluded_formatted +
                  ', ' +
                  translation().commons.pricing.tax_included +
                  ': ' +
                  option.price_formatted +
                  ')'}
              </MenuItem>
            );
          })}
        </MultipleSelectField>
      ) : (
        !isOptionsLoading &&
        product && (
          <Typography variant="subtitle2">
            {translation().views.orders.create.products.options.empty}
          </Typography>
        )
      )}
      {isError && (
        <Alert
          type="danger"
          style={{ marginTop: 10 }}
          status={translation().commons.alerts.error}
          text={translation().core.list.error_list}
        />
      )}
      {product && (
        <div className={classes.marginTop}>
          <Typography gutterBottom>
            {translation().views.product_view.pricing.simulator.result}
          </Typography>
          {product.price && (
            <Typography variant="subtitle2">
              {translation().commons.pricing.total_formatted}{' '}
              {calcPriceTaxIncluded(product.price, optionsSelected)} {currencySymbol}
            </Typography>
          )}
          {product.price_tax_excluded && (
            <Typography variant="button">
              {translation().commons.pricing.total_tax_excluded_formatted}{' '}
              {calcPriceTaxExcluded(product.price_tax_excluded, optionsSelected)} {currencySymbol}
            </Typography>
          )}
        </div>
      )}
      {!product && isProductLoaded && (
        <div className={classes.marginTop}>
          <Typography variant="subtitle2">
            {translation().views.orders.create.products.product.empty}
          </Typography>
        </div>
      )}
      {!missingFields ? (
        <div className={classes.submitWrapper}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmitProperty}
            disabled={!address || !size}>
            {translation().actions.confirm}
          </Button>
        </div>
      ) : (
        false
      )}
    </div>
  );
}

Simulator.propTypes = {
  groupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  productId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  user: PropTypes.shape().isRequired
};

export default Simulator;
