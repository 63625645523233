/**
 * Invoicer Id metrics
 */
export default {
  name: 'Invoicers metrics',
  routes_selector: [
    {
      label: 'Factures émises',
      value: 'invoices-emittted'
    },
    {
      label: 'Factures reçues',
      value: 'invoices'
    },
    {
      label: 'Queues émises',
      value: 'invoice-queue-emitted'
    },
    {
      label: 'Queues reçues',
      value: 'invoice-queue-received'
    }
  ],
  metrics_routes: {
    'invoices-emittted': {
      child_routes: {
        'invoices-emittted-interval-auto': {
          route: 'invoices',
          chart_props: {
            type: 'line',
            title: 'Nombre de factures:',
            metric_calc_mode: 'count'
          },
          query_params: {
            time_interval: 'auto'
          },
          filter_key: 'vendor_invoicer_id'
        },
        'invoices-emittted-interval-auto-period': {
          route: 'invoices',
          chart_props: {
            title: 'Évolution du nombre de factures (période):',
            type: 'trending',
            metric_calc_mode: 'avg',
            custom: true
          },
          query_params: {
            compare: 'period'
          },
          filter_key: 'vendor_invoicer_id'
        },
        'invoices-emittted-revenue-interval-auto': {
          route: 'invoices-revenue',
          chart_props: {
            type: 'line',
            title: 'CA factures',
            metric_calc_mode: 'count'
          },
          query_params: {
            time_interval: 'auto'
          },
          filter_key: 'vendor_invoicer_id'
        },
        'invoices-emittted-revenue-interval-auto-period': {
          route: 'invoices-revenue',
          chart_props: {
            title: 'Évolution du CA factures (période):',
            type: 'trending',
            metric_calc_mode: 'avg',
            custom: true
          },
          query_params: {
            compare: 'period'
          },
          filter_key: 'vendor_invoicer_id'
        }
      }
    },
    invoices: {
      child_routes: {
        'invoices-interval-auto': {
          route: 'invoices',
          chart_props: {
            type: 'line',
            title: 'Nombre de factures:',
            metric_calc_mode: 'count'
          },
          query_params: {
            time_interval: 'auto'
          },
          filter_key: 'client_invoicer_id'
        },
        'invoices-interval-auto-period': {
          route: 'invoices',
          chart_props: {
            title: 'Évolution du nombre de factures (période):',
            type: 'trending',
            metric_calc_mode: 'avg',
            custom: true
          },
          query_params: {
            compare: 'period'
          },
          filter_key: 'client_invoicer_id'
        },
        'invoices-revenue-interval-auto': {
          route: 'invoices-revenue',
          chart_props: {
            type: 'line',
            title: 'CA factures',
            metric_calc_mode: 'count'
          },
          query_params: {
            time_interval: 'auto'
          },
          filter_key: 'client_invoicer_id'
        },
        'invoices-revenue-interval-auto-period': {
          route: 'invoices-revenue',
          chart_props: {
            title: 'Évolution du CA factures (période):',
            type: 'trending',
            metric_calc_mode: 'avg',
            custom: true
          },
          query_params: {
            compare: 'period'
          },
          filter_key: 'client_invoicer_id'
        }
      }
    },
    'invoice-queue-emitted': {
      child_routes: {
        'invoice-queue-interval-auto': {
          route: 'invoice-queue',
          chart_props: {
            type: 'line',
            title: 'Nombre de factures:',
            metric_calc_mode: 'count'
          },
          query_params: {
            time_interval: 'auto'
          },
          filter_key: 'vendor_invoicer_id'
        },
        'invoice-queue-interval-auto-period': {
          route: 'invoice-queue',
          chart_props: {
            title: 'Évolution du nombre de factures (période):',
            type: 'trending',
            metric_calc_mode: 'avg',
            custom: true
          },
          query_params: {
            compare: 'period'
          },
          filter_key: 'vendor_invoicer_id'
        },
        'invoice-queue-revenue-interval-auto': {
          route: 'invoice-queue-revenue',
          chart_props: {
            type: 'line',
            title: 'CA factures',
            metric_calc_mode: 'count'
          },
          query_params: {
            time_interval: 'auto'
          },
          filter_key: 'vendor_invoicer_id'
        },
        'invoice-queue-revenue-interval-auto-period': {
          route: 'invoice-queue-revenue',
          chart_props: {
            title: 'Évolution du CA factures (période):',
            type: 'trending',
            metric_calc_mode: 'avg',
            custom: true
          },
          query_params: {
            compare: 'period'
          },
          filter_key: 'vendor_invoicer_id'
        }
      }
    },
    'invoice-queue-received': {
      child_routes: {
        'invoice-queue-interval-auto': {
          route: 'invoice-queue',
          chart_props: {
            type: 'line',
            title: 'Nombre de factures:',
            metric_calc_mode: 'count'
          },
          query_params: {
            time_interval: 'auto'
          },
          filter_key: 'client_invoicer_id'
        },
        'invoice-queue-interval-auto-period': {
          route: 'invoice-queue',
          chart_props: {
            title: 'Évolution du nombre de factures (période):',
            type: 'trending',
            metric_calc_mode: 'avg',
            custom: true
          },
          query_params: {
            compare: 'period'
          },
          filter_key: 'client_invoicer_id'
        },
        'invoice-queue-revenue-interval-auto': {
          route: 'invoice-queue-revenue',
          chart_props: {
            type: 'line',
            title: 'CA factures',
            metric_calc_mode: 'count'
          },
          query_params: {
            time_interval: 'auto'
          },
          filter_key: 'client_invoicer_id'
        },
        'invoice-queue-revenue-interval-auto-period': {
          route: 'invoice-queue-revenue',
          chart_props: {
            title: 'Évolution du CA factures (période):',
            type: 'trending',
            metric_calc_mode: 'avg',
            custom: true
          },
          query_params: {
            compare: 'period'
          },
          filter_key: 'client_invoicer_id'
        }
      }
    }
  }
};
