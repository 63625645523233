import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDropzone } from 'react-dropzone';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import _ from 'lodash';

import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';

import { generateHash } from '../../../utils/functions/utils';

import FileCard from './components/FileCard';
import UploadFileInDropperIcon from '../../icons/UploadFileInDropperIcon';

import translation from '../../../translation/translation';

const FileCardSortable = SortableElement(
  ({ file, mediasErrors, sliderIndex, onDeleteFile, onOpenSlider }) => {
    return (
      <FileCard
        inputPlaceholder={translation().views.orders.media_groups.file_uploader.autocomplete}
        file={file}
        onDeleteFile={onDeleteFile}
        onOpenSlider={onOpenSlider}
        sliderIndex={sliderIndex}
        mediasErrors={mediasErrors}
      />
    );
  }
);

const SortableFileCardList = SortableContainer(
  ({
    filesArray,
    mediasErrors,
    onClearDroppper,
    onDeleteFile,
    onOpenSlider,
    openDropper,
    uploadInProgressCount
  }) => {
    function handleOpenDropzone() {
      openDropper();
    }

    function handleClearDropper() {
      onClearDroppper();
    }

    return (
      <div>
        {!uploadInProgressCount && filesArray && filesArray.length <= 0 ? (
          <div className="drop-it">
            <UploadFileInDropperIcon height={125} />
            <div>
              <Typography component="p">
                {translation().views.orders.media_groups.file_uploader.drop_file}
              </Typography>
              <Typography className="or-click" component="span">
                {translation().views.orders.media_groups.file_uploader.or}
              </Typography>
              <Button
                variant="contained"
                onClick={handleOpenDropzone}
                aria-label="Drop files"
                size="small">
                {translation().views.orders.media_groups.file_uploader.select}
              </Button>
            </div>
          </div>
        ) : (
          <>
            <Fab
              aria-label="Remove all files"
              size="small"
              className="drop-corner-btn remove-all"
              onClick={handleClearDropper}>
              <DeleteForeverIcon />
            </Fab>
            <Fab
              aria-label="Drop files"
              size="small"
              color="primary"
              className="drop-corner-btn"
              onClick={handleOpenDropzone}>
              <AddIcon />
            </Fab>
          </>
        )}
        {filesArray && filesArray.length > 0
          ? filesArray.map((file, index) => {
              return (
                <FileCardSortable
                  key={`item-${generateHash(file.url)}`}
                  file={file}
                  index={index}
                  sortIndex={index}
                  sliderIndex={index}
                  onDeleteFile={onDeleteFile}
                  onOpenSlider={onOpenSlider}
                  style={{ zIndex: 10000 }}
                  mediasErrors={mediasErrors}
                />
              );
            })
          : ''}
        {_.times(
          uploadInProgressCount && uploadInProgressCount > 0 && uploadInProgressCount,
          (index) => (
            <div className="MuiPaper-root file-incoming" key={index}>
              <div className="file-loader">
                <CircularProgress color="secondary" size={35} />
              </div>
            </div>
          )
        )}
      </div>
    );
  }
);

SortableFileCardList.propTypes = {
  onDeleteFile: PropTypes.func.isRequired,
  filesArray: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onSortEnd: PropTypes.func.isRequired,
  uploadInProgressCount: PropTypes.number,
  totalMinSize: PropTypes.number,
  totalMaxSize: PropTypes.number,
  onOpenSlider: PropTypes.func,
  openDropper: PropTypes.func.isRequired,
  onClearDroppper: PropTypes.func.isRequired
};

function FileUploader(props) {
  const {
    extensionsAccepted,
    filesArray,
    mediasErrors,
    name,
    totalMaxSize,
    totalMinSize,
    onClearDroppper,
    onDeleteFile,
    onDrop,
    onOpenSlider,
    onSortEnd,
    uploadInProgressCount
  } = props;

  function handleDrop(filesAccepted, filesRejected) {
    if (onDrop) {
      onDrop(filesAccepted, filesRejected);
    }
  }

  const { getRootProps, isDragActive, getInputProps, open } = useDropzone({
    accept: extensionsAccepted,
    disableClick: true,
    noClick: true,
    noKeyboard: true,
    multiple: true,
    onDrop: handleDrop,
    maxSize: totalMaxSize,
    minSize: totalMinSize,
    name: name
  });

  return (
    <div className="drop-zone">
      <div
        {...getRootProps({
          className: classNames('dropzone dropper', {
            'dropzone--isActive dropper-ondrag': isDragActive
          })
        })}>
        <input {...getInputProps()} />
        <SortableFileCardList
          axis="xy"
          filesArray={filesArray}
          helperClass="sortable-helper"
          hideSortableGhost={false}
          lockToContainerEdges={false}
          mediasErrors={mediasErrors}
          onClearDroppper={onClearDroppper}
          onDeleteFile={onDeleteFile}
          onSortEnd={onSortEnd}
          onOpenSlider={onOpenSlider}
          openDropper={open}
          uploadInProgressCount={uploadInProgressCount}
        />
      </div>
    </div>
  );
}

FileUploader.propTypes = {
  extensionsAccepted: PropTypes.any.isRequired,
  filesArray: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  mediasErrors: PropTypes.shape(),
  name: PropTypes.any.isRequired,
  onClearDroppper: PropTypes.func.isRequired,
  onDeleteFile: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
  onOpenSlider: PropTypes.func,
  onSortEnd: PropTypes.func.isRequired,
  totalMaxSize: PropTypes.number,
  totalMinSize: PropTypes.number,
  uploadInProgressCount: PropTypes.number.isRequired
};

export default FileUploader;
