import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

function FormTextField(props) {
  const {
    autoComplete,
    defaultValue,
    disabled,
    error,
    helperText,
    id,
    fullWidth,
    label,
    margin,
    multiline,
    name,
    style,
    type,
    rows,
    rowsMax,
    required,
    onChange,
    write,
    ...other
  } = props;

  function handleChange(event) {
    if (event && onChange) {
      onChange(event.target.value);
    }
  }

  function handleMouseWheel(event) {
    event.target.blur();
  }

  return (
    <TextField
      {...other}
      id={id}
      autoComplete={autoComplete}
      defaultValue={defaultValue}
      disabled={!write || disabled}
      error={error ? true : false}
      helperText={error || helperText}
      margin={margin}
      multiline={multiline}
      name={name}
      label={label}
      type={type}
      rows={rows}
      style={multiline ? { ...style, overflow: 'auto' } : style}
      rowsMax={rowsMax}
      required={required}
      onChange={handleChange}
      onWheel={handleMouseWheel}
      fullWidth={fullWidth}
      inputProps={type && type === 'number' ? { step: 'any' } : {}}
    />
  );
}

FormTextField.defaultProps = {
  fullWidth: true,
  margin: 'normal',
  multiline: false,
  style: {},
  type: 'text',
  rows: 1,
  rowsMax: 10,
  write: true
};

FormTextField.propTypes = {
  autoComplete: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fullWidth: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  multiline: PropTypes.bool,
  margin: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  style: PropTypes.shape(),
  type: PropTypes.string,
  required: PropTypes.bool,
  rows: PropTypes.number,
  rowsMax: PropTypes.number,
  onChange: PropTypes.func,
  write: PropTypes.bool
};

export default FormTextField;
