const initialState = {
  data: [],
  error: false,
  loading: false,
  paging: null,
  selected: {
    data: null,
    loading: false,
    error: null
  },
  create_view: {
    loading: false,
    error: null
  },
  delete_view: {
    loading: false,
    error: null
  },
  update_view: {
    loading: false,
    error: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_REDUCERS':
      return initialState;
    case 'CLEAR_TASK_VIEWS':
      return initialState;
    case 'CLEAR_TASK_VIEW':
      return {
        ...state,
        selected: initialState.selected
      };
    case 'REFRESH_TASK_VIEWS':
      return {
        ...initialState,
        loading: true
      };
    case 'SET_TASK_VIEW_PROP':
      return {
        ...state,
        selected: {
          ...state.selected,
          data: {
            ...state.selected.data,
            [action.prop]: action.value
          }
        }
      };
    case 'REFRESH_TASK_VIEW':
      return {
        ...state,
        selected: {
          ...state.selected,
          loading: true
        }
      };
    case 'GET_TASK_VIEW':
      return {
        ...state,
        selected: {
          loading: true,
          error: null,
          data: null
        }
      };
    case 'GET_TASK_VIEW_SUCCESS':
      return {
        ...state,
        selected: {
          loading: false,
          error: null,
          data: action.data
        }
      };
    case 'GET_TASK_VIEW_ERROR':
      return {
        ...state,
        selected: {
          ...state.selected,
          loading: false,
          error: action.error
        }
      };
    case 'GET_TASK_VIEWS':
      return {
        ...state,
        data: action.next ? state.data : initialState.data,
        loading: true
      };
    case 'GET_TASK_VIEWS_SUCCESS': {
      const dataConcatenated = state.data.concat(action.data);

      return {
        ...state,
        paging: action.paging ? action.paging : state.paging,
        data: dataConcatenated,
        error: null,
        loading: false
      };
    }
    case 'GET_TASK_VIEWS_ERROR':
      return {
        ...state,
        loading: false,
        error: true
      };
    case 'CREATE_TASK_VIEW':
      return {
        ...state,
        create_view: {
          error: null,
          loading: true
        }
      };
    case 'CREATE_TASK_VIEW_SUCCESS': {
      const newArray = [...state.data, action.data];

      return {
        ...state,
        data: newArray,
        create_view: {
          error: null,
          loading: false
        }
      };
    }
    case 'CREATE_TASK_VIEW_ERROR':
      return {
        ...state,
        create_view: {
          error: action.error,
          loading: false
        }
      };
    case 'UPDATE_TASK_VIEW':
      return {
        ...state,
        update_view: {
          loading: true,
          error: null
        }
      };
    case 'UPDATE_TASK_VIEW_SUCCESS': {
      let newArray = [];

      if (state.data.length > 0) {
        for (let i = 0; i < state.data.length; i++) {
          if (state.data[i].id === action.data.id) {
            newArray = [...state.data];
            newArray[i] = action.data;
            break;
          } else {
            newArray = [...state.data, action.data];
          }
        }
      } else {
        newArray = [...state.data, action.data];
      }

      return {
        ...state,
        data: newArray,
        update_view: {
          loading: false,
          error: null
        }
      };
    }
    case 'UPDATE_TASK_VIEW_ERROR':
      return {
        ...state,
        update_view: {
          loading: false,
          error: action.error
        }
      };
    case 'DELETE_TASK_VIEW':
      return {
        ...state,
        delete_view: {
          loading: true,
          error: null
        }
      };
    case 'DELETE_TASK_VIEW_SUCCESS': {
      const arrayAfterDelete = state.data.filter((el) => {
        return el.id !== action.id;
      });

      return {
        ...state,
        data: arrayAfterDelete,
        delete_view: {
          loading: false,
          error: null
        }
      };
    }
    case 'DELETE_TASK_VIEW_ERROR':
      return {
        ...state,
        delete_view: {
          loading: false,
          error: action.error
        }
      };
    default:
      return state;
  }
};
