import './lab.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import BuildIcon from '@material-ui/icons/Build';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PowerIcon from '@material-ui/icons/Power';
import Typography from '@material-ui/core/Typography';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';

import Documentation from './components/Documentation';
import IconLibrary from './components/IconLibrary';
import LightshopPlugin from './components/LightshopPlugin';
import Workshop from './components/Workshop';
import ReactIcon from '../../components/icons/ReactIcon';
import translation from '../../translation/translation';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  pageTitle: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row'
  },
  tabs: {
    flexGrow: 1,
    width: '100%'
  },
  tabPanel: {
    marginTop: theme.spacing(2)
  },
  item: {
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 4,
    padding: 9,
    width: 'auto',
    border: '1px solid #dcdcdc'
  }
}));

function Lab(props) {
  const { history } = props;
  const tabArray = ['forge', 'icons', 'doc', 'lsplugin'];

  const classes = useStyles();
  const [tab, setTab] = useState(0);

  useEffect(() => {
    document.title = 'Actarus | Lab';

    if (history && history.location && history.location.hash) {
      if (history.location.hash === '#forge') setTab(0);
      else if (history.location.hash === '#icons') setTab(1);
      else if (history.location.hash === '#doc') setTab(2);
      else if (history.location.hash === '#lsplugin') setTab(3);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleChangeTab(e, newTab) {
    setTab(newTab);
    window.location.hash = tabArray[newTab];
  }

  return (
    <div className="Lab">
      <Container>
        <div className={classes.pageTitle}>
          <Typography gutterBottom component="h2" variant="h2" className={classes.title}>
            {translation().views.lab.page_title}
            <ReactIcon width={75} />
          </Typography>
          <Typography gutterBottom component="p" variant="body1">
            {translation().views.lab.page_baseline}
          </Typography>
        </div>
        <div className={classes.tabs}>
          <Tabs
            value={tab}
            onChange={handleChangeTab}
            variant="scrollable"
            scrollButtons="auto"
            indicatorColor="secondary"
            textColor="secondary"
            aria-label="lab tabs">
            <Tab icon={<BuildIcon />} label={translation().views.lab.workshop.title} />
            <Tab icon={<FavoriteIcon />} label={translation().views.lab.icons.title} />
            <Tab icon={<LocalLibraryIcon />} label={translation().views.lab.doc.title} />
            <Tab icon={<PowerIcon />} label={translation().views.lab.lightshop_plugin.title} />
          </Tabs>
        </div>
        <Paper className={classes.tabPanel}>
          <div className={classes.root}>
            {tab === 0 && <Workshop />}
            {tab === 1 && <IconLibrary />}
            {tab === 2 && <Documentation />}
            {tab === 3 && <LightshopPlugin />}
          </div>
        </Paper>
      </Container>
    </div>
  );
}

Lab.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape()
  })
};

export default Lab;
