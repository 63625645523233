const initialState = {
  data: [],
  isError: false,
  isLoading: false,
  paging: null,
  search: {
    value: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_REDUCERS':
      return initialState;
    case 'CLEAR_ORDER_PRODUCTS_LIST_REDUCER':
      return initialState;
    case 'REFRESH_ORDER_PRODUCTS':
      return {
        ...initialState,
        isLoading: true
      };
    case 'SET_ORDER_PRODUCTS_SEARCH_VALUE':
      return {
        ...state,
        search: {
          ...state.search,
          value: action.value
        }
      };
    case 'GET_ORDER_PRODUCTS':
      return {
        ...state,
        data: action.next ? state.data : initialState.data,
        paging: action.search ? initialState.paging : state.paging,
        isLoading: true,
        isError: false
      };
    case 'GET_ORDER_PRODUCTS_SUCCESS': {
      const dataConcatenated = state.data.concat(action.data);

      return {
        ...state,
        data: dataConcatenated,
        paging: action.paging ? action.paging : state.paging,
        isLoading: false,
        isError: false
      };
    }
    case 'GET_ORDER_PRODUCTS_ERROR':
      return {
        ...state,
        isLoading: false,
        isError: true
      };
    default:
      return state;
  }
};
