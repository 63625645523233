import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchApi } from '../../../../utils/functions/api';
import { enqueueSnackbar } from '../../../../redux/actions/appActions';
import {
  getMetricsList,
  getMetricsListSuccess,
  getMetricsListError
} from '../../../../redux/actions/metrics/dashboard/form/metricsFormActions';
import translation from '../../../../translation/translation';
import MetricsForm from '../../../../views/metrics/dashboard/form/MetricsForm';

const mapStateToProps = (store) => ({
  metricsForm: store.metrics.dashboard.form
});

const mapDispatchToProps = (dispatch) => {
  return {
    getMetricsList() {
      dispatch(getMetricsList());

      fetchApi(
        'get',
        'metrics',
        null,
        null,
        null,
        (success) => {
          dispatch(getMetricsListSuccess(success));
        },
        (error) => {
          dispatch(getMetricsListError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.error_list,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().core.list.error_list,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MetricsForm));
