const initialState = {
  data: [],
  metadata: null,
  error: false,
  loading: false,
  paging: null,
  completion_statuses: {
    data: null,
    loading: false,
    error: null
  },
  teams: {
    data: null,
    loading: false,
    error: null
  },
  create: {
    loading: false,
    error: false
  },
  update: {
    loading: false
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_REDUCERS':
      return initialState;
    case 'CLEAR_TASKS':
      return initialState;
    case 'GET_TASK_METADATA_SUCCESS':
      return {
        ...state,
        metadata: action.metadata
      };
    case 'REFRESH_TASK_VIEW':
      return {
        ...state,
        data: initialState.data,
        paging: initialState.paging,
        error: initialState.error,
        create: initialState.create,
        update: initialState.update
      };
    case 'GET_COMPLETION_STATUSES':
      return {
        ...state,
        completion_statuses: {
          loading: true,
          data: null,
          error: null
        }
      };
    case 'GET_COMPLETION_STATUSES_SUCCESS':
      return {
        ...state,
        completion_statuses: {
          loading: false,
          data: action.data,
          error: null
        }
      };
    case 'GET_COMPLETION_STATUSES_ERROR':
      return {
        ...state,
        completion_statuses: {
          ...state.completion_statuses,
          loading: false,
          error: action.error
        }
      };
    case 'GET_TASKS_VIEW_TEAMS':
      return {
        ...state,
        teams: {
          loading: true,
          data: null,
          error: null
        }
      };
    case 'GET_TASKS_VIEW_TEAMS_SUCCESS':
      return {
        ...state,
        teams: {
          loading: false,
          data: action.data,
          error: null
        }
      };
    case 'GET_TASKS_VIEW_TEAMS_ERROR':
      return {
        ...state,
        teams: {
          ...state.teams,
          loading: false,
          error: action.error
        }
      };
    case 'REFRESH_TASKS':
      return {
        ...state,
        data: initialState.data,
        paging: initialState.paging,
        loading: true,
        error: false
      };
    case 'GET_TASKS':
      return {
        ...state,
        data: action.next ? state.data : initialState.data,
        paging: action.search ? initialState.paging : state.paging,
        loading: true,
        error: false
      };
    case 'GET_TASKS_SUCCESS': {
      const dataConcatenated = state.data.concat(action.data);

      return {
        ...state,
        data: dataConcatenated,
        paging: action.paging ? action.paging : state.paging,
        loading: false,
        error: null
      };
    }
    case 'GET_TASKS_ERROR':
      return {
        ...state,
        loading: false,
        error: true
      };
    case 'CREATE_TASK':
      return {
        ...state,
        create: {
          loading: true,
          error: false
        }
      };
    case 'CREATE_TASK_SUCCESS':
      return {
        ...state,
        create: {
          loading: false,
          error: false
        }
      };
    case 'CREATE_TASK_ERROR':
      return {
        ...state,
        create: {
          loading: false,
          error: true
        }
      };
    case 'UPDATE_TASK_VIEW_PROPS':
      return {
        ...state,
        update: {
          loading: true
        }
      };
    case 'UPDATE_TASK_VIEW_PROPS_SUCCESS':
      return {
        ...state,
        update: {
          loading: false
        }
      };
    case 'UPDATE_TASK_VIEW_PROPS_ERROR':
      return {
        ...state,
        update: {
          loading: false
        }
      };
    default:
      return state;
  }
};
