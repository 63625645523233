import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Breadcrumbs,
  IconButton,
  Toolbar as MuiToolbar,
  Tooltip,
  Typography
} from '@material-ui/core';
import { ArrowBack as ArrowBackIcon, NavigateNext as NavigateNextIcon } from '@material-ui/icons';

import { isNavigationBackPossible, navigateBack } from '../../../../utils/functions/navigation';
import translation from '../../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  actionsWpr: {
    display: 'flex'
  },
  created: {
    marginLeft: 4
  },
  root: {
    width: '100%'
  },
  grow: {
    flexGrow: 1
  },
  breadcrumb: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },
  backIcon: {
    padding: 10
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  }
}));

function Toolbar(props) {
  const { isLoading, history, parentUri, routeUri } = props;

  const classes = useStyles();

  function handleHistoryBack() {
    navigateBack(history);
  }

  return (
    <div className={classes.root}>
      <AppBar elevation={0} position="static" color="default">
        <MuiToolbar>
          <Breadcrumbs
            maxItems={5}
            separator={<NavigateNextIcon fontSize="small" />}
            arial-label="Create order breadcrumb"
            className={classes.breadcrumb}>
            <Typography variant="body2" color="textPrimary">
              {parentUri}
            </Typography>
            <Typography variant="body2" color="textPrimary">
              {routeUri}
            </Typography>
          </Breadcrumbs>
          <div className={classes.grow} />
          <div className={classes.actionsWpr}>
            {isNavigationBackPossible(history) && (
              <Tooltip
                title={translation().actions.back || 'Back'}
                aria-label={translation().actions.back || 'Back'}
                enterDelay={700}>
                <div>
                  <IconButton
                    color="inherit"
                    aria-label="Back to orders"
                    onClick={handleHistoryBack}
                    className={classes.backIcon}
                    disabled={isLoading}>
                    <ArrowBackIcon fontSize="small" />
                  </IconButton>
                </div>
              </Tooltip>
            )}
          </div>
        </MuiToolbar>
      </AppBar>
    </div>
  );
}

Toolbar.defaultProps = {
  parentUri: 'Providers',
  routeUri: 'Provider factory'
};

Toolbar.propTypes = {
  isLoading: PropTypes.bool,
  history: PropTypes.shape().isRequired,
  parentUri: PropTypes.string.isRequired,
  routeUri: PropTypes.string.isRequired
};

export { Toolbar };
