import './userView.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AccountCircleTwoToneIcon from '@material-ui/icons/AccountCircleTwoTone';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Alert from '../../../components/alert/Alert';
import UserInvoices from './components/invoices/UserInvoices';
import UserOrders from './components/orders/UserOrders';
import UserMetrics from './components/metrics/UserMetrics';
// import UserRatings from './components/ratings/UserRatings'
import UserSummary from './components/summary/UserSummary';
import UserViewBar from './components/UserViewBar';
import translation from '../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  loaderIcon: {
    fontSize: 56,
    display: 'block',
    margin: '15px auto'
  },
  linearProgress: {
    margin: 'auto',
    maxWidth: 250
  },
  progressWrapper: {
    padding: theme.spacing(3),
    textAlign: 'center',
    width: '100%',
    '& .loader-wpr': {
      margin: 0,
      paddingTop: 7,
      paddingBottom: 10
    }
  },
  tabsRoot: {
    ...theme.mixins.gutters()
  }
}));

function UserView(props) {
  const classes = useStyles();

  const tabs = [
    {
      label: translation().views.user_view.infos.label,
      hash: 'infos',
      value: 0
    },
    {
      label: translation().views.user_view.orders.label,
      hash: 'orders',
      value: 1
    },
    {
      label: translation().views.user_view.invoice_label,
      hash: 'invoices',
      value: 2
    },
    {
      label: translation().views.user_view.metrics.label,
      hash: 'metrics',
      value: 3
    }
    // {
    //   label: translation().views.user_view.ratings.label,
    //   hash: "ratings",
    //   value: 4
    // },
  ];

  const {
    cleanUserViewAddressesReducer,
    cleanUserViewDevicesReducer,
    cleanUserViewInvoicesEmittedReducer,
    cleanUserViewInvoicesReducer,
    cleanUserViewOrdersReducer,
    cleanUserViewPaymentMethodReducer,
    // cleanUserViewRatingsReducer,
    getUser,
    getUserViewAddresses,
    getUserViewDevices,
    getUserViewInvoices,
    getUserViewInvoicesEmitted,
    getUserViewPaymentMethods,
    // getUserViewRatings,
    getUserViewOrders,
    getOrdersMetadata,
    getInvoicerId,
    getPayerId,
    history,
    match,
    location: { pathname },
    user: {
      data,
      error,
      addresses,
      isLoading,
      invoices,
      devices,
      invoices_emitted,
      payment_methods,
      orders
      // ratings
    },
    updateUserLevel,
    sub_routes
  } = props;

  const [tab, setTab] = useState(0);

  function loadView() {
    if (match && match.params && match.params.id) {
      getUser(match && match.params && match.params.id, () => {
        if (history && history.location && history.location.hash) {
          for (let i = 0; i < tabs.length; i++) {
            if (tabs[i].hash === history.location.hash.substring(1)) {
              setTab(i);
              break;
            }
          }
        }
      });
    }
  }

  useEffect(() => {
    if (match && match.params && match.params.id) {
      document.title = `Actarus | User #${match.params.id}`;
    } else {
      document.title = 'Actarus | User view';
    }

    loadView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleRefresh() {
    loadView();
  }

  function handleChangeTab(e, newTab) {
    setTab(newTab);

    for (let i = 0; i < tabs.length; i++) {
      if (tabs[i].value === newTab) {
        window.location.hash = tabs[i].hash;
        break;
      }
    }
  }

  return (
    <div className="UserView">
      <UserViewBar
        item={data ? data : null}
        history={history}
        parentUri="Iam"
        routeUri={pathname.substring(1)}
        subRoutes={sub_routes}
        disabled={false}
        handleRefresh={handleRefresh}
      />
      <div className="UserView-content">
        {!isLoading && error && (!data || data.length <= 0) ? (
          <div className="error-list">
            <Alert
              type="danger"
              style={{ marginTop: 10 }}
              status={translation().commons.alerts.error}
              text={translation().core.list.error_list}
            />
          </div>
        ) : (
          false
        )}
        {isLoading && (
          <div className="loading-wrapper">
            <div className={classes.progressWrapper}>
              <AccountCircleTwoToneIcon
                fontSize="large"
                color="primary"
                className={classes.loaderIcon}
              />
              <div className={classes.linearProgress}>
                <LinearProgress color="secondary" variant="query" />
              </div>
              <p className="loader-wpr">{translation().views.user_view.loading_user}</p>
            </div>
          </div>
        )}
        {!isLoading && data && (
          <>
            <Tabs
              value={tab}
              className={classes.tabsRoot}
              onChange={handleChangeTab}
              variant="scrollable"
              indicatorColor="secondary"
              textColor="secondary"
              aria-label="lab tabs">
              {tabs.map((tab) => (
                <Tab key={tab.value} label={tab.label} />
              ))}
            </Tabs>
            <div>
              {tab === 0 && (
                <UserSummary
                  user={data}
                  devices={devices}
                  getUserViewDevices={getUserViewDevices}
                  cleanUserViewDevicesReducer={cleanUserViewDevicesReducer}
                  updateUserLevel={updateUserLevel}
                  onRefresh={handleRefresh}
                />
              )}
              {tab === 1 && (
                <UserOrders
                  userId={data.id}
                  getUserViewOrders={getUserViewOrders}
                  getOrdersMetadata={getOrdersMetadata}
                  cleanUserViewOrdersReducer={cleanUserViewOrdersReducer}
                  orders={orders}
                />
              )}
              {tab === 2 && (
                <UserInvoices
                  addresses={addresses}
                  cleanUserViewAddressesReducer={cleanUserViewAddressesReducer}
                  cleanUserViewInvoicesEmittedReducer={cleanUserViewInvoicesEmittedReducer}
                  cleanUserViewInvoicesReducer={cleanUserViewInvoicesReducer}
                  cleanUserViewPaymentMethodReducer={cleanUserViewPaymentMethodReducer}
                  getUserViewAddresses={getUserViewAddresses}
                  getUserViewInvoices={getUserViewInvoices}
                  getUserViewInvoicesEmitted={getUserViewInvoicesEmitted}
                  getUserViewPaymentMethods={getUserViewPaymentMethods}
                  getInvoicerId={getInvoicerId}
                  getPayerId={getPayerId}
                  invoices={invoices}
                  invoicesEmitted={invoices_emitted}
                  paymentMethods={payment_methods}
                  user={data}
                />
              )}
              {tab === 3 && <UserMetrics />}
              {/* {
                tab === 4 &&
                <UserRatings
                  cleanUserViewRatingsReducer={cleanUserViewRatingsReducer}
                  getUserViewRatings={getUserViewRatings}
                  ratings={ratings}
                  userId={data.id}
                />
              } */}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

UserView.propTypes = {
  cleanUserViewAddressesReducer: PropTypes.func.isRequired,
  cleanUserViewDevicesReducer: PropTypes.func.isRequired,
  cleanUserViewInvoicesEmittedReducer: PropTypes.func.isRequired,
  cleanUserViewInvoicesReducer: PropTypes.func.isRequired,
  cleanUserViewOrdersReducer: PropTypes.func.isRequired,
  cleanUserViewPaymentMethodReducer: PropTypes.func.isRequired,
  // cleanUserViewRatingsReducer: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  getUserViewAddresses: PropTypes.func.isRequired,
  getUserViewDevices: PropTypes.func.isRequired,
  getUserViewInvoices: PropTypes.func.isRequired,
  getUserViewInvoicesEmitted: PropTypes.func.isRequired,
  getUserViewPaymentMethods: PropTypes.func.isRequired,
  getUserViewOrders: PropTypes.func.isRequired,
  getOrdersMetadata: PropTypes.func.isRequired,
  // getUserViewRatings: PropTypes.func.isRequired,
  getInvoicerId: PropTypes.func.isRequired,
  getPayerId: PropTypes.func.isRequired,
  history: PropTypes.shape().isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  }).isRequired,
  user: PropTypes.shape({
    data: PropTypes.shape(),
    addresses: PropTypes.shape(),
    invoices: PropTypes.shape(),
    invoices_emitted: PropTypes.shape(),
    payment_methods: PropTypes.shape(),
    devices: PropTypes.shape(),
    orders: PropTypes.shape(),
    error: PropTypes.string,
    isLoading: PropTypes.bool
  }).isRequired,
  updateUserLevel: PropTypes.func.isRequired,
  sub_routes: PropTypes.shape()
};

export default UserView;
