/* eslint-disable react/jsx-no-bind */
import './profile.scss';

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { clone } from 'lodash';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import EditIcon from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';
import SaveIcon from '@material-ui/icons/Save';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { makeStyles } from '@material-ui/core/styles';

import FormFileField from '../../components/forms/fields/FormFileField';
import Infos from './components/Infos';
import Settings from './components/Settings';
import Password from './components/Password';
import { dateInTz } from '../../utils/functions/dates';
import { setLocale } from '../../utils/functions/cookies';
import translation from '../../translation/translation';
import config from '../../config';

const useStyles = makeStyles((theme) => ({
  picPaper: {
    marginBottom: theme.spacing(3)
  },
  loadingText: {
    display: 'inline-block',
    verticalAlign: 'top',
    margin: '2px 10px'
  },
  pageTitle: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  tab: {
    display: 'none',
    '&.open': {
      display: 'block'
    }
  },
  saveIcon: {
    marginRight: 4
  },
  tabPanel: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  }
}));

function Profile(props) {
  const { history, profile, updateUser, updateUserPassword, user, setProfileErrors } = props;

  const tabArray = ['infos', 'settings', 'password'];

  const classes = useStyles();
  const [isPicOpen, setPicOpen] = useState(false);
  const [tab, setTab] = useState(0);
  const [values, setValues] = useState({});
  const defaultValues = {
    first_name: user.data && user.data.first_name ? user.data.first_name : null,
    last_name: user.data && user.data.last_name ? user.data.last_name : null,
    email: user.data && user.data.email ? user.data.email : null,
    gender: user.data && user.data.gender ? user.data.gender : null,
    date_of_birth: user.data && user.data.date_of_birth ? user.data.date_of_birth : null,
    timezone_code: user.data && user.data.timezone_code ? user.data.timezone_code : null,
    language_locale:
      user.data && user.data.language && user.data.language.locale ? user.data.language : null,
    currency_isocode:
      user.data && user.data.currency && user.data.currency.isocode ? user.data.currency : null,
    citizen_country_isocode:
      user.data && user.data.citizen_country ? user.data.citizen_country : null,
    residence_country_isocode:
      user.data && user.data.residence_country ? user.data.residence_country : null,
    mobile: user.data && user.data.mobile ? user.data.mobile : null,
    phone: user.data && user.data.phone ? user.data.phone : null,
    picture_url: user.data && user.data.picture_url ? user.data.picture_url : null,
    unit_system: user.data && user.data.unit_system ? user.data.unit_system : null
  };

  useEffect(() => {
    document.title = 'Actarus | Profile';

    if (history && history.location && history.location.hash) {
      if (history.location.hash === '#infos') setTab(0);
      else if (history.location.hash === '#settings') setTab(1);
      else if (history.location.hash === '#password') setTab(2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleTogglePic() {
    setPicOpen(!isPicOpen);
  }

  function handleChangeTab(e, newTab) {
    setTab(newTab);
    window.location.hash = tabArray[newTab];
    setProfileErrors({ ...profile.errors, confirm_new_password: null });
  }

  function handleSumbit(event) {
    if (event) {
      event.preventDefault();
    }

    const payload = clone(values);

    if (values.language_locale && values.language_locale.locale) {
      payload.language_locale = values.language_locale.locale;
      setLocale(values.language_locale.locale);
    }

    if (values.currency_isocode && values.currency_isocode.isocode)
      payload.currency_isocode = values.currency_isocode.isocode;

    updateUser(payload);
  }

  function handleSumbitPassword(event) {
    if (event) {
      event.preventDefault();
    }

    const payload = {};

    if (values.old_password && values.new_password && values.confirm_new_password) {
      payload.old_password = values.old_password;
      payload.new_password = values.new_password;

      if (values.new_password === values.confirm_new_password) {
        updateUserPassword(payload, () => {
          handleChangeTab(null, 0);
        });
      } else {
        setProfileErrors({
          ...profile.errors,
          confirm_new_password: translation().views.profile.form.password_not_matching
        });
      }
    }
  }

  const handleChange = (name) => (value) => {
    setValues({ ...values, [name]: value });
  };

  const handleChangeAutocomplete = (name, targetKey) => (value) => {
    setValues({
      ...values,
      [name]: value && value[targetKey] ? values[targetKey] : value || null
    });
  };

  const pictureUrl = values.picture_url
    ? values.picture_url + '?client_id=' + config.api_key
    : defaultValues.picture_url
    ? defaultValues.picture_url + '?client_id=' + config.api_key
    : `assets/images/commons/account-avatar-${
        defaultValues.gender && defaultValues.gender === 'M' ? 'male' : 'female'
      }.svg`;

  return (
    <div className="Profile">
      <Container>
        <div className={classes.pageTitle}>
          <Typography gutterBottom component="h2" variant="h2">
            {translation().views.profile.page_title}
          </Typography>
          <Typography gutterBottom component="p" variant="body1">
            {translation().views.profile.page_baseline}
          </Typography>
        </div>
        <Tabs
          value={tab}
          onChange={handleChangeTab}
          variant="scrollable"
          scrollButtons="auto"
          indicatorColor="secondary"
          textColor="secondary"
          aria-label="profile tabs">
          <Tab icon={<PersonIcon />} label={translation().views.profile.form.infos} />
          <Tab icon={<SettingsIcon />} label={translation().views.profile.form.settings} />
          <Tab icon={<VpnKeyIcon />} label={translation().views.profile.form.password} />
        </Tabs>
        <div className={classNames(classes.tab, { open: tab === 0 })}>
          <form onSubmit={handleSumbit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={4} lg={3}>
                <Paper className={classes.tabPanel} elevation={1}>
                  <div className="avatar-wrapper">
                    <img className="avatar" src={pictureUrl} alt="Profile avatar" />
                    <div className="avatar-overlay" onClick={handleTogglePic}>
                      {isPicOpen ? (
                        <CloseIcon className="edit-avatar" />
                      ) : (
                        <EditIcon className="edit-avatar" />
                      )}
                    </div>
                  </div>
                  {isPicOpen && (
                    <div className={classes.picPaper}>
                      <FormFileField
                        name="picture_url"
                        label="Picture url"
                        defaultValue={values.picture_url || defaultValues.picture_url}
                        type="image"
                        id="profile-picture_url"
                        onChange={handleChange('picture_url')}
                        error={
                          profile.errors && profile.errors['picture_url']
                            ? profile.errors['picture_url']
                            : null
                        }
                        isDisplayImg={false}
                        disabled={profile.isLoading}
                      />
                    </div>
                  )}
                  <div className="user-info">
                    {user.data && user.data.first_name && user.data.last_name && (
                      <Typography gutterBottom component="p" variant="body2">
                        {translation().views.profile.form.labels.last_name}: {user.data.first_name}{' '}
                        {user.data.last_name}
                      </Typography>
                    )}
                    {user.data && user.data.type && user.data.type.name && (
                      <Typography gutterBottom component="p" variant="body2">
                        {translation().views.profile.form.labels.type}: {user.data.type.name}
                      </Typography>
                    )}
                    {user.data && user.data.level && user.data.level.name && (
                      <Typography gutterBottom component="p" variant="body2">
                        {translation().views.profile.form.labels.level}: {user.data.level.name}
                      </Typography>
                    )}
                    {user.data && user.data.company && user.data.company.commercial_name && (
                      <Typography gutterBottom component="p" variant="body2">
                        {translation().views.profile.form.labels.company}:{' '}
                        {user.data.company.commercial_name}
                        {user.data.company_admin && (
                          <Typography component="span" variant="body2">
                            {' - '}(Admin)
                          </Typography>
                        )}
                      </Typography>
                    )}
                    {user.data && user.data.role && (
                      <Typography gutterBottom component="p" variant="body2">
                        {translation().views.profile.form.labels.role}: {user.data.role}
                      </Typography>
                    )}
                    {user.data && user.data.created && (
                      <Typography gutterBottom component="p" variant="body2">
                        {translation().views.profile.form.labels.created}:{' '}
                        {dateInTz(user.data.created, 'localized-datetime')}
                      </Typography>
                    )}
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={9}>
                <Paper className={classes.tabPanel} elevation={1}>
                  <Infos defaultValues={defaultValues} profile={profile} onChange={handleChange} />
                  <div className="submit-wrapper">
                    {profile.isLoading && (
                      <div className="loader-wpr">
                        <CircularProgress size={20} color="secondary" />
                        <p>{translation().views.profile.form.loading}</p>
                      </div>
                    )}
                    <Button
                      variant="outlined"
                      color="secondary"
                      type="submit"
                      disabled={profile.isLoading}>
                      <SaveIcon className={classes.saveIcon} />
                      {translation().actions.save}
                    </Button>
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </form>
        </div>
        <div className={classNames(classes.tab, { open: tab === 1 })}>
          <Paper className={classes.tabPanel} elevation={1}>
            <form onSubmit={handleSumbit}>
              <Settings
                defaultValues={defaultValues}
                profile={profile}
                onChange={handleChange}
                onChangeAutocomplete={handleChangeAutocomplete}
              />
              <div className="submit-wrapper">
                {profile.isLoading && (
                  <div className="loader-wpr">
                    <CircularProgress size={20} color="secondary" />
                    <p>{translation().views.profile.form.loading}</p>
                  </div>
                )}
                <Button
                  variant="outlined"
                  color="secondary"
                  type="submit"
                  disabled={profile.isLoading}>
                  <SaveIcon className={classes.saveIcon} />
                  {translation().actions.save}
                </Button>
              </div>
            </form>
          </Paper>
        </div>
        {tab === 2 && (
          <Paper className={classes.tabPanel} elevation={1}>
            <form onSubmit={handleSumbitPassword} autoComplete="off">
              <Password profile={profile} onChange={handleChange} />
              <div className="submit-wrapper">
                {profile.isLoading && (
                  <div className="loader-wpr">
                    <CircularProgress size={20} color="secondary" />
                    <p>{translation().views.profile.form.loading}</p>
                  </div>
                )}
                <Button
                  variant="outlined"
                  color="secondary"
                  type="submit"
                  disabled={profile.isLoading}>
                  <SaveIcon className={classes.saveIcon} />
                  {`${translation().actions.save} ${translation().views.profile.form.password}`}
                </Button>
              </div>
            </form>
          </Paper>
        )}
      </Container>
    </div>
  );
}

Profile.propTypes = {
  profile: PropTypes.shape({
    isLoading: PropTypes.bool,
    errors: PropTypes.shape()
  }).isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape()
  }),
  updateUser: PropTypes.func.isRequired,
  updateUserPassword: PropTypes.func.isRequired,
  user: PropTypes.shape({
    data: PropTypes.shape().isRequired
  }).isRequired,
  setProfileErrors: PropTypes.func.isRequired
};

export default Profile;
