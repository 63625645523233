import { connect } from 'react-redux';

import FormFactory from '../../components/forms/factory/FormFactory';
import {
  cleanFormsErrors,
  deleteForm,
  initializeForm,
  setFormsErrors
} from '../../redux/actions/forms/formsActions';

const mapStateToProps = (store) => ({ forms: store.forms });

const mapDispatchToProps = (dispatch) => {
  return {
    deleteForm(name) {
      dispatch(deleteForm(name));
    },
    initializeForm(name, fields) {
      dispatch(initializeForm(name, fields));
    },
    setFormsErrors(name, errors) {
      dispatch(setFormsErrors(name, errors));
    },
    cleanFormsErrors(name) {
      dispatch(cleanFormsErrors(name));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormFactory);
