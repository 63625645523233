import React, { useEffect, PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Alert from '../../../../../components/alert/Alert';
import { dateInTz } from '../../../../../utils/functions/dates';
import translation from '../../../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2)
  },
  tableContainer: {
    maxHeight: 350
  },
  loadingWrapper: {
    width: '100%',
    height: 350,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      height: 'auto'
    }
  }
}));

class CustomHref extends PureComponent {
  render() {
    return <a {...this.props}>{this.props.children}</a>;
  }
}

CustomHref.propTypes = {
  children: PropTypes.any
};

function AddressRow(props) {
  const { address } = props;

  const addressValue =
    (address.street_number ? address.street_number : '') +
    ' ' +
    (address.street ? address.street : '') +
    ' ' +
    (address.city ? address.city : '') +
    ' ' +
    (address.zip_code ? address.zip_code : '');

  return (
    <TableRow>
      <TableCell>{address.id}</TableCell>
      <TableCell>{address.name ? address.name : '-'}</TableCell>
      <TableCell>{addressValue}</TableCell>
      <TableCell>
        {address.created ? dateInTz(address.created, 'localized-datetime') : '-'}
      </TableCell>
    </TableRow>
  );
}

AddressRow.propTypes = {
  address: PropTypes.shape().isRequired
};

function Addresses(props) {
  const classes = useStyles();

  const columns = ['id', 'name', 'address', 'created'];

  const { addresses, cleanUserViewAddressesReducer, getUserViewAddresses, userId } = props;

  useEffect(() => {
    loadAddresses(userId);

    return () => {
      cleanUserViewAddressesReducer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function loadAddresses(userId, next = null) {
    getUserViewAddresses(userId, next);
  }

  function handleInfiniteScroll(event) {
    if (!event) console.error('No table scroll event found.');

    if (!event.currentTarget.scrollTop || event.currentTarget.scrollTop === 0) return;

    if (
      event.currentTarget.scrollHeight - event.currentTarget.clientHeight <=
      event.currentTarget.scrollTop
    ) {
      if (addresses && addresses.paging && addresses.paging.next) {
        getUserViewAddresses(userId, addresses.paging.next);
      }
    }
  }

  return (
    <div className={classNames(classes.root, 'UserAddresses')}>
      {!addresses.isLoading &&
      addresses.error &&
      (!addresses.data || addresses.data.length <= 0) ? (
        <div className="error-list">
          <Alert
            type="danger"
            style={{ marginTop: 10 }}
            status={translation().commons.alerts.error}
            text={translation().core.list.error_list}
          />
        </div>
      ) : (
        false
      )}
      {addresses.data && addresses.data.length > 0 ? (
        <TableContainer onScroll={handleInfiniteScroll} className={classes.tableContainer}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column}>{column}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {addresses.data.map((address, index) => (
                <AddressRow address={address} key={index} userId={userId} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : !addresses.isLoading && !addresses.error ? (
        <Alert
          type="info"
          style={{ marginTop: 10 }}
          status={translation().commons.alerts.info}
          text={translation().views.user_view.addresses.empty}
        />
      ) : (
        false
      )}
      {addresses.isLoading && !addresses.paging && (
        <div className={classNames(classes.loadingWrapper, 'loader-wpr')}>
          <div style={{ width: '100%' }}>
            <CircularProgress color="primary" />
            <p>{translation().views.user_view.addresses.loading_text}</p>
          </div>
        </div>
      )}
      {addresses.isLoading && addresses.paging && addresses.paging.next ? (
        <>
          <LinearProgress color="primary" />
          <div className="loader-wpr linear">
            <p>{translation().core.list.load_next}</p>
          </div>
        </>
      ) : (
        <div className="end-of-list-wpr">
          {!addresses.isLoading &&
            addresses.paging &&
            !addresses.paging.next &&
            addresses.paging.prev && <p>{translation().core.list.load_next_done}</p>}
        </div>
      )}
    </div>
  );
}

Addresses.propTypes = {
  cleanUserViewAddressesReducer: PropTypes.func.isRequired,
  getUserViewAddresses: PropTypes.func.isRequired,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  addresses: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape()),
    error: PropTypes.string,
    isLoading: PropTypes.bool,
    paging: PropTypes.shape({
      next: PropTypes.string,
      prev: PropTypes.string
    })
  }).isRequired
};

export default Addresses;
