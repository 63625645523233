import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import FixedHeaderTable from '../../../components/tables/fixedHeaderTable/FixedHeaderTable';
import translation from '../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    height: 450
  },
  root: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      minWidth: 500
    }
  }
}));

function CheckboxPermission(props) {
  const { companyId, getPermission, handlePermission, userId, paymentMethodId } = props;
  const [checked, setChecked] = useState(false);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (companyId || userId || paymentMethodId) {
      setLoading(true);

      getPermission(
        companyId,
        paymentMethodId,
        userId,
        () => {
          setChecked(true);
          setLoading(false);
        },
        () => {
          setChecked(false);
          setLoading(false);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleChange(event) {
    setChecked(event.target.checked);

    if (companyId || userId || paymentMethodId) {
      setLoading(true);

      handlePermission(
        companyId,
        paymentMethodId,
        userId,
        event.target.checked,
        () => {
          setChecked(!checked);
          setLoading(false);
        },
        () => {
          setChecked(checked);
          setLoading(false);
        }
      );
    }
  }

  return (
    <>
      {isLoading ? (
        <div style={{ padding: 8 }}>
          <CircularProgress size={20} />
        </div>
      ) : (
        <Checkbox checked={checked} onChange={handleChange} value="primary" />
      )}
    </>
  );
}

CheckboxPermission.propTypes = {
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  getPermission: PropTypes.func.isRequired,
  handlePermission: PropTypes.func.isRequired,
  userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  paymentMethodId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

function AdminizePaymentMethod(props) {
  const {
    columns,
    getCompanyPaymentMethodUserPermission,
    getCompanyUsers,
    handleCompanyPaymentMethodUserPermission,
    resourceId,
    resource
  } = props;
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [paging, setPaging] = useState(null);

  useEffect(() => {
    if (resourceId) {
      setLoading(true);

      getCompanyUsers(
        resourceId,
        paging && paging.next ? paging.next : null,
        ({ successData, paging }) => {
          setLoading(false);
          setData(successData);

          if (paging) {
            setPaging(paging);
          }
        },
        () => {
          setLoading(false);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleScrollList() {
    if (!isLoading && paging && paging.next) {
      setLoading(true);

      getCompanyUsers(
        resourceId,
        paging.next,
        ({ successData, paging }) => {
          const dataConcatenated = data.concat(successData);
          setLoading(false);
          setData(dataConcatenated);

          if (paging) {
            setPaging(paging);
          }
        },
        () => {
          setLoading(false);
        }
      );
    }
  }

  function getTableBodyFromData(data, columns = []) {
    const table_body = [];
    let temp_body = null;

    for (let line in data) {
      if (Array.isArray(columns)) {
        temp_body = [];

        for (let i = 0; i < columns.length; i++) {
          for (let key in data[line]) {
            if (data[line][columns[i].name]) {
              if (key === columns[i].name) {
                if (key === 'user') {
                  let name = '';

                  if (data[line][key] && data[line][key].first_name && data[line][key].last_name) {
                    name = data[line][key].first_name + ' ' + data[line][key].last_name;
                  } else if (data[line][key] && data[line][key].last_name) {
                    name = '[Missing first name] ' + data[line][key].last_name;
                  } else {
                    name = '[Missing user object]';
                  }

                  if (data[line]['company']) {
                    name += ' (' + data[line]['company'].commercial_name + ')';
                  }

                  temp_body[key] = name;
                } else {
                  temp_body[columns[i].name] = data[line][key];
                }
              }
            } else {
              temp_body[columns[i].name] = '-';
            }
          }
        }

        table_body.push(temp_body);
      }
    }

    return table_body;
  }

  function renderTable(data) {
    if (data && data.length) {
      const tableBody = getTableBodyFromData(data, columns);
      const tableValues = tableBody.map((row, index) => {
        let result = [];

        Object.keys(row).forEach((key) => {
          if (row[key]) {
            result.push(
              <TableCell key={key}>
                <Typography variant="body2">{row[key]}</Typography>
              </TableCell>
            );
          }
        });

        return (
          <TableRow key={index}>
            {result}
            <TableCell style={{ textAlign: 'right' }}>
              <CheckboxPermission
                companyId={resourceId}
                getPermission={getCompanyPaymentMethodUserPermission}
                handlePermission={handleCompanyPaymentMethodUserPermission}
                userId={row.id}
                paymentMethodId={resource && resource.id ? resource.id : null}
              />
            </TableCell>
          </TableRow>
        );
      });

      return (
        <FixedHeaderTable
          tableHeader={[
            <TableRow key="autho_pm_header">
              <TableCell>Id</TableCell>
              <TableCell>
                {
                  translation().core.list.dialogs.custom_list_actions.adminize_payment_method
                    .table_labels.last_name
                }
              </TableCell>
              <TableCell>
                {
                  translation().core.list.dialogs.custom_list_actions.adminize_payment_method
                    .table_labels.first_name
                }
              </TableCell>
              <TableCell style={{ textAlign: 'right' }}>
                {
                  translation().core.list.dialogs.custom_list_actions.adminize_payment_method
                    .table_labels.authorized
                }
              </TableCell>
            </TableRow>
          ]}
          tableValues={tableValues}
          metadata="users"
          isActions={false}
          onScrollList={handleScrollList}
          isLoading={isLoading}
          style={{ height: '100%' }}
        />
      );
    }
  }

  return (
    <div className={`AdminizePaymentMethod ${classes.root}`}>
      {data && data.length > 0 ? (
        <div className={classes.tableContainer}>{renderTable(data)}</div>
      ) : (
        false
      )}
      {isLoading && (
        <div className="loader-wpr">
          <CircularProgress color="primary" size={25} />
          <p>
            {
              translation().core.list.dialogs.custom_list_actions.adminize_payment_method
                .loading_text
            }
          </p>
        </div>
      )}
    </div>
  );
}

AdminizePaymentMethod.defaultProps = {
  columns: [{ name: 'id' }, { name: 'last_name' }, { name: 'first_name' }]
};

AdminizePaymentMethod.propTypes = {
  columns: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
  getCompanyPaymentMethodUserPermission: PropTypes.func.isRequired,
  getCompanyUsers: PropTypes.func.isRequired,
  handleCompanyPaymentMethodUserPermission: PropTypes.func.isRequired,
  resource: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }),
  resourceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};

export default AdminizePaymentMethod;
