import React from 'react';

function ListEmptyIcon(props) {
  return (
    <svg
      version="1.1"
      id="ListEmptyIcon"
      width="100"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 468.293 468.293"
      style={{ enableBackground: 'new 0 0 468.293 468.293' }}
      {...props}>
      <polygon
        fill="#7162ff"
        points="219.673,433.224 219.673,184.921 37.006,117.745 37.006,357.509 "
      />
      <polygon
        fill="#c1d5e0"
        points="227.62,35.068 428.559,108.859 219.673,184.921 42.595,108.859 "
      />
      <polygon
        fill="#8477fa"
        points="219.673,433.224 219.673,184.921 428.559,117.745 428.559,357.509 "
      />
      <g>
        <polygon
          fill="#4E4BC6"
          points="428.559,108.859 219.673,184.921 263.948,251.901 468.293,170.163 	"
        />
        <polygon
          fill="#4E4BC6"
          points="0,173.082 42.595,108.859 219.673,184.921 183.998,248.025 	"
        />
      </g>
    </svg>
  );
}

export default ListEmptyIcon;
