const initialState = {
  data: null,
  error: null,
  isLoading: false,
  hosting_plans: {
    data: [],
    error: null,
    isLoading: false
  },
  options: {
    data: [],
    error: null,
    isLoading: false
  },
  media_types: {
    data: [],
    error: null,
    isLoading: false
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_REDUCERS':
      return initialState;
    case 'CLEAN_PRODUCT_VIEW_REDUCER':
      return initialState;
    case 'CLEAN_PRODUCT_VIEW_OPTIONS_REDUCER':
      return {
        ...state,
        options: initialState.options
      };
    case 'CLEAN_PRODUCT_VIEW_MEDIA_TYPES_REDUCER':
      return {
        ...state,
        media_types: initialState.media_types
      };
    case 'CLEAN_PRODUCT_VIEW_HOSTING_PLANS_REDUCER':
      return {
        ...state,
        hosting_plans: initialState.hosting_plans
      };
    case 'GET_PRODUCT_VIEW':
      return {
        ...initialState,
        isLoading: true
      };
    case 'GET_PRODUCT_VIEW_SUCCESS':
      return {
        ...state,
        data: action.data,
        error: null,
        isLoading: false
      };
    case 'GET_PRODUCT_VIEW_ERROR':
      return {
        ...state,
        error: action.error,
        isLoading: false
      };
    case 'GET_PRODUCT_VIEW_OPTIONS':
      return {
        ...state,
        options: {
          ...state.options,
          data: action.next ? state.options.data : initialState.options.data,
          isLoading: true
        }
      };
    case 'GET_PRODUCT_VIEW_OPTIONS_SUCCESS': {
      const optionsConcatenated = state.options.data.concat(action.data);

      return {
        ...state,
        options: {
          ...state.options,
          data: optionsConcatenated,
          paging: action.paging ? action.paging : state.options.paging,
          isLoading: false,
          error: null
        }
      };
    }
    case 'GET_PRODUCT_VIEW_OPTIONS_ERROR':
      return {
        ...state,
        options: {
          ...state.options,
          error: action.error,
          isLoading: false
        }
      };
    case 'GET_PRODUCT_VIEW_MEDIA_TYPES':
      return {
        ...state,
        media_types: {
          ...state.media_types,
          data: action.next ? state.media_types.data : initialState.media_types.data,
          isLoading: true
        }
      };
    case 'GET_PRODUCT_VIEW_MEDIA_TYPES_SUCCESS': {
      const mediaTypesConcatenated = state.media_types.data.concat(action.data);

      return {
        ...state,
        media_types: {
          ...state.media_types,
          data: mediaTypesConcatenated,
          paging: action.paging ? action.paging : state.media_types.paging,
          isLoading: false,
          error: null
        }
      };
    }
    case 'GET_PRODUCT_VIEW_MEDIA_TYPES_ERROR':
      return {
        ...state,
        media_types: {
          ...state.media_types,
          error: action.error,
          isLoading: false
        }
      };
    case 'GET_PRODUCT_VIEW_HOSTING_PLANS':
      return {
        ...state,
        hosting_plans: {
          ...state.hosting_plans,
          data: action.next ? state.hosting_plans.data : initialState.hosting_plans.data,
          isLoading: true
        }
      };
    case 'GET_PRODUCT_VIEW_HOSTING_PLANS_SUCCESS': {
      const hostingPlansConcatenated = state.hosting_plans.data.concat(action.data);

      return {
        ...state,
        hosting_plans: {
          ...state.hosting_plans,
          data: hostingPlansConcatenated,
          paging: action.paging ? action.paging : state.hosting_plans.paging,
          isLoading: false,
          error: null
        }
      };
    }
    case 'GET_PRODUCT_VIEW_HOSTING_PLANS_ERROR':
      return {
        ...state,
        hosting_plans: {
          ...state.hosting_plans,
          error: action.error,
          isLoading: false
        }
      };
    default:
      return state;
  }
};
