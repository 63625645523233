import React from 'react';

function PlanningUnavailableIcon(props) {
  return (
    <svg
      id="PlanningUnavailableIcon"
      x="0px"
      y="0px"
      width="100"
      height="100"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      style={{ enableBackground: 'new 0 0 512 512' }}
      {...props}>
      <g>
        <path d="m471.843 120h-215.843-195l-61 31v361h256 135l121-90v-271z" fill="#f0f7ff" />
        <path d="m512 422v-271l-40.157-31h-215.843v392h135z" fill="#dfe7f4" />
        <path d="m256 30h-256v121h256 256v-121z" fill="#ff8066" />
        <path d="m256 30h256v121h-256z" fill="#fd435b" />
        <path d="m512 422v21.299l-69.699 68.701h-51.301v-90z" fill="#c7cfe1" />
        <g>
          <path d="m301 0h30v90h-30z" fill="#463f3f" />
        </g>
        <g>
          <path d="m421 0h30v90h-30z" fill="#463f3f" />
        </g>
        <path
          d="m256 212c-57.891 0-105 47.109-105 105s47.109 105 105 105 105-47.109 105-105-47.109-105-105-105zm0 180c-41.353 0-75-33.647-75-75 0-15.26 4.634-29.436 12.495-41.294l62.505 62.505 41.294 41.294c-11.858 7.861-26.034 12.495-41.294 12.495zm62.505-33.706-62.505-62.505-41.294-41.294c11.858-7.861 26.034-12.495 41.294-12.495 41.353 0 75 33.647 75 75 0 15.26-4.634 29.436-12.495 41.294z"
          fill="#ff8066"
        />
        <path
          d="m361 317c0-57.891-47.109-105-105-105v30c41.353 0 75 33.647 75 75 0 15.26-4.634 29.436-12.495 41.294l-62.505-62.505v42.422l41.294 41.294c-11.858 7.861-26.034 12.495-41.294 12.495v30c57.891 0 105-47.109 105-105z"
          fill="#fd435b"
        />
        <g>
          <path d="m61 0h30v90h-30z" fill="#5b5555" />
        </g>
        <g>
          <path d="m181 0h30v90h-30z" fill="#5b5555" />
        </g>
      </g>
    </svg>
  );
}

export default PlanningUnavailableIcon;
