/* eslint-disable react/jsx-max-props-per-line */
import React from 'react';

function MissingImageicon(props) {
  return (
    <svg
      id="MissingImageicon"
      xmlns="http://www.w3.org/2000/svg"
      width={100}
      viewBox="0 0 729.47 695.09"
      {...props}>
      <defs>
        <linearGradient
          id="fc2ce546-a06c-4acb-8cca-fc7989cc5e45"
          x1="611.98"
          y1="687.2"
          x2="611.98"
          y2="258.73"
          gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="gray" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="gray" stopOpacity="0.12" />
          <stop offset="1" stopColor="gray" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="8de405ef-36e0-4554-af41-d0565e95cbca"
          x1="410.91"
          y1="358.56"
          x2="452.61"
          y2="86.08"
          gradientTransform="matrix(1.01, 0.13, -0.13, 1.02, 62.29, -41.05)"
        />
        <linearGradient
          id="90a47f5c-11a4-432e-bbe5-e819485e2974"
          x1="597.01"
          y1="453.03"
          x2="597.01"
          y2="138.64"
          gradientTransform="translate(140.76 -189.73) rotate(20.42)"
        />
        <linearGradient
          id="c3d8783b-6f33-4c65-b7a9-7a0b4c25dfb1"
          x1="756.62"
          y1="488.3"
          x2="772.25"
          y2="249.01"
          gradientTransform="matrix(0.26, 0.99, -1.02, 0.25, 910.43, -476.82)"
        />
        <linearGradient
          id="1441cd83-913d-413c-98d4-824021df009a"
          x1="310"
          y1="695.09"
          x2="310"
          y2="203.86"
        />
        <linearGradient
          id="4913d3bc-5f66-46f4-9c65-3645c89ed5d3"
          x1="545.79"
          y1="695.81"
          x2="545.79"
          y2="464.64"
          gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#b3b3b3" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#b3b3b3" stopOpacity="0.1" />
          <stop offset="1" stopColor="#b3b3b3" stopOpacity="0.05" />
        </linearGradient>
        <linearGradient
          id="0cf8a40c-444c-472e-8722-d672ac4b1674"
          x1="310.8"
          y1="580.62"
          x2="310.8"
          y2="375.85"
        />
        <clipPath id="f4edd298-8257-4895-a91b-ae15b3b0d94f" transform="translate(-235.26 -102.45)">
          <rect
            x="400.07"
            y="483.3"
            width="291.69"
            height="194.01"
            rx="8.85"
            ry="8.85"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <title>folder</title>
      <polygon
        points="618.97 687.2 513.53 687.2 513.53 258.73 710.42 258.73 618.97 687.2"
        fill="url(#fc2ce546-a06c-4acb-8cca-fc7989cc5e45)"
      />
      <polygon
        points="613.72 687.2 510.37 687.2 510.37 258.73 703.35 258.73 613.72 687.2"
        fill="#f5f5f5"
      />
      <rect
        x="249.88"
        y="158.45"
        width="439.19"
        height="168.85"
        transform="translate(-283.32 32.9) rotate(-15.62)"
        fill="url(#8de405ef-36e0-4554-af41-d0565e95cbca)"
      />
      <rect
        x="254.32"
        y="160.66"
        width="431.85"
        height="163.44"
        transform="translate(-283.15 33.09) rotate(-15.62)"
        fill="#f4f4f4"
      />
      <rect
        x="367.9"
        y="230.63"
        width="108.65"
        height="8.29"
        transform="translate(-282.88 19.88) rotate(-15.62)"
        fill="#f5f5f5"
      />
      <rect
        x="376.82"
        y="262.55"
        width="108.65"
        height="8.29"
        transform="translate(-291.14 23.46) rotate(-15.62)"
        fill="#fff"
      />
      <rect
        x="371.14"
        y="237.71"
        width="174.6"
        height="8.29"
        transform="translate(-283.45 29.89) rotate(-15.62)"
        fill="#fff"
      />
      <circle
        cx="329.58"
        cy="277.88"
        r="19.34"
        transform="translate(-297.9 -3.47) rotate(-15.62)"
        fill="#fff"
      />
      <rect
        x="376.38"
        y="210.25"
        width="441.26"
        height="171.17"
        transform="translate(-300.97 124.47) rotate(-20.42)"
        fill="url(#90a47f5c-11a4-432e-bbe5-e819485e2974)"
      />
      <rect
        x="381.03"
        y="214.81"
        width="431.85"
        height="163.44"
        transform="translate(-301.21 124.5) rotate(-20.42)"
        fill="#f7f7f7"
      />
      <rect
        x="494.14"
        y="288.83"
        width="108.65"
        height="8.29"
        transform="translate(-303.02 107.35) rotate(-20.42)"
        fill="#f5f5f5"
      />
      <rect
        x="505.71"
        y="319.89"
        width="108.65"
        height="8.29"
        transform="translate(-313.13 113.34) rotate(-20.42)"
        fill="#fff"
      />
      <rect
        x="497.85"
        y="292.85"
        width="174.6"
        height="8.29"
        transform="matrix(0.94, -0.35, 0.35, 0.94, -302.12, 120.41)"
        fill="#fff"
      />
      <circle
        cx="459.76"
        cy="343.69"
        r="19.34"
        transform="translate(-326.29 79.59) rotate(-20.42)"
        fill="#fff"
      />
      <rect
        x="646.89"
        y="150.88"
        width="172.17"
        height="440.14"
        transform="translate(3.97 925.06) rotate(-79.9)"
        fill="url(#c3d8783b-6f33-4c65-b7a9-7a0b4c25dfb1)"
      />
      <rect
        x="515.81"
        y="288.22"
        width="431.85"
        height="163.44"
        transform="translate(-159.05 -225.04) rotate(10.1)"
        fill="#fafafa"
      />
      <rect
        x="687.63"
        y="287.92"
        width="8.29"
        height="108.65"
        transform="translate(-1.75 860.83) rotate(-79.9)"
        fill="#f5f5f5"
      />
      <rect
        x="681.81"
        y="320.56"
        width="8.29"
        height="108.65"
        transform="translate(-38.67 882.02) rotate(-79.9)"
        fill="#fff"
      />
      <rect
        x="717.18"
        y="277.05"
        width="8.29"
        height="174.6"
        transform="translate(0.86 908.15) rotate(-79.9)"
        fill="#fff"
      />
      <circle
        cx="589.6"
        cy="340.88"
        r="19.34"
        transform="translate(-84.66 759.11) rotate(-79.9)"
        fill="#fff"
      />
      <polygon
        points="171.78 253.69 100.61 203.86 2.07 203.86 2.07 253.69 2.07 278.1 2.07 695.09 617.93 695.09 617.93 253.69 171.78 253.69"
        fill="url(#1441cd83-913d-413c-98d4-824021df009a)"
      />
      <polygon
        points="174.44 258.73 104.36 210.36 7.34 210.36 7.34 258.73 7.34 282.43 7.34 687.2 613.72 687.2 613.72 258.73 174.44 258.73"
        fill="#fff"
      />
      <path
        d="M711.54,688.53a7.25,7.25,0,0,1-7.21,7.28H387.26a7.25,7.25,0,0,1-7.21-7.28V471.93a7.25,7.25,0,0,1,7.21-7.28H704.33a7.25,7.25,0,0,1,7.21,7.28"
        transform="translate(-235.26 -102.45)"
        fill="url(#4913d3bc-5f66-46f4-9c65-3645c89ed5d3)"
      />
      <path
        d="M707.81,685a7,7,0,0,1-7,7H391.05a7,7,0,0,1-7-7V475.62a7,7,0,0,1,7-7H700.77a7,7,0,0,1,7,7"
        transform="translate(-235.26 -102.45)"
        fill="#fff"
      />
      <g id="114cebd5-d8fe-4021-8e49-fe55d7dac6be">
        <rect
          x="161.12"
          y="375.85"
          width="299.37"
          height="204.76"
          rx="8.85"
          ry="8.85"
          fill="url(#0cf8a40c-444c-472e-8722-d672ac4b1674)"
        />
      </g>
      <rect x="164.8" y="380.84" width="291.69" height="194.01" rx="8.85" ry="8.85" fill="#fff" />
      <g clipPath="url(#f4edd298-8257-4895-a91b-ae15b3b0d94f)">
        <path
          d="M383.84,675.53l81.44-93.31a16.21,16.21,0,0,1,22.94-1.5L511,600.84a16.21,16.21,0,0,0,21.16.25l69.7-58.58A16.21,16.21,0,0,1,624.3,544l85.53,94.14a16.21,16.21,0,0,1,4.15,9.47l3.3,37.13a16.21,16.21,0,0,1-16.15,17.65H396.06a16.21,16.21,0,0,1-16.2-15.72h0A16.21,16.21,0,0,1,383.84,675.53Z"
          transform="translate(-235.26 -102.45)"
          fill="#6c63ff"
        />
      </g>
      <circle cx="199.93" cy="411.95" r="18.06" fill="#ff5252" />
    </svg>
  );
}

export default MissingImageicon;
