import './providersMap.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withGoogleMap, GoogleMap, InfoWindow, Circle } from 'react-google-maps';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import ProvidersMapViewBar from './components/ProvidersMapViewBar';
import FormApiAutocompleteField from '../../../components/forms/fields/FormApiAutocompleteField';
import FormDateTimeField from '../../../components/forms/fields/FormDateTimeField';
import FormSelectField from '../../../components/forms/fields/FormSelectField';
import silverStyle from './mapStyles/mapSilverStyle';
import { createDate } from '../../../utils/functions/dates';
import translation from '../../../translation/translation';
import config from '../../../config';

function CircleMarker(props) {
  const { marker } = props;
  const [open, setOpen] = useState(false);
  const [visible, setVisible] = useState(true);

  function handleHide() {
    setVisible(false);
    setOpen(false);
  }

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <span>
      <Circle
        visible={visible}
        clickable
        draggable={false}
        editable={false}
        center={{
          lat: marker.base_latitude,
          lng: marker.base_longitude
        }}
        radius={marker.radius * 1000}
        onClick={handleOpen}
        options={{
          fillColor: marker.ghost ? '#ff9800' : '#4CAF50',
          fillOpacity: marker.ghost ? 0.3 : 0.5,
          strokeColor: 'transparent',
          zIndex: marker.id
        }}
      />
      {open && (
        <InfoWindow
          onCloseClick={handleClose}
          position={{
            lat: marker.base_latitude,
            lng: marker.base_longitude
          }}>
          <div style={{ width: '100%' }}>
            <IconButton size="small" onClick={handleClose} className="close-infobox">
              <CloseIcon fontSize="small" />
            </IconButton>
            <Link target="_blank" href={`/providers/${marker.id}/update`}>
              <Typography variant="subtitle2" component="span">
                {translation().views.providers_map.infobox.provider_id}{' '}
                <Typography variant="body2" component="span">
                  {marker.id}
                </Typography>
              </Typography>
            </Link>
            <div className="xxs-space" />
            <Typography variant="subtitle2" component="p">
              {translation().views.providers_map.infobox.radius}{' '}
              <Typography variant="body2" component="span">
                {marker.radius}
              </Typography>
            </Typography>
            <div className="xxs-space" />
            <Typography variant="subtitle2" component="p">
              {translation().views.providers_map.infobox.ghost}{' '}
              <Typography variant="body2" component="span">
                {marker.ghost
                  ? translation().views.providers_map.infobox.yes
                  : translation().views.providers_map.infobox.no}
              </Typography>
            </Typography>
            <div className="xs-space" />
            <Button onClick={handleHide} variant="outlined" color="default">
              {translation().views.providers_map.infobox.hide}
            </Button>
          </div>
        </InfoWindow>
      )}
    </span>
  );
}

CircleMarker.propTypes = {
  marker: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    ghost: PropTypes.bool,
    base_latitude: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    base_longitude: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    radius: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
  }).isRequired
};

const ProviderGoogleMap = withGoogleMap((props) => {
  return <GoogleMap {...props}>{props.children}</GoogleMap>;
});

function ProvidersMap(props) {
  const {
    clearOwnReducer,
    getProvidersProductCoverage,
    history,
    providersMap: { data, isLoading }
  } = props;

  const defaultZoom = 6;
  const [product, setProduct] = useState(null);
  const [slot, setSlot] = useState(null);
  const [mode, setMode] = useState('all');
  const [colorInvert, setColorInvert] = useState(false);

  useEffect(() => {
    document.title = 'Actarus | Providers map';

    return () => {
      clearOwnReducer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleChangeProducts(val) {
    setProduct(val);
  }

  function handleChangeStart(val) {
    if (val) {
      setSlot(createDate(val));
    } else {
      setSlot(null);
    }
  }

  function handleChangeMode(val) {
    setMode(val);
  }

  function handleChangeStyle() {
    setColorInvert((colorInvert) => !colorInvert);
  }

  function handleSubmit() {
    if (!product || !product.id) return;

    const values = {
      product_id: product.id,
      slot,
      mode
    };

    getProvidersProductCoverage(values);
  }

  function renderMarker(data) {
    if (!data || data.length <= 0) return;

    const markers = [];

    for (let i = 0; i < data.length; i++) {
      markers.push(<CircleMarker key={i} marker={data[i]} />);
    }

    return markers;
  }

  return (
    <div className="ProvidersMap">
      <ProvidersMapViewBar
        colorInvert={colorInvert}
        handleChangeStyle={handleChangeStyle}
        history={history}
        parentUri="Shop"
        routeUri="Providers map"
      />
      <Backdrop open={isLoading} className="ProvidersMap-loader">
        <CircularProgress color="inherit" size={60} />
      </Backdrop>
      <Paper className="map-form" elevation={0} square>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} lg={4}>
            <FormApiAutocompleteField
              name="products_autocomplete"
              label={translation().views.providers_map.form.labels.products}
              placeholder={translation().views.providers_map.form.labels.product_placeholder}
              links={[{ rel: 'list', href: `${config.api_url}products` }]}
              onSelectValue={handleChangeProducts}
              targetKey="id"
              getFullObject
              defaultValue={product}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={4}>
            <FormDateTimeField
              name="date_start"
              label={translation().views.providers_map.form.labels.start}
              onChange={handleChangeStart}
              value={slot}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={2}>
            <FormSelectField
              name="mode"
              label={translation().views.providers_map.form.labels.mode}
              onChange={handleChangeMode}
              value={mode}
              defaultValue="all">
              <MenuItem value="all">{translation().views.providers_map.form.labels.all}</MenuItem>
              <MenuItem value="no_ghosts">
                {translation().views.providers_map.form.labels.no_ghosts}
              </MenuItem>
              <MenuItem value="ghosts_only">
                {translation().views.providers_map.form.labels.ghosts_only}
              </MenuItem>
            </FormSelectField>
          </Grid>
          <Grid item xs={12} md={3} lg={2} style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              color="primary"
              variant="contained"
              onClick={handleSubmit}
              disabled={!product || !product.id || isLoading}>
              {translation().views.providers_map.form.submit}
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <ProviderGoogleMap
        defaultCenter={{
          lat: 48.866667,
          lng: 2.333333
        }}
        defaultZoom={defaultZoom}
        options={{
          styles: colorInvert ? silverStyle : []
        }}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div className="map-wrapper" />}
        mapElement={<div style={{ height: `100%` }} />}>
        {renderMarker(data)}
      </ProviderGoogleMap>
    </div>
  );
}

ProvidersMap.propTypes = {
  clearOwnReducer: PropTypes.func.isRequired,
  getProvidersProductCoverage: PropTypes.func.isRequired,
  history: PropTypes.shape().isRequired,
  providersMap: PropTypes.shape({
    isLoading: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.shape())
  }).isRequired
};

export default ProvidersMap;
