import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchApi } from '../../../utils/functions/api';
import { formatErrors, formatValuesBeforePost } from '../../../utils/functions/forms';

import { enqueueSnackbar } from '../../../redux/actions/appActions';
import {
  cancelGetMetadata,
  getMetadata,
  getMetadataSuccess,
  getMetadataError
} from '../../../redux/actions/core/metadata/metadataActions';
import {
  updateResource,
  updateResourceSuccess,
  updateResourceError,
  getUpdateResourceLanguages,
  getUpdateResourceLanguagesSuccess,
  getUpdateResourceLanguagesError,
  setUpdateResourceLocale,
  setUpdateResourceTimezone,
  setResourceCollection,
  unmountUpdateView
} from '../../../redux/actions/core/update/updateActions';
import {
  getItem,
  getItemSuccess,
  getItemError,
  cancelGetItem
} from '../../../redux/actions/core/item/itemActions';
import { setListNeedReload } from '../../../redux/actions/core/list/listActions';
import { setFormsErrors, cleanFormsErrors } from '../../../redux/actions/forms/formsActions';

import UpdateView from '../../../views/core/update/UpdateView';

import translation from '../../../translation/translation';

const mapStateToProps = (store) => ({
  item: store.core.item,
  metadata: store.core.metadata,
  update: store.core.update
});

const mapDispatchToProps = (dispatch) => {
  return {
    unmountUpdateView() {
      dispatch(unmountUpdateView());
    },
    setListNeedReload(callback) {
      dispatch(setListNeedReload(callback));
    },
    setUpdateResourceLocale(locale) {
      dispatch(setUpdateResourceLocale(locale));
    },
    setUpdateResourceTimezone(timezone) {
      dispatch(setUpdateResourceTimezone(timezone));
    },
    getApiLanguages() {
      dispatch(getUpdateResourceLanguages());

      fetchApi(
        'get',
        'languages',
        null,
        null,
        null,
        (data) => {
          dispatch(getUpdateResourceLanguagesSuccess(data));
        },
        (error) => {
          dispatch(getUpdateResourceLanguagesError(error));
        }
      );
    },
    async getItem(uri, sourceToken = null, locale) {
      dispatch(getItem());

      const query = {};

      if (locale) {
        query.locale = locale;
      }

      try {
        await fetchApi(
          'get',
          `${uri}`,
          query,
          null,
          null,
          (data) => {
            dispatch(getItemSuccess(data));
          },
          (error) => {
            if (sourceToken) dispatch(cancelGetItem());

            dispatch(getItemError(true, error));

            if (error.response) {
              dispatch(
                enqueueSnackbar({
                  message:
                    error.response.data && error.response.data.detail
                      ? error.response.data.detail
                      : error.response.data.status_code
                      ? error.response.data.status_code + ': ' + translation().core.item.not_found
                      : translation().core.list.error_list,
                  status: error.response.status || undefined,
                  options: {
                    variant: 'error'
                  }
                })
              );
            } else {
              dispatch(
                enqueueSnackbar({
                  message: translation().core.list.error_list,
                  options: {
                    variant: 'error'
                  }
                })
              );
            }
          },
          null,
          sourceToken
        );
      } catch (error) {
        dispatch(getItemError(true, error));
      }
    },
    async getMetadata(uri, sourceToken = null) {
      if (!uri) return;

      dispatch(getMetadata());

      try {
        await fetchApi(
          'get',
          `${uri}/metadata`,
          null,
          null,
          null,
          (metadata) => {
            if (metadata && metadata && metadata.fields && metadata.fields.length > 0) {
              for (let i = 0; i < metadata.fields.length; i++) {
                if (metadata.fields[i].type && metadata.fields[i].type === 'collection')
                  dispatch(setResourceCollection(metadata.fields[i]));
              }
            }

            dispatch(getMetadataSuccess(metadata));
          },
          (error) => {
            if (sourceToken) dispatch(cancelGetMetadata());
            else dispatch(getMetadataError(true, error));
          },
          null,
          sourceToken
        );
      } catch (error) {
        dispatch(getMetadataError(true, error));
      }
    },
    async updateResource(uri, data, metadata, locale, callbackSuccess) {
      if (!uri || !data || !metadata) return;

      dispatch(updateResource());
      dispatch(cleanFormsErrors('updateForm'));

      const dataFormatted = formatValuesBeforePost(metadata, data);

      const query = {};

      if (locale) query.locale = locale;

      try {
        await fetchApi(
          'put',
          `${uri}`,
          query,
          dataFormatted,
          null,
          (success) => {
            dispatch(updateResourceSuccess(success));
            dispatch(
              enqueueSnackbar({
                message: translation().core.update.callbacks.success,
                options: {
                  variant: 'success'
                }
              })
            );

            if (callbackSuccess && typeof callbackSuccess === 'function') callbackSuccess();
          },
          (error) => {
            dispatch(updateResourceError(true, error));

            if (error.response) {
              if (
                error.response.data &&
                error.response.data.errors &&
                error.response.data.errors.length > 0
              ) {
                dispatch(setFormsErrors('updateForm', formatErrors(error.response.data.errors)));
              }

              dispatch(
                enqueueSnackbar({
                  message:
                    error.response.data && error.response.data.detail
                      ? error.response.data.detail
                      : translation().core.update.callbacks.error,
                  status: error.response.status || undefined,
                  options: {
                    variant: 'error'
                  }
                })
              );
            } else {
              dispatch(
                enqueueSnackbar({
                  message: translation().core.update.callbacks.error,
                  options: {
                    variant: 'error'
                  }
                })
              );
            }
          }
        );
      } catch (error) {
        dispatch(updateResourceError(true, error));
        dispatch(
          enqueueSnackbar({
            message: translation().core.update.callbacks.error,
            options: {
              variant: 'error'
            }
          })
        );
      }
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UpdateView));
