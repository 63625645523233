const initialState = {
  step: 0,
  company: {
    data: null,
    error: null,
    loading: false,
    selected: null,
    linked: false,
    admin: false
  },
  user: {
    data: null,
    error: null,
    loading: false
  },
  provider: {
    data: null,
    error: null,
    loading: false
  },
  invoice: {
    address: {
      data: null,
      error: null,
      loading: false
    },
    payment: {
      data: null,
      error: null,
      loading: false
    }
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_REDUCERS':
      return initialState;
    case 'CLEAR_PROVIDER_FACTORY':
      return initialState;
    case 'SET_PROVIDER_FACTORY_STEP':
      return {
        ...state,
        step: action.step
      };
    case 'CREATE_USER_PROVIDER_FACTORY':
      return {
        ...state,
        user: {
          ...initialState.user,
          loading: true
        },
        provider: {
          ...initialState.provider,
          loading: true
        }
      };
    case 'CREATE_USER_PROVIDER_FACTORY_SUCCESS':
      return {
        ...state,
        user: {
          ...state.user,
          data: action.user,
          error: null,
          loading: false
        },
        provider: {
          ...state.provider,
          data: action.provider,
          error: null,
          loading: false
        }
      };
    case 'CREATE_USER_PROVIDER_FACTORY_USER_ERROR':
      return {
        ...state,
        user: {
          ...state.user,
          error: action.error,
          loading: false
        },
        provider: {
          ...state.provider,
          loading: false
        }
      };
    case 'CREATE_USER_PROVIDER_FACTORY_PROVIDER_ERROR':
      return {
        ...state,
        provider: {
          ...state.provider,
          error: action.error,
          loading: false
        },
        user: {
          ...state.user,
          loading: false
        }
      };
    case 'CREATE_USER_PROVIDER_FACTORY_COMPANY':
      return {
        ...state,
        company: {
          ...state.company,
          loading: true
        }
      };
    case 'CREATE_USER_PROVIDER_FACTORY_COMPANY_SUCCESS':
      return {
        ...state,
        company: {
          ...state.company,
          data: action.company,
          error: null,
          loading: false
        }
      };
    case 'CREATE_USER_PROVIDER_FACTORY_COMPANY_ERROR':
      return {
        ...state,
        company: {
          ...state.company,
          error: action.error,
          loading: false
        }
      };
    case 'SELECT_USER_PROVIDER_COMPANY':
      return {
        ...state,
        company: {
          ...state.company,
          selected: action.company
        }
      };
    case 'LINK_USER_PROVIDER_FACTORY_SUCCESS':
      return {
        ...state,
        company: {
          ...state.company,
          linked: true
        }
      };
    case 'CHECK_USER_PROVIDER_FACTORY_ADMIN':
      return {
        ...state,
        company: {
          ...state.company,
          admin: action.checked
        }
      };
    case 'CREATE_PROVIDER_FACTORY_ADDRESS':
      return {
        ...state,
        invoice: {
          ...state.invoice,
          address: {
            data: null,
            error: null,
            loading: true
          }
        }
      };
    case 'CREATE_PROVIDER_FACTORY_ADDRESS_SUCCESS':
      return {
        ...state,
        invoice: {
          ...state.invoice,
          address: {
            data: { name: action.name, address: action.address },
            error: null,
            loading: false
          }
        }
      };
    case 'CREATE_PROVIDER_FACTORY_ADDRESS_ERROR':
      return {
        ...state,
        invoice: {
          ...state.invoice,
          address: {
            data: null,
            error: action.error,
            loading: false
          }
        }
      };
    case 'CREATE_PROVIDER_FACTORY_PAYMENT_METHOD':
      return {
        ...state,
        invoice: {
          ...state.invoice,
          payment: {
            data: null,
            error: null,
            loading: true
          }
        }
      };
    case 'CREATE_PROVIDER_FACTORY_PAYMENT_METHOD_SUCCESS':
      return {
        ...state,
        invoice: {
          ...state.invoice,
          payment: {
            data: action.data,
            error: null,
            loading: false
          }
        }
      };
    case 'CREATE_PROVIDER_FACTORY_PAYMENT_METHOD_ERROR':
      return {
        ...state,
        invoice: {
          ...state.invoice,
          payment: {
            data: null,
            error: action.error,
            loading: false
          }
        }
      };
    default:
      return state;
  }
};
