import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import translation from '../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  submitButton: {
    display: 'flex',
    marginLeft: 'auto'
  },
  formControl: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  inputPay: {
    marginBottom: theme.spacing(2)
  }
}));

function PayInvoice(props) {
  const classes = useStyles();
  const {
    changeInvoicePaymentMethod,
    closeDialogFunction,
    getInvoicerPaymentMethods,
    resource,
    reloadListFunction,
    payInvoice
  } = props;

  const [payValue, setPayValue] = useState(null);
  const [methods, setMethods] = useState([]);
  const [methodValue, setMethodsValue] = useState('');
  const [isMethodLoading, setIsMethodLoading] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [isPayLoading, setIsPayLoading] = useState(false);

  useEffect(() => {
    if (resource) {
      setIsMethodLoading(true);

      getInvoicerPaymentMethods(
        resource.id,
        resource.currency_isocode,
        (methods) => {
          setMethods(methods);
          setIsMethodLoading(false);
        },
        () => {
          setIsMethodLoading(false);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleChangePayValue(event) {
    setPayValue(event.target.value);
  }

  function handlePay(e) {
    e.preventDefault();

    if (!resource.id) return;

    setIsPayLoading(true);

    payInvoice(
      resource.id,
      payValue,
      () => {
        setIsPayLoading(false);
        closeDialogFunction();
        reloadListFunction();
      },
      () => {
        setIsPayLoading(false);
      }
    );
  }

  function handleChangeMethods(event) {
    setMethodsValue(event.target.value);
  }

  function handleSaveMethod() {
    if (!resource.id) return;

    setIsSaveLoading(true);

    changeInvoicePaymentMethod(
      resource.id,
      resource.currency_isocode,
      methodValue,
      () => {
        setIsSaveLoading(false);
        closeDialogFunction();
      },
      () => {
        setIsSaveLoading(false);
      }
    );
  }

  return (
    <div className="PayInvoice">
      <form onSubmit={handlePay} noValidate>
        <TextField
          fullWidth
          name="invoice-payment-field"
          id="invoice-payment-field"
          onChange={handleChangePayValue}
          disabled={isSaveLoading || isPayLoading}
          className={classes.inputPay}
          label={translation().core.list.dialogs.custom_list_actions.pay_invoice.input_label}
        />
        <Button
          variant="contained"
          type="submit"
          color="primary"
          disabled={isSaveLoading || isPayLoading}
          className={classes.submitButton}>
          {translation().core.list.dialogs.custom_list_actions.pay_invoice.buttons.pay}
        </Button>
      </form>
      {isPayLoading && (
        <div className="loader-wpr">
          <CircularProgress color="primary" size={25} />
          <p>{translation().core.list.dialogs.custom_list_actions.pay_invoice.load_pay_invoice}</p>
        </div>
      )}
      <div className="md-space" />
      <Typography gutterBottom variant="h6">
        {translation().core.list.dialogs.custom_list_actions.pay_invoice.change_method}
      </Typography>
      {!isMethodLoading && (
        <FormControl className={classes.formControl}>
          <InputLabel id="invoice-payment-method">
            {translation().core.list.dialogs.custom_list_actions.pay_invoice.select_label}
          </InputLabel>
          <Select
            labelId="invoice-payment-method"
            id="invoice-payment-method-field"
            name="invoice-payment-method-field"
            onChange={handleChangeMethods}
            className={classes.inputPay}
            disabled={isSaveLoading || isPayLoading}
            value={methodValue}
            fullWidth>
            {methods.length > 0 ? (
              methods.map((method, index) => (
                <MenuItem key={index} value={method.id}>{`${method.type} ${method.name}`}</MenuItem>
              ))
            ) : (
              <MenuItem />
            )}
          </Select>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            onClick={handleSaveMethod}
            disabled={isSaveLoading || isPayLoading}
            className={classes.submitButton}>
            {translation().core.list.dialogs.custom_list_actions.pay_invoice.buttons.save}
          </Button>
        </FormControl>
      )}
      {isMethodLoading && (
        <div className="loader-wpr">
          <CircularProgress color="primary" size={25} />
          <p>
            {translation().core.list.dialogs.custom_list_actions.pay_invoice.load_payment_method}
          </p>
        </div>
      )}
      {isSaveLoading && (
        <div className="loader-wpr">
          <CircularProgress color="primary" size={25} />
          <p>
            {
              translation().core.list.dialogs.custom_list_actions.pay_invoice
                .load_save_payment_method
            }
          </p>
        </div>
      )}
    </div>
  );
}

PayInvoice.propTypes = {
  changeInvoicePaymentMethod: PropTypes.func.isRequired,
  closeDialogFunction: PropTypes.func.isRequired,
  getInvoicerPaymentMethods: PropTypes.func.isRequired,
  resource: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    currency_isocode: PropTypes.string
  }),
  reloadListFunction: PropTypes.func.isRequired,
  payInvoice: PropTypes.func.isRequired
};

export default PayInvoice;
