import axios from 'axios';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchApi } from '../../../utils/functions/api';
import { formatErrors } from '../../../utils/functions/forms';
import { enqueueSnackbar } from '../../../redux/actions/appActions';
import translation from '../../../translation/translation';
import ProviderProducts from '../../../views/providers/providerFactory/steps/products/ProviderProducts';

const mapDispatchToProps = (dispatch) => {
  return {
    getProviderProducts(providerId, next, onSuccess, onError, sourceToken = null) {
      if (!providerId) return;

      fetchApi(
        'get',
        next ? next : `providers/${providerId}/products`,
        null,
        null,
        null,
        (success, paging) => {
          if (onSuccess) {
            onSuccess(success, paging);
          }
        },
        (error) => {
          if (!axios.isCancel()) {
            const message =
              error && error.response && error.response.data && error.response.data.detail
                ? error.response.data.detail
                : translation().views.provider_factory.steps.products.callbacks.get_error;

            dispatch(
              enqueueSnackbar({
                message,
                options: {
                  variant: 'error'
                }
              })
            );

            if (onError) {
              onError(message);
            }
          }
        },
        null,
        sourceToken
      );
    },
    createProviderProduct(providerId, product, onSuccess, onError) {
      if (!providerId || !product) return;

      fetchApi(
        'post',
        `providers/${providerId}/products`,
        null,
        product,
        null,
        (success) => {
          if (onSuccess) {
            onSuccess(success);
          }
        },
        (error) => {
          const message =
            error && error.response && error.response.data && error.response.data.detail
              ? error.response.data.detail
              : translation().views.provider_factory.steps.products.callbacks.push_error;

          dispatch(
            enqueueSnackbar({
              message,
              options: {
                variant: 'error'
              }
            })
          );

          if (onError) {
            if (error.response) {
              if (
                error.response.data &&
                error.response.data.errors &&
                error.response.data.errors.length > 0
              ) {
                onError(formatErrors(error.response.data.errors));
              } else {
                onError(message);
              }
            } else {
              onError(message);
            }
          }
        }
      );
    },
    deleteProviderProduct(productId, providerId, onSuccess, onError) {
      if (!providerId || !productId) return;

      fetchApi(
        'delete',
        `providers/${providerId}/products/${productId}`,
        null,
        null,
        null,
        () => {
          if (onSuccess) {
            onSuccess();
          }
        },
        (error) => {
          const message =
            error && error.response && error.response.data && error.response.data.detail
              ? error.response.data.detail
              : translation().views.provider_factory.steps.products.callbacks.delete_error;

          dispatch(
            enqueueSnackbar({
              message,
              options: {
                variant: 'error'
              }
            })
          );

          if (onError) {
            onError(message);
          }
        }
      );
    },
    updateProviderProduct(productId, providerId, product, onSuccess, onError) {
      if (!providerId || !productId || !product) return;

      fetchApi(
        'put',
        `providers/${providerId}/products/${productId}`,
        null,
        product,
        null,
        (success) => {
          if (onSuccess) {
            onSuccess(success);
          }
        },
        (error) => {
          const message =
            error && error.response && error.response.data && error.response.data.detail
              ? error.response.data.detail
              : translation().views.provider_factory.steps.products.callbacks.put_error;

          dispatch(
            enqueueSnackbar({
              message,
              options: {
                variant: 'error'
              }
            })
          );

          if (onError) {
            if (error.response) {
              if (
                error.response.data &&
                error.response.data.errors &&
                error.response.data.errors.length > 0
              ) {
                onError(formatErrors(error.response.data.errors));
              } else {
                onError(message);
              }
            } else {
              onError(message);
            }
          }
        }
      );
    }
  };
};

export default withRouter(connect(null, mapDispatchToProps)(ProviderProducts));
