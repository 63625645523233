export const getMetricsList = () => ({
  type: 'GET_METRICS_LIST'
});

export const getMetricsListSuccess = (data) => ({
  type: 'GET_METRICS_LIST_SUCCESS',
  data
});

export const getMetricsListError = (error) => ({
  type: 'GET_METRICS_LIST_ERROR',
  error
});
