import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Modal from '@material-ui/core/Modal';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { apiPostFile } from '../../../utils/functions/api';
import config from '../../../config';
import translation from '../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 'auto',
    maxWidth: 700,
    minWidth: 300,
    outline: 'none',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.paper,
    textAlign: 'center',
    maxHeight: '100%'
  },
  root: {
    position: 'relative',
    '&.fullWidth': {
      width: '100%'
    }
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  dropperActions: {
    position: 'absolute',
    top: 28,
    right: 0
  },
  fileLoader: {
    position: 'relative',
    top: 10
  },
  textInput: {
    paddingRight: 65
  },
  iconButton: {
    padding: 6
  },
  modalActions: {
    textAlign: 'right',
    borderBottom: '1px solid #e0e0e0',
    padding: 3
  }
}));

function FormFileField(props) {
  const classes = useStyles();

  const {
    autoComplete,
    defaultValue,
    disabled,
    error,
    id,
    isDisplayImg,
    fullWidth,
    label,
    margin,
    multiline,
    name,
    onChange,
    rows,
    required,
    type,
    write
  } = props;

  const [isZoomOpen, setZoomOpen] = useState(false);
  const [state, setState] = useState({
    fileError: null,
    accepted: [],
    files: defaultValue
      ? [
          {
            url: defaultValue,
            thumbnail_url: defaultValue + '?client_id=' + config.api_key
          }
        ]
      : [],
    rejected: [],
    isLoading: false,
    textFieldValue: defaultValue || ''
  });

  let accept = '*';

  if (type === 'image') {
    accept = 'image/jpeg, image/png, image/gif';
  } else if (type === 'file') {
    accept =
      'application/pdf, text/csv, text/plain, text/xml, application/zip, application/x-zip-compressed, application/xml, application/xhtml+xml, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.oasis.opendocument.text, video/mpeg, audio/x-mpeg-3, audio/mpeg3, audio/mp3, video/mp4, image/jpeg, image/png, image/gif';
  }

  const { getRootProps, isDragActive, getInputProps } = useDropzone({
    accept: accept,
    onDrop: handleDropFile
  });

  function handleChangeTextField(event) {
    setState({
      files: [],
      textFieldValue: event.target.value
    });

    if (onChange) {
      onChange(event.target.value || null);
    }
  }

  function handleDeleteFile(e) {
    e.stopPropagation();

    setState({
      files: [],
      textFieldValue: ''
    });

    if (onChange) {
      onChange(null);
    }
  }

  function handleZoomFile() {
    setZoomOpen(true);
  }

  function handleZoomOutFile() {
    setZoomOpen(false);
  }

  function handleDropFile(accepted, rejected) {
    setState({
      ...state,
      accepted,
      rejected
    });

    if (accepted) {
      for (let i = 0; i < accepted.length; i++) {
        if (accepted.length <= 1) {
          setState({ ...state, isLoading: true });

          apiPostFile(
            accepted[i],
            (success) => {
              const newFile = success.data && success.data ? success.data : '';
              const newFiles = [newFile];

              setState({
                ...state,
                files: newFiles,
                fileError: null,
                isLoading: false,
                textFieldValue: newFile.url
              });

              if (onChange) {
                onChange(newFile.url);
              }
            },
            (error) => {
              setState({
                ...state,
                fileError:
                  error.response && error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().commons.file_field.error_sent,
                isLoading: false
              });
            }
          );
        } else {
          setState({
            ...state,
            fileError: translation().commons.file_field.only_one
          });
          return;
        }
      }
    }

    /**
     * Handling files rejected
     */
    if (rejected && rejected.length > 0) {
      let fileError = translation().commons.file_field.files;

      for (let j = 0; j < rejected.length; j++) {
        if (rejected[j].name) fileError += rejected[j].name + ', ';
      }

      fileError += ' ' + translation().commons.file_field.refused;

      setState({ ...state, fileError });
    }
  }

  return (
    <div className={classNames(classes.root, { fullWidth })}>
      <TextField
        autoComplete={autoComplete}
        disabled={!write || disabled}
        helperText={error || state.fileError ? state.fileError : null}
        margin={margin}
        multiline={multiline}
        name={name}
        label={label}
        type="text"
        rows={rows}
        required={required}
        fullWidth={fullWidth}
        inputProps={{
          className: classes.textInput
        }}
        id={id || 'trigger-input-file-' + name}
        error={error || state.fileError ? true : false}
        onChange={handleChangeTextField}
        value={state.textFieldValue}
      />
      <div className={classes.flexContainer}>
        {type === 'image' && isDisplayImg && state && state.files && state.files.length > 0
          ? state.files.map((file, i) => {
              return (
                <img
                  alt="404"
                  key={i}
                  style={{ maxHeight: 70, marginTop: 4 }}
                  src={`${file.url}?client_id=${config.api_key}`}
                />
              );
            })
          : ''}
        {type === 'file' && isDisplayImg && state && state.files && state.files.length > 0
          ? state.files.map((file, i) => {
              return (
                <img
                  alt={file.extension}
                  key={i}
                  style={{ marginTop: 4 }}
                  src={`/assets/images/medias/files/small/file-extension-${file.extension}.png`}
                />
              );
            })
          : ''}
        {type === 'image' && isDisplayImg && state.files && state.files[0] && (
          <IconButton
            size="small"
            aria-label="Zoom"
            component="span"
            style={{ marginLeft: 5 }}
            className={classes.iconButton}
            disabled={disabled}
            onClick={handleZoomFile}>
            <VisibilityIcon fontSize="small" />
          </IconButton>
        )}
      </div>
      <div
        {...getRootProps({
          className: classNames('dropzone dropper', {
            'dropzone--isActive dropper-ondrag': isDragActive
          })
        })}>
        <div className={classes.dropperActions}>
          {!state.isLoading ? (
            <>
              {state.files && state.files[0] && (
                <IconButton
                  size="small"
                  aria-label="Delete"
                  component="span"
                  disabled={disabled}
                  className={classes.iconButton}
                  onClick={handleDeleteFile}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              )}
              <label htmlFor={id || 'trigger-input-file-' + name}>
                <IconButton
                  size="small"
                  aria-label="Upload"
                  component="span"
                  disabled={disabled}
                  className={classes.iconButton}>
                  <CloudUploadIcon fontSize="small" />
                </IconButton>
              </label>
            </>
          ) : (
            <CircularProgress color="secondary" size={15} className={classes.fileLoader} />
          )}
        </div>
        <input {...getInputProps()} />
      </div>
      <Modal open={isZoomOpen} onClose={handleZoomOutFile}>
        <div className={classes.paper}>
          <div className={classes.modalActions}>
            <IconButton
              size="small"
              aria-label="Close"
              component="span"
              className={classes.closeModalButton}
              onClick={handleZoomOutFile}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>
          {state.files && state.files[0] && (
            <img
              alt="404"
              style={{ maxWidth: '100%', padding: 8 }}
              src={`${state.files[0].url}?client_id=${config.api_key}`}
            />
          )}
        </div>
      </Modal>
    </div>
  );
}

FormFileField.defaultProps = {
  isDisplayImg: true,
  fullWidth: true,
  margin: 'normal',
  multiline: false,
  type: 'file',
  rows: null,
  write: true
};

FormFileField.propTypes = {
  autoComplete: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fullWidth: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isDisplayImg: PropTypes.bool,
  multiline: PropTypes.bool,
  margin: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  rows: PropTypes.number,
  type: PropTypes.string,
  write: PropTypes.bool
};

export default FormFileField;
