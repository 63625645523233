import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';

import FormApiAutocompleteField from '../../../components/forms/fields/FormApiAutocompleteField';
import translation from '../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 450,
    [theme.breakpoints.down('sm')]: {
      minWidth: 200
    }
  },
  alignRight: {
    textAlign: 'right'
  },
  unMarge: {
    marginLeft: -16
  }
}));

function LinkUserToCompany(props) {
  const {
    adminizeCompanyUser,
    closeDialogFunction,
    linkUserToCompany,
    unlinkUserFromCompany,
    reloadListFunction,
    resource
  } = props;
  const classes = useStyles();
  const [toggleValue, setToggleValue] = useState(false);
  const [isAdminLoading, setAdminLoading] = useState(false);
  const [isLinkLoading, setLinkLoading] = useState(false);
  const [companyValue, setCompanyValue] = useState(false);

  useEffect(() => {
    if (resource && resource.company_admin) {
      setToggleValue(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleChangeCompany(value) {
    setCompanyValue(value);
  }

  function handleLinkUser() {
    if (!resource || !companyValue || !resource.id) return;

    if (resource.company && resource.company.id) {
      setLinkLoading(true);

      unlinkUserFromCompany(resource.company.id, resource.id, () => {
        linkUserToCompany(
          companyValue,
          resource.id,
          () => {
            setLinkLoading(false);
            closeDialogFunction();
            reloadListFunction();
          },
          () => {
            setLinkLoading(false);
          }
        );
      });
    } else {
      setLinkLoading(true);

      linkUserToCompany(
        companyValue,
        resource.id,
        () => {
          setLinkLoading(false);
          closeDialogFunction();
          reloadListFunction();
        },
        () => {
          setLinkLoading(false);
        }
      );
    }
  }

  function handleUnlinkUser() {
    if (!resource || !resource.company || !resource.company.id || !resource.id) return;

    setLinkLoading(true);

    unlinkUserFromCompany(
      resource.company.id,
      resource.id,
      () => {
        setLinkLoading(false);
        closeDialogFunction();
        reloadListFunction();
      },
      () => {
        setLinkLoading(false);
      }
    );
  }

  function handleAdminzeUser(event, checked) {
    if (!resource || !resource.company || !resource.company.id || !resource.id) return;

    setAdminLoading(true);

    adminizeCompanyUser(
      resource.company.id,
      resource.id,
      checked,
      () => {
        setAdminLoading(false);
        setToggleValue(checked);
        reloadListFunction();
        closeDialogFunction();
      },
      () => {
        setAdminLoading(false);
      }
    );
  }

  return (
    <div className="LinkUserToCompany">
      <div className={classes.root}>
        {resource && resource.company && (
          <div>
            {resource.company.commercial_name && (
              <Typography>
                {
                  translation().core.list.dialogs.custom_list_actions.link_user_to_company
                    .current_company
                }{' '}
                {resource.company.commercial_name +
                  (resource.company.legal_name
                    ? ` (${
                        translation().core.list.dialogs.custom_list_actions.link_user_to_company
                          .legal_name
                      } ${resource.company.legal_name})`
                    : '')}
              </Typography>
            )}
            <div className="xs-space" />
            <div className={classes.alignRight}>
              <Button
                disabled={isAdminLoading || isLinkLoading}
                variant="contained"
                onClick={handleUnlinkUser}
                color="primary">
                {translation().core.list.dialogs.custom_list_actions.link_user_to_company.unlink}
              </Button>
            </div>
            <div className="sm-space" />
            <div className={classes.unMarge}>
              <FormControlLabel
                checked={toggleValue}
                control={<Switch color="primary" />}
                label={
                  translation().core.list.dialogs.custom_list_actions.link_user_to_company.admin
                }
                labelPlacement="start"
                disabled={isAdminLoading || isLinkLoading}
                onChange={handleAdminzeUser}
              />
            </div>
          </div>
        )}
        <div className="sm-space" />
        <FormApiAutocompleteField
          name="link_u_to_c_field"
          label={
            translation().core.list.dialogs.custom_list_actions.link_user_to_company.input_label
          }
          placeholder={
            translation().core.list.dialogs.custom_list_actions.link_user_to_company
              .input_placeholder
          }
          links={[{ rel: 'list', href: 'companies' }]}
          onSelectValue={handleChangeCompany}
          targetKey="id"
          autoComplete="off"
          disabled={isAdminLoading || isLinkLoading}
        />
        <div className={classes.alignRight}>
          <Button
            disabled={!companyValue || isAdminLoading || isLinkLoading}
            variant="contained"
            onClick={handleLinkUser}
            color="primary">
            {translation().core.list.dialogs.custom_list_actions.link_user_to_company.link}
          </Button>
        </div>
        <div className="sm-space" />
        {(isLinkLoading || isAdminLoading) && (
          <div className="loader-wpr">
            <CircularProgress color="primary" size={25} />
            <p>
              {
                translation().core.list.dialogs.custom_list_actions.link_user_to_company
                  .loading_text
              }
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

LinkUserToCompany.propTypes = {
  adminizeCompanyUser: PropTypes.func.isRequired,
  closeDialogFunction: PropTypes.func.isRequired,
  linkUserToCompany: PropTypes.func.isRequired,
  unlinkUserFromCompany: PropTypes.func.isRequired,
  reloadListFunction: PropTypes.func.isRequired,
  resource: PropTypes.shape()
};

export default LinkUserToCompany;
