import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AppBar from '@material-ui/core/AppBar';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import Tooltip from '@material-ui/core/Tooltip';

import { isNavigationBackPossible, navigateBack } from '../../../../utils/functions/navigation';
import translation from '../../../../translation/translation';

const styles = (theme) => ({
  root: {
    width: '100%'
  },
  grow: {
    flexGrow: 1
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
});

class CreateHostingExtensionsViewBar extends PureComponent {
  state = {
    mobileMoreAnchorEl: null
  };

  handleMobileMenuOpen = (event) => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  handleHistory = () => {
    const { history } = this.props;

    navigateBack(history);
  };

  render() {
    const { mobileMoreAnchorEl } = this.state;
    const { classes, disabled, history, parentUri, routeUri } = this.props;

    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const renderMobileMenu = isNavigationBackPossible(history) ? (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        onClose={this.handleMobileMenuClose}>
        <MenuItem onClick={this.handleHistory}>
          <ArrowBackIcon fontSize="small" style={{ marginRight: 8 }} />
          <p>{translation().actions.back}</p>
        </MenuItem>
      </Menu>
    ) : (
      false
    );

    return (
      <div className={classes.root}>
        <AppBar elevation={0} position="static" color="default">
          <Toolbar>
            <div className={classes.sectionDesktop}>
              <Breadcrumbs
                maxItems={5}
                separator={<NavigateNextIcon fontSize="small" />}
                arial-label="Moderation view breadcrumb">
                <Typography variant="body2" color="textPrimary">
                  {parentUri}
                </Typography>
                {routeUri.length &&
                  routeUri.split('/').map((n, i) => {
                    return (
                      <Typography variant="body2" color="textPrimary" key={i}>
                        {n}
                      </Typography>
                    );
                  })}
              </Breadcrumbs>
            </div>
            <div className={classes.sectionMobile}>
              {translation().views.order_products.hosting_extensions.create.title}
            </div>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              {isNavigationBackPossible(history) && (
                <Tooltip
                  title={translation().actions.back || 'Back'}
                  aria-label={translation().actions.back || 'Back'}
                  enterDelay={700}>
                  <div>
                    <IconButton
                      onClick={this.handleHistory}
                      color="inherit"
                      className={classes.backIcon}
                      disabled={disabled}
                      style={{ marginLeft: 7 }}>
                      <ArrowBackIcon fontSize="small" />
                    </IconButton>
                  </div>
                </Tooltip>
              )}
            </div>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-haspopup="true"
                onClick={this.handleMobileMenuOpen}
                disabled={disabled}
                color="inherit"
                style={{ marginLeft: 7 }}>
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
      </div>
    );
  }
}

CreateHostingExtensionsViewBar.defaultProps = {
  disabled: false
};

CreateHostingExtensionsViewBar.propTypes = {
  disabled: PropTypes.bool,
  classes: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  parentUri: PropTypes.string.isRequired,
  routeUri: PropTypes.string.isRequired
};

export default withStyles(styles)(CreateHostingExtensionsViewBar);
