import './providerView.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import BusinessCenterTwoToneIcon from '@material-ui/icons/BusinessCenterTwoTone';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Alert from '../../../components/alert/Alert';
import ProviderInvoices from './components/invoices/ProviderInvoices';
import ProviderMetrics from './components/metrics/ProviderMetrics';
import ProviderOrderProducts from './components/orderProducts/ProviderOrderProducts';
import ProviderProducts from './components/products/ProviderProducts';
import ProviderRatings from './components/ratings/ProviderRatings';
import ProviderSchedule from './components/schedule/ProviderSchedule';
import ProviderSummary from './components/summary/ProviderSummary';
import ProviderViewBar from './components/ProviderViewBar';
import translation from '../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  loaderIcon: {
    fontSize: 56,
    display: 'block',
    margin: '15px auto'
  },
  linearProgress: {
    margin: 'auto',
    maxWidth: 250
  },
  progressWrapper: {
    padding: theme.spacing(3),
    textAlign: 'center',
    width: '100%',
    '& .loader-wpr': {
      margin: 0,
      paddingTop: 7,
      paddingBottom: 10
    }
  },
  tabsRoot: {
    ...theme.mixins.gutters()
  }
}));

function ProviderView(props) {
  const classes = useStyles();

  const tabs = [
    {
      label: translation().views.provider_view.infos.label,
      hash: 'infos',
      value: 0
    },
    {
      label: translation().views.provider_view.schedule.label,
      hash: 'schedule',
      value: 1
    },
    {
      label: translation().views.provider_view.products.label,
      hash: 'products',
      value: 2
    },
    {
      label: translation().views.provider_view.order_products.label,
      hash: 'order-products',
      value: 3
    },
    {
      label: translation().views.provider_view.invoice_label,
      hash: 'invoices',
      value: 4
    },
    {
      label: translation().views.provider_view.metrics.label,
      hash: 'metrics',
      value: 5
    },
    {
      label: translation().views.provider_view.ratings.label,
      hash: 'rating',
      value: 6
    }
  ];

  const {
    cleanProviderViewAddressesReducer,
    cleanProviderViewDevicesReducer,
    cleanProviderViewInvoicesReducer,
    cleanProviderViewInvoiceQueueReducer,
    cleanProviderViewOrderProductsReducer,
    cleanProviderViewPaymentMethodsReducer,
    cleanProviderViewRatingsReducer,
    getProviderView,
    getProviderViewAddresses,
    getProviderViewDevices,
    getProviderViewInvoices,
    getProviderViewInvoiceQueue,
    getProviderViewPaymentMethods,
    getProviderViewOrderProducts,
    getProviderViewRatingsEmitted,
    getProviderViewRatingsReceived,
    getOrderProductsMetadata,
    getInvoicerId,
    getPayerId,
    getProviderHash,
    history,
    match,
    location: { pathname },
    provider: {
      data,
      error,
      isLoading,
      addresses,
      devices,
      invoices,
      invoice_queue,
      isModeCompany,
      payment_methods,
      order_products,
      ratings
    },
    sub_routes,
    toggleProviderViewMode
  } = props;

  const [tab, setTab] = useState(0);

  useEffect(() => {
    if (match && match.params && match.params.id) {
      document.title = `Actarus | Provider #${match.params.id}`;
    } else {
      document.title = 'Actarus | Provider view';
    }

    if (match && match.params && match.params.id) {
      getProviderView(
        match && match.params && match.params.id ? match.params.id : null,
        (provider) => {
          if (provider && provider.user && provider.user.company) {
            toggleProviderViewMode(true);
          }

          if (history && history.location && history.location.hash) {
            for (let i = 0; i < tabs.length; i++) {
              if (tabs[i].hash === history.location.hash.substring(1)) {
                setTab(i);
                break;
              }
            }
          }
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleRefresh() {
    return false;
  }

  function handleChangeTab(e, newTab) {
    setTab(newTab);

    for (let i = 0; i < tabs.length; i++) {
      if (tabs[i].value === newTab) {
        window.location.hash = tabs[i].hash;
        break;
      }
    }
  }

  return (
    <div className="ProviderView">
      <ProviderViewBar
        item={data ? data : null}
        history={history}
        parentUri="Shop"
        routeUri={pathname.substring(1)}
        subRoutes={sub_routes}
        disabled={false}
        handleRefresh={handleRefresh}
      />
      <div className="ProviderView-content">
        {!isLoading && error && (!data || data.length <= 0) ? (
          <div className="error-list">
            <Alert
              type="danger"
              style={{ marginTop: 10 }}
              status={translation().commons.alerts.error}
              text={translation().core.list.error_list}
            />
          </div>
        ) : (
          false
        )}
        {isLoading && (
          <div className="loading-wrapper">
            <div className={classes.progressWrapper}>
              <BusinessCenterTwoToneIcon
                fontSize="large"
                color="primary"
                className={classes.loaderIcon}
              />
              <div className={classes.linearProgress}>
                <LinearProgress color="secondary" variant="query" />
              </div>
              <p className="loader-wpr">{translation().views.provider_view.loading_provider}</p>
            </div>
          </div>
        )}
        {!isLoading && data && (
          <>
            <Tabs
              value={tab}
              className={classes.tabsRoot}
              onChange={handleChangeTab}
              variant="scrollable"
              indicatorColor="secondary"
              textColor="secondary"
              aria-label="lab tabs">
              {tabs.map((tab) => (
                <Tab key={tab.value} label={tab.label} />
              ))}
            </Tabs>
            <div>
              {tab === 0 && (
                <ProviderSummary
                  cleanProviderViewDevicesReducer={cleanProviderViewDevicesReducer}
                  devices={devices}
                  getProviderViewDevices={getProviderViewDevices}
                  provider={data}
                />
              )}
              {tab === 1 && <ProviderSchedule match={match} getProviderHash={getProviderHash} />}
              {tab === 2 && <ProviderProducts blacklist={data.blacklist} providerId={data.id} />}
              {tab === 3 && (
                <ProviderOrderProducts
                  cleanProviderViewOrderProductsReducer={cleanProviderViewOrderProductsReducer}
                  getProviderViewOrderProducts={getProviderViewOrderProducts}
                  getOrderProductsMetadata={getOrderProductsMetadata}
                  providerId={data.id}
                  orderProducts={order_products}
                />
              )}
              {tab === 4 && (
                <ProviderInvoices
                  addresses={addresses}
                  cleanProviderViewAddressesReducer={cleanProviderViewAddressesReducer}
                  cleanProviderViewInvoicesReducer={cleanProviderViewInvoicesReducer}
                  cleanProviderViewInvoiceQueueReducer={cleanProviderViewInvoiceQueueReducer}
                  cleanProviderViewPaymentMethodsReducer={cleanProviderViewPaymentMethodsReducer}
                  getProviderViewAddresses={getProviderViewAddresses}
                  getProviderViewInvoices={getProviderViewInvoices}
                  getProviderViewInvoiceQueue={getProviderViewInvoiceQueue}
                  getProviderViewPaymentMethods={getProviderViewPaymentMethods}
                  getInvoicerId={getInvoicerId}
                  getPayerId={getPayerId}
                  invoices={invoices}
                  invoiceQueue={invoice_queue}
                  isModeCompany={isModeCompany}
                  paymentMethods={payment_methods}
                  provider={data}
                  toggleProviderViewMode={toggleProviderViewMode}
                />
              )}
              {tab === 5 && <ProviderMetrics />}
              {tab === 6 && (
                <ProviderRatings
                  getProviderViewRatingsEmitted={getProviderViewRatingsEmitted}
                  getProviderViewRatingsReceived={getProviderViewRatingsReceived}
                  cleanProviderViewRatingsReducer={cleanProviderViewRatingsReducer}
                  ratings={ratings}
                  providerId={data.id}
                />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

ProviderView.propTypes = {
  cleanProviderViewAddressesReducer: PropTypes.func.isRequired,
  cleanProviderViewInvoicesReducer: PropTypes.func.isRequired,
  cleanProviderViewInvoiceQueueReducer: PropTypes.func.isRequired,
  cleanProviderViewOrderProductsReducer: PropTypes.func.isRequired,
  cleanProviderViewPaymentMethodsReducer: PropTypes.func.isRequired,
  cleanProviderViewDevicesReducer: PropTypes.func.isRequired,
  cleanProviderViewRatingsReducer: PropTypes.func.isRequired,
  getProviderView: PropTypes.func.isRequired,
  getProviderViewAddresses: PropTypes.func.isRequired,
  getProviderViewDevices: PropTypes.func.isRequired,
  getProviderViewInvoiceQueue: PropTypes.func.isRequired,
  getProviderViewPaymentMethods: PropTypes.func.isRequired,
  getProviderViewInvoices: PropTypes.func.isRequired,
  getProviderViewOrderProducts: PropTypes.func.isRequired,
  getProviderViewRatingsEmitted: PropTypes.func.isRequired,
  getProviderViewRatingsReceived: PropTypes.func.isRequired,
  getOrderProductsMetadata: PropTypes.func.isRequired,
  getInvoicerId: PropTypes.func.isRequired,
  getPayerId: PropTypes.func.isRequired,
  getProviderHash: PropTypes.func.isRequired,
  history: PropTypes.shape().isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  }).isRequired,
  provider: PropTypes.shape({
    addresses: PropTypes.shape(),
    data: PropTypes.shape(),
    devices: PropTypes.shape(),
    error: PropTypes.string,
    isLoading: PropTypes.bool,
    invoices: PropTypes.shape(),
    invoice_queue: PropTypes.shape(),
    isModeCompany: PropTypes.bool,
    payment_methods: PropTypes.shape(),
    order_products: PropTypes.shape(),
    ratings: PropTypes.shape()
  }).isRequired,
  sub_routes: PropTypes.shape(),
  toggleProviderViewMode: PropTypes.func.isRequired
};

export default ProviderView;
