import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Breadcrumbs, IconButton, Toolbar, Typography } from '@material-ui/core';
import {
  InvertColors as InvertColorsIcon,
  InvertColorsOff as InvertColorsOffIcon,
  NavigateNext as NavigateNextIcon
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  grow: {
    flexGrow: 1
  },
  breadcrumb: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  }
}));

function ProvidersMapViewBar(props) {
  const { colorInvert, handleChangeStyle, parentUri, routeUri } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar elevation={0} position="static" color="default">
        <Toolbar>
          <Breadcrumbs
            maxItems={5}
            separator={<NavigateNextIcon fontSize="small" />}
            arial-label="List view breadcrumb"
            className={classes.breadcrumb}>
            <Typography variant="body2" color="textPrimary">
              {parentUri}
            </Typography>
            {routeUri.length &&
              routeUri.split('/').map((n, i) => {
                return (
                  <Typography variant="body2" color="textPrimary" key={i}>
                    {n}
                  </Typography>
                );
              })}
          </Breadcrumbs>
          <div className={classes.grow} />
          <IconButton onClick={handleChangeStyle} color="inherit" className={classes.backIcon}>
            {colorInvert ? (
              <InvertColorsOffIcon fontSize="small" />
            ) : (
              <InvertColorsIcon fontSize="small" />
            )}
          </IconButton>
        </Toolbar>
      </AppBar>
    </div>
  );
}

ProvidersMapViewBar.propTypes = {
  colorInvert: PropTypes.bool,
  handleChangeStyle: PropTypes.func.isRequired,
  parentUri: PropTypes.string.isRequired,
  routeUri: PropTypes.string.isRequired
};

export default ProvidersMapViewBar;
