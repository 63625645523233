export const getOrderProductsOverdueMetadata = () => ({
  type: 'GET_ORDER_PRODUCTS_OVERDUE_METADATA'
});

export const getOrderProductsOverdueMetadataSuccess = (data) => ({
  type: 'GET_ORDER_PRODUCTS_OVERDUE_METADATA_SUCCESS',
  data
});

export const getOrderProductsOverdueMetadataError = (isError) => ({
  type: 'GET_ORDER_PRODUCTS_OVERDUE_METADATA_ERROR',
  isError
});
