import store from '../../redux/store';

import {
  storeMediaType,
  removeMediaTypes,
  storeMediaTypeMedia,
  removeMediaTypeMedia,
  removeMediaTypeMedias,
  storeMediaTypeProperty
} from '../../redux/actions/medias/mediasActions';

/**
 * Set a mediaType in redux
 */
export const setMediaType = (mediaType) => {
  if (mediaType) store.dispatch(storeMediaType(mediaType));
};

/**
 * Remove into redux all mediaTypes
 */
export const unsetMediaTypes = () => {
  store.dispatch(removeMediaTypes());
};

/**
 * Add a media in a mediaType in redux
 */
export const setMediaTypeMedia = (media, mediaTypeShortcode) => {
  if (media && mediaTypeShortcode) store.dispatch(storeMediaTypeMedia(media, mediaTypeShortcode));
};

/**
 * Get all medias for a mediaType from redux for a given shortcode
 */
export const getMediaTypeMedias = (mediaTypeShortcode) => {
  const state = store.getState();
  const medias = state.medias.orderProductMedias[mediaTypeShortcode].mediasBucket;
  return medias;
};

/**
 * Get all mediaTypes from redux
 */
export const getOrderProductMedias = () => {
  const state = store.getState();
  const medias = state.orderProductMedias;
  return medias;
};

/**
 * Remove a media from a mediaType in redux
 */
export const unsetMediaTypeMedia = (mediaUrl, mediaTypeShortcode) => {
  if (mediaUrl && mediaTypeShortcode)
    store.dispatch(removeMediaTypeMedia(mediaUrl, mediaTypeShortcode));
};

/**
 * Set a property media type to a mediaType in redux
 */
export const setMediaTypeProperty = (
  mediaUrl,
  mediaTypeShortcode,
  mediaProperty,
  mediaPropertyValue
) => {
  if (mediaUrl && mediaTypeShortcode && mediaProperty && mediaPropertyValue)
    store.dispatch(
      storeMediaTypeProperty(mediaUrl, mediaTypeShortcode, mediaProperty, mediaPropertyValue)
    );
};

/**
 * Reset all medias in a mediaType in redux
 */
export const resetMediaTypeMedias = (mediaTypeShortcode) => {
  if (mediaTypeShortcode) store.dispatch(removeMediaTypeMedias(mediaTypeShortcode));
};
