import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import PaymentIcon from '@material-ui/icons/Payment';
import ReceiptIcon from '@material-ui/icons/Receipt';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Typography from '@material-ui/core/Typography';

import Addresses from './Addresses';
import Invoices from './Invoices';
import InvoicesEmitted from './InvoicesEmitted';
import PaymentMethods from './PaymentMethods';
import translation from '../../../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    minHeight: 60,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.cardHeader
  },
  cardContent: {
    overflow: 'auto',
    minHeight: 420,
    padding: theme.spacing(1),
    '&:last-child': {
      paddingBottom: 0
    },
    [theme.breakpoints.down('md')]: {
      minHeight: 'auto'
    }
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(1)
  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2)
  },
  listItemBox: {
    minWidth: 35
  },
  listItem: {
    padding: 8
  },
  linksContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    minHeight: 50,
    marginBottom: 8
  },
  linkWrapper: {
    margin: theme.spacing(0, 1),
    '&:first-child': {
      marginRight: 24
    }
  },
  linkLoader: {
    display: 'flex',
    alignItems: 'center',
    padding: 0
  },
  loaderText: {
    marginLeft: 8
  }
}));

function UserInvoices(props) {
  const {
    addresses,
    cleanUserViewAddressesReducer,
    cleanUserViewInvoicesReducer,
    cleanUserViewInvoicesEmittedReducer,
    cleanUserViewPaymentMethodReducer,
    getUserViewAddresses,
    getUserViewInvoices,
    getUserViewInvoicesEmitted,
    getUserViewPaymentMethods,
    getInvoicerId,
    getPayerId,
    paymentMethods,
    invoicesEmitted,
    invoices,
    user
  } = props;

  const classes = useStyles();

  const [isPayerLoading, setPayerLoading] = useState(false);
  const [isInvoicerLoading, setInvoicerLoading] = useState(false);
  const [payerLink, setPayerLink] = useState(false);
  const [invoicerLink, setInvoicerLink] = useState(false);

  useEffect(() => {
    /**
     * Get the payer and the invoicer for links
     */
    loadPayer();
    loadInvoicer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function loadPayer() {
    setPayerLoading(true);

    getPayerId(
      user.id,
      (success) => {
        setPayerLoading(false);
        if (success && success[0]) {
          setPayerLink(`/payers/${success[0].id}/update`);
        }
      },
      () => {
        setPayerLoading(false);
      }
    );
  }

  function loadInvoicer() {
    setInvoicerLoading(true);

    getInvoicerId(
      user.id,
      (success) => {
        setInvoicerLoading(false);
        if (success && success[0]) {
          setInvoicerLink(`/invoicers/${success[0].id}/update`);
        }
      },
      () => {
        setInvoicerLoading(false);
      }
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.linksContainer}>
        <div className={classes.linkWrapper}>
          {isPayerLoading && (
            <div className={classNames('loader-wpr', classes.linkLoader)}>
              <CircularProgress color="primary" size={25} />
              <p className={classes.loaderText}>
                {translation().views.user_view.payer_bridge.payer_loading}
              </p>
            </div>
          )}
          {payerLink && (
            <div>
              <Typography component="span" variant="body2">
                {translation().views.user_view.payer_bridge.label}:{' '}
              </Typography>
              <Link href={payerLink} target="_blank">
                {payerLink}
              </Link>
            </div>
          )}
        </div>
        <div className={classes.linkWrapper}>
          {isInvoicerLoading && (
            <div className={classNames('loader-wpr', classes.linkLoader)}>
              <CircularProgress color="primary" size={25} />
              <p className={classes.loaderText}>
                {translation().views.user_view.invoicer_bridge.invoicer_loading}
              </p>
            </div>
          )}
          {invoicerLink && (
            <div>
              <Typography component="span" variant="body2">
                {translation().views.user_view.invoicer_bridge.label}:{' '}
              </Typography>
              <Link href={invoicerLink} target="_blank">
                {invoicerLink}
              </Link>
            </div>
          )}
        </div>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Card className={classes.paper}>
            <CardHeader
              className={classes.cardHeader}
              title={translation().views.user_view.invoices.label}
              titleTypographyProps={{
                variant: 'h5',
                style: {
                  fontSize: 21
                }
              }}
              avatar={
                <Avatar>
                  <ReceiptIcon />
                </Avatar>
              }
              action={
                <IconButton
                  aria-label="See all invoices"
                  href={`/users/${user.id}/invoices`}
                  target="_blank">
                  <OpenInNewIcon fontSize="small" />
                </IconButton>
              }
            />
            <CardContent className={classes.cardContent}>
              <Invoices
                cleanUserViewInvoicesReducer={cleanUserViewInvoicesReducer}
                getUserViewInvoices={getUserViewInvoices}
                invoices={invoices}
                userId={user.id}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Card className={classes.paper}>
            <CardHeader
              className={classes.cardHeader}
              title={translation().views.user_view.invoices_emitted.label}
              titleTypographyProps={{
                variant: 'h5',
                style: {
                  fontSize: 21
                }
              }}
              avatar={
                <Avatar>
                  <ReceiptIcon />
                </Avatar>
              }
              action={
                <IconButton
                  aria-label="See all invoices emitted"
                  href={`/users/${user.id}/invoices-emitted`}
                  target="_blank">
                  <OpenInNewIcon fontSize="small" />
                </IconButton>
              }
            />
            <CardContent className={classes.cardContent}>
              <InvoicesEmitted
                cleanUserViewInvoicesEmittedReducer={cleanUserViewInvoicesEmittedReducer}
                getUserViewInvoicesEmitted={getUserViewInvoicesEmitted}
                invoicesEmitted={invoicesEmitted}
                userId={user.id}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Card className={classes.paper}>
            <CardHeader
              className={classes.cardHeader}
              title={translation().views.user_view.addresses.label}
              titleTypographyProps={{
                variant: 'h5',
                style: {
                  fontSize: 21
                }
              }}
              avatar={
                <Avatar>
                  <HomeWorkIcon />
                </Avatar>
              }
            />
            <CardContent className={classes.cardContent}>
              <Addresses
                addresses={addresses}
                cleanUserViewAddressesReducer={cleanUserViewAddressesReducer}
                getUserViewAddresses={getUserViewAddresses}
                userId={user.id}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Card className={classes.paper}>
            <CardHeader
              className={classes.cardHeader}
              title={translation().views.user_view.payment_methods.label}
              titleTypographyProps={{
                variant: 'h5',
                style: {
                  fontSize: 21
                }
              }}
              avatar={
                <Avatar>
                  <PaymentIcon />
                </Avatar>
              }
              action={
                <IconButton
                  aria-label="See all payment methods"
                  href={`/users/${user.id}/payment-methods`}
                  target="_blank">
                  <OpenInNewIcon fontSize="small" />
                </IconButton>
              }
            />
            <CardContent className={classes.cardContent}>
              <PaymentMethods
                userId={user.id}
                getUserViewPaymentMethods={getUserViewPaymentMethods}
                paymentMethods={paymentMethods}
                cleanUserViewPaymentMethodReducer={cleanUserViewPaymentMethodReducer}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

UserInvoices.propTypes = {
  cleanUserViewAddressesReducer: PropTypes.func.isRequired,
  cleanUserViewInvoicesReducer: PropTypes.func.isRequired,
  cleanUserViewInvoicesEmittedReducer: PropTypes.func.isRequired,
  cleanUserViewPaymentMethodReducer: PropTypes.func.isRequired,
  getUserViewAddresses: PropTypes.func.isRequired,
  getUserViewInvoices: PropTypes.func.isRequired,
  getUserViewInvoicesEmitted: PropTypes.func.isRequired,
  getUserViewPaymentMethods: PropTypes.func.isRequired,
  getInvoicerId: PropTypes.func.isRequired,
  getPayerId: PropTypes.func.isRequired,
  addresses: PropTypes.shape({
    isLoading: PropTypes.bool
  }).isRequired,
  invoices: PropTypes.shape({
    isLoading: PropTypes.bool
  }).isRequired,
  invoicesEmitted: PropTypes.shape({
    isLoading: PropTypes.bool
  }).isRequired,
  paymentMethods: PropTypes.shape({
    isLoading: PropTypes.bool
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
  }).isRequired
};

export default UserInvoices;
