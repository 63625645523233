import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Tooltip from '@material-ui/core/Tooltip';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import { isNavigationBackPossible, navigateBack } from '../../../../../utils/functions/navigation';
import translation from '../../../../../translation/translation';

const useStyles = makeStyles(() => ({
  actionsWpr: {
    display: 'flex'
  },
  created: {
    marginLeft: 4
  },
  root: {
    width: '100%'
  },
  grow: {
    flexGrow: 1
  },
  backIcon: {
    padding: 10
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  }
}));

function ScheduleViewBar(props) {
  const { isLoading, history, parentUri, resourceId, routeUri } = props;

  const classes = useStyles();

  function handleHistoryBack() {
    navigateBack(history);
  }

  return (
    <div className={classes.root}>
      <AppBar elevation={0} position="static" color="default">
        <Toolbar>
          <Breadcrumbs
            maxItems={5}
            separator={<NavigateNextIcon fontSize="small" />}
            arial-label="Provider cal breadcrumb">
            <Typography variant="body2" color="textPrimary">
              {parentUri}
            </Typography>
            <Typography variant="body2" color="textPrimary">
              {resourceId}
            </Typography>
            <Typography variant="body2" color="textPrimary">
              {routeUri}
            </Typography>
          </Breadcrumbs>
          <div className={classes.grow} />
          <div className={classes.actionsWpr}>
            <Tooltip
              title={translation().actions.custom_list_actions.provider_view}
              aria-label={translation().actions.custom_list_actions.provider_view}
              enterDelay={700}>
              <div>
                <Link to={`/providers/${resourceId}/provider-view`}>
                  <IconButton
                    aria-label="To provider view"
                    className={classes.backIcon}
                    disabled={isLoading}>
                    <ViewQuiltIcon fontSize="small" />
                  </IconButton>
                </Link>
              </div>
            </Tooltip>
            <Tooltip
              title={translation().actions.update || 'Update'}
              aria-label={translation().actions.update || 'Update'}
              enterDelay={700}>
              <div>
                <Link to={`/providers/${resourceId}/update`}>
                  <IconButton
                    aria-label="To update"
                    className={classes.backIcon}
                    disabled={isLoading}>
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Link>
              </div>
            </Tooltip>
            {isNavigationBackPossible(history) && (
              <Tooltip
                title={translation().actions.back || 'Back'}
                aria-label={translation().actions.back || 'Back'}
                enterDelay={700}>
                <div>
                  <IconButton
                    color="inherit"
                    aria-label="Go back"
                    onClick={handleHistoryBack}
                    className={classes.backIcon}
                    disabled={isLoading}>
                    <ArrowBackIcon fontSize="small" />
                  </IconButton>
                </div>
              </Tooltip>
            )}
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

ScheduleViewBar.defaultProps = {
  parentUri: 'providers',
  routeUri: 'cal'
};

ScheduleViewBar.propTypes = {
  isLoading: PropTypes.bool,
  history: PropTypes.shape().isRequired,
  parentUri: PropTypes.string.isRequired,
  resourceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  routeUri: PropTypes.string.isRequired
};

export default ScheduleViewBar;
