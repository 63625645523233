import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import {
  AppBar,
  Breadcrumbs,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle as MuiDialogTitle,
  Fab,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Popover,
  Toolbar,
  Tooltip,
  Typography
} from '@material-ui/core';
import {
  Add as AddIcon,
  ArrowBack as ArrowBackIcon,
  Close as CloseIcon,
  ExpandMore as ExpandMoreIcon,
  ImportExport as ImportExportIcon,
  FilterList as FilterListIcon,
  NavigateNext as NavigateNextIcon,
  NotInterested as NotInterestedIcon,
  MoreVert as MoreIcon,
  Refresh as RefreshIcon,
  Search as SearchIcon,
  VisibilityOffOutlined as VisibilityOffOutlinedIcon,
  ViewQuilt as ViewQuiltIcon
} from '@material-ui/icons';

import { addThreeDots } from '../../../../../utils/functions/utils';
import ViewFields from '../forms/fields/ViewFields';
import ViewUsers from '../users/ViewUsers';
import ViewTypeForm from '../forms/type/ViewTypeForm';
import ViewSortForm from '../forms/sort/ViewSortForm';
import FiltersContainer from '../../../../../containers/filters/FiltersContainer';
import translation from '../../../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  avatar: {
    marginRight: 2
  },
  avatars: {
    display: 'flex',
    flexDirection: 'row'
  },
  grow: {
    flexGrow: 1
  },
  toolbarExtanded: {
    background: theme.palette.background.paper,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: 12,
      paddingTop: 12,
      paddingBottom: 12
    }
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  createFab: {
    color: 'white',
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: fade(theme.palette.success.main, 0.55)
    },
    marginRight: 8
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.35),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.55)
    },
    marginLeft: 5,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(5),
      width: 'auto'
    }
  },
  searchIcon: {
    width: theme.spacing(6),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit',
    width: '100%'
  },
  inputInput: {
    fontSize: 14,
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(6),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200
      }
    }
  },
  queryButtons: {
    marginRight: 8,
    borderColor: theme.palette.type === 'dark' ? fade('#FFFFFF', 0.55) : 'rgba(0, 0, 0, 0.23)',
    fontWeight: 400,
    textTransform: 'inherit',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 8
    }
  },
  expandIcon: {
    transition: '200ms ease-in',
    '&.expanded': {
      transform: 'rotate(-180deg)'
    }
  },
  popover: {
    padding: theme.spacing(2),
    maxWidth: 250
  }
}));

const dialogStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(dialogStyles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function TaskViewBar(props) {
  const classes = useStyles();

  const {
    expanded,
    history,
    loading,
    onChangeFields,
    onChangeSearch,
    onChangeSort,
    onChangeViewType,
    onClearFilters,
    onClickCreate,
    onExpand,
    onFilter,
    onLinkuser,
    onRefresh,
    onRemoveUserTaskView,
    onSearch,
    onUpdateUserTaskViewLevel,
    search,
    userView,
    viewMetadata,
    viewProps
  } = props;

  const [anchorFields, setAnchorFields] = useState(null);
  const [anchorSorting, setAnchorSorting] = useState(null);
  const [anchorViewType, setAnchorViewType] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const [filtersOpen, setFiltersOpen] = useState(false);
  const [sort_order, setSortOrder] = useState('asc');

  const fieldsOpen = Boolean(anchorFields);
  const sortingOpen = Boolean(anchorSorting);
  const viewTypeOpen = Boolean(anchorViewType);
  const mobileMenuOpen = Boolean(mobileMoreAnchorEl);

  useEffect(() => {
    decomposeSortOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewProps]);

  function decomposeSortOrder() {
    if (viewProps && viewProps.sort && viewProps.sort.length > 0) {
      if (viewProps.sort.substring(0, 1) === '-') {
        setSortOrder('desc');
      } else {
        setSortOrder('asc');
      }
    }
  }

  function decomposeSort(sort) {
    if (sort && sort.length) {
      if (sort.substring(0, 1) === '-') {
        return sort.substring(1);
      } else {
        return sort;
      }
    }
  }

  function handleToggleExpand() {
    onExpand();
  }

  function handleOpenSorting(event) {
    setAnchorSorting(event.currentTarget);
  }

  function handleOpenFilters() {
    setFiltersOpen(true);
  }

  function handleCloseFilters() {
    setFiltersOpen(false);
  }

  function handleClearFilters() {
    handleCloseFilters();
    onClearFilters();
  }

  function handleCloseSorting() {
    setAnchorSorting(null);
  }

  function handleOpenFields(event) {
    setAnchorFields(event.currentTarget);
  }

  function handleCloseFields() {
    setAnchorFields(null);
  }

  function handleOpenViewType(event) {
    setAnchorViewType(event.currentTarget);
  }

  function handleCloseViewType() {
    setAnchorViewType(null);
  }

  function handleMobileMenuOpen(event) {
    setMobileMoreAnchorEl(event.currentTarget);
  }

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }

  function handleChangeSortOrder(order, sort) {
    if (!sort) return;

    if (order === 'desc') {
      onChangeSort(`-${sort}`);
    } else {
      onChangeSort(sort);
    }

    setSortOrder(order);
  }

  function handleChangeSort(property) {
    if (viewProps && viewProps.sort && viewProps.sort.substring(0, 1) === '-') {
      onChangeSort(`-${property}`);
    } else {
      onChangeSort(property);
    }
  }

  function handleChangeViewType(type) {
    onChangeViewType(type);
    handleCloseViewType();
  }

  function handleChangeSearch(e) {
    onChangeSearch(e);
  }

  function handleSubmitFields(fields) {
    handleCloseFields();
    onChangeFields(fields);
  }

  function handleRefresh() {
    onRefresh();
  }

  function handleHistoryBack() {
    if (history && history.push) {
      history.push('/personal-tasks');
    }
  }

  function handleSubmitSearch(e) {
    e.preventDefault();
    onSearch(e);
  }

  function handleSubmitFilter() {
    handleCloseFilters();
    onFilter();
  }

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={mobileMenuOpen}
      onClose={handleMobileMenuClose}>
      <MenuItem onClick={onClickCreate}>
        <AddIcon fontSize="small" style={{ marginRight: 8 }} />
        <p>{translation().actions.create}</p>
      </MenuItem>
      <MenuItem onClick={handleHistoryBack}>
        <ArrowBackIcon fontSize="small" style={{ marginRight: 8 }} />
        <p>{translation().actions.back}</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.root}>
      <AppBar elevation={0} position="static" color="default">
        <Toolbar>
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            <Breadcrumbs
              maxItems={5}
              separator={<NavigateNextIcon fontSize="small" />}
              arial-label="My tasks view breadcrumb">
              <Typography variant="body2" color="textPrimary">
                {viewProps ? addThreeDots(viewProps.name, 24) : false}
              </Typography>
            </Breadcrumbs>
          )}
          <form onSubmit={handleSubmitSearch}>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                disabled={loading}
                type="text"
                value={search}
                onChange={handleChangeSearch}
                placeholder={`${translation().actions.search}...`}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput
                }}
              />
            </div>
          </form>
          <IconButton
            aria-label="Refresh moderation"
            disabled={loading}
            style={{ padding: 5, marginLeft: 7 }}
            onClick={handleRefresh}>
            <RefreshIcon />
          </IconButton>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <Tooltip
              title={translation().actions.create || 'create'}
              aria-label={translation().actions.create || 'create'}
              enterDelay={700}>
              <div>
                <Fab
                  size="small"
                  color="default"
                  disabled={loading}
                  onClick={onClickCreate}
                  className={classes.createFab}>
                  <AddIcon />
                </Fab>
              </div>
            </Tooltip>
            <Tooltip
              title={translation().actions.back || 'Back'}
              aria-label={translation().actions.back || 'Back'}
              enterDelay={700}>
              <div>
                <IconButton
                  color="default"
                  aria-label="Back to orders"
                  onClick={handleHistoryBack}
                  className={classes.backIcon}
                  disabled={loading}>
                  <ArrowBackIcon fontSize="small" />
                </IconButton>
              </div>
            </Tooltip>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              disabled={loading}
              color="inherit"
              style={{ marginLeft: 7 }}>
              <MoreIcon />
            </IconButton>
          </div>
          {renderMobileMenu}
          {userView &&
            userView.level &&
            (userView.level === 'admin' || userView.level === 'manager') && (
              <Tooltip
                title={translation().actions.expand}
                aria-label={translation().actions.expand}
                enterDelay={700}>
                <div>
                  <IconButton
                    onClick={handleToggleExpand}
                    aria-label="Expand toolbar"
                    color="default"
                    className={classNames(classes.expandIcon, { expanded })}
                    disabled={loading}>
                    <ExpandMoreIcon />
                  </IconButton>
                </div>
              </Tooltip>
            )}
        </Toolbar>
        <Collapse in={expanded}>
          <Toolbar className={classes.toolbarExtanded}>
            <Button
              startIcon={<VisibilityOffOutlinedIcon />}
              onClick={handleOpenFields}
              color="inherit"
              variant="outlined"
              className={classes.queryButtons}>
              {translation().views.task_views.tasks.fields.label}
            </Button>
            <Button
              startIcon={<ImportExportIcon />}
              onClick={handleOpenSorting}
              color="inherit"
              variant="outlined"
              className={classes.queryButtons}>
              {translation().views.task_views.tasks.sort.label}
            </Button>
            <Button
              startIcon={<FilterListIcon />}
              onClick={handleOpenFilters}
              color="inherit"
              variant="outlined"
              className={classes.queryButtons}>
              {translation().views.task_views.tasks.filters.label}
            </Button>
            <Button
              startIcon={<ViewQuiltIcon />}
              onClick={handleOpenViewType}
              color="inherit"
              variant="outlined"
              className={classes.queryButtons}>
              {translation().views.task_views.tasks.view_type.label}
            </Button>
            <div className={classes.grow} />
            {viewProps && viewProps.users && viewProps.users.length > 0 ? (
              <ViewUsers
                {...viewProps}
                onLinkuser={onLinkuser}
                onRemoveUserTaskView={onRemoveUserTaskView}
                onUpdateUserTaskViewLevel={onUpdateUserTaskViewLevel}
              />
            ) : (
              false
            )}
          </Toolbar>
        </Collapse>
      </AppBar>
      <Popover
        id={sortingOpen ? 'sorting-popover' : undefined}
        open={sortingOpen}
        anchorEl={anchorSorting}
        onClose={handleCloseSorting}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        PaperProps={{ className: classes.popover }}>
        {viewMetadata && viewMetadata.fields && viewMetadata.fields.length > 0 ? (
          <ViewSortForm
            fields={viewMetadata.fields}
            values={{ sort: decomposeSort(viewProps?.sort), sort_order }}
            onChangeSort={handleChangeSort}
            onChangeSortOrder={handleChangeSortOrder}
          />
        ) : (
          'Missing view metadata fields'
        )}
      </Popover>
      <Popover
        id={viewTypeOpen ? 'view-type-popover' : undefined}
        open={viewTypeOpen}
        anchorEl={anchorViewType}
        onClose={handleCloseViewType}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        PaperProps={{ className: classes.popover }}>
        {viewProps && viewProps.type ? (
          <ViewTypeForm value={viewProps.type} onChange={handleChangeViewType} />
        ) : (
          'Missing view type prop'
        )}
      </Popover>
      <Popover
        id={fieldsOpen ? 'fields-popover' : undefined}
        open={fieldsOpen}
        anchorEl={anchorFields}
        onClose={handleCloseFields}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
        {viewMetadata && viewMetadata.fields && viewMetadata.fields.length > 0 ? (
          <ViewFields
            fields={viewProps.fields}
            metadataFields={viewMetadata.fields}
            onSubmit={handleSubmitFields}
          />
        ) : (
          'Missing view field prop'
        )}
      </Popover>
      <Dialog
        open={filtersOpen}
        onClose={handleCloseFilters}
        aria-labelledby="filter-tasks-title"
        disableBackdropClick={loading}
        fullWidth
        maxWidth="lg">
        <DialogTitle id="filter-tasks-title" onClose={handleCloseFilters}>
          {translation().views.task_views.tasks.fields.title}
        </DialogTitle>
        <DialogContent>
          <FiltersContainer onSubmit={handleSubmitFilter} metadata={{ data: viewMetadata }} />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClearFilters}
            color="primary"
            variant="contained"
            startIcon={<NotInterestedIcon />}>
            {translation().views.task_views.tasks.filters.clear}
          </Button>
          <div className={classes.grow} />
          <Button onClick={handleCloseFilters}>{translation().actions.cancel}</Button>
          <Button onClick={handleSubmitFilter} color="secondary" variant="contained">
            {translation().actions.search}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

TaskViewBar.propTypes = {
  expanded: PropTypes.bool,
  history: PropTypes.shape(),
  loading: PropTypes.bool,
  onChangeSearch: PropTypes.func.isRequired,
  onChangeSort: PropTypes.func.isRequired,
  onChangeViewType: PropTypes.func.isRequired,
  onChangeFields: PropTypes.func.isRequired,
  onClearFilters: PropTypes.func.isRequired,
  onClickCreate: PropTypes.func.isRequired,
  onExpand: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
  onLinkuser: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onRemoveUserTaskView: PropTypes.func.isRequired,
  onUpdateUserTaskViewLevel: PropTypes.func.isRequired,
  search: PropTypes.string,
  userView: PropTypes.shape({
    level: PropTypes.oneOf(['admin', 'manager', 'editor', 'operator', 'viewer'])
  }),
  viewMetadata: PropTypes.shape({
    fields: PropTypes.arrayOf(PropTypes.shape()),
    name: PropTypes.string
  }),
  viewProps: PropTypes.shape({
    fields: PropTypes.string,
    filters: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    sort: PropTypes.string,
    type: PropTypes.string,
    users: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        level: PropTypes.oneOf(['admin', 'manager', 'editor', 'operator', 'viewer']),
        user: PropTypes.shape({
          id: PropTypes.number,
          first_name: PropTypes.string,
          last_name: PropTypes.string,
          email: PropTypes.string
        })
      })
    )
  })
};

export default TaskViewBar;
