export const getOrderProductsMetadata = () => ({
  type: 'GET_ORDER_PRODUCTS_METADATA'
});

export const getOrderProductsMetadataSuccess = (data) => ({
  type: 'GET_ORDER_PRODUCTS_METADATA_SUCCESS',
  data
});

export const getOrderProductsMetadataError = (isError) => ({
  type: 'GET_ORDER_PRODUCTS_METADATA_ERROR',
  isError
});
