import './pageNotFound.scss';

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import translation from '../../translation/translation';

function PageNotFound(props) {
  const {
    history: { push }
  } = props;

  useEffect(() => {
    document.title = 'Actarus | 404 page not found';
  }, []);

  function handleGoBack() {
    if (push) {
      push('/');
    }
  }

  return (
    <div className="Page-not-found">
      <Container>
        <img src="/assets/images/commons/page-not-found.svg" alt="Not Found" />
        <Typography variant="h3" gutterBottom>
          {translation().views.page_not_found.title} {translation().views.page_not_found.sub_title}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {translation().views.page_not_found.text}{' '}
          <span className="actarus-punchline">Actarus - Goldorak</span>
        </Typography>
        <Button variant="outlined" color="primary" onClick={handleGoBack}>
          {translation().views.page_not_found.button}
        </Button>
      </Container>
    </div>
  );
}

PageNotFound.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default withRouter(PageNotFound);
