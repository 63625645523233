/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';

import translation from '../../../../translation/translation';

const createMarkup = (encodedHtml) => ({
  __html: _.unescape(encodedHtml)
});

const useStyles = makeStyles(() => ({
  open: {
    position: 'absolute',
    top: 28,
    right: 0,
    zIndex: 2
  },
  content: {
    color: 'initial'
  },
  textInput: {
    paddingRight: 65,
    '&::-webkit-scrollbar-track': {
      background: grey[200]
    },
    '&::-webkit-scrollbar-thumb': {
      background: grey[400],
      borderRadius: 2
    },
    '&::-webkit-scrollbar': {
      width: 4
    }
  },
  iconButton: {
    padding: 6
  },
  root: {
    position: 'relative'
  }
}));

function HtmlTextField(props) {
  const {
    autoComplete,
    defaultValue,
    disabled,
    error,
    helperText,
    id,
    fullWidth,
    label,
    margin,
    name,
    style,
    rows,
    rowsMax,
    required,
    onChange,
    write,
    ...other
  } = props;

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(defaultValue || '');

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleChange(event) {
    if (event && onChange) {
      onChange(event.target.value);
      setValue(event.target.value);
    }
  }

  return (
    <div className={classes.root}>
      <TextField
        {...other}
        id={id}
        autoComplete={autoComplete}
        defaultValue={defaultValue}
        disabled={!write || disabled}
        error={error ? true : false}
        helperText={error || helperText}
        margin={margin}
        name={name}
        label={label}
        type="text"
        rows={rows}
        style={style}
        rowsMax={rowsMax}
        required={required}
        onChange={handleChange}
        fullWidth={fullWidth}
        multiline
        inputProps={{
          className: classes.textInput
        }}
      />
      <div className={classes.open} style={value ? { right: 8 } : {}}>
        <IconButton
          size="small"
          aria-label="Open"
          component="span"
          className={classes.iconButton}
          onClick={handleOpen}>
          <OpenInBrowserIcon fontSize="small" />
        </IconButton>
      </div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md" scroll="paper">
        <DialogContent className={classes.content}>
          <div dangerouslySetInnerHTML={createMarkup(value ? value : defaultValue)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{translation().actions.close}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

HtmlTextField.defaultProps = {
  fullWidth: true,
  margin: 'normal',
  multiline: false,
  style: {},
  rows: 1,
  rowsMax: 10,
  write: true
};

HtmlTextField.propTypes = {
  autoComplete: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fullWidth: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  margin: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  style: PropTypes.shape(),
  required: PropTypes.bool,
  rows: PropTypes.number,
  rowsMax: PropTypes.number,
  onChange: PropTypes.func,
  write: PropTypes.bool
};

export default HtmlTextField;
