import React, { useEffect, useState, PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from '@material-ui/core/MenuItem';
import TablePagination from '@material-ui/core/TablePagination';
import TuneIcon from '@material-ui/icons/Tune';
import Paper from '@material-ui/core/Paper';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';

import FormsContainer from '../../../../../containers/forms/FormsContainer';
import FixedHeaderTable from '../../../../../components/tables/fixedHeaderTable/FixedHeaderTable';
import Alert from '../../../../../components/alert/Alert';
import ListViewSpeedDial from '../../../list/components/ListViewSpeedDial';
import ListViewMobileMenu from '../../../list/components//ListViewMobileMenu';
import ProviderFactoryProductsContainer from '../../../../../containers/providers/factory/ProviderFactoryProductsContainer';
import { fetchApi } from '../../../../../utils/functions/api';
import {
  getUserTableStringWidth,
  getUserTableLinksDisplayMode
} from '../../../../../utils/functions/cookies';
import { dateInTz, createDateUtc, alterDate } from '../../../../../utils/functions/dates';
import { addThreeDots } from '../../../../../utils/functions/utils';
import { formatErrors, formatValuesBeforePost } from '../../../../../utils/functions/forms';
import { enqueueSnackbar } from '../../../../../redux/actions/appActions';
import { setFormsErrors, cleanFormsErrors } from '../../../../../redux/actions/forms/formsActions';
import config from '../../../../../config';
import translation from '../../../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  createButton: {
    color: 'white',
    backgroundColor: config.success_color,
    '&:hover': {
      backgroundColor: fade(config.success_color, 0.55)
    },
    marginTop: 28
  },
  configButton: {
    color: 'white',
    backgroundColor: config.config_color,
    '&:hover': {
      backgroundColor: fade(config.config_color, 0.55)
    },
    marginTop: 28,
    marginRight: 8
  },
  deleteButton: {
    color: config.error_color || '#F44336',
    '&:hover': {
      backgroundColor: fade(config.error_color || '#F44336', 0.15)
    }
  },
  updateLoadingText: {
    display: 'inline-block',
    verticalAlign: 'top',
    margin: '2px 10px'
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  submitWrapper: {
    textAlign: 'right',
    marginTop: theme.spacing(3)
  },
  menuItem: {
    paddingTop: 8,
    paddingBottom: 8
  },
  iconMenuItem: {
    fontSize: 18,
    marginRight: 8
  },
  iconButton: {
    marginRight: 4
  }
}));

const loaderWrapperStyle = {
  display: 'inline-block',
  width: 'auto',
  padding: 0,
  verticalAlign: 'middle',
  margin: '0 10px'
};

class CustomLink extends PureComponent {
  render() {
    return (
      <Link to={this.props.id} {...this.props}>
        {this.props.children}
      </Link>
    );
  }
}

CustomLink.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.any
};

class CustomHref extends PureComponent {
  render() {
    return <a {...this.props}>{this.props.children}</a>;
  }
}

CustomHref.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired
};

class CustomDialogButton extends PureComponent {
  handleClick = () => {
    const {
      customprops: { data, onOpenCustomActionDialog, route }
    } = this.props;

    onOpenCustomActionDialog(route, data);
  };

  render() {
    return (
      <div {...this.props} onClick={this.handleClick}>
        {this.props.children}
      </div>
    );
  }
}

CustomDialogButton.propTypes = {
  children: PropTypes.any.isRequired,
  customprops: PropTypes.shape().isRequired
};

const mapDispatchToProps = (dispatch) => {
  return {
    _enqueueSnackbar(queue) {
      dispatch(enqueueSnackbar(queue));
    },
    _setFormsErrors(formName, errors) {
      dispatch(setFormsErrors(formName, errors));
    },
    _cleanFormsErrors(formName) {
      dispatch(cleanFormsErrors(formName));
    }
  };
};

function UpdateViewCollection(props) {
  const {
    columns,
    createButtonStyle,
    configButtonStyle,
    isCreatable,
    isDeletable,
    isUpdatable,
    links,
    locale,
    subRoutes,
    subtype,
    tabValue,
    tabIndex,
    timezone,
    routeUri,
    resourceId,
    PaperProps,
    _cleanFormsErrors,
    _enqueueSnackbar,
    _setFormsErrors
  } = props;

  const classes = useStyles();
  const [isNoLink, setNoLink] = useState(false);
  const [isCreateOpen, setCreateOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isProviderProductsOpen, setProviderProductsOpen] = useState(false);
  const [customDialog, setCustomDialog] = useState({
    opened: false,
    data: null
  });
  const [updateItemOpen, setItemUpdateOpen] = useState(false);
  const [page, setPage] = useState(0);
  const rowsPerPage = 10;
  const [createState, setCreateState] = useState({
    isLoading: false,
    isError: false
  });
  const [listState, setListState] = useState({
    uri: null,
    list: [],
    isLoading: false,
    isPageLoading: false,
    isError: false,
    paging: null
  });
  const [metadataState, setMetadataState] = useState({
    data: {},
    isLoading: false,
    isError: false
  });
  const [deleteItemState, setDeleteItemState] = useState({
    isLoading: false,
    isError: false
  });
  const [updateItemState, setUpdateItemState] = useState({
    isLoading: false,
    isError: false
  });

  function getList(uri) {
    setListState({
      list: [],
      isLoading: true,
      isError: false,
      paging: null
    });

    setPage(0);

    const query = {};

    if (locale) query.locale = locale;

    fetchApi(
      'get',
      uri.replace(/{([^}]*)}/g, resourceId),
      query,
      null,
      null,
      (list, paging) => {
        setListState({
          uri: uri.replace(/{([^}]*)}/g, resourceId).replace(config.api_url, ''),
          list: list,
          isLoading: false,
          isError: false,
          paging: paging
        });
      },
      (error) => {
        setListState({
          ...listState,
          isLoading: false,
          isError:
            error &&
            error.response &&
            error.response.data &&
            error.response.data.status_code &&
            error.response.data.detail
              ? `${error.response.data.status_code}: ${error.response.data.detail}`
              : error.response.data.detail
              ? error.response.data.detail
              : translation().core.list.error_list
        });
      }
    );
  }

  function getNextList(next) {
    setListState({
      ...listState,
      isPageLoading: true
    });

    fetchApi(
      'get',
      next,
      null,
      null,
      null,
      (list, paging) => {
        setListState({
          ...listState,
          list: [...listState.list, ...list],
          isPageLoading: false,
          isError: false,
          paging: paging
        });
      },
      (error) => {
        setListState({
          ...listState,
          isPageLoading: false,
          isError:
            error &&
            error.response &&
            error.response.data &&
            error.response.data.status_code &&
            error.response.data.detail
              ? `${error.response.data.status_code}: ${error.response.data.detail}`
              : error.response.data.detail
              ? error.response.data.detail
              : translation().core.list.error_list
        });
      }
    );
  }

  useEffect(() => {
    if (tabValue === tabIndex) {
      if (links && links.length > 0) {
        for (let i = 0; i < links.length; i++) {
          if (links[i].href && links[i].rel && links[i].rel === 'list') {
            getList(links[i].href);
          }

          if (links[i].href && links[i].rel && links[i].rel === 'metadata') {
            setMetadataState({
              data: {},
              isLoading: true,
              isError: false
            });

            fetchApi(
              'get',
              links[i].href.replace(/{([^}]*)}/g, 1),
              null,
              null,
              null,
              (metadata) => {
                setMetadataState({
                  data: metadata,
                  isLoading: false,
                  isError: false
                });
              },
              () => {
                setMetadataState({
                  ...metadataState,
                  isLoading: false,
                  isError: true
                });
              }
            );
          }
        }
      } else {
        setNoLink(true);
      }
    }

    return () => {
      setPage(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabValue]);

  function getItemInList(id) {
    if (!id || !listState.list) return;

    let item = null;

    if (listState.list.length > 0) {
      for (let i = 0; i < listState.list.length; i++) {
        if (listState.list[i].id && listState.list[i].id === id) item = listState.list[i];
      }
    }

    return item;
  }

  function handleScrollList() {
    return 0;
  }

  function handleOpenCreate() {
    _cleanFormsErrors('createSubResourceForm');
    setCreateOpen(true);
  }

  function handleCloseCreate() {
    setCreateOpen(false);
  }

  function handleCreate(values) {
    if (!listState.uri) return;

    const dataFormatted = formatValuesBeforePost(
      metadataState.data && metadataState.data.fields ? metadataState.data.fields : [],
      values
    );

    _cleanFormsErrors('createSubResourceForm');

    setCreateState({
      isLoading: true,
      isError: false
    });

    const query = {};

    if (locale) query.locale = locale;

    fetchApi(
      'post',
      listState.uri,
      query,
      dataFormatted,
      null,
      () => {
        setCreateState({
          isLoading: false,
          isError: false
        });

        handleCloseCreate();
        getList(listState.uri);

        _enqueueSnackbar({
          message: translation().core.create.callbacks.success,
          options: {
            variant: 'success'
          }
        });
      },
      (error) => {
        if (error.response) {
          if (
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.length > 0
          ) {
            _setFormsErrors('createSubResourceForm', formatErrors(error.response.data.errors));
          }
          _enqueueSnackbar({
            message:
              error.response.data && error.response.data.detail
                ? error.response.data.detail
                : translation().core.create.callbacks.error,
            status: error.response.status || undefined,
            options: {
              variant: 'error'
            }
          });
        } else {
          _enqueueSnackbar({
            message: translation().core.create.callbacks.error,
            options: {
              variant: 'error'
            }
          });
        }

        setCreateState({
          isLoading: false,
          isError: true
        });
      }
    );
  }

  function handleOpenDelete(id) {
    setIsDeleteOpen(id);
  }

  function handleOpenProviderProducts() {
    setProviderProductsOpen(true);
  }

  function handleCloseProviderProducts() {
    setProviderProductsOpen(false);
    getList(listState.uri);
  }

  function handleCloseDelete() {
    setIsDeleteOpen(false);
  }

  function handleOpenUpdate(id) {
    const item = getItemInList(id);
    setItemUpdateOpen(item);
  }

  function handleCloseUpdate() {
    setItemUpdateOpen(false);
  }

  function handleUpdate(values) {
    if (!listState.uri || !updateItemOpen.id) return;

    setUpdateItemState({
      isLoading: true,
      isError: false
    });
    _cleanFormsErrors('updateSubResourceForm');

    const dataFormatted = formatValuesBeforePost(
      metadataState.data && metadataState.data.fields ? metadataState.data.fields : [],
      values
    );

    const query = {};

    if (locale) query.locale = locale;

    fetchApi(
      'put',
      `${listState.uri}/${updateItemOpen.id}`,
      query,
      dataFormatted,
      null,
      () => {
        setUpdateItemState({
          isLoading: false,
          isError: false
        });

        handleCloseUpdate();
        getList(listState.uri);

        _enqueueSnackbar({
          message: translation().core.update.callbacks.success,
          options: {
            variant: 'success'
          }
        });
      },
      (error) => {
        setUpdateItemState({
          isLoading: false,
          isError: true
        });

        if (error.response) {
          if (
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors.length > 0
          ) {
            _setFormsErrors('updateSubResourceForm', formatErrors(error.response.data.errors));
          }

          _enqueueSnackbar({
            message:
              error.response.data && error.response.data.detail
                ? error.response.data.detail
                : translation().core.update.callbacks.error,
            status: error.response.status || undefined,
            options: {
              variant: 'error'
            }
          });
        } else {
          _enqueueSnackbar({
            message: translation().core.update.callbacks.error,
            options: {
              variant: 'error'
            }
          });
        }
      }
    );
  }

  function handleDeleteResourceItem() {
    if (!isDeleteOpen) return;

    setDeleteItemState({
      isLoading: true,
      isError: false
    });

    fetchApi(
      'delete',
      `${listState.uri}/${isDeleteOpen}`,
      null,
      null,
      null,
      () => {
        setDeleteItemState({
          isLoading: false,
          isError: false
        });

        handleCloseDelete();
        getList(listState.uri);
      },
      (error) => {
        if (error.response) {
          _enqueueSnackbar({
            message:
              error.response.data && error.response.data.detail
                ? error.response.data.detail
                : translation().core.list.callbacks.delete.error,
            status: error.response.status || undefined,
            options: {
              variant: 'error'
            }
          });
        } else {
          _enqueueSnackbar({
            message: translation().core.list.callbacks.delete.error,
            options: {
              variant: 'error'
            }
          });
        }

        setDeleteItemState({
          isLoading: false,
          isError: true
        });
      }
    );
  }

  function handleOpenCustomActionDialog(route, data) {
    setCustomDialog({ opened: route, data });
  }

  function handleCloseCustomActionDialog() {
    setCustomDialog({ opened: false, data: null });
  }

  function handleChangePagination(event, value) {
    setPage(value);

    const { isLoading, paging } = listState;

    if (value >= Math.ceil(listState.list.length / rowsPerPage) - 1) {
      if (!isLoading && paging && paging.next) {
        getNextList(paging.next);
      }
    }
  }

  function handleReload() {
    getList(listState.uri);
  }

  function getTableBodyFromData(data, columns = 7) {
    const table_body = [];
    let temp_body = null;

    for (let line in data) {
      if (Array.isArray(columns)) {
        temp_body = [];

        for (let i = 0; i < columns.length; i++) {
          for (let key in data[line]) {
            if (data[line][columns[i].name]) {
              if (key === columns[i].name) {
                if (key === 'user') {
                  let name = '';

                  if (data[line][key] && data[line][key].first_name && data[line][key].last_name) {
                    name = data[line][key].first_name + ' ' + data[line][key].last_name;
                  } else if (data[line][key] && data[line][key].last_name) {
                    name = '[Missing first name] ' + data[line][key].last_name;
                  } else {
                    name = '[Missing user object]';
                  }

                  if (data[line]['company']) {
                    name += ' (' + data[line]['company'].commercial_name + ')';
                  }

                  temp_body[key] = name;
                } else {
                  temp_body[columns[i].name] = data[line][key];
                }
              }
            } else if (typeof data[line][columns[i].name] === 'boolean') {
              temp_body[columns[i].name] = data[line][columns[i].name];
            } else if (columns[i].name.indexOf('.') > -1) {
              const tmpArray = columns[i].name.split('.');

              if (data[line][tmpArray[0]] && data[line][tmpArray[0]][tmpArray[1]]) {
                temp_body[columns[i].name] = data[line][tmpArray[0]][tmpArray[1]];
              } else {
                temp_body[columns[i].name] = '-';
              }
            } else {
              temp_body[columns[i].name] = '-';
            }
          }
        }

        table_body.push(temp_body);
      } else if (typeof columns === 'number') {
        temp_body = [];

        let counter = 0;

        for (let key in data[line]) {
          if (counter >= columns) {
            break;
          }
          if (key === 'user') {
            let name = '';

            if (data[line][key] && data[line][key].first_name && data[line][key].last_name) {
              name = data[line][key].first_name + ' ' + data[line][key].last_name;
            } else if (data[line][key] && data[line][key].last_name) {
              name = '[Missing first name] ' + data[line][key].last_name;
            } else {
              name = '[Missing user object]';
            }

            if (data[line]['company']) {
              name += ' (' + data[line]['company'].commercial_name + ')';
            }

            temp_body[key] = name;
          } else {
            temp_body[key] = addThreeDots(
              data[line][key],
              parseInt(getUserTableStringWidth(), 10) || 150
            );
          }

          counter++;
        }

        table_body.push(temp_body);
      }
    }

    return table_body;
  }

  function checkIfNextIconPageButtonDisabled() {
    if (listState.list.length !== 1) {
      if (page >= Math.ceil(listState.list.length / rowsPerPage) - 1) {
        return true;
      } else if (listState.isPageLoading) {
        return true;
      } else if (
        page <= Math.ceil(listState.list.length / rowsPerPage) - 1 &&
        listState.paging &&
        !listState.paging.next &&
        listState.paging.prev
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  function renderTable(data, columns = 7) {
    if (data && data.length) {
      let isActions = false;
      const tableBody = getTableBodyFromData(data, columns);
      const tableValues = tableBody.map((row, index) => {
        let result = [];

        Object.keys(row).forEach((key) => {
          if (Array.isArray(row[key])) {
            result.push(
              <TableCell key={key}>
                <Typography variant="body2">[Array]</Typography>
              </TableCell>
            );
          } else if (typeof row[key] === 'object' && row[key] !== null) {
            result.push(
              <TableCell key={key}>
                <Typography variant="body2">[Object]</Typography>
              </TableCell>
            );
          } else if (typeof row[key] === 'boolean') {
            if (row[key] === false) {
              result.push(
                <TableCell key={key}>
                  <CloseIcon style={{ color: config.error_color || '#F44336' }} fontSize="small" />
                </TableCell>
              );
            } else if (row[key] === true) {
              result.push(
                <TableCell key={key}>
                  <DoneIcon style={{ color: config.success_color || '#4CAF50' }} fontSize="small" />
                </TableCell>
              );
            }
          } else {
            const fields = metadataState.data.fields || [];

            if (fields && fields.length > 0 && row[key] && row[key] !== '-') {
              for (let x = 0; x < fields.length; x++) {
                if (fields[x].type === 'timestamp' && fields[x].name === key) {
                  row[key] = dateInTz(row[key], 'localized-datetime');
                } else if (fields[x].type === 'date' && fields[x].name === key) {
                  row[key] = dateInTz(row[key], 'localized-date"');
                } else if (fields[x].type === 'time' && fields[x].name === key) {
                  const timeSplitted = row[key].split(':');
                  const timeAltered = alterDate(
                    createDateUtc(null).toString(),
                    null,
                    null,
                    null,
                    timeSplitted[0],
                    timeSplitted[1],
                    null
                  );
                  row[key] = dateInTz(timeAltered, 'HH:mm');
                }
              }
            }

            if (row[key]) {
              result.push(
                <TableCell key={key}>
                  <Typography variant="body2">{row[key]}</Typography>
                </TableCell>
              );
            } else {
              result.push(
                <TableCell key={key}>
                  <Typography variant="body2">-</Typography>
                </TableCell>
              );
            }
          }
        });

        /**
         * Build list items actions
         */
        const actions = [];
        const mobileActions = [];

        if (isUpdatable) {
          isActions = true;

          actions.push(
            <SpeedDialAction
              key="speedDial-update-subitem"
              icon={<EditIcon />}
              tooltipTitle={translation().actions.update}
              //eslint-disable-next-line
              onClick={() => handleOpenUpdate(row.id)}
              FabProps={{
                style: {
                  marginTop: 0,
                  marginBottom: 0
                }
              }}
            />
          );

          mobileActions.push(
            <MenuItem
              key="menu-update-subitem"
              className={classes.menuItem}
              //eslint-disable-next-line
              onClick={() => handleOpenUpdate(row.id)}>
              <i className={classNames('material-icons', classes.iconMenuItem)}>edit</i>
              <Typography component="span">{translation().actions.update}</Typography>
            </MenuItem>
          );
        }

        if (isDeletable) {
          isActions = true;

          actions.push(
            <SpeedDialAction
              key="speedDial-delete-subitem"
              icon={<DeleteIcon />}
              tooltipTitle={translation().actions.delete}
              //eslint-disable-next-line
              onClick={() => handleOpenDelete(row.id)}
              FabProps={{
                style: {
                  marginTop: 0,
                  marginBottom: 0
                }
              }}
            />
          );

          mobileActions.push(
            <MenuItem
              key="menu-delete-subitem"
              className={classes.menuItem}
              //eslint-disable-next-line
              onClick={() => handleOpenDelete(row.id)}>
              <i className={classNames('material-icons', classes.iconMenuItem)}>delete</i>
              <Typography component="span">{translation().actions.delete}</Typography>
            </MenuItem>
          );
        }

        if (subRoutes) {
          for (let route in subRoutes) {
            if (subRoutes[route].is_show_in_table) {
              if (subRoutes[route].is_route) {
                isActions = true;

                actions.push(
                  <SpeedDialAction
                    key={'speedDial-route-' + subRoutes[route].name}
                    icon={<i className="material-icons">{subRoutes[route].icon}</i>}
                    tooltipTitle={subRoutes[route].name}
                    FabProps={{
                      style: {
                        marginTop: 0,
                        marginBottom: 0
                      },
                      id: route.replace(':id', row.id),
                      component: CustomLink
                    }}
                  />
                );

                mobileActions.push(
                  <Link
                    to={route.replace(':id', row.id)}
                    key={'mobile-menu-route-' + subRoutes[route].name}>
                    <MenuItem className={classes.menuItem}>
                      <i className={classNames('material-icons', classes.iconMenuItem)}>
                        {subRoutes[route].icon}
                      </i>
                      <Typography component="span">{subRoutes[route].name}</Typography>
                    </MenuItem>
                  </Link>
                );
              } else if (subRoutes[route].is_modal) {
                for (let j = 0; j < listState.list.length; j++) {
                  if (listState.list[j].id === row.id) {
                    actions.push(
                      <SpeedDialAction
                        key={'custom-dialog-' + subRoutes[route].name}
                        icon={<i className="material-icons">{subRoutes[route].icon}</i>}
                        tooltipTitle={subRoutes[route].name}
                        customprops={{
                          data: listState.list[j],
                          onOpenCustomActionDialog: handleOpenCustomActionDialog,
                          route: route
                        }}
                        FabProps={{
                          style: {
                            marginTop: 0,
                            marginBottom: 0
                          },
                          component: CustomDialogButton
                        }}
                      />
                    );

                    mobileActions.push(
                      <MenuItem
                        key="menu-customdialog-item"
                        className={classes.menuItem}
                        //eslint-disable-next-line
                        onClick={() => handleOpenCustomActionDialog(route, listState.list[j])}>
                        <i className={classNames('material-icons', classes.iconMenuItem)}>
                          {subRoutes[route].icon}
                        </i>
                        <Typography component="span">{subRoutes[route].name}</Typography>
                      </MenuItem>
                    );
                  }
                }
              } else if (subRoutes[route].is_link) {
                let link = null;

                if (subRoutes[route].props && subRoutes[route].props.link_url) {
                  if (subRoutes[route].props.link_url.indexOf(':id') > -1)
                    link = subRoutes[route].props.link_url.replace(':id', row.id);
                  else link = subRoutes[route].props.link_url;
                }

                actions.push(
                  <SpeedDialAction
                    key={'speedDial-link-' + subRoutes[route].name}
                    icon={<i className="material-icons">{subRoutes[route].icon}</i>}
                    tooltipTitle={subRoutes[route].name}
                    target="_blank"
                    href={link}
                    FabProps={{
                      style: {
                        marginTop: 0,
                        marginBottom: 0
                      },
                      component: CustomHref
                    }}
                  />
                );

                mobileActions.push(
                  <MenuItem
                    target="_blank"
                    key={'menu-link-' + subRoutes[route].name}
                    href={link}
                    component={CustomHref}
                    className={classes.menuItem}>
                    <i className={classNames('material-icons', classes.iconMenuItem)}>
                      {subRoutes[route].icon}
                    </i>
                    <Typography component="span">{subRoutes[route].name}</Typography>
                  </MenuItem>
                );
              }
            }
          }
        }

        return (
          <TableRow key={index}>
            {result}
            {actions && actions.length > 0 ? (
              <TableCell key={'action-' + index} style={{ textAlign: 'right' }}>
                {getUserTableLinksDisplayMode() === 'select' ? (
                  <ListViewMobileMenu actions={mobileActions} />
                ) : (
                  <>
                    <div className={classes.sectionMobile}>
                      <ListViewMobileMenu actions={mobileActions} />
                    </div>
                    <div className={classes.sectionDesktop}>
                      <ListViewSpeedDial actions={actions} />
                    </div>
                  </>
                )}
              </TableCell>
            ) : (
              ''
            )}
          </TableRow>
        );
      });

      return (
        <FixedHeaderTable
          rows={tableBody}
          tableValues={tableValues}
          metadata={metadataState.data}
          isActions={isActions}
          onScrollList={handleScrollList}
          isLoading={listState.isLoading}
        />
      );
    }
  }

  function renderCustomActionsDialog() {
    const customActionsDialog = [];

    for (let route in subRoutes) {
      if (subRoutes[route].is_show_in_table) {
        if (subRoutes[route].is_modal) {
          const ActionComponent = subRoutes[route].container ? subRoutes[route].container : false;

          customActionsDialog.push(
            <Dialog
              key={route}
              open={customDialog.opened === route ? true : false}
              onClose={handleCloseCustomActionDialog}
              aria-labelledby={route + '-custom-dialog-title'}
              aria-describedby={route + '-custom-dialog-description'}>
              <DialogTitle id={route + '-custom-dialog-title'}>
                {subRoutes[route].props.title}
              </DialogTitle>
              <DialogContent>
                {subRoutes[route].props.description && (
                  <DialogContentText id={route + '-custom-dialog-description'}>
                    {subRoutes[route].props.description}
                  </DialogContentText>
                )}
                {ActionComponent && (
                  <ActionComponent
                    resource={customDialog.data}
                    resourceId={resourceId}
                    routeUri={routeUri}
                    closeDialogFunction={handleCloseCustomActionDialog}
                    reloadListFunction={handleReload}
                    fullProps={subRoutes[route].props}
                  />
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseCustomActionDialog} color="default">
                  {translation().actions.close}
                </Button>
              </DialogActions>
            </Dialog>
          );
        }
      }
    }

    return customActionsDialog;
  }

  function renderLabelDisplayedRows({ from, to, count }) {
    return `${from}-${to} ${translation().core.update.collections.pagination.label} ${
      count !== -1 ? count : `${translation().core.update.collections.pagination.more_than} ${to}`
    }`;
  }

  return (
    <div>
      {!listState.isLoading && !metadataState.isLoading && (
        <Paper className="FormFactory" {...PaperProps}>
          {listState.list && listState.list.length
            ? renderTable(
                listState.list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
                columns
              )
            : false}
          {listState.list && listState.list.length ? (
            <>
              {listState.isPageLoading && <LinearProgress />}
              <TablePagination
                rowsPerPageOptions={[10]}
                component="div"
                count={listState.list.length}
                labelDisplayedRows={renderLabelDisplayedRows}
                page={page}
                rowsPerPage={rowsPerPage}
                onChangePage={handleChangePagination}
                nextIconButtonProps={{
                  disabled: checkIfNextIconPageButtonDisabled()
                }}
              />
            </>
          ) : (
            false
          )}
          {!isNoLink && !listState.isError && (!listState.list || listState.list.length <= 0) && (
            <div className="loader-wpr linear">
              <p>{translation().core.list.empty}</p>
            </div>
          )}
          {listState.isError && (
            <div className="error-list">
              <Alert
                type="danger"
                style={{ margin: 'auto' }}
                status={translation().commons.alerts.error}
                text={listState.isError}
              />
            </div>
          )}
          {isNoLink && (
            <div className="loader-wpr linear">
              <p>{translation().core.update.collections.no_link}</p>
            </div>
          )}
          {subtype && subtype === 'ProviderProduct' && (
            <Button
              variant="contained"
              size="small"
              disabled={createState.isLoading}
              className={classes.configButton}
              onClick={handleOpenProviderProducts}
              style={configButtonStyle}>
              <TuneIcon className={classes.iconButton} />
              {translation().actions.config_view_mode}
            </Button>
          )}
          {isCreatable && !isNoLink && !listState.isError && (
            <Button
              variant="contained"
              size="small"
              disabled={createState.isLoading}
              className={classes.createButton}
              onClick={handleOpenCreate}
              style={createButtonStyle}>
              <AddIcon className={classes.iconButton} />
              {translation().actions.create}
            </Button>
          )}
        </Paper>
      )}
      {(listState.isLoading || metadataState.isLoading) && !listState.paging && (
        <div className="loader-wpr">
          <CircularProgress color="primary" />
          {listState.isLoading && <p>{translation().core.list.load_list}</p>}
          {metadataState.isLoading && <p>{translation().core.load_metadata}</p>}
        </div>
      )}
      {renderCustomActionsDialog()}
      <Dialog
        open={isCreateOpen ? true : false}
        onClose={handleCloseCreate}
        aria-labelledby="create-subresource-item"
        disableBackdropClick={createState.isLoading}>
        <DialogContent>
          <FormsContainer
            metadata={metadataState}
            timezone={timezone}
            formName="createSubResourceForm"
            onSubmit={handleCreate}>
            <div className={classes.submitWrapper}>
              {createState.isLoading && (
                <div className="loader-wpr" style={loaderWrapperStyle}>
                  <CircularProgress size={20} color="secondary" />
                  <p className={classes.updateLoadingText}>
                    {translation().core.create.load_create}
                  </p>
                </div>
              )}
              <Button
                variant="contained"
                color="primary"
                type="submit"
                style={{ marginRight: 8 }}
                disabled={createState.isLoading}>
                <i className="material-icons" style={{ marginRight: 8 }}>
                  done
                </i>
                {translation().actions.create}
              </Button>
            </div>
          </FormsContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCreate} color="default" disabled={createState.isLoading}>
            {translation().actions.cancel}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isDeleteOpen ? true : false}
        onClose={handleCloseDelete}
        aria-labelledby="delete-subresource-item"
        aria-describedby="delete-subdialog-description"
        disableBackdropClick={deleteItemState.isLoading}>
        <DialogTitle id="delete-subresource-item">
          {translation().core.list.dialogs.delete.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-subdialog-description">
            {translation().core.list.dialogs.delete.content}
          </DialogContentText>
          {deleteItemState.isLoading && (
            <div className="loader-wpr" style={{ marginTop: 15 }}>
              <CircularProgress size={30} color="secondary" />
              <p>{translation().core.list.dialogs.delete.load_delete}</p>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete} color="default" disabled={deleteItemState.isLoading}>
            {translation().actions.cancel}
          </Button>
          <Button
            onClick={handleDeleteResourceItem}
            className={classes.deleteButton}
            disabled={deleteItemState.isLoading}>
            {translation().actions.delete}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isProviderProductsOpen}
        onClose={handleCloseProviderProducts}
        aria-labelledby="provider-produts-config"
        maxWidth="xl"
        fullWidth>
        <DialogContent>
          <ProviderFactoryProductsContainer
            providerId={resourceId}
            PaperProps={{
              style: { padding: 0 },
              elevation: 0,
              square: true
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseProviderProducts} color="default">
            {translation().actions.close}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={updateItemOpen ? true : false}
        onClose={handleCloseUpdate}
        aria-labelledby="update-subresource-item"
        disableBackdropClick={updateItemState.isLoading}>
        <DialogContent>
          <FormsContainer
            isWillReceiveValues
            metadata={metadataState}
            formName="updateSubResourceForm"
            defaultValues={updateItemOpen || {}}
            timezone={timezone}
            onSubmit={handleUpdate}>
            <div className={classes.submitWrapper}>
              {updateItemState.isLoading && (
                <div className="loader-wpr" style={loaderWrapperStyle}>
                  <CircularProgress size={20} color="secondary" />
                  <p className={classes.updateLoadingText}>
                    {translation().core.update.load_update}
                  </p>
                </div>
              )}
              <Button
                variant="contained"
                color="primary"
                type="submit"
                style={{ marginRight: 8 }}
                disabled={updateItemState.isLoading}>
                <i className="material-icons" style={{ marginRight: 8 }}>
                  done
                </i>
                {translation().actions.update}
              </Button>
            </div>
          </FormsContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUpdate} color="default" disabled={updateItemState.isLoading}>
            {translation().actions.cancel}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

UpdateViewCollection.defaultProps = {
  columns: 7,
  createButtonStyle: {},
  configButtonStyle: {},
  isCreatable: true,
  isDeletable: true,
  isUpdatable: true,
  PaperProps: {}
};

UpdateViewCollection.propTypes = {
  _cleanFormsErrors: PropTypes.func.isRequired,
  _enqueueSnackbar: PropTypes.func.isRequired,
  _setFormsErrors: PropTypes.func.isRequired,
  columns: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
  createButtonStyle: PropTypes.shape(),
  configButtonStyle: PropTypes.shape(),
  isCreatable: PropTypes.bool,
  isDeletable: PropTypes.bool,
  isUpdatable: PropTypes.bool,
  links: PropTypes.arrayOf(PropTypes.any),
  locale: PropTypes.string,
  PaperProps: PropTypes.shape(),
  routeUri: PropTypes.string.isRequired,
  resourceId: PropTypes.any.isRequired,
  subRoutes: PropTypes.shape(),
  subtype: PropTypes.string,
  tabIndex: PropTypes.number,
  tabValue: PropTypes.number,
  timezone: PropTypes.string
};

export default connect(null, mapDispatchToProps)(UpdateViewCollection);
