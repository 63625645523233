import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchApi } from '../../../utils/functions/api';
import { enqueueSnackbar } from '../../../redux/actions/appActions';
import ProviderHash from '../../../views/core/customListActions/ProviderHash';
import translation from '../../../translation/translation';

const mapDispatchToProps = (dispatch) => {
  return {
    getProviderHash: (providerId, callbackSuccess, callbackError) => {
      if (!providerId) {
        return;
      }

      fetchApi(
        'get',
        `providers/${providerId}/hash`,
        null,
        null,
        null,
        (success) => {
          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success);
          }
        },
        (error) => {
          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.dialogs.custom_list_actions.provider_hash.callbacks
                        .error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message:
                  translation().core.list.dialogs.custom_list_actions.provider_hash.callbacks.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError(error);
          }
        }
      );
    }
  };
};

export default withRouter(connect(null, mapDispatchToProps)(ProviderHash));
