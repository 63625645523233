import { addToDate, formatDate, getCurrentDate } from '../../../../utils/functions/dates';

const initialState = {
  data: null,
  isLoading: false,
  error: null,
  deliveryStatuses: {
    data: null,
    isLoading: false,
    error: null
  },
  discounts: {
    data: null,
    error: false,
    isLoading: false,
    addDiscount: {
      error: false,
      isLoading: false
    },
    deleteDiscount: {
      error: false,
      isLoading: false
    }
  },
  invoiceAddresses: {
    data: null,
    isLoading: false,
    error: null,
    edit_action: {
      isLoading: false,
      error: null
    }
  },
  paymentMethods: {
    data: null,
    isLoading: false,
    error: null,
    edit_action: {
      isLoading: false,
      error: null
    }
  },
  productCancel: {
    isLoading: false,
    error: null
  },
  productFlag: {
    isLoading: false,
    error: null
  },
  productHistory: {
    data: null,
    isLoading: false,
    error: null
  },
  productOptions: {
    isLoading: false,
    error: null
  },
  productPlanning: {
    slots: null,
    nbTimeMax: 0,
    startDate: formatDate(getCurrentDate(), 'YYYY-MM-DD'),
    endDate: formatDate(addToDate(getCurrentDate(), 7, 'days'), 'YYYY-MM-DD'),
    minStartDate: formatDate(getCurrentDate(), 'YYYY-MM-DD'),
    maxStartDate: formatDate(addToDate(getCurrentDate(), 3, 'months'), 'YYYY-MM-DD'),
    calendar: null,
    isLoading: false,
    isSendLoading: false,
    isWeekLoading: false,
    error: null
  },
  productProvider: {
    isLoading: false,
    error: null
  },
  productPropertyDetails: {
    isLoading: false,
    error: null
  },
  providersInfos: {
    data: null,
    isLoading: false,
    error: null
  },
  providersDispatching: {
    isLoading: false,
    isSendLoading: false,
    error: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_REDUCERS':
      return initialState;
    case 'CLEAR_ORDER_UPDATE_REDUCER':
      return initialState;
    case 'GET_ORDER':
      return {
        ...state,
        data: null,
        isLoading: true,
        error: false
      };
    case 'GET_ORDER_SUCCESS':
      return {
        ...state,
        data: action.data,
        productPlanning: {
          ...initialState.productPlanning,
          startDate: formatDate(
            getCurrentDate(
              null,
              action.data.property && action.data.property.timezone_code
                ? action.data.property.timezone_code
                : undefined
            ),
            'YYYY-MM-DD'
          ),
          endDate: formatDate(
            addToDate(
              getCurrentDate(
                null,
                action.data.property && action.data.property.timezone_code
                  ? action.data.property.timezone_code
                  : undefined
              ),
              7,
              'days'
            ),
            'YYYY-MM-DD'
          ),
          minStartDate: formatDate(
            getCurrentDate(
              null,
              action.data.property && action.data.property.timezone_code
                ? action.data.property.timezone_code
                : undefined
            ),
            'YYYY-MM-DD'
          ),
          maxStartDate: formatDate(
            addToDate(
              getCurrentDate(
                null,
                action.data.property && action.data.property.timezone_code
                  ? action.data.property.timezone_code
                  : undefined
              ),
              3,
              'months'
            ),
            'YYYY-MM-DD'
          )
        },
        isLoading: false,
        error: null
      };
    case 'GET_ORDER_ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    case 'GET_ORDER_DISCOUNTS':
      return {
        ...state,
        discounts: {
          ...state.discounts,
          data: null,
          isLoading: true,
          error: null
        }
      };
    case 'GET_ORDER_DISCOUNTS_SUCCESS':
      return {
        ...state,
        discounts: {
          ...state.discounts,
          data: action.data,
          isLoading: false,
          error: null
        }
      };
    case 'GET_ORDER_DISCOUNTS_ERROR':
      return {
        ...state,
        discounts: {
          ...state.discounts,
          isLoading: false,
          error: action.error
        }
      };
    case 'DELETE_ORDER_DISCOUNT':
      return {
        ...state,
        discounts: {
          ...state.discounts,
          deleteDiscount: {
            isLoading: true,
            error: null
          }
        }
      };
    case 'DELETE_ORDER_DISCOUNT_SUCCESS':
      return {
        ...state,
        discounts: {
          ...state.discounts,
          deleteDiscount: {
            isLoading: false,
            error: null
          }
        }
      };
    case 'DELETE_ORDER_DISCOUNT_ERROR':
      return {
        ...state,
        discounts: {
          ...state.discounts,
          deleteDiscount: {
            isLoading: false,
            error: action.error
          }
        }
      };
    case 'ADD_ORDER_DISCOUNT':
      return {
        ...state,
        discounts: {
          ...state.discounts,
          addDiscount: {
            isLoading: true,
            error: null
          }
        }
      };
    case 'ADD_ORDER_DISCOUNT_SUCCESS':
      return {
        ...state,
        discounts: {
          ...state.discounts,
          addDiscount: {
            isLoading: false,
            error: null
          }
        }
      };
    case 'ADD_ORDER_DISCOUNT_ERROR':
      return {
        ...state,
        discounts: {
          ...state.discounts,
          addDiscount: {
            isLoading: false,
            error: action.error
          }
        }
      };
    case 'GET_ORDER_CLIENT_ADDRESSES':
      return {
        ...state,
        invoiceAddresses: {
          ...initialState.invoiceAddresses,
          isLoading: true
        }
      };
    case 'GET_ORDER_CLIENT_ADDRESSES_SUCCESS':
      return {
        ...state,
        invoiceAddresses: {
          ...initialState.invoiceAddresses,
          data: action.data
        }
      };
    case 'GET_ORDER_CLIENT_ADDRESSES_ERROR':
      return {
        ...state,
        invoiceAddresses: {
          ...state.invoiceAddresses,
          isLoading: false,
          error: action.error
        }
      };
    case 'GET_ORDER_CLIENT_PAYMENT_METHODS':
      return {
        ...state,
        paymentMethods: {
          ...initialState.paymentMethods,
          isLoading: true
        }
      };
    case 'GET_ORDER_CLIENT_PAYMENT_METHODS_SUCCESS':
      return {
        ...state,
        paymentMethods: {
          ...initialState.paymentMethods,
          data: action.data
        }
      };
    case 'GET_ORDER_CLIENT_PAYMENT_METHODS_ERROR':
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          isLoading: false,
          error: action.error
        }
      };
    case 'EDIT_ORDER_INVOICE_ADDRESS':
      return {
        ...state,
        invoiceAddresses: {
          ...state.invoiceAddresses,
          edit_action: {
            isLoading: true,
            error: null
          }
        }
      };
    case 'EDIT_ORDER_INVOICE_ADDRESS_SUCCESS':
      return {
        ...state,
        invoiceAddresses: {
          ...state.invoiceAddresses,
          edit_action: {
            isLoading: false,
            error: null
          }
        }
      };
    case 'EDIT_ORDER_INVOICE_ADDRESS_ERROR':
      return {
        ...state,
        invoiceAddresses: {
          ...state.invoiceAddresses,
          edit_action: {
            isLoading: false,
            error: action.error
          }
        }
      };
    case 'EDIT_ORDER_PAYMENT_METHOD':
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          edit_action: {
            isLoading: true,
            error: null
          }
        }
      };
    case 'EDIT_ORDER_PAYMENT_METHOD_SUCCESS':
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          edit_action: {
            isLoading: false,
            error: null
          }
        }
      };
    case 'EDIT_ORDER_PAYMENT_METHOD_ERROR':
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          edit_action: {
            isLoading: false,
            error: action.error
          }
        }
      };
    case 'GET_ORDER_PRODUCT_PROVIDERS_INFOS':
      return {
        ...state,
        providersInfos: {
          ...initialState.providersInfos,
          isLoading: true
        }
      };
    case 'GET_ORDER_PRODUCT_PROVIDERS_INFOS_SUCCESS':
      return {
        ...state,
        providersInfos: {
          ...initialState.providersInfos,
          data: action.data
        }
      };
    case 'GET_ORDER_PRODUCT_PROVIDERS_INFOS_ERROR':
      return {
        ...state,
        providersInfos: {
          ...state.providersInfos,
          isLoading: false,
          error: action.error
        }
      };
    case 'GET_ORDER_PRODUCT_HISTORY':
      return {
        ...state,
        productHistory: {
          ...initialState.productHistory,
          isLoading: true
        }
      };
    case 'GET_ORDER_PRODUCT_HISTORY_SUCCESS':
      return {
        ...state,
        productHistory: {
          ...initialState.productHistory,
          data: action.data
        }
      };
    case 'GET_ORDER_PRODUCT_HISTORY_ERROR':
      return {
        ...state,
        productHistory: {
          ...state.productHistory,
          isLoading: false,
          error: action.error
        }
      };
    case 'CANCEL_ORDER_PRODUCT':
      return {
        ...state,
        productCancel: {
          error: null,
          isLoading: true
        }
      };
    case 'CANCEL_ORDER_PRODUCT_SUCCESS':
      return {
        ...state,
        productCancel: {
          error: null,
          isLoading: false
        }
      };
    case 'CANCEL_ORDER_PRODUCT_ERROR':
      return {
        ...state,
        productCancel: {
          isLoading: false,
          error: action.error
        }
      };

    case 'ADD_ORDER_PRODUCT_OPTION':
      return {
        ...state,
        productOptions: {
          error: null,
          isLoading: true
        }
      };
    case 'ADD_ORDER_PRODUCT_OPTION_SUCCESS':
      return {
        ...state,
        productOptions: {
          error: null,
          isLoading: false
        }
      };
    case 'ADD_ORDER_PRODUCT_OPTION_ERROR':
      return {
        ...state,
        productOptions: {
          isLoading: false,
          error: action.error
        }
      };
    case 'REMOVE_ORDER_PRODUCT_OPTION':
      return {
        ...state,
        productOptions: {
          error: null,
          isLoading: true
        }
      };
    case 'REMOVE_ORDER_PRODUCT_OPTION_SUCCESS':
      return {
        ...state,
        productOptions: {
          error: null,
          isLoading: false
        }
      };
    case 'REMOVE_ORDER_PRODUCT_OPTION_ERROR':
      return {
        ...state,
        productOptions: {
          isLoading: false,
          error: action.error
        }
      };
    case 'GET_ORDER_PRODUCT_DELIVERY_STATUSES':
      return {
        ...state,
        deliveryStatuses: {
          ...initialState.deliveryStatuses,
          isLoading: true
        }
      };
    case 'GET_ORDER_PRODUCT_DELIVERY_STATUSES_SUCCESS':
      return {
        ...state,
        deliveryStatuses: {
          ...state.deliveryStatuses,
          data: action.data,
          error: null,
          isLoading: false
        }
      };
    case 'GET_ORDER_PRODUCT_DELIVERY_STATUSES_ERROR':
      return {
        ...state,
        deliveryStatuses: {
          ...state.deliveryStatuses,
          isLoading: false,
          error: action.error
        }
      };
    case 'UPDATE_ORDER_PRODUCT_DELIVERY_STATUS':
      return {
        ...state,
        deliveryStatuses: {
          ...state.deliveryStatuses,
          isLoading: true
        }
      };
    case 'UPDATE_ORDER_PRODUCT_DELIVERY_STATUS_SUCCESS':
      return {
        ...state,
        deliveryStatuses: {
          ...state.deliveryStatuses,
          isLoading: false
        }
      };
    case 'UPDATE_ORDER_PRODUCT_DELIVERY_STATUS_ERROR':
      return {
        ...state,
        deliveryStatuses: {
          ...state.deliveryStatuses,
          isLoading: false,
          error: action.error
        }
      };
    case 'UPDATE_ORDER_PRODUCT_PROVIDER':
      return {
        ...state,
        productProvider: {
          ...state.productProvider,
          isLoading: true
        }
      };
    case 'UPDATE_ORDER_PRODUCT_PROVIDER_SUCCESS':
      return {
        ...state,
        productProvider: {
          ...state.productProvider,
          isLoading: false
        }
      };
    case 'UPDATE_ORDER_PRODUCT_PROVIDER_ERROR':
      return {
        ...state,
        productProvider: {
          ...state.productProvider,
          isLoading: false,
          error: action.error
        }
      };
    case 'GET_ORDER_PRODUCT_SLOTS':
      return {
        ...state,
        productPlanning: {
          ...state.productPlanning,
          slots: null,
          isLoading: true
        }
      };
    case 'GET_ORDER_PRODUCT_SLOTS_SUCCESS':
      return {
        ...state,
        productPlanning: {
          ...state.productPlanning,
          slots: action.slots,
          isLoading: false,
          error: null
        }
      };
    case 'GET_ORDER_PRODUCT_SLOTS_ERROR':
      return {
        ...state,
        productPlanning: {
          ...state.productPlanning,
          slots: null,
          isLoading: false,
          error: action.error
        }
      };
    case 'GET_PREVIOUS_ORDER_PRODUCT_SLOTS':
      return {
        ...state,
        productPlanning: {
          ...state.productPlanning,
          slots: null,
          isWeekLoading: true
        }
      };
    case 'GET_PREVIOUS_ORDER_PRODUCT_SLOTS_SUCCESS':
      return {
        ...state,
        productPlanning: {
          ...state.productPlanning,
          slots: action.slots,
          isWeekLoading: false,
          error: null
        }
      };
    case 'GET_PREVIOUS_ORDER_PRODUCT_SLOTS_ERROR':
      return {
        ...state,
        productPlanning: {
          ...state.productPlanning,
          slots: null,
          isWeekLoading: false,
          error: action.error
        }
      };
    case 'GET_NEXT_ORDER_PRODUCT_SLOTS':
      return {
        ...state,
        productPlanning: {
          ...state.productPlanning,
          slots: null,
          isWeekLoading: true
        }
      };
    case 'GET_NEXT_ORDER_PRODUCT_SLOTS_SUCCESS':
      return {
        ...state,
        productPlanning: {
          ...state.productPlanning,
          slots: action.slots,
          isWeekLoading: false,
          error: null
        }
      };
    case 'GET_NEXT_ORDER_PRODUCT_SLOTS_ERROR':
      return {
        ...state,
        productPlanning: {
          ...state.productPlanning,
          slots: null,
          isWeekLoading: false,
          error: action.error
        }
      };
    case 'CREATE_UPDATE_CALENDAR':
      return {
        ...state,
        productPlanning: {
          ...state.productPlanning,
          isLoading: false,
          calendar: action.payload.calendar,
          startDate: action.payload.startDate
            ? action.payload.startDate
            : state.productPlanning.startDate,
          endDate: action.payload.endDate ? action.payload.endDate : state.productPlanning.endDate
        }
      };
    case 'SET_UPDATE_NB_TIME_MAX':
      return {
        ...state,
        productPlanning: {
          ...state.productPlanning,
          nbTimeMax: action.number
        }
      };
    case 'CLEAR_ORDER_UPDATE_PLANNING':
      return {
        ...state,
        productPlanning: {
          ...initialState.productPlanning,
          startDate: formatDate(
            getCurrentDate(
              null,
              state.data.property && state.data.property.timezone_code
                ? state.data.property.timezone_code
                : undefined
            ),
            'YYYY-MM-DD'
          ),
          endDate: formatDate(
            addToDate(
              getCurrentDate(
                null,
                state.data.property && state.data.property.timezone_code
                  ? state.data.property.timezone_code
                  : undefined
              ),
              7,
              'days'
            ),
            'YYYY-MM-DD'
          ),
          minStartDate: formatDate(
            getCurrentDate(
              null,
              state.data.property && state.data.property.timezone_code
                ? state.data.property.timezone_code
                : undefined
            ),
            'YYYY-MM-DD'
          ),
          maxStartDate: formatDate(
            addToDate(
              getCurrentDate(
                null,
                state.data.property && state.data.property.timezone_code
                  ? state.data.property.timezone_code
                  : undefined
              ),
              3,
              'months'
            ),
            'YYYY-MM-DD'
          )
        }
      };
    case 'UPDATE_ORDER_PRODUCT_START_DATE':
      return {
        ...state,
        productPlanning: {
          ...state.productPlanning,
          isSendLoading: true
        }
      };
    case 'UPDATE_ORDER_PRODUCT_START_DATE_SUCCESS':
      return {
        ...state,
        productPlanning: {
          ...state.productPlanning,
          isSendLoading: false
        }
      };
    case 'UPDATE_ORDER_PRODUCT_START_DATE_ERROR':
      return {
        ...state,
        productPlanning: {
          ...state.productPlanning,
          isSendLoading: false,
          error: action.error
        }
      };
    case 'GET_ORDER_PRODUCT_DISPATCHABLE_PROVIDERS':
      return {
        ...state,
        providersDispatching: {
          ...initialState.providersDispatching,
          isLoading: true
        }
      };
    case 'GET_ORDER_PRODUCT_DISPATCHABLE_PROVIDERS_SUCCESS':
      return {
        ...state,
        providersDispatching: {
          ...state.providersDispatching,
          isLoading: false,
          error: null
        }
      };
    case 'GET_ORDER_PRODUCT_DISPATCHABLE_PROVIDERS_ERROR':
      return {
        ...state,
        providersDispatching: {
          ...state.providersDispatching,
          isLoading: false,
          error: action.error
        }
      };
    case 'SEND_ORDER_PRODUCT_DISPATCHABLE_PROVIDERS':
      return {
        ...state,
        providersDispatching: {
          ...initialState.providersDispatching,
          isSendLoading: true
        }
      };
    case 'SEND_ORDER_PRODUCT_DISPATCHABLE_PROVIDERS_SUCCESS':
      return {
        ...state,
        providersDispatching: {
          ...state.providersDispatching,
          isSendLoading: false,
          error: null
        }
      };
    case 'SEND_ORDER_PRODUCT_DISPATCHABLE_PROVIDERS_ERROR':
      return {
        ...state,
        providersDispatching: {
          ...state.providersDispatching,
          isSendLoading: false,
          error: action.error
        }
      };
    case 'UPDATE_ORDER_PRODUCT_PROPERTY_DETAILS':
      return {
        ...state,
        productPropertyDetails: {
          isLoading: true,
          error: null
        }
      };
    case 'UPDATE_ORDER_PRODUCT_PROPERTY_DETAILS_SUCCESS':
      return {
        ...state,
        productPropertyDetails: {
          isLoading: false,
          error: null
        }
      };
    case 'UPDATE_ORDER_PRODUCT_PROPERTY_DETAILS_ERROR':
      return {
        ...state,
        productPropertyDetails: {
          isLoading: false,
          error: action.error
        }
      };
    case 'MANAGE_ORDER_PRODUCT_FLAG':
      return {
        ...state,
        productFlag: {
          isLoading: true,
          error: null
        }
      };
    case 'MANAGE_ORDER_PRODUCT_FLAG_SUCCESS':
      return {
        ...state,
        productFlag: {
          isLoading: false,
          error: null
        }
      };
    case 'MANAGE_ORDER_PRODUCT_FLAG_ERROR':
      return {
        ...state,
        productFlag: {
          isLoading: false,
          error: action.error
        }
      };
    default:
      return state;
  }
};
