/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { animateScroll as scroll } from 'react-scroll';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Typography } from '@material-ui/core';

import { StepNavigation, Stepper, Toolbar } from './components';
import ProviderFactoryProductsContainer from '../../../containers/providers/factory/ProviderFactoryProductsContainer';
import { Company, CompanyCreated, Invoice, Provider, ProviderCreated } from './steps';
import { isObjEmpty } from '../../../utils/functions/utils';
import translation from '../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  head: {
    paddingTop: 35,
    paddingBottom: 35
  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  button: {
    marginRight: theme.spacing(1)
  },
  buttonWrapper: {
    width: '100%',
    textAlign: 'right',
    marginBottom: 12
  },
  title: {
    textAlign: 'center'
  }
}));

function getSteps() {
  return [
    translation().views.provider_factory.steps.user_provider.title,
    translation().views.provider_factory.steps.company.title,
    translation().views.provider_factory.steps.products.title,
    translation().views.provider_factory.steps.invoice.title
  ];
}

function ProviderFactory(props) {
  const classes = useStyles();

  const {
    adminizeCompanyUser,
    checkUserProviderFactoryAdmin,
    clearOwnReducer,
    createCompany,
    createUserProvider,
    createProviderAddress,
    enqueueSnackbar,
    getUserProviderPayer,
    getUserProviderPaymentMethods,
    history,
    linkUserToCompany,
    linkProviderToProviderCompany,
    providerFactory: { company, loading, invoice, user, provider, step },
    selectUserProviderFactoryCompany,
    setActiveStep
  } = props;

  const steps = getSteps();
  const [isCompany, setCompany] = useState(false);
  const [companyValues, setCompanyValues] = useState({});
  const [providerValues, setProviderValues] = useState({
    late_cancel_reversion_percentage: 0
  });
  const [userValues, setUserValues] = useState({
    date_of_birth: '1980-01-01',
    unit_system: 'metric'
  });

  useEffect(() => {
    document.title = `Actarus | ${translation().views.provider_factory.title}`;

    scroll.scrollToTop();

    return () => {
      clearOwnReducer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleNext() {
    setActiveStep(step + 1);
  }

  function handleBack() {
    setActiveStep(step - 1);
  }

  function handleChangeCompanyValue(name, value) {
    setCompanyValues((values) => ({ ...values, [name]: value }));
  }

  function handleChangeUserValue(name, value) {
    setUserValues((values) => ({ ...values, [name]: value }));
  }

  function handleChangeProvider(name, value) {
    if (name === 'location') {
      setProviderValues((values) => ({
        ...values,
        base_latitude: value.lat,
        base_longitude: value.lng
      }));
    } else {
      setProviderValues((values) => ({
        ...values,
        [name]: value
      }));
    }
  }

  function handleDisableGhost() {
    setProviderValues((values) => ({
      ...values,
      ghost: false,
      ghost_start_time: null,
      ghost_end_time: null
    }));
  }

  function handleSubmitProvider(event) {
    event.preventDefault();

    createUserProvider(userValues, providerValues);
  }

  function getFirstStepButtonDisabledState() {
    if (user.data && provider.data && !isObjEmpty(provider.data)) {
      return false;
    } else if (
      user.loading ||
      provider.loading ||
      !userValues.first_name ||
      !userValues.last_name ||
      !userValues.email ||
      !userValues.mobile ||
      !userValues.timezone_code ||
      !userValues.language_locale ||
      !userValues.currency_isocode ||
      !userValues.citizen_country_isocode ||
      !userValues.residence_country_isocode ||
      !userValues.unit_system ||
      !providerValues.base_latitude ||
      !providerValues.base_longitude ||
      !providerValues.social_regime ||
      !providerValues.transportation_shortcode ||
      !providerValues.tax_rule_shortcode
    ) {
      return true;
    }

    return false;
  }

  function getSecondStepButtonDisabledState() {
    if (company.data && !isObjEmpty(company.data)) {
      return false;
    } else if (isCompany && company.linked) {
      return false;
    } else if (
      isCompany &&
      (company.loading || !companyValues.legal_name || !companyValues.legal_id)
    ) {
      return true;
    }

    return false;
  }

  function handleCheckCompany(checked) {
    setCompany(checked);
  }

  function handleSubmitCompany(event) {
    event.preventDefault();

    if (!companyValues.legal_name || !companyValues.legal_id) return;

    createCompany(
      companyValues,
      user && user.data && user.data.id ? user.data.id : null,
      (success) => {
        linkProviderToProviderCompany(
          provider && provider.data && provider.data.id ? provider.data.id : null,
          success.id
        );
      }
    );
  }

  function renderUserProviderStep() {
    return (
      <Grid
        container
        component={user.data && provider.data && !isObjEmpty(provider.data) ? 'div' : 'form'}
        onSubmit={handleSubmitProvider}
        spacing={2}
        autoComplete="chrome-off">
        <StepNavigation
          disabled={getFirstStepButtonDisabledState()}
          loading={user.loading || provider.loading}
          loadingText={translation().views.provider_factory.steps.user_provider.loading}
          onNavigationBack={handleBack}
          onNavigationNext={handleNext}
          step={step}
          submit={user.data && provider.data && !isObjEmpty(provider.data) ? false : true}
        />
        {user.data && provider.data && !isObjEmpty(provider.data) ? (
          <ProviderCreated
            user={user.data}
            provider={provider.data}
            setActiveStep={setActiveStep}
          />
        ) : (
          <Provider
            disableGhost={handleDisableGhost}
            onChange={handleChangeUserValue}
            onChangeProvider={handleChangeProvider}
            timezone={userValues && userValues.timezone_code ? userValues.timezone_code : null}
            user={user}
            provider={provider}
          />
        )}
        <StepNavigation
          disabled={getFirstStepButtonDisabledState()}
          loading={user.loading || provider.loading}
          loadingText={translation().views.provider_factory.steps.user_provider.loading}
          onNavigationBack={handleBack}
          onNavigationNext={handleNext}
          step={step}
          submit={user.data && provider.data && !isObjEmpty(provider.data) ? false : true}
        />
      </Grid>
    );
  }

  function renderProductsStep() {
    return (
      <Grid container spacing={2}>
        <StepNavigation onNavigationBack={handleBack} onNavigationNext={handleNext} step={step} />
        <ProviderFactoryProductsContainer
          providerId={provider && provider.data && provider.data.id ? provider.data.id : undefined}
        />
        <StepNavigation onNavigationBack={handleBack} onNavigationNext={handleNext} step={step} />
      </Grid>
    );
  }

  function renderCompanyStep() {
    return (
      <Grid
        container
        component={
          company.linked
            ? 'div'
            : company.data && !isObjEmpty(company.data)
            ? 'div'
            : isCompany
            ? 'form'
            : 'div'
        }
        onSubmit={handleSubmitCompany}
        spacing={2}
        autoComplete="chrome-off">
        <StepNavigation
          disabled={getSecondStepButtonDisabledState()}
          loading={company.loading}
          loadingText={translation().views.provider_factory.steps.company.loading}
          onNavigationBack={handleBack}
          onNavigationNext={handleNext}
          step={step}
          submit={
            company.linked ? false : company.data && !isObjEmpty(company.data) ? false : isCompany
          }
        />
        {company.data && !isObjEmpty(company.data) ? (
          <CompanyCreated company={company.data} setActiveStep={setActiveStep} />
        ) : (
          <Company
            adminizeCompanyUser={adminizeCompanyUser}
            checkUserProviderFactoryAdmin={checkUserProviderFactoryAdmin}
            company={company}
            companyChecked={isCompany}
            onChangeCompany={handleChangeCompanyValue}
            onCheckCompany={handleCheckCompany}
            linkUserToCompany={linkUserToCompany}
            linkProviderToProviderCompany={linkProviderToProviderCompany}
            onSelectCompany={selectUserProviderFactoryCompany}
            user={user.data}
            provider={provider.data}
          />
        )}
        <StepNavigation
          disabled={getSecondStepButtonDisabledState()}
          loading={company.loading}
          loadingText={translation().views.provider_factory.steps.company.loading}
          onNavigationBack={handleBack}
          onNavigationNext={handleNext}
          step={step}
          submit={
            company.linked ? false : company.data && !isObjEmpty(company.data) ? false : isCompany
          }
        />
      </Grid>
    );
  }

  function renderInvoiceStep() {
    return (
      <Grid container spacing={2}>
        <StepNavigation onNavigationBack={handleBack} onNavigationNext={handleNext} step={step} />
        <Invoice
          createProviderAddress={createProviderAddress}
          enqueueSnackbar={enqueueSnackbar}
          getUserProviderPayer={getUserProviderPayer}
          getUserProviderPaymentMethods={getUserProviderPaymentMethods}
          invoice={invoice}
          user={user.data}
        />
        <StepNavigation onNavigationBack={handleBack} onNavigationNext={handleNext} step={step} />
      </Grid>
    );
  }

  function renderSteps(step) {
    switch (step) {
      case 0:
        return renderUserProviderStep();
      case 1:
        return renderCompanyStep();
      case 2:
        return renderProductsStep();
      case 3:
        return renderInvoiceStep();
      default:
        return renderUserProviderStep();
    }
  }

  return (
    <div>
      <Toolbar isLoading={loading} history={history} />
      <div className={classes.head}>
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={classes.root}>
                <Typography variant="h5" gutterBottom className={classes.title}>
                  {translation().views.provider_factory.title}
                </Typography>
                <Stepper activeStep={step} steps={steps} />
              </div>
            </Grid>
          </Grid>
          {renderSteps(step)}
        </Container>
      </div>
    </div>
  );
}

ProviderFactory.propTypes = {
  adminizeCompanyUser: PropTypes.func.isRequired,
  checkUserProviderFactoryAdmin: PropTypes.func.isRequired,
  clearOwnReducer: PropTypes.func.isRequired,
  createCompany: PropTypes.func.isRequired,
  createUserProvider: PropTypes.func.isRequired,
  createProviderAddress: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  getUserProviderPayer: PropTypes.func.isRequired,
  getUserProviderPaymentMethods: PropTypes.func.isRequired,
  history: PropTypes.shape().isRequired,
  linkUserToCompany: PropTypes.func.isRequired,
  linkProviderToProviderCompany: PropTypes.func.isRequired,
  providerFactory: PropTypes.shape({
    company: PropTypes.shape(),
    user: PropTypes.shape(),
    provider: PropTypes.shape(),
    invoice: PropTypes.shape(),
    step: PropTypes.number,
    loading: PropTypes.bool
  }).isRequired,
  selectUserProviderFactoryCompany: PropTypes.func.isRequired,
  setActiveStep: PropTypes.func.isRequired
};

export default ProviderFactory;
