export const setOrdersSearchValue = (value) => ({
  type: 'SET_ORDERS_SEARCH_VALUE',
  value
});

export const refreshOrders = () => ({
  type: 'REFRESH_ORDERS'
});

export const getOrders = (search, next) => ({
  type: 'GET_ORDERS',
  search,
  next
});

export const getOrdersSuccess = (data, paging) => ({
  type: 'GET_ORDERS_SUCCESS',
  data,
  paging
});

export const getOrdersError = (isError) => ({
  type: 'GET_ORDERS_ERROR',
  isError
});

export const clearOwnReducer = () => ({
  type: 'CLEAR_ORDERS_LIST_REDUCER'
});
