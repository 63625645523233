import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';

import { fetchApi } from '../../../../../../utils/functions/api';
import { dateInTz } from '../../../../../../utils/functions/dates';
import translation from '../../../../../../translation/translation';
import PriorityFlag from '../priority/PriorityFlag';

const useStyles = makeStyles(() => ({
  tableContainer: {
    maxHeight: 350
  }
}));

const dataReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...state,
        isLoading: true,
        isError: false
      };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.data,
        paging: action.paging
      };
    case 'FETCH_FAILURE':
      return {
        ...state,
        isLoading: false,
        isError: true
      };
    default:
      return state;
  }
};

const GetDataApi = (getData) => {
  useEffect(() => {
    getData(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const GetNextDataApi = (getData) => {
  const doNext = (initialNext) => {
    if (initialNext) getData(initialNext);
  };

  return { doNext };
};

function TaskHistory(props) {
  const { id, onClose } = props;

  const classes = useStyles();

  const [state, dispatch] = useReducer(dataReducer, {
    isLoading: false,
    isError: false,
    data: [],
    paging: null
  });

  const { isLoading, data, paging } = state;
  function getData(next) {
    if (!id) return;

    dispatch({ type: 'FETCH_INIT' });

    fetchApi(
      'get',
      next ? next : `tasks/${id}/history`,
      null,
      null,
      null,
      (data, paging) => {
        dispatch({
          type: 'FETCH_SUCCESS',
          data: [...state.data, ...data],
          paging
        });
      },
      () => {
        dispatch({ type: 'FETCH_FAILURE' });
      }
    );
  }

  GetDataApi(getData);
  const { doNext } = GetNextDataApi(getData);

  function handleScroll(event) {
    if (!event) console.error('No table scroll event found.');

    if (!event.currentTarget.scrollTop || event.currentTarget.scrollTop === 0) return;

    if (
      event.currentTarget.scrollHeight - event.currentTarget.clientHeight <=
      event.currentTarget.scrollTop
    ) {
      if (!isLoading && paging && paging.next) {
        doNext(paging.next);
      }
    }
  }

  return (
    <Dialog
      open
      fullWidth
      maxWidth="xl"
      onClose={onClose}
      aria-labelledby="task-history"
      disableBackdropClick={isLoading}>
      <DialogTitle id="task-history">
        {translation().views.task_views.tasks.history.title}
      </DialogTitle>
      <DialogContent>
        {data && data.length > 0 ? (
          <TableContainer
            // eslint-disable-next-line react/jsx-no-bind
            onScroll={handleScroll}
            className={classes.tableContainer}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>{translation().views.task_views.tasks.labels.started}</TableCell>
                  <TableCell>{translation().views.task_views.tasks.labels.due}</TableCell>
                  <TableCell>{translation().views.task_views.tasks.labels.priority}</TableCell>
                  <TableCell>
                    {translation().views.task_views.tasks.labels.completion_status}
                  </TableCell>
                  <TableCell>{translation().views.task_views.tasks.labels.team}</TableCell>
                  <TableCell>{translation().views.task_views.tasks.labels.assign_user}</TableCell>
                  <TableCell>{translation().views.task_views.tasks.labels.created}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((item, idx) => (
                  <TableRow key={idx}>
                    <TableCell>{item.iam_user_name ? item.iam_user_name : '-'}</TableCell>
                    <TableCell>
                      {item.due ? dateInTz(item.due, 'localized-datetime-with-seconds') : '-'}
                    </TableCell>
                    <TableCell>
                      {item.priority ? <PriorityFlag value={item.priority} /> : '-'}
                    </TableCell>
                    <TableCell>
                      {item.completion_status && item.completion_status.name
                        ? item.completion_status.name
                        : '-'}
                    </TableCell>
                    <TableCell>
                      {item.assigned_team && item.assigned_team.name
                        ? item.assigned_team.name
                        : '-'}
                    </TableCell>
                    <TableCell>
                      {item.assigned_user &&
                      item.assigned_user.first_name &&
                      item.assigned_user.last_name
                        ? `${item.assigned_user.first_name} ${item.assigned_user.last_name}`
                        : item.assigned_user && item.assigned_user.first_name
                        ? item.assigned_user.first_name
                        : '-'}
                    </TableCell>
                    <TableCell>
                      {dateInTz(item.created, 'localized-datetime-with-seconds')}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          !isLoading && (
            <Typography variant="body2">
              {translation().views.orders.update.products.product_history.no_history}
            </Typography>
          )
        )}
        {state.isLoading && (
          <div className="loader-wpr">
            <CircularProgress size={30} color="secondary" />
            <p>{translation().views.orders.update.products.product_history.loading_text}</p>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="default" disabled={isLoading}>
          {translation().actions.close}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

TaskHistory.propTypes = {
  id: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired
};

export default TaskHistory;
