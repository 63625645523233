import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';

import translation from '../../../../translation/translation';

function TimezoneField(props) {
  const {
    autoFocus,
    clearable,
    className,
    defaultValue,
    id,
    inputClassName,
    disabled,
    error,
    fullWidth,
    helperText,
    label,
    margin,
    multiple,
    name,
    onChange,
    onInputChange,
    placeholder,
    required,
    style,
    variant,
    write
  } = props;

  const [value, setValue] = useState('');
  const [selected, setSelected] = useState(defaultValue ? defaultValue : multiple ? [] : null);
  const [open, setOpen] = useState(false);

  function handleInputChange(event, newInputValue) {
    setValue(newInputValue);

    if (onInputChange) {
      onInputChange(newInputValue);
    }
  }

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleChange(event, value) {
    setSelected(value);

    if (onChange) {
      onChange(value);
    }
  }

  function renderInput(params) {
    return (
      <TextField
        {...params}
        className={inputClassName}
        id={id || name}
        label={label}
        placeholder={
          translation().actions.search +
          ' ' +
          (placeholder ? placeholder.toLowerCase() : label ? label.toLowerCase() : undefined)
        }
        disabled={!write || disabled}
        autoFocus={(!value || value.length <= 0) && autoFocus ? true : false}
        error={error ? true : false}
        required={required}
        variant={variant}
        margin={margin}
        fullWidth={fullWidth}
        name={name}
        InputProps={{
          ...params.InputProps,
          autoComplete: 'off'
        }}
      />
    );
  }

  return (
    <>
      <Autocomplete
        style={!fullWidth ? { ...style, width: 300 } : style}
        open={open}
        disabled={!write || disabled}
        className={className}
        onOpen={handleOpen}
        onClose={handleClose}
        onChange={handleChange}
        onInputChange={handleInputChange}
        options={moment.tz.names()}
        multiple={multiple}
        renderInput={renderInput}
        value={selected}
        disableClearable={!clearable}
        noOptionsText={translation().commons.selectAutoComplete.noOption}
      />
      {helperText && (
        <FormHelperText component="span" style={{ margin: 0 }}>
          {helperText}
        </FormHelperText>
      )}
      {error && (
        <FormHelperText component="span" style={{ margin: 0 }} error>
          {error}
        </FormHelperText>
      )}
    </>
  );
}

TimezoneField.defaultProps = {
  autoFocus: false,
  clearable: true,
  disabled: false,
  fullWidth: true,
  margin: 'normal',
  multiple: false,
  style: {},
  variant: 'standard',
  write: true
};

TimezoneField.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  clearable: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.string,
    PropTypes.number
  ]),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputClassName: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  margin: PropTypes.string,
  multiple: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  style: PropTypes.shape(),
  required: PropTypes.bool,
  variant: PropTypes.string,
  write: PropTypes.bool
};

export default TimezoneField;
