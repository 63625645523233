export const clearOwnReducer = () => ({
  type: 'CLEAR_PROVIDERS_MAP'
});

export const getProvidersProductCoverage = () => ({
  type: 'GET_PROVIDERS_PRODUCTS_COVERAGE'
});

export const getProvidersProductCoverageSuccess = (data) => ({
  type: 'GET_PROVIDERS_PRODUCTS_COVERAGE_SUCCESS',
  data
});

export const getProvidersProductCoverageError = (isError) => ({
  type: 'GET_PROVIDERS_PRODUCTS_COVERAGE_ERROR',
  isError
});
