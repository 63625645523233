import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { makeStyles, withTheme } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Card from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import grey from '@material-ui/core/colors/grey';
import Typography from '@material-ui/core/Typography';

import Alert from '../../../../components/alert/Alert';
import DateRangeField from '../../../../components/forms/fields/customs/dateRangeField/DateRangeField';
import MetricsFormContainer from '../../../../containers/metrics/dashboard/form/MetricsFormContainer';
import UpdateDashboardMetricsBar from './components/UpdateDashboardMetricsBar';
import DateRangeMenu from './components/DateRangeMenu';
import Metric from '../../Metric';
import {
  addToDate,
  alterDate,
  createDate,
  getCurrentDateUtc,
  formatDate
} from '../../../../utils/functions/dates';
import translation from '../../../../translation/translation';

const types = [
  {
    label: 'line + count',
    value: 'line_count',
    type: 'line',
    metric_calc_mode: 'count'
  },
  {
    label: 'line + avg',
    value: 'line_avg',
    type: 'line',
    metric_calc_mode: 'avg'
  },
  {
    label: 'line',
    value: 'line',
    type: 'line'
  },
  {
    label: 'bar',
    value: 'bar',
    type: 'bar'
  },
  {
    label: 'doughnut',
    value: 'doughnut',
    type: 'doughnut'
  },
  {
    label: 'pie',
    value: 'pie',
    type: 'pie'
  },
  {
    label: 'trending',
    value: 'trending',
    type: 'trending',
    metric_calc_mode: 'avg',
    custom: true
  },
  {
    label: 'label',
    value: 'label',
    type: 'label',
    custom: true
  }
];

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  dialogContent: {
    paddingTop: 24
  },
  addNew: {
    fontSize: '3rem',
    color: 'white'
  },
  card: {
    minHeight: 250,
    display: 'flex',
    flexDirection: 'column'
  },
  dateInputRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  dateInputButton: {
    top: 5,
    marginRight: 4
  },
  newMetric: {
    cursor: 'pointer',
    backgroundColor: theme.palette.type === 'dark' ? '#292929' : grey[300],
    transition: '200ms',
    willChange: 'background-color',
    '&:hover': {
      backgroundColor: theme.palette.type === 'dark' ? grey[800] : grey[200]
    }
  },
  metricsWrapper: {
    marginTop: 36
  }
}));

const DragHandle = sortableHandle((props) => (
  <IconButton
    aria-label={`drag-metric-${props.id}`}
    size="small"
    className="metric-action draggable">
    <DragIndicatorIcon fontSize="small" />
  </IconButton>
));

const SortableItem = SortableElement((props) => {
  const {
    id,
    deleteLoadingId,
    name,
    classes,
    submitBuffer,
    metric,
    chartProps,
    queryParams,
    getMetrics,
    theme,
    handleSelectMetric,
    handleRemoveMetric
  } = props;

  return (
    <Grid key={id} item xs={12} sm={12} md={6}>
      <Metric
        className={classes.card}
        submitBuffer={submitBuffer}
        chartProps={{
          name: `metric-dashboard-${id}`,
          title: name,
          ...chartProps
        }}
        queryParams={queryParams}
        defaultType="line"
        routeName={metric}
        getMetrics={getMetrics}
        themeMode={theme.palette.type}
        actions={
          deleteLoadingId && deleteLoadingId === id
            ? [<CircularProgress size={25} key={`loading-metric-${id}`} />]
            : [
                <IconButton
                  key={`edit-metric-${id}`}
                  aria-label={`edit-metric-${id}`}
                  size="small"
                  className="metric-action"
                  onClick={handleSelectMetric}>
                  <EditIcon fontSize="small" />
                </IconButton>,
                <IconButton
                  key={`delete-metric-${id}`}
                  aria-label={`delete-metric-${id}`}
                  size="small"
                  className="metric-action"
                  onClick={handleRemoveMetric}>
                  <DeleteIcon fontSize="small" />
                </IconButton>,
                <DragHandle key={`drag-metric-${id}`} id={id} />
              ]
        }
      />
    </Grid>
  );
});

const SortableList = SortableContainer((props) => {
  const [deleteLoadingId, setDeleteLoadingId] = useState(false);

  const {
    from,
    until,
    data,
    dashboardId,
    deleteDashbardMetric,
    removeMetricFromDashboard,
    handleOpenForm,
    setMetricSelected,
    theme,
    getMetrics,
    classes
  } = props;

  function renderMetrics(values, index) {
    const {
      chart_type,
      criteria,
      compare,
      id,
      metric,
      name,
      position,
      segment,
      submitBuffer,
      time_interval
    } = values;

    const range = {};

    const fromAltered = alterDate(from, null, null, null, '00', '00', '00');
    const untilAltered = alterDate(until, null, null, null, '23', '59', '59');

    range.from = formatDate(fromAltered, 'YYYY-MM-DDTHH:mm:ss');
    range.until = formatDate(untilAltered, 'YYYY-MM-DDTHH:mm:ss');

    let chartProps = {};
    const queryParams = { range };

    if (!metric || !range) return;

    if (compare) {
      queryParams.compare = compare;
    }
    if (time_interval) {
      queryParams.time_interval = time_interval;
    }
    if (segment) {
      queryParams.segment = segment;
    }
    if (criteria) {
      queryParams.filters = criteria;
    }

    for (let i = 0; i < types.length; i++) {
      if (types[i].value === chart_type) {
        chartProps = types[i];
        break;
      }
    }

    function handleRemoveMetric() {
      setDeleteLoadingId(id);

      deleteDashbardMetric(
        dashboardId,
        id,
        () => {
          removeMetricFromDashboard(id);
          setDeleteLoadingId(null);
        },
        () => {
          setDeleteLoadingId(null);
        }
      );
    }

    function handleSelectMetric() {
      setMetricSelected(values);
      handleOpenForm();
    }

    return (
      <SortableItem
        index={position ? position : index}
        key={id}
        id={id}
        classes={classes}
        submitBuffer={submitBuffer}
        metric={metric}
        chartProps={chartProps}
        queryParams={queryParams}
        getMetrics={getMetrics}
        theme={theme}
        name={name}
        handleSelectMetric={handleSelectMetric}
        handleRemoveMetric={handleRemoveMetric}
        deleteLoadingId={deleteLoadingId}
      />
    );
  }

  return (
    <Grid container spacing={2}>
      {data && data.length > 0
        ? data.map((metric, index) => (metric ? renderMetrics(metric, index) : false))
        : false}
      <Grid item xs={12} sm={12} md={6}>
        <ButtonBase
          component={Card}
          onClick={handleOpenForm}
          className={classNames(classes.card, classes.newMetric)}
          elevation={0}>
          <AddCircleIcon className={classes.addNew} />
        </ButtonBase>
      </Grid>
    </Grid>
  );
});

function UpdateDashboardMetrics(props) {
  const {
    clearDashboard,
    createDashboardMetric,
    deleteDashbardMetric,
    getDashboard,
    getMetrics,
    history,
    match,
    moveMetricsIntoDashboard,
    update: { isLoading, data, dateRange, error },
    removeMetricFromDashboard,
    setDashboardDateRange,
    setMetricToDashboard,
    theme,
    updateDashboardMetric,
    updateDashboardMetricPosition
  } = props;

  const classes = useStyles();
  const [isFormLoading, setFormLoading] = useState(false);
  const [from, setFrom] = useState(dateRange && dateRange.from ? createDate(dateRange.from) : null);
  const [until, setUntil] = useState(
    dateRange && dateRange.until ? createDate(dateRange.until) : null
  );
  const [metricSelected, setMetricSelected] = useState(null);
  const [isFormOpen, setFormOpen] = useState(false);

  useEffect(() => {
    document.title = 'Actarus | Dashboard';

    loadDashboard();

    const today = getCurrentDateUtc();
    const todayAltered = alterDate(today, null, null, null, '23', '59', '59');
    const lastMonthDate = addToDate(todayAltered, -1, 'months');
    const lastMonthDateAltered = alterDate(lastMonthDate, null, null, null, '00', '00', '00');
    const from = createDate(lastMonthDateAltered, 'UTC');
    const until = createDate(todayAltered, 'UTC');

    setFrom(from);
    setUntil(until);

    const range = {
      from: formatDate(from, 'YYYY-MM-DDTHH:mm:ss'),
      until: formatDate(until, 'YYYY-MM-DDTHH:mm:ss')
    };

    setDashboardDateRange(range);

    return () => {
      clearDashboard();

      /**
       * On metrics unmounting, we are forced to use MASIVE cancel request for stop every calls launched.
       */
      try {
        window.stop();
      } catch (exception) {
        document.execCommand('Stop');
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function loadDashboard() {
    if (match && match.params && match.params.id) {
      getDashboard(match.params.id);
    }
  }

  function handleRefresh() {
    loadDashboard();
    setMetricSelected(null);
    setFormOpen(false);
  }

  function handleSelectRange({ startDate, endDate }) {
    if (startDate) setFrom(startDate);

    if (endDate) setUntil(endDate);
  }

  function handleSubmitRange() {
    if (from && until) {
      setDashboardDateRange({ from, until });
    }
  }

  function handleOpenForm() {
    setFormOpen(true);
  }

  function handleCloseForm() {
    setMetricSelected(null);
    setFormOpen(false);
  }

  function handleSelectRangeMode(mode) {
    const today = getCurrentDateUtc();
    const todayAltered = alterDate(today, null, null, null, '23', '59', '59');

    const fromDate = addToDate(todayAltered, -1, mode);
    const fromDateAltered = alterDate(fromDate, null, null, null, '00', '00', '00');

    const from = createDate(fromDateAltered, 'UTC');
    const until = createDate(todayAltered, 'UTC');

    setFrom(from);
    setUntil(until);

    const range = {
      from: formatDate(from, 'YYYY-MM-DDTHH:mm:ss'),
      until: formatDate(until, 'YYYY-MM-DDTHH:mm:ss')
    };

    setDashboardDateRange(range);
  }

  function handleSortEnd({ oldIndex, newIndex }) {
    if (!data || data.metrics.length <= 0) {
      return;
    }

    if (oldIndex === newIndex) {
      return;
    }

    const oldArray = data.metrics;

    for (let i = 0; i < oldArray.length; i++) {
      if (oldArray[i].position === oldIndex) {
        /**
         * Check position for find the current metric id
         */
        moveMetricsIntoDashboard(arrayMove(data.metrics, oldIndex, newIndex));
        updateDashboardMetricPosition(match.params.id, oldArray[i].id, newIndex, null, () => {
          /**
           * In case of error, reset the old array for reinit position
           */
          moveMetricsIntoDashboard(oldArray);
        });
        break;
      }
    }
  }

  function handleSubmit(metricObject, id = null, buffer = 0) {
    setFormLoading(true);

    if (id) {
      updateDashboardMetric(
        match.params.id,
        id,
        metricObject,
        buffer,
        (success) => {
          setFormLoading(false);
          handleCloseForm();
          setMetricToDashboard(success);
        },
        () => {
          setFormLoading(false);
        }
      );
    } else {
      createDashboardMetric(
        match.params.id,
        metricObject,
        (success) => {
          setFormLoading(false);
          handleCloseForm();
          setMetricToDashboard(success);
        },
        () => {
          setFormLoading(false);
        }
      );
    }
  }

  return (
    <div className="UpdateDashboardMetrics">
      <UpdateDashboardMetricsBar
        loading={isLoading}
        history={history}
        dashboardName={data.name}
        handleRefresh={handleRefresh}
      />
      {error && (
        <div className="error-list">
          <Alert
            type="danger"
            style={{ marginTop: 10 }}
            status={translation().commons.alerts.error}
            text={translation().core.list.error_list}
          />
        </div>
      )}
      {isLoading ? (
        <div className="loader-wpr">
          <CircularProgress color="primary" />
          <p>{translation().metrics.dashboard.update.metrics.get.loading_text}</p>
        </div>
      ) : (
        <div className={classes.root}>
          <Container>
            <Grid container spacing={2} alignItems="flex-end">
              <Grid item xs={12} sm={12} md={4}>
                <Typography variant="h5">{data.name || 'Missing dashboard name'}</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} className={classes.dateInputRow}>
                <DateRangeMenu
                  classes={classes}
                  disabled={isFormLoading || isLoading}
                  onSelectItem={handleSelectRangeMode}
                />
                <DateRangeField
                  endDate={until}
                  endDateId="update_new_metrics_end_date"
                  startDate={from}
                  startDateId="update_new_metrics_start_date"
                  onSelectRange={handleSelectRange}
                  fullWidth
                  orientation="horizontal"
                  disableWithFullScreenPortal
                  disabled={isFormLoading || isLoading}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmitRange}
                  disabled={!from || !until}>
                  {translation().actions.confirm}
                </Button>
              </Grid>
            </Grid>
            <div className={classes.metricsWrapper}>
              <SortableList
                classes={classes}
                axis="xy"
                data={data.metrics}
                removeMetricFromDashboard={removeMetricFromDashboard}
                handleOpenForm={handleOpenForm}
                setMetricSelected={setMetricSelected}
                getMetrics={getMetrics}
                theme={theme}
                from={from}
                until={until}
                useDragHandle
                onSortEnd={handleSortEnd}
                deleteDashbardMetric={deleteDashbardMetric}
                dashboardId={match.params.id}
              />
            </div>
          </Container>
        </div>
      )}
      <Dialog
        open={isFormOpen}
        onClose={handleCloseForm}
        fullWidth
        maxWidth="md"
        disableBackdropClick={isFormLoading}
        aria-labelledby="update-new-metric-dialog">
        <DialogTitle id="update-new-metric-dialog">
          {metricSelected
            ? translation().metrics.dashboard.update.metrics.update.title
            : translation().metrics.dashboard.update.metrics.create.title}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <MetricsFormContainer
            onSumbit={handleSubmit}
            initialValues={metricSelected}
            disabled={isFormLoading}
            types={types}
          />
          {isFormLoading && (
            <div className="loader-wpr">
              <CircularProgress color="primary" />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseForm} disabled={isFormLoading} color="default">
            {translation().actions.close}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

UpdateDashboardMetrics.propTypes = {
  clearDashboard: PropTypes.func.isRequired,
  createDashboardMetric: PropTypes.func.isRequired,
  deleteDashbardMetric: PropTypes.func.isRequired,
  getDashboard: PropTypes.func.isRequired,
  getMetrics: PropTypes.func.isRequired,
  history: PropTypes.shape().isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    }).isRequired
  }).isRequired,
  moveMetricsIntoDashboard: PropTypes.func.isRequired,
  update: PropTypes.shape({
    data: PropTypes.shape(),
    error: PropTypes.string,
    isLoading: PropTypes.bool,
    dateRange: PropTypes.shape()
  }).isRequired,
  removeMetricFromDashboard: PropTypes.func.isRequired,
  setDashboardDateRange: PropTypes.func.isRequired,
  setMetricToDashboard: PropTypes.func.isRequired,
  theme: PropTypes.shape().isRequired,
  updateDashboardMetric: PropTypes.func.isRequired,
  updateDashboardMetricPosition: PropTypes.func.isRequired
};

export default withTheme(UpdateDashboardMetrics);
