import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';

import Alert from '../../../../../components/alert/Alert';
import translation from '../../../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2)
  },
  tableContainer: {
    maxHeight: 350
  }
}));

function Blacklist(props) {
  const classes = useStyles();

  const { blacklist } = props;

  return (
    <div className={classNames(classes.root, 'ProviderBlacklist')}>
      {blacklist && blacklist.length > 0 ? (
        <TableContainer className={classNames(classes.tableContainer, 'FixedHeaderTableContainer')}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>
                  {translation().views.provider_view.blacklist.table.first_name}
                </TableCell>
                <TableCell>{translation().views.provider_view.blacklist.table.last_name}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {blacklist.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.first_name ? item.first_name : '-'}</TableCell>
                  <TableCell>{item.last_name ? item.last_name : '-'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Alert
          type="info"
          status={translation().commons.alerts.info}
          text={translation().views.provider_view.blacklist.empty}
        />
      )}
    </div>
  );
}

Blacklist.propTypes = {
  blacklist: PropTypes.arrayOf(PropTypes.any)
};

export default Blacklist;
