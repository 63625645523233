import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import EuroIcon from '@material-ui/icons/Euro';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import PricingFields from './PricingFields';
import Simulator from './Simulator';
import translation from '../../../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    minHeight: 60,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.cardHeader
  },
  cardContent: {
    padding: theme.spacing(1)
  },
  paper: {
    minHeight: 400,
    overflow: 'auto',
    width: '100%',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      minHeight: 'auto'
    }
  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2)
  },
  listItemBox: {
    minWidth: 35
  },
  listItem: {
    padding: 4
  }
}));

function Pricing(props) {
  const classes = useStyles();

  const { product, user } = props;

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Card className={classes.paper}>
            <CardHeader
              className={classes.cardHeader}
              title={translation().views.product_view.pricing.infos.title}
              titleTypographyProps={{
                variant: 'h5',
                style: {
                  fontSize: 21
                }
              }}
              avatar={
                <Avatar>
                  <EuroIcon />
                </Avatar>
              }
            />
            <CardContent className={classes.cardContent}>
              <List>
                {product.price_fixed ? (
                  <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemBox}>
                      <EuroIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={translation().views.product_view.pricing.infos.price}
                      secondary={product.price_fixed}
                    />
                  </ListItem>
                ) : (
                  false
                )}
                {product.tax_rule && product.tax_rule.name && (
                  <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemBox}>
                      <AccountBalanceIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={translation().views.product_view.pricing.infos.tax_rule}
                      secondary={product.tax_rule.name}
                    />
                  </ListItem>
                )}
              </List>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={8}>
          <Card className={classes.paper}>
            <CardHeader
              className={classes.cardHeader}
              title={translation().views.product_view.pricing.pricing_fields.title}
              titleTypographyProps={{
                variant: 'h5',
                style: {
                  fontSize: 21
                }
              }}
              avatar={
                <Avatar>
                  <Icon>price_change</Icon>
                </Avatar>
              }
            />
            <CardContent className={classes.cardContent}>
              <PricingFields fields={product.pricing_fields} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card className={classes.paper}>
            <CardHeader
              className={classes.cardHeader}
              title={translation().views.product_view.pricing.simulator.title}
              titleTypographyProps={{
                variant: 'h5',
                style: {
                  fontSize: 21
                }
              }}
              avatar={
                <Avatar>
                  <Icon>calculate</Icon>
                </Avatar>
              }
            />
            <CardContent className={classes.cardContent}>
              <Simulator
                productId={product.id}
                groupId={
                  product.product_group && product.product_group.id
                    ? product.product_group.id
                    : null
                }
                user={user}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

Pricing.propTypes = {
  product: PropTypes.shape(),
  user: PropTypes.shape().isRequired
};

export default Pricing;
