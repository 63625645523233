import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';

import FormApiAutocompleteField from '../../../components/forms/fields/FormApiAutocompleteField';
import FormSelectField from '../../../components/forms/fields/FormSelectField';
import TimezoneField from '../../../components/forms/fields/customs/TimezoneField';
import translation from '../../../translation/translation';
import config from '../../../config';

const useStyles = makeStyles((theme) => ({
  fieldsWrapper: {
    margin: theme.spacing(1, 0, 5)
  }
}));

function Settings(props) {
  const { defaultValues, onChange, onChangeAutocomplete, profile } = props;

  const classes = useStyles();

  return (
    <div className={classes.fieldsWrapper}>
      <TimezoneField
        name="timezone_code"
        id="profile-timezone_code"
        label={translation().views.profile.form.labels.timezone}
        onInputChange={onChange('timezone_code')}
        defaultValue={defaultValues.timezone_code}
        error={
          profile.errors && profile.errors['timezone_code'] ? profile.errors['timezone_code'] : null
        }
        disabled={profile.isLoading}
        required
      />
      <FormApiAutocompleteField
        name="language_locale"
        id="language_locale"
        label={translation().views.profile.form.labels.language}
        links={[{ rel: 'list', href: `${config.api_url}languages` }]}
        query={{ filters: 'active|eq|1' }}
        onSelectValue={onChangeAutocomplete('language_locale', 'locale')}
        targetKey="locale"
        defaultValue={defaultValues.language_locale}
        error={
          profile.errors && profile.errors['language_locale']
            ? profile.errors['language_locale']
            : null
        }
        disabled={profile.isLoading}
        required
      />
      <FormApiAutocompleteField
        name="currency_isocode"
        id="currency_isocode"
        label={translation().views.profile.form.labels.currency}
        links={[{ rel: 'list', href: `${config.api_url}currencies` }]}
        query={{ filters: 'active|eq|1' }}
        onSelectValue={onChangeAutocomplete('currency_isocode', 'isocode')}
        targetKey="isocode"
        defaultValue={defaultValues.currency_isocode}
        error={
          profile.errors && profile.errors['currency_isocode']
            ? profile.errors['currency_isocode']
            : null
        }
        disabled={profile.isLoading}
        required
      />
      <FormApiAutocompleteField
        name="citizen_country_isocode"
        id="citizen_country_isocode"
        label={translation().views.profile.form.labels.citizen_country}
        links={[{ rel: 'list', href: `${config.api_url}countries` }]}
        onSelectValue={onChangeAutocomplete('citizen_country_isocode', 'isocode')}
        targetKey="isocode"
        defaultValue={defaultValues.citizen_country_isocode}
        error={
          profile.errors && profile.errors['citizen_country_isocode']
            ? profile.errors['citizen_country_isocode']
            : null
        }
        disabled={profile.isLoading}
        required
      />
      <FormApiAutocompleteField
        id="residence_country_isocode"
        name="residence_country_isocode"
        label={translation().views.profile.form.labels.residence_country}
        links={[{ rel: 'list', href: `${config.api_url}countries` }]}
        onSelectValue={onChangeAutocomplete('residence_country_isocode', 'isocode')}
        targetKey="isocode"
        defaultValue={defaultValues.residence_country_isocode}
        error={
          profile.errors && profile.errors['residence_country_isocode']
            ? profile.errors['residence_country_isocode']
            : null
        }
        disabled={profile.isLoading}
        required
      />
      <FormSelectField
        required
        displayEmpty
        id="profile-unit_system"
        name="unit_system"
        label={translation().views.profile.form.labels.unit_system}
        defaultValue={defaultValues.unit_system}
        error={
          profile.errors && profile.errors['unit_system'] ? profile.errors['unit_system'] : null
        }
        disabled={profile.isLoading}
        onChange={onChange('unit_system')}>
        {['metric', 'imperial', 'imperial_us'].map((choice, indexItem) => {
          return (
            <MenuItem key={indexItem} value={choice}>
              {choice}
            </MenuItem>
          );
        })}
      </FormSelectField>
    </div>
  );
}

Settings.propTypes = {
  defaultValues: PropTypes.shape({
    timezone_code: PropTypes.string,
    language_locale: PropTypes.shape({}),
    currency_isocode: PropTypes.shape({}),
    citizen_country_isocode: PropTypes.shape({}),
    residence_country_isocode: PropTypes.shape({}),
    unit_system: PropTypes.string
  }),
  onChange: PropTypes.func.isRequired,
  onChangeAutocomplete: PropTypes.func.isRequired,
  profile: PropTypes.shape({
    isLoading: PropTypes.bool,
    errors: PropTypes.shape()
  }).isRequired
};

export default Settings;
