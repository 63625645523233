import React from 'react';

import CoreMetricsContainer from '../../../../../containers/metrics/CoreMetricsContainer';
import productIdMetrics from '../../../../../routes/metrics/productIdMetrics';

function ProductMetrics() {
  return <CoreMetricsContainer embed mapping={productIdMetrics} />;
}

export default ProductMetrics;
