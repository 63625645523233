import './sass/bigCalendar.scss';
import './sass/schedule.scss';

import React, { Component } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';

import {
  addToDate,
  alterDate,
  createDateUtc,
  dateInTz,
  formatDate,
  getDateRangeOfWeek,
  getWeekNumberFromJsDate,
  removeFromDate,
  toJsDate
} from '../../../../utils/functions/dates';
import { getLocale } from '../../../../utils/functions/cookies';
import CreateAvailabilityPopup from './components/modals/CreateAvailabilityPopup';
import CreateUnavailabilityPopup from './components/modals/CreateUnavailabilityPopup';
import Schedule from './components/Schedule';
import ScheduleEventWeek from './components/events/ScheduleEventWeek';
import ScheduleEventMonth from './components/events/ScheduleEventMonth';
import ScheduleEventPopup from './components/modals/ScheduleEventPopup';
import ScheduleToolbar from './components/ScheduleToolbar';
import ScheduleViewBar from './components/ScheduleViewBar';
import translation from '../../../../translation/translation';

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

class ProviderSchedule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      event: null,
      isPopupEventOpen: false,
      isCreateAvailabilityOpen: false,
      isCreateUnavailabilityOpen: false,
      timezone: null
    };

    this.minHour = 7;
    this.maxHour = 22;
    this.minTime = new Date();
    this.minTime.setHours(this.minHour, 0, 0);
    this.maxTime = new Date();
    this.maxTime.setHours(this.maxHour, 30, 0);

    this.step = 30;
    this.timeslots = 1;
    this.defaultView = window.innerWidth < 768 ? 'day' : this.props.defaultView;
    this.defaultDate = new Date(formatDate(createDateUtc(), 'iso'));

    this.messages = {
      allDay: '',
      previous: translation().views.provider_schedule.calendar.previous,
      next: translation().views.provider_schedule.calendar.next,
      today: translation().views.provider_schedule.calendar.today,
      month: translation().views.provider_schedule.calendar.month,
      week: translation().views.provider_schedule.calendar.week,
      day: translation().views.provider_schedule.calendar.day,
      agenda: translation().views.provider_schedule.calendar.agenda,
      date: translation().views.provider_schedule.calendar.date,
      time: translation().views.provider_schedule.calendar.time,
      event: translation().views.provider_schedule.calendar.event,
      showMore: (nbSlots) => {
        return '+' + nbSlots;
      }
    };

    this.formats = {
      timeGutterFormat: 'HH:mm',
      dayFormat: 'ddd D/MM',
      dayHeaderFormat: 'dddd DD MMM YYYY',
      dayRangeHeaderFormat: ({ start, end }) =>
        translation().views.provider_schedule.calendar.week +
        ' ' +
        start.toLocaleDateString(getLocale(), {
          month: 'long',
          day: 'numeric'
        }) +
        ' - ' +
        end.toLocaleDateString(getLocale(), {
          month: 'long',
          day: 'numeric',
          year: 'numeric'
        })
    };

    this.convertDataIntoEvent = this.convertDataIntoEvent.bind(this);
    this.getDrilldownView = this.getDrilldownView.bind(this);
    this.selectSlot = this.selectSlot.bind(this);
    this.setDayTimeRange = this.setDayTimeRange.bind(this);
    this.setWeekTimeRange = this.setWeekTimeRange.bind(this);
    this.setMonthTimeRange = this.setMonthTimeRange.bind(this);
    this.loadEventsView = this.loadEventsView.bind(this);

    this.handleMoveEvent = this.handleMoveEvent.bind(this);
    this.handleResizeEvent = this.handleResizeEvent.bind(this);
    this.handleNavigate = this.handleNavigate.bind(this);
    this.handleRangeChange = this.handleRangeChange.bind(this);
    this.handleSelectEvent = this.handleSelectEvent.bind(this);
    this.handleView = this.handleView.bind(this);
    this.handleCloseEventPopup = this.handleCloseEventPopup.bind(this);
    this.handleCloseCreateAvailability = this.handleCloseCreateAvailability.bind(this);
    this.handleOpenCreateUnavailability = this.handleOpenCreateUnavailability.bind(this);
    this.handleCloseCreateUnavailability = this.handleCloseCreateUnavailability.bind(this);
    this.handleSelectUserTimezone = this.handleSelectUserTimezone.bind(this);

    this.createAvailability = this.createAvailability.bind(this);
    this.createUnavailability = this.createUnavailability.bind(this);
    this.editAvailability = this.editAvailability.bind(this);
    this.editUnavailability = this.editUnavailability.bind(this);
    this.removeAvailability = this.removeAvailability.bind(this);
    this.removeUnavailability = this.removeUnavailability.bind(this);
    this.renderCreateAvailability = this.renderCreateAvailability.bind(this);
    this.renderEventPopup = this.renderEventPopup.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.loadEventsView(this.defaultView);
    this.props.setCurrentView(this.defaultView);
  }

  componentDidMount() {
    Modal.setAppElement('#iframes-container');
  }

  componentWillUnmount() {
    this.props.clearSchedule();
  }

  convertDataIntoEvent(events, timezone) {
    if (!events || events.length <= 0) return [];

    const providerEvents = [];

    /**
     * Check all event types for build the right provider event
     */
    for (let i = 0; i < events.length; i++) {
      const timezoneCode =
        events[i].location && events[i].location.timezone_code
          ? events[i].location.timezone_code
          : undefined;

      if (events[i].type && events[i].type === 'availability') {
        const event = {
          type: 'availability',
          title: translation().views.provider_schedule.availability.edit.title,
          writable: false,
          deletable: true,
          id: events[i].id
            ? events[i].id
            : events[i]['details'] && events[i]['details']['id']
            ? events[i]['details']['id']
            : undefined,
          className: 'event-availability',
          draggable: true,
          onOpenCreateUnavailability: this.handleOpenCreateUnavailability
        };

        if (events[i]['start']) {
          event.start = toJsDate(dateInTz(events[i]['start'], null, timezone));
        }

        if (events[i]['end']) {
          event.end = toJsDate(dateInTz(events[i]['end'], null, timezone));
        }

        providerEvents.push(event);
      } else if (events[i].type && events[i].type === 'unavailability') {
        const event = {
          type: 'unavailability',
          title: events[i].title || null,
          writable: false,
          deletable: true,
          location: events[i].location ? events[i].location : null,
          id: events[i].id
            ? events[i].id
            : events[i]['details'] && events[i]['details']['id']
            ? events[i]['details']['id']
            : undefined,
          className: 'event-unavailability',
          draggable: true
        };

        if (events[i]['start']) {
          event.start = toJsDate(dateInTz(events[i]['start'], null, timezone));
        }

        if (events[i]['end']) {
          event.end = toJsDate(dateInTz(events[i]['end'], null, timezone));
        }

        providerEvents.push(event);
      } else if (events[i].type && events[i].type === 'order_product') {
        const event = {
          type: 'orderProduct',
          title: events[i]['title'] ? events[i]['title'] : 'Prestation',
          writable: false,
          deletable: false,
          order_id: null,
          className: 'event-order-product',
          id:
            events[i]['details'] && events[i]['details']['id']
              ? events[i]['details']['id']
              : undefined,
          timezone_code: timezoneCode,
          product: {
            duration: null,
            name: null,
            property_size_formatted: null,
            icon_url_light: null,
            icon_url_dark: null,
            property: {},
            property_access_details: null,
            property_contact_comment: null,
            property_contact_name: null,
            property_contact_phone: null,
            delivery_status: {},
            status: {},
            options: []
          }
        };

        if (events[i]['start']) {
          event.start = toJsDate(dateInTz(events[i]['start'], null, timezone));
          event.local_start = dateInTz(events[i]['start'], undefined, timezoneCode);
        }

        if (events[i]['end']) {
          event.end = toJsDate(dateInTz(events[i]['end'], null, timezone));
          event.local_end = dateInTz(events[i]['end'], undefined, timezoneCode);
        }

        if (events[i]['details'] && events[i]['details']['order_id'])
          event.order_id = events[i]['details']['order_id'];

        if (events[i]['title']) event.product.name = events[i]['title'];

        if (events[i]['details'] && events[i]['details']['duration'])
          event.product.duration = events[i]['details']['duration'];

        if (events[i]['details'] && events[i]['details']['property_access_details'])
          event.product.property_access_details = events[i]['details']['property_access_details'];

        if (events[i]['details'] && events[i]['details']['property_contact_comment'])
          event.product.property_contact_comment = events[i]['details']['property_contact_comment'];

        if (events[i]['details'] && events[i]['details']['property_contact_name'])
          event.product.property_contact_name = events[i]['details']['property_contact_name'];

        if (events[i]['details'] && events[i]['details']['property_size_formatted'])
          event.product.property_size_formatted = events[i]['details']['property_size_formatted'];

        if (events[i]['details'] && events[i]['details']['property_contact_phone'])
          event.product.property_contact_phone = events[i]['details']['property_contact_phone'];

        if (events[i]['status']) {
          event.product.status = events[i]['status'];

          if (events[i]['status']['shortcode'] && events[i]['status']['shortcode'] === 'planned')
            event.className = 'event-order-product inc';
          else if (events[i]['status']['shortcode'] && events[i]['status']['shortcode'] === 'done')
            event.className = 'event-order-product todo';
          else event.className = 'event-order-product archived';
        }

        if (events[i]['details'] && events[i]['details']['options'])
          event.product.options = events[i]['details']['options'].split(',');

        if (events[i]['location']) event.product.property = events[i]['location'];

        providerEvents.push(event);
      } else if (events[i].type && events[i].type === 'cart_product') {
        const event = {
          type: 'cartProduct',
          title: translation().views.provider_schedule.product_in_cart,
          id: events[i]['id'] ? events[i]['id'] : undefined,
          writable: false,
          deletable: false,
          className: 'event-cart-product',
          product: {
            name: translation().views.provider_schedule.product_in_cart
          }
        };

        if (events[i]['start'])
          event.start = toJsDate(dateInTz(events[i]['start'], null, timezone));

        if (events[i]['end']) event.end = toJsDate(dateInTz(events[i]['end'], null, timezone));

        providerEvents.push(event);
      }
    }

    return providerEvents;
  }

  selectSlot(slotInfo) {
    this.setState({
      isCreateAvailabilityOpen: true,
      slotInfo
    });
  }

  handleSelectEvent(event) {
    this.setState({
      isPopupEventOpen: true,
      event
    });
  }

  handleSelectUserTimezone(code) {
    const { setCurrentTimezone } = this.props;

    setCurrentTimezone(code);
  }

  createAvailability(values, slot) {
    if (!values || !slot) return;

    const {
      match: {
        params: { id }
      },
      schedule: { timezone },
      postAvailability
    } = this.props;

    postAvailability(id, values, slot, timezone, this.handleCloseCreateAvailability);
  }

  createUnavailability(values) {
    const { event } = this.state;
    const {
      match: {
        params: { id }
      },
      schedule: { timezone },
      postUnavailability
    } = this.props;

    if (!event || !event.start || !values) return;

    postUnavailability(id, event.start, values, timezone, this.handleCloseCreateUnavailability);
  }

  editAvailability(id, values, eventStart) {
    const {
      match: { params },
      schedule: { timezone },
      putAvailability
    } = this.props;

    if (!params || !params.id || !id || !values || !eventStart) return;

    putAvailability(params.id, id, values, eventStart, timezone, this.handleCloseEventPopup);
  }

  editUnavailability(event, values) {
    const {
      match: { params },
      schedule: { timezone },
      putUnavailability
    } = this.props;

    if (!params || !params.id || !event || !event.start || !event.id || !values) return;

    putUnavailability(
      params.id,
      event.id,
      event.start,
      values,
      timezone,
      this.handleCloseEventPopup
    );
  }

  handleCloseEventPopup() {
    this.setState({
      isPopupEventOpen: false,
      event: null
    });
  }

  handleCloseCreateAvailability() {
    this.setState({
      isCreateAvailabilityOpen: false,
      slotInfo: null
    });
  }

  handleOpenCreateUnavailability(event) {
    this.setState({
      isCreateUnavailabilityOpen: true,
      event
    });
  }

  handleCloseCreateUnavailability() {
    this.setState({
      isCreateUnavailabilityOpen: false,
      event: null
    });
  }

  handleView(view) {
    this.props.setCurrentView(view);
  }

  handleRangeChange() {}

  handleMoveEvent({ event, start, end }) {
    const {
      resizeAvailability,
      resizeUnavailability,
      match: { params },
      schedule: { timezone }
    } = this.props;

    if (!event && !event.id) return;
    if (!params || !params.id) return;

    if (event.type && event.type === 'availability') {
      resizeAvailability(params.id, event, start, end, timezone);
    } else if (event.type && event.type === 'unavailability') {
      resizeUnavailability(params.id, event, start, end, timezone);
    }
  }

  handleResizeEvent({ event, start, end }) {
    const {
      resizeAvailability,
      resizeUnavailability,
      match: { params },
      schedule: { timezone }
    } = this.props;

    if (!event && !event.id) return;
    if (!params || !params.id) return;

    if (event.type && event.type === 'availability') {
      resizeAvailability(params.id, event, start, end, timezone);
    } else if (event.type && event.type === 'unavailability') {
      resizeUnavailability(params.id, event, start, end, timezone);
    }
  }

  getDrilldownView() {
    /**
     * Important! : return null for block day redirect that make 2 onNavigates so 2 calls Api
     * Params: (targetDate, currentViewName, configuredViewNames)
     */
    return null;
  }

  handleNavigate(date, view, action = '!date') {
    const clonedDate = new Date(date.getTime());

    if (view === 'day' || action.toLowerCase() === 'date' || action === 'DATE') {
      this.setDayTimeRange(clonedDate);
    } else {
      this.loadEventsView(view, clonedDate);
    }
  }

  loadEventsView(view, providedDate) {
    const eventDate = providedDate ? providedDate : new Date();

    if (view === 'month') this.setMonthTimeRange(eventDate);
    else if (view === 'week') this.setWeekTimeRange(eventDate);
    else if (view === 'day') this.setDayTimeRange(eventDate);
  }

  setDayTimeRange(date) {
    if (!date) return;

    const {
      getProviderEvents,
      setCurrentTimeRange,
      match: {
        params: { id }
      }
    } = this.props;

    /**
     * Clone the date for not alter calendar current date
     * Get the week number from current date (JS date)
     * Then get the current week TimeRange from week number
     * Set start and end for have { start: ../../../00:00:00 , end: (../../../23:59:59) }
     */
    const clonedDate = new Date(date.getTime());

    const start = alterDate(clonedDate, null, null, null, '0', '0', '0', 'YYYY-MM-DDTHH:mm:ss');
    const end = alterDate(clonedDate, null, null, null, '23', '59', '59', 'YYYY-MM-DDTHH:mm:ss');

    setCurrentTimeRange({ start, end });
    getProviderEvents(id, { start, end });
  }

  setMonthTimeRange(date) {
    if (!date) return;

    const {
      getProviderEvents,
      setCurrentTimeRange,
      match: {
        params: { id }
      }
    } = this.props;

    /**
     * Clone the date for not alter calendar current date
     * Check type of view for GET TimeRange start & end
     * Set start and end for have { start: ../../../00:00:00 , end: (../../../23:59:59) }
     * Remove 6 days from start and Add 6 days to end, for have all slots on months views
     */
    const clonedDate = new Date(date.getTime());

    const firstDay = new Date(clonedDate.getFullYear(), clonedDate.getMonth(), 1);
    const lastDay = new Date(clonedDate.getFullYear(), clonedDate.getMonth() + 1, 0);

    const startTimeRangeUtc = createDateUtc(firstDay);
    const endTimeRangeUtc = createDateUtc(lastDay);

    const dateUtcStartAltered = alterDate(startTimeRangeUtc, null, null, null, '0', '0', '0');
    const dateUtcEndAltered = alterDate(endTimeRangeUtc, null, null, null, '23', '59', '59');

    const start = removeFromDate(dateUtcStartAltered, '5', 'day', 'YYYY-MM-DDTHH:mm:ssZ');
    const end = addToDate(dateUtcEndAltered, '7', 'day', 'YYYY-MM-DDTHH:mm:ssZ');

    setCurrentTimeRange({ start, end });
    getProviderEvents(id, { start, end });
  }

  setWeekTimeRange(date) {
    if (!date) return;

    const {
      getProviderEvents,
      setCurrentTimeRange,
      match: {
        params: { id }
      }
    } = this.props;

    /**
     * Clone the date for not alter calendar current date
     * Get the week number from current date (JS date)
     * Then get the current week TimeRange from week number
     * Set start and end for have { start: ../../../00:00:00 , end: (../../../23:59:59) }
     */
    const clonedDate = new Date(date.getTime());

    const weekNbr = getWeekNumberFromJsDate(clonedDate);

    const weekTimeRange = getDateRangeOfWeek(clonedDate, weekNbr);
    const startTimeRangeUtc = createDateUtc(weekTimeRange.start);
    const endTimeRangeUtc = createDateUtc(weekTimeRange.end);

    const dateUtcStartAltered = alterDate(startTimeRangeUtc, null, null, null, '0', '0', '0');
    const dateUtcEndAltered = alterDate(endTimeRangeUtc, null, null, null, '23', '59', '59');

    const start = formatDate(dateUtcStartAltered, 'YYYY-MM-DDTHH:mm:ssZ');
    const end = formatDate(dateUtcEndAltered, 'YYYY-MM-DDTHH:mm:ssZ');

    setCurrentTimeRange({ start, end });
    getProviderEvents(id, { start, end });
  }

  removeAvailability(id) {
    const {
      match: { params },
      deleteAvailability
    } = this.props;

    if (!params || !params.id || !id) return;

    deleteAvailability(params.id, id, this.handleCloseEventPopup);
  }

  removeUnavailability(id) {
    const {
      match: { params },
      deleteUnavailability
    } = this.props;

    if (!params || !params.id || !id) return;

    deleteUnavailability(params.id, id, this.handleCloseEventPopup);
  }

  renderCreateAvailability(slotInfo, isOpen) {
    if (!slotInfo) return;

    const createAvailabilityLoading = this.props.schedule.availabilities.create.loading
      ? this.props.schedule.availabilities.create.loading
      : false;

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={this.handleCloseCreateAvailability}
        style={modalStyles}>
        <CreateAvailabilityPopup
          slot={slotInfo}
          onClose={this.handleCloseCreateAvailability}
          createAvailability={this.createAvailability}
          createAvailabilityLoading={createAvailabilityLoading}
          minHour={this.minHour}
          maxHour={this.maxHour}
        />
      </Modal>
    );
  }

  renderEventPopup(event) {
    if (!event || event.type === 'cartProduct') return;

    const {
      getOrderProductTripDistance,
      schedule: { availabilities, unavailabilities }
    } = this.props;

    const deleteAvailabilityLoading = availabilities.delete.loading
      ? availabilities.delete.loading
      : false;
    const editAvailabilityLoading = availabilities.edit.loading
      ? availabilities.edit.loading
      : false;
    const deleteUnavailabilityLoading = unavailabilities.delete.loading
      ? unavailabilities.delete.loading
      : false;
    const editUnavailabilityLoading = unavailabilities.edit.loading
      ? unavailabilities.edit.loading
      : false;

    return (
      <Modal
        isOpen={this.state.isPopupEventOpen}
        onRequestClose={this.handleCloseEventPopup}
        style={modalStyles}>
        <ScheduleEventPopup
          editAvailability={this.editAvailability}
          editAvailabilityLoading={editAvailabilityLoading}
          editUnavailability={this.editUnavailability}
          editUnavailabilityLoading={editUnavailabilityLoading}
          event={event}
          deleteAvailabilityLoading={deleteAvailabilityLoading}
          deleteUnavailabilityLoading={deleteUnavailabilityLoading}
          onClose={this.handleCloseEventPopup}
          removeAvailability={this.removeAvailability}
          removeUnavailability={this.removeUnavailability}
          getOrderProductTripDistance={getOrderProductTripDistance}
          minHour={this.minHour}
          maxHour={this.maxHour}
        />
      </Modal>
    );
  }

  render() {
    const { event, isCreateAvailabilityOpen, isCreateUnavailabilityOpen, slotInfo } = this.state;

    const { currentView, events, unavailabilities } = this.props.schedule;

    const {
      clearCurrentTimezone,
      embed,
      history,
      getProviderInfos,
      match: {
        params: { id }
      }
    } = this.props;

    const createUnavailabilityLoading = unavailabilities.create.loading
      ? unavailabilities.create.loading
      : false;
    const providerEvents = this.convertDataIntoEvent(events.list, this.props.schedule.timezone);

    return (
      <div className="Schedule">
        {!embed && <ScheduleViewBar history={history} isLoading={events.loading} resourceId={id} />}
        <div className="schedule-wrapper">
          {events.loading && (
            <div className="schedule-load-overlay">
              <CircularProgress style={{ color: '#FFFFFF' }} size={70} />
            </div>
          )}
          <Container className={classNames('calendar-wrapper', { embed })}>
            <div className="calendar-container">
              <Schedule
                defaultView={this.defaultView}
                defaultDate={this.defaultDate}
                events={providerEvents}
                formats={this.formats}
                getDrilldownView={this.getDrilldownView}
                maxTime={this.maxTime}
                minTime={this.minTime}
                messages={this.messages}
                onNavigate={this.handleNavigate}
                onRangeChange={this.handleRangeChange}
                selectSlot={this.selectSlot}
                onSelectEvent={this.handleSelectEvent}
                onView={this.handleView}
                onEventResize={this.handleResizeEvent}
                onEventDrop={this.handleMoveEvent}
                step={this.step}
                timeslots={this.timeslots}
                resizable
                currentView={currentView}
                components={{
                  week: {
                    event: ScheduleEventWeek
                  },
                  day: {
                    event: ScheduleEventWeek
                  },
                  month: {
                    event: ScheduleEventMonth
                  },
                  toolbar: (args) => (
                    <ScheduleToolbar
                      {...args}
                      messages={this.messages}
                      providerId={id}
                      providerInfos={this.props.schedule.provider_infos}
                      timezone={this.props.schedule.timezone}
                      getProviderInfos={getProviderInfos}
                      onSelectUserTimezone={this.handleSelectUserTimezone}
                      onUnselectUserTimezone={clearCurrentTimezone}
                    />
                  )
                }}
              />
            </div>
          </Container>
          {this.renderCreateAvailability(slotInfo, isCreateAvailabilityOpen)}
          {this.renderEventPopup(event)}
          <Modal
            isOpen={isCreateUnavailabilityOpen}
            onRequestClose={this.handleCloseCreateUnavailability}
            style={modalStyles}>
            <CreateUnavailabilityPopup
              createUnavailability={this.createUnavailability}
              createUnavailabilityLoading={createUnavailabilityLoading}
              onClose={this.handleCloseCreateUnavailability}
              maxHour={this.maxHour}
              minHour={this.minHour}
            />
          </Modal>
        </div>
      </div>
    );
  }
}

ProviderSchedule.defaultProps = {
  defaultView: 'week',
  embed: false
};

ProviderSchedule.propTypes = {
  clearSchedule: PropTypes.func.isRequired,
  clearCurrentTimezone: PropTypes.func.isRequired,
  defaultView: PropTypes.string,
  deleteAvailability: PropTypes.func.isRequired,
  deleteUnavailability: PropTypes.func.isRequired,
  embed: PropTypes.bool,
  getProviderEvents: PropTypes.func.isRequired,
  getProviderInfos: PropTypes.func.isRequired,
  getOrderProductTripDistance: PropTypes.func.isRequired,
  history: PropTypes.shape().isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    }).isRequired
  }).isRequired,
  postAvailability: PropTypes.func.isRequired,
  postUnavailability: PropTypes.func.isRequired,
  putAvailability: PropTypes.func.isRequired,
  putUnavailability: PropTypes.func.isRequired,
  resizeAvailability: PropTypes.func.isRequired,
  resizeUnavailability: PropTypes.func.isRequired,
  schedule: PropTypes.shape({
    availabilities: PropTypes.shape().isRequired,
    unavailabilities: PropTypes.shape().isRequired,
    events: PropTypes.shape().isRequired,
    currentView: PropTypes.string,
    currentTimeRange: PropTypes.shape(),
    provider_infos: PropTypes.shape().isRequired,
    timezone: PropTypes.string
  }).isRequired,
  setCurrentTimeRange: PropTypes.func.isRequired,
  setCurrentTimezone: PropTypes.func.isRequired,
  setCurrentView: PropTypes.func.isRequired
};

export default ProviderSchedule;
