import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Alert from '../../../../../components/alert/Alert';
import config from '../../../../../config';
import translation from '../../../../../translation/translation';

const useStyles = makeStyles(() => ({
  tableContainer: {
    maxHeight: 300
  }
}));

function PricingFields(props) {
  const classes = useStyles();

  const columns = ['id', 'price', 'duration', 'property_field', 'required'];

  const { fields } = props;

  return (
    <div className="ProductPricingFields">
      {fields && fields.length > 0 ? (
        <TableContainer className={classes.tableContainer}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column}>{column}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {fields.map((field, index) => (
                <TableRow key={index}>
                  <TableCell>{field.id}</TableCell>
                  <TableCell>{field.price ? field.price : '-'}</TableCell>
                  <TableCell>{field.duration ? field.duration : '_'}</TableCell>
                  <TableCell>
                    {field.property_field && field.property_field.name
                      ? field.property_field.name
                      : '-'}
                  </TableCell>
                  <TableCell>
                    {field.required ? (
                      <DoneIcon
                        style={{ color: config.success_color || '#4CAF50' }}
                        fontSize="small"
                      />
                    ) : (
                      <CloseIcon
                        style={{ color: config.error_color || '#F44336' }}
                        fontSize="small"
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Alert
          type="info"
          style={{ marginTop: 10 }}
          status={translation().commons.alerts.info}
          text={translation().views.product_view.pricing.pricing_fields.empty}
        />
      )}
    </div>
  );
}

PricingFields.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.any).isRequired
};

export default PricingFields;
