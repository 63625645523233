import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchApi } from '../../../utils/functions/api';
import { enqueueSnackbar } from '../../../redux/actions/appActions';
import CancelInvoiceLine from '../../../views/core/customListActions/CancelInvoiceLine';
import translation from '../../../translation/translation';

const mapDispatchToProps = (dispatch) => {
  return {
    postCancelInvoiceLine: (invoiceId, lineId, callbackSuccess, callbackError) => {
      if (!invoiceId || !lineId) {
        return;
      }

      fetchApi(
        'post',
        `invoices/${invoiceId}/lines/${lineId}/cancel`,
        null,
        null,
        null,
        (success) => {
          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success);
          }

          dispatch(
            enqueueSnackbar({
              message:
                translation().core.list.dialogs.custom_list_actions.cancel_invoice_line.callbacks
                  .success,
              options: {
                variant: 'success'
              }
            })
          );
        },
        (error) => {
          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.dialogs.custom_list_actions.cancel_invoice_line
                        .callbacks.error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message:
                  translation().core.list.dialogs.custom_list_actions.cancel_invoice_line.callbacks
                    .error,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError();
          }
        }
      );
    }
  };
};

export default withRouter(connect(null, mapDispatchToProps)(CancelInvoiceLine));
