import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Button,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Popover
} from '@material-ui/core';
import { ArrowDropDown, Person as PersonIcon } from '@material-ui/icons';

import LevelMenuItem from './LevelMenuItem';
import { stringToColor } from '../../../../../utils/functions/colors';
import translation from '../../../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(1, 0)
  },
  noBg: {
    backgroundColor: 'transparent'
  },
  selectButton: {
    color: 'currentColor',
    textTransform: 'inherit',
    height: 50
  }
}));

function UserListItem(props) {
  const classes = useStyles();

  const { id, initials, level, levels, onRemove, onSelectLevel, user, userName } = props;

  const [anchorLevels, setAnchorLevels] = useState(null);
  const [levelValue, setLevelValue] = useState(levels[level]);
  const [loading, setLoading] = useState(false);

  const levelsOpen = Boolean(anchorLevels);

  function handleOpenLevels(event) {
    setAnchorLevels(event.currentTarget);
  }

  function handleCloseLevels() {
    setAnchorLevels(null);
  }

  function handleSelectLevel(lvl) {
    setLevelValue(lvl);
    setLoading(true);

    onSelectLevel(
      id,
      lvl.value,
      () => {
        setLoading(false);
      },
      () => {
        setLoading(false);
        setLevelValue(levels[level]);
      }
    );

    handleCloseLevels();
  }

  function handleRemoveUser() {
    handleCloseLevels();
    setLoading(true);

    onRemove(
      id,
      () => {
        setLoading(false);
      },
      () => {
        setLoading(false);
        setLevelValue(levels[level]);
      }
    );
  }

  function handleGetAvatarColor(user) {
    if (!user || !user.id) return;
    return stringToColor(`${user.id}${user.first_name}${user.last_name}`);
  }

  return (
    <>
      <ListItem className={classes.listItem}>
        <ListItemAvatar>
          <Avatar aria-label="user" style={{ backgroundColor: handleGetAvatarColor(user) }}>
            {initials ? initials : <PersonIcon fontSize="large" />}
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={userName} secondary={user && user.email ? user.email : ''} />
        <ListItemSecondaryAction>
          {loading ? (
            <CircularProgress size={25} />
          ) : (
            <Button
              variant="contained"
              disableElevation
              className={classNames(classes.selectButton, classes.noBg)}
              endIcon={<ArrowDropDown />}
              disabled={loading}
              onClick={handleOpenLevels}>
              {levelValue.name}
            </Button>
          )}
        </ListItemSecondaryAction>
      </ListItem>
      <Popover
        id={levelsOpen ? 'levels-popover' : undefined}
        open={levelsOpen}
        anchorEl={anchorLevels}
        onClose={handleCloseLevels}
        style={{ zIndex: 1400 }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
        <List>
          {Object.keys(levels).map((level, index) => (
            <LevelMenuItem
              key={index}
              {...levels[level]}
              selected={levelValue}
              onSelect={handleSelectLevel}
            />
          ))}
          <Divider />
          <ListItem button className={classes.removeAccess} onClick={handleRemoveUser}>
            <ListItemText
              primaryTypographyProps={{
                color: 'error'
              }}
              primary={translation().views.task_views.tasks.users.remove_access}
            />
          </ListItem>
        </List>
      </Popover>
    </>
  );
}

UserListItem.propTypes = {
  initials: PropTypes.string,
  id: PropTypes.number,
  level: PropTypes.oneOf(['admin', 'manager', 'editor', 'operator', 'viewer']),
  levels: PropTypes.shape({
    admin: PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired
    }),
    editor: PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired
    }),
    manager: PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired
    }),
    viewer: PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired
    })
  }),
  onRemove: PropTypes.func.isRequired,
  onSelectLevel: PropTypes.func.isRequired,
  taskViewId: PropTypes.number.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string
  }).isRequired,
  userName: PropTypes.string.isRequired
};

export default UserListItem;
