const initialState = {
  data: [],
  paging: null,
  error: null,
  isLaunched: false,
  isLoading: false,
  item: {
    data: null,
    error: null,
    isLoading: false
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_REDUCERS':
      return initialState;
    case 'CLEAR_MODERATION':
      return initialState;
    case 'CLEAR_ORDER_PRODUCT_DATA':
      return {
        ...state,
        item: initialState.item
      };
    case 'GET_MODERATION_DATA':
      return {
        ...state,
        data: action.next ? state.data : initialState.data,
        paging: action.search ? initialState.paging : state.paging,
        isLoading: true,
        error: false
      };
    case 'GET_MODERATION_DATA_SUCCESS': {
      const dataConcatenated = state.data.concat(action.data);

      return {
        ...state,
        data: dataConcatenated,
        paging: action.paging ? action.paging : state.paging,
        isLaunched: true,
        isLoading: false,
        error: null
      };
    }
    case 'GET_MODERATION_DATA_ERROR':
      return {
        ...initialState,
        isLaunched: true,
        isLoading: false,
        error: action.error
      };
    case 'GET_ORDER_PRODUCT_DATA':
      return {
        ...state,
        item: {
          ...initialState.item,
          isLoading: true
        }
      };
    case 'GET_ORDER_PRODUCT_DATA_SUCCESS':
      return {
        ...state,
        item: {
          ...state.item,
          data: action.data,
          isLoading: false,
          error: null
        }
      };
    case 'GET_ORDER_PRODUCT_DATA_ERROR':
      return {
        ...state,
        item: {
          ...state.item,
          isLoading: false,
          error: action.error
        }
      };
    case 'DELIVER_PRODUCT_TO_MODERATE':
      return {
        ...state,
        item: {
          ...state.item,
          isLoading: true
        }
      };
    case 'DELIVER_PRODUCT_TO_MODERATE_SUCCESS':
      return {
        ...state,
        item: {
          ...state.item,
          isLoading: false
        }
      };
    case 'DELIVER_PRODUCT_TO_MODERATE_ERROR':
      return {
        ...state,
        item: {
          ...state.item,
          isLoading: false,
          error: action.error
        }
      };
    default:
      return state;
  }
};
