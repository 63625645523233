import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

import FormApiAutocompleteField from '../../../../../../components/forms/fields/FormApiAutocompleteField';
import translation from '../../../../../../translation/translation';

function AddOptionButton(props) {
  const {
    addOrderProductOption,
    orderId,
    orderProductId,
    productId,
    productOptions,
    property,
    reloadOrder
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(null);

  function handleOpenDialog() {
    setIsOpen(true);
  }

  function handleCloseDialog() {
    setIsOpen(false);
  }

  function handleSelect(code) {
    setValue(code);
  }

  function handleSubmit() {
    addOrderProductOption(orderId, orderProductId, value, reloadOrder);
  }

  return (
    <>
      <Tooltip title={translation().views.orders.update.products.add_option} enterDelay={500}>
        <Fab aria-label="add option" onClick={handleOpenDialog} size="small">
          <AddIcon />
        </Fab>
      </Tooltip>
      <Dialog
        fullWidth
        open={isOpen ? true : false}
        onClose={handleCloseDialog}
        aria-labelledby="add-product-option"
        aria-describedby="add-product-option-description"
        disableBackdropClick={productOptions.isLoading}>
        <DialogTitle id="add-product-option">
          {translation().views.orders.update.products.add_option}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="add-product-option-description">
            {translation().views.orders.update.products.product_options_add.description}
          </DialogContentText>
          <FormApiAutocompleteField
            disabled={productOptions.isLoading}
            query={property ? { property } : ''}
            links={[{ rel: 'list', href: `products/${productId}/options/orderable` }]}
            name="order_product_option_autocomplete"
            onSelectValue={handleSelect}
            placeholder={
              translation().views.orders.update.products.product_options_add.input_placeholder
            }
            targetKey="id"
          />
          <div className="sm-space" />
          {productOptions.isLoading && (
            <div className="loader-wpr" style={{ marginTop: 15 }}>
              <CircularProgress size={30} color="secondary" />
              <p>{translation().views.orders.update.products.product_options_add.loading_text}</p>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="default" disabled={productOptions.isLoading}>
            {translation().actions.cancel}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={productOptions.isLoading || !value}>
            {translation().actions.confirm}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

AddOptionButton.propTypes = {
  addOrderProductOption: PropTypes.func.isRequired,
  orderId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  orderProductId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  productId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  productOptions: PropTypes.shape({
    isLoading: PropTypes.bool
  }).isRequired,
  property: PropTypes.shape().isRequired,
  reloadOrder: PropTypes.func.isRequired
};

export default AddOptionButton;
