/**
 * Provider ID metrics
 */
export default {
  name: 'Provider metrics',
  routes_selector: [
    {
      label: 'Prestations',
      value: 'order-products'
    }
  ],
  metrics_routes: {
    'order-products': {
      child_routes: {
        'order-products-interval-auto': {
          route: 'order-products',
          chart_props: {
            type: 'line',
            title: 'Nombre de prestations:',
            metric_calc_mode: 'count'
          },
          query_params: {
            time_interval: 'auto'
          },
          filter_key: 'provider_id'
        },
        'order-products-interval-auto-compare-period': {
          route: 'order-products',
          chart_props: {
            type: 'trending',
            metric_calc_mode: 'avg',
            custom: true,
            title: 'Évolution du nombre de prestations (période):'
          },
          query_params: {
            compare: 'period'
          },
          filter_key: 'provider_id'
        },
        'order-products-avg-provider-cost-interval-auto': {
          route: 'order-products-avg-provider-cost',
          chart_props: {
            type: 'line',
            title: 'Moyenne de rémunération:',
            metric_calc_mode: 'avg'
          },
          query_params: {
            time_interval: 'auto'
          },
          filter_key: 'provider_id'
        },
        'order-products-avg-provider-cost-interval-auto-compare-period': {
          route: 'order-products-avg-provider-cost',
          chart_props: {
            type: 'trending',
            title: 'Évolution de la rémunération moyenne (période):',
            metric_calc_mode: 'avg',
            custom: true
          },
          query_params: {
            compare: 'period'
          },
          filter_key: 'provider_id'
        },
        'order-products-avg-profit-margin-ratio-interval-auto': {
          route: 'order-products-avg-profit-margin-ratio',
          chart_props: {
            type: 'line',
            title: 'Marge:',
            metric_calc_mode: 'function',
            metric_calc_function: {
              type: 'call-api',
              route: 'orders-avg-profit-margin-ratio',
              calc_mode: 'avg',
              query_params: {}
            }
          },
          query_params: {
            time_interval: 'auto'
          },
          filter_key: 'provider_id'
        },
        'order-products-avg-profit-margin-ratio-interval-auto-compare-period': {
          route: 'order-products-avg-profit-margin-ratio',
          chart_props: {
            title: 'Évolution de la marge (période):',
            type: 'trending',
            metric_calc_mode: 'avg',
            custom: true
          },
          query_params: {
            compare: 'period'
          },
          filter_key: 'provider_id'
        },
        'order-products-avg-trip-distance-interval-auto': {
          route: 'order-products-avg-trip-distance',
          chart_props: {
            type: 'line',
            title: 'Moyenne de distance parcourue:',
            metric_calc_mode: 'function',
            metric_calc_function: {
              type: 'call-api',
              route: 'order-products-avg-trip-distance',
              calc_mode: 'avg',
              query_params: {}
            }
          },
          query_params: {
            time_interval: 'auto'
          },
          filter_key: 'provider_id'
        },
        'order-products-avg-trip-distance-interval-auto-compare-period': {
          route: 'order-products-avg-trip-distance',
          chart_props: {
            title: 'Évolution de la distance parcourue moyenne (période):',
            type: 'trending',
            metric_calc_mode: 'avg',
            custom: true
          },
          query_params: {
            compare: 'period'
          },
          filter_key: 'provider_id'
        },
        'order-products-avg-trip-compensation-interval-auto': {
          route: 'order-products-avg-trip-compensation',
          chart_props: {
            type: 'line',
            title: "Moyenne d'indemnités kilométriques:",
            metric_calc_mode: 'function',
            metric_calc_function: {
              type: 'call-api',
              route: 'order-products-avg-trip-compensation',
              calc_mode: 'avg',
              query_params: {}
            }
          },
          query_params: {
            time_interval: 'auto'
          },
          filter_key: 'provider_id'
        },
        'order-products-avg-trip-compensation-interval-auto-compare-period': {
          route: 'order-products-avg-trip-compensation',
          chart_props: {
            title: 'Évolution des indemnités kilométriques (période):',
            type: 'trending',
            metric_calc_mode: 'avg',
            custom: true
          },
          query_params: {
            compare: 'period'
          },
          filter_key: 'provider_id'
        },
        'order-products-per-product-bar': {
          route: 'order-products-per-product',
          chart_props: {
            type: 'bar',
            title: 'Palmarès produit en volume:'
          },
          query_params: {
            limit: 20
          },
          filter_key: 'provider_id'
        },
        'order-products-per-product-doughnut': {
          route: 'order-products-per-product',
          chart_props: {
            type: 'doughnut',
            title: 'Répartition produit en volume:'
          },
          query_params: {
            limit: 20
          },
          filter_key: 'provider_id'
        },
        'order-products-avg-provider-rating-interval-auto': {
          route: 'order-products-avg-provider-rating',
          chart_props: {
            type: 'line',
            title: "Moyenne d'évaluation prestataire:",
            metric_calc_mode: 'function',
            metric_calc_function: {
              type: 'call-api',
              route: 'order-products-avg-provider-rating',
              calc_mode: 'avg',
              query_params: {}
            }
          },
          query_params: {
            time_interval: 'auto'
          },
          filter_key: 'provider_id'
        }
      }
    }
  }
};
