import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContentText from '@material-ui/core/DialogContentText';

import translation from '../../../translation/translation';

const useStyles = makeStyles(() => ({
  alignRight: {
    textAlign: 'right',
    marginTop: 24,
    marginBottom: 24
  }
}));

function ManageCompanyFlag(props) {
  const { closeDialogFunction, manageCompanyFlag, reloadListFunction, resource } = props;

  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);

  function handleSubmit() {
    if (!resource || !resource.id) return;

    setLoading(true);

    manageCompanyFlag(
      resource.id,
      !resource.flag,
      () => {
        setLoading(false);
        closeDialogFunction();
        reloadListFunction();
      },
      () => {
        setLoading(false);
      }
    );
  }

  return (
    <div className="ManageCompanyFlag">
      {resource && (
        <DialogContentText>
          {resource.flag
            ? translation().core.list.dialogs.custom_list_actions.manage_company_flag
                .description_remove
            : translation().core.list.dialogs.custom_list_actions.manage_company_flag
                .description_add}
        </DialogContentText>
      )}
      <div>
        {resource && (
          <div className={classes.alignRight}>
            <Button disabled={isLoading} variant="contained" onClick={handleSubmit} color="primary">
              {resource.flag
                ? translation().core.list.dialogs.custom_list_actions.manage_company_flag.buttons
                    .remove
                : translation().core.list.dialogs.custom_list_actions.manage_company_flag.buttons
                    .add}
            </Button>
          </div>
        )}
        {isLoading && (
          <div className="loader-wpr">
            <CircularProgress color="primary" size={25} />
            <p>
              {translation().core.list.dialogs.custom_list_actions.manage_company_flag.loading_text}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

ManageCompanyFlag.propTypes = {
  closeDialogFunction: PropTypes.func.isRequired,
  manageCompanyFlag: PropTypes.func.isRequired,
  reloadListFunction: PropTypes.func.isRequired,
  resource: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    flag: PropTypes.bool
  })
};

export default ManageCompanyFlag;
