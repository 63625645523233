import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchApi } from '../../utils/functions/api';
import { formatFiltersValuesBeforePost } from '../../utils/functions/fitlers';
import { enqueueSnackbar } from '../../redux/actions/appActions';
import {
  getOrderProductsOverdueMetadata,
  getOrderProductsOverdueMetadataSuccess,
  getOrderProductsOverdueMetadataError
} from '../../redux/actions/orderProductsOverdue/metadata/orderProductsOverdueMetadataActions';
import {
  clearOwnReducer,
  getOrderProductsOverdue,
  getOrderProductsOverdueSuccess,
  getOrderProductsOverdueError,
  refreshOrderProductsOverdue,
  setOrderProductsOverdueSearchValue
} from '../../redux/actions/orderProductsOverdue/list/orderProductsOverdueListActions';
import OrderProductsOverdue from '../../views/orderProductsOverdue/OrderProductsOverdue';
import { setListNeedReload } from '../../redux/actions/core/list/listActions';
import { clearFilters } from '../../redux/actions/filters/filtersActions';
import { getCurrentDateUtc, addToDate } from '../../utils/functions/dates';
import translation from '../../translation/translation';
import store from '../../redux/store';

const mapStateToProps = (store) => ({
  list: store.production.orderProductsOverdue.list,
  metadata: store.production.orderProductsOverdue.metadata
});

const mapDispatchToProps = (dispatch) => {
  return {
    clearFilters() {
      dispatch(clearFilters());
    },
    clearOwnReducer() {
      dispatch(clearOwnReducer());
    },
    setDataSearchValue(value) {
      dispatch(setOrderProductsOverdueSearchValue(value));
    },
    setListNeedReload() {
      dispatch(setListNeedReload());
    },
    async getMetadata(uri, sourceToken = null) {
      if (!uri) return;

      dispatch(getOrderProductsOverdueMetadata());

      try {
        await fetchApi(
          'get',
          `${uri}/metadata`,
          null,
          null,
          null,
          (metadata) => {
            dispatch(getOrderProductsOverdueMetadataSuccess(metadata));
          },
          (error) => {
            dispatch(getOrderProductsOverdueMetadataError(true, error));

            if (error.response) {
              dispatch(
                enqueueSnackbar({
                  message:
                    error.response.data && error.response.data.detail
                      ? error.response.data.detail
                      : error.response.data.status_code
                      ? error.response.data.status_code + ': ' + translation().core.item.not_found
                      : translation().core.list.error_metadata,
                  status: error.response.status || undefined,
                  options: {
                    variant: 'error'
                  }
                })
              );
            } else {
              dispatch(
                enqueueSnackbar({
                  message: translation().core.list.error_metadata,
                  options: {
                    variant: 'error'
                  }
                })
              );
            }
          },
          null,
          sourceToken
        );
      } catch (error) {
        dispatch(getOrderProductsOverdueMetadataError(true, error));
        dispatch(
          enqueueSnackbar({
            message: translation().core.list.error_metadata,
            options: {
              variant: 'error'
            }
          })
        );
      }
    },
    async getData(uri, sourceToken = null, search = null, next = null, isRefresh = false) {
      const now = getCurrentDateUtc();
      const overdueLimit = addToDate(now, 2, 'hour', 'YYYY-MM-DDTHH:mm:ss');
      const filters = `delivery_status_id|in|3/4/5,expected|lte|${overdueLimit}`;

      const query = {
        filters,
        sort: 'expected'
      };

      if (!uri) return;

      if (isRefresh) dispatch(refreshOrderProductsOverdue());
      else dispatch(getOrderProductsOverdue(search, next));

      try {
        await fetchApi(
          'get',
          next ? next : uri,
          !next && search ? { search, ...query } : query,
          null,
          null,
          (data, paging) => {
            dispatch(getOrderProductsOverdueSuccess(data, paging));
          },
          (error) => {
            dispatch(getOrderProductsOverdueError(true, error));
          },
          null,
          sourceToken
        );
      } catch (error) {
        dispatch(getOrderProductsOverdueError(true, error));
      }
    },
    async filterOrderProducts(uri, metadata) {
      if (!uri) {
        return;
      }

      const filtersValues = store.getState().filters.values;
      let query = { filters: '' };

      dispatch(getOrderProductsOverdue(true));

      for (let value in filtersValues) {
        /**
         * Check if operator in range is set (for timestamp type for example)
         */
        if (
          filtersValues[value] &&
          filtersValues[`${value}.start`] &&
          filtersValues[`${value}.end`] &&
          filtersValues[`${value}.start`].value &&
          filtersValues[`${value}.end`].value &&
          filtersValues[value].operator &&
          filtersValues[value].operator === 'range'
        ) {
          const rangeStart = { value: filtersValues[`${value}.start`].value };
          const rangeEnd = { value: filtersValues[`${value}.end`].value };

          query.filters += `${value}|gte|${formatFiltersValuesBeforePost(
            metadata,
            value,
            rangeStart
          )},`;
          query.filters += `${value}|lte|${formatFiltersValuesBeforePost(
            metadata,
            value,
            rangeEnd
          )},`;
        } else if (
          filtersValues[value] &&
          filtersValues[value].operator &&
          (filtersValues[value].operator === 'nu' || filtersValues[value].operator === 'nnu')
        ) {
          query.filters += `${value}|${filtersValues[value].operator}|,`;
        } else if (
          filtersValues[value] &&
          filtersValues[value].value &&
          !value.includes('.start') &&
          !value.includes('.end')
        ) {
          query.filters += `${value}|${
            filtersValues[value].operator ? filtersValues[value].operator : 'eq'
          }|${formatFiltersValuesBeforePost(metadata, value, filtersValues[value])},`;
        }
      }

      try {
        await fetchApi(
          'get',
          `${uri}`,
          query,
          null,
          null,
          (data, paging) => {
            dispatch(getOrderProductsOverdueSuccess(data, paging));
          },
          (error) => {
            dispatch(getOrderProductsOverdueError(true, error));
          }
        );
      } catch (error) {
        dispatch(getOrderProductsOverdueError(true, error));
      }
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderProductsOverdue));
