import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AppBar from '@material-ui/core/AppBar';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import Tooltip from '@material-ui/core/Tooltip';

import translation from '../../../../translation/translation';

const styles = (theme) => ({
  root: {
    width: '100%'
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  breadcrumb: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
});

class ModerationViewBar extends PureComponent {
  state = {
    mobileMoreAnchorEl: null
  };

  handleMobileMenuOpen = (event) => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  handleHistory = () => {
    const { history } = this.props;

    history.push('/production/moderation');
  };

  render() {
    const { mobileMoreAnchorEl } = this.state;
    const { classes, disabled, productId } = this.props;

    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const renderMobileMenu = (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        onClose={this.handleMobileMenuClose}>
        <MenuItem onClick={this.handleHistory}>
          <ArrowBackIcon fontSize="small" style={{ marginRight: 8 }} />
          <p>{translation().actions.back}</p>
        </MenuItem>
      </Menu>
    );

    return (
      <div className={classes.root}>
        <AppBar elevation={0} position="static" color="default">
          <Toolbar>
            <Breadcrumbs
              maxItems={5}
              separator={<NavigateNextIcon fontSize="small" />}
              arial-label="OrderProduct moderation view breadcrumb"
              className={classes.breadCrumb}>
              <Typography variant="body2" color="textPrimary">
                {translation().views.production.title}
              </Typography>
              <Typography variant="body2" color="textPrimary">
                {translation().views.production.moderation.title}
              </Typography>
              <Typography variant="body2" color="textPrimary">
                {translation().views.production.moderation.item.title}
              </Typography>
              <Typography variant="body2" color="textPrimary">
                #{productId}
              </Typography>
            </Breadcrumbs>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <Tooltip
                title={translation().actions.back || 'Back'}
                aria-label={translation().actions.back || 'Back'}
                disabled={disabled}
                enterDelay={700}>
                <div>
                  <IconButton
                    onClick={this.handleHistory}
                    color="inherit"
                    className={classes.backIcon}
                    disabled={disabled}>
                    <ArrowBackIcon fontSize="small" />
                  </IconButton>
                </div>
              </Tooltip>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-haspopup="true"
                onClick={this.handleMobileMenuOpen}
                color="inherit"
                disabled={disabled}>
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
      </div>
    );
  }
}

ModerationViewBar.defaultProps = {
  disabled: false
};

ModerationViewBar.propTypes = {
  disabled: PropTypes.bool,
  classes: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  productId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default withStyles(styles)(ModerationViewBar);
