export const createDashboard = () => ({
  type: 'CREATE_DASHBOARD'
});

export const createDashboardSuccess = (data) => ({
  type: 'CREATE_DASHBOARD_SUCCESS',
  data
});

export const createDashboardError = (error) => ({
  type: 'CREATE_DASHBOARD_ERROR',
  error
});
