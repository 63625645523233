import React, { useState, useEffect, PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import LabelIcon from '@material-ui/icons/Label';
import LinearProgress from '@material-ui/core/LinearProgress';
import Rating from '@material-ui/lab/Rating';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import Alert from '../../../../../components/alert/Alert';
import translation from '../../../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    maxHeight: 350
  },
  loadingWrapper: {
    width: '100%',
    height: 350,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      height: 'auto'
    }
  },
  ratingWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  ratingValue: {
    marginLeft: 4
  },
  orderLink: {
    marginTop: 0,
    marginBottom: 0,
    margin: 8
  }
}));

class CustomLink extends PureComponent {
  render() {
    return (
      <Link to={this.props.link} {...this.props}>
        {this.props.children}
      </Link>
    );
  }
}

CustomLink.propTypes = {
  link: PropTypes.string.isRequired,
  children: PropTypes.any
};

const createCancelToken = () => axios.CancelToken.source();

const GetDataApi = (getData, providerId) => {
  const [source] = useState(createCancelToken());

  useEffect(() => {
    getData(providerId, source);

    return () => {
      source.cancel('=> Cancel get provider ratings emitted.');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const GetNextDataApi = (getData, url) => {
  const [source] = useState(createCancelToken());

  const doNext = (initialNext) => {
    if (initialNext) getData(url, source, initialNext);
  };

  useEffect(() => {
    return () => {
      source.cancel('=> Next: Cancelled.');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { doNext };
};

function RatingsEmitted(props) {
  const classes = useStyles();

  const columns = ['order_id', 'client_rating', 'client_rating_comment', 'client_rating_issues'];

  const { providerId, emitted, getProviderViewRatingsEmitted } = props;

  GetDataApi(getProviderViewRatingsEmitted, providerId);

  const { doNext } = GetNextDataApi(getProviderViewRatingsEmitted, providerId);

  function handleInfiniteScroll(event) {
    if (!event) console.error('No table scroll event found.');

    if (!event.currentTarget.scrollTop || event.currentTarget.scrollTop === 0) return;

    if (
      event.currentTarget.scrollHeight - event.currentTarget.clientHeight <=
      event.currentTarget.scrollTop
    ) {
      if (emitted && emitted.paging && emitted.paging.next) {
        doNext(emitted.paging.next);
      }
    }
  }

  return (
    <div className="ProviderRatingsEmitted">
      {!emitted.isLoading && emitted.error && (!emitted.data || emitted.data.length <= 0) ? (
        <div className="error-list">
          <Alert
            type="danger"
            style={{ marginTop: 10 }}
            status={translation().commons.alerts.error}
            text={translation().core.list.error_list}
          />
        </div>
      ) : (
        false
      )}
      {emitted.data && emitted.data.length > 0 ? (
        <TableContainer onScroll={handleInfiniteScroll} className={classes.tableContainer}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column}>{column}</TableCell>
                ))}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {emitted.data.map((rating, index) => (
                <TableRow key={index}>
                  <TableCell>{rating.order_id}</TableCell>
                  <TableCell style={{ minWidth: 150 }}>
                    <div className={classes.ratingWrapper}>
                      <Rating value={rating.client_rating} precision={0.5} readOnly size="small" />
                      <Typography component="span" variant="body2" className={classes.ratingValue}>
                        ({rating.client_rating})
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell>
                    {rating.client_rating_comment.length
                      ? rating.client_rating_comment.split('\n').map((line, key) => {
                          return (
                            <span key={key}>
                              {line}
                              <br />
                            </span>
                          );
                        })
                      : '-'}
                  </TableCell>
                  <TableCell>
                    {rating.client_rating_issues.length
                      ? rating.client_rating_issues.map((line, key) => {
                          return (
                            <span key={key}>
                              {line}
                              <br />
                            </span>
                          );
                        })
                      : '-'}
                  </TableCell>
                  <TableCell key={`action-${index}`} style={{ textAlign: 'right' }}>
                    <Tooltip title={translation().actions.custom_list_actions.see_order}>
                      <IconButton
                        className={classes.orderLink}
                        component={CustomLink}
                        link={`/orders/${rating.order_id}/update`}
                        target="_blank"
                        size="small">
                        <LabelIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : !emitted.isLoading && !emitted.error ? (
        <Alert
          type="info"
          style={{ marginTop: 10 }}
          status={translation().commons.alerts.info}
          text={translation().views.provider_view.ratings.emitted.empty}
        />
      ) : (
        false
      )}
      {emitted.isLoading && !emitted.paging && (
        <div className={classNames(classes.loadingWrapper, 'loader-wpr')}>
          <div style={{ width: '100%' }}>
            <CircularProgress color="primary" />
            <p>{translation().views.provider_view.ratings.emitted.loading_text}</p>
          </div>
        </div>
      )}
      {emitted.isLoading && emitted.paging && emitted.paging.next ? (
        <>
          <LinearProgress color="primary" />
          <div className="loader-wpr linear">
            <p>{translation().core.list.load_next}</p>
          </div>
        </>
      ) : (
        <div className="end-of-list-wpr">
          {!emitted.isLoading && emitted.paging && !emitted.paging.next && emitted.paging.prev && (
            <p>{translation().core.list.load_next_done}</p>
          )}
        </div>
      )}
    </div>
  );
}

RatingsEmitted.propTypes = {
  emitted: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape()),
    error: PropTypes.string,
    isLoading: PropTypes.bool,
    paging: PropTypes.shape({
      next: PropTypes.string,
      prev: PropTypes.string
    })
  }).isRequired,
  getProviderViewRatingsEmitted: PropTypes.func.isRequired,
  providerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export default RatingsEmitted;
