import React from 'react';

function ProvidersMapIcon(props) {
  return (
    <svg
      version="1.1"
      id="ProvidersMapIcon"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={100}
      viewBox="0 0 512 512"
      style={{ enableBackground: 'new 0 0 512 512' }}
      {...props}>
      <g>
        <circle fill="#4482C3" cx="256" cy="256" r="220.69" />
        <g>
          <path
            fill="#4FBA6F"
            d="M192.353,113.788c-9.799,16.331-1.677,35.928,5.032,53.319c3.007,5.629,3.795,12.18,2.207,18.361
            c-2.529,4.951-6.379,9.106-11.123,12.006c-12.888,9.975-25.806,19.862-38.753,29.661c-9.586,6.408-17.709,14.772-23.834,24.541
            c-10.24,19.686,0.265,42.902-4.149,64.177c-2.56,12.006-31.956,47.228-46.874,66.119C34.17,323.526,24.203,249.034,48.094,181.949
            S126.796,63.442,195.266,43.873l0.088,0.265c16.959,12.2,21.452,35.514,10.24,53.142
            C200.883,102.537,196.463,108.048,192.353,113.788z"
          />
          <path
            fill="#4FBA6F"
            d="M476.69,256c0.028,16.004-1.719,31.962-5.208,47.581h-0.088
            c-9.478,2.198-19.431-0.373-26.659-6.885c-11.564-9.534-21.098-23.658-38.135-21.451c-9.69,1.652-18.154,7.5-23.128,15.978
            c-14.389,23.217-6.974,52.789-19.068,76.182c-7.047,11.642-16.352,21.756-27.366,29.749c-0.088,0.088-0.265,0.177-0.353,0.353
            c-6.476,5.17-15.45,5.869-22.648,1.764c-7.198-4.105-11.166-12.184-10.014-20.39c2.597-20.842-2.831-41.893-15.183-58.88
            c-9.887-13.33-24.629-25.512-24.541-42.019c0.265-22.334,27.277-35.84,45.021-43.52c9.692-3.986,17.921-10.859,23.57-19.686
            c15.89-26.571-8.739-47.316-21.186-69.65c-17.229-32.629-9.775-72.822,18.008-97.103C417.831,79.191,476.727,162.53,476.69,256z"
          />
          <path fill="#4FBA6F" d="M329.71,48.022l0.088-0.088c0.177-0.088,0.353-0.265,0.53-0.353" />
          <path
            fill="#4FBA6F"
            d="M274.715,475.807c-6.179,0.618-12.447,0.883-18.715,0.883
            c-50.245,0.075-99.001-17.059-138.152-48.552l35.046-25.777c5.012-3.697,11.179-5.481,17.39-5.032
            c13.241,1.501,22.245,15.007,34.957,18.891c9.975,3.09,20.568-0.088,30.897,0.353c2.076,0.06,4.118,0.54,6.003,1.412
            c1.944,1.102,3.63,2.607,4.943,4.414C259.443,438.29,262.091,460.27,274.715,475.807z"
          />
        </g>
        <circle fill="#CC4B4C" cx="432.552" cy="105.931" r="79.448" />
        <path
          fill="#E6E7E8"
          d="M476.69,148.64c0.052,10.283-3.3,20.294-9.534,28.473c-21.753,11.022-47.455,11.022-69.208,0
          c-8.642-11.399-11.614-26.117-8.073-39.976c3.541-13.859,13.208-25.348,26.258-31.206h0.088c9.432,7.961,23.23,7.961,32.662,0
          h0.088C465.92,113.344,476.822,130.142,476.69,148.64z"
        />
        <path
          fill="#FFFFFF"
          d="M459.035,88.276c0.059,8.105-3.662,15.774-10.063,20.745h-0.088c-9.543,7.651-23.119,7.651-32.662,0
          h-0.088c-6.402-4.971-10.122-12.64-10.063-20.745c0-14.626,11.857-26.483,26.483-26.483S459.035,73.65,459.035,88.276z"
        />
        <circle fill="#E4C05C" cx="79.448" cy="79.448" r="79.448" />
        <path
          fill="#E6E7E8"
          d="M123.586,122.158c0.052,10.283-3.3,20.294-9.534,28.473c-21.753,11.022-47.455,11.022-69.208,0
          c-8.642-11.399-11.614-26.117-8.073-39.976c3.541-13.859,13.208-25.348,26.258-31.206h0.088c9.432,7.961,23.23,7.961,32.662,0
          h0.088C112.816,86.861,123.718,103.659,123.586,122.158z"
        />
        <path
          fill="#FFFFFF"
          d="M105.931,61.793c0.059,8.105-3.662,15.774-10.063,20.745h-0.088c-9.543,7.651-23.119,7.651-32.662,0
          h-0.088c-6.402-4.971-10.122-12.64-10.063-20.745c0-14.626,11.857-26.483,26.483-26.483S105.931,47.167,105.931,61.793z"
        />
        <circle fill="#4e4bc6" cx="79.448" cy="432.552" r="79.448" />
        <path
          fill="#E6E7E8"
          d="M123.586,475.261c0.052,10.283-3.3,20.294-9.534,28.472c-21.753,11.022-47.455,11.022-69.208,0
          c-8.642-11.399-11.614-26.117-8.073-39.976c3.541-13.859,13.208-25.348,26.258-31.206h0.088c9.432,7.961,23.23,7.961,32.662,0
          h0.088C112.816,439.965,123.718,456.763,123.586,475.261z"
        />
        <path
          fill="#FFFFFF"
          d="M105.931,414.897c0.059,8.105-3.662,15.774-10.063,20.745h-0.088
          c-9.543,7.651-23.119,7.651-32.662,0h-0.088c-6.402-4.971-10.122-12.64-10.063-20.745c0-14.626,11.857-26.483,26.483-26.483
          S105.931,400.271,105.931,414.897z"
        />
      </g>
    </svg>
  );
}

export default ProvidersMapIcon;
