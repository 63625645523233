import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import translation from '../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'right',
    [theme.breakpoints.up('sm')]: {
      minWidth: 300
    }
  }
}));

function CancelInvoiceLine(props) {
  const { closeDialogFunction, reloadListFunction, postCancelInvoiceLine, resource, resourceId } =
    props;
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);

  function handleClick() {
    if (resource && resource.id && resourceId) {
      setLoading(true);

      postCancelInvoiceLine(
        resourceId,
        resource.id,
        () => {
          setLoading(false);
          closeDialogFunction();
          reloadListFunction();
        },
        () => {
          setLoading(false);
        }
      );
    }
  }

  return (
    <div className="CancelInvoiceLine">
      <div className={classes.root}>
        <div className="sm-space" />
        <Button variant="contained" color="primary" disabled={isLoading} onClick={handleClick}>
          {translation().core.list.dialogs.custom_list_actions.cancel_invoice_line.button}
        </Button>
        <div className="sm-space" />
        {isLoading && (
          <div className="loader-wpr">
            <CircularProgress color="primary" size={25} />
            <p>
              {
                translation().core.list.dialogs.custom_list_actions.cancel_invoice_line
                  .cancel_loading
              }
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

CancelInvoiceLine.propTypes = {
  closeDialogFunction: PropTypes.func.isRequired,
  postCancelInvoiceLine: PropTypes.func.isRequired,
  reloadListFunction: PropTypes.func.isRequired,
  resource: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }),
  resourceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default CancelInvoiceLine;
