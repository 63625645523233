import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchApi } from '../../../utils/functions/api';
import { enqueueSnackbar } from '../../../redux/actions/appActions';
import PayerBalance from '../../../views/core/customListActions/PayerBalance';
import translation from '../../../translation/translation';

const mapDispatchToProps = (dispatch) => {
  return {
    getBalance(payerId, query = {}, callbackSuccess, callbackError) {
      if (!payerId) return;

      fetchApi(
        'get',
        `payers/${payerId}/balance`,
        query,
        null,
        null,
        (success) => {
          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success);
          }
        },
        (error) => {
          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.dialogs.custom_list_actions.pay_withdrawal.callbacks
                        .get_balance_error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message:
                  translation().core.list.dialogs.custom_list_actions.pay_withdrawal.callbacks
                    .get_balance_error,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError();
          }
        }
      );
    },
    getPaymentMethodsUsable(payerId, query = {}, callbackSuccess, callbackError) {
      if (!payerId) return;

      fetchApi(
        'get',
        `payers/${payerId}/payment-methods/usable`,
        {
          way: 'receive',
          ...query
        },
        null,
        null,
        (success) => {
          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success);
          }
        },
        (error) => {
          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.dialogs.custom_list_actions.pay_withdrawal.callbacks
                        .get_methods,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message:
                  translation().core.list.dialogs.custom_list_actions.pay_withdrawal.callbacks
                    .get_methods,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError();
          }
        }
      );
    },
    payWithdraw(payerId, values, callbackSuccess, callbackError) {
      if (!payerId || !values) return;

      fetchApi(
        'post',
        `payers/${payerId}/withdraw`,
        null,
        values,
        null,
        (success) => {
          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success);
          }

          dispatch(
            enqueueSnackbar({
              message:
                translation().core.list.dialogs.custom_list_actions.pay_withdrawal.callbacks.pay
                  .success,
              options: {
                variant: 'success'
              }
            })
          );
        },
        (error) => {
          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.dialogs.custom_list_actions.pay_withdrawal.callbacks
                        .pay.error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message:
                  translation().core.list.dialogs.custom_list_actions.pay_withdrawal.callbacks.pay
                    .error,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError();
          }
        }
      );
    }
  };
};

export default withRouter(connect(null, mapDispatchToProps)(PayerBalance));
