/**
 * Calendar actions
 */
export const clearSchedule = (data) => ({
  type: 'CLEAR_SCHEDULE',
  data
});

export const setCurrentView = (data) => ({
  type: 'STORE_CURRENT_VIEW',
  data
});

export const setCurrentTimeRange = (data) => ({
  type: 'STORE_CURRENT_TIME_RANGE',
  data
});

export const setCurrentTimezone = (data) => ({
  type: 'STORE_CURRENT_TIMEZONE',
  data
});

export const clearCurrentTimezone = () => ({
  type: 'CLEAR_CURRENT_TIMEZONE'
});

/**
 * Get providers events
 */
export const getProviderCalendar = () => ({
  type: 'GET_PROVIDER_CALENDAR'
});

export const getProviderCalendarSuccess = (data) => ({
  type: 'GET_PROVIDER_CALENDAR_SUCCESS',
  data
});

export const getProviderCalendarError = (error) => ({
  type: 'GET_PROVIDER_CALENDAR_ERROR',
  error
});

/**
 * Availabilities actions
 */
export const getAvailabilities = (next) => ({
  type: 'GET_AVAILABILITIES',
  next
});

export const getAvailabilitiesSuccess = (data, paging) => ({
  type: 'GET_AVAILABILITIES_SUCCESS',
  data,
  paging
});

export const getAvailabilitiesError = (error) => ({
  type: 'GET_AVAILABILITIES',
  error
});

export const updateAvailabilities = (data, paging) => ({
  type: 'UPDATE_AVAILABILITIES',
  data,
  paging
});

export const postAvailability = () => ({
  type: 'POST_AVAILABILITY'
});

export const postAvailabilitySuccess = (data) => ({
  type: 'POST_AVAILABILITY_SUCCESS',
  data
});

export const postAvailabilityError = (error) => ({
  type: 'POST_AVAILABILITY_ERROR',
  error
});

export const putAvailability = () => ({
  type: 'PUT_AVAILABILITY'
});

export const putAvailabilitySuccess = (id, data) => ({
  type: 'PUT_AVAILABILITY_SUCCESS',
  id,
  data
});

export const putAvailabilityError = (error) => ({
  type: 'PUT_AVAILABILITY_ERROR',
  error
});

export const deleteAvailability = () => ({
  type: 'DELETE_AVAILABILITY'
});

export const deleteAvailabilitySuccess = (id) => ({
  type: 'DELETE_AVAILABILITY_SUCCESS',
  id
});

export const deleteAvailabilityError = (error) => ({
  type: 'DELETE_AVAILABILITY_ERROR',
  error
});

/**
 * Unavailabilities actions
 */
export const getUnavailabilities = (next) => ({
  type: 'GET_UNAVAILABILITIES',
  next
});

export const getUnavailabilitiesSuccess = (data, paging) => ({
  type: 'GET_UNAVAILABILITIES_SUCCESS',
  data,
  paging
});

export const getUnavailabilitiesError = (error) => ({
  type: 'GET_UNAVAILABILITIES',
  error
});

export const updateUnavailabilities = (data, paging) => ({
  type: 'UPDATE_UNAVAILABILITIES',
  data,
  paging
});

export const postUnavailability = () => ({
  type: 'POST_UNAVAILABILITY'
});

export const postUnavailabilitySuccess = (data) => ({
  type: 'POST_UNAVAILABILITY_SUCCESS',
  data
});

export const postUnavailabilityError = (error) => ({
  type: 'POST_UNAVAILABILITY_ERROR',
  error
});

export const putUnavailability = () => ({
  type: 'PUT_UNAVAILABILITY'
});

export const putUnavailabilitySuccess = (id, data) => ({
  type: 'PUT_UNAVAILABILITY_SUCCESS',
  id,
  data
});

export const putUnavailabilityError = (error) => ({
  type: 'PUT_UNAVAILABILITY_ERROR',
  error
});

export const deleteUnavailability = () => ({
  type: 'DELETE_UNAVAILABILITY'
});

export const deleteUnavailabilitySuccess = (id) => ({
  type: 'DELETE_UNAVAILABILITY_SUCCESS',
  id
});

export const deleteUnavailabilityError = (error) => ({
  type: 'DELETE_UNAVAILABILITY_ERROR',
  error
});

/**
 * Resize and Dnd events
 */
export const resizeAvailability = (id, data) => ({
  type: 'RESIZE_AVAILABILITY',
  id,
  data
});

export const resizeAvailabilitySuccess = (id, data) => ({
  type: 'RESIZE_AVAILABILITY_SUCCESS',
  id,
  data
});

export const resizeAvailabilityFailure = (id, data) => ({
  type: 'RESIZE_AVAILABILITY_FAILURE',
  id,
  data
});

export const resizeUnavailability = (id, data) => ({
  type: 'RESIZE_UNAVAILABILITY',
  id,
  data
});

export const resizeUnavailabilitySuccess = (id, data) => ({
  type: 'RESIZE_UNAVAILABILITY_SUCCESS',
  id,
  data
});

export const resizeUnavailabilityFailure = (id, data) => ({
  type: 'RESIZE_UNAVAILABILITY_FAILURE',
  id,
  data
});

/**
 * Get providers infos
 */
export const getProviderInfosCalendar = () => ({
  type: 'GET_PROVIDER_INFOS_CALENDAR'
});

export const getProviderInfosCalendarSuccess = (data) => ({
  type: 'GET_PROVIDER_INFOS_CALENDAR_SUCCESS',
  data
});

export const getProviderInfosCalendarFailure = (error) => ({
  type: 'GET_PROVIDER_INFOS_CALENDAR_FAILURE',
  error
});
