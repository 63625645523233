import { compose, createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

/**
 * COMMONS REDUCERS
 */
import formsReducer from './reducers/forms/formsReducer';
import filtersReducer from './reducers/filters/filtersReducer';

/**
 * CORE REDUCERS
 */
import createCoreReducer from './reducers/core/create/createCoreReducer';
import deleteCoreReducer from './reducers/core/delete/deleteCoreReducer';
import itemReducer from './reducers/core/item/itemReducer';
import listCoreReducer from './reducers/core/list/listCoreReducer';
import metadataReducer from './reducers/core/metadata/metadataReducer';
import updateCoreReducer from './reducers/core/update/updateCoreReducer';

/**
 * VIEWS / COMPONENTS REDUCERS
 */
import appReducer from './reducers/appReducer';
import authReducer from './reducers/auth/authReducer';
import userReducer from './reducers/user/userReducer';
import moderationReducer from './reducers/moderation/moderationReducer';
import previousDayListReducer from './reducers/previousDay/list/previousDayListReducer';
import previousDayMetadataReducer from './reducers/previousDay/metadata/previousDayMetadataReducer';
import profileReducer from './reducers/views/profile/profileReducer';
import providersMapReducer from './reducers/providers/providersMapReducer';
import providerFactoryReducer from './reducers/providers/providerFactoryReducer';
import providerViewReducer from './reducers/views/provider/providerViewReducer';
import productViewReducer from './reducers/views/product/productViewReducer';
import scheduleReducer from './reducers/schedule/scheduleReducer';
import companyViewReducer from './reducers/views/company/companyViewReducer';
import userViewReducer from './reducers/views/user/userViewReducer';
import tasksReducer from './reducers/views/tasks/tasks/tasksReducer';
import taskViewsReducer from './reducers/views/tasks/task-views/taskViewsReducer';

/**
 * ORDERS REDUCERS
 */
import createOrderReducer from './reducers/orders/create/createOrderReducer';
import ordersListReducer from './reducers/orders/list/ordersListReducer';
import ordersMetadataReducer from './reducers/orders/metadata/ordersMetadataReducer';
import ordersUpdateReducer from './reducers/orders/update/ordersUpdateReducer';
import orderProductsListReducer from './reducers/orderProducts/list/orderProductsListReducer';
import orderProductsMetadataReducer from './reducers/orderProducts/metadata/orderProductsMetadataReducer';
import orderProductsOverdueListReducer from './reducers/orderProductsOverdue/list/orderProductsOverdueListReducer';
import orderProductsOverdueMetadataReducer from './reducers/orderProductsOverdue/metadata/orderProductsOverdueMetadataReducer';
import orderProductsUndispatchedListReducer from './reducers/orderProductsUndispatched/list/orderProductsUndispatchedListReducer';
import orderProductsUndispatchedMetadataReducer from './reducers/orderProductsUndispatched/metadata/orderProductsUndispatchedMetadataReducer';
import hostingExtensionsReducer from './reducers/orderProducts/hostingExtensions/hostingExtensionsReducer';

/**
 * MEDIAS REDUCERS
 */

import mediaGroupsReducer from './reducers/medias/mediaGroupsReducer';
import mediasReducer from './reducers/medias/mediasReducer';

/**
 * METRICS REDUCERS
 */
import createDashboardMetricsReducer from './reducers/metrics/dashboard/create/createDashboardMetricsReducer';
import listDashboardMetricsReducer from './reducers/metrics/dashboard/list/listDashboardMetricsReducer';
import updateDashboardMetricsReducer from './reducers/metrics/dashboard/update/updateDashboardMetricsReducer';
import metricsFormReducer from './reducers/metrics/dashboard/form/metricsFormReducer';

import config from '../config';

const coreReducer = combineReducers({
  metadata: metadataReducer,
  create: createCoreReducer,
  deleteItem: deleteCoreReducer,
  list: listCoreReducer,
  update: updateCoreReducer,
  item: itemReducer
});

const orderProductsOverdueReducer = combineReducers({
  metadata: orderProductsOverdueMetadataReducer,
  list: orderProductsOverdueListReducer
});

const orderProductsUndispatchedReducer = combineReducers({
  metadata: orderProductsUndispatchedMetadataReducer,
  list: orderProductsUndispatchedListReducer
});

const previousDayReducer = combineReducers({
  metadata: previousDayMetadataReducer,
  list: previousDayListReducer
});

const productionReducer = combineReducers({
  moderation: moderationReducer,
  previousDay: previousDayReducer,
  orderProductsOverdue: orderProductsOverdueReducer
});

const providersReducer = combineReducers({
  providerFactory: providerFactoryReducer,
  providersMap: providersMapReducer
});

const tasksReducers = combineReducers({
  tasks: tasksReducer,
  task_views: taskViewsReducer
});

const viewsReducer = combineReducers({
  profile: profileReducer,
  provider: providerViewReducer,
  product: productViewReducer,
  user: userViewReducer,
  company: companyViewReducer,
  tasks: tasksReducers
});

const ordersReducer = combineReducers({
  metadata: ordersMetadataReducer,
  create: createOrderReducer,
  list: ordersListReducer,
  update: ordersUpdateReducer
});

const orderProductsReducer = combineReducers({
  metadata: orderProductsMetadataReducer,
  list: orderProductsListReducer,
  hosting_extensions: hostingExtensionsReducer
});

const dashboardMetricsReducer = combineReducers({
  form: metricsFormReducer,
  create: createDashboardMetricsReducer,
  list: listDashboardMetricsReducer,
  update: updateDashboardMetricsReducer
});

const metricsReducer = combineReducers({
  dashboard: dashboardMetricsReducer
});

const reducers = combineReducers({
  app: appReducer,
  authentication: authReducer,
  user: userReducer,
  core: coreReducer,
  orderProducts: orderProductsReducer,
  orderProductsUndispatched: orderProductsUndispatchedReducer,
  orders: ordersReducer,
  mediaGroups: mediaGroupsReducer,
  medias: mediasReducer,
  metrics: metricsReducer,
  production: productionReducer,
  providers: providersReducer,
  schedule: scheduleReducer,
  views: viewsReducer,
  filters: filtersReducer,
  forms: formsReducer
});

const composeEnhancers = config.env_mode === 'prod' ? compose : composeWithDevTools;

export default createStore(reducers, composeEnhancers(applyMiddleware(thunk)));
