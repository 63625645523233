/**
 * Waiting for animation and time fix from the plugin v3
 * https://github.com/chartjs/Chart.js/issues/6677
 */
import './HealthMonitor.scss';

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import 'chartjs-plugin-streaming';
import 'chartjs-plugin-annotation';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import { addToDate, formatDate, getCurrentDateUtc } from '../../utils/functions/dates';

function HealthMonitor(props) {
  const { getMetrics } = props;
  const chartRefreshPeriod = 30;

  useEffect(() => {
    document.title = 'Actarus | Health monitor';
  }, []);

  function handleRefresh(chart) {
    const now = getCurrentDateUtc();
    const beforeNow = addToDate(now, -1, 'hours');

    const query = {
      from: formatDate(beforeNow, 'YYYY-MM-DDTHH:mm:ssZ'),
      until: formatDate(now, 'YYYY-MM-DDTHH:mm:ssZ')
    };

    chart.data.datasets.forEach(function (dataset) {
      getMetrics(query, (success) => {
        dataset.data.push({
          x: Date.now(),
          y: success.values[0] ? parseFloat(success.values[0], 10) : 0
        });
      });
    });
  }

  function renderMonitor() {
    const getDataProps = (canvas) => {
      const ctx = canvas.getContext('2d');
      const fillGradient = ctx.createLinearGradient(0, 0, 0, 200);

      fillGradient.addColorStop(0, 'rgb(244, 65, 65)');
      fillGradient.addColorStop(0.25, 'rgb(244, 65, 65)');
      fillGradient.addColorStop(0.45, 'rgb(244, 158, 66)');
      fillGradient.addColorStop(0.65, 'rgb(235, 244, 65)');
      fillGradient.addColorStop(0.8, 'rgb(65, 244, 119)');
      fillGradient.addColorStop(1, 'rgb(65, 244, 119)');

      return {
        datasets: [
          {
            data: [],
            label: 'Rate',
            borderColor: 'black',
            borderWidth: 1,
            pointRadius: 0,
            backgroundColor: fillGradient
          }
        ]
      };
    };

    return (
      <Line
        data={getDataProps} // eslint-disable-line
        options={{
          responsive: true,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  min: 0,
                  max: 100
                }
              }
            ],
            xAxes: [
              {
                type: 'realtime',
                realtime: {
                  duration: chartRefreshPeriod * 1000 * 10,
                  refresh: chartRefreshPeriod * 1000,
                  delay: (chartRefreshPeriod + 3) * 1000,
                  frameRate: 20,
                  onRefresh: handleRefresh
                }
              }
            ]
          },
          legend: {
            display: false
          },
          annotation: {
            annotations: [
              {
                type: 'line',
                mode: 'horizontal',
                scaleID: 'y-axis-0',
                value: 20,
                borderColor: 'red',
                borderWidth: 3,
                label: {
                  enabled: false
                }
              }
            ]
          }
        }}
      />
    );
  }

  return (
    <div className="HealthMonitor">
      <AppBar elevation={0} position="static" color="default">
        <Toolbar>
          <Typography variant="body2" color="textPrimary">
            Api health monitor
          </Typography>
        </Toolbar>
      </AppBar>
      <div className="monitor-wrapper">{renderMonitor()}</div>
    </div>
  );
}

HealthMonitor.propTypes = {
  getMetrics: PropTypes.func.isRequired
};

export default HealthMonitor;
