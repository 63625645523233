import './createView.scss';

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { Button, CircularProgress, Paper } from '@material-ui/core';
import { Add as AddIcon, Save as SaveIcon } from '@material-ui/icons';

import CreateViewBar from './components/CreateViewBar';
import FormsContainer from '../../../containers/forms/FormsContainer';
// import { TimezoneField } from '../../../components/forms/fields';
// import { getTimezone } from '../../../utils/functions/cookies';
import translation from '../../../translation/translation';

const createCancelToken = () => axios.CancelToken.source();
const getCancelToken = () => axios.CancelToken.source();

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingBottom: theme.spacing(2)
  },
  // formHead: {
  //   display: "flex",
  //   alignItems: "flex-end",
  //   justifyContent: "flex-end",
  //   padding: "0px 12px",
  //   [theme.breakpoints.down(400)]: {
  //     flexDirection: "column"
  //   },
  // },
  button: {
    marginTop: 4,
    fontWeight: 400
  },
  buttonIcon: {
    marginRight: 8
  },
  createLoadingText: {
    display: 'inline-block',
    verticalAlign: 'top',
    margin: '2px 10px'
  },
  submitWrapper: {
    textAlign: 'right',
    marginTop: theme.spacing(3)
  }
  // timezoneField: {
  //   minWidth: 220,
  //   marginRight: 8,
  //   [theme.breakpoints.down(400)]: {
  //     width: "100%",
  //     minWidth: "100%",
  //     marginRight: 0
  //   },
  // }
}));

const loaderWrapperStyle = {
  display: 'inline-block',
  width: 'auto',
  padding: 0,
  verticalAlign: 'middle',
  margin: '0 10px'
};

const GetMetadataApi = (getMetadata, url, needLoad) => {
  const [source] = useState(createCancelToken());

  useEffect(() => {
    if (needLoad) getMetadata(url, source);

    return () => {
      source.cancel('=> Core metadata changed.');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const GetItemApi = (getItem, url) => {
  const [source] = useState(getCancelToken());

  const doRefresh = (locale) => {
    getItem(url, source, locale);
  };

  useEffect(() => {
    getItem(url, source);

    return () => {
      source.cancel('=> Core data changed.');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { doRefresh };
};

function CreateView(props) {
  const classes = useStyles();

  const {
    create: {
      isLoading
      // timezone
    },
    createResource,
    item,
    getItem,
    getMetadata,
    history,
    location: { pathname },
    metadata,
    metadataUri,
    parentUri,
    routeUri,
    // setCreateResourceTimezone,
    setListNeedReload
  } = props;

  const isDuplicateForm = pathname.indexOf('duplicate') !== -1;
  const [submitType, setSubmitType] = useState('create');

  GetMetadataApi(getMetadata, metadataUri, true);
  // const { doRefresh } = GetItemApi(getItem, pathname.substring(1).replace('/duplicate', ''));

  if (isDuplicateForm) {
    GetItemApi(getItem, pathname.substring(1).replace('/duplicate', ''));
  }

  useEffect(() => {
    if (isDuplicateForm) {
      document.title = `Actarus | Duplicate ${pathname.substring(1).replace('/duplicate', '')}`;
    } else {
      document.title = `Actarus | Create ${routeUri}`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleSumbit(values) {
    if (values && !isLoading && metadata && metadata.data && metadata.data.fields) {
      if (values.id) {
        values.id = '';
      }
      if (values.created) {
        values.created = '';
      }

      if (isDuplicateForm) {
        // Check if duplicate route, if yes remove the last ID in the URL
        const duplicatePath = pathname.substring(1).replace('/duplicate', '');
        const duplicatePathArray = duplicatePath.split('/');
        duplicatePathArray.pop();
        const finalDuplicatePath = duplicatePathArray.join('/');

        createResource(finalDuplicatePath, values, metadata.data.fields, (success) => {
          setListNeedReload();

          if (submitType === 'save') {
            if (history) {
              history.push(`/${finalDuplicatePath}/${success.id}/update`);
            } else {
              window.location = `/${finalDuplicatePath}/${success.id}/update`;
            }
          } else {
            if (history) {
              history.push(`/${finalDuplicatePath}`);
            } else {
              window.location = `/${finalDuplicatePath}`;
            }
          }
        });
      } else {
        createResource(
          pathname.substring(1).replace('/create', ''),
          values,
          metadata.data.fields,
          (success) => {
            setListNeedReload();

            if (submitType === 'save') {
              if (history) {
                history.push(pathname.replace('/create', '') + '/' + success.id + '/update');
              } else {
                window.location = pathname.replace('/create', '') + '/' + success.id + '/update';
              }
            } else {
              if (history) {
                history.push(pathname.replace('/create', ''));
              } else {
                window.location = pathname.replace('/create', '');
              }
            }
          }
        );
      }
    }
  }

  function handleCreate() {
    setSubmitType('create');
  }

  // function handleChangeTimezone(value) {
  //   setCreateResourceTimezone(value);
  // }

  function handleSave() {
    setSubmitType('save');
  }

  // function renderTimezones() {
  //   return (
  //     <TimezoneField
  //       className={classes.timezoneField}
  //       label="Timezone"
  //       placeholder="timezone"
  //       fullWidth
  //       name="update-resource-select-timezone"
  //       onChange={handleChangeTimezone}
  //       defaultValue={timezone ? timezone : getTimezone()}
  //       clearable={false}
  //     />
  //   );
  // }

  return (
    <div className="CreateView">
      <CreateViewBar history={history} parentUri={parentUri} disabled={isLoading} />
      <div className={classes.root}>
        <div className="form-wrapper">
          {!metadata.isLoading && (
            <Paper>
              {/* <div className={classes.formHead}>
                {renderTimezones()}
              </div> */}
              <FormsContainer
                isWillReceiveValues={isDuplicateForm}
                metadata={metadata}
                // timezone={timezone}
                defaultValues={(isDuplicateForm && item.data) || undefined}
                formName="createForm"
                onSubmit={handleSumbit}>
                <div className={classes.submitWrapper}>
                  {isLoading && (
                    <div className="loader-wpr" style={loaderWrapperStyle}>
                      <CircularProgress size={20} color="secondary" />
                      <p className={classes.createLoadingText}>
                        {translation().core.create.load_create}
                      </p>
                    </div>
                  )}
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    type="submit"
                    style={{ marginRight: 8 }}
                    onClick={handleCreate}
                    disabled={isLoading}>
                    <AddIcon className={classes.buttonIcon} />
                    {translation().actions.create_and_quit}
                  </Button>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    type="submit"
                    onClick={handleSave}
                    disabled={isLoading}>
                    <SaveIcon className={classes.buttonIcon} />
                    {translation().actions.save}
                  </Button>
                </div>
              </FormsContainer>
            </Paper>
          )}
          {(item.isLoading || metadata.isLoading) && (
            <div className="loader-wpr">
              <CircularProgress color="secondary" />
              <p>{translation().core.item.load_item}</p>
              {metadata.isLoading && <p>{translation().core.load_metadata}</p>}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

CreateView.propTypes = {
  create: PropTypes.shape().isRequired,
  createResource: PropTypes.func.isRequired,
  item: PropTypes.shape({
    data: PropTypes.shape(),
    isLoading: PropTypes.bool
  }),
  getItem: PropTypes.func.isRequired,
  getMetadata: PropTypes.func.isRequired,
  history: PropTypes.shape().isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired,
  metadata: PropTypes.shape(),
  metadataUri: PropTypes.string.isRequired,
  parentUri: PropTypes.string.isRequired,
  routeUri: PropTypes.string.isRequired,
  // setCreateResourceTimezone: PropTypes.func.isRequired,
  setListNeedReload: PropTypes.func.isRequired
};

export default CreateView;
