/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Card from '@material-ui/core/Card';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import grey from '@material-ui/core/colors/grey';

import Alert from '../../../../components/alert/Alert';
import ListDashboardMetricsBar from './components/ListDashboardMetricsBar';
import Dashboard from './components/Dashboard';
import config from '../../../../config';
import translation from '../../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  addNewDashboad: {
    minHeight: 310,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    cursor: 'pointer',
    backgroundColor: theme.palette.type === 'dark' ? '#292929' : grey[300],
    transition: '200ms',
    willChange: 'background-color',
    '&:hover': {
      backgroundColor: theme.palette.type === 'dark' ? grey[800] : grey[200]
    }
  },
  addNewIcon: {
    fontSize: '3rem',
    color: 'white'
  },
  cardAction: {
    marginLeft: 4
  },
  cardHeaderAction: {
    margin: 0
  },
  cardContent: {
    minHeight: 250,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  deleteButton: {
    marginLeft: 8,
    color: config.error_color || '#F44336',
    '&:hover': {
      backgroundColor: fade(config.error_color || '#F44336', 0.15)
    }
  },
  img: {
    width: 150,
    marginBottom: 24
  }
}));

function ListDashboardMetrics(props) {
  const classes = useStyles();
  const {
    create,
    createDashboard,
    deleteDashboard,
    getDashboards,
    history,
    list: { data, paging, error, isLoading, delete_dashboard, update_dashboard },
    updateDashboard
  } = props;

  const [dashboardSelected, setDashboardSelected] = useState(null);
  const [isDeleteOpen, setDeleteOpen] = useState(false);
  const [isFormOpen, setFormOpen] = useState(false);
  const [name, setName] = useState('');

  function loadDashboards(next, isRefresh = false) {
    getDashboards(next, isRefresh);
  }

  useEffect(() => {
    document.title = 'Actarus | Dashboards';

    loadDashboards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleLoadNext() {
    if (!isLoading && paging && paging.next) {
      loadDashboards(paging.next);
    }
  }

  function handleOpenCreateDialog() {
    setFormOpen(true);
  }

  function handleOpenUpdateDialog(dashboardObject) {
    setDashboardSelected(dashboardObject);

    if (dashboardObject && dashboardObject.name) {
      setName(dashboardObject.name);
    }

    setFormOpen(true);
  }

  function handleRefresh() {
    loadDashboards(undefined, true);
  }

  function handleCloseFormDialog() {
    setFormOpen(false);
    setDashboardSelected(null);
    setName('');
  }

  function handleOpenDeleteDialog(dashboardObject) {
    setDashboardSelected(dashboardObject);
    setDeleteOpen(true);
  }

  function handleCloseDeleteDialog() {
    setDeleteOpen(false);
    setDashboardSelected(null);
  }

  function handleSubmit(event) {
    event.preventDefault();

    if (dashboardSelected && dashboardSelected.id) {
      updateDashboard(dashboardSelected.id, { name }, () => {
        handleCloseFormDialog();
        setDashboardSelected(null);
        setName('');
      });
    } else {
      createDashboard({ name }, handleCloseFormDialog);
    }
  }

  function handleChangeName(event) {
    setName(event.target.value);
  }

  function handleNavigateToDashboard(id) {
    history.push(`/dashboard/${id}/update`);
  }

  function handleDeleteDashboard() {
    if (dashboardSelected && dashboardSelected.id) {
      deleteDashboard(dashboardSelected.id, handleCloseDeleteDialog);
    }
  }

  function renderList(data) {
    const list = [];

    for (let i = 0; i < data.length; i++) {
      list.push(
        <Dashboard
          key={data[i].id}
          data={data[i]}
          classes={classes}
          onClick={handleNavigateToDashboard}
          openDeleteDialog={handleOpenDeleteDialog}
          openUpdateDialog={handleOpenUpdateDialog}
        />
      );
    }

    return list;
  }

  return (
    <div className="ListDashboardMetrics">
      <ListDashboardMetricsBar
        loading={isLoading}
        handleRefresh={handleRefresh}
        handleCreate={handleOpenCreateDialog}
      />
      <div className={classes.root}>
        {!isLoading && error && (!data || data.length <= 0) ? (
          <div className="error-list">
            <Alert
              type="danger"
              style={{ marginTop: 10 }}
              status={translation().commons.alerts.error}
              text={translation().core.list.error_list}
            />
          </div>
        ) : (
          false
        )}
        {!error && (
          <Container>
            <Grid container spacing={2}>
              {data && data.length > 0 ? renderList(data) : false}
              {!isLoading && (
                <Grid item xs={12} sm={6} md={4}>
                  <ButtonBase
                    component={Card}
                    onClick={handleOpenCreateDialog}
                    className={classes.addNewDashboad}
                    elevation={0}>
                    <AddCircleIcon className={classes.addNewIcon} />
                  </ButtonBase>
                </Grid>
              )}
            </Grid>
          </Container>
        )}
        {isLoading && (
          <div className="loader-wpr">
            <CircularProgress color="primary" />
            <p>{translation().metrics.dashboard.list.loading_text}</p>
          </div>
        )}
        {paging && paging.next && (
          <div className="loader-wpr">
            <Button
              color="primary"
              variant="contained"
              onClick={handleLoadNext}
              disabled={isLoading}>
              {translation().actions.load_more}
            </Button>
          </div>
        )}
      </div>
      <Dialog
        open={isDeleteOpen}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="delete-dashboard-dialog"
        disableBackdropClick={delete_dashboard.isLoading}
        fullWidth
        maxWidth="sm">
        <DialogTitle id="delete-resource-item">
          {translation().metrics.dashboard.delete.dialog.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            {translation().metrics.dashboard.delete.dialog.content}
          </DialogContentText>
          {delete_dashboard.isLoading && (
            <>
              <div className="sm-space" />
              <div className="loader-wpr">
                <CircularProgress size={30} color="secondary" />
                <p>{translation().metrics.dashboard.delete.dialog.load_delete}</p>
              </div>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDeleteDialog}
            disabled={delete_dashboard.isLoading}
            color="default">
            {translation().actions.close}
          </Button>
          <Button
            onClick={handleDeleteDashboard}
            className={classes.deleteButton}
            disabled={delete_dashboard.isLoading || !dashboardSelected}>
            {translation().actions.delete}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isFormOpen}
        onClose={handleCloseFormDialog}
        aria-labelledby="create-new-dashboard-dialog"
        disableBackdropClick={create.isLoading || update_dashboard.isLoading}
        fullWidth
        maxWidth="sm">
        <DialogTitle id="create-new-dashboard-dialog">
          {dashboardSelected
            ? translation().metrics.dashboard.update.title
            : translation().metrics.dashboard.create.title}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <TextField
              id="dashboard-name"
              label={translation().metrics.dashboard.create.form.labels.name}
              required
              value={name}
              onChange={handleChangeName}
              disabled={create.isLoading || update_dashboard.isLoading}
              fullWidth
            />
          </form>
          {create.isLoading && (
            <>
              <div className="sm-space" />
              <div className="loader-wpr">
                <CircularProgress color="primary" />
                <p>{translation().metrics.dashboard.create.loading_text}</p>
              </div>
            </>
          )}
          {update_dashboard.isLoading && (
            <>
              <div className="sm-space" />
              <div className="loader-wpr">
                <CircularProgress color="primary" />
                <p>{translation().metrics.dashboard.update.loading_text}</p>
              </div>
            </>
          )}
          <div className="sm-space" />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseFormDialog}
            color="default"
            disabled={create.isLoading || update_dashboard.isLoading}>
            {translation().actions.close}
          </Button>
          <Button
            onClick={handleSubmit}
            style={{ marginLeft: 8 }}
            variant="contained"
            color="primary"
            disabled={!name || create.isLoading || update_dashboard.isLoading}>
            {dashboardSelected ? translation().actions.save : translation().actions.create}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ListDashboardMetrics.propTypes = {
  create: PropTypes.shape({
    isLoading: PropTypes.bool
  }).isRequired,
  createDashboard: PropTypes.func.isRequired,
  deleteDashboard: PropTypes.func.isRequired,
  getDashboards: PropTypes.func.isRequired,
  history: PropTypes.shape().isRequired,
  list: PropTypes.shape({
    error: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape()),
    paging: PropTypes.shape({
      next: PropTypes.string,
      prev: PropTypes.string
    }),
    isLoading: PropTypes.bool,
    delete_dashboard: PropTypes.shape({
      isLoading: PropTypes.bool
    }),
    update_dashboard: PropTypes.shape({
      isLoading: PropTypes.bool
    })
  }).isRequired,
  updateDashboard: PropTypes.func.isRequired
};

export default ListDashboardMetrics;
