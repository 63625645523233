import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchApi } from '../../../utils/functions/api';
import { enqueueSnackbar } from '../../../redux/actions/appActions';
import PayerBridge from '../../../views/core/customListActions/PayerBridge';
import translation from '../../../translation/translation';

const mapDispatchToProps = (dispatch) => {
  return {
    getPayerId: (userType = 'users', id, callbackSuccess, callbackError) => {
      if (!id) return;

      fetchApi(
        'get',
        `payers`,
        userType === 'companies'
          ? { filters: `iam_company_id|eq|${id}` }
          : { filters: `iam_user_id|eq|${id}` },
        null,
        null,
        (success) => {
          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success);
          }
        },
        (error) => {
          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.dialogs.custom_list_actions.payer_bridge
                        .callback_error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message:
                  translation().core.list.dialogs.custom_list_actions.payer_bridge.callback_error,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError();
          }
        }
      );
    }
  };
};

export default withRouter(connect(null, mapDispatchToProps)(PayerBridge));
