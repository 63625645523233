import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Check } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  checkIcon: {
    color: theme.palette.success.main
  },
  listItem: {
    padding: theme.spacing(1, 0)
  },
  levelListIcon: {
    minWidth: 42
  }
}));

function LevelMenuItem(props) {
  const classes = useStyles();

  const { name, onSelect, selected, value } = props;

  function handleSelect() {
    onSelect({ name, value });
  }

  return (
    <ListItem button onClick={handleSelect}>
      <ListItemIcon className={classes.levelListIcon}>
        {selected && selected.value === value && <Check className={classes.checkIcon} />}
      </ListItemIcon>
      <ListItemText>{name}</ListItemText>
    </ListItem>
  );
}

LevelMenuItem.propTypes = {
  name: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  }),
  value: PropTypes.string.isRequired
};

export default LevelMenuItem;
