import axios from 'axios';

import { getAccessToken, getLocale, getCurrency, getTimezone } from './cookies';
import { generateRandom, isObjEmpty, stringifyQuery } from './utils';

import config from '../../config';

export const fetchApi = (
  method,
  route,
  query,
  data,
  headers,
  onSuccess,
  onError,
  onCancel,
  source = null,
  isAccessToken = true
) => {
  if (!method) method = 'get';

  if (['get', 'post', 'put', 'delete', 'options'].indexOf(method) < 0)
    throw new Error('Wrong method');

  if (!route) throw new Error('Route not found');

  if (!query) query = {};

  if (!data) data = {};

  if (!headers) headers = {};

  const accessToken = getAccessToken();
  const timezone = getTimezone();
  const currency = getCurrency();
  const locale = getLocale();
  const axiosRequest = {};

  if (source && source.token) axiosRequest.cancelToken = source.token;

  if (isAccessToken) {
    if (accessToken) query.access_token = accessToken;
    else query.client_id = config.api_key;
  } else query.client_id = config.api_key;

  if (method) axiosRequest.method = method;

  if (route) {
    if (route.match(config.api_url)) {
      route = route.replace(config.api_url, '');
    }
    axiosRequest.url = config.api_url + route;
  }

  if (locale && !query.locale) query.locale = locale;

  if (currency && !query.currency) query.currency = currency;

  if (timezone && !query.timezone) query.timezone = timezone;

  if (!isObjEmpty(query)) axiosRequest.params = query;

  if (!isObjEmpty(data)) axiosRequest.data = data;

  if (!isObjEmpty(headers)) axiosRequest.headers = headers;

  axios(axiosRequest)
    .then((response) => {
      const data = response.data.data;
      const paging = response.data.paging;

      if (onSuccess && typeof onSuccess === 'function') onSuccess(data, paging);
      else return { data, paging };
    })
    .catch((thrown) => {
      if (axios.isCancel(thrown)) {
        // eslint-disable-next-line
        console.info(
          '%cREQUEST CANCELLED: ' + thrown.message,
          'background: #8378F4; color: #D1C4E9; padding: 2px 4px'
        );

        if (onCancel && typeof onCancel === 'function') onCancel(thrown);
      } else {
        if (onError && typeof onError === 'function') onError(thrown);
      }
    });
};

/**
 * Post files to clouding
 */
export const apiPostFile = (file, onSuccess, onError) => {
  if (!file) return;

  if (file) {
    const formData = new FormData();
    formData.append('file', file);

    axios
      .post(
        `${config.api_url}files/temp?client_id=${config.api_key}&tmp=${generateRandom(
          1,
          9999
        )}${new Date().getTime()}`,
        formData,
        { headers: { 'X-Requested-With': 'XMLHttpRequest' } }
      )
      .then((response) => {
        onSuccess(response.data);
      })
      .catch((error) => {
        onError(error);
      });
  }
};

export const getApiUrl = (route, query, encodeUrl = true) => {
  if (!route) throw new Error('Route not found');

  if (!query) query = {};

  let url = null;
  let queryString,
    queryJoin = '';
  const accessToken = getAccessToken();

  if (accessToken) query.access_token = accessToken;
  else query.client_id = config.api_key;

  const locale = getLocale();
  if (locale) query.locale = locale;

  const currency = getCurrency();
  if (currency) query.currency = currency;

  const timezone = getTimezone();
  if (timezone) query.timezone = timezone;

  if (route) url = config.api_url + route;

  queryString = stringifyQuery(query, encodeUrl);

  if (queryString) queryJoin = url.indexOf('?') >= 0 ? '&' : '?';

  return url + queryJoin + queryString;
};

/**
 * Load in DOM the payment iframe
 */
export const getApiPaymentMethodIframe = (route, way, onSuccess, onError) => {
  if (!route || !onSuccess) return;

  const accessToken = getAccessToken();
  const iframeContainer = document.getElementById('payment-frame-container');
  const iframePaymentLoader = document.getElementById('frame-payment-loader');

  if (!iframeContainer) return;

  if (iframePaymentLoader) iframePaymentLoader.style.display = 'block';

  const src = `${config.api_url}${route}?access_token=${accessToken}${way ? '&way=' + way : ''}`;
  const paymentFrame = `<iframe id="frame-payment" style="min-height: 450px" title="paymentMethod" src=${src} />`;

  iframeContainer.innerHTML = '';
  iframeContainer.innerHTML = paymentFrame;

  document.getElementById('frame-payment').onload = function () {
    if (iframePaymentLoader) iframePaymentLoader.style.display = 'none';

    const paymentFrame = document.getElementById('frame-payment');

    try {
      const frameWindowLocation = paymentFrame.contentWindow.location.href;

      if (!frameWindowLocation) return;
      else if (frameWindowLocation && !paymentFrame.contentWindow.location.hash) {
        return;
      }
    } catch (e) {
      if (iframePaymentLoader) iframePaymentLoader.style.display = 'none';

      return;
    }

    /**
     * Get and split all hash
     */
    const hash = paymentFrame.contentWindow.location.hash;

    if (hash === '#success' && onSuccess && typeof onSuccess === 'function') {
      onSuccess();
    } else {
      if (onError && typeof onError === 'function') {
        onError();
      }
    }
  };
};

export const apiGeocodeTimezone = (query = {}, onSuccess, onError) => {
  axios
    .get(`${config.api_url}geocoder/timezone`, {
      params: { ...query, access_token: getAccessToken() }
    })
    .then((response) => {
      onSuccess(response.data.data);
    })
    .catch((error) => {
      onError(error);
    });
};

export const getApiConfigFromLocation = (key) => {
  const envs = {
    PROD: {
      api_url: 'https://api.wall-market.com/v1/',
      api_key: '9560f5b83f8737fd33468df147bf863f',
      name: 'prod'
    },
    SANDBOX: {
      api_url: 'https://api.sandbox.wall-market.com/v1/',
      api_key: '559568af7befafe30a606c393e7a77d0',
      name: 'sandbox'
    },
    DEV: {
      api_url: 'https://api.dev-wall-market.com/v1/',
      api_key: '559568af7befafe30a606c393e7a77d0',
      name: 'dev'
    },
    LOCAL: {
      api_url: 'https://api.dev-wall-market.com/v1/',
      api_key: '77a66f5f416a8abff3f1fb6df03c8b3a',
      name: 'local'
    }
  };

  const locations = {
    PROD: 'actarus.wall-market.com',
    SANDBOX: 'actarus.sandbox.wall-market.com',
    DEV: 'actarus.dev-wall-market.com',
    LOCAL: 'localhost'
  };

  if (window && window.location && window.location.hostname) {
    for (let prop in locations) {
      if (window.location.hostname === locations[prop]) {
        return envs[prop][key];
      }
    }
  } else {
    return envs['LOCAL'][key];
  }
};
