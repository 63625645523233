const initialState = {
  error: null,
  isLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_REDUCERS':
      return initialState;
    case 'CREATE_DASHBOARD':
      return {
        ...state,
        isLoading: true
      };
    case 'CREATE_DASHBOARD_SUCCESS':
      return {
        ...state,
        isLoading: false,
        error: null
      };
    case 'CREATE_DASHBOARD_ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    default:
      return state;
  }
};
