const initialState = {
  currentView: 'week',
  currentTimeRange: {},
  loading: false,
  error: null,
  availabilities: {
    delete: {
      loading: false,
      error: null
    },
    create: {
      loading: false,
      error: null
    },
    edit: {
      loading: false,
      error: null
    },
    loading: false,
    error: null
  },
  unavailabilities: {
    delete: {
      loading: false,
      error: null
    },
    create: {
      loading: false,
      error: null
    },
    edit: {
      loading: false,
      error: null
    },
    loading: false,
    error: null
  },
  events: {
    list: [],
    loading: false,
    error: null
  },
  provider_infos: {
    loading: false,
    error: null,
    data: null
  },
  timezone: null
};

const scheduleReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_REDUCERS':
      return initialState;
    case 'CLEAR_SCHEDULE':
      return initialState;
    case 'CLEAR_CURRENT_TIMEZONE':
      return {
        ...state,
        timezone: initialState.timezone
      };
    case 'STORE_CURRENT_VIEW':
      return {
        ...state,
        currentView: action.data
      };
    case 'STORE_CURRENT_TIME_RANGE':
      return {
        ...state,
        currentTimeRange: action.data
      };
    case 'STORE_CURRENT_TIMEZONE':
      return {
        ...state,
        timezone: action.data
      };
    case 'GET_PROVIDER_CALENDAR': {
      return {
        ...state,
        events: {
          list: [],
          loading: true,
          error: null
        }
      };
    }
    case 'GET_PROVIDER_CALENDAR_SUCCESS': {
      return {
        ...state,
        events: {
          list: action.data,
          loading: false,
          error: null
        }
      };
    }
    case 'GET_PROVIDER_CALENDAR_ERROR': {
      return {
        ...state,
        events: {
          ...state.events,
          loading: false,
          error: action.error
        }
      };
    }

    /**
     * Availabilities actions
     */
    case 'POST_AVAILABILITY':
      return {
        ...state,
        availabilities: {
          ...state.availabilities,
          loading: true,
          create: {
            loading: true,
            error: null
          }
        }
      };
    case 'POST_AVAILABILITY_SUCCESS': {
      const newAvailabilities = state.events.list.concat(action.data);

      return {
        ...state,
        events: {
          ...state.events,
          list: newAvailabilities
        },
        availabilities: {
          ...state.availabilities,
          loading: false,
          create: {
            loading: false,
            error: null
          }
        }
      };
    }
    case 'POST_AVAILABILITY_ERROR':
      return {
        ...state,
        availabilities: {
          ...state.availabilities,
          loading: false,
          create: {
            loading: false,
            error: action.error
          }
        }
      };

    case 'PUT_AVAILABILITY':
      return {
        ...state,
        availabilities: {
          ...state.availabilities,
          edit: {
            loading: true,
            error: null
          }
        }
      };
    case 'PUT_AVAILABILITY_SUCCESS': {
      let eventsUpdated = state.events.list;
      /**
       * Replace old availability by new one returned by the API
       */
      for (let event in state.events.list) {
        if (eventsUpdated[event]['id'] === action.id) eventsUpdated[event] = action.data;
      }

      return {
        ...state,
        events: {
          ...state.events,
          list: eventsUpdated
        },
        availabilities: {
          ...state.availabilities,
          edit: {
            loading: false,
            error: null
          }
        }
      };
    }
    case 'PUT_AVAILABILITY_ERROR':
      return {
        ...state,
        events: {
          ...state.events,
          loading: false
        },
        availabilities: {
          ...state.availabilities,
          edit: {
            loading: false,
            error: null
          }
        }
      };
    case 'DELETE_AVAILABILITY':
      return {
        ...state,
        availabilities: {
          ...state.availabilities,
          delete: {
            loading: true,
            error: null
          }
        }
      };
    case 'DELETE_AVAILABILITY_SUCCESS': {
      const eventsAfterDelete = state.events.list.filter((el) => {
        return el.id !== action.id;
      });

      return {
        ...state,
        events: {
          ...state.events,
          list: eventsAfterDelete
        },
        availabilities: {
          ...state.availabilities,
          delete: {
            loading: false,
            error: null
          }
        }
      };
    }
    case 'DELETE_AVAILABILITY_ERROR':
      return {
        ...state,
        availabilities: {
          ...state.availabilities,
          delete: {
            loading: false,
            error: action.error
          }
        }
      };
    case 'RESIZE_AVAILABILITY': {
      let eventsUpdated = state.events.list;
      /**
       * Replace old availability by new one returned by the API
       */
      for (let event in state.events.list) {
        if (eventsUpdated[event]['id'] === action.id) eventsUpdated[event] = action.data;
      }

      return {
        ...state,
        events: {
          ...state.events,
          list: eventsUpdated,
          loading: true
        }
      };
    }
    case 'RESIZE_AVAILABILITY_SUCCESS': {
      let eventsUpdated = state.events.list;
      /**
       * Replace old availability by new one returned by the API
       */
      for (let event in state.events.list) {
        if (eventsUpdated[event]['id'] === action.id) eventsUpdated[event] = action.data;
      }

      return {
        ...state,
        events: {
          ...state.events,
          list: eventsUpdated,
          loading: false
        }
      };
    }
    case 'RESIZE_AVAILABILITY_FAILURE': {
      let eventsUpdated = state.events.list;
      /**
       * Replace old availability by new one returned by the API
       */
      for (let event in state.events.list) {
        if (eventsUpdated[event]['id'] === action.id) eventsUpdated[event] = action.data;
      }

      return {
        ...state,
        events: {
          ...state.events,
          list: eventsUpdated,
          loading: false
        }
      };
    }
    /**
     * Unavailabilities actions
     */
    case 'POST_UNAVAILABILITY':
      return {
        ...state,
        unavailabilities: {
          ...state.unavailabilities,
          loading: true,
          create: {
            loading: true,
            error: null
          }
        }
      };
    case 'POST_UNAVAILABILITY_SUCCESS': {
      const newEvents = state.events.list.concat(action.data);

      return {
        ...state,
        events: {
          ...state.events,
          list: newEvents
        },
        unavailabilities: {
          ...state.unavailabilities,
          loading: false,
          create: {
            loading: false,
            error: null
          }
        }
      };
    }
    case 'POST_UNAVAILABILITY_ERROR':
      return {
        ...state,
        unavailabilities: {
          ...state.unavailabilities,
          loading: false,
          create: {
            loading: false,
            error: action.error
          }
        }
      };
    case 'PUT_UNAVAILABILITY':
      return {
        ...state,
        unavailabilities: {
          ...state.unavailabilities,
          edit: {
            loading: true,
            error: null
          }
        }
      };
    case 'PUT_UNAVAILABILITY_SUCCESS': {
      let eventsUpdatedUna = state.events.list;
      /**
       * Replace old availability by new one returned by the API
       */
      for (let event in state.events.list) {
        if (eventsUpdatedUna[event]['id'] === action.id) eventsUpdatedUna[event] = action.data;
      }

      return {
        ...state,
        events: {
          ...state.events,
          list: eventsUpdatedUna
        },
        unavailabilities: {
          ...state.unavailabilities,
          edit: {
            loading: false,
            error: null
          }
        }
      };
    }
    case 'PUT_UNAVAILABILITY_ERROR':
      return {
        ...state,
        events: {
          ...state.events,
          loading: false
        },
        unavailabilities: {
          ...state.unavailabilities,
          edit: {
            loading: false,
            error: null
          }
        }
      };
    case 'DELETE_UNAVAILABILITY':
      return {
        ...state,
        unavailabilities: {
          ...state.unavailabilities,
          delete: {
            loading: true,
            error: null
          }
        }
      };
    case 'DELETE_UNAVAILABILITY_SUCCESS': {
      const eventsAfterDeleteU = state.events.list.filter((el) => {
        return el.id !== action.id;
      });

      return {
        ...state,
        events: {
          ...state.events,
          list: eventsAfterDeleteU
        },
        unavailabilities: {
          ...state.unavailabilities,
          delete: {
            loading: false,
            error: null
          }
        }
      };
    }
    case 'DELETE_UNAVAILABILITY_ERROR':
      return {
        ...state,
        unavailabilities: {
          ...state.unavailabilities,
          delete: {
            loading: false,
            error: action.error
          }
        }
      };
    case 'RESIZE_UNAVAILABILITY': {
      let eventsUpdatedUna = state.events.list;
      /**
       * Replace old availability by new one returned by the API
       */
      for (let event in state.events.list) {
        if (eventsUpdatedUna[event]['id'] === action.id) eventsUpdatedUna[event] = action.data;
      }

      return {
        ...state,
        events: {
          ...state.events,
          list: eventsUpdatedUna,
          loading: true
        }
      };
    }
    case 'RESIZE_UNAVAILABILITY_SUCCESS': {
      let eventsUpdatedUna = state.events.list;
      /**
       * Replace old availability by new one returned by the API
       */
      for (let event in state.events.list) {
        if (eventsUpdatedUna[event]['id'] === action.id) eventsUpdatedUna[event] = action.data;
      }

      return {
        ...state,
        events: {
          ...state.events,
          list: eventsUpdatedUna,
          loading: false
        }
      };
    }
    case 'RESIZE_UNAVAILABILITY_FAILURE': {
      let eventsUpdatedUna = state.events.list;
      /**
       * Replace old availability by new one returned by the API
       */
      for (let event in state.events.list) {
        if (eventsUpdatedUna[event]['id'] === action.id) eventsUpdatedUna[event] = action.data;
      }

      return {
        ...state,
        events: {
          ...state.events,
          list: eventsUpdatedUna,
          loading: false
        }
      };
    }
    case 'GET_PROVIDER_INFOS_CALENDAR':
      return {
        ...state,
        provider_infos: {
          data: null,
          error: null,
          loading: true
        }
      };
    case 'GET_PROVIDER_INFOS_CALENDAR_SUCCESS':
      return {
        ...state,
        provider_infos: {
          data: action.data,
          error: null,
          loading: false
        }
      };
    case 'GET_PROVIDER_INFOS_CALENDAR_FAILURE':
      return {
        ...state,
        provider_infos: {
          ...state.provider_infos,
          error: action.error,
          loading: false
        }
      };
    default:
      return state;
  }
};

export default scheduleReducer;
