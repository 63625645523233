import React from 'react';

import CoreMetricsContainer from '../../../../../containers/metrics/CoreMetricsContainer';
import providerIdMetrics from '../../../../../routes/metrics/providerIdMetrics';

function ProviderMetrics() {
  return <CoreMetricsContainer embed mapping={providerIdMetrics} />;
}

export default ProviderMetrics;
