export const setOrderProductsSearchValue = (value) => ({
  type: 'SET_ORDER_PRODUCTS_SEARCH_VALUE',
  value
});

export const refreshOrderProducts = () => ({
  type: 'REFRESH_ORDER_PRODUCTS'
});

export const getOrderProducts = (search, next) => ({
  type: 'GET_ORDER_PRODUCTS',
  search,
  next
});

export const getOrderProductsSuccess = (data, paging) => ({
  type: 'GET_ORDER_PRODUCTS_SUCCESS',
  data,
  paging
});

export const getOrderProductsError = (isError) => ({
  type: 'GET_ORDER_PRODUCTS_ERROR',
  isError
});

export const clearOwnReducer = () => ({
  type: 'CLEAR_ORDER_PRODUCTS_LIST_REDUCER'
});
