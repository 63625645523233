export const toggleProviderViewMode = (value) => ({
  type: 'TOGGLE_PROVIDER_VIEW_MODE',
  value
});

export const cleanProviderViewInvoicesReducer = () => ({
  type: 'CLEAN_PROVIDER_VIEW_INVOICES_REDUCER'
});

export const cleanProviderViewInvoiceQueueReducer = () => ({
  type: 'CLEAN_PROVIDER_VIEW_INVOICE_QUEUE_REDUCER'
});

export const cleanProviderViewPaymentMethodsReducer = () => ({
  type: 'CLEAN_PROVIDER_VIEW_PAYMENT_METHODS_REDUCER'
});

export const cleanProviderViewDevicesReducer = () => ({
  type: 'CLEAN_PROVIDER_VIEW_DEVICES_REDUCER'
});

export const cleanProviderViewAddressesReducer = () => ({
  type: 'CLEAN_PROVIDER_VIEW_ADDRESSES_REDUCER'
});

export const cleanProviderViewOrderProductsReducer = () => ({
  type: 'CLEAN_PROVIDER_VIEW_ORDER_PRODUCTS_REDUCER'
});

export const cleanProviderViewBlacklistReducer = () => ({
  type: 'CLEAN_PROVIDER_VIEW_BLACKLIST_REDUCER'
});

export const cleanProviderViewRatingsReducer = () => ({
  type: 'CLEAN_PROVIDER_VIEW_RATINGS_REDUCER'
});

export const getProviderView = () => ({
  type: 'GET_PROVIDER_VIEW'
});

export const getProviderViewSuccess = (data) => ({
  type: 'GET_PROVIDER_VIEW_SUCCESS',
  data
});

export const getProviderViewError = (error) => ({
  type: 'GET_PROVIDER_VIEW_ERROR',
  error
});

export const getProviderViewDevices = (next) => ({
  type: 'GET_PROVIDER_VIEW_DEVICES',
  next
});

export const getProviderViewDevicesSuccess = (data, paging) => ({
  type: 'GET_PROVIDER_VIEW_DEVICES_SUCCESS',
  data,
  paging
});

export const getProviderViewDevicesError = (error) => ({
  type: 'GET_PROVIDER_VIEW_DEVICES_ERROR',
  error
});

export const getProviderViewPaymentMethods = (next) => ({
  type: 'GET_PROVIDER_VIEW_PAYMENT_METHODS',
  next
});

export const getProviderViewPaymentMethodsSuccess = (data, paging) => ({
  type: 'GET_PROVIDER_VIEW_PAYMENT_METHODS_SUCCESS',
  data,
  paging
});

export const getProviderViewPaymentMethodsError = (error) => ({
  type: 'GET_PROVIDER_VIEW_PAYMENT_METHODS_ERROR',
  error
});

export const getProviderViewAddresses = (next) => ({
  type: 'GET_PROVIDER_VIEW_ADDRESSES',
  next
});

export const getProviderViewAddressesSuccess = (data, paging) => ({
  type: 'GET_PROVIDER_VIEW_ADDRESSES_SUCCESS',
  data,
  paging
});

export const getProviderViewAddressesError = (error) => ({
  type: 'GET_PROVIDER_VIEW_ADDRESSES_ERROR',
  error
});

export const getProviderViewInvoices = (next) => ({
  type: 'GET_PROVIDER_VIEW_INVOICES',
  next
});

export const getProviderViewInvoicesSuccess = (data, paging) => ({
  type: 'GET_PROVIDER_VIEW_INVOICES_SUCCESS',
  data,
  paging
});

export const getProviderViewInvoicesError = (error) => ({
  type: 'GET_PROVIDER_VIEW_INVOICES_ERROR',
  error
});

export const getProviderViewInvoiceQueue = (next) => ({
  type: 'GET_PROVIDER_VIEW_INVOICE_QUEUE',
  next
});

export const getProviderViewInvoiceQueueSuccess = (data, paging) => ({
  type: 'GET_PROVIDER_VIEW_INVOICE_QUEUE_SUCCESS',
  data,
  paging
});

export const getProviderViewInvoiceQueueError = (error) => ({
  type: 'GET_PROVIDER_VIEW_INVOICE_QUEUE_ERROR',
  error
});

export const getProviderViewOrderProducts = (next) => ({
  type: 'GET_PROVIDER_VIEW_ORDER_PRODUCTS',
  next
});

export const getProviderViewOrderProductsSuccess = (data, paging) => ({
  type: 'GET_PROVIDER_VIEW_ORDER_PRODUCTS_SUCCESS',
  data,
  paging
});

export const getProviderViewOrderProductsError = (error) => ({
  type: 'GET_PROVIDER_VIEW_ORDER_PRODUCTS_ERROR',
  error
});

export const getProviderViewOrderProductsMetadata = () => ({
  type: 'GET_PROVIDER_VIEW_ORDER_PRODUCTS_METADATA'
});

export const getProviderViewOrderProductsMetadataSuccess = (data) => ({
  type: 'GET_PROVIDER_VIEW_ORDER_PRODUCTS_METADATA_SUCCESS',
  data
});

export const getProviderViewOrderProductsMetadataError = (isError) => ({
  type: 'GET_PROVIDER_VIEW_ORDER_PRODUCTS_METADATA_ERROR',
  isError
});

export const getProviderViewBlacklist = (next) => ({
  type: 'GET_PROVIDER_VIEW_BLACKLIST',
  next
});

export const getProviderViewBlacklistSuccess = (data, paging) => ({
  type: 'GET_PROVIDER_VIEW_BLACKLIST_SUCCESS',
  data,
  paging
});

export const getProviderViewBlacklistError = (error) => ({
  type: 'GET_PROVIDER_VIEW_BLACKLIST_ERROR',
  error
});

export const getProviderViewRatingsEmitted = (next) => ({
  type: 'GET_PROVIDER_VIEW_RATINGS_EMITTED',
  next
});

export const getProviderViewRatingsEmittedSuccess = (data, paging) => ({
  type: 'GET_PROVIDER_VIEW_RATINGS_EMITTED_SUCCESS',
  data,
  paging
});

export const getProviderViewRatingsEmittedError = (error) => ({
  type: 'GET_PROVIDER_VIEW_RATINGS_EMITTED_ERROR',
  error
});

export const getProviderViewRatingsReceived = (next) => ({
  type: 'GET_PROVIDER_VIEW_RATINGS_RECEIVED',
  next
});

export const getProviderViewRatingsReceivedSuccess = (data, paging) => ({
  type: 'GET_PROVIDER_VIEW_RATINGS_RECEIVED_SUCCESS',
  data,
  paging
});

export const getProviderViewRatingsReceivedError = (error) => ({
  type: 'GET_PROVIDER_VIEW_RATINGS_RECEIVED_ERROR',
  error
});
