import * as moment from 'moment-timezone';
import 'moment/locale/it';
import 'moment/locale/es';
import 'moment/locale/en-gb';
import 'moment/locale/fr';

import { pad } from './utils';
import { getTimezone, getLocale } from './cookies';

export const initDateLocale = (locale) => {
  /**
   * Init local date
   * 'en' does not exist, pass 'en-gb'
   */
  if (locale && locale === 'en') {
    moment.locale('en-gb');
  } else if (locale) {
    moment.locale(locale);
  } else {
    moment.locale(getLocale());
  }
};

/**
 * Check if is valid date
 */
export const isDateValid = (date) => moment(date).isValid();

/**
 * Check if date contains a timezone
 */
export const isDateZoned = (date) => {
  if (!date || !isDateValid(date)) return false;

  if (moment.isMoment(date)) return true;

  const offsetReg = /[+|-]\d{4}$/;
  const zReg = /[Z|z]$/;

  return date.match(offsetReg) || date.match(zReg);
};

/**
 * Get the hour offset of a date zoned
 */
export const getDateZoneOffset = (date) => {
  if (!isDateZoned(date)) return false;

  if (moment.isMoment(date)) return date.format('Z');

  let offset = '+0000';
  const offsetReg = /[+|-]\d{4}$/;
  const matchResult = date.match(offsetReg);

  if (matchResult && matchResult.length) offset = matchResult[0];

  return offset;
};

/**
 * Create date
 */
export const createDate = (date = null, tz = getTimezone()) => {
  if (!tz) tz = getTimezone();

  if (date === null) return moment.tz(moment(), tz);

  return moment.tz(date, tz);
};

export const createDateUtc = (date) => createDate(date, 'UTC');

export const toUtcTimestamp = (date) => dateInTz(date, 'YYYY-MM-DDTHH:mm:ssZ', 'UTC');

export const createDateFromArray = (array, tz = getTimezone()) => {
  if (!tz) tz = getTimezone();

  /**
   * Fix javascript months -1
   */
  if (array.length && array[1]) {
    let month = Math.max(0, parseInt(array[1], 10) - 1);
    array[1] = month;
  }

  return createDate(array, tz);
};

export const createDateUtcFromArray = (array) => createDateFromArray(array, 'UTC');

/*
 * Convert string date to an object date
 */
export const toDateObject = (date, tz = getTimezone()) => {
  if (!tz) tz = getTimezone();

  if (moment.isMoment(date)) return date;

  if (typeof date === 'string' && isDateZoned(date))
    return moment(date).utcOffset(getDateZoneOffset(date));

  return createDate(date, tz);
};

export const toDateObjectUtc = (date) => toDateObject(date, 'UTC');

/**
 * Format a date
 */
export const formatDate = (date, format = 'iso') => {
  let dateObject;

  if (moment.isMoment(date)) {
    dateObject = date;
  } else {
    dateObject = toDateObject(date);
  }

  if (format && format.toLowerCase() === 'iso') {
    return dateObject.format();
  }

  if (format && format.toLowerCase() === 'localized-date') {
    return dateObject.format('L');
  }

  if (format && format.toLowerCase() === 'localized-long-date') {
    return dateObject.format('LL');
  }

  if (format && format.toLowerCase() === 'localized-long-datetime') {
    return dateObject.format('LL - HH:mm');
  }

  if (format && format.toLowerCase() === 'localized-datetime') {
    return dateObject.format('L - HH:mm');
  }

  if (format && format.toLowerCase() === 'localized-long-datetime-with-seconds') {
    return dateObject.format('LL - HH:mm:ss');
  }

  if (format && format.toLowerCase() === 'localized-datetime-with-seconds') {
    return dateObject.format('L - HH:mm:ss');
  }

  return dateObject.format(format);
};

/**
 * Convert a date with timezone
 */
export const dateInTz = (date, format = null, tz = getTimezone()) => {
  const dateTz = createDate(createDate(date, getTimezone()), tz);

  if (format) return formatDate(dateTz, format);
  else return dateTz;
};

/**
 * Get the current date
 */
export const getCurrentDate = (format = null, tz = getTimezone()) => {
  const date = toDateObject(null, tz);

  if (format) return formatDate(date, format);

  return date;
};

export const getCurrentDateUtc = (format = null) => getCurrentDate(format, 'UTC');

/*
 * Convert date to unix timestamp
 */
export const toUnix = (date) => formatDate(date, 'X');

/**
 * Add an object (min, sec, months, days, years ...) to a date
 */
export const addToDate = (date, number, what, format = null) => {
  const newDateObj = toDateObject(date).clone().add(number, what);

  if (format) return formatDate(newDateObj, format);

  return newDateObj;
};

/**
 * Remove object  (min, sec, months, days, years ...) from a date
 */
export const removeFromDate = (date, number, what, format = null) =>
  addToDate(date, -number, what, format);

/**
 * Date comparisons
 */
export const isDateAfter = (date, dateToCheck) => {
  if (toUnix(dateToCheck) > toUnix(date)) return true;

  return false;
};

export const isDateBefore = (date, dateToCheck) => {
  if (toUnix(dateToCheck) < toUnix(date)) return true;

  return false;
};

export const isDateSame = (date, dateToCheck) => {
  if (toUnix(dateToCheck) === toUnix(date)) return true;

  return false;
};

/**
 * Calc the time between 2 dates, diff return milliseconds
 */
export const calcDateDiff = (startDate, endDate, isPad = false) => {
  if (!startDate || !endDate) {
    return;
  }

  const start = toDateObject(startDate);
  const end = toDateObject(endDate);
  const duration = moment.duration(end.diff(start));

  const years = duration._data.years
    ? isPad
      ? pad(duration._data.years)
      : duration._data.years
    : pad('0');
  const months = duration._data.months
    ? isPad
      ? pad(duration._data.months)
      : duration._data.months
    : pad('0');
  const days = duration._data.days
    ? isPad
      ? pad(duration._data.days)
      : duration._data.days
    : pad('0');
  const hours = duration._data.hours
    ? isPad
      ? pad(duration._data.hours)
      : duration._data.hours
    : pad('0');
  const minutes = duration._data.minutes
    ? isPad
      ? pad(duration._data.minutes)
      : duration._data.minutes
    : pad('0');
  const seconds = duration._data.seconds
    ? isPad
      ? pad(duration._data.seconds)
      : duration._data.seconds
    : pad('0');
  const milliseconds = duration._data.milliseconds
    ? isPad
      ? pad(duration._data.milliseconds)
      : duration._data.milliseconds
    : pad('0');

  return { years, months, days, hours, minutes, seconds, milliseconds };
};

/**
 * Modify given date
 */
export const alterDate = (
  date,
  year = null,
  month = null,
  day = null,
  hour = null,
  minute = null,
  second = null,
  format = null
) => {
  const newDateObj = toDateObject(date).clone();
  let monthFixed = Math.max(0, parseInt(month, 10) - 1);

  if (year) newDateObj.set('year', year);

  if (month) newDateObj.set('month', monthFixed);

  if (day) newDateObj.set('date', day);

  if (hour) newDateObj.set('hour', hour);

  if (minute) newDateObj.set('minute', minute);

  if (second) newDateObj.set('second', second);

  if (format) return formatDate(newDateObj, format);

  return newDateObj;
};

Math.trunc =
  Math.trunc ||
  function (x) {
    if (isNaN(x)) {
      return NaN;
    }
    if (x > 0) {
      return Math.floor(x);
    }
    return Math.ceil(x);
  };

export const convertMinsToHrsMins = (mins, separator = ':') => {
  let h = Math.floor(mins / 60);
  let m = Math.floor(mins % 60);
  h = h < 10 ? '0' + h : h;
  m = m < 10 ? '0' + m : Math.trunc(m);

  return `${h}` + separator + `${m}`;
};

/**
 * JS localized date conversion because browser timezone default settings
 */
export const replaceTzInJsDate = (date, format = null /*, tz = getTimezone() */) => {
  if (!date) date = new Date();

  const dateUtc = toDateObject(date.toISOString());
  const dateUtcPlusOffset = addToDate(
    dateUtc,
    parseInt(date.getTimezoneOffset(), 10) * -1,
    'minutes',
    'YYYY-MM-DD HH:mm:ss'
  );
  const finalDate = toDateObject(dateUtcPlusOffset);

  if (format) return formatDate(finalDate, format);

  return finalDate;
};

/**
 * Get week number from a javascript date
 * src: https://stackoverflow.com/questions/16590500/javascript-calculate-date-from-week-number
 */
/* eslint no-eval: 0 */
export const getWeekNumberFromJsDate = (date) => {
  const target = new Date(date.valueOf());
  const dayNr = (date.getDay() + 6) % 7;

  target.setDate(target.getDate() - dayNr + 3);

  const firstThursday = target.valueOf();

  target.setMonth(0, 1);

  if (target.getDay() !== 4) target.setMonth(0, 1 + ((4 - target.getDay() + 7) % 7));

  return 1 + Math.ceil((firstThursday - target) / 604800000);
};

/**
 * Return week period from a week number
 * src: https://stackoverflow.com/questions/16590500/javascript-calculate-date-from-week-number
 */
export const getDateRangeOfWeek = (date, weekNbr) => {
  if (!weekNbr) return;

  const numOfdaysPastSinceLastMonday = eval(date.getDay() - 1);

  date.setDate(date.getDate() - numOfdaysPastSinceLastMonday);

  const weekNbrToday = getWeekNumberFromJsDate(date);
  const weeksInTheFuture = eval(weekNbr - weekNbrToday);

  date.setDate(date.getDate() + eval(7 * weeksInTheFuture));

  const rangeStart = eval(date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();

  date.setDate(date.getDate() + 6);

  const rangeEnd = eval(date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();

  return { start: rangeStart, end: rangeEnd };
};

/**
 * Format a date for pickers inputs
 */
export const formatPickerDate = (date, format) => {
  if (format && format.toLowerCase() === 'iso') {
    return moment(date).format();
  }

  return moment(date).format(format);
};

export function formatJSDate(date) {
  let month = '' + (date.getMonth() + 1);
  let day = '' + date.getDate();
  let year = date.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}

/**
 * Convert a moment date or a date to a javascript Date with the correct offset
 */
export const toJsDate = (date) => {
  let strDate = null;
  if (moment.isMoment(date)) strDate = formatDate(date, 'YYYY-MM-DDTHH:mm:ssZ');
  else strDate = date;

  const dateObj = toDateObject(strDate);
  const tzOffset = moment.parseZone(dateObj).utcOffset();
  const localOffset = parseInt(new Date(strDate).getTimezoneOffset(), 10);
  const dateAltered = addToDate(dateObj, localOffset + tzOffset, 'minutes');
  const dateJs = new Date(dateAltered.toISOString());

  return dateJs;
};
