import './formPhoneField.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import PhoneIcon from '@material-ui/icons/Phone';

import { getNightTheme, getResidenceCountry } from '../../../../../utils/functions/cookies';
import translation from '../../../../../translation/translation';

function InternationalIcon() {
  const isDarkTheme = getNightTheme();

  const iconStyle = {
    fontSize: 18,
    position: 'relative',
    bottom: 2,
    left: 4,
    color:
      isDarkTheme === true || isDarkTheme === 'true' ? 'rgb(171, 171, 171)' : 'rgba(0, 0, 0, 0.52)'
  };

  return <PhoneIcon style={iconStyle} />;
}

function FormPhoneField(props) {
  const {
    autoComplete,
    defaultCountry,
    defaultValue,
    disabled,
    displayInitialValueAsLocalNumber,
    error,
    id,
    fullWidth,
    helperText,
    name,
    onChange,
    margin,
    label,
    placeholder,
    required,
    value,
    write
  } = props;

  const [localValue, setLocalValue] = useState(defaultValue || '');

  function handleChange(localValue) {
    setLocalValue(localValue);

    if (onChange) onChange(localValue ? localValue : null);
  }

  const userCountry = getResidenceCountry() ? getResidenceCountry().toUpperCase() : undefined;

  /**
   * Custom theme color handling
   */
  const isDarkTheme = getNightTheme();
  const finalClassName = classNames('FormPhoneField', {
    error: error,
    disabled: disabled,
    'theme-night': isDarkTheme === true || isDarkTheme === 'true',
    'theme-light': isDarkTheme === false || isDarkTheme === 'false'
  });

  return (
    <div className={finalClassName}>
      <FormControl
        required={required}
        disabled={!write || disabled}
        fullWidth={fullWidth}
        margin={margin}>
        {label && (
          <InputLabel error={error ? true : false} htmlFor={id || name} shrink>
            {label}
          </InputLabel>
        )}
        <div className="PhoneInput-InputBase">
          <PhoneInput
            id={id || name}
            name={name}
            disabled={!write || disabled}
            displayInitialValueAsLocalNumber={displayInitialValueAsLocalNumber}
            required={required}
            autoComplete={autoComplete}
            defaultCountry={defaultCountry || userCountry}
            onChange={handleChange}
            value={value || localValue}
            placeholder={placeholder || translation().commons.phone_field.placeholder}
            internationalIcon={InternationalIcon}
          />
        </div>
      </FormControl>
      {(error || helperText) && (
        <FormHelperText style={{ margin: 0 }} error={error ? true : false}>
          {error || helperText}
        </FormHelperText>
      )}
    </div>
  );
}

FormPhoneField.defaultProps = {
  autoComplete: 'nope',
  defaultCountry: undefined,
  displayInitialValueAsLocalNumber: true,
  disabled: false,
  fullWidth: true,
  margin: 'normal',
  multiline: false,
  type: 'text',
  rows: null,
  write: true
};

FormPhoneField.propTypes = {
  autoComplete: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultCountry: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  displayInitialValueAsLocalNumber: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fullWidth: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  margin: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.any,
  write: PropTypes.bool
};

export default FormPhoneField;
