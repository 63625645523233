import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import GitHubIcon from '@material-ui/icons/GitHub';

import translation from '../../../translation/translation';

const useStyles = makeStyles(() => ({
  githubLink: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    whiteSpace: 'pre-wrap'
  }
}));

const packages = {
  devDependencies: {
    '@babel/cli': '^7.2.0',
    '@babel/core': '^7.2.0',
    '@babel/plugin-proposal-class-properties': '^7.4.0',
    '@babel/plugin-syntax-dynamic-import': '^7.2.0',
    '@babel/plugin-transform-arrow-functions': '^7.2.0',
    '@babel/plugin-transform-runtime': '^7.4.3',
    '@babel/polyfill': '^7.0.0',
    '@babel/preset-env': '^7.2.0',
    '@babel/preset-react': '^7.0.0',
    'autoprefixer-core': '^5.2.1',
    'babel-eslint': '^10.0.1',
    'babel-loader': '^8.0.4',
    bowser: '^2.11.0',
    'eslint-loader': '^2.1.2',
    'eslint-plugin-react': '^7.12.4',
    'eslint-plugin-react-hooks': '^4.0.4',
    'file-loader': '^4.2.0',
    'html-loader': '^0.5.5',
    'html-webpack-plugin': '^3.2.0',
    'jss-plugin-nested': '^10.0.0',
    'node-sass': '^4.13.0',
    'react-big-calendar': '^0.26.0',
    'react-hot-loader': '^4.3.12',
    'react-select': '^2.4.3',
    'redux-devtools-extension': '^2.13.8',
    'sass-loader': '^8.0.2',
    'style-loader': '^0.23.1',
    'webpack-cli': '^3.1.2'
  },
  dependencies: {
    '@date-io/moment': '^1.3.13',
    '@material-ui/codemod': '^4.5.0',
    '@material-ui/core': '^4.11.0',
    '@material-ui/icons': '^4.9.1',
    '@material-ui/lab': '^4.0.0-alpha.56',
    '@material-ui/pickers': '^3.2.10',
    '@material-ui/styles': '^4.10.0',
    '@material-ui/utils': '^4.10.2',
    'array-move': '^2.2.0',
    axios: '^0.18.0',
    'chart.js': '^2.9.3',
    'chartjs-plugin-annotation': '^0.5.7',
    'chartjs-plugin-streaming': '^1.8.0',
    'classlist-polyfill': '^1.2.0',
    'core-util-is': '^1.0.2',
    'cross-spawn': '^6.0.5',
    'js-cookie': '^2.2.0',
    moment: '^2.24.0',
    'moment-timezone': '^0.5.23',
    'mui-places-autocomplete': '^2.0.0',
    react: '^16.13.1',
    'react-chartjs-2': '^2.9.0',
    'react-countup': '^4.3.3',
    'react-dates': '^21.8.0',
    'react-dnd': '^11.1.3',
    'react-dnd-html5-backend': '^11.1.3',
    'react-dom': '^16.13.1',
    'react-dropzone': '^11.0.1',
    'react-google-maps': '^9.4.5',
    'react-modal': '^3.11.1',
    'react-moment-proptypes': '^1.7.0',
    'react-phone-number-input': '^3.0.23',
    'react-redux': '^7.1.3',
    'react-router-dom': '^5.1.2',
    'react-scripts': '^2.1.8',
    'react-scroll': '^1.7.14',
    'react-slick': '^0.25.2',
    'react-sortable-hoc': '^1.11.0',
    redux: '^4.0.1',
    'redux-thunk': '^2.3.0',
    'slick-carousel': '^1.8.1',
    'webpack-dev-server': '^3.10.3'
  },
  engines: {
    node: '>=0.10.0'
  },
  eslintConfig: {
    extends: 'react-app'
  },
  browserslist: ['>0.2%', 'not dead', 'not ie <= 10', 'not op_mini all']
};

function Documentation() {
  const classes = useStyles();

  return (
    <div className="Documentation">
      <Typography gutterBottom component="h5" variant="h5">
        {translation().views.lab.doc.title}
      </Typography>
      <Typography gutterBottom component="p" variant="body1">
        {translation().views.lab.doc.baseline}
      </Typography>
      <div className="sm-space" />
      <div className={classes.githubLink}>
        <GitHubIcon /> Github:{' '}
        <Link target="_blank" href="https://github.com/WallMarket/ACTARUS-FRONT-PREBUILD">
          https://github.com/WallMarket/ACTARUS-FRONT-PREBUILD
        </Link>
      </div>
      <div className="sm-space" />
      <Typography gutterBottom component="p" variant="body1">
        package.json:
      </Typography>
      <div>
        <code>
          <pre>{JSON.stringify(packages, null, ' ')}</pre>
        </code>
      </div>
    </div>
  );
}

export default Documentation;
