import './UpdateView.scss';

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  CircularProgress,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  Paper,
  Tabs,
  Tab,
  Typography
} from '@material-ui/core';
import { Done as DoneIcon, Save as SaveIcon } from '@material-ui/icons';

import Alert from '../../../components/alert/Alert';
import FormsContainer from '../../../containers/forms/FormsContainer';
import UpdateViewBar from './components/UpdateViewBar';
import UpdateViewCollection from './components/collections/UpdateViewCollection';
// import { TimezoneField } from '../../../components/forms/fields';
import {
  getLocale
  // getTimezone
} from '../../../utils/functions/cookies';
import translation from '../../../translation/translation';

const updateCancelToken = () => axios.CancelToken.source();

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingBottom: theme.spacing(2)
  },
  formHead: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    padding: '12px 24px',
    marginBottom: 12,
    [theme.breakpoints.down(400)]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  button: {
    marginTop: 4,
    fontWeight: 400
  },
  buttonIcon: {
    marginRight: 8
  },
  flagSelect: {
    paddingTop: 6,
    paddingBottom: 6
  },
  flagControl: {
    marginTop: 16,
    marginBottom: 8,
    minWidth: 70
  },
  updateLoadingText: {
    display: 'inline-block',
    verticalAlign: 'top',
    margin: '2px 10px'
  },
  submitWrapper: {
    textAlign: 'right',
    marginTop: theme.spacing(3)
  },
  tabPanel: {
    marginTop: theme.spacing(2)
  },
  timezoneField: {
    minWidth: 220,
    marginRight: 8,
    [theme.breakpoints.down(400)]: {
      width: '100%',
      minWidth: '100%',
      marginRight: 0
    }
  }
}));

const loaderWrapperStyle = {
  display: 'inline-block',
  width: 'auto',
  padding: 0,
  verticalAlign: 'middle',
  margin: '0 10px'
};

const GetMetadataApi = (getMetadata, url) => {
  const [source] = useState(updateCancelToken());

  useEffect(() => {
    getMetadata(url, source);

    return () => {
      source.cancel('=> Core metadata changed.');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const GetItemApi = (getItem, url) => {
  const [source] = useState(updateCancelToken());

  const doRefresh = (locale) => {
    getItem(url, source, locale);
  };

  useEffect(() => {
    getItem(url, source);

    return () => {
      source.cancel('=> Core data changed.');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { doRefresh };
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`update-tabpanel-${index}`}
      aria-labelledby={`update-tab-${index}`}
      {...other}>
      <Box>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function UpdateView(props) {
  const classes = useStyles();

  const {
    collections_columns,
    item,
    getItem,
    update: { collections, isLoading, languages, locale, timezone },
    getMetadata,
    getApiLanguages,
    history,
    location: { pathname },
    match,
    metadata,
    metadataUri,
    parentUri,
    setListNeedReload,
    setUpdateResourceLocale,
    // setUpdateResourceTimezone,
    sub_routes,
    unmountUpdateView,
    updateResource
  } = props;

  const userLocale = getLocale();

  useEffect(() => {
    document.title = 'Actarus | Update ' + metadataUri;

    setUpdateResourceLocale(userLocale);
    getApiLanguages();

    return () => {
      unmountUpdateView();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [submitType, setSubmitType] = useState('update');
  const [tabOpen, setTabOpen] = useState(0);
  const { doRefresh } = GetItemApi(getItem, pathname.substring(1).replace('/update', ''));

  GetMetadataApi(getMetadata, metadataUri);

  function handleSumbit(values) {
    if (values && !isLoading && metadata && metadata.data && metadata.data.fields) {
      updateResource(
        pathname.substring(1).replace('/update', ''),
        values,
        metadata.data.fields,
        locale,
        () => {
          if (submitType === 'save') {
            setListNeedReload();
            doRefresh(locale);
          } else {
            setListNeedReload();

            let newPath = pathname.split('/');
            newPath.pop();
            newPath.pop();
            newPath = newPath.join('/');

            if (history) {
              history.push(newPath);
            } else {
              window.location = newPath;
            }
          }
        }
      );
    }
  }

  function handleRefresh() {
    doRefresh(locale);
  }

  function handleUpdate() {
    setSubmitType('update');
  }

  function handleSave() {
    setSubmitType('save');
  }

  function handleChangeTabs(e, newValue) {
    setTabOpen(newValue);
  }

  function handleChangeLocale(event) {
    setUpdateResourceLocale(event.target.value);
    doRefresh(event.target.value);
  }

  // function handleChangeTimezone(value) {
  //   setUpdateResourceTimezone(value);
  // }

  function renderLanguagesValue(value) {
    return (
      <img
        src={`/assets/images/flags/${value.toUpperCase()}.svg`}
        alt={value}
        height={12.25}
        width={18.38}
      />
    );
  }

  function renderLanguages(langs) {
    return (
      <div>
        {langs.isLoading && <CircularProgress size={23} />}
        {langs.data && langs.data.length > 0 ? (
          <FormControl className={classes.flagControl}>
            <InputLabel shrink>{translation().commons.language}</InputLabel>
            <Select
              id="update-resource-select-locale"
              name="update-resource-select-locale"
              label="Lang"
              renderValue={renderLanguagesValue}
              value={locale}
              onChange={handleChangeLocale}
              classes={{
                root: classes.flagSelect
              }}>
              {langs.data.reverse().map((language, index) => (
                <MenuItem key={index} value={language.locale}>
                  <img
                    src={`/assets/images/flags/${language.locale.toUpperCase()}.svg`}
                    alt={language.locale}
                    height={12.25}
                    width={18.38}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          !langs.isLoading && 'No lang found.'
        )}
      </div>
    );
  }

  // function renderTimezones() {
  //   return (
  //     <TimezoneField
  //       className={classes.timezoneField}
  //       label={translation().commons.timezone}
  //       placeholder={translation().commons.timezone.toLocaleLowerCase()}
  //       fullWidth
  //       name="update-resource-select-timezone"
  //       onChange={handleChangeTimezone}
  //       defaultValue={timezone ? timezone : getTimezone()}
  //       clearable={false}
  //     />
  //   );
  // }

  return (
    <div className="UpdateView">
      <UpdateViewBar
        item={item}
        history={history}
        parentUri={parentUri}
        routeUri={pathname.substring(1)}
        subRoutes={sub_routes}
        disabled={isLoading}
        handleRefresh={handleRefresh}
      />
      <div className={classes.root}>
        {collections && collections.length > 0 ? (
          <div className="form-wrapper with-collections">
            <Tabs
              value={tabOpen}
              onChange={handleChangeTabs}
              indicatorColor="secondary"
              textColor="secondary"
              variant="scrollable"
              scrollButtons="auto">
              <Tab label="general" />
              {collections.map((collection, i) => (
                <Tab label={collection.label} key={i} />
              ))}
            </Tabs>
            <TabPanel className={classes.tabPanel} index={0} value={tabOpen}>
              <Paper className={classes.formHead}>
                {/* {renderTimezones()} */}
                {renderLanguages(languages)}
              </Paper>
              {!metadata.isLoading && !item.isLoading && (
                <Paper>
                  <FormsContainer
                    isWillReceiveValues
                    metadata={metadata}
                    formName="updateForm"
                    onSubmit={handleSumbit}
                    defaultValues={item.data || {}}
                    timezone={timezone}>
                    <div className={classes.submitWrapper}>
                      {isLoading && (
                        <div className="loader-wpr" style={loaderWrapperStyle}>
                          <CircularProgress size={20} color="secondary" />
                          <p className={classes.updateLoadingText}>
                            {translation().core.update.load_update}
                          </p>
                        </div>
                      )}
                      <Button
                        className={classes.button}
                        variant="contained"
                        color="primary"
                        type="submit"
                        style={{ marginRight: 8 }}
                        onClick={handleUpdate}
                        disabled={isLoading}>
                        <DoneIcon className={classes.buttonIcon} />
                        {translation().actions.update_and_quit}
                      </Button>
                      <Button
                        className={classes.button}
                        variant="contained"
                        color="secondary"
                        type="submit"
                        onClick={handleSave}
                        disabled={isLoading}>
                        <SaveIcon className={classes.buttonIcon} />
                        {translation().actions.save}
                      </Button>
                    </div>
                  </FormsContainer>
                </Paper>
              )}
            </TabPanel>
            {collections.map((collection, j) => {
              return (
                <TabPanel className={classes.tabPanel} index={j + 1} key={j} value={tabOpen}>
                  <UpdateViewCollection
                    tabIndex={j + 1}
                    tabValue={tabOpen}
                    resourceId={match.params.id}
                    routeUri={pathname.substring(1)}
                    timezone={timezone}
                    locale={locale}
                    {...collection}
                    subRoutes={
                      collection.name &&
                      collections_columns &&
                      collections_columns[collection.name] &&
                      collections_columns[collection.name].sub_routes
                        ? collections_columns[collection.name].sub_routes
                        : undefined
                    }
                    columns={
                      collection.name &&
                      collections_columns &&
                      collections_columns[collection.name] &&
                      collections_columns[collection.name].columns
                        ? collections_columns[collection.name].columns
                        : undefined
                    }
                  />
                </TabPanel>
              );
            })}
          </div>
        ) : (
          <div className="form-wrapper">
            {!metadata.isLoading && !item.isLoading && (
              <>
                <Paper className={classes.formHead}>
                  {/* {renderTimezones()} */}
                  {renderLanguages(languages)}
                </Paper>
                <Paper>
                  <FormsContainer
                    isWillReceiveValues
                    metadata={metadata}
                    formName="updateForm"
                    onSubmit={handleSumbit}
                    timezone={timezone}
                    defaultValues={item.data || {}}>
                    <div className={classes.submitWrapper}>
                      {isLoading && (
                        <div className="loader-wpr" style={loaderWrapperStyle}>
                          <CircularProgress size={20} color="secondary" />
                          <p className={classes.updateLoadingText}>
                            {translation().core.update.load_update}
                          </p>
                        </div>
                      )}
                      <Button
                        className={classes.button}
                        variant="contained"
                        color="primary"
                        type="submit"
                        style={{ marginRight: 8 }}
                        onClick={handleUpdate}
                        disabled={isLoading}>
                        <DoneIcon className={classes.buttonIcon} />
                        {translation().actions.update_and_quit}
                      </Button>
                      <Button
                        className={classes.button}
                        variant="contained"
                        color="secondary"
                        type="submit"
                        onClick={handleSave}
                        disabled={isLoading}>
                        <SaveIcon className={classes.buttonIcon} />
                        {translation().actions.save}
                      </Button>
                    </div>
                  </FormsContainer>
                </Paper>
              </>
            )}
          </div>
        )}
        {(item.isLoading || metadata.isLoading) && (
          <div className="loader-wpr">
            <CircularProgress color="secondary" />
            <p>{translation().core.item.load_item}</p>
            {metadata.isLoading && <p>{translation().core.load_metadata}</p>}
          </div>
        )}
        {!item.isLoading && item.isError && !item.data ? (
          <div className="error-list">
            <Alert
              type="danger"
              style={{ margin: 'auto' }}
              status={translation().commons.alerts.error}
              text={translation().core.list.error_list}
            />
          </div>
        ) : (
          false
        )}
      </div>
    </div>
  );
}

UpdateView.propTypes = {
  collections_columns: PropTypes.shape(),
  item: PropTypes.shape().isRequired,
  update: PropTypes.shape({
    collections: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    isLoading: PropTypes.bool,
    timezone: PropTypes.string,
    languages: PropTypes.shape({
      isLoading: PropTypes.bool
    }).isRequired,
    locale: PropTypes.string
  }).isRequired,
  updateResource: PropTypes.func.isRequired,
  getItem: PropTypes.func.isRequired,
  getMetadata: PropTypes.func.isRequired,
  getApiLanguages: PropTypes.func.isRequired,
  history: PropTypes.shape().isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    }).isRequired
  }).isRequired,
  metadata: PropTypes.shape(),
  metadataUri: PropTypes.string.isRequired,
  parentUri: PropTypes.string.isRequired,
  setListNeedReload: PropTypes.func.isRequired,
  setUpdateResourceLocale: PropTypes.func.isRequired,
  // setUpdateResourceTimezone: PropTypes.func.isRequired,
  sub_routes: PropTypes.shape(),
  unmountUpdateView: PropTypes.func.isRequired
};

export default UpdateView;
