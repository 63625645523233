import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';

import FormApiAutocompleteField from '../../../../components/forms/fields/FormApiAutocompleteField';
import translation from '../../../../translation/translation';

function AddDiscountButton(props) {
  const { addDiscount, addOrderDiscount } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(null);

  function handleOpenDialog() {
    setIsOpen(true);
  }

  function handleCloseDialog() {
    setIsOpen(false);
  }

  function handleSelect(code) {
    setValue(code);
  }

  function handleSubmit() {
    addOrderDiscount(value);
  }

  return (
    <>
      <IconButton edge="end" aria-label="Add" onClick={handleOpenDialog}>
        <AddIcon fontSize="small" />
      </IconButton>
      <Dialog
        fullWidth
        open={isOpen ? true : false}
        onClose={handleCloseDialog}
        aria-labelledby="add-order-discount"
        aria-describedby="add-order-discount-description"
        disableBackdropClick={addDiscount.isLoading}>
        <DialogTitle id="add-order-discount">
          {translation().views.orders.update.discounts.add.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="add-order-discount-description">
            {translation().views.orders.update.discounts.add.content}
          </DialogContentText>
          <FormApiAutocompleteField
            disabled={addDiscount.isLoading}
            links={[{ rel: 'list', href: 'discounts' }]}
            name="order_discount_autocomplete"
            onSelectValue={handleSelect}
            placeholder={translation().views.orders.create.payment.discount.input_placeholder}
            targetKey="id"
          />
          <div className="sm-space" />
          {addDiscount.isLoading && (
            <div className="loader-wpr" style={{ marginTop: 15 }}>
              <CircularProgress size={30} color="secondary" />
              <p>{translation().views.orders.update.discounts.add.load_delete}</p>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="default" disabled={addDiscount.isLoading}>
            {translation().actions.cancel}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={addDiscount.isLoading || !value}>
            {translation().actions.confirm}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

AddDiscountButton.propTypes = {
  addDiscount: PropTypes.shape({
    isLoading: PropTypes.bool
  }).isRequired,
  addOrderDiscount: PropTypes.func.isRequired
};

export default AddDiscountButton;
