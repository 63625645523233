export const getItem = () => ({
  type: 'GET_ITEM'
});

export const getItemSuccess = (data) => ({
  type: 'GET_ITEM_SUCCESS',
  data
});

export const getItemError = (isError) => ({
  type: 'GET_ITEM_ERROR',
  isError
});

export const cancelGetItem = () => ({
  type: 'CANCEL_GET_ITEM'
});
