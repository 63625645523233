import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchApi } from '../../../utils/functions/api';
import { enqueueSnackbar } from '../../../redux/actions/appActions';
import UserPositions from '../../../views/core/customListActions/userPositions/UserPositions';
import translation from '../../../translation/translation';

const mapDispatchToProps = (dispatch) => {
  return {
    getUserPositions: (id, isProvider = false, callbackSuccess, callbackError) => {
      if (!id) {
        return;
      }

      fetchApi(
        'get',
        isProvider ? `providers/${id}/positions` : `users/${id}/positions`,
        null,
        null,
        null,
        (success) => {
          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success);
          }
        },
        (error) => {
          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().views.user_positions.error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().views.user_positions.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError(error);
          }
        }
      );
    }
  };
};

export default withRouter(connect(null, mapDispatchToProps)(UserPositions));
