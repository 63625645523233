import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';

import OrderProductContainer from '../../../containers/moderation/OrderProductContainer';
import translation from '../../../translation/translation';

const useStyles = makeStyles(() => ({
  root: {
    padding: 0,
    '&:first-child': {
      paddingTop: 0
    }
  }
}));

function OrderProductDialog(props) {
  const { disableClose, onClose, onDeliver, open, product } = props;

  const classes = useStyles();

  function handleClose() {
    onClose();
  }

  function handleDeliver() {
    onDeliver();
  }

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      open={open}
      disableEscapeKeyDown
      disableBackdropClick
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title">
      {product && product.id && (
        <DialogContent className={classes.root}>
          <OrderProductContainer mode="dialog" productId={product.id} onDeliver={handleDeliver} />
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={handleClose} color="default" disabled={disableClose}>
          {translation().actions.close}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

OrderProductDialog.propTypes = {
  disableClose: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onDeliver: PropTypes.func.isRequired,
  open: PropTypes.bool,
  product: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired
  })
};

export default OrderProductDialog;
