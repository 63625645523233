export const getOrderProductsUndispatchedMetadata = () => ({
  type: 'GET_ORDER_PRODUCTS_UNDISPATCHED_METADATA'
});

export const getOrderProductsUndispatchedMetadataSuccess = (data) => ({
  type: 'GET_ORDER_PRODUCTS_UNDISPATCHED_METADATA_SUCCESS',
  data
});

export const getOrderProductsUndispatchedMetadataError = (isError) => ({
  type: 'GET_ORDER_PRODUCTS_UNDISPATCHED_METADATA_ERROR',
  isError
});
