import { connect } from 'react-redux';

import { fetchApi } from '../../utils/functions/api';
import { isObjEmpty } from '../../utils/functions/utils';
import {
  clearMediasErrors,
  setMediasErrors,
  clearMediaGroups,
  removeMediaTypeMedias,
  downloadOrderProductMedias,
  downloadOrderProductMediasSuccess,
  downloadOrderProductMediasError,
  getProductMediaGroups,
  getProductMediaGroupsSuccess,
  getProductMediaGroupsError,
  uploadOrderProductMedias,
  uploadOrderProductMediasSuccess,
  uploadOrderProductMediasError
} from '../../redux/actions/medias/mediasActions';
import { enqueueSnackbar } from '../../redux/actions/appActions';
import MediasDropper from '../../components/medias/dropper/MediasDropper';
import translation from '../../translation/translation';

const mapStateToProps = (store) => ({
  medias: store.medias,
  mediaGroups: store.mediaGroups
});

const mapDispatchToProps = (dispatch) => {
  const checkBeforeSubmit = (mediasOrderArray) => {
    /**
     * Check if all media templates are set
     * If missing media templates, store errors into redux
     */
    const errors = {};
    let isValid = true;

    if (!mediasOrderArray || mediasOrderArray.length < 1) {
      return false;
    }

    for (let i = 0; i < mediasOrderArray.length; i++) {
      if (
        mediasOrderArray[i]['media_template_id'] !== undefined &&
        mediasOrderArray[i].media_template_id === 'null'
      ) {
        errors[mediasOrderArray[i].url] = translation().commons.required_field;
        isValid = false;
      }
    }

    if (!isObjEmpty(errors)) {
      dispatch(setMediasErrors(errors));
    }

    return isValid;
  };

  return {
    clearMediaGroups() {
      dispatch(clearMediaGroups());
    },
    clearMediasErrors() {
      dispatch(clearMediasErrors());
    },
    removeMediaTypeMedias(shortcode) {
      if (!shortcode) return;
      dispatch(removeMediaTypeMedias(shortcode));
    },
    downloadOrderProductMedias(orderId, orderProductId, query) {
      if (!orderId || !orderProductId || !query || !query.media_group_id) return;

      dispatch(downloadOrderProductMedias(query.media_group_id));

      fetchApi(
        'post',
        `orders/${orderId}/products/${orderProductId}/medias/download`,
        null,
        query,
        null,
        (url) => {
          const downloadIframe = document.getElementById('frame-download');
          const iframeContainer = document.getElementById('iframes-container');

          if (downloadIframe) {
            downloadIframe.setAttribute('src', url);
          } else {
            const downloadIframe =
              '<iframe id="frame-download" title="iframe-download" width="0" height="0" style="display: none" src=' +
              url +
              ' />';
            iframeContainer.innerHTML = downloadIframe;
          }

          dispatch(downloadOrderProductMediasSuccess());
        },
        (error) => {
          dispatch(downloadOrderProductMediasError());

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().views.orders.media_groups.callbacks.download_medias.error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().views.orders.media_groups.callbacks.download_medias.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    },
    getProductMediaGroups(orderId, orderProductId) {
      if (!orderId || !orderProductId) return;

      dispatch(getProductMediaGroups());

      fetchApi(
        'get',
        `orders/${orderId}/products/${orderProductId}/medias/structured`,
        null,
        null,
        null,
        (data) => {
          dispatch(getProductMediaGroupsSuccess(data));
        },
        (error) => {
          dispatch(getProductMediaGroupsError(error));
        }
      );
    },
    getMediaTypeTemplates(value, mediaTypeId, callbackSuccess, callbackError) {
      if (!mediaTypeId) return;

      fetchApi(
        'get',
        `media-types/${mediaTypeId}/templates`,
        { filters: `media-types|lk|${value}`, sort: 'name', limit: 5 },
        null,
        null,
        callbackSuccess,
        callbackError
      );
    },
    uploadOrder(ordersProductMedias, orderId, orderProductId, callbackSuccess, callbackFailure) {
      if (!ordersProductMedias || !orderId || !orderProductId) return;

      let mediasOrderArray = [];

      /**
       * Loop on all orderProductsMedias mediasTypes
       * Then get the medias for all mediasTypes and push it to the array for send to api
       */
      if (Object.keys(ordersProductMedias).length) {
        for (let mediaType in ordersProductMedias) {
          if (
            ordersProductMedias[mediaType].mediasBucket &&
            ordersProductMedias[mediaType].mediasBucket.length
          ) {
            for (let j = 0; j < ordersProductMedias[mediaType].mediasBucket.length; j++) {
              let mediasToPost = {
                url: ordersProductMedias[mediaType].mediasBucket[j].url
                  ? ordersProductMedias[mediaType].mediasBucket[j].url
                  : null,
                media_type_id: ordersProductMedias[mediaType].id
                  ? ordersProductMedias[mediaType].id
                  : null,
                floor: ordersProductMedias[mediaType].mediasBucket[j].floor
                  ? ordersProductMedias[mediaType].mediasBucket[j].floor
                  : null,
                position: ordersProductMedias[mediaType].mediasBucket[j].position
                  ? ordersProductMedias[mediaType].mediasBucket[j].position
                  : null
              };

              /**
               * Check if media have media template id key and if his value exists, then pass value to it
               */
              let media_template_id = null;

              if (
                ordersProductMedias[mediaType].mediasBucket[j].media_type &&
                ordersProductMedias[mediaType].mediasBucket[j].media_type.has_templates
              ) {
                if (ordersProductMedias[mediaType].mediasBucket[j]['media_template_id']) {
                  media_template_id =
                    ordersProductMedias[mediaType].mediasBucket[j].media_template_id;
                } else if (
                  ordersProductMedias[mediaType].mediasBucket[j]['media_template'] &&
                  ordersProductMedias[mediaType].mediasBucket[j].media_template['id']
                ) {
                  media_template_id =
                    ordersProductMedias[mediaType].mediasBucket[j].media_template.id;
                } else if (
                  ordersProductMedias[mediaType].mediasBucket[j]['media_template'] === null
                ) {
                  media_template_id = 'null';
                }

                if (media_template_id) mediasToPost.media_template_id = media_template_id;
              }

              mediasOrderArray.push(mediasToPost);
            }
          }
        }
      }

      /**
       * Check if all media templates fields are filled
       */
      if (!checkBeforeSubmit(mediasOrderArray)) {
        return dispatch(
          enqueueSnackbar({
            message: translation().views.orders.media_groups.callbacks.fill_templates,
            options: {
              variant: 'warning'
            }
          })
        );
      }

      const mediasOrder = {
        medias: mediasOrderArray
      };

      dispatch(uploadOrderProductMedias());

      if (orderProductId) {
        fetchApi(
          'post',
          `orders/${orderId}/products/${orderProductId}/medias/upload`,
          null,
          mediasOrder,
          null,
          () => {
            dispatch(
              enqueueSnackbar({
                message: translation().views.orders.media_groups.callbacks.upload_medias.success,
                options: {
                  variant: 'success'
                }
              })
            );
            dispatch(clearMediasErrors());
            dispatch(uploadOrderProductMediasSuccess());

            if (callbackSuccess && typeof callbackSuccess === 'function') callbackSuccess();
          },
          (error) => {
            const errorMessage =
              translation().views.orders.media_groups.callbacks.upload_medias.error;

            if (error.response) {
              dispatch(
                enqueueSnackbar({
                  message:
                    error.response.data && error.response.data.detail
                      ? error.response.data.detail
                      : errorMessage,
                  status: error.response.status || undefined,
                  options: {
                    variant: 'error'
                  }
                })
              );
            } else {
              dispatch(
                enqueueSnackbar({
                  message: errorMessage,
                  options: {
                    variant: 'error'
                  }
                })
              );
            }

            if (callbackFailure && typeof callbackFailure === 'function') callbackFailure();

            dispatch(uploadOrderProductMediasError(error));
          }
        );
      }
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MediasDropper);
