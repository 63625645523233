const initialState = {
  locale: '',
  timezone: undefined,
  languages: {
    data: [],
    isLoading: false,
    error: null
  },
  collections: [],
  isError: false,
  isLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_REDUCERS':
      return initialState;
    case 'UPDATE_RESOURCE':
      return {
        ...state,
        isError: false,
        isLoading: true
      };
    case 'UPDATE_RESOURCE_SUCCESS':
      return {
        ...state,
        isError: false,
        isLoading: false
      };
    case 'UPDATE_RESOURCE_ERROR':
      return {
        ...state,
        isError: true,
        isLoading: false
      };
    case 'SET_RESOURCE_COLLECTION': {
      const newStateCollection = state.collections.concat(action.collection);

      return {
        ...state,
        collections: newStateCollection
      };
    }
    case 'GET_UPDATE_RESOURCE_LANGUAGES':
      return {
        ...state,
        languages: {
          data: [],
          isLoading: true,
          error: null
        }
      };
    case 'GET_UPDATE_RESOURCE_LANGUAGES_SUCCESS':
      return {
        ...state,
        languages: {
          data: action.data,
          isLoading: false,
          error: null
        }
      };
    case 'GET_UPDATE_RESOURCE_LANGUAGES_ERROR':
      return {
        ...state,
        languages: {
          ...state.languages,
          isLoading: false,
          error: null
        }
      };
    case 'SET_UPDATE_RESOURCE_LOCALE':
      return {
        ...state,
        locale: action.locale
      };
    case 'SET_UPDATE_RESOURCE_TIMEZONE':
      return {
        ...state,
        timezone: action.timezone
      };
    case 'UNMOUNT_UPDATE_VIEW':
      return initialState;
    default:
      return state;
  }
};
