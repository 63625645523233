import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import BlockIcon from '@material-ui/icons/Block';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DoneIcon from '@material-ui/icons/Done';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Alert from '../../../../../components/alert/Alert';
import BlockPaymentMethodContainer from '../../../../../containers/core/customListActions/BlockPaymentMethodContainer';
import { dateInTz } from '../../../../../utils/functions/dates';
import translation from '../../../../../translation/translation';
import config from '../../../../../config';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2)
  },
  tableContainer: {
    maxHeight: 350
  },
  loadingWrapper: {
    width: '100%',
    height: 350,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      height: 'auto'
    }
  }
}));

function PaymentMethodRow(props) {
  const { action, data } = props;

  const typeStyle = {
    VISA: {
      color: '#6374ff',
      fontWeight: 500
    },
    MASTERCARD: {
      color: '#ffbe1c',
      fontWeight: 500
    },
    IBAN: {
      color: '#16cf7d',
      fontWeight: 500
    }
  };

  function handleClick() {
    action(data);
  }

  return (
    <TableRow>
      <TableCell>{data.id}</TableCell>
      <TableCell
        style={data.type && typeStyle[data.type] ? typeStyle[data.type] : { fontWeight: 500 }}>
        {data.type ? data.type : '-'}
      </TableCell>
      <TableCell>{data.name ? data.name : '-'}</TableCell>
      <TableCell>{data.currency_isocode ? data.currency_isocode : '-'}</TableCell>
      <TableCell>
        {data.blocked ? (
          <DoneIcon style={{ color: config.success_color || '#4CAF50' }} fontSize="small" />
        ) : (
          <CloseIcon style={{ color: config.error_color || '#F44336' }} fontSize="small" />
        )}
      </TableCell>
      <TableCell>{data.created ? dateInTz(data.created, 'localized-datetime') : '-'}</TableCell>
      <TableCell>
        <IconButton aria-label="Block payment method" onClick={handleClick} size="small">
          <BlockIcon fontSize="small" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

PaymentMethodRow.propTypes = {
  action: PropTypes.func.isRequired,
  data: PropTypes.shape().isRequired
};

function PaymentMethods(props) {
  const classes = useStyles();

  const [methodSelected, setMethodSelected] = useState(null);

  const columns = ['id', 'type', 'name', 'currency_isocode', 'blocked', 'created'];

  const {
    cleanProviderViewPaymentMethodsReducer,
    companyId,
    getProviderViewPaymentMethods,
    isModeCompany,
    paymentMethods,
    userId
  } = props;

  useEffect(() => {
    loadPaymentMethods(userId);

    return () => {
      cleanProviderViewPaymentMethodsReducer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModeCompany]);

  function loadPaymentMethods(userId, next = null) {
    getProviderViewPaymentMethods(userId, next, isModeCompany, companyId);
  }

  function handleBlockPaymentMethod(pm) {
    setMethodSelected(pm);
  }

  function handleCloseDialog() {
    setMethodSelected(null);
  }

  function handleReloadList() {
    cleanProviderViewPaymentMethodsReducer();
    loadPaymentMethods(userId, null);
  }

  function handleInfiniteScroll(event) {
    if (!event) console.error('No table scroll event found.');

    if (!event.currentTarget.scrollTop || event.currentTarget.scrollTop === 0) return;

    if (
      event.currentTarget.scrollHeight - event.currentTarget.clientHeight <=
      event.currentTarget.scrollTop
    ) {
      if (paymentMethods && paymentMethods.paging && paymentMethods.paging.next) {
        getProviderViewPaymentMethods(userId, paymentMethods.paging.next);
      }
    }
  }

  return (
    <div className={classNames(classes.root, 'ProviderPaymentMethods')}>
      {!paymentMethods.isLoading &&
      paymentMethods.error &&
      (!paymentMethods.data || paymentMethods.data.length <= 0) ? (
        <div className="error-list">
          <Alert
            type="danger"
            style={{ marginTop: 10 }}
            status={translation().commons.alerts.error}
            text={translation().core.list.error_list}
          />
        </div>
      ) : (
        false
      )}
      {paymentMethods.data && paymentMethods.data.length > 0 ? (
        <TableContainer onScroll={handleInfiniteScroll} className={classes.tableContainer}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column}>{column}</TableCell>
                ))}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentMethods.data.map((pm, index) => (
                <PaymentMethodRow key={index} data={pm} action={handleBlockPaymentMethod} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : !paymentMethods.isLoading && !paymentMethods.error ? (
        <Alert
          type="info"
          style={{ marginTop: 10 }}
          status={translation().commons.alerts.info}
          text={translation().views.user_view.payment_methods.empty}
        />
      ) : (
        false
      )}
      {paymentMethods.isLoading && !paymentMethods.paging && (
        <div className={classNames(classes.loadingWrapper, 'loader-wpr')}>
          <div style={{ width: '100%' }}>
            <CircularProgress color="primary" />
            <p>{translation().views.user_view.payment_methods.loading_text}</p>
          </div>
        </div>
      )}
      {paymentMethods.isLoading && paymentMethods.paging && paymentMethods.paging.next ? (
        <>
          <LinearProgress color="primary" />
          <div className="loader-wpr linear">
            <p>{translation().core.list.load_next}</p>
          </div>
        </>
      ) : (
        <div className="end-of-list-wpr">
          {!paymentMethods.isLoading &&
            paymentMethods.paging &&
            !paymentMethods.paging.next &&
            paymentMethods.paging.prev && <p>{translation().core.list.load_next_done}</p>}
        </div>
      )}
      <Dialog
        open={methodSelected ? true : false}
        onClose={handleCloseDialog}
        aria-labelledby="block-payment-method"
        aria-describedby="block-payment-method-id">
        <DialogTitle id="block-payment-method">
          {translation().core.list.dialogs.custom_list_actions.block_payment_method.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="block-payment-method-id">
            {translation().core.list.dialogs.custom_list_actions.block_payment_method.content}
          </DialogContentText>
          <BlockPaymentMethodContainer
            closeDialogFunction={handleCloseDialog}
            resource={methodSelected}
            fullProps={{
              resourceUri: isModeCompany && companyId ? 'companies' : 'users'
            }}
            resourceId={isModeCompany && companyId ? companyId : userId}
            reloadListFunction={handleReloadList}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>{translation().actions.close}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

PaymentMethods.propTypes = {
  cleanProviderViewPaymentMethodsReducer: PropTypes.func.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  getProviderViewPaymentMethods: PropTypes.func.isRequired,
  isModeCompany: PropTypes.bool,
  paymentMethods: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape()),
    error: PropTypes.string,
    isLoading: PropTypes.bool,
    paging: PropTypes.shape({
      next: PropTypes.string,
      prev: PropTypes.string
    })
  }).isRequired,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export default PaymentMethods;
