import React from 'react';

import CoreMetricsContainer from '../../../../../containers/metrics/CoreMetricsContainer';
import companyIdMetrics from '../../../../../routes/metrics/companyIdMetrics';

function CompanyMetrics() {
  return <CoreMetricsContainer embed mapping={companyIdMetrics} />;
}

export default CompanyMetrics;
