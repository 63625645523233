const initialState = {
  data: [],
  paging: null,
  error: null,
  isLoading: false,
  delete_dashboard: {
    isLoading: false,
    error: null
  },
  update_dashboard: {
    isLoading: false,
    error: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_REDUCERS':
      return initialState;
    case 'REFRESH_DASHBOARDS':
      return {
        ...initialState,
        isLoading: true
      };
    case 'GET_DASHBOARDS':
      return {
        ...state,
        data: action.next ? state.data : initialState.data,
        isLoading: true
      };
    case 'GET_DASHBOARDS_SUCCESS': {
      const dataConcatenated = state.data.concat(action.data);

      return {
        ...state,
        paging: action.paging ? action.paging : state.paging,
        data: dataConcatenated,
        error: null,
        isLoading: false
      };
    }
    case 'GET_DASHBOARDS_ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    case 'CREATE_DASHBOARD_SUCCESS': {
      const newArray = [...state.data, action.data];

      return {
        ...state,
        data: newArray,
        error: null,
        isLoading: false
      };
    }
    case 'UPDATE_DASHBOARD':
      return {
        ...state,
        update_dashboard: {
          isLoading: true,
          error: null
        }
      };
    case 'UPDATE_DASHBOARD_SUCCESS': {
      let newArray = [];

      if (state.data.length > 0) {
        for (let i = 0; i < state.data.length; i++) {
          if (state.data[i].id === action.data.id) {
            newArray = [...state.data];
            newArray[i] = action.data;
            break;
          } else {
            newArray = [...state.data, action.data];
          }
        }
      } else {
        newArray = [...state.data, action.data];
      }

      return {
        ...state,
        data: newArray,
        update_dashboard: {
          isLoading: false,
          error: null
        }
      };
    }
    case 'UPDATE_DASHBOARD_ERROR':
      return {
        ...state,
        update_dashboard: {
          isLoading: false,
          error: action.error
        }
      };
    case 'DELETE_DASHBOARD':
      return {
        ...state,
        delete_dashboard: {
          isLoading: true,
          error: null
        }
      };
    case 'DELETE_DASHBOARD_SUCCESS': {
      const arrayAfterDelete = state.data.filter((el) => {
        return el.id !== action.id;
      });

      return {
        ...state,
        data: arrayAfterDelete,
        delete_dashboard: {
          isLoading: false,
          error: null
        }
      };
    }
    case 'DELETE_DASHBOARD_ERROR':
      return {
        ...state,
        delete_dashboard: {
          isLoading: false,
          error: action.error
        }
      };
    default:
      return state;
  }
};
