import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';

import AclCustomField from '../fields/customs/aclCustomField/AclCustomField';
import FormApiAutocompleteField from '../fields/FormApiAutocompleteField';
import FormDateField from '../fields/FormDateField';
import FormDateTimeField from '../fields/FormDateTimeField';
import FormFileField from '../fields/FormFileField';
import FormSelectField from '../fields/FormSelectField';
import FormSwitchField from '../fields/FormSwitchField';
import FormPhoneField from '../fields/customs/formPhoneField/FormPhoneField';
import FormTextField from '../fields/FormTextField';
import FormTimeField from '../fields/FormTimeField';
import HtmlTextField from '../fields/customs/HtmlTextField';
import TimezoneField from '../fields/customs/TimezoneField';
import {
  alterDate,
  createDate,
  createDateUtc,
  formatDate,
  formatJSDate,
  getCurrentDateUtc
} from '../../../utils/functions/dates';

function FormField(props) {
  const {
    choices,
    error,
    formName,
    defaultValue,
    name,
    label,
    links,
    setFieldValue,
    target_key,
    type,
    read,
    reference_key,
    required,
    timezone,
    value,
    write
  } = props;

  useEffect(() => {
    /**
     * Pre-value converting
     */
    let valueForRedux = defaultValue || null;

    if (type === 'boolean') {
      let booleanValue = defaultValue;

      if (defaultValue === null) {
        if (value && value.default === true) booleanValue = true;
        else if (value && value.default === false) booleanValue = false;
      } else if (defaultValue === false) booleanValue = false;
      else if (defaultValue === true) booleanValue = true;

      setFieldValue(formName, name, booleanValue);
    } else if (type === 'time' && defaultValue) {
      const defaultValueSplitted = defaultValue.split(':');
      const hour = defaultValueSplitted[0];
      const mins = defaultValueSplitted[1];
      const secs = defaultValueSplitted[2];
      const displayValue = alterDate(getCurrentDateUtc(), null, null, null, hour, mins, secs);

      setFieldValue(formName, name, formatDate(displayValue, 'HH:mm:00'));
    } else if (type === 'date' && defaultValue) {
      setFieldValue(formName, name, formatDate(defaultValue, 'YYYY/MM/DD'));
    } else if (type === 'timestamp' && defaultValue) {
      setFieldValue(formName, name, createDate(defaultValue));
    } else if (type === 'array' && defaultValue && defaultValue.length > 0) {
      setFieldValue(formName, name, defaultValue.join('\n'));
    } else if (type === 'object') {
      setFieldValue(formName, name, JSON.stringify(defaultValue, null, 4));
    } else if (type === 'entity' && defaultValue) {
      setFieldValue(formName, reference_key || name, defaultValue);
    } else if (type !== 'collection') {
      if (valueForRedux) {
        setFieldValue(formName, name, valueForRedux);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleChangeText(value) {
    setFieldValue(formName, name, value);
  }

  function handleChangeEntity(value) {
    setFieldValue(formName, reference_key || name, value);
  }

  function handleChangeSelect(value) {
    setFieldValue(formName, name, value);
  }

  function handleChangeSwitch(value) {
    setFieldValue(formName, name, value || false);
  }

  function handleChangeDate(value) {
    setFieldValue(formName, name, formatDate(value, 'YYYY/MM/DD'));
  }

  function handleChangeTimestamp(value) {
    setFieldValue(formName, name, createDate(value));
  }

  function handleChangeTime(value) {
    const day = formatJSDate(new Date());
    const start = createDate(day + 'T' + value);
    const utcValue = createDateUtc(start);

    setFieldValue(formName, name, formatDate(utcValue, 'HH:mm:00'));
  }

  function handleChangeAcl(json) {
    setFieldValue(formName, name, json);
  }

  if ((!read && name !== 'password') || !type || type === 'collection') {
    return false;
  }

  if (type === 'entity') {
    return (
      <FormApiAutocompleteField
        name={reference_key || name}
        label={label}
        links={links}
        onSelectValue={handleChangeEntity}
        targetKey={target_key}
        defaultValue={defaultValue}
        write={write}
        required={required}
        error={error}
        helperText={
          !links || links.length <= 0 ? 'This entity contains no link for the autocomplete.' : ''
        }
      />
    );
  } else if (type === 'set') {
    return (
      <FormApiAutocompleteField
        name={reference_key || name}
        label={label}
        links={links}
        onSelectValue={handleChangeEntity}
        targetKey={target_key}
        write={write}
        defaultValue={defaultValue}
        required={required}
        error={error}
        multiple
        helperText={
          !links || links.length <= 0 ? 'This entity contains no link for the autocomplete.' : ''
        }
      />
    );
  } else if (type === 'number' || type === 'integer' || type === 'float' || type === 'decimal') {
    return (
      <FormTextField
        type="number"
        name={name}
        label={label}
        write={write}
        defaultValue={defaultValue}
        onChange={handleChangeText}
        required={required}
        error={error}
      />
    );
  } else if (choices && choices.length > 0) {
    return (
      <FormSelectField
        displayEmpty={true}
        id={name}
        name={name}
        label={label}
        write={write}
        defaultValue={defaultValue}
        onChange={handleChangeSelect}
        required={required}
        error={error}>
        {choices.map((choice, indexItem) => {
          return (
            <MenuItem key={indexItem} value={choice}>
              {choice}
            </MenuItem>
          );
        })}
      </FormSelectField>
    );
  } else if (type === 'boolean') {
    let booleanValue = defaultValue;

    if (defaultValue === null) {
      if (value && value.default === true) booleanValue = true;
      else if (value && value.default === false) booleanValue = false;
    } else if (defaultValue === false) booleanValue = false;
    else if (defaultValue === true) booleanValue = true;

    return (
      <FormSwitchField
        name={name}
        label={label}
        write={write}
        onChange={handleChangeSwitch}
        required={required}
        defaultValue={booleanValue}
        error={error}
      />
    );
  } else if (type === 'date') {
    return (
      <FormDateField
        name={name}
        label={label}
        write={write}
        defaultValue={defaultValue}
        required={required}
        onChange={handleChangeDate}
        error={error}
      />
    );
  } else if (type === 'timestamp') {
    return (
      <FormDateTimeField
        name={name}
        label={label}
        write={write}
        defaultValue={defaultValue}
        onChange={handleChangeTimestamp}
        required={required}
        timezone={timezone}
        error={error}
      />
    );
  } else if (type === 'time') {
    return (
      <FormTimeField
        name={name}
        label={label}
        write={write}
        defaultValue={defaultValue}
        onChange={handleChangeTime}
        required={required}
        timezone={timezone}
        error={error}
      />
    );
  } else if (type === 'string') {
    return (
      <FormTextField
        name={name}
        label={label}
        write={write}
        defaultValue={defaultValue}
        onChange={handleChangeText}
        required={required}
        error={error}
      />
    );
  } else if (type === 'object') {
    if (name.toLowerCase() === 'acl') {
      return (
        <AclCustomField
          name={name}
          label={label}
          write={write}
          defaultValue={defaultValue}
          required={required}
          onJsonChange={handleChangeAcl}
          onTextChange={handleChangeText}
          error={error}
        />
      );
    } else {
      return (
        <FormTextField
          name={name}
          label={label}
          write={write}
          multiline
          type="textarea"
          defaultValue={defaultValue ? JSON.stringify(defaultValue, null, 4) : ''}
          onChange={handleChangeText}
          required={required}
          error={error}
        />
      );
    }
  } else if (type === 'array') {
    return (
      <FormTextField
        name={name}
        label={label}
        write={write}
        multiline
        type="textarea"
        defaultValue={defaultValue ? defaultValue.join('\n') : ''}
        onChange={handleChangeText}
        required={required}
        error={error}
      />
    );
  } else if (type === 'email') {
    return (
      <FormTextField
        type="email"
        name={name}
        label={label}
        write={write}
        defaultValue={defaultValue}
        onChange={handleChangeText}
        required={required}
        error={error}
      />
    );
  } else if (type === 'timezone') {
    return (
      <TimezoneField
        name={name}
        label={label}
        write={write}
        defaultValue={defaultValue}
        onInputChange={handleChangeText}
        required={required}
        error={error}
      />
    );
  } else if (type === 'text') {
    return (
      <FormTextField
        name={name}
        label={label}
        write={write}
        multiline
        type="textarea"
        defaultValue={defaultValue}
        onChange={handleChangeText}
        required={required}
        error={error}
      />
    );
  } else if (type === 'html') {
    return (
      <HtmlTextField
        name={name}
        label={label}
        write={write}
        defaultValue={defaultValue}
        onChange={handleChangeText}
        required={required}
        error={error}
      />
    );
  } else if (type === 'url') {
    return (
      <FormTextField
        type="text"
        name={name}
        label={label}
        write={write}
        defaultValue={defaultValue}
        required={required}
        onChange={handleChangeText}
        error={error}
      />
    );
  } else if (type === 'password') {
    return (
      <FormTextField
        name={name}
        label={label}
        write={true}
        defaultValue={defaultValue}
        required={required}
        onChange={handleChangeText}
        error={error}
      />
    );
  } else if (type === 'phone' || type === 'mobile' || type === 'tel') {
    return (
      <FormPhoneField
        type="tel"
        name={name}
        label={label}
        write={write}
        defaultValue={defaultValue}
        required={required}
        onChange={handleChangeText}
        error={error}
      />
    );
  } else if (type === 'image' || type === 'file') {
    return (
      <FormFileField
        type={type}
        name={name}
        label={label}
        required={required}
        defaultValue={defaultValue}
        write={write}
        onChange={handleChangeText}
        error={error}
      />
    );
  } else {
    return (
      <FormTextField
        name={name}
        label={label}
        write={write}
        defaultValue={defaultValue}
        required={required}
        onChange={handleChangeText}
        error={error}
      />
    );
  }
}

FormField.defaultProps = {
  defaultValue: null
};

FormField.propTypes = {
  choices: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.shape()])
  ),
  error: PropTypes.string,
  defaultValue: PropTypes.any,
  formName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(PropTypes.shape()),
  target_key: PropTypes.any,
  name: PropTypes.string.isRequired,
  read: PropTypes.bool,
  setFieldValue: PropTypes.func.isRequired,
  timezone: PropTypes.string,
  type: PropTypes.string.isRequired,
  value: PropTypes.shape(),
  write: PropTypes.bool,
  reference_key: PropTypes.string,
  required: PropTypes.bool
};

export default FormField;
