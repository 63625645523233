const initialState = {
  isLoading: false,
  data: null,
  error: null,
  download_action: {
    isLoading: false,
    error: null
  },
  download_settings: {
    definition: null,
    watermark: null
  },
  upload_action: {
    isLoading: false,
    error: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_REDUCERS':
      return initialState;
    case 'CLEAR_MEDIA_GROUPS':
      return initialState;
    case 'SET_DOWNLOAD_SETTINGS_WATERMARK':
      return {
        ...state,
        download_settings: {
          ...state.download_settings,
          watermark: action.data
        }
      };
    case 'SET_DOWNLOAD_SETTINGS_DEFINITION':
      return {
        ...state,
        download_settings: {
          ...state.download_settings,
          definition: action.data
        }
      };
    case 'GET_ORDER_PRODUCT_MEDIA_GROUPS':
      return {
        ...state,
        isLoading: true
      };
    case 'GET_ORDER_PRODUCT_MEDIA_GROUPS_SUCCESS':
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    case 'GET_ORDER_PRODUCT_MEDIA_GROUPS_ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.error
      };
    case 'DOWNLOAD_ORDER_PRODUCT_MEDIAS':
      return {
        ...state,
        download_action: {
          isLoading: action.id,
          error: null
        }
      };
    case 'DOWNLOAD_ORDER_PRODUCT_MEDIAS_SUCCESS':
      return {
        ...state,
        download_action: {
          isLoading: false,
          error: null
        }
      };
    case 'DOWNLOAD_ORDER_PRODUCT_MEDIAS_ERROR':
      return {
        ...state,
        download_action: {
          isLoading: false,
          error: action.error
        }
      };
    case 'UPLOAD_ORDER_PRODUCT_MEDIAS':
      return {
        ...state,
        upload_action: {
          isLoading: true,
          error: null
        }
      };
    case 'UPLOAD_ORDER_PRODUCT_MEDIAS_SUCCESS':
      return {
        ...state,
        upload_action: {
          isLoading: false,
          error: null
        }
      };
    case 'UPLOAD_ORDER_PRODUCT_MEDIAS_ERROR':
      return {
        ...state,
        upload_action: {
          isLoading: false,
          error: action.error
        }
      };
    default:
      return state;
  }
};
