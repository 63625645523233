export const cleanUserViewInvoicesReducer = () => ({
  type: 'CLEAN_USER_VIEW_INVOICES_REDUCER'
});

export const cleanUserViewOrdersReducer = () => ({
  type: 'CLEAN_USER_VIEW_ORDERS_REDUCER'
});

export const cleanUserViewInvoicesEmittedReducer = () => ({
  type: 'CLEAN_USER_VIEW_INVOICES_EMITTED_REDUCER'
});

export const cleanUserViewPaymentMethodReducer = () => ({
  type: 'CLEAN_USER_VIEW_PAYMENT_METHOD_REDUCER'
});

export const cleanUserViewDevicesReducer = () => ({
  type: 'CLEAN_USER_VIEW_DEVICES_REDUCER'
});

export const cleanUserViewAddressesReducer = () => ({
  type: 'CLEAN_USER_VIEW_ADDRESSES_REDUCER'
});

export const cleanUserViewRatingsReducer = () => ({
  type: 'CLEAN_USER_VIEW_RATINGS_REDUCER'
});

export const getUser = () => ({
  type: 'GET_USER_VIEW'
});

export const getUserSuccess = (data) => ({
  type: 'GET_USER_VIEW_SUCCESS',
  data
});

export const getUserError = (error) => ({
  type: 'GET_USER_VIEW_ERROR',
  error
});

export const getUserViewInvoices = (next) => ({
  type: 'GET_USER_VIEW_INVOICES',
  next
});

export const getUserViewInvoicesSuccess = (data, paging) => ({
  type: 'GET_USER_VIEW_INVOICES_SUCCESS',
  data,
  paging
});

export const getUserViewInvoicesError = (error) => ({
  type: 'GET_USER_VIEW_INVOICES_ERROR',
  error
});

export const getUserViewInvoicesEmitted = (next) => ({
  type: 'GET_USER_VIEW_INVOICES_EMITTED',
  next
});

export const getUserViewInvoicesEmittedSuccess = (data, paging) => ({
  type: 'GET_USER_VIEW_INVOICES_EMITTED_SUCCESS',
  data,
  paging
});

export const getUserViewInvoicesEmittedError = (error) => ({
  type: 'GET_USER_VIEW_INVOICES_EMITTED_ERROR',
  error
});

export const getUserViewPaymentMethods = (next) => ({
  type: 'GET_USER_VIEW_PAYMENT_METHODS',
  next
});

export const getUserViewPaymentMethodsSuccess = (data, paging) => ({
  type: 'GET_USER_VIEW_PAYMENT_METHODS_SUCCESS',
  data,
  paging
});

export const getUserViewPaymentMethodsError = (error) => ({
  type: 'GET_USER_VIEW_PAYMENT_METHODS_ERROR',
  error
});

export const getUserViewDevices = (next) => ({
  type: 'GET_USER_VIEW_DEVICES',
  next
});

export const getUserViewDevicesSuccess = (data, paging) => ({
  type: 'GET_USER_VIEW_DEVICES_SUCCESS',
  data,
  paging
});

export const getUserViewDevicesError = (error) => ({
  type: 'GET_USER_VIEW_DEVICES_ERROR',
  error
});

export const getUserViewOrders = (next) => ({
  type: 'GET_USER_VIEW_ORDERS',
  next
});

export const getUserViewOrdersSuccess = (data, paging) => ({
  type: 'GET_USER_VIEW_ORDERS_SUCCESS',
  data,
  paging
});

export const getUserViewOrdersError = (error) => ({
  type: 'GET_USER_VIEW_ORDERS_ERROR',
  error
});

export const getUserViewOrdersMetadata = () => ({
  type: 'GET_USER_VIEW_ORDERS_METADATA'
});

export const getUserViewOrdersMetadataSuccess = (data) => ({
  type: 'GET_USER_VIEW_ORDERS_METADATA_SUCCESS',
  data
});

export const getUserViewOrdersMetadataError = (isError) => ({
  type: 'GET_USER_VIEW_ORDERS_METADATA_ERROR',
  isError
});

export const getUserViewAddresses = (next) => ({
  type: 'GET_USER_VIEW_ADDRESSES',
  next
});

export const getUserViewAddressesSuccess = (data, paging) => ({
  type: 'GET_USER_VIEW_ADDRESSES_SUCCESS',
  data,
  paging
});

export const getUserViewAddressesError = (error) => ({
  type: 'GET_USER_VIEW_ADDRESSES_ERROR',
  error
});

export const getUserViewRatings = (next) => ({
  type: 'GET_USER_VIEW_RATINGS',
  next
});

export const getUserViewRatingsSuccess = (data, paging) => ({
  type: 'GET_USER_VIEW_RATINGS_SUCCESS',
  data,
  paging
});

export const getUserViewRatingsError = (error) => ({
  type: 'GET_USER_VIEW_RATINGS_ERROR',
  error
});
