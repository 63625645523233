const initialState = {
  data: {
    metrics: []
  },
  dateRange: null,
  error: null,
  isLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_REDUCERS':
      return initialState;
    case 'CLEAR_DASHBOARD':
      return initialState;
    case 'GET_DASHBOARD':
      return {
        ...state,
        data: {
          metrics: []
        },
        error: null,
        isLoading: true
      };
    case 'GET_DASHBOARD_SUCCESS':
      return {
        ...state,
        data: action.data,
        error: null,
        isLoading: false
      };
    case 'GET_DASHBOARD_ERROR':
      return {
        ...state,
        error: action.error,
        isLoading: false
      };
    case 'SET_DASHBOARD_DATE_RANGE': {
      let newArray = [];

      if (state.data.metrics.length > 0) {
        for (let i = 0; i < state.data.metrics.length; i++) {
          newArray = [...state.data.metrics];
          newArray[i].submitBuffer += 1;
        }
      } else {
        newArray = [...state.data.metrics];
      }

      return {
        ...state,
        dateRange: action.range,
        data: {
          ...state.data,
          metrics: newArray
        }
      };
    }
    case 'SET_METRIC_TO_DASHBOARD': {
      let newArray = [];

      if (state.data.metrics.length > 0) {
        for (let i = 0; i < state.data.metrics.length; i++) {
          if (state.data.metrics[i].id === action.metric.id) {
            newArray = [...state.data.metrics];
            newArray[i] = action.metric;
            break;
          } else {
            newArray = [...state.data.metrics, action.metric];
          }
        }
      } else {
        newArray = [...state.data.metrics, action.metric];
      }

      return {
        ...state,
        data: {
          ...state.data,
          metrics: newArray
        }
      };
    }
    case 'MOVE_METRICS_INTO_DASHBOARD': {
      const newArray = [...action.metrics];

      if (newArray.length > 0) {
        for (let i = 0; i < newArray.length; i++) {
          newArray[i].position = i;
        }
      }

      return {
        ...state,
        data: {
          ...state.data,
          metrics: newArray
        }
      };
    }
    case 'REMOVE_METRIC_FROM_DASHBOARD': {
      const arrayAfterDelete = state.data.metrics.filter((el) => {
        return el.id !== action.id;
      });

      if (arrayAfterDelete.length > 0) {
        for (let i = 0; i < arrayAfterDelete.length; i++) {
          arrayAfterDelete[i].position = i;
        }
      }

      return {
        ...state,
        data: {
          ...state.data,
          metrics: arrayAfterDelete
        }
      };
    }
    default:
      return state;
  }
};
