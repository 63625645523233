import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';

import Alert from '../../../../components/alert/Alert';
import FormSelectField from '../../../../components/forms/fields/FormSelectField';
import translation from '../../../../translation/translation';

function PaymentStep(props) {
  const { addresses, paymentMethods, onSelectAddress, onSelectMethod } = props;

  function handleChangeAddress(value) {
    onSelectAddress(value);
  }

  function handleChangePaymentMethod(value) {
    onSelectMethod(value);
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6}>
          {addresses && addresses.data && addresses.data.length > 0 ? (
            <FormSelectField
              displayEmpty
              id="invoice_address_id"
              label={translation().views.orders.create.payment.invoicing.addresses.label}
              name="invoice_address_id"
              onChange={handleChangeAddress}
              required
              value={addresses.selected}>
              {addresses.data.map((address, index) => {
                return (
                  <MenuItem key={index} value={address.id}>
                    {address.name +
                      ' (' +
                      address.street_number +
                      ' ' +
                      address.street +
                      ' ' +
                      (address.zip_code ? address.zip_code + ' ' : ' ') +
                      address.city +
                      ')'}
                  </MenuItem>
                );
              })}
            </FormSelectField>
          ) : (
            <Alert
              type="warning"
              style={{ marginTop: 10 }}
              status={translation().commons.alerts.warning}
              text={translation().views.order_products.hosting_extensions.create.no_addresses}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          {paymentMethods && paymentMethods.data && paymentMethods.data.length > 0 ? (
            <FormSelectField
              id="payment_method_id"
              displayEmpty
              label={translation().views.orders.create.payment.invoicing.methods}
              name="payment_method_id"
              onChange={handleChangePaymentMethod}
              required
              value={paymentMethods.selected}>
              {paymentMethods.data.map((pm, index) => {
                return (
                  <MenuItem key={index} value={pm.id}>
                    {(pm.type ? pm.type : '') + ' ' + pm.name}
                  </MenuItem>
                );
              })}
            </FormSelectField>
          ) : (
            <Alert
              type="warning"
              style={{ marginTop: 10 }}
              status={translation().commons.alerts.warning}
              text={translation().views.order_products.hosting_extensions.create.no_payment_methods}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
}

PaymentStep.propTypes = {
  addresses: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.any),
    selected: PropTypes.any
  }).isRequired,
  paymentMethods: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.any),
    selected: PropTypes.any
  }).isRequired,
  onSelectAddress: PropTypes.func.isRequired,
  onSelectMethod: PropTypes.func.isRequired
};

export default PaymentStep;
