import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import FormSelectField from '../../../../../../components/forms/fields/FormSelectField';
import translation from '../../../../../../translation/translation';

const useStyles = makeStyles(() => ({
  currentStatusText: {
    display: 'flex',
    lineHeight: 'inherit'
  },
  currentStatusTitle: {
    marginRight: 4
  },
  currentStatusWrapper: {
    display: 'flex',
    alignItems: 'center'
  }
}));

function DeliveryStatusButton(props) {
  const {
    className,
    currentStatus,
    onEnter,
    orderId,
    orderProductId,
    productDeliveryStatuses,
    reloadOrder,
    updateOrderProductDeliveryStatus
  } = props;

  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(null);

  function handleOpenDialog() {
    setIsOpen(true);
  }

  function handleChangeValue(value) {
    setValue(value);
  }

  function handleCloseDialog() {
    setIsOpen(false);
  }

  function handleEnterDialog() {
    setValue(null);
    onEnter();
  }

  function handleSubmit() {
    updateOrderProductDeliveryStatus(orderId, orderProductId, value, reloadOrder);
  }

  return (
    <>
      <Tooltip
        title={translation().views.orders.update.products.product_delivery_status.title}
        enterDelay={500}>
        <IconButton aria-label="status" className={className} onClick={handleOpenDialog}>
          <AssignmentTurnedInIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        fullWidth
        open={isOpen ? true : false}
        onClose={handleCloseDialog}
        aria-labelledby="add-product-option"
        aria-describedby="add-product-option-description"
        disableBackdropClick={productDeliveryStatuses.isLoading}
        onEnter={handleEnterDialog}>
        <DialogTitle id="add-product-option">
          {translation().views.orders.update.products.product_delivery_status.title}
        </DialogTitle>
        <DialogContent>
          {currentStatus && (
            <DialogContentText id="add-product-option-description" component="div">
              <div className={classes.currentStatusWrapper}>
                {currentStatus.name && (
                  <Typography
                    className={`${classes.currentStatusTitle} ${classes.currentStatusText}`}>
                    {translation().views.orders.update.products.product_delivery_status.current}:
                  </Typography>
                )}
                {currentStatus.icon_url_dark && (
                  <img
                    height="25"
                    width="25"
                    style={{ marginRight: 2 }}
                    alt={currentStatus.name ? currentStatus.name : 'Status name not found'}
                    src={currentStatus.icon_url_dark}
                  />
                )}
                {currentStatus.name && (
                  <Typography
                    variant="subtitle1"
                    className={classes.currentStatusText}
                    style={currentStatus.color ? { color: currentStatus.color } : {}}>
                    {currentStatus.name}
                  </Typography>
                )}
              </div>
            </DialogContentText>
          )}
          {productDeliveryStatuses &&
          productDeliveryStatuses.data &&
          productDeliveryStatuses.data.length > 0 ? (
            <FormSelectField
              id="o_p_delivery_status"
              disabled={productDeliveryStatuses.isLoading}
              displayEmpty
              label={translation().views.orders.update.products.product_delivery_status.label}
              name="o_p_delivery_status"
              onChange={handleChangeValue}
              required
              value={value}>
              {productDeliveryStatuses.data.map((status, index) => (
                <MenuItem key={index} value={status.id}>
                  {status.name}
                </MenuItem>
              ))}
            </FormSelectField>
          ) : (
            !productDeliveryStatuses.isLoading && (
              <Typography>
                {translation().views.orders.update.products.product_delivery_status.no_statuses}
              </Typography>
            )
          )}
          {productDeliveryStatuses.isLoading && (
            <div className="loader-wpr" style={{ marginTop: 15 }}>
              <CircularProgress size={30} color="secondary" />
              <p>
                {value
                  ? translation().views.orders.update.products.product_delivery_status.sending_text
                  : translation().views.orders.update.products.product_delivery_status.loading_text}
              </p>
            </div>
          )}
          <div className="sm-space" />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            color="default"
            disabled={productDeliveryStatuses.isLoading}>
            {translation().actions.cancel}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={productDeliveryStatuses.isLoading || !value}>
            {translation().actions.confirm}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

DeliveryStatusButton.propTypes = {
  className: PropTypes.string.isRequired,
  currentStatus: PropTypes.shape({
    name: PropTypes.any.isRequired,
    color: PropTypes.string,
    icon_url_dark: PropTypes.any
  }).isRequired,
  onEnter: PropTypes.func.isRequired,
  orderId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  orderProductId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  productDeliveryStatuses: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape()),
    isLoading: PropTypes.bool
  }).isRequired,
  reloadOrder: PropTypes.func.isRequired,
  updateOrderProductDeliveryStatus: PropTypes.func.isRequired
};

export default DeliveryStatusButton;
