const initialState = {
  timezone: undefined,
  isError: false,
  isLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_REDUCERS':
      return initialState;
    case 'CREATE_RESOURCE':
      return {
        ...state,
        isError: false,
        isLoading: true
      };
    case 'CREATE_RESOURCE_SUCCESS':
      return {
        ...state,
        isError: false,
        isLoading: false
      };
    case 'CREATE_RESOURCE_ERROR':
      return {
        ...state,
        isError: true,
        isLoading: false
      };
    case 'SET_CREATE_RESOURCE_TIMEZONE':
      return {
        ...state,
        timezone: action.timezone
      };
    default:
      return state;
  }
};
