import { addToDate, formatDate, getCurrentDate } from '../../../../utils/functions/dates';

const initialState = {
  isLoading: false,
  step: 0,
  user: null,
  estateForm: {
    floor: '',
    digital_code: '',
    intercom: '',
    property_contact_name: '',
    property_contact_phone: '',
    property_contact_comment: ''
  },
  propertyForm: {
    address: null,
    size: null,
    property_type: null
  },
  propertyGeo: {
    location: null,
    timezone_code: null
  },
  paymentDelegate: {
    open: false,
    delegate_email: '',
    delegate_amount: ''
  },
  cart: {
    data: [],
    isCreateLoading: false,
    isLoading: false,
    error: null,
    deleteItem: {
      isLoading: false,
      error: null
    }
  },
  addresses: {
    create: {
      isLoading: false,
      error: null
    },
    data: [],
    isLoading: false,
    error: null,
    selected: {
      id: null
    }
  },
  orderReference: null,
  paymentMethods: {
    data: [],
    isLoading: false,
    error: null,
    selected: {
      id: null
    }
  },
  productGroups: {
    data: [],
    isLoading: false,
    error: null
  },
  products: {
    data: [],
    isLoading: false,
    error: null
  },
  productOptions: {
    data: [],
    isLoading: false,
    error: null
  },
  planning: {
    slots: null,
    nbTimeMax: 0,
    startDate: formatDate(getCurrentDate(), 'YYYY-MM-DD'),
    endDate: formatDate(addToDate(getCurrentDate(), 7, 'days'), 'YYYY-MM-DD'),
    minStartDate: formatDate(getCurrentDate(), 'YYYY-MM-DD'),
    maxStartDate: formatDate(addToDate(getCurrentDate(), 3, 'months'), 'YYYY-MM-DD'),
    calendar: null,
    isLoading: false,
    isWeekLoading: false
  },
  productToCart: {
    isLoading: false,
    error: null
  },
  discountToCart: {
    isLoading: false,
    error: null
  },
  missingFields: {
    data: [],
    isLoading: false,
    error: null
  },
  validatingFields: {
    data: [],
    isLoading: false,
    error: null
  },
  orderCreate: {
    data: null,
    isLoading: false,
    error: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_REDUCERS':
      return initialState;
    case 'CLEAR_ORDER_CREATE_REDUCER':
      return initialState;
    case 'SET_ACTIVE_ORDER_STEP':
      return {
        ...state,
        step: action.step
      };
    case 'STORE_CLIENT':
      return {
        ...state,
        user: action.user
      };
    case 'STORE_PROPERTY':
      return {
        ...state,
        propertyForm: {
          ...action.property
        }
      };
    case 'STORE_PROPERTY_LOCATION':
      return {
        ...state,
        propertyGeo: {
          ...state.propertyGeo,
          location: action.location,
          timezone_code: null
        }
      };
    case 'STORE_PROPERTY_TIMEZONE':
      return {
        ...state,
        propertyGeo: {
          ...state.propertyGeo,
          timezone_code: action.timezone
        },
        planning: {
          ...initialState.planning,
          startDate: formatDate(getCurrentDate(null, action.timezone), 'YYYY-MM-DD'),
          endDate: formatDate(
            addToDate(getCurrentDate(null, action.timezone), 7, 'days'),
            'YYYY-MM-DD'
          ),
          minStartDate: formatDate(getCurrentDate(null, action.timezone), 'YYYY-MM-DD'),
          maxStartDate: formatDate(
            addToDate(getCurrentDate(null, action.timezone), 3, 'months'),
            'YYYY-MM-DD'
          )
        }
      };
    case 'STORE_ESTATE_VALUE':
      return {
        ...state,
        estateForm: {
          ...state.estateForm,
          [action.name]: action.value
        }
      };
    case 'STORE_ORDER_REFERENCE':
      return {
        ...state,
        orderReference: action.value
      };
    case 'STORE_PAYMENT_DELEGATE_VALUE':
      return {
        ...state,
        paymentDelegate: {
          ...state.paymentDelegate,
          [action.name]: action.value
        }
      };
    case 'STORE_PROPERTY_VALUE':
      return {
        ...state,
        propertyForm: {
          ...state.propertyForm,
          [action.name]: action.value
        }
      };
    case 'GET_CLIENT_PAYMENT_METHODS':
      return {
        ...state,
        paymentMethods: {
          ...initialState.paymentMethods,
          isLoading: true
        }
      };
    case 'GET_CLIENT_PAYMENT_METHODS_SUCCESS':
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          data: action.data,
          isLoading: false,
          error: null
        }
      };
    case 'GET_CLIENT_PAYMENT_METHODS_ERROR':
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          isLoading: false,
          error: action.error
        }
      };
    case 'GET_CLIENT_ADDRESSES':
      return {
        ...state,
        addresses: {
          ...state.addresses,
          data: [],
          isLoading: true,
          error: null
        }
      };
    case 'GET_CLIENT_ADDRESSES_SUCCESS':
      return {
        ...state,
        addresses: {
          ...state.addresses,
          data: action.data,
          isLoading: false,
          error: null
        }
      };
    case 'GET_CLIENT_ADDRESSES_ERROR':
      return {
        ...state,
        addresses: {
          ...state.addresses,
          isLoading: false,
          error: action.error
        }
      };
    case 'POST_CLIENT_ADDRESS':
      return {
        ...state,
        addresses: {
          ...state.addresses,
          create: {
            isLoading: true,
            error: null
          }
        }
      };
    case 'POST_CLIENT_ADDRESS_SUCCESS':
      return {
        ...state,
        addresses: {
          ...state.addresses,
          create: {
            isLoading: false,
            error: null
          }
        }
      };
    case 'POST_CLIENT_ADDRESS_ERROR':
      return {
        ...state,
        addresses: {
          ...state.addresses,
          create: {
            isLoading: false,
            error: action.error
          }
        }
      };
    case 'GET_CLIENT_CART':
      return {
        ...state,
        cart: {
          ...state.cart,
          isLoading: true
        }
      };
    case 'GET_CLIENT_CART_SUCCESS':
      return {
        ...state,
        cart: {
          ...state.cart,
          data: action.data,
          isLoading: false,
          error: null
        }
      };
    case 'GET_CLIENT_CART_ERROR':
      return {
        ...state,
        cart: {
          ...state.cart,
          isLoading: false,
          error: action.error
        }
      };
    case 'CREATE_CLIENT_CART':
      return {
        ...state,
        cart: {
          ...state.cart,
          isCreateLoading: true
        }
      };
    case 'CREATE_CLIENT_CART_SUCCESS':
      return {
        ...state,
        cart: {
          ...state.cart,
          data: action.data,
          isCreateLoading: false,
          error: null
        }
      };
    case 'CREATE_CLIENT_CART_ERROR':
      return {
        ...state,
        cart: {
          ...state.cart,
          isCreateLoading: false,
          error: action.error
        }
      };
    case 'DELETE_CLIENT_CART':
      return {
        ...state,
        cart: {
          ...state.cart,
          isLoading: true
        }
      };
    case 'DELETE_CLIENT_CART_SUCCESS':
      return {
        ...state,
        cart: initialState.cart
      };
    case 'DELETE_CLIENT_CART_ERROR':
      return {
        ...state,
        cart: {
          ...state.cart,
          isLoading: false,
          error: action.error
        }
      };
    case 'DELETE_CLIENT_CART_ITEM':
      return {
        ...state,
        cart: {
          ...state.cart,
          deleteItem: {
            isLoading: true,
            error: null
          }
        }
      };
    case 'DELETE_CLIENT_CART_ITEM_SUCCESS':
      return {
        ...state,
        cart: {
          ...state.cart,
          deleteItem: {
            isLoading: false,
            error: null
          }
        }
      };
    case 'DELETE_CLIENT_CART_ITEM_ERROR':
      return {
        ...state,
        cart: {
          ...state.cart,
          deleteItem: {
            isLoading: false,
            error: action.error
          }
        }
      };
    case 'GET_PRODUCT_GROUPS':
      return {
        ...state,
        productGroups: {
          ...initialState.productGroups,
          isLoading: true
        },
        planning: {
          ...initialState.planning,
          startDate: state.planning.startDate,
          endDate: state.planning.endDate,
          minStartDate: state.planning.minStartDate,
          maxStartDate: state.planning.maxStartDate
        }
      };
    case 'GET_PRODUCT_GROUPS_SUCCESS':
      return {
        ...state,
        productGroups: {
          data: action.data,
          isLoading: false,
          error: null
        }
      };
    case 'GET_PRODUCT_GROUPS_ERROR':
      return {
        ...state,
        productGroups: {
          ...state.productGroups,
          isLoading: false,
          error: action.error
        }
      };
    case 'GET_PRODUCTS':
      return {
        ...state,
        products: {
          ...initialState.products,
          isLoading: true
        },
        planning: {
          ...initialState.planning,
          startDate: state.planning.startDate,
          endDate: state.planning.endDate,
          minStartDate: state.planning.minStartDate,
          maxStartDate: state.planning.maxStartDate
        },
        missingFields: initialState.missingFields
      };
    case 'GET_PRODUCTS_SUCCESS':
      return {
        ...state,
        products: {
          data: action.data,
          isLoading: false,
          error: null
        }
      };
    case 'GET_PRODUCTS_ERROR':
      return {
        ...state,
        products: {
          ...state.products,
          isLoading: false,
          error: action.error
        }
      };
    case 'BLUR_PRODUCT_OPTIONS':
      return {
        ...state,
        planning: {
          ...initialState.planning,
          startDate: formatDate(
            getCurrentDate(null, state.propertyGeo.timezone_code),
            'YYYY-MM-DD'
          ),
          endDate: formatDate(
            addToDate(getCurrentDate(null, state.propertyGeo.timezone_code), 7, 'days'),
            'YYYY-MM-DD'
          ),
          minStartDate: formatDate(
            getCurrentDate(null, state.propertyGeo.timezone_code),
            'YYYY-MM-DD'
          ),
          maxStartDate: formatDate(
            addToDate(getCurrentDate(null, state.propertyGeo.timezone_code), 3, 'months'),
            'YYYY-MM-DD'
          )
        }
      };
    case 'GET_PRODUCT_OPTIONS':
      return {
        ...state,
        productOptions: {
          ...initialState.productOptions,
          isLoading: true
        },
        planning: {
          ...initialState.planning,
          startDate: state.planning.startDate,
          endDate: state.planning.endDate,
          minStartDate: state.planning.minStartDate,
          maxStartDate: state.planning.maxStartDate
        }
      };
    case 'GET_PRODUCT_OPTIONS_SUCCESS':
      return {
        ...state,
        productOptions: {
          data: action.data,
          isLoading: false,
          error: null
        }
      };
    case 'GET_PRODUCT_OPTIONS_ERROR':
      return {
        ...state,
        productOptions: {
          ...state.productOptions,
          isLoading: false,
          error: action.error
        }
      };
    case 'GET_PRODUCT_SLOTS':
      return {
        ...state,
        planning: {
          ...initialState.planning,
          isLoading: true
        }
      };
    case 'GET_PRODUCT_SLOTS_SUCCESS':
      return {
        ...state,
        planning: {
          ...state.planning,
          slots: action.slots,
          isLoading: false,
          error: null
        }
      };
    case 'GET_PRODUCT_SLOTS_ERROR':
      return {
        ...state,
        planning: {
          ...state.planning,
          slots: null,
          isLoading: false,
          error: action.error
        }
      };
    case 'GET_PREVIOUS_PRODUCT_SLOTS':
      return {
        ...state,
        planning: {
          ...state.planning,
          slots: null,
          isWeekLoading: true
        }
      };
    case 'GET_PREVIOUS_PRODUCT_SLOTS_SUCCESS':
      return {
        ...state,
        planning: {
          ...state.planning,
          slots: action.slots,
          isWeekLoading: false,
          error: null
        }
      };
    case 'GET_PREVIOUS_PRODUCT_SLOTS_ERROR':
      return {
        ...state,
        planning: {
          ...state.planning,
          slots: null,
          isWeekLoading: false,
          error: action.error
        }
      };
    case 'GET_NEXT_PRODUCT_SLOTS':
      return {
        ...state,
        planning: {
          ...state.planning,
          slots: null,
          isWeekLoading: true
        }
      };
    case 'GET_NEXT_PRODUCT_SLOTS_SUCCESS':
      return {
        ...state,
        planning: {
          ...state.planning,
          slots: action.slots,
          isWeekLoading: false,
          error: null
        }
      };
    case 'GET_NEXT_PRODUCT_SLOTS_ERROR':
      return {
        ...state,
        planning: {
          ...state.planning,
          slots: null,
          isWeekLoading: false,
          error: action.error
        }
      };
    case 'CREATE_CALENDAR':
      return {
        ...state,
        planning: {
          ...state.planning,
          isLoading: false,
          calendar: action.payload.calendar,
          startDate: action.payload.startDate
            ? action.payload.startDate
            : initialState.planning.startDate,
          endDate: action.payload.endDate ? action.payload.endDate : initialState.planning.endDate
        }
      };
    case 'ADD_PRODUCT_TO_CART':
      return {
        ...state,
        productToCart: {
          isLoading: true,
          error: null
        }
      };
    case 'ADD_PRODUCT_TO_CART_SUCCESS':
      return {
        ...state,
        products: initialState.products,
        productOptions: initialState.productOptions,
        planning: initialState.planning,
        productToCart: {
          isLoading: false,
          error: null
        }
      };
    case 'ADD_PRODUCT_TO_CART_ERROR':
      return {
        ...state,
        productToCart: {
          isLoading: false,
          error: action.error
        }
      };
    case 'SET_NB_TIME_MAX':
      return {
        ...state,
        planning: {
          ...state.planning,
          nbTimeMax: action.number
        }
      };
    case 'SET_MISSING_FIELDS':
      return {
        ...state,
        missingFields: {
          ...initialState.missingFields,
          data: action.fields
        },
        products: {
          ...state.products,
          isLoading: false
        }
      };
    case 'POST_MISSING_FIELDS':
      return {
        ...state,
        missingFields: {
          ...state.missingFields,
          isLoading: true,
          error: null
        }
      };
    case 'POST_MISSING_FIELDS_SUCCESS':
      return {
        ...state,
        missingFields: initialState.missingFields
      };
    case 'POST_MISSING_FIELDS_ERROR':
      return {
        ...state,
        missingFields: {
          ...state.missingFields,
          isLoading: false,
          error: action.error
        }
      };
    case 'CANCEL_VALIDATING_FIELDS': {
      return {
        ...state,
        validatingFields: initialState.validatingFields,
        productToCart: initialState.productToCart,
        productOptions: initialState.productOptions,
        planning: initialState.planning
      };
    }
    case 'SET_VALIDATING_FIELDS':
      return {
        ...state,
        validatingFields: {
          ...initialState.validatingFields,
          data: action.fields
        },
        productToCart: initialState.productToCart
      };
    case 'POST_VALIDATING_FIELDS':
      return {
        ...state,
        validatingFields: {
          ...state.validatingFields,
          isLoading: true,
          error: null
        }
      };
    case 'POST_VALIDATING_FIELDS_SUCCESS':
      return {
        ...state,
        validatingFields: initialState.validatingFields
      };
    case 'POST_VALIDATING_FIELDS_ERROR':
      return {
        ...state,
        validatingFields: {
          ...state.validatingFields,
          isLoading: false,
          error: action.error
        }
      };
    case 'ADD_DISCOUNT_TO_CART':
      return {
        ...state,
        discountToCart: {
          ...state.discountToCart,
          isLoading: true,
          error: null
        }
      };
    case 'ADD_DISCOUNT_TO_CART_SUCCESS':
      return {
        ...state,
        discountToCart: initialState.discountToCart
      };
    case 'ADD_DISCOUNT_TO_CART_ERROR':
      return {
        ...state,
        discountToCart: {
          ...state.discountToCart,
          isLoading: false,
          error: action.error
        }
      };
    case 'REMOVE_DISCOUNT_TO_CART':
      return {
        ...state,
        discountToCart: {
          ...state.discountToCart,
          isLoading: true,
          error: null
        }
      };
    case 'REMOVE_DISCOUNT_TO_CART_SUCCESS':
      return {
        ...state,
        discountToCart: initialState.discountToCart
      };
    case 'REMOVE_DISCOUNT_TO_CART_ERROR':
      return {
        ...state,
        discountToCart: {
          ...state.discountToCart,
          isLoading: false,
          error: action.error
        }
      };
    case 'SELECT_CLIENT_INVOICE_ADDRESS':
      return {
        ...state,
        addresses: {
          ...state.addresses,
          selected: action.selected
        }
      };
    case 'SELECT_CLIENT_PAYMENT_METHOD':
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          selected: action.selected
        }
      };
    case 'CREATE_ORDER':
      return {
        ...state,
        orderCreate: {
          data: null,
          isLoading: true,
          error: null
        }
      };
    case 'CREATE_ORDER_SUCCESS':
      return {
        ...initialState,
        orderCreate: {
          data: action.data,
          isLoading: false,
          error: null
        }
      };
    case 'CREATE_ORDER_ERROR':
      return {
        ...state,
        orderCreate: {
          ...state.orderCreate,
          isLoading: false,
          error: action.error
        }
      };
    case 'TOGGLE_PAYMENT_DELEGATE':
      return {
        ...state,
        paymentDelegate: {
          ...initialState.paymentDelegate,
          open: !state.paymentDelegate.open
        }
      };
    case 'CLOSE_PAYMENT_DELEGATE':
      return {
        ...state,
        paymentDelegate: initialState.paymentDelegate
      };
    default:
      return state;
  }
};
