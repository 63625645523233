import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, IconButton } from '@material-ui/core';
import { Flag } from '@material-ui/icons';

import translation from '../../../../../../translation/translation';

const useStyles = makeStyles(() => ({
  1: {
    color: '#b8c4d2'
  },
  2: {
    color: '#ffce42'
  },
  3: {
    color: '#ff4842'
  },
  readOnly: {
    pointerEvents: 'none'
  }
}));

function PriorityFlag(props) {
  const classes = useStyles();

  const { className, disabled, loading, onChange, onClick, value } = props;

  function handleClick(e) {
    if (onClick) {
      onClick(e);
    }

    if (onChange && value) {
      onChange(value);
    }
  }

  const labels = {
    normal: translation().views.task_views.tasks.labels.priority_normal,
    high: translation().views.task_views.tasks.labels.priority_high,
    urgent: translation().views.task_views.tasks.labels.priority_urgent
  };

  return (
    <IconButton
      className={classNames(classes[value], className, {
        [classes.readOnly]: !onClick && !onChange
      })}
      label={labels[value]}
      onClick={handleClick}
      disabled={disabled || loading}>
      {loading ? <CircularProgress size={18} /> : <Flag />}
    </IconButton>
  );
}

PriorityFlag.defaultProps = {
  disabled: false,
  loading: false
};

PriorityFlag.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  value: PropTypes.number
};

export default PriorityFlag;
