import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

import Alert from '../../../../components/alert/Alert';
import FormApiAutocompleteField from '../../../../components/forms/fields/FormApiAutocompleteField';
import FormSelectField from '../../../../components/forms/fields/FormSelectField';
import GooglePlaceField from '../../../../components/forms/fields/customs/GooglePlaceField';
import SizeInput from '../../components/fields/SizeInput';

import { convertUnitSystem } from '../../../../utils/functions/utils';

import translation from '../../../../translation/translation';
import config from '../../../../config';

const useStyles = makeStyles(() => ({
  cancelButton: {
    color: config.error_color || '#F44336',
    '&:hover': {
      backgroundColor: fade(config.error_color || '#F44336', 0.15)
    }
  },
  title: {
    textAlign: 'center',
    fontSize: 18
  }
}));

function PropertyStep(props) {
  const {
    cart,
    deleteClientCart,
    getClientCart,
    getClientPaymentMethods,
    paymentMethods,
    propertyForm,
    storePropertyLocation,
    storeClient,
    storeProperty,
    storePropertyValue,
    user
  } = props;

  const classes = useStyles();
  const [address, setAddress] = useState('');
  const [size, setSize] = useState('');
  const [type, setType] = useState('');
  const [isAddressFailed, setIsAddressFailed] = useState(null);
  const [isPaymentOk, setIsPaymentOk] = useState(true);
  const [isPropertyDialogOpen, setIsPropertyDialogOpen] = useState(false);

  useEffect(() => {
    if (propertyForm) {
      if (propertyForm.address) setAddress(propertyForm.address);

      if (propertyForm.size) setSize(propertyForm.size);

      if (propertyForm.property_type || propertyForm.type)
        setType(propertyForm.property_type || propertyForm.type);
    }

    /**
     * Check cart property object and compare values, trigger modal for reset cart if values are differents
     */
    if (cart && cart.data && cart.data[0] && cart.data[0].property_object) {
      if (cart.data[0].property_object.address && propertyForm.address) {
        if (cart.data[0].property_object.address !== propertyForm.address) {
          setIsPropertyDialogOpen(true);
        }
      }

      if (cart.data[0].property_object.type && propertyForm.property_type) {
        if (cart.data[0].property_object.type !== propertyForm.property_type) {
          setIsPropertyDialogOpen(true);
        }
      }

      if (cart.data[0].property_object.size && propertyForm.size) {
        if (cart.data[0].property_object.size !== parseFloat(propertyForm.size)) {
          setIsPropertyDialogOpen(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyForm]);

  function handleClosePropertyDialog() {
    setIsPropertyDialogOpen(false);
  }

  function handleChangeClient(user) {
    storeClient(user);
    getClientCart(user);

    getClientPaymentMethods(user, (data) => {
      if (data && data.length <= 0) setIsPaymentOk(false);
      else if (!data) setIsPaymentOk(false);
      else setIsPaymentOk(true);
    });

    if (user) getClientCart(user);
  }

  function handleChangeSize(value) {
    setSize(value);
    storePropertyValue('size', value);
  }

  function handleChangeType(value) {
    setType(value);
    storePropertyValue('property_type', value);
  }

  function handleChangeAddress(value) {
    setAddress(value);
    storePropertyValue('address', value);
    storePropertyLocation(null);
  }

  function handleSelectAddress(value, suggestion, geo) {
    if (geo && geo.location) {
      storePropertyLocation({
        lat: geo.location.lat(),
        lng: geo.location.lng()
      });
    }

    if (value) {
      if (!value.postal_code || !value.locality || !value.route) {
        setIsAddressFailed(true);
        setAddress('');
      } else {
        setIsAddressFailed(false);
        setAddress(suggestion.description);
        storePropertyValue('address', suggestion.description);
      }
    } else {
      setIsAddressFailed(true);
      setAddress('');
    }
  }

  function handleDeleteCart() {
    if (cart && cart.data && cart.data[0] && cart.data[0].id) {
      deleteClientCart(cart.data[0].id, () => {
        if (user) getClientCart(user);

        storeProperty({ address, size, type });
        handleClosePropertyDialog();
      });
    }
  }

  function handleCancelDeleteCart() {
    if (cart && cart.data && cart.data[0] && cart.data[0].property_object) {
      storeProperty(cart.data[0].property_object);
      handleClosePropertyDialog();
    }
  }

  return (
    <div className="PropertyStep">
      <Typography variant="h5" gutterBottom className={classes.title}>
        {translation().views.orders.create.informations.title}
      </Typography>
      <FormApiAutocompleteField
        autoFocus
        name="user"
        label={translation().views.orders.create.informations.client}
        links={[{ href: 'users', rel: 'list' }]}
        getFullObject
        autoComplete="off"
        defaultValue={user}
        onSelectValue={handleChangeClient}
        required
        fullWidth
      />
      {paymentMethods.isLoading && (
        <div className="loader-wpr">
          <CircularProgress size={25} color="secondary" />
        </div>
      )}
      {!isPaymentOk && !paymentMethods.isLoading && (
        <Alert
          type="warning"
          style={{ marginTop: 10 }}
          status={translation().commons.alerts.warning}
          text={translation().views.orders.create.informations.warning_client}
        />
      )}
      <GooglePlaceField
        type="text"
        name="address"
        label={translation().views.orders.create.informations.address}
        isResultMapped
        onChange={handleChangeAddress}
        onSelectAddress={handleSelectAddress}
        value={address}
        required
        autoComplete="off"
        error={isAddressFailed ? translation().views.orders.create.informations.error_property : ''}
      />
      <SizeInput
        id="property_size"
        name="size"
        label={translation().views.orders.create.informations.size}
        onChange={handleChangeSize}
        value={size}
        required
        autoComplete="off"
        adornment={convertUnitSystem(user && user.unit_system ? user.unit_system : null)}
      />
      <FormSelectField
        id="property_type"
        name="property_type"
        label={translation().views.orders.create.informations.type}
        onChange={handleChangeType}
        value={type}>
        <MenuItem value={translation().views.orders.create.informations.types.apartment}>
          {translation().views.orders.create.informations.types.apartment}
        </MenuItem>
        <MenuItem value={translation().views.orders.create.informations.types.house}>
          {translation().views.orders.create.informations.types.house}
        </MenuItem>
        <MenuItem value={translation().views.orders.create.informations.types.office}>
          {translation().views.orders.create.informations.types.office}
        </MenuItem>
        <MenuItem value={translation().views.orders.create.informations.types.other}>
          {translation().views.orders.create.informations.types.other}
        </MenuItem>
      </FormSelectField>
      <Dialog
        open={isPropertyDialogOpen}
        onClose={handleCancelDeleteCart}
        aria-labelledby="property-changed-dialog-title"
        aria-describedby="property-changed-dialog-description"
        disableBackdropClick={cart.isLoading}>
        <DialogTitle id="property-changed-dialog-title">
          {translation().views.orders.create.informations.dialogs.property_changed.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="property-changed-dialog-description">
            {translation().views.orders.create.informations.dialogs.property_changed.description}
          </DialogContentText>
          {cart.isLoading && (
            <div className="loader-wpr" style={{ marginTop: 15 }}>
              <CircularProgress size={30} color="secondary" />
              <p>{translation().core.list.dialogs.delete.load_delete}</p>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDeleteCart} color="default" disabled={cart.isLoading}>
            {translation().actions.cancel}
          </Button>
          <Button
            onClick={handleDeleteCart}
            disabled={cart.isLoading}
            className={classes.cancelButton}>
            {translation().actions.delete}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

PropertyStep.propTypes = {
  cart: PropTypes.shape().isRequired,
  deleteClientCart: PropTypes.func.isRequired,
  getClientCart: PropTypes.func.isRequired,
  getClientPaymentMethods: PropTypes.func.isRequired,
  storePropertyLocation: PropTypes.func.isRequired,
  storeClient: PropTypes.func.isRequired,
  storeProperty: PropTypes.func.isRequired,
  storePropertyValue: PropTypes.func.isRequired,
  paymentMethods: PropTypes.shape().isRequired,
  propertyForm: PropTypes.shape().isRequired,
  user: PropTypes.shape()
};

export default PropertyStep;
