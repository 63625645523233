import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchApi } from '../../utils/functions/api';
import { enqueueSnackbar } from '../../redux/actions/appActions';
import translation from '../../translation/translation';
import ProvidersSlotsCheck from '../../views/providers/providersSlotsCheck/ProvidersSlotsCheck';

const mapDispatchToProps = (dispatch) => {
  return {
    getProducts: (next, callbackSuccess = null, callbackError = null) => {
      fetchApi(
        'get',
        next ? next : 'products',
        {
          filters: 'require_planning|eq|1,active|eq|1',
          sort: '-id'
        },
        null,
        null,
        (success, paging) => {
          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success, paging);
          }
        },
        (error) => {
          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.callbacks.error_list,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().core.list.callbacks.error_list,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError(error);
          }
        }
      );
    },
    getProductsSlots: (id, address, callbackSuccess = null, callbackError = null) => {
      if (!id || !address) return;

      fetchApi(
        'get',
        `products/orderable/${id}/slots`,
        {
          property: {
            nb_rooms: 2,
            temporary_picture: 'https://www.google.fr/',
            temporary_floorplan: 'https://www.google.fr/',
            electrical_installation: '+ de 15 ans',
            property_type: 'Apartment',
            construction_year_range: "I don't know",
            gas_installation: "I don't know",
            trade_type: 'Vente',
            size: 100,
            address
          }
        },
        null,
        null,
        (success) => {
          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success);
          }
        },
        (error) => {
          if (callbackError && typeof callbackError === 'function') {
            callbackError(error);
          }
        }
      );
    }
  };
};

export default withRouter(connect(null, mapDispatchToProps)(ProvidersSlotsCheck));
