import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchApi } from '../../../utils/functions/api';
import { formatFiltersValuesBeforePost } from '../../../utils/functions/fitlers';
import { enqueueSnackbar } from '../../../redux/actions/appActions';
import {
  getMetadata,
  getMetadataSuccess,
  getMetadataError
} from '../../../redux/actions/core/metadata/metadataActions';
import {
  clearOwnReducer,
  getData,
  getDataSuccess,
  getDataError,
  exportListData,
  exportListDataSuccess,
  exportListDataError,
  refreshData,
  setDataName,
  setDataSearchValue,
  deleteResourceItem,
  deleteResourceItemSuccess,
  deleteResourceItemError,
  unsetListNeedReload
} from '../../../redux/actions/core/list/listActions';
import { clearFilters, setFiltersQueryString } from '../../../redux/actions/filters/filtersActions';
import ListView from '../../../views/core/list/ListView';
import translation from '../../../translation/translation';
import store from '../../../redux/store';

const mapStateToProps = (store) => ({
  deleteItem: store.core.deleteItem,
  list: store.core.list,
  metadata: store.core.metadata,
  filters: store.filters
});

const mapDispatchToProps = (dispatch) => {
  return {
    clearFilters() {
      dispatch(clearFilters());
    },
    clearOwnReducer() {
      dispatch(clearOwnReducer());
    },
    setDataName(name) {
      dispatch(setDataName(name));
    },
    setDataSearchValue(value) {
      dispatch(setDataSearchValue(value));
    },
    unsetListNeedReload() {
      dispatch(unsetListNeedReload());
    },
    async getMetadata(uri, sourceToken = null) {
      if (!uri) return;

      dispatch(getMetadata());

      try {
        await fetchApi(
          'get',
          `${uri}/metadata`,
          null,
          null,
          null,
          (metadata) => {
            dispatch(getMetadataSuccess(metadata));
          },
          (error) => {
            dispatch(getMetadataError(true, error));

            if (error.response) {
              dispatch(
                enqueueSnackbar({
                  message:
                    error.response.data && error.response.data.detail
                      ? error.response.data.detail
                      : error.response.data.status_code
                      ? error.response.data.status_code + ': ' + translation().core.item.not_found
                      : translation().core.list.error_metadata,
                  status: error.response.status || undefined,
                  options: {
                    variant: 'error'
                  }
                })
              );
            } else {
              dispatch(
                enqueueSnackbar({
                  message: translation().core.list.error_metadata,
                  options: {
                    variant: 'error'
                  }
                })
              );
            }
          },
          null,
          sourceToken
        );
      } catch (error) {
        dispatch(getMetadataError(true, error));
        dispatch(
          enqueueSnackbar({
            message: translation().core.list.error_metadata,
            options: {
              variant: 'error'
            }
          })
        );
      }
    },
    async getData(uri, sourceToken = null, search = null, next = null, isRefresh = false) {
      if (!uri) return;

      if (isRefresh) dispatch(refreshData());
      else dispatch(getData(search, next));

      try {
        await fetchApi(
          'get',
          next ? next : `${uri}`,
          !next && search ? { search } : {},
          null,
          null,
          (data, paging) => {
            dispatch(getDataSuccess(data, paging));
          },
          (error) => {
            dispatch(getDataError(true, error));
          },
          null,
          sourceToken
        );
      } catch (error) {
        dispatch(getDataError(true, error));
      }
    },
    async deleteResourceItem(uri, id, callbackSuccess, sourceToken = null) {
      if (!uri || !id) return;

      dispatch(deleteResourceItem());

      try {
        await fetchApi(
          'delete',
          `${uri}/${id}`,
          null,
          null,
          null,
          () => {
            dispatch(deleteResourceItemSuccess());

            if (callbackSuccess && typeof callbackSuccess === 'function') callbackSuccess();
          },
          (error) => {
            dispatch(deleteResourceItemError(true, error));

            if (error.response) {
              dispatch(
                enqueueSnackbar({
                  message:
                    error.response.data && error.response.data.detail
                      ? error.response.data.detail
                      : translation().core.list.callbacks.delete.error,
                  status: error.response.status || undefined,
                  options: {
                    variant: 'error'
                  }
                })
              );
            } else {
              dispatch(
                enqueueSnackbar({
                  message: translation().core.list.callbacks.delete.error,
                  options: {
                    variant: 'error'
                  }
                })
              );
            }
          },
          null,
          sourceToken
        );
      } catch (error) {
        dispatch(deleteResourceItemError(true, error));
        dispatch(
          enqueueSnackbar({
            message: translation().core.list.callbacks.delete.error,
            options: {
              variant: 'error'
            }
          })
        );
      }
    },
    async filterData(uri, metadata) {
      if (!uri) {
        return;
      }

      const filtersValues = store.getState().filters.values;
      let query = { filters: '' };

      dispatch(getData(true));

      for (let value in filtersValues) {
        /**
         * Check if operator in range is set (for timestamp type for example)
         */
        if (
          filtersValues[value] &&
          filtersValues[`${value}.start`] &&
          filtersValues[`${value}.end`] &&
          filtersValues[`${value}.start`].value &&
          filtersValues[`${value}.end`].value &&
          filtersValues[value].operator &&
          filtersValues[value].operator === 'range'
        ) {
          const rangeStart = { value: filtersValues[`${value}.start`].value };
          const rangeEnd = { value: filtersValues[`${value}.end`].value };

          query.filters += `${value}|gte|${formatFiltersValuesBeforePost(
            metadata,
            value,
            rangeStart
          )},`;
          query.filters += `${value}|lte|${formatFiltersValuesBeforePost(
            metadata,
            value,
            rangeEnd
          )},`;
        } else if (
          filtersValues[value] &&
          filtersValues[value].operator &&
          (filtersValues[value].operator === 'nu' || filtersValues[value].operator === 'nnu')
        ) {
          query.filters += `${value}|${filtersValues[value].operator}|,`;
        } else if (
          filtersValues[value] &&
          filtersValues[value].value &&
          !value.includes('.start') &&
          !value.includes('.end')
        ) {
          query.filters += `${value}|${
            filtersValues[value].operator ? filtersValues[value].operator : 'eq'
          }|${formatFiltersValuesBeforePost(metadata, value, filtersValues[value])},`;
        }
      }

      dispatch(setFiltersQueryString(query.filters));

      try {
        await fetchApi(
          'get',
          `${uri}`,
          query,
          null,
          null,
          (data, paging) => {
            dispatch(getDataSuccess(data, paging));
          },
          (error) => {
            dispatch(getDataError(true, error));
          }
        );
      } catch (error) {
        dispatch(getDataError(true, error));
      }
    },
    async exportData(uri, query) {
      if (!uri) {
        return;
      }

      dispatch(exportListData(true));

      try {
        await fetchApi(
          'post',
          `${uri}/export`,
          query,
          null,
          null,
          (success) => {
            dispatch(exportListDataSuccess());
            window.open(success);
          },
          (error) => {
            dispatch(exportListDataError());

            if (error.response) {
              dispatch(
                enqueueSnackbar({
                  message:
                    error.response.data && error.response.data.detail
                      ? error.response.data.detail
                      : translation().core.exportList.callback_error,
                  status: error.response.status || undefined,
                  options: {
                    variant: 'error'
                  }
                })
              );
            } else {
              dispatch(
                enqueueSnackbar({
                  message: translation().core.exportList.callback_error,
                  options: {
                    variant: 'error'
                  }
                })
              );
            }
          }
        );
      } catch (error) {
        dispatch(exportListDataError());
      }
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListView));
