import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchApi } from '../../../utils/functions/api';
import { formatErrors, formatValuesBeforePost } from '../../../utils/functions/forms';
import { enqueueSnackbar } from '../../../redux/actions/appActions';
import {
  cancelGetMetadata,
  getMetadata,
  getMetadataSuccess,
  getMetadataError
} from '../../../redux/actions/core/metadata/metadataActions';
import {
  createResource,
  createResourceSuccess,
  createResourceError,
  setCreateResourceTimezone
} from '../../../redux/actions/core/create/createActions';
import {
  getItem,
  getItemSuccess,
  getItemError,
  cancelGetItem
} from '../../../redux/actions/core/item/itemActions';
import { setListNeedReload } from '../../../redux/actions/core/list/listActions';
import { setFormsErrors, cleanFormsErrors } from '../../../redux/actions/forms/formsActions';
import CreateView from '../../../views/core/create/CreateView';
import translation from '../../../translation/translation';

const mapStateToProps = (store) => ({
  item: store.core.item,
  create: store.core.create,
  metadata: store.core.metadata
});

const mapDispatchToProps = (dispatch) => {
  return {
    setListNeedReload(callback) {
      dispatch(setListNeedReload(callback));
    },
    setCreateResourceTimezone(timezone) {
      dispatch(setCreateResourceTimezone(timezone));
    },
    async getItem(uri, sourceToken = null, locale) {
      dispatch(getItem());

      const query = {};

      if (locale) {
        query.locale = locale;
      }

      try {
        await fetchApi(
          'get',
          `${uri}`,
          query,
          null,
          null,
          (data) => {
            dispatch(getItemSuccess(data));
          },
          (error) => {
            if (sourceToken) dispatch(cancelGetItem());

            dispatch(getItemError(true, error));

            if (error.response) {
              dispatch(
                enqueueSnackbar({
                  message:
                    error.response.data && error.response.data.detail
                      ? error.response.data.detail
                      : error.response.data.status_code
                      ? error.response.data.status_code + ': ' + translation().core.item.not_found
                      : translation().core.list.error_list,
                  status: error.response.status || undefined,
                  options: {
                    variant: 'error'
                  }
                })
              );
            } else {
              dispatch(
                enqueueSnackbar({
                  message: translation().core.list.error_list,
                  options: {
                    variant: 'error'
                  }
                })
              );
            }
          },
          null,
          sourceToken
        );
      } catch (error) {
        dispatch(getItemError(true, error));
      }
    },
    async getMetadata(uri, sourceToken = null) {
      if (!uri) return;

      dispatch(getMetadata());

      try {
        await fetchApi(
          'get',
          `${uri}/metadata`,
          null,
          null,
          null,
          (metadata) => {
            dispatch(getMetadataSuccess(metadata));
          },
          (error) => {
            if (sourceToken) {
              dispatch(cancelGetMetadata());
            } else {
              dispatch(getMetadataError(true, error));
            }
          },
          null,
          sourceToken
        );
      } catch (error) {
        dispatch(getMetadataError(true, error));
      }
    },
    async createResource(uri, data, metadata, callbackSuccess) {
      if (!uri || !data || !metadata) return;

      dispatch(createResource());
      dispatch(cleanFormsErrors('createForm'));

      const dataFormatted = formatValuesBeforePost(metadata, data);

      try {
        await fetchApi(
          'post',
          `${uri}`,
          null,
          dataFormatted,
          null,
          (success) => {
            dispatch(createResourceSuccess(success));
            dispatch(
              enqueueSnackbar({
                message: translation().core.create.callbacks.success,
                options: {
                  variant: 'success'
                }
              })
            );

            if (callbackSuccess && typeof callbackSuccess === 'function') {
              callbackSuccess(success);
            }
          },
          (error) => {
            dispatch(createResourceError(true, error));

            if (error.response) {
              if (
                error.response.data &&
                error.response.data.errors &&
                error.response.data.errors.length > 0
              ) {
                dispatch(setFormsErrors('createForm', formatErrors(error.response.data.errors)));
              }

              dispatch(
                enqueueSnackbar({
                  message:
                    error.response.data && error.response.data.detail
                      ? error.response.data.detail
                      : translation().core.create.callbacks.error,
                  status: error.response.status || undefined,
                  options: {
                    variant: 'error'
                  }
                })
              );
            } else {
              dispatch(
                enqueueSnackbar({
                  message: translation().core.create.callbacks.error,
                  options: {
                    variant: 'error'
                  }
                })
              );
            }
          }
        );
      } catch (error) {
        dispatch(createResourceError(true, error));
        dispatch(
          enqueueSnackbar({
            message: translation().core.create.callbacks.error,
            options: {
              variant: 'error'
            }
          })
        );
      }
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateView));
