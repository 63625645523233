import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import {
  Box,
  Chip,
  CircularProgress,
  ClickAwayListener,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import { Warning } from '@material-ui/icons';

import {
  dateInTz,
  createDate,
  createDateUtc,
  getCurrentDateUtc,
  formatDate,
  toUtcTimestamp
} from '../../../../../../utils/functions/dates';
import { FormApiAutocompleteField } from '../../../../../../components/forms/fields';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 6,
    borderWidth: 1,
    transition: '200ms ease-in',
    willChange: 'border',
    borderColor: 'transparent',
    borderStyle: 'solid',
    width: '100%',
    minHeight: 28,
    borderRadius: 4
  },
  rootDate: {
    minWidth: 105
  },
  input: {
    padding: 12,
    fontSize: 12,
    minWidth: 50,
    '&.MuiOutlinedInput-inputMultiline': {
      padding: 0
    }
  },
  output: {
    fontSize: 12
  },
  formControl: {
    minWidth: 120
  },
  overdue: {
    color: theme.palette.error.main,
    display: 'flex',
    alignItems: 'center'
  },
  writable: {
    cursor: 'pointer',
    '&:not(.no-hover):hover': {
      borderColor: grey[300]
    }
  },
  completionStatus: {
    position: 'absolute',
    top: 0,
    left: 0,
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    willChange: 'color',
    width: '100%',
    height: '100%',
    border: 0,
    borderRadius: 0,
    '&:not(.no-hover):hover': {
      opacity: '0.65'
    }
  },
  statusMenuItem: {
    color: 'white',
    '&:not(.no-hover):hover': {
      opacity: '0.65'
    }
  },
  team: {
    padding: 0,
    minHeight: 'inherit',
    borderRadius: 0,
    border: 0
  }
}));

function InputBox(props) {
  const classes = useStyles();

  const {
    boxClassName,
    boxComponent,
    className,
    getFullObject,
    entityHref,
    fullWidth,
    id,
    name,
    onChange,
    outputClassName,
    placeholder,
    referenceKey,
    selectData,
    type,
    targetKey,
    value,
    write
  } = props;

  const [loading, setLoading] = useState(false);
  const [innerValue, setInnerValue] = useState(value);
  const [open, setOpen] = useState(false);

  function handleSendValue(newValue) {
    /**
     * Check if value changed, if not return;
     */
    if (innerValue === value && !newValue) return;

    /**
     * Check if type = entity and value exists. If not return;
     */
    const isBlurIssue = type === 'select' || type === 'entity';
    if (targetKey && isBlurIssue && !newValue) return;

    /**
     * Check if type = entity and value changed. If not return;
     */
    if (
      targetKey &&
      newValue &&
      innerValue &&
      innerValue[targetKey] &&
      innerValue[targetKey] === newValue[targetKey]
    )
      return;

    /**
     * Check if type = entity and value changed. If not return;
     */
    if (
      targetKey &&
      newValue &&
      innerValue &&
      innerValue[targetKey] &&
      innerValue[targetKey] === newValue[targetKey]
    )
      return;

    let data = newValue || innerValue;

    setLoading(true);

    if (type === 'datetime-local') {
      const dateTZ = dateInTz(innerValue);
      const dateUTC = createDateUtc(dateTZ).toString();

      data = formatDate(dateUTC, 'YYYY-MM-DDTHH:mm:ss');
    }

    onChange(
      id,
      { [name]: data },
      (success) => {
        setLoading(false);
        setInnerValue(
          targetKey && !isBlurIssue
            ? success[referenceKey ? referenceKey : name][targetKey]
            : success[referenceKey ? referenceKey : name]
        );
      },
      () => {
        setLoading(false);
        setInnerValue(value);
      }
    );
  }

  function handleClick() {
    if (name !== 'id' && write) {
      setOpen(true);
    }
  }

  function handleBlur() {
    setOpen(false);
    handleSendValue();
  }

  function handleSubmit(e) {
    e.preventDefault();
    handleBlur();
  }

  function handleChange(e) {
    setInnerValue(e.target.value);
  }

  function handleChangeSelectStatus(e) {
    setInnerValue(e.target.value);
    setOpen(false);
    handleSendValue(e.target.value);
  }

  function handleChangeEntity(val) {
    setInnerValue(val);
    setOpen(false);
    handleSendValue(targetKey && val[targetKey] ? val[targetKey] : val);
  }

  function handleMouseWheel(event) {
    event.target.blur();
  }

  function renderOverdueIcon(expected) {
    const now = getCurrentDateUtc();

    if (toUtcTimestamp(expected) < toUtcTimestamp(now)) {
      return (
        <Warning
          style={{ marginLeft: 4, marginTop: -1, verticalAlign: 'middle' }}
          color="error"
          fontSize="small"
        />
      );
    } else {
      return false;
    }
  }

  function renderValue() {
    if (type === 'datetime-local') {
      if (name === 'due') {
        return innerValue ? (
          <div
            className={classNames(classes.rootDate, {
              [classes.overdue]: Boolean(renderOverdueIcon(innerValue))
            })}>
            {dateInTz(innerValue, 'D MMM HH:mm')}
            {renderOverdueIcon(innerValue)}
          </div>
        ) : (
          '-'
        );
      } else {
        return innerValue ? dateInTz(innerValue, 'D MMM HH:mm') : '-';
      }
    } else if (type === 'text') {
      if (name === 'label') {
        return innerValue ? (
          <span style={{ fontWeight: 500, fontSize: 12 }}>{innerValue}</span>
        ) : (
          '-'
        );
      } else {
        return innerValue ? innerValue : '-';
      }
    } else if (type === 'textarea') {
      return innerValue && innerValue.length > 0
        ? innerValue.split('\n').map((item, key) => (
            <span key={key}>
              {item}
              <br />
            </span>
          ))
        : '-';
    } else if (type === 'entity') {
      if (name === 'assigned_iam_user_id') {
        return innerValue && innerValue.first_name && innerValue.last_name
          ? `${innerValue.first_name} ${innerValue.last_name}`
          : innerValue && innerValue.first_name
          ? innerValue.first_name
          : '-';
      } else {
        return innerValue && innerValue.name
          ? innerValue.name
          : innerValue && innerValue.id
          ? innerValue.id
          : '-';
      }
    } else if (type === 'select') {
      if (name === 'completion_status_id') {
        return innerValue && innerValue.name ? innerValue.name : '-';
      } else if (name === 'assigned_iam_team_id') {
        return innerValue && innerValue.name ? (
          <Chip label={innerValue.name} variant="outlined" clickable={write} />
        ) : (
          '-'
        );
      } else {
        return false;
      }
    } else {
      return innerValue && innerValue.name
        ? innerValue.name
        : innerValue && innerValue.id
        ? innerValue.id
        : innerValue
        ? innerValue
        : '-';
    }
  }

  if (open && write) {
    if (type === 'textarea') {
      return (
        <form onSubmit={handleSubmit}>
          <TextField
            type="text"
            multiline
            rows={5}
            onBlur={handleBlur}
            onChange={handleChange}
            fullWidth={fullWidth}
            autoFocus
            variant="outlined"
            value={innerValue}
            placeholder={placeholder}
            inputProps={{
              className: classes.input
            }}
          />
        </form>
      );
    } else if (type === 'datetime-local') {
      const dateValue = createDate(innerValue).toString();
      const dateFormated = formatDate(dateValue, 'YYYY-MM-DDTHH:mm').toString();

      return (
        <form onSubmit={handleSubmit}>
          <TextField
            type="datetime-local"
            onBlur={handleBlur}
            onChange={handleChange}
            fullWidth={fullWidth}
            autoFocus
            variant="outlined"
            value={innerValue ? dateFormated : ''}
            placeholder={placeholder}
            inputProps={{
              className: classes.input
            }}
          />
        </form>
      );
    } else if (type === 'select') {
      if (name === 'completion_status_id') {
        return (
          <ClickAwayListener onClickAway={handleBlur} mouseEvent="onMouseDown">
            <Box
              className={classNames(classes.completionStatus, boxClassName)}
              sx={{
                bgcolor:
                  name === 'completion_status_id' && innerValue && innerValue.color
                    ? innerValue.color
                    : ''
              }}>
              <Select
                name={name}
                open={true}
                value={innerValue ? innerValue.id : ''}
                onChange={handleChangeSelectStatus}
                label={placeholder}
                onClose={handleBlur}
                style={{ opacity: 0 }}
                MenuProps={{ MenuListProps: { style: { padding: 0 } } }}>
                {selectData && selectData.length > 0 ? (
                  selectData.map((el, index) => (
                    <MenuItem
                      key={index}
                      value={el.id}
                      style={{
                        backgroundColor: el && el.color ? el.color : ''
                      }}
                      className={classes.statusMenuItem}>
                      {el.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No status found.</MenuItem>
                )}
              </Select>
            </Box>
          </ClickAwayListener>
        );
      } else if (name === 'assigned_iam_team_id') {
        return (
          <ClickAwayListener onClickAway={handleBlur} mouseEvent="onMouseDown">
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel>{placeholder}</InputLabel>
              <Select
                name={name}
                open={true}
                value={innerValue ? innerValue.id : ''}
                onChange={handleChangeSelectStatus}
                label={placeholder}
                onClose={handleBlur}>
                {selectData && selectData.length > 0 ? (
                  selectData.map((el, index) => (
                    <MenuItem key={index} value={el.id}>
                      {el.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No team found.</MenuItem>
                )}
              </Select>
            </FormControl>
          </ClickAwayListener>
        );
      } else {
        return false;
      }
    } else if (type === 'entity') {
      return (
        <FormApiAutocompleteField
          id={`input-box-${name}-autocomplete`}
          name={name}
          variant="outlined"
          margin="none"
          fullWidth
          autoFocus
          links={[
            {
              rel: 'list',
              href: entityHref
            }
          ]}
          InputProps={{
            onBlur: handleBlur,
            style: {
              padding: 8,
              height: 50
            }
          }}
          clearable={false}
          defaultValue={innerValue}
          getFullObject={getFullObject}
          onSelectValue={handleChangeEntity}
          placeholder={placeholder ? placeholder : name}
          targetKey={targetKey ? targetKey : 'id'}
          inputProps={{
            className: classNames(classes.input, classes.formControl)
          }}
        />
      );
    } else if (type === 'number') {
      return (
        <form onSubmit={handleSubmit}>
          <TextField
            type={type}
            onBlur={handleBlur}
            onChange={handleChange}
            fullWidth={fullWidth}
            placeholder={placeholder}
            onWheel={handleMouseWheel}
            autoFocus
            variant="outlined"
            value={innerValue || ''}
            inputProps={{
              className: classes.input
            }}
          />
        </form>
      );
    } else {
      return (
        <form onSubmit={handleSubmit}>
          <TextField
            type={type}
            onBlur={handleBlur}
            onChange={handleChange}
            fullWidth={fullWidth}
            placeholder={placeholder}
            autoFocus
            variant="outlined"
            value={innerValue || ''}
            inputProps={{
              className: classes.input
            }}
          />
        </form>
      );
    }
  } else {
    return (
      <Box
        component={boxComponent}
        onClick={handleClick}
        className={classNames(
          className,
          classes.root,
          {
            'no-hover': !write,
            [classes.writable]: write,
            [classes.completionStatus]: name === 'completion_status_id',
            [classes.team]: innerValue && name === 'assigned_iam_team_id'
          },
          boxClassName
        )}
        sx={{
          bgcolor:
            name === 'completion_status_id' && innerValue && innerValue.color
              ? innerValue.color
              : ''
        }}>
        {loading ? (
          <CircularProgress size={18} />
        ) : (
          <Typography component="span" className={classNames(classes.output, outputClassName)}>
            {renderValue()}
          </Typography>
        )}
      </Box>
    );
  }
}

InputBox.defaultProps = {
  boxComponent: 'div',
  getFullObject: false,
  fullWidth: true,
  loading: false,
  selectData: [],
  write: true
};

InputBox.propTypes = {
  boxComponent: PropTypes.string,
  boxClassName: PropTypes.string,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  getFullObject: PropTypes.bool,
  entityHref: PropTypes.string,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  outputClassName: PropTypes.string,
  placeholder: PropTypes.string,
  referenceKey: PropTypes.string,
  selectData: PropTypes.arrayOf(PropTypes.shape()),
  targetKey: PropTypes.string,
  type: PropTypes.string.isRequired,
  value: PropTypes.any,
  write: PropTypes.bool
};

export default InputBox;
