/**
 * MODERATION ACTIONS
 */
export const clearModeration = () => ({
  type: 'CLEAR_MODERATION'
});

export const clearOrderProduct = () => ({
  type: 'CLEAR_ORDER_PRODUCT_DATA'
});

export const getModerationData = (search, next) => ({
  type: 'GET_MODERATION_DATA',
  search,
  next
});

export const getModerationDataSuccess = (data, paging) => ({
  type: 'GET_MODERATION_DATA_SUCCESS',
  data,
  paging
});

export const getModerationDataError = (error) => ({
  type: 'GET_MODERATION_DATA_ERROR',
  error
});

export const getOrderProductData = () => ({
  type: 'GET_ORDER_PRODUCT_DATA'
});

export const getOrderProductDataSuccess = (data) => ({
  type: 'GET_ORDER_PRODUCT_DATA_SUCCESS',
  data
});

export const getOrderProductDataError = (error) => ({
  type: 'GET_ORDER_PRODUCT_DATA_ERROR',
  error
});

export const deliverProductToModerate = () => ({
  type: 'DELIVER_PRODUCT_TO_MODERATE'
});

export const deliverProductToModerateSuccess = () => ({
  type: 'DELIVER_PRODUCT_TO_MODERATE_SUCCESS'
});

export const deliverProductToModerateError = (error) => ({
  type: 'DELIVER_PRODUCT_TO_MODERATE_ERROR',
  error
});
