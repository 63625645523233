const initialState = {
  isModeCompany: false,
  data: null,
  error: null,
  isLoading: false,
  addresses: {
    data: [],
    isLoading: false,
    paging: null,
    error: null
  },
  invoices: {
    data: [],
    isLoading: false,
    paging: null,
    error: null
  },
  invoice_queue: {
    data: [],
    isLoading: false,
    paging: null,
    error: null
  },
  order_products: {
    data: [],
    metadata: {
      data: {},
      isLoading: false,
      isError: null
    },
    isLoading: false,
    paging: null,
    error: null
  },
  payment_methods: {
    data: [],
    isLoading: false,
    paging: null,
    error: null
  },
  devices: {
    data: [],
    isLoading: false,
    paging: null,
    error: null
  },
  blacklist: {
    data: [],
    isLoading: false,
    paging: null,
    error: null
  },
  ratings: {
    emitted: {
      data: [],
      isLoading: false,
      error: null
    },
    received: {
      data: [],
      isLoading: false,
      error: null
    }
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_REDUCERS':
      return initialState;
    case 'TOGGLE_PROVIDER_VIEW_MODE':
      return {
        ...state,
        isModeCompany: action.value
      };
    case 'CLEAN_PROVIDER_VIEW_INVOICES_REDUCER':
      return {
        ...state,
        invoices: initialState.invoices
      };
    case 'CLEAN_PROVIDER_VIEW_INVOICE_QUEUE_REDUCER':
      return {
        ...state,
        invoice_queue: initialState.invoice_queue
      };
    case 'CLEAN_PROVIDER_VIEW_PAYMENT_METHODS_REDUCER':
      return {
        ...state,
        payment_methods: initialState.payment_methods
      };
    case 'CLEAN_PROVIDER_VIEW_ORDER_PRODUCTS_REDUCER':
      return {
        ...state,
        order_products: initialState.order_products
      };
    case 'CLEAN_PROVIDER_VIEW_BLACKLIST_REDUCER':
      return {
        ...state,
        blacklist: initialState.blacklist
      };
    case 'CLEAN_PROVIDER_VIEW_ADDRESSES_REDUCER':
      return {
        ...state,
        addresses: initialState.addresses
      };
    case 'CLEAN_PROVIDER_VIEW_DEVICES_REDUCER':
      return {
        ...state,
        devices: initialState.devices
      };
    case 'CLEAN_PROVIDER_VIEW_RATINGS_REDUCER':
      return {
        ...state,
        ratings: initialState.ratings
      };
    case 'GET_PROVIDER_VIEW':
      return {
        ...initialState,
        isLoading: true
      };
    case 'GET_PROVIDER_VIEW_SUCCESS':
      return {
        ...state,
        data: action.data,
        error: null,
        isLoading: false
      };
    case 'GET_PROVIDER_VIEW_ERROR':
      return {
        ...state,
        error: action.error,
        isLoading: false
      };
    case 'GET_PROVIDER_VIEW_DEVICES':
      return {
        ...state,
        devices: {
          ...state.devices,
          data: action.next ? state.devices.data : initialState.devices.data,
          isLoading: true
        }
      };
    case 'GET_PROVIDER_VIEW_DEVICES_SUCCESS': {
      const devicesConcatenated = state.devices.data.concat(action.data);

      return {
        ...state,
        devices: {
          ...state.devices,
          data: devicesConcatenated,
          paging: action.paging ? action.paging : state.devices.paging,
          isLoading: false,
          error: null
        }
      };
    }
    case 'GET_PROVIDER_VIEW_DEVICES_ERROR':
      return {
        ...state,
        devices: {
          ...state.devices,
          error: action.error,
          isLoading: false
        }
      };
    case 'GET_PROVIDER_VIEW_INVOICES':
      return {
        ...state,
        invoices: {
          ...state.invoices,
          data: action.next ? state.invoices.data : initialState.invoices.data,
          isLoading: true
        }
      };
    case 'GET_PROVIDER_VIEW_INVOICES_SUCCESS': {
      const invoicesConcatenated = state.invoices.data.concat(action.data);

      return {
        ...state,
        invoices: {
          ...state.invoices,
          data: invoicesConcatenated,
          paging: action.paging ? action.paging : state.invoices.paging,
          isLoading: false,
          error: null
        }
      };
    }
    case 'GET_PROVIDER_VIEW_INVOICES_ERROR':
      return {
        ...state,
        invoices: {
          ...state.invoices,
          error: action.error,
          isLoading: false
        }
      };
    case 'GET_PROVIDER_VIEW_INVOICE_QUEUE':
      return {
        ...state,
        invoice_queue: {
          ...state.invoice_queue,
          data: action.next ? state.invoice_queue.data : initialState.invoice_queue.data,
          isLoading: true
        }
      };
    case 'GET_PROVIDER_VIEW_INVOICE_QUEUE_SUCCESS': {
      const invoicesQueueConcatenated = state.invoice_queue.data.concat(action.data);

      return {
        ...state,
        invoice_queue: {
          ...state.invoice_queue,
          data: invoicesQueueConcatenated,
          paging: action.paging ? action.paging : state.invoice_queue.paging,
          isLoading: false,
          error: null
        }
      };
    }
    case 'GET_PROVIDER_VIEW_INVOICE_QUEUE_ERROR':
      return {
        ...state,
        invoice_queue: {
          ...state.invoice_queue,
          error: action.error,
          isLoading: false
        }
      };
    case 'GET_PROVIDER_VIEW_ORDER_PRODUCTS':
      return {
        ...state,
        order_products: {
          ...state.order_products,
          data: action.next ? state.order_products.data : initialState.order_products.data,
          isLoading: true
        }
      };
    case 'GET_PROVIDER_VIEW_ORDER_PRODUCTS_SUCCESS': {
      const orderProductsConcatenated = state.order_products.data.concat(action.data);

      return {
        ...state,
        order_products: {
          ...state.order_products,
          data: orderProductsConcatenated,
          paging: action.paging ? action.paging : state.order_products.paging,
          isLoading: false,
          error: null
        }
      };
    }
    case 'GET_PROVIDER_VIEW_ORDER_PRODUCTS_ERROR':
      return {
        ...state,
        order_products: {
          ...state.order_products,
          error: action.error,
          isLoading: false
        }
      };
    case 'GET_PROVIDER_VIEW_ORDER_PRODUCTS_METADATA':
      return {
        ...state,
        order_products: {
          ...state.order_products,
          metadata: {
            ...initialState.order_products.metadata,
            isLoading: true,
            isError: false
          }
        }
      };
    case 'GET_PROVIDER_VIEW_ORDER_PRODUCTS_METADATA_SUCCESS':
      return {
        ...state,
        order_products: {
          ...state.order_products,
          metadata: {
            data: action.data,
            isLoading: false,
            isError: false
          }
        }
      };
    case 'GET_PROVIDER_VIEW_ORDER_PRODUCTS_METADATA_ERROR':
      return {
        ...state,
        order_products: {
          ...state.order_products,
          metadata: {
            ...state.order_products.metadata.data,
            isLoading: false,
            isError: true
          }
        }
      };
    case 'GET_PROVIDER_VIEW_BLACKLIST':
      return {
        ...state,
        blacklist: {
          ...state.blacklist,
          data: action.next ? state.blacklist.data : initialState.blacklist.data,
          isLoading: true
        }
      };
    case 'GET_PROVIDER_VIEW_BLACKLIST_SUCCESS': {
      const blacklistConcatenated = state.blacklist.data.concat(action.data);

      return {
        ...state,
        blacklist: {
          ...state.blacklist,
          data: blacklistConcatenated,
          paging: action.paging ? action.paging : state.blacklist.paging,
          isLoading: false,
          error: null
        }
      };
    }
    case 'GET_PROVIDER_VIEW_BLACKLIST_ERROR':
      return {
        ...state,
        blacklist: {
          ...state.blacklist,
          error: action.error,
          isLoading: false
        }
      };
    case 'GET_PROVIDER_VIEW_ADDRESSES':
      return {
        ...state,
        addresses: {
          ...state.addresses,
          data: action.next ? state.addresses.data : initialState.addresses.data,
          isLoading: true
        }
      };
    case 'GET_PROVIDER_VIEW_ADDRESSES_SUCCESS': {
      const addressesConcatenated = state.addresses.data.concat(action.data);

      return {
        ...state,
        addresses: {
          ...state.addresses,
          data: addressesConcatenated,
          paging: action.paging ? action.paging : state.addresses.paging,
          isLoading: false,
          error: null
        }
      };
    }
    case 'GET_PROVIDER_VIEW_ADDRESSES_ERROR':
      return {
        ...state,
        addresses: {
          ...state.addresses,
          error: action.error,
          isLoading: false
        }
      };
    case 'GET_PROVIDER_VIEW_PAYMENT_METHODS':
      return {
        ...state,
        payment_methods: {
          ...state.payment_methods,
          data: action.next ? state.payment_methods.data : initialState.payment_methods.data,
          isLoading: true
        }
      };
    case 'GET_PROVIDER_VIEW_PAYMENT_METHODS_SUCCESS': {
      const paymentMethodsConcatenated = state.payment_methods.data.concat(action.data);

      return {
        ...state,
        payment_methods: {
          ...state.payment_methods,
          data: paymentMethodsConcatenated,
          paging: action.paging ? action.paging : state.payment_methods.paging,
          isLoading: false,
          error: null
        }
      };
    }
    case 'GET_PROVIDER_VIEW_PAYMENT_METHODS_ERROR':
      return {
        ...state,
        payment_methods: {
          ...state.payment_methods,
          error: action.error,
          isLoading: false
        }
      };
    case 'GET_PROVIDER_VIEW_RATINGS_EMITTED':
      return {
        ...state,
        ratings: {
          ...state.ratings,
          emitted: {
            ...state.ratings.emitted,
            data: action.next ? state.ratings.emitted.data : initialState.ratings.emitted.data,
            isLoading: true
          }
        }
      };
    case 'GET_PROVIDER_VIEW_RATINGS_EMITTED_SUCCESS': {
      const ratingsEmittedConcatenated = state.ratings.emitted.data.concat(action.data);

      return {
        ...state,
        ratings: {
          ...state.ratings,
          emitted: {
            ...state.ratings.emitted,
            data: ratingsEmittedConcatenated,
            paging: action.paging ? action.paging : state.ratings.emitted.paging,
            isLoading: false,
            error: null
          }
        }
      };
    }
    case 'GET_PROVIDER_VIEW_RATINGS_EMITTED_ERROR':
      return {
        ...state,
        ratings: {
          ...state.ratings,
          emitted: {
            ...state.ratings.emitted,
            error: action.error,
            isLoading: false
          }
        }
      };
    case 'GET_PROVIDER_VIEW_RATINGS_RECEIVED':
      return {
        ...state,
        ratings: {
          ...state.ratings,
          received: {
            ...state.ratings.received,
            data: action.next ? state.ratings.received.data : initialState.ratings.received.data,
            isLoading: true
          }
        }
      };
    case 'GET_PROVIDER_VIEW_RATINGS_RECEIVED_SUCCESS': {
      const ratingsReceivedConcatenated = state.ratings.received.data.concat(action.data);

      return {
        ...state,
        ratings: {
          ...state.ratings,
          received: {
            ...state.ratings.received,
            data: ratingsReceivedConcatenated,
            paging: action.paging ? action.paging : state.ratings.received.paging,
            isLoading: false,
            error: null
          }
        }
      };
    }
    case 'GET_PROVIDER_VIEW_RATINGS_RECEIVED_ERROR':
      return {
        ...state,
        ratings: {
          ...state.ratings,
          received: {
            ...state.ratings.received,
            error: action.error,
            isLoading: false
          }
        }
      };
    default:
      return state;
  }
};
