export const storeMediaType = (mediaType) => ({
  type: 'STORE_MEDIA_TYPE',
  mediaType
});

export const storeMediaTypeMedia = (data, mediaTypeShortcode) => ({
  type: 'STORE_MEDIA_TYPE_MEDIA',
  data,
  mediaTypeShortcode
});

export const storeMediaTypeProperty = (
  mediaUrl,
  mediaTypeShortcode,
  mediaProperty,
  mediaPropertyValue
) => ({
  type: 'STORE_MEDIA_TYPE_MEDIA_PROPERTY',
  mediaUrl,
  mediaTypeShortcode,
  mediaProperty,
  mediaPropertyValue
});

export const removeMediaTypeMedia = (mediaUrl, mediaTypeShortcode) => ({
  type: 'REMOVE_MEDIA_TYPE_MEDIA',
  mediaUrl,
  mediaTypeShortcode
});

export const removeMediaTypeMedias = (mediaTypeShortcode) => ({
  type: 'REMOVE_MEDIA_TYPE_MEDIAS',
  mediaTypeShortcode
});

export const removeMediaTypes = () => ({
  type: 'REMOVE_MEDIA_TYPES'
});

export const clearMediaGroups = () => ({
  type: 'CLEAR_MEDIA_GROUPS'
});

export const getProductMediaGroups = () => ({
  type: 'GET_ORDER_PRODUCT_MEDIA_GROUPS'
});

export const getProductMediaGroupsSuccess = (data) => ({
  type: 'GET_ORDER_PRODUCT_MEDIA_GROUPS_SUCCESS',
  data
});

export const getProductMediaGroupsError = (error) => ({
  type: 'GET_ORDER_PRODUCT_MEDIA_GROUPS_ERROR',
  error
});

export const downloadOrderProductMedias = (id) => ({
  type: 'DOWNLOAD_ORDER_PRODUCT_MEDIAS',
  id
});

export const downloadOrderProductMediasSuccess = () => ({
  type: 'DOWNLOAD_ORDER_PRODUCT_MEDIAS_SUCCESS'
});

export const downloadOrderProductMediasError = (error) => ({
  type: 'DOWNLOAD_ORDER_PRODUCT_MEDIAS_ERROR',
  error
});

export const uploadOrderProductMedias = () => ({
  type: 'UPLOAD_ORDER_PRODUCT_MEDIAS'
});

export const uploadOrderProductMediasSuccess = () => ({
  type: 'UPLOAD_ORDER_PRODUCT_MEDIAS_SUCCESS'
});

export const uploadOrderProductMediasError = (error) => ({
  type: 'UPLOAD_ORDER_PRODUCT_MEDIAS_ERROR',
  error
});

export const setDownloadSettingsDefinition = (data) => ({
  type: 'SET_DOWNLOAD_SETTINGS_DEFINITION',
  data
});

export const setDownloadSettingsWatermark = (data) => ({
  type: 'SET_DOWNLOAD_SETTINGS_WATERMARK',
  data
});

export const setMediasErrors = (errors) => ({
  type: 'SET_MEDIAS_ERRORS',
  errors
});

export const clearMediasErrors = () => ({
  type: 'CLEAR_MEDIAS_ERRORS'
});
