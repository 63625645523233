/**
 * Service ID metrics
 */
export default {
  name: 'Service metrics',
  routes_selector: [
    {
      label: 'Requêtes',
      value: 'calls'
    }
  ],
  metrics_routes: {
    calls: {
      child_routes: {
        'calls-interval-auto': {
          route: 'calls',
          chart_props: {
            type: 'line',
            title: 'Nombre de requêtes:',
            metric_calc_mode: 'count'
          },
          query_params: {
            time_interval: 'auto'
          },
          filter_key: 'service_id'
        },
        'calls-interval-auto-compare-period': {
          route: 'calls',
          chart_props: {
            title: 'Évolution nombre de requêtes (période):',
            type: 'trending',
            metric_calc_mode: 'avg',
            custom: true
          },
          query_params: {
            compare: 'period'
          },
          filter_key: 'service_id'
        },
        'calls-per-app-interval-auto-bar': {
          route: 'calls-per-app',
          chart_props: {
            type: 'bar',
            title: 'Palmarès applications (appels):'
          },
          query_params: {
            time_interval: 'auto',
            limit: 20
          },
          filter_key: 'service_id'
        },
        'calls-per-app-interval-auto-doughnut': {
          route: 'calls-per-app',
          chart_props: {
            type: 'doughnut',
            title: 'Répartition applications (appels):'
          },
          query_params: {
            time_interval: 'auto',
            limit: 20
          },
          filter_key: 'service_id'
        },
        'calls-per-route-interval-auto-bar': {
          route: 'calls-per-route',
          chart_props: {
            type: 'bar',
            title: 'Palmarès routes:'
          },
          query_params: {
            time_interval: 'auto',
            limit: 20
          },
          filter_key: 'service_id'
        },
        'calls-per-route-interval-auto-doughnut': {
          route: 'calls-per-route',
          chart_props: {
            type: 'doughnut',
            title: 'Répartition routes:'
          },
          query_params: {
            time_interval: 'auto',
            limit: 20
          },
          filter_key: 'service_id'
        },
        'calls-failed-interval-auto': {
          route: 'calls-failed',
          chart_props: {
            type: 'line',
            title: 'Nombre de requêtes échouées:',
            metric_calc_mode: 'count'
          },
          query_params: {
            time_interval: 'auto'
          },
          filter_key: 'service_id'
        },
        'calls-failed-interval-auto-compare-period': {
          route: 'calls-failed',
          chart_props: {
            title: 'Évolution nombre de requêtes échouées (période):',
            type: 'trending',
            metric_calc_mode: 'avg',
            custom: true
          },
          query_params: {
            compare: 'period'
          },
          filter_key: 'service_id'
        },
        'calls-failed-per-status-interval-auto-bar': {
          route: 'calls-failed-per-status',
          chart_props: {
            type: 'bar',
            title: "Palmarès status d'erreur:"
          },
          query_params: {
            time_interval: 'auto',
            limit: 20
          },
          filter_key: 'service_id'
        },
        'calls-failed-per-status-interval-auto-doughnut': {
          route: 'calls-failed-per-status',
          chart_props: {
            type: 'doughnut',
            title: "Répartition status d'erreur:"
          },
          query_params: {
            time_interval: 'auto',
            limit: 20
          },
          filter_key: 'service_id'
        },
        'calls-failed-per-app-interval-auto-bar': {
          route: 'calls-failed-per-app',
          chart_props: {
            type: 'bar',
            title: 'Palmarès applications (échecs):'
          },
          query_params: {
            time_interval: 'auto',
            limit: 20
          },
          filter_key: 'service_id'
        },
        'calls-failed-per-app-interval-auto-doughnut': {
          route: 'calls-failed-per-app',
          chart_props: {
            type: 'doughnut',
            title: 'Répartition applications (échecs):'
          },
          query_params: {
            time_interval: 'auto',
            limit: 20
          },
          filter_key: 'service_id'
        },
        'calls-failed-validation-error-interval-auto': {
          route: 'calls-failed-validation-error',
          chart_props: {
            type: 'line',
            title: 'Nombre de requêtes échouées - 400:',
            metric_calc_mode: 'count'
          },
          query_params: {
            time_interval: 'auto'
          },
          filter_key: 'service_id'
        },
        'calls-failed-validation-error-interval-auto-compare-period': {
          route: 'calls-failed-validation-error',
          chart_props: {
            title: 'Évolution nombre de requêtes échouées - 400 (période):',
            type: 'trending',
            metric_calc_mode: 'avg',
            custom: true
          },
          query_params: {
            compare: 'period'
          },
          filter_key: 'service_id'
        },
        'calls-failed-forbidden-interval-auto': {
          route: 'calls-failed-forbidden',
          chart_props: {
            type: 'line',
            title: 'Nombre de requêtes échouées - 403:',
            metric_calc_mode: 'count'
          },
          query_params: {
            time_interval: 'auto'
          },
          filter_key: 'service_id'
        },
        'calls-failed-forbidden-interval-auto-compare-period': {
          route: 'calls-failed-forbidden',
          chart_props: {
            title: 'Évolution nombre de requêtes échouées - 403 (période):',
            type: 'trending',
            metric_calc_mode: 'avg',
            custom: true
          },
          query_params: {
            compare: 'period'
          },
          filter_key: 'service_id'
        },
        'calls-failed-not-found-interval-auto': {
          route: 'calls-failed-not-found',
          chart_props: {
            type: 'line',
            title: 'Nombre de requêtes échouées - 404:',
            metric_calc_mode: 'count'
          },
          query_params: {
            time_interval: 'auto'
          },
          filter_key: 'service_id'
        },
        'calls-failed-not-found-interval-auto-compare-period': {
          route: 'calls-failed-not-found',
          chart_props: {
            title: 'Évolution nombre de requêtes échouées - 404 (période):',
            type: 'trending',
            metric_calc_mode: 'avg',
            custom: true
          },
          query_params: {
            compare: 'period'
          },
          filter_key: 'service_id'
        },
        'calls-failed-missing-fields-interval-auto': {
          route: 'calls-failed-missing-fields',
          chart_props: {
            type: 'line',
            title: 'Nombre de requêtes échouées - 422:',
            metric_calc_mode: 'count'
          },
          query_params: {
            time_interval: 'auto'
          },
          filter_key: 'service_id'
        },
        'calls-failed-missing-fields-interval-auto-compare-period': {
          route: 'calls-failed-missing-fields',
          chart_props: {
            title: 'Évolution nombre de requêtes échouées - 422 (période):',
            type: 'trending',
            metric_calc_mode: 'avg',
            custom: true
          },
          query_params: {
            compare: 'period'
          },
          filter_key: 'service_id'
        },
        'calls-failed-rate-limit-interval-auto': {
          route: 'calls-failed-missing-fields',
          chart_props: {
            type: 'line',
            title: 'Nombre de requêtes échouées - 429:',
            metric_calc_mode: 'count'
          },
          query_params: {
            time_interval: 'auto'
          },
          filter_key: 'service_id'
        },
        'calls-failed-rate-limit-interval-auto-compare-period': {
          route: 'calls-failed-rate-limit',
          chart_props: {
            title: 'Évolution nombre de requêtes échouées - 429 (période):',
            type: 'trending',
            metric_calc_mode: 'avg',
            custom: true
          },
          query_params: {
            compare: 'period'
          },
          filter_key: 'service_id'
        },
        'calls-failed-unknown-interval-auto': {
          route: 'calls-failed-unknown',
          chart_props: {
            type: 'line',
            title: 'Nombre de requêtes échouées - 500:',
            metric_calc_mode: 'count'
          },
          query_params: {
            time_interval: 'auto'
          },
          filter_key: 'service_id'
        },
        'calls-failed-unknown-interval-auto-compare-period': {
          route: 'calls-failed-unknown',
          chart_props: {
            title: 'Évolution nombre de requêtes échouées - 500 (période):',
            type: 'trending',
            metric_calc_mode: 'avg',
            custom: true
          },
          query_params: {
            compare: 'period'
          },
          filter_key: 'service_id'
        }
      }
    }
  }
};
