import * as Cookies from 'js-cookie';

import config from '../../config';

export const getAccessToken = () => Cookies.get(config.access_token_cookie_name);

export const setAccessToken = (accessToken, expirationDelay) => {
  if (accessToken && expirationDelay) {
    if (config.env_mode && config.env_mode === 'prod')
      Cookies.set(config.access_token_cookie_name, accessToken, {
        expires: expirationDelay,
        secure: true
      });
    else
      Cookies.set(config.access_token_cookie_name, accessToken, {
        expires: expirationDelay
      });
  }
};

export const removeAccessToken = () => {
  if (getAccessToken()) Cookies.remove(config.access_token_cookie_name);
};

export const getLocale = () => {
  const locale = Cookies.get(config.locale_cookie_name);

  if (locale) return locale;
  else return null;
};

export const setLocale = (locale) => {
  if (locale) Cookies.set(config.locale_cookie_name, locale, { expires: 365 });
};

export const getCurrency = () => {
  const currency = Cookies.get(config.currency_cookie_name);

  if (currency) return currency;
  else return null;
};

export const setCurrency = (currency) => {
  if (currency) Cookies.set(config.currency_cookie_name, currency, { expires: 365 });
};

export const getTimezone = () => {
  const timezone = Cookies.get(config.timezone_cookie_name);

  if (timezone) return timezone;
  else return null;
};

export const setTimezone = (timezone) => {
  if (timezone) Cookies.set(config.timezone_cookie_name, timezone, { expires: 365 });
};

export const getResidenceCountry = () => {
  const country = Cookies.get(config.residence_country_cookie_name);

  if (country) return country;
  else return config.default_country;
};

export const setResidenceCountry = (country) => {
  if (country)
    Cookies.set(config.residence_country_cookie_name, country, {
      expires: 365
    });
};

export const setNightTheme = (isDarkTheme) => {
  Cookies.set(config.theme_cookie_name, isDarkTheme, { expires: 1000 });
};

export const getNightTheme = () => {
  const isDarkTheme = Cookies.get(config.theme_cookie_name);

  if (isDarkTheme) return isDarkTheme;
  else return false;
};

export const removeAppTheme = () => {
  Cookies.remove(config.theme_cookie_name);
};

export const setUserTableStringWidth = (width) => {
  Cookies.set(config.user_table_string_width_cookie_name, width, {
    expires: 1000
  });
};

export const getUserTableStringWidth = () => {
  const width = Cookies.get(config.user_table_string_width_cookie_name);

  if (width) return width;
  else return false;
};

export const removeUserTableStringWidth = () => {
  Cookies.remove(config.user_table_string_width_cookie_name);
};

export const setUserTableLinksDisplayMode = (value) => {
  Cookies.set(config.user_table_links_display_mode, value, { expires: 1000 });
};

export const getUserTableLinksDisplayMode = () => {
  const mode = Cookies.get(config.user_table_links_display_mode);

  if (mode) return mode;
  else return config.default_table_links_display_mode;
};

export const setUserSearchBarPosition = (value) => {
  Cookies.set(config.user_search_bar_position, value, { expires: 1000 });
};

export const getUserSearchBarPosition = () => {
  const position = Cookies.get(config.user_search_bar_position);

  if (position) return position;
  else return config.default_search_bar_position;
};
