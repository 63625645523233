import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchApi } from '../../../utils/functions/api';
import { enqueueSnackbar } from '../../../redux/actions/appActions';
import PayInvoice from '../../../views/core/customListActions/PayInvoice';
import translation from '../../../translation/translation';

const mapDispatchToProps = (dispatch) => {
  return {
    getInvoicerPaymentMethods(invoiceId, currency, callbackSuccess, callbackError) {
      if (!invoiceId) {
        return;
      }

      fetchApi(
        'get',
        `invoices/${invoiceId}/full`,
        currency ? { currency } : {},
        null,
        null,
        (invoiceData) => {
          if (invoiceData.client_invoicer_id) {
            fetchApi(
              'get',
              `invoicers/${invoiceData.client_invoicer_id}`,
              currency ? { currency } : {},
              null,
              null,
              (invoicerData) => {
                let userType = null;
                let iamId = null;

                if (invoicerData.iam_company_id) {
                  userType = 'companies';
                  iamId = invoicerData.iam_company_id;
                } else if (invoicerData.iam_user_id) {
                  userType = 'users';
                  iamId = invoicerData.iam_user_id;
                }

                if (userType && iamId) {
                  fetchApi(
                    'get',
                    `${userType}/${iamId}/payment-methods` +
                      (userType === 'companies' ? '' : '/usable'),
                    currency ? { currency } : {},
                    null,
                    null,
                    (success) => {
                      if (callbackSuccess && typeof callbackSuccess === 'function') {
                        callbackSuccess(success);
                      }
                    },
                    (error) => {
                      if (error.response) {
                        dispatch(
                          enqueueSnackbar({
                            message:
                              error.response.data && error.response.data.detail
                                ? error.response.data.detail
                                : translation().core.list.error_list,
                            options: {
                              variant: 'error'
                            }
                          })
                        );
                      } else {
                        dispatch(
                          enqueueSnackbar({
                            message: translation().core.list.error_list,
                            options: {
                              variant: 'error'
                            }
                          })
                        );
                      }

                      if (callbackError && typeof callbackError === 'function') {
                        callbackError();
                      }
                    }
                  );
                }
              },
              (error) => {
                if (error.response) {
                  dispatch(
                    enqueueSnackbar({
                      message:
                        error.response.data && error.response.data.detail
                          ? error.response.data.detail
                          : translation().core.list.error_list,
                      status: error.response.status || undefined,
                      options: {
                        variant: 'error'
                      }
                    })
                  );
                } else {
                  dispatch(
                    enqueueSnackbar({
                      message: translation().core.list.error_list,
                      options: {
                        variant: 'error'
                      }
                    })
                  );
                }

                if (callbackError && typeof callbackError === 'function') {
                  callbackError();
                }
              }
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().core.item.not_found,
                options: {
                  variant: 'error'
                }
              })
            );

            if (callbackError && typeof callbackError === 'function') {
              callbackError();
            }
          }
        },
        (error) => {
          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.error_list,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().core.list.error_list,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError();
          }
        }
      );
    },
    changeInvoicePaymentMethod: (
      invoiceId,
      currency,
      paymentMethodId,
      callbackSuccess,
      callbackError
    ) => {
      if (!invoiceId || !paymentMethodId) {
        return;
      }

      const data = {
        client_pay_payment_method_id: paymentMethodId
      };

      if (currency) {
        data.currency = currency;
      }

      fetchApi(
        'put',
        `invoices/${invoiceId}/client-payment-method`,
        null,
        data,
        null,
        (success) => {
          dispatch(
            enqueueSnackbar({
              message:
                translation().core.list.dialogs.custom_list_actions.pay_invoice.callbacks.save
                  .success,
              options: {
                variant: 'success'
              }
            })
          );

          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success);
          }
        },
        (error) => {
          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.dialogs.custom_list_actions.pay_invoice.callbacks.save
                        .error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message:
                  translation().core.list.dialogs.custom_list_actions.pay_invoice.callbacks.save
                    .error,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError();
          }
        }
      );
    },
    payInvoice: (invoiceId, amount, callbackSuccess, callbackError) => {
      if (!invoiceId) {
        return;
      }

      fetchApi(
        'post',
        `invoices/${invoiceId}/pay`,
        amount ? { amount } : {},
        null,
        null,
        (success) => {
          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success);
          }

          if (success.status) {
            if (success.status === 'success') {
              dispatch(
                enqueueSnackbar({
                  message:
                    translation().core.list.dialogs.custom_list_actions.pay_invoice.callbacks.pay
                      .success,
                  options: {
                    variant: 'success'
                  }
                })
              );
            } else if (success.status === 'pending') {
              dispatch(
                enqueueSnackbar({
                  message:
                    'Requested payment.\n' +
                    (success.comments ? ' ' + success.comments.replace(/\n/g, '\n') : 'Pending'),
                  options: {
                    variant: 'warning'
                  }
                })
              );
            } else if (success.status === 'error') {
              if (success.comments) {
                dispatch(
                  enqueueSnackbar({
                    message: 'Payed with error:\n' + success.comments.replace(/\n/g, '\n'),
                    options: {
                      variant: 'error'
                    }
                  })
                );
              } else {
                dispatch(
                  enqueueSnackbar({
                    message: 'Payed with error: No comment provided.',
                    options: {
                      variant: 'error'
                    }
                  })
                );
              }
            }
          } else {
            dispatch(
              enqueueSnackbar({
                message: 'Payed with no status provided.',
                options: {
                  variant: 'error'
                }
              })
            );
          }
        },
        (error) => {
          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.dialogs.custom_list_actions.pay_invoice.callbacks.pay
                        .error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message:
                  translation().core.list.dialogs.custom_list_actions.pay_invoice.callbacks.pay
                    .error,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError();
          }
        }
      );
    }
  };
};

export default withRouter(connect(null, mapDispatchToProps)(PayInvoice));
