import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';

import GooglePlaceField from '../../../../components/forms/fields/customs/GooglePlaceField';
import translation from '../../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  createLoadingText: {
    display: 'inline-block',
    verticalAlign: 'top',
    margin: '2px 10px'
  },
  formRoot: {
    minWidth: 475,
    minHeight: 307,
    [theme.breakpoints.down('sm')]: {
      minWidth: 'inherit'
    }
  },
  switch: {
    marginBottom: 12
  }
}));

const loaderWrapperStyle = {
  padding: 0,
  verticalAlign: 'middle',
  marginTop: 24
};

function CreateAddressForm(props) {
  const { errors, isFormLoading, onSubmit, onClose, user } = props;

  const classes = useStyles();
  const [addressName, setAddressName] = useState('');
  const [addressValue, setAddressValue] = useState('');
  const [switchValue, setSwitchValue] = useState(user.company_admin && user.company ? true : false);

  function handleChangeAddressName(event) {
    setAddressName(event.target.value);
  }

  function handleChangeAddressValue(value) {
    setAddressValue(value);
  }

  /* eslint-disable-next-line */
  function handleSelectAddress(value, suggestion) {
    setAddressValue(suggestion.description);
  }

  function handleChangeSwitch(event) {
    if (event) {
      setSwitchValue(event.target.checked);
    }
  }

  function handleSubmit() {
    const values = {
      address: addressValue,
      name: addressName
    };

    onSubmit(values, switchValue);
  }

  function handleClose() {
    onClose();
  }

  return (
    <div className="CreateClientAddressForm">
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="create-address-client-dialog"
        disableBackdropClick={isFormLoading}>
        <DialogTitle id="create-address-client-dialog">
          {translation().views.orders.create.payment.invoicing.addresses.form.title}
        </DialogTitle>
        <DialogContent>
          <div className={classes.formRoot}>
            {user && user.company && user.company.id && user.company_admin && (
              <FormControlLabel
                disabled={isFormLoading}
                control={
                  <Switch checked={switchValue} onChange={handleChangeSwitch} color="primary" />
                }
                className={classes.switch}
                label={translation().views.orders.create.payment.invoicing.addresses.professional}
              />
            )}
            <GooglePlaceField
              autoFocus
              type="text"
              id="invoice_address_value"
              name="invoice_address_value"
              label={
                translation().views.orders.create.payment.invoicing.addresses.form.labels.address
              }
              onChange={handleChangeAddressValue}
              onSelectAddress={handleSelectAddress}
              disabled={isFormLoading}
              value={addressValue}
              required
              autoComplete="off"
              margin="normal"
              error={
                errors && errors['invoice_address_value'] ? errors['invoice_address_value'] : ''
              }
            />
            <TextField
              id="invoice_address_name"
              name="invoice_address_name"
              label={translation().views.orders.create.payment.invoicing.addresses.form.labels.name}
              type="text"
              disabled={isFormLoading}
              onChange={handleChangeAddressName}
              value={addressName}
              fullWidth
              autoComplete="off"
              margin="normal"
              required
              error={errors && errors['invoice_address_name'] ? true : false}
              helperText={
                errors && errors['invoice_address_name']
                  ? errors['invoice_address_name']
                  : translation().views.orders.create.payment.invoicing.addresses.form.labels
                      .helperText
              }
            />
            {isFormLoading && (
              <div className="loader-wpr" style={loaderWrapperStyle}>
                <CircularProgress size={20} color="secondary" />
                <p className={classes.createLoadingText}>
                  {translation().views.orders.create.payment.invoicing.addresses.creating_text}
                </p>
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="default" disabled={isFormLoading}>
            {translation().actions.close}
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={!addressValue || !addressName || isFormLoading}
            color="primary"
            variant="contained">
            {translation().actions.confirm}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

CreateAddressForm.defaultProps = {
  isFormLoading: false
};

CreateAddressForm.propTypes = {
  errors: PropTypes.any,
  isFormLoading: PropTypes.bool,
  user: PropTypes.shape({
    id: PropTypes.any.isRequired,
    company_admin: PropTypes.bool,
    company: PropTypes.shape({
      id: PropTypes.any
    })
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default CreateAddressForm;
