import React from 'react';
import PropTypes from 'prop-types';
import MUIPlacesAutocomplete, { geocodeByPlaceID } from 'mui-places-autocomplete';

import { googleMapsAddressMapper } from '../../../../utils/functions/utils';

/**
 * This component is controlled by an outside value, with an outside handleChange function.
 */
function GooglePlaceField(props) {
  const {
    autoComplete,
    autoFocus,
    defaultValue,
    disabled,
    error,
    helperText,
    isResultMapped,
    id,
    fullWidth,
    label,
    margin,
    multiline,
    name,
    style,
    type,
    rows,
    rowsMax,
    required,
    onChange,
    onSelectAddress,
    value,
    write
  } = props;

  function handleSelectSuggestion(suggestion) {
    geocodeByPlaceID(suggestion.place_id)
      .then((results) => {
        if (onChange && results[0]) {
          if (isResultMapped)
            onSelectAddress(googleMapsAddressMapper(results[0]), suggestion, results[0].geometry);
          else onSelectAddress(results[0], suggestion, results[0].geometry);
        }
      })
      .catch(() => {
        throw new Error('Maps failed to geocode place id.');
      });
  }

  function renderTarget() {
    return <div />;
  }

  function handleChange(e) {
    if (onChange) {
      onChange(e.target.value);
    }
  }

  return (
    <div style={{ position: 'relative' }}>
      <MUIPlacesAutocomplete
        onSuggestionSelected={handleSelectSuggestion}
        renderTarget={renderTarget}
        textFieldProps={{
          id: id,
          autoComplete: autoComplete,
          autoFocus: autoFocus,
          defaultValue: defaultValue,
          disabled: !write || disabled,
          error: error ? true : false,
          helperText: error || helperText,
          margin: margin,
          multiline: multiline,
          name: name,
          label: label,
          type: type,
          rows: rows,
          style: multiline ? { ...style, overflow: 'auto' } : style,
          rowsMax: rowsMax,
          required: required,
          fullWidth: fullWidth,
          onChange: handleChange,
          value: value
        }}
      />
    </div>
  );
}

GooglePlaceField.defaultProps = {
  autoFocus: false,
  isResultMapped: true,
  fullWidth: true,
  margin: 'normal',
  multiline: false,
  style: {},
  type: 'text',
  rows: 1,
  rowsMax: 10,
  write: true
};

GooglePlaceField.propTypes = {
  autoComplete: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  autoFocus: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fullWidth: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.any,
  isResultMapped: PropTypes.bool,
  multiline: PropTypes.bool,
  margin: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  style: PropTypes.shape(),
  type: PropTypes.string,
  required: PropTypes.bool,
  rows: PropTypes.number,
  rowsMax: PropTypes.number,
  onSelectAddress: PropTypes.func,
  onChange: PropTypes.func,
  write: PropTypes.bool
};

export default GooglePlaceField;
