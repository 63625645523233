import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchApi } from '../../../utils/functions/api';
import { enqueueSnackbar } from '../../../redux/actions/appActions';
import AdminizePaymentMethod from '../../../views/core/customListActions/AdminizePaymentMethod';
import translation from '../../../translation/translation';

const mapDispatchToProps = (dispatch) => {
  return {
    getCompanyPaymentMethodUserPermission: (
      companyId,
      paymentMethodId,
      userId,
      callbackSuccess,
      callbackError
    ) => {
      if (!companyId || !paymentMethodId || !userId) {
        return;
      }

      fetchApi(
        'get',
        `companies/${companyId}/payment-methods/${paymentMethodId}/users/${userId}`,
        null,
        null,
        null,
        (success) => {
          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success);
          }
        },
        (error) => {
          if (callbackError && typeof callbackError === 'function') {
            callbackError(error);
          }
        }
      );
    },
    getCompanyUsers: (companyId, next = null, callbackSuccess, callbackError) => {
      if (!companyId) {
        return;
      }

      fetchApi(
        'get',
        next ? next : `companies/${companyId}/users`,
        null,
        null,
        null,
        (successData, paging) => {
          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess({ successData, paging });
          }
        },
        (error) => {
          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.dialogs.custom_list_actions.adminize_payment_method
                        .callbacks.get_user_error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message:
                  translation().core.list.dialogs.custom_list_actions.adminize_payment_method
                    .callbacks.get_user_error,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError();
          }
        }
      );
    },
    handleCompanyPaymentMethodUserPermission: (
      companyId,
      paymentMethodId,
      userId,
      isChecked,
      callbackSuccess,
      callbackError
    ) => {
      if (!companyId || !paymentMethodId || !userId) {
        return;
      }

      fetchApi(
        isChecked ? 'post' : 'delete',
        `companies/${companyId}/payment-methods/${paymentMethodId}/users/${userId}`,
        null,
        null,
        null,
        (success) => {
          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success);
          }
        },
        (error) => {
          if (callbackError && typeof callbackError === 'function') {
            callbackError(error);
          }

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.dialogs.custom_list_actions.adminize_payment_method
                        .callbacks.handle_permission,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message:
                  translation().core.list.dialogs.custom_list_actions.adminize_payment_method
                    .callbacks.handle_permission,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    }
  };
};

export default withRouter(connect(null, mapDispatchToProps)(AdminizePaymentMethod));
