import React from 'react';

function UploadFileInDropperIcon(props) {
  return (
    <svg
      version="1.1"
      id="UploadFileInDropperIcon"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 728 796.4"
      style={{ enableBackground: 'new 0 0 728 796.4' }}
      {...props}>
      <linearGradient
        id="SVGID_1_"
        gradientUnits="userSpaceOnUse"
        x1="506.015"
        y1="38.0798"
        x2="506.015"
        y2="744.0798"
        gradientTransform="matrix(1 0 0 -1 -236 744.0798)">
        <stop offset="0" style={{ stopColor: '#B3B3B3', stopOpacity: 0.25 }} />
        <stop offset="0.54" style={{ stopColor: '#B3B3B3', stopOpacity: 0.1 }} />
        <stop offset="1" style={{ stopColor: '#B3B3B3', stopOpacity: '5.000000e-02' }} />
      </linearGradient>
      <path
        fill={"url('#SVGID_1_')"}
        d="M414,643c0-51.8,51.1-99.5,102.9-99.5c7.9,0,15.6-2,23-0.2c0.1-2.3,0.2-15.5,0.2-17.8V0H92L0,94.5V706h423
        C413.9,691.6,414,661.3,414,643z"
      />
      <path
        fill="#FCFCFC"
        d="M406,653c0-64.6,52.4-117,117-117c3.5,0,7,0.2,10.5,0.5V265.2H8.5V694h404.9C408.5,680.9,406,667,406,653z"
      />
      <polygon fill="#FCFCFC" points="533.1,269.2 6.9,269.2 6.9,94.5 92,5.5 533.1,5.5 " />
      <rect x="357.6" y="58.6" fill="#E0E0E0" width="142.7" height="10.9" />
      <rect x="357.6" y="98.6" fill="#E0E0E0" width="142.7" height="10.9" />
      <rect x="357.6" y="138.6" fill="#E0E0E0" width="142.7" height="10.9" />
      <rect
        x="357.6"
        y="178.6"
        style={{ opacity: 0.5, fill: '#6C63FF' }}
        width="142.7"
        height="10.9"
      />
      <rect x="357.6" y="218.6" fill="#E0E0E0" width="142.7" height="10.9" />
      <rect x="357.6" y="258.6" fill="#E0E0E0" width="142.7" height="10.9" />
      <rect
        x="84.7"
        y="343.6"
        style={{ opacity: 0.5, fill: '#6C63FF' }}
        width="142.7"
        height="10.9"
      />
      <rect x="84.7" y="387.1" fill="#E0E0E0" width="142.7" height="10.9" />
      <rect
        x="84.7"
        y="365.4"
        style={{ opacity: 0.5, fill: '#4CAF50' }}
        width="372.6"
        height="10.9"
      />
      <rect
        x="84.7"
        y="448.8"
        style={{ opacity: 0.5, fill: '#6C63FF' }}
        width="142.7"
        height="10.9"
      />
      <rect x="84.7" y="492.4" fill="#E0E0E0" width="142.7" height="10.9" />
      <rect x="84.7" y="470.6" fill="#E0E0E0" width="372.6" height="10.9" />
      <rect
        x="84.7"
        y="554.1"
        style={{ opacity: 0.5, fill: '#6C63FF' }}
        width="142.7"
        height="10.9"
      />
      <rect x="84.7" y="597.6" fill="#E0E0E0" width="142.7" height="10.9" />
      <rect x="84.7" y="575.8" fill="#E0E0E0" width="305.4" height="10.9" />
      <polygon fill="#6C63FF" points="310,281 50,281 50,54 59,43 79.8,43 310,43 " />
      <linearGradient
        id="SVGID_2_"
        gradientUnits="userSpaceOnUse"
        x1="49.455"
        y1="699.3898"
        x2="49.455"
        y2="792.3898"
        gradientTransform="matrix(1 0 0 -1 0 795.8898)">
        <stop offset="0" style={{ stopColor: '#B3B3B3', stopOpacity: 0.25 }} />
        <stop offset="0.54" style={{ stopColor: '#B3B3B3', stopOpacity: 0.1 }} />
        <stop offset="1" style={{ stopColor: '#B3B3B3', stopOpacity: '5.000000e-02' }} />
      </linearGradient>
      <polygon fill={"url('#SVGID_2_')"} points="4.9,96.5 94,96.5 94,3.5 " />
      <polygon fill="#F5F5F5" points="6.9,94.5 92,94.5 92,5.5 " />
      <g style={{ opacity: 0.5 }}>
        <linearGradient
          id="SVGID_3_"
          gradientUnits="userSpaceOnUse"
          x1="627.87"
          y1="-0.4902"
          x2="627.87"
          y2="199.7698"
          gradientTransform="matrix(1 0 0 -1 0 795.8898)">
          <stop offset="0" style={{ stopColor: '#808080', stopOpacity: 0.25 }} />
          <stop offset="0.54" style={{ stopColor: '#808080', stopOpacity: 0.12 }} />
          <stop offset="1" style={{ stopColor: '#808080', stopOpacity: 0.1 }} />
        </linearGradient>
        <circle fill={"url('#SVGID_3_')"} cx="627.9" cy="696.3" r="100.1" />
      </g>
      <circle fill="#4CAF50" cx="627.9" cy="696.3" r="93.7" />
      <g style={{ opacity: 0.5 }}>
        <linearGradient
          id="SVGID_4_"
          gradientUnits="userSpaceOnUse"
          x1="627.87"
          y1="49.0698"
          x2="627.87"
          y2="150.2098"
          gradientTransform="matrix(1 0 0 -1 0 795.8898)">
          <stop offset="0" style={{ stopColor: '#808080', stopOpacity: 0.25 }} />
          <stop offset="0.54" style={{ stopColor: '#808080', stopOpacity: 0.12 }} />
          <stop offset="1" style={{ stopColor: '#808080', stopOpacity: 0.1 }} />
        </linearGradient>
        <polygon
          fill={"url('#SVGID_4_')"}
          points="678.4,685 639.2,685 639.2,645.7 616.6,645.7 616.6,685 577.3,685 577.3,707.5 616.6,707.5
          616.6,746.8 639.2,746.8 639.2,707.5 678.4,707.5 	"
        />
      </g>
      <rect x="618.3" y="653.3" fill="#FFFFFF" width="19.2" height="86" />
      <rect x="584.9" y="686.7" fill="#FFFFFF" width="86" height="19.2" />
      <linearGradient
        id="SVGID_5_"
        gradientUnits="userSpaceOnUse"
        x1="611.015"
        y1="-6.9202"
        x2="611.015"
        y2="699.0798"
        gradientTransform="matrix(1 0 0 -1 -236 744.0798)">
        <stop offset="0" style={{ stopColor: '#B3B3B3', stopOpacity: 0.25 }} />
        <stop offset="0.54" style={{ stopColor: '#B3B3B3', stopOpacity: 0.1 }} />
        <stop offset="1" style={{ stopColor: '#B3B3B3', stopOpacity: '5.000000e-02' }} />
      </linearGradient>
      <path
        fill={"url('#SVGID_5_')"}
        d="M519,688c0-51.8,51.1-99.5,102.9-99.5c7.9,0,15.6-2,23-0.2c0.1-2.3,0.2-15.5,0.2-17.8V45H197l-92,94.5V751h423
        C518.9,736.6,519,706.3,519,688z"
      />
      <path
        fill="#FFFFFF"
        d="M511,698c0-64.6,52.4-117,117-117l0,0c3.5,0,7,0.2,10.5,0.5V310.2h-525V739h404.9C513.5,725.9,511,712,511,698
        z"
      />
      <polygon fill="#FFFFFF" points="638.1,314.2 111.9,314.2 111.9,139.5 197,50.5 638.1,50.5 " />
      <rect x="462.6" y="103.6" fill="#E0E0E0" width="142.7" height="10.9" />
      <rect x="462.6" y="143.6" fill="#E0E0E0" width="142.7" height="10.9" />
      <rect x="462.6" y="183.6" fill="#E0E0E0" width="142.7" height="10.9" />
      <rect
        x="462.6"
        y="223.6"
        style={{ opacity: 0.5, fill: '#6C63FF' }}
        width="142.7"
        height="10.9"
      />
      <rect x="462.6" y="263.6" fill="#E0E0E0" width="142.7" height="10.9" />
      <rect x="462.6" y="303.6" fill="#E0E0E0" width="142.7" height="10.9" />
      <rect
        x="189.7"
        y="388.6"
        style={{ opacity: 0.5, fill: '#6C63FF' }}
        width="142.7"
        height="10.9"
      />
      <rect x="189.7" y="432.1" fill="#E0E0E0" width="142.7" height="10.9" />
      <rect
        x="189.7"
        y="410.4"
        style={{ opacity: 0.5, fill: '#4CAF50' }}
        width="372.6"
        height="10.9"
      />
      <rect
        x="189.7"
        y="493.8"
        style={{ opacity: 0.5, fill: '#6C63FF' }}
        width="142.7"
        height="10.9"
      />
      <rect x="189.7" y="537.4" fill="#E0E0E0" width="142.7" height="10.9" />
      <rect x="189.7" y="515.6" fill="#E0E0E0" width="372.6" height="10.9" />
      <rect
        x="189.7"
        y="599.1"
        style={{ opacity: 0.5, fill: '#6C63FF' }}
        width="142.7"
        height="10.9"
      />
      <rect x="189.7" y="642.6" fill="#E0E0E0" width="142.7" height="10.9" />
      <rect x="189.7" y="620.8" fill="#E0E0E0" width="305.4" height="10.9" />
      <polygon fill="#6C63FF" points="415,326 155,326 155,99 164,88 184.8,88 415,88 " />
      <linearGradient
        id="SVGID_6_"
        gradientUnits="userSpaceOnUse"
        x1="154.455"
        y1="654.3898"
        x2="154.455"
        y2="747.3898"
        gradientTransform="matrix(1 0 0 -1 0 795.8898)">
        <stop offset="0" style={{ stopColor: '#B3B3B3', stopOpacity: 0.25 }} />
        <stop offset="0.54" style={{ stopColor: '#B3B3B3', stopOpacity: 0.1 }} />
        <stop offset="1" style={{ stopColor: '#B3B3B3', stopOpacity: '5.000000e-02' }} />
      </linearGradient>
      <polygon fill={"url('#SVGID_6_')"} points="109.9,141.5 199,141.5 199,48.5 " />
      <polygon fill="#FCFCFC" points="111.9,139.5 197,139.5 197,50.5 " />
    </svg>
  );
}

export default UploadFileInDropperIcon;
