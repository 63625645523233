import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';

/**
 * This component is fully controlled.
 */
const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  }
}));

function MultipleSelectField(props) {
  const {
    autoWidth,
    children,
    defaultValue,
    disabled,
    error,
    fullWidth,
    helperText,
    id,
    label,
    margin,
    name,
    required,
    onChange,
    onBlur,
    displayKey,
    write
  } = props;

  const classes = useStyles();
  const [value, setValue] = useState([]);

  useEffect(() => {
    if (defaultValue.length) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  function handleChange(event) {
    setValue(event.target.value);
    onChange(event.target.value);
  }

  return (
    <div className={classes.root}>
      <FormControl
        required={required}
        disabled={!write || disabled}
        fullWidth={fullWidth}
        margin={margin}
        error={error ? true : false}>
        <InputLabel htmlFor={id || name}>{label}</InputLabel>
        <Select
          multiple
          required={required}
          disabled={!write || disabled}
          onChange={handleChange}
          name={name}
          autoWidth={autoWidth}
          value={value}
          onClose={onBlur}
          input={<Input id={id || name} />}
          // eslint-disable-next-line react/jsx-no-bind
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value, index) => (
                <Chip
                  key={index}
                  label={value[displayKey] ? value[displayKey] : value}
                  className={classes.chip}
                />
              ))}
            </div>
          )}>
          {children}
        </Select>
      </FormControl>
      {(error || helperText) && <FormHelperText>{error || helperText}</FormHelperText>}
    </div>
  );
}

MultipleSelectField.defaultProps = {
  autoWidth: false,
  fullWidth: true,
  margin: 'normal',
  displayKey: 'name',
  write: true
};

MultipleSelectField.propTypes = {
  autoWidth: PropTypes.bool,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  defaultValue: PropTypes.arrayOf(PropTypes.any),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  displayKey: PropTypes.string,
  margin: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  write: PropTypes.bool
};

export default MultipleSelectField;
