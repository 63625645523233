import React from 'react';

function PunctualityIcon(props) {
  return (
    <svg
      version="1.1"
      id="PunctualityIcon"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="100"
      viewBox="0 0 54.3 54.3"
      style={{ enableBackground: 'new 0 0 54.3 54.3' }}
      {...props}>
      <path
        fill="#EFBE9A"
        d="M41.1,34.6H26.5l-5.6,3.7c-0.7,0.4-1.5,0.7-2.3,0.7c-1.1,0-2.1-0.4-2.9-1.2l-3.2-3.2H8.9c-1.2,0-2.2-1-2.2-2.2
        c0-1.2,1-2.2,2.2-2.2H7.4c-1.2,0-2.2-1-2.2-2.2s1-2.2,2.2-2.2h1.5c-1.2,0-2.2-1-2.2-2.2c0-1.2,1-2.2,2.2-2.2h2.9
        c-1.2,0-2.2-1-2.2-2.2c0-1.2,1-2.2,2.2-2.2h9.1c1.7,0,3.4,0.5,4.8,1.5c1.4,1,3.1,1.5,4.8,1.5h10.6"
      />
      <path fill="#4472B2" d="M30.9,23.9l0.7-5.5H36l0.7,5.5" />
      <path fill="#4472B2" d="M30.9,30.6l0.7,5.5H36l0.7-5.5" />
      <circle fill="#E64C3C" cx="33.8" cy="27.3" r="4.4" />
      <path fill="#4472B2" d="M49.2,36.1h-8.1V18.5h8.1" />
      <path
        fill="#FFFFFF"
        d="M34.5,29.5h-1.5v-2.2c0-0.1,0-0.3,0.1-0.4l1.5-2.2l1.2,0.8l-1.3,2V29.5z"
      />
    </svg>
  );
}

export default PunctualityIcon;
