import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchApi } from '../../../utils/functions/api';
import {
  dateInTz,
  addToDate,
  formatDate,
  createDate,
  createDateUtc
} from '../../../utils/functions/dates';
import { enqueueSnackbar } from '../../../redux/actions/appActions';
import {
  clearOrderUpdateReducer,
  cancelOrderProduct,
  cancelOrderProductSuccess,
  cancelOrderProductError,
  deleteOrderDiscount,
  deleteOrderDiscountSuccess,
  deleteOrderDiscountError,
  addOrderDiscount,
  addOrderDiscountSuccess,
  addOrderDiscountError,
  editOrderInvoiceAddress,
  editOrderInvoiceAddressSuccess,
  editOrderInvoiceAddressError,
  editOrderPaymentMethod,
  editOrderPaymentMethodSuccess,
  editOrderPaymentMethodError,
  getOrder,
  getOrderSuccess,
  getOrderError,
  getOrderDiscounts,
  getOrderDiscountsSuccess,
  getOrderDiscountsError,
  getOrderClientAddresses,
  getOrderClientAddressesSuccess,
  getOrderClientAddressesError,
  getOrderClientPaymentMethods,
  getOrderClientPaymentMethodsSuccess,
  getOrderClientPaymentMethodsError,
  getOrderProductProviderInfos,
  getOrderProductProviderInfosSuccess,
  getOrderProductProviderInfosError,
  getOrderProductHistory,
  getOrderProductHistorySuccess,
  getOrderProductHistoryError,
  addOrderProductOption,
  addOrderProductOptionSuccess,
  addOrderProductOptionError,
  removeOrderProductOption,
  removeOrderProductOptionSuccess,
  removeOrderProductOptionError,
  getOrderProductDeliveryStatuses,
  getOrderProductDeliveryStatusesSuccess,
  getOrderProductDeliveryStatusesError,
  updateOrderProductDeliveryStatus,
  updateOrderProductDeliveryStatusSuccess,
  updateOrderProductDeliveryStatusError,
  updateOrderProductProvider,
  updateOrderProductProviderSuccess,
  updateOrderProductProviderError,
  createUpdateCalendar,
  clearOrderUpdatePlanning,
  setUpdateNbTimeMax,
  getOrderProductSlots,
  getOrderProductSlotsSuccess,
  getOrderProductSlotsError,
  getPreviousOrderProductSlots,
  getPreviousOrderProductSlotsSuccess,
  getPreviousOrderProductSlotsError,
  getNextOrderProductSlots,
  getNextOrderProductSlotsSuccess,
  getNextOrderProductSlotsError,
  updateOrderProductStartDate,
  updateOrderProductStartDateSuccess,
  updateOrderProductStartDateError,
  getOrderProductDispatchableProviders,
  getOrderProductDispatchableProvidersSuccess,
  getOrderProductDispatchableProvidersError,
  postOrderProductDispatchableProviders,
  postOrderProductDispatchableProvidersSuccess,
  postOrderProductDispatchableProvidersError,
  updateOrderProductPropertyDetails,
  updateOrderProductPropertyDetailsSuccess,
  updateOrderProductPropertyDetailsError,
  manageOrderProductFlag,
  manageOrderProductFlagSuccess,
  manageOrderProductFlagError
} from '../../../redux/actions/orders/update/ordersUpdateActions';
import OrdersUpdateView from '../../../views/orders/update/OrdersUpdateView';
import translation from '../../../translation/translation';

const mapStateToProps = (store) => ({
  update: store.orders.update
});

const mapDispatchToProps = (dispatch) => {
  return {
    clearOwnReducer() {
      dispatch(clearOrderUpdateReducer());
    },
    clearOrderUpdatePlanning() {
      dispatch(clearOrderUpdatePlanning());
    },
    async getOrder(orderId) {
      if (!orderId) return;

      dispatch(getOrder());

      try {
        await fetchApi(
          'get',
          `orders/${orderId}/full`,
          null,
          null,
          null,
          (data) => {
            dispatch(getOrderSuccess(data));
          },
          (error) => {
            dispatch(getOrderError(true, error));

            if (error.response) {
              dispatch(
                enqueueSnackbar({
                  message:
                    error.response.data && error.response.data.detail
                      ? error.response.data.detail
                      : error.response.data.status_code
                      ? error.response.data.status_code + ': ' + translation().core.item.not_found
                      : translation().views.orders.update.callbacks.get_order.error,
                  status: error.response.status || undefined,
                  options: {
                    variant: 'error'
                  }
                })
              );
            } else {
              dispatch(
                enqueueSnackbar({
                  message: translation().views.orders.update.callbacks.get_order.error,
                  options: {
                    variant: 'error'
                  }
                })
              );
            }
          }
        );
      } catch (error) {
        dispatch(getOrderError(true, error));
        dispatch(
          enqueueSnackbar({
            message: translation().views.orders.update.callbacks.get_order.error,
            options: {
              variant: 'error'
            }
          })
        );
      }
    },
    async getOrderDiscounts(orderId) {
      if (!orderId) return;

      dispatch(getOrderDiscounts());

      try {
        await fetchApi(
          'get',
          `orders/${orderId}/discounts`,
          null,
          null,
          null,
          (data) => {
            dispatch(getOrderDiscountsSuccess(data));
          },
          (error) => {
            dispatch(getOrderDiscountsError(true, error));

            if (error.response) {
              dispatch(
                enqueueSnackbar({
                  message:
                    error.response.data && error.response.data.detail
                      ? error.response.data.detail
                      : translation().views.orders.update.callbacks.get_discount.error,
                  status: error.response.status || undefined,
                  options: {
                    variant: 'error'
                  }
                })
              );
            } else {
              dispatch(
                enqueueSnackbar({
                  message: translation().views.orders.update.callbacks.get_discount.error,
                  options: {
                    variant: 'error'
                  }
                })
              );
            }
          }
        );
      } catch (error) {
        dispatch(getOrderDiscountsError(true, error));
        dispatch(
          enqueueSnackbar({
            message: translation().views.orders.update.callbacks.get_discount.error,
            options: {
              variant: 'error'
            }
          })
        );
      }
    },
    async deleteOrderDiscount(orderId, discountId, onSuccess) {
      if (!orderId || !discountId) return;

      dispatch(deleteOrderDiscount());

      try {
        await fetchApi(
          'delete',
          `orders/${orderId}/discounts/${discountId}`,
          null,
          null,
          null,
          (data) => {
            dispatch(deleteOrderDiscountSuccess(data));
            dispatch(
              enqueueSnackbar({
                message: translation().views.orders.update.callbacks.delete_discount.success,
                options: {
                  variant: 'success'
                }
              })
            );

            if (onSuccess && typeof onSuccess === 'function') onSuccess();
          },
          (error) => {
            dispatch(deleteOrderDiscountError(true, error));

            if (error.response) {
              dispatch(
                enqueueSnackbar({
                  message:
                    error.response.data && error.response.data.detail
                      ? error.response.data.detail
                      : error.response.data.status_code
                      ? error.response.data.status_code + ': ' + translation().core.item.not_found
                      : translation().views.orders.update.callbacks.delete_discount.error,
                  status: error.response.status || undefined,
                  options: {
                    variant: 'error'
                  }
                })
              );
            } else {
              dispatch(
                enqueueSnackbar({
                  message: translation().views.orders.update.callbacks.delete_discount.error,
                  options: {
                    variant: 'error'
                  }
                })
              );
            }
          }
        );
      } catch (error) {
        dispatch(deleteOrderDiscountError(true, error));
        dispatch(
          enqueueSnackbar({
            message: translation().views.orders.update.callbacks.delete_discount.error,
            options: {
              variant: 'error'
            }
          })
        );
      }
    },
    async addOrderDiscount(orderId, discountId, onSuccess) {
      if (!orderId || !discountId) return;

      dispatch(addOrderDiscount());

      try {
        await fetchApi(
          'post',
          `orders/${orderId}/discounts`,
          null,
          { discount_id: discountId },
          null,
          (data) => {
            dispatch(addOrderDiscountSuccess(data));
            dispatch(
              enqueueSnackbar({
                message: translation().views.orders.update.callbacks.add_discount.success,
                options: {
                  variant: 'success'
                }
              })
            );

            if (onSuccess && typeof onSuccess === 'function') onSuccess();
          },
          (error) => {
            dispatch(addOrderDiscountError(true, error));

            if (error.response) {
              dispatch(
                enqueueSnackbar({
                  message:
                    error.response.data && error.response.data.detail
                      ? error.response.data.detail
                      : translation().views.orders.update.callbacks.add_discount.error,
                  status: error.response.status || undefined,
                  options: {
                    variant: 'error'
                  }
                })
              );
            } else {
              dispatch(
                enqueueSnackbar({
                  message: translation().views.orders.update.callbacks.add_discount.error,
                  options: {
                    variant: 'error'
                  }
                })
              );
            }
          }
        );
      } catch (error) {
        dispatch(addOrderDiscountError(true, error));
        dispatch(
          enqueueSnackbar({
            message: translation().views.orders.update.callbacks.add_discount.error,
            options: {
              variant: 'error'
            }
          })
        );
      }
    },
    async getOrderClientAddresses(userId) {
      if (!userId) return;

      dispatch(getOrderClientAddresses());

      try {
        await fetchApi(
          'get',
          `users/${userId}/addresses/usable`,
          null,
          null,
          null,
          (data) => {
            dispatch(getOrderClientAddressesSuccess(data));
          },
          (error) => {
            dispatch(getOrderClientAddressesError(true, error));

            if (error.response) {
              dispatch(
                enqueueSnackbar({
                  message:
                    error.response.data && error.response.data.detail
                      ? error.response.data.detail
                      : translation().views.orders.create.callbacks.get_client_addresses.error,
                  status: error.response.status || undefined,
                  options: {
                    variant: 'error'
                  }
                })
              );
            } else {
              dispatch(
                enqueueSnackbar({
                  message: translation().views.orders.create.callbacks.get_client_addresses.error,
                  options: {
                    variant: 'error'
                  }
                })
              );
            }
          }
        );
      } catch (error) {
        dispatch(getOrderClientAddressesError(true, error));
        dispatch(
          enqueueSnackbar({
            message: translation().views.orders.create.callbacks.get_client_addresses.error,
            options: {
              variant: 'error'
            }
          })
        );
      }
    },
    async editOrderInvoiceAddress(orderId, addressId, onSuccess) {
      if (!orderId || !addressId) return;

      dispatch(editOrderInvoiceAddress());

      try {
        await fetchApi(
          'put',
          `orders/${orderId}/invoice-address`,
          null,
          { invoice_address_id: addressId },
          null,
          () => {
            dispatch(editOrderInvoiceAddressSuccess());
            dispatch(
              enqueueSnackbar({
                message: translation().views.orders.update.callbacks.edit_invoice_address.success,
                options: {
                  variant: 'success'
                }
              })
            );

            if (onSuccess && typeof onSuccess === 'function') onSuccess();
          },
          (error) => {
            dispatch(editOrderInvoiceAddressError(true, error));

            if (error.response) {
              dispatch(
                enqueueSnackbar({
                  message:
                    error.response.data && error.response.data.detail
                      ? error.response.data.detail
                      : translation().views.orders.update.callbacks.edit_invoice_address.error,
                  status: error.response.status || undefined,
                  options: {
                    variant: 'error'
                  }
                })
              );
            } else {
              dispatch(
                enqueueSnackbar({
                  message: translation().views.orders.update.callbacks.edit_invoice_address.error,
                  options: {
                    variant: 'error'
                  }
                })
              );
            }
          }
        );
      } catch (error) {
        dispatch(editOrderInvoiceAddressError(true, error));
        dispatch(
          enqueueSnackbar({
            message: translation().views.orders.update.callbacks.edit_invoice_address.error,
            options: {
              variant: 'error'
            }
          })
        );
      }
    },
    async getOrderClientPaymentMethods(userId, currency) {
      if (!userId || !currency || !currency.isocode) return;

      dispatch(getOrderClientPaymentMethods());

      try {
        await fetchApi(
          'get',
          `users/${userId}/payment-methods/usable`,
          { currency: currency.isocode },
          null,
          null,
          (data) => {
            dispatch(getOrderClientPaymentMethodsSuccess(data));
          },
          (error) => {
            dispatch(getOrderClientPaymentMethodsError(true, error));

            if (error.response) {
              dispatch(
                enqueueSnackbar({
                  message:
                    error.response.data && error.response.data.detail
                      ? error.response.data.detail
                      : translation().views.orders.create.callbacks.get_client_payment_methods
                          .error,
                  status: error.response.status || undefined,
                  options: {
                    variant: 'error'
                  }
                })
              );
            } else {
              dispatch(
                enqueueSnackbar({
                  message:
                    translation().views.orders.create.callbacks.get_client_payment_methods.error,
                  options: {
                    variant: 'error'
                  }
                })
              );
            }
          }
        );
      } catch (error) {
        dispatch(getOrderClientPaymentMethodsError(true, error));
        dispatch(
          enqueueSnackbar({
            message: translation().views.orders.create.callbacks.get_client_payment_methods.error,
            options: {
              variant: 'error'
            }
          })
        );
      }
    },
    async editOrderPaymentMethod(orderId, methodId, onSuccess) {
      if (!orderId || !methodId) return;

      dispatch(editOrderPaymentMethod());

      try {
        await fetchApi(
          'put',
          `orders/${orderId}/invoice-payment-method`,
          null,
          { invoice_payment_method_id: methodId },
          null,
          () => {
            dispatch(editOrderPaymentMethodSuccess());
            dispatch(
              enqueueSnackbar({
                message: translation().views.orders.update.callbacks.edit_payment_method.success,
                options: {
                  variant: 'success'
                }
              })
            );

            if (onSuccess && typeof onSuccess === 'function') onSuccess();
          },
          (error) => {
            dispatch(editOrderPaymentMethodError(true, error));

            if (error.response) {
              dispatch(
                enqueueSnackbar({
                  message:
                    error.response.data && error.response.data.detail
                      ? error.response.data.detail
                      : translation().views.orders.update.callbacks.edit_payment_method.error,
                  status: error.response.status || undefined,
                  options: {
                    variant: 'error'
                  }
                })
              );
            } else {
              dispatch(
                enqueueSnackbar({
                  message: translation().views.orders.update.callbacks.edit_payment_method.error,
                  options: {
                    variant: 'error'
                  }
                })
              );
            }
          }
        );
      } catch (error) {
        dispatch(editOrderPaymentMethodError(true, error));
        dispatch(
          enqueueSnackbar({
            message: translation().views.orders.update.callbacks.edit_payment_method.error,
            options: {
              variant: 'error'
            }
          })
        );
      }
    },
    async getOrderProductHistory(orderId, orderProductId) {
      if (!orderId || !orderProductId) return;

      dispatch(getOrderProductHistory());

      try {
        await fetchApi(
          'get',
          `orders/${orderId}/products/${orderProductId}/history`,
          null,
          null,
          null,
          (data) => {
            dispatch(getOrderProductHistorySuccess(data));
          },
          (error) => {
            dispatch(getOrderProductHistoryError(true, error));

            if (error.response) {
              dispatch(
                enqueueSnackbar({
                  message:
                    error.response.data && error.response.data.detail
                      ? error.response.data.detail
                      : translation().views.orders.update.products.callbacks
                          .get_order_product_history.error,
                  status: error.response.status || undefined,
                  options: {
                    variant: 'error'
                  }
                })
              );
            } else {
              dispatch(
                enqueueSnackbar({
                  message:
                    translation().views.orders.update.products.callbacks.get_order_product_history
                      .error,
                  options: {
                    variant: 'error'
                  }
                })
              );
            }
          }
        );
      } catch (error) {
        dispatch(getOrderProductHistoryError(true, error));
        dispatch(
          enqueueSnackbar({
            message:
              translation().views.orders.update.products.callbacks.get_order_product_history.error,
            options: {
              variant: 'error'
            }
          })
        );
      }
    },
    async getOrderProductProviderInfos(providerId, orderProductId) {
      if (!providerId || !orderProductId) return;

      dispatch(getOrderProductProviderInfos());

      try {
        await fetchApi(
          'get',
          `providers/${providerId}/order-products/${orderProductId}`,
          null,
          null,
          null,
          (data) => {
            dispatch(getOrderProductProviderInfosSuccess(data));
          },
          (error) => {
            dispatch(getOrderProductProviderInfosError(true, error));

            if (error.response) {
              dispatch(
                enqueueSnackbar({
                  message:
                    error.response.data && error.response.data.detail
                      ? error.response.data.detail
                      : translation().views.orders.update.products.callbacks.get_provider_infos
                          .error,
                  status: error.response.status || undefined,
                  options: {
                    variant: 'error'
                  }
                })
              );
            } else {
              dispatch(
                enqueueSnackbar({
                  message:
                    translation().views.orders.update.products.callbacks.get_provider_infos.error,
                  options: {
                    variant: 'error'
                  }
                })
              );
            }
          }
        );
      } catch (error) {
        dispatch(getOrderProductProviderInfosError(true, error));
        dispatch(
          enqueueSnackbar({
            message: translation().views.orders.update.products.callbacks.get_provider_infos.error,
            options: {
              variant: 'error'
            }
          })
        );
      }
    },
    async cancelOrderProduct(orderId, orderProductId, onSuccess) {
      if (!orderId || !orderProductId) return;

      dispatch(cancelOrderProduct());

      try {
        await fetchApi(
          'post',
          `orders/${orderId}/products/${orderProductId}/cancel`,
          null,
          null,
          null,
          () => {
            dispatch(cancelOrderProductSuccess());
            dispatch(
              enqueueSnackbar({
                message:
                  translation().views.orders.update.products.callbacks.cancel_product.success,
                options: {
                  variant: 'success'
                }
              })
            );

            if (onSuccess && typeof onSuccess === 'function') onSuccess();
          },
          (error) => {
            dispatch(cancelOrderProductError(true, error));

            if (error.response) {
              dispatch(
                enqueueSnackbar({
                  message:
                    error.response.data && error.response.data.detail
                      ? error.response.data.detail
                      : translation().views.orders.update.products.callbacks.cancel_product.error,
                  status: error.response.status || undefined,
                  options: {
                    variant: 'error'
                  }
                })
              );
            } else {
              dispatch(
                enqueueSnackbar({
                  message:
                    translation().views.orders.update.products.callbacks.cancel_product.error,
                  options: {
                    variant: 'error'
                  }
                })
              );
            }
          }
        );
      } catch (error) {
        dispatch(cancelOrderProductError(true, error));
        dispatch(
          enqueueSnackbar({
            message: translation().views.orders.update.products.callbacks.cancel_product.error,
            options: {
              variant: 'error'
            }
          })
        );
      }
    },
    async addOrderProductOption(orderId, orderProductId, value, onSuccess) {
      if (!orderId || !orderProductId || !value) return;

      dispatch(addOrderProductOption());

      try {
        await fetchApi(
          'post',
          `orders/${orderId}/products/${orderProductId}/options`,
          null,
          { product_option_id: value },
          null,
          () => {
            dispatch(addOrderProductOptionSuccess());
            dispatch(
              enqueueSnackbar({
                message:
                  translation().views.orders.update.products.callbacks.add_order_product_option
                    .success,
                options: {
                  variant: 'success'
                }
              })
            );

            if (onSuccess && typeof onSuccess === 'function') onSuccess();
          },
          (error) => {
            dispatch(addOrderProductOptionError(true, error));

            if (error.response) {
              dispatch(
                enqueueSnackbar({
                  message:
                    error.response.data && error.response.data.detail
                      ? error.response.data.detail
                      : translation().views.orders.update.products.callbacks
                          .add_order_product_option.error,
                  status: error.response.status || undefined,
                  options: {
                    variant: 'error'
                  }
                })
              );
            } else {
              dispatch(
                enqueueSnackbar({
                  message:
                    translation().views.orders.update.products.callbacks.add_order_product_option
                      .error,
                  options: {
                    variant: 'error'
                  }
                })
              );
            }
          }
        );
      } catch (error) {
        dispatch(addOrderProductOptionError(true, error));
        dispatch(
          enqueueSnackbar({
            message:
              translation().views.orders.update.products.callbacks.add_order_product_option.error,
            options: {
              variant: 'error'
            }
          })
        );
      }
    },
    async removeOrderProductOption(orderId, orderProductId, optionId, onSuccess) {
      if (!orderId || !orderProductId || !optionId) return;

      dispatch(removeOrderProductOption());

      try {
        await fetchApi(
          'delete',
          `orders/${orderId}/products/${orderProductId}/options/${optionId}`,
          null,
          null,
          null,
          () => {
            dispatch(removeOrderProductOptionSuccess());
            dispatch(
              enqueueSnackbar({
                message:
                  translation().views.orders.update.products.callbacks.remove_order_product_option
                    .success,
                options: {
                  variant: 'success'
                }
              })
            );

            if (onSuccess && typeof onSuccess === 'function') onSuccess();
          },
          (error) => {
            dispatch(removeOrderProductOptionError(true, error));

            if (error.response) {
              dispatch(
                enqueueSnackbar({
                  message:
                    error.response.data && error.response.data.detail
                      ? error.response.data.detail
                      : translation().views.orders.update.products.callbacks
                          .remove_order_product_option.error,
                  status: error.response.status || undefined,
                  options: {
                    variant: 'error'
                  }
                })
              );
            } else {
              dispatch(
                enqueueSnackbar({
                  message:
                    translation().views.orders.update.products.callbacks.remove_order_product_option
                      .error,
                  options: {
                    variant: 'error'
                  }
                })
              );
            }
          }
        );
      } catch (error) {
        dispatch(removeOrderProductOptionError(true, error));
        dispatch(
          enqueueSnackbar({
            message:
              translation().views.orders.update.products.callbacks.remove_order_product_option
                .error,
            options: {
              variant: 'error'
            }
          })
        );
      }
    },
    async getOrderProductDeliveryStatuses() {
      dispatch(getOrderProductDeliveryStatuses());

      try {
        await fetchApi(
          'get',
          `delivery-statuses`,
          null,
          null,
          null,
          (data) => {
            dispatch(getOrderProductDeliveryStatusesSuccess(data));
          },
          (error) => {
            dispatch(getOrderProductDeliveryStatusesError(true, error));

            if (error.response) {
              dispatch(
                enqueueSnackbar({
                  message:
                    error.response.data && error.response.data.detail
                      ? error.response.data.detail
                      : translation().views.orders.update.products.callbacks.get_delivery_status
                          .error,
                  status: error.response.status || undefined,
                  options: {
                    variant: 'error'
                  }
                })
              );
            } else {
              dispatch(
                enqueueSnackbar({
                  message:
                    translation().views.orders.update.products.callbacks.get_delivery_status.error,
                  options: {
                    variant: 'error'
                  }
                })
              );
            }
          }
        );
      } catch (error) {
        dispatch(getOrderProductDeliveryStatusesError(true, error));
        dispatch(
          enqueueSnackbar({
            message: translation().views.orders.update.products.callbacks.get_delivery_status.error,
            options: {
              variant: 'error'
            }
          })
        );
      }
    },
    async updateOrderProductDeliveryStatus(orderId, orderProductId, status, onSuccess) {
      if (!orderId || !orderProductId || !status) return;

      dispatch(updateOrderProductDeliveryStatus());

      try {
        await fetchApi(
          'put',
          `orders/${orderId}/products/${orderProductId}/delivery-status`,
          null,
          { delivery_status_id: status },
          null,
          () => {
            dispatch(updateOrderProductDeliveryStatusSuccess());

            if (onSuccess && typeof onSuccess === 'function') onSuccess();
          },
          (error) => {
            dispatch(updateOrderProductDeliveryStatusError(true, error));

            if (error.response) {
              dispatch(
                enqueueSnackbar({
                  message:
                    error.response.data && error.response.data.detail
                      ? error.response.data.detail
                      : translation().views.orders.update.products.callbacks.get_delivery_status
                          .error,
                  status: error.response.status || undefined,
                  options: {
                    variant: 'error'
                  }
                })
              );
            } else {
              dispatch(
                enqueueSnackbar({
                  message:
                    translation().views.orders.update.products.callbacks.get_delivery_status.error,
                  options: {
                    variant: 'error'
                  }
                })
              );
            }
          }
        );
      } catch (error) {
        dispatch(updateOrderProductDeliveryStatusError(true, error));
        dispatch(
          enqueueSnackbar({
            message: translation().views.orders.update.products.callbacks.get_delivery_status.error,
            options: {
              variant: 'error'
            }
          })
        );
      }
    },
    async updateOrderProductProvider(orderId, orderProductId, id, isForcing, onSuccess) {
      dispatch(updateOrderProductProvider());

      try {
        await fetchApi(
          'put',
          isForcing
            ? `orders/${orderId}/products/${orderProductId}/provider/force`
            : `orders/${orderId}/products/${orderProductId}/provider`,
          null,
          { provider_id: id },
          null,
          () => {
            dispatch(updateOrderProductProviderSuccess());
            dispatch(
              enqueueSnackbar({
                message:
                  translation().views.orders.update.products.callbacks.put_order_product_provider
                    .success,
                options: {
                  variant: 'success'
                }
              })
            );

            if (onSuccess && typeof onSuccess === 'function') onSuccess();
          },
          (error) => {
            dispatch(updateOrderProductProviderError(true, error));

            if (error.response) {
              dispatch(
                enqueueSnackbar({
                  message:
                    error.response.data && error.response.data.detail
                      ? error.response.data.detail
                      : translation().views.orders.update.products.callbacks
                          .put_order_product_provider.error,
                  status: error.response.status || undefined,
                  options: {
                    variant: 'error'
                  }
                })
              );
            } else {
              dispatch(
                enqueueSnackbar({
                  message:
                    translation().views.orders.update.products.callbacks.put_order_product_provider
                      .error,
                  options: {
                    variant: 'error'
                  }
                })
              );
            }
          }
        );
      } catch (error) {
        dispatch(updateOrderProductProviderError(true, error));
        dispatch(
          enqueueSnackbar({
            message:
              translation().views.orders.update.products.callbacks.put_order_product_provider.error,
            options: {
              variant: 'error'
            }
          })
        );
      }
    },
    getOrderProductSlots(
      orderId,
      orderProductId,
      options,
      startDate,
      endDate,
      timezone = undefined
    ) {
      if (!orderId || !orderProductId) return;

      dispatch(getOrderProductSlots());

      const query = {};

      query.start = startDate;
      query.end = endDate;

      if (options && options.length > 0) query.options = options;

      fetchApi(
        'get',
        `orders/${orderId}/products/${orderProductId}/slots`,
        query,
        null,
        null,
        (slots) => {
          dispatch(getOrderProductSlotsSuccess(slots));

          const calendar = {};
          let nbTimesMax = 0;

          /**
           * Create calendar days
           */
          for (let i = 0; i < 7; i++) {
            let nowTz = dateInTz(startDate, null, timezone);
            let day = addToDate(nowTz, i, 'days', 'YYYY-MM-DD');
            calendar[day] = [];
          }

          /**
           * Create slots time and push them to their day
           */
          if (slots) {
            for (let j = 0; j < slots.length; j++) {
              let slotDate = dateInTz(slots[j], 'YYYY-MM-DD', timezone);
              let slotTime = dateInTz(slots[j], 'HH:mm', timezone);

              if (calendar[slotDate]) {
                calendar[slotDate].push(slotTime);
              }
            }
          }

          /**
           * Push new calendar to component state
           */
          dispatch(createUpdateCalendar(calendar));

          /**
           * Calculate the number max of slots in all days
           */
          for (let slotDate in calendar) {
            nbTimesMax = Math.max(5, nbTimesMax, calendar[slotDate].length);
            dispatch(setUpdateNbTimeMax(nbTimesMax));
          }
        },
        (error) => {
          dispatch(
            getOrderProductSlotsError(
              error.response && error.response.data && error.response.data.detail
                ? error.response.data.detail
                : translation().views.orders.create.callbacks.get_slots.error
            )
          );

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().views.orders.create.callbacks.get_slots.error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().views.orders.create.callbacks.get_slots.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    },
    getNextOrderProductSlots(
      orderId,
      orderProductId,
      options,
      startDate,
      endDate,
      timezone = undefined
    ) {
      if (!orderId || !orderProductId) return;

      dispatch(getNextOrderProductSlots());

      const query = {};

      if (options && options.length > 0) query.options = options;

      const start = addToDate(startDate, 7, 'days');
      const end = addToDate(endDate, 7, 'days');
      const startDateFormated = formatDate(start, 'YYYY-MM-DD');
      const endDateFormated = formatDate(end, 'YYYY-MM-DD');

      query.start = startDateFormated;
      query.end = endDateFormated;

      fetchApi(
        'get',
        `orders/${orderId}/products/${orderProductId}/slots`,
        query,
        null,
        null,
        (slots) => {
          dispatch(getNextOrderProductSlotsSuccess(slots));

          const calendar = {};
          let nbTimesMax = 0;

          for (let i = 0; i < 7; i++) {
            let nowTz = dateInTz(start, null, timezone);
            let day = addToDate(nowTz, i, 'days', 'YYYY-MM-DD');
            calendar[day] = [];
          }

          if (slots) {
            for (let j = 0; j < slots.length; j++) {
              let slotDate = dateInTz(slots[j], 'YYYY-MM-DD', timezone);
              let slotTime = dateInTz(slots[j], 'HH:mm', timezone);

              if (calendar[slotDate]) {
                calendar[slotDate].push(slotTime);
              }
            }
          }

          dispatch(createUpdateCalendar(calendar, start, end));

          /**
           * Calculate the number max of slots in all days
           */
          for (let slotDate in calendar) {
            nbTimesMax = Math.max(5, nbTimesMax, calendar[slotDate].length);
            dispatch(setUpdateNbTimeMax(nbTimesMax));
          }
        },
        (error) => {
          dispatch(
            getNextOrderProductSlotsError(
              error.response && error.response.data && error.response.data.detail
                ? error.response.data.detail
                : translation().views.orders.create.callbacks.get_slots.error
            )
          );

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().views.orders.create.callbacks.get_slots.error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().views.orders.create.callbacks.get_slots.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    },
    getPreviousOrderProductSlots(
      orderId,
      orderProductId,
      options,
      startDate,
      endDate,
      timezone = undefined
    ) {
      if (!orderId || !orderProductId) return;

      dispatch(getPreviousOrderProductSlots());

      const query = {};

      if (options && options.length > 0) query.options = options;

      const start = addToDate(startDate, -7, 'days');
      const end = addToDate(endDate, -7, 'days');
      const startDateFormated = formatDate(start, 'YYYY-MM-DD');
      const endDateFormated = formatDate(end, 'YYYY-MM-DD');

      query.start = startDateFormated;
      query.end = endDateFormated;

      fetchApi(
        'get',
        `orders/${orderId}/products/${orderProductId}/slots`,
        query,
        null,
        null,
        (slots) => {
          dispatch(getPreviousOrderProductSlotsSuccess(slots));

          const calendar = {};
          let nbTimesMax = 0;

          for (let i = 0; i < 7; i++) {
            let nowTz = dateInTz(start, null, timezone);
            let day = addToDate(nowTz, i, 'days', 'YYYY-MM-DD');
            calendar[day] = [];
          }

          if (slots) {
            for (let j = 0; j < slots.length; j++) {
              let slotDate = dateInTz(slots[j], 'YYYY-MM-DD', timezone);
              let slotTime = dateInTz(slots[j], 'HH:mm', timezone);

              if (calendar[slotDate]) {
                calendar[slotDate].push(slotTime);
              }
            }
          }

          dispatch(createUpdateCalendar(calendar, start, end));

          for (let slotDate in calendar) {
            nbTimesMax = Math.max(5, nbTimesMax, calendar[slotDate].length);
            dispatch(setUpdateNbTimeMax(nbTimesMax));
          }
        },
        (error) => {
          dispatch(
            getPreviousOrderProductSlotsError(
              error.response && error.response.data && error.response.data.detail
                ? error.response.data.detail
                : translation().views.orders.create.callbacks.get_slots.error
            )
          );

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().views.orders.create.callbacks.get_slots.error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().views.orders.create.callbacks.get_slots.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    },
    async updateOrderProductStartDate(orderId, orderProductId, start, isForcing, onSuccess) {
      if (!orderId || !orderProductId || !start) return;

      const localStart = createDate(start);
      const utcStart = createDateUtc(localStart).toString();
      const startForced = formatDate(utcStart, 'YYYY-MM-DDTHH:mm');

      const data = {
        start: isForcing ? startForced : start
      };

      dispatch(updateOrderProductStartDate());

      try {
        await fetchApi(
          'put',
          isForcing
            ? `orders/${orderId}/products/${orderProductId}/start/force`
            : `orders/${orderId}/products/${orderProductId}/start`,
          null,
          data,
          null,
          () => {
            dispatch(updateOrderProductStartDateSuccess());
            dispatch(
              enqueueSnackbar({
                message:
                  translation().views.orders.update.products.callbacks.put_order_product_start
                    .success,
                options: {
                  variant: 'success'
                }
              })
            );

            if (onSuccess && typeof onSuccess === 'function') onSuccess();
          },
          (error) => {
            dispatch(updateOrderProductStartDateError(true, error));

            if (error.response) {
              dispatch(
                enqueueSnackbar({
                  message:
                    error.response.data && error.response.data.detail
                      ? error.response.data.detail
                      : translation().views.orders.update.products.callbacks.put_order_product_start
                          .error,
                  status: error.response.status || undefined,
                  options: {
                    variant: 'error'
                  }
                })
              );
            } else {
              dispatch(
                enqueueSnackbar({
                  message:
                    translation().views.orders.update.products.callbacks.put_order_product_start
                      .error,
                  options: {
                    variant: 'error'
                  }
                })
              );
            }
          }
        );
      } catch (error) {
        dispatch(updateOrderProductStartDateError(true, error));
        dispatch(
          enqueueSnackbar({
            message:
              translation().views.orders.update.products.callbacks.put_order_product_start.error,
            options: {
              variant: 'error'
            }
          })
        );
      }
    },
    async getOrderProductDispatchableProviders(orderId, productId, onSuccess = null) {
      if (!orderId || !productId) return;

      dispatch(getOrderProductDispatchableProviders());

      try {
        await fetchApi(
          'get',
          `orders/${orderId}/products/${productId}/dispatchable-providers`,
          null,
          null,
          null,
          (data) => {
            dispatch(getOrderProductDispatchableProvidersSuccess(data));

            if (onSuccess && typeof onSuccess === 'function') {
              onSuccess(data);
            }
          },
          (error) => {
            dispatch(getOrderProductDispatchableProvidersError(error));

            if (error.response) {
              dispatch(
                enqueueSnackbar({
                  message:
                    error.response.data && error.response.data.detail
                      ? error.response.data.detail
                      : translation().views.orders.update.products.callbacks
                          .get_order_product_dispatchable_providers.error,
                  status: error.response.status || undefined,
                  options: {
                    variant: 'error'
                  }
                })
              );
            } else {
              dispatch(
                enqueueSnackbar({
                  message:
                    translation().views.orders.update.products.callbacks
                      .get_order_product_dispatchable_providers.error,
                  options: {
                    variant: 'error'
                  }
                })
              );
            }
          }
        );
      } catch (error) {
        dispatch(getOrderProductDispatchableProvidersError(error));
        dispatch(
          enqueueSnackbar({
            message:
              translation().views.orders.update.products.callbacks
                .get_order_product_dispatchable_providers.error,
            options: {
              variant: 'error'
            }
          })
        );
      }
    },
    async postOrderProductDispatchableProviders(orderId, productId, values, onSuccess = null) {
      if (!orderId || !productId) return;

      dispatch(postOrderProductDispatchableProviders());

      try {
        await fetchApi(
          'post',
          `orders/${orderId}/products/${productId}/send-dispatch-invites`,
          null,
          { providers: values },
          null,
          () => {
            dispatch(postOrderProductDispatchableProvidersSuccess());

            if (onSuccess && typeof onSuccess === 'function') {
              onSuccess();
            }

            dispatch(
              enqueueSnackbar({
                message:
                  translation().views.orders.update.products.callbacks
                    .send_order_product_dispatchable_providers.success,
                options: {
                  variant: 'success'
                }
              })
            );
          },
          (error) => {
            dispatch(postOrderProductDispatchableProvidersError(error));

            if (error.response) {
              dispatch(
                enqueueSnackbar({
                  message:
                    error.response.data && error.response.data.detail
                      ? error.response.data.detail
                      : translation().views.orders.update.products.callbacks
                          .send_order_product_dispatchable_providers.error,
                  status: error.response.status || undefined,
                  options: {
                    variant: 'error'
                  }
                })
              );
            } else {
              dispatch(
                enqueueSnackbar({
                  message:
                    translation().views.orders.update.products.callbacks
                      .send_order_product_dispatchable_providers.error,
                  options: {
                    variant: 'error'
                  }
                })
              );
            }
          }
        );
      } catch (error) {
        dispatch(postOrderProductDispatchableProvidersError(error));
        dispatch(
          enqueueSnackbar({
            message:
              translation().views.orders.update.products.callbacks
                .send_order_product_dispatchable_providers.error,
            options: {
              variant: 'error'
            }
          })
        );
      }
    },
    async updateOrderProductPropertyDetails(orderId, productId, values = {}, onSuccess = null) {
      if (!orderId || !productId) return;

      const data = { ...values };

      dispatch(updateOrderProductPropertyDetails());

      try {
        await fetchApi(
          'put',
          `orders/${orderId}/products/${productId}/details`,
          null,
          data,
          null,
          () => {
            dispatch(updateOrderProductPropertyDetailsSuccess());

            dispatch(
              enqueueSnackbar({
                message:
                  translation().views.orders.update.products.callbacks
                    .update_ortder_product_property_details.success,
                options: {
                  variant: 'success'
                }
              })
            );

            if (onSuccess && typeof onSuccess === 'function') {
              onSuccess();
            }
          },
          (error) => {
            dispatch(updateOrderProductPropertyDetailsError(error));

            if (error.response) {
              dispatch(
                enqueueSnackbar({
                  message:
                    error.response.data && error.response.data.detail
                      ? error.response.data.detail
                      : translation().views.orders.update.products.callbacks
                          .update_ortder_product_property_details.error,
                  status: error.response.status || undefined,
                  options: {
                    variant: 'error'
                  }
                })
              );
            } else {
              dispatch(
                enqueueSnackbar({
                  message:
                    translation().views.orders.update.products.callbacks
                      .update_ortder_product_property_details.error,
                  options: {
                    variant: 'error'
                  }
                })
              );
            }
          }
        );
      } catch (error) {
        dispatch(updateOrderProductPropertyDetailsError(error));
        dispatch(
          enqueueSnackbar({
            message:
              translation().views.orders.update.products.callbacks
                .update_ortder_product_property_details.error,
            options: {
              variant: 'error'
            }
          })
        );
      }
    },
    async manageOrderProductFlag(orderId, productId, value, onSuccess = null) {
      if (!orderId || !productId) return;

      const data = { flag: value };

      const successAddMessage =
        translation().views.orders.update.products.callbacks.manage_order_product_flag.success_add;
      const successRemoveMessage =
        translation().views.orders.update.products.callbacks.manage_order_product_flag
          .success_remove;
      const errorAddMessage =
        translation().views.orders.update.products.callbacks.manage_order_product_flag.error_add;
      const errorRemoveMessage =
        translation().views.orders.update.products.callbacks.manage_order_product_flag.error_remove;

      dispatch(manageOrderProductFlag());

      try {
        await fetchApi(
          'put',
          `orders/${orderId}/products/${productId}/flag`,
          null,
          data,
          null,
          () => {
            dispatch(manageOrderProductFlagSuccess());

            dispatch(
              enqueueSnackbar({
                message: !value ? successRemoveMessage : successAddMessage,
                options: {
                  variant: 'success'
                }
              })
            );

            if (onSuccess && typeof onSuccess === 'function') {
              onSuccess();
            }
          },
          (error) => {
            dispatch(manageOrderProductFlagError(error));

            if (error.response) {
              dispatch(
                enqueueSnackbar({
                  message:
                    error.response.data && error.response.data.detail
                      ? error.response.data.detail
                      : !value
                      ? errorRemoveMessage
                      : errorAddMessage,
                  status: error.response.status || undefined,
                  options: {
                    variant: 'error'
                  }
                })
              );
            } else {
              dispatch(
                enqueueSnackbar({
                  message: !value ? errorRemoveMessage : errorAddMessage,
                  options: {
                    variant: 'error'
                  }
                })
              );
            }
          }
        );
      } catch (error) {
        dispatch(manageOrderProductFlagError(error));
        dispatch(
          enqueueSnackbar({
            message: !value ? errorRemoveMessage : errorAddMessage,
            options: {
              variant: 'error'
            }
          })
        );
      }
    },
    updateOrderProductAttributes(orderId, orderProductId, values, onSuccess, onError) {
      if (!orderId || !orderProductId) return;

      fetchApi(
        'put',
        `orders/${orderId}/products/${orderProductId}/attributes`,
        null,
        { attributes: values },
        null,
        () => {
          dispatch(cancelOrderProductSuccess());
          dispatch(
            enqueueSnackbar({
              message: translation().views.orders.update.products.product_attributes.success,
              options: {
                variant: 'success'
              }
            })
          );

          if (onSuccess && typeof onSuccess === 'function') onSuccess();
        },
        (error) => {
          if (onError && typeof onError === 'function') onError();

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().views.orders.update.products.product_attributes.error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().views.orders.update.products.product_attributes.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrdersUpdateView));
