import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import ErrorSharpIcon from '@material-ui/icons/ErrorSharp';

import { getTimezone } from '../../../../../../utils/functions/cookies';
import translation from '../../../../../../translation/translation';

const ScheduleEventMonth = ({ event }) => {
  return (
    <div className={classNames({ [`${event.className}`]: event.className })}>
      <Typography variant="caption">
        {event.title
          ? event.title
          : event && event.type === 'unavailability'
          ? translation().views.provider_schedule.unavailability.unavailability
          : ''}
      </Typography>
      {event.type === 'orderProduct' &&
        event.timezone_code &&
        getTimezone() !== event.timezone_code && <ErrorSharpIcon className="timezone-warning" />}
    </div>
  );
};

ScheduleEventMonth.propTypes = {
  event: PropTypes.shape({
    className: PropTypes.string,
    type: PropTypes.string,
    timezone_code: PropTypes.string,
    title: PropTypes.any,
    style: PropTypes.shape()
  }).isRequired
};

export default ScheduleEventMonth;
