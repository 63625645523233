export const setMetricToDashboard = (metric) => ({
  type: 'SET_METRIC_TO_DASHBOARD',
  metric
});

export const moveMetricsIntoDashboard = (metrics) => ({
  type: 'MOVE_METRICS_INTO_DASHBOARD',
  metrics
});

export const setDashboardDateRange = (range) => ({
  type: 'SET_DASHBOARD_DATE_RANGE',
  range
});

export const removeMetricFromDashboard = (id) => ({
  type: 'REMOVE_METRIC_FROM_DASHBOARD',
  id
});

export const getDashboard = () => ({
  type: 'GET_DASHBOARD'
});

export const getDashboardSuccess = (data) => ({
  type: 'GET_DASHBOARD_SUCCESS',
  data
});

export const getDashboardError = (error) => ({
  type: 'GET_DASHBOARD_ERROR',
  error
});

export const clearDashboard = () => ({
  type: 'CLEAR_DASHBOARD'
});
