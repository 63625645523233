export const cleanProductViewReducer = () => ({
  type: 'CLEAN_PRODUCT_VIEW_REDUCER'
});

export const cleanProductViewOptionsReducer = () => ({
  type: 'CLEAN_PRODUCT_VIEW_OPTIONS_REDUCER'
});

export const cleanProductViewMediaTypesReducer = () => ({
  type: 'CLEAN_PRODUCT_VIEW_MEDIA_TYPES_REDUCER'
});

export const cleanProductViewHostingPlansReducer = () => ({
  type: 'CLEAN_PRODUCT_VIEW_HOSTING_PLANS_REDUCER'
});

export const getProduct = () => ({
  type: 'GET_PRODUCT_VIEW'
});

export const getProductSuccess = (data) => ({
  type: 'GET_PRODUCT_VIEW_SUCCESS',
  data
});

export const getProductError = (error) => ({
  type: 'GET_PRODUCT_VIEW_ERROR',
  error
});

export const getProductOptions = (next) => ({
  type: 'GET_PRODUCT_VIEW_OPTIONS',
  next
});

export const getProductOptionsSuccess = (data, paging) => ({
  type: 'GET_PRODUCT_VIEW_OPTIONS_SUCCESS',
  data,
  paging
});

export const getProductOptionsError = (error) => ({
  type: 'GET_PRODUCT_VIEW_OPTIONS_ERROR',
  error
});

export const getProductMediaTypes = (next) => ({
  type: 'GET_PRODUCT_VIEW_MEDIA_TYPES',
  next
});

export const getProductMediaTypesSuccess = (data, paging) => ({
  type: 'GET_PRODUCT_VIEW_MEDIA_TYPES_SUCCESS',
  data,
  paging
});

export const getProductMediaTypesError = (error) => ({
  type: 'GET_PRODUCT_VIEW_MEDIA_TYPES_ERROR',
  error
});

export const getProductHostingPlans = (next) => ({
  type: 'GET_PRODUCT_VIEW_HOSTING_PLANS',
  next
});

export const getProductHostingPlansSuccess = (data, paging) => ({
  type: 'GET_PRODUCT_VIEW_HOSTING_PLANS_SUCCESS',
  data,
  paging
});

export const getProductHostingPlansError = (error) => ({
  type: 'GET_PRODUCT_VIEW_HOSTING_PLANS_ERROR',
  error
});
