import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchApi } from '../../utils/functions/api';
import { enqueueSnackbar } from '../../redux/actions/appActions';
import {
  cleanUserViewInvoicesReducer,
  cleanUserViewOrdersReducer,
  cleanUserViewInvoicesEmittedReducer,
  cleanUserViewPaymentMethodReducer,
  cleanUserViewDevicesReducer,
  cleanUserViewAddressesReducer,
  cleanUserViewRatingsReducer,
  getUser,
  getUserSuccess,
  getUserError,
  getUserViewOrders,
  getUserViewOrdersSuccess,
  getUserViewOrdersError,
  getUserViewOrdersMetadata,
  getUserViewOrdersMetadataSuccess,
  getUserViewOrdersMetadataError,
  getUserViewInvoices,
  getUserViewInvoicesSuccess,
  getUserViewInvoicesError,
  getUserViewInvoicesEmitted,
  getUserViewInvoicesEmittedSuccess,
  getUserViewInvoicesEmittedError,
  getUserViewPaymentMethods,
  getUserViewPaymentMethodsSuccess,
  getUserViewPaymentMethodsError,
  getUserViewDevices,
  getUserViewDevicesSuccess,
  getUserViewDevicesError,
  getUserViewAddresses,
  getUserViewAddressesSuccess,
  getUserViewAddressesError,
  getUserViewRatings,
  getUserViewRatingsSuccess,
  getUserViewRatingsError
} from '../../redux/actions/views/userViewActions';
import translation from '../../translation/translation';
import UserView from '../../views/users/userView/UserView';

const mapStateToProps = (store) => ({
  user: store.views.user
});

const mapDispatchToProps = (dispatch) => {
  return {
    cleanUserViewInvoicesReducer() {
      dispatch(cleanUserViewInvoicesReducer());
    },
    cleanUserViewOrdersReducer() {
      dispatch(cleanUserViewOrdersReducer());
    },
    cleanUserViewInvoicesEmittedReducer() {
      dispatch(cleanUserViewInvoicesEmittedReducer());
    },
    cleanUserViewPaymentMethodReducer() {
      dispatch(cleanUserViewPaymentMethodReducer());
    },
    cleanUserViewDevicesReducer() {
      dispatch(cleanUserViewDevicesReducer());
    },
    cleanUserViewAddressesReducer() {
      dispatch(cleanUserViewAddressesReducer());
    },
    cleanUserViewRatingsReducer() {
      dispatch(cleanUserViewRatingsReducer());
    },
    getUser: (id, callbackSuccess, callbackError) => {
      if (!id) return;

      dispatch(getUser());

      fetchApi(
        'get',
        `users/${id}`,
        null,
        null,
        null,
        (user) => {
          dispatch(getUserSuccess(user));

          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(user);
          }
        },
        (error) => {
          dispatch(getUserError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.callbacks.error_list,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().core.list.callbacks.error_list,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError(error);
          }
        }
      );
    },
    updateUserLevel: (id, levelId, callbackSuccess = null, callbackError = null) => {
      if (!id || !levelId) return;

      fetchApi(
        'put',
        `users/${id}/level`,
        null,
        { user_level_id: levelId },
        null,
        (success) => {
          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success);
          }
          dispatch(
            enqueueSnackbar({
              message: translation().views.user_view.user_level.callbacks.success,
              options: {
                variant: 'success'
              }
            })
          );
        },
        (error) => {
          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().views.user_view.user_level.callbacks.error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().views.user_view.user_level.callbacks.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError(error);
          }
        }
      );
    },
    async getOrdersMetadata(sourceToken = null) {
      dispatch(getUserViewOrdersMetadata());

      try {
        await fetchApi(
          'get',
          'orders/metadata',
          null,
          null,
          null,
          (metadata) => {
            dispatch(getUserViewOrdersMetadataSuccess(metadata));
          },
          (error) => {
            dispatch(getUserViewOrdersMetadataError(true, error));

            if (error.response) {
              dispatch(
                enqueueSnackbar({
                  message:
                    error.response.data && error.response.data.detail
                      ? error.response.data.detail
                      : error.response.data.status_code
                      ? error.response.data.status_code + ': ' + translation().core.item.not_found
                      : translation().core.list.error_metadata,
                  status: error.response.status || undefined,
                  options: {
                    variant: 'error'
                  }
                })
              );
            } else {
              dispatch(
                enqueueSnackbar({
                  message: translation().core.list.error_metadata,
                  options: {
                    variant: 'error'
                  }
                })
              );
            }
          },
          null,
          sourceToken
        );
      } catch (error) {
        dispatch(getUserViewOrdersMetadataError(true, error));
        dispatch(
          enqueueSnackbar({
            message: translation().core.list.error_metadata,
            options: {
              variant: 'error'
            }
          })
        );
      }
    },
    getUserViewOrders: (
      userId,
      sourceToken,
      next = null,
      callbackSuccess = null,
      callbackError = null
    ) => {
      if (!userId) return;

      dispatch(getUserViewOrders(next));

      fetchApi(
        'get',
        next ? next : `orders`,
        { filters: `iam_user_id|eq|${userId}` },
        null,
        null,
        (success, paging) => {
          dispatch(getUserViewOrdersSuccess(success, paging));

          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success, paging);
          }
        },
        (error) => {
          dispatch(getUserViewOrdersError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.callbacks.error_list,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().core.list.callbacks.error_list,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError(error);
          }
        },
        null,
        sourceToken
      );
    },
    getUserViewAddresses: (userId, next = null, callbackSuccess = null, callbackError = null) => {
      if (!userId) return;

      dispatch(getUserViewAddresses(next));

      fetchApi(
        'get',
        next ? next : `users/${userId}/addresses`,
        null,
        null,
        null,
        (success, paging) => {
          dispatch(getUserViewAddressesSuccess(success, paging));

          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success, paging);
          }
        },
        (error) => {
          dispatch(getUserViewAddressesError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.callbacks.error_list,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().core.list.callbacks.error_list,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError(error);
          }
        }
      );
    },
    getUserViewInvoices: (userId, next = null, callbackSuccess = null, callbackError = null) => {
      if (!userId) return;

      dispatch(getUserViewInvoices(next));

      fetchApi(
        'get',
        next ? next : `users/${userId}/invoices`,
        null,
        null,
        null,
        (success, paging) => {
          dispatch(getUserViewInvoicesSuccess(success, paging));

          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success, paging);
          }
        },
        (error) => {
          dispatch(getUserViewInvoicesError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.callbacks.error_list,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().core.list.callbacks.error_list,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError(error);
          }
        }
      );
    },
    getUserViewInvoicesEmitted: (
      userId,
      next = null,
      callbackSuccess = null,
      callbackError = null
    ) => {
      if (!userId) return;

      dispatch(getUserViewInvoicesEmitted(next));

      fetchApi(
        'get',
        next ? next : `users/${userId}/invoices-emitted`,
        null,
        null,
        null,
        (success, paging) => {
          dispatch(getUserViewInvoicesEmittedSuccess(success, paging));

          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success, paging);
          }
        },
        (error) => {
          dispatch(getUserViewInvoicesEmittedError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.callbacks.error_list,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().core.list.callbacks.error_list,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError(error);
          }
        }
      );
    },
    getUserViewPaymentMethods: (
      userId,
      next = null,
      callbackSuccess = null,
      callbackError = null
    ) => {
      if (!userId) return;

      dispatch(getUserViewPaymentMethods(next));

      fetchApi(
        'get',
        next ? next : `users/${userId}/payment-methods`,
        null,
        null,
        null,
        (success, paging) => {
          dispatch(getUserViewPaymentMethodsSuccess(success, paging));

          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success, paging);
          }
        },
        (error) => {
          dispatch(getUserViewPaymentMethodsError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.callbacks.error_list,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().core.list.callbacks.error_list,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError(error);
          }
        }
      );
    },
    getUserViewDevices: (
      userId,
      sourceToken,
      next = null,
      callbackSuccess = null,
      callbackError = null
    ) => {
      if (!userId) return;

      dispatch(getUserViewDevices(next));

      fetchApi(
        'get',
        next ? next : `users/${userId}/devices`,
        null,
        null,
        null,
        (success, paging) => {
          dispatch(getUserViewDevicesSuccess(success, paging));

          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success, paging);
          }
        },
        (error) => {
          dispatch(getUserViewDevicesError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.callbacks.error_list,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().core.list.callbacks.error_list,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError(error);
          }
        },
        null,
        sourceToken
      );
    },
    getPayerId: (userId, callbackSuccess, callbackError) => {
      if (!userId) return;

      fetchApi(
        'get',
        `payers`,
        { filters: `iam_user_id|eq|${userId}` },
        null,
        null,
        (success) => {
          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success);
          }
        },
        (error) => {
          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().views.user_view.payer_bridge.callback_error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().views.user_view.payer_bridge.callback_error,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError();
          }
        }
      );
    },
    getInvoicerId: (userId, callbackSuccess, callbackError) => {
      if (!userId) return;

      fetchApi(
        'get',
        `invoicers`,
        { filters: `iam_user_id|eq|${userId}` },
        null,
        null,
        (success) => {
          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success);
          }
        },
        (error) => {
          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().views.user_view.invoicer_bridge.callback_error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().views.user_view.invoicer_bridge.callback_error,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError();
          }
        }
      );
    },
    getUserViewRatings: (
      userId,
      sourceToken,
      next = null,
      callbackSuccess = null,
      callbackError = null
    ) => {
      if (!userId) return;

      dispatch(getUserViewRatings(next));

      fetchApi(
        'get',
        next ? next : 'orders/all/products',
        {
          filters: `iam_user_id|eq|${userId},provider_rating|gt|0`,
          fields:
            'id,order_id,iam_user_id,provider_id,provider_name,provider_rating,provider_rating_issues,provider_rating_comment,client_rating,client_rating_issues,client_rating_comment'
        },
        null,
        null,
        (success, paging) => {
          dispatch(getUserViewRatingsSuccess(success, paging));

          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success, paging);
          }
        },
        (error) => {
          dispatch(getUserViewRatingsError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.callbacks.error_list,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().core.list.callbacks.error_list,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError(error);
          }
        },
        null,
        sourceToken
      );
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserView));
