import React from 'react';
import PropTypes from 'prop-types';

function OrderDoneIcon(props) {
  return (
    <svg version="1.1" id="OrderDoneImgSvg" viewBox="0 0 1013 871.7" {...props}>
      <g>
        <path
          fill="#4E4BC6"
          d="M773.5,19.3c5.7,1.9,11.6,3,16.9,6.2c8,4.9,14.2,11.4,18.6,19.7c3.7-5.4,7.3-5.6,10.1,0.4
          c5.1,10.7,9,22.1,9.3,34.1c0.3,13.1-0.2,26.2-4.6,38.8c-1.9,5.3-5.2,9.5-8.2,14c-7.9,11.9-13.6,25-20.5,37.4
          c-10,17.9-23,33.7-40.5,45.2c-5.2,3.4-10.3,5.6-16.6,5.3c-4.4-0.2-8.7,1.7-12.6,3.8c-8.3,4.4-17,7.2-26.6,6.5
          c-8.3-0.6-16.1,3.5-24.4,3.8c-2.3,0.1-4.5,2.1-5.5,4.7c-2.6,6.6-4.1,13.4-5.4,20.4c-2.7,14.4-8.9,27.3-20.2,37.3
          c-3.7,3.3-7.1,7-10.4,10.7c-4.7,5.1-2.4,23.9,4.7,28.9c2.1,1.5,4.9,1.8,7.3,2.8c10.2,4,15,12.8,18.6,22.1
          c3.9,10.2,9.6,19.5,14.4,29.3c4.6,9.2,8.6,18.4,9.9,28.8c0.9,7.4,5.6,13.3,9.3,19.6c1.3,2.3,3.1,4.5,2.6,7.3
          c-2.1,11.7,5.3,18.1,13.4,24.1c2.8,2.1,5.7,4.1,8,6.7c8.1,9.4,9.7,11.1-1.4,22.1c-2.3,2.2-4.6,3.9-4.2,7.7c0.3,2.4,2,3.3,3.1,4.8
          c5.9,8.9,14.3,12.8,24.9,12.4c4.9-0.2,9.4,1.7,14.1,2.5c24.6,4.3,45.4,17.9,67.9,27.2c16.3,6.7,32.5,14.1,48.9,20.5
          c10.4,4.1,22.6,2.5,32.5-5.1c3.2-2.4,6.1-5,8.9-7.9c4.7-5,10.8-7.6,17.6-8.1c5.3-0.3,9.5,2.8,11.9,6.8c7.1,11.8,10.9,24.5,6.5,38.3
          c-1.4,4.5-0.2,8.9,0.1,13.3c0.1,1,0.6,2.3,1.8,2.8c11.3,5.1,16.7,14.4,19.2,25.9c0.1,0.3,0.4,0.5,0.7,0.7c0,5.3,0,10.7,0,16
          c-2.1,5.1-4,10.3-6.2,15.3c-4.8,10.7-13.2,12.7-23.2,3.7c-4-3.6-6.1-8.6-8-13.5c-1.8-4.7-3.7-9.3-7.2-12.9
          c-3.7-3.8-6.2-8.6-9.7-12.5c-5-5.6-10.7-10.8-19.3-9.9c-2.1,0.2-4.3-0.1-6.5,0.1c-8.9,0.7-12.5-2.8-14.8-11.3
          c-1.2-4.4-3.5-6.3-7.9-7.5c-14-4.1-27.9-8-42.3-10.7c-15.3-2.9-30.5-5.9-45.7-9.1c-4.5-0.9-8.9-0.2-13.3,0
          c-8.6,0.4-17.3,0.4-25.6-2.4c-2-0.7-4.1-0.9-6.6,0.2c2.5,3.3,6,5,8.3,8c3.2,4.2,5.4,8.6,6.7,13.6c2.4,9.8,8.1,18.1,12.9,26.7
          c4.9,8.8,9.5,17.8,17,24.9c2,1.8,3.4,4.6,4.3,7.2c2.9,8.2,9.3,14.4,12.3,22.6c0.9,2.6,5.1,3.9,8.2,5.1c12.7,4.8,25.4,9.6,38,14.4
          c8.8,3.4,9.6,5.2,7.7,14.6c-1.2,6.2-3.8,11.4-7.9,16.2c-4.8,5.6-8.1,12.4-11.8,18.8c-1.2,2.1-0.7,4.6-0.5,6.9
          c1.4,13.8-3.7,24.9-13.7,34c-6.5,5.9-12.4,12.5-20.2,16.7c-1.4-2.2-6.9-2.2-8.8,0.1c-0.7,0.9-1.2,2-2.2,2.7c-6.2,0-9.5-3.7-11.5-9
          c-1-2.6-1.5-5.5,0.6-7.6c7.9-8,7.9-17.7,6.5-27.8c-0.9-6.6-1.2-13.4-0.4-19.9c0.7-6.2-3.2-11.6-1.8-17.8c0.4-1.9-1.4-4.9-3.7-6.7
          c-4-3.2-7.8-6.7-11.7-10.1c-1.2-1-2.3-2.1-1.7-3.8c0.6-1.7,2.2-1.2,3.4-1.2c0.7,0,1.4,0.1,2,0c1.8-0.4,4.7,1.2,5.3-1.1
          c0.6-2.1,0.4-4.9-1.7-6.9c-2.8-2.8-5.1-6.1-8.2-8.7c-7.8-6.6-13.7-14.8-19-23.5c-10.7-17.3-25.8-30.5-41.6-43.1
          c-4.1-3.3-8.1-7-11.7-10.7c-13.7-14.4-28.8-27.2-43.7-40.2c-11.3-9.9-19.7-21.9-26.2-35.2c-8.3-16.8-18.8-32.3-28.2-48.4
          c-4.4-7.6-8-15.6-12.3-23.2c-7.6-13.4-17.5-25.3-24.6-39c-3.3-6.5-6.8-12.9-8.7-19.9c-2.7-9.9-5.5-19.7-9-29.4
          c-1.9-5.5-3-11.1-3.4-17c-0.4-6.4-1.8-12.9-4.7-18.6c-5.6-10.8-7.2-22.4-7.9-34.1c-0.7-11.6,0-23.3,0-34.9c2.5-2.4,2.4-5.5,2.4-8.6
          c-0.1-9.8,0-19.6,0-29.4c0,11.6,0,23.2,0,36c-2.2-3.9-2.8-28.4-1.6-38.2c-0.5-1.5,1.8-3.5-0.1-4.4c-1.7-0.9-3.8-0.4-5.7,0.6
          c-4.1,2.2-7.3,5.2-9.8,8.9c-2.3,3.3-4.9,6.1-8.8,7.7c-17,6.8-33.5,14.8-50.9,20.6c-8.9,3-18.1,5.2-27.3,6.8
          c-6.7,1.2-12.5,4.1-18.6,6.4c-2.2,0.8-3.8,3-4.4,5.2c-2.2,7.5-7.8,12.1-13.6,16.6c-2.6,2-3.9,4.1-3.6,7.5
          c0.5,5.7,0.1,11.3-2.9,16.4c-0.9,1.6-1.2,4.4-3.9,3.4c-2.2-0.8-2-3.4-1.5-5.3c1.1-5,0.8-9.9,0.1-14.9c-0.2-1.1,0.2-3.1-1.6-3.1
          c-1.5,0-1.8,1.7-2.3,2.9c-2.4,0.7-4.7,0.5-6.8-0.9c-1.3-0.9-2.5-1.4-3.8,0.1c-2.1,0.5-0.9,0.3-2.7,2.1c-1,0.9-2.6,7.6-3.9,8.1
          c-2.5,1-5.3-0.3-4.5-4.4c0.1-0.5,0-1-0.4-1.3c0.1,0.3,0.5,0.7,0.3,1.1c-0.6,1.2-1.5,1-2.3,0.2c1.2-5.1,2.4-9.5,2.7-14.8
          c-6.9,0.8-13.5,0.5-18.9,5.3c-1.7,1.5-4.4,1.5-5.9-0.3c-1.6-2,0-4.4,1.6-5.7c5.8-4.7,11.2-10.1,19.2-11.6c4.2-0.8,7.9-4.3,12.1-5.6
          c10.2-3.3,17.3-10.8,25.7-16.8c10.7-7.6,21.9-14.8,33.4-21.4c12.8-7.4,25.2-15.3,36.9-24.3c8.3-6.4,18.4-9.7,27.1-15.5
          c3.5-2.4,8.1-1.3,12.2-1.9c4.8-0.7,8.6-2.8,11.5-6.2c3.5-4,7.6-6.7,12.4-8.7c3-1.3,6-2.7,8.5-5c1.8-1.6,3-3.1,2.9-5.8
          c-0.2-5.1,4.2-8.1,6.8-11.8c8.3-12.1,21.1-17.2,34.3-21.4c3.5-1.1,7.6,0,11.4,0c1.1,0,2.6,0.7,3.1-0.6c0.5-1.4-0.7-2.8-1.8-3.2
          c-6.6-2.3-4.7-6.7-3.1-11.3c1.4-4.3,3.3-8.1,5.8-12c2-3.2,4.9-7.8,2.5-12.8c-1-2.1,0-4.6,2.2-6c2.1-1.4,4.2-2.7,6.4-3.8
          c3.6-1.7,6-3.7,5.6-8.3c-0.3-3.8,2.6-6.2,5.4-8.4c13.9-11.5,29.2-10.9,45-5.4c5.7,1.9,11,4.8,15.9,8.1c7.5,5.2,10.3,13.3,11.4,21.8
          c0.9,7.4,0,14.8-1.7,22.2c-2.3,10.1-9.8,14.6-18.2,18c-5.8,2.4-8.3,7-10.5,11.9c-1.9,4.1,0.1,7.4,4.6,8.4c1.8,0.4,3.1,1,3.4,3
          c0.7,4.9,4,5,8,4.7c7.9-0.7,15.5,0.6,22.6,4.4c1.1,0.1,1.6,0.9,2.1,1.8c0.6,0.4,1.2,0.8,1.8,1.2c2.9,1.8,6,1.9,9.2,0.9
          c5-1.9,10.1-2.6,15.4-1.4c3,0.6,6.3,0.2,9.5,0.3c1,0,2-0.1,3,0.1c3.3,0.6,6.3,4.5,9.7,2.4c4.2-2.6,8.3-6.1,10.5-10.5
          c4.4-8.8,11.6-14.5,19-20c13.7-10.1,23.8-23.7,35.1-36c2.1-2.2,2.4-5,1.5-7.4c-1.7-4.2-0.8-8.6-2.2-13.1
          c-6.3,5.5-13.2,9.7-21.4,11.9c-25.8,6.9-51.2-8.9-57.6-36c-5.7-24.1,10.8-50.1,36.1-54.7c0.7-0.1,1.4-0.4,2.3-0.6
          C764.2,19.3,768.9,19.3,773.5,19.3z"
        />
        <path
          fill="#4E4BC6"
          d="M357.6,314.5c1-1.5,3.1-2.4,4-1.2c2.1,2.6,5.3,1.7,7.7,3c-1.2,6.9-3.7,13.2-7.1,19.2c-1,1.7-1.8,3.7-4.4,2.9
          c-2.6-0.8-2.3-2.9-2.1-4.9c0.1-4.1,1.4-7.9,2.8-11.7C359.3,319.4,360,316.7,357.6,314.5z"
        />
        <path
          fill="#4E4BC6"
          d="M345.2,321.2c2.2,0.7,1.2-1.7,2.1-2.3c2,1.1,0.6,2.9,0.8,5c2.3-1,4.3-1.8,6.3-2.6c-2.6,6.1-2.7,7.4-4.7,12
          c-0.2,0.3-0.1,0.4-0.3,0.8c-0.6,1.1-0.7,1.2-1.8,3.2c-0.6,0.8-1.1,1.6-1.8,2.3c-1,1-1.9,2.5-3.7,1.5c-1.5-0.8-2.3-2-2.3-4
          C339.9,331.3,343.5,326.6,345.2,321.2z"
        />
        <path
          fill="#4E4BC6"
          d="M524.4,241c0,12.1,1.1,19.8,1.1,31.9c0.4,0-0.4,4.3,0,4.3c0-10,0-19.9,0-29.9c0-1.3,0-2.7,0-4
          c0-1,0.2-2,1.4-2.3c0.3-0.1,0.7,0.1,0.9,0.3c1.1,1.4-0.9,1.4-1,2c-0.3,1.3-0.3,2.6-0.3,3.9c0,9.8,0,19.7,0,29.5
          c0,2,0.8,4.7-2.8,4.5c0-11.8,0-23.6,0-35.4C523.7,244.2,523.4,242.5,524.4,241z"
        />
        <path
          fill="#4E4BC6"
          d="M787.5,819.1c1.5-4.8,1.5-4.8,12.1-5.7c-0.4,1-0.7,2-1.1,2.9C795,817.8,791.6,819.8,787.5,819.1z"
        />
        <path
          fill="#F4F4F3"
          d="M698.5,184.3c-3.2,0.8-6.3,1.6-9.2-0.9C692.4,183.7,695.5,184,698.5,184.3z"
        />
        <path
          fill="#F4F4F3"
          d="M687.6,182.1c-1-0.2-1.6-0.9-2.1-1.8c0.5-0.9,1.2-1.1,2-0.4C688.4,180.6,687.9,181.4,687.6,182.1z"
        />
        <path
          fill="#4E4BC6"
          d="M782.8,816.1c-1,0-1.8-0.5-1.6-1.4c0.2-0.7,0.9-1.5,1.6-1.7c1.3-0.3,1.6,0.8,1.7,1.8
          C784.5,816,783.3,815.6,782.8,816.1z"
        />
        <path
          fill="#4E4BC6"
          d="M787,815c0.2,1.1-0.1,1.9-1.2,1.7c-0.5-0.1-1.3-1.2-1.2-1.3c0.4-0.7,1-1.5,1.7-1.6
          C787.4,813.5,786.6,814.7,787,815z"
        />
      </g>
      <circle fill="#FFFFFF" cx="766.5" cy="66.1" r="39.7" />
      <polygon fill="#8378F4" points="766.6,55.8 741.7,41.6 741.7,48.6 766.6,63 " />
      <polygon fill="#4E4BC6" points="766.6,55.8 791.4,41.6 791.4,48.6 766.6,63 " />
      <polygon fill="#8378F4" points="747.2,44.7 741.7,41.6 741.7,77.9 747.2,74.8 " />
      <polygon fill="#8378F4" points="766.6,76.8 791.4,91.2 791.4,84 766.6,69.8 " />
      <polygon fill="#4E4BC6" points="766.6,76.8 741.7,91.2 741.7,84 766.6,69.8 " />
      <polygon fill="#8378F4" points="786,88.1 791.4,91.2 791.4,54.9 786,58 " />
      <path
        fill="#FFD700"
        d="M179.5,148L161,175.7l-8.8-8.8v-19h-12.7v19l-13,13L113.6,167v-19h-12.7v19l-9.2,9.2L72.9,148H57.6l24.1,36.2
        v80.2c0,5.7,6.9,8.5,10.8,4.5l14.7-14.7l14.8,14.8c2.5,2.5,6.5,2.5,9,0l14.2-14.2l14.6,14.6c4,4,10.8,1.2,10.8-4.5
        c0-3.5,0-76.2,0-80.6l24.1-36.2L179.5,148z M145.9,178.5l9.4,9.4L145,198.3l-9.4-9.4L145.9,178.5z M136,207.3l-10.1,10.1l-9.5-9.5
        l10.1-10.1L136,207.3z M107.2,178.6l10.3,10.3L107.5,199l-10.3-10.3L107.2,178.6z M94.4,203.9l4,4l-4,4V203.9z M94.4,249v-7.7
        l3.8,3.8L94.4,249z M107.2,236.1l-9.5-9.5l9.7-9.7l9.5,9.5L107.2,236.1z M126.5,255.4l-10.3-10.3l9.7-9.7l10.3,10.3L126.5,255.4z
        M134.9,226.4l10.1-10.1l10.3,10.3l-10.1,10.1L134.9,226.4z M157.9,249.5l-3.7-3.7l3.7-3.7V249.5z M157.9,211.3l-4-4l4-4V211.3z"
      />
      <path
        fill="#FFD700"
        d="M179.5,148l-1.4,2.1L161,175.7l-8.8-8.8v-19h-12.7v19l-13,13v18l9.5,9.5l-9.5,9.5V236l9.7,9.7l-9.7,9.7v15.3
        c1.6,0,3.3-0.6,4.5-1.9l14.2-14.2l14.6,14.6c4,4,10.8,1.2,10.8-4.5v-80.6l22.7-34.1l1.4-2.1L179.5,148z M135.5,188.9l10.4-10.4
        l9.4,9.4L145,198.3L135.5,188.9z M134.9,226.4l10.1-10.1l10.3,10.3l-10.1,10.1L134.9,226.4z M157.9,249.5l-3.7-3.7l3.7-3.7V249.5z
        M157.9,211.3l-4-4l4-4V211.3z"
      />
      <path
        fill="#FFD700"
        d="M209.2,143.7c0,3.5-2.8,6.4-6.4,6.4H28.4v-12.7h174.4C206.3,137.4,209.2,140.2,209.2,143.7z"
      />
      <path
        fill="#FFD700"
        d="M209.2,143.7c0,3.5-2.8,6.4-6.4,6.4h-76.3v-12.7h76.3C206.3,137.4,209.2,140.2,209.2,143.7z"
      />
      <path
        fill="#FFD700"
        d="M27.3,251.9H14.6c-1.8,0-3.2-2.8-3.2-6.4V99.5c0-3.5,1.4-6.3,3.2-6.3h12.7c1.8,0,3.2,2.8,3.2,6.3v146.1
        C30.5,249,29.1,251.9,27.3,251.9z"
      />
    </svg>
  );
}

OrderDoneIcon.defaultProps = {
  className: ''
};

OrderDoneIcon.propTypes = {
  className: PropTypes.string
};

export default OrderDoneIcon;
