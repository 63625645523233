import classNames from 'classnames';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  CircularProgress,
  Fab,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  IconButton,
  TableCell,
  TableRow,
  Switch
} from '@material-ui/core';
import { Check, Delete, Remove, Save } from '@material-ui/icons';
import { green, red } from '@material-ui/core/colors';

import { FormApiAutocompleteField, FormTextField } from '../../../../../components/forms/fields';
import config from '../../../../../config';
import translation from '../../../../../translation/translation';

const useStyles = makeStyles(() => ({
  actions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  action: {
    marginLeft: 4,
    marginRight: 4
  },
  autocompleteField: {
    minWidth: 170
  },
  decrementFab: {
    width: 25,
    height: 25,
    minHeight: 25
  },
  saveProgress: {
    color: green[500]
  },
  deleteProgress: {
    color: red[500]
  },
  actionProgress: {
    position: 'absolute',
    top: 2,
    left: 6,
    zIndex: 1
  },
  actionWrapper: {
    position: 'relative'
  },
  saved: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: green[500],
    color: '#FFF',
    borderRadius: 50,
    width: 40,
    height: 40
  },
  switchCell: {
    minWidth: 120
  },
  tableRow: {
    display: 'table-row',
    transition: '400ms ease-out'
  }
}));

function ProductRow(props) {
  const classes = useStyles();
  const {
    createProviderProduct,
    deleteProviderProduct,
    numberOfRows,
    onRemove,
    providerProduct: {
      _index,
      id,
      product,
      boost_factor,
      radius,
      reversion_hourly_rate,
      trip_compensation,
      reversion_amount,
      moderate
    },
    providerId,
    updateProviderProduct
  } = props;

  const [state, setState] = useState({
    error: null,
    loading: false,
    deleteLoading: false,
    deleted: false,
    changes: 0,
    saved: id ? 1 : 0,
    provider_product_id: id ? id : null,
    values: {
      product_id: product && product.id ? product.id : null,
      boost_factor: boost_factor ? boost_factor : 5,
      radius: radius ? radius : 10,
      reversion_hourly_rate: reversion_hourly_rate ? reversion_hourly_rate : undefined,
      trip_compensation: trip_compensation ? trip_compensation : undefined,
      reversion_amount: reversion_amount ? reversion_amount : undefined,
      moderate: moderate
    }
  });

  const { changes, error, loading, deleted, deleteLoading, provider_product_id, saved, values } =
    state;

  function handleRemove() {
    onRemove(_index);
  }

  function handleDelete() {
    const toDelete = id ? id : provider_product_id ? provider_product_id : null;

    if (!toDelete) return;

    setState((state) => ({
      ...state,
      deleted: false,
      deleteLoading: true
    }));

    deleteProviderProduct(
      toDelete,
      providerId,
      () => {
        setState((state) => ({
          ...state,
          deleted: true,
          deleteLoading: false
        }));

        setTimeout(() => {
          onRemove(_index);
        }, 400);
      },
      () => {
        setState((state) => ({
          ...state,
          deleted: false,
          deleteLoading: false
        }));
      }
    );
  }

  function handleChangeProduct(value) {
    setState((state) => ({
      ...state,
      changes: state.changes + 1,
      values: {
        ...state.values,
        product_id: value
      }
    }));
  }

  function handleChangeBoost(value) {
    setState((state) => ({
      ...state,
      changes: state.changes + 1,
      values: {
        ...state.values,
        boost_factor: value
      }
    }));
  }

  function handleChangeRadius(value) {
    setState((state) => ({
      ...state,
      changes: state.changes + 1,
      values: {
        ...state.values,
        radius: value
      }
    }));
  }

  function handleChangeReversionAmount(value) {
    setState((state) => ({
      ...state,
      changes: state.changes + 1,
      values: {
        ...state.values,
        reversion_amount: value
      }
    }));
  }

  function handleChangeReversionRate(value) {
    setState((state) => ({
      ...state,
      changes: state.changes + 1,
      values: {
        ...state.values,
        reversion_hourly_rate: value
      }
    }));
  }

  function handleChangeTripCompensation(value) {
    setState((state) => ({
      ...state,
      changes: state.changes + 1,
      values: {
        ...state.values,
        trip_compensation: value
      }
    }));
  }

  function handleChangeModerate(event) {
    setState((state) => ({
      ...state,
      changes: state.changes + 1,
      values: {
        ...state.values,
        moderate: event.target.checked
      }
    }));
  }

  function handleSaveRow() {
    if (!values.product_id);

    setState((state) => ({
      ...state,
      error: null,
      loading: true
    }));

    if (saved > 0) {
      updateProviderProduct(
        id ? id : provider_product_id,
        providerId,
        values,
        () => {
          setState((state) => ({
            ...state,
            error: null,
            loading: false,
            saved: state.saved + 1,
            changes: 0
          }));
        },
        (error) => {
          setState((state) => ({
            ...state,
            error,
            loading: false
          }));
        }
      );
    } else {
      createProviderProduct(
        providerId,
        values,
        (success) => {
          setState((state) => ({
            ...state,
            provider_product_id: success.id,
            error: null,
            loading: false,
            saved: state.saved + 1,
            changes: 0
          }));
        },
        (error) => {
          setState((state) => ({
            ...state,
            error,
            loading: false
          }));
        }
      );
    }
  }

  return (
    <TableRow className={classNames(classes.tableRow, { 'fade-out': deleted })}>
      <TableCell>
        <FormApiAutocompleteField
          name="product_id"
          margin="none"
          placeholder={translation().views.providers_map.form.labels.product_placeholder}
          links={[{ rel: 'list', href: `${config.api_url}products` }]}
          query={{ filters: 'active|eq|1' }}
          className={classes.autocompleteField}
          onSelectValue={handleChangeProduct}
          defaultValue={product}
          targetKey="id"
          disabled={loading || deleteLoading}
          error={error && error['product_id'] ? error['product_id'] : null}
        />
      </TableCell>
      <TableCell>
        <FormTextField
          id="boost_factor"
          name="boost_factor"
          type="number"
          placeholder="0"
          defaultValue={values.boost_factor}
          error={error && error['boost_factor'] ? error['boost_factor'] : null}
          disabled={loading || deleteLoading}
          margin="none"
          onChange={handleChangeBoost}
        />
      </TableCell>
      <TableCell>
        <FormTextField
          id="radius"
          name="radius"
          type="number"
          placeholder="0"
          defaultValue={values.radius}
          error={error && error['radius'] ? error['radius'] : null}
          disabled={loading || deleteLoading}
          margin="none"
          onChange={handleChangeRadius}
        />
      </TableCell>
      <TableCell>
        <FormTextField
          id="reversion_hourly_rate"
          name="reversion_hourly_rate"
          type="number"
          placeholder="0"
          defaultValue={values.reversion_hourly_rate}
          error={error && error['reversion_hourly_rate'] ? error['reversion_hourly_rate'] : null}
          disabled={loading || deleteLoading}
          margin="none"
          onChange={handleChangeReversionRate}
        />
      </TableCell>
      <TableCell>
        <FormTextField
          id="trip_compensation"
          name="trip_compensation"
          type="number"
          placeholder="0"
          defaultValue={values.trip_compensation}
          error={error && error['trip_compensation'] ? error['trip_compensation'] : null}
          disabled={loading || deleteLoading}
          margin="none"
          onChange={handleChangeTripCompensation}
        />
      </TableCell>
      <TableCell>
        <FormTextField
          id="reversion_amount"
          name="reversion_amount"
          type="number"
          placeholder="0"
          defaultValue={values.reversion_amount}
          error={error && error['reversion_amount'] ? error['reversion_amount'] : null}
          disabled={loading || deleteLoading}
          margin="none"
          onChange={handleChangeReversionAmount}
        />
      </TableCell>
      <TableCell className={classes.switchCell}>
        <FormControl disabled={loading || deleteLoading} margin="none" error={error ? true : false}>
          <FormGroup row>
            <FormControlLabel
              name="moderate"
              control={
                <Switch id="moderate" onChange={handleChangeModerate} checked={values.moderate} />
              }
            />
          </FormGroup>
          {error && error['moderate'] && (
            <FormHelperText component="span" style={{ margin: 0 }}>
              {error['moderate']}
            </FormHelperText>
          )}
        </FormControl>
      </TableCell>
      <TableCell align="right">
        <div className={classes.actions}>
          {saved > 0 && changes < 1 ? (
            <div className={classes.saved}>
              <Check />
            </div>
          ) : (
            <div className={classes.actionWrapper}>
              <IconButton
                aria-label="Save provider product"
                className={classes.action}
                disabled={loading || !values.product_id}
                onClick={handleSaveRow}>
                <Save />
              </IconButton>
              {loading && (
                <CircularProgress
                  size={40}
                  className={classNames(classes.saveProgress, classes.actionProgress)}
                />
              )}
            </div>
          )}
          {saved > 0 ? (
            <div className={classes.actionWrapper}>
              <IconButton
                aria-label="Delete provider product"
                disabled={loading || deleteLoading}
                margin="none"
                className={classes.action}
                onClick={handleDelete}>
                <Delete />
              </IconButton>
              {deleteLoading && (
                <CircularProgress
                  size={40}
                  className={classNames(classes.deleteProgress, classes.actionProgress)}
                />
              )}
            </div>
          ) : _index > 0 && numberOfRows !== 1 && !loading ? (
            <Fab
              size="small"
              onClick={handleRemove}
              disabled={loading || deleteLoading}
              margin="none"
              className={classNames(classes.action, classes.decrementFab)}>
              <Remove fontSize="small" />
            </Fab>
          ) : (
            false
          )}
        </div>
      </TableCell>
    </TableRow>
  );
}

ProductRow.propTypes = {
  createProviderProduct: PropTypes.func.isRequired,
  deleteProviderProduct: PropTypes.func.isRequired,
  numberOfRows: PropTypes.number.isRequired,
  providerProduct: PropTypes.shape({
    _index: PropTypes.number,
    id: PropTypes.number,
    product: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    }),
    boost_factor: PropTypes.number,
    radius: PropTypes.number,
    reversion_hourly_rate: PropTypes.number,
    trip_compensation: PropTypes.number,
    reversion_amount: PropTypes.number,
    moderate: PropTypes.bool
  }),
  onRemove: PropTypes.func.isRequired,
  providerId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  updateProviderProduct: PropTypes.func.isRequired
};

export default ProductRow;
