import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchApi } from '../../utils/functions/api';
import { enqueueSnackbar } from '../../redux/actions/appActions';
import {
  cleanCompanyViewAddressesReducer,
  cleanCompanyViewInvoicesReducer,
  cleanCompanyViewInvoicesEmittedReducer,
  cleanCompanyViewPaymentMethodReducer,
  cleanCompanyViewOrdersReducer,
  cleanCompanyViewUsersReducer,
  cleanCompanyViewRatingsReducer,
  getCompany,
  getCompanySuccess,
  getCompanyError,
  getCompanyViewAddresses,
  getCompanyViewAddressesSuccess,
  getCompanyViewAddressesError,
  getCompanyViewOrders,
  getCompanyViewOrdersSuccess,
  getCompanyViewOrdersError,
  getCompanyViewOrdersMetadata,
  getCompanyViewOrdersMetadataSuccess,
  getCompanyViewOrdersMetadataError,
  getCompanyViewInvoices,
  getCompanyViewInvoicesSuccess,
  getCompanyViewInvoicesError,
  getCompanyViewInvoicesEmitted,
  getCompanyViewInvoicesEmittedSuccess,
  getCompanyViewInvoicesEmittedError,
  getCompanyViewUsers,
  getCompanyViewUsersSuccess,
  getCompanyViewUsersError,
  getCompanyViewPaymentMethods,
  getCompanyViewPaymentMethodsSuccess,
  getCompanyViewPaymentMethodsError,
  getCompanyViewRatings,
  getCompanyViewRatingsSuccess,
  getCompanyViewRatingsError
} from '../../redux/actions/views/companyViewActions';
import translation from '../../translation/translation';
import CompanyView from '../../views/companies/companyView/CompanyView';

const mapStateToProps = (store) => ({
  company: store.views.company
});

const mapDispatchToProps = (dispatch) => {
  return {
    cleanCompanyViewAddressesReducer() {
      dispatch(cleanCompanyViewAddressesReducer());
    },
    cleanCompanyViewInvoicesReducer() {
      dispatch(cleanCompanyViewInvoicesReducer());
    },
    cleanCompanyViewOrdersReducer() {
      dispatch(cleanCompanyViewOrdersReducer());
    },
    cleanCompanyViewInvoicesEmittedReducer() {
      dispatch(cleanCompanyViewInvoicesEmittedReducer());
    },
    cleanCompanyViewPaymentMethodReducer() {
      dispatch(cleanCompanyViewPaymentMethodReducer());
    },
    cleanCompanyViewUsersReducer() {
      dispatch(cleanCompanyViewUsersReducer());
    },
    cleanCompanyViewRatingsReducer() {
      dispatch(cleanCompanyViewRatingsReducer());
    },
    getCompany: (id, callbackSuccess, callbackError) => {
      if (!id) return;

      dispatch(getCompany());

      fetchApi(
        'get',
        `companies/${id}`,
        null,
        null,
        null,
        (company) => {
          dispatch(getCompanySuccess(company));

          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(company);
          }
        },
        (error) => {
          dispatch(getCompanyError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.callbacks.error_list,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().core.list.callbacks.error_list,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError(error);
          }
        }
      );
    },
    async getOrdersMetadata(uri, sourceToken = null) {
      if (!uri) return;

      dispatch(getCompanyViewOrdersMetadata());

      try {
        await fetchApi(
          'get',
          'orders/metadata',
          null,
          null,
          null,
          (metadata) => {
            dispatch(getCompanyViewOrdersMetadataSuccess(metadata));
          },
          (error) => {
            dispatch(getCompanyViewOrdersMetadataError(true, error));

            if (error.response) {
              dispatch(
                enqueueSnackbar({
                  message:
                    error.response.data && error.response.data.detail
                      ? error.response.data.detail
                      : error.response.data.status_code
                      ? error.response.data.status_code + ': ' + translation().core.item.not_found
                      : translation().core.list.error_metadata,
                  status: error.response.status || undefined,
                  options: {
                    variant: 'error'
                  }
                })
              );
            } else {
              dispatch(
                enqueueSnackbar({
                  message: translation().core.list.error_metadata,
                  options: {
                    variant: 'error'
                  }
                })
              );
            }
          },
          null,
          sourceToken
        );
      } catch (error) {
        dispatch(getCompanyViewOrdersMetadataError(true, error));
        dispatch(
          enqueueSnackbar({
            message: translation().core.list.error_metadata,
            options: {
              variant: 'error'
            }
          })
        );
      }
    },
    getCompanyViewOrders: (
      companyId,
      sourceToken,
      next = null,
      callbackSuccess = null,
      callbackError = null
    ) => {
      if (!companyId) return;

      dispatch(getCompanyViewOrders(next));

      fetchApi(
        'get',
        next ? next : `orders`,
        { filters: `iam_company_id|eq|${companyId}` },
        null,
        null,
        (success, paging) => {
          dispatch(getCompanyViewOrdersSuccess(success, paging));

          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success, paging);
          }
        },
        (error) => {
          dispatch(getCompanyViewOrdersError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.callbacks.error_list,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().core.list.callbacks.error_list,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError(error);
          }
        },
        null,
        sourceToken
      );
    },
    getCompanyViewAddresses: (
      companyId,
      next = null,
      callbackSuccess = null,
      callbackError = null
    ) => {
      if (!companyId) return;

      dispatch(getCompanyViewAddresses(next));

      fetchApi(
        'get',
        next ? next : `companies/${companyId}/addresses`,
        null,
        null,
        null,
        (success, paging) => {
          dispatch(getCompanyViewAddressesSuccess(success, paging));

          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success, paging);
          }
        },
        (error) => {
          dispatch(getCompanyViewAddressesError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.callbacks.error_list,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().core.list.callbacks.error_list,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError(error);
          }
        }
      );
    },
    getCompanyViewInvoices: (
      companyId,
      next = null,
      callbackSuccess = null,
      callbackError = null
    ) => {
      if (!companyId) return;

      dispatch(getCompanyViewInvoices(next));

      fetchApi(
        'get',
        next ? next : `companies/${companyId}/invoices`,
        null,
        null,
        null,
        (success, paging) => {
          dispatch(getCompanyViewInvoicesSuccess(success, paging));

          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success, paging);
          }
        },
        (error) => {
          dispatch(getCompanyViewInvoicesError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.callbacks.error_list,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().core.list.callbacks.error_list,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError(error);
          }
        }
      );
    },
    getCompanyViewInvoicesEmitted: (
      companyId,
      next = null,
      callbackSuccess = null,
      callbackError = null
    ) => {
      if (!companyId) return;

      dispatch(getCompanyViewInvoicesEmitted(next));

      fetchApi(
        'get',
        next ? next : `companies/${companyId}/invoices-emitted`,
        null,
        null,
        null,
        (success, paging) => {
          dispatch(getCompanyViewInvoicesEmittedSuccess(success, paging));

          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success, paging);
          }
        },
        (error) => {
          dispatch(getCompanyViewInvoicesEmittedError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.callbacks.error_list,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().core.list.callbacks.error_list,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError(error);
          }
        }
      );
    },
    getCompanyViewPaymentMethods: (
      companyId,
      next = null,
      callbackSuccess = null,
      callbackError = null
    ) => {
      if (!companyId) return;

      dispatch(getCompanyViewPaymentMethods(next));

      fetchApi(
        'get',
        next ? next : `companies/${companyId}/payment-methods`,
        null,
        null,
        null,
        (success, paging) => {
          dispatch(getCompanyViewPaymentMethodsSuccess(success, paging));

          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success, paging);
          }
        },
        (error) => {
          dispatch(getCompanyViewPaymentMethodsError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.callbacks.error_list,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().core.list.callbacks.error_list,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError(error);
          }
        }
      );
    },
    getPayerId: (companyId, callbackSuccess, callbackError) => {
      if (!companyId) return;

      fetchApi(
        'get',
        `payers`,
        { filters: `iam_company_id|eq|${companyId}` },
        null,
        null,
        (success) => {
          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success);
          }
        },
        (error) => {
          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().views.company_view.payer_bridge.callback_error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().views.company_view.payer_bridge.callback_error,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError();
          }
        }
      );
    },
    getInvoicerId: (companyId, callbackSuccess, callbackError) => {
      if (!companyId) return;

      fetchApi(
        'get',
        `invoicers`,
        { filters: `iam_company_id|eq|${companyId}` },
        null,
        null,
        (success) => {
          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success);
          }
        },
        (error) => {
          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().views.company_view.invoicer_bridge.callback_error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().views.company_view.invoicer_bridge.callback_error,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError();
          }
        }
      );
    },
    getCompanyViewUsers: (
      companyId,
      sourceToken,
      next = null,
      callbackSuccess = null,
      callbackError = null
    ) => {
      if (!companyId) return;

      dispatch(getCompanyViewUsers(next));

      fetchApi(
        'get',
        next ? next : `companies/${companyId}/users`,
        null,
        null,
        null,
        (success, paging) => {
          dispatch(getCompanyViewUsersSuccess(success, paging));

          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success, paging);
          }
        },
        (error) => {
          dispatch(getCompanyViewUsersError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.callbacks.error_list,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().core.list.callbacks.error_list,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError(error);
          }
        },
        null,
        sourceToken
      );
    },
    getCompanyViewRatings: (
      companyId,
      sourceToken,
      next = null,
      callbackSuccess = null,
      callbackError = null
    ) => {
      if (!companyId) return;

      dispatch(getCompanyViewRatings(next));

      fetchApi(
        'get',
        next ? next : 'orders/all/products',
        {
          filters: `iam_company_id|eq|${companyId},provider_rating|gt|0`,
          fields:
            'id,order_id,iam_user_id,provider_id,provider_name,provider_rating,provider_rating_issues,provider_rating_comment,client_rating,client_rating_issues,client_rating_comment'
        },
        null,
        null,
        (success, paging) => {
          dispatch(getCompanyViewRatingsSuccess(success, paging));

          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success, paging);
          }
        },
        (error) => {
          dispatch(getCompanyViewRatingsError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.callbacks.error_list,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().core.list.callbacks.error_list,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError(error);
          }
        },
        null,
        sourceToken
      );
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CompanyView));
