import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchApi } from '../../../utils/functions/api';
import { enqueueSnackbar } from '../../../redux/actions/appActions';
import {
  clearTaskViews,
  refreshTaskViews,
  getTaskViews,
  getTaskViewsError,
  getTaskViewsSuccess,
  createTaskView,
  createTaskViewError,
  createTaskViewSuccess,
  deleteTaskView,
  deleteTaskViewError,
  deleteTaskViewSuccess,
  updateTaskView,
  updateTaskViewError,
  updateTaskViewSuccess
} from '../../../redux/actions/views/tasksActions';
import translation from '../../../translation/translation';
import TaskViews from '../../../views/tasks/TaskViews';

const mapStateToProps = (store) => ({
  user: store.user.data,
  list: store.views.tasks.task_views
});

const mapDispatchToProps = (dispatch) => {
  return {
    clearTaskViews() {
      dispatch(clearTaskViews());
    },
    getTaskViews: (next = null, isRefresh = false) => {
      isRefresh ? dispatch(refreshTaskViews()) : dispatch(getTaskViews(next));

      fetchApi(
        'get',
        next ? next : `users/self/task-views`,
        null,
        null,
        null,
        (data, paging) => {
          dispatch(getTaskViewsSuccess(data, paging));
        },
        (error) => {
          dispatch(getTaskViewsError());

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.callbacks.error_list,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().core.list.callbacks.error_list,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    },
    createTaskView: (data = null, callbackSuccess = null, callbackError = null) => {
      if (!data) return;

      dispatch(createTaskView());

      fetchApi(
        'post',
        `users/self/task-views`,
        null,
        data,
        null,
        (success) => {
          dispatch(createTaskViewSuccess(success));

          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success);
          }
        },
        (error) => {
          dispatch(createTaskViewError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().views.task_views.create.callbacks.error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().views.task_views.create.callbacks.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError(error);
          }
        }
      );
    },
    updateTaskView: (tvid = null, data = null, callbackSuccess = null, callbackError = null) => {
      if (!tvid || !data) return;

      dispatch(updateTaskView());

      fetchApi(
        'put',
        `users/self/task-views/${tvid}`,
        null,
        data,
        null,
        (success) => {
          dispatch(updateTaskViewSuccess(success));

          dispatch(
            enqueueSnackbar({
              message: translation().views.task_views.update.callbacks.success,
              options: {
                variant: 'success'
              }
            })
          );

          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success);
          }
        },
        (error) => {
          dispatch(updateTaskViewError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().views.task_views.update.callbacks.error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().views.task_views.update.callbacks.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError(error);
          }
        }
      );
    },
    deleteTaskView: (tvid = null, callbackSuccess = null, callbackError = null) => {
      if (!tvid) return;

      dispatch(deleteTaskView());

      fetchApi(
        'delete',
        `users/self/task-views/${tvid}`,
        null,
        null,
        null,
        (success) => {
          dispatch(deleteTaskViewSuccess(tvid));

          dispatch(
            enqueueSnackbar({
              message: translation().views.task_views.delete.callbacks.success,
              options: {
                variant: 'success'
              }
            })
          );

          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success);
          }
        },
        (error) => {
          dispatch(deleteTaskViewError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().views.task_views.delete.callbacks.error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().views.task_views.delete.callbacks.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError(error);
          }
        }
      );
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TaskViews));
