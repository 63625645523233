import React, { Fragment, useState, useEffect, useReducer } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Box,
  CircularProgress,
  FormHelperText,
  Button,
  Paper,
  TextField,
  Typography,
  Divider
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ChevronRight } from '@material-ui/icons';

import {
  getAccessToken,
  getLocale,
  getCurrency,
  getTimezone
} from '../../../utils/functions/cookies';
import { isObjEmpty } from '../../../utils/functions/utils';
import config from '../../../config';
import translation from '../../../translation/translation';
import { fetchApi } from '../../../utils/functions/api';

const useStyles = makeStyles(() => ({
  paging: {
    zIndex: 1310,
    display: 'flex',
    alignItems: 'center',
    padding: '8px 16px'
  },
  loading: {
    display: 'flex',
    alignItems: 'center'
  },
  grow: {
    flexGrow: 1
  },
  btnNext: {
    lineHeight: 0
  }
}));

const dataFetchReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...state,
        isLoading: true,
        isError: false,
        paging: null
      };
    case 'FETCH_PAGING':
      return {
        ...state,
        isLoading: true
      };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.data,
        paging: action.paging
      };
    case 'FETCH_FAILURE':
      return {
        ...state,
        isLoading: false,
        isError: true
      };
    default:
      return state;
  }
};

const useDataApi = (value, links = [], params = {}) => {
  const initialData = [];
  const query = { ...params };
  const headers = {};
  const axiosRequest = { method: 'get' };
  const accessToken = getAccessToken();
  const locale = getLocale();
  const currency = getCurrency();
  const timezone = getTimezone();

  const [state, dispatch] = useReducer(dataFetchReducer, {
    isLoading: false,
    isError: false,
    data: initialData
  });

  useEffect(() => {
    if (value) {
      if (!links || links.length <= 0) {
        return;
      }

      let url = null;

      for (let i = 0; i < links.length; i++) {
        if (links[i].rel === 'list' && links[i].href) {
          url = links[i].href;
        }
      }

      query.search = value;

      if (accessToken) {
        query.access_token = accessToken;
      } else {
        query.client_id = config.api_key;
      }

      if (locale && !query.locale) {
        query.locale = locale;
      }

      if (currency && !query.currency) {
        query.currency = currency;
      }

      if (timezone && !query.timezone) {
        query.timezone = timezone;
      }

      if (url) {
        if (url.match(config.api_url)) {
          url = url.replace(config.api_url, '');
        }

        axiosRequest.url = config.api_url + url;
      }

      if (!isObjEmpty(query)) {
        axiosRequest.params = query;
      }

      if (!isObjEmpty(headers)) {
        axiosRequest.headers = headers;
      }

      const source = axios.CancelToken.source();
      axiosRequest.cancelToken = source.token;

      const fetchData = async () => {
        dispatch({ type: 'FETCH_INIT' });

        try {
          const result = await axios(axiosRequest);
          dispatch({
            type: 'FETCH_SUCCESS',
            data: result.data.data,
            paging: result.data.paging
          });
        } catch (error) {
          dispatch({ type: 'FETCH_FAILURE' });
        }
      };

      fetchData();

      return () => {
        source.cancel();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const navigate = (route) => {
    dispatch({ type: 'FETCH_PAGING' });

    fetchApi(
      'get',
      route,
      null,
      null,
      null,
      (data, paging) => {
        dispatch({
          type: 'FETCH_SUCCESS',
          data: [...state.data, ...data],
          paging
        });
      },
      () => {
        dispatch({ type: 'FETCH_FAILURE' });
      }
    );
  };

  return [navigate, state];
};

function FormApiAutocompleteField(props) {
  const {
    autoComplete,
    autoFocus,
    classes,
    className,
    clearable,
    cleared,
    defaultValue,
    disabled,
    error,
    fullWidth,
    getFullObject,
    helperText,
    id,
    inputProps,
    InputProps,
    label,
    links,
    margin,
    multiple,
    name,
    onInputChange,
    onSelectValue,
    placeholder,
    query,
    required,
    showId,
    style,
    targetKey,
    variant,
    write
  } = props;

  const styles = useStyles();
  const [value, setValue] = useState('');
  const [selected, setSelected] = useState(defaultValue ? defaultValue : multiple ? [] : null);
  const [open, setOpen] = useState(false);

  const [navigate, { data, isLoading, paging }] = useDataApi(value, links, query);

  function handleInputChange(event) {
    if (event && event.target && event.target.value) {
      setValue(event.target.value);

      if (onInputChange) {
        onInputChange(event.target.value);
      }
    }
  }

  useEffect(() => {
    if (cleared) {
      setSelected(null);
      setValue('');
    }
  }, [cleared]);

  function handleNext() {
    if (paging && paging.next) {
      navigate(paging.next);
    }
  }

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleChange(event, value) {
    setSelected(value);

    if (onSelectValue) {
      if (!getFullObject) {
        if (value && value[targetKey]) {
          onSelectValue(value[targetKey], value);
        } else if (value && value.id) {
          onSelectValue(value.id, value);
        } else {
          onSelectValue(value || null);
        }
      } else {
        onSelectValue(value || null);
      }
    }
  }

  function handleGetOptionLabel(option) {
    if (option.last_name && option.first_name)
      return (
        option.first_name +
        ' ' +
        (option.last_name ? option.last_name : ' ') +
        (option.company && option.company.legal_name ? ' (' + option.company.legal_name + ')' : '')
      );
    else if (option.commercial_name)
      return (
        option.commercial_name +
        (option.legal_name
          ? ` (${translation().commons.selectAutoComplete.legal_name} ${option.legal_name})`
          : '')
      );
    else if (option.name) return option.name + (showId && option.id ? ` (#${option.id})` : '');
    else if (option.label) return option.label;
    else if (option.text) return option.text;
    else if (option.description) return option.description;
    else if (option.value) return option.value.toString();
    else if (option.email) return option.email;
    else if (option.url_complement) return option.url_complement;
    else if (option.id) return option.id.toString();
    else return option;
  }

  function handleFilterOptions(options) {
    return options;
  }

  const PaperComponent = (props) => {
    function handleMouseDown(event) {
      event.preventDefault();
    }

    return (
      <Paper {...props}>
        {props.children}
        {paging && (
          <>
            <Divider />
            <Box className={styles.paging} onMouseDown={handleMouseDown}>
              {isLoading && (
                <Box className={styles.loading}>
                  <CircularProgress size={20} style={{ marginRight: 8 }} />
                  <Typography color="textSecondary" variant="body2">
                    {translation().core.list.load_next}
                  </Typography>
                </Box>
              )}
              {!isLoading && paging && !paging.next && paging.prev && (
                <Typography color="textSecondary" variant="body2">
                  {translation().core.list.load_next_done}
                </Typography>
              )}
              <div className={styles.grow} />
              {paging.next && (
                <Button
                  size="small"
                  color="inherit"
                  onClick={handleNext}
                  className={styles.btnNext}
                  endIcon={<ChevronRight fontSize="small" />}>
                  {translation().actions.load_more}
                </Button>
              )}
            </Box>
          </>
        )}
      </Paper>
    );
  };

  PaperComponent.propTypes = {
    children: PropTypes.node
  };

  function renderInput(params) {
    return (
      <TextField
        {...params}
        id={id || name}
        label={label}
        placeholder={
          translation().actions.search +
          ' ' +
          (placeholder ? placeholder.toLowerCase() : label ? label.toLowerCase() : undefined)
        }
        disabled={!write || disabled}
        autoFocus={(!value || value.length <= 0) && autoFocus ? true : false}
        error={error ? true : false}
        required={required}
        variant={variant}
        margin={margin}
        fullWidth={fullWidth}
        classes={classes}
        name={name}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{
          ...params.inputProps,
          ...inputProps,
          autoComplete
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={{
          ...params.InputProps,
          ...InputProps,
          endAdornment: (
            <Fragment>
              {isLoading ? <CircularProgress size={20} /> : null}
              {params.InputProps.endAdornment}
            </Fragment>
          )
        }}
      />
    );
  }

  return (
    <>
      <Autocomplete
        style={!fullWidth ? { ...style, width: 300 } : { ...style, width: '100%' }}
        open={open}
        disabled={!write || disabled}
        onOpen={handleOpen}
        onClose={handleClose}
        getOptionLabel={handleGetOptionLabel}
        filterOptions={handleFilterOptions}
        onChange={handleChange}
        onInputChange={handleInputChange}
        options={data}
        multiple={multiple}
        loading={isLoading}
        renderInput={renderInput}
        value={selected}
        className={className}
        disableClearable={!clearable}
        noOptionsText={translation().commons.selectAutoComplete.noOption}
        loadingText={translation().commons.selectAutoComplete.loading}
        PaperComponent={PaperComponent}
      />
      {helperText && (
        <FormHelperText component="span" style={{ margin: 0 }}>
          {helperText}
        </FormHelperText>
      )}
      {error && (
        <FormHelperText component="span" style={{ margin: 0 }} error>
          {error}
        </FormHelperText>
      )}
    </>
  );
}

FormApiAutocompleteField.defaultProps = {
  autoComplete: 'off',
  autoFocus: false,
  clearable: true,
  cleared: false,
  disabled: false,
  getFullObject: false,
  fullWidth: true,
  margin: 'normal',
  multiple: false,
  showId: false,
  style: {},
  variant: 'standard',
  write: true
};

FormApiAutocompleteField.propTypes = {
  autoFocus: PropTypes.bool,
  autoComplete: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  getFullObject: PropTypes.bool,
  className: PropTypes.string,
  classes: PropTypes.shape(),
  clearable: PropTypes.bool,
  cleared: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.shape(), PropTypes.arrayOf(PropTypes.shape())]),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fullWidth: PropTypes.bool,
  inputProps: PropTypes.shape(),
  InputProps: PropTypes.shape(),
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  query: PropTypes.shape(),
  label: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.shape()),
  margin: PropTypes.string,
  multiple: PropTypes.bool,
  name: PropTypes.string,
  onInputChange: PropTypes.func,
  onSelectValue: PropTypes.func,
  style: PropTypes.shape(),
  targetKey: PropTypes.any,
  showId: PropTypes.bool,
  required: PropTypes.bool,
  variant: PropTypes.string,
  write: PropTypes.bool
};

export default FormApiAutocompleteField;
