const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_REDUCERS':
      return initialState;
    case 'DELETE_FORM': {
      delete state[action.formName];
      return state;
    }
    case 'INITIALIZE_FORM':
      return {
        ...state,
        [action.formName]: {
          init: true,
          fields: action.fields,
          errors: {},
          values: {}
        }
      };
    case 'SET_FORM_ERRORS':
      return {
        ...state,
        [action.formName]: {
          ...state[action.formName],
          errors: action.errors
        }
      };
    case 'CLEAN_FORM_ERRORS':
      return {
        ...state,
        [action.formName]: {
          ...state[action.formName],
          errors: {}
        }
      };
    case 'SET_FIELD_VALUE': {
      return {
        ...state,
        [action.formName]: {
          ...state[action.formName],
          values: {
            ...state[action.formName]['values'],
            [action.name]: action.value !== '' ? action.value : null
          }
        }
      };
    }
    default:
      return state;
  }
};
