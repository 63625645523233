import './OrdersUpdateView.scss';

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit';
import EuroIcon from '@material-ui/icons/Euro';
import Grid from '@material-ui/core/Grid';
import HouseIcon from '@material-ui/icons/House';
import IconButton from '@material-ui/core/IconButton';
import LabelIcon from '@material-ui/icons/Label';
import LaunchIcon from '@material-ui/icons/Launch';
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ShoppingCartTwoToneIcon from '@material-ui/icons/ShoppingCartTwoTone';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';

import Alert from '../../../components/alert/Alert';
import AddDiscountButton from './components/AddDiscountButton';
import DeleteDiscountButton from './components/DeleteDiscountButton';
import EditInvoiceAddressButton from './components/EditInvoiceAddressButton';
import EditPaymentMethodButton from './components/EditPaymentMethodButton';
import OrderDetailsButton from './components/OrderDetailsButton';
import OrdersUpdateViewBar from './components/OrdersUpdateViewBar';
import ProductCard from './components/product/ProductCard';
import { isObjEmpty } from '../../../utils/functions/utils';
import { dateInTz } from '../../../utils/functions/dates';
import translation from '../../../translation/translation';
import config from '../../../config';

const useStyles = makeStyles((theme) => ({
  breakWord: {
    wordBreak: 'break-all'
  },
  cardHeader: {
    padding: theme.spacing(0, 1),
    marginBottom: theme.spacing(1)
  },
  cardContent: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0
    }
  },
  cartIcon: {
    fontSize: 56,
    display: 'block',
    margin: '15px auto'
  },
  linearProgress: {
    margin: 'auto',
    maxWidth: 250,
    padding: theme.spacing(3),
    textAlign: 'center',
    width: '100%',
    '& .loader-wpr': {
      margin: 0,
      paddingTop: 7,
      paddingBottom: 10
    }
  },
  listIconRoot: {
    minWidth: 30
  },
  listItemName: {
    fontWeight: 500,
    marginRight: 4
  },
  listItemPrice: {
    fontWeight: 500,
    marginLeft: 7
  },
  listItemUnpadBottom: {
    paddingBottom: 0
  },
  listItemUnpadTop: {
    paddingTop: 0
  },
  listRoot: {
    flexGrow: 1
  },
  orderLoaderWrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  orderCancelledLabel: {
    border: '0!important',
    borderRadius: 4,
    display: 'inline-flex',
    margin: '16px 16px 0!important',
    padding: theme.spacing(0, 2)
  },
  paper: {
    overflow: 'auto',
    width: '100%',
    padding: theme.spacing(2, 1),
    marginBottom: theme.spacing(2),
    minHeight: 235
  },
  paperTitle: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

function OrdersUpdateView(props) {
  const classes = useStyles();
  const {
    addOrderDiscount,
    addOrderProductOption,
    cancelOrderProduct,
    clearOrderUpdatePlanning,
    clearOwnReducer,
    deleteOrderDiscount,
    editOrderInvoiceAddress,
    editOrderPaymentMethod,
    getOrder,
    getOrderClientAddresses,
    getOrderClientPaymentMethods,
    getOrderDiscounts,
    getOrderProductDeliveryStatuses,
    getOrderProductDispatchableProviders,
    getOrderProductHistory,
    getOrderProductProviderInfos,
    getOrderProductSlots,
    getNextOrderProductSlots,
    getPreviousOrderProductSlots,
    history,
    manageOrderProductFlag,
    match,
    postOrderProductDispatchableProviders,
    removeOrderProductOption,
    update: {
      data,
      deliveryStatuses,
      discounts,
      isLoading,
      invoiceAddresses,
      paymentMethods,
      productCancel,
      productFlag,
      productHistory,
      productOptions,
      productPlanning,
      productProvider,
      productPropertyDetails,
      providersInfos,
      providersDispatching
    },
    updateOrderProductAttributes,
    updateOrderProductDeliveryStatus,
    updateOrderProductProvider,
    updateOrderProductStartDate,
    updateOrderProductPropertyDetails
  } = props;

  useEffect(() => {
    if (match && match.params && match.params.id) {
      document.title = `Actarus | Order #${match.params.id}`;
    } else {
      document.title = 'Actarus | Update order';
    }

    loadOrder();

    return () => {
      clearOwnReducer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function loadOrder() {
    if (match && match.params && match.params.id) {
      getOrder(match.params.id);
      getOrderDiscounts(match.params.id);
    }
  }

  function handleAddOrderDiscount(code) {
    if (!code || !data.id) return;

    addOrderDiscount(data.id, code, loadOrder);
  }

  function handleDeleteDiscount(id) {
    if (!id || !data.id) return;

    deleteOrderDiscount(data.id, id, loadOrder);
  }

  return (
    <div className="OrdersUpdateView">
      <OrdersUpdateViewBar
        created={data && data.created ? dateInTz(data.created, 'localized-datetime') : null}
        orderId={match.params.id}
        history={history}
        isLoading={isLoading}
        handleRefresh={loadOrder}
        reference={data && data.reference ? data.reference : null}
        updated={data && data.updated ? dateInTz(data.updated, 'localized-datetime') : null}
      />
      <div className="OrdersUpdateView-content">
        {isLoading && (
          <div className={classes.orderLoaderWrapper}>
            <div className={classes.linearProgress}>
              <ShoppingCartTwoToneIcon
                fontSize="large"
                color="primary"
                className={classes.cartIcon}
              />
              <LinearProgress color="secondary" variant="query" />
              <p className="loader-wpr">{translation().views.orders.update.loading_text}</p>
            </div>
          </div>
        )}
        {data && !isObjEmpty(data) && (
          <div className={classes.listRoot}>
            <Grid spacing={3} container>
              <Grid item xs={12} sm={12} md={4} lg={3}>
                {(data.user || data.company || data.application) && (
                  <Card className={classes.paper}>
                    <CardHeader
                      className={classes.cardHeader}
                      avatar={
                        <Avatar>
                          <AccountCircleIcon />
                        </Avatar>
                      }
                      title={translation().views.orders.create.confirmation.client}
                      titleTypographyProps={{
                        variant: 'h5',
                        style: {
                          fontSize: 21
                        }
                      }}
                      action={
                        <IconButton
                          aria-label="Edit user"
                          href={`/users/${data.user.id}/update`}
                          target="_blank">
                          <EditIcon fontSize="small" />
                        </IconButton>
                      }
                    />
                    <CardContent className={classes.cardContent}>
                      <List>
                        {data.user && data.user.email && (
                          <ListItem dense>
                            <ListItemText
                              disableTypography
                              primary={
                                <>
                                  <span className={classes.listItemName}>
                                    {translation().views.orders.create.confirmation.email}:
                                  </span>
                                  {data.user.email}
                                </>
                              }
                            />
                          </ListItem>
                        )}
                        {data.user && data.user.last_name && data.user.first_name && (
                          <ListItem dense>
                            <ListItemText
                              disableTypography
                              primary={
                                <>
                                  <span className={classes.listItemName}>
                                    {translation().views.orders.create.confirmation.last_first_name}
                                    :
                                  </span>
                                  {data.user.last_name} {data.user.first_name}
                                </>
                              }
                            />
                          </ListItem>
                        )}
                        {data.company && data.company.commercial_name && (
                          <ListItem dense>
                            <ListItemText
                              disableTypography
                              primary={
                                <>
                                  <span className={classes.listItemName}>
                                    {translation().views.orders.create.confirmation.company}:
                                  </span>
                                  {data.company.commercial_name}
                                </>
                              }
                            />
                          </ListItem>
                        )}
                        {data.application && data.application.name && (
                          <ListItem dense>
                            <ListItemText
                              disableTypography
                              primary={
                                <>
                                  <span className={classes.listItemName}>
                                    {translation().views.orders.update.labels.application}:
                                  </span>
                                  {data.application.name}
                                </>
                              }
                            />
                          </ListItem>
                        )}
                        {data.delegate_email && (
                          <ListItem dense>
                            <ListItemText
                              disableTypography
                              primary={
                                <>
                                  <span className={classes.listItemName}>
                                    {translation().views.orders.update.labels.delegate_email}:
                                  </span>
                                  {data.delegate_email}
                                </>
                              }
                            />
                          </ListItem>
                        )}
                        {data.delegate_user &&
                          data.delegate_user.last_name &&
                          data.delegate_user.first_name && (
                            <ListItem dense>
                              <ListItemText
                                disableTypography
                                primary={
                                  <>
                                    <span className={classes.listItemName}>
                                      {translation().views.orders.update.labels.delegate_user}:
                                    </span>
                                    {data.delegate_user.last_name} {data.delegate_user.first_name}
                                  </>
                                }
                              />
                            </ListItem>
                          )}
                        {data.delegate_status !== null && (
                          <ListItem dense>
                            <ListItemText
                              disableTypography
                              style={{
                                display: 'flex'
                              }}
                              primary={
                                <>
                                  <span className={classes.listItemName}>
                                    {translation().views.orders.update.labels.delegate_status}:
                                  </span>
                                  {data.delegate_status ? (
                                    <DoneIcon
                                      style={{ color: config.success_color || '#4CAF50' }}
                                      fontSize="small"
                                    />
                                  ) : (
                                    <CloseIcon
                                      style={{ color: config.error_color || '#F44336' }}
                                      fontSize="small"
                                    />
                                  )}
                                </>
                              }
                            />
                          </ListItem>
                        )}
                      </List>
                    </CardContent>
                  </Card>
                )}
                {data.property && (
                  <Card className={classes.paper}>
                    <CardHeader
                      className={classes.cardHeader}
                      avatar={
                        <Avatar>
                          <HouseIcon />
                        </Avatar>
                      }
                      title={translation().views.orders.create.confirmation.property}
                      titleTypographyProps={{
                        variant: 'h5',
                        style: {
                          fontSize: 21
                        }
                      }}
                      action={
                        <IconButton
                          aria-label="Edit property"
                          href={`/properties/${data.property.id}/update`}
                          target="_blank">
                          <EditIcon fontSize="small" />
                        </IconButton>
                      }
                    />
                    <CardContent className={classes.cardContent}>
                      <List>
                        {data.property.address && (
                          <ListItem dense>
                            <ListItemText
                              disableTypography
                              primary={
                                <>
                                  <span className={classes.listItemName}>
                                    {
                                      translation().views.orders.create.confirmation
                                        .property_address
                                    }
                                    :
                                  </span>
                                  {data.property.address}
                                </>
                              }
                            />
                            {data.property.latitude && data.property.longitude && (
                              <ListItemSecondaryAction>
                                <IconButton
                                  edge="end"
                                  aria-label="launch map"
                                  href={
                                    'https://www.google.com/maps/search/?api=1&query=' +
                                    data.property.latitude +
                                    ',' +
                                    data.property.longitude
                                  }
                                  target="_blank"
                                  size="small">
                                  <LaunchIcon fontSize="small" />
                                </IconButton>
                              </ListItemSecondaryAction>
                            )}
                          </ListItem>
                        )}
                        {data.property.city && (
                          <ListItem dense>
                            <ListItemText
                              disableTypography
                              primary={
                                <>
                                  <span className={classes.listItemName}>
                                    {translation().views.orders.update.labels.city}:
                                  </span>
                                  {data.property.city}
                                </>
                              }
                            />
                          </ListItem>
                        )}
                        {data.property.size_formatted && (
                          <ListItem dense>
                            <ListItemText
                              disableTypography
                              primary={
                                <>
                                  <span className={classes.listItemName}>
                                    {translation().views.orders.create.confirmation.property_size}:
                                  </span>
                                  {data.property.size_formatted}
                                </>
                              }
                            />
                          </ListItem>
                        )}
                        {data.property.property_type ? (
                          <ListItem dense>
                            <ListItemText
                              disableTypography
                              primary={
                                <>
                                  <span className={classes.listItemName}>
                                    {translation().views.orders.create.confirmation.property_type}:
                                  </span>
                                  {data.property.property_type}
                                </>
                              }
                            />
                          </ListItem>
                        ) : data.estate_property_object &&
                          data.estate_property_object.property_type ? (
                          <ListItem dense>
                            <ListItemText
                              disableTypography
                              primary={
                                <>
                                  <span className={classes.listItemName}>
                                    {translation().views.orders.create.confirmation.property_type}:
                                  </span>
                                  {data.estate_property_object.property_type}
                                </>
                              }
                            />
                          </ListItem>
                        ) : (
                          false
                        )}
                        {data.property.country_isocode && (
                          <ListItem dense>
                            <ListItemText
                              disableTypography
                              style={{ display: 'flex', alignItems: 'center' }}
                              primary={
                                <>
                                  <img
                                    src={`/assets/images/flags/${data.property.country_isocode.toUpperCase()}.svg`}
                                    alt={data.property.country_isocode}
                                    height={18}
                                    width={24}
                                    style={{ marginRight: 4 }}
                                  />
                                  {data.property.country
                                    ? data.property.country
                                    : data.property.country_isocode}
                                </>
                              }
                            />
                          </ListItem>
                        )}
                      </List>
                    </CardContent>
                  </Card>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={6}>
                {data.products && data.products.length > 0 ? (
                  data.products.map((product, index) => (
                    <ProductCard
                      key={index}
                      {...product}
                      history={history}
                      addOrderProductOption={addOrderProductOption}
                      cancelOrderProduct={cancelOrderProduct}
                      clearOrderUpdatePlanning={clearOrderUpdatePlanning}
                      getOrderProductHistory={getOrderProductHistory}
                      getOrderProductDeliveryStatuses={getOrderProductDeliveryStatuses}
                      getOrderProductDispatchableProviders={getOrderProductDispatchableProviders}
                      getOrderProductProviderInfos={getOrderProductProviderInfos}
                      getOrderProductSlots={getOrderProductSlots}
                      getNextOrderProductSlots={getNextOrderProductSlots}
                      getPreviousOrderProductSlots={getPreviousOrderProductSlots}
                      manageOrderProductFlag={manageOrderProductFlag}
                      orderProperty={data.property}
                      productCancel={productCancel}
                      productFlag={productFlag}
                      providersDispatching={providersDispatching}
                      productHistory={productHistory}
                      productOptions={productOptions}
                      productDeliveryStatuses={deliveryStatuses}
                      productPlanning={productPlanning}
                      productProvider={productProvider}
                      productPropertyDetails={productPropertyDetails}
                      providersInfos={providersInfos}
                      postOrderProductDispatchableProviders={postOrderProductDispatchableProviders}
                      reloadOrder={loadOrder}
                      removeOrderProductOption={removeOrderProductOption}
                      updateOrderProductAttributes={updateOrderProductAttributes}
                      updateOrderProductDeliveryStatus={updateOrderProductDeliveryStatus}
                      updateOrderProductProvider={updateOrderProductProvider}
                      updateOrderProductStartDate={updateOrderProductStartDate}
                      updateOrderProductPropertyDetails={updateOrderProductPropertyDetails}
                    />
                  ))
                ) : (
                  <Alert
                    type="info"
                    status={translation().commons.alerts.info}
                    text={translation().views.orders.update.products.no_product}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={3}>
                <Card className={classes.paper}>
                  <CardHeader
                    className={classes.cardHeader}
                    avatar={
                      <Avatar>
                        <EuroIcon />
                      </Avatar>
                    }
                    title={translation().views.orders.update.pricing.title}
                    titleTypographyProps={{
                      variant: 'h5',
                      style: {
                        fontSize: 21
                      }
                    }}
                  />
                  <CardContent className={classes.cardContent}>
                    <List>
                      <ListItem dense>
                        <ListItemText
                          disableTypography
                          primary={
                            <>
                              {
                                translation().commons.pricing
                                  .total_tax_excluded_before_discount_formatted
                              }
                              <span className={classes.listItemPrice}>
                                {data.total_tax_excluded_before_discount_formatted}
                              </span>
                            </>
                          }
                        />
                      </ListItem>
                      <ListItem dense>
                        <ListItemText
                          disableTypography
                          primary={
                            <>
                              {translation().commons.pricing.total_discount_formatted}
                              <span className={classes.listItemPrice}>
                                {data.total_discount_formatted}
                              </span>
                            </>
                          }
                        />
                        <ListItemSecondaryAction>
                          <AddDiscountButton
                            addDiscount={discounts.addDiscount}
                            addOrderDiscount={handleAddOrderDiscount}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                      {discounts && discounts.data && discounts.data.length > 0
                        ? discounts.data.map((promo, index) => {
                            return (
                              <ListItem dense key={index}>
                                <ListItemIcon className={classes.listIconRoot}>
                                  <LabelIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText
                                  primary={
                                    <>
                                      {promo.discount && promo.discount.name
                                        ? promo.discount.name
                                        : '[Missing name]'}
                                      <span className={classes.listItemPrice}>
                                        {promo.amount_formatted
                                          ? promo.amount_formatted
                                          : '[Missing amount]'}
                                      </span>
                                    </>
                                  }
                                />
                                <ListItemSecondaryAction>
                                  <DeleteDiscountButton
                                    id={promo.id}
                                    deleteOrderDiscount={handleDeleteDiscount}
                                    deleteDiscount={discounts.deleteDiscount}
                                  />
                                </ListItemSecondaryAction>
                              </ListItem>
                            );
                          })
                        : !discounts.isLoading && (
                            <ListItem className={classes.listItemUnpadTop}>
                              <ListItemText
                                secondary={translation().views.orders.update.discounts.no_discount}
                              />
                            </ListItem>
                          )}
                      {discounts.isLoading && (
                        <div className="loader-wpr">
                          <CircularProgress size={25} />
                        </div>
                      )}
                      <ListItem dense>
                        <ListItemText
                          disableTypography
                          primary={
                            <>
                              {translation().commons.pricing.total_tax_formatted}
                              <span className={classes.listItemPrice}>
                                {data.total_tax_formatted}
                              </span>
                            </>
                          }
                        />
                      </ListItem>
                      <ListItem dense>
                        <ListItemText
                          disableTypography
                          primary={
                            <>
                              {translation().commons.pricing.total_tax_excluded_formatted}
                              <span className={classes.listItemPrice}>
                                {data.total_tax_excluded_formatted}
                              </span>
                            </>
                          }
                        />
                      </ListItem>
                      {data.delegate_amount && data.delegate_amount_formatted ? (
                        <ListItem dense>
                          <ListItemText
                            disableTypography
                            primary={
                              <>
                                {translation().views.orders.update.labels.delegate_amount_formatted}
                                :
                                <span className={classes.listItemPrice}>
                                  {data.delegate_amount_formatted}
                                </span>
                              </>
                            }
                          />
                        </ListItem>
                      ) : (
                        ''
                      )}
                      {data.delegate_amount_tax_excluded &&
                      data.delegate_amount_tax_excluded_formatted ? (
                        <ListItem dense>
                          <ListItemText
                            disableTypography
                            primary={
                              <>
                                {
                                  translation().views.orders.update.labels
                                    .delegate_amount_tax_excluded_formatted
                                }
                                :
                                <span className={classes.listItemPrice}>
                                  {data.delegate_amount_tax_excluded_formatted}
                                </span>
                              </>
                            }
                          />
                        </ListItem>
                      ) : (
                        ''
                      )}
                    </List>
                    <Divider />
                    <List>
                      <ListItem dense>
                        <ListItemText
                          primaryTypographyProps={{
                            style: { fontSize: 21 }
                          }}
                          primary={
                            <>
                              {translation().commons.pricing.total_formatted}
                              <span className={classes.listItemPrice}>{data.total_formatted}</span>
                            </>
                          }
                        />
                        <ListItemSecondaryAction>
                          <OrderDetailsButton order={data} />
                        </ListItemSecondaryAction>
                      </ListItem>
                    </List>
                  </CardContent>
                </Card>
                <Card className={classes.paper} style={{ minHeight: 0 }}>
                  <CardHeader
                    className={classes.cardHeader}
                    avatar={
                      <Avatar>
                        <ReceiptIcon />
                      </Avatar>
                    }
                    title={translation().views.orders.update.invoicing.title}
                    titleTypographyProps={{
                      variant: 'h5',
                      style: {
                        fontSize: 21
                      }
                    }}
                  />
                  <CardContent className={classes.cardContent}>
                    <List>
                      <ListItem dense>
                        {data.invoice_address ? (
                          <ListItemText
                            disableTypography
                            primary={
                              <>
                                <span className={classes.listItemName}>
                                  {translation().views.orders.create.confirmation.invoice_address}:
                                </span>
                                {(data.invoice_address.name
                                  ? data.invoice_address.name + ' '
                                  : '') +
                                  (data.invoice_address.street_number
                                    ? data.invoice_address.street_number + ' '
                                    : ' ') +
                                  (data.invoice_address.street
                                    ? data.invoice_address.street + ' '
                                    : ' ') +
                                  (data.invoice_address.zip_code
                                    ? data.invoice_address.zip_code + ' '
                                    : ' ') +
                                  (data.invoice_address.city
                                    ? data.invoice_address.city + ' '
                                    : '')}
                              </>
                            }
                          />
                        ) : (
                          <ListItemText
                            disableTypography
                            primary={
                              <>
                                <span className={classes.listItemName}>
                                  {translation().views.orders.create.confirmation.invoice_address}:
                                </span>
                                {translation().views.orders.update.invoicing.no_address}
                              </>
                            }
                          />
                        )}
                        <ListItemSecondaryAction>
                          <EditInvoiceAddressButton
                            editOrderInvoiceAddress={editOrderInvoiceAddress}
                            getOrderClientAddresses={getOrderClientAddresses}
                            initialAddress={data.invoice_address}
                            invoiceAddresses={invoiceAddresses}
                            onSuccess={loadOrder}
                            orderId={data.id}
                            user={data.user}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                      <ListItem dense>
                        {data.invoice_payment_method ? (
                          <ListItemText
                            disableTypography
                            primary={
                              <>
                                <span className={classes.listItemName}>
                                  {translation().views.orders.create.confirmation.invoice_payment}:
                                </span>
                                <span className={classes.breakWord}>
                                  {data.invoice_payment_method.name
                                    ? data.invoice_payment_method.name
                                    : data.invoice_payment_method.id}
                                </span>
                              </>
                            }
                          />
                        ) : (
                          <ListItemText
                            disableTypography
                            primary={
                              <>
                                <span className={classes.listItemName}>
                                  {translation().views.orders.create.confirmation.invoice_payment}:
                                </span>
                                {
                                  translation().views.orders.update.payment_methods
                                    .no_method_in_order
                                }
                              </>
                            }
                          />
                        )}
                        <ListItemSecondaryAction>
                          <EditPaymentMethodButton
                            currency={data.currency}
                            editOrderPaymentMethod={editOrderPaymentMethod}
                            getOrderClientPaymentMethods={getOrderClientPaymentMethods}
                            initialMethod={
                              data.invoice_payment_method && data.invoice_payment_method.name
                                ? data.invoice_payment_method.name
                                : null
                            }
                            paymentMethods={paymentMethods}
                            onSuccess={loadOrder}
                            orderId={data.id}
                            user={data.user}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                      {data.delegate_invoice_address && (
                        <ListItem dense>
                          <ListItemText
                            disableTypography
                            primary={
                              <>
                                <span className={classes.listItemName}>
                                  {translation().views.orders.update.labels.delegate_address}:
                                </span>
                                {(data.delegate_invoice_address.name
                                  ? data.delegate_invoice_address.name + ' '
                                  : '') +
                                  (data.delegate_invoice_address.street_number
                                    ? data.delegate_invoice_address.street_number + ' '
                                    : ' ') +
                                  (data.delegate_invoice_address.street
                                    ? data.delegate_invoice_address.street + ' '
                                    : ' ') +
                                  (data.delegate_invoice_address.zip_code
                                    ? data.delegate_invoice_address.zip_code + ' '
                                    : ' ') +
                                  (data.delegate_invoice_address.city
                                    ? data.delegate_invoice_address.city + ' '
                                    : '')}
                              </>
                            }
                          />
                        </ListItem>
                      )}
                      <ListItem dense>
                        {data.delegate_invoice_payment_method && (
                          <ListItemText
                            disableTypography
                            primary={
                              <>
                                <span className={classes.listItemName}>
                                  {translation().views.orders.update.labels.delegate_payment_method}
                                  :
                                </span>
                                <span className={classes.breakWord}>
                                  {data.delegate_invoice_payment_method.name
                                    ? data.delegate_invoice_payment_method.name
                                    : data.delegate_invoice_payment_method.id}
                                </span>
                              </>
                            }
                          />
                        )}
                      </ListItem>
                    </List>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    </div>
  );
}

OrdersUpdateView.propTypes = {
  addOrderDiscount: PropTypes.func.isRequired,
  addOrderProductOption: PropTypes.func.isRequired,
  cancelOrderProduct: PropTypes.func.isRequired,
  clearOrderUpdatePlanning: PropTypes.func.isRequired,
  clearOwnReducer: PropTypes.func.isRequired,
  deleteOrderDiscount: PropTypes.func.isRequired,
  editOrderInvoiceAddress: PropTypes.func.isRequired,
  editOrderPaymentMethod: PropTypes.func.isRequired,
  getOrder: PropTypes.func.isRequired,
  getOrderClientAddresses: PropTypes.func.isRequired,
  getOrderClientPaymentMethods: PropTypes.func.isRequired,
  getOrderDiscounts: PropTypes.func.isRequired,
  getOrderProductDeliveryStatuses: PropTypes.func.isRequired,
  getOrderProductDispatchableProviders: PropTypes.func.isRequired,
  getOrderProductHistory: PropTypes.func.isRequired,
  getOrderProductProviderInfos: PropTypes.func.isRequired,
  getOrderProductSlots: PropTypes.func.isRequired,
  getNextOrderProductSlots: PropTypes.func.isRequired,
  getPreviousOrderProductSlots: PropTypes.func.isRequired,
  history: PropTypes.shape().isRequired,
  manageOrderProductFlag: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired])
    }).isRequired
  }).isRequired,
  postOrderProductDispatchableProviders: PropTypes.func.isRequired,
  removeOrderProductOption: PropTypes.func.isRequired,
  update: PropTypes.shape({
    isLoading: PropTypes.bool,
    data: PropTypes.shape(),
    deliveryStatuses: PropTypes.shape({
      isLoading: PropTypes.bool,
      data: PropTypes.arrayOf(PropTypes.any)
    }).isRequired,
    discounts: PropTypes.shape({
      isLoading: PropTypes.bool,
      data: PropTypes.arrayOf(PropTypes.any),
      addDiscount: PropTypes.shape(),
      deleteDiscount: PropTypes.shape()
    }).isRequired,
    invoiceAddresses: PropTypes.shape().isRequired,
    paymentMethods: PropTypes.shape().isRequired,
    productCancel: PropTypes.shape().isRequired,
    productFlag: PropTypes.shape().isRequired,
    productHistory: PropTypes.shape().isRequired,
    productOptions: PropTypes.shape().isRequired,
    productPlanning: PropTypes.shape().isRequired,
    productProvider: PropTypes.shape().isRequired,
    productPropertyDetails: PropTypes.shape().isRequired,
    providersDispatching: PropTypes.shape().isRequired,
    providersInfos: PropTypes.shape().isRequired
  }).isRequired,
  updateOrderProductAttributes: PropTypes.func.isRequired,
  updateOrderProductDeliveryStatus: PropTypes.func.isRequired,
  updateOrderProductProvider: PropTypes.func.isRequired,
  updateOrderProductStartDate: PropTypes.func.isRequired,
  updateOrderProductPropertyDetails: PropTypes.func.isRequired
};

export default OrdersUpdateView;
