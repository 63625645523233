import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import FormTextField from '../../../components/forms/fields/FormTextField';
import translation from '../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  fieldsWrapper: {
    margin: theme.spacing(1, 0, 5)
  }
}));

function Password(props) {
  const { onChange, profile } = props;

  const classes = useStyles();

  return (
    <div className={classes.fieldsWrapper}>
      <FormTextField
        name="old_password"
        label={translation().views.profile.form.labels.current_password}
        type="password"
        autoComplete="current-password"
        onChange={onChange('old_password')}
        error={
          profile.errors && profile.errors['old_password'] ? profile.errors['old_password'] : null
        }
        disabled={profile.isLoading}
        required
      />
      <FormTextField
        name="new_password"
        label={translation().views.profile.form.labels.new_password}
        type="password"
        autoComplete="new-password"
        onChange={onChange('new_password')}
        error={
          profile.errors && profile.errors['new_password'] ? profile.errors['new_password'] : null
        }
        disabled={profile.isLoading}
        required
      />
      <FormTextField
        name="confirm_new_password"
        label={translation().views.profile.form.labels.confirm_new_password}
        type="password"
        autoComplete="new-password"
        onChange={onChange('confirm_new_password')}
        error={
          profile.errors && profile.errors['confirm_new_password']
            ? profile.errors['confirm_new_password']
            : null
        }
        disabled={profile.isLoading}
        required
      />
    </div>
  );
}

Password.propTypes = {
  onChange: PropTypes.func.isRequired,
  profile: PropTypes.shape({
    isLoading: PropTypes.bool,
    errors: PropTypes.shape()
  }).isRequired
};

export default Password;
