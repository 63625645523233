const initialState = {
  values: {},
  isLoading: false,
  isError: false,
  queryString: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_REDUCERS':
      return initialState;
    case 'CLEAR_FILTERS':
      return initialState;
    case 'SET_FILTER_VALUE':
      return {
        ...state,
        values: {
          ...state['values'],
          [action.name]: {
            ...state.values[action.name],
            value: action.value !== '' ? action.value : null
          }
        }
      };
    case 'SET_FILTER_OPERATOR':
      return {
        ...state,
        values: {
          ...state['values'],
          [action.name]: {
            ...state.values[action.name],
            operator: action.operator !== '' ? action.operator : null
          }
        }
      };
    case 'SET_FILTER_VALUE_FULL_OBJECT':
      return {
        ...state,
        values: {
          ...state['values'],
          [action.name]: {
            ...state.values[action.name],
            fullObject: action.fullObj
          }
        }
      };
    case 'SET_FILTERS_VALUES':
      return {
        ...state,
        values: action.values
      };
    case 'SET_FILTERS_QUERY_STRING':
      return {
        ...state,
        queryString: action.query
      };
    default:
      return state;
  }
};
