import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchApi } from '../../utils/functions/api';
import { formatFiltersValuesBeforePost } from '../../utils/functions/fitlers';

import { enqueueSnackbar } from '../../redux/actions/appActions';
import {
  getOrderProductsMetadata,
  getOrderProductsMetadataSuccess,
  getOrderProductsMetadataError
} from '../../redux/actions/orderProducts/metadata/orderProductsMetadataActions';
import {
  clearOwnReducer,
  getOrderProducts,
  getOrderProductsSuccess,
  getOrderProductsError,
  refreshOrderProducts,
  setOrderProductsSearchValue
} from '../../redux/actions/orderProducts/list/orderProductsListActions';
import { setListNeedReload } from '../../redux/actions/core/list/listActions';

import OrderProductsListView from '../../views/orderProducts/OrderProductsListView';

import { clearFilters } from '../../redux/actions/filters/filtersActions';

import translation from '../../translation/translation';
import store from '../../redux/store';

const mapStateToProps = (store) => ({
  list: store.orderProducts.list,
  metadata: store.orderProducts.metadata
});

const mapDispatchToProps = (dispatch) => {
  return {
    clearFilters() {
      dispatch(clearFilters());
    },
    clearOwnReducer() {
      dispatch(clearOwnReducer());
    },
    setDataSearchValue(value) {
      dispatch(setOrderProductsSearchValue(value));
    },
    setListNeedReload() {
      dispatch(setListNeedReload());
    },
    async getMetadata(uri, sourceToken = null) {
      if (!uri) return;

      dispatch(getOrderProductsMetadata());

      try {
        await fetchApi(
          'get',
          `${uri}/metadata`,
          null,
          null,
          null,
          (metadata) => {
            dispatch(getOrderProductsMetadataSuccess(metadata));
          },
          (error) => {
            dispatch(getOrderProductsMetadataError(true, error));

            if (error.response) {
              dispatch(
                enqueueSnackbar({
                  message:
                    error.response.data && error.response.data.detail
                      ? error.response.data.detail
                      : error.response.data.status_code
                      ? error.response.data.status_code + ': ' + translation().core.item.not_found
                      : translation().core.list.error_metadata,
                  status: error.response.status || undefined,
                  options: {
                    variant: 'error'
                  }
                })
              );
            } else {
              dispatch(
                enqueueSnackbar({
                  message: translation().core.list.error_metadata,
                  options: {
                    variant: 'error'
                  }
                })
              );
            }
          },
          null,
          sourceToken
        );
      } catch (error) {
        dispatch(getOrderProductsMetadataError(true, error));
        dispatch(
          enqueueSnackbar({
            message: translation().core.list.error_metadata,
            options: {
              variant: 'error'
            }
          })
        );
      }
    },
    async getData(uri, sourceToken = null, search = null, next = null, isRefresh = false) {
      /**
       * Double GET list for tall screens
       */

      if (!uri) return;

      if (isRefresh) dispatch(refreshOrderProducts());
      else dispatch(getOrderProducts(search, next));

      try {
        await fetchApi(
          'get',
          next ? next : uri,
          !next && search ? { search } : {},
          null,
          null,
          (data, paging) => {
            dispatch(getOrderProductsSuccess(data, paging));
          },
          (error) => {
            dispatch(getOrderProductsError(true, error));
          },
          null,
          sourceToken
        );
      } catch (error) {
        dispatch(getOrderProductsError(true, error));
      }
    },
    async filterOrderProducts(uri, metadata) {
      if (!uri) {
        return;
      }

      const filtersValues = store.getState().filters.values;
      let query = { filters: '' };

      dispatch(getOrderProducts(true));

      for (let value in filtersValues) {
        /**
         * Check if operator in range is set (for timestamp type for example)
         */
        if (
          filtersValues[value] &&
          filtersValues[`${value}.start`] &&
          filtersValues[`${value}.end`] &&
          filtersValues[`${value}.start`].value &&
          filtersValues[`${value}.end`].value &&
          filtersValues[value].operator &&
          filtersValues[value].operator === 'range'
        ) {
          const rangeStart = { value: filtersValues[`${value}.start`].value };
          const rangeEnd = { value: filtersValues[`${value}.end`].value };

          query.filters += `${value}|gte|${formatFiltersValuesBeforePost(
            metadata,
            value,
            rangeStart
          )},`;
          query.filters += `${value}|lte|${formatFiltersValuesBeforePost(
            metadata,
            value,
            rangeEnd
          )},`;
        } else if (
          filtersValues[value] &&
          filtersValues[value].operator &&
          (filtersValues[value].operator === 'nu' || filtersValues[value].operator === 'nnu')
        ) {
          query.filters += `${value}|${filtersValues[value].operator}|,`;
        } else if (
          filtersValues[value] &&
          filtersValues[value].value &&
          !value.includes('.start') &&
          !value.includes('.end')
        ) {
          query.filters += `${value}|${
            filtersValues[value].operator ? filtersValues[value].operator : 'eq'
          }|${formatFiltersValuesBeforePost(metadata, value, filtersValues[value])},`;
        }
      }

      try {
        await fetchApi(
          'get',
          `${uri}`,
          query,
          null,
          null,
          (data, paging) => {
            dispatch(getOrderProductsSuccess(data, paging));
          },
          (error) => {
            dispatch(getOrderProductsError(true, error));
          }
        );
      } catch (error) {
        dispatch(getOrderProductsError(true, error));
      }
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderProductsListView));
