import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { grey, orange } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import DateRangeIcon from '@material-ui/icons/DateRange';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ReceiptIcon from '@material-ui/icons/Receipt';
import RemoveShoppingCartIcon from '@material-ui/icons/RemoveShoppingCart';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';

import { getTimezone } from '../../../../utils/functions/cookies';
import { dateInTz } from '../../../../utils/functions/dates';
import translation from '../../../../translation/translation';
import config from '../../../../config';

const useStyles = makeStyles((theme) => ({
  deleteIcon: {
    padding: 5,
    top: 5
  },
  deleteButton: {
    color: config.error_color || '#F44336',
    '&:hover': {
      backgroundColor: fade(config.error_color || '#F44336', 0.15)
    }
  },
  card: {
    marginBottom: 12,
    background: theme.palette.background.default,
    '&:last-child': {
      marginBottom: 0
    }
  },
  cardContent: {
    paddingTop: 0,
    '&:last-child': {
      paddingBottom: theme.spacing(2)
    }
  },
  optionsWrapper: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  cardHeader: {
    padding: theme.spacing(1, 2)
  },
  listRoot: {
    paddingTop: 0
  },
  listIcon: {
    minWidth: 30
  },
  listItem: {
    padding: 0
  },
  listItemSkeleton: {
    padding: 0,
    marginTop: 4,
    marginBottom: 8
  },
  root: {
    height: '100%',
    width: '100%',
    maxHeight: 450,
    overflow: 'auto',
    padding: theme.spacing(1),
    '&::-webkit-scrollbar': {
      width: 5
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent'
    },
    '&::-webkit-scrollbar-thumb': {
      background: grey['500'],
      borderRadius: 50
    }
  },
  title: {
    textAlign: 'center',
    fontSize: 18
  },
  emptyCart: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: 318,
    [theme.breakpoints.down('sm')]: {
      height: 75
    },
    '&.cartLoaded': {
      height: 178
    }
  },
  emptyCartIcon: {
    fontSize: 36,
    color: grey['400']
  },
  emptyCartText: {
    color: grey['400']
  },
  pricingCartRoot: {
    padding: theme.spacing(1)
  },
  pricingCartDivider: {
    marginTop: 4,
    marginBottom: 4
  },
  timezoneInfo: {
    color: orange[600]
  }
}));

function CartItem(props) {
  const classes = useStyles();
  const { id, name, start, price_formatted, options, handleOpenDeleteItem, timezone } = props;

  function handleClick() {
    handleOpenDeleteItem(id);
  }

  return (
    <Card className={classes.card}>
      <CardHeader
        titleTypographyProps={{
          variant: 'body1',
          component: 'p'
        }}
        action={
          <IconButton
            aria-label="delete"
            size="small"
            className={classes.deleteIcon}
            onClick={handleClick}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        }
        title={name}
        className={classes.cardHeader}
      />
      <CardContent className={classes.cardContent}>
        <List component="div" aria-label="details" className={classes.listRoot}>
          {start && (
            <ListItem className={classes.listItem}>
              <ListItemIcon className={classes.listIcon}>
                <DateRangeIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={dateInTz(start, 'localized-date')} disableTypography />
            </ListItem>
          )}
          {start && (
            <ListItem className={classes.listItem}>
              <ListItemIcon className={classes.listIcon}>
                <AccessTimeIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={dateInTz(start, 'HH:mm')} disableTypography />
            </ListItem>
          )}
          {timezone && getTimezone() !== timezone && (
            <ListItem className={classes.listItem}>
              <ListItemIcon className={classes.listIcon}>
                <ErrorOutlineIcon fontSize="small" className={classes.timezoneInfo} />
              </ListItemIcon>
              <ListItemText
                primary={`${translation().commons.hour_on_place} ${dateInTz(
                  start,
                  'localized-datetime',
                  timezone
                )} (${timezone})`}
                className={classes.timezoneInfo}
                disableTypography
              />
            </ListItem>
          )}
          {price_formatted && (
            <ListItem className={classes.listItem}>
              <ListItemIcon className={classes.listIcon}>
                <ReceiptIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={price_formatted} disableTypography />
            </ListItem>
          )}
        </List>
        {options && options.length > 0 ? (
          <div className={classes.optionsWrapper}>
            {options.map((option, i) => {
              return (
                <Chip
                  key={i}
                  size="small"
                  style={{ margin: 2 }}
                  label={option.name}
                  title={option.name}
                />
              );
            })}
          </div>
        ) : (
          ''
        )}
      </CardContent>
    </Card>
  );
}

CartItem.propTypes = {
  id: PropTypes.any.isRequired,
  name: PropTypes.string.isRequired,
  start: PropTypes.any,
  price_formatted: PropTypes.any,
  options: PropTypes.arrayOf(PropTypes.any),
  handleOpenDeleteItem: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired
};

function OrderItemSkeleton() {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <List component="div">
          <ListItem className={classes.listItemSkeleton}>
            <Skeleton width="80%" height={18} variant="rect" />
          </ListItem>
          <ListItem className={classes.listItemSkeleton}>
            <Skeleton width="55%" height={14} variant="rect" />
          </ListItem>
          <ListItem className={classes.listItemSkeleton}>
            <Skeleton width="55%" height={14} variant="rect" />
          </ListItem>
          <ListItem className={classes.listItemSkeleton}>
            <Skeleton width="55%" height={14} variant="rect" />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
}

function OrderCart(props) {
  const classes = useStyles();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const {
    data,
    isCreateLoading,
    isLoading,
    getClientCart,
    deleteClientCartItem,
    deleteItem,
    user
  } = props;

  const timezoneCode =
    data && data[0] && data[0].property_timezone_code ? data[0].property_timezone_code : undefined;

  function handleOpenDeleteItem(id) {
    setDeleteOpen(id);
  }

  function handleCloseDeleteItem() {
    setDeleteOpen(false);
  }

  function handleDeleteCartItem() {
    if (data && data[0] && data[0].id) {
      deleteClientCartItem(data[0].id, deleteOpen, () => {
        getClientCart(user);
        handleCloseDeleteItem();
      });
    }
  }

  return (
    <div>
      <div className={classes.root}>
        {isCreateLoading && (
          <div className="loader-wpr">
            <CircularProgress color="secondary" />
            <p>{translation().views.orders.create.cart.loading_text}</p>
          </div>
        )}
        {isLoading && <OrderItemSkeleton />}
        {data && data[0] && data[0].products && data[0].products.length > 0
          ? data[0].products.map((product, index) => {
              return (
                <CartItem
                  {...product}
                  key={index}
                  timezone={timezoneCode}
                  handleOpenDeleteItem={handleOpenDeleteItem}
                />
              );
            })
          : !isLoading &&
            !isCreateLoading && (
              <div
                className={classNames(classes.emptyCart, {
                  cartLoaded: data && data[0] ? true : false
                })}>
                <RemoveShoppingCartIcon className={classes.emptyCartIcon} />
                <Typography variant="body2" className={classes.emptyCartText} gutterBottom>
                  {translation().views.orders.create.cart.empty}
                </Typography>
              </div>
            )}
      </div>
      {data && data[0] && (
        <div className={classes.pricingCartRoot}>
          {data[0].total_tax_excluded_before_discount_formatted && (
            <div>
              <Typography variant="body2" component="span">
                {translation().commons.pricing.total_tax_excluded_before_discount_formatted}{' '}
              </Typography>
              <Typography variant="subtitle2" component="span">
                {data[0].total_tax_excluded_before_discount_formatted}
              </Typography>
            </div>
          )}
          {data[0].total_discount_formatted && (
            <div>
              <Typography variant="body2" component="span">
                {translation().commons.pricing.total_discount_formatted}{' '}
              </Typography>
              <Typography variant="subtitle2" component="span">
                {data[0].total_discount_formatted}
              </Typography>
            </div>
          )}
          {data[0].total_tax_formatted && (
            <div>
              <Typography variant="body2" component="span">
                {translation().commons.pricing.total_tax_formatted}{' '}
              </Typography>
              <Typography variant="subtitle2" component="span">
                {data[0].total_tax_formatted}
              </Typography>
            </div>
          )}
          {data[0].total_tax_excluded_formatted && (
            <div>
              <Typography variant="body2" component="span">
                {translation().commons.pricing.total_tax_excluded_formatted}{' '}
              </Typography>
              <Typography variant="subtitle2" component="span">
                {data[0].total_tax_excluded_formatted}
              </Typography>
            </div>
          )}
          <Divider className={classes.pricingCartDivider} />
          {data[0].total_formatted && (
            <div>
              <Typography variant="button" component="span">
                {translation().commons.pricing.total_formatted}{' '}
              </Typography>
              <Typography variant="button" component="span">
                {data[0].total_formatted}
              </Typography>
            </div>
          )}
        </div>
      )}
      <Dialog
        open={deleteOpen ? true : false}
        onClose={handleCloseDeleteItem}
        aria-labelledby="delete-cart-item"
        aria-describedby="delete-cart-item-description"
        disableBackdropClick={deleteItem.isLoading}>
        <DialogTitle id="delete-cart-item">
          {translation().core.list.dialogs.delete.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-cart-item-description">
            {translation().core.list.dialogs.delete.content}
          </DialogContentText>
          {deleteItem.isLoading && (
            <div className="loader-wpr" style={{ marginTop: 15 }}>
              <CircularProgress size={30} color="secondary" />
              <p>{translation().core.list.dialogs.delete.load_delete}</p>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteItem} color="default" disabled={deleteItem.isLoading}>
            {translation().actions.cancel}
          </Button>
          <Button
            onClick={handleDeleteCartItem}
            className={classes.deleteButton}
            disabled={deleteItem.isLoading}>
            {translation().actions.delete}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

OrderCart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  deleteClientCartItem: PropTypes.func.isRequired,
  deleteItem: PropTypes.shape({
    isLoading: PropTypes.bool
  }).isRequired,
  isCreateLoading: PropTypes.bool,
  isLoading: PropTypes.bool,
  getClientCart: PropTypes.func.isRequired,
  user: PropTypes.shape()
};

export default OrderCart;
