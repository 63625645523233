import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import { useDropzone } from 'react-dropzone';
import { Button, CircularProgress, Fab, Typography } from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';

import translation from '../../../translation/translation';
import UploadFileInDropperIcon from '../../icons/UploadFileInDropperIcon';
import DropCard from './DropCard';

function Dropper(props) {
  const {
    accept,
    disabled,
    filesArray,
    maxFiles,
    maxSize,
    minSize,
    onClearDroppper,
    onDeleteFile,
    onDrop,
    showFilesName,
    uploadInProgressCount
  } = props;

  function handleDrop(filesAccepted, filesRejected) {
    if (onDrop) {
      onDrop(filesAccepted, filesRejected);
    }
  }

  const { getRootProps, isDragActive, getInputProps, open } = useDropzone({
    accept,
    disabled,
    noClick: true,
    noKeyboard: true,
    multiple: true,
    onDrop: handleDrop,
    maxFiles,
    maxSize,
    minSize
  });

  return (
    <div className="drop-zone">
      <div
        {...getRootProps({
          className: classNames('dropzone dropper', {
            'dropzone--isActive dropper-ondrag': isDragActive
          })
        })}>
        <input {...getInputProps()} />
        <div>
          {!uploadInProgressCount && filesArray && filesArray.length <= 0 ? (
            <div className="drop-it">
              <UploadFileInDropperIcon height={125} />
              <div>
                <Typography>
                  {translation().views.orders.media_groups.file_uploader.drop_file}
                </Typography>
                <Typography className="or-click" component="span">
                  {translation().views.orders.media_groups.file_uploader.or}
                </Typography>
                <Button onClick={open} variant="outlined">
                  {translation().views.orders.media_groups.file_uploader.select}
                </Button>
              </div>
            </div>
          ) : (
            <>
              <Fab
                aria-label="Remove all files"
                className="drop-corner-btn remove-all"
                disabled={disabled}
                onClick={onClearDroppper}
                size="small">
                <Delete />
              </Fab>
              <Fab
                aria-label="Drop files"
                className="drop-corner-btn"
                color="primary"
                disabled={disabled}
                onClick={open}
                size="small">
                <Add />
              </Fab>
            </>
          )}
          {filesArray && filesArray.length > 0
            ? filesArray.map((file, index) => {
                return (
                  <DropCard
                    key={index}
                    disabled={disabled}
                    file={file}
                    onDeleteFile={onDeleteFile}
                    showFilesName={showFilesName}
                  />
                );
              })
            : ''}
          {_.times(uploadInProgressCount, (index) => (
            <div className="MuiPaper-root file-incoming" key={index}>
              <div className="file-loader">
                <CircularProgress color="secondary" size={35} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

Dropper.defaultProps = {
  accept: '',
  disabled: false,
  maxFiles: 1000,
  maxSize: 10000000,
  minSize: 0,
  showFilesName: false
};

Dropper.propTypes = {
  accept: PropTypes.string,
  disabled: PropTypes.bool,
  filesArray: PropTypes.arrayOf(PropTypes.shape()),
  maxFiles: PropTypes.number,
  maxSize: PropTypes.number,
  minSize: PropTypes.number,
  onClearDroppper: PropTypes.func.isRequired,
  onDeleteFile: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
  showFilesName: PropTypes.bool,
  uploadInProgressCount: PropTypes.number
};

export default Dropper;
