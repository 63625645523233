import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import config from '../../../../../../config';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    display: 'flex',
    margin: 4,
    width: 175,
    height: 175
  },
  card: {
    display: 'flex',
    height: '100%',
    width: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: 4,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#e0e0e0',
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  },
  footer: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    textAlign: 'right',
    padding: 4
  },
  iconButton: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: '#FFFFFF',
    margin: 4,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.4)'
    }
  }
}));

function AttachedFile(props) {
  const { url } = props;

  const classes = useStyles();
  const imagesExtensions = [
    'png',
    'PNG',
    'jpg',
    'jpeg',
    'JPG',
    'JPEG',
    'gif',
    'eps',
    'tif',
    'tiff'
  ];

  function handleDownload() {
    if (!url) return;

    const downloadIframe = document.getElementById('frame-download');
    const iframesContainer = document.getElementById('iframes-container');

    /**
     * If iframe already exists we only change url, if not we create it
     */
    if (downloadIframe) {
      downloadIframe.setAttribute('src', `${url}/download?client_id=${config.api_key}`);
    } else {
      const downloadIframe = `<iframe id="frame-download" title="iframe-download" width="0" height="0" style="display: none" src="${url}/download?client_id=${config.api_key}"/>`;
      iframesContainer.innerHTML = downloadIframe;
    }
  }

  let imgPreviewStyle = {};
  const isValid = url.indexOf('files') !== -1;
  const extension = url.split('.').pop();

  if (isValid && imagesExtensions.indexOf(extension) !== -1) {
    imgPreviewStyle = {
      backgroundImage:
        'url("' +
        (url.indexOf(`client_id=${config.api_key}`) !== -1
          ? url
          : `${url}?client_id=${config.api_key}`) +
        '")'
    };
  } else if (isValid) {
    imgPreviewStyle = {
      backgroundSize: 'inherit',
      backgroundImage: `url("/assets/images/medias/files/large/file-extension-${extension}.png")`
    };
  } else {
    return false;
  }

  return (
    <div className={classes.root}>
      <div className={classes.card} style={imgPreviewStyle}>
        <IconButton aria-label="Download" className={classes.iconButton} onClick={handleDownload}>
          <CloudDownloadIcon fontSize="small" />
        </IconButton>
      </div>
    </div>
  );
}

AttachedFile.propTypes = {
  url: PropTypes.string.isRequired
};

export default AttachedFile;
