import { connect } from 'react-redux';

import Filters from '../../components/filters/Filters';
import {
  setFilterFullObject,
  setFilterOperator,
  setFilterValue
} from '../../redux/actions/filters/filtersActions';

const mapStateToProps = (store) => ({ filters: store.filters });

const mapDispatchToProps = (dispatch) => {
  return {
    setFilterFullObject(name, fullObj) {
      dispatch(setFilterFullObject(name, fullObj));
    },
    setFilterOperator(name, operator) {
      dispatch(setFilterOperator(name, operator));
    },
    setFilterValue(name, value) {
      dispatch(setFilterValue(name, value));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
