import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { apiGeocodeTimezone, fetchApi } from '../../../utils/functions/api';
import { dateInTz, addToDate, formatDate } from '../../../utils/functions/dates';
import { enqueueSnackbar } from '../../../redux/actions/appActions';
import {
  addDiscountToCart,
  addDiscountToCartError,
  addDiscountToCartSuccess,
  addProductToCart,
  addProductToCartError,
  addProductToCartSuccess,
  blurProductOptions,
  cancelValidatingFields,
  clearOrderCreateReducer,
  createCalendar,
  createClientCart,
  createClientCartError,
  createClientCartSuccess,
  createOrder,
  createOrderError,
  createOrderSuccess,
  deleteClientCart,
  deleteClientCartError,
  deleteClientCartItem,
  deleteClientCartItemError,
  deleteClientCartItemSuccess,
  deleteClientCartSuccess,
  getClientAddresses,
  getClientAddressesError,
  getClientAddressesSuccess,
  getClientCart,
  getClientCartError,
  getClientCartSuccess,
  getClientPaymentMethods,
  getClientPaymentMethodsError,
  getClientPaymentMethodsSuccess,
  getNextProductSlots,
  getNextProductSlotsError,
  getNextProductSlotsSuccess,
  getPreviousProductSlots,
  getPreviousProductSlotsError,
  getPreviousProductSlotsSuccess,
  getProductGroups,
  getProductGroupsError,
  getProductGroupsSuccess,
  getProductOptions,
  getProductOptionsError,
  getProductOptionsSuccess,
  getProducts,
  getProductsError,
  getProductSlots,
  getProductSlotsError,
  getProductSlotsSuccess,
  getProductsSuccess,
  postClientAddress,
  postClientAddressError,
  postClientAddressSuccess,
  postMissingFields,
  postMissingFieldsError,
  postMissingFieldsSuccess,
  postValidatingFields,
  postValidatingFieldsError,
  postValidatingFieldsSuccess,
  removeDiscountToCart,
  removeDiscountToCartError,
  removeDiscountToCartSuccess,
  selectClientInvoiceAddress,
  selectClientPaymentMethod,
  setActiveOrderStep,
  setMissingFields,
  setNbTimeMax,
  setValidatingFields,
  storeClient,
  storeEstateValue,
  storeOrderReference,
  storePaymentDelegateValue,
  storeProperty,
  storePropertyLocation,
  storePropertyTimezone,
  storePropertyValue,
  togglePaymentDelegate
} from '../../../redux/actions/orders/create/ordersCreateActions';
import CreateOrderView from '../../../views/orders/create/CreateOrderView';
import translation from '../../../translation/translation';

const mapStateToProps = (store) => ({
  order_create: store.orders.create
});

const mapDispatchToProps = (dispatch) => {
  return {
    clearOrderCreateReducer() {
      dispatch(clearOrderCreateReducer());
    },
    blurProductOptions() {
      dispatch(blurProductOptions());
    },
    setActiveStep(step) {
      dispatch(setActiveOrderStep(step));
    },
    storePropertyLocation(location) {
      dispatch(storePropertyLocation(location));
    },
    storeClient(user) {
      dispatch(storeClient(user));
    },
    storePropertyValue(name, value) {
      dispatch(storePropertyValue(name, value));
    },
    storeEstateValue(name, value) {
      dispatch(storeEstateValue(name, value));
    },
    storeOrderReference(value) {
      dispatch(storeOrderReference(value));
    },
    storePaymentDelegateValue(name, value) {
      dispatch(storePaymentDelegateValue(name, value));
    },
    storeProperty(property) {
      if (property) dispatch(storeProperty(property));
    },
    selectClientInvoiceAddress(selected) {
      if (selected) dispatch(selectClientInvoiceAddress(selected));
    },
    selectClientPaymentMethod(selected) {
      if (selected) dispatch(selectClientPaymentMethod(selected));
    },
    togglePaymentDelegate() {
      dispatch(togglePaymentDelegate());
    },
    getPropertyTimezone(location, onSuccess, onError) {
      dispatch(getProductGroups());

      apiGeocodeTimezone(
        location,
        (success) => {
          if (success && success.code) {
            dispatch(storePropertyTimezone(success.code));
          }

          if (onSuccess && typeof onSuccess === 'function') {
            onSuccess();
          }
        },
        () => {
          dispatch(getProductGroupsError());

          if (onError && typeof onError === 'function') {
            onError();
          }
        }
      );
    },
    getClientPaymentMethods(user, onSuccess) {
      if (!user || !user.id) return;

      dispatch(getClientPaymentMethods());

      const query = { way: 'pay' };

      if (user.currency && user.currency.isocode) query.currency = user.currency.isocode;

      fetchApi(
        'get',
        `users/${user.id}/payment-methods/usable`,
        query,
        null,
        null,
        (data) => {
          dispatch(getClientPaymentMethodsSuccess(data));

          if (onSuccess && typeof onSuccess === 'function') onSuccess(data);
        },
        (error) => {
          dispatch(getClientPaymentMethodsError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().views.orders.create.callbacks.get_client_payment_methods.error,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message:
                  translation().views.orders.create.callbacks.get_client_payment_methods.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    },
    getClientAddresses(user, callback) {
      if (!user || !user.id) return;

      dispatch(getClientAddresses());

      const query = {};

      if (user.currency && user.currency.isocode) query.currency = user.currency.isocode;

      if (user.unit_system) query.unit_system = user.unit_system;

      fetchApi(
        'get',
        `users/${user.id}/addresses/usable`,
        query,
        null,
        null,
        (data) => {
          dispatch(getClientAddressesSuccess(data));

          if (callback && typeof callback === 'function') callback(data);
        },
        (error) => {
          dispatch(getClientAddressesError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().views.orders.create.callbacks.get_client_addresses.error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().views.orders.create.callbacks.get_client_addresses.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callback && typeof callback === 'function') callback();
        }
      );
    },
    getClientCart(user) {
      if (!user || !user.id) return;

      dispatch(getClientCart());

      const query = {
        filters: `iam_user_id|eq|${user.id}`
      };

      if (user.currency && user.currency.isocode) query.currency = user.currency.isocode;

      if (user.unit_system) query.unit_system = user.unit_system;

      fetchApi(
        'get',
        'carts',
        query,
        null,
        null,
        (data) => {
          dispatch(getClientCartSuccess(data));

          if (data[0] && data[0].property_object) dispatch(storeProperty(data[0].property_object));

          if (!data || !data[0]) {
            dispatch(createClientCart());

            fetchApi(
              'post',
              'carts',
              null,
              { iam_user_id: user.id },
              null,
              (data) => {
                dispatch(createClientCartSuccess([data]));

                if (data[0] && data[0].property_object)
                  dispatch(storeProperty(data[0].property_object));
              },
              (error) => {
                dispatch(createClientCartError(error));

                if (error.response) {
                  dispatch(
                    enqueueSnackbar({
                      message:
                        error.response.data && error.response.data.detail
                          ? error.response.data.detail
                          : translation().views.orders.create.callbacks.get_client_cart.error,
                      status: error.response.status || undefined,
                      options: {
                        variant: 'error'
                      }
                    })
                  );
                } else {
                  dispatch(
                    enqueueSnackbar({
                      message: translation().views.orders.create.callbacks.get_client_cart.error,
                      options: {
                        variant: 'error'
                      }
                    })
                  );
                }
              }
            );
          }
        },
        (error) => {
          dispatch(getClientCartError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().views.orders.create.callbacks.get_client_cart.error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().views.orders.create.callbacks.get_client_cart.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    },
    getProductGroups(propertyForm, user) {
      if (!propertyForm || !propertyForm.address || !propertyForm.size || !user) {
        return;
      }

      dispatch(getProductGroups());

      const query = {
        sort: 'position',
        property: {
          ...propertyForm
        }
      };

      if (
        propertyForm &&
        propertyForm.property_type &&
        propertyForm.property_type.toLowerCase() === 'other'
      ) {
        query.property.property_type = null;
      }

      if (user.currency && user.currency.isocode) {
        query.currency = user.currency.isocode;
      }

      if (user.unit_system) {
        query.unit_system = user.unit_system;
      }

      fetchApi(
        'get',
        'product-groups/orderable',
        query,
        null,
        null,
        (data) => {
          dispatch(getProductGroupsSuccess(data));
        },
        (error) => {
          dispatch(getProductGroupsError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().views.orders.create.callbacks.get_groups.error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().views.orders.create.callbacks.get_groups.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    },
    getProducts(propertyForm, user, groupId) {
      if (!propertyForm || !propertyForm.address || !propertyForm.size || !user) {
        return;
      }

      dispatch(getProducts());

      const query = {
        filters: `product_group_id|eq|${groupId}`,
        sort: 'position',
        property: {
          ...propertyForm
        }
      };

      if (
        propertyForm &&
        propertyForm.property_type &&
        propertyForm.property_type.toLowerCase() === 'other'
      ) {
        query.property.property_type = null;
      }

      if (user.currency && user.currency.isocode) {
        query.currency = user.currency.isocode;
      }

      if (user.unit_system) {
        query.unit_system = user.unit_system;
      }

      fetchApi(
        'get',
        'products/orderable',
        query,
        null,
        null,
        (data) => {
          dispatch(getProductsSuccess(data));
        },
        (error) => {
          if (error && error.response && error.response.data) {
            if (
              error.response.data.status_code &&
              error.response.data.status_code === 422 &&
              error.response.data.errors &&
              error.response.data.errors.length > 0
            ) {
              dispatch(setMissingFields(error.response.data.errors));
            } else {
              dispatch(getProductsError(error));
              dispatch(
                enqueueSnackbar({
                  message: error.response.data.detail
                    ? error.response.data.detail
                    : translation().views.orders.create.callbacks.get_products.error,
                  options: {
                    variant: 'error'
                  }
                })
              );
            }
          } else {
            dispatch(getProductsError(error));
            dispatch(
              enqueueSnackbar({
                message: translation().views.orders.create.callbacks.get_products.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    },
    getProductOptions(propertyForm, user, productId, onSuccess = null, onError = null) {
      if (!propertyForm || !propertyForm.address || !propertyForm.size || !user) {
        return;
      }

      dispatch(getProductOptions());

      const query = {
        property: {
          ...propertyForm
        }
      };

      if (
        propertyForm &&
        propertyForm.property_type &&
        propertyForm.property_type.toLowerCase() === 'other'
      ) {
        query.property.property_type = null;
      }

      if (user.currency && user.currency.isocode) {
        query.currency = user.currency.isocode;
      }

      if (user.unit_system) {
        query.unit_system = user.unit_system;
      }

      fetchApi(
        'get',
        `products/${productId}/options/orderable`,
        query,
        null,
        null,
        (data) => {
          dispatch(getProductOptionsSuccess(data));

          if (onSuccess && typeof onSuccess === 'function') {
            onSuccess(data);
          }
        },
        (error) => {
          dispatch(getProductOptionsError(error));

          if (onError && typeof onError === 'function') {
            onError(error);
          }

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().views.orders.create.callbacks.get_options.error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().views.orders.create.callbacks.get_options.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    },
    getProductSlots(
      propertyForm,
      user,
      productId,
      startDate,
      endDate,
      options,
      timezone = undefined
    ) {
      if (!productId || !propertyForm || !propertyForm.address || !propertyForm.size || !user) {
        return;
      }

      dispatch(getProductSlots());

      const query = {
        property: {
          ...propertyForm
        }
      };

      if (
        propertyForm &&
        propertyForm.property_type &&
        propertyForm.property_type.toLowerCase() === 'other'
      ) {
        query.property.property_type = null;
      }

      if (user.currency && user.currency.isocode) {
        query.currency = user.currency.isocode;
      }

      if (user.unit_system) {
        query.unit_system = user.unit_system;
      }

      query.start = startDate;
      query.end = endDate;

      if (options && options.length > 0) query.options = options;

      fetchApi(
        'get',
        `products/orderable/${productId}/slots`,
        query,
        null,
        null,
        (slots) => {
          dispatch(getProductSlotsSuccess(slots));

          const calendar = {};
          let nbTimesMax = 0;

          /**
           * Create calendar days
           */
          for (let i = 0; i < 7; i++) {
            let nowTz = dateInTz(startDate, null, timezone);
            let day = addToDate(nowTz, i, 'days', 'YYYY-MM-DD');
            calendar[day] = [];
          }

          /**
           * Create slots time and push them to their day
           */
          if (slots) {
            for (let j = 0; j < slots.length; j++) {
              let slotDate = dateInTz(slots[j], 'YYYY-MM-DD', timezone);
              let slotTime = dateInTz(slots[j], 'HH:mm', timezone);

              if (calendar[slotDate]) {
                calendar[slotDate].push(slotTime);
              }
            }
          }

          /**
           * Push new calendar to component state
           */
          dispatch(createCalendar(calendar));

          /**
           * Calculate the number max of slots in all days
           */
          for (let slotDate in calendar) {
            nbTimesMax = Math.max(5, nbTimesMax, calendar[slotDate].length);
            dispatch(setNbTimeMax(nbTimesMax));
          }
        },
        (error) => {
          dispatch(
            getProductSlotsError(
              error.response && error.response.statusText
                ? error.response.statusText
                : translation().views.orders.create.callbacks.get_slots.error
            )
          );

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().views.orders.create.callbacks.get_slots.error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().views.orders.create.callbacks.get_slots.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    },
    getNextProductSlots(
      propertyForm,
      user,
      productId,
      startDate,
      endDate,
      options,
      timezone = undefined
    ) {
      if (!productId || !propertyForm || !propertyForm.address || !propertyForm.size || !user) {
        return;
      }

      dispatch(getNextProductSlots());

      const query = {
        property: {
          ...propertyForm
        }
      };

      if (
        propertyForm &&
        propertyForm.property_type &&
        propertyForm.property_type.toLowerCase() === 'other'
      ) {
        query.property.property_type = null;
      }

      if (user.currency && user.currency.isocode) {
        query.currency = user.currency.isocode;
      }

      if (user.unit_system) {
        query.unit_system = user.unit_system;
      }

      if (options && options.length > 0) {
        query.options = options;
      }

      const start = addToDate(startDate, 7, 'days');
      const end = addToDate(endDate, 7, 'days');
      const startDateFormated = formatDate(start, 'YYYY-MM-DD');
      const endDateFormated = formatDate(end, 'YYYY-MM-DD');

      query.start = startDateFormated;
      query.end = endDateFormated;

      fetchApi(
        'get',
        `products/orderable/${productId}/slots`,
        query,
        null,
        null,
        (slots) => {
          dispatch(getNextProductSlotsSuccess(slots));

          const calendar = {};
          let nbTimesMax = 0;

          for (let i = 0; i < 7; i++) {
            let nowTz = dateInTz(start, null, timezone);
            let day = addToDate(nowTz, i, 'days', 'YYYY-MM-DD');
            calendar[day] = [];
          }

          if (slots) {
            for (let j = 0; j < slots.length; j++) {
              let slotDate = dateInTz(slots[j], 'YYYY-MM-DD', timezone);
              let slotTime = dateInTz(slots[j], 'HH:mm', timezone);

              if (calendar[slotDate]) {
                calendar[slotDate].push(slotTime);
              }
            }
          }

          dispatch(createCalendar(calendar, start, end));

          /**
           * Calculate the number max of slots in all days
           */
          for (let slotDate in calendar) {
            nbTimesMax = Math.max(5, nbTimesMax, calendar[slotDate].length);
            dispatch(setNbTimeMax(nbTimesMax));
          }
        },
        (error) => {
          dispatch(
            getNextProductSlotsError(
              error.response && error.response.statusText
                ? error.response.statusText
                : translation().views.orders.create.callbacks.get_slots.error
            )
          );

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().views.orders.create.callbacks.get_slots.error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().views.orders.create.callbacks.get_slots.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    },
    getPreviousProductSlots(
      propertyForm,
      user,
      productId,
      startDate,
      endDate,
      options,
      timezone = undefined
    ) {
      if (!productId || !propertyForm || !propertyForm.address || !propertyForm.size || !user) {
        return;
      }

      dispatch(getPreviousProductSlots());

      const query = {
        property: {
          ...propertyForm
        }
      };

      if (
        propertyForm &&
        propertyForm.property_type &&
        propertyForm.property_type.toLowerCase() === 'other'
      ) {
        query.property.property_type = null;
      }

      if (user.currency && user.currency.isocode) {
        query.currency = user.currency.isocode;
      }

      if (user.unit_system) {
        query.unit_system = user.unit_system;
      }

      if (options && options.length > 0) {
        query.options = options;
      }

      const start = addToDate(startDate, -7, 'days');
      const end = addToDate(endDate, -7, 'days');
      const startDateFormated = formatDate(start, 'YYYY-MM-DD');
      const endDateFormated = formatDate(end, 'YYYY-MM-DD');

      query.start = startDateFormated;
      query.end = endDateFormated;

      fetchApi(
        'get',
        `products/orderable/${productId}/slots`,
        query,
        null,
        null,
        (slots) => {
          dispatch(getPreviousProductSlotsSuccess(slots));

          const calendar = {};
          let nbTimesMax = 0;

          for (let i = 0; i < 7; i++) {
            let nowTz = dateInTz(start, null, timezone);
            let day = addToDate(nowTz, i, 'days', 'YYYY-MM-DD');
            calendar[day] = [];
          }

          if (slots) {
            for (let j = 0; j < slots.length; j++) {
              let slotDate = dateInTz(slots[j], 'YYYY-MM-DD', timezone);
              let slotTime = dateInTz(slots[j], 'HH:mm', timezone);

              if (calendar[slotDate]) {
                calendar[slotDate].push(slotTime);
              }
            }
          }

          dispatch(createCalendar(calendar, start, end));

          for (let slotDate in calendar) {
            nbTimesMax = Math.max(5, nbTimesMax, calendar[slotDate].length);
            dispatch(setNbTimeMax(nbTimesMax));
          }
        },
        (error) => {
          dispatch(
            getPreviousProductSlotsError(
              error.response && error.response.statusText
                ? error.response.statusText
                : translation().views.orders.create.callbacks.get_slots.error
            )
          );

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().views.orders.create.callbacks.get_slots.error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().views.orders.create.callbacks.get_slots.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    },
    addProductToCart(cartId, propertyForm, user, productId, slot, options, onSuccess) {
      if (
        !cartId ||
        !productId ||
        !propertyForm ||
        !propertyForm.address ||
        !propertyForm.size ||
        !user
      ) {
        return;
      }

      dispatch(addProductToCart());

      const query = {};
      const data = {
        property: {
          ...propertyForm
        }
      };

      if (
        propertyForm &&
        propertyForm.property_type &&
        propertyForm.property_type.toLowerCase() === 'other'
      ) {
        query.property.property_type = null;
      }

      if (user.currency && user.currency.isocode) {
        query.currency = user.currency.isocode;
      }

      if (user.unit_system) {
        query.unit_system = user.unit_system;
      }

      if (options && options.length > 0) {
        data.options = options;
      }

      if (slot) {
        data.slot = slot;
      }

      data.product_id = productId;

      fetchApi(
        'post',
        `carts/${cartId}/products`,
        query,
        data,
        null,
        () => {
          dispatch(addProductToCartSuccess());

          if (onSuccess && typeof onSuccess === 'function') onSuccess();
        },
        (error) => {
          if (error && error.response && error.response.data) {
            if (
              error.response.data.status_code &&
              error.response.data.status_code === 422 &&
              error.response.data.errors &&
              error.response.data.errors.length > 0
            ) {
              dispatch(setValidatingFields(error.response.data.errors));
            } else {
              dispatch(addProductToCartError(error));
              dispatch(
                enqueueSnackbar({
                  message: error.response.data.detail
                    ? error.response.data.detail
                    : translation().views.orders.create.callbacks.add_to_cart.error,
                  options: {
                    variant: 'error'
                  }
                })
              );
            }
          } else {
            dispatch(addProductToCartError(error));
            dispatch(
              enqueueSnackbar({
                message: translation().views.orders.create.callbacks.add_to_cart.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    },
    deleteClientCart(id, onSuccess) {
      if (!id) return;

      dispatch(deleteClientCart());

      fetchApi(
        'delete',
        `carts/${id}`,
        null,
        null,
        null,
        () => {
          dispatch(deleteClientCartSuccess());

          if (onSuccess && typeof onSuccess === 'function') onSuccess();
        },
        (error) => {
          dispatch(deleteClientCartError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().views.orders.create.callbacks.delete_cart.error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().views.orders.create.callbacks.delete_cart.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    },
    deleteClientCartItem(cartId, productId, onSuccess) {
      if (!cartId || !productId) return;

      dispatch(deleteClientCartItem());

      fetchApi(
        'delete',
        `carts/${cartId}/products/${productId}`,
        null,
        null,
        null,
        () => {
          dispatch(deleteClientCartItemSuccess());

          if (onSuccess && typeof onSuccess === 'function') onSuccess();
        },
        (error) => {
          dispatch(deleteClientCartItemError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().views.orders.create.callbacks.delete_cart_item.error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().views.orders.create.callbacks.delete_cart_item.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    },
    postMissingFields(propertyForm, user, groupId, values) {
      if (!propertyForm || !propertyForm.address || !propertyForm.size || !user) {
        return;
      }

      dispatch(postMissingFields());
      dispatch(getProducts());

      const query = {
        filters: `product_group_id|eq|${groupId}`,
        property: {
          ...propertyForm,
          ...values
        }
      };

      if (
        propertyForm &&
        propertyForm.property_type &&
        propertyForm.property_type.toLowerCase() === 'other'
      ) {
        query.property.property_type = null;
      }

      if (user.currency && user.currency.isocode) {
        query.currency = user.currency.isocode;
      }

      if (user.unit_system) {
        query.unit_system = user.unit_system;
      }

      dispatch(storeProperty(query.property));

      fetchApi(
        'get',
        'products/orderable',
        query,
        null,
        null,
        (data) => {
          dispatch(getProductsSuccess(data));
          dispatch(postMissingFieldsSuccess());
        },
        (error) => {
          dispatch(postMissingFieldsError(error));

          if (error && error.response && error.response.data) {
            if (
              error.response.data.status_code &&
              error.response.data.status_code === 422 &&
              error.response.data.errors &&
              error.response.data.errors.length > 0
            ) {
              dispatch(setMissingFields(error.response.data.errors));
            } else {
              dispatch(getProductsError(error));
              dispatch(
                enqueueSnackbar({
                  message: error.response.data.detail
                    ? error.response.data.detail
                    : translation().views.orders.create.callbacks.get_products.error,
                  options: {
                    variant: 'error'
                  }
                })
              );
            }
          } else {
            dispatch(getProductsError(error));
            dispatch(
              enqueueSnackbar({
                message: translation().views.orders.create.callbacks.get_products.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    },
    cancelValidatingFields() {
      dispatch(cancelValidatingFields());
    },
    postValidatingFields(cartId, propertyForm, values, user, productId, slot, options, onSuccess) {
      if (
        !cartId ||
        !productId ||
        !propertyForm ||
        !propertyForm.address ||
        !propertyForm.size ||
        !user
      ) {
        return;
      }

      dispatch(addProductToCart());
      dispatch(postValidatingFields());

      const query = {};
      const data = {
        property: {
          ...propertyForm,
          ...values
        }
      };

      if (propertyForm.property_type) data.property.type = propertyForm.property_type;

      if (user.currency && user.currency.isocode) query.currency = user.currency.isocode;

      if (user.unit_system) query.unit_system = user.unit_system;

      if (options && options.length > 0) data.options = options;

      if (slot) data.slot = slot;

      data.product_id = productId;

      fetchApi(
        'post',
        `carts/${cartId}/products`,
        query,
        data,
        null,
        () => {
          dispatch(addProductToCartSuccess());
          dispatch(postValidatingFieldsSuccess());
          dispatch(storeProperty(data.property));

          if (onSuccess && typeof onSuccess === 'function') onSuccess();
        },
        (error) => {
          dispatch(postValidatingFieldsError(error));

          if (error && error.response && error.response.data) {
            if (
              error.response.data.status_code &&
              error.response.data.status_code === 422 &&
              error.response.data.errors &&
              error.response.data.errors.length > 0
            ) {
              dispatch(setValidatingFields(error.response.data.errors));
            } else {
              dispatch(addProductToCartError(error));
              dispatch(
                enqueueSnackbar({
                  message: error.response.data.detail
                    ? error.response.data.detail
                    : translation().views.orders.create.callbacks.add_to_cart.error,
                  options: {
                    variant: 'error'
                  }
                })
              );
            }
          } else {
            dispatch(addProductToCartError(error));
            dispatch(
              enqueueSnackbar({
                message: translation().views.orders.create.callbacks.add_to_cart.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    },
    createClientAddress(user, { address, name }, isCompanyToggled, onSuccess, onError) {
      if (!address || !name || !user || !user.id) return;

      dispatch(postClientAddress());

      fetchApi(
        'get',
        'geocoder/geocode',
        { address },
        null,
        null,
        (geoData) => {
          const geoAddress = {
            name,
            latitude: geoData.latitude,
            longitude: geoData.longitude,
            street: geoData.street,
            street_number: geoData.street_number,
            zip_code: geoData.zip_code,
            city: geoData.city,
            country_isocode: geoData.country_isocode,
            state_isocode: geoData.state_isocode
          };

          if (isCompanyToggled && user.company && user.company.id && user.company_admin) {
            fetchApi(
              'post',
              `companies/${user.company.id}/addresses`,
              null,
              geoAddress,
              null,
              (success) => {
                dispatch(postClientAddressSuccess());

                if (onSuccess && typeof onSuccess === 'function') onSuccess(success);
              },
              (error) => {
                dispatch(postClientAddressError(error));

                if (onError && typeof onError === 'function') onError(error);
              }
            );
          } else {
            fetchApi(
              'post',
              `users/${user.id}/addresses`,
              null,
              geoAddress,
              null,
              (success) => {
                dispatch(postClientAddressSuccess());

                if (onSuccess && typeof onSuccess === 'function') onSuccess(success);
              },
              (error) => {
                dispatch(postClientAddressError(error));

                if (onError && typeof onError === 'function') onError(error);
              }
            );
          }
        },
        (geoError) => {
          dispatch(postClientAddressError(geoError));

          if (onError && typeof onError === 'function') onError(geoError);
        }
      );
    },
    addDiscountToCart(user, cartId, code, onSuccess, onError) {
      if (!user || !cartId || !code) return;

      const query = {};

      if (user.currency && user.currency.isocode) query.currency = user.currency.isocode;

      dispatch(addDiscountToCart());

      fetchApi(
        'post',
        `carts/${cartId}/discounts`,
        query,
        { code },
        null,
        (data) => {
          dispatch(addDiscountToCartSuccess());

          if (onSuccess && typeof onSuccess === 'function') onSuccess(data);
        },
        (error) => {
          dispatch(addDiscountToCartError(error));

          if (onError && typeof onError === 'function') onError();

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().views.orders.create.callbacks.add_discount_to_cart.error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().views.orders.create.callbacks.add_discount_to_cart.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    },
    removeDiscountToCart(cartId, discountId, onSuccess, onError) {
      if (!discountId || !cartId) return;

      dispatch(removeDiscountToCart());

      fetchApi(
        'delete',
        `carts/${cartId}/discounts/${discountId}`,
        null,
        null,
        null,
        (data) => {
          dispatch(removeDiscountToCartSuccess());

          if (onSuccess && typeof onSuccess === 'function') onSuccess(data);
        },
        (error) => {
          dispatch(removeDiscountToCartError(error));

          if (onError && typeof onError === 'function') onError();

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().views.orders.create.callbacks.remove_discount_to_cart.error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().views.orders.create.callbacks.remove_discount_to_cart.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    },
    createOrder(
      user,
      cartId,
      { invoiceAddressId, paymentMethodId },
      estateValues,
      orderReference,
      paymentDelegate,
      onSuccess
    ) {
      if (!user || !cartId || !invoiceAddressId || !paymentMethodId) return;

      dispatch(createOrder());

      const query = {};
      let property_access_details = null;
      const {
        floor,
        digital_code,
        intercom,
        property_contact_comment,
        property_contact_name,
        property_contact_phone
      } = estateValues;

      if (floor || digital_code || intercom) {
        property_access_details =
          (floor
            ? `\n${translation().views.orders.create.payment.estate.form.labels.level}: ${floor},`
            : '') +
          (digital_code
            ? `\n${
                translation().views.orders.create.payment.estate.form.labels.digital_code
              }: ${digital_code},`
            : '') +
          (intercom
            ? `\n${
                translation().views.orders.create.payment.estate.form.labels.intercom
              }: ${intercom}`
            : '');
      }

      const data = {
        invoice_address_id: invoiceAddressId,
        invoice_payment_method_id: paymentMethodId,
        property_contact_name: property_contact_name,
        property_contact_phone: property_contact_phone,
        property_contact_comment: property_contact_comment,
        property_access_details: property_access_details
      };

      if (orderReference) {
        data.reference = orderReference;
      }

      if (user.currency && user.currency.isocode) {
        query.currency = user.currency.isocode;
      }

      if (user.unit_system) {
        query.unit_system = user.unit_system;
      }

      if (paymentDelegate && paymentDelegate['delegate_email']) {
        data.delegate_email = paymentDelegate['delegate_email'];

        if (paymentDelegate['delegate_amount']) {
          data.delegate_amount = paymentDelegate['delegate_amount'];
        }
      }

      fetchApi(
        'post',
        `carts/${cartId}/order`,
        query,
        data,
        null,
        (order) => {
          dispatch(createOrderSuccess(order));

          if (onSuccess && typeof onSuccess === 'function') {
            onSuccess();
          }
        },
        (error) => {
          dispatch(createOrderError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().views.orders.create.callbacks.create_order.error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().views.orders.create.callbacks.create_order.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateOrderView));
