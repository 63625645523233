import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import {
  // Box,
  Button,
  ButtonBase,
  Card,
  // CardHeader,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  // Divider,
  Grid,
  TextField
} from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';

import Alert from '../../components/alert/Alert';
import { TaskViewCard, ViewBar } from './components';
import config from '../../config';
import translation from '../../translation/translation';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  addNewView: {
    minHeight: 200,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    cursor: 'pointer',
    backgroundColor: theme.palette.type === 'dark' ? '#292929' : grey[300],
    transition: '200ms',
    willChange: 'background-color',
    '&:hover': {
      backgroundColor: theme.palette.type === 'dark' ? grey[800] : grey[200]
    }
  },
  addNewIcon: {
    fontSize: '3rem',
    color: 'white'
  },
  cardAction: {
    marginLeft: 4
  },
  cardHeaderAction: {
    margin: 0
  },
  cardContent: {
    minHeight: 140,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  deleteButton: {
    marginLeft: 8,
    color: config.error_color || '#F44336',
    '&:hover': {
      backgroundColor: fade(config.error_color || '#F44336', 0.15)
    }
  },
  imgMedium: {
    width: 100
  },
  imgLarge: {
    width: 150
  }
}));

function TaskViews(props) {
  const classes = useStyles();
  const {
    clearTaskViews,
    createTaskView,
    deleteTaskView,
    getTaskViews,
    history,
    list: { data, paging, error, loading, create_view, delete_view, update_view },
    updateTaskView,
    user
  } = props;

  const [taskViewSelected, setTaskViewSelected] = useState(null);
  const [isDeleteOpen, setDeleteOpen] = useState(false);
  const [isFormOpen, setFormOpen] = useState(false);
  const [name, setName] = useState('');

  function loadTaskViews(next, isRefresh = false) {
    getTaskViews(next, isRefresh);
  }

  useEffect(() => {
    document.title = 'Actarus | Personnal task views';

    loadTaskViews();

    return () => {
      clearTaskViews();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleLoadNext() {
    if (!loading && paging && paging.next) {
      loadTaskViews(paging.next);
    }
  }

  function handleOpenCreateDialog() {
    setFormOpen(true);
  }

  function handleOpenUpdateDialog(taskViews) {
    setTaskViewSelected(taskViews);

    if (taskViews && taskViews.name) {
      setName(taskViews.name);
    }

    setFormOpen(true);
  }

  function handleRefresh() {
    loadTaskViews(undefined, true);
  }

  function handleCloseFormDialog() {
    setFormOpen(false);
    setTaskViewSelected(null);
    setName('');
  }

  function handleOpenDeleteDialog(taskViews) {
    setTaskViewSelected(taskViews);
    setDeleteOpen(true);
  }

  function handleCloseDeleteDialog() {
    setDeleteOpen(false);
    setTaskViewSelected(null);
  }

  function handleSubmit(event) {
    event.preventDefault();

    if (taskViewSelected && taskViewSelected.id) {
      updateTaskView(taskViewSelected.id, { name }, () => {
        handleCloseFormDialog();
        setTaskViewSelected(null);
        setName('');
      });
    } else {
      createTaskView({ name }, handleCloseFormDialog);
    }
  }

  function handleChangeName(event) {
    setName(event.target.value);
  }

  function handleNavigateToView(id) {
    history.push(`/personal-tasks/${id}`);
  }

  function handleDeleteTaskView() {
    if (taskViewSelected && taskViewSelected.id) {
      deleteTaskView(taskViewSelected.id, handleCloseDeleteDialog);
    }
  }

  function renderList(data) {
    const list = [];

    for (let i = 0; i < data.length; i++) {
      list.push(
        <TaskViewCard
          key={i}
          data={data[i]}
          user={user}
          classes={classes}
          onClick={handleNavigateToView}
          openDeleteDialog={handleOpenDeleteDialog}
          openUpdateDialog={handleOpenUpdateDialog}
        />
      );
    }

    return list;
  }

  return (
    <div className="TaskViews">
      <ViewBar
        loading={loading}
        handleRefresh={handleRefresh}
        handleCreate={handleOpenCreateDialog}
      />
      <div className={classes.root}>
        {!loading && error && (!data || data.length <= 0) ? (
          <div className="error-list">
            <Alert
              type="danger"
              style={{ marginTop: 10 }}
              status={translation().commons.alerts.error}
              text={translation().core.list.error_list}
            />
          </div>
        ) : (
          false
        )}
        {/* <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card className={classes.card}>
                <CardHeader
                  title="Mes tâches persos"
                  classes={{
                    action: classes.cardHeaderAction
                  }}
                />
                <ButtonBase component={Link} to="users/self/tasks" className={classes.cardContent}>
                  <img
                    src="/assets/images/tasks/tasks-illustration.svg"
                    alt="task view"
                    className={classes.imgMedium}
                  />
                </ButtonBase>
              </Card>
            </Grid>
          </Grid>
        </Container>
        <Box my={6}>
          <Divider />
        </Box> */}
        {!error && (
          <Container>
            <Grid container spacing={2}>
              {data && data.length > 0 ? renderList(data) : false}
              {!loading && (
                <Grid item xs={12} sm={6} md={4}>
                  <ButtonBase
                    component={Card}
                    onClick={handleOpenCreateDialog}
                    className={classes.addNewView}
                    elevation={0}>
                    <AddCircleIcon className={classes.addNewIcon} />
                  </ButtonBase>
                </Grid>
              )}
            </Grid>
          </Container>
        )}
        {loading && (
          <div className="loader-wpr">
            <CircularProgress color="primary" />
            <p>{translation().views.task_views.list.loading_text}</p>
          </div>
        )}
        {paging && paging.next && (
          <div className="loader-wpr">
            <Button color="primary" variant="contained" onClick={handleLoadNext} disabled={loading}>
              {translation().actions.load_more}
            </Button>
          </div>
        )}
      </div>
      <Dialog
        open={isDeleteOpen}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="delete-task-views-dialog"
        disableBackdropClick={delete_view.loading}
        fullWidth
        maxWidth="sm">
        <DialogTitle id="delete-resource-item">
          {translation().views.task_views.delete.dialog.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            {translation().views.task_views.delete.dialog.content}
          </DialogContentText>
          {delete_view.loading && (
            <>
              <div className="sm-space" />
              <div className="loader-wpr">
                <CircularProgress size={30} color="secondary" />
                <p>{translation().views.task_views.delete.dialog.load_delete}</p>
              </div>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} disabled={delete_view.loading} color="default">
            {translation().actions.close}
          </Button>
          <Button
            onClick={handleDeleteTaskView}
            className={classes.deleteButton}
            disabled={delete_view.loading || !taskViewSelected}>
            {translation().actions.delete}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isFormOpen}
        onClose={handleCloseFormDialog}
        aria-labelledby="create-new-task-views-dialog"
        disableBackdropClick={create_view.loading || update_view.loading}
        fullWidth
        maxWidth="sm">
        <DialogTitle id="create-new-task-views-dialog">
          {taskViewSelected
            ? translation().views.task_views.update.title
            : translation().views.task_views.create.title}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <TextField
              id="task-views-name"
              label={translation().views.task_views.create.form.labels.name}
              required
              value={name}
              onChange={handleChangeName}
              disabled={create_view.loading || update_view.loading}
              fullWidth
            />
          </form>
          {create_view.loading && (
            <>
              <div className="sm-space" />
              <div className="loader-wpr">
                <CircularProgress color="primary" />
                <p>{translation().views.task_views.create.loading_text}</p>
              </div>
            </>
          )}
          {update_view.loading && (
            <>
              <div className="sm-space" />
              <div className="loader-wpr">
                <CircularProgress color="primary" />
                <p>{translation().views.task_views.update.loading_text}</p>
              </div>
            </>
          )}
          <div className="sm-space" />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseFormDialog}
            color="default"
            disabled={create_view.loading || update_view.loading}>
            {translation().actions.close}
          </Button>
          <Button
            onClick={handleSubmit}
            style={{ marginLeft: 8 }}
            variant="contained"
            color="primary"
            disabled={!name || create_view.loading || update_view.loading}>
            {taskViewSelected ? translation().actions.save : translation().actions.create}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

TaskViews.propTypes = {
  clearTaskViews: PropTypes.func.isRequired,
  createTaskView: PropTypes.func.isRequired,
  deleteTaskView: PropTypes.func.isRequired,
  getTaskViews: PropTypes.func.isRequired,
  history: PropTypes.shape().isRequired,
  list: PropTypes.shape({
    error: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.shape()),
    paging: PropTypes.shape({
      next: PropTypes.string,
      prev: PropTypes.string
    }),
    loading: PropTypes.bool,
    create_view: PropTypes.shape({
      loading: PropTypes.bool
    }),
    delete_view: PropTypes.shape({
      loading: PropTypes.bool
    }),
    update_view: PropTypes.shape({
      loading: PropTypes.bool
    })
  }).isRequired,
  updateTaskView: PropTypes.func.isRequired,
  user: PropTypes.shape().isRequired
};

export default TaskViews;
