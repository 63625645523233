import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button, CircularProgress, Grid, Typography } from '@material-ui/core';

import translation from '../../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  button: {
    '&:first-of-type': {
      marginRight: theme.spacing(1)
    }
  },
  buttonWrapper: {
    display: 'flex'
  },
  circularProgress: {
    marginRight: 8
  },
  loader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      marginBottom: 12
    }
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  }
}));

function StepNavigation(props) {
  const { disabled, loading, loadingText, onNavigationBack, onNavigationNext, step, submit } =
    props;

  const classes = useStyles();

  function handleBack() {
    onNavigationBack();
  }

  function handleNext() {
    onNavigationNext();
  }

  function renderSubmitButton() {
    if (submit) {
      return (
        <Button
          variant="contained"
          color="primary"
          disabled={disabled}
          className={classes.button}
          type="submit">
          {translation().actions.next}
        </Button>
      );
    } else {
      return (
        <Button
          variant="contained"
          color="primary"
          disabled={disabled}
          className={classes.button}
          onClick={handleNext}>
          {translation().actions.next}
        </Button>
      );
    }
  }

  function renderButtons(step) {
    switch (step) {
      case 0:
        return renderSubmitButton();
      case 1:
        return renderSubmitButton();
      case 2:
        return (
          <Button
            variant="contained"
            color="primary"
            disabled={disabled}
            onClick={handleNext}
            className={classes.button}>
            {translation().actions.next}
          </Button>
        );
      default:
        return false;
    }
  }

  return (
    <Grid item xs={12}>
      <div className={classes.root}>
        <div className={classes.loader}>
          {loading && loadingText && (
            <>
              <CircularProgress size={30} className={classes.circularProgress} />
              <Typography variant="body2" color="textSecondary">
                {loadingText}
              </Typography>
            </>
          )}
        </div>
        <div className={classes.buttonWrapper}>
          <Button disabled={step === 0} onClick={handleBack} className={classes.button}>
            {translation().actions.back}
          </Button>
          {renderButtons(step)}
        </div>
      </div>
    </Grid>
  );
}

StepNavigation.defaultProps = {
  step: 0,
  submit: false
};

StepNavigation.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  loadingText: PropTypes.string,
  onNavigationBack: PropTypes.func.isRequired,
  onNavigationNext: PropTypes.func.isRequired,
  step: PropTypes.number,
  submit: PropTypes.bool
};

export { StepNavigation };
