import { clone } from 'lodash';
import { createDate, createDateUtc, formatDate, formatJSDate } from './dates';

/**
 * Format form values after sumbit for send to API
 */
export const formatValuesBeforePost = (metadata, values) => {
  if (!metadata || metadata.length <= 0) return;

  let valuesFormatted = clone(values);

  for (let i = 0; i < metadata.length; i++) {
    if (metadata[i].write || metadata[i].name === 'password') {
      if (values[metadata[i].name]) {
        if (metadata[i].name && metadata[i].type === 'date') {
          valuesFormatted[metadata[i].name] = formatDate(values[metadata[i].name], 'YYYY-MM-DD');
        } else if (metadata[i].name && metadata[i].type === 'time') {
          const day = formatJSDate(new Date());
          const start = createDate(day + 'T' + values[metadata[i].name]);
          const displayValue = createDate(start).toString();

          valuesFormatted[metadata[i].name] = formatDate(displayValue, 'HH:mm:00');
        } else if (metadata[i].name && metadata[i].type === 'timestamp') {
          const utcTimestamp = createDateUtc(values[metadata[i].name]);

          valuesFormatted[metadata[i].name] = formatDate(utcTimestamp, 'YYYY-MM-DDTHH:mm:ss');
        } else if (metadata[i].name && metadata[i].type === 'object') {
          valuesFormatted[metadata[i].name] = JSON.parse(values[metadata[i].name]);
        } else if (
          metadata[i].name &&
          metadata[i].type === 'array' &&
          values[metadata[i].name].length > 0
        ) {
          valuesFormatted[metadata[i].name] = values[metadata[i].name].split('\n');
        } else if (metadata[i].name && metadata[i].type === 'collection') {
          delete valuesFormatted[metadata[i].name];
        }
      } else {
        if (metadata[i].reference_key && metadata[i].type === 'entity') {
          if (values[metadata[i].reference_key]) {
            if (values[metadata[i].reference_key][metadata[i].target_key]) {
              valuesFormatted[metadata[i].reference_key] =
                values[metadata[i].reference_key][metadata[i].target_key];
            }
          }

          /**
            const keys = Object.keys(values);
            if (keys.length) {
              for (let x = 0; x < keys.length; x++) {
                if (metadata[i].reference_key === keys[x]) {
                  if (metadata[i].value) {
                    valuesFormatted[metadata[i].name] = metadata[i].value;
                  }
                }
              }
            }
          */
        }
      }
    } else if (!metadata[i].write) {
      delete valuesFormatted[metadata[i].name];
    }
  }

  return valuesFormatted;
};

export const formatErrors = (errors) => {
  const errorsFormatted = {};

  for (let i = 0; i < errors.length; i++) {
    if (errors[i].propertyPath) {
      errorsFormatted[errors[i].propertyPath] = errors[i].message;
    }
  }

  return errorsFormatted;
};
