import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ApartmentIcon from '@material-ui/icons/Apartment';
import { LinearProgress, Tab, Tabs } from '@material-ui/core';

import './CompanyView.scss';
import Alert from '../../../components/alert/Alert';
import CompanyInvoices from './components/invoices/CompanyInvoices';
import CompanyOrders from './components/orders/CompanyOrders';
import CompanyMetrics from './components/metrics/CompanyMetrics';
// import CompanyRatings from './components/ratings/CompanyRatings';
import CompanySummary from './components/summary/CompanySummary';
import CompanyViewBar from './components/CompanyViewBar';
import translation from '../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  loaderIcon: {
    fontSize: 56,
    display: 'block',
    margin: '15px auto'
  },
  linearProgress: {
    margin: 'auto',
    maxWidth: 250
  },
  progressWrapper: {
    padding: theme.spacing(3),
    textAlign: 'center',
    width: '100%',
    '& .loader-wpr': {
      margin: 0,
      paddingTop: 7,
      paddingBottom: 10
    }
  },
  tabsRoot: {
    ...theme.mixins.gutters()
  }
}));

function CompanyView(props) {
  const classes = useStyles();

  const tabs = [
    {
      label: translation().views.company_view.infos.label,
      hash: 'infos',
      value: 0
    },
    {
      label: translation().views.company_view.orders.label,
      hash: 'orders',
      value: 1
    },
    {
      label: translation().views.company_view.invoice_label,
      hash: 'invoices',
      value: 2
    },
    {
      label: translation().views.company_view.metrics.label,
      hash: 'metrics',
      value: 3
    }
    // {
    //   label: translation().views.company_view.ratings.label,
    //   hash: "ratings",
    //   value: 4
    // },
  ];

  const {
    cleanCompanyViewAddressesReducer,
    cleanCompanyViewInvoicesReducer,
    cleanCompanyViewInvoicesEmittedReducer,
    cleanCompanyViewOrdersReducer,
    cleanCompanyViewPaymentMethodReducer,
    cleanCompanyViewUsersReducer,
    // cleanCompanyViewRatingsReducer,
    getCompany,
    getCompanyViewAddresses,
    getCompanyViewInvoices,
    getCompanyViewInvoicesEmitted,
    getCompanyViewPaymentMethods,
    getCompanyViewOrders,
    getCompanyViewUsers,
    // getCompanyViewRatings,
    getOrdersMetadata,
    getInvoicerId,
    getPayerId,
    history,
    match,
    location: { pathname },
    company: {
      data,
      error,
      isLoading,
      addresses,
      invoices,
      invoices_emitted,
      payment_methods,
      orders,
      users
      // ratings
    },
    sub_routes
  } = props;

  const [tab, setTab] = useState(0);

  function loadView() {
    if (match && match.params && match.params.id) {
      getCompany(match && match.params && match.params.id ? match.params.id : null, () => {
        if (history && history.location && history.location.hash) {
          for (let i = 0; i < tabs.length; i++) {
            if (tabs[i].hash === history.location.hash.substring(1)) {
              setTab(i);
              break;
            }
          }
        }
      });
    }
  }

  useEffect(() => {
    if (match && match.params && match.params.id) {
      document.title = `Actarus | Company #${match.params.id}`;
    } else {
      document.title = 'Actarus | Company view';
    }

    loadView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleRefresh() {
    loadView();
  }

  function handleChangeTab(e, newTab) {
    setTab(newTab);

    for (let i = 0; i < tabs.length; i++) {
      if (tabs[i].value === newTab) {
        window.location.hash = tabs[i].hash;
        break;
      }
    }
  }

  return (
    <div className="CompanyView">
      <CompanyViewBar
        item={data ? data : null}
        history={history}
        parentUri="Iam"
        routeUri={pathname.substring(1)}
        subRoutes={sub_routes}
        disabled={false}
        // eslint-disable-next-line react/jsx-no-bind
        handleRefresh={handleRefresh}
      />
      <div className="CompanyView-content">
        {!isLoading && error && (!data || data.length <= 0) ? (
          <div className="error-list">
            <Alert
              type="danger"
              style={{ marginTop: 10 }}
              status={translation().commons.alerts.error}
              text={translation().core.list.error_list}
            />
          </div>
        ) : (
          false
        )}
        {isLoading && (
          <div className="loading-wrapper">
            <div className={classes.progressWrapper}>
              <ApartmentIcon fontSize="large" color="primary" className={classes.loaderIcon} />
              <div className={classes.linearProgress}>
                <LinearProgress color="secondary" variant="query" />
              </div>
              <p className="loader-wpr">{translation().views.company_view.loading_company}</p>
            </div>
          </div>
        )}
        {!isLoading && data && (
          <>
            <Tabs
              value={tab}
              className={classes.tabsRoot}
              // eslint-disable-next-line react/jsx-no-bind
              onChange={handleChangeTab}
              variant="scrollable"
              indicatorColor="secondary"
              textColor="secondary"
              aria-label="lab tabs">
              {tabs.map((tab) => (
                <Tab key={tab.value} label={tab.label} />
              ))}
            </Tabs>
            <div>
              {tab === 0 && (
                <CompanySummary
                  company={data}
                  cleanCompanyViewUsersReducer={cleanCompanyViewUsersReducer}
                  getCompanyViewUsers={getCompanyViewUsers}
                  users={users}
                />
              )}
              {tab === 1 && (
                <CompanyOrders
                  companyId={data.id}
                  getCompanyViewOrders={getCompanyViewOrders}
                  getOrdersMetadata={getOrdersMetadata}
                  cleanCompanyViewOrdersReducer={cleanCompanyViewOrdersReducer}
                  orders={orders}
                />
              )}
              {tab === 2 && (
                <CompanyInvoices
                  addresses={addresses}
                  cleanCompanyViewAddressesReducer={cleanCompanyViewAddressesReducer}
                  cleanCompanyViewInvoicesEmittedReducer={cleanCompanyViewInvoicesEmittedReducer}
                  cleanCompanyViewInvoicesReducer={cleanCompanyViewInvoicesReducer}
                  cleanCompanyViewPaymentMethodReducer={cleanCompanyViewPaymentMethodReducer}
                  getCompanyViewAddresses={getCompanyViewAddresses}
                  getCompanyViewInvoices={getCompanyViewInvoices}
                  getCompanyViewInvoicesEmitted={getCompanyViewInvoicesEmitted}
                  getCompanyViewPaymentMethods={getCompanyViewPaymentMethods}
                  getInvoicerId={getInvoicerId}
                  getPayerId={getPayerId}
                  invoices={invoices}
                  invoicesEmitted={invoices_emitted}
                  paymentMethods={payment_methods}
                  company={data}
                />
              )}
              {tab === 3 && <CompanyMetrics />}
              {/* { tab === 4  &&
                <CompanyRatings
                  cleanCompanyViewRatingsReducer={cleanCompanyViewRatingsReducer}
                  companyId={data.id}
                  getCompanyViewRatings={getCompanyViewRatings}
                  ratings={ratings}
                />
              } */}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

CompanyView.propTypes = {
  // cleanCompanyViewRatingsReducer: PropTypes.func.isRequired,
  // getCompanyViewRatings: PropTypes.func.isRequired,
  cleanCompanyViewAddressesReducer: PropTypes.func.isRequired,
  cleanCompanyViewInvoicesEmittedReducer: PropTypes.func.isRequired,
  cleanCompanyViewInvoicesReducer: PropTypes.func.isRequired,
  cleanCompanyViewOrdersReducer: PropTypes.func.isRequired,
  cleanCompanyViewPaymentMethodReducer: PropTypes.func.isRequired,
  cleanCompanyViewUsersReducer: PropTypes.func.isRequired,
  getCompany: PropTypes.func.isRequired,
  getCompanyViewAddresses: PropTypes.func.isRequired,
  getCompanyViewInvoices: PropTypes.func.isRequired,
  getCompanyViewInvoicesEmitted: PropTypes.func.isRequired,
  getCompanyViewOrders: PropTypes.func.isRequired,
  getCompanyViewPaymentMethods: PropTypes.func.isRequired,
  getCompanyViewUsers: PropTypes.func.isRequired,
  getInvoicerId: PropTypes.func.isRequired,
  getOrdersMetadata: PropTypes.func.isRequired,
  getPayerId: PropTypes.func.isRequired,
  history: PropTypes.shape().isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  }).isRequired,
  company: PropTypes.shape({
    addresses: PropTypes.shape(),
    data: PropTypes.shape(),
    error: PropTypes.string,
    invoices_emitted: PropTypes.shape(),
    invoices: PropTypes.shape(),
    isLoading: PropTypes.bool,
    orders: PropTypes.shape(),
    payment_methods: PropTypes.shape(),
    ratings: PropTypes.shape(),
    users: PropTypes.shape()
  }).isRequired,
  sub_routes: PropTypes.shape()
};

export default CompanyView;
