const initialState = {
  user: null,
  addresses: {
    selected: null,
    data: [],
    error: null,
    isLoading: false
  },
  payment_methods: {
    selected: null,
    data: [],
    error: null,
    isLoading: false
  },
  plans: {
    selected: null,
    data: [],
    error: null,
    isLoading: false
  },
  create: {
    isLoading: false,
    error: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_REDUCERS':
      return initialState;
    case 'CLEAR_ORDER_PRODUCT_HOSTING_EXTENSIONS':
      return initialState;
    case 'SELECT_USER_HOSTING_EXTENSIONS':
      return {
        ...state,
        user: action.user
      };
    case 'SELECT_PLAN_HOSTING_EXTENSIONS':
      return {
        ...state,
        plans: {
          ...state.plans,
          selected: action.plan
        }
      };
    case 'SELECT_PAYMENT_METHOD_HOSTING_EXTENSIONS':
      return {
        ...state,
        payment_methods: {
          ...state.payment_methods,
          selected: action.method
        }
      };
    case 'SELECT_ADDRESS_HOSTING_EXTENSIONS':
      return {
        ...state,
        addresses: {
          ...state.addresses,
          selected: action.address
        }
      };
    case 'GET_ORDER_PRODUCT_HOSTING_PLANS':
      return {
        ...state,
        plans: {
          ...state.plans,
          data: initialState.plans.data,
          isLoading: true,
          error: false
        }
      };
    case 'GET_ORDER_PRODUCT_HOSTING_PLANS_SUCCESS':
      return {
        ...state,
        plans: {
          ...state.plans,
          data: action.data,
          isLoading: false,
          error: false
        }
      };
    case 'GET_ORDER_PRODUCT_HOSTING_PLANS_ERROR':
      return {
        ...state,
        plans: {
          ...state.plans,
          isLoading: false,
          error: action.error
        }
      };
    case 'GET_HOSTING_EXTENSIONS_CLIENT_ADDRESSES':
      return {
        ...state,
        addresses: {
          ...state.addresses,
          data: initialState.addresses.data,
          isLoading: true,
          error: false
        }
      };
    case 'GET_HOSTING_EXTENSIONS_CLIENT_ADDRESSES_SUCCESS':
      return {
        ...state,
        addresses: {
          ...state.addresses,
          data: action.data,
          isLoading: false,
          error: false
        }
      };
    case 'GET_HOSTING_EXTENSIONS_CLIENT_ADDRESSES_ERROR':
      return {
        ...state,
        addresses: {
          ...state.addresses,
          isLoading: false,
          error: action.error
        }
      };
    case 'GET_HOSTING_EXTENSIONS_CLIENT_PAYMENT_METHODS':
      return {
        ...state,
        payment_methods: {
          ...state.payment_methods,
          data: initialState.payment_methods.data,
          isLoading: true,
          error: false
        }
      };
    case 'GET_HOSTING_EXTENSIONS_CLIENT_PAYMENT_METHODS_SUCCESS':
      return {
        ...state,
        payment_methods: {
          ...state.payment_methods,
          data: action.data,
          isLoading: false,
          error: false
        }
      };
    case 'GET_HOSTING_EXTENSIONS_CLIENT_PAYMENT_METHODS_ERROR':
      return {
        ...state,
        payment_methods: {
          ...state.payment_methods,
          isLoading: false,
          error: action.error
        }
      };
    case 'CREATE_HOSTING_EXTENSION':
      return {
        ...state,
        create: {
          isLoading: true,
          error: null
        }
      };
    case 'CREATE_HOSTING_EXTENSION_SUCCESS':
      return {
        ...state,
        create: {
          isLoading: false,
          error: null
        }
      };
    case 'CREATE_HOSTING_EXTENSION_ERROR':
      return {
        ...state,
        create: {
          isLoading: false,
          error: action.error
        }
      };
    default:
      return state;
  }
};
