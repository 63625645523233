import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

const SampleNextArrow = (props) => {
  const { style, onClick } = props;

  return (
    <IconButton aria-label="next" className="arrow-next" style={{ ...style }} onClick={onClick}>
      <ChevronRightIcon />
    </IconButton>
  );
};

SampleNextArrow.propTypes = {
  style: PropTypes.shape(),
  onClick: PropTypes.func
};

const SamplePrevArrow = (props) => {
  const { style, onClick } = props;

  return (
    <IconButton aria-label="previous" className="arrow-prev" style={{ ...style }} onClick={onClick}>
      <ChevronLeftIcon />
    </IconButton>
  );
};

SamplePrevArrow.propTypes = {
  style: PropTypes.shape(),
  onClick: PropTypes.func
};

function MediasCarousel(props) {
  const {
    accessiblity,
    children,
    dots,
    isCustomPaging,
    initialSlide,
    infinite,
    speed,
    slidesToShow,
    slidesToScroll
  } = props;

  function renderCustomPaging(i) {
    if (children[i].props.thumbnailurl) {
      return <img src={children[i].props.thumbnailurl} alt={children[i].props.name} />;
    } else {
      return (
        <div
          style={{
            backgroundImage: `url("/assets/images/medias/files/large/file-extension-${children[i].props.extension}.png")`
          }}
          alt={children[i].props.name}
          className="file-custom-dots"
        />
      );
    }
  }

  const settings = {
    accessiblity,
    initialSlide,
    customPaging: isCustomPaging ? renderCustomPaging : null,
    dots,
    infinite,
    speed,
    slidesToShow,
    slidesToScroll,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };

  return <Slider {...settings}>{children}</Slider>;
}

MediasCarousel.defaultProps = {
  accessiblity: true,
  isCustomPaging: false,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

MediasCarousel.propTypes = {
  accessiblity: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  dots: PropTypes.bool,
  isCustomPaging: PropTypes.bool,
  initialSlide: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  infinite: PropTypes.bool,
  speed: PropTypes.number,
  slidesToShow: PropTypes.number,
  slidesToScroll: PropTypes.number
};

export default MediasCarousel;
