import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  CircularProgress,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem
} from '@material-ui/core';
// import { green } from '@material-ui/core/colors';
import {
  AssignmentInd,
  // CheckCircle,
  Delete,
  History,
  MoreVert as MoreVertIcon
} from '@material-ui/icons';

import translation from '../../../../../../translation/translation';

const useStyles = makeStyles(() => ({
  listItemIcon: {
    minWidth: 28
  }
}));

function TaskActions(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);

  const {
    id,
    onAssignSelf,
    // onComplete
    onDelete,
    onOpenHistory,
    userLevel
  } = props;

  const menuOpen = Boolean(anchorEl);
  const menuId = menuOpen ? 'card-id-menu' : undefined;

  function handleOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleAutoAssign() {
    if (!id) return;

    setLoading(true);

    onAssignSelf(
      id,
      () => {
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  }

  // function handleComplete() {
  //   if (!id) return;

  //   setLoading(true);

  //   onComplete(
  //     id,
  //     () => {
  //       setLoading(false);
  //     },
  //     () => {
  //       setLoading(false);
  //     }
  //   );
  // }

  function handleDelete() {
    if (!id) return;

    setLoading(true);

    onDelete(
      id,
      () => {
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  }

  function handleOpenHistory() {
    if (!id) return;

    onOpenHistory(id);
    handleClose();
  }

  return (
    <>
      <IconButton aria-label="Task actions" aria-haspopup="true" onClick={handleOpen}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={menuId}
        open={menuOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        disableAutoFocusItem
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}>
        <MenuItem onClick={handleOpenHistory} disabled={loading}>
          <ListItemIcon className={classes.listItemIcon}>
            <History fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={translation().views.task_views.tasks.history.label} />
        </MenuItem>
        {userLevel && userLevel !== 'operator' && userLevel !== 'viewer' && (
          <MenuItem onClick={handleAutoAssign} disabled={loading}>
            <ListItemIcon className={classes.listItemIcon}>
              <AssignmentInd fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={translation().views.task_views.tasks.labels.auto_assign} />
          </MenuItem>
        )}
        {userLevel &&
          (userLevel === 'admin' || userLevel === 'manager') && [
            <Divider key="assign-divider" />,
            <MenuItem onClick={handleDelete} disabled={loading} key="delete-action">
              <ListItemIcon className={classes.listItemIcon}>
                <Delete fontSize="small" color="error" />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ color: 'error' }}
                primary={translation().actions.delete}
              />
            </MenuItem>
          ]}
        {/* <MenuItem onClick={handleComplete} disabled={loading}>
          <ListItemIcon className={classes.listItemIcon}>
            <CheckCircle fontSize="small" style={{ color: green[600] }} />
          </ListItemIcon>
          <ListItemText primary={translation().actions.done} />
        </MenuItem> */}

        {loading && (
          <MenuItem disabled={loading}>
            <CircularProgress size={18} />
          </MenuItem>
        )}
      </Menu>
    </>
  );
}

TaskActions.propTypes = {
  id: PropTypes.number.isRequired,
  onAssignSelf: PropTypes.func.isRequired,
  // onComplete: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onOpenHistory: PropTypes.func.isRequired,
  userLevel: PropTypes.oneOf(['admin', 'manager', 'editor', 'operator', 'viewer'])
};

export default TaskActions;
