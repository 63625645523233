/**
 * APP MAIN ACTIONS
 */

export const clearReducers = () => ({
  type: 'CLEAR_REDUCERS'
});

export const authentication = () => ({
  type: 'AUTHENTICATION'
});

export const authenticationSuccess = () => ({
  type: 'AUTHENTICATION_SUCCESS'
});

export const authenticationError = (error) => ({
  type: 'AUTHENTICATION_ERROR',
  error
});

export const enqueueSnackbar = (notification) => ({
  type: 'ENQUEUE_SNACKBAR',
  notification: {
    key: new Date().getTime() + Math.random(),
    ...notification
  }
});

export const removeSnackbar = (key) => ({
  type: 'REMOVE_SNACKBAR',
  key
});

export const setThemeNightMode = (isDarkMode) => ({
  type: 'SET_THEME_DARK_MODE',
  isDarkMode
});
