import { getLocale } from '../utils/functions/cookies';

const fr_FR = require('./languages/fr.json');
const de_DE = require('./languages/de.json');
const it_IT = require('./languages/it.json');
const en_GB = require('./languages/en.json');
const es_ES = require('./languages/es.json');

const translation = (locale) => {
  if (!locale) {
    locale = getLocale();
  }

  switch (locale) {
    case 'fr':
      return fr_FR;
    case 'de':
      return de_DE;
    case 'it':
      return it_IT;
    case 'en':
      return en_GB;
    case 'es':
      return es_ES;
    default:
      return fr_FR;
  }
};

export default translation;
