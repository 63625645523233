import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import green from '@material-ui/core/colors/green';
import blue from '@material-ui/core/colors/blue';
import amber from '@material-ui/core/colors/amber';

import translation from '../../translation/translation';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const styles = (theme) => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: blue[500]
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: 5
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  },
  messageWrapper: {
    width: 'calc(100% - 52px)',
    maxWidth: 780
  }
});

function CustomSnackbarContent(props) {
  const { classes, className, message, onClose, variant, statusCode, ...other } = props;

  let messageOverridden = null;
  let variantOveridden = null;

  if (statusCode && parseInt(statusCode, 10) === 403) {
    /**
     * Check if status code is 403 (user have no right for the action) then override notification messsage.
     */
    messageOverridden = translation().commons.status_denied;
    variantOveridden = 'error';
  }

  const Icon = variantIcon[variantOveridden || variant];

  return (
    <SnackbarContent
      classes={{
        root: classNames(classes[variantOveridden || variant], className),
        message: classes.messageWrapper
      }}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {messageOverridden && messageOverridden.length > 0 ? (
            <div>
              {messageOverridden.split('\n').map((item, key) => {
                return (
                  <span key={key}>
                    {item}
                    <br />
                  </span>
                );
              }) || messageOverridden}
            </div>
          ) : (
            <div>
              {message.split('\n').map((item, key) => {
                return (
                  <span key={key}>
                    {item}
                    <br />
                  </span>
                );
              }) || message}
            </div>
          )}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  );
}

CustomSnackbarContent.propTypes = {
  classes: PropTypes.shape(),
  className: PropTypes.func,
  message: PropTypes.any,
  statusCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClose: PropTypes.func.isRequired,
  variant: PropTypes.string
};

const SnackbarContentWrapper = withStyles(styles)(CustomSnackbarContent);

class Notifier extends Component {
  render() {
    const { notifications, removeSnackbar } = this.props;

    return (
      <div>
        <Snackbar
          key={notifications.key}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          open={notifications.message ? true : false}
          autoHideDuration={5000}
          disableWindowBlurListener={true}
          ClickAwayListenerProps={{
            onClickAway: () => {
              return false;
            }
          }}
          onClose={removeSnackbar}>
          <SnackbarContentWrapper
            onClose={removeSnackbar}
            variant={
              notifications && notifications.options && notifications.options.variant
                ? notifications.options.variant
                : 'info'
            }
            message={notifications.message ? notifications.message : ''}
            statusCode={notifications.status ? notifications.status : null}
          />
        </Snackbar>
      </div>
    );
  }
}

Notifier.propTypes = {
  removeSnackbar: PropTypes.func.isRequired,
  notifications: PropTypes.shape({
    key: PropTypes.any,
    status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    options: PropTypes.shape(),
    message: PropTypes.string
  })
};

export default Notifier;
