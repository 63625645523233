import './production.scss';

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import ProductionViewBar from './components/ProductionViewBar';
import translation from '../../translation/translation';

const useStyles = makeStyles((theme) => ({
  img: {
    height: 240,
    marginBottom: 24,
    [theme.breakpoints.down('md')]: {
      height: 150
    }
  },
  buttonBase: {
    width: '100%'
  },
  title: {
    fontWeight: 400
  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      overflow: 'auto',
      display: 'block'
    }
  },
  paper: {
    padding: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    overflow: 'hidden'
  },
  productionItem: {
    margin: 16,
    minWidth: 250,
    maxWidth: 450,
    flex: 1,
    [theme.breakpoints.down('md')]: {
      display: 'inline-block'
    }
  }
}));

function Production(props) {
  useEffect(() => {
    document.title = 'Actarus | Production';
  }, []);

  const classes = useStyles();
  const { history } = props;

  const handleNavigate = (url) => () => {
    history.push(url);
  };

  return (
    <div className="Production">
      <ProductionViewBar history={history} />
      <div className={classes.root}>
        <div className={classes.productionItem}>
          <ButtonBase
            className={classes.buttonBase}
            onClick={handleNavigate('/production/moderation')}>
            <Paper className={classes.paper}>
              <img
                alt={translation().views.production.moderation.title}
                className={classes.img}
                src="/assets/images/production/moderation.svg"
              />
              <Typography variant="h6" className={classes.title}>
                {translation().views.production.moderation.title}
              </Typography>
            </Paper>
          </ButtonBase>
        </div>
        <div className={classes.productionItem}>
          <ButtonBase
            className={classes.buttonBase}
            onClick={handleNavigate('/production/previous-day')}>
            <Paper className={classes.paper}>
              <img
                alt={translation().views.production.previous_day.title}
                className={classes.img}
                src="/assets/images/production/previous-day.svg"
              />
              <Typography variant="h6" className={classes.title}>
                {translation().views.production.previous_day.title}
              </Typography>
            </Paper>
          </ButtonBase>
        </div>
        <div className={classes.productionItem}>
          <ButtonBase
            className={classes.buttonBase}
            onClick={handleNavigate('/production/order-products-overdue')}>
            <Paper className={classes.paper}>
              <img
                alt={translation().views.production.order_products_overdue.title}
                className={classes.img}
                src="/assets/images/production/order-products-overdue.svg"
              />
              <Typography variant="h6" className={classes.title}>
                {translation().views.production.order_products_overdue.title}
              </Typography>
            </Paper>
          </ButtonBase>
        </div>
      </div>
    </div>
  );
}

Production.propTypes = {
  history: PropTypes.shape().isRequired
};

export default Production;
