/**
 * USERS ACTIONS
 */

export const storeUser = (data) => ({
  type: 'STORE_USER',
  data
});

export const getUser = () => ({
  type: 'GET_USER'
});

export const getUserSuccess = (data) => ({
  type: 'GET_USER_SUCCESS',
  data
});

export const getUserError = (error) => ({
  type: 'GET_USER_ERROR',
  error
});

export const removeUser = () => ({
  type: 'REMOVE_USER'
});

export const updateUserSelf = () => ({
  type: 'UPDATE_USER_SELF'
});

export const updateUserSelfSuccess = () => ({
  type: 'UPDATE_USER_SELF_SUCCESS'
});

export const updateUserSelfError = (error) => ({
  type: 'UPDATE_USER_SELF_ERROR',
  error
});

export const updateUserSelfErrors = (errors) => ({
  type: 'UPDATE_USER_SELF_ERRORS',
  errors
});
