import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';

import './useChromeBrowser.scss';
import translation from '../../../translation/translation';

const GoogleButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText('#1AA260'),
    backgroundColor: '#1AA260',
    '&:hover': {
      backgroundColor: '#2fb976'
    }
  }
}))(Button);

function UseChromeBrowser() {
  const [isOpen, setOpen] = useState(true);
  const body = document.body;

  useEffect(() => {
    if (body) {
      body.style.overflow = 'hidden';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleClose() {
    setOpen(false);

    if (body) {
      body.style.overflow = 'auto';
    }
  }

  return (
    <div className={classNames('UseChromeBrowser', { closed: !isOpen })}>
      <div className="disclamer-wrapper">
        <img
          src="/assets/images/chrome/chrome-logo.svg"
          alt="Chrome logo"
          className="chrome-logo"
        />
        <Typography variant="h2" gutterBottom>
          {translation().views.browser.useChrome.title}
        </Typography>
        <Typography variant="subtitle1">
          {translation().views.browser.useChrome.baseline}
        </Typography>
        <div className="buttons-wrapper">
          <a href="https://www.google.fr/chrome/" target="_blank" rel="noreferrer noopener">
            <GoogleButton
              variant="contained"
              color="primary"
              style={{ marginRight: 8, marginBottom: 8 }}>
              {translation().views.browser.useChrome.install}
            </GoogleButton>
          </a>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClose}
            style={{ marginBottom: 8 }}>
            {translation().actions.close}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default UseChromeBrowser;
