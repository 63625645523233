import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchApi } from '../../utils/functions/api';
import { enqueueSnackbar } from '../../redux/actions/appActions';
import {
  clearOrderProduct,
  getOrderProductData,
  getOrderProductDataSuccess,
  getOrderProductDataError,
  deliverProductToModerate,
  deliverProductToModerateSuccess,
  deliverProductToModerateError
} from '../../redux/actions/moderation/moderationActions';
import OrderProduct from '../../views/moderation/orderProduct/OrderProduct';
import translation from '../../translation/translation';

const mapStateToProps = (store) => ({
  orderProduct: store.production.moderation.item
});

const mapDispatchToProps = (dispatch) => {
  return {
    clearOrderProduct() {
      dispatch(clearOrderProduct());
    },
    async getOrderProductData(orderProductId, callbackSuccess = null) {
      if (!orderProductId) {
        return;
      }

      dispatch(getOrderProductData());

      const query = {
        filters: `id|eq|${orderProductId}`
      };

      await fetchApi(
        'get',
        'orders/all/products/received',
        query,
        null,
        null,
        (data) => {
          dispatch(getOrderProductDataSuccess(data));

          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess();
          }
        },
        (error) => {
          dispatch(getOrderProductDataError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.error_list,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().core.list.error_list,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    },
    deliverOrderProduct: (order_id, product_id, callbackSuccess) => {
      if (!order_id || !product_id) return;

      dispatch(deliverProductToModerate());

      /**
       * {delivery_status_id: 6} === "delivered" status
       */
      fetchApi(
        'put',
        `orders/${order_id}/products/${product_id}/delivery-status`,
        null,
        { delivery_status_id: 6 },
        null,
        (data) => {
          dispatch(deliverProductToModerateSuccess(data));

          dispatch(
            enqueueSnackbar({
              message: translation().views.production.moderation.item.delivery.callbacks.success,
              options: {
                variant: 'success'
              }
            })
          );

          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess();
          }
        },
        (error) => {
          dispatch(deliverProductToModerateError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().views.production.moderation.item.delivery.callbacks.error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().views.production.moderation.item.delivery.callbacks.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderProduct));
