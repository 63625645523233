import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import DepartureBoardIcon from '@material-ui/icons/DepartureBoard';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';

import EditAvailabilityForm from '../forms/availabilities/edit/EditAvailabilityForm';
import EditUnavailabilityForm from '../forms/unavailabilities/edit/EditUnavailabilityForm';
import { convertMinsToHrsMins, formatDate } from '../../../../../../utils/functions/dates';
import { pad } from '../../../../../../utils/functions/utils';
import { getLocale, getTimezone } from '../../../../../../utils/functions/cookies';
import translation from '../../../../../../translation/translation';

const styles = (theme) => ({
  backgroundModal: {
    backgroundColor: theme.palette.background.paper
  }
});

class ScheduleEventPopup extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isEditAvailabilityOpen: false,
      isEditUnavailabilityOpen: false,
      isProductTripDistanceLoading: false,
      isProductTripDistanceOpen: false,
      productTripDistance: null
    };

    this.handleToggleEditAvailability = this.handleToggleEditAvailability.bind(this);
    this.handleToggleEditUnavailability = this.handleToggleEditUnavailability.bind(this);
    this.handleToggleProductTripDistance = this.handleToggleProductTripDistance.bind(this);

    this.handleDeleteAvailability = this.handleDeleteAvailability.bind(this);
    this.handleEditAvailability = this.handleEditAvailability.bind(this);

    this.handleDeleteUnavailability = this.handleDeleteUnavailability.bind(this);
    this.handleEditUnavailability = this.handleEditUnavailability.bind(this);

    this.renderAvailability = this.renderAvailability.bind(this);
    this.renderUnavailability = this.renderUnavailability.bind(this);
    this.renderOrderProduct = this.renderOrderProduct.bind(this);
  }

  handleToggleEditAvailability() {
    this.setState((state) => ({
      isEditAvailabilityOpen: !state.isEditAvailabilityOpen
    }));
  }

  handleToggleEditUnavailability() {
    this.setState((state) => ({
      isEditUnavailabilityOpen: !state.isEditUnavailabilityOpen
    }));
  }

  handleToggleProductTripDistance() {
    const { isProductTripDistanceOpen } = this.state;
    const {
      event: { id, order_id },
      getOrderProductTripDistance
    } = this.props;

    if (isProductTripDistanceOpen) {
      this.setState({ isProductTripDistanceOpen: false });
    } else {
      this.setState({ isProductTripDistanceLoading: true });

      getOrderProductTripDistance(
        order_id,
        id,
        (success) => {
          this.setState({
            isProductTripDistanceOpen: true,
            isProductTripDistanceLoading: false,
            productTripDistance: success
          });
        },
        () => {
          this.setState({ isProductTripDistanceLoading: false });
        }
      );
    }
  }

  handleEditAvailability(values) {
    if (!values) return;

    const {
      editAvailability,
      event: { id, start }
    } = this.props;

    if (id && start) {
      editAvailability(id, values, start);
    }
  }

  handleDeleteAvailability() {
    const {
      removeAvailability,
      event: { id }
    } = this.props;

    if (id) {
      removeAvailability(id);
    }
  }

  handleEditUnavailability(values) {
    const { editUnavailability, event } = this.props;

    if (!values || !event) return;

    editUnavailability(event, values);
  }

  handleDeleteUnavailability() {
    const {
      removeUnavailability,
      event: { id }
    } = this.props;

    if (id) {
      removeUnavailability(id);
    }
  }

  renderOrderProduct(event) {
    const { isProductTripDistanceOpen, isProductTripDistanceLoading, productTripDistance } =
      this.state;

    const { classes } = this.props;

    const startHour = pad(event.start.getHours().toString());
    const startMinutes = pad(event.start.getMinutes().toString());
    const endHour = pad(event.end.getHours().toString());
    const endMinutes = pad(event.end.getMinutes().toString());

    return (
      <div className={event.className}>
        <div className="event-pp-header">
          <div className="event-actions">
            <IconButton
              aria-label="Close"
              className="event-action"
              onClick={this.props.onClose}
              size="small">
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>
          <div className="event-title">
            {event.product && event.product.icon_url_light && (
              <img className="product-img" src={event.product.icon_url_light} alt="icon status" />
            )}
            <Typography>{event.title}</Typography>
          </div>
        </div>
        <div className={classNames('event-pp-content', classes.backgroundModal)}>
          <Typography variant="subtitle2" className="detail-item">
            {translation().views.provider_schedule.orders.infos}
          </Typography>
          {event.start && event.end && (
            <span className="detail">
              <span className="detail-icon icon-horaire detail-date" />
              <div className="detail-date">
                <Typography variant="subtitle2">
                  {event.start.toLocaleDateString(getLocale(), {
                    weekday: 'long',
                    month: 'long',
                    day: 'numeric'
                  })}
                </Typography>
                <Typography variant="body2">
                  {startHour}:{startMinutes} - {endHour}:{endMinutes}
                </Typography>
              </div>
            </span>
          )}
          {event.timezone_code &&
            getTimezone() !== event.timezone_code &&
            event.local_start &&
            event.local_end && (
              <span className="detail timezone-warning">
                <span className="detail-icon icon-infos detail-date" />
                <div className="detail-date">
                  {translation().commons.hour_on_place}:{' '}
                  {formatDate(event.local_start, 'localized-datetime')} <br />
                  <span>({event.timezone_code})</span>
                </div>
              </span>
            )}
          {event.product &&
            event.product &&
            event.product.property &&
            event.product.property.address && (
              <span className="detail">
                <span className="detail-icon icon-comment01" />
                <Typography variant="body2">{event.product.property.address}</Typography>
              </span>
            )}
          <div>
            {event.product && event.product && event.product.property_size_formatted ? (
              <span className="detail">
                <span className="detail-icon icon-surface" />
                <Typography variant="body2">{event.product.property_size_formatted}</Typography>
              </span>
            ) : (
              ''
            )}
            {event.product && event.product.duration ? (
              <span className="detail">
                <span className="detail-icon icon-duree" />
                <Typography variant="body2">
                  {event.product &&
                    event.product.duration &&
                    convertMinsToHrsMins(event.product.duration, 'h')}
                </Typography>
              </span>
            ) : (
              ''
            )}
          </div>
          {event.order_id && (
            <span className="detail">
              <span className="detail-icon icon-panier" />
              <Typography variant="body2">
                {translation().views.provider_schedule.orders.order} n° {event.order_id}
                {event.id &&
                  ' | ' + translation().views.provider_schedule.orders.product + ' n° ' + event.id}
              </Typography>
            </span>
          )}
          <div>
            {event.product && event.product.property_contact_name ? (
              <span className="detail">
                <span className="detail-icon icon-connecte" />
                <Typography variant="body2">
                  {translation().views.provider_schedule.orders.on_place}:{' '}
                  {event.product.property_contact_name}{' '}
                  {event.product.property_contact_phone
                    ? '(' + event.product.property_contact_phone + ')'
                    : ''}
                </Typography>
              </span>
            ) : (
              ''
            )}
          </div>
          <div>
            {event.product && event.product.property_access_details ? (
              <span className="detail no-flex" style={{ marginTop: 15 }}>
                <Typography variant="subtitle2">
                  {translation().views.provider_schedule.orders.details}:
                </Typography>
                <Typography variant="body2">{event.product.property_access_details}</Typography>
              </span>
            ) : (
              ''
            )}
          </div>
          <div>
            {event.product && event.product.property_contact_comment ? (
              <span className="detail no-flex" style={{ marginTop: 15 }}>
                <Typography variant="subtitle2">
                  {translation().views.provider_schedule.orders.comment}:
                </Typography>
                <Typography variant="body2">{event.product.property_contact_comment}</Typography>
              </span>
            ) : (
              ''
            )}
          </div>
          {event.product && event.product.options && event.product.options.length ? (
            <div className="options">
              <Typography variant="subtitle2" className="detail-item">
                {translation().views.provider_schedule.orders.options}:
              </Typography>
              <div style={{ maxHeight: 92, overflow: 'auto' }}>
                {event.product.options.map((option, index) => {
                  return <Chip key={index} label={option} className="option" />;
                })}
              </div>
            </div>
          ) : (
            ''
          )}
          {event.product && event.product.status && event.product.status.icon_url_dark && (
            <div className="detail status no-flex">
              <Typography variant="subtitle2" className="detail-item">
                {translation().views.provider_schedule.orders.status}:
              </Typography>
              <img
                src={event.product.status.icon_url_dark}
                alt={event.product.status.name ? event.product.status.name : ''}
                className="status-img"
              />
              <span
                style={{
                  fontWeight: 500,
                  color: event.product.status.color ? event.product.status.color : '#000000'
                }}>
                {event.product.status.name && event.product.status.name}
              </span>
            </div>
          )}
          <div className="product-calc-trip">
            <div className="detail">
              <DepartureBoardIcon className="detail-icon" />
              <Typography variant="body2" onClick={this.handleToggleProductTripDistance}>
                {translation().views.provider_schedule.orders.trip_distance.label}
              </Typography>
            </div>
            {isProductTripDistanceLoading && <CircularProgress size={20} />}
            {isProductTripDistanceOpen && (
              <code className="calc-trip-details">
                {productTripDistance && productTripDistance.length > 0
                  ? productTripDistance.map((line, i) => <div key={i}>{line}</div>)
                  : false}
              </code>
            )}
          </div>
        </div>
      </div>
    );
  }

  renderAvailability(event) {
    const { isEditAvailabilityOpen } = this.state;

    const {
      classes,
      maxHour,
      minHour,
      onClose,
      deleteAvailabilityLoading,
      editAvailabilityLoading
    } = this.props;

    const startHour = pad(event.start.getHours().toString());
    const startMinutes = pad(event.start.getMinutes().toString());
    const endHour = pad(event.end.getHours().toString());
    const endMinutes = pad(event.end.getMinutes().toString());

    return (
      <div className={event.className}>
        <div className="event-pp-header">
          <div className="event-actions">
            <IconButton
              aria-label="Delete availability"
              className="event-action"
              disabled={deleteAvailabilityLoading}
              onClick={this.handleDeleteAvailability}
              size="small">
              <DeleteIcon fontSize="small" />
            </IconButton>
            <IconButton
              aria-label="Close availability"
              className="event-action"
              disabled={deleteAvailabilityLoading}
              onClick={onClose}
              size="small">
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>
          <div className="event-title">
            <Typography>{event.title}</Typography>
          </div>
          <div className="edit-event" onClick={this.handleToggleEditAvailability}>
            <span className="icon-editer" />
          </div>
        </div>
        <div className={classNames('event-pp-content', classes.backgroundModal)}>
          {event.start && event.end && (
            <div>
              {isEditAvailabilityOpen ? (
                <div className="edit-availability">
                  <span className="detail">
                    <span className="icon-horaire date-icon" />
                    <div className="detail-date">
                      <Typography variant="subtitle2">
                        {event.start.toLocaleDateString(getLocale(), {
                          weekday: 'long',
                          month: 'long',
                          day: 'numeric'
                        })}
                      </Typography>
                    </div>
                  </span>
                  <EditAvailabilityForm
                    onSubmit={this.handleEditAvailability}
                    maxHour={maxHour}
                    minHour={minHour}
                    initialValues={{
                      hour_start: startHour,
                      min_start: startMinutes,
                      hour_end: endHour,
                      min_end: endMinutes
                    }}
                    editAction={{
                      loading: editAvailabilityLoading
                    }}
                  />
                </div>
              ) : (
                <span className="detail">
                  <span className="detail-icon icon-horaire detail-date" />
                  <div className="detail-date">
                    <Typography variant="subtitle2">
                      {event.start.toLocaleDateString(getLocale(), {
                        weekday: 'long',
                        month: 'long',
                        day: 'numeric'
                      })}
                    </Typography>
                    <Typography variant="body2">
                      {startHour}:{startMinutes} - {endHour}:{endMinutes}
                    </Typography>
                  </div>
                </span>
              )}
            </div>
          )}
          {deleteAvailabilityLoading && (
            <div className="loader-wpr">
              <CircularProgress size={25} className="event-circular-progress" />
              <p>{translation().views.provider_schedule.availability.delete.load_delete}</p>
            </div>
          )}
        </div>
      </div>
    );
  }

  renderUnavailability(event) {
    const { isEditUnavailabilityOpen } = this.state;

    const {
      classes,
      maxHour,
      minHour,
      onClose,
      deleteUnavailabilityLoading,
      editUnavailabilityLoading
    } = this.props;

    const startHour = pad(event.start.getHours().toString());
    const startMinutes = pad(event.start.getMinutes().toString());
    const endHour = pad(event.end.getHours().toString());
    const endMinutes = pad(event.end.getMinutes().toString());

    return (
      <div className={event.className}>
        <div className="event-pp-header">
          <div className="event-actions">
            <IconButton
              aria-label="Delete unavailability"
              className="event-action"
              disabled={deleteUnavailabilityLoading}
              onClick={this.handleDeleteUnavailability}
              size="small">
              <DeleteIcon fontSize="small" />
            </IconButton>
            <IconButton
              aria-label="Close unavailability"
              className="event-action"
              disabled={deleteUnavailabilityLoading}
              onClick={onClose}
              size="small">
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>
          <div className="event-title">
            <Typography>
              {event.title || translation().views.provider_schedule.unavailability.unavailability}
            </Typography>
          </div>
          <div className="edit-event" onClick={this.handleToggleEditUnavailability}>
            <span className="icon-editer" />
          </div>
        </div>
        <div
          className={classNames('event-pp-content', classes.backgroundModal)}
          style={isEditUnavailabilityOpen ? { paddingTop: 20 } : {}}>
          {event.start && event.end && (
            <div>
              {isEditUnavailabilityOpen ? (
                <div className="new-unavailability">
                  <EditUnavailabilityForm
                    onSubmit={this.handleEditUnavailability}
                    editAction={{
                      loading: editUnavailabilityLoading
                    }}
                    initialValues={{
                      location:
                        event.location && event.location.address ? event.location.address : '',
                      longitude:
                        event.location && event.location.longitude ? event.location.longitude : '',
                      latitude:
                        event.location && event.location.latitude ? event.location.latitude : '',
                      hour_start: startHour,
                      min_start: startMinutes,
                      hour_end: endHour,
                      min_end: endMinutes
                    }}
                    maxHour={maxHour}
                    minHour={minHour}
                  />
                </div>
              ) : (
                <div>
                  <span className="detail">
                    <span className="detail-icon icon-horaire detail-date" />
                    <div className="detail-date">
                      <Typography variant="subtitle2">
                        {event.start.toLocaleDateString(getLocale(), {
                          weekday: 'long',
                          month: 'long',
                          day: 'numeric'
                        })}
                      </Typography>
                      <Typography variant="body2">
                        {startHour}:{startMinutes} - {endHour}:{endMinutes}
                      </Typography>
                    </div>
                  </span>
                  {event.location && (
                    <span className="detail">
                      <span className="detail-icon detail-date icon-comment01" />
                      <div className="detail-date">
                        <Typography variant="subtitle2">{event.location.address}</Typography>
                        {event.location.latitude && event.location.longitude && (
                          <Typography variant="body2">
                            {event.location.latitude} / {event.location.longitude}
                          </Typography>
                        )}
                      </div>
                    </span>
                  )}
                </div>
              )}
            </div>
          )}
          {deleteUnavailabilityLoading && (
            <div className="loader-wpr">
              <CircularProgress size={25} className="event-circular-progress" />
              <p>{translation().views.provider_schedule.availability.delete.load_delete}</p>
            </div>
          )}
        </div>
      </div>
    );
  }

  render() {
    const { event } = this.props;

    return (
      <div className="event-popup">
        {event.type && event.type === 'availability' ? this.renderAvailability(event) : ''}
        {event.type && event.type === 'unavailability' ? this.renderUnavailability(event) : ''}
        {event.type && event.type === 'orderProduct' ? this.renderOrderProduct(event) : ''}
      </div>
    );
  }
}

ScheduleEventPopup.propTypes = {
  classes: PropTypes.shape(),
  deleteAvailabilityLoading: PropTypes.bool,
  deleteUnavailabilityLoading: PropTypes.bool,
  editAvailability: PropTypes.func.isRequired,
  editAvailabilityLoading: PropTypes.bool,
  editUnavailability: PropTypes.func.isRequired,
  editUnavailabilityLoading: PropTypes.bool,
  event: PropTypes.shape({
    id: PropTypes.any,
    type: PropTypes.string,
    order_id: PropTypes.any,
    start: PropTypes.any.isRequired,
    end: PropTypes.any.isRequired
  }).isRequired,
  getOrderProductTripDistance: PropTypes.func.isRequired,
  maxHour: PropTypes.number.isRequired,
  minHour: PropTypes.number.isRequired,
  removeAvailability: PropTypes.func.isRequired,
  removeUnavailability: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default withStyles(styles)(ScheduleEventPopup);
