import { getApiConfigFromLocation } from './utils/functions/api';

const config = () => ({
  site_version: 'v2.5.4',
  site_name: 'Actarus',
  site_brand: 'Wall-Market',
  phone_number: '+33 1 42 27 45 53',
  primary_color: '#8378F4',
  secondary_color: '#4E4BC6',
  config_color: '#42a5f5',
  success_color: '#4CAF50',
  error_color: '#F44336',
  grey_color: '#e0e0e0',
  dark_color: '#1A2123',

  env_mode: process.env.NODE_ENV === 'production' ? 'prod' : 'test',

  google_api_key: 'AIzaSyBiZjIxOAl3U1UBj6IyY1GU7MuapU73tyA',

  api_url: getApiConfigFromLocation('api_url'),
  api_key: getApiConfigFromLocation('api_key'),
  api_env: getApiConfigFromLocation('name'),

  /**
   * BROWSERS
   */
  validBrowsers: {
    safari: '>=9',
    chrome: '>50',
    opera: '>=22'
  },

  /**
   * COOKIES
   */
  access_token_cookie_name: '_Secure-access_token',
  locale_cookie_name: 'locale',
  currency_cookie_name: 'currency',
  timezone_cookie_name: 'timezone',
  residence_country_cookie_name: 'residenceCountry',
  theme_cookie_name: 'isDarkThemeActive',
  user_search_bar_position: 'userSearchBarPosition',
  user_table_string_width_cookie_name: 'userTableStringWidth',
  user_table_links_display_mode: 'userTableLinksDisplayMode',

  /**
   * DEFAULT VALUES
   */
  default_locale: 'fr',
  default_currency: 'EUR',
  default_currency_symbol: '€',
  default_timezone: 'Europe/Paris',
  default_country: 'FR',
  default_unit_system: 'metric',
  default_table_links_display_mode: 'speedDial',
  default_search_bar_position: 'left'
});

export default new config();
