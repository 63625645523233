const clearOwnReducer = () => ({
  type: 'CLEAR_PROVIDER_FACTORY'
});

const setActiveStep = (step) => ({
  type: 'SET_PROVIDER_FACTORY_STEP',
  step
});

const createUserProviderFactory = () => ({
  type: 'CREATE_USER_PROVIDER_FACTORY'
});

const createUserProviderFactorySuccess = (user, provider) => ({
  type: 'CREATE_USER_PROVIDER_FACTORY_SUCCESS',
  user,
  provider
});

const createUserProviderFactoryUserError = (error) => ({
  type: 'CREATE_USER_PROVIDER_FACTORY_USER_ERROR',
  error
});

const createUserProviderFactoryProviderError = (error) => ({
  type: 'CREATE_USER_PROVIDER_FACTORY_PROVIDER_ERROR',
  error
});

const createUserProviderFactoryCompany = () => ({
  type: 'CREATE_USER_PROVIDER_FACTORY_COMPANY'
});

const createUserProviderFactoryCompanySuccess = (company) => ({
  type: 'CREATE_USER_PROVIDER_FACTORY_COMPANY_SUCCESS',
  company
});

const createUserProviderFactoryCompanyError = (error) => ({
  type: 'CREATE_USER_PROVIDER_FACTORY_COMPANY_ERROR',
  error
});

const selectUserProviderFactoryCompany = (company) => ({
  type: 'SELECT_USER_PROVIDER_COMPANY',
  company
});

const linkUserProviderFactorySuccess = () => ({
  type: 'LINK_USER_PROVIDER_FACTORY_SUCCESS'
});

const checkUserProviderFactoryAdmin = (checked) => ({
  type: 'CHECK_USER_PROVIDER_FACTORY_ADMIN',
  checked
});

const createProviderFactoryAddress = () => ({
  type: 'CREATE_PROVIDER_FACTORY_ADDRESS'
});

const createProviderFactoryAddressSuccess = ({ address, name }) => ({
  type: 'CREATE_PROVIDER_FACTORY_ADDRESS_SUCCESS',
  address,
  name
});

const createProviderFactoryAddressError = (error) => ({
  type: 'CREATE_PROVIDER_FACTORY_ADDRESS_ERROR',
  error
});

const createProviderFactoryPaymentMethod = () => ({
  type: 'CREATE_PROVIDER_FACTORY_PAYMENT_METHOD'
});

const createProviderFactoryPaymentMethodSuccess = (data) => ({
  type: 'CREATE_PROVIDER_FACTORY_PAYMENT_METHOD_SUCCESS',
  data
});

const createProviderFactoryPaymentMethodError = (error) => ({
  type: 'CREATE_PROVIDER_FACTORY_PAYMENT_METHOD_ERROR',
  error
});

export {
  checkUserProviderFactoryAdmin,
  clearOwnReducer,
  setActiveStep,
  createUserProviderFactory,
  createUserProviderFactoryCompany,
  createUserProviderFactoryCompanySuccess,
  createUserProviderFactoryCompanyError,
  createUserProviderFactorySuccess,
  createUserProviderFactoryUserError,
  createUserProviderFactoryProviderError,
  linkUserProviderFactorySuccess,
  selectUserProviderFactoryCompany,
  createProviderFactoryAddress,
  createProviderFactoryAddressSuccess,
  createProviderFactoryAddressError,
  createProviderFactoryPaymentMethod,
  createProviderFactoryPaymentMethodSuccess,
  createProviderFactoryPaymentMethodError
};
