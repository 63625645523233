const createResource = () => ({
  type: 'CREATE_RESOURCE'
});

const createResourceSuccess = (data) => ({
  type: 'CREATE_RESOURCE_SUCCESS',
  data
});

const createResourceError = (isError) => ({
  type: 'CREATE_RESOURCE_ERROR',
  isError
});

const setCreateResourceTimezone = (timezone) => ({
  type: 'SET_CREATE_RESOURCE_TIMEZONE',
  timezone
});

export { createResource, createResourceSuccess, createResourceError, setCreateResourceTimezone };
