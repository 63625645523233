import './whatshot.scss';

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import translation from '../../translation/translation';

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  listItem: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5)
  },
  listText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start'
  },
  italicText: {
    display: 'contents'
  }
}));

function Badge({ type }) {
  return <div className={`Badge ${type.toLowerCase()}`}>{type}</div>;
}

Badge.propTypes = {
  type: PropTypes.string
};

function WhatsHot() {
  useEffect(() => {
    document.title = 'Actarus | Changelog';
  }, []);

  const classes = useStyles();

  return (
    <div className="WhatsHot">
      <Container>
        <div className={classes.pageTitle}>
          <Typography gutterBottom component="h2" variant="h2">
            {translation().views.whatshot.page_title}
          </Typography>
          <Typography gutterBottom component="p" variant="body1">
            {translation().views.whatshot.description}
          </Typography>
        </div>
        <Paper className={classes.root} elevation={1}>
          <div className="changelog">
            <Typography gutterBottom variant="h5">
              v2.5.4{' '}
              <Typography component="span" variant="subtitle2">
                (Duplicate item)
              </Typography>
            </Typography>
            <ul>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" /> Il est désormais possible de dupliquer des données depuis
                  la liste de certaines ressources.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" /> Ajout de la liste des media types pour un produit, depuis
                  la vue produit.
                </Typography>
              </li>
            </ul>
          </div>
        </Paper>
        <Paper className={classes.root} elevation={1}>
          <div className="changelog">
            <Typography gutterBottom variant="h5">
              v2.5.3{' '}
              <Typography component="span" variant="subtitle2">
                (Payment delegate)
              </Typography>
            </Typography>
            <ul>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" /> Ajout des champs de délégation de paiement dans la
                  création de commande.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" /> Ajout des informations relatives à la délégation de
                  paiement dans les vues de commandes.
                </Typography>
              </li>
            </ul>
          </div>
        </Paper>
        <Paper className={classes.root} elevation={1}>
          <div className="changelog">
            <Typography gutterBottom variant="h5">
              v2.5.2
            </Typography>
            <ul>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="fixed" /> Le taux de reversion hors délai prestaire du formulaire{' '}
                  <i className={classes.italicText}>Provider Factory</i> accepte maintenant zéro
                  comme valeur.
                </Typography>
              </li>
            </ul>
          </div>
        </Paper>
        <Paper className={classes.root} elevation={1}>
          <div className="changelog">
            <Typography gutterBottom variant="h5">
              v2.5.1
            </Typography>
            <ul>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" /> Ajout du nouveau champ{' '}
                  {'"Late Cancel Reversion Percentage"'} dans la vue {"d'édition d'un provider"} et
                  dans la <i className={classes.italicText}>Provider View</i>.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" /> Ajout du nouveau champ provider{' '}
                  {'"Late Cancel Reversion Percentage"'} dans la{' '}
                  <i className={classes.italicText}>Provider Factory</i>.
                </Typography>
              </li>
            </ul>
          </div>
        </Paper>
        <Paper className={classes.root} elevation={1}>
          <div className="changelog">
            <Typography gutterBottom variant="h5">
              v2.5.0{' '}
              <Typography component="span" variant="subtitle2">
                (Tasks manager)
              </Typography>
            </Typography>
            <ul>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Typography component="span" className={classes.listText}>
                    <Badge type="created" /> Création {"d'une"} nouvelle liste déroulante{' '}
                    <i className={classes.italicText}>Mission</i> dans le menu principal, dédiée aux
                    routes qui concernent la gestion des taches.
                  </Typography>
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" /> Déploiement de{' '}
                  <i className={classes.italicText}>Task Manager</i>, le système de tache interne.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" /> Ajout des <i className={classes.italicText}>teams</i>{' '}
                  dans <i className={classes.italicText}>Iam</i>.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" /> Ajout {"d'une"} pagination de liste dans les champs{' '}
                  {"d'autocomplétion"}.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="update" /> {'react-scripts: 3.0.0'}
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" /> Ajout de la colonne{' '}
                  <i className={classes.italicText}>execute_on</i> dans la liste des transactions.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" /> Ajout de la colonne{' '}
                  <i className={classes.italicText}>scheduled</i> dans la liste des paiements{' '}
                  {"d'une"} facture.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" /> Ajout du <i className={classes.italicText}>way</i>{' '}
                  {'"pay"'} dans la récupération des moyens de paiement du tunnel de commande.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" /> Ajout de la devise et du{' '}
                  <i className={classes.italicText}>way</i> dans la{' '}
                  <i className={classes.italicText}>Payers Balance</i>.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" /> Ajout du <i className={classes.italicText}>way</i> dans
                  le formulaire de création de moyen de paiment dans{' '}
                  <i className={classes.italicText}>Payers</i>.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="fixed" /> Suppression de {"l'espace"} devant les :mailto
                </Typography>
              </li>
            </ul>
          </div>
        </Paper>
        <Paper className={classes.root} elevation={1}>
          <div className="changelog">
            <Typography gutterBottom variant="h5">
              v2.4.2
            </Typography>
            <ul>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" /> Ajout {"d'un"} champ mot de passe utilisateur dans la{' '}
                  <i className={classes.italicText}>Provider Factory</i>.
                </Typography>
              </li>
            </ul>
          </div>
        </Paper>
        <Paper className={classes.root} elevation={1}>
          <div className="changelog">
            <Typography gutterBottom variant="h5">
              v2.4.1
            </Typography>
            <ul>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" /> Ajout des attributs des produits de commande, dans la vue{' '}
                  {"d'une"} commande.
                </Typography>
              </li>
            </ul>
          </div>
        </Paper>
        <Paper className={classes.root} elevation={1}>
          <div className="changelog">
            <Typography gutterBottom variant="h5">
              v2.4.0{' '}
              <Typography component="span" variant="subtitle2">
                (Yarn)
              </Typography>
            </Typography>
            <ul>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="changed" /> Changement de gestionnaire de ressources, abandon de npm
                  pour yarn.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="update" /> Upgrade de modules.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="fixed" /> Résolutions de nombreux warnings.
                </Typography>
              </li>
            </ul>
          </div>
        </Paper>
        <Paper className={classes.root} elevation={1}>
          <div className="changelog">
            <Typography gutterBottom variant="h5">
              v2.3.4{' '}
              <Typography component="span" variant="subtitle2">
                (Provider menu)
              </Typography>
            </Typography>
            <ul>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" /> Création {"d'une"} nouvelle liste déroulante{' '}
                  <i className={classes.italicText}>Provider</i> dans le menu principal, dédiée aux
                  routes qui concernent la gestion des prestataires.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" /> Ajout du mode{' '}
                  <i className={classes.italicText}>Configuration produits</i> dans la liste des
                  produits {"d'un"} prestataire.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" /> Déplacement de la liste des produits de commandes
                  prestaire de la Providers View dans un onglet dédié.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" /> Mise en place {"d'un"} tunnel de création de prestaire,
                  appelé <i className={classes.italicText}>Provider Factory</i>, qui regroupe en un
                  écran: la création du user, du provider, de la company, la configuration des
                  produits et de la facturation.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="fixed" /> Correction du chargement infini des données dans la liste
                  des dashboards.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="fixed" /> Améliorations diverses et corrections de warnings.
                </Typography>
              </li>
            </ul>
          </div>
        </Paper>
        <Paper className={classes.root} elevation={1}>
          <div className="changelog">
            <Typography gutterBottom variant="h5">
              v2.3.3
            </Typography>
            <ul>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" /> Un nouvel écran est disponible dans Production:{' '}
                  <i className={classes.italicText}>Order products overdue</i> : Liste de produits
                  de commande dont la livraison est en retard.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" /> Un nouvel écran est disponible dans Shop:{' '}
                  <i className={classes.italicText}>Providers ghosts</i> : Liste de produits de
                  commande planifiés, en cours, ou faits, et ayant pour prestaire un ghost.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="changed" /> Il est maintenant possible de sélectionner plusieurs
                  produits dans les filtres
                  {" de l'écran "}
                  <i className={classes.italicText}>Previous day</i>.
                </Typography>
              </li>
            </ul>
          </div>
        </Paper>
        <Paper className={classes.root} elevation={1}>
          <div className="changelog">
            <Typography gutterBottom variant="h5">
              v2.3.2
            </Typography>
            <ul>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" /> Intégration des mediatypes de type formulaire dans les
                  médias des produits de commande et la modération.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" /> Ajout du type <i className={classes.italicText}>label</i>{' '}
                  dans les metrics.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="changed" /> Modification de la méthode de calcul pour les valeurs
                  moyennes dans les metrics.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="changed" /> Les produits de commande de la liste de modération{' '}
                  {"s'ouvrent"} maintenant dans une modale.
                </Typography>
              </li>
            </ul>
          </div>
        </Paper>
        <Paper className={classes.root} elevation={1}>
          <div className="changelog">
            <Typography gutterBottom variant="h5">
              v2.3.1
            </Typography>
            <ul>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" />
                  Ajout du champ{' '}
                  <i className={classes.italicText}>référence interne de la commande</i> dans{' '}
                  {"l'étape"} paiement du tunnel de création de commande.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" />
                  Ajout de la référence (si elle existe) {"d'une"} commande, dans la vue commande.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" />
                  Ajout {"d'un"} tunnel de création de provider, avec configuration de produits et
                  facturation.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="fixed" /> Correction des envois et affichages de dates vers {"l'API"}{' '}
                  depuis le calendrier prestataire, lors de {"l'utilisation"} de ce dernier avec une
                  autre timezone que celle de {"l'utilisateur"} connecté.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="changed" /> Les flèches de retour dans les vues se basent maintenant
                  sur {"l'url"} du navigateur et non sur son historique.
                </Typography>
              </li>
            </ul>
          </div>
        </Paper>
        <Paper className={classes.root} elevation={1}>
          <div className="changelog">
            <Typography gutterBottom variant="h5">
              v2.3.0
            </Typography>
            <ul>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" /> Introduction des timezones dans les collections.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" /> Les dates liées aux prestations sont désormais affichées
                  en heure locale et heure de {"l'utilisateur"} connecté.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" /> Ajout de trois nouvelles langues: Allemand, Espagnol et
                  Italien.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" /> Ajout du formuaire de changement de votre mot de passe,
                  directement depuis votre profil Actarus.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" /> Ajout de liens rapides vers les autres applications et
                  sites utiles aux équipes, disponibles dans le header.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="changed" /> {"L'envoi"} du formulaire {"d'accès"} au bien lors de la
                  création de commande est désormais traduit.
                </Typography>
              </li>
            </ul>
          </div>
        </Paper>
        <Paper className={classes.root} elevation={1}>
          <div className="changelog">
            <Typography gutterBottom variant="h5">
              v2.2.7
            </Typography>
            <ul>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" /> Ajout {"d'une vue"} produit, avec détails, options, plans{' '}
                  {"d'hébergement"} et simulateur de prix.
                </Typography>
              </li>
            </ul>
          </div>
        </Paper>
        <Paper className={classes.root} elevation={1}>
          <div className="changelog">
            <Typography gutterBottom variant="h5">
              v2.2.6
            </Typography>
            <ul>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" /> Ajout des opérateurs de filtres {'"null" et "non null"'}.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" /> Ajout {"d'un"} sélecteur de langue dans les vues{' '}
                  {"d'édition"} pour mettre à jour une ressource dans une langue donnée.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" /> Pagination de liste dans les collections.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="fixed" /> Corrections mineures des formulaires.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="changed" /> Déplacement de la{' '}
                  <i className={classes.italicText}>Providers map</i> dans la zone Shop.
                </Typography>
              </li>
            </ul>
          </div>
        </Paper>
        <Paper className={classes.root} elevation={1}>
          <div className="changelog">
            <Typography gutterBottom variant="h5">
              v2.2.5
            </Typography>
            <ul>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" /> Ajout des routes de liste, de création et {"d'édition"}{' '}
                  des plans {"d'hébergement"} pour un produit donné.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="deleted" /> Retrait de {"l'écran"} de la charge prévisionnelle.
                </Typography>
              </li>
            </ul>
          </div>
        </Paper>
        <Paper className={classes.root} elevation={1}>
          <div className="changelog">
            <Typography gutterBottom variant="h5">
              v2.2.4
            </Typography>
            <ul>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" /> Ajout {"d'un"} formulaire de création de moyen de paiment
                  pour un {'"payer"'} donné.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" /> Possibilité de modifier le niveau{' '}
                  {"d'administration d'un"} utilisateur donné depuis sa vue utilisateur.
                </Typography>
              </li>
            </ul>
          </div>
        </Paper>
        <Paper className={classes.root} elevation={1}>
          <div className="changelog">
            <Typography gutterBottom variant="h5">
              v2.2.3
            </Typography>
            <ul>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" /> Ajout {"d'un"} tunnel de commande {"d'hébergement"} de
                  médias pour un produit {"d'une"} commande.
                </Typography>
              </li>
            </ul>
          </div>
        </Paper>
        <Paper className={classes.root} elevation={1}>
          <div className="changelog">
            <Typography gutterBottom variant="h5">
              v2.2.2
            </Typography>
            <ul>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" /> Vue complète {"d'un"} utilisateur.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" /> Vue complète {"d'une"} entreprise.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="update" /> Refonte de la vue complète {"d'un"} prestataire.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="update" /> {'@material-ui/core: 4.11.3'}
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="update" /> {'@material-ui/icons: 4.11.2'}
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="update" /> {'@material-ui/styles: 4.11.3'}
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="update" /> {'@material-ui/utils: 4.11.2'}
                </Typography>
              </li>
            </ul>
          </div>
        </Paper>
        <Paper className={classes.root} elevation={1}>
          <div className="changelog">
            <Typography gutterBottom variant="h5">
              v2.2.1
            </Typography>
            <ul>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" /> Ajout {"d'un"} menu de sélection rapide de période pour
                  consulter un dashboard.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" /> Ajout du champ {'"limit"'} dans le formulaire de création{' '}
                  {"d'une"} metrique {"d'un"} dashboard.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="update" /> Refactorisation du composant Alert.
                </Typography>
              </li>

              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" /> Ajout des extensions de fichiers en plus des mime types
                  dans les droppers de fichiers.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" /> Intégration de RingOver.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="changed" /> Mise en attente du widget RingOver.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="changed" /> Changements mineurs des variables de la table de{' '}
                  <i className={classes.italicText}>Providers ticketing</i>.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" /> Ajout {"d'une"} carte affichant les différentes positions
                  pour un utilisateur donné.
                </Typography>
              </li>
            </ul>
          </div>
        </Paper>
        <Paper className={classes.root} elevation={1}>
          <div className="changelog">
            <Typography gutterBottom variant="h5">
              v2.2.0
            </Typography>
            <ul>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" /> Metrics dashboard.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="deleted" /> Smart metrics.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="update" /> {'react-big-calendar: 0.28.1'}
                </Typography>
              </li>
            </ul>
          </div>
        </Paper>
        <Paper className={classes.root} elevation={1}>
          <div className="changelog">
            <Typography gutterBottom variant="h5">
              v2.1.3
            </Typography>
            <ul>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" /> Ajout du type {'"line + avg"'} dans les types de Smart
                  Metrics.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" /> Ajout {"d'un"} champ date pour consulter le solde{' '}
                  {"d'un"} payeur à un moment donné.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" /> Installation du paquet npm Bowser, qui permet de pouvoir
                  récupérer les informations sur {"l'appareil"} et le navigateur utilisés par{' '}
                  {"l'utilisateur"}.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="fixed" /> Les champs commentaires et détails{' '}
                  {"d'accès au bien d'un produit d'une commande"} peuvent être désormais agrandis ou
                  réduis au besoin avec la souris.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="deleted" /> Suppression du zoom sur mobile.
                </Typography>
              </li>
            </ul>
          </div>
        </Paper>
        <Paper className={classes.root} elevation={1}>
          <div className="changelog">
            <Typography gutterBottom variant="h5">
              v2.1.2
            </Typography>
            <ul>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" /> Il est maintenant possible de glisser-déposer, et de
                  réduire ou agrandir des disponiblités ou des indisponiblités depuis le calendrier{' '}
                  {"d'un"} prestataire.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" /> Il est également possible de consulter le calendrier{' '}
                  {"d'un"} prestataire depuis sa timezone.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" /> Ajout {"d'un champ d'auto-complétion"} pour tous les
                  champs de type timezone.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" /> Ajout {"d'une"} vue invitant à utiliser Google Chrome
                  lorsque ce {"n'est"} pas le navigateur sélectionné pour Actarus.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="fixed" /> Quelques fixs sur le thème en mode nuit.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="changed" /> classNames refactorisation.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="deleted" /> clsx
                </Typography>
              </li>
            </ul>
          </div>
        </Paper>
        <Paper className={classes.root} elevation={1}>
          <div className="changelog">
            <Typography gutterBottom variant="h5">
              v2.1.1
            </Typography>
            <ul>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="fixed" /> Fix de liens inexistants dans le champ{' '}
                  {"d'auto-complétion"}.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="fixed" /> Ajout {"d'un"} nombre aléatoire dans la requète pour
                  envoyer un fichier au serveur.
                </Typography>
              </li>
            </ul>
          </div>
        </Paper>
        <Paper className={classes.root} elevation={1}>
          <div className="changelog">
            <Typography gutterBottom variant="h5">
              v2.1.0
            </Typography>
            <ul>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="created" /> Ajout des metrics queues émises et queues reçues pour les{' '}
                  <i className={classes.italicText}>invoicers</i>.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="info" /> Amélioration des messages {"d'alerte qu'on"} appellera les{' '}
                  <i className={classes.italicText}>Snackbars</i> pour indiquer{' '}
                  {"à l'utilisateur qu'il n'a pas"} la permission {"d'effectuer"} une action si tel
                  est le cas.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="fixed" /> Fix du champ {"d'auto-complétion"} et des valeurs
                  retournées.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="fixed" /> Le bug de déplacement des médias dans la modération a été
                  corrigé.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="fixed" /> Fix du sélecteur de dates des {'"metrics"'} en format
                  mobile.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="changed" /> Changement du composant de tables, react-sticky-table
                  abandonné pour @material-ui/core/Tables.
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="changed" /> Changement du format des dates qui interrogent {"l'API"}{' '}
                  sur les metrics (metrics, smart metrics): la requête ne se fait plus en format
                  local mais de nouveau en format international (UTC).
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="deleted" /> react-sticky-table
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="deleted" /> react-virtualized
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="update" /> react: 16.13.1
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="update" /> react-dom: 16.13.1
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="update" /> react-dates: 21.8.0
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="update" /> react-dropzone: 11.0.1
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="update" /> react-sortable-hoc: 1.11.0
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="update" /> react-dnd: 11.1.3
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="update" /> react-dnd-html5-backend: 11.1.3
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="update" /> react-phone-number-input: 3.0.23
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="update" /> @material-ui/core: 4.11.0
                </Typography>
              </li>
              <li className={classes.listItem}>
                <Typography component="span" className={classes.listText}>
                  <Badge type="update" /> @material-ui/lab: 4.0.0-alpha.56
                </Typography>
              </li>
            </ul>
          </div>
        </Paper>
      </Container>
    </div>
  );
}

export default WhatsHot;
