import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Paper,
  Stepper,
  Step,
  StepContent,
  StepLabel,
  Typography
} from '@material-ui/core';

import CreateHostingExtensionsViewBar from './components/CreateHostingExtensionsViewBar';
import HostingPlansStep from './steps/HostingPlansStep';
import PaymentStep from './steps/PaymentStep';
import UserStep from './steps/UserStep';
import translation from '../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  paper: {
    ...theme.mixins.gutters(),
    marginTop: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  stepContent: {
    padding: theme.spacing(2)
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(0, 2)
  },
  stepper: {
    marginTop: theme.spacing(3),
    padding: 0
  },
  summary: {
    marginTop: 8,
    padding: theme.spacing(1)
  },
  divider: {
    marginTop: 4,
    marginBottom: 4
  },
  planSelected: {
    marginBottom: 12
  }
}));

function CreateOrderProductsHostingExtensions(props) {
  const {
    clearOwnReducer,
    createHostingExtension,
    getClientAddresses,
    getClientPaymentMethods,
    getOrderProductHostingPlans,
    history,
    hostingExtensions: { create, user, addresses, payment_methods, plans },
    location: { pathname },
    match: {
      params: { order_id, order_product_id }
    },
    parentUri,
    selectAddress,
    selectPaymentMethod,
    selectPlan,
    selectUser,
    setListNeedReload
  } = props;

  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [isPaymentAvailable, setPaymentAvailable] = useState(false);
  const [isAddressAvailable, setAddressAvailable] = useState(false);
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);

  useEffect(() => {
    return () => {
      clearOwnReducer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleNext() {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  function handleBack() {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  function handleSelectUser(user) {
    selectUser(user);

    getClientPaymentMethods(user, (data) => {
      if (data && data.length <= 0) setPaymentAvailable(false);
      else if (!data) setPaymentAvailable(false);
      else setPaymentAvailable(true);
    });

    getClientAddresses(user, (data) => {
      if (data && data.length <= 0) setAddressAvailable(false);
      else if (!data) setAddressAvailable(false);
      else setAddressAvailable(true);
    });
  }

  function handleSelectPlan(plan) {
    selectPlan(plan);
  }

  function handleSelectPaymentMethod(plan) {
    selectPaymentMethod(plan);
  }

  function handleSelectAddress(plan) {
    selectAddress(plan);
  }

  function handleOpenConfirmDialog() {
    setConfirmDialogOpen(true);
  }

  function handleCloseConfirmDialog() {
    setConfirmDialogOpen(false);
  }

  function handleOrder() {
    if (!addresses.selected || !payment_methods.selected || !plans.selected || !user.id) {
      return;
    }

    createHostingExtension(
      order_id,
      order_product_id,
      {
        iam_user_id: user.id,
        invoice_address_id: addresses.selected,
        invoice_payment_method_id: payment_methods.selected,
        product_hosting_plan_id: plans.selected
      },
      () => {
        setListNeedReload();
        handleCloseConfirmDialog();

        if (history && history.push) {
          history.push(`/orders/${order_id}/products/${order_product_id}/hosting-extensions`);
        }
      }
    );
  }

  function getSteps() {
    return translation().views.order_products.hosting_extensions.create.steps_labels;
  }

  function getSelectedPlanObject() {
    if (plans.selected && plans.data && plans.data.length) {
      for (let i = 0; i < plans.data.length; i++) {
        if (plans.data[i].id && plans.data[i].id === plans.selected) return plans.data[i];
      }
    }

    return null;
  }

  function renderStepContent(step) {
    switch (step) {
      case 0:
        return (
          <UserStep
            user={user}
            addresses={addresses}
            addressAvailable={isAddressAvailable}
            paymentMethods={payment_methods}
            onSelect={handleSelectUser}
            paymentAvailable={isPaymentAvailable}
          />
        );
      case 1:
        return (
          <HostingPlansStep
            getOrderProductHostingPlans={getOrderProductHostingPlans}
            orderId={order_id}
            orderProductId={order_product_id}
            plans={plans}
            onSelect={handleSelectPlan}
          />
        );
      case 2:
        return (
          <PaymentStep
            addresses={addresses}
            paymentMethods={payment_methods}
            onSelectMethod={handleSelectPaymentMethod}
            onSelectAddress={handleSelectAddress}
            planSelected={getSelectedPlanObject()}
          />
        );
      default:
        return (
          <UserStep
            user={user}
            addresses={addresses}
            paymentMethods={payment_methods}
            onSelect={handleSelectUser}
            paymentAvailable={isPaymentAvailable}
          />
        );
    }
  }

  function renderStepButtons(step) {
    switch (step) {
      case 0:
        return (
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            disabled={!user}
            className={classes.button}>
            {translation().actions.next}
          </Button>
        );
      case 1:
        return (
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            disabled={!user || !plans.selected}
            className={classes.button}>
            {translation().actions.next}
          </Button>
        );
      case 2:
        return (
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenConfirmDialog}
            disabled={
              !user ||
              !plans.selected ||
              !addresses.selected ||
              !payment_methods.selected ||
              create.isLoading
            }
            className={classes.button}>
            {translation().actions.confirm}
          </Button>
        );
      default:
        return (
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            disabled={!user || create.isLoading}
            className={classes.button}>
            {translation().actions.next}
          </Button>
        );
    }
  }

  const steps = getSteps();
  const planSelected = getSelectedPlanObject();

  return (
    <div>
      <CreateHostingExtensionsViewBar
        history={history}
        disabled={false}
        parentUri={parentUri}
        routeUri={pathname.substring(1)}
      />
      <Container>
        <Paper className={classes.paper}>
          <Typography variant="h5">
            {translation().views.order_products.hosting_extensions.create.title}
          </Typography>
          <Stepper className={classes.stepper} activeStep={activeStep} orientation="vertical">
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
                <StepContent>
                  <div className={classes.stepContent}>{renderStepContent(index)}</div>
                  <div className={classes.actionsContainer}>
                    {activeStep > 0 && (
                      <Button
                        onClick={handleBack}
                        className={classes.button}
                        disabled={create.isLoading}>
                        {translation().actions.back}
                      </Button>
                    )}
                    {renderStepButtons(index)}
                  </div>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </Paper>
      </Container>
      <Dialog
        open={isConfirmDialogOpen}
        onClose={handleCloseConfirmDialog}
        aria-labelledby="confirm-order-hosting-plan"
        fullWidth
        maxWidth="sm"
        disableBackdropClick={create.isLoading}>
        <DialogTitle id="confirm-order-hosting-plan">
          {translation().views.order_products.hosting_extensions.create.confirm_dialog.title}
        </DialogTitle>
        <DialogContent>
          {planSelected && (
            <div className={classes.summary}>
              {planSelected.nb_months && (
                <div className={classes.planSelected}>
                  <Typography component="span">
                    {translation().views.order_products.hosting_extensions.create.hosting_selected}{' '}
                  </Typography>
                  <Typography variant="subtitle2" component="span">{`${planSelected.nb_months} ${
                    translation().views.order_products.hosting_extensions.create.months
                  }`}</Typography>
                </div>
              )}
              {planSelected.total_tax_excluded_before_discount_formatted && (
                <div>
                  <Typography variant="body1" component="span">
                    {translation().commons.pricing.total_tax_excluded_before_discount_formatted}{' '}
                  </Typography>
                  <Typography variant="subtitle2" component="span">
                    {planSelected.price_tax_excluded_before_discount_formatted}
                  </Typography>
                </div>
              )}
              {planSelected.price_discount_formatted && (
                <div>
                  <Typography variant="body1" component="span">
                    {translation().commons.pricing.total_discount_formatted}{' '}
                  </Typography>
                  <Typography variant="subtitle2" component="span">
                    {planSelected.price_discount_formatted}
                  </Typography>
                </div>
              )}
              {planSelected.price_tax_formatted && (
                <div>
                  <Typography variant="body1" component="span">
                    {translation().commons.pricing.total_tax_formatted}{' '}
                  </Typography>
                  <Typography variant="subtitle2" component="span">
                    {planSelected.price_tax_formatted}
                  </Typography>
                </div>
              )}
              {planSelected.price_tax_excluded_formatted && (
                <div>
                  <Typography variant="body1" component="span">
                    {translation().commons.pricing.total_tax_excluded_formatted}{' '}
                  </Typography>
                  <Typography variant="subtitle2" component="span">
                    {planSelected.price_tax_excluded_formatted}
                  </Typography>
                </div>
              )}
              <Divider className={classes.divider} />
              {planSelected.price_formatted && (
                <div>
                  <Typography variant="button" component="span">
                    {translation().commons.pricing.total_formatted}{' '}
                  </Typography>
                  <Typography variant="button" component="span">
                    {planSelected.price_formatted}
                  </Typography>
                </div>
              )}
              {create.isLoading && (
                <div className="loader-wpr">
                  <CircularProgress color="primary" size={30} />
                  <p>
                    {
                      translation().views.order_products.hosting_extensions.create.confirm_dialog
                        .loading_text
                    }
                  </p>
                </div>
              )}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} disabled={create.isLoading}>
            {translation().actions.cancel}
          </Button>
          <Button
            onClick={handleOrder}
            variant="contained"
            color="primary"
            disabled={create.isLoading}>
            {translation().actions.order}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

CreateOrderProductsHostingExtensions.defaultProps = {
  parentUri: 'Shop'
};

CreateOrderProductsHostingExtensions.propTypes = {
  clearOwnReducer: PropTypes.func.isRequired,
  createHostingExtension: PropTypes.func.isRequired,
  getClientPaymentMethods: PropTypes.func.isRequired,
  getClientAddresses: PropTypes.func.isRequired,
  getOrderProductHostingPlans: PropTypes.func.isRequired,
  history: PropTypes.shape().isRequired,
  hostingExtensions: PropTypes.shape({
    addresses: PropTypes.shape().isRequired,
    create: PropTypes.shape({
      isLoading: PropTypes.bool
    }).isRequired,
    plans: PropTypes.shape().isRequired,
    payment_methods: PropTypes.shape().isRequired,
    user: PropTypes.shape()
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      order_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      order_product_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  }).isRequired,
  parentUri: PropTypes.string,
  selectAddress: PropTypes.func.isRequired,
  selectPaymentMethod: PropTypes.func.isRequired,
  selectPlan: PropTypes.func.isRequired,
  selectUser: PropTypes.func.isRequired,
  setListNeedReload: PropTypes.func.isRequired
};

export default CreateOrderProductsHostingExtensions;
