import './planning.scss';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { grey, orange } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Alert from '../../../../components/alert/Alert';
import {
  createDate,
  dateInTz,
  formatDate,
  toUtcTimestamp
} from '../../../../utils/functions/dates';
import { objectMapToArray } from '../../../../utils/functions/utils';
import { getTimezone } from '../../../../utils/functions/cookies';
import translation from '../../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    maxHeight: 455
  },
  tableCell: {
    borderColor: 'transparent'
  },
  title: {
    color: theme.palette.text.secondary,
    marginBottom: 12
  },
  timeSlot: {
    border: '1px solid ' + grey['500'],
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      color: '#FFFFFF'
    },
    '&.selected': {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      color: '#FFFFFF'
    }
  },
  nav: {
    height: 56,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: '1px solid #e0e0e0'
  },
  timezoneWarning: {
    backgroundColor: orange[600],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 8,
    color: '#FFFFFF'
  }
}));

function Planning(props) {
  const {
    disabled,
    onSlotChange,
    onClickNextWeek,
    onClickPreviousWeek,
    startDate,
    minStartDate,
    maxStartDate,
    calendar,
    nbTimeMax,
    isWeekLoading,
    timezone
  } = props;

  const classes = useStyles();
  const startDateFormated = formatDate(startDate, 'YYYY-MM-DD');
  const minStartDateFormated = formatDate(minStartDate, 'YYYY-MM-DD');
  const maxStartDateFormated = formatDate(maxStartDate, 'YYYY-MM-DD');
  const [selected, setSelected] = useState(null);

  function handleSlotChange(time, date) {
    if (!time || !date) return;

    if (time !== 'Invalid date' || date !== 'Invalid date') {
      const slotSelected = createDate(date + ' ' + time, timezone);
      const slotSelectedUTC = toUtcTimestamp(slotSelected);

      setSelected(slotSelectedUTC);

      if (onSlotChange) onSlotChange(slotSelectedUTC);
    }
  }

  const isPrevDisabled = startDateFormated <= minStartDateFormated ? true : false;
  const isNextDisabled = startDateFormated >= maxStartDateFormated ? true : false;

  if (timezone) {
    return (
      <div className="Planning">
        <Typography variant="body1" component="p" className={classes.title}>
          {translation().views.orders.create.products.slots.label}
        </Typography>
        <div className="Planning-container">
          {timezone && timezone !== getTimezone() && (
            <div className={classes.timezoneWarning}>
              <ErrorOutlineIcon fontSize="small" style={{ marginRight: 4 }} />
              <Typography>
                {translation().views.orders.update.products.product_planning.timezone_diff} (
                {timezone})
              </Typography>
            </div>
          )}
          <TableContainer className={classes.tableContainer}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" className={classes.tableCell}>
                    <IconButton
                      aria-label="Load previous week"
                      size="small"
                      disabled={isPrevDisabled}
                      onClick={onClickPreviousWeek}>
                      <ChevronLeftIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                  {calendar &&
                    Object.keys(calendar).map((date, index) => {
                      return (
                        <TableCell key={index} align="center" className={classes.tableCell}>
                          <Typography variant="subtitle2" component="p">
                            {dateInTz(date, 'dddd')}
                          </Typography>
                          <Typography variant="caption" component="span">
                            {dateInTz(date, 'localized-date')}
                          </Typography>
                        </TableCell>
                      );
                    })}
                  <TableCell align="center" className={classes.tableCell}>
                    <IconButton
                      aria-label="Load next week"
                      size="small"
                      disabled={isNextDisabled}
                      onClick={onClickNextWeek}>
                      <ChevronRightIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell />
                  {calendar &&
                    objectMapToArray(calendar, (date, times, index) => {
                      let nbEmptyTimes = Math.max(0, nbTimeMax - times.length);
                      let emptySlots = [];

                      for (let g = 0; g < nbEmptyTimes; g++) {
                        emptySlots.push(g);
                      }
                      return (
                        <TableCell key={index} align="center">
                          {times.map((time, index) => {
                            return (
                              <div
                                key={index}
                                className={classNames(classes.timeSlot, 'time-slot', {
                                  selected:
                                    selected ===
                                    toUtcTimestamp(createDate(date + ' ' + time, timezone))
                                })}
                                onClick={() => handleSlotChange(time, date)} //eslint-disable-line
                              >
                                <Typography variant="body2" component="span">
                                  {time}
                                </Typography>
                              </div>
                            );
                          })}
                          {emptySlots.map((elem, index) => {
                            return (
                              <div key={index} className="empty-slot">
                                <span />
                              </div>
                            );
                          })}
                        </TableCell>
                      );
                    })}
                  <TableCell />
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          {isWeekLoading && (
            <div className="Planning-overlay">
              <div className="loader-wpr">
                <CircularProgress size={28} color="secondary" />
                <p>{translation().views.orders.create.products.slots.loading_text}</p>
              </div>
            </div>
          )}
          {disabled && (
            <div className="Planning-overlay">
              <div className="loader-wpr" />
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <Alert
        type="danger"
        text={`${
          translation().views.orders.update.products.product_planning.timezone_not_found
        } (error code: property_timezone_not_found)`}
      />
    );
  }
}

Planning.propTypes = {
  disabled: PropTypes.bool,
  isWeekLoading: PropTypes.bool,
  startDate: PropTypes.any,
  minStartDate: PropTypes.any,
  maxStartDate: PropTypes.any,
  calendar: PropTypes.shape().isRequired,
  nbTimeMax: PropTypes.number,
  onSlotChange: PropTypes.func.isRequired,
  onClickNextWeek: PropTypes.func.isRequired,
  onClickPreviousWeek: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired
};

export default Planning;
