import './createOrderView.scss';

import React, { forwardRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { orange } from '@material-ui/core/colors';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Chip from '@material-ui/core/Chip';
import { EuroSymbol } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import EmailIcon from '@material-ui/icons/Email';
import HouseIcon from '@material-ui/icons/House';
import IconButton from '@material-ui/core/IconButton';
import LanguageIcon from '@material-ui/icons/Language';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Paper from '@material-ui/core/Paper';
import PaymentIcon from '@material-ui/icons/Payment';
import ReceiptIcon from '@material-ui/icons/Receipt';
import Slide from '@material-ui/core/Slide';
import SquareFootIcon from '@material-ui/icons/SquareFoot';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TimerIcon from '@material-ui/icons/Timer';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import WorkIcon from '@material-ui/icons/Work';

import LocalDateInfo from '../../../components/timezone/LocalDateInfo';
import OrderCart from './components/OrderCart';
import OrderCreateViewBar from './components/OrderCreateViewBar';
import OrderStepper from './components/OrderStepper';
import OrderDoneIcon from '../../../components/icons/OrderDoneIcon';
import ProductsStep from './steps/ProductsStep';
import PropertyStep from './steps/PropertyStep';
import PaymentStep from './steps/PaymentStep';

import { convertMinsToHrsMins, dateInTz } from '../../../utils/functions/dates';
import { isObjEmpty } from '../../../utils/functions/utils';
import { getTimezone } from '../../../utils/functions/cookies';

import translation from '../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1)
  },
  buttonLink: {
    marginRight: theme.spacing(1),
    marginBottom: 4
  },
  buttonWrapper: {
    position: 'absolute',
    bottom: 10,
    left: 0,
    right: 0,
    width: '100%',
    textAlign: 'right'
  },
  cartContainer: {
    minHeight: 400,
    position: 'relative',
    paddingBottom: 50,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      minHeight: 100
    }
  },
  configmAppBar: {
    position: 'relative'
  },
  configmCloseBtn: {
    marginLeft: 'auto'
  },
  configmDialogRoot: {
    padding: 15
  },
  confirmListRoot: {
    flexGrow: 1
  },
  confirmDialogPaper: {
    background: theme.palette.background.default
  },
  confirmPaper: {
    overflow: 'auto',
    width: '100%',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  confirmPaperTitle: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  doneImage: {
    display: 'block',
    width: 200,
    height: 'auto',
    margin: 'auto'
  },
  formContainer: {
    minHeight: 400,
    position: 'relative'
  },
  hiddenDivider: {
    display: 'block',
    height: 35
  },
  orderCreatePaper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: '100%'
  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  subtitle2: {
    textAlign: 'center',
    fontSize: 16
  },
  tableCell: {
    fontSize: 12
  },
  tablePaper: {
    marginTop: theme.spacing(2)
  },
  title: {
    textAlign: 'center'
  },
  timezoneAvatar: {
    backgroundColor: orange[600]
  },
  timezoneInfo: {
    color: orange[600]
  }
}));

function getSteps() {
  return [
    translation().views.orders.create.steps.infos,
    translation().views.orders.create.steps.products,
    translation().views.orders.create.steps.payment
  ];
}

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CreateOrderView(props) {
  const {
    addDiscountToCart,
    addProductToCart,
    blurProductOptions,
    cancelValidatingFields,
    clearOrderCreateReducer,
    createClientAddress,
    deleteClientCart,
    createOrder,
    deleteClientCartItem,
    getClientAddresses,
    getClientCart,
    getClientPaymentMethods,
    getPropertyTimezone,
    getProductGroups,
    getProducts,
    getProductOptions,
    getProductSlots,
    getNextProductSlots,
    getPreviousProductSlots,
    history,
    order_create: {
      addresses,
      cart,
      discountToCart,
      estateForm,
      isLoading,
      missingFields,
      orderCreate,
      orderReference,
      paymentDelegate,
      paymentMethods,
      planning,
      productGroups,
      productOptions,
      products,
      productToCart,
      propertyForm,
      propertyGeo,
      step,
      user,
      validatingFields
    },
    postMissingFields,
    postValidatingFields,
    removeDiscountToCart,
    selectClientInvoiceAddress,
    selectClientPaymentMethod,
    setActiveStep,
    storeClient,
    storeEstateValue,
    storeOrderReference,
    storePaymentDelegateValue,
    storeProperty,
    storePropertyLocation,
    storePropertyValue,
    togglePaymentDelegate
  } = props;

  const classes = useStyles();
  const steps = getSteps();
  const [isOrderConfirmationOpen, setOrderConfirmationOpen] = useState(false);

  useEffect(() => {
    document.title =
      'Actarus | ' + translation().views.orders.create.page_title || 'Actarus | Create order';

    scroll.scrollToTop();

    return () => {
      clearOrderCreateReducer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    /**
     * Listen cart products number, back to service step if no products on payment
     */
    if (
      step >= 2 &&
      cart &&
      cart.data &&
      cart.data[0] &&
      (!cart.data[0].products || cart.data[0].products.length <= 0)
    ) {
      handleBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart]);

  function handleNext() {
    setActiveStep(step + 1);
  }

  function handleBack() {
    setActiveStep(step - 1);
  }

  function handleResetStep() {
    setActiveStep(0);
  }

  function handleOpenOrderConfirmation() {
    setOrderConfirmationOpen(true);
  }

  function handleCloseOrderConfirmation() {
    setOrderConfirmationOpen(false);
  }

  function handleSubmitOrder() {
    if (
      !cart ||
      !cart.data ||
      !cart.data[0] ||
      !cart.data[0].id ||
      !addresses.selected.id ||
      !paymentMethods.selected.id
    ) {
      return;
    }

    createOrder(
      user,
      cart.data[0].id,
      {
        invoiceAddressId: addresses.selected.id,
        paymentMethodId: paymentMethods.selected.id
      },
      estateForm,
      orderReference,
      paymentDelegate,
      handleCloseOrderConfirmation
    );
  }

  function isInformationsComplete() {
    if (propertyForm && propertyForm.size && propertyForm.address && user) return true;
    else return false;
  }

  function isCartEmpty() {
    if (
      cart &&
      cart.data &&
      cart.data[0] &&
      cart.data[0].products &&
      cart.data[0].products.length > 0
    )
      return false;
    else return true;
  }

  function renderSteps(step) {
    switch (step) {
      case 0:
        return (
          <PropertyStep
            cart={cart}
            deleteClientCart={deleteClientCart}
            getClientCart={getClientCart}
            getClientPaymentMethods={getClientPaymentMethods}
            paymentMethods={paymentMethods}
            propertyForm={propertyForm}
            storePropertyLocation={storePropertyLocation}
            storeClient={storeClient}
            storeProperty={storeProperty}
            storePropertyValue={storePropertyValue}
            user={user}
          />
        );
      case 1:
        return (
          <ProductsStep
            addProductToCart={addProductToCart}
            blurProductOptions={blurProductOptions}
            cart={cart}
            cancelValidatingFields={cancelValidatingFields}
            getPropertyTimezone={getPropertyTimezone}
            getProductGroups={getProductGroups}
            getProducts={getProducts}
            getClientCart={getClientCart}
            getNextProductSlots={getNextProductSlots}
            getPreviousProductSlots={getPreviousProductSlots}
            getProductOptions={getProductOptions}
            getProductSlots={getProductSlots}
            missingFields={missingFields}
            planning={planning}
            products={products}
            propertyForm={propertyForm}
            propertyGeo={propertyGeo}
            productGroups={productGroups}
            productOptions={productOptions}
            postMissingFields={postMissingFields}
            postValidatingFields={postValidatingFields}
            productToCart={productToCart}
            user={user}
            validatingFields={validatingFields}
          />
        );
      case 2:
        return (
          <PaymentStep
            addDiscountToCart={addDiscountToCart}
            addresses={addresses}
            cart={cart}
            createClientAddress={createClientAddress}
            discountToCart={discountToCart}
            estateForm={estateForm}
            getClientAddresses={getClientAddresses}
            getClientCart={getClientCart}
            orderCreate={orderCreate}
            orderReference={orderReference}
            paymentDelegate={paymentDelegate}
            paymentMethods={paymentMethods}
            propertyForm={propertyForm}
            removeDiscountToCart={removeDiscountToCart}
            selectClientInvoiceAddress={selectClientInvoiceAddress}
            selectClientPaymentMethod={selectClientPaymentMethod}
            storeEstateValue={storeEstateValue}
            storeOrderReference={storeOrderReference}
            storePaymentDelegateValue={storePaymentDelegateValue}
            togglePaymentDelegate={togglePaymentDelegate}
            user={user}
          />
        );
      default:
        return (
          <>
            <div className="sm-space" />
            <Typography variant="subtitle2" component="p" gutterBottom>
              {translation().views.orders.create.navigation.way_issue}
            </Typography>
            <div className="sm-space" />
            <Button variant="contained" onClick={handleResetStep} className={classes.button}>
              {translation().actions.back}
            </Button>
          </>
        );
    }
  }

  function renderCartSteps(step) {
    switch (step) {
      case 0:
        return (
          <Button
            variant="contained"
            color="primary"
            disabled={!isInformationsComplete()}
            onClick={handleNext}
            className={classes.button}>
            {translation().views.orders.create.navigation.next}
          </Button>
        );
      case 1:
        return (
          <Button
            variant="contained"
            color="primary"
            disabled={
              !isInformationsComplete() ||
              isCartEmpty() ||
              cart.isLoading ||
              productToCart.isLoading
            }
            onClick={handleNext}
            className={classes.button}>
            {translation().views.orders.create.navigation.next}
          </Button>
        );
      case 2:
        return (
          <Button
            variant="contained"
            color="primary"
            disabled={
              !isInformationsComplete() ||
              isCartEmpty() ||
              cart.isLoading ||
              productToCart.isLoading ||
              !addresses.selected.id ||
              !paymentMethods.selected.id ||
              (paymentDelegate && paymentDelegate.open && !paymentDelegate['delegate_email'])
            }
            onClick={handleOpenOrderConfirmation}
            className={classes.button}>
            {translation().views.orders.create.navigation.confirm}
          </Button>
        );
      default:
        return false;
    }
  }

  function renderOrderCreated(order) {
    if (!order) return;

    scroll.scrollToTop();

    const timezoneCode =
      order && order.property && order.property.timezone_code
        ? order.property.timezone_code
        : undefined;

    return (
      <Grid container className="OrderCreated">
        <Paper className={classes.orderCreatePaper} elevation={1}>
          <OrderDoneIcon className={classes.doneImage} />
          <Typography variant="h4" gutterBottom className={classes.title}>
            {translation().views.orders.create.orderCreated.title}
          </Typography>
          <Typography variant="subtitle1" gutterBottom className={classes.title}>
            {translation().views.orders.create.orderCreated.emailSent}{' '}
            <span className="medium primary">
              {order.user && order.user.email ? order.user.email : 'Missing user email.'}
            </span>
          </Typography>
          <div className="loader-wpr">
            <Button
              color="primary"
              className={classes.buttonLink}
              to={`/orders/${order.id}/update`}
              component={Link}
              variant="contained">
              {translation().views.orders.create.orderCreated.buttons.display}
            </Button>
            <Button
              color="secondary"
              className={classes.buttonLink}
              to="/orders"
              component={Link}
              variant="contained">
              {translation().views.orders.create.orderCreated.buttons.back}
            </Button>
          </div>
          <Divider variant="middle" />
          <div className="sm-space" />
          <Typography variant="subtitle2" gutterBottom className={classes.subtitle2}>
            {order.property && order.property.address
              ? order.property.address
              : 'Missing property address.'}
          </Typography>
          <div className="sm-space" />
          {order && order.products && order.products.length > 0
            ? order.products.map((product, index) => {
                return (
                  <div key={index} className="order-item">
                    <div className="item-title">{product.name}</div>
                    <div className="item-planning">
                      <Grid container>
                        {product.start ? (
                          <>
                            <Grid item xs={12} sm={4} md={4}>
                              <DateRangeIcon className="item-icon" />
                              <Typography component="p" variant="body2">
                                {dateInTz(product.start, 'localized-date')}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                              <AccessTimeIcon className="item-icon" />
                              <Typography component="p" variant="body2">
                                {dateInTz(product.start, 'HH:mm')}
                              </Typography>
                              <LocalDateInfo timezone={timezoneCode} date={product.start} />
                            </Grid>
                          </>
                        ) : (
                          false
                        )}
                        {product.duration ? (
                          <Grid item xs={12} sm={4} md={4}>
                            <TimerIcon className="item-icon" />
                            <Typography component="p" variant="body2">
                              {convertMinsToHrsMins(product.duration, 'h')}
                            </Typography>
                          </Grid>
                        ) : (
                          false
                        )}
                      </Grid>
                    </div>
                    <div className="item-product">
                      <Grid container>
                        <Grid item xs={12} sm={8} md={8}>
                          <Typography className="item-name" component="p" variant="body1">
                            {product.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                          <Typography className="item-price" component="p" variant="body1">
                            {product.amount_without_options_formatted
                              ? product.amount_without_options_formatted
                              : ''}{' '}
                            {translation().commons.pricing.ttc}
                          </Typography>
                        </Grid>
                        {product &&
                          product.options &&
                          product.options.map((option, index) => {
                            return (
                              <Grid className="item-option" container key={index}>
                                <Grid item xs={6} sm={8} md={8}>
                                  <Typography className="option-name" component="p" variant="body1">
                                    <CheckCircleOutlineIcon size="small" className="option-icon" />{' '}
                                    {option.name}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} sm={4} md={4}>
                                  <Typography
                                    className="option-price"
                                    component="p"
                                    variant="body2">
                                    {`+${option.amount_formatted} ${
                                      translation().commons.pricing.ttc
                                    }`}
                                  </Typography>
                                </Grid>
                              </Grid>
                            );
                          })}
                      </Grid>
                    </div>
                    <div className="item-total">
                      <Typography component="p" variant="h6">
                        {`${product.amount_formatted}  ${translation().commons.pricing.ttc}`}
                      </Typography>
                    </div>
                  </div>
                );
              })
            : 'Missing products.'}
          <div className="xs-space" />
          <Paper className="order-total" elevation={0} square>
            <Grid container>
              {order && order.discounts && order.discounts.length > 0 && (
                <Grid container>
                  <Grid item xs={6} className="col-text">
                    <Typography component="p" variant="body1">
                      {translation().views.orders.create.orderCreate.discounts}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} className="col-price">
                    <Typography component="p" variant="body1" className="total small">
                      {order.total_discount_formatted}
                    </Typography>
                  </Grid>
                </Grid>
              )}
              <Grid item xs={6} className="col-text">
                <Typography component="p" variant="subtitle2" className="small" gutterBottom>
                  {translation().commons.pricing.tax_excluded}
                </Typography>
              </Grid>
              <Grid item xs={6} className="col-price">
                <Typography component="p" variant="subtitle2" className="total small" gutterBottom>
                  {order.total_tax_excluded_formatted}
                </Typography>
              </Grid>
              <Grid item xs={6} className="col-text">
                <Typography component="p" variant="subtitle2" className="small" gutterBottom>
                  {translation().commons.pricing.tax_included}
                </Typography>
              </Grid>
              <Grid item xs={6} className="col-price">
                <Typography component="p" variant="subtitle2" className="small" gutterBottom>
                  {order.total_tax_formatted}
                </Typography>
              </Grid>
              <Grid item xs={6} className="col-text">
                <Typography component="p" variant="body1" className="large">
                  {translation().commons.pricing.total_formatted}
                </Typography>
              </Grid>
              <Grid item xs={6} className="col-price">
                <Typography component="p" variant="body1" className="total large">
                  {order.total_formatted}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
          <div className="sm-space" />
          <div className="loader-wpr">
            <Button
              color="primary"
              className={classes.buttonLink}
              to={`/orders/${order.id}/update`}
              component={Link}
              variant="contained">
              {translation().views.orders.create.orderCreated.buttons.display}
            </Button>
            <Button
              color="secondary"
              className={classes.buttonLink}
              to="/orders"
              component={Link}
              variant="contained">
              {translation().views.orders.create.orderCreated.buttons.back}
            </Button>
          </div>
        </Paper>
      </Grid>
    );
  }

  return (
    <div>
      <CssBaseline />
      <OrderCreateViewBar isLoading={isLoading} history={history} />
      <div className="CreateOrderView">
        <Container maxWidth="lg">
          {orderCreate && orderCreate.data && !isObjEmpty(orderCreate.data) ? (
            renderOrderCreated(orderCreate.data)
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className={classes.root}>
                  <Typography variant="h5" gutterBottom className={classes.title}>
                    {translation().views.orders.create.page_title}
                  </Typography>
                  <OrderStepper isLoading={isLoading} activeStep={step} steps={steps} />
                </div>
              </Grid>
              <div className={classes.hiddenDivider} />
              <Grid item xs={12} sm={12} md={8}>
                <Paper className={classNames(classes.root, classes.formContainer)} elevation={1}>
                  {renderSteps(step)}
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Paper className={classNames(classes.root, classes.cartContainer)} elevation={1}>
                  <OrderCart
                    {...cart}
                    deleteClientCartItem={deleteClientCartItem}
                    getClientCart={getClientCart}
                    user={user}
                  />
                  <div className={classes.buttonWrapper}>
                    <Button
                      disabled={step === 0 || productToCart.isLoading}
                      onClick={handleBack}
                      className={classes.button}>
                      {translation().views.orders.create.navigation.back}
                    </Button>
                    {renderCartSteps(step)}
                  </div>
                </Paper>
              </Grid>
            </Grid>
          )}
        </Container>
        {cart && cart.data && cart.data[0] && user && !isObjEmpty(user) && (
          <Dialog
            fullScreen
            open={isOrderConfirmationOpen}
            onClose={handleCloseOrderConfirmation}
            TransitionComponent={Transition}
            classes={{
              paper: classes.confirmDialogPaper
            }}>
            <AppBar className={classes.configmAppBar}>
              <Toolbar>
                <Typography variant="h6">
                  {translation().views.orders.create.confirmation.dialogTitle}
                </Typography>
                <IconButton
                  aria-label="close"
                  className={classes.configmCloseBtn}
                  color="inherit"
                  disabled={orderCreate.isLoading}
                  edge="start"
                  onClick={handleCloseOrderConfirmation}>
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <DialogContent className={classes.configmDialogRoot}>
              <div className={classes.confirmListRoot}>
                <Grid container spacing={3}>
                  <Grid item sm={12} md={4} lg={3}>
                    <Paper className={classes.confirmPaper}>
                      <Typography variant="h6" component="h6" className={classes.confirmPaperTitle}>
                        {translation().views.orders.create.confirmation.client}
                      </Typography>
                      <List>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar>
                              <EmailIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={translation().views.orders.create.confirmation.email}
                            secondary={user.email}
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar>
                              <AccountCircleIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={translation().views.orders.create.confirmation.last_first_name}
                            secondary={`${user.last_name} ${user.first_name}`}
                          />
                        </ListItem>
                        {user.company && user.company.commercial_name && (
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar>
                                <WorkIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={translation().views.orders.create.confirmation.company}
                              secondary={user.company.commercial_name}
                            />
                          </ListItem>
                        )}
                        {addresses.selected && addresses.selected.id && (
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar>
                                <ReceiptIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                translation().views.orders.create.confirmation.invoice_address
                              }
                              secondary={
                                (addresses.selected.name ? addresses.selected.name + ' ' : '') +
                                (addresses.selected.street_number
                                  ? addresses.selected.street_number + ' '
                                  : ' ') +
                                (addresses.selected.street
                                  ? addresses.selected.street + ' '
                                  : ' ') +
                                (addresses.selected.zip_code
                                  ? addresses.selected.zip_code + ' '
                                  : ' ') +
                                (addresses.selected.city ? addresses.selected.city + ' ' : '')
                              }
                            />
                          </ListItem>
                        )}
                        {paymentMethods.selected &&
                          paymentMethods.selected.id &&
                          paymentMethods.selected.type &&
                          paymentMethods.selected.name && (
                            <ListItem>
                              <ListItemAvatar>
                                <Avatar>
                                  <PaymentIcon />
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                primary={
                                  translation().views.orders.create.confirmation.invoice_payment
                                }
                                secondary={`${paymentMethods.selected.type} ${paymentMethods.selected.name}`}
                              />
                            </ListItem>
                          )}
                      </List>
                    </Paper>
                    {paymentDelegate && paymentDelegate['delegate_email'] && (
                      <Paper className={classes.confirmPaper}>
                        <Typography
                          variant="h6"
                          component="h6"
                          className={classes.confirmPaperTitle}>
                          {translation().views.orders.create.confirmation.delegate}
                        </Typography>
                        <List>
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar>
                                <EmailIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={translation().views.orders.create.confirmation.email}
                              secondary={paymentDelegate['delegate_email']}
                            />
                          </ListItem>
                          {paymentDelegate['delegate_amount'] && (
                            <ListItem>
                              <ListItemAvatar>
                                <Avatar>
                                  <EuroSymbol />
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                primary={
                                  translation().views.orders.create.payment.payment_delegate.amount
                                }
                                secondary={paymentDelegate['delegate_amount']}
                              />
                            </ListItem>
                          )}
                        </List>
                      </Paper>
                    )}
                    <Paper className={classes.confirmPaper}>
                      <List>
                        <Typography
                          variant="h6"
                          component="h6"
                          className={classes.confirmPaperTitle}>
                          {translation().views.orders.create.confirmation.property}
                        </Typography>
                        {cart.data[0].property_object && (
                          <>
                            {cart.data[0].property_object.address && (
                              <ListItem>
                                <ListItemAvatar>
                                  <Avatar>
                                    <LocationOnIcon />
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                  primary={
                                    translation().views.orders.create.confirmation.property_address
                                  }
                                  secondary={cart.data[0].property_object.address}
                                />
                              </ListItem>
                            )}
                            {cart.data &&
                              cart.data[0] &&
                              cart.data[0].property_timezone_code &&
                              cart.data[0].property_timezone_code !== getTimezone() && (
                                <ListItem>
                                  <ListItemAvatar>
                                    <Avatar className={classes.timezoneAvatar}>
                                      <LanguageIcon />
                                    </Avatar>
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary={translation().commons.timezone}
                                    secondary={cart.data[0].property_timezone_code}
                                    primaryTypographyProps={{
                                      className: classes.timezoneInfo
                                    }}
                                    secondaryTypographyProps={{
                                      className: classes.timezoneInfo
                                    }}
                                  />
                                </ListItem>
                              )}
                            {cart.data[0].property_object.size_formatted && (
                              <ListItem>
                                <ListItemAvatar>
                                  <Avatar>
                                    <SquareFootIcon />
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                  primary={
                                    translation().views.orders.create.confirmation.property_size
                                  }
                                  secondary={cart.data[0].property_object.size_formatted}
                                />
                              </ListItem>
                            )}
                            {cart.data[0].property_object.property_type && (
                              <ListItem>
                                <ListItemAvatar>
                                  <Avatar>
                                    <HouseIcon />
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                  primary={
                                    translation().views.orders.create.confirmation.property_type
                                  }
                                  secondary={cart.data[0].property_object.property_type}
                                />
                              </ListItem>
                            )}
                          </>
                        )}
                      </List>
                    </Paper>
                  </Grid>
                  <Grid item sm={12} md={8} lg={9}>
                    <Paper className={classes.confirmPaper}>
                      <Typography variant="h6" component="h6" className={classes.confirmPaperTitle}>
                        {translation().views.orders.create.confirmation.products}
                      </Typography>
                      {cart.data[0].products && cart.data[0].products.length ? (
                        <Paper elevation={0} className={classes.tablePaper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell className={classes.tableCell}>
                                  {
                                    translation().views.orders.create.confirmation.product_table
                                      .product
                                  }
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {
                                    translation().views.orders.create.confirmation.product_table
                                      .total_tax_excluded_before_discount_formatted
                                  }
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {
                                    translation().views.orders.create.confirmation.product_table
                                      .total_discount_formatted
                                  }
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {
                                    translation().views.orders.create.confirmation.product_table
                                      .total_tax_excluded_formatted
                                  }
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {
                                    translation().views.orders.create.confirmation.product_table
                                      .start
                                  }
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {translation().views.orders.create.confirmation.product_table.end}
                                </TableCell>
                                <TableCell className={classes.tableCell} align="right">
                                  {
                                    translation().views.orders.create.confirmation.product_table
                                      .total_formatted
                                  }
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {cart.data[0].products.map((product, idx) => (
                                <TableRow key={idx}>
                                  <TableCell className={classes.tableCell}>
                                    <Typography variant="subtitle2" gutterBottom>
                                      {product.name}
                                    </Typography>
                                    {product.options && product.options.length
                                      ? product.options.map((option, index) => (
                                          <Chip
                                            key={index}
                                            size="small"
                                            style={{ margin: 2 }}
                                            label={option.name}
                                          />
                                        ))
                                      : ''}
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    {product.price_tax_excluded_before_discount_formatted}
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    {product.price_discount_formatted}
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    {product.price_tax_excluded_formatted}
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    {product && product.start ? (
                                      <>
                                        {dateInTz(product.start, 'localized-datetime')}
                                        {cart.data &&
                                          cart.data[0] &&
                                          cart.data[0].property_timezone_code && (
                                            <LocalDateInfo
                                              timezone={cart.data[0].property_timezone_code}
                                              date={product.start}
                                            />
                                          )}
                                      </>
                                    ) : (
                                      '-'
                                    )}
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    {product && product.end ? (
                                      <>
                                        {dateInTz(product.end, 'localized-datetime')}
                                        {cart.data &&
                                          cart.data[0] &&
                                          cart.data[0].property_timezone_code && (
                                            <LocalDateInfo
                                              timezone={cart.data[0].property_timezone_code}
                                              date={product.end}
                                            />
                                          )}
                                      </>
                                    ) : (
                                      '-'
                                    )}
                                  </TableCell>
                                  <TableCell className={classes.tableCell} align="right">
                                    {product.price_formatted}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </Paper>
                      ) : (
                        false
                      )}
                    </Paper>
                    {orderCreate.isLoading && (
                      <div className="loader-wpr">
                        <CircularProgress color="primary" />
                        <p>{translation().views.orders.create.create_loading_text}</p>
                      </div>
                    )}
                  </Grid>
                </Grid>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseOrderConfirmation}
                color="default"
                disabled={orderCreate.isLoading}>
                {translation().actions.close}
              </Button>
              <Button
                color="secondary"
                variant="contained"
                disabled={orderCreate.isLoading}
                onClick={handleSubmitOrder}>
                {translation().actions.order}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    </div>
  );
}

CreateOrderView.propTypes = {
  addDiscountToCart: PropTypes.func.isRequired,
  addProductToCart: PropTypes.func.isRequired,
  blurProductOptions: PropTypes.func.isRequired,
  cancelValidatingFields: PropTypes.func.isRequired,
  clearOrderCreateReducer: PropTypes.func.isRequired,
  createClientAddress: PropTypes.func.isRequired,
  createOrder: PropTypes.func.isRequired,
  deleteClientCart: PropTypes.func.isRequired,
  deleteClientCartItem: PropTypes.func.isRequired,
  getClientPaymentMethods: PropTypes.func.isRequired,
  getClientAddresses: PropTypes.func.isRequired,
  getClientCart: PropTypes.func.isRequired,
  getProductGroups: PropTypes.func.isRequired,
  getProducts: PropTypes.func.isRequired,
  getProductOptions: PropTypes.func.isRequired,
  getProductSlots: PropTypes.func.isRequired,
  getPropertyTimezone: PropTypes.func.isRequired,
  getPreviousProductSlots: PropTypes.func.isRequired,
  getNextProductSlots: PropTypes.func.isRequired,
  history: PropTypes.shape().isRequired,
  order_create: PropTypes.shape({
    addresses: PropTypes.shape().isRequired,
    cart: PropTypes.shape().isRequired,
    discountToCart: PropTypes.shape().isRequired,
    estateForm: PropTypes.shape().isRequired,
    isLoading: PropTypes.bool,
    missingFields: PropTypes.shape().isRequired,
    orderReference: PropTypes.string,
    paymentMethods: PropTypes.shape().isRequired,
    propertyGeo: PropTypes.shape().isRequired,
    planning: PropTypes.shape().isRequired,
    productGroups: PropTypes.shape().isRequired,
    productOptions: PropTypes.shape().isRequired,
    productToCart: PropTypes.shape().isRequired,
    propertyForm: PropTypes.shape(),
    paymentDelegate: PropTypes.shape({
      open: PropTypes.bool,
      delegate_amount: PropTypes.string,
      delegate_email: PropTypes.string
    }).isRequired,
    products: PropTypes.shape(),
    orderCreate: PropTypes.shape(),
    step: PropTypes.number.isRequired,
    user: PropTypes.shape(),
    validatingFields: PropTypes.shape().isRequired
  }).isRequired,
  postMissingFields: PropTypes.func.isRequired,
  postValidatingFields: PropTypes.func.isRequired,
  selectClientInvoiceAddress: PropTypes.func.isRequired,
  selectClientPaymentMethod: PropTypes.func.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  storePropertyLocation: PropTypes.func.isRequired,
  storeClient: PropTypes.func.isRequired,
  storeEstateValue: PropTypes.func.isRequired,
  storePaymentDelegateValue: PropTypes.func.isRequired,
  storeOrderReference: PropTypes.func.isRequired,
  storeProperty: PropTypes.func.isRequired,
  storePropertyValue: PropTypes.func.isRequired,
  togglePaymentDelegate: PropTypes.func.isRequired,
  removeDiscountToCart: PropTypes.func.isRequired
};

export default CreateOrderView;
