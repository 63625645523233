const initialState = {
  data: {},
  isError: null,
  isLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_REDUCERS':
      return initialState;
    case 'GET_ORDER_PRODUCTS_UNDISPATCHED_METADATA':
      return {
        ...initialState,
        isLoading: true,
        isError: false
      };
    case 'GET_ORDER_PRODUCTS_UNDISPATCHED_METADATA_SUCCESS':
      return {
        ...state,
        data: action.data,
        isLoading: false,
        isError: false
      };
    case 'GET_ORDER_PRODUCTS_UNDISPATCHED_METADATA_ERROR':
      return {
        ...state,
        isLoading: false,
        isError: true
      };
    case 'CANCEL_GET_ORDER_PRODUCTS_UNDISPATCHED_METADATA':
      return initialState;
    default:
      return state;
  }
};
