import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import GradeIcon from '@material-ui/icons/Grade';
import Grid from '@material-ui/core/Grid';

import RatingsReceived from './RatingsReceived';
import RatingsEmitted from './RatingsEmitted';
import translation from '../../../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    minHeight: 60,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.cardHeader
  },
  cardContent: {
    overflow: 'auto',
    minHeight: 420,
    padding: theme.spacing(1),
    '&:last-child': {
      paddingBottom: 0
    },
    [theme.breakpoints.down('md')]: {
      minHeight: 'auto'
    }
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(1)
  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2)
  },
  listItemBox: {
    minWidth: 35
  },
  listItem: {
    padding: 8
  },
  linksContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    minHeight: 50,
    marginBottom: 8
  }
}));

function ProviderRatings(props) {
  const classes = useStyles();

  const {
    getProviderViewRatingsEmitted,
    getProviderViewRatingsReceived,
    cleanProviderViewRatingsReducer,
    providerId,
    ratings: { emitted, received }
  } = props;

  useEffect(() => {
    return () => {
      cleanProviderViewRatingsReducer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Card className={classes.paper}>
            <CardHeader
              className={classes.cardHeader}
              title={translation().views.provider_view.ratings.received.label}
              titleTypographyProps={{
                variant: 'h5',
                style: {
                  fontSize: 21
                }
              }}
              avatar={
                <Avatar>
                  <GradeIcon />
                </Avatar>
              }
            />
            <CardContent className={classes.cardContent}>
              <RatingsReceived
                getProviderViewRatingsReceived={getProviderViewRatingsReceived}
                received={received}
                providerId={providerId}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Card className={classes.paper}>
            <CardHeader
              className={classes.cardHeader}
              title={translation().views.provider_view.ratings.emitted.label}
              titleTypographyProps={{
                variant: 'h5',
                style: {
                  fontSize: 21
                }
              }}
              avatar={
                <Avatar>
                  <GradeIcon />
                </Avatar>
              }
            />
            <CardContent className={classes.cardContent}>
              <RatingsEmitted
                getProviderViewRatingsEmitted={getProviderViewRatingsEmitted}
                emitted={emitted}
                providerId={providerId}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

ProviderRatings.propTypes = {
  cleanProviderViewRatingsReducer: PropTypes.func.isRequired,
  getProviderViewRatingsEmitted: PropTypes.func.isRequired,
  getProviderViewRatingsReceived: PropTypes.func.isRequired,
  providerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  ratings: PropTypes.shape({
    emitted: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape()),
      error: PropTypes.string,
      isLoading: PropTypes.bool,
      paging: PropTypes.shape({
        next: PropTypes.string,
        prev: PropTypes.string
      })
    }).isRequired,
    received: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape()),
      error: PropTypes.string,
      isLoading: PropTypes.bool,
      paging: PropTypes.shape({
        next: PropTypes.string,
        prev: PropTypes.string
      })
    }).isRequired
  }).isRequired
};

export default ProviderRatings;
