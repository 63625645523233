export const cleanCompanyViewInvoicesReducer = () => ({
  type: 'CLEAN_COMPANY_VIEW_INVOICES_REDUCER'
});

export const cleanCompanyViewOrdersReducer = () => ({
  type: 'CLEAN_COMPANY_VIEW_ORDERS_REDUCER'
});

export const cleanCompanyViewInvoicesEmittedReducer = () => ({
  type: 'CLEAN_COMPANY_VIEW_INVOICES_EMITTED_REDUCER'
});

export const cleanCompanyViewPaymentMethodReducer = () => ({
  type: 'CLEAN_COMPANY_VIEW_PAYMENT_METHOD_REDUCER'
});

export const cleanCompanyViewUsersReducer = () => ({
  type: 'CLEAN_COMPANY_VIEW_USERS_REDUCER'
});

export const cleanCompanyViewAddressesReducer = () => ({
  type: 'CLEAN_COMPANY_VIEW_ADDRESSES_REDUCER'
});

export const cleanCompanyViewRatingsReducer = () => ({
  type: 'CLEAN_COMPANY_VIEW_RATINGS_REDUCER'
});

export const getCompany = () => ({
  type: 'GET_COMPANY_VIEW'
});

export const getCompanySuccess = (data) => ({
  type: 'GET_COMPANY_VIEW_SUCCESS',
  data
});

export const getCompanyError = (error) => ({
  type: 'GET_COMPANY_VIEW_ERROR',
  error
});

export const getCompanyViewInvoices = (next) => ({
  type: 'GET_COMPANY_VIEW_INVOICES',
  next
});

export const getCompanyViewInvoicesSuccess = (data, paging) => ({
  type: 'GET_COMPANY_VIEW_INVOICES_SUCCESS',
  data,
  paging
});

export const getCompanyViewInvoicesError = (error) => ({
  type: 'GET_COMPANY_VIEW_INVOICES_ERROR',
  error
});

export const getCompanyViewInvoicesEmitted = (next) => ({
  type: 'GET_COMPANY_VIEW_INVOICES_EMITTED',
  next
});

export const getCompanyViewInvoicesEmittedSuccess = (data, paging) => ({
  type: 'GET_COMPANY_VIEW_INVOICES_EMITTED_SUCCESS',
  data,
  paging
});

export const getCompanyViewInvoicesEmittedError = (error) => ({
  type: 'GET_COMPANY_VIEW_INVOICES_EMITTED_ERROR',
  error
});

export const getCompanyViewPaymentMethods = (next) => ({
  type: 'GET_COMPANY_VIEW_PAYMENT_METHODS',
  next
});

export const getCompanyViewPaymentMethodsSuccess = (data, paging) => ({
  type: 'GET_COMPANY_VIEW_PAYMENT_METHODS_SUCCESS',
  data,
  paging
});

export const getCompanyViewPaymentMethodsError = (error) => ({
  type: 'GET_COMPANY_VIEW_PAYMENT_METHODS_ERROR',
  error
});

export const getCompanyViewOrders = (next) => ({
  type: 'GET_COMPANY_VIEW_ORDERS',
  next
});

export const getCompanyViewOrdersSuccess = (data, paging) => ({
  type: 'GET_COMPANY_VIEW_ORDERS_SUCCESS',
  data,
  paging
});

export const getCompanyViewOrdersError = (error) => ({
  type: 'GET_COMPANY_VIEW_ORDERS_ERROR',
  error
});

export const getCompanyViewOrdersMetadata = () => ({
  type: 'GET_COMPANY_VIEW_ORDERS_METADATA'
});

export const getCompanyViewOrdersMetadataSuccess = (data) => ({
  type: 'GET_COMPANY_VIEW_ORDERS_METADATA_SUCCESS',
  data
});

export const getCompanyViewOrdersMetadataError = (isError) => ({
  type: 'GET_COMPANY_VIEW_ORDERS_METADATA_ERROR',
  isError
});

export const getCompanyViewUsers = (next) => ({
  type: 'GET_COMPANY_VIEW_USERS',
  next
});

export const getCompanyViewUsersSuccess = (data, paging) => ({
  type: 'GET_COMPANY_VIEW_USERS_SUCCESS',
  data,
  paging
});

export const getCompanyViewUsersError = (error) => ({
  type: 'GET_COMPANY_VIEW_USERS_ERROR',
  error
});

export const getCompanyViewAddresses = (next) => ({
  type: 'GET_COMPANY_VIEW_ADDRESSES',
  next
});

export const getCompanyViewAddressesSuccess = (data, paging) => ({
  type: 'GET_COMPANY_VIEW_ADDRESSES_SUCCESS',
  data,
  paging
});

export const getCompanyViewAddressesError = (error) => ({
  type: 'GET_COMPANY_VIEW_ADDRESSES_ERROR',
  error
});

export const getCompanyViewRatings = (next) => ({
  type: 'GET_COMPANY_VIEW_RATINGS',
  next
});

export const getCompanyViewRatingsSuccess = (data, paging) => ({
  type: 'GET_COMPANY_VIEW_RATINGS_SUCCESS',
  data,
  paging
});

export const getCompanyViewRatingsError = (error) => ({
  type: 'GET_COMPANY_VIEW_RATINGS_ERROR',
  error
});
