import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import MultipleSelectField from '../../../../components/forms/fields/customs/MultipleSelectField';
import FormSelectField from '../../../../components/forms/fields/FormSelectField';
import Planning from '../../components/planning/Planning';
import MissingFieldsForm from '../components/MissingFieldsForm';
import ValidatingFieldsForm from '../components/ValidatingFieldsForm';
import { addToDate, formatDate, getCurrentDate } from '../../../../utils/functions/dates';
import translation from '../../../../translation/translation';

const useStyles = makeStyles(() => ({
  title: {
    textAlign: 'center',
    fontSize: 18
  },
  noData: {
    textAlign: 'center',
    marginTop: 25,
    marginBottom: 20
  },
  missingFieldsWrapper: {
    marginTop: 24
  },
  btnWrapper: {
    marginTop: 24,
    width: '100%',
    textAlign: 'right'
  }
}));

function ProductsStep(props) {
  const {
    addProductToCart,
    blurProductOptions,
    cart,
    cancelValidatingFields,
    getProductGroups,
    getProducts,
    getProductOptions,
    getProductSlots,
    getNextProductSlots,
    getPreviousProductSlots,
    getPropertyTimezone,
    getClientCart,
    missingFields,
    propertyForm,
    propertyGeo,
    productGroups,
    productOptions,
    products,
    planning,
    productToCart,
    postMissingFields,
    postValidatingFields,
    user,
    validatingFields
  } = props;

  const classes = useStyles();
  const [groupSelected, setGroupSelected] = useState('');
  const [productSelected, setProductSelected] = useState('');
  const [optionsSelected, setOptionsSelected] = useState('');
  const [slotSelected, setSlotSelected] = useState(null);
  const [requirePlanning, setRequirePlanning] = useState(false);

  const isAddToCartReady =
    groupSelected && productSelected && ((requirePlanning && slotSelected) || !requirePlanning);

  useEffect(() => {
    if (propertyForm && propertyForm.address && propertyForm.size && user) {
      const property = {
        address: propertyForm.address,
        size: propertyForm.size
      };

      if (propertyForm.type) {
        property.type = propertyForm.type;
      }

      if (propertyGeo && propertyGeo.timezone_code) {
        getProductGroups(propertyForm, user);
      } else {
        const propertyLocation =
          propertyGeo &&
          propertyGeo.location &&
          propertyGeo.location.lat &&
          propertyGeo.location.lng
            ? propertyGeo.location
            : cart &&
              cart.data &&
              cart.data[0] &&
              cart.data[0].property_object &&
              cart.data[0].property_object.latitude &&
              cart.data[0].property_object.longitude
            ? {
                lat: cart.data[0].property_object.latitude,
                lng: cart.data[0].property_object.longitude
              }
            : undefined;

        if (propertyLocation) {
          getPropertyTimezone(
            propertyLocation,
            () => {
              getProductGroups(propertyForm, user);
            },
            () => {
              getProductGroups(propertyForm, user);
            }
          );
        } else {
          getProductGroups(propertyForm, user);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleGetRequiredPlanning(id) {
    if (!id || !products.data || products.data.length <= 0) return false;

    for (let i = 0; i < products.data.length; i++) {
      if (products.data[i].id && id === products.data[i].id) {
        return products.data[i].require_planning;
      }
    }

    return false;
  }

  function getOptionsIds(options) {
    const optionsIds = [];

    if (!options || options.length <= 0) return [];

    for (let i = 0; i < options.length; i++) {
      if (options[i].id) optionsIds.push(options[i].id);
    }

    return optionsIds;
  }

  function handleChangeProductGroup(id) {
    setGroupSelected(id);
    setProductSelected('');
    setSlotSelected(null);
    getProducts(propertyForm, user, id);
  }

  function handleChangeProduct(id) {
    const optionsAdvised = [];
    const isRequirePlanning = handleGetRequiredPlanning(id);

    setProductSelected(id);
    setOptionsSelected('');
    setSlotSelected(null);

    getProductOptions(
      propertyForm,
      user,
      id,
      (options) => {
        if (options && options.length > 0) {
          for (let i = 0; i < options.length; i++) {
            if (options[i].advised) {
              optionsAdvised.push(options[i]);
            }
          }

          setOptionsSelected(optionsAdvised);
        }

        if (isRequirePlanning) {
          getProductSlots(
            propertyForm,
            user,
            id,
            planning.startDate,
            planning.endDate,
            getOptionsIds(optionsAdvised),
            propertyGeo.timezone_code
          );
          setRequirePlanning(true);
        } else {
          setRequirePlanning(false);
        }
      },
      () => {
        if (isRequirePlanning) {
          getProductSlots(
            propertyForm,
            user,
            id,
            planning.startDate,
            planning.endDate,
            null,
            propertyGeo.timezone_code
          );
          setRequirePlanning(true);
        } else {
          setRequirePlanning(false);
        }
      }
    );
  }

  function handleChangeOptions(options) {
    setOptionsSelected(options);
    setSlotSelected(null);
  }

  function handleBlurOptions() {
    blurProductOptions();

    const startDate = formatDate(getCurrentDate(null, propertyGeo.timezone_code), 'YYYY-MM-DD');
    const endDate = formatDate(
      addToDate(getCurrentDate(null, propertyGeo.timezone_code), 7, 'days'),
      'YYYY-MM-DD'
    );
    const isRequirePlanning = handleGetRequiredPlanning(productSelected);

    if (isRequirePlanning) {
      getProductSlots(
        propertyForm,
        user,
        productSelected,
        startDate,
        endDate,
        getOptionsIds(optionsSelected),
        propertyGeo.timezone_code
      );
      setRequirePlanning(true);
    } else {
      setRequirePlanning(false);
    }
  }

  function handleClickNextWeek() {
    getNextProductSlots(
      propertyForm,
      user,
      productSelected,
      planning.startDate,
      planning.endDate,
      getOptionsIds(optionsSelected),
      propertyGeo.timezone_code
    );
  }

  function handleClickPreviousWeek() {
    getPreviousProductSlots(
      propertyForm,
      user,
      productSelected,
      planning.startDate,
      planning.endDate,
      getOptionsIds(optionsSelected),
      propertyGeo.timezone_code
    );
  }

  function handleChangeSlot(slot) {
    setSlotSelected(slot);
  }

  function handleAddToCart() {
    if (cart && cart.data && cart.data[0] && cart.data[0].id) {
      addProductToCart(
        cart.data[0].id,
        propertyForm,
        user,
        productSelected,
        slotSelected,
        getOptionsIds(optionsSelected),
        () => {
          setGroupSelected('');
          setProductSelected('');
          setOptionsSelected('');
          setSlotSelected(null);
          setRequirePlanning(false);

          if (user) getClientCart(user);
        }
      );
    }
  }

  function handleSubmitMissingFields(values) {
    postMissingFields(propertyForm, user, groupSelected, values);
  }

  function handleSubmitValidatingFields(values) {
    if (cart && cart.data && cart.data[0] && cart.data[0].id) {
      postValidatingFields(
        cart.data[0].id,
        propertyForm,
        values,
        user,
        productSelected,
        slotSelected,
        getOptionsIds(optionsSelected),
        () => {
          setGroupSelected('');
          setProductSelected('');
          setOptionsSelected('');
          setSlotSelected(null);
          setRequirePlanning(false);

          if (user) getClientCart(user);
        }
      );
    }
  }

  function handleCloseValidatingFieldsDialog() {
    setProductSelected('');
    setOptionsSelected('');
    cancelValidatingFields();
  }

  return (
    <div className="ProductsStep">
      <Typography variant="h5" gutterBottom className={classes.title}>
        {translation().views.orders.create.products.title}
      </Typography>
      {productGroups.isLoading && (
        <div className="loader-wpr">
          <CircularProgress size={28} color="secondary" />
          <p>{translation().views.orders.create.products.groups.loading_text}</p>
        </div>
      )}
      {!productGroups.isLoading && productGroups.data && productGroups.data.length > 0 ? (
        <FormSelectField
          displayEmpty
          id="product_groups"
          name="product_groups"
          label={translation().views.orders.create.products.groups.label}
          onChange={handleChangeProductGroup}
          value={groupSelected}
          disabled={products.isLoading || productOptions.isLoading}>
          {productGroups.data.map((group, index) => {
            return (
              <MenuItem key={index} value={group.id}>
                {group.name}
              </MenuItem>
            );
          })}
        </FormSelectField>
      ) : (
        !productGroups.isLoading && (
          <Typography variant="subtitle2" className={classes.noData}>
            {translation().views.orders.create.products.groups.empty}
          </Typography>
        )
      )}
      {groupSelected &&
      !productGroups.isLoading &&
      productGroups.data &&
      productGroups.data.length > 0 ? (
        <>
          {products.data && products.data.length > 0 ? (
            <FormSelectField
              displayEmpty
              id="products"
              name="products"
              label={translation().views.orders.create.products.product.label}
              onChange={handleChangeProduct}
              value={productSelected}
              disabled={productOptions.isLoading}>
              {products.data.map((product, index) => {
                return (
                  <MenuItem key={index} value={product.id}>
                    {product.name +
                      ' (' +
                      translation().commons.pricing.tax_excluded +
                      ': ' +
                      product.price_tax_excluded_formatted +
                      ', ' +
                      translation().commons.pricing.tax_included +
                      ': ' +
                      product.price_formatted +
                      ')'}
                  </MenuItem>
                );
              })}
            </FormSelectField>
          ) : !products.isLoading && missingFields.data.length <= 0 ? (
            <Typography variant="subtitle2" className={classes.noData}>
              {translation().views.orders.create.products.product.empty}
            </Typography>
          ) : (
            false
          )}
        </>
      ) : (
        ''
      )}
      {missingFields && missingFields.data && missingFields.data.length > 0 ? (
        <div className={classes.missingFieldsWrapper}>
          <Typography variant="h5" gutterBottom className={classes.title}>
            {translation().views.orders.create.products.missingFields.title}
          </Typography>
          <MissingFieldsForm
            fields={missingFields.data}
            buttonLabel={translation().actions.confirm}
            isFormLoading={missingFields.isLoading}
            onSubmit={handleSubmitMissingFields}
          />
        </div>
      ) : (
        false
      )}
      {products.isLoading && (
        <div className="loader-wpr">
          <CircularProgress size={28} color="secondary" />
          <p>{translation().views.orders.create.products.product.loading_text}</p>
        </div>
      )}
      {groupSelected &&
      productSelected &&
      !productOptions.isLoading &&
      productOptions.data &&
      productOptions.data.length > 0 ? (
        <>
          {productOptions.data && productOptions.data.length > 0 ? (
            <MultipleSelectField
              id="options"
              name="options"
              label={translation().views.orders.create.products.options.label}
              onChange={handleChangeOptions}
              defaultValue={optionsSelected || []}
              onBlur={handleBlurOptions}
              disabled={planning.isLoading}>
              {productOptions.data.map((option, index) => {
                return (
                  <MenuItem key={index} value={option}>
                    {option.name +
                      ' (' +
                      translation().commons.pricing.tax_excluded +
                      ': ' +
                      option.price_tax_excluded_formatted +
                      ', ' +
                      translation().commons.pricing.tax_included +
                      ': ' +
                      option.price_formatted +
                      ')'}
                  </MenuItem>
                );
              })}
            </MultipleSelectField>
          ) : (
            !productOptions.isLoading && (
              <Typography variant="subtitle2" className={classes.noData}>
                {translation().views.orders.create.products.options.empty}
              </Typography>
            )
          )}
        </>
      ) : (
        ''
      )}
      {productOptions.isLoading && (
        <div className="loader-wpr">
          <CircularProgress size={28} color="secondary" />
          <p>{translation().views.orders.create.products.options.loading_text}</p>
        </div>
      )}
      {planning.isLoading && (
        <div className="loader-wpr">
          <CircularProgress size={28} color="secondary" />
          <p>{translation().views.orders.create.products.slots.loading_text}</p>
        </div>
      )}
      {groupSelected &&
        productSelected &&
        planning.calendar &&
        !productOptions.isLoading &&
        !products.isLoading &&
        !planning.isLoading &&
        requirePlanning &&
        !productToCart.isLoading && (
          <Planning
            {...planning}
            timezone={propertyGeo.timezone_code}
            onSlotChange={handleChangeSlot}
            onClickNextWeek={handleClickNextWeek}
            onClickPreviousWeek={handleClickPreviousWeek}
          />
        )}
      {validatingFields && validatingFields.data && validatingFields.data.length > 0 ? (
        <ValidatingFieldsForm
          dialogTitle={translation().views.orders.create.products.missingFields.title}
          fields={validatingFields.data}
          submitLabel={translation().actions.confirm}
          closeLabel={translation().actions.close}
          isFormLoading={validatingFields.isLoading || productToCart.isLoading}
          loadingLabel={translation().views.orders.create.cart.add_loading_text}
          isSubmitLoading={productToCart.isLoading}
          onSubmit={handleSubmitValidatingFields}
          onClose={handleCloseValidatingFieldsDialog}
        />
      ) : (
        false
      )}
      {groupSelected && productSelected && (
        <div className={classes.btnWrapper}>
          <Button
            variant="contained"
            color="primary"
            disabled={
              !isAddToCartReady ||
              productToCart.isLoading ||
              productOptions.isLoading ||
              planning.isLoading
            }
            onClick={handleAddToCart}>
            {translation().views.orders.create.cart.add}
          </Button>
        </div>
      )}
      {productToCart.isLoading && (
        <div className="loader-wpr">
          <CircularProgress size={28} color="secondary" />
          <p>{translation().views.orders.create.cart.add_loading_text}</p>
        </div>
      )}
    </div>
  );
}

ProductsStep.propTypes = {
  addProductToCart: PropTypes.func.isRequired,
  blurProductOptions: PropTypes.func.isRequired,
  cancelValidatingFields: PropTypes.func.isRequired,
  cart: PropTypes.shape().isRequired,
  getClientCart: PropTypes.func.isRequired,
  getProductGroups: PropTypes.func.isRequired,
  getProducts: PropTypes.func.isRequired,
  getProductOptions: PropTypes.func.isRequired,
  getProductSlots: PropTypes.func.isRequired,
  getNextProductSlots: PropTypes.func.isRequired,
  getPreviousProductSlots: PropTypes.func.isRequired,
  getPropertyTimezone: PropTypes.func.isRequired,
  missingFields: PropTypes.shape().isRequired,
  propertyForm: PropTypes.shape().isRequired,
  propertyGeo: PropTypes.shape().isRequired,
  productGroups: PropTypes.shape().isRequired,
  productOptions: PropTypes.shape().isRequired,
  productToCart: PropTypes.shape().isRequired,
  products: PropTypes.shape().isRequired,
  planning: PropTypes.shape().isRequired,
  postMissingFields: PropTypes.func.isRequired,
  postValidatingFields: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.any.isRequired
  }).isRequired,
  validatingFields: PropTypes.shape().isRequired
};

export default ProductsStep;
