export const getOrderProductHostingPlans = () => ({
  type: 'GET_ORDER_PRODUCT_HOSTING_PLANS'
});

export const getOrderProductHostingPlansSuccess = (data) => ({
  type: 'GET_ORDER_PRODUCT_HOSTING_PLANS_SUCCESS',
  data
});

export const getOrderProductHostingPlansError = (error) => ({
  type: 'GET_ORDER_PRODUCT_HOSTING_PLANS_ERROR',
  error
});

export const clearOwnReducer = () => ({
  type: 'CLEAR_ORDER_PRODUCT_HOSTING_EXTENSIONS'
});

export const selectUser = (user) => ({
  type: 'SELECT_USER_HOSTING_EXTENSIONS',
  user
});

export const selectPlan = (plan) => ({
  type: 'SELECT_PLAN_HOSTING_EXTENSIONS',
  plan
});

export const selectAddress = (address) => ({
  type: 'SELECT_ADDRESS_HOSTING_EXTENSIONS',
  address
});

export const selectPaymentMethod = (method) => ({
  type: 'SELECT_PAYMENT_METHOD_HOSTING_EXTENSIONS',
  method
});

export const getHostingExtensionsClientPaymentMethods = () => ({
  type: 'GET_HOSTING_EXTENSIONS_CLIENT_PAYMENT_METHODS'
});

export const getHostingExtensionsClientPaymentMethodsSuccess = (data) => ({
  type: 'GET_HOSTING_EXTENSIONS_CLIENT_PAYMENT_METHODS_SUCCESS',
  data
});

export const getHostingExtensionsClientPaymentMethodsError = (error) => ({
  type: 'GET_HOSTING_EXTENSIONS_CLIENT_PAYMENT_METHODS_ERROR',
  error
});

export const getHostingExtensionsAddresses = () => ({
  type: 'GET_HOSTING_EXTENSIONS_CLIENT_ADDRESSES'
});

export const getHostingExtensionsAddressesSuccess = (data) => ({
  type: 'GET_HOSTING_EXTENSIONS_CLIENT_ADDRESSES_SUCCESS',
  data
});

export const getHostingExtensionsAddressesError = (error) => ({
  type: 'GET_HOSTING_EXTENSIONS_CLIENT_ADDRESSES_ERROR',
  error
});

export const createHostingExtension = () => ({
  type: 'CREATE_HOSTING_EXTENSION'
});

export const createHostingExtensionSuccess = (data) => ({
  type: 'CREATE_HOSTING_EXTENSION_SUCCESS',
  data
});

export const createHostingExtensionError = (error) => ({
  type: 'CREATE_HOSTING_EXTENSION_ERROR',
  error
});
