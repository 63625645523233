import React, { useState, useEffect, PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Alert from '../../../../../components/alert/Alert';
import { dateInTz } from '../../../../../utils/functions/dates';
import translation from '../../../../../translation/translation';
import config from '../../../../../config';

class CustomHref extends PureComponent {
  render() {
    return <a {...this.props}>{this.props.children}</a>;
  }
}

CustomHref.propTypes = {
  children: PropTypes.any
};

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    maxHeight: 380
  },
  loadingWrapper: {
    width: '100%',
    height: 350,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      height: 'auto'
    }
  }
}));

const createCancelToken = () => axios.CancelToken.source();

const GetDataApi = (getData, companyId) => {
  const [source] = useState(createCancelToken());

  useEffect(() => {
    getData(companyId, source);

    return () => {
      source.cancel('=> Cancel get company users.');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const GetNextDataApi = (getData, url) => {
  const [source] = useState(createCancelToken());

  const doNext = (initialNext) => {
    if (initialNext) getData(url, source, initialNext);
  };

  useEffect(() => {
    return () => {
      source.cancel('=> Next: Cancelled.');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { doNext };
};

function Users(props) {
  const classes = useStyles();

  const columns = ['id', 'first_name', 'last_name', 'mobile', 'email', 'company_admin', 'created'];

  const { companyId, users, getCompanyViewUsers, cleanCompanyViewUsersReducer } = props;

  useEffect(() => {
    return () => {
      cleanCompanyViewUsersReducer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  GetDataApi(getCompanyViewUsers, companyId);

  const { doNext } = GetNextDataApi(getCompanyViewUsers, companyId);

  function handleInfiniteScroll(event) {
    if (!event) console.error('No table scroll event found.');

    if (!event.currentTarget.scrollTop || event.currentTarget.scrollTop === 0) return;

    if (
      event.currentTarget.scrollHeight - event.currentTarget.clientHeight <=
      event.currentTarget.scrollTop
    ) {
      if (users && users.paging && users.paging.next) {
        doNext(users.paging.next);
      }
    }
  }

  return (
    <div className="CompanyUsers">
      {!users.isLoading && users.error && (!users.data || users.data.length <= 0) ? (
        <div className="error-list">
          <Alert
            type="danger"
            style={{ marginTop: 10 }}
            status={translation().commons.alerts.error}
            text={translation().core.list.error_list}
          />
        </div>
      ) : (
        false
      )}
      {users.data && users.data.length > 0 ? (
        <TableContainer onScroll={handleInfiniteScroll} className={classes.tableContainer}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column}>{column}</TableCell>
                ))}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {users.data.map((user, index) => (
                <TableRow key={index}>
                  <TableCell>{user.id}</TableCell>
                  <TableCell>{user.first_name ? user.first_name : '_'}</TableCell>
                  <TableCell>{user.last_name ? user.last_name : '_'}</TableCell>
                  <TableCell>{user.mobile ? user.mobile : '-'}</TableCell>
                  <TableCell>{user.email ? user.email : '-'}</TableCell>
                  <TableCell>
                    {user.company_admin ? (
                      <DoneIcon
                        style={{ color: config.success_color || '#4CAF50' }}
                        fontSize="small"
                      />
                    ) : (
                      <CloseIcon
                        style={{ color: config.error_color || '#F44336' }}
                        fontSize="small"
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    {user.created ? dateInTz(user.created, 'localized-datetime') : '-'}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="update user"
                      component={CustomHref}
                      target="_blank"
                      size="small"
                      href={`/users/${user.id}/update`}>
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : !users.isLoading && !users.error ? (
        <Alert
          type="info"
          style={{ marginTop: 10 }}
          status={translation().commons.alerts.info}
          text={translation().views.company_view.users.empty}
        />
      ) : (
        false
      )}
      {users.isLoading && !users.paging && (
        <div className={classNames(classes.loadingWrapper, 'loader-wpr')}>
          <div style={{ width: '100%' }}>
            <CircularProgress color="primary" />
            <p>{translation().views.company_view.users.loading_text}</p>
          </div>
        </div>
      )}
      {users.isLoading && users.paging && users.paging.next ? (
        <>
          <LinearProgress color="primary" />
          <div className="loader-wpr linear">
            <p>{translation().core.list.load_next}</p>
          </div>
        </>
      ) : (
        <div className="end-of-list-wpr">
          {!users.isLoading && users.paging && !users.paging.next && users.paging.prev && (
            <p>{translation().core.list.load_next_done}</p>
          )}
        </div>
      )}
    </div>
  );
}

Users.propTypes = {
  cleanCompanyViewUsersReducer: PropTypes.func.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  getCompanyViewUsers: PropTypes.func.isRequired,
  users: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape()),
    error: PropTypes.string,
    isLoading: PropTypes.bool,
    paging: PropTypes.shape({
      next: PropTypes.string,
      prev: PropTypes.string
    })
  }).isRequired
};

export default Users;
