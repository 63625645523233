import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

import { alterDate, dateInTz, getCurrentDateUtc } from '../../../utils/functions/dates';
import { getTimezone } from '../../../utils/functions/cookies';

const styles = (theme) => ({
  input: {
    color: theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.54)'
  }
});

function FormTimeField(props) {
  const {
    autoComplete,
    classes,
    defaultValue,
    disabled,
    error,
    helperText,
    id,
    fullWidth,
    label,
    margin,
    name,
    onChange,
    required,
    timezone,
    write
  } = props;

  const [localValue, setLocalValue] = useState('');

  useEffect(() => {
    if (defaultValue && timezone) {
      const defaultValueSplitted = defaultValue ? defaultValue.split(':') : null;
      let displayValue = null;
      let hour = null;
      let mins = null;
      let secs = null;

      if (defaultValueSplitted && defaultValueSplitted.length > 0) {
        hour = defaultValueSplitted[0];
        mins = defaultValueSplitted[1];
        secs = defaultValueSplitted[2];
      }

      if (hour && mins) {
        displayValue = alterDate(getCurrentDateUtc(), null, null, null, hour, mins, secs);
      }

      setLocalValue(dateInTz(displayValue, 'HH:mm:00', timezone).toString());
    }
  }, [defaultValue, timezone]);

  function handleChange(event) {
    setLocalValue(event.target.value);

    if (event && onChange) {
      onChange(event.target.value);
    }
  }

  return (
    <TextField
      id={id}
      autoComplete={autoComplete}
      value={localValue}
      disabled={!write || disabled}
      helperText={error || helperText}
      margin={margin}
      name={name}
      label={
        timezone ? (
          <span>
            {label} <i>({timezone})</i>
          </span>
        ) : (
          label
        )
      }
      type="time"
      required={required}
      error={error ? true : false}
      onChange={handleChange}
      fullWidth={fullWidth}
      InputLabelProps={{
        shrink: true
      }}
      InputProps={{
        classes: { input: localValue ? null : classes.input }
      }}
    />
  );
}

FormTimeField.defaultProps = {
  fullWidth: true,
  margin: 'normal',
  timezone: getTimezone(),
  write: true
};

FormTimeField.propTypes = {
  autoComplete: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  classes: PropTypes.shape(),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  margin: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  label: PropTypes.string,
  timezone: PropTypes.string,
  required: PropTypes.bool,
  write: PropTypes.bool
};

export default withStyles(styles)(FormTimeField);
