import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchApi } from '../../../../utils/functions/api';
import { enqueueSnackbar } from '../../../../redux/actions/appActions';
import {
  deleteDashboard,
  deleteDashboardSuccess,
  deleteDashboardError,
  getDashboards,
  getDashboardsSuccess,
  getDashboardsError,
  updateDashboard,
  updateDashboardSuccess,
  updateDashboardError,
  refreshDashboards
} from '../../../../redux/actions/metrics/dashboard/list/listDashboardMetricsActions';
import {
  createDashboard,
  createDashboardSuccess,
  createDashboardError
} from '../../../../redux/actions/metrics/dashboard/create/createDashboardMetricsActions';
import ListDashboardMetrics from '../../../../views/metrics/dashboard/list/ListDashboardMetrics';
import translation from '../../../../translation/translation';

const mapStateToProps = (store) => ({
  list: store.metrics.dashboard.list,
  create: store.metrics.dashboard.create
});

const mapDispatchToProps = (dispatch) => {
  return {
    getDashboards: (next = null, isRefresh = false) => {
      isRefresh ? dispatch(refreshDashboards()) : dispatch(getDashboards(next));

      fetchApi(
        'get',
        next ? next : `users/self/dashboards`,
        null,
        null,
        null,
        (data, paging) => {
          dispatch(getDashboardsSuccess(data, paging));
        },
        (error) => {
          dispatch(getDashboardsError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.callbacks.error_list,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().core.list.callbacks.error_list,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    },
    createDashboard: (data = null, callbackSuccess = null, callbackError = null) => {
      if (!data) return;

      dispatch(createDashboard());

      fetchApi(
        'post',
        `users/self/dashboards`,
        null,
        data,
        null,
        (success) => {
          dispatch(createDashboardSuccess(success));

          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success);
          }
        },
        (error) => {
          dispatch(createDashboardError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().metrics.dashboard.create.callbacks.error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().metrics.dashboard.create.callbacks.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError(error);
          }
        }
      );
    },
    updateDashboard: (
      dashboardId = null,
      data = null,
      callbackSuccess = null,
      callbackError = null
    ) => {
      if (!dashboardId || !data) return;

      dispatch(updateDashboard());

      fetchApi(
        'put',
        `users/self/dashboards/${dashboardId}`,
        null,
        data,
        null,
        (success) => {
          dispatch(updateDashboardSuccess(success));

          dispatch(
            enqueueSnackbar({
              message: translation().metrics.dashboard.update.callbacks.success,
              options: {
                variant: 'success'
              }
            })
          );

          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success);
          }
        },
        (error) => {
          dispatch(updateDashboardError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().metrics.dashboard.update.callbacks.error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().metrics.dashboard.update.callbacks.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError(error);
          }
        }
      );
    },
    deleteDashboard: (dashboardId = null, callbackSuccess = null, callbackError = null) => {
      if (!dashboardId) return;

      dispatch(deleteDashboard());

      fetchApi(
        'delete',
        `users/self/dashboards/${dashboardId}`,
        null,
        null,
        null,
        (success) => {
          dispatch(deleteDashboardSuccess(dashboardId));

          dispatch(
            enqueueSnackbar({
              message: translation().metrics.dashboard.delete.callbacks.success,
              options: {
                variant: 'success'
              }
            })
          );

          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success);
          }
        },
        (error) => {
          dispatch(deleteDashboardError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().metrics.dashboard.delete.callbacks.error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().metrics.dashboard.delete.callbacks.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError(error);
          }
        }
      );
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListDashboardMetrics));
