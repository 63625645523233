function getUserNameWithLevel(user, level = null, levels = {}, needRole = false) {
  return user && user.first_name && user.last_name
    ? `${user.first_name} ${user.last_name} ${
        level
          ? needRole && levels && levels[level].description
            ? levels[level].description
            : ''
          : ''
      }`
    : user.user && user.user.first_name
    ? `${user.user.first_name}} ${
        level ? (needRole && levels[level].description ? levels[level].description : '') : ''
      }`
    : '';
}

function getUserName(user) {
  return user && user.first_name && user.last_name
    ? `${user.first_name} ${user.last_name}`
    : user && user.first_name
    ? user.first_name
    : '';
}

function getUserInitials(user) {
  return user && user.first_name && user.last_name
    ? `${user.first_name.charAt(0)}${user.last_name.charAt(0)}`
    : user && user.first_name
    ? `${user.first_name.charAt(0)}`
    : '';
}

export { getUserName, getUserNameWithLevel, getUserInitials };
