import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

import FormSelectField from '../../../../components/forms/fields/FormSelectField';
import Alert from '../../../../components/alert/Alert';
import translation from '../../../../translation/translation';

const useStyles = makeStyles(() => ({
  laoderWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  textLoader: {
    marginLeft: 8
  }
}));

function HostingPlansStep(props) {
  const classes = useStyles();

  const {
    getOrderProductHostingPlans,
    orderId,
    orderProductId,
    onSelect,
    plans: { data, error, isLoading, selected }
  } = props;

  useEffect(() => {
    getOrderProductHostingPlans(orderId, orderProductId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleChange(value) {
    onSelect(value);
  }

  return (
    <div>
      {isLoading && (
        <div className={classes.laoderWrapper}>
          <CircularProgress color="primary" size={30} />
          <Typography color="textSecondary" className={classes.textLoader}>
            {translation().views.order_products.hosting_extensions.create.loading_text}
          </Typography>
        </div>
      )}
      {!isLoading && data && data.length > 0 ? (
        <FormSelectField
          displayEmpty
          id="hosting_plans"
          name="hosting_plans"
          label={translation().views.order_products.hosting_extensions.create.select_label}
          onChange={handleChange}
          value={selected}>
          {data.map((plan, index) => {
            return (
              <MenuItem key={index} value={plan.id}>
                {`${plan.nb_months} ${
                  translation().views.order_products.hosting_extensions.create.months
                } (${translation().commons.pricing.tax_excluded}: ${
                  plan.price_tax_excluded_formatted
                }, ${translation().commons.pricing.tax_included}: ${plan.price_formatted})`}
              </MenuItem>
            );
          })}
        </FormSelectField>
      ) : (
        false
      )}
      {!isLoading && error && (!data || data.length <= 0) ? (
        <Alert
          type="danger"
          style={{ marginTop: 10 }}
          status={translation().commons.alerts.error}
          text={error}
        />
      ) : (
        false
      )}
      {!isLoading && !error && (!data || data.length <= 0) ? (
        <Alert
          type="info"
          style={{ marginTop: 10 }}
          status={translation().commons.alerts.info}
          text={translation().views.order_products.hosting_extensions.create.empty}
        />
      ) : (
        false
      )}
    </div>
  );
}

HostingPlansStep.propTypes = {
  getOrderProductHostingPlans: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  orderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  orderProductId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  plans: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape()),
    error: PropTypes.string,
    isLoading: PropTypes.bool,
    selected: PropTypes.any
  }).isRequired
};

export default HostingPlansStep;
