import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import LocalDateInfo from '../../../../../../components/timezone/LocalDateInfo';
import FormDateTimeField from '../../../../../../components/forms/fields/FormDateTimeField';
import Planning from '../../../../components/planning/Planning';
import PlanningUnavailableIcon from '../../../../../../components/icons/PlanningUnavailableIcon';
import { createDate, dateInTz, toUtcTimestamp } from '../../../../../../utils/functions/dates';
import translation from '../../../../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  currentDateText: {
    display: 'flex',
    fontWeight: 500
  },
  currentDateTitle: {
    display: 'flex',
    marginRight: 4
  },
  currentDateWpr: {
    display: 'flex',
    alignItems: 'center'
  },
  forceButton: {
    marginRight: 'auto'
  },
  forceWrapper: {
    backgroundColor: 'rgba(244, 67, 54, 0.2)',
    borderLeft: '5px solid #F44336',
    padding: theme.spacing(2, 1)
  },
  planningError: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

function DateStartButton(props) {
  const {
    className,
    clearOrderUpdatePlanning,
    currentStartDate,
    getOrderProductSlots,
    getNextOrderProductSlots,
    getPreviousOrderProductSlots,
    orderId,
    orderProductId,
    options,
    productPlanning,
    reloadOrder,
    timezone,
    updateOrderProductStartDate
  } = props;

  const classes = useStyles();
  const [isForcing, setIsForcing] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(null);

  function getOptionsIds(options) {
    if (Array.isArray(options)) {
      const optionsIds = [];

      if (!options || options.length <= 0) return [];

      for (let i = 0; i < options.length; i++) {
        if (options[i].product_option_id) optionsIds.push(options[i].product_option_id);
      }

      return optionsIds;
    } else {
      return false;
    }
  }

  function handleOpenDialog() {
    setValue(null);
    setIsOpen(true);
    setIsForcing(false);
  }

  function handleCloseDialog() {
    clearOrderUpdatePlanning();
    setIsOpen(false);
  }

  function handleChangeSlot(date) {
    const slotSelected = createDate(date, timezone);
    const slotSelectedUTC = toUtcTimestamp(slotSelected);

    setValue(slotSelectedUTC);
  }

  function handleEnterDialog() {
    getOrderProductSlots(
      orderId,
      orderProductId,
      getOptionsIds(options),
      productPlanning.startDate,
      productPlanning.endDate,
      timezone
    );
  }

  function handleClickNextWeek() {
    getNextOrderProductSlots(
      orderId,
      orderProductId,
      getOptionsIds(options),
      productPlanning.startDate,
      productPlanning.endDate,
      timezone
    );
  }

  function handleClickPreviousWeek() {
    getPreviousOrderProductSlots(
      orderId,
      orderProductId,
      getOptionsIds(options),
      productPlanning.startDate,
      productPlanning.endDate,
      timezone
    );
  }

  function handleSubmit() {
    updateOrderProductStartDate(orderId, orderProductId, value, isForcing, () => {
      clearOrderUpdatePlanning();
      reloadOrder();
    });
  }

  function handleToggleForcing() {
    setValue(null);
    setIsForcing(!isForcing);
  }

  return (
    <>
      <Tooltip
        title={translation().views.orders.update.products.product_planning.title}
        enterDelay={500}>
        <IconButton aria-label="event-date" className={className} onClick={handleOpenDialog}>
          <DateRangeIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        fullWidth
        maxWidth="md"
        open={isOpen ? true : false}
        onClose={handleCloseDialog}
        aria-labelledby="update-date-start-order-product"
        aria-describedby="update-date-start-order-product-description"
        disableBackdropClick={
          productPlanning.isLoading ||
          productPlanning.isWeekLoading ||
          productPlanning.isSendLoading
        }
        onEnter={handleEnterDialog}>
        <DialogTitle id="update-date-start-order-product">
          {translation().views.orders.update.products.product_planning.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="update-date-start-order-product-description" component="div">
            <div className={classes.currentDateWpr}>
              <Typography className={classes.currentDateTitle}>
                {translation().views.orders.update.products.product_planning.current}:
              </Typography>
              <Typography className={classes.currentDateText}>
                {dateInTz(currentStartDate, 'localized-long-datetime')}
              </Typography>
            </div>
            <LocalDateInfo
              timezone={timezone}
              date={currentStartDate}
              className={classes.currentDateWpr}
            />
            {translation().views.orders.update.products.product_planning.description}
          </DialogContentText>
          {isForcing ? (
            <div className={classes.forceWrapper}>
              <Typography gutterBottom>
                {translation().views.orders.update.products.product_planning.disclaimer}
              </Typography>
              <FormDateTimeField
                name="update_order_product_start"
                label={
                  translation().views.orders.update.products.product_planning.input_placeholder
                }
                write
                onChange={handleChangeSlot}
                disabled={productPlanning.isSendLoading}
                required
              />
            </div>
          ) : (
            <>
              {!productPlanning.isLoading && productPlanning.calendar && (
                <Planning
                  {...productPlanning}
                  timezone={timezone}
                  onSlotChange={handleChangeSlot}
                  onClickNextWeek={handleClickNextWeek}
                  onClickPreviousWeek={handleClickPreviousWeek}
                  disabled={productPlanning.isSendLoading}
                />
              )}
              {productPlanning.error && !productPlanning.calendar && (
                <div className={classes.planningError}>
                  <PlanningUnavailableIcon height={70} width={70} />
                </div>
              )}
            </>
          )}
          <div className="sm-space" />
          {productPlanning.isLoading && (
            <div className="loader-wpr" style={{ marginTop: 15 }}>
              <CircularProgress size={30} color="secondary" />
              <p>{translation().views.orders.update.products.product_planning.loading_text}</p>
            </div>
          )}
          {productPlanning.isSendLoading && (
            <div className="loader-wpr" style={{ marginTop: 15 }}>
              <CircularProgress size={30} color="secondary" />
              <p>{translation().views.orders.update.products.product_planning.sending_text}</p>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleToggleForcing}
            variant="contained"
            size="small"
            className={classes.forceButton}
            disabled={
              productPlanning.isLoading ||
              productPlanning.isWeekLoading ||
              productPlanning.isSendLoading
            }>
            {isForcing
              ? translation().views.orders.update.products.product_provider.unforce
              : translation().views.orders.update.products.product_provider.force}
          </Button>
          <Button
            onClick={handleCloseDialog}
            color="default"
            disabled={
              productPlanning.isLoading ||
              productPlanning.isWeekLoading ||
              productPlanning.isSendLoading
            }>
            {translation().actions.cancel}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={
              productPlanning.isLoading ||
              productPlanning.isWeekLoading ||
              productPlanning.isSendLoading ||
              !value
            }>
            {translation().actions.confirm}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

DateStartButton.propTypes = {
  className: PropTypes.string.isRequired,
  clearOrderUpdatePlanning: PropTypes.func.isRequired,
  currentStartDate: PropTypes.any.isRequired,
  getOrderProductSlots: PropTypes.func.isRequired,
  getNextOrderProductSlots: PropTypes.func.isRequired,
  getPreviousOrderProductSlots: PropTypes.func.isRequired,
  options: PropTypes.any,
  orderId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  orderProductId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  productPlanning: PropTypes.shape().isRequired,
  reloadOrder: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
  updateOrderProductStartDate: PropTypes.func.isRequired
};

export default DateStartButton;
