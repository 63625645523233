/* eslint-disable react/jsx-max-props-per-line */
import './home.scss';

import React, { useEffect, useState, PureComponent } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import CountUp from 'react-countup';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import MuiLink from '@material-ui/core/Link';

import translation from '../../translation/translation';

const createCancelToken = () => axios.CancelToken.source();

class HomeLink extends PureComponent {
  render() {
    return <Link {...this.props}>{this.props.children}</Link>;
  }
}

HomeLink.propTypes = {
  children: PropTypes.any
};

function CountUpMetric(props) {
  const { calcMode, countProps, getMetric, interval, label, route } = props;

  const [number, setNumber] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [source] = useState(createCancelToken());

  useEffect(() => {
    if (route) {
      setLoading(true);

      getMetric(
        route,
        { time_interval: interval },
        source,
        (success) => {
          setNumber(calcMetricNumber(success, calcMode));
          setLoading(false);
        },
        () => {
          setLoading(false);
        }
      );
    }

    return () => {
      source.cancel('=> Metrics countup.');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function calcMetricNumber(metric, type = 'count') {
    if (metric && metric.values && metric.values.length > 0) {
      let sum = 0;
      let j = 0;

      for (let i = 0; i < metric.values.length; i++) {
        sum += parseFloat(metric.values[i], 10);

        if (parseFloat(metric.values[i], 10) > 0) {
          j++;
        }
      }

      if (type.toLowerCase() === 'count') {
        return sum.toFixed(2);
      } else if (type.toLowerCase() === 'avg') {
        if (sum) {
          if (j > 0) {
            return (sum / j).toFixed(2);
          } else {
            return (sum / 1).toFixed(2);
          }
        } else {
          return 0;
        }
      }
    } else {
      return 0;
    }
  }

  return (
    <Grid className="home-metric" item xs={12} sm={6} md={3}>
      {isLoading && <Skeleton height={54} variant="rect" />}
      {!isLoading && (
        <div>
          <CountUp
            start={0}
            end={number ? parseFloat(number, 10) : 0}
            className="home-metric-countup"
            decimal={number ? countProps && countProps.decimal : null}
            decimals={number ? countProps && countProps.decimals : null}
          />
        </div>
      )}
      {isLoading ? (
        <div>
          <div className="xs-space" />
          <Skeleton variant="rect" height={18} />
        </div>
      ) : (
        <Typography variant="overline">{label}</Typography>
      )}
    </Grid>
  );
}

CountUpMetric.propTypes = {
  calcMode: PropTypes.string,
  countProps: PropTypes.shape(),
  interval: PropTypes.string,
  getMetric: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired
};

function Home(props) {
  const { getMetric, user } = props;

  useEffect(() => {
    document.title = 'Actarus | Home';
  }, []);

  return (
    <div className="Home">
      <Container>
        <Grid container spacing={2} alignItems="center">
          <Grid item md={6}>
            <Typography gutterBottom component="h2" variant="overline">
              {translation().views.home.page_title}
            </Typography>
            <Typography gutterBottom component="h1" variant="h4" className="home-title">
              {translation().views.home.hello},{' '}
              {user.data && user.data.first_name ? user.data.first_name : ''}
            </Typography>
            <Typography gutterBottom>{translation().views.home.today}</Typography>
            <Typography>
              {translation().views.home.check_update}{' '}
              <MuiLink component={HomeLink} to="/whats-hot">
                {translation().views.home.update_link}
              </MuiLink>
              .
            </Typography>
          </Grid>
          <Grid item md={6}>
            <svg
              id="home-svg"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              width="747.75751"
              height="668.84054"
              viewBox="0 0 747.75751 668.84054"
              className="home-laptop-img">
              <path
                d="M927.17065,245.86427c26.72006,38.98517,20.80717,89.48769,20.80717,89.48769s-49.23467-12.7049-75.95473-51.69006-20.80718-89.48769-20.80718-89.48769S900.45058,206.87911,927.17065,245.86427Z"
                transform="translate(-227.12124 -115.57973)"
                fill="#2f2e41"
              />
              <path
                d="M907.945,253.30828c3.12667,47.15961,39.01681,83.17856,39.01681,83.17856S977.78266,296.045,974.656,248.88536s-39.01682-83.17856-39.01682-83.17856S904.81836,206.14867,907.945,253.30828Z"
                transform="translate(-227.12124 -115.57973)"
                fill="#6c63ff"
              />
              <path
                d="M601.16721,116.56176,963.94182,298.9512a17.15473,17.15473,0,0,1,9.41008,16.48217L952.27481,627.63773a8.49961,8.49961,0,0,1-12.31475,7.013L568.91284,445.287,587.962,124.17251A9.1232,9.1232,0,0,1,601.16721,116.56176Z"
                transform="translate(-227.12124 -115.57973)"
                fill="#d0cde1"
              />
              <path
                d="M605.34247,122.92382l353.86394,177.9095a16.73337,16.73337,0,0,1,9.17895,16.07732L947.826,621.44648a8.29085,8.29085,0,0,1-12.01228,6.84077L573.88034,443.57476l18.58127-313.22712A8.89911,8.89911,0,0,1,605.34247,122.92382Z"
                transform="translate(-227.12124 -115.57973)"
                fill="#3f3d56"
              />
              <path
                d="M620.85055,146.55434,941.61776,307.824a15.1683,15.1683,0,0,1,8.32044,14.57362L931.30173,598.45039a7.5154,7.5154,0,0,1-10.88877,6.201L592.33107,437.21494l16.84337-283.93113A8.06678,8.06678,0,0,1,620.85055,146.55434Z"
                transform="translate(-227.12124 -115.57973)"
                fill="#fff"
              />
              <path
                d="M289.86708,598.9106,653.105,781.84248a24.18508,24.18508,0,0,0,21.78584-.02841L943.60452,645.40645c3.15077-3.15087,2.71071-7.82029,1.70307-11.326a25.229,25.229,0,0,0-1.98686-4.89647l-36.61767-19.25973L598.83151,456.31491l-18.39407-9.7647a34.05929,34.05929,0,0,0-31.63579-.12777L289.75342,580.985a10.05764,10.05764,0,0,0,.11366,17.9256Z"
                transform="translate(-227.12124 -115.57973)"
                fill="#d0cde1"
              />
              <path
                d="M289.86708,598.9106,653.105,781.84248a24.18508,24.18508,0,0,0,21.78584-.02841L943.60452,645.40645c3.15077-3.15087,2.71071-7.82029,1.70307-11.326L677.39709,773.334a23.37,23.37,0,0,1-21.19939.18164L284.4453,588.30848A10.01705,10.01705,0,0,0,289.86708,598.9106Z"
                transform="translate(-227.12124 -115.57973)"
                opacity="0.1"
              />
              <path
                d="M634.63782,653.72083,517.00265,594.27572a16.52947,16.52947,0,0,0-15.244.1735l-68.24076,36.45739a4.88152,4.88152,0,0,0,.10227,8.66427l119.941,60.48308a13.586,13.586,0,0,0,12.78587-.29411L634.86,661.161A4.20291,4.20291,0,0,0,634.63782,653.72083Z"
                transform="translate(-227.12124 -115.57973)"
                opacity="0.1"
              />
              <path
                d="M911.68665,643.779l-126.5861,67.24886a10.2967,10.2967,0,0,1-9.47746.09542l-357.66015-180.87a6.08239,6.08239,0,0,1,.04678-10.879l123.6145-61.18293a13.40423,13.40423,0,0,1,11.97733.04264L911.631,638.62577A2.90134,2.90134,0,0,1,911.68665,643.779Z"
                transform="translate(-227.12124 -115.57973)"
                opacity="0.1"
              />
              <rect
                x="766.90324"
                y="273.07164"
                width="1.41945"
                height="130.11848"
                transform="translate(-105.22868 757.90833) rotate(-63.43495)"
                fill="#6c63ff"
              />
              <rect
                x="766.90383"
                y="290.49228"
                width="1.41827"
                height="130.75937"
                transform="translate(-125.90958 761.62113) rotate(-62.90908)"
                fill="#6c63ff"
              />
              <rect
                x="763.355"
                y="338.2919"
                width="1.41951"
                height="74.90016"
                transform="translate(-145.30545 769.58676) rotate(-62.93456)"
                fill="#6c63ff"
              />
              <path
                d="M366.64816,429.1266s-28.38045-12.36653-41.71067.69684S360.62806,438.58675,366.64816,429.1266Z"
                transform="translate(-227.12124 -115.57973)"
                fill="#3f3d56"
              />
              <path
                d="M317.6426,370.47769C327.18,354.36241,346.049,353.61137,346.049,353.61137s18.38679-2.35129,30.18179,22.19249c10.99389,22.87681,26.16686,44.96485,2.44273,50.32014l-4.28527-13.33766L371.73444,427.117a92.82684,92.82684,0,0,1-10.15067.17348c-25.40663-.82028-49.6026.24-48.82352-8.877C313.7959,406.29377,308.46569,385.98382,317.6426,370.47769Z"
                transform="translate(-227.12124 -115.57973)"
                fill="#2f2e41"
              />
              <path
                d="M304.29716,662.62032s12.0402,22.36036,19.78032,22.36036,6.88011,14.62023,5.16008,18.06029-6.02009,11.18018.86,22.36036c2.40321,3.90523,1.97331,8.33511.39629,12.44665-4.41861,11.52-21.60692,8.02981-21.05858-4.2963q.00908-.20384.022-.41023c.86-13.76022-6.0201-35.26056-12.90021-40.42064s-12.90021-18.92031-12.90021-18.92031Z"
                transform="translate(-227.12124 -115.57973)"
                fill="#ffb8b8"
              />
              <path
                d="M386.85848,657.46024s-12.04019,22.36036-19.78031,22.36036-6.88011,14.62023-5.16009,18.06029,6.0201,11.18018-.86,22.36035c-2.40322,3.90524-1.97332,8.33512-.39629,12.44666,4.41861,11.52,21.60692,8.02981,21.05857-4.2963q-.00906-.20384-.022-.41023c-.86-13.76022,6.02009-35.26056,12.9002-40.42065s12.90021-18.9203,12.90021-18.9203Z"
                transform="translate(-227.12124 -115.57973)"
                fill="#ffb8b8"
              />
              <path
                d="M377.39833,547.37849l5.16008,11.18017s75.68121,24.9404,68.8011,54.18087-40.42064,74.82119-45.58073,73.10117-32.68052-6.88011-33.54053-21.50035,19.78031-40.42064,19.78031-40.42064L363.63811,598.1193l-41.29815-.86-35.24307,26.66043s36.98059,32.68052,20.64033,45.58072-40.42065,21.50035-43.8607,16.34027-42.14067-81.70131-36.12058-88.58142S294.837,564.57876,294.837,564.57876l16.34026-23.22037,63.641-3.44006Z"
                transform="translate(-227.12124 -115.57973)"
                fill="#2f2e41"
              />
              <path
                d="M361.91808,405.47622s-4.30007,28.38045,4.30007,31.82051S349.01788,455.357,349.01788,455.357H334.39764l-9.46015-19.78032s8.60014-15.48025,3.44006-30.10048S361.91808,405.47622,361.91808,405.47622Z"
                transform="translate(-227.12124 -115.57973)"
                fill="#ffb8b8"
              />
              <path
                d="M361.91808,405.47622s-4.30007,28.38045,4.30007,31.82051S349.01788,455.357,349.01788,455.357H334.39764l-9.46015-19.78032s8.60014-15.48025,3.44006-30.10048S361.91808,405.47622,361.91808,405.47622Z"
                transform="translate(-227.12124 -115.57973)"
                opacity="0.1"
              />
              <path
                d="M367.93818,429.55661S358.478,439.87677,356.758,441.5968s-9.46015,11.18018-25.80041-2.58-18.06029,2.58-18.06029,2.58V544.79844s13.76022-6.02009,24.9404,0,34.40055-1.72,34.40055-1.72l4.30007-106.6417Z"
                transform="translate(-227.12124 -115.57973)"
                fill="#d0cde1"
              />
              <path
                d="M361.621,429.55661s8.03717-3.44006,14.91728,0,30.96049,20.64032,29.24046,28.38045-24.08038,61.921-8.60013,87.7214S415.23893,578.339,415.23893,578.339l-37.8406-19.78032s-29.24047-18.06028-26.66043-29.24046S361.621,429.55661,361.621,429.55661Z"
                transform="translate(-227.12124 -115.57973)"
                fill="#3f3d56"
              />
              <path
                d="M327.50739,429.55661s-18.05014,1.72-18.91016,3.44005-27.09043,9.03014-25.3704,16.77027,25.3704,76.97123,15.05024,89.87143-27.52044,30.9605-21.50035,32.68052,16.34027-2.58,26.66043,0,34.40055-18.9203,31.82051-42.14067S335.23737,435.5767,327.50739,429.55661Z"
                transform="translate(-227.12124 -115.57973)"
                fill="#3f3d56"
              />
              <path
                d="M370.51822,593.81923v8.60013s4.30007,19.78032-9.46015,18.92031-5.16008-24.08039-5.16008-24.08039l.86-7.74012Z"
                transform="translate(-227.12124 -115.57973)"
                fill="#ffb8b8"
              />
              <path
                d="M331.8176,594.67924s9.46015,19.78032,0,21.50034-16.34026-11.18017-16.34026-14.62023S331.8176,594.67924,331.8176,594.67924Z"
                transform="translate(-227.12124 -115.57973)"
                fill="#ffb8b8"
              />
              <ellipse cx="92.22616" cy="278.2863" rx="1.72003" ry="3.44005" fill="#ffb8b8" />
              <ellipse cx="142.96697" cy="278.2863" rx="1.72003" ry="3.44005" fill="#ffb8b8" />
              <path
                d="M304.29716,440.73678s-21.50034,0-20.64033,8.60014,29.24047,156.5225,29.24047,156.5225,12.04019-11.18018,22.36036-8.60014Z"
                transform="translate(-227.12124 -115.57973)"
                fill="#3f3d56"
              />
              <path
                d="M387.71849,443.31682s21.50035,4.30007,21.50035,17.20028-30.9605,136.74218-35.26056,136.74218-20.64033-2.58-20.64033-6.88011S387.71849,443.31682,387.71849,443.31682Z"
                transform="translate(-227.12124 -115.57973)"
                fill="#3f3d56"
              />
              <path
                d="M382.98842,468.68723l-19.35031,75.67348S372.66825,478.14738,382.98842,468.68723Z"
                transform="translate(-227.12124 -115.57973)"
                opacity="0.2"
              />
              <path
                d="M307.72259,458.0542,327.293,556.99327S321.03817,463.76143,307.72259,458.0542Z"
                transform="translate(-227.12124 -115.57973)"
                opacity="0.2"
              />
              <circle cx="117.59656" cy="278.71631" r="24.9404" fill="#ffb8b8" />
              <polygon
                points="94.738 253.344 113.38 243.58 139.123 247.574 144.449 271.098 131.191 270.589 127.487 261.946 127.487 270.446 121.369 270.211 117.818 256.451 115.599 271.098 93.85 270.655 94.738 253.344"
                fill="#2f2e41"
              />
              <polyline points="374.28 108.551 364 133 450.768 178.545 480 93" fill="#f2f2f2" />
              <polygon
                points="448.768 163.545 487.099 73.352 399 30 362 118 448.768 163.545"
                fill="#6c63ff"
              />
              <rect
                x="656.355"
                y="160.2919"
                width="1.41951"
                height="74.90016"
                transform="translate(-45.11285 577.296) rotate(-62.93456)"
                fill="#fff"
              />
              <rect
                x="651.355"
                y="172.2919"
                width="1.41951"
                height="74.90016"
                transform="translate(-58.52366 579.38347) rotate(-62.93456)"
                fill="#fff"
              />
            </svg>
          </Grid>
        </Grid>
        <Paper className="home-metrics">
          <Grid container justify="space-between" alignItems="center">
            <CountUpMetric
              label={translation().views.home.countup.order_number}
              getMetric={getMetric}
              calcMode="count"
              route="orders"
              interval="auto"
            />
            <CountUpMetric
              label={translation().views.home.countup.revenue}
              getMetric={getMetric}
              calcMode="count"
              route="orders-revenue"
              interval="auto"
              countProps={{
                decimals: 2,
                decimal: ','
              }}
            />
            <CountUpMetric
              label={translation().views.home.countup.margin}
              getMetric={getMetric}
              route="orders-avg-profit-margin-ratio"
              calcMode="avg"
              countProps={{
                decimals: 2,
                decimal: ','
              }}
            />
            <CountUpMetric
              label={translation().views.home.countup.basket}
              getMetric={getMetric}
              route="orders-avg-basket"
              calcMode="avg"
              countProps={{
                decimals: 2,
                decimal: ','
              }}
            />
          </Grid>
        </Paper>
        <div className="md-space" />
      </Container>
    </div>
  );
}

Home.propTypes = {
  getMetric: PropTypes.func.isRequired,
  user: PropTypes.shape({
    data: PropTypes.shape({
      first_name: PropTypes.string
    })
  }).isRequired
};

export default Home;
