/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

import FormFileField from '../../../../components/forms/fields/FormFileField';
import FormSwitchField from '../../../../components/forms/fields/FormSwitchField';

function ValidatingFieldsForm(props) {
  const {
    closeLabel,
    dialogTitle,
    isFormLoading,
    fields,
    loadingLabel,
    onClose,
    onSubmit,
    submitLabel
  } = props;

  const [values, setValues] = useState({});

  const handleChange = (name) => (event) => {
    setValues({
      ...values,
      [name]: event.target.value
    });
  };

  /**
   * We are forced to use arrow function, cause our customs components are not designed for use: arg => arg2 => functions
   */
  function handleChangeArrowF(name, value) {
    setValues({
      ...values,
      [name]: value
    });
  }

  function handleSubmit() {
    onSubmit(values);
  }

  function handleClose() {
    onClose();
  }

  function renderFields() {
    if (!fields || fields.length <= 0) return;

    const fieldsArray = [];

    for (let i = 0; i < fields.length; i++) {
      if (fields[i].field.input_type === 'file') {
        fieldsArray.push(
          <div key={'missingFields-' + i}>
            <FormFileField
              disabled={isFormLoading}
              type={fields[i].field.type}
              name={fields[i].field.name}
              label={fields[i].field.label ? fields[i].field.label : 'Missing field label'}
              onChange={(value) => {
                handleChangeArrowF(fields[i].field.name, value);
              }}
              required
              write
            />
          </div>
        );
      } else if (fields[i].field.input_type === 'select') {
        fieldsArray.push(
          <div key={'missingFields-' + i}>
            <FormControl required disabled={isFormLoading} fullWidth margin="normal">
              <InputLabel htmlFor={fields[i].field.name}>
                {fields[i].field.label ? fields[i].field.label : 'Missing field label'}
              </InputLabel>
              <Select
                name={fields[i].field.name}
                id={fields[i].field.name}
                onChange={handleChange(fields[i].field.name)}
                disabled={isFormLoading}
                value={values[fields[i].field.name] || ''}
                inputProps={{
                  name: fields[i].field.name,
                  id: fields[i].field.name
                }}
                required>
                {fields[i].field.choices.map((choice, k) => {
                  return (
                    <MenuItem key={k} value={choice.value}>
                      {choice.value}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        );
      } else if (
        fields[i].field.input_type === 'number' ||
        fields[i].field.input_type === 'integer' ||
        fields[i].field.input_type === 'float' ||
        fields[i].field.input_type === 'decimal'
      ) {
        fieldsArray.push(
          <div key={'missingFields-' + i}>
            <TextField
              type="number"
              disabled={isFormLoading}
              name={fields[i].field.name}
              id={fields[i].field.name}
              label={fields[i].field.label ? fields[i].field.label : 'Missing field label'}
              value={values[fields[i].field.name] || ''}
              onChange={handleChange(fields[i].field.name)}
              fullWidth
              required
              margin="normal"
            />
          </div>
        );
      } else if (fields[i].field.input_type === 'boolean') {
        fieldsArray.push(
          <div key={'missingFields-' + i}>
            <FormSwitchField
              disabled={isFormLoading}
              name={fields[i].field.name}
              id={fields[i].field.name}
              label={fields[i].field.label ? fields[i].field.label : 'Missing field label'}
              onChange={(value) => {
                handleChangeArrowF(fields[i].field.name, value);
              }}
              required
              write
            />
          </div>
        );
      } else {
        fieldsArray.push(
          <div key={'missingFields-' + i}>
            <TextField
              type="text"
              disabled={isFormLoading}
              name={fields[i].field.name}
              id={fields[i].field.name}
              label={fields[i].field.label ? fields[i].field.label : 'Missing field label'}
              value={values[fields[i].field.name] || ''}
              onChange={handleChange(fields[i].field.name)}
              fullWidth
              required
              margin="normal"
            />
          </div>
        );
      }
    }

    return fieldsArray;
  }

  return (
    <div className="ValidatingFieldsForm">
      <Dialog
        open
        onClose={handleClose}
        aria-labelledby="form-validating-fields"
        fullWidth
        maxWidth="sm">
        <DialogTitle id="form-validating-fields">{dialogTitle}</DialogTitle>
        <DialogContent>
          {renderFields()}
          <div className="md-space" />
          {isFormLoading && (
            <div className="loader-wpr">
              <CircularProgress size={28} color="secondary" />
              <p>{loadingLabel}</p>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={isFormLoading}>
            {closeLabel}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={isFormLoading}>
            {submitLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ValidatingFieldsForm.propTypes = {
  closeLabel: PropTypes.string.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  isFormLoading: PropTypes.bool,
  fields: PropTypes.arrayOf(PropTypes.any).isRequired,
  loadingLabel: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitLabel: PropTypes.string.isRequired
};

export default ValidatingFieldsForm;
