import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

function FormSelectField(props) {
  const {
    autoWidth,
    children,
    defaultValue,
    disabled,
    error,
    fullWidth,
    helperText,
    displayEmpty,
    id,
    isLabelShrink,
    label,
    margin,
    name,
    required,
    onChange,
    write,
    value
  } = props;

  const [localValue, setLocalValue] = useState(defaultValue || '');

  useEffect(() => {
    if (value) {
      setLocalValue(value);
    }

    if (value === '') {
      setLocalValue('');
    }
  }, [value]);

  function handleChange(event) {
    setLocalValue(event.target.value);

    if (onChange) {
      onChange(event.target.value, event);
    }
  }

  return (
    <FormControl
      required={required}
      disabled={!write || disabled}
      fullWidth={fullWidth}
      margin={margin}
      error={error ? true : false}>
      <InputLabel shrink={localValue ? true : isLabelShrink} htmlFor={id || name}>
        {label}
      </InputLabel>
      <Select
        displayEmpty={displayEmpty}
        required={required}
        disabled={!write || disabled}
        onChange={handleChange}
        name={name}
        autoWidth={autoWidth}
        value={localValue}
        input={<Input name={name} id={id || name} />}
        inputProps={{
          id: id || name
        }}>
        {children}
      </Select>
      {(error || helperText) && <FormHelperText>{error || helperText}</FormHelperText>}
    </FormControl>
  );
}

FormSelectField.defaultProps = {
  autoWidth: false,
  fullWidth: true,
  isLabelShrink: false,
  margin: 'normal',
  write: true
};

FormSelectField.propTypes = {
  autoWidth: PropTypes.bool,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  displayEmpty: PropTypes.bool,
  defaultValue: PropTypes.any,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fullWidth: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isLabelShrink: PropTypes.bool,
  value: PropTypes.any,
  margin: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  write: PropTypes.bool
};

export default FormSelectField;
