import React from 'react';

function DeliveryTimeIcon(props) {
  return (
    <svg
      version="1.1"
      id="DeliveryTimeIcon"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="100"
      viewBox="0 0 480 480"
      style={{ enableBackground: 'new 0 0 480 480' }}
      {...props}>
      <g>
        <path fill="#8892A0" d="M25.5,40.5l8.3-13.7L201,129l-8.3,13.7L25.5,40.5z" />
        <path fill="#8892A0" d="M232,40h16v72h-16V40z" />
        <path fill="#8892A0" d="M34.2,16h193.5v16H34.2V16z" />
        <path fill="#8892A0" d="M250.1,34l7.2-14.3l88.4,44.2l-7.2,14.3L250.1,34z" />
        <path fill="#8892A0" d="M359.1,71.5l93.3-46.7l7.2,14.3l-93.3,46.7L359.1,71.5z" />
      </g>
      <path fill="#B8CBD9" d="M0,128h480v272H0V128z" />
      <path fill="#E8EFF4" d="M32,160h416v208H32V160z" />
      <path
        fill="#E64C3C"
        d="M352,224c0,61.9-50.1,112-112,112s-112-50.1-112-112s50.1-112,112-112S352,162.1,352,224z"
      />
      <path
        fill="#758190"
        d="M328,448H152c-17.7,0-32,14.3-32,32h240C360,462.3,345.7,448,328,448z"
      />
      <path fill="#8892A0" d="M200,400h80v48h-80V400z" />
      <path fill="#7ECB92" d="M48,24c0,13.3-10.7,24-24,24S0,37.3,0,24S10.7,0,24,0S48,10.7,48,24z" />
      <path
        fill="#E64C3C"
        d="M480,24c0,13.3-10.7,24-24,24s-24-10.7-24-24s10.7-24,24-24S480,10.7,480,24z"
      />
      <path
        fill="#7ECB92"
        d="M376,72c0,13.3-10.7,24-24,24s-24-10.7-24-24s10.7-24,24-24S376,58.7,376,72z"
      />
      <path
        fill="#7ECB92"
        d="M264,24c0,13.3-10.7,24-24,24s-24-10.7-24-24s10.7-24,24-24S264,10.7,264,24z"
      />
      <ellipse
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -88.0975 235.3137)"
        fill="#E64C3C"
        cx="240"
        cy="224"
        rx="110.6"
        ry="110.6"
      />
      <path
        fill="#FFFFFF"
        d="M222.4,168.7l37.7,0l0,55.3c0,2.5,0,7.5-2.5,10.1l-37.7,55.3l-30.2-20.1l32.7-50.3L222.4,168.7z"
      />
    </svg>
  );
}

export default DeliveryTimeIcon;
