import React from 'react';

function PropertyStatusIcon(props) {
  return (
    <svg
      version="1.1"
      id="PropertyStatusIcon"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="100"
      viewBox="0 0 480 480"
      style={{ enableBackground: 'new 0 0 480 480' }}
      {...props}>
      <path fill="#EDEFF1" d="M376,24v63.4L320,52V24H376z" />
      <path fill="#E64C3C" d="M312,0h73.3v24H312V0z" />
      <path fill="#EDEFF1" d="M240,40L64,152v248h352V152L240,40z" />
      <path
        fill="#D3D3D3"
        d="M416,400V238.4c-3.3-3.1-7.4-5.3-12-6.1c-1.3-0.2-2.7-0.3-4-0.3c-13.2,0-24,10.8-24,24v88c0,4.4-3.6,8-8,8h-3.5
        c-11,0-20.6,7.5-23.3,18.2l-4.9,19.7l-2.8,10.1H416z"
      />
      <path fill="#D3D3D3" d="M264,200h128v200H264V200z" />
      <path fill="#D3D3D3" d="M64,152v24L240,64l176,112v-24L240,40L64,152z" />
      <g>
        <path fill="#E64C3C" d="M240,1.3L40,128v40L240,41.3L440,168v-40L240,1.3z" />
        <path
          fill="#E64C3C"
          d="M272,120c0,17.7-14.3,32-32,32s-32-14.3-32-32s14.3-32,32-32S272,102.3,272,120z"
        />
        <path fill="#F97A4E" d="M272,208h112v192H272V208z" />
      </g>
      <path
        fill="#466089"
        d="M376,256v88c0,4.4-3.6,8-8,8h-3.5c-11,0-20.6,7.5-23.3,18.2l-4.9,19.7l-2.8,10.1H384V238.2
        C379.1,242.6,376,248.9,376,256z"
      />
      <path
        fill="#FFE352"
        d="M296.7,303.3c0,4.4-3.6,8-8,8s-8-3.6-8-8s3.6-8,8-8S296.7,298.9,296.7,303.3z"
      />
      <path
        fill="#80D6FB"
        d="M264,120c0,13.3-10.7,24-24,24s-24-10.7-24-24s10.7-24,24-24S264,106.7,264,120z"
      />
      <path fill="#6E83B7" d="M88,200h144v112H88V200z" />
      <path fill="#80D6FB" d="M96,208h128v96H96V208z" />
      <path fill="#6E83B7" d="M224,252h-60v-44h-8v44H96v8h60v44h8v-44h60V252z" />
      <path
        fill="#80D6FB"
        d="M16,377c17.7,0,32-14.3,32-32c0,17.7,14.3,32,32,32s32-14.3,32-32c0,17.7,14.3,32,32,32s32-14.3,32-32
        c0,17.7,14.3,32,32,32s32-14.3,32-32c0,17.7,14.3,32,32,32s32-14.3,32-32c0,17.7,14.3,32,32,32s32-14.3,32-32c0,17.7,14.3,32,32,32
        s32-14.3,32-32c0,17.7,14.3,32,32,32h16v104H0V377H16z"
      />
      <path
        fill="#71CBEA"
        d="M480,480v-30.4l-16.2-59.5l-3.6-14.4c-13.1-1.6-23.8-11.1-27-23.6H432c-0.4,0-0.7-0.2-1.1-0.2
        c-3.5,13.9-16,24.2-30.9,24.2s-27.4-10.3-30.9-24.2c-0.4,0-0.7,0.2-1.1,0.2h-1.1c-3.3,12.6-13.9,22-27,23.6l-3.6,14.3L311.7,480H480
        z"
      />
      <path fill="#FFE352" d="M480,480l-24-88H344l-24,88H480z" />
      <path fill="#FFB236" d="M392,392h16v32h-16V392z" />
      <path fill="#FFB236" d="M424,392h16v24h-16V392z" />
      <path fill="#FFB236" d="M360,392h16v24h-16V392z" />
      <path
        fill="#6E83B7"
        d="M456,392l-5-19.9c-1.8-7.1-8.2-12.1-15.5-12.1H432c-8.8,0-16-7.2-16-16v-87.1c0-8-5.4-15.4-13.3-16.6
        c-10-1.6-18.7,6.1-18.7,15.8v88c0,8.8-7.2,16-16,16h-3.5c-7.3,0-13.7,5-15.5,12.1l-5,19.9H456z M400,248c4.4,0,8,3.6,8,8s-3.6,8-8,8
        s-8-3.6-8-8S395.6,248,400,248z"
      />
      <g>
        <path
          fill="#415A6B"
          d="M30,462.6h-8.2c-2.3,0-3.9-1.6-3.9-3.9v-9h16v9C33.9,461,31.9,462.6,30,462.6z"
        />
        <path
          fill="#415A6B"
          d="M95.5,462.6h-8.2c-2.3,0-3.9-1.6-3.9-3.9v-9h16v9C99.4,461,97.8,462.6,95.5,462.6z"
        />
      </g>
      <path
        fill="#E8EAE9"
        d="M150.5,436.8H161v-115c0.4-25.7-20.7-46.4-46.4-46.4s-46.4,20.7-46.4,46.4v6.6h10.5v-6.6
        c0-19.9,16-35.9,35.9-35.9s35.9,16,35.9,35.9L150.5,436.8L150.5,436.8z"
      />
      <rect x="63.1" y="324.2" fill="#F05540" width="21.4" height="20.7" />
      <path
        fill="#F3705A"
        d="M58.8,334.7L58.8,334.7c-22.2,0-40.5,16.8-42.9,38.6l-7.8,65.9c-0.8,5.8,3.1,11.3,9.4,12.9
        c27.7,5.8,55,5.8,82.6,0c5.8-1.2,9.7-6.6,9.4-12.9l-7.4-66.3C99.4,351.5,80.7,334.7,58.8,334.7z"
      />
      <path
        fill="#F05540"
        d="M90,440l-1.2-23c-0.8-16-14-28.8-30.4-28.8S28.8,400.6,28.1,417l-1.2,23H90L90,440z"
      />
      <circle fill="#FFD15C" cx="58.8" cy="364.7" r="9.7" />
      <polygon fill="#415A6B" points="190.2,444.6 121.6,444.6 116.5,462.6 195.3,462.6 " />
      <rect x="143.4" y="434.9" fill="#ADB3BA" width="24.6" height="9.7" />
    </svg>
  );
}

export default PropertyStatusIcon;
