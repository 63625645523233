import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import FormApiAutocompleteField from '../../../../../../components/forms/fields/FormApiAutocompleteField';
import translation from '../../../../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  currentProviderText: {
    display: 'flex',
    fontWeight: 500
  },
  currentProviderTitle: {
    display: 'flex',
    marginRight: 4
  },
  currentProviderWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  dialogActions: {
    flexWrap: 'wrap'
  },
  forceButton: {
    marginRight: 'auto'
  },
  forceWrapper: {
    backgroundColor: 'rgba(244, 67, 54, 0.2)',
    borderLeft: '5px solid #F44336',
    padding: theme.spacing(2, 1)
  },
  tableContainer: {
    maxHeight: 600
  },
  tableRow: {
    cursor: 'pointer'
  }
}));

function ProvidersButton(props) {
  const {
    className,
    currentProvider,
    getOrderProductDispatchableProviders,
    orderId,
    orderProductId,
    postOrderProductDispatchableProviders,
    productProvider,
    providersDispatching,
    reloadOrder,
    updateOrderProductProvider
  } = props;

  const classes = useStyles();
  const [isForcing, setForcing] = useState(false);
  const [isDispatchableProvidersOpen, setDispatchableProvidersOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(null);
  const [dispatchableProviders, setDispatchableProviders] = useState([]);
  const [checked, setChecked] = useState([]);

  function handleOpenDialog() {
    setIsOpen(true);
  }

  function handleSelect(value) {
    setValue(value);
  }

  function handleCloseDialog() {
    setIsOpen(false);
    setDispatchableProvidersOpen(false);
    setDispatchableProviders([]);
    setChecked([]);
    setForcing(false);
  }

  function handleToggleForcing() {
    setForcing((isForcing) => !isForcing);
  }

  function handleOpenDispatchableProviders() {
    setDispatchableProvidersOpen(true);
    setDispatchableProviders([]);

    getOrderProductDispatchableProviders(orderId, orderProductId, (data) => {
      setDispatchableProviders(data);
    });
  }

  function handleCloseDispatchableProviders() {
    setDispatchableProvidersOpen(false);
    setDispatchableProviders([]);
    setChecked([]);
  }

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  function handleDispatch() {
    const providersToDispatch = [];

    if (checked.length <= 0) return;

    for (let i = 0; i < checked.length; i++) {
      if (checked[i].id) {
        providersToDispatch.push(checked[i].id);
      }
    }

    postOrderProductDispatchableProviders(
      orderId,
      orderProductId,
      providersToDispatch,
      handleCloseDialog
    );
  }

  function handleSubmit() {
    updateOrderProductProvider(orderId, orderProductId, value, isForcing, reloadOrder);
  }

  return (
    <>
      <Tooltip title="Gérer le prestataire" enterDelay={500}>
        <IconButton aria-label="providers" className={className} onClick={handleOpenDialog}>
          <AssignmentIndIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        fullWidth
        open={isOpen ? true : false}
        onClose={handleCloseDialog}
        aria-labelledby="add-product-option"
        aria-describedby="add-product-option-description"
        disableBackdropClick={
          productProvider.isLoading ||
          providersDispatching.isLoading ||
          providersDispatching.isSendLoading
        }>
        <DialogTitle id="add-product-option">
          {translation().views.orders.update.products.product_provider.title}
        </DialogTitle>
        <DialogContent>
          {currentProvider && currentProvider.name && (
            <DialogContentText id="add-product-option-description" component="div">
              <div className={classes.currentProviderWrapper}>
                <Typography className={`${classes.currentProviderTitle}`}>
                  {translation().views.orders.update.products.product_provider.current}:
                </Typography>
                <Typography className={classes.currentProviderText}>
                  {currentProvider.name} {currentProvider.id ? `(#${currentProvider.id})` : false}
                </Typography>
              </div>
            </DialogContentText>
          )}
          {!isDispatchableProvidersOpen && (
            <>
              <div className={classNames({ [classes.forceWrapper]: isForcing })}>
                {isForcing && (
                  <Typography gutterBottom>
                    {translation().views.orders.update.products.product_provider.disclaimer}
                  </Typography>
                )}
                <FormApiAutocompleteField
                  disabled={productProvider.isLoading}
                  links={[{ rel: 'list', href: `providers` }]}
                  name="upd_order_product_provider"
                  showId
                  onSelectValue={handleSelect}
                  targetKey="id"
                  label={translation().views.orders.update.products.product_provider.label}
                  placeholder={
                    translation().views.orders.update.products.product_provider.input_placeholder
                  }
                />
              </div>
              {productProvider.isLoading && (
                <div className="loader-wpr" style={{ marginTop: 15 }}>
                  <CircularProgress size={30} color="secondary" />
                  <p>{translation().views.orders.update.products.product_provider.loading_text}</p>
                </div>
              )}
            </>
          )}
          {isDispatchableProvidersOpen && (
            <div>
              {providersDispatching.isLoading && (
                <div className="loader-wpr" style={{ marginTop: 15 }}>
                  <CircularProgress size={30} color="secondary" />
                  <p>
                    {
                      translation().views.orders.update.products.product_provider
                        .loading_get_dispatch
                    }
                  </p>
                </div>
              )}
              {providersDispatching.isSendLoading && (
                <div className="loader-wpr" style={{ marginTop: 15 }}>
                  <CircularProgress size={30} color="secondary" />
                  <p>
                    {translation().views.orders.update.products.product_provider.sending_dispatch}
                  </p>
                </div>
              )}
              {!providersDispatching.isLoading &&
              !providersDispatching.isSendLoading &&
              dispatchableProviders &&
              dispatchableProviders.length <= 0 ? (
                <Typography>
                  {
                    translation().views.orders.update.products.product_provider
                      .no_provider_to_dispatch
                  }
                </Typography>
              ) : (
                false
              )}
              {!providersDispatching.isSendLoading &&
              dispatchableProviders &&
              dispatchableProviders.length ? (
                <TableContainer className={classes.tableContainer}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell variant="head" />
                        <TableCell variant="head">
                          {
                            translation().views.orders.update.products.product_provider
                              .providers_table.name
                          }
                        </TableCell>
                        <TableCell variant="head">
                          {
                            translation().views.orders.update.products.product_provider
                              .providers_table.trip_distance
                          }
                        </TableCell>
                        <TableCell variant="head">
                          {
                            translation().views.orders.update.products.product_provider
                              .providers_table.cost
                          }
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dispatchableProviders.map((provider, index) => {
                        const labelId = `checkbox-list-label-${provider.id}`;
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={index}
                            onClick={handleToggle(provider)}
                            className={classes.tableRow}
                            selected={checked.indexOf(provider) !== -1}>
                            <TableCell variant="body" size="small">
                              <Checkbox
                                edge="start"
                                checked={checked.indexOf(provider) !== -1}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': labelId }}
                              />
                            </TableCell>
                            <TableCell variant="body" size="small">
                              {`${provider.name} (#${provider.id})`}
                            </TableCell>
                            <TableCell variant="body" size="small">
                              {provider.dispatch_info && provider.dispatch_info.trip_distance
                                ? provider.dispatch_info.trip_distance
                                : '-'}{' '}
                              km
                            </TableCell>
                            <TableCell variant="body" size="small">
                              {provider.dispatch_info &&
                              provider.dispatch_info.amount_reversion_tax_excluded_formatted
                                ? provider.dispatch_info.amount_reversion_tax_excluded_formatted
                                : '-'}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                false
              )}
            </div>
          )}
          <div className="sm-space" />
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          {isDispatchableProvidersOpen ? (
            <Button
              disabled={productProvider.isLoading || providersDispatching.isSendLoading}
              variant="contained"
              size="small"
              onClick={handleCloseDispatchableProviders}
              className={classes.forceButton}>
              {translation().views.orders.update.products.product_provider.close_dispatch}
            </Button>
          ) : (
            <Button
              disabled={productProvider.isLoading}
              variant="contained"
              size="small"
              onClick={handleOpenDispatchableProviders}>
              {translation().views.orders.update.products.product_provider.open_dispatch}
            </Button>
          )}
          {!isDispatchableProvidersOpen && (
            <Button
              disabled={productProvider.isLoading}
              variant="contained"
              size="small"
              className={classes.forceButton}
              onClick={handleToggleForcing}>
              {isForcing
                ? translation().views.orders.update.products.product_provider.unforce
                : translation().views.orders.update.products.product_provider.force}
            </Button>
          )}
          <Button
            onClick={handleCloseDialog}
            color="default"
            disabled={productProvider.isLoading || providersDispatching.isSendLoading}>
            {translation().actions.cancel}
          </Button>
          {isDispatchableProvidersOpen ? (
            <Button
              variant="contained"
              color="primary"
              onClick={handleDispatch}
              disabled={checked.length <= 0 || providersDispatching.isSendLoading}>
              {translation().actions.send}
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={productProvider.isLoading || !value}>
              {translation().actions.confirm}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

ProvidersButton.propTypes = {
  className: PropTypes.string.isRequired,
  currentProvider: PropTypes.shape().isRequired,
  getOrderProductDispatchableProviders: PropTypes.func.isRequired,
  orderId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  orderProductId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  productProvider: PropTypes.shape({
    isLoading: PropTypes.bool
  }).isRequired,
  providersDispatching: PropTypes.shape({
    isLoading: PropTypes.bool,
    isSendLoading: PropTypes.bool
  }).isRequired,
  postOrderProductDispatchableProviders: PropTypes.func.isRequired,
  reloadOrder: PropTypes.func.isRequired,
  updateOrderProductProvider: PropTypes.func.isRequired
};

export default ProvidersButton;
