import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchApi } from '../../../utils/functions/api';
import { enqueueSnackbar } from '../../../redux/actions/appActions';
import ManageCompanyFlag from '../../../views/core/customListActions/ManageCompanyFlag';
import translation from '../../../translation/translation';

const mapDispatchToProps = (dispatch) => {
  return {
    manageCompanyFlag: (companyId, value, callbackSuccess, callbackError) => {
      if (!companyId) return;

      const data = { flag: value };

      const successAddMessage =
        translation().core.list.dialogs.custom_list_actions.manage_company_flag.callbacks
          .success_add;
      const successRemoveMessage =
        translation().core.list.dialogs.custom_list_actions.manage_company_flag.callbacks
          .success_remove;
      const errorAddMessage =
        translation().core.list.dialogs.custom_list_actions.manage_company_flag.callbacks.error_add;
      const errorRemoveMessage =
        translation().core.list.dialogs.custom_list_actions.manage_company_flag.callbacks
          .error_remove;

      fetchApi(
        'put',
        `companies/${companyId}/flag`,
        null,
        data,
        null,
        (success) => {
          dispatch(
            enqueueSnackbar({
              message: !value ? successRemoveMessage : successAddMessage,
              options: {
                variant: 'success'
              }
            })
          );

          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success);
          }
        },
        (error) => {
          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : !value
                    ? errorRemoveMessage
                    : errorAddMessage,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: !value ? errorRemoveMessage : errorAddMessage,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError(
              error && error.response && error.response.data && error.response.data.detail
                ? error.response.data.detail
                : !value
                ? errorRemoveMessage
                : errorAddMessage
            );
          }
        }
      );
    }
  };
};

export default withRouter(connect(null, mapDispatchToProps)(ManageCompanyFlag));
