const initialState = {
  data: null,
  loading: false,
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_REDUCERS':
      return initialState;
    case 'REMOVE_USER':
      return initialState;
    case 'STORE_USER':
      return {
        ...state,
        data: action.data
      };
    case 'GET_USER':
      return {
        ...state,
        loading: true,
        error: null
      };
    case 'GET_USER_SUCCESS':
      return {
        ...state,
        data: action.data,
        loading: false,
        error: null
      };
    case 'GET_USER_ERROR':
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
};
