import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';

import translation from '../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      minWidth: 300
    }
  }
}));

function PayerBridge(props) {
  const { getPayerId, fullProps, resource } = props;
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  const [link, setLink] = useState(null);

  useEffect(() => {
    if (resource && resource.id && fullProps && fullProps.resourceUri) {
      setLoading(true);

      getPayerId(
        fullProps.resourceUri,
        resource.id,
        (success) => {
          setLoading(false);
          if (success && success[0]) {
            setLink(`/payers/${success[0].id}/update`);
          }
        },
        () => {
          setLoading(false);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="PayerBridge">
      <div className={classes.root}>
        {isLoading && (
          <div className="loader-wpr">
            <CircularProgress color="primary" size={25} />
            <p>{translation().core.list.dialogs.custom_list_actions.payer_bridge.payer_loading}</p>
          </div>
        )}
        {link && (
          <Link href={link} target="_blank">
            {link}
          </Link>
        )}
      </div>
    </div>
  );
}

PayerBridge.propTypes = {
  getPayerId: PropTypes.func.isRequired,
  fullProps: PropTypes.shape({
    resourceUri: PropTypes.string.isRequired
  }).isRequired,
  resource: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  })
};

export default PayerBridge;
