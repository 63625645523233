import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchApi } from '../../utils/functions/api';
import { enqueueSnackbar } from '../../redux/actions/appActions';
import translation from '../../translation/translation';
import CoreMetrics from '../../views/metrics/core/CoreMetrics';

const mapDispatchToProps = (dispatch) => {
  return {
    getMetrics: (route, query, callbackSuccess = null, callbackError = null) => {
      if (!route || !query) return;

      fetchApi(
        'get',
        `metrics/${route}`,
        {
          ...query,
          split: true
        },
        null,
        null,
        (success) => {
          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success);
          }
        },
        (error) => {
          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.callbacks.error_list,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().core.list.callbacks.error_list,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError(error);
          }
        }
      );
    }
  };
};

export default withRouter(connect(null, mapDispatchToProps)(CoreMetrics));
