import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import {
  Avatar,
  Button,
  ButtonBase,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  Popover,
  Tooltip,
  Typography
} from '@material-ui/core';
import {
  ArrowBack as ArrowBackIcon,
  ArrowDropDown as ArrowDropDownIcon,
  Person as PersonIcon
} from '@material-ui/icons';
import { grey } from '@material-ui/core/colors';

import LevelMenuItem from './LevelMenuItem';
import UserListItem from './UserListItem';
import { getUserNameWithLevel, getUserInitials } from '../../../../../utils/functions/user';
import { stringToColor } from '../../../../../utils/functions/colors';
import { FormApiAutocompleteField } from '../../../../../components/forms/fields';
import translation from '../../../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  accessTitle: {
    fontSize: 16,
    color: grey[600]
  },
  autocomplete: {
    width: '100%',
    '&.withValue': {
      marginRight: 8
    }
  },
  avatar: {
    marginRight: 2
  },
  avatars: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 20,
    paddingRight: 10,
    borderRadius: 4,
    '&:hover': {
      backgroundColor: theme.palette.type === 'dark' ? fade('#FFFFFFF', 0.1) : fade('#000000', 0.1)
    }
  },
  arrowBackIcon: {
    left: -10
  },
  list: {
    maxHeight: 400,
    overflow: 'auto'
  },
  listItem: {
    padding: theme.spacing(1, 0)
  },
  noBg: {
    backgroundColor: 'transparent'
  },
  fields: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 50,
    marginBottom: 18
  },
  selectButton: {
    textTransform: 'inherit',
    height: 50
  }
}));

function ViewUsers(props) {
  const classes = useStyles();
  const { id, name, onLinkuser, onRemoveUserTaskView, onUpdateUserTaskViewLevel, users } = props;

  const levels = {
    admin: {
      name: translation().views.task_views.tasks.users.permissions.admin,
      value: 'admin',
      description: translation().views.task_views.tasks.users.can_admin
    },
    manager: {
      name: translation().views.task_views.tasks.users.permissions.manager,
      value: 'manager',
      description: translation().views.task_views.tasks.users.can_manage
    },
    editor: {
      name: translation().views.task_views.tasks.users.permissions.editor,
      value: 'editor',
      description: translation().views.task_views.tasks.users.can_edit
    },
    operator: {
      name: translation().views.task_views.tasks.users.permissions.operator,
      value: 'operator',
      description: translation().views.task_views.tasks.users.can_oper
    },
    viewer: {
      name: translation().views.task_views.tasks.users.permissions.viewer,
      value: 'viewer',
      description: translation().views.task_views.tasks.users.can_view
    }
  };

  const [open, setOpen] = useState(false);
  const [userValue, setUserValue] = useState(null);
  const [levelValue, setLevelValue] = useState(levels.editor);
  const [anchorLevels, setAnchorLevels] = useState(null);
  const [linkLoading, setLinkLoading] = useState(false);

  const levelsOpen = Boolean(anchorLevels);

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
    setUserValue(null);
    setLevelValue(levels.editor);
  }

  function handleOpenLevels(event) {
    setAnchorLevels(event.currentTarget);
  }

  function handleCloseLevels() {
    setAnchorLevels(null);
  }

  function handleSelectLevel(value) {
    setLevelValue(value);
    handleCloseLevels();
  }

  function handleSelectUser(value) {
    setUserValue(value);
    setLevelValue(levels.editor);
  }

  function handleClearUser() {
    setUserValue(null);
  }

  function handleLinkUser() {
    setLinkLoading(true);

    onLinkuser(
      id,
      {
        iam_user_id: userValue,
        level: levelValue.value
      },
      () => {
        setLinkLoading(false);
        handleClose();
      },
      () => {
        setLinkLoading(false);
      }
    );
  }

  function handleUpdateUserTaskViewLevel(uid, level, onSuccess, onError) {
    if (!uid || !level) return;

    onUpdateUserTaskViewLevel(id, uid, level, onSuccess, onError);
  }

  function handleRemoveUserTaskView(uid, onSuccess, onError) {
    if (!uid) return;

    setLinkLoading(true);

    onRemoveUserTaskView(
      id,
      uid,
      () => {
        if (onSuccess) {
          onSuccess();
        }

        setLinkLoading(false);
        handleClose();
      },
      () => {
        if (onError) {
          onError();
        }

        setLinkLoading(false);
      }
    );
  }

  function handleGetAvatarColor(user) {
    if (!user || !user.id) return;
    return stringToColor(`${user.id}${user.first_name}${user.last_name}`);
  }

  function renderAvatars() {
    const avatars = [];
    const nbOfAvatars = 2;

    for (let i = 0; i < users.length; i++) {
      const userName = getUserNameWithLevel(users[i].user, users[i].level, levels, true);
      const initials = getUserInitials(users[i].user);

      avatars.push(
        <Tooltip key={i} title={userName}>
          <Avatar
            aria-label="user"
            className={classNames(classes.avatar)}
            style={{ backgroundColor: handleGetAvatarColor(users[i].user) }}>
            {initials ? initials : <PersonIcon fontSize="large" />}
          </Avatar>
        </Tooltip>
      );

      if (users.length !== nbOfAvatars && i === nbOfAvatars - 1) {
        avatars.push(
          <Tooltip
            key={i + 1}
            title={`${translation().views.task_views.tasks.users.view} ${users.length - i - 1} ${
              translation().views.task_views.tasks.users.more
            }`}>
            <Avatar className={classes.avatar}>+{users.length - i - 1}</Avatar>
          </Tooltip>
        );
        break;
      }
    }

    return avatars;
  }

  function renderList() {
    const listItems = [];

    for (let i = 0; i < users.length; i++) {
      const userName = getUserNameWithLevel(users[i].user);
      const initials = getUserInitials(users[i].user);

      listItems.push(
        <UserListItem
          key={users[i].user.id}
          taskViewId={id}
          initials={initials}
          userName={userName}
          levels={levels}
          {...users[i]}
          onRemove={handleRemoveUserTaskView}
          onSelectLevel={handleUpdateUserTaskViewLevel}
        />
      );
    }

    return listItems;
  }

  return (
    <>
      <ButtonBase onClick={handleOpen} className={classes.avatars}>
        {renderAvatars()}
      </ButtonBase>
      <Dialog
        aria-labelledby="manage-task-views-users"
        fullWidth
        maxWidth="xs"
        onClose={handleClose}
        disableBackdropClick={linkLoading}
        keepMounted
        open={open}>
        <DialogTitle id="manage-task-views-users">
          {userValue && (
            <IconButton
              onClick={handleClearUser}
              className={classes.arrowBackIcon}
              disabled={linkLoading}>
              <ArrowBackIcon />
            </IconButton>
          )}
          {`${translation().views.task_views.tasks.users.share} "${name}"`}
        </DialogTitle>
        <DialogContent>
          <div className={classes.fields}>
            <FormApiAutocompleteField
              id="add-new-task-view-user"
              name="add-new-task-view-user"
              variant="filled"
              margin="none"
              className={classNames(classes.autocomplete, {
                withValue: Boolean(userValue)
              })}
              cleared={!userValue}
              fullWidth
              links={[
                {
                  rel: 'list',
                  href: `users`
                }
              ]}
              InputProps={{
                style: {
                  padding: 8,
                  height: 50
                }
              }}
              onSelectValue={handleSelectUser}
              placeholder={translation().views.task_views.tasks.users.label}
              disabled={linkLoading}
              targetKey="id"
            />
            {userValue && (
              <Button
                variant="contained"
                disableElevation
                className={classes.selectButton}
                endIcon={<ArrowDropDownIcon />}
                disabled={linkLoading}
                onClick={handleOpenLevels}>
                {levelValue.name}
              </Button>
            )}
            <Popover
              id={levelsOpen ? 'levels-popover' : undefined}
              open={levelsOpen}
              anchorEl={anchorLevels}
              onClose={handleCloseLevels}
              style={{ zIndex: 1400 }}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
              <List>
                {Object.keys(levels).map((level, index) => (
                  <LevelMenuItem
                    key={index}
                    {...levels[level]}
                    selected={levelValue}
                    onSelect={handleSelectLevel}
                  />
                ))}
              </List>
            </Popover>
          </div>
          <Collapse in={!userValue}>
            <Typography className={classes.accessTitle}>
              {translation().views.task_views.tasks.users.with_access}
            </Typography>
            <List dense className={classes.list}>
              {renderList()}
            </List>
          </Collapse>
        </DialogContent>
        <DialogActions>
          {userValue ? (
            <>
              <Button onClick={handleClearUser} disabled={linkLoading}>
                {translation().actions.cancel}
              </Button>
              <Button
                color="secondary"
                disabled={linkLoading}
                onClick={handleLinkUser}
                variant="contained"
                style={{ minWidth: 100 }}>
                {linkLoading ? <CircularProgress size={20} /> : translation().actions.confirm}
              </Button>
            </>
          ) : (
            <Button disabled={linkLoading} onClick={handleClose}>
              {translation().actions.close}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

ViewUsers.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  onLinkuser: PropTypes.func.isRequired,
  onRemoveUserTaskView: PropTypes.func.isRequired,
  onUpdateUserTaskViewLevel: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      level: PropTypes.oneOf(['admin', 'manager', 'editor', 'operator', 'viewer']),
      user: PropTypes.shape({
        id: PropTypes.number,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        email: PropTypes.string
      })
    })
  )
};

export default ViewUsers;
