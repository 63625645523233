/**
 * IAM METRICS
 */
export default {
  name: 'Iam metrics',
  routes_selector: [
    {
      label: 'Entreprises',
      value: 'companies'
    },
    {
      label: 'Utilisateurs',
      value: 'users'
    }
  ],
  metrics_routes: {
    companies: {
      child_routes: {
        'companies-interval-auto': {
          route: 'companies',
          chart_props: {
            type: 'line',
            title: "Nombre d'entreprises inscrites:",
            metric_calc_mode: 'count'
          },
          query_params: {
            time_interval: 'auto'
          }
        },
        'companies-interval-auto-compare-period': {
          route: 'companies',
          chart_props: {
            title: "Évolution nombre d'entreprises inscrites (période):",
            type: 'trending',
            metric_calc_mode: 'avg',
            custom: true
          },
          query_params: {
            compare: 'period'
          }
        },
        'companies-with-orders-interval-auto': {
          route: 'companies-with-orders',
          chart_props: {
            type: 'line',
            title: "Nombre d'entreprises consommatrices:",
            metric_calc_mode: 'function',
            metric_calc_function: {
              type: 'call-api',
              route: 'companies-with-orders',
              calc_mode: 'count',
              query_params: {}
            }
          },
          query_params: {
            time_interval: 'auto'
          }
        },
        'companies-with-orders-interval-auto-compare-period': {
          route: 'companies-with-orders',
          chart_props: {
            title: "Évolution nombre d'entreprises consommatrices (période):",
            type: 'trending',
            metric_calc_mode: 'avg',
            custom: true
          },
          query_params: {
            compare: 'period'
          }
        },
        'companies-with-orders-ratio-interval-auto': {
          route: 'companies-with-orders-ratio',
          chart_props: {
            type: 'line',
            title: "Pourcentage d'entreprises actives:",
            metric_calc_mode: 'function',
            metric_calc_function: {
              type: 'call-api',
              route: 'companies-with-orders-ratio',
              calc_mode: 'count',
              query_params: {}
            }
          },
          query_params: {
            time_interval: 'auto'
          }
        }
      }
    },
    users: {
      child_routes: {
        'users-interval-auto': {
          route: 'users',
          chart_props: {
            type: 'line',
            title: "Nombre d'utilisateurs inscrits:",
            metric_calc_mode: 'count'
          },
          query_params: {
            time_interval: 'auto'
          }
        },
        'users-interval-auto-compare-period': {
          route: 'users',
          chart_props: {
            title: "Évolution nombre d'utilisateurs inscrits (période):",
            type: 'trending',
            metric_calc_mode: 'avg',
            custom: true
          },
          query_params: {
            compare: 'period'
          }
        },
        'users-with-orders-interval-auto': {
          route: 'users-with-orders',
          chart_props: {
            type: 'line',
            title: "Nombre d'utilisateurs consommateurs:",
            metric_calc_mode: 'function',
            metric_calc_function: {
              type: 'call-api',
              route: 'users-with-orders',
              calc_mode: 'count',
              query_params: {}
            }
          },
          query_params: {
            time_interval: 'auto'
          }
        },
        'users-with-orders-interval-auto-compare-period': {
          route: 'users-with-orders',
          chart_props: {
            title: "Évolution nombre d'utilisateurs consommateurs (période):",
            type: 'trending',
            metric_calc_mode: 'avg',
            custom: true
          },
          query_params: {
            compare: 'period'
          }
        },
        'users-with-orders-ratio-interval-auto': {
          route: 'users-with-orders-ratio',
          chart_props: {
            type: 'line',
            title: "Pourcentage d'utilisateurs actifs:",
            metric_calc_mode: 'function',
            metric_calc_function: {
              type: 'call-api',
              route: 'users-with-orders-ratio',
              calc_mode: 'count',
              query_params: {}
            }
          },
          query_params: {
            time_interval: 'auto'
          }
        }
      }
    }
  }
};
