import React from 'react';

function ModerationIcon(props) {
  return (
    <svg
      version="1.1"
      id="ModerationIcon"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="100"
      viewBox="0 0 512 512"
      style={{ enableBackground: 'new 0 0 512 512' }}
      {...props}>
      <path
        fill="#40596B"
        d="M512,21.867c0-11.733-9.6-21.333-21.333-21.333H21.333C9.6,0.533,0,10.133,0,21.867V344h512V21.867z"
      />
      <path
        fill="#CDD6E0"
        d="M410.667,498.667h-19.2c-18.133,0-32-13.867-32-32v-46.933H152.533v46.933c0,18.133-13.867,32-32,32
        h-19.2c-3.2,0-6.4,3.2-6.4,6.4l0,0c0,3.2,3.2,6.4,6.4,6.4h309.333c3.2,0,6.4-3.2,6.4-6.4l0,0
        C417.067,501.867,413.867,498.667,410.667,498.667z"
      />
      <rect x="32" y="32.533" fill="#FFFFFF" width="448" height="278.4" />
      <rect x="32" y="32.533" fill="#F2F2F2" width="448" height="278.4" />
      <rect x="32" y="32.533" fill="#CDD6E0" width="448" height="23.467" />
      <path
        fill="#F2F2F2"
        d="M0,344v55.467c0,11.733,9.6,21.333,21.333,21.333h469.333c11.733,0,21.333-9.6,21.333-21.333V344H0z"
      />
      <circle fill="#CDD6E0" cx="256" cy="382.4" r="17.07" />
      <rect x="152.53" y="420.799" fill="#C3D0DB" width="205.87" height="16" />
      <path fill="#55BFE9" d="M397.867,284.267v6.4V284.267z" />
      <polygon fill="#FFD15C" points="387.2,290.667 387.2,290.667 397.867,290.667 " />
      <rect x="44.8" y="96.53" fill="#CDD6E0" width="44.8" height="193.07" />
      <rect x="44.8" y="83.73" fill="#40596B" width="44.8" height="12.8" />
      <g>
        <rect x="55.47" y="118.93" fill="#ACB3BA" width="23.467" height="14.933" />
        <rect x="55.47" y="163.73" fill="#ACB3BA" width="23.467" height="14.933" />
        <rect x="55.47" y="207.47" fill="#ACB3BA" width="23.467" height="14.933" />
        <rect x="55.47" y="252.27" fill="#ACB3BA" width="23.467" height="14.933" />
      </g>
      <rect x="380.8" y="101.87" fill="#CDD6E0" width="88.53" height="64" />
      <rect x="380.8" y="83.73" fill="#40596B" width="88.53" height="18.13" />
      <rect x="380.8" y="225.6" fill="#CDD6E0" width="88.53" height="64" />
      <rect x="380.8" y="207.47" fill="#40596B" width="88.53" height="18.13" />
      <g>
        <rect x="392.53" y="113.6" fill="#ACB3BA" width="64" height="14.933" />
        <rect x="392.53" y="139.2" fill="#ACB3BA" width="64" height="14.933" />
        <rect x="392.53" y="237.33" fill="#ACB3BA" width="64" height="14.933" />
        <rect x="392.53" y="262.93" fill="#ACB3BA" width="64" height="14.933" />
      </g>
      <polygon fill="#F8B64C" points="187.733,164.8 129.067,259.733 245.333,259.733 " />
      <polygon fill="#FFD15C" points="253.867,126.4 172.8,259.733 336,259.733 " />
      <circle fill="#FF7058" cx="212.27" cy="142.4" r="16" />
      <polygon
        fill="#40596B"
        points="334.933,171.2 312.533,155.2 310.4,182.933 317.867,177.6 323.2,189.333 331.733,185.067
        326.4,173.333 "
      />
    </svg>
  );
}

export default ModerationIcon;
