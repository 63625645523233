export const setDataName = (name) => ({
  type: 'SET_DATA_NAME',
  name
});

export const setDataSearchValue = (value) => ({
  type: 'SET_DATA_SEARCH_VALUE',
  value
});

export const refreshData = () => ({
  type: 'REFRESH_DATA'
});

export const getData = (search, next) => ({
  type: 'GET_DATA',
  search,
  next
});

export const getDataSuccess = (data, paging) => ({
  type: 'GET_DATA_SUCCESS',
  data,
  paging
});

export const getDataError = (isError) => ({
  type: 'GET_DATA_ERROR',
  isError
});

export const cancelGetData = () => ({
  type: 'CANCEL_GET_DATA'
});

export const clearOwnReducer = () => ({
  type: 'CLEAR_CORE_LIST_REDUCER'
});

export const deleteResourceItem = () => ({
  type: 'DELETE_RESOURCE_ITEM'
});

export const deleteResourceItemSuccess = () => ({
  type: 'DELETE_RESOURCE_ITEM_SUCCESS'
});

export const deleteResourceItemError = (isError) => ({
  type: 'DELETE_RESOURCE_ITEM_ERROR',
  isError
});

export const cancelDeleteResourceItem = () => ({
  type: 'CANCEL_DELETE_RESOURCE_ITEM'
});

export const setListNeedReload = () => ({
  type: 'SET_LIST_NEED_RELOAD'
});

export const unsetListNeedReload = () => ({
  type: 'UNSET_LIST_NEED_RELOAD'
});

export const exportListData = () => ({
  type: 'EXPORT_LIST_DATA'
});

export const exportListDataSuccess = () => ({
  type: 'EXPORT_LIST_DATA_SUCCESS'
});

export const exportListDataError = () => ({
  type: 'EXPORT_LIST_DATA_ERROR'
});
