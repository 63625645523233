import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, InputLabel, Slider } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    marginTop: 32,
    marginBottom: 16
  },
  slider: {
    paddingTop: 20,
    paddingBottom: 20
  }
}));

function FormSliderField(props) {
  const classes = useStyles();

  const { id, defaultValue, disabled, label, markSuffix, max, min, onChange } = props;

  function handleChange(event, value) {
    onChange(value);
  }

  const marks = [
    {
      value: 0,
      label: `0${markSuffix}`
    },
    {
      value: 50,
      label: `50${markSuffix}`
    },
    {
      value: 100,
      label: `100${markSuffix}`
    }
  ];

  return (
    <Box className={classes.root}>
      <InputLabel id={id} disabled={disabled}>
        {label}
      </InputLabel>
      <Slider
        aria-label={label}
        aria-labelledby={id}
        className={classes.slider}
        color="secondary"
        defaultValue={defaultValue}
        disabled={disabled}
        marks={marks}
        max={max}
        min={min}
        onChange={handleChange}
        valueLabelDisplay="auto"
      />
    </Box>
  );
}

FormSliderField.defaultProps = {
  defaultValue: 0,
  disabled: false,
  markSuffix: '',
  max: 100,
  min: 0
};

FormSliderField.propTypes = {
  defaultValue: PropTypes.number,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  markSuffix: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  onChange: PropTypes.func.isRequired
};

export default FormSliderField;
