import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Fab,
  Paper,
  Typography
} from '@material-ui/core';
import { Add } from '@material-ui/icons';

import translation from '../../../../../translation/translation';
import CreateAddressForm from '../../../../orders/create/components/CreateAddressForm';
import { getApiPaymentMethodIframe } from '../../../../../utils/functions/api';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  title: {
    fontSize: 18,
    textAlign: 'center'
  },
  subtitle: {
    fontSize: 14
  },
  section: {
    marginTop: 36
  },
  paymentFrame: {
    '& > #frame-payment': {
      width: '100%'
    }
  },
  itemTitle: {
    marginRight: 4
  }
}));

function Invoice(props) {
  const {
    createProviderAddress,
    enqueueSnackbar,
    invoice: { address, payment },
    getUserProviderPayer,
    getUserProviderPaymentMethods,
    user
  } = props;

  const classes = useStyles();

  const [addressErrors, setAddressErrors] = useState(null);
  const [isCreateAddressDialogOpen, setCreateAddressDialogOpen] = useState(false);
  const [isCreatePaymentDialogOpen, setCreatePaymentDialogOpen] = useState(false);
  const [payerId, setPayerId] = useState(null);
  const [payerLoading, setPayerLoading] = useState(false);

  useEffect(() => {
    setPayerId(null);
    setPayerLoading(true);

    getUserProviderPayer(
      user.id,
      (success) => {
        if (success[0] && success[0].id) {
          setPayerId(success[0].id);
        }
        setPayerLoading(false);
      },
      () => {
        setPayerId(null);
        setPayerLoading(false);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleCreateAddress(values, isCompanyToggled) {
    createProviderAddress(
      user,
      values,
      isCompanyToggled,
      () => {
        setAddressErrors(null);
        handleCloseCreateAddressDialog();
      },
      (error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.errors &&
          error.response.data.errors.length > 0
        ) {
          const fieldsErrors = [
            'street_number',
            'street',
            'zip_code',
            'city',
            'country_isocode',
            'state_isocode',
            'latitude',
            'longitude'
          ];
          const validationErrors = {};
          const errorsArray = error.response.data.errors;

          for (let i = 0; i < errorsArray.length; i++) {
            if (errorsArray[i].propertyPath === 'address') {
              validationErrors['invoice_address_value'] = errorsArray[i].message;
            } else if (fieldsErrors.indexOf(errorsArray[i].propertyPath) >= 0) {
              validationErrors['invoice_address_value'] =
                errorsArray[i].propertyPath + ': ' + errorsArray[i].message;
            } else if (errorsArray[i].propertyPath === 'name') {
              validationErrors['invoice_address_name'] = errorsArray[i].message;
            } else {
              validationErrors[errorsArray[i].propertyPath] = errorsArray[i].message;
            }
          }

          setAddressErrors(validationErrors);
        }
      }
    );
  }

  function handleOpenCreateAddressDialog() {
    setCreateAddressDialogOpen(true);
  }

  function handleCloseCreateAddressDialog() {
    setCreateAddressDialogOpen(false);
  }

  function handleOpenCreatePaymentDialog() {
    setCreatePaymentDialogOpen(true);
  }

  function handleCloseCreatePaymentDialog() {
    setCreatePaymentDialogOpen(false);
  }

  function handleLoadPaymentIframe() {
    if (!payerId) return;

    getApiPaymentMethodIframe(
      `payers/${payerId}/payment-methods/form`,
      'receive',
      () => {
        enqueueSnackbar({
          message:
            translation().core.list.dialogs.custom_create_actions.create_payment_method.callbacks
              .success,
          status: 200,
          options: { variant: 'success' }
        });
        getUserProviderPaymentMethods(user.id);
        handleCloseCreatePaymentDialog();
      },
      () => {
        enqueueSnackbar({
          message:
            translation().core.list.dialogs.custom_create_actions.create_payment_method.callbacks
              .error,
          status: 400,
          options: { variant: 'error' }
        });
      }
    );
  }

  return (
    <Grid item xs={12}>
      <Paper className={classes.root} elevation={1}>
        <Typography variant="h5" gutterBottom className={classes.title}>
          {translation().views.provider_factory.steps.invoice.title}
        </Typography>
        <div className={classes.section}>
          <Typography variant="subtitle2" className={classes.subtitle} gutterBottom>
            {translation().views.provider_factory.steps.invoice.address_label}
          </Typography>
          {isCreateAddressDialogOpen && (
            <CreateAddressForm
              errors={addressErrors}
              isFormLoading={address.loading}
              onSubmit={handleCreateAddress}
              onClose={handleCloseCreateAddressDialog}
              user={user}
            />
          )}
          {address.data && address.data.name && address.data.address ? (
            <Typography>
              <Typography variant="button" className={classes.itemTitle} gutterBottom>
                {address.data.name}:
              </Typography>
              {address.data.address}
            </Typography>
          ) : (
            <Fab
              aria-label="create-provider-address"
              className={classes.margin}
              size="small"
              onClick={handleOpenCreateAddressDialog}>
              <Add />
            </Fab>
          )}
        </div>
        <div className={classes.section}>
          <Typography variant="subtitle2" className={classes.subtitle} gutterBottom>
            {translation().views.provider_factory.steps.invoice.payment_label}
          </Typography>
          {payment.data ? (
            <Typography>
              <Typography variant="button" className={classes.itemTitle} gutterBottom>
                {payment.data.type}:
              </Typography>
              {payment.data.name}
            </Typography>
          ) : payerLoading ? (
            <CircularProgress size={40} />
          ) : (
            <Fab
              aria-label="create-provider-payment"
              className={classes.margin}
              size="small"
              onClick={handleOpenCreatePaymentDialog}>
              <Add />
            </Fab>
          )}
        </div>
        <Dialog
          open={isCreatePaymentDialogOpen}
          onClose={handleCloseCreatePaymentDialog}
          aria-labelledby="create-pm-provider-dialog"
          onEnter={handleLoadPaymentIframe}
          fullWidth
          maxWidth="sm">
          <DialogTitle id="create-pm-provider-dialog">
            {translation().core.list.dialogs.custom_create_actions.create_payment_method.title}
          </DialogTitle>
          <DialogContent className={classes.paymentFrame} id="payment-frame-container" />
          <DialogActions>
            <Button onClick={handleCloseCreatePaymentDialog}>{translation().actions.close}</Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </Grid>
  );
}

Invoice.propTypes = {
  createProviderAddress: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  invoice: PropTypes.shape({
    address: PropTypes.shape({
      loading: PropTypes.bool,
      data: PropTypes.shape({
        name: PropTypes.string,
        address: PropTypes.string
      })
    }).isRequired,
    payment: PropTypes.shape({
      loading: PropTypes.bool,
      data: PropTypes.shape({
        name: PropTypes.string,
        type: PropTypes.string
      })
    }).isRequired
  }).isRequired,
  getUserProviderPayer: PropTypes.func.isRequired,
  getUserProviderPaymentMethods: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired
};

export default Invoice;
