import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import LabelIcon from '@material-ui/icons/Label';
import PanToolIcon from '@material-ui/icons/PanTool';

import Blacklist from './Blacklist';
import Products from './Products';
import translation from '../../../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    minHeight: 60,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.cardHeader
  },
  cardContent: {
    overflow: 'auto',
    minHeight: 600,
    padding: theme.spacing(1),
    '&:last-child': {
      paddingBottom: 0
    },
    [theme.breakpoints.down('md')]: {
      minHeight: 'auto'
    }
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(1)
  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2)
  },
  blacklistIcon: {
    position: 'relative',
    left: -2
  }
}));

function ProviderProducts(props) {
  const classes = useStyles();

  const { providerId, blacklist } = props;

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={8}>
          <Card className={classes.paper}>
            <CardHeader
              className={classes.cardHeader}
              title={translation().views.provider_view.products.label}
              titleTypographyProps={{
                variant: 'h5',
                style: {
                  fontSize: 21
                }
              }}
              avatar={
                <Avatar>
                  <LabelIcon />
                </Avatar>
              }
            />
            <CardContent className={classes.cardContent}>
              <Products providerId={providerId} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4}>
          <Card className={classes.paper}>
            <CardHeader
              className={classes.cardHeader}
              title={translation().views.provider_view.blacklist.label}
              titleTypographyProps={{
                variant: 'h5',
                style: {
                  fontSize: 21
                }
              }}
              avatar={
                <Avatar>
                  <PanToolIcon className={classes.blacklistIcon} />
                </Avatar>
              }
            />
            <CardContent className={classes.cardContent}>
              <Blacklist blacklist={blacklist} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

ProviderProducts.propTypes = {
  blacklist: PropTypes.arrayOf(PropTypes.any),
  providerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export default ProviderProducts;
