import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  Link,
  List,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { grey } from '@material-ui/core/colors';
import { Add, Close, DeleteOutline, Group, Person } from '@material-ui/icons';

import { getUserInitials } from '../../../../../../utils/functions/user';
import { stringToColor } from '../../../../../../utils/functions/colors';
import { addToDate, createDate } from '../../../../../../utils/functions/dates';
import {
  FormApiAutocompleteField,
  FormDateTimeField
} from '../../../../../../components/forms/fields';
import PriorityChip from '../../display/priority/PriorityChip';
import UserListItem from './UserListItem';
import translation from '../../../../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 480,
    height: '100%'
  },
  content: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    overflow: 'auto',
    height: 'calc(100% - 62px - 62px)'
  },
  head: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 61
  },
  footer: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: 61
  },
  form: {
    height: '100%'
  },
  accessTitle: {
    fontSize: 16,
    color: grey[600]
  },
  autoAssign: {
    marginLeft: 4,
    cursor: 'pointer'
  },
  deleteButton: {
    marginLeft: 8,
    color: theme.palette.error.light,
    padding: 8,
    border: `1px dashed ${theme.palette.error.light}`,
    '&:hover': {
      backgroundColor: fade(theme.palette.error.light, 0.12)
    }
  },
  addButton: {
    padding: 8,
    border: '1px dashed rgba(145, 158, 171, 0.24)'
  },
  field: {
    marginBottom: 24
  },
  flex: {
    display: 'flex'
  },
  priority: {
    marginTop: 24
  },
  inputLabel: {
    marginBottom: 12
  },
  list: {
    maxHeight: 400,
    overflow: 'auto'
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 12
  },
  more: {
    marginTop: 36
  },
  disabledLink: {
    cursor: 'default',
    userSelect: 'none',
    pointerEvents: 'none',
    color: theme.palette.text.disabled
  },
  dueBtn: {
    margin: '4px 4px 0px 0px'
  }
}));

function CreateTaskForm(props) {
  const classes = useStyles();

  const { loading, onClose, onSubmit, teams, userLogged, users } = props;

  const [userOpen, setUserOpen] = useState(false);
  const [teamOpen, setTeamOpen] = useState(false);
  const [dueDateButton, setDueDateButton] = useState(null);
  const [values, setValues] = useState({
    label: '',
    description: '',
    due: '',
    priority: 1,
    assigned_iam_team_id: '',
    assigned_iam_user_id: null
  });

  function handleChangeLabel(event) {
    const { value } = event.target;

    setValues((state) => ({
      ...state,
      label: value
    }));
  }

  function handleChangeDesc(event) {
    const { value } = event.target;

    setValues((state) => ({
      ...state,
      description: value
    }));
  }

  function handleChangeDue(value) {
    setDueDateButton(null);
    setValues((state) => ({
      ...state,
      due: value
    }));
  }

  function handleChangeDueOneDay() {
    setDueDateButton(1);

    const today = createDate();
    const due = addToDate(today, 1, 'day', 'YYYY-MM-DDTHH:mm:ss');

    setValues((state) => ({
      ...state,
      due
    }));
  }

  function handleChangeDueTwoDay() {
    setDueDateButton(2);

    const today = createDate();
    const due = addToDate(today, 2, 'day', 'YYYY-MM-DDTHH:mm:ss');

    setValues((state) => ({
      ...state,
      due
    }));
  }

  function handleChangeDueOneWeek() {
    setDueDateButton(7);

    const today = createDate();
    const due = addToDate(today, 1, 'week', 'YYYY-MM-DDTHH:mm:ss');

    setValues((state) => ({
      ...state,
      due
    }));
  }

  function handleChangeDueOneMonth() {
    setDueDateButton(30);

    const today = createDate();
    const due = addToDate(today, 1, 'month', 'YYYY-MM-DDTHH:mm:ss');

    setValues((state) => ({
      ...state,
      due
    }));
  }

  function handleChangePriority(value) {
    setValues((state) => ({
      ...state,
      priority: value
    }));
  }

  function handleClearTeamAssigned() {
    setValues((state) => ({
      ...state,
      assigned_iam_team_id: ''
    }));
  }

  function handleClearUser() {
    setValues((state) => ({
      ...state,
      assigned_iam_user_id: null
    }));
  }

  function handleChangeUser(value) {
    setValues((state) => ({
      ...state,
      assigned_iam_user_id: value
    }));
  }

  function handleSelectUser(value) {
    setValues((state) => ({
      ...state,
      assigned_iam_user_id: value.user
    }));
  }

  function handleAssignSelf() {
    setValues((state) => ({
      ...state,
      assigned_iam_user_id: userLogged
    }));

    setUserOpen(false);
  }

  function handleSelectTeam(event) {
    const { value } = event.target;

    setValues((state) => ({
      ...state,
      assigned_iam_team_id: value
    }));
  }

  function handleChangeOrder(event) {
    const { value } = event.target;

    setValues((state) => ({
      ...state,
      shop_order_id: value
    }));
  }

  function handleChangeOrderProduct(event) {
    const { value } = event.target;

    setValues((state) => ({
      ...state,
      shop_order_product_id: value
    }));
  }

  function handleChangeOrderProductOption(event) {
    const { value } = event.target;

    setValues((state) => ({
      ...state,
      shop_order_product_option_id: value
    }));
  }

  function handleOpenUserDialog() {
    setUserOpen(true);
  }

  function handleCloseUserDialog() {
    handleClearUser();
    setUserOpen(false);
  }

  function handleConfirmUserAssigned() {
    setUserOpen(false);
  }

  function handleOpenTeamDialog() {
    setTeamOpen(true);
  }

  function handleCloseTeamDialog() {
    setValues((state) => ({
      ...state,
      assigned_iam_team_id: ''
    }));
    setTeamOpen(false);
  }

  function handleConfirmTeamAssigned() {
    setTeamOpen(false);
  }

  function handleMouseWheel(event) {
    event.target.blur();
  }

  function handleSubmit(e) {
    e.preventDefault();
    onSubmit({
      ...values,
      assigned_iam_user_id:
        values.assigned_iam_user_id && values.assigned_iam_user_id.id
          ? values.assigned_iam_user_id.id
          : null,
      assigned_iam_team_id:
        values.assigned_iam_team_id && values.assigned_iam_team_id.id
          ? values.assigned_iam_team_id.id
          : null
    });
  }

  function handleGetAvatarColor(user) {
    if (!user || !user.id) return;
    return stringToColor(`${user.id}${user.first_name}${user.last_name}`);
  }

  return (
    <div className={classes.root}>
      <form className={classes.form} onSubmit={handleSubmit}>
        <div className={classes.head}>
          <Typography variant="h6">{translation().views.task_views.tasks.create.title}</Typography>
          <IconButton onClick={onClose} disabled={loading}>
            <Close />
          </IconButton>
        </div>
        <Divider />
        <div className={classes.content}>
          <TextField
            id="task-label"
            name="label"
            label={translation().views.task_views.tasks.labels.label}
            autoFocus
            fullWidth
            onChange={handleChangeLabel}
            className={classes.field}
            required
          />
          <TextField
            id="task-desc"
            name="description"
            label={translation().views.task_views.tasks.labels.desc}
            fullWidth
            multiline
            rows={5}
            onChange={handleChangeDesc}
            className={classes.field}
          />
          <FormDateTimeField
            id="task-due-date"
            name="due"
            label={translation().views.task_views.tasks.labels.due}
            value={values.due}
            onChange={handleChangeDue}
            required
            fullWidth
          />
          <Button
            size="small"
            variant="contained"
            disableElevation
            className={classes.dueBtn}
            onClick={handleChangeDueOneDay}
            color={dueDateButton && dueDateButton === 1 ? 'primary' : 'default'}>
            {translation().views.task_views.tasks.labels.due_1_day}
          </Button>
          <Button
            size="small"
            variant="contained"
            disableElevation
            className={classes.dueBtn}
            onClick={handleChangeDueTwoDay}
            color={dueDateButton && dueDateButton === 2 ? 'primary' : 'default'}>
            {translation().views.task_views.tasks.labels.due_2_days}
          </Button>
          <Button
            size="small"
            variant="contained"
            disableElevation
            className={classes.dueBtn}
            onClick={handleChangeDueOneWeek}
            color={dueDateButton && dueDateButton === 7 ? 'primary' : 'default'}>
            {translation().views.task_views.tasks.labels.due_1_week}
          </Button>
          <Button
            size="small"
            variant="contained"
            disableElevation
            className={classes.dueBtn}
            onClick={handleChangeDueOneMonth}
            color={dueDateButton && dueDateButton === 30 ? 'primary' : 'default'}>
            {translation().views.task_views.tasks.labels.due_1_month}
          </Button>
          <div className={classNames(classes.priority, classes.field)}>
            <InputLabel className={classes.inputLabel}>
              {translation().views.task_views.tasks.labels.priority}
            </InputLabel>
            <div className={classes.flex}>
              <PriorityChip onChange={handleChangePriority} value={1} checked={values.priority} />
              <PriorityChip onChange={handleChangePriority} value={2} checked={values.priority} />
              <PriorityChip onChange={handleChangePriority} value={3} checked={values.priority} />
            </div>
          </div>
          <div className={classes.field}>
            <InputLabel className={classes.inputLabel}>
              {translation().views.task_views.tasks.labels.user_assigned}:
              {!values.assigned_iam_user_id && (
                <Link className={classes.autoAssign} onClick={handleAssignSelf} variant="body2">
                  {translation().views.task_views.tasks.labels.auto_assign}
                </Link>
              )}
            </InputLabel>
            {values.assigned_iam_user_id ? (
              <div className={classes.flex}>
                <Avatar
                  aria-label="user"
                  style={{
                    backgroundColor: handleGetAvatarColor(values.assigned_iam_user_id)
                  }}
                  size="small">
                  {values.assigned_iam_user_id ? (
                    getUserInitials(values.assigned_iam_user_id)
                  ) : (
                    <Person fontSize="large" />
                  )}
                </Avatar>
                <IconButton onClick={handleClearUser} size="small" className={classes.deleteButton}>
                  <DeleteOutline />
                </IconButton>
              </div>
            ) : (
              <IconButton onClick={handleOpenUserDialog} size="small" className={classes.addButton}>
                <Add />
              </IconButton>
            )}
          </div>
          <div className={classes.field}>
            <InputLabel className={classes.inputLabel}>
              {translation().views.task_views.tasks.labels.team}:
            </InputLabel>
            {values.assigned_iam_team_id ? (
              <div className={classes.flex}>
                <Avatar aria-label="team" size="small">
                  {getUserInitials({
                    first_name: values.assigned_iam_team_id.name
                  }) ? (
                    getUserInitials({
                      first_name: values.assigned_iam_team_id.name
                    })
                  ) : (
                    <Group fontSize="large" />
                  )}
                </Avatar>
                <IconButton
                  onClick={handleClearTeamAssigned}
                  size="small"
                  className={classes.deleteButton}>
                  <DeleteOutline />
                </IconButton>
              </div>
            ) : (
              <IconButton onClick={handleOpenTeamDialog} size="small" className={classes.addButton}>
                <Add />
              </IconButton>
            )}
          </div>
          <div className={classes.more}>
            <Typography paragraph>
              {translation().views.task_views.tasks.labels.more_info}
            </Typography>
            <TextField
              id="shop_order_id"
              name="shop_order_id"
              fullWidth
              type="number"
              label={translation().views.task_views.tasks.labels.order}
              className={classes.field}
              onChange={handleChangeOrder}
              onWheel={handleMouseWheel}
            />
            <TextField
              id="shop_order_product_id"
              name="shop_order_product_id"
              fullWidth
              type="number"
              label={translation().views.task_views.tasks.labels.order_product}
              className={classes.field}
              onChange={handleChangeOrderProduct}
              onWheel={handleMouseWheel}
            />
            <TextField
              id="shop_order_product_option_id"
              name="shop_order_product_option_id"
              fullWidth
              type="number"
              label={translation().views.task_views.tasks.labels.order_product_option}
              className={classes.field}
              onChange={handleChangeOrderProductOption}
              onWheel={handleMouseWheel}
            />
          </div>
        </div>
        <Divider />
        <div className={classes.footer}>
          {loading && (
            <div className={classes.loading}>
              <CircularProgress size={25} style={{ marginRight: 8 }} />
              <Typography color="textSecondary" variant="body2">
                {translation().views.task_views.tasks.create.loading_text}
              </Typography>
            </div>
          )}
          <Button
            color="secondary"
            variant="contained"
            disabled={loading || !values.due || !values.label}
            type="submit">
            {translation().actions.confirm}
          </Button>
        </div>
      </form>
      <Dialog
        aria-labelledby="assigned-user-dialog"
        fullWidth
        maxWidth="xs"
        onClose={handleCloseUserDialog}
        open={userOpen}>
        <DialogTitle id="assigned-user-dialog">
          {translation().views.task_views.tasks.labels.user_assigned}
        </DialogTitle>
        <DialogContent>
          <FormApiAutocompleteField
            id="assign-user-autocomplete"
            name="assign-user-autocomplete"
            variant="filled"
            margin="none"
            targetKey="id"
            cleared={
              values.assigned_iam_user_id && values.assigned_iam_user_id.reactFrom === 'list'
            }
            fullWidth
            links={[
              {
                rel: 'list',
                href: `users`
              }
            ]}
            InputProps={{
              style: {
                padding: 8,
                height: 50
              }
            }}
            getFullObject
            className={classes.field}
            onSelectValue={handleChangeUser}
            placeholder={translation().views.task_views.tasks.users.label}
          />
          {users && users.length > 0 ? (
            <>
              <Typography className={classes.accessTitle}>
                {translation().views.task_views.tasks.users.with_access}
                <Link onClick={handleAssignSelf} variant="body2" className={classes.autoAssign}>
                  {translation().views.task_views.tasks.labels.auto_assign}
                </Link>
              </Typography>
              <List dense className={classes.list}>
                {users.map((user, index) => (
                  <UserListItem
                    key={index}
                    id={user?.user.id}
                    user={user?.user}
                    selected={values.assigned_iam_user_id}
                    userIdLogged={userLogged.id}
                    onSelect={handleSelectUser}
                  />
                ))}
              </List>
            </>
          ) : (
            false
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUserDialog}>{translation().actions.cancel}</Button>
          <Button
            color="secondary"
            onClick={handleConfirmUserAssigned}
            disabled={!values.assigned_iam_user_id}
            variant="contained">
            {translation().actions.confirm}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        aria-labelledby="assigned-team-dialog"
        fullWidth
        maxWidth="xs"
        onClose={handleCloseTeamDialog}
        open={teamOpen}>
        <DialogTitle id="assigned-team-dialog">
          {translation().views.task_views.tasks.labels.team}
        </DialogTitle>
        <DialogContent>
          {teams && teams.data && teams.data.length > 0 ? (
            <FormControl fullWidth variant="outlined" className={classes.field}>
              <InputLabel>{translation().views.task_views.tasks.labels.team}</InputLabel>
              <Select
                id="assign-team-autocomplete"
                name="assigned_iam_team_id"
                fullWidth
                value={values.assigned_iam_team_id}
                onChange={handleSelectTeam}
                placeholder={translation().views.task_views.tasks.labels.team}
                label={translation().views.task_views.tasks.labels.team}>
                {teams.data.map((team, index) => (
                  <MenuItem key={index} value={team}>
                    {team.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            false
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTeamDialog}>{translation().actions.cancel}</Button>
          <Button
            color="secondary"
            onClick={handleConfirmTeamAssigned}
            disabled={!values.assigned_iam_team_id}
            variant="contained">
            {translation().actions.confirm}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

CreateTaskForm.propTypes = {
  loading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  teams: PropTypes.shape({
    loading: PropTypes.bool,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string
      })
    )
  }).isRequired,
  userLogged: PropTypes.shape({
    id: PropTypes.number.isRequired,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string
  }).isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      level: PropTypes.oneOf(['admin', 'manager', 'editor', 'operator', 'viewer']),
      user: PropTypes.shape({
        id: PropTypes.number,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        email: PropTypes.string
      })
    })
  )
};

export default CreateTaskForm;
