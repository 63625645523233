/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import ButtonBase from '@material-ui/core/ButtonBase';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';

function TaskViewCard(props) {
  const {
    classes,
    data: { id, name, users },
    onClick,
    openDeleteDialog,
    openUpdateDialog,
    user
  } = props;

  function getUserLevel() {
    const levels = {
      viewer: 1,
      editor: 2,
      manager: 3,
      admin: 4
    };

    if (user && user.id && users && users.length) {
      for (let i = 0; i < users.length; i++) {
        if (user.id && users[i].user && users[i].user.id && user.id === users[i].user.id) {
          return users[i].level ? levels[users[i].level] : levels.viewer;
        }
      }
    }

    return levels.viewer;
  }

  function handleClick() {
    onClick(id);
  }

  function handleOpenDeleteDialog() {
    openDeleteDialog(props.data);
  }

  function handleSelectView() {
    openUpdateDialog(props.data);
  }

  const EditAction = (
    <IconButton
      key={`edit-task-view-${id}`}
      aria-label={`edit-task-view-${id}`}
      size="small"
      className={classes.cardAction}
      onClick={handleSelectView}>
      <EditIcon fontSize="small" />
    </IconButton>
  );

  const DeleteAction = (
    <IconButton
      key={`delete-task-view-${id}`}
      aria-label={`delete-task-view-${id}`}
      size="small"
      className={classes.cardAction}
      onClick={handleOpenDeleteDialog}>
      <DeleteIcon fontSize="small" />
    </IconButton>
  );

  function getActions() {
    const level = getUserLevel();

    if (level === 3) {
      return [EditAction];
    } else if (level === 4) {
      return [EditAction, DeleteAction];
    } else {
      return [];
    }
  }

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card className={classes.card}>
        <CardHeader
          action={getActions()}
          title={name || 'Missing name task-view'}
          classes={{
            action: classes.cardHeaderAction
          }}
        />
        <ButtonBase component={CardContent} className={classes.cardContent} onClick={handleClick}>
          <img
            alt="task view"
            className={classes.imgLarge}
            src="/assets/images/tasks/shared-tasks-illustration.svg"
          />
        </ButtonBase>
      </Card>
    </Grid>
  );
}

TaskViewCard.propTypes = {
  classes: PropTypes.shape().isRequired,
  data: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    users: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        level: PropTypes.oneOf(['admin', 'manager', 'editor', 'operator', 'viewer']),
        user: PropTypes.shape({
          id: PropTypes.number,
          first_name: PropTypes.string,
          last_name: PropTypes.string,
          email: PropTypes.string
        })
      })
    )
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  openDeleteDialog: PropTypes.func.isRequired,
  openUpdateDialog: PropTypes.func.isRequired,
  user: PropTypes.shape().isRequired
};

export { TaskViewCard };
