import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';

function ListViewMobileMenu(props) {
  const { actions } = props;

  const [mobileAnchorEl, setMobileAnchorEl] = useState(null);

  function handleOpenMobileMenu(event) {
    setMobileAnchorEl(event.currentTarget);
  }

  function handleMenuClose() {
    setMobileAnchorEl(null);
  }

  const isOpen = Boolean(mobileAnchorEl);

  return (
    <div>
      <IconButton aria-haspopup="true" onClick={handleOpenMobileMenu} color="inherit">
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={mobileAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        disableAutoFocusItem
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isOpen}
        onClose={handleMenuClose}>
        {actions && actions}
      </Menu>
    </div>
  );
}

ListViewMobileMenu.propTypes = {
  actions: PropTypes.any.isRequired
};

export default ListViewMobileMenu;
