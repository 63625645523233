const updateResource = () => ({
  type: 'UPDATE_RESOURCE'
});

const updateResourceSuccess = (data) => ({
  type: 'UPDATE_RESOURCE_SUCCESS',
  data
});

const updateResourceError = (isError) => ({
  type: 'UPDATE_RESOURCE_ERROR',
  isError
});

const setResourceCollection = (collection) => ({
  type: 'SET_RESOURCE_COLLECTION',
  collection
});

const unmountUpdateView = () => ({
  type: 'UNMOUNT_UPDATE_VIEW'
});

const getUpdateResourceLanguages = () => ({
  type: 'GET_UPDATE_RESOURCE_LANGUAGES'
});

const getUpdateResourceLanguagesSuccess = (data) => ({
  type: 'GET_UPDATE_RESOURCE_LANGUAGES_SUCCESS',
  data
});

const getUpdateResourceLanguagesError = (error) => ({
  type: 'GET_UPDATE_RESOURCE_LANGUAGES_ERROR',
  error
});

const setUpdateResourceLocale = (locale) => ({
  type: 'SET_UPDATE_RESOURCE_LOCALE',
  locale
});

const setUpdateResourceTimezone = (timezone) => ({
  type: 'SET_UPDATE_RESOURCE_TIMEZONE',
  timezone
});

export {
  getUpdateResourceLanguages,
  getUpdateResourceLanguagesSuccess,
  getUpdateResourceLanguagesError,
  setResourceCollection,
  setUpdateResourceLocale,
  setUpdateResourceTimezone,
  updateResource,
  updateResourceSuccess,
  updateResourceError,
  unmountUpdateView
};
