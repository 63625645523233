import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@material-ui/core/styles';

function WmWishIcon(props) {
  const { theme, color } = props;
  const fill = color
    ? color
    : theme.palette && theme.palette.type && theme.palette.type === 'dark'
    ? '#FFFFFF'
    : '#0a0a0a';

  return (
    <svg
      version="1.1"
      id="WmWishIcon"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="100"
      viewBox="0 0 120 120"
      style={{ enableBackground: 'new 0 0 120 120' }}
      {...props}>
      <polygon fill={fill} points="59.8,35.9 2.9,3.3 2.9,19.5 59.8,52.1 " />
      <polygon fill={fill} points="59.8,35.9 116.6,3.3 116.6,19.5 59.8,52.1 " />
      <polygon fill={fill} points="15.3,10.4 2.9,3.3 2.9,86.2 15.3,79 " />
      <polygon fill={fill} points="59.8,84.1 116.6,116.8 116.6,100.5 59.8,67.9 " />
      <polygon fill={fill} points="59.8,84.1 2.9,116.8 2.9,100.5 59.8,67.9 " />
      <polygon fill={fill} points="104.3,109.7 116.6,116.8 116.6,33.8 104.3,41 " />
    </svg>
  );
}

WmWishIcon.propTypes = {
  color: PropTypes.string,
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      type: PropTypes.string
    }).isRequired
  }).isRequired
};

export default withTheme(WmWishIcon);
