// eslint-disable react-hooks/exhaustive-deps
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

import CssBaseline from '@material-ui/core/CssBaseline';
import Collapse from '@material-ui/core/Collapse';
import Drawer from '@material-ui/core/Drawer';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Hidden from '@material-ui/core/Hidden';
import HomeIcon from '@material-ui/icons/Home';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import HeaderBar from './HeaderBar';
import { objectMapToArray } from '../../utils/functions/utils';
import routeMap from '../../routes/routeMap';
import config from '../../config';

const drawerWidth = 200;

const styles = (theme) => ({
  root: {
    display: 'flex'
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appVersion: {
    fontSize: 8,
    margin: 'auto',
    marginTop: '-18px',
    display: 'flex',
    paddingLeft: 20
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  list: {
    padding: 0,
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    marginTop: '-1px'
  },
  listItem: {
    paddingTop: 6,
    paddingBottom: 6
  },
  listItemNested: {
    '&:first-child': {
      paddingLeft: 15
    },
    paddingLeft: 15
  },
  listItemIcon: {
    minWidth: 42,
    marginRight: 0
  },
  toolbar: {
    ...theme.mixins.toolbar,
    flexWrap: 'wrap',
    alignItems: 'end'
  },
  drawerPaper: {
    width: drawerWidth
  },
  envLabel: {
    backgroundColor: '#42a5f5',
    borderRadius: 50,
    padding: '4px 12px',
    lineHeight: 1,
    fontSize: 10,
    color: 'white',
    marginLeft: 4
  }
});

function NestedDrawerItem(props) {
  const { classes, item, pathname, onDrawerClose } = props;

  const [open, setOpen] = useState(false);

  useEffect(() => {
    const resources = Object.values(item.resources);

    if (resources.length > 0) {
      for (let i = 0; i < resources.length; i++) {
        if (resources[i].nav_uri === pathname) {
          setOpen(true);
          break;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleToggle() {
    setOpen((oldOpen) => !oldOpen);
  }

  return (
    <>
      <ListItem button onClick={handleToggle}>
        {item.nav_icon && (
          <ListItemIcon className={classes.listItemIcon}>
            <i className="material-icons">{item.nav_icon}</i>
          </ListItemIcon>
        )}
        <ListItemText primary={item.nav_name} />
        <Typography>{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}</Typography>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {objectMapToArray(item.resources, (index, resource) => {
            if (resource.is_show_in_nav) {
              return (
                <ListItem
                  button
                  key={index}
                  component={Link}
                  to={resource.nav_uri}
                  selected={resource.nav_uri === pathname}
                  onClick={onDrawerClose}
                  className={classes.listItem}>
                  <ListItemText
                    primaryTypographyProps={{ style: { fontSize: 12 } }}
                    className={classes.listItemNested}
                    primary={resource.nav_name}
                  />
                </ListItem>
              );
            } else {
              return false;
            }
          })}
        </List>
      </Collapse>
    </>
  );
}

NestedDrawerItem.propTypes = {
  classes: PropTypes.shape().isRequired,
  item: PropTypes.shape({
    nav_name: PropTypes.string.isRequired,
    nav_icon: PropTypes.string,
    resources: PropTypes.shape()
  }).isRequired,
  pathname: PropTypes.string.isRequired,
  onDrawerClose: PropTypes.func.isRequired
};

function Header(props) {
  const {
    isDarkTheme,
    classes,
    container,
    handleSignOut,
    location: { pathname },
    theme,
    user
  } = props;

  const [open, setOpen] = useState(false);

  function handleDrawerToggle() {
    setOpen((oldOpen) => !oldOpen);
  }

  function handleCloseDrawer() {
    setOpen(false);
  }

  function handleRefreshPage() {
    window.location.reload();
  }

  function renderEnvironmentMode() {
    switch (config.api_env) {
      case 'dev':
        return <div className={classes.envLabel}>{config.api_env}</div>;
      case 'sandbox':
        return <div className={classes.envLabel}>{config.api_env}</div>;
      case 'local':
        return <div className={classes.envLabel}>{config.api_env}</div>;
      default:
        return false;
    }
  }

  const drawer = (
    <div>
      <Toolbar className={classes.toolbar} disableGutters={!open}>
        <img
          src={isDarkTheme ? '/assets/images/logo-light.svg' : '/assets/images/logo-dark.svg'}
          alt="Actarus logo"
          style={{ cursor: 'pointer', maxHeight: 58 }}
          onClick={handleRefreshPage}
          width="100%"
        />
        <div className={classes.appVersion}>
          <Typography variant="caption" gutterBottom style={{ margin: 'auto' }}>
            {config.site_version}
          </Typography>
          {renderEnvironmentMode()}
        </div>
      </Toolbar>
      <List
        className={classes.list}
        style={isDarkTheme ? { borderTop: '1px solid rgba(255, 255, 255, 0.12)' } : {}}>
        <ListItem
          button
          component={Link}
          to="/"
          selected={'/' === pathname}
          onClick={handleCloseDrawer}>
          <ListItemIcon className={classes.listItemIcon}>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        {objectMapToArray(routeMap, (index, item) => {
          return (
            <NestedDrawerItem
              key={index}
              item={item}
              pathname={pathname}
              classes={classes}
              onDrawerClose={handleCloseDrawer}
            />
          );
        })}
      </List>
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <HeaderBar user={user} onSignOut={handleSignOut} onDraw={handleDrawerToggle} />
      <nav className={classes.drawer}>
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={open}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper
            }}>
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper
            }}
            variant="permanent"
            open>
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </div>
  );
}

Header.propTypes = {
  isDarkTheme: PropTypes.bool,
  handleSignOut: PropTypes.func.isRequired,
  user: PropTypes.shape({
    last_name: PropTypes.string,
    first_name: PropTypes.string,
    picture_url: PropTypes.string
  }).isRequired,
  classes: PropTypes.shape().isRequired,
  container: PropTypes.shape(),
  location: PropTypes.shape({
    pathname: PropTypes.any
  }),
  theme: PropTypes.shape().isRequired
};

const WrappedHeader = withStyles(styles, { withTheme: true })(Header);

export default withRouter(WrappedHeader);
