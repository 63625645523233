import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import DateRangeField from '../../../components/forms/fields/customs/dateRangeField/DateRangeField';
import FormSelectField from '../../../components/forms/fields/FormSelectField';
import {
  addToDate,
  alterDate,
  formatDate,
  createDate,
  getCurrentDateUtc
} from '../../../utils/functions/dates';
import { isNavigationBackPossible, navigateBack } from '../../../utils/functions/navigation';
import { countObjProps, objectMapToArray } from '../../../utils/functions/utils';
import Metric from '../Metric';
import translation from '../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1
  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  form: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'baseline'
  },
  field: {
    display: 'flex',
    marginBottom: 8,
    minWidth: 250,
    paddingLeft: 16,
    paddingRight: 16,
    alignItems: 'center'
  },
  metricsWrapper: {
    marginTop: 24
  },
  submitButton: {
    [theme.breakpoints.down(835)]: {
      marginTop: theme.spacing(2)
    }
  }
}));

function CoreMetrics(props) {
  const {
    embed,
    getMetrics,
    history,
    mapping: { name, routes_selector, metrics_routes },
    location,
    match,
    theme
  } = props;

  const classes = useStyles();
  const [submitBuffer, setSubmitBuffer] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [metric, setMetric] = useState(
    routes_selector && routes_selector[0] && routes_selector[0].value
      ? routes_selector[0].value
      : null
  );
  const [finalRange, setFinalRange] = useState({ from: null, until: null });
  const [finalRoute, setFinalRoute] = useState(null);

  useEffect(() => {
    if (!embed) {
      document.title = 'Actarus | Core metrics';
    }

    const today = getCurrentDateUtc();
    const todayAltered = alterDate(today, null, null, null, '23', '59', '59');
    const lastMonthDate = addToDate(todayAltered, -1, 'months');
    const lastMonthDateAltered = alterDate(lastMonthDate, null, null, null, '00', '00', '00');
    const from = createDate(lastMonthDateAltered, 'UTC');
    const until = createDate(todayAltered, 'UTC');

    const range = {
      from: formatDate(from, 'YYYY-MM-DDTHH:mm:ss'),
      until: formatDate(until, 'YYYY-MM-DDTHH:mm:ss')
    };

    setStartDate(from);
    setEndDate(until);
    setFinalRange(range);
    setFinalRoute(
      routes_selector && routes_selector[0] && routes_selector[0].value
        ? routes_selector[0].value
        : null
    );

    return () => {
      /**
       * On metrics unmounting, we are forced to use MASIVE cancel request for stop every calls launched.
       */
      try {
        window.stop();
      } catch (exception) {
        document.execCommand('Stop');
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleSelectRange({ startDate, endDate }) {
    if (startDate) {
      setStartDate(startDate);
    }

    if (endDate) {
      setEndDate(endDate);
    }
  }

  function handleSelectMetric(val) {
    setMetric(val);
  }

  function handleHistoryBack() {
    navigateBack(history);
  }

  function handleSubmit() {
    const range = {};

    if (startDate && endDate) {
      const startAltered = createDate(alterDate(startDate, null, null, null, '00', '00', '00'));
      const endAltered = createDate(alterDate(endDate, null, null, null, '23', '59', '59'));
      const from = createDate(startAltered, 'UTC');
      const until = createDate(endAltered, 'UTC');

      range.from = formatDate(from, 'YYYY-MM-DDTHH:mm:ss');
      range.until = formatDate(until, 'YYYY-MM-DDTHH:mm:ss');

      setFinalRange(range);
      setFinalRoute(metric);
      setSubmitBuffer(submitBuffer + 1);
    }
  }

  function renderMetrics(route = null, range) {
    let metricsMap = [];

    for (let metricRoute in metrics_routes) {
      if (!route) {
        if (
          metrics_routes[metricRoute].child_routes &&
          countObjProps(metrics_routes[metricRoute].child_routes) > 0
        ) {
          const childRoutes = metrics_routes[metricRoute].child_routes;

          objectMapToArray(childRoutes, (route, obj, index) => {
            metricsMap.push(
              <Grid item xs={12} sm={12} md={6} key={index}>
                <Metric
                  submitBuffer={submitBuffer}
                  chartProps={{
                    ...obj.chart_props,
                    name: route
                  }}
                  queryParams={{
                    ...obj.query_params,
                    range
                  }}
                  ref={route}
                  filterKey={obj.filter_key}
                  filterKeys={obj.filter_keys ? obj.filter_keys : null}
                  defaultType="line"
                  routeName={obj.route}
                  getMetrics={getMetrics}
                  location={location}
                  match={match}
                  themeMode={theme.palette.type}
                />
              </Grid>
            );
          });

          return metricsMap;
        }
      } else if (route === metricRoute) {
        const childRoutes = metrics_routes[metricRoute].child_routes;

        objectMapToArray(childRoutes, (route, obj, index) => {
          metricsMap.push(
            <Grid item xs={12} sm={12} md={6} key={index}>
              <Metric
                submitBuffer={submitBuffer}
                chartProps={{
                  ...obj.chart_props,
                  name: route
                }}
                queryParams={{
                  ...obj.query_params,
                  range
                }}
                filterKey={obj.filter_key}
                filterKeys={obj.filter_keys ? obj.filter_keys : null}
                defaultType="line"
                routeName={obj.route}
                getMetrics={getMetrics}
                location={location}
                match={match}
                themeMode={theme.palette.type}
              />
            </Grid>
          );
        });

        return metricsMap;
      }
    }
  }

  return (
    <div className="CoreMetrics">
      {!embed && (
        <AppBar elevation={0} position="static" color="default">
          <Toolbar>
            <Breadcrumbs
              maxItems={5}
              separator={<NavigateNextIcon fontSize="small" />}
              arial-label="Metrics breadcrumb">
              <Typography variant="body2" color="textPrimary">
                {name}
              </Typography>
              <Typography variant="body2" color="textPrimary">
                {finalRoute}
              </Typography>
            </Breadcrumbs>
            <div className={classes.grow} />
            {isNavigationBackPossible(history) && (
              <Tooltip
                title={translation().actions.back || 'Back'}
                aria-label={translation().actions.back || 'Back'}
                enterDelay={700}>
                <IconButton onClick={handleHistoryBack} color="inherit">
                  <ArrowBackIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </Toolbar>
        </AppBar>
      )}
      <Paper elevation={0} className={classes.root} square>
        <div className={classes.form}>
          {routes_selector && routes_selector.length > 1 ? (
            <>
              <div className={classes.field}>
                <FormSelectField
                  name="metric_route"
                  fullWidth
                  onChange={handleSelectMetric}
                  label={translation().metrics.core.form.labels.category}
                  required
                  value={metric}>
                  {routes_selector.map((choice, indexItem) => (
                    <MenuItem key={indexItem} value={choice.value}>
                      {choice.label}
                    </MenuItem>
                  ))}
                </FormSelectField>
              </div>
              <div className={classes.field}>
                <DateRangeField
                  endDate={endDate}
                  endDateId="core_metrics_end_date"
                  startDate={startDate}
                  startDateId="core_metrics_start_date"
                  onSelectRange={handleSelectRange}
                  required
                />
              </div>
            </>
          ) : (
            <div className={classes.field}>
              <DateRangeField
                endDate={endDate}
                endDateId="core_metrics_end_date"
                startDate={startDate}
                startDateId="core_metrics_start_date"
                onSelectRange={handleSelectRange}
                required
              />
            </div>
          )}
          <div className={classes.field}>
            <Button
              onClick={handleSubmit}
              color="primary"
              variant="contained"
              className={classes.submitButton}>
              {translation().metrics.core.form.submit}
            </Button>
          </div>
        </div>
      </Paper>
      <Divider />
      <div className={classes.root}>
        <div className={classes.metricsWrapper}>
          <Grid container spacing={2}>
            {finalRange &&
              finalRange.from &&
              finalRange.until &&
              renderMetrics(finalRoute, finalRange)}
          </Grid>
        </div>
      </div>
    </div>
  );
}

CoreMetrics.defaultProps = {
  embed: false
};

CoreMetrics.propTypes = {
  embed: PropTypes.bool,
  getMetrics: PropTypes.func.isRequired,
  history: PropTypes.shape().isRequired,
  mapping: PropTypes.shape({
    name: PropTypes.string,
    metrics_routes: PropTypes.shape(),
    routes_selector: PropTypes.arrayOf(PropTypes.any)
  }).isRequired,
  location: PropTypes.shape().isRequired,
  match: PropTypes.shape(),
  theme: PropTypes.shape().isRequired
};

export default withTheme(CoreMetrics);
