import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { isObjEmpty } from '../../../utils/functions/utils';
import FieldContainer from '../../../containers/forms/FieldContainer';

function FormFactory(props) {
  const {
    forms,
    formName,
    children,
    defaultValues,
    deleteForm,
    isWillReceiveValues,
    initializeForm,
    metadata,
    onSubmit,
    timezone
  } = props;

  useEffect(() => {
    if (metadata && metadata.data && metadata.data.fields && metadata.data.fields.length > 0) {
      initializeForm(formName, metadata.data.fields);
    }

    return () => {
      deleteForm(formName);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleSumbit(e) {
    e.preventDefault();

    if (!forms) throw new Error('FormFactory submit: There is no form found in redux.');

    if (forms[formName] && onSubmit) {
      onSubmit(forms[formName].values);
    } else throw new Error('FormFactory submit: No form key found for this form.');
  }

  return (
    <div className="FormFactory">
      {isWillReceiveValues &&
      defaultValues &&
      !isObjEmpty(defaultValues) &&
      forms[formName] &&
      forms[formName].init &&
      forms[formName].fields &&
      forms[formName].fields.length > 0 ? (
        <form name={formName} onSubmit={handleSumbit}>
          {forms[formName].fields.map((field, index) => {
            return (
              <FieldContainer
                key={index}
                formName={formName}
                error={forms[formName].errors[field.reference_key || field.name] || null}
                defaultValue={defaultValues[field.name]}
                timezone={timezone}
                {...field}
              />
            );
          })}
          {children ? children : ''}
        </form>
      ) : (
        false
      )}
      {!isWillReceiveValues &&
      (!defaultValues || isObjEmpty(defaultValues)) &&
      forms[formName] &&
      forms[formName].init &&
      forms[formName].fields &&
      forms[formName].fields.length > 0 ? (
        <form name={formName} onSubmit={handleSumbit}>
          {forms[formName].fields.map((field, index) => {
            return (
              <FieldContainer
                key={index}
                formName={formName}
                error={
                  forms && forms[formName]
                    ? forms[formName].errors[field.reference_key || field.name]
                    : null
                }
                timezone={timezone}
                {...field}
              />
            );
          })}
          {children ? children : ''}
        </form>
      ) : (
        false
      )}
    </div>
  );
}

FormFactory.defaultProps = {
  isWillReceiveValues: false
};

FormFactory.propTypes = {
  children: PropTypes.node,
  deleteForm: PropTypes.func.isRequired,
  initializeForm: PropTypes.func.isRequired,
  isWillReceiveValues: PropTypes.bool,
  defaultValues: PropTypes.shape(),
  formName: PropTypes.string.isRequired,
  forms: PropTypes.shape().isRequired,
  metadata: PropTypes.shape().isRequired,
  timezone: PropTypes.string,
  onSubmit: PropTypes.func
};

export default FormFactory;
