import { connect } from 'react-redux';

import FormField from '../../components/forms/factory/FormField';
import { setFieldValue } from '../../redux/actions/forms/fieldActions';

const mapStateToProps = (store) => ({ forms: store.forms });

const mapDispatchToProps = (dispatch) => {
  return {
    setFieldValue(formName, name, value) {
      dispatch(setFieldValue(formName, name, value));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormField);
