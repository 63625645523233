/**
 * Product Id metrics
 */
export default {
  name: 'Products metrics',
  routes_selector: [
    {
      label: 'Commandes',
      value: 'orders'
    },
    {
      label: 'Prestataires',
      value: 'provider-order-products'
    },
    {
      label: 'Clients',
      value: 'clients-order-products'
    }
  ],
  metrics_routes: {
    orders: {
      child_routes: {
        'order-products-interval-auto': {
          route: 'order-products',
          chart_props: {
            type: 'line',
            title: 'Nombre de commande:',
            metric_calc_mode: 'count'
          },
          query_params: {
            time_interval: 'auto'
          },
          filter_key: 'product_id'
        },
        'order-products-interval-auto-period': {
          route: 'order-products',
          chart_props: {
            title: 'Évolution du nombre de commande (période):',
            type: 'trending',
            metric_calc_mode: 'avg',
            custom: true
          },
          query_params: {
            compare: 'period'
          },
          filter_key: 'product_id'
        },
        'order-products-revenue-interval-auto': {
          route: 'order-products-revenue',
          chart_props: {
            type: 'line',
            title: "Chiffre d'affaires:",
            metric_calc_mode: 'count'
          },
          query_params: {
            time_interval: 'auto'
          },
          filter_key: 'product_id'
        },
        'order-products-revenue-interval-auto-compare-period': {
          route: 'order-products-revenue',
          chart_props: {
            title: "Évolution du chiffre d'affaires (période):",
            type: 'trending',
            metric_calc_mode: 'avg',
            custom: true
          },
          query_params: {
            compare: 'period'
          },
          filter_key: 'product_id'
        },
        'order-products-avg-profit-margin-ratio-interval-auto': {
          route: 'order-products-avg-profit-margin-ratio',
          chart_props: {
            type: 'line',
            title: 'Marge nette:',
            metric_calc_mode: 'function',
            metric_calc_function: {
              type: 'call-api',
              route: 'order-products-avg-profit-margin-ratio',
              calc_mode: 'avg',
              query_params: {}
            }
          },
          query_params: {
            time_interval: 'auto'
          },
          filter_key: 'product_id'
        },
        'order-products-avg-profit-margin-ratio-interval-auto-compare-period': {
          route: 'order-products-avg-profit-margin-ratio',
          chart_props: {
            title: 'Évolution de la marge nette (période):',
            type: 'trending',
            metric_calc_mode: 'avg',
            custom: true
          },
          query_params: {
            compare: 'period'
          },
          filter_key: 'product_id'
        },
        'order-products-avg-gross-profit-margin-ratio-interval-auto': {
          route: 'order-products-avg-gross-profit-margin-ratio',
          chart_props: {
            type: 'line',
            title: 'Marge brute:',
            metric_calc_mode: 'function',
            metric_calc_function: {
              type: 'call-api',
              route: 'order-products-avg-gross-profit-margin-ratio',
              calc_mode: 'avg',
              query_params: {}
            }
          },
          query_params: {
            time_interval: 'auto'
          },
          filter_key: 'product_id'
        },
        'order-products-avg-gross-profit-margin-ratio-interval-auto-compare-period': {
          route: 'order-products-avg-gross-profit-margin-ratio',
          chart_props: {
            title: 'Évolution de la marge brute (période):',
            type: 'trending',
            metric_calc_mode: 'avg',
            custom: true
          },
          query_params: {
            compare: 'period'
          },
          filter_key: 'product_id'
        },
        'order-products-avg-contribution-margin-ratio-interval-auto': {
          route: 'order-products-avg-contribution-margin-ratio',
          chart_props: {
            type: 'line',
            title: 'Marge extra-brute:',
            metric_calc_mode: 'function',
            metric_calc_function: {
              type: 'call-api',
              route: 'order-products-avg-contribution-margin-ratio',
              calc_mode: 'avg',
              query_params: {}
            }
          },
          query_params: {
            time_interval: 'auto'
          },
          filter_key: 'product_id'
        },
        'order-products-avg-contribution-margin-ratio-interval-auto-compare-period': {
          route: 'order-products-avg-contribution-margin-ratio',
          chart_props: {
            title: 'Évolution de la marge extra-brute (période):',
            type: 'trending',
            metric_calc_mode: 'avg',
            custom: true
          },
          query_params: {
            compare: 'period'
          },
          filter_key: 'product_id'
        },
        'order-products-avg-amount-interval-auto': {
          route: 'order-products-avg-amount',
          chart_props: {
            type: 'line',
            title: 'Prix moyen:',
            metric_calc_mode: 'function',
            metric_calc_function: {
              type: 'call-api',
              route: 'order-products-avg-amount',
              calc_mode: 'avg',
              query_params: {}
            }
          },
          query_params: {
            time_interval: 'auto'
          },
          filter_key: 'product_id'
        },
        'order-products-avg-amount-interval-auto-compare-period': {
          route: 'order-products-avg-amount',
          chart_props: {
            title: 'Évolution prix moyen (période):',
            type: 'trending',
            metric_calc_mode: 'avg',
            custom: true
          },
          query_params: {
            compare: 'period'
          },
          filter_key: 'product_id'
        },
        'order-products-avg-discount-ratio-interval-auto': {
          route: 'order-products-avg-discount-ratio',
          chart_props: {
            type: 'line',
            title: 'Discount moyen:',
            metric_calc_mode: 'function',
            metric_calc_function: {
              type: 'call-api',
              route: 'order-products-avg-discount',
              calc_mode: 'avg',
              query_params: {}
            }
          },
          query_params: {
            time_interval: 'auto'
          },
          filter_key: 'product_id'
        },
        'order-products-avg-discount-ratio-interval-auto-compare-period': {
          route: 'order-products-avg-discount-ratio',
          chart_props: {
            title: 'Évolution discount moyen (période):',
            type: 'trending',
            metric_calc_mode: 'avg',
            custom: true
          },
          query_params: {
            compare: 'period'
          },
          filter_key: 'product_id'
        }
      }
    },
    'provider-order-products': {
      child_routes: {
        'order-products-avg-provider-cost-interval-auto': {
          route: 'order-products-avg-provider-cost',
          chart_props: {
            type: 'line',
            title: 'Rem prestataire moyenne:',
            metric_calc_mode: 'function',
            metric_calc_function: {
              type: 'call-api',
              route: 'order-products-avg-provider-cost',
              calc_mode: 'avg',
              query_params: {}
            }
          },
          query_params: {
            time_interval: 'auto'
          },
          filter_key: 'product_id'
        },
        'order-products-avg-provider-cost-interval-auto-compare-period': {
          route: 'order-products-avg-provider-cost',
          chart_props: {
            title: 'Évolution rem prestataire moyenne (période):',
            type: 'trending',
            metric_calc_mode: 'avg',
            custom: true
          },
          query_params: {
            compare: 'period'
          },
          filter_key: 'product_id'
        },
        'order-products-avg-trip-distance-interval-auto': {
          route: 'order-products-avg-trip-distance',
          chart_props: {
            type: 'line',
            title: 'Distance parcourue moyenne:',
            metric_calc_mode: 'function',
            metric_calc_function: {
              type: 'call-api',
              route: 'order-products-avg-trip-distance',
              calc_mode: 'avg',
              query_params: {}
            }
          },
          query_params: {
            time_interval: 'auto'
          },
          filter_key: 'product_id'
        },
        'order-products-avg-trip-distance-interval-auto-compare-period': {
          route: 'order-products-avg-trip-distance',
          chart_props: {
            title: 'Évolution distance parcourue moyenne (période):',
            type: 'trending',
            metric_calc_mode: 'avg',
            custom: true
          },
          query_params: {
            compare: 'period'
          },
          filter_key: 'product_id'
        },
        'order-products-avg-trip-compensation-interval-auto': {
          route: 'order-products-avg-trip-compensation',
          chart_props: {
            type: 'line',
            title: 'Indem Km moyenne:',
            metric_calc_mode: 'function',
            metric_calc_function: {
              type: 'call-api',
              route: 'order-products-avg-trip-compensation',
              calc_mode: 'avg',
              query_params: {}
            }
          },
          query_params: {
            time_interval: 'auto'
          },
          filter_key: 'product_id'
        },
        'order-products-avg-trip-compensation-interval-auto-compare-period': {
          route: 'order-products-avg-trip-compensation',
          chart_props: {
            title: 'Évolution indem Km moyenne (période):',
            type: 'trending',
            metric_calc_mode: 'avg',
            custom: true
          },
          query_params: {
            compare: 'period'
          },
          filter_key: 'product_id'
        },
        'order-products-avg-provider-rating-interval-auto': {
          route: 'order-products-avg-provider-rating',
          chart_props: {
            type: 'line',
            title: 'Évaluation provider moyenne:',
            metric_calc_mode: 'function',
            metric_calc_function: {
              type: 'call-api',
              route: 'order-products-avg-provider-rating',
              calc_mode: 'avg',
              query_params: {}
            }
          },
          query_params: {
            time_interval: 'auto'
          },
          filter_key: 'product_id'
        },
        'order-products-avg-provider-rating-interval-auto-compare-period': {
          route: 'order-products-avg-provider-rating',
          chart_props: {
            title: 'Évolution évaluation provider moyenne (période):',
            type: 'trending',
            metric_calc_mode: 'avg',
            custom: true
          },
          query_params: {
            compare: 'period'
          },
          filter_key: 'product_id'
        },
        'order-products-avg-client-rating-interval-auto': {
          route: 'order-products-avg-client-rating',
          chart_props: {
            type: 'line',
            title: 'Évaluation client moyenne:',
            metric_calc_mode: 'function',
            metric_calc_function: {
              type: 'call-api',
              route: 'order-products-avg-client-rating',
              calc_mode: 'avg',
              query_params: {}
            }
          },
          query_params: {
            time_interval: 'auto'
          },
          filter_key: 'product_id'
        },
        'order-products-avg-client-rating-interval-auto-compare-period': {
          route: 'order-products-avg-client-rating',
          chart_props: {
            title: 'Évolution évaluation client moyenne (période):',
            type: 'trending',
            metric_calc_mode: 'avg',
            custom: true
          },
          query_params: {
            compare: 'period'
          },
          filter_key: 'product_id'
        }
      }
    }
  }
};
