export const setOrderProductsUndispatchedSearchValue = (value) => ({
  type: 'SET_ORDER_PRODUCTS_UNDISPATCHED_SEARCH_VALUE',
  value
});

export const refreshOrderProductsUndispatched = () => ({
  type: 'REFRESH_ORDER_PRODUCTS_UNDISPATCHED'
});

export const getOrderProductsUndispatched = (search, next) => ({
  type: 'GET_ORDER_PRODUCTS_UNDISPATCHED',
  search,
  next
});

export const getOrderProductsUndispatchedSuccess = (data, paging) => ({
  type: 'GET_ORDER_PRODUCTS_UNDISPATCHED_SUCCESS',
  data,
  paging
});

export const getOrderProductsUndispatchedError = (isError) => ({
  type: 'GET_ORDER_PRODUCTS_UNDISPATCHED_ERROR',
  isError
});

export const clearOwnReducer = () => ({
  type: 'CLEAR_ORDER_PRODUCTS_UNDISPATCHED_LIST_REDUCER'
});
