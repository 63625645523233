import React, { useEffect, useState, PureComponent } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import Alert from '../../../../../components/alert/Alert';
import FixedHeaderTable from '../../../../../components/tables/fixedHeaderTable/FixedHeaderTable';
import ListEmptyIcon from '../../../../../components/icons/ListEmptyIcon';
import LocalDateInfo from '../../../../../components/timezone/LocalDateInfo';
import { dateInTz, isDateValid } from '../../../../../utils/functions/dates';
import config from '../../../../../config';
import translation from '../../../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2)
  },
  tableContainer: {
    maxHeight: '640px!important'
  },
  labelImportant: {
    color: theme.palette.type === 'dark' ? '#cb6059' : config.error_color || '#F44336'
  },
  iconStatus: {
    width: 27
  },
  updateOrder: {
    marginTop: 0,
    marginBottom: 0,
    margin: 8
  },
  loadingWrapper: {
    width: '100%',
    height: 50
  }
}));

class CustomLink extends PureComponent {
  render() {
    return (
      <Link to={this.props.link} {...this.props}>
        {this.props.children}
      </Link>
    );
  }
}

CustomLink.propTypes = {
  link: PropTypes.string.isRequired,
  children: PropTypes.any
};

const createCancelToken = () => axios.CancelToken.source();

const GetDataApi = (getData, userId) => {
  const [source] = useState(createCancelToken());

  useEffect(() => {
    getData(userId, source);

    return () => {
      source.cancel('=> Cancel get user orders.');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const GetMetadataApi = (getMetadata) => {
  const [source] = useState(createCancelToken());

  useEffect(() => {
    getMetadata(source);

    return () => {
      source.cancel('=> Cancel get orders metadata.');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const GetNextDataApi = (getData, url) => {
  const [source] = useState(createCancelToken());

  const doNext = (initialNext) => {
    if (initialNext) getData(url, source, initialNext);
  };

  useEffect(() => {
    return () => {
      source.cancel('=> Next: Cancelled.');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { doNext };
};

function UserOrders(props) {
  const classes = useStyles();

  const columns = [
    'id',
    'products',
    'property',
    'application',
    'total_tax_excluded_formatted',
    'created',
    'cancelled',
    'delivery_status'
  ];

  const { getUserViewOrders, userId, orders, getOrdersMetadata, cleanUserViewOrdersReducer } =
    props;

  useEffect(() => {
    return () => {
      cleanUserViewOrdersReducer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  GetDataApi(getUserViewOrders, userId);
  GetMetadataApi(getOrdersMetadata);
  const { doNext } = GetNextDataApi(getUserViewOrders, userId);

  function handleScrollList() {
    if (!orders.isLoading && orders.paging && orders.paging.next) {
      doNext(orders.paging.next);
    }
  }

  function getTableBodyFromData(orders) {
    const tableBody = [];

    for (let line in orders) {
      let temp_body = [];
      let isOrderFlag = false;

      for (let key in orders[line]) {
        if (columns.indexOf(key) !== -1) {
          if (key === 'id') {
            temp_body[key] = orders[line][key];
          } else if (key === 'products') {
            let _products = [];

            for (let product in orders[line][key]) {
              let products = {};
              products.name = orders[line][key][product].name;

              if (orders[line][key][product].start) {
                products.start_tz = dateInTz(
                  orders[line][key][product].start,
                  'localized-datetime'
                );
                products.start = orders[line][key][product].start;
              } else {
                products.start_tz = '-';
              }

              if (orders[line][key][product].cancelled) {
                products.cancelled = orders[line][key][product].cancelled;
              }

              products.provider_name = orders[line][key][product].provider_name;

              if (orders[line][key][product].flag) {
                isOrderFlag = true;
              }

              _products.push(products);
            }

            temp_body['products'] = _products;
            temp_body['isOrderFlag'] = isOrderFlag;
          } else if (key === 'property') {
            temp_body['address'] =
              orders[line][key] && orders[line][key].address
                ? orders[line][key].address
                : '[Address not found]';
            temp_body['timezone'] =
              orders[line][key] && orders[line][key].timezone_code
                ? orders[line][key].timezone_code
                : undefined;
          } else if (key === 'application') {
            temp_body['application'] =
              orders[line][key] && orders[line][key].name
                ? orders[line][key].name
                : '[App not found]';
          } else if (key === 'created') {
            const date_created = dateInTz(orders[line][key], 'localized-datetime');
            temp_body['orderDate'] = date_created;
          } else if (key === 'cancelled') {
            if (isDateValid(orders[line][key])) {
              temp_body['cancelDate'] = dateInTz(orders[line][key], 'localized-datetime');
            }
          } else if (key === 'delivery_status') {
            temp_body['delivery_status_icon'] = orders[line][key].icon_url_dark;
            temp_body['delivery_status_name'] = orders[line][key].name;
          } else if (key === 'total_tax_excluded_formatted') {
            temp_body['price'] = orders[line].total_tax_excluded_formatted;
          }
        }
      }

      tableBody.push(temp_body);
    }

    return tableBody;
  }

  function renderTable(data) {
    if (data && data.length) {
      const tableBody = getTableBodyFromData(data);

      const tableHeader = [
        <TableRow key="tableHeader">
          <TableCell>{translation().views.orders.list.table_header.id}</TableCell>
          <TableCell>{translation().views.orders.list.table_header.products}</TableCell>
          <TableCell>{translation().views.orders.list.table_header.address}</TableCell>
          <TableCell>{translation().views.orders.list.table_header.app}</TableCell>
          <TableCell>{translation().views.orders.list.table_header.total}</TableCell>
          <TableCell>{translation().views.orders.list.table_header.date}</TableCell>
          <TableCell>{translation().views.orders.list.table_header.cancelled}</TableCell>
          <TableCell>{translation().views.orders.list.table_header.status}</TableCell>
          <TableCell />
        </TableRow>
      ];

      const tableValues = tableBody.map((row, index) => {
        const typoClass = classNames({
          'order-cancelled': row.cancelDate,
          [`${classes.labelImportant}`]: row.isOrderFlag
        });

        return (
          <TableRow key={index}>
            <TableCell className={typoClass}>
              <Typography variant="body2">{row.id}</Typography>
            </TableCell>
            <TableCell className={typoClass} style={{ maxWidth: 250 }}>
              {row.products.map((product, i) => (
                <Typography
                  key={i}
                  component="p"
                  variant="subtitle2"
                  gutterBottom={false}
                  style={i > 0 ? { minWidth: 150, marginTop: 4 } : { minWidth: 150 }}>
                  {`${product.name}: `}
                  <Typography component="span" gutterBottom variant="body2">
                    {product.start_tz} | {product.provider_name}
                    {row.timezone && product.start && (
                      <LocalDateInfo
                        timezone={row.timezone}
                        date={product.start}
                        className={classNames({
                          'order-cancelled': row.cancelDate
                        })}
                      />
                    )}
                  </Typography>
                </Typography>
              ))}
            </TableCell>
            <TableCell className={typoClass}>
              <Typography variant="body2">{row.address}</Typography>
            </TableCell>
            <TableCell className={typoClass}>
              <Typography variant="body2">{row.application}</Typography>
            </TableCell>
            <TableCell className={typoClass}>
              <Typography variant="body2">{row.price}</Typography>
            </TableCell>
            <TableCell className={typoClass}>
              <Typography variant="body2">{row.orderDate}</Typography>
            </TableCell>
            <TableCell className={typoClass}>
              <Typography variant="body2">{row.cancelDate || '-'}</Typography>
            </TableCell>
            <TableCell>
              <img
                className={classes.iconStatus}
                src={row.delivery_status_icon}
                title={row.delivery_status_name}
                alt={row.delivery_status_name}
              />
            </TableCell>
            <TableCell key={'action-' + index} style={{ textAlign: 'right' }}>
              <Tooltip title={translation().actions.update}>
                <IconButton
                  className={classes.updateOrder}
                  component={CustomLink}
                  link={`/orders/${row.id}/update`}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        );
      });

      return (
        <FixedHeaderTable
          tableHeader={tableHeader}
          tableValues={tableValues}
          metadata={orders.metadata}
          onScrollList={handleScrollList}
          isLoading={orders.isLoading}
          className={classes.tableContainer}
          useMarginBottom={false}
        />
      );
    }
  }

  return (
    <div className={classNames('UserOrders', classes.root)}>
      <Paper className={classes.paper}>
        {!orders.metadata.isLoading && orders.data && orders.data.length
          ? renderTable(orders.data)
          : false}
        {!orders.isLoading && orders.error && (!orders.data || orders.data.length <= 0) ? (
          <div className="error-list">
            <Alert
              type="danger"
              style={{ marginTop: 10 }}
              status={translation().commons.alerts.error}
              text={translation().core.list.error_list}
            />
          </div>
        ) : (
          false
        )}
        {(orders.isLoading || orders.metadata.isLoading) && !orders.paging && (
          <>
            <div className={classes.loadingWrapper} />
            <div className="loader-wpr">
              <CircularProgress color="primary" />
              <p>{translation().views.orders.list.loading_text}</p>
              {orders.metadata.isLoading && <p>{translation().core.load_metadata}</p>}
            </div>
          </>
        )}
        {!orders.isLoading &&
          !orders.metadata.isLoading &&
          !orders.error &&
          (!orders.data || !orders.data.length) && (
            <div className="loader-wpr">
              <ListEmptyIcon />
              <p style={{ margin: '7px 0', lineHeight: 'initial' }}>
                {translation().core.list.empty}
              </p>
            </div>
          )}
        {!orders.metadata.isLoading &&
        !orders.isLoading &&
        !orders.paging &&
        orders.data &&
        orders.data.length > 0 ? (
          <div className="loader-wpr linear">
            <p>{translation().core.list.load_next_done}</p>
          </div>
        ) : (
          false
        )}
        {orders.isLoading && orders.paging && orders.paging.next ? (
          <div style={{ height: 40 }}>
            <LinearProgress color="primary" />
            <div className="loader-wpr linear">
              <p>{translation().core.list.load_next}</p>
            </div>
          </div>
        ) : (
          <div className="end-of-list-wpr">
            {!orders.metadata.isLoading &&
              !orders.isLoading &&
              orders.paging &&
              !orders.paging.next &&
              orders.paging.prev && <p>{translation().core.list.load_next_done}</p>}
          </div>
        )}
      </Paper>
    </div>
  );
}

UserOrders.propTypes = {
  cleanUserViewOrdersReducer: PropTypes.func.isRequired,
  getUserViewOrders: PropTypes.func.isRequired,
  getOrdersMetadata: PropTypes.func.isRequired,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  orders: PropTypes.shape({
    metadata: PropTypes.shape({
      error: PropTypes.string,
      isLoading: PropTypes.bool
    }),
    data: PropTypes.arrayOf(PropTypes.shape()),
    error: PropTypes.string,
    isLoading: PropTypes.bool,
    paging: PropTypes.shape({
      next: PropTypes.string,
      prev: PropTypes.string
    })
  }).isRequired
};

export default UserOrders;
