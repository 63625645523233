import './restrictedArea.scss';

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import translation from '../../translation/translation';

function RestrictedArea(props) {
  useEffect(() => {
    document.title = 'Actarus | Permission denied';
  }, []);

  return (
    <div className="Restricted-area">
      <img src="/assets/images/commons/not-allowed.svg" alt="Not allowed" />
      <Typography variant="h3" gutterBottom>
        {translation().views.restricted_area.title}
      </Typography>
      <Typography component="p">{translation().views.restricted_area.text}</Typography>
      <Button variant="outlined" color="primary" onClick={props.handleSignOut}>
        {translation().views.restricted_area.back}
      </Button>
    </div>
  );
}

RestrictedArea.propTypes = {
  handleSignOut: PropTypes.func.isRequired
};

export default RestrictedArea;
