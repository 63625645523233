import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  Tooltip,
  Typography
} from '@material-ui/core';
import {
  Bookmarks as BookmarksIcon,
  ExpandMore as ExpandMoreIcon,
  Group as GroupIcon,
  Label as LabelIcon,
  OpenInNewOutlined as OpenInNewIcon,
  ShoppingCart as ShoppingCartIcon,
  ToggleOn as ToggleOnIcon
} from '@material-ui/icons';

import InputBox from '../../forms/inputs/InputBox';
import InputPriority from '../../forms/inputs/InputPriority';
import TaskActions from '../actions/TaskActions';
import { dateInTz } from '../../../../../../utils/functions/dates';
import { getUserName } from '../../../../../../utils/functions/user';
import translation from '../../../../../../translation/translation';
import InputAssignedUser from '../../forms/inputs/InputAssignedUser';
import InputAttachments from '../../forms/inputs/InputAttachments';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0.5),
    width: 345
  },
  archived: {
    backgroundColor: theme.palette.type === 'dark' ? '#212121' : '#f0f0f0',
    opacity: 0.36
  },
  grow: {
    flexGrow: 1
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  head: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 16px'
  },
  prop: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 32
  },
  description: {
    minHeight: 48
  },
  descriptionText: {
    color: theme.palette.text.secondary,
    fontSize: 12
  },
  taskLabel: {
    fontWeight: 500,
    fontSize: 14
  },
  detail: {
    fontSize: 12
  },
  detailIcon: {
    marginRight: 4
  },
  detailLabel: {
    marginRight: 4,
    fontSize: 12
  },
  completionStatus: {
    position: 'relative',
    borderRadius: 4,
    width: 'inherit',
    minWidth: 100
  },
  assignedTeam: {
    minWidth: 100
  }
}));

function TaskCard(props) {
  const classes = useStyles();

  const {
    archived,
    assigned_team,
    assigned_user,
    attachments,
    closed,
    completion_status,
    completionStatuses,
    created,
    description,
    due,
    id,
    label,
    order_product_option,
    order_product,
    order,
    priority,
    started,
    onAssignSelf,
    onCompleteTask,
    onDeleteTask,
    onOpenHistory,
    teams,
    topic,
    updated,
    updateTaskProps,
    user,
    userLevel,
    userLogged,
    viewProps
  } = props;

  const columns =
    viewProps && viewProps.fields && viewProps.fields.length ? viewProps.fields.split(',') : [];

  const write = userLevel && userLevel !== 'viewer' && userLevel !== 'operator';

  const [expanded, setExpanded] = useState(false);

  const dataCollapsed = [
    'archived',
    'closed',
    'created',
    'order_product_option',
    'order_product',
    'order',
    'started',
    'updated',
    'user'
  ];

  function handleExpandClick() {
    setExpanded(!expanded);
  }

  function handleChangeColumn(id, value, onSuccess, onError) {
    if (viewProps.id && id) {
      updateTaskProps(viewProps.id, id, value, onSuccess, onError);
    }
  }

  return (
    <Card className={classNames(classes.root, { [classes.archived]: archived })}>
      <CardHeader
        avatar={
          columns.indexOf('assigned_user') > -1 ? (
            <InputAssignedUser
              index={id}
              id={id}
              data={assigned_user}
              userLogged={userLogged}
              users={viewProps.users}
              onChange={handleChangeColumn}
              write={write}
            />
          ) : null
        }
        action={
          <TaskActions
            id={id}
            onAssignSelf={onAssignSelf}
            onComplete={onCompleteTask}
            onDelete={onDeleteTask}
            onOpenHistory={onOpenHistory}
            userLevel={userLevel}
          />
        }
        title={
          label && columns.indexOf('label') > -1 ? (
            <InputBox
              fullWidth={false}
              id={id}
              boxComponent="span"
              name="label"
              value={label}
              type="text"
              onChange={handleChangeColumn}
              outputClassName={classes.taskLabel}
              write={write}
            />
          ) : (
            ''
          )
        }
        subheaderTypographyProps={{
          style: { lineHeight: 2 }
        }}
        subheader={
          columns.indexOf('due') > -1 ? (
            <span className={classes.prop}>
              <InputBox
                fullWidth={false}
                id={id}
                boxComponent="span"
                name="due"
                value={due}
                type="datetime-local"
                onChange={handleChangeColumn}
                write={write}
              />
            </span>
          ) : (
            ''
          )
        }
      />
      <Box className={classes.head}>
        {columns.indexOf('assigned_team') > -1 && (
          <Box pb={1} className={classes.prop}>
            <Tooltip title={translation().views.task_views.tasks.labels.team}>
              <GroupIcon color="action" className={classes.detailIcon} />
            </Tooltip>
            <InputBox
              fullWidth
              id={id}
              boxComponent="span"
              boxClassName={classes.assignedTeam}
              name="assigned_iam_team_id"
              targetKey="id"
              referenceKey="assigned_team"
              value={assigned_team}
              selectData={teams.data}
              loading={teams.loading}
              placeholder={translation().views.task_views.tasks.labels.team}
              type="select"
              getFullObject
              entityHref="teams"
              onChange={handleChangeColumn}
              write={write}
            />
          </Box>
        )}
        {columns.indexOf('priority') > -1 && (
          <Box mr={-1}>
            {priority ? (
              <InputPriority
                variant="flag"
                id={id}
                name="priority"
                value={priority}
                onChange={handleChangeColumn}
                write={write}
              />
            ) : (
              '-'
            )}
          </Box>
        )}
      </Box>
      <CardContent>
        {columns.indexOf('description') > -1 && (
          <Box pb={2}>
            <InputBox
              id={id}
              boxComponent="div"
              name="description"
              value={description}
              type="textarea"
              onChange={handleChangeColumn}
              className={classes.description}
              outputClassName={classes.descriptionText}
              placeholder={translation().views.task_views.tasks.labels.desc}
              write={write}
            />
          </Box>
        )}
        {columns.indexOf('order') > -1 && (
          <Box className={classes.prop}>
            <Tooltip title={translation().views.task_views.tasks.labels.order}>
              <ShoppingCartIcon color="action" className={classes.detailIcon} />
            </Tooltip>
            <InputBox
              fullWidth={false}
              id={id}
              boxComponent="span"
              targetKey="id"
              referenceKey="order"
              name="shop_order_id"
              value={order}
              type="number"
              placeholder={translation().views.task_views.tasks.labels.order}
              onChange={handleChangeColumn}
              outputClassName={classes.detail}
              write={write}
            />
            {order && order.id && (
              <IconButton
                size="small"
                component="a"
                target="_blank"
                rel="noopener noreferrer"
                href={`/orders/${order.id}/update`}>
                <OpenInNewIcon style={{ fontSize: 16 }} />
              </IconButton>
            )}
          </Box>
        )}
      </CardContent>
      <CardActions disableSpacing style={{ position: 'relative' }}>
        {columns.indexOf('completion_status') > -1 && (
          <InputBox
            id={id}
            targetKey="id"
            name="completion_status_id"
            referenceKey="completion_status"
            value={completion_status}
            getFullObject
            type="select"
            selectData={completionStatuses.data}
            loading={completionStatuses.loading}
            boxClassName={classes.completionStatus}
            placeholder={translation().views.task_views.tasks.labels.completion_status}
            onChange={handleChangeColumn}
            write={write || (userLevel && userLevel === 'operator')}
          />
        )}
        <div className={classes.grow} />
        <InputAttachments
          taskId={id}
          viewId={viewProps?.id}
          attachments={attachments}
          disabled={!write}
        />
        {dataCollapsed.some((r) => columns.indexOf(r) >= 0) && (
          <IconButton
            className={classNames(classes.expand, {
              [classes.expandOpen]: expanded
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more">
            <ExpandMoreIcon />
          </IconButton>
        )}
      </CardActions>
      {dataCollapsed.some((r) => columns.indexOf(r) >= 0) && (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            {columns.indexOf('topic') > -1 && (
              <Box className={classes.prop}>
                <Tooltip title={translation().views.task_views.tasks.labels.topic}>
                  <BookmarksIcon color="action" className={classes.detailIcon} />
                </Tooltip>
                <InputBox
                  id={id}
                  boxComponent="div"
                  name="topic"
                  value={topic}
                  type="text"
                  placeholder={translation().views.task_views.tasks.labels.topic}
                  onChange={handleChangeColumn}
                  write={write}
                />
              </Box>
            )}
            {columns.indexOf('order_product') > -1 && (
              <Box className={classes.prop}>
                <Tooltip title={translation().views.task_views.tasks.labels.order_product}>
                  <LabelIcon color="action" className={classes.detailIcon} />
                </Tooltip>
                <InputBox
                  fullWidth={false}
                  id={id}
                  boxComponent="span"
                  targetKey="id"
                  referenceKey="order_product"
                  name="shop_order_product_id"
                  value={order_product}
                  type="number"
                  onChange={handleChangeColumn}
                  outputClassName={classes.detail}
                  placeholder={translation().views.task_views.tasks.labels.order_product}
                  write={write}
                />
              </Box>
            )}
            {columns.indexOf('order_product_option') > -1 && (
              <Box className={classes.prop}>
                <Tooltip title={translation().views.task_views.tasks.labels.order_product_option}>
                  <ToggleOnIcon color="action" className={classes.detailIcon} />
                </Tooltip>
                <InputBox
                  fullWidth={false}
                  id={id}
                  boxComponent="span"
                  targetKey="id"
                  referenceKey="order_product_option"
                  name="shop_order_product_option_id"
                  value={order_product_option}
                  type="number"
                  onChange={handleChangeColumn}
                  outputClassName={classes.detail}
                  placeholder={translation().views.task_views.tasks.labels.order_product_option}
                  write={write}
                />
              </Box>
            )}
            {columns.indexOf('created') > -1 && (
              <Box className={classes.prop}>
                <Typography component="span" color="textSecondary" className={classes.detailLabel}>
                  {translation().views.task_views.tasks.labels.created}:{' '}
                </Typography>
                <Typography className={classes.detail}>
                  {created ? dateInTz(created, 'D MMM HH:mm') : '-'}
                </Typography>
                {columns.indexOf('user') > -1 && (
                  <Typography className={classes.detail} style={{ marginLeft: 4 }}>
                    ({user ? getUserName(user) : '-'})
                  </Typography>
                )}
              </Box>
            )}
            {columns.indexOf('updated') > -1 && (
              <Box className={classes.prop}>
                <Typography component="span" color="textSecondary" className={classes.detailLabel}>
                  {translation().views.task_views.tasks.labels.updated}:{' '}
                </Typography>
                <Typography className={classes.detail}>
                  {updated ? dateInTz(updated, 'D MMM HH:mm') : '-'}
                </Typography>
              </Box>
            )}
            {columns.indexOf('started') > -1 && (
              <Box className={classes.prop}>
                <Typography component="span" color="textSecondary" className={classes.detailLabel}>
                  {translation().views.task_views.tasks.labels.started}:{' '}
                </Typography>
                <Typography className={classes.detail}>
                  {started ? dateInTz(started, 'D MMM HH:mm') : '-'}
                </Typography>
              </Box>
            )}
            {columns.indexOf('closed') > -1 && (
              <Box className={classes.prop}>
                <Typography component="span" color="textSecondary" className={classes.detailLabel}>
                  {translation().views.task_views.tasks.labels.closed}:{' '}
                </Typography>
                <Typography className={classes.detail}>
                  {closed ? dateInTz(closed, 'D MMM HH:mm') : '-'}
                </Typography>
              </Box>
            )}
            {columns.indexOf('archived') > -1 && (
              <Box className={classes.prop}>
                <Typography component="span" color="textSecondary" className={classes.detailLabel}>
                  {translation().views.task_views.tasks.labels.archived}:{' '}
                </Typography>
                <Typography className={classes.detail}>
                  {archived ? dateInTz(archived, 'D MMM HH:mm') : '-'}
                </Typography>
              </Box>
            )}
          </CardContent>
        </Collapse>
      )}
    </Card>
  );
}

TaskCard.propTypes = {
  archived: PropTypes.string,
  assigned_team: PropTypes.shape(),
  assigned_user: PropTypes.shape(),
  attachments: PropTypes.arrayOf(PropTypes.shape()),
  closed: PropTypes.string,
  completion_status: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    shortcode: PropTypes.string,
    is_closed: PropTypes.bool,
    color: PropTypes.string
  }),
  completionStatuses: PropTypes.shape({
    loading: PropTypes.bool,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        color: PropTypes.string,
        id: PropTypes.number,
        name: PropTypes.string
      })
    ).isRequired
  }).isRequired,
  teams: PropTypes.shape({
    loading: PropTypes.bool,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string
      })
    )
  }).isRequired,
  topic: PropTypes.string,
  created: PropTypes.string,
  description: PropTypes.string,
  due: PropTypes.string,
  id: PropTypes.number,
  label: PropTypes.string,
  order: PropTypes.shape(),
  order_product: PropTypes.shape(),
  order_product_option: PropTypes.shape(),
  priority: PropTypes.number,
  started: PropTypes.string,
  onAssignSelf: PropTypes.func.isRequired,
  onCompleteTask: PropTypes.func.isRequired,
  onDeleteTask: PropTypes.func.isRequired,
  onOpenHistory: PropTypes.func.isRequired,
  updated: PropTypes.string,
  updateTaskProps: PropTypes.func.isRequired,
  user: PropTypes.shape(),
  userLevel: PropTypes.oneOf(['admin', 'manager', 'editor', 'operator', 'viewer']),
  userLogged: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  viewProps: PropTypes.shape({
    id: PropTypes.number,
    fields: PropTypes.string,
    users: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        level: PropTypes.oneOf(['admin', 'manager', 'editor', 'operator', 'viewer']),
        user: PropTypes.shape({
          id: PropTypes.number,
          first_name: PropTypes.string,
          last_name: PropTypes.string,
          email: PropTypes.string
        })
      })
    )
  })
};

export default TaskCard;
