import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AppBar from '@material-ui/core/AppBar';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import RefreshIcon from '@material-ui/icons/Refresh';
import Tooltip from '@material-ui/core/Tooltip';

import { isNavigationBackPossible, navigateBack } from '../../../../../utils/functions/navigation';
import translation from '../../../../../translation/translation';

const styles = (theme) => ({
  root: {
    width: '100%'
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
});

class UpdateDashboardMetricsBar extends PureComponent {
  state = {
    mobileMoreAnchorEl: null
  };

  handleMobileMenuOpen = (event) => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  handleHistory = () => {
    const { history } = this.props;

    navigateBack(history);
  };

  render() {
    const { mobileMoreAnchorEl } = this.state;
    const { classes, dashboardName, history, handleRefresh, loading } = this.props;

    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const renderMobileMenu = isNavigationBackPossible(history) ? (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        onClose={this.handleMobileMenuClose}>
        <MenuItem onClick={this.handleHistory}>
          <ArrowBackIcon fontSize="small" style={{ marginRight: 8 }} />
          <p>{translation().actions.back}</p>
        </MenuItem>
      </Menu>
    ) : (
      false
    );

    return (
      <div className={classes.root}>
        <AppBar elevation={0} position="static" color="default">
          <Toolbar>
            <div className={classes.sectionDesktop}>
              <Breadcrumbs
                maxItems={5}
                separator={<NavigateNextIcon fontSize="small" />}
                arial-label="Update dashboard breadcrumb">
                <Typography variant="body2" color="textPrimary">
                  {translation().metrics.dashboard.list.title}
                </Typography>
                <Typography variant="body2" color="textPrimary">
                  {dashboardName}
                </Typography>
              </Breadcrumbs>
            </div>
            <div className={classes.sectionMobile}>
              <Typography variant="body2" color="textPrimary">
                {translation().metrics.dashboard.title}
              </Typography>
            </div>
            <IconButton
              aria-label="Refresh moderation"
              color="default"
              disabled={loading}
              style={{ padding: 5, marginLeft: 7 }}
              onClick={handleRefresh}>
              <RefreshIcon />
            </IconButton>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              {isNavigationBackPossible(history) && (
                <Tooltip
                  title={translation().actions.back || 'Back'}
                  aria-label={translation().actions.back || 'Back'}
                  disabled={loading}
                  enterDelay={700}>
                  <div>
                    <IconButton
                      onClick={this.handleHistory}
                      color="inherit"
                      className={classes.backIcon}
                      disabled={loading}
                      style={{ marginLeft: 7 }}>
                      <ArrowBackIcon fontSize="small" />
                    </IconButton>
                  </div>
                </Tooltip>
              )}
            </div>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-haspopup="true"
                onClick={this.handleMobileMenuOpen}
                color="inherit"
                disabled={loading}
                style={{ marginLeft: 7 }}>
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
      </div>
    );
  }
}

UpdateDashboardMetricsBar.defaultProps = {
  loading: false
};

UpdateDashboardMetricsBar.propTypes = {
  dashboardName: PropTypes.string,
  loading: PropTypes.bool,
  classes: PropTypes.shape().isRequired,
  handleRefresh: PropTypes.func.isRequired,
  history: PropTypes.shape().isRequired
};

export default withStyles(styles)(UpdateDashboardMetricsBar);
