import React, { useState } from 'react';
import PropTypes from 'prop-types';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

import FormSelectField from '../../../../components/forms/fields/FormSelectField';
import translation from '../../../../translation/translation';

function EditInvoiceAddressButton(props) {
  const {
    editOrderInvoiceAddress,
    initialAddress,
    invoiceAddresses,
    getOrderClientAddresses,
    onSuccess,
    orderId,
    user
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(null);

  function handleOpenDialog() {
    setIsOpen(true);
  }

  function handleCloseDialog() {
    setIsOpen(false);
    setValue(null);
  }

  function handleSelect(id) {
    setValue(id);
  }

  function handleDialogEnter() {
    if (user && user.id) getOrderClientAddresses(user.id);
  }

  function handleSubmit() {
    if (!value) return;

    editOrderInvoiceAddress(orderId, value, onSuccess);
  }

  return (
    <>
      <IconButton edge="end" size="small" aria-label="Add" onClick={handleOpenDialog}>
        <EditIcon fontSize="small" />
      </IconButton>
      <Dialog
        fullWidth
        open={isOpen ? true : false}
        onClose={handleCloseDialog}
        aria-labelledby="add-order-discount"
        aria-describedby="add-order-discount-description"
        disableBackdropClick={invoiceAddresses.isLoading || invoiceAddresses.edit_action.isLoading}
        onEnter={handleDialogEnter}>
        <DialogTitle id="add-order-discount">
          {translation().views.orders.update.invoicing.dialog_title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="add-order-discount-description">
            {initialAddress ? (
              <>
                <Typography component="span" style={{ fontWeight: 500 }}>
                  {translation().views.orders.update.invoicing.dialog_desc}:{' '}
                </Typography>
                {(initialAddress.name ? initialAddress.name + ' ' : '') +
                  (initialAddress.street_number ? initialAddress.street_number + ' ' : ' ') +
                  (initialAddress.street ? initialAddress.street + ' ' : ' ') +
                  (initialAddress.zip_code ? initialAddress.zip_code + ' ' : ' ') +
                  (initialAddress.city ? initialAddress.city + ' ' : '')}
              </>
            ) : (
              translation().views.orders.update.invoicing.no_address
            )}
          </DialogContentText>
          {invoiceAddresses && invoiceAddresses.data && invoiceAddresses.data.length > 0 ? (
            <FormSelectField
              displayEmpty
              fullWidth
              disabled={invoiceAddresses.isLoading || invoiceAddresses.edit_action.isLoading}
              onChange={handleSelect}
              id="order_client_addresses"
              name="order_client_addresses"
              label={translation().views.orders.create.payment.invoicing.addresses.label}
              value={value}
              required>
              {invoiceAddresses.data.map((address, index) => (
                <MenuItem key={index} value={address.id}>
                  {address.name +
                    ' (' +
                    address.street_number +
                    ' ' +
                    address.street +
                    ' ' +
                    (address.zip_code ? address.zip_code + ' ' : ' ') +
                    address.city +
                    ')'}
                </MenuItem>
              ))}
            </FormSelectField>
          ) : (
            !invoiceAddresses.isLoading && (
              <Typography>
                {translation().views.orders.create.payment.invoicing.addresses.empty}
              </Typography>
            )
          )}
          <div className="sm-space" />
          {invoiceAddresses.isLoading && (
            <div className="loader-wpr" style={{ marginTop: 15 }}>
              <CircularProgress size={30} color="secondary" />
              <p>{translation().views.orders.create.payment.invoicing.addresses.loading_text}</p>
            </div>
          )}
          {invoiceAddresses.edit_action.isLoading && (
            <div className="loader-wpr" style={{ marginTop: 15 }}>
              <CircularProgress size={30} color="secondary" />
              <p>{translation().views.orders.update.invoicing.edit_loading}</p>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            color="default"
            disabled={invoiceAddresses.isLoading || invoiceAddresses.edit_action.isLoading}>
            {translation().actions.cancel}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={invoiceAddresses.edit_action.isLoading || !value}>
            {translation().actions.confirm}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

EditInvoiceAddressButton.propTypes = {
  editOrderInvoiceAddress: PropTypes.func.isRequired,
  initialAddress: PropTypes.any,
  invoiceAddresses: PropTypes.shape().isRequired,
  getOrderClientAddresses: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  orderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  user: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired
};

export default EditInvoiceAddressButton;
