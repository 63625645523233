import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import { getLocale } from '../../../../../../utils/functions/cookies';
import { pad } from '../../../../../../utils/functions/utils';
import CreateAvailabilityForm from '../forms/availabilities/create/CreateAvailabilityForm';
import translation from '../../../../../../translation/translation';

const styles = (theme) => ({
  backgroundModal: {
    backgroundColor: theme.palette.background.paper
  }
});

function CreateAvailabilityPopup(props) {
  function handleCreateAvailability(values) {
    if (!values) return;

    const { createAvailability, slot } = props;

    if (slot) {
      createAvailability(values, slot);
    }
  }

  function handleBlockBlur(e) {
    if (e) {
      e.stopPropagation();
    }
  }

  const { createAvailabilityLoading, classes, maxHour, minHour, onClose, slot } = props;

  const startHour = pad(slot.start.getHours().toString());
  const startMinutes = pad(slot.start.getMinutes().toString());
  const endHour = pad(slot.end.getHours().toString());
  const endMinutes = pad(slot.end.getMinutes().toString());

  return (
    <div className="event-popup">
      <div className="create-availability">
        <div className="event-pp-header">
          <div className="event-actions">
            <IconButton
              aria-label="Close"
              className="event-action"
              disabled={createAvailabilityLoading}
              onClick={onClose}
              onBlur={handleBlockBlur}
              size="small">
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>
          <div className="event-title">
            <Typography>
              {translation().views.provider_schedule.availability.create.title}
            </Typography>
          </div>
        </div>
        <div className={classNames('event-pp-content', classes.backgroundModal)}>
          <div className="new-availability">
            <span className="detail">
              <span className="icon-horaire date-icon" />
              <div className="detail-date">
                <Typography variant="subtitle2">
                  {slot.start.toLocaleDateString(getLocale(), {
                    weekday: 'long',
                    month: 'long',
                    day: 'numeric'
                  })}
                </Typography>
              </div>
            </span>
          </div>
          <CreateAvailabilityForm
            onSubmit={handleCreateAvailability}
            onBlur={handleBlockBlur}
            minHour={minHour}
            maxHour={maxHour}
            initialValues={{
              hour_end: endHour,
              hour_start: startHour,
              min_end: endMinutes,
              min_start: startMinutes
            }}
            createAction={{
              loading: createAvailabilityLoading
            }}
          />
        </div>
      </div>
    </div>
  );
}

CreateAvailabilityPopup.propTypes = {
  createAvailability: PropTypes.func.isRequired,
  createAvailabilityLoading: PropTypes.bool,
  classes: PropTypes.shape(),
  maxHour: PropTypes.number.isRequired,
  minHour: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  slot: PropTypes.shape({
    start: PropTypes.any.isRequired,
    end: PropTypes.any.isRequired
  }).isRequired
};

export default withStyles(styles)(CreateAvailabilityPopup);
