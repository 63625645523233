import React from 'react';

function MouseClickRight(props) {
  return (
    <svg
      width="30"
      height="30"
      version="1.1"
      viewBox="0 0 700 700"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}>
      <path
        d="m350 525c90.207 0 163.33-73.125 163.33-163.33v-163.34c0-90.207-73.125-163.33-163.33-163.33s-163.33 73.125-163.33 163.33v163.34c0 90.207 73.125 163.33 163.33 163.33zm140-256.67h-280v93.336c0 77.312 62.684 140 140 140s140-62.688 140-140zm-118.03-63.195c-3.1289 2.0742-6.6289 3.5234-10.301 4.2695v35.594h78.562zm13.031-46.434v22.129c0 1.5781-0.12891 3.1406-0.375 4.668l101.86 59.5h3.5117v-24.965zm-46.668-53.109v-46.781c-71.855 5.9258-128.33 66.125-128.33 139.52v46.668h128.33v-35.594c-5.5625-1.1328-10.719-3.875-14.793-7.9453-5.4727-5.4727-8.5391-12.895-8.5391-20.629v-46.664c0-7.7344 3.0664-15.156 8.5391-20.629 4.0742-4.0703 9.2305-6.8125 14.793-7.9453zm23.336-14.57v14.57c5.5625 1.1328 10.719 3.875 14.793 7.9453 4.8633 4.8672 7.8281 11.27 8.4219 18.074l105.01 61.328c-0.38281-10.148-1.8438-20.031-4.2812-29.516zm-23.336 89.809v-46.664c0-1.5547 0.62109-3.0352 1.7031-4.1328 1.0977-1.082 2.5781-1.7031 4.1328-1.7031h11.664c1.5547 0 3.0352 0.62109 4.1328 1.7031 1.082 1.0977 1.7031 2.5781 1.7031 4.1328v46.664c0 1.5547-0.62109 3.0352-1.7031 4.1328-1.0977 1.082-2.5781 1.7031-4.1328 1.7031h-11.664c-1.5547 0-3.0352-0.62109-4.1328-1.7031-1.082-1.0977-1.7031-2.5781-1.7031-4.1328zm23.336-116.83 109.33 63.863c-22.426-38.43-62.629-65.207-109.33-69.055z"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default MouseClickRight;
