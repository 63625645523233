import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DeleteIcon from '@material-ui/icons/Delete';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';

import config from '../../../../config';
import translation from '../../../../translation/translation';

const useStyles = makeStyles(() => ({
  deleteButton: {
    color: config.error_color || '#F44336',
    '&:hover': {
      backgroundColor: fade(config.error_color || '#F44336', 0.15)
    }
  }
}));

function DeleteDiscountButton(props) {
  const { deleteDiscount, deleteOrderDiscount, id } = props;

  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  function handleOpenDialog() {
    setIsOpen(true);
  }

  function handleCloseDialog() {
    setIsOpen(false);
  }

  function handleSubmit() {
    deleteOrderDiscount(id);
  }

  return (
    <>
      <IconButton edge="end" aria-label="Delete" size="small" onClick={handleOpenDialog}>
        <DeleteIcon fontSize="small" />
      </IconButton>
      <Dialog
        open={isOpen ? true : false}
        onClose={handleCloseDialog}
        aria-labelledby="delete-order-discount"
        aria-describedby="delete-order-discount-description"
        disableBackdropClick={deleteDiscount.isLoading}>
        <DialogTitle id="delete-order-discount">
          {translation().views.orders.update.discounts.delete.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-order-discount-description">
            {translation().views.orders.update.discounts.delete.content}
          </DialogContentText>
          {deleteDiscount.isLoading && (
            <div className="loader-wpr" style={{ marginTop: 15 }}>
              <CircularProgress size={30} color="secondary" />
              <p>{translation().views.orders.update.discounts.delete.load_delete}</p>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="default" disabled={deleteDiscount.isLoading}>
            {translation().actions.cancel}
          </Button>
          <Button
            onClick={handleSubmit}
            className={classes.deleteButton}
            disabled={deleteDiscount.isLoading}>
            {translation().actions.delete}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

DeleteDiscountButton.propTypes = {
  deleteDiscount: PropTypes.shape({
    isLoading: PropTypes.bool
  }).isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  deleteOrderDiscount: PropTypes.func.isRequired
};

export default DeleteDiscountButton;
