import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { enqueueSnackbar } from '../../../redux/actions/appActions';
import CreatePaymentMethod from '../../../views/core/customCreateActions/CreatePaymentMethod';

const mapDispatchToProps = (dispatch) => {
  return {
    enqueueSnackbar: (message, options) => {
      if (!message || !options || !options.variant) return;

      dispatch(
        enqueueSnackbar({
          message,
          status: undefined,
          options: {
            ...options
          }
        })
      );
    }
  };
};

export default withRouter(connect(null, mapDispatchToProps)(CreatePaymentMethod));
