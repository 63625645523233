import classNames from 'classnames';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Grid, IconButton, MenuItem, Paper, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import {
  FormApiAutocompleteField,
  FormPhoneField,
  FormSelectField,
  FormSliderField,
  FormSwitchField,
  FormTextField,
  FormTimeField,
  LocationField,
  TimezoneField
} from '../../../../../components/forms/fields';

import config from '../../../../../config';
import translation from '../../../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  addressNotFound: {
    textDecoration: 'underline',
    cursor: 'pointer',
    userSelect: 'none',
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  cancelButton: {
    color: config.error_color || '#F44336',
    '&:hover': {
      backgroundColor: fade(config.error_color || '#F44336', 0.15)
    }
  },
  divider: {
    height: 50
  },
  title: {
    textAlign: 'center',
    fontSize: 18
  },
  formContainer: {
    minHeight: 850,
    position: 'relative'
  },
  box: {
    backgroundColor: 'rgba(181, 197, 255, 0.2)',
    borderRadius: 4,
    padding: '0px 12px 12px',
    marginTop: 8,
    position: 'relative'
  },
  closeBox: {
    position: 'absolute',
    top: 4,
    right: 4,
    zIndex: 1
  }
}));

function Provider(props) {
  const classes = useStyles();
  const initialLocation = { lat: '', lng: '' };

  const [isGhost, setIsGhost] = useState(false);
  const [addressNotFoundOpen, setAddressNotFoundOpen] = useState(false);
  const [location, setLocation] = useState(initialLocation);

  const { disableGhost, onChange, onChangeProvider, provider, timezone, user } = props;

  const handleChange = (name) => (value) => {
    onChange(name, value);
  };

  const handleChangeProvider = (name) => (value) => {
    onChangeProvider(name, value);

    if (value && value.lat && value.lng) {
      setLocation(value);
    }
  };

  function handleChangeLat(value) {
    setLocation((location) => ({ ...location, lat: value }));
    onChangeProvider('base_latitude', value);
  }

  function handleChangeLng(value) {
    setLocation((location) => ({ ...location, lng: value }));
    onChangeProvider('base_longitude', value);
  }

  function handleChangeGhost(value) {
    setIsGhost((checked) => !checked);

    if (!value || value === false) {
      disableGhost();
    } else {
      onChangeProvider('ghost', true);
    }
  }

  function handleToggleAddressNotFound() {
    setAddressNotFoundOpen((open) => !open);
    setLocation(initialLocation);
    onChangeProvider('location', initialLocation);
  }

  return (
    <>
      <Grid item xs={12} sm={12} md={6}>
        <Paper className={classNames(classes.root, classes.formContainer)} elevation={1}>
          <Typography variant="h5" gutterBottom className={classes.title}>
            {translation().views.provider_factory.steps.user_provider.user}
          </Typography>
          <FormTextField
            name="first_name"
            label={translation().views.provider_factory.steps.user_provider.labels.first_name}
            type="text"
            onChange={handleChange('first_name')}
            error={user.error && user.error['first_name'] ? user.error['first_name'] : null}
            required
            disabled={provider.loading || user.loading}
            autoComplete="none"
          />
          <FormTextField
            name="last_name"
            label={translation().views.provider_factory.steps.user_provider.labels.last_name}
            type="text"
            onChange={handleChange('last_name')}
            error={user.error && user.error['last_name'] ? user.error['last_name'] : null}
            disabled={provider.loading || user.loading}
            required
            autoComplete="none"
          />
          <FormTextField
            name="email"
            label={translation().views.provider_factory.steps.user_provider.labels.email}
            type="text"
            onChange={handleChange('email')}
            error={user.error && user.error['email'] ? user.error['email'] : null}
            disabled={provider.loading || user.loading}
            required
            autoComplete="none"
          />
          <FormTextField
            name="password"
            label={translation().views.provider_factory.steps.user_provider.labels.password}
            type="text"
            onChange={handleChange('password')}
            error={user.error && user.error['password'] ? user.error['password'] : null}
            disabled={provider.loading || user.loading}
            required
            autoComplete="none"
          />
          <FormPhoneField
            type="tel"
            disabled={provider.loading || user.loading}
            name="mobile"
            label={translation().views.provider_factory.steps.user_provider.labels.mobile}
            onChange={handleChange('mobile')}
            error={user.error && user.error['mobile'] ? user.error['mobile'] : null}
            required
            autoComplete="none"
          />
          <TimezoneField
            name="timezone_code"
            label={translation().views.provider_factory.steps.user_provider.labels.timezone}
            onInputChange={handleChange('timezone_code')}
            error={user.error && user.error['timezone_code'] ? user.error['timezone_code'] : null}
            disabled={provider.loading || user.loading}
            required
            autoComplete="none"
          />
          <FormApiAutocompleteField
            name="language_locale"
            label={translation().views.provider_factory.steps.user_provider.labels.language}
            links={[{ rel: 'list', href: `${config.api_url}languages` }]}
            onSelectValue={handleChange('language_locale')}
            targetKey="locale"
            error={
              user.error && user.error['language_locale'] ? user.error['language_locale'] : null
            }
            disabled={provider.loading || user.loading}
            required
            autoComplete="none"
          />
          <FormApiAutocompleteField
            name="currency_isocode"
            label={translation().views.provider_factory.steps.user_provider.labels.currency}
            links={[{ rel: 'list', href: `${config.api_url}currencies` }]}
            onSelectValue={handleChange('currency_isocode')}
            targetKey="isocode"
            error={
              user.error && user.error['currency_isocode'] ? user.error['currency_isocode'] : null
            }
            disabled={provider.loading || user.loading}
            required
            autoComplete="none"
          />
          <FormApiAutocompleteField
            name="citizen_country_isocode"
            label={translation().views.provider_factory.steps.user_provider.labels.citizen_country}
            links={[{ rel: 'list', href: `${config.api_url}countries` }]}
            onSelectValue={handleChange('citizen_country_isocode')}
            targetKey="isocode"
            error={
              user.error && user.error['citizen_country_isocode']
                ? user.error['citizen_country_isocode']
                : null
            }
            disabled={provider.loading || user.loading}
            required
            autoComplete="none"
          />
          <FormApiAutocompleteField
            name="residence_country_isocode"
            label={
              translation().views.provider_factory.steps.user_provider.labels.residence_country
            }
            links={[{ rel: 'list', href: `${config.api_url}countries` }]}
            onSelectValue={handleChange('residence_country_isocode')}
            targetKey="isocode"
            error={
              user.error && user.error['residence_country_isocode']
                ? user.error['residence_country_isocode']
                : null
            }
            disabled={provider.loading || user.loading}
            required
            autoComplete="none"
          />
          <FormSelectField
            required
            displayEmpty
            label={translation().views.provider_factory.steps.user_provider.labels.unit_system}
            name="unit_system"
            defaultValue="metric"
            error={user.error && user.error['unit_system'] ? user.error['unit_system'] : null}
            disabled={provider.loading || user.loading}
            onChange={handleChange('unit_system')}
            autoComplete="none">
            {['metric', 'imperial', 'imperial_us'].map((choice, i) => {
              return (
                <MenuItem key={i} value={choice}>
                  {choice}
                </MenuItem>
              );
            })}
          </FormSelectField>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Paper className={classNames(classes.root, classes.formContainer)} elevation={1}>
          <Typography variant="h5" gutterBottom className={classes.title}>
            {translation().views.provider_factory.steps.user_provider.provider}
          </Typography>
          <FormTextField
            name="bio"
            label={translation().views.provider_factory.steps.user_provider.labels.bio}
            type="text"
            onChange={handleChangeProvider('bio')}
            error={provider.error && provider.error['bio'] ? provider.error['bio'] : null}
            disabled={provider.loading || user.loading}
            autoComplete="none"
          />
          {!addressNotFoundOpen && (
            <>
              <LocationField
                name="location"
                onSelect={handleChangeProvider('location')}
                error={
                  provider.error && provider.error['base_latitude']
                    ? provider.error['base_latitude']
                    : null
                }
                label={translation().views.provider_factory.steps.user_provider.labels.location}
                disabled={provider.loading || user.loading}
                required={!addressNotFoundOpen}
                autoComplete="none"
              />
              {!provider.loading && !user.loading && (
                <Typography
                  variant="body2"
                  component="span"
                  color="textSecondary"
                  className={classes.addressNotFound}
                  onClick={handleToggleAddressNotFound}>
                  {translation().views.provider_factory.steps.user_provider.address_not_found}
                </Typography>
              )}
            </>
          )}
          {addressNotFoundOpen && (
            <div className={classes.box}>
              <IconButton
                onClick={handleToggleAddressNotFound}
                className={classes.closeBox}
                size="small">
                <Close fontSize="small" />
              </IconButton>
              <FormTextField
                type="number"
                name="base_latitude"
                label="base_latitude"
                error={
                  provider.error && provider.error['base_latitude']
                    ? provider.error['base_latitude']
                    : null
                }
                value={location.lat}
                onChange={handleChangeLat}
                disabled={provider.loading || user.loading}
                autoComplete="none"
                required={addressNotFoundOpen}
              />
              <FormTextField
                type="number"
                name="base_longitude"
                label="base_longitude"
                value={location.lng}
                error={
                  provider.error && provider.error['base_longitude']
                    ? provider.error['base_longitude']
                    : null
                }
                onChange={handleChangeLng}
                disabled={provider.loading || user.loading}
                required={addressNotFoundOpen}
                autoComplete="none"
              />
            </div>
          )}
          <FormTextField
            name="social_regime"
            label={translation().views.provider_factory.steps.user_provider.labels.social}
            type="text"
            onChange={handleChangeProvider('social_regime')}
            error={
              provider.error && provider.error['social_regime']
                ? provider.error['social_regime']
                : null
            }
            disabled={provider.loading || user.loading}
            autoComplete="none"
            required
          />
          <FormSwitchField
            disabled={provider.loading || user.loading}
            name="active"
            id="is-provider-active"
            defaultValue={true}
            label="active"
            autoComplete="none"
            onChange={handleChangeProvider('active')}
            error={provider.error && provider.error['active'] ? provider.error['active'] : null}
          />
          <FormSwitchField
            disabled={provider.loading || user.loading}
            name="accept_trip"
            id="accept-trip"
            label="accept_trip"
            onChange={handleChangeProvider('accept_trip')}
            autoComplete="none"
            error={
              provider.error && provider.error['accept_trip'] ? provider.error['accept_trip'] : null
            }
          />
          <FormSwitchField
            disabled={provider.loading || user.loading}
            name="ghost"
            id="is-provider-ghost"
            label="ghost"
            onChange={handleChangeGhost}
            autoComplete="none"
            error={provider.error && provider.error['ghost'] ? provider.error['ghost'] : null}
          />
          {isGhost && (
            <div className={classes.box}>
              <FormTextField
                type="number"
                name="ghost_start_delay"
                label="ghost_start_delay"
                onChange={handleChangeProvider('ghost_start_delay')}
                disabled={provider.loading || user.loading}
                autoComplete="none"
                error={
                  provider.error && provider.error['ghost_start_delay']
                    ? provider.error['ghost_start_delay']
                    : null
                }
              />
              <FormTimeField
                name="ghost_start_time"
                label="ghost_start_time"
                timezone={timezone}
                onChange={handleChangeProvider('ghost_start_time')}
                disabled={provider.loading || user.loading}
                error={
                  provider.error && provider.error['ghost_start_time']
                    ? provider.error['ghost_start_time']
                    : null
                }
                autoComplete="none"
                required
              />
              <FormTimeField
                name="ghost_end_time"
                label="ghost_end_time"
                onChange={handleChangeProvider('ghost_end_time')}
                timezone={timezone}
                disabled={provider.loading || user.loading}
                error={
                  provider.error && provider.error['ghost_end_time']
                    ? provider.error['ghost_end_time']
                    : null
                }
                autoComplete="none"
                required
              />
            </div>
          )}
          <FormApiAutocompleteField
            name="transportation_shortcode"
            label={translation().views.provider_factory.steps.user_provider.labels.transport}
            links={[{ rel: 'list', href: `${config.api_url}transportations` }]}
            onSelectValue={handleChangeProvider('transportation_shortcode')}
            targetKey="shortcode"
            error={
              provider.error && provider.error['transportation_shortcode']
                ? provider.error['transportation_shortcode']
                : null
            }
            autoComplete="none"
            disabled={provider.loading || user.loading}
            required
          />
          <FormApiAutocompleteField
            name="tax_rule_shortcode"
            label={translation().views.provider_factory.steps.user_provider.labels.tax_rule}
            links={[{ rel: 'list', href: `${config.api_url}tax-rules` }]}
            onSelectValue={handleChangeProvider('tax_rule_shortcode')}
            targetKey="shortcode"
            error={
              provider.error && provider.error['tax_rule_shortcode']
                ? provider.error['tax_rule_shortcode']
                : null
            }
            disabled={provider.loading || user.loading}
            autoComplete="none"
            required
          />
          <FormSliderField
            id="late_cancel_reversion_percentage"
            label={
              translation().views.provider_factory.steps.user_provider.labels
                .late_cancel_reversion_percentage
            }
            name="late_cancel_reversion_percentage"
            onChange={handleChangeProvider('late_cancel_reversion_percentage')}
            markSuffix="%"
            disabled={provider.loading || user.loading}
          />
        </Paper>
      </Grid>
    </>
  );
}

Provider.propTypes = {
  disableGhost: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeProvider: PropTypes.func.isRequired,
  provider: PropTypes.shape({
    loading: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()])
  }),
  timezone: PropTypes.string,
  user: PropTypes.shape({
    loading: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()])
  })
};

export default Provider;
