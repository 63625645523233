import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';

import FormApiAutocompleteField from '../forms/fields/FormApiAutocompleteField';
import FormDateField from '../forms/fields/FormDateField';
import FormDateTimeField from '../forms/fields/FormDateTimeField';
import FormSelectField from '../forms/fields/FormSelectField';
import FormTextField from '../forms/fields/FormTextField';
import FormTimeField from '../forms/fields/FormTimeField';
import {
  alterDate,
  createDate,
  createDateUtc,
  dateInTz,
  formatDate,
  formatJSDate,
  getCurrentDateUtc
} from '../../utils/functions/dates';
import translation from '../../translation/translation';

function FilterField(props) {
  const {
    choices,
    error,
    defaultOperator,
    defaultValue,
    name,
    label,
    links,
    setFilterFullObject,
    setFilterOperator,
    setFilterValue,
    target_key,
    type,
    reference_key,
    required
  } = props;

  const queryOperators = [
    {
      operator: 'lk',
      label: (
        <Tooltip title={translation().filters.labels.like}>
          <div style={{ width: '100%' }}>&asymp;</div>
        </Tooltip>
      )
    },
    {
      operator: 'eq',
      label: (
        <Tooltip title={translation().filters.labels.equal}>
          <div style={{ width: '100%' }}>=</div>
        </Tooltip>
      )
    },
    {
      operator: 'gte',
      label: (
        <Tooltip title={translation().filters.labels.gte}>
          <div style={{ width: '100%' }}>&ge;</div>
        </Tooltip>
      )
    },
    {
      operator: 'gt',
      label: (
        <Tooltip title={translation().filters.labels.gt}>
          <div style={{ width: '100%' }}>&gt;</div>
        </Tooltip>
      )
    },
    {
      operator: 'lte',
      label: (
        <Tooltip title={translation().filters.labels.lte}>
          <div style={{ width: '100%' }}>&le;</div>
        </Tooltip>
      )
    },
    {
      operator: 'lt',
      label: (
        <Tooltip title={translation().filters.labels.lt}>
          <div style={{ width: '100%' }}>&lt;</div>
        </Tooltip>
      )
    },
    {
      operator: 'ne',
      label: (
        <Tooltip title={translation().filters.labels.ne}>
          <div style={{ width: '100%' }}>&ne;</div>
        </Tooltip>
      )
    },
    {
      operator: 'in',
      label: (
        <Tooltip title={translation().filters.labels.in}>
          <div style={{ width: '100%' }}>&#10687;</div>
        </Tooltip>
      )
    },
    {
      operator: 'nin',
      label: (
        <Tooltip title={translation().filters.labels.nin}>
          <div style={{ width: '100%' }}>&#10672;</div>
        </Tooltip>
      )
    },
    {
      operator: 'nu',
      label: (
        <Tooltip title={translation().filters.labels.null}>
          <div style={{ width: '100%' }}>null</div>
        </Tooltip>
      )
    },
    {
      operator: 'nnu',
      label: (
        <Tooltip title={translation().filters.labels.not_null}>
          <div style={{ width: '100%' }}>not null</div>
        </Tooltip>
      )
    }
  ];

  const timestampQueryOperators = [
    ...queryOperators,
    {
      operator: 'range',
      label: (
        <Tooltip title={translation().filters.labels.range}>
          <div style={{ width: '100%' }}>&gt;&lt;</div>
        </Tooltip>
      )
    }
  ];

  const [isRangeSelected, setRangeSelected] = useState(false);
  const [rangeStartValue, setRangeStartValue] = useState('');
  const [rangeEndValue, setRangeEndValue] = useState('');

  useEffect(() => {
    if (defaultOperator && defaultOperator === 'range') {
      setRangeSelected(true);
    } else {
      setRangeSelected(false);
    }

    if (defaultValue && defaultValue.start) {
      setRangeStartValue(formatDate(defaultValue.start, 'YYYY-MM-DDTHH:mm:ss'));
    }
    if (defaultValue && defaultValue.end) {
      setRangeEndValue(formatDate(defaultValue.end, 'YYYY-MM-DDTHH:mm:ss'));
    }

    if (type === 'time' && defaultValue) {
      const defaultValueSplitted = defaultValue.split(':');
      const hour = defaultValueSplitted[0];
      const mins = defaultValueSplitted[1];
      const secs = defaultValueSplitted[2];
      const displayValue = alterDate(getCurrentDateUtc(), null, null, null, hour, mins, secs);

      setFilterValue(name, formatDate(displayValue, 'HH:mm:00'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function formatDefaultValueTimestamp(fieldName) {
    if (!defaultValue || !fieldName) {
      return;
    }

    if (fieldName === `${name}.start` && defaultValue.start) {
      return dateInTz(defaultValue.start, 'YYYY-MM-DDTHH:mm:ss');
    }
    if (fieldName === `${name}.end` && defaultValue.end) {
      return dateInTz(defaultValue.end, 'YYYY-MM-DDTHH:mm:ss');
    }

    return dateInTz(defaultValue, 'YYYY-MM-DDTHH:mm:ss');
  }

  function handleChangeText(value) {
    setFilterValue(name, value);
  }

  function handleChangeEntity(value, fullObj) {
    setFilterValue(reference_key || name, value);
    setFilterFullObject(reference_key || name, fullObj);
  }

  function handleChangeSelect(value) {
    setFilterValue(name, value);
  }

  function handleChangeDate(value) {
    setFilterValue(name, formatDate(value, 'YYYY/MM/DD'));
  }

  const handleChangeTimestamp = (fieldName) => (value) => {
    setFilterValue(fieldName, createDate(value));

    if (fieldName === `${name}.start`) {
      setRangeStartValue(value);
    } else if (fieldName === `${name}.end`) {
      setRangeEndValue(value);
    }
  };

  function handleChangeTime(value) {
    const day = formatJSDate(new Date());
    const start = createDate(day + 'T' + value);
    const utcValue = createDateUtc(start);

    setFilterValue(name, formatDate(utcValue, 'HH:mm:00'));
  }

  function handleChangeOperator(operator) {
    setFilterOperator(reference_key || name, operator);
  }

  function handleChangeRangeOperator(operator) {
    if (operator === 'range') {
      setRangeSelected(true);
      setFilterValue(name, '');
      setFilterValue(`${name}.end`, '');
      setFilterValue(`${name}.start`, '');
      setRangeStartValue('');
      setRangeEndValue('');
    } else {
      setRangeSelected(false);
      setFilterValue(`${name}.end`, '');
      setFilterValue(`${name}.start`, '');
      setRangeStartValue('');
      setRangeEndValue('');
    }

    setFilterOperator(reference_key || name, operator);
  }

  if (type === 'entity') {
    return (
      <Grid container direction="row" justify="center" alignItems="flex-end" spacing={2}>
        <Grid item xs={3}>
          <FormSelectField
            onChange={handleChangeOperator}
            name={`${name}.operator`}
            defaultValue={defaultOperator}
            label={translation().filters.operator}>
            {queryOperators.map((choice, indexItem) => (
              <MenuItem key={indexItem} value={choice.operator}>
                {choice.label}
              </MenuItem>
            ))}
          </FormSelectField>
        </Grid>
        <Grid item xs={9}>
          <FormApiAutocompleteField
            name={reference_key || name}
            label={label}
            links={links}
            onSelectValue={handleChangeEntity}
            targetKey={target_key}
            defaultValue={defaultValue}
            required={required}
            error={error}
            helperText={
              !links || links.length <= 0
                ? 'This entity contains no link for the autocomplete.'
                : ''
            }
          />
        </Grid>
      </Grid>
    );
  } else if (type === 'set') {
    return (
      <Grid container direction="row" justify="center" spacing={2}>
        <Grid item xs={3}>
          <FormSelectField
            onChange={handleChangeOperator}
            name={`${name}.operator`}
            defaultValue={defaultOperator}
            label={translation().filters.operator}>
            {queryOperators.map((choice, indexItem) => (
              <MenuItem key={indexItem} value={choice.operator}>
                {choice.label}
              </MenuItem>
            ))}
          </FormSelectField>
        </Grid>
        <Grid item xs={9}>
          <FormApiAutocompleteField
            name={reference_key || name}
            label={label}
            links={links}
            onSelectValue={handleChangeEntity}
            targetKey={target_key}
            defaultValue={defaultValue}
            required={required}
            error={error}
            helperText={
              !links || links.length <= 0
                ? 'This entity contains no link for the autocomplete.'
                : translation().filters.type_set_disclamer
            }
            multiple
          />
        </Grid>
      </Grid>
    );
  } else if (type === 'number' || type === 'integer' || type === 'float' || type === 'decimal') {
    return (
      <Grid container direction="row" justify="center" alignItems="flex-end" spacing={2}>
        <Grid item xs={3}>
          <FormSelectField
            onChange={handleChangeOperator}
            name={`${name}.operator`}
            defaultValue={defaultOperator}
            label={translation().filters.operator}>
            {queryOperators.map((choice, indexItem) => (
              <MenuItem key={indexItem} value={choice.operator}>
                {choice.label}
              </MenuItem>
            ))}
          </FormSelectField>
        </Grid>
        <Grid item xs={9}>
          <FormTextField
            type="number"
            name={name}
            label={label}
            write
            defaultValue={defaultValue}
            onChange={handleChangeText}
            required={required}
            error={error}
          />
        </Grid>
      </Grid>
    );
  } else if (choices && choices.length > 0) {
    return (
      <Grid container direction="row" justify="center" alignItems="flex-end" spacing={2}>
        <Grid item xs={3}>
          <FormSelectField
            onChange={handleChangeOperator}
            name={`${name}.operator`}
            defaultValue={defaultOperator}
            label={translation().filters.operator}>
            {queryOperators.map((choice, indexItem) => (
              <MenuItem key={indexItem} value={choice.operator}>
                {choice.label}
              </MenuItem>
            ))}
          </FormSelectField>
        </Grid>
        <Grid item xs={9}>
          <FormSelectField
            displayEmpty={true}
            id={name}
            name={name}
            label={label}
            write
            defaultValue={defaultValue}
            onChange={handleChangeSelect}
            required={required}
            error={error}>
            {choices.map((choice, indexItem) => {
              return (
                <MenuItem key={indexItem} value={choice}>
                  {choice}
                </MenuItem>
              );
            })}
          </FormSelectField>
        </Grid>
      </Grid>
    );
  } else if (type === 'boolean') {
    let booleanValue = defaultValue;

    if (defaultValue === '0' || parseInt(defaultValue, 10) === 0) booleanValue = '0';
    else if (defaultValue === '1' || parseInt(defaultValue, 10) === 1) booleanValue = '1';

    return (
      <Grid container direction="row" justify="center" alignItems="flex-end" spacing={2}>
        <Grid item xs={3}>
          <FormSelectField
            onChange={handleChangeOperator}
            name={`${name}.operator`}
            defaultValue={defaultOperator}
            label={translation().filters.operator}>
            {queryOperators.map((choice, indexItem) => (
              <MenuItem key={indexItem} value={choice.operator}>
                {choice.label}
              </MenuItem>
            ))}
          </FormSelectField>
        </Grid>
        <Grid item xs={9}>
          <FormSelectField
            displayEmpty={true}
            id={name}
            name={name}
            label={label}
            write
            defaultValue={booleanValue}
            onChange={handleChangeSelect}
            required={required}
            error={error}>
            <MenuItem value={'1'}>{translation().interface.yes}</MenuItem>
            <MenuItem value={'0'}>{translation().interface.no}</MenuItem>
          </FormSelectField>
        </Grid>
      </Grid>
    );
  } else if (type === 'date') {
    return (
      <Grid container direction="row" justify="center" alignItems="flex-end" spacing={2}>
        <Grid item xs={3}>
          <FormSelectField
            onChange={handleChangeOperator}
            name={`${name}.operator`}
            defaultValue={defaultOperator}
            label={translation().filters.operator}>
            {queryOperators.map((choice, indexItem) => (
              <MenuItem key={indexItem} value={choice.operator}>
                {choice.label}
              </MenuItem>
            ))}
          </FormSelectField>
        </Grid>
        <Grid item xs={9}>
          <FormDateField
            name={name}
            label={label}
            write
            defaultValue={defaultValue}
            required={required}
            onChange={handleChangeDate}
            error={error}
          />
        </Grid>
      </Grid>
    );
  } else if (type === 'timestamp') {
    return (
      <Grid container direction="row" justify="center" alignItems="flex-end" spacing={2}>
        <Grid item xs={isRangeSelected ? 4 : 3} sm={isRangeSelected ? 2 : 3}>
          <FormSelectField
            onChange={handleChangeRangeOperator}
            name={`${name}.operator`}
            defaultValue={defaultOperator}
            label={translation().filters.operator}>
            {timestampQueryOperators.map((choice, indexItem) => (
              <MenuItem key={indexItem} value={choice.operator}>
                {choice.label}
              </MenuItem>
            ))}
          </FormSelectField>
        </Grid>
        {isRangeSelected ? (
          <>
            <Grid item xs={4} sm={5}>
              <FormDateTimeField
                name={`${name}.start`}
                label={label}
                write
                defaultValue={formatDefaultValueTimestamp(`${name}.start`)}
                value={rangeStartValue}
                onChange={handleChangeTimestamp(`${name}.start`)}
                required={required}
                error={error}
              />
            </Grid>
            <Grid item xs={4} sm={5}>
              <FormDateTimeField
                name={`${name}.end`}
                label={label}
                write
                defaultValue={formatDefaultValueTimestamp(`${name}.end`)}
                value={rangeEndValue}
                onChange={handleChangeTimestamp(`${name}.end`)}
                required={required}
                error={error}
              />
            </Grid>
          </>
        ) : (
          <Grid item xs={9}>
            <FormDateTimeField
              name={name}
              label={label}
              write
              defaultValue={formatDefaultValueTimestamp(name)}
              onChange={handleChangeTimestamp(name)}
              required={required}
              error={error}
            />
          </Grid>
        )}
      </Grid>
    );
  } else if (type === 'time') {
    return (
      <Grid container direction="row" justify="center" alignItems="flex-end" spacing={2}>
        <Grid item xs={3}>
          <FormSelectField
            onChange={handleChangeOperator}
            name={`${name}.operator`}
            defaultValue={defaultOperator}
            label={translation().filters.operator}>
            {queryOperators.map((choice, indexItem) => (
              <MenuItem key={indexItem} value={choice.operator}>
                {choice.label}
              </MenuItem>
            ))}
          </FormSelectField>
        </Grid>
        <Grid item xs={9}>
          <FormTimeField
            name={name}
            label={label}
            write
            defaultValue={defaultValue}
            onChange={handleChangeTime}
            required={required}
            error={error}
          />
        </Grid>
      </Grid>
    );
  } else if (type === 'string') {
    return (
      <Grid container direction="row" justify="center" alignItems="flex-end" spacing={2}>
        <Grid item xs={3}>
          <FormSelectField
            onChange={handleChangeOperator}
            name={`${name}.operator`}
            defaultValue={defaultOperator}
            label={translation().filters.operator}>
            {queryOperators.map((choice, indexItem) => (
              <MenuItem key={indexItem} value={choice.operator}>
                {choice.label}
              </MenuItem>
            ))}
          </FormSelectField>
        </Grid>
        <Grid item xs={9}>
          <FormTextField
            name={name}
            label={label}
            write
            defaultValue={defaultValue}
            onChange={handleChangeText}
            required={required}
            error={error}
          />
        </Grid>
      </Grid>
    );
  } else if (type === 'email') {
    return (
      <Grid container direction="row" justify="center" alignItems="flex-end" spacing={2}>
        <Grid item xs={3}>
          <FormSelectField
            onChange={handleChangeOperator}
            name={`${name}.operator`}
            defaultValue={defaultOperator}
            label={translation().filters.operator}>
            {queryOperators.map((choice, indexItem) => (
              <MenuItem key={indexItem} value={choice.operator}>
                {choice.label}
              </MenuItem>
            ))}
          </FormSelectField>
        </Grid>
        <Grid item xs={9}>
          <FormTextField
            type="email"
            name={name}
            label={label}
            write
            defaultValue={defaultValue}
            onChange={handleChangeText}
            required={required}
            error={error}
          />
        </Grid>
      </Grid>
    );
  } else if (type === 'text') {
    return (
      <Grid container direction="row" justify="center" alignItems="flex-end" spacing={2}>
        <Grid item xs={3}>
          <FormSelectField
            onChange={handleChangeOperator}
            name={`${name}.operator`}
            defaultValue={defaultOperator}
            label={translation().filters.operator}>
            {queryOperators.map((choice, indexItem) => (
              <MenuItem key={indexItem} value={choice.operator}>
                {choice.label}
              </MenuItem>
            ))}
          </FormSelectField>
        </Grid>
        <Grid item xs={9}>
          <FormTextField
            name={name}
            label={label}
            write
            multiline
            type="textarea"
            defaultValue={defaultValue}
            onChange={handleChangeText}
            required={required}
            error={error}
          />
        </Grid>
      </Grid>
    );
  } else if (type === 'url') {
    return (
      <Grid container direction="row" justify="center" alignItems="flex-end" spacing={2}>
        <Grid item xs={3}>
          <FormSelectField
            onChange={handleChangeOperator}
            name={`${name}.operator`}
            defaultValue={defaultOperator}
            label={translation().filters.operator}>
            {queryOperators.map((choice, indexItem) => (
              <MenuItem key={indexItem} value={choice.operator}>
                {choice.label}
              </MenuItem>
            ))}
          </FormSelectField>
        </Grid>
        <Grid item xs={9}>
          <FormTextField
            type="text"
            name={name}
            label={label}
            write
            defaultValue={defaultValue}
            required={required}
            onChange={handleChangeText}
            error={error}
          />
        </Grid>
      </Grid>
    );
  } else if (type === 'image' || type === 'file') {
    return (
      <Grid container direction="row" justify="center" alignItems="flex-end" spacing={2}>
        <Grid item xs={3}>
          <FormSelectField
            onChange={handleChangeOperator}
            name={`${name}.operator`}
            defaultValue={defaultOperator}
            label={translation().filters.operator}>
            {queryOperators.map((choice, indexItem) => (
              <MenuItem key={indexItem} value={choice.operator}>
                {choice.label}
              </MenuItem>
            ))}
          </FormSelectField>
        </Grid>
        <Grid item xs={9}>
          <FormTextField
            type="url"
            name={name}
            label={label}
            required={required}
            defaultValue={defaultValue}
            write
            onChange={handleChangeText}
            error={error}
          />
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container direction="row" justify="center" alignItems="flex-end" spacing={2}>
        <Grid item xs={3}>
          <FormSelectField
            onChange={handleChangeOperator}
            name={`${name}.operator`}
            defaultValue={defaultOperator}
            label={translation().filters.operator}>
            {queryOperators.map((choice, indexItem) => (
              <MenuItem key={indexItem} value={choice.operator}>
                {choice.label}
              </MenuItem>
            ))}
          </FormSelectField>
        </Grid>
        <Grid item xs={9}>
          <FormTextField
            name={name}
            label={label}
            write
            type="text"
            defaultValue={defaultValue}
            required={required}
            onChange={handleChangeText}
            error={error}
          />
        </Grid>
      </Grid>
    );
  }
}

FilterField.defaultProps = {
  defaultValue: null
};

FilterField.propTypes = {
  defaultOperator: PropTypes.string,
  error: PropTypes.string,
  choices: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.shape(), PropTypes.string, PropTypes.number])
  ),
  defaultValue: PropTypes.any,
  label: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(PropTypes.shape()),
  target_key: PropTypes.any,
  name: PropTypes.string.isRequired,
  setFilterOperator: PropTypes.func.isRequired,
  setFilterFullObject: PropTypes.func.isRequired,
  setFilterValue: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  reference_key: PropTypes.string,
  required: PropTypes.bool
};

export default FilterField;
