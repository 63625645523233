import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import SettingsIcon from '@material-ui/icons/Settings';

import { objectMapToArray } from '../../../../utils/functions/utils';
import { fetchApi } from '../../../../utils/functions/api';
import {
  setDownloadSettingsDefinition,
  setDownloadSettingsWatermark
} from '../../../../redux/actions/medias/mediasActions';
import FormSelectField from '../../../../components/forms/fields/FormSelectField';
import translation from '../../../../translation/translation';

const mapStateToProps = (store) => ({
  downloadAction: store.mediaGroups.download_action,
  downloadSettings: store.mediaGroups.download_settings
});

const mapDispatchToProps = (dispatch) => {
  return {
    setDefinition(value) {
      dispatch(setDownloadSettingsDefinition(value));
    },
    setWatermark(value) {
      dispatch(setDownloadSettingsWatermark(value));
    }
  };
};

function DownloadMediasButton(props) {
  const {
    disabled,
    downloadSettings: { definition, watermark },
    mediaGroupId,
    setDefinition,
    setWatermark,
    onClick
  } = props;

  const anchorRef = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [isDLSettingsLoading, setDLSettingsLoading] = useState(false);
  const [definitionSettings, setDefinitionSettings] = useState([]);

  const containerStyle = {
    minWidth: 200,
    marginTop: 4,
    padding: '8px 12px 16px 12px'
  };

  const loaderStyle = {
    width: '100%',
    padding: 2,
    textAlign: 'center',
    marginTop: 16
  };

  const watermarkSettings = [
    {
      value: '',
      label: translation().views.orders.media_groups.watermark_labels.none
    },
    {
      value: 'center-center',
      label: translation().views.orders.media_groups.watermark_labels.center_center
    },
    {
      value: 'left-bottom',
      label: translation().views.orders.media_groups.watermark_labels.left_bottom
    },
    {
      value: 'right-bottom',
      label: translation().views.orders.media_groups.watermark_labels.right_bottom
    },
    {
      value: 'left-top',
      label: translation().views.orders.media_groups.watermark_labels.left_top
    },
    {
      value: 'right-top',
      label: translation().views.orders.media_groups.watermark_labels.right_top
    },
    {
      value: 'center-bottom',
      label: translation().views.orders.media_groups.watermark_labels.center_bottom
    },
    {
      value: 'center-top',
      label: translation().views.orders.media_groups.watermark_labels.center_top
    }
  ];

  function handleClick() {
    onClick();
  }

  function handleOpen() {
    setOpen(true);
    setDLSettingsLoading(true);

    fetchApi(
      'get',
      `media-groups/${mediaGroupId}/thumbnails`,
      { sort: '-landscape_width' },
      null,
      null,
      (thumbnails) => {
        const defs = [];

        objectMapToArray(thumbnails, (index, thumbnail) => {
          let options = {};

          options['value'] = thumbnail.id;
          options['label'] =
            thumbnail.name +
            (thumbnail.landscape_width ? ' (' + thumbnail.landscape_width + 'px)' : '');
          defs.push(options);
        });

        setDLSettingsLoading(false);
        setDefinitionSettings(defs);
      },
      () => {
        setDLSettingsLoading(false);
      }
    );
  }

  function handleClose() {
    setOpen(false);
  }

  function handleChangeDefinition(value) {
    setDefinition(value);
  }

  function handleChangeWatermark(value) {
    setWatermark(value);
  }

  return (
    <>
      <ButtonGroup
        ref={anchorRef}
        disabled={disabled}
        color="primary"
        variant="contained"
        aria-label="download settings">
        <Button onClick={handleClick}>{translation().actions.download}</Button>
        {!isOpen ? (
          <Button
            size="small"
            aria-controls="open menu settings"
            aria-expanded={false}
            aria-label="select download settings"
            aria-haspopup="menu"
            onClick={handleOpen}>
            <SettingsIcon />
          </Button>
        ) : (
          <Button
            size="small"
            aria-controls="close menu settings"
            aria-expanded={true}
            aria-label="select download settings"
            aria-haspopup="menu"
            onClick={handleClose}>
            <CloseIcon />
          </Button>
        )}
      </ButtonGroup>
      <Popper
        open={isOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 9, maxWidth: 200 }}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
            }}>
            <Paper>
              <div style={containerStyle}>
                <div style={{ maxWidth: 200 }}>
                  {isDLSettingsLoading ? (
                    <div style={loaderStyle}>
                      <CircularProgress size={20} />
                    </div>
                  ) : definitionSettings && definitionSettings.length > 0 ? (
                    <FormSelectField
                      id="file-dimensions"
                      name="file-dimensions"
                      label={translation().views.orders.media_groups.definition}
                      displayEmpty
                      isLabelShrink
                      value={
                        definition
                          ? definition
                          : definitionSettings && definitionSettings.length
                          ? definitionSettings[0].value
                          : ''
                      }
                      onChange={handleChangeDefinition}>
                      {definitionSettings.map(({ value, label }, id) => (
                        <MenuItem key={id} value={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </FormSelectField>
                  ) : (
                    false
                  )}
                  <FormSelectField
                    id="file-watermark"
                    name="file-watermark"
                    displayEmpty
                    isLabelShrink
                    value={watermark ? watermark : watermarkSettings[0].value}
                    label={translation().views.orders.media_groups.watermark}
                    onChange={handleChangeWatermark}>
                    {watermarkSettings.map(({ value, label }, id) => (
                      <MenuItem key={id} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </FormSelectField>
                </div>
              </div>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(DownloadMediasButton);

DownloadMediasButton.propTypes = {
  disabled: PropTypes.bool,
  downloadSettings: PropTypes.shape({
    definition: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    watermark: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }).isRequired,
  mediaGroupId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onClick: PropTypes.func.isRequired,
  setDefinition: PropTypes.func.isRequired,
  setWatermark: PropTypes.func.isRequired
};
