import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchApi } from '../../utils/functions/api';
import { enqueueSnackbar } from '../../redux/actions/appActions';
import {
  clearOwnReducer,
  getPreviousDay,
  getPreviousDaySuccess,
  getPreviousDayError,
  refreshPreviousDay,
  setPreviousDaySearchValue
} from '../../redux/actions/previousDay/list/previousDayListActions';
import {
  getPreviousDayMetadata,
  getPreviousDayMetadataSuccess,
  getPreviousDayMetadataError
} from '../../redux/actions/previousDay/metadata/previousDayMetadataActions';
import PreviousDay from '../../views/previousDay/PreviousDay';
import translation from '../../translation/translation';
import { getCurrentDateUtc, alterDate, formatDate } from '../../utils/functions/dates';

const mapStateToProps = (store) => ({
  list: store.production.previousDay.list,
  metadata: store.production.previousDay.metadata
});

const mapDispatchToProps = (dispatch) => {
  return {
    clearOwnReducer() {
      dispatch(clearOwnReducer());
    },
    setDataSearchValue(value) {
      dispatch(setPreviousDaySearchValue(value));
    },
    async getMetadata(uri, sourceToken = null) {
      if (!uri) return;

      dispatch(getPreviousDayMetadata());

      try {
        await fetchApi(
          'get',
          `${uri}/metadata`,
          null,
          null,
          null,
          (metadata) => {
            dispatch(getPreviousDayMetadataSuccess(metadata));
          },
          (error) => {
            dispatch(getPreviousDayMetadataError(true, error));

            if (error.response) {
              dispatch(
                enqueueSnackbar({
                  message:
                    error.response.data && error.response.data.detail
                      ? error.response.data.detail
                      : error.response.data.status_code
                      ? error.response.data.status_code + ': ' + translation().core.item.not_found
                      : translation().core.list.error_metadata,
                  status: error.response.status || undefined,
                  options: {
                    variant: 'error'
                  }
                })
              );
            } else {
              dispatch(
                enqueueSnackbar({
                  message: translation().core.list.error_metadata,
                  options: {
                    variant: 'error'
                  }
                })
              );
            }
          },
          null,
          sourceToken
        );
      } catch (error) {
        dispatch(getPreviousDayMetadataError(true, error));
        dispatch(
          enqueueSnackbar({
            message: translation().core.list.error_metadata,
            options: {
              variant: 'error'
            }
          })
        );
      }
    },
    async getOrder(orderId, sourceToken = null, callbackSuccess, callbackError) {
      if (!orderId) return;

      try {
        await fetchApi(
          'get',
          'orders',
          { filters: `id|eq|${orderId}` },
          null,
          null,
          (data) => {
            if (data && data.length && callbackSuccess && typeof callbackSuccess === 'function') {
              callbackSuccess(data[0]);
            }
          },
          (error) => {
            if (callbackError && typeof callbackError === 'function') {
              callbackError(
                error && error.response && error.response.data && error.response.data.detail
                  ? error.response.data.detail
                  : translation().core.list.error_list
              );
            }
          },
          null,
          sourceToken
        );
      } catch (error) {
        if (callbackError && typeof callbackError === 'function') {
          callbackError(error);
        }
      }
    },
    async getData(
      uri,
      sourceToken = null,
      query = null,
      next = null,
      isRefresh = false,
      isDateSet = false,
      isDelivered = false,
      callbackSuccess
    ) {
      if (isRefresh) dispatch(refreshPreviousDay());
      else dispatch(getPreviousDay(query, next));

      let filters = '';
      const today = getCurrentDateUtc();
      const todayAltered = alterDate(today, null, null, null, '23', '59', '59');

      if (query && query.filters) {
        if (isDelivered) {
          filters += query.filters;
        } else {
          filters += 'delivery_status_id|ne|6,' + query.filters;
        }

        if (!isDateSet) {
          filters += 'start|lte|' + formatDate(todayAltered, 'YYYY-MM-DDTHH:mm:ss') + ',';
        }
      } else {
        if (isDelivered) {
          filters += 'start|lte|' + formatDate(todayAltered, 'YYYY-MM-DDTHH:mm:ss');
        } else {
          filters +=
            'delivery_status_id|ne|6,start|lte|' + formatDate(todayAltered, 'YYYY-MM-DDTHH:mm:ss');
        }
      }

      const queryApi = { ...query, sort: 'start', filters };

      try {
        await fetchApi(
          'get',
          next ? next : uri,
          next ? {} : queryApi,
          null,
          null,
          (data, paging) => {
            dispatch(getPreviousDaySuccess(data, paging));

            if (callbackSuccess && typeof callbackSuccess === 'function') {
              callbackSuccess(data, paging);
            }
          },
          (error) => {
            dispatch(getPreviousDayError(true, error));

            if (error.response) {
              dispatch(
                enqueueSnackbar({
                  message:
                    error.response.data && error.response.data.detail
                      ? error.response.data.detail
                      : translation().core.list.error_list,
                  status: error.response.status || undefined,
                  options: {
                    variant: 'error'
                  }
                })
              );
            } else {
              dispatch(
                enqueueSnackbar({
                  message: translation().core.list.error_list,
                  options: {
                    variant: 'error'
                  }
                })
              );
            }
          },
          null,
          sourceToken
        );
      } catch (error) {
        dispatch(getPreviousDayError(true, error));
      }
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PreviousDay));
