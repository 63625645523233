import React from 'react';

import CoreMetricsContainer from '../../../../../containers/metrics/CoreMetricsContainer';
import userIdMetrics from '../../../../../routes/metrics/userIdMetrics';

function UserMetrics() {
  return <CoreMetricsContainer embed mapping={userIdMetrics} />;
}

export default UserMetrics;
