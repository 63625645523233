import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Link, Paper, Typography } from '@material-ui/core';

import translation from '../../../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      textAlign: 'center'
    }
  },
  box: {
    padding: 12,
    width: '100%'
  },
  img: {
    maxWidth: 200,
    width: '100%',
    height: 'auto',
    margin: 'auto'
  },
  imgBox: {
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right'
    }
  },
  title: {
    marginBottom: 12,
    fontSize: 18,
    textAlign: 'center'
  },
  label: {
    fontWeight: 500,
    marginRight: 4
  }
}));

function CompanyCreated({ company }) {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Paper className={classes.root} elevation={1}>
        <Typography variant="h5" className={classes.title}>
          {translation().views.provider_factory.steps.company.created} (
          <Link target="_blank" href={`/companies/${company.id}/update`}>
            #{company.id}
          </Link>
          )
        </Typography>
        <div className={classes.container}>
          <div className={classNames(classes.box, classes.imgBox)}>
            <img
              src="/assets/images/production/provider-factory-step-completed.svg"
              alt="Step completed"
              className={classes.img}
            />
          </div>
          <div className={classes.box}>
            <Typography>
              <Typography component="span" className={classes.label}>
                {translation().views.provider_factory.steps.company.labels.legal_id}:
              </Typography>
              {company.legal_id}
            </Typography>
            <Typography>
              <Typography component="span" className={classes.label}>
                {translation().views.provider_factory.steps.company.labels.legal_name}:
              </Typography>
              {company.legal_name}
            </Typography>
          </div>
        </div>
      </Paper>
    </Grid>
  );
}

CompanyCreated.propTypes = {
  company: PropTypes.shape({
    id: PropTypes.number.isRequired,
    legal_id: PropTypes.string.isRequired,
    legal_name: PropTypes.string.isRequired
  }).isRequired
};

export { CompanyCreated };
