import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchApi } from '../../../utils/functions/api';
import { createDate, createDateUtc } from '../../../utils/functions/dates';
import { enqueueSnackbar } from '../../../redux/actions/appActions';
import {
  clearSchedule,
  clearCurrentTimezone,
  setCurrentTimeRange,
  setCurrentView,
  setCurrentTimezone,
  getProviderCalendar,
  getProviderCalendarSuccess,
  getProviderCalendarError,
  postAvailability,
  postAvailabilitySuccess,
  postAvailabilityError,
  putAvailability,
  putAvailabilitySuccess,
  putAvailabilityError,
  deleteAvailability,
  deleteAvailabilitySuccess,
  deleteAvailabilityError,
  postUnavailability,
  postUnavailabilitySuccess,
  postUnavailabilityError,
  deleteUnavailability,
  deleteUnavailabilitySuccess,
  deleteUnavailabilityError,
  putUnavailability,
  putUnavailabilitySuccess,
  putUnavailabilityError,
  resizeAvailability,
  resizeAvailabilitySuccess,
  resizeAvailabilityFailure,
  resizeUnavailability,
  resizeUnavailabilitySuccess,
  resizeUnavailabilityFailure,
  getProviderInfosCalendar,
  getProviderInfosCalendarSuccess,
  getProviderInfosCalendarFailure
} from '../../../redux/actions/schedule/scheduleActions';
import ProviderSchedule from '../../../views/core/customListActions/providerSchedule/ProviderSchedule';
import translation from '../../../translation/translation';

const mapStateToProps = (state) => ({ schedule: state.schedule });

const mapDispatchToProps = (dispatch) => {
  function ownFormatJSDate(date) {
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    let year = date.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  return {
    clearSchedule: () => {
      dispatch(clearSchedule());
    },
    clearCurrentTimezone: () => {
      dispatch(clearCurrentTimezone());
    },
    setCurrentTimeRange: ({ start, end }) => {
      if (!start || !end) return;

      dispatch(setCurrentTimeRange({ start, end }));
    },
    setCurrentView: (view) => {
      if (!view) return;

      dispatch(setCurrentView(view));
    },
    setCurrentTimezone: (code) => {
      dispatch(setCurrentTimezone(code));
    },
    getProviderInfos(id) {
      if (!id) return;

      dispatch(getProviderInfosCalendar());

      fetchApi(
        'get',
        `providers/${id}`,
        // {fields: "id, user"},
        null,
        null,
        null,
        (data) => {
          dispatch(getProviderInfosCalendarSuccess(data));
        },
        (error) => {
          dispatch(getProviderInfosCalendarFailure(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().views.provider_schedule.calendar.timezone.callbackFailure,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().views.provider_schedule.calendar.callbackFailure,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    },
    getProviderEvents: (providerId, slot, callbackSuccess, callbackFailure) => {
      if (!providerId) return;

      dispatch(getProviderCalendar());

      let query = {};

      if (slot && slot.start && slot.end) {
        query = {
          start: slot.start,
          end: slot.end,
          types: 'cart_product,order_product,availability,unavailability'
        };
      }

      fetchApi(
        'get',
        `providers/${providerId}/cal`,
        query,
        null,
        null,
        (data) => {
          for (let i = 0; i < data.length; i++) {
            if (data[i].details && data[i].details.id) data[i].id = data[i].details.id;
          }

          dispatch(getProviderCalendarSuccess(data));

          if (callbackSuccess && typeof callbackSuccess === 'function') callbackSuccess();
        },
        (error) => {
          dispatch(getProviderCalendarError(error));

          if (callbackFailure && typeof callbackFailure === 'function') callbackFailure();

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().views.provider_schedule.calendar.callbackFailure,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().views.provider_schedule.calendar.callbackFailure,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    },
    enqueueSnackbarSchedule: (message, options) => {
      if (!message || !options) return;

      dispatch(enqueueSnackbar({ message, options }));
    },
    postAvailability: (
      providerId,
      values,
      { start: eventStart },
      timezone,
      callbackSuccess,
      callbackFailure
    ) => {
      if (!providerId || !values || !eventStart) return;

      const day = ownFormatJSDate(eventStart);
      const start = createDate(
        day + 'T' + values.hour_start + ':' + values.min_start + ':00',
        timezone
      );
      const end = createDate(day + 'T' + values.hour_end + ':' + values.min_end + ':00', timezone);

      if (!start || !end) return;

      const startUtc = createDateUtc(start).toString();
      const endUtc = createDateUtc(end).toString();

      dispatch(postAvailability());

      const availability = {
        start: startUtc,
        end: endUtc
      };

      fetchApi(
        'post',
        `providers/${providerId}/availabilities`,
        null,
        availability,
        null,
        (success) => {
          /**
           * We need to force some keys in redux for use function binded by ids
           */
          success.type = 'availability';
          success.details = {};
          success.details.id = success.id;

          dispatch(postAvailabilitySuccess(success));

          if (callbackSuccess && typeof callbackSuccess === 'function') callbackSuccess();

          dispatch(
            enqueueSnackbar({
              message: translation().views.provider_schedule.availability.create.callbacks.success,
              options: {
                variant: 'success'
              }
            })
          );
        },
        (error) => {
          dispatch(postAvailabilityError(error));

          if (callbackFailure && typeof callbackFailure === 'function') callbackFailure();

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().views.provider_schedule.availability.create.callbacks.error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().views.provider_schedule.availability.create.callbacks.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    },
    putAvailability: (
      providerId,
      id,
      values,
      eventStart,
      timezone,
      callbackSuccess,
      callbackFailure
    ) => {
      if (!values || !eventStart) return;

      const day = ownFormatJSDate(eventStart);
      const start = createDate(
        day + 'T' + values.hour_start + ':' + values.min_start + ':00',
        timezone
      );
      const end = createDate(day + 'T' + values.hour_end + ':' + values.min_end + ':00', timezone);

      if (!start || !end) return;

      const startUtc = createDateUtc(start).toString();
      const endUtc = createDateUtc(end).toString();

      dispatch(putAvailability());

      const availability = {
        start: startUtc,
        end: endUtc
      };

      fetchApi(
        'put',
        `providers/${providerId}/availabilities/${id}`,
        null,
        availability,
        null,
        (success) => {
          /**
           * We need to force some keys in redux for use function binded by ids and type
           */
          success.type = 'availability';
          success.details = {};
          success.details.id = success.id;

          dispatch(putAvailabilitySuccess(id, success));

          if (callbackSuccess && typeof callbackSuccess === 'function') callbackSuccess();

          dispatch(
            enqueueSnackbar({
              message: translation().views.provider_schedule.availability.edit.callbacks.success,
              options: {
                variant: 'success'
              }
            })
          );
        },
        (error) => {
          dispatch(putAvailabilityError(error));

          if (callbackFailure && typeof callbackFailure === 'function') callbackFailure();

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().views.provider_schedule.availability.edit.callbacks.error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().views.provider_schedule.availability.edit.callbacks.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    },
    deleteAvailability: (providerId, id, callbackSuccess, callbackFailure) => {
      if (!providerId || !id) return;

      dispatch(deleteAvailability());

      fetchApi(
        'delete',
        `providers/${providerId}/availabilities/${id}`,
        null,
        null,
        null,
        () => {
          dispatch(deleteAvailabilitySuccess(id));

          if (callbackSuccess && typeof callbackSuccess === 'function') callbackSuccess();

          dispatch(
            enqueueSnackbar({
              message: translation().views.provider_schedule.availability.delete.callbacks.success,
              options: {
                variant: 'success'
              }
            })
          );
        },
        (error) => {
          dispatch(deleteAvailabilityError(error));

          if (callbackFailure && typeof callbackFailure === 'function') callbackFailure();

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().views.provider_schedule.availability.delete.callbacks.error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                options: {
                  message:
                    translation().views.provider_schedule.availability.delete.callbacks.error,
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    },
    postUnavailability: (
      providerId,
      startDate,
      values,
      timezone,
      callbackSuccess,
      callbackFailure
    ) => {
      if (
        !providerId ||
        !startDate ||
        !values ||
        !values.hour_start ||
        !values.min_start ||
        !values.hour_end ||
        !values.min_end
      )
        return;

      const day = ownFormatJSDate(startDate);
      const start = createDate(
        day + 'T' + values.hour_start + ':' + values.min_start + ':00',
        timezone
      );
      const end = createDate(day + 'T' + values.hour_end + ':' + values.min_end + ':00', timezone);

      if (!start || !end) return;

      const startUtc = createDateUtc(start).toString();
      const endUtc = createDateUtc(end).toString();

      if (!values.latitude || !values.longitude) {
        dispatch(
          enqueueSnackbar({
            message:
              translation().views.provider_schedule.unavailability.create.callbacks.no_lat_lng,
            options: {
              variant: 'warning'
            }
          })
        );
        return;
      }

      dispatch(postUnavailability());

      const unavailability = {
        title: values.title ? values.title : '',
        location: values.location,
        latitude: values.latitude,
        longitude: values.longitude,
        start: startUtc,
        end: endUtc
      };

      fetchApi(
        'post',
        `providers/${providerId}/unavailabilities`,
        null,
        unavailability,
        null,
        (success) => {
          const newUnavailability = {
            ...success,
            type: 'unavailability',
            details: {
              id: success.id
            },
            location: {
              address: success.location,
              longitude: success.longitude,
              latitude: success.latitude
            }
          };

          dispatch(postUnavailabilitySuccess(newUnavailability));

          if (callbackSuccess && typeof callbackSuccess === 'function') callbackSuccess();

          dispatch(
            enqueueSnackbar({
              message:
                translation().views.provider_schedule.unavailability.create.callbacks.success,
              options: {
                variant: 'success'
              }
            })
          );
        },
        (error) => {
          dispatch(postUnavailabilityError(error));

          if (callbackFailure && typeof callbackFailure === 'function') callbackFailure();

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().views.provider_schedule.unavailability.create.callbacks.error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message:
                  translation().views.provider_schedule.unavailability.create.callbacks.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    },
    deleteUnavailability: (providerId, id, callbackSuccess, callbackFailure) => {
      if (!providerId || !id) return;

      dispatch(deleteUnavailability());

      fetchApi(
        'delete',
        `providers/${providerId}/unavailabilities/${id}`,
        null,
        null,
        null,
        () => {
          dispatch(deleteUnavailabilitySuccess(id));

          if (callbackSuccess && typeof callbackSuccess === 'function') callbackSuccess();

          dispatch(
            enqueueSnackbar({
              message:
                translation().views.provider_schedule.unavailability.delete.callbacks.success,
              options: {
                variant: 'success'
              }
            })
          );
        },
        (error) => {
          dispatch(deleteUnavailabilityError(error));

          if (callbackFailure && typeof callbackFailure === 'function') callbackFailure();

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().views.provider_schedule.unavailability.delete.callbacks.error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message:
                  translation().views.provider_schedule.unavailability.delete.callbacks.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    },
    putUnavailability: (
      providerId,
      id,
      startDate,
      values,
      timezone,
      callbackSuccess,
      callbackFailure
    ) => {
      if (
        !providerId ||
        !id ||
        !startDate ||
        !values ||
        !values.hour_start ||
        !values.min_start ||
        !values.hour_end ||
        !values.min_end
      )
        return;

      const day = ownFormatJSDate(startDate);
      const start = createDate(
        day + 'T' + values.hour_start + ':' + values.min_start + ':00',
        timezone
      );
      const end = createDate(day + 'T' + values.hour_end + ':' + values.min_end + ':00', timezone);

      if (!start || !end) return;

      const startUtc = createDateUtc(start).toString();
      const endUtc = createDateUtc(end).toString();

      if (!values.latitude || !values.longitude) {
        dispatch(
          enqueueSnackbar({
            message: translation().views.provider_schedule.unavailability.edit.callbacks.no_lat_lng,
            options: {
              variant: 'warning'
            }
          })
        );
        return;
      }

      dispatch(putUnavailability());

      const newValues = {
        title: values.title ? values.title : '',
        location: values.location,
        latitude: values.latitude,
        longitude: values.longitude,
        start: startUtc,
        end: endUtc
      };

      fetchApi(
        'put',
        `providers/${providerId}/unavailabilities/${id}`,
        null,
        newValues,
        null,
        (success) => {
          const newUnavailability = {
            ...success,
            type: 'unavailability',
            details: {
              id: success.id
            },
            location: {
              address: success.location,
              longitude: success.longitude,
              latitude: success.latitude
            }
          };

          dispatch(putUnavailabilitySuccess(id, newUnavailability));

          if (callbackSuccess && typeof callbackSuccess === 'function') callbackSuccess();

          dispatch(
            enqueueSnackbar({
              message: translation().views.provider_schedule.unavailability.edit.callbacks.success,
              options: {
                variant: 'success'
              }
            })
          );
        },
        (error) => {
          dispatch(putUnavailabilityError(error));

          if (callbackFailure && typeof callbackFailure === 'function') callbackFailure();

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().views.provider_schedule.unavailability.edit.callbacks.error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().views.provider_schedule.unavailability.edit.callbacks.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    },
    resizeAvailability(
      providerId,
      event,
      startDate,
      endDate,
      timezone,
      callbackSuccess = null,
      callbackFailure = null
    ) {
      if (!providerId || !event || !event.id || !startDate || !endDate) return;

      const day = ownFormatJSDate(startDate);
      let hoursStart = startDate.getHours().toString();
      let minsStart = startDate.getMinutes().toString();
      let hoursEnd = endDate.getHours().toString();
      let minsEnd = endDate.getMinutes().toString();

      if (minsStart.length < 2) minsStart = '0' + minsStart;
      if (hoursStart.length < 2) hoursStart = '0' + hoursStart;
      if (hoursEnd.length < 2) hoursEnd = '0' + hoursEnd;
      if (minsEnd.length < 2) minsEnd = '0' + minsEnd;

      const start = createDate(day + 'T' + hoursStart + ':' + minsStart + ':00', timezone);
      const end = createDate(day + 'T' + hoursEnd + ':' + minsEnd + ':00', timezone);

      if (!start || !end) return;

      const startUtc = createDateUtc(start).toString();
      const endUtc = createDateUtc(end).toString();

      const availability = {
        start: startUtc,
        end: endUtc
      };

      dispatch(resizeAvailability(event.id, { ...event, ...availability }));

      fetchApi(
        'put',
        `providers/${providerId}/availabilities/${event.id}`,
        null,
        availability,
        null,
        (success) => {
          /**
           * We need to force some keys in redux for use function binded by ids and type
           */
          success.type = 'availability';
          success.details = {};
          success.details.id = success.id;

          dispatch(resizeAvailabilitySuccess(event.id, success));

          if (callbackSuccess && typeof callbackSuccess === 'function') callbackSuccess();

          dispatch(
            enqueueSnackbar({
              message: translation().views.provider_schedule.availability.edit.callbacks.success,
              options: {
                variant: 'success'
              }
            })
          );
        },
        (error) => {
          const previousDay = ownFormatJSDate(event.start);
          let prevHoursStart = event.start.getHours().toString();
          let prevMinsStart = event.start.getMinutes().toString();
          let prevHoursEnd = event.end.getHours().toString();
          let prevMinsEnd = event.end.getMinutes().toString();

          if (prevMinsStart.length < 2) prevMinsStart = '0' + prevMinsStart;
          if (prevHoursStart.length < 2) prevHoursStart = '0' + prevHoursStart;
          if (prevHoursEnd.length < 2) prevHoursEnd = '0' + prevHoursEnd;
          if (prevMinsEnd.length < 2) prevMinsEnd = '0' + prevMinsEnd;

          const start = createDate(
            previousDay + 'T' + prevHoursStart + ':' + prevMinsStart + ':00',
            timezone
          );
          const end = createDate(
            previousDay + 'T' + prevHoursEnd + ':' + prevMinsEnd + ':00',
            timezone
          );

          event.start = start;
          event.end = end;

          dispatch(resizeAvailabilityFailure(event.id, event));

          if (callbackFailure && typeof callbackFailure === 'function') callbackFailure();

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().views.provider_schedule.availability.edit.callbacks.error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().views.provider_schedule.availability.edit.callbacks.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    },
    resizeUnavailability(
      providerId,
      event,
      startDate,
      endDate,
      timezone,
      callbackSuccess = null,
      callbackFailure = null
    ) {
      if (!providerId || !event || !event.id || !startDate || !endDate) return;

      const day = ownFormatJSDate(startDate);
      let hoursStart = startDate.getHours().toString();
      let minsStart = startDate.getMinutes().toString();
      let hoursEnd = endDate.getHours().toString();
      let minsEnd = endDate.getMinutes().toString();

      if (minsStart.length < 2) minsStart = '0' + minsStart;
      if (hoursStart.length < 2) hoursStart = '0' + hoursStart;
      if (hoursEnd.length < 2) hoursEnd = '0' + hoursEnd;
      if (minsEnd.length < 2) minsEnd = '0' + minsEnd;

      const start = createDate(day + 'T' + hoursStart + ':' + minsStart + ':00', timezone);
      const end = createDate(day + 'T' + hoursEnd + ':' + minsEnd + ':00', timezone);

      if (!start || !end) return;

      const startUtc = createDateUtc(start).toString();
      const endUtc = createDateUtc(end).toString();

      if (!event.location || !event.location.latitude || !event.location.longitude) {
        dispatch(
          enqueueSnackbar({
            message: translation().schedule.unavailability.edit.callbacks.no_lat_lng,
            options: {
              variant: 'warning'
            }
          })
        );
        return;
      }

      const newValues = {
        title: event.title ? event.title : '',
        location: event.location.location,
        latitude: event.location.latitude,
        longitude: event.location.longitude,
        start: startUtc,
        end: endUtc
      };

      dispatch(resizeUnavailability(event.id, { ...event, ...newValues }));

      fetchApi(
        'put',
        `providers/${providerId}/unavailabilities/${event.id}`,
        null,
        newValues,
        null,
        (success) => {
          const newUnavailability = {
            ...success,
            type: 'unavailability',
            details: {
              id: success.id
            },
            location: {
              address: success.location,
              longitude: success.longitude,
              latitude: success.latitude
            }
          };

          dispatch(resizeUnavailabilitySuccess(event.id, newUnavailability));

          if (callbackSuccess && typeof callbackSuccess === 'function') callbackSuccess();

          dispatch(
            enqueueSnackbar({
              message: translation().views.provider_schedule.unavailability.edit.callbacks.success,
              options: {
                variant: 'success'
              }
            })
          );
        },
        (error) => {
          const previousDay = ownFormatJSDate(event.start);
          let prevHoursStart = event.start.getHours().toString();
          let prevMinsStart = event.start.getMinutes().toString();
          let prevHoursEnd = event.end.getHours().toString();
          let prevMinsEnd = event.end.getMinutes().toString();

          if (prevMinsStart.length < 2) prevMinsStart = '0' + prevMinsStart;
          if (prevHoursStart.length < 2) prevHoursStart = '0' + prevHoursStart;
          if (prevHoursEnd.length < 2) prevHoursEnd = '0' + prevHoursEnd;
          if (prevMinsEnd.length < 2) prevMinsEnd = '0' + prevMinsEnd;

          const start = createDate(
            previousDay + 'T' + prevHoursStart + ':' + prevMinsStart + ':00',
            timezone
          );
          const end = createDate(
            previousDay + 'T' + prevHoursEnd + ':' + prevMinsEnd + ':00',
            timezone
          );

          event.start = start;
          event.end = end;

          dispatch(resizeUnavailabilityFailure(event.id, event));

          if (callbackFailure && typeof callbackFailure === 'function') callbackFailure();

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().views.provider_schedule.unavailability.edit.callbacks.error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().views.provider_schedule.unavailability.edit.callbacks.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    },
    getOrderProductTripDistance: (orderId, productId, callbackSuccess, callbackFailure) => {
      if (!orderId || !productId) return;

      fetchApi(
        'get',
        `orders/${orderId}/products/${productId}/trip-distance-calc`,
        { details: true },
        null,
        null,
        (success) => {
          if (callbackSuccess && typeof callbackSuccess === 'function') callbackSuccess(success);
        },
        (error) => {
          if (callbackFailure && typeof callbackFailure === 'function') callbackFailure();

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().views.provider_schedule.orders.trip_distance.callback_error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().views.provider_schedule.orders.trip_distance.callback_error,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProviderSchedule));
