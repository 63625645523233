import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import OrderProducts from './OrderProducts';
import translation from '../../../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    minHeight: 60,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.cardHeader
  },
  cardContent: {
    overflow: 'auto',
    minHeight: 420,
    padding: theme.spacing(1),
    '&:last-child': {
      paddingBottom: 0
    },
    [theme.breakpoints.down('md')]: {
      minHeight: 'auto'
    }
  },
  cardLarge: {
    minHeight: 520
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(1)
  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2)
  }
}));

function ProviderOrderProducts(props) {
  const classes = useStyles();

  const {
    cleanProviderViewOrderProductsReducer,
    getOrderProductsMetadata,
    getProviderViewOrderProducts,
    providerId,
    orderProducts
  } = props;

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card className={classes.paper}>
            <CardHeader
              className={classes.cardHeader}
              title={translation().views.provider_view.order_products.label}
              titleTypographyProps={{
                variant: 'h5',
                style: {
                  fontSize: 21
                }
              }}
              avatar={
                <Avatar>
                  <ShoppingCartIcon />
                </Avatar>
              }
            />
            <CardContent
              className={classNames(
                classes.cardContent,
                classes.cardLarge,
                'ProviderOrderProducts'
              )}>
              <OrderProducts
                cleanProviderViewOrderProductsReducer={cleanProviderViewOrderProductsReducer}
                getMetadata={getOrderProductsMetadata}
                getProviderViewOrderProducts={getProviderViewOrderProducts}
                orderProducts={orderProducts}
                providerId={providerId}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

ProviderOrderProducts.propTypes = {
  cleanProviderViewOrderProductsReducer: PropTypes.func.isRequired,
  getOrderProductsMetadata: PropTypes.func.isRequired,
  getProviderViewOrderProducts: PropTypes.func.isRequired,
  providerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  orderProducts: PropTypes.shape({
    isLoading: PropTypes.bool
  }).isRequired
};

export default ProviderOrderProducts;
