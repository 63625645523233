import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';

import FormPhoneField from '../../../../../../components/forms/fields/customs/formPhoneField/FormPhoneField';
import translation from '../../../../../../translation/translation';

function OrderProductDetails(props) {
  const {
    accessDetails,
    classes,
    contactName,
    contactPhone,
    comment,
    children,
    orderId,
    orderProductId,
    productPropertyDetails,
    reloadOrder,
    updateOrderProductPropertyDetails
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [details, setDetails] = useState({
    property_access_details: accessDetails,
    property_contact_comment: comment,
    property_contact_name: contactName,
    property_contact_phone: contactPhone
  });

  function handleOpenPropertyDetailsDialog() {
    setIsOpen(true);
  }

  function handleClosePropertyDetailsDialog() {
    setIsOpen(false);
  }

  const handleChangeValue = (name) => (event) => {
    setDetails({
      ...details,
      [name]: event.target.value
    });
  };

  function handleChangePhoneInput(val) {
    setDetails({
      ...details,
      property_contact_phone: val
    });
  }

  function handleSubmit() {
    updateOrderProductPropertyDetails(orderId, orderProductId, details, reloadOrder);
  }

  return (
    <div className="OrderProductDetails">
      <ListItem button className={classes.listItem} onClick={handleOpenPropertyDetailsDialog}>
        {children}
      </ListItem>
      <Dialog
        fullWidth
        disableBackdropClick={productPropertyDetails.isLoading}
        open={isOpen ? true : false}
        onClose={handleClosePropertyDetailsDialog}
        aria-labelledby="product-property-details"
        aria-describedby="product-property-details-description">
        <DialogTitle id="product-property-details">
          {translation().views.orders.update.products.product_property_details.title}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoComplete="off"
            disabled={productPropertyDetails.isLoading}
            id="property_contact_name"
            fullWidth
            label={
              translation().views.orders.update.products.product_property_details
                .property_contact_name
            }
            name="property_contact_name"
            margin="normal"
            onChange={handleChangeValue('property_contact_name')}
            type="text"
            value={details['property_contact_name'] || ''}
          />
          <FormPhoneField
            id="property_contact_phone"
            name="property_contact_phone"
            disabled={productPropertyDetails.isLoading}
            onChange={handleChangePhoneInput}
            placeholder={
              translation().views.orders.update.products.product_property_details
                .property_contact_phone
            }
            label={
              translation().views.orders.update.products.product_property_details
                .property_contact_phone
            }
            type="tel"
            value={details['property_contact_phone'] || ''}
          />
          <TextField
            autoComplete="off"
            id="property_access_details"
            disabled={productPropertyDetails.isLoading}
            fullWidth
            label={
              translation().views.orders.update.products.product_property_details
                .property_access_details
            }
            margin="normal"
            multiline
            name="property_access_details"
            onChange={handleChangeValue('property_access_details')}
            rows={6}
            type="text"
            value={details['property_access_details'] || ''}
            inputProps={{
              style: { resize: 'vertical' }
            }}
          />
          <TextField
            autoComplete="off"
            id="property_contact_comment"
            disabled={productPropertyDetails.isLoading}
            fullWidth
            label={
              translation().views.orders.update.products.product_property_details
                .property_contact_comment
            }
            margin="normal"
            multiline
            name="property_contact_comment"
            onChange={handleChangeValue('property_contact_comment')}
            rows={6}
            type="text"
            value={details['property_contact_comment'] || ''}
            inputProps={{
              style: { resize: 'vertical' }
            }}
          />
          {productPropertyDetails.isLoading && (
            <div className="loader-wpr">
              <CircularProgress size={30} color="secondary" />
              <p>
                {translation().views.orders.update.products.product_property_details.loading_text}
              </p>
            </div>
          )}
          <div className="sm-space" />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClosePropertyDetailsDialog}
            color="default"
            disabled={productPropertyDetails.isLoading}>
            {translation().actions.close}
          </Button>
          <Button
            color="primary"
            onClick={handleSubmit}
            variant="contained"
            disabled={productPropertyDetails.isLoading}>
            {translation().actions.save}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

OrderProductDetails.propTypes = {
  accessDetails: PropTypes.any,
  classes: PropTypes.shape(),
  contactName: PropTypes.string,
  contactPhone: PropTypes.string,
  comment: PropTypes.string,
  children: PropTypes.node.isRequired,
  orderId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  orderProductId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  productPropertyDetails: PropTypes.shape().isRequired,
  reloadOrder: PropTypes.func.isRequired,
  updateOrderProductPropertyDetails: PropTypes.func.isRequired
};

export default OrderProductDetails;
