import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import AddLocationIcon from '@material-ui/icons/AddLocation';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import StepConnector from '@material-ui/core/StepConnector';

import config from '../../../../config';

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,' +
        config.primary_color +
        ' 0%,' +
        config.primary_color +
        ' 50%,' +
        config.secondary_color +
        ' 100%)'
    }
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,' +
        config.primary_color +
        ' 0%,' +
        config.primary_color +
        ' 50%,' +
        config.secondary_color +
        ' 100%)'
    }
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1
  }
})(StepConnector);

const useColorlibStepIconStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.type === 'dark' ? '#333' : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  active: {
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    backgroundImage:
      'linear-gradient( 136deg,' +
      config.primary_color +
      ' 0%,' +
      config.primary_color +
      ' 50%,' +
      config.secondary_color +
      ' 100%)'
  },
  completed: {
    backgroundImage:
      'linear-gradient( 136deg,' +
      config.primary_color +
      ' 0%,' +
      config.primary_color +
      ' 50%,' +
      config.secondary_color +
      ' 100%)'
  }
}));

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <AddLocationIcon />,
    2: <AddShoppingCartIcon />,
    3: <CreditCardIcon />
  };

  return (
    <div
      className={classNames(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed
      })}>
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  button: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  stepper: {
    padding: theme.spacing(2, 0),
    backgroundColor: 'transparent'
  }
}));

function OrderStepper(props) {
  const { activeStep, steps } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        className={classes.stepper}
        connector={<ColorlibConnector />}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}

OrderStepper.defaultProps = {
  activeStep: 0
};

OrderStepper.propTypes = {
  steps: PropTypes.array.isRequired, // eslint-disable-line
  activeStep: PropTypes.number.isRequired
};

export default OrderStepper;
