import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import Alert from '../../../components/alert/Alert';
import translation from '../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      minWidth: 350
    }
  },
  secret: {
    color: theme.palette.secondary.main,
    display: 'inline-block',
    fontSize: 16
  },
  secretLabel: {
    display: 'inline-block',
    marginRight: 4
  }
}));

function ApplicationSecret(props) {
  const { getApplicationSecret, resource } = props;
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  const [secret, setSecret] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (resource && resource.id) {
      setError(null);
      setLoading(true);

      getApplicationSecret(
        resource.id,
        (success) => {
          setLoading(false);

          if (success.api_secret) {
            setSecret(success.api_secret);
          } else {
            setSecret(
              translation().core.list.dialogs.custom_list_actions.application_secret.callbacks.empty
            );
          }
        },
        () => {
          setLoading(false);
          setSecret(null);
          setError(
            translation().core.list.dialogs.custom_list_actions.application_secret.callbacks.empty
          );
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="ApplicationSecret">
      <div className={classes.root}>
        {secret && (
          <>
            <Typography className={classes.secretLabel}>Secret:</Typography>
            <Typography className={classes.secret}>{secret}</Typography>
          </>
        )}
        {error && (
          <Alert
            type="danger"
            style={{ marginTop: 10 }}
            status={translation().commons.alerts.error}
            text={error}
          />
        )}
        {isLoading && (
          <div className="loader-wpr">
            <CircularProgress color="primary" size={25} />
            <p>
              {translation().core.list.dialogs.custom_list_actions.application_secret.load_secret}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

ApplicationSecret.propTypes = {
  getApplicationSecret: PropTypes.func.isRequired,
  resource: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  })
};

export default ApplicationSecret;
