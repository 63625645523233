import React, { useState, useEffect, PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PhoneInput from 'react-phone-number-input';
import { makeStyles, withTheme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { green, grey, orange } from '@material-ui/core/colors';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Avatar from '@material-ui/core/Avatar';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DirectionsIcon from '@material-ui/icons/Directions';
import Divider from '@material-ui/core/Divider';
import EuroIcon from '@material-ui/icons/Euro';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import FeedbackIcon from '@material-ui/icons/Feedback';
import Grid from '@material-ui/core/Grid';
import HistoryIcon from '@material-ui/icons/History';
import HourglassEmptyRoundedIcon from '@material-ui/icons/HourglassEmptyRounded';
import IconButton from '@material-ui/core/IconButton';
import LabelOffIcon from '@material-ui/icons/LabelOff';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import LockIcon from '@material-ui/icons/Lock';
import NaturePeopleIcon from '@material-ui/icons/NaturePeople';
import Rating from '@material-ui/lab/Rating';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import Alert from '../../../../../components/alert/Alert';
import DeliveryTimeIcon from '../../../../../components/icons/DeliveryTimeIcon';
import PunctualityIcon from '../../../../../components/icons/PunctualityIcon';
import MediasQualityIcon from '../../../../../components/icons/MediasQualityIcon';
import BehaviourIcon from '../../../../../components/icons/BehaviourIcon';
import PropertyStatusIcon from '../../../../../components/icons/PropertyStatusIcon';
import LocalDateInfo from '../../../../../components/timezone/LocalDateInfo';
import AddOptionButton from './components/AddOptionButton';
import DateStartButton from './components/DateStartButton';
import DeliveryStatusButton from './components/DeliveryStatusButton';
import FlagButton from './components/FlagButton';
import OptionChip from './components/OptionChip';
import OrderProductDetails from './components/OrderProductDetails';
import ProvidersButton from './components/ProvidersButton';
import MediasContainer from '../../../../../containers/medias/MediasContainer';
import { convertMinsToHrsMins, dateInTz } from '../../../../../utils/functions/dates';
import { getTimezone } from '../../../../../utils/functions/cookies';
import {
  getUrlsArrayFromString,
  isObjEmpty,
  realRound
} from '../../../../../utils/functions/utils';

import translation from '../../../../../translation/translation';
import config from '../../../../../config';
import AttachedFile from './components/AttachedFile';
import Attributes from './components/Attributes';

const useStyles = makeStyles((theme) => ({
  avatar: {
    padding: 7
  },
  attributesWrapper: {
    width: '100%',
    padding: '4px 0px 0px',
    justifyContent: 'flex-start',
    '&:hover': {
      backgroundColor: fade(theme.palette.text.primary, theme.palette.action.hoverOpacity)
    }
  },
  cardActions: {
    padding: theme.spacing(1),
    marginLeft: 4
  },
  cancelButton: {
    color: config.error_color || '#F44336',
    '&:hover': {
      backgroundColor: fade(config.error_color || '#F44336', 0.15)
    }
  },
  cancelIconWithFlag: {
    color: '#FFFFFF',
    '&:hover': {
      color: '#CA1010'
    }
  },
  cancelIconButton: {
    color: '#FFFFFF',
    '&:hover': {
      color: fade(config.error_color || '#F44336', 1)
    }
  },
  cardHeader: {
    backgroundColor: theme.palette.secondary.main
  },
  cardHeaderImportant: {
    backgroundColor: theme.palette.type === 'dark' ? '#cb6059' : config.error_color || '#F44336'
  },
  cardRoot: {
    marginBottom: theme.spacing(2),
    '&:last-child': {
      marginBottom: theme.spacing(2)
    }
  },
  cardTitle: {
    fontSize: 18,
    fontWeight: 500,
    color: '#FFFFFF'
  },
  cardSubTitle: {
    color: '#bbbbbb'
  },
  cardIconAction: {
    color: '#FFFFFF'
  },
  dialogContentMedias: {
    padding: 0
  },
  deliveryStatus: {
    height: 21,
    width: 21,
    '& img': {
      backgroundColor: 'transparent'
    }
  },
  detailSpan: {
    marginLeft: 4,
    fontWeight: 500
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  financialTitle: {
    fontSize: 14
  },
  issueImg: {
    width: 100,
    marginBottom: 8
  },
  lineHeight: {
    lineHeight: 2
  },
  listItemBox: {
    minWidth: 35
  },
  listItem: {
    padding: 4
  },
  listRoot: {
    padding: 0
  },
  timezoneColor: {
    color: orange[400]
  },
  settled: {
    cursor: 'default',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 30,
    marginLeft: 'auto!important'
  },
  optionsWrapper: {
    padding: theme.spacing(1.5, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5)
    }
  },
  optionsList: {
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'auto'
  },
  providerInfosB: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    marginLeft: 4
  },
  providerIssue: {
    margin: 24,
    textAlign: 'center',
    '&:first-child': {
      marginLeft: 0
    }
  },
  providerIssueNoImg: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    color: config.error_color || '#F44336',
    border: '1px solid ' + config.error_color || '#F44336'
  },
  providerIssues: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  productCancelledLabel: {
    border: '0!important',
    borderRadius: 4,
    display: 'inline-flex',
    padding: theme.spacing(0, 2)
  },
  providerRating: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      '& legend': {
        fontWeight: 500
      }
    }
  },
  ratingTitle: {
    fontWeight: 500
  },
  ratingWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  pptyContactComment: {
    wordBreak: 'break-word'
  },
  attachedFiles: {
    display: 'flex',
    flexDirection: 'row',
    flexFlow: 'wrap'
  },
  attachedFilesButton: {
    backgroundColor: theme.palette.type === 'dark' ? '#212121' : '#FFFFFF',
    '&:hover': {
      backgroundColor:
        theme.palette.type === 'dark' ? 'rgba(0, 0, 0, 0.4)' : 'rgba(255, 255, 255, 0.4)'
    }
  }
}));

class CustomHref extends PureComponent {
  render() {
    return <a {...this.props}>{this.props.children}</a>;
  }
}

CustomHref.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired
};

function ProductCard(props) {
  const classes = useStyles();

  const {
    addOrderProductOption,
    amount_commissions_tax_excluded_formatted,
    amount_commissions_tax_formatted,
    amount_commissions_formatted,
    amount_formatted,
    amount_tax_excluded,
    amount_tax_excluded_formatted,
    amount_provider_reversion_tax_excluded_formatted,
    amount_provider_reversion_tax_formatted,
    amount_provider_reversion_formatted,
    amount_provider_trip_compensation_tax_excluded_formatted,
    attributes,
    cancellable,
    cancelled,
    cancelOrderProduct,
    clearOrderUpdatePlanning,
    client_rating,
    client_rating_comment,
    client_rating_issues,
    commissions,
    delivery_status,
    duration,
    expired,
    expire,
    history,
    icon_url_dark,
    icon_url_light,
    id,
    flag,
    getOrderProductDeliveryStatuses,
    getOrderProductDispatchableProviders,
    getOrderProductHistory,
    getOrderProductProviderInfos,
    getOrderProductSlots,
    getNextOrderProductSlots,
    getPreviousOrderProductSlots,
    manageOrderProductFlag,
    name,
    net_margin,
    net_margin_formatted,
    options,
    order_id,
    orderProperty,
    postOrderProductDispatchableProviders,
    product_id,
    productDeliveryStatuses,
    productCancel,
    productFlag,
    productHistory,
    productOptions,
    productPropertyDetails,
    productPlanning,
    productProvider,
    property_access_details,
    property_contact_comment,
    property_contact_name,
    property_contact_phone,
    provider_id,
    providersDispatching,
    providersInfos,
    provider_name,
    provider_rating,
    provider_rating_comment,
    provider_rating_issues,
    reloadOrder,
    removeOrderProductOption,
    settled,
    start,
    theme,
    updateOrderProductAttributes,
    updateOrderProductDeliveryStatus,
    updateOrderProductProvider,
    updateOrderProductStartDate,
    updateOrderProductPropertyDetails
  } = props;

  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
  const [isClientRatingDialogOpen, setIsClientRatingDialogOpen] = useState(false);
  const [isHistoryDialogOpen, setIsHistoryDialogOpen] = useState(false);
  const [isPricingDialogOpen, setIsPricingDialogOpen] = useState(false);
  const [isProviderDialogOpen, setIsProviderDialogOpen] = useState(false);
  const [isOrderProductMediasOpen, setIsOrderProductMediasDialogOpen] = useState(false);
  const [isProviderRatingDialogOpen, setIsProviderRatingDialogOpen] = useState(false);
  const [isAttachedFilesDialogOpen, setAttachedFilesDialogOpen] = useState(false);

  const [isAttributesDialogOpen, setAttributesDialogOpen] = useState(false);
  const [attachedFiles, setAttachedFiles] = useState([]);

  useEffect(() => {
    /**
     * Check if there are actions on product in the url
     */
    if (history && history.location && history.location.hash) {
      /**
       * Action: Open order product medias
       */
      if (history.location.hash.includes('medias')) {
        const orderProductHashId = history.location.hash.slice(8);
        if (orderProductHashId === id.toString()) {
          setIsOrderProductMediasDialogOpen(true);
        }
      }
    }

    if (property_contact_comment) {
      const urls = getUrlsArrayFromString(property_contact_comment);

      if (urls && urls.length > 0) {
        setAttachedFiles(urls);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleOpenProviderDialog() {
    setIsProviderDialogOpen(true);
  }

  function handleCloseProviderDialog() {
    setIsProviderDialogOpen(false);
  }

  function handleProviderDialogEnter() {
    if (!provider_id || !id) return;

    getOrderProductProviderInfos(provider_id, id);
  }

  function handleOpenHistoryDialog() {
    setIsHistoryDialogOpen(true);
  }

  function handleCloseHistoryDialog() {
    setIsHistoryDialogOpen(false);
  }

  function handleOpenHistoryDialogEnter() {
    if (!order_id || !id) return;

    getOrderProductHistory(order_id, id);
  }

  function handleOpenPricingDialog() {
    setIsPricingDialogOpen(true);
  }

  function handleClosePricingDialog() {
    setIsPricingDialogOpen(false);
  }

  function handleOpenCancelDialog() {
    setIsCancelDialogOpen(true);
  }

  function handleCloseCancelDialog() {
    setIsCancelDialogOpen(false);
  }

  function handleCancelOrderProduct() {
    if (!order_id || !id) return;

    cancelOrderProduct(order_id, id, reloadOrder);
  }

  function handleOpenProviderRatingDialog() {
    setIsProviderRatingDialogOpen(true);
  }

  function handleCloseProviderRatingDialog() {
    setIsProviderRatingDialogOpen(false);
  }

  function handleOpenClientRatingDialog() {
    setIsClientRatingDialogOpen(true);
  }

  function handleCloseClientRatingDialog() {
    setIsClientRatingDialogOpen(false);
  }

  function handleOpenOrderProductMediasDialog() {
    setIsOrderProductMediasDialogOpen(true);
  }

  function handleCloseOrderProductMediasDialog() {
    setIsOrderProductMediasDialogOpen(false);
  }

  function handleUploadOrderSuccess() {
    reloadOrder();
  }

  function handleBlockChange() {
    return false;
  }

  function handleOpenAttachedFilesDialog() {
    setAttachedFilesDialogOpen(true);
  }

  function handleCloseAttachedFilesDialog() {
    setAttachedFilesDialogOpen(false);
  }

  function handleOpenAttributesDialog() {
    setAttributesDialogOpen(true);
  }

  function handleCloseAttributesDialog() {
    setAttributesDialogOpen(false);
  }

  function getAttributesCompletion() {
    const total = Object.keys(attributes).length;
    let completed = 0;

    for (const key in attributes) {
      if (attributes[key].value) {
        completed += 1;
      }
    }

    return {
      color: completed === total ? green[400] : grey[300],
      textColor: completed === total ? '#FFFFFF' : theme.palette.text.primary,
      value: `${completed}/${total}`
    };
  }

  return (
    <Card className={classNames(classes.cardRoot, 'OrderUpdateProduct')}>
      <CardHeader
        action={
          <>
            <FlagButton
              className={classes.cardIconAction}
              flag={flag}
              manageOrderProductFlag={manageOrderProductFlag}
              orderId={order_id}
              orderProductId={id}
              productFlag={productFlag}
              reloadOrder={reloadOrder}
            />
            <Tooltip title={translation().views.orders.update.open_medias} enterDelay={500}>
              <IconButton
                aria-label="medias"
                className={classes.cardIconAction}
                onClick={handleOpenOrderProductMediasDialog}>
                <PermMediaIcon />
              </IconButton>
            </Tooltip>
            <DeliveryStatusButton
              onEnter={getOrderProductDeliveryStatuses}
              className={classes.cardIconAction}
              orderId={order_id}
              orderProductId={id}
              reloadOrder={reloadOrder}
              currentStatus={delivery_status}
              productDeliveryStatuses={productDeliveryStatuses}
              updateOrderProductDeliveryStatus={updateOrderProductDeliveryStatus}
            />
            {!cancelled && start && (
              <DateStartButton
                className={classes.cardIconAction}
                clearOrderUpdatePlanning={clearOrderUpdatePlanning}
                currentStartDate={start}
                getOrderProductSlots={getOrderProductSlots}
                getNextOrderProductSlots={getNextOrderProductSlots}
                getPreviousOrderProductSlots={getPreviousOrderProductSlots}
                orderId={order_id}
                orderProductId={id}
                options={options}
                timezone={orderProperty.timezone_code}
                productPlanning={productPlanning}
                property={orderProperty}
                reloadOrder={reloadOrder}
                updateOrderProductStartDate={updateOrderProductStartDate}
              />
            )}
            <ProvidersButton
              className={classes.cardIconAction}
              currentProvider={{
                id: provider_id,
                name: provider_name
              }}
              getOrderProductDispatchableProviders={getOrderProductDispatchableProviders}
              orderId={order_id}
              orderProductId={id}
              productProvider={productProvider}
              providersDispatching={providersDispatching}
              postOrderProductDispatchableProviders={postOrderProductDispatchableProviders}
              reloadOrder={reloadOrder}
              updateOrderProductProvider={updateOrderProductProvider}
            />
            <Tooltip
              title={translation().views.orders.update.products.product_history.title}
              enterDelay={500}
              onClick={handleOpenHistoryDialog}>
              <IconButton aria-label="history" className={classes.cardIconAction}>
                <HistoryIcon />
              </IconButton>
            </Tooltip>
            {cancellable && (
              <Tooltip
                title={translation().views.orders.update.products.product_cancel.title}
                enterDelay={500}>
                <IconButton
                  aria-label="Cancel"
                  className={flag ? classes.cancelIconWithFlag : classes.cancelIconButton}
                  onClick={handleOpenCancelDialog}>
                  <CancelOutlinedIcon />
                </IconButton>
              </Tooltip>
            )}
          </>
        }
        avatar={
          <Avatar
            alt={name}
            className={classNames(classes.avatar, 'MuiAvatar-colorDefault')}
            src={
              icon_url_dark && theme && theme.palette && theme.palette.type === 'dark'
                ? icon_url_dark
                : icon_url_light
            }
          />
        }
        classes={{
          action: classes.cardHeaderActions,
          root: flag ? classes.cardHeaderImportant : classes.cardHeader
        }}
        title={name}
        titleTypographyProps={{
          className: classes.cardTitle
        }}
        subheader={`${translation().views.orders.update.products.product_id} ${id}`}
        subheaderTypographyProps={{
          className: classes.cardSubTitle
        }}
      />
      <CardContent>
        {cancelled && (
          <Alert
            type="info"
            className={classes.productCancelledLabel}
            style={{ margin: '0 0 10px' }}
            icon={<LabelOffIcon className="alert-icon" />}
            text={`${translation().views.orders.update.products.cancelled} ${dateInTz(
              cancelled,
              'localized-long-datetime'
            )}`}
          />
        )}
        <Grid container spacing={2}>
          <Grid item xs={12} lg={4}>
            <List className={classes.listRoot}>
              {start ? (
                <>
                  <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemBox}>
                      <DateRangeIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={translation().views.orders.update.products.product_infos.labels.day}
                      secondary={dateInTz(start, 'localized-long-date')}
                      primaryTypographyProps={{
                        variant: 'body2'
                      }}
                    />
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemBox}>
                      <AccessTimeIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={translation().views.orders.update.products.product_infos.labels.hour}
                      secondary={dateInTz(start, 'HH:mm')}
                      primaryTypographyProps={{
                        variant: 'body2'
                      }}
                    />
                  </ListItem>
                  {orderProperty.timezone_code && orderProperty.timezone_code !== getTimezone() && (
                    <ListItem className={classes.listItem}>
                      <ListItemIcon
                        className={classNames(classes.listItemBox, classes.timezoneColor)}>
                        <ErrorOutlineIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={translation().commons.hour_on_place}
                        primaryTypographyProps={{
                          variant: 'body2'
                        }}
                        secondary={`${dateInTz(
                          start,
                          'localized-datetime',
                          orderProperty.timezone_code
                        )} (${orderProperty.timezone_code})`}
                        secondaryTypographyProps={{
                          className: classes.timezoneColor
                        }}
                      />
                    </ListItem>
                  )}
                </>
              ) : (
                false
              )}
              {duration ? (
                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.listItemBox}>
                    <TimelapseIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      translation().views.orders.update.products.product_infos.labels.duration
                    }
                    secondary={convertMinsToHrsMins(duration)}
                    primaryTypographyProps={{
                      variant: 'body2'
                    }}
                  />
                </ListItem>
              ) : (
                false
              )}
              {amount_tax_excluded_formatted && (
                <ListItem button className={classes.listItem} onClick={handleOpenPricingDialog}>
                  <ListItemIcon className={classes.listItemBox}>
                    <EuroIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      translation().views.orders.update.products.product_infos.labels.tax_excluded
                    }
                    secondary={`${amount_tax_excluded_formatted} ${
                      translation().commons.pricing.tax_excluded
                    }`}
                    primaryTypographyProps={{
                      variant: 'body2'
                    }}
                  />
                </ListItem>
              )}
              {delivery_status && delivery_status.icon_url_dark && delivery_status.name && (
                <ListItem className={classes.listItem}>
                  <ListItemAvatar className={classes.listItemBox}>
                    <Avatar
                      className={classes.deliveryStatus}
                      alt={delivery_status.name}
                      src={delivery_status.icon_url_dark}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={translation().views.orders.update.products.product_infos.labels.status}
                    secondary={delivery_status.name}
                    primaryTypographyProps={{
                      variant: 'body2'
                    }}
                    secondaryTypographyProps={
                      delivery_status.color
                        ? {
                            variant: 'subtitle2',
                            style: { color: delivery_status.color }
                          }
                        : {}
                    }
                  />
                </ListItem>
              )}
              {(expire || expired) && (
                <ListItem
                  button
                  className={classes.listItem}
                  component={CustomHref}
                  href={`/orders/${order_id}/products/${id}/hosting-extensions`}
                  target="_blank">
                  <ListItemIcon className={classes.listItemBox}>
                    <HourglassEmptyRoundedIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={translation().views.orders.update.products.product_infos.labels.expire}
                    secondary={
                      expire && !expired
                        ? dateInTz(expire, 'localized-date')
                        : expired
                        ? `${
                            translation().views.orders.update.products.product_infos.labels.expired
                          } ${dateInTz(expired, 'localized-date')}`
                        : dateInTz(expire, 'localized-date')
                    }
                    primaryTypographyProps={{
                      variant: 'body2'
                    }}
                  />
                </ListItem>
              )}
            </List>
          </Grid>
          <Grid item xs={12} lg={4}>
            <List className={classes.listRoot}>
              {provider_name && provider_id && (
                <ListItem button className={classes.listItem} onClick={handleOpenProviderDialog}>
                  <ListItemIcon className={classes.listItemBox}>
                    <BusinessCenterIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      translation().views.orders.update.products.product_infos.labels.provider
                    }
                    secondary={`${provider_name} (#${provider_id})`}
                    primaryTypographyProps={{
                      variant: 'body2'
                    }}
                  />
                </ListItem>
              )}
              {property_contact_name && (
                <OrderProductDetails
                  classes={{ listItem: classes.listItem }}
                  contactName={property_contact_name}
                  contactPhone={property_contact_phone}
                  accessDetails={property_access_details}
                  comment={property_contact_comment}
                  orderId={order_id}
                  orderProductId={id}
                  updateOrderProductPropertyDetails={updateOrderProductPropertyDetails}
                  productPropertyDetails={productPropertyDetails}
                  reloadOrder={reloadOrder}>
                  <ListItemIcon className={classes.listItemBox}>
                    <NaturePeopleIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      translation().views.orders.update.products.product_infos.labels
                        .person_on_place
                    }
                    secondary={property_contact_name}
                    primaryTypographyProps={{
                      variant: 'body2'
                    }}
                  />
                </OrderProductDetails>
              )}
              {property_contact_phone && (
                <OrderProductDetails
                  classes={{ listItem: classes.listItem }}
                  contactName={property_contact_name}
                  contactPhone={property_contact_phone}
                  accessDetails={property_access_details}
                  comment={property_contact_comment}
                  orderId={order_id}
                  orderProductId={id}
                  updateOrderProductPropertyDetails={updateOrderProductPropertyDetails}
                  productPropertyDetails={productPropertyDetails}
                  reloadOrder={reloadOrder}>
                  <ListItemIcon className={classes.listItemBox}>
                    <PhoneInput
                      value={property_contact_phone}
                      disabled
                      onChange={handleBlockChange}
                      className="orders-update-phone-input"
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      translation().views.orders.update.products.product_infos.labels.phone_on_place
                    }
                    secondary={property_contact_phone}
                    primaryTypographyProps={{
                      variant: 'body2'
                    }}
                  />
                </OrderProductDetails>
              )}
              {property_access_details && (
                <OrderProductDetails
                  classes={{ listItem: classes.listItem }}
                  contactName={property_contact_name}
                  contactPhone={property_contact_phone}
                  accessDetails={property_access_details}
                  comment={property_contact_comment}
                  orderId={order_id}
                  orderProductId={id}
                  updateOrderProductPropertyDetails={updateOrderProductPropertyDetails}
                  productPropertyDetails={productPropertyDetails}
                  reloadOrder={reloadOrder}>
                  <ListItemIcon className={classes.listItemBox}>
                    <DirectionsIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      translation().views.orders.update.products.product_infos.labels.access_details
                    }
                    secondary={property_access_details}
                    primaryTypographyProps={{
                      variant: 'body2'
                    }}
                  />
                </OrderProductDetails>
              )}
              {property_contact_comment && (
                <OrderProductDetails
                  classes={{ listItem: classes.listItem }}
                  contactName={property_contact_name}
                  contactPhone={property_contact_phone}
                  accessDetails={property_access_details}
                  comment={property_contact_comment}
                  orderId={order_id}
                  orderProductId={id}
                  updateOrderProductPropertyDetails={updateOrderProductPropertyDetails}
                  productPropertyDetails={productPropertyDetails}
                  reloadOrder={reloadOrder}>
                  <ListItemIcon className={classes.listItemBox}>
                    <FeedbackIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      translation().views.orders.update.products.product_infos.labels.comment
                    }
                    secondary={
                      property_contact_comment.split('\n').map((line, key) => {
                        return (
                          <span key={key}>
                            {line}
                            <br />
                          </span>
                        );
                      }) || property_contact_comment
                    }
                    primaryTypographyProps={{
                      variant: 'body2'
                    }}
                    secondaryTypographyProps={{
                      className: classes.pptyContactComment
                    }}
                  />
                  {attachedFiles && attachedFiles.length > 0 ? (
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        size="small"
                        aria-label="See attached files"
                        onClick={handleOpenAttachedFilesDialog}
                        className={classes.attachedFilesButton}>
                        <AttachFileIcon fontSize="small" />
                      </IconButton>
                    </ListItemSecondaryAction>
                  ) : (
                    false
                  )}
                </OrderProductDetails>
              )}
            </List>
          </Grid>
          <Grid item xs={12} lg={4}>
            {attributes && !isObjEmpty(attributes) ? (
              <ButtonBase
                className={classes.attributesWrapper}
                onClick={handleOpenAttributesDialog}>
                <Typography variant="subtitle2" gutterBottom>
                  {translation().views.orders.update.products.product_attributes.title}
                  :
                  <Chip
                    style={{
                      backgroundColor: getAttributesCompletion().color,
                      color: getAttributesCompletion().textColor,
                      marginLeft: 4,
                      cursor: 'inherit'
                    }}
                    label={getAttributesCompletion().value}
                  />
                </Typography>
              </ButtonBase>
            ) : (
              false
            )}
            <div className={classes.optionsWrapper}>
              <Typography variant="subtitle2" gutterBottom>
                {translation().views.orders.update.products.options}:
              </Typography>
              <div className={classes.optionsList}>
                {options && options.length > 0 ? (
                  options.map((option, index) => (
                    <OptionChip
                      key={index}
                      id={option.id}
                      label={option.name}
                      orderId={order_id}
                      orderProductId={id}
                      productOptions={productOptions}
                      removeOrderProductOption={removeOrderProductOption}
                      reloadOrder={reloadOrder}
                    />
                  ))
                ) : (
                  <Typography variant="body2" gutterBottom>
                    {translation().views.orders.update.products.no_option}
                  </Typography>
                )}
              </div>
              <div className="xxs-space" />
              <AddOptionButton
                addOrderProductOption={addOrderProductOption}
                orderId={order_id}
                orderProductId={id}
                productOptions={productOptions}
                property={orderProperty}
                productId={product_id}
                reloadOrder={reloadOrder}
              />
            </div>
          </Grid>
        </Grid>
      </CardContent>
      {(settled || provider_rating || client_rating) && (
        <CardActions className={classes.cardActions}>
          {(provider_rating || client_rating) && (
            <div className={classes.ratingWrapper}>
              {client_rating && (
                <div onClick={handleOpenClientRatingDialog} className={classes.providerRating}>
                  <Typography variant="body2" component="legend">
                    {translation().views.orders.update.products.client_rating.button}:{' '}
                  </Typography>
                  <Rating
                    name="client_rating"
                    value={client_rating}
                    precision={0.5}
                    readOnly
                    size="small"
                  />
                </div>
              )}
              {provider_rating && client_rating && <div className="xxs-space" />}
              {provider_rating && (
                <div onClick={handleOpenProviderRatingDialog} className={classes.providerRating}>
                  <Typography variant="body2" component="legend">
                    {translation().views.orders.update.products.provider_rating.button}:{' '}
                  </Typography>
                  <Rating
                    name="provider_rating"
                    value={provider_rating}
                    precision={0.5}
                    readOnly
                    size="small"
                  />
                </div>
              )}
            </div>
          )}
          {settled && (
            <Tooltip
              placement="top-end"
              title={dateInTz(settled, 'localized-long-datetime')}
              enterDelay={650}>
              <div className={classes.settled}>
                <LockIcon fontSize="small" />
                <Typography variant="body2" style={{ marginTop: 4 }}>
                  {translation().views.orders.update.products.settled}
                </Typography>
              </div>
            </Tooltip>
          )}
        </CardActions>
      )}
      <Dialog
        fullWidth
        maxWidth="lg"
        open={isOrderProductMediasOpen ? true : false}
        onClose={handleCloseOrderProductMediasDialog}
        aria-labelledby="order-product-medias"
        aria-describedby="order-product-medias-description"
        disableBackdropClick>
        <DialogTitle id="order-product-medias">
          {translation().views.orders.update.products.product_medias.title}
        </DialogTitle>
        <DialogContent>
          <MediasContainer
            product={{
              id,
              order_id,
              uploadable: true
            }}
            onUploadSuccess={handleUploadOrderSuccess}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOrderProductMediasDialog} color="default">
            {translation().actions.close}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="md"
        open={isClientRatingDialogOpen ? true : false}
        onClose={handleCloseClientRatingDialog}
        aria-labelledby="provider-rating"
        aria-describedby="provider-rating-description">
        <DialogTitle id="provider-rating">
          {translation().views.orders.update.products.client_rating.title}
        </DialogTitle>
        <DialogContent>
          {client_rating && (
            <>
              <Typography className={classes.ratingTitle} gutterBottom>
                {translation().views.orders.update.products.client_rating.labels.rating}:
              </Typography>
              <Rating
                name="provider_rating_in_dialog"
                value={client_rating}
                precision={0.5}
                readOnly
              />
            </>
          )}
          {client_rating_issues && client_rating_issues.length > 0 ? (
            <>
              <div className="md-space" />
              <Typography className={classes.ratingTitle}>
                {translation().views.orders.update.products.client_rating.labels.issues}:
              </Typography>
              <div className={classes.providerIssues}>
                {client_rating_issues.map((issue, j) => {
                  if (issue === 'client_punctuality') {
                    return (
                      <div key={j} className={classes.providerIssue}>
                        <PunctualityIcon height={50} />
                        <div className="xs-space" />
                        <Typography variant="subtitle2">
                          {
                            translation().views.orders.update.products.client_rating.issues
                              .client_punctuality
                          }
                        </Typography>
                      </div>
                    );
                  } else if (issue === 'client_behaviour') {
                    return (
                      <div key={j} className={classes.providerIssue}>
                        <BehaviourIcon height={50} />
                        <div className="xs-space" />
                        <Typography variant="subtitle2">
                          {
                            translation().views.orders.update.products.client_rating.issues
                              .client_behaviour
                          }
                        </Typography>
                      </div>
                    );
                  } else if (issue === 'property_status') {
                    return (
                      <div key={j} className={classes.providerIssue}>
                        <PropertyStatusIcon height={50} />
                        <div className="xs-space" />
                        <Typography variant="subtitle2">
                          {
                            translation().views.orders.update.products.client_rating.issues
                              .property_status
                          }
                        </Typography>
                      </div>
                    );
                  } else {
                    return (
                      <Typography
                        key={j}
                        variant="subtitle2"
                        className={classes.providerIssueNoImg}>
                        {issue}
                      </Typography>
                    );
                  }
                })}
              </div>
            </>
          ) : (
            false
          )}
          {client_rating_comment && (
            <>
              <div className="md-space" />
              <Typography className={classes.ratingTitle} gutterBottom>
                {translation().views.orders.update.products.provider_rating.labels.comment}:
              </Typography>
              <Typography>
                {client_rating_comment.split('\n').map((line, key) => {
                  return (
                    <span key={key}>
                      {line}
                      <br />
                    </span>
                  );
                }) || client_rating_comment}
              </Typography>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseClientRatingDialog} color="default">
            {translation().actions.close}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="md"
        open={isProviderRatingDialogOpen ? true : false}
        onClose={handleCloseProviderRatingDialog}
        aria-labelledby="provider-rating"
        aria-describedby="provider-rating-description">
        <DialogTitle id="provider-rating">
          {translation().views.orders.update.products.provider_rating.title}
        </DialogTitle>
        <DialogContent>
          {provider_rating && (
            <>
              <Typography className={classes.ratingTitle} gutterBottom>
                {translation().views.orders.update.products.provider_rating.labels.rating}:
              </Typography>
              <Rating
                name="provider_rating_in_dialog"
                value={provider_rating}
                precision={0.5}
                readOnly
              />
            </>
          )}
          {provider_rating_issues && provider_rating_issues.length > 0 ? (
            <>
              <div className="md-space" />
              <Typography className={classes.ratingTitle}>
                {translation().views.orders.update.products.provider_rating.labels.issues}:
              </Typography>
              <div className={classes.providerIssues}>
                {provider_rating_issues.map((issue, j) => {
                  if (issue === 'provider_punctuality') {
                    return (
                      <div key={j} className={classes.providerIssue}>
                        <PunctualityIcon height={50} />
                        <div className="xs-space" />
                        <Typography variant="subtitle2">
                          {
                            translation().views.orders.update.products.provider_rating.issues
                              .provider_punctuality
                          }
                        </Typography>
                      </div>
                    );
                  } else if (issue === 'provider_behaviour') {
                    return (
                      <div key={j} className={classes.providerIssue}>
                        <BehaviourIcon height={50} />
                        <div className="xs-space" />
                        <Typography variant="subtitle2">
                          {
                            translation().views.orders.update.products.provider_rating.issues
                              .provider_behaviour
                          }
                        </Typography>
                      </div>
                    );
                  } else if (issue === 'delivery_time') {
                    return (
                      <div key={j} className={classes.providerIssue}>
                        <DeliveryTimeIcon height={50} />
                        <div className="xs-space" />
                        <Typography variant="subtitle2">
                          {
                            translation().views.orders.update.products.provider_rating.issues
                              .delivery_time
                          }
                        </Typography>
                      </div>
                    );
                  } else if (issue === 'medias_quality') {
                    return (
                      <div key={j} className={classes.providerIssue}>
                        <MediasQualityIcon height={50} />
                        <div className="xs-space" />
                        <Typography variant="subtitle2">
                          {
                            translation().views.orders.update.products.provider_rating.issues
                              .medias_quality
                          }
                        </Typography>
                      </div>
                    );
                  } else {
                    return (
                      <Typography
                        key={j}
                        variant="subtitle2"
                        className={classes.providerIssueNoImg}>
                        {issue}
                      </Typography>
                    );
                  }
                })}
              </div>
            </>
          ) : (
            false
          )}
          {provider_rating_comment && (
            <>
              <div className="md-space" />
              <Typography className={classes.ratingTitle} gutterBottom>
                {translation().views.orders.update.products.provider_rating.labels.comment}:
              </Typography>
              <Typography>
                {provider_rating_comment.split('\n').map((line, key) => {
                  return (
                    <span key={key}>
                      {line}
                      <br />
                    </span>
                  );
                }) || provider_rating_comment}
              </Typography>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseProviderRatingDialog} color="default">
            {translation().actions.close}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        open={isCancelDialogOpen ? true : false}
        onClose={handleCloseCancelDialog}
        aria-labelledby="product-cancel"
        aria-describedby="product-cancel-description"
        disableBackdropClick={productCancel.isLoading}>
        <DialogTitle id="product-cancel">
          {translation().views.orders.update.products.product_cancel.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            {translation().views.orders.update.products.product_cancel.description}
          </DialogContentText>
          {productCancel.isLoading && (
            <div className="loader-wpr" style={{ marginTop: 15 }}>
              <CircularProgress size={30} color="secondary" />
              <p>{translation().views.orders.update.products.product_cancel.loading_text}</p>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseCancelDialog}
            color="default"
            disabled={productCancel.isLoading}>
            {translation().actions.close}
          </Button>
          <Button
            onClick={handleCancelOrderProduct}
            className={classes.cancelButton}
            disabled={productCancel.isLoading}>
            {translation().views.orders.update.products.product_cancel.title}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        open={isPricingDialogOpen ? true : false}
        onClose={handleClosePricingDialog}
        aria-labelledby="product-financial-details"
        aria-describedby="product-financial-details-description">
        <DialogTitle id="product-financial-details">
          {translation().views.orders.update.products.product_financial_details.title}
        </DialogTitle>
        <DialogContent>
          <Typography className={classes.financialTitle} component="p" variant="h6">
            {translation().views.orders.update.financial_details.labels.order}:
          </Typography>
          <div className="xs-space" />
          <Typography variant="body1" className={classes.lineHeight}>
            {translation().commons.pricing.amount_tax_excluded_formatted}
            <span className={classNames(classes.detailSpan, 'primary')}>
              {amount_tax_excluded_formatted}
            </span>
          </Typography>
          <Typography variant="body1" className={classes.lineHeight}>
            {translation().commons.pricing.amount_formatted}
            <span className={classNames(classes.detailSpan, 'primary')}>{amount_formatted}</span>
          </Typography>
          <Divider className={classes.divider} />
          <Typography className={classes.financialTitle} component="p" variant="h6">
            {translation().views.orders.update.financial_details.labels.provider_reversion}:
          </Typography>
          <div className="xs-space" />
          <Typography variant="body1" className={classes.lineHeight}>
            {translation().commons.pricing.amount_tax_excluded_formatted}
            <span className={classNames(classes.detailSpan, 'primary')}>
              {amount_provider_reversion_tax_excluded_formatted}
            </span>
          </Typography>
          {amount_provider_trip_compensation_tax_excluded_formatted && (
            <Typography variant="body2" gutterBottom>
              -{' '}
              {
                translation().views.orders.update.products.product_financial_details
                  .trip_compensation
              }
              :{' '}
              <span className={classNames(classes.detailSpan, 'primary')}>
                {amount_provider_trip_compensation_tax_excluded_formatted}
              </span>
            </Typography>
          )}
          <Typography variant="body1" className={classes.lineHeight}>
            {translation().commons.pricing.amount_tax_formatted}
            <span className={classNames(classes.detailSpan, 'primary')}>
              {amount_provider_reversion_tax_formatted}
            </span>
          </Typography>
          <Typography variant="body1" className={classes.lineHeight}>
            {translation().commons.pricing.amount_formatted}
            <span className={classNames(classes.detailSpan, 'primary')}>
              {amount_provider_reversion_formatted}
            </span>
          </Typography>
          <Divider className={classes.divider} />
          <Typography className={classes.financialTitle} component="p" variant="h6">
            {translation().views.orders.update.financial_details.labels.commissions}:
          </Typography>
          <div className="xs-space" />
          {commissions && commissions.length > 0 ? (
            <>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {translation().views.orders.update.financial_details.labels.recipient}
                    </TableCell>
                    <TableCell align="right">
                      {translation().commons.pricing.amount_tax_excluded_formatted}
                    </TableCell>
                    <TableCell align="right">
                      {translation().commons.pricing.amount_formatted}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {commissions.map((com, idx) => (
                    <TableRow key={idx}>
                      <TableCell>
                        {com.iam_company_id ? com.iam_company_name : com.iam_user_name}
                      </TableCell>
                      <TableCell align="right">{com.amount_tax_excluded_formatted}</TableCell>
                      <TableCell align="right">{com.amount_formatted}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <div className="xs-space" />
            </>
          ) : (
            ''
          )}
          <Typography variant="body1" className={classes.lineHeight}>
            {translation().commons.pricing.amount_tax_excluded_formatted}
            <span className={classNames(classes.detailSpan, 'primary')}>
              {amount_commissions_tax_excluded_formatted}
            </span>
          </Typography>
          <Typography variant="body1" className={classes.lineHeight}>
            {translation().commons.pricing.amount_tax_formatted}
            <span className={classNames(classes.detailSpan, 'primary')}>
              {amount_commissions_tax_formatted}
            </span>
          </Typography>
          <Typography variant="body1" className={classes.lineHeight}>
            {translation().commons.pricing.amount_formatted}
            <span className={classNames(classes.detailSpan, 'primary')}>
              {amount_commissions_formatted}
            </span>
          </Typography>
          <Divider className={classes.divider} />
          <Typography className={classes.financialTitle} component="p" variant="h6">
            {translation().views.orders.update.financial_details.labels.margin}:
          </Typography>
          <div className="xs-space" />
          <Typography variant="body1" className={classes.lineHeight}>
            {translation().views.orders.update.financial_details.labels.net_margin}:
            <span className={classNames(classes.detailSpan, 'primary')}>
              {net_margin_formatted}
            </span>
            {net_margin && amount_tax_excluded && (
              <span style={{ marginLeft: 8 }}>
                {'('}
                {realRound((net_margin * 100) / amount_tax_excluded, 2)}%{')'}
              </span>
            )}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePricingDialog} color="default">
            {translation().actions.close}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="xl"
        open={isHistoryDialogOpen ? true : false}
        onClose={handleCloseHistoryDialog}
        aria-labelledby="product-history"
        aria-describedby="product-history-description"
        disableBackdropClick={productHistory.isLoading}
        onEnter={handleOpenHistoryDialogEnter}>
        <DialogTitle id="product-history">
          {translation().views.orders.update.products.product_history.title}
        </DialogTitle>
        <DialogContent>
          {productHistory.data && productHistory.data.length > 0 ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {translation().views.orders.update.products.product_history.labels.user}
                  </TableCell>
                  <TableCell>
                    {translation().views.orders.update.products.product_history.labels.start}
                  </TableCell>
                  <TableCell>
                    {translation().views.orders.update.products.product_history.labels.end}
                  </TableCell>
                  <TableCell>
                    {translation().views.orders.update.products.product_history.labels.provider}
                  </TableCell>
                  <TableCell>
                    {
                      translation().views.orders.update.products.product_history.labels
                        .amount_tax_excluded_formatted
                    }
                  </TableCell>
                  <TableCell>
                    {
                      translation().views.orders.update.products.product_history.labels
                        .delivery_status
                    }
                  </TableCell>
                  <TableCell>
                    {translation().views.orders.update.products.product_history.labels.created}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {productHistory.data.map((item, idx) => (
                  <TableRow key={idx}>
                    <TableCell>{item.iam_user_name ? item.iam_user_name : '-'}</TableCell>
                    <TableCell>
                      {item.start ? dateInTz(item.start, 'localized-datetime-with-seconds') : '-'}
                      {item.start && (
                        <LocalDateInfo
                          timezone={orderProperty.timezone_code}
                          date={item.start}
                          format="localized-datetime-with-seconds"
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {item.end ? dateInTz(item.end, 'localized-datetime-with-seconds') : '-'}
                      {item.end && (
                        <LocalDateInfo
                          timezone={orderProperty.timezone_code}
                          date={item.end}
                          format="localized-datetime-with-seconds"
                        />
                      )}
                    </TableCell>
                    <TableCell>{item.provider_name}</TableCell>
                    <TableCell>{item.amount_tax_excluded_formatted}</TableCell>
                    <TableCell>
                      {item.delivery_status && item.delivery_status.icon_url_dark && (
                        <img
                          style={{ width: '20px' }}
                          title={item.delivery_status && item.delivery_status.name}
                          src={item.delivery_status.icon_url_dark}
                          alt={item.delivery_status.name}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {dateInTz(item.created, 'localized-datetime-with-seconds')}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            !productHistory.isLoading && (
              <Typography variant="body2">
                {translation().views.orders.update.products.product_history.no_history}
              </Typography>
            )
          )}
          {productHistory.isLoading && (
            <div className="loader-wpr">
              <CircularProgress size={30} color="secondary" />
              <p>{translation().views.orders.update.products.product_history.loading_text}</p>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseHistoryDialog}
            color="default"
            disabled={productHistory.isLoading}>
            {translation().actions.close}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        open={isProviderDialogOpen ? true : false}
        onClose={handleCloseProviderDialog}
        aria-labelledby="product-provider-details"
        aria-describedby="product-provider-details-description"
        disableBackdropClick={providersInfos.isLoading}
        onEnter={handleProviderDialogEnter}>
        <DialogTitle id="product-provider-details">
          {translation().views.orders.update.products.providers_infos.title}
        </DialogTitle>
        <DialogContent>
          {providersInfos.data && (
            <>
              <Typography gutterBottom>
                {translation().views.orders.update.products.providers_infos.labels.name}:
                <Typography component="span" className={classes.providerInfosB}>
                  {providersInfos.data.provider_name}
                </Typography>
              </Typography>
              <Typography gutterBottom>
                {translation().views.orders.update.products.providers_infos.labels.trip_distance}:
                <Typography component="span" className={classes.providerInfosB}>
                  {providersInfos.data.trip_distance ? providersInfos.data.trip_distance : 0} km
                </Typography>
              </Typography>
              <Typography gutterBottom>
                {
                  translation().views.orders.update.products.providers_infos.labels
                    .amount_provider_reversion_tax_excluded_formatted
                }
                :
                <Typography component="span" className={classes.providerInfosB}>
                  {providersInfos.data.amount_provider_reversion_tax_excluded_formatted}
                </Typography>
              </Typography>
              <Typography gutterBottom>
                {
                  translation().views.orders.update.products.providers_infos.labels
                    .amount_provider_reversion_tax_formatted
                }
                :
                <Typography component="span" className={classes.providerInfosB}>
                  {providersInfos.data.amount_provider_reversion_tax_formatted}
                </Typography>
              </Typography>

              <Typography gutterBottom>
                {
                  translation().views.orders.update.products.providers_infos.labels
                    .amount_provider_reversion_formatted
                }
                :
                <Typography component="span" className={classes.providerInfosB}>
                  {providersInfos.data.amount_provider_reversion_formatted}
                </Typography>
              </Typography>
              <Typography gutterBottom>
                {
                  translation().views.orders.update.products.providers_infos.labels
                    .amount_provider_social_charges_formatted
                }
                :
                <Typography component="span" className={classes.providerInfosB}>
                  {providersInfos.data.amount_provider_social_charges_formatted}
                </Typography>
              </Typography>
              <Typography gutterBottom>
                {
                  translation().views.orders.update.products.providers_infos.labels
                    .amount_provider_broadcast_charges_formatted
                }
                :
                <Typography component="span" className={classes.providerInfosB}>
                  {providersInfos.data.amount_provider_broadcast_charges_formatted}
                </Typography>
              </Typography>
            </>
          )}
          {providersInfos.isLoading && (
            <div className="loader-wpr">
              <CircularProgress size={30} color="secondary" />
              <p>{translation().views.orders.update.products.providers_infos.loading_text}</p>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseProviderDialog}
            color="default"
            disabled={providersInfos.isLoading}>
            {translation().actions.close}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        open={isAttachedFilesDialogOpen ? true : false}
        onClose={handleCloseAttachedFilesDialog}
        disableBackdropClick={providersInfos.isLoading}>
        <DialogContent className={classes.attachedFiles}>
          {attachedFiles && attachedFiles.length > 0
            ? attachedFiles.map((file, index) => <AttachedFile key={index} url={file} />)
            : false}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAttachedFilesDialog} color="default">
            {translation().actions.close}
          </Button>
        </DialogActions>
      </Dialog>
      {attributes && (
        <Attributes
          attributes={attributes}
          orderId={order_id}
          onClose={handleCloseAttributesDialog}
          open={isAttributesDialogOpen ? true : false}
          productId={id}
          reloadOrder={reloadOrder}
          updateOrderProductAttributes={updateOrderProductAttributes}
        />
      )}
    </Card>
  );
}

ProductCard.propTypes = {
  addOrderProductOption: PropTypes.func.isRequired,
  amount_commissions_tax_excluded_formatted: PropTypes.any,
  amount_commissions_tax_formatted: PropTypes.any,
  amount_commissions_formatted: PropTypes.any,
  amount_formatted: PropTypes.any,
  amount_tax_excluded: PropTypes.any,
  amount_tax_excluded_formatted: PropTypes.any,
  amount_provider_reversion_tax_excluded_formatted: PropTypes.any,
  amount_provider_reversion_tax_formatted: PropTypes.any,
  amount_provider_reversion_formatted: PropTypes.any,
  amount_provider_trip_compensation_tax_excluded_formatted: PropTypes.any,
  attributes: PropTypes.shape(),
  cancellable: PropTypes.any,
  cancelled: PropTypes.any,
  cancelOrderProduct: PropTypes.func.isRequired,
  clearOrderUpdatePlanning: PropTypes.func.isRequired,
  client_rating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  client_rating_comment: PropTypes.string,
  client_rating_issues: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.string]),
  commissions: PropTypes.any,
  delivery_status: PropTypes.shape({
    color: PropTypes.string,
    icon_url_dark: PropTypes.string,
    name: PropTypes.string
  }),
  duration: PropTypes.any,
  icon_url_light: PropTypes.string.isRequired,
  icon_url_dark: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  expire: PropTypes.any,
  expired: PropTypes.any,
  flag: PropTypes.bool,
  getOrderProductDeliveryStatuses: PropTypes.func.isRequired,
  getOrderProductDispatchableProviders: PropTypes.func.isRequired,
  getOrderProductHistory: PropTypes.func.isRequired,
  getOrderProductProviderInfos: PropTypes.func.isRequired,
  getOrderProductSlots: PropTypes.func.isRequired,
  getNextOrderProductSlots: PropTypes.func.isRequired,
  getPreviousOrderProductSlots: PropTypes.func.isRequired,
  history: PropTypes.shape().isRequired,
  manageOrderProductFlag: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  net_margin: PropTypes.any,
  net_margin_formatted: PropTypes.any,
  options: PropTypes.arrayOf(PropTypes.shape()),
  order_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  orderProperty: PropTypes.shape().isRequired,
  postOrderProductDispatchableProviders: PropTypes.func.isRequired,
  productDeliveryStatuses: PropTypes.shape({
    isLoading: PropTypes.bool
  }),
  product_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  property_contact_comment: PropTypes.string,
  property_access_details: PropTypes.string,
  property_contact_name: PropTypes.string,
  property_contact_phone: PropTypes.string,
  productCancel: PropTypes.shape({
    isLoading: PropTypes.bool
  }),
  productFlag: PropTypes.shape({
    isLoading: PropTypes.bool
  }),
  productHistory: PropTypes.shape(),
  productOptions: PropTypes.shape({
    isLoading: PropTypes.bool
  }),
  productProvider: PropTypes.shape({
    isLoading: PropTypes.bool
  }),
  productPlanning: PropTypes.shape({
    isLoading: PropTypes.bool
  }),
  productPropertyDetails: PropTypes.shape({
    isLoading: PropTypes.bool
  }),
  provider_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  providersInfos: PropTypes.shape(),
  providersDispatching: PropTypes.shape({
    isLoading: PropTypes.bool
  }).isRequired,
  provider_name: PropTypes.string,
  provider_rating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  provider_rating_comment: PropTypes.string,
  provider_rating_issues: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.string]),
  reloadOrder: PropTypes.func.isRequired,
  removeOrderProductOption: PropTypes.func.isRequired,
  settled: PropTypes.any,
  start: PropTypes.any,
  theme: PropTypes.shape().isRequired,
  updateOrderProductAttributes: PropTypes.func.isRequired,
  updateOrderProductDeliveryStatus: PropTypes.func.isRequired,
  updateOrderProductProvider: PropTypes.func.isRequired,
  updateOrderProductStartDate: PropTypes.func.isRequired,
  updateOrderProductPropertyDetails: PropTypes.func.isRequired
};

export default withTheme(ProductCard);
