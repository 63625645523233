import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import IconButton from '@material-ui/core/IconButton';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Link from '@material-ui/core/Link';
import PaymentIcon from '@material-ui/icons/Payment';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ReceiptIcon from '@material-ui/icons/Receipt';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';

import Addresses from './Addresses';
import Invoices from './Invoices';
import InvoiceQueue from './InvoiceQueue';
import PaymentMethods from './PaymentMethods';
import translation from '../../../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    minHeight: 60,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.cardHeader
  },
  cardContent: {
    overflow: 'auto',
    minHeight: 420,
    padding: theme.spacing(1),
    '&:last-child': {
      paddingBottom: 0
    },
    [theme.breakpoints.down('md')]: {
      minHeight: 'auto'
    }
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(1)
  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2)
  },
  invoicesViewHead: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  listItemBox: {
    minWidth: 35
  },
  listItem: {
    padding: 8
  },
  linksContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    minHeight: 50,
    marginBottom: 8
  },
  linkWrapper: {
    margin: theme.spacing(0, 1),
    '&:first-child': {
      marginRight: 24
    }
  },
  linkLoader: {
    display: 'flex',
    alignItems: 'center',
    padding: 0
  },
  loaderText: {
    marginLeft: 8
  }
}));

function ProviderInvoices(props) {
  const {
    addresses,
    cleanProviderViewAddressesReducer,
    cleanProviderViewInvoicesReducer,
    cleanProviderViewInvoiceQueueReducer,
    cleanProviderViewPaymentMethodsReducer,
    getProviderViewAddresses,
    getProviderViewInvoices,
    getProviderViewInvoiceQueue,
    getProviderViewPaymentMethods,
    getInvoicerId,
    getPayerId,
    paymentMethods,
    invoiceQueue,
    invoices,
    isModeCompany,
    provider,
    toggleProviderViewMode
  } = props;

  const classes = useStyles();

  const [isPayerLoading, setPayerLoading] = useState(false);
  const [isInvoicerLoading, setInvoicerLoading] = useState(false);
  const [payerLink, setPayerLink] = useState(false);
  const [invoicerLink, setInvoicerLink] = useState(false);

  useEffect(() => {
    /**
     * Get the payer and the invoicer for links
     */
    if (provider && provider.user && provider.user.id) {
      loadPayer();
      loadInvoicer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleChangeMode(event) {
    toggleProviderViewMode(event.target.checked);
  }

  function loadPayer() {
    setPayerLoading(true);

    getPayerId(
      provider.user.id,
      (success) => {
        setPayerLoading(false);
        if (success && success[0]) {
          setPayerLink(`/payers/${success[0].id}/update`);
        }
      },
      () => {
        setPayerLoading(false);
      }
    );
  }

  function loadInvoicer() {
    setInvoicerLoading(true);

    getInvoicerId(
      provider.user.id,
      (success) => {
        setInvoicerLoading(false);
        if (success && success[0]) {
          setInvoicerLink(`/invoicers/${success[0].id}/update`);
        }
      },
      () => {
        setInvoicerLoading(false);
      }
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.invoicesViewHead}>
        <div className={classes.linksContainer}>
          <div className={classes.linkWrapper}>
            {isPayerLoading && (
              <div className={classNames('loader-wpr', classes.linkLoader)}>
                <CircularProgress color="primary" size={25} />
                <p className={classes.loaderText}>
                  {translation().views.provider_view.payer_bridge.payer_loading}
                </p>
              </div>
            )}
            {payerLink && (
              <div>
                <Typography component="span" variant="body2">
                  {translation().views.provider_view.payer_bridge.label}:{' '}
                </Typography>
                <Link href={payerLink} target="_blank">
                  {payerLink}
                </Link>
              </div>
            )}
          </div>
          <div className={classes.linkWrapper}>
            {isInvoicerLoading && (
              <div className={classNames('loader-wpr', classes.linkLoader)}>
                <CircularProgress color="primary" size={25} />
                <p className={classes.loaderText}>
                  {translation().views.provider_view.invoicer_bridge.invoicer_loading}
                </p>
              </div>
            )}
            {invoicerLink && (
              <div>
                <Typography component="span" variant="body2">
                  {translation().views.provider_view.invoicer_bridge.label}:{' '}
                </Typography>
                <Link href={invoicerLink} target="_blank">
                  {invoicerLink}
                </Link>
              </div>
            )}
          </div>
        </div>
        {provider && provider.user && provider.user.company && provider.user.company.id && (
          <FormGroup row>
            <FormControlLabel
              disabled={
                invoiceQueue.isLoading ||
                invoices.isLoading ||
                addresses.isLoading ||
                paymentMethods.isLoading
              }
              control={
                <Switch
                  checked={isModeCompany}
                  onChange={handleChangeMode}
                  name="companyMode"
                  color="primary"
                />
              }
              label={translation().views.provider_view.mode.company}
            />
          </FormGroup>
        )}
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Card className={classes.paper}>
            <CardHeader
              className={classes.cardHeader}
              title={translation().views.provider_view.invoice_queue.label}
              titleTypographyProps={{
                variant: 'h5',
                style: {
                  fontSize: 21
                }
              }}
              avatar={
                <Avatar>
                  <ReceiptIcon />
                </Avatar>
              }
              action={
                <IconButton
                  aria-label="See all invoices"
                  href={`/${isModeCompany ? 'companies' : 'providers'}/${
                    isModeCompany && provider.user.company && provider.user.company.id
                      ? provider.user.company.id
                      : provider.id
                  }/invoice-queue`}
                  target="_blank">
                  <OpenInNewIcon fontSize="small" />
                </IconButton>
              }
            />
            <CardContent className={classes.cardContent}>
              <InvoiceQueue
                cleanProviderViewInvoiceQueueReducer={cleanProviderViewInvoiceQueueReducer}
                companyId={
                  provider.user.company && provider.user.company.id
                    ? provider.user.company.id
                    : null
                }
                getProviderViewInvoiceQueue={getProviderViewInvoiceQueue}
                invoiceQueue={invoiceQueue}
                providerId={provider.id}
                isModeCompany={isModeCompany}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Card className={classes.paper}>
            <CardHeader
              className={classes.cardHeader}
              title={translation().views.provider_view.invoices.label}
              titleTypographyProps={{
                variant: 'h5',
                style: {
                  fontSize: 21
                }
              }}
              avatar={
                <Avatar>
                  <ReceiptIcon />
                </Avatar>
              }
              action={
                <IconButton
                  aria-label="See all invoices"
                  href={`/${isModeCompany ? 'companies' : 'providers'}/${
                    isModeCompany && provider.user.company && provider.user.company.id
                      ? provider.user.company.id
                      : provider.id
                  }/invoices`}
                  target="_blank">
                  <OpenInNewIcon fontSize="small" />
                </IconButton>
              }
            />
            <CardContent className={classes.cardContent}>
              <Invoices
                cleanProviderViewInvoicesReducer={cleanProviderViewInvoicesReducer}
                companyId={
                  provider.user.company && provider.user.company.id
                    ? provider.user.company.id
                    : null
                }
                getProviderViewInvoices={getProviderViewInvoices}
                invoices={invoices}
                providerId={provider.id}
                isModeCompany={isModeCompany}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Card className={classes.paper}>
            <CardHeader
              className={classes.cardHeader}
              title={translation().views.provider_view.addresses.label}
              titleTypographyProps={{
                variant: 'h5',
                style: {
                  fontSize: 21
                }
              }}
              avatar={
                <Avatar>
                  <HomeWorkIcon />
                </Avatar>
              }
            />
            <CardContent className={classes.cardContent}>
              <Addresses
                addresses={addresses}
                cleanProviderViewAddressesReducer={cleanProviderViewAddressesReducer}
                companyId={
                  provider.user.company && provider.user.company.id
                    ? provider.user.company.id
                    : null
                }
                getProviderViewAddresses={getProviderViewAddresses}
                userId={provider.user.id}
                isModeCompany={isModeCompany}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Card className={classes.paper}>
            <CardHeader
              className={classes.cardHeader}
              title={translation().views.provider_view.payment_methods.label}
              titleTypographyProps={{
                variant: 'h5',
                style: {
                  fontSize: 21
                }
              }}
              avatar={
                <Avatar>
                  <PaymentIcon />
                </Avatar>
              }
              action={
                <IconButton
                  aria-label="See all payment methods"
                  href={`/${isModeCompany ? 'companies' : 'users'}/${
                    isModeCompany && provider.user.company && provider.user.company.id
                      ? provider.user.company.id
                      : provider.id
                  }/payment-methods`}
                  target="_blank">
                  <OpenInNewIcon fontSize="small" />
                </IconButton>
              }
            />
            <CardContent className={classes.cardContent}>
              <PaymentMethods
                companyId={
                  provider.user.company && provider.user.company.id
                    ? provider.user.company.id
                    : null
                }
                cleanProviderViewPaymentMethodsReducer={cleanProviderViewPaymentMethodsReducer}
                getProviderViewPaymentMethods={getProviderViewPaymentMethods}
                isModeCompany={isModeCompany}
                paymentMethods={paymentMethods}
                userId={provider.user.id}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

ProviderInvoices.defaultProps = {
  isModeCompany: false
};

ProviderInvoices.propTypes = {
  cleanProviderViewAddressesReducer: PropTypes.func.isRequired,
  cleanProviderViewInvoicesReducer: PropTypes.func.isRequired,
  cleanProviderViewInvoiceQueueReducer: PropTypes.func.isRequired,
  cleanProviderViewPaymentMethodsReducer: PropTypes.func.isRequired,
  getProviderViewAddresses: PropTypes.func.isRequired,
  getProviderViewInvoices: PropTypes.func.isRequired,
  getProviderViewInvoiceQueue: PropTypes.func.isRequired,
  getProviderViewPaymentMethods: PropTypes.func.isRequired,
  getInvoicerId: PropTypes.func.isRequired,
  getPayerId: PropTypes.func.isRequired,
  addresses: PropTypes.shape({
    isLoading: PropTypes.bool
  }).isRequired,
  invoices: PropTypes.shape({
    isLoading: PropTypes.bool
  }).isRequired,
  invoiceQueue: PropTypes.shape({
    isLoading: PropTypes.bool
  }).isRequired,
  isModeCompany: PropTypes.bool,
  paymentMethods: PropTypes.shape({
    isLoading: PropTypes.bool
  }).isRequired,
  provider: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    user: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      company: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
      })
    }).isRequired
  }).isRequired,
  toggleProviderViewMode: PropTypes.func.isRequired
};

export default ProviderInvoices;
