import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Step, StepConnector, StepLabel, Stepper as MuiStepper } from '@material-ui/core';
import { Business, LibraryAddCheck, Person, Receipt } from '@material-ui/icons';

import config from '../../../../config';

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,' +
        config.primary_color +
        ' 0%,' +
        config.primary_color +
        ' 50%,' +
        config.secondary_color +
        ' 100%)'
    }
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,' +
        config.primary_color +
        ' 0%,' +
        config.primary_color +
        ' 50%,' +
        config.secondary_color +
        ' 100%)'
    }
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1
  }
})(StepConnector);

const useColorlibStepIconStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.type === 'dark' ? '#333' : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  active: {
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    backgroundImage:
      'linear-gradient( 136deg,' +
      config.primary_color +
      ' 0%,' +
      config.primary_color +
      ' 50%,' +
      config.secondary_color +
      ' 100%)'
  },
  completed: {
    backgroundImage:
      'linear-gradient( 136deg,' +
      config.primary_color +
      ' 0%,' +
      config.primary_color +
      ' 50%,' +
      config.secondary_color +
      ' 100%)'
  }
}));

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <Person />,
    2: <Business />,
    3: <LibraryAddCheck />,
    4: <Receipt />
  };

  return (
    <div
      className={classNames(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed
      })}>
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  button: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  stepper: {
    padding: theme.spacing(2, 0),
    backgroundColor: 'transparent'
  }
}));

function Stepper({ activeStep, steps }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <MuiStepper
        alternativeLabel
        activeStep={activeStep}
        className={classes.stepper}
        connector={<ColorlibConnector />}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </MuiStepper>
    </div>
  );
}

Stepper.defaultProps = {
  activeStep: 0
};

Stepper.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  activeStep: PropTypes.number.isRequired
};

export { Stepper };
