import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import translation from '../../../translation/translation';

const useStyles = makeStyles(() => ({
  alignRight: {
    textAlign: 'right'
  },
  unMarge: {
    marginLeft: -16
  }
}));

function BlockPaymentMethod(props) {
  const {
    closeDialogFunction,
    fullProps: { resourceUri },
    reloadListFunction,
    resource,
    resourceId,
    manageBlockPaymentMethod
  } = props;
  const classes = useStyles();
  const [toggleValue, setToggleValue] = useState(false);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (resource && resource.blocked) {
      setToggleValue(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleToggle(event, checked) {
    if (!resource || !resourceUri || !resourceId || !resource.id) return;

    setLoading(true);

    manageBlockPaymentMethod(
      resourceUri,
      resourceId,
      resource.id,
      checked,
      () => {
        setLoading(false);
        setToggleValue(checked);
        closeDialogFunction();
        reloadListFunction();
      },
      () => {
        setLoading(false);
      }
    );
  }

  return (
    <div className="BlockPaymentMethod">
      <div className={classes.unMarge}>
        <FormControlLabel
          checked={toggleValue}
          control={<Switch color="primary" />}
          label={translation().core.list.dialogs.custom_list_actions.block_payment_method.label}
          labelPlacement="start"
          disabled={isLoading}
          onChange={handleToggle}
        />
      </div>
      {isLoading && (
        <div className="loader-wpr">
          <CircularProgress color="primary" size={25} />
          <p>
            {translation().core.list.dialogs.custom_list_actions.block_payment_method.loading_text}
          </p>
        </div>
      )}
    </div>
  );
}

BlockPaymentMethod.propTypes = {
  closeDialogFunction: PropTypes.func.isRequired,
  fullProps: PropTypes.shape({
    resourceUri: PropTypes.string.isRequired
  }).isRequired,
  reloadListFunction: PropTypes.func.isRequired,
  resource: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    blocked: PropTypes.bool
  }),
  resourceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  manageBlockPaymentMethod: PropTypes.func.isRequired
};

export default BlockPaymentMethod;
