import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import BehaviourIcon from '../../../components/icons/BehaviourIcon';
import DeliveryTimeIcon from '../../../components/icons/DeliveryTimeIcon';
import ModerationIcon from '../../../components/icons/ModerationIcon';
import OrderDoneIcon from '../../../components/icons/OrderDoneIcon';
import PropertyStatusIcon from '../../../components/icons/PropertyStatusIcon';
import PreviousDayIcon from '../../../components/icons/PreviousDayIcon';
import ProvidersMapIcon from '../../../components/icons/ProvidersMapIcon';
import PlanningUnavailableIcon from '../../../components/icons/PlanningUnavailableIcon';
import PunctualityIcon from '../../../components/icons/PunctualityIcon';
import UploadFileInDropperIcon from '../../../components/icons/UploadFileInDropperIcon';
import ListEmptyIcon from '../../../components/icons/ListEmptyIcon';
import MediasQualityIcon from '../../../components/icons/MediasQualityIcon';
import MissingImageicon from '../../../components/icons/MissingImageicon';
import ReactIcon from '../../../components/icons/ReactIcon';
import WmWishIcon from '../../../components/icons/WmWishIcon';
import translation from '../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  item: {
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 4,
    padding: 12,
    width: 'auto'
  },
  itemName: {
    marginTop: 8
  }
}));

function Lab() {
  const classes = useStyles();

  const icons = [
    {
      title: 'Behaviour',
      component: BehaviourIcon
    },
    {
      title: 'DeliveryTime',
      component: DeliveryTimeIcon
    },
    {
      title: 'ListEmpty',
      component: ListEmptyIcon
    },
    {
      title: 'MediasQuality',
      component: MediasQualityIcon
    },
    {
      title: 'MissingImage',
      component: MissingImageicon
    },
    {
      title: 'Moderation',
      component: ModerationIcon
    },
    {
      title: 'OrderDone',
      component: OrderDoneIcon
    },
    {
      title: 'PreviousDay',
      component: PreviousDayIcon
    },
    {
      title: 'PlanningUnavailable',
      component: PlanningUnavailableIcon
    },
    {
      title: 'PropertyStatus',
      component: PropertyStatusIcon
    },
    {
      title: 'ProvidersMap',
      component: ProvidersMapIcon
    },
    {
      title: 'Punctuality',
      component: PunctualityIcon
    },
    {
      title: 'React',
      component: ReactIcon
    },
    {
      title: 'UploadFileInDropper',
      component: UploadFileInDropperIcon
    },
    {
      title: 'WmWish',
      component: WmWishIcon
    }
  ];

  return (
    <div className="IconLibrary">
      <Typography gutterBottom component="h5" variant="h5">
        {translation().views.lab.icons.title}
      </Typography>
      <Typography gutterBottom component="p" variant="body1">
        {translation().views.lab.icons.material_icons}{' '}
        <Link
          target="_blank"
          href="https://material-ui.com/components/material-icons/#material-icons">
          https://material-ui.com/components/material-icons/#material-icons
        </Link>
        .
      </Typography>
      <Typography gutterBottom component="p" variant="body1">
        {translation().views.lab.icons.actarus_icons}{' '}
        <Link target="_blank" href="https://www.flaticon.com">
          https://www.flaticon.com
        </Link>
        .
      </Typography>
      <div className="sm-space" />
      {icons.map((icon, index) => {
        const Icon = icon.component;
        return (
          <div key={index} className={classes.item}>
            <Icon height={50} />
            <Typography className={classes.itemName} component="p" variant="body2">
              {icon.title}
            </Typography>
          </div>
        );
      })}
    </div>
  );
}
export default Lab;
