import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import { Person as PersonIcon } from '@material-ui/icons';

import { getUserInitials, getUserName } from '../../../../../../utils/functions/user';
import { stringToColor } from '../../../../../../utils/functions/colors';
import translation from '../../../../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(1, 0)
  }
}));

function UserListItem(props) {
  const classes = useStyles();

  const { id, disabled, onSelect, selected, userIdLogged, user } = props;

  const initials = getUserInitials(user);
  const userName = getUserName(user);

  function handleClick() {
    onSelect({
      id,
      user,
      reactFrom: 'list'
    });
  }

  function handleGetAvatarColor(user) {
    if (!user || !user.id) return;
    return stringToColor(`${user.id}${user.first_name}${user.last_name}`);
  }

  return (
    <ListItem
      button
      className={classes.listItem}
      selected={selected && selected.id === id}
      onClick={handleClick}
      disabled={disabled}>
      <ListItemAvatar>
        <Avatar aria-label="user" style={{ backgroundColor: handleGetAvatarColor(user) }}>
          {initials ? initials : <PersonIcon fontSize="large" />}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={`${userName} ${
          userIdLogged === user.id ? `(${translation().views.task_views.tasks.labels.me})` : ''
        }`}
        secondary={user && user.email ? user.email : ''}
      />
    </ListItem>
  );
}

UserListItem.propTypes = {
  id: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.shape({
    id: PropTypes.number,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string
  }),
  user: PropTypes.shape({
    id: PropTypes.number,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string
  }).isRequired,
  userIdLogged: PropTypes.number.isRequired
};

export default UserListItem;
