import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AppBar from '@material-ui/core/AppBar';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import RefreshIcon from '@material-ui/icons/Refresh';
import SearchIcon from '@material-ui/icons/Search';
import Tooltip from '@material-ui/core/Tooltip';

import { getUserSearchBarPosition } from '../../../utils/functions/cookies';
import translation from '../../../translation/translation';

const styles = (theme) => ({
  root: {
    width: '100%'
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.35),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.55)
    },
    marginLeft: 5,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(5),
      width: 'auto'
    }
  },
  searchIcon: {
    width: theme.spacing(6),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit',
    width: '100%'
  },
  inputInput: {
    fontSize: 14,
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(6),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200
      }
    }
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
});

class ModerationViewBar extends PureComponent {
  state = {
    mobileMoreAnchorEl: null
  };

  handleMobileMenuOpen = (event) => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  handleHistory = () => {
    const { history } = this.props;

    history.push('/production');
  };

  render() {
    const { mobileMoreAnchorEl } = this.state;
    const { classes, disabled, isLoading, handleRefresh, handleSearch, handleSubmit, search } =
      this.props;

    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const renderMobileMenu = (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        onClose={this.handleMobileMenuClose}>
        <MenuItem onClick={this.handleHistory}>
          <ArrowBackIcon fontSize="small" style={{ marginRight: 8 }} />
          <p>{translation().actions.back}</p>
        </MenuItem>
      </Menu>
    );

    return (
      <div className={classes.root}>
        <AppBar elevation={0} position="static" color="default">
          <Toolbar>
            <div className={classes.sectionDesktop}>
              <Breadcrumbs
                maxItems={5}
                separator={<NavigateNextIcon fontSize="small" />}
                arial-label="Moderation view breadcrumb">
                <Typography variant="body2" color="textPrimary">
                  {translation().views.production.title}
                </Typography>
                <Typography variant="body2" color="textPrimary">
                  {translation().views.production.moderation.title}
                </Typography>
              </Breadcrumbs>
            </div>
            <div className={classes.sectionMobile}>
              <Typography variant="body2" color="textPrimary">
                {translation().views.production.moderation.title}
              </Typography>
            </div>
            {getUserSearchBarPosition() === 'left' && (
              <form onSubmit={handleSubmit}>
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    disabled={isLoading}
                    type="text"
                    value={search}
                    onChange={handleSearch}
                    placeholder={translation().actions.search + '...'}
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput
                    }}
                  />
                </div>
              </form>
            )}
            <IconButton
              aria-label="Refresh moderation"
              color="default"
              disabled={isLoading}
              style={{ padding: 5, marginLeft: 7 }}
              onClick={handleRefresh}>
              <RefreshIcon />
            </IconButton>
            <div className={classes.grow} />
            {getUserSearchBarPosition() === 'right' && (
              <form onSubmit={handleSubmit}>
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    disabled={isLoading}
                    type="text"
                    value={search}
                    onChange={handleSearch}
                    placeholder={translation().actions.search + '...'}
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput
                    }}
                  />
                </div>
              </form>
            )}
            <div className={classes.sectionDesktop}>
              <Tooltip
                title={translation().actions.back || 'Back'}
                aria-label={translation().actions.back || 'Back'}
                disabled={disabled}
                enterDelay={700}>
                <div>
                  <IconButton
                    onClick={this.handleHistory}
                    color="inherit"
                    className={classes.backIcon}
                    disabled={disabled}
                    style={{ marginLeft: 7 }}>
                    <ArrowBackIcon fontSize="small" />
                  </IconButton>
                </div>
              </Tooltip>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-haspopup="true"
                onClick={this.handleMobileMenuOpen}
                color="inherit"
                disabled={disabled}
                style={{ marginLeft: 7 }}>
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
      </div>
    );
  }
}

ModerationViewBar.defaultProps = {
  isLoading: false
};

ModerationViewBar.propTypes = {
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  classes: PropTypes.shape().isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleRefresh: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func,
  history: PropTypes.shape().isRequired,
  search: PropTypes.string
};

export default withStyles(styles)(ModerationViewBar);
