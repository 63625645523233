import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import DateRangeIcon from '@material-ui/icons/DateRange';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';

import translation from '../../../../../translation/translation';

function DateRangeMenuItem(props) {
  const { label, mode, onClick } = props;

  function handleClick() {
    onClick(mode);
  }

  return <MenuItem onClick={handleClick}>{label}</MenuItem>;
}

DateRangeMenuItem.propTypes = {
  label: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(['day', 'week', 'month', 'year']).isRequired,
  onClick: PropTypes.func.isRequired
};

function DateRangeMenu(props) {
  const { classes, disabled, onSelectItem } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  function handleClick(event) {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  }

  function handleClickAway() {
    setAnchorEl(null);
  }

  function handleSelectItem(mode) {
    handleClickAway();
    onSelectItem(mode);
  }

  const open = Boolean(anchorEl);
  const id = open ? 'legend' : undefined;

  return (
    <div>
      <IconButton
        aria-controls="date-range-menu"
        aria-haspopup="true"
        onClick={handleClick}
        disabled={disabled}
        className={classes.dateInputButton}>
        <DateRangeIcon aria-describedby={id} />
      </IconButton>
      <Popper id={id} anchorEl={anchorEl} open={open} disablePortal style={{ zIndex: 9 }}>
        <Paper>
          <ClickAwayListener onClickAway={handleClickAway}>
            <div>
              <DateRangeMenuItem
                onClick={handleSelectItem}
                mode="day"
                label={translation().metrics.dashboard.update.date_ranges.day}
              />
              <DateRangeMenuItem
                onClick={handleSelectItem}
                mode="week"
                label={translation().metrics.dashboard.update.date_ranges.week}
              />
              <DateRangeMenuItem
                onClick={handleSelectItem}
                mode="month"
                label={translation().metrics.dashboard.update.date_ranges.month}
              />
              <DateRangeMenuItem
                onClick={handleSelectItem}
                mode="year"
                label={translation().metrics.dashboard.update.date_ranges.year}
              />
            </div>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </div>
  );
}

DateRangeMenu.defaultProps = {
  disabled: false
};

DateRangeMenu.propTypes = {
  classes: PropTypes.shape().isRequired,
  disabled: PropTypes.bool,
  onSelectItem: PropTypes.func.isRequired
};

export default DateRangeMenu;
