import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  Fab,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import LabelIcon from '@material-ui/icons/Label';

import CreateAddressForm from '../components/CreateAddressForm';
import Alert from '../../../../components/alert/Alert';
import FormPhoneField from '../../../../components/forms/fields/customs/formPhoneField/FormPhoneField';
import FormApiAutocompleteField from '../../../../components/forms/fields/FormApiAutocompleteField';
import FormSelectField from '../../../../components/forms/fields/FormSelectField';
import translation from '../../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1, 0)
  },
  title: {
    textAlign: 'center',
    marginBottom: 30,
    fontSize: 18
  },
  subtitle: {
    fontSize: 14
  },
  discountsWrapper: {
    marginTop: 4,
    display: 'flex',
    flexWrap: 'wrap'
  },
  discountChip: {
    margin: 2
  },
  paymentDelegate: {
    background: theme.palette.background.default,
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  }
}));

function DiscountChip(props) {
  const {
    className,
    discount: { id, name },
    onDeleteDiscount
  } = props;

  function handleDelete() {
    onDeleteDiscount(id);
  }

  return (
    <Chip
      icon={<LabelIcon />}
      label={name ? name : `Missing name, ID is ${id}`}
      onDelete={handleDelete}
      className={className}
    />
  );
}

DiscountChip.propTypes = {
  className: PropTypes.string.isRequired,
  discount: PropTypes.shape({
    id: PropTypes.any.isRequired,
    name: PropTypes.string
  }),
  onDeleteDiscount: PropTypes.func.isRequired
};

function PaymentStep(props) {
  const {
    addDiscountToCart,
    addresses,
    cart,
    createClientAddress,
    discountToCart,
    estateForm,
    getClientAddresses,
    getClientCart,
    orderCreate,
    orderReference,
    paymentDelegate,
    paymentMethods,
    removeDiscountToCart,
    selectClientInvoiceAddress,
    selectClientPaymentMethod,
    storeEstateValue,
    storePaymentDelegateValue,
    storeOrderReference,
    togglePaymentDelegate,
    user
  } = props;

  const classes = useStyles();
  const [addressErrors, setAddressErrors] = useState(null);
  const [addressSelected, setAddressSelected] = useState('');
  const [isAddressLoaded, setIsAddressLoaded] = useState(false);
  const [isCreateAddressDialogOpen, setCreateAddressDialogOpen] = useState(false);
  const [paymentMethodSelected, setPaymentMethodSelected] = useState('');
  const [delegateAmountError, setDelegateAmountError] = useState('');
  const [delegateEmailError, setDelegateEmailError] = useState('');

  useEffect(() => {
    getClientAddresses(user, () => {
      setIsAddressLoaded(true);
    });

    if (paymentMethods && paymentMethods.selected && paymentMethods.selected.id) {
      setPaymentMethodSelected(paymentMethods.selected.id);
    }

    if (addresses && addresses.selected && addresses.selected.id) {
      setAddressSelected(addresses.selected.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function checkInvoiceAddressSelected(value) {
    if (addresses && addresses.data && addresses.data.length) {
      for (let i = 0; i < addresses.data.length; i++) {
        if (addresses.data[i].id === value) {
          selectClientInvoiceAddress(addresses.data[i]);
          break;
        }
      }
    }
  }

  function checkPaymentMethodSelected(value) {
    if (paymentMethods && paymentMethods.data && paymentMethods.data.length) {
      for (let i = 0; i < paymentMethods.data.length; i++) {
        if (paymentMethods.data[i].id === value) {
          selectClientPaymentMethod(paymentMethods.data[i]);
          break;
        }
      }
    }
  }

  function handleCheckPaymentDelegate() {
    togglePaymentDelegate();
    setDelegateEmailError('');
    setDelegateAmountError('');

    if (paymentDelegate.open) {
      storePaymentDelegateValue('delegate_amount', '');
    } else {
      storePaymentDelegateValue('delegate_amount', '100%');
    }
  }

  const handleChangePaymentDelegateValues = (name) => (event) => {
    storePaymentDelegateValue(name, event.target.value);
  };

  const handleChangeEstateValues = (name) => (event) => {
    storeEstateValue(name, event.target.value);
  };

  function handleChangePhoneInput(val) {
    storeEstateValue('property_contact_phone', val);
  }

  function handleChangeOrderReference(event) {
    storeOrderReference(event.target.value);
  }

  function handleChangePaymentMethod(id) {
    setPaymentMethodSelected(id);
    checkPaymentMethodSelected(id);
  }

  function handleChangeAddress(id) {
    setAddressSelected(id);
    checkInvoiceAddressSelected(id);
  }

  function handleOpenCreateAddressDialog() {
    setCreateAddressDialogOpen(true);
  }

  function handleCloseCreateAddressDialog() {
    setCreateAddressDialogOpen(false);
  }

  function handleCreateAddress(values, isCompanyToggled) {
    createClientAddress(
      user,
      values,
      isCompanyToggled,
      (success) => {
        setAddressErrors(null);
        handleCloseCreateAddressDialog();
        getClientAddresses(user);
        setAddressSelected(success.id);
        selectClientInvoiceAddress(success);
      },
      (error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.errors &&
          error.response.data.errors.length > 0
        ) {
          const fieldsErrors = [
            'street_number',
            'street',
            'zip_code',
            'city',
            'country_isocode',
            'state_isocode',
            'latitude',
            'longitude'
          ];
          const validationErrors = {};
          const errorsArray = error.response.data.errors;

          for (let i = 0; i < errorsArray.length; i++) {
            if (errorsArray[i].propertyPath === 'address') {
              validationErrors['invoice_address_value'] = errorsArray[i].message;
            } else if (fieldsErrors.indexOf(errorsArray[i].propertyPath) >= 0) {
              validationErrors['invoice_address_value'] =
                errorsArray[i].propertyPath + ': ' + errorsArray[i].message;
            } else if (errorsArray[i].propertyPath === 'name') {
              validationErrors['invoice_address_name'] = errorsArray[i].message;
            } else {
              validationErrors[errorsArray[i].propertyPath] = errorsArray[i].message;
            }
          }

          setAddressErrors(validationErrors);
        }
      }
    );
  }

  function handleSelectDiscount(code) {
    if (!code || !cart || !cart.data || !cart.data[0] || !cart.data[0].id) return;

    addDiscountToCart(user, cart.data[0].id, code, () => {
      getClientCart(user);
    });
  }

  function handleDeleteDiscount(id) {
    if (!id || !cart || !cart.data || !cart.data[0] || !cart.data[0].id) return;

    removeDiscountToCart(cart.data[0].id, id, () => {
      getClientCart(user);
    });
  }

  function handleBlurDelegateAmount() {
    if (
      paymentDelegate['delegate_amount'] &&
      !/^\d*\.?\d*,?\d*%?$/.test(paymentDelegate['delegate_amount'])
    ) {
      setDelegateAmountError(
        translation().views.orders.create.payment.payment_delegate.error_regexp
      );
    } else {
      setDelegateAmountError(null);
    }
  }

  function handleFocusDelegateAmount() {
    setDelegateAmountError(null);
  }

  function handleBlurDelegateEmail() {
    if (!paymentDelegate['delegate_email']) {
      setDelegateEmailError(translation().commons.required_field);
    } else {
      setDelegateEmailError(null);
    }
  }

  function handleFocusDelegateEmail() {
    setDelegateEmailError(null);
  }

  return (
    <div className="PaymentStep">
      <Typography variant="h5" className={classes.title}>
        {translation().views.orders.create.payment.title}
      </Typography>
      <Typography variant="subtitle2" className={classes.subtitle}>
        {translation().views.orders.create.payment.invoicing.title}
      </Typography>
      {paymentMethods && paymentMethods.data && paymentMethods.data.length > 0 ? (
        <FormSelectField
          id="payment_method_id"
          disabled={orderCreate.isLoading}
          displayEmpty
          label={translation().views.orders.create.payment.invoicing.methods}
          name="payment_method_id"
          onChange={handleChangePaymentMethod}
          required
          value={paymentMethodSelected}>
          {paymentMethods.data.map((pm, index) => {
            return (
              <MenuItem key={index} value={pm.id}>
                {(pm.type ? pm.type : '') + ' ' + pm.name}
              </MenuItem>
            );
          })}
        </FormSelectField>
      ) : (
        <Alert
          type="warning"
          status={translation().commons.alerts.warning}
          text={translation().views.orders.create.informations.warning_client}
        />
      )}
      {addresses.isLoading && (
        <div className="loader-wpr">
          <CircularProgress size={28} color="secondary" />
          <p>{translation().views.orders.create.payment.invoicing.addresses.loading_text}</p>
        </div>
      )}
      {addresses && addresses.data && addresses.data.length > 0 ? (
        <FormSelectField
          disabled={orderCreate.isLoading}
          displayEmpty
          id="invoice_address_id"
          label={translation().views.orders.create.payment.invoicing.addresses.label}
          name="invoice_address_id"
          onChange={handleChangeAddress}
          required
          value={addressSelected}>
          {addresses.data.map((address, index) => {
            return (
              <MenuItem key={index} value={address.id}>
                {address.name +
                  ' (' +
                  address.street_number +
                  ' ' +
                  address.street +
                  ' ' +
                  (address.zip_code ? address.zip_code + ' ' : ' ') +
                  address.city +
                  ')'}
              </MenuItem>
            );
          })}
        </FormSelectField>
      ) : isAddressLoaded && !addresses.isLoading ? (
        <Alert
          type="warning"
          status={translation().commons.alerts.warning}
          text={translation().views.orders.create.payment.invoicing.addresses.empty}
        />
      ) : (
        ''
      )}
      {isAddressLoaded && !addresses.isLoading && (
        <Tooltip
          aria-label="create-client-address"
          enterDelay={500}
          title={translation().views.orders.create.payment.invoicing.addresses.tooltip}>
          <Fab
            aria-label="create-client-address"
            className={classes.margin}
            size="small"
            onClick={handleOpenCreateAddressDialog}>
            <AddIcon />
          </Fab>
        </Tooltip>
      )}
      {isCreateAddressDialogOpen && (
        <CreateAddressForm
          isFormLoading={addresses.create.isLoading}
          errors={addressErrors}
          onClose={handleCloseCreateAddressDialog}
          onSubmit={handleCreateAddress}
          user={user}
        />
      )}
      <div className="sm-space" />
      <FormControlLabel
        control={
          <Checkbox
            checked={paymentDelegate.open}
            onChange={handleCheckPaymentDelegate}
            name="delegate_payment_checkbox"
            color="primary"
          />
        }
        label={translation().views.orders.create.payment.payment_delegate.title}
        classes={{
          label: classes.subtitle
        }}
      />
      {paymentDelegate.open && (
        <Box className={classes.paymentDelegate}>
          <Typography color="textSecondary">
            {translation().views.orders.create.payment.payment_delegate.desc}
          </Typography>
          <Grid container spacing={2}>
            <Grid xs={12} sm={6} item>
              <TextField
                autoComplete="none"
                disabled={orderCreate.isLoading}
                error={Boolean(delegateEmailError)}
                fullWidth
                helperText={delegateEmailError}
                id="delegate-email"
                label={translation().views.orders.create.payment.payment_delegate.email}
                margin="normal"
                name="delegate_email"
                onBlur={handleBlurDelegateEmail}
                onChange={handleChangePaymentDelegateValues('delegate_email')}
                onFocus={handleFocusDelegateEmail}
                required
                type="email"
                value={paymentDelegate['delegate_email'] || ''}
              />
            </Grid>
            <Grid xs={12} sm={6} item>
              <TextField
                autoComplete="none"
                disabled={orderCreate.isLoading}
                error={Boolean(delegateAmountError)}
                fullWidth
                helperText={delegateAmountError}
                id="delegate-amount"
                label={translation().views.orders.create.payment.payment_delegate.amount}
                margin="normal"
                name="delegate_amount"
                onBlur={handleBlurDelegateAmount}
                onChange={handleChangePaymentDelegateValues('delegate_amount')}
                onFocus={handleFocusDelegateAmount}
                value={paymentDelegate['delegate_amount'] || ''}
              />
            </Grid>
          </Grid>
        </Box>
      )}
      <div className="sm-space" />
      <Typography className={classes.subtitle} style={{ marginBottom: 4 }} variant="subtitle2">
        {translation().views.orders.create.payment.estate.title}
      </Typography>
      <TextField
        autoComplete="none"
        disabled={orderCreate.isLoading}
        id="floor"
        fullWidth
        label={translation().views.orders.create.payment.estate.form.labels.level}
        margin="normal"
        name="floor"
        onChange={handleChangeEstateValues('floor')}
        type="number"
        value={estateForm['floor'] || ''}
      />
      <TextField
        autoComplete="none"
        disabled={orderCreate.isLoading}
        id="digital_code"
        fullWidth
        label={translation().views.orders.create.payment.estate.form.labels.digital_code}
        margin="normal"
        name="digital_code"
        onChange={handleChangeEstateValues('digital_code')}
        type="text"
        value={estateForm['digital_code'] || ''}
      />
      <TextField
        autoComplete="none"
        id="intercom"
        fullWidth
        label={translation().views.orders.create.payment.estate.form.labels.intercom}
        margin="normal"
        name="intercom"
        disabled={orderCreate.isLoading}
        onChange={handleChangeEstateValues('intercom')}
        type="text"
        value={estateForm['intercom'] || ''}
      />
      <TextField
        autoComplete="none"
        disabled={orderCreate.isLoading}
        id="property_contact_name"
        fullWidth
        helperText={translation().views.orders.create.payment.estate.form.labels.helperText}
        label={translation().views.orders.create.payment.estate.form.labels.property_contact_name}
        name="property_contact_name"
        margin="normal"
        onChange={handleChangeEstateValues('property_contact_name')}
        type="text"
        value={estateForm['property_contact_name'] || ''}
      />
      <FormPhoneField
        id="property_contact_phone"
        helperText={translation().views.orders.create.payment.estate.form.labels.helperText}
        name="property_contact_phone"
        onChange={handleChangePhoneInput}
        placeholder={
          translation().views.orders.create.payment.estate.form.labels.property_contact_phone
        }
        type="tel"
        value={estateForm['property_contact_phone'] || ''}
      />
      <TextField
        autoComplete="none"
        disabled={orderCreate.isLoading}
        id="reference"
        fullWidth
        label={translation().views.orders.create.payment.order_reference}
        name="reference"
        margin="normal"
        onChange={handleChangeOrderReference}
        type="text"
        value={orderReference || ''}
      />
      <TextField
        autoComplete="none"
        id="property_contact_comment"
        disabled={orderCreate.isLoading}
        fullWidth
        label={
          translation().views.orders.create.payment.estate.form.labels.property_contact_comment
        }
        margin="normal"
        multiline
        name="property_contact_comment"
        onChange={handleChangeEstateValues('property_contact_comment')}
        rows={5}
        type="text"
        value={estateForm['property_contact_comment'] || ''}
      />
      <div className="sm-space" />
      <Typography className={classes.subtitle} style={{ marginBottom: 4 }} variant="subtitle2">
        {translation().views.orders.create.payment.discount.title}
      </Typography>
      <FormApiAutocompleteField
        disabled={discountToCart.isLoading}
        fullWidth
        links={[{ rel: 'list', href: 'discounts' }]}
        name="cart_discount_autocomplete"
        onSelectValue={handleSelectDiscount}
        placeholder={translation().views.orders.create.payment.discount.input_placeholder}
        query={{
          currency: user.currency && user.currency.isocode ? user.currency.isocode : undefined
        }}
        targetKey="code"
      />
      <div className={classes.discountsWrapper}>
        {!discountToCart.isLoading &&
        cart &&
        cart.data &&
        cart.data[0] &&
        cart.data[0].discounts &&
        cart.data[0].discounts.length
          ? cart.data[0].discounts.map((discount, idx) => {
              return (
                <DiscountChip
                  key={idx}
                  className={classes.discountChip}
                  discount={discount}
                  onDeleteDiscount={handleDeleteDiscount}
                />
              );
            })
          : ''}
      </div>
      {discountToCart.isLoading && (
        <div className="loader-wpr">
          <CircularProgress size={28} color="secondary" />
          <p>{translation().views.orders.create.payment.discount.add_loading_text}</p>
        </div>
      )}
    </div>
  );
}

PaymentStep.propTypes = {
  addDiscountToCart: PropTypes.func.isRequired,
  addresses: PropTypes.shape({
    selected: PropTypes.shape({
      id: PropTypes.number
    }),
    error: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape()),
    isLoading: PropTypes.bool,
    create: PropTypes.shape({
      isLoading: PropTypes.bool
    }).isRequired
  }).isRequired,
  cart: PropTypes.shape().isRequired,
  createClientAddress: PropTypes.func.isRequired,
  discountToCart: PropTypes.shape({
    isLoading: PropTypes.bool
  }).isRequired,
  estateForm: PropTypes.shape().isRequired,
  getClientAddresses: PropTypes.func.isRequired,
  getClientCart: PropTypes.func.isRequired,
  orderCreate: PropTypes.shape().isRequired,
  orderReference: PropTypes.string,
  paymentMethods: PropTypes.shape().isRequired,
  paymentDelegate: PropTypes.shape({
    open: PropTypes.bool,
    delegate_amount: PropTypes.string,
    delegate_email: PropTypes.string
  }).isRequired,
  removeDiscountToCart: PropTypes.func.isRequired,
  selectClientInvoiceAddress: PropTypes.func.isRequired,
  selectClientPaymentMethod: PropTypes.func.isRequired,
  storeEstateValue: PropTypes.func.isRequired,
  storePaymentDelegateValue: PropTypes.func.isRequired,
  storeOrderReference: PropTypes.func.isRequired,
  togglePaymentDelegate: PropTypes.func.isRequired,
  user: PropTypes.shape().isRequired
};

export default PaymentStep;
