import './moderation.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import EventNoteIcon from '@material-ui/icons/EventNote';
import RefreshIcon from '@material-ui/icons/Refresh';
import Typography from '@material-ui/core/Typography';

import { addToDate, dateInTz } from '../../utils/functions/dates';
import translation from '../../translation/translation';
import Alert from '../../components/alert/Alert';
import ModerationViewBar from './components/ModerationViewBar';
import Product from './components/Product';
import OrderProductDialog from './components/OrderProductDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: '100%',
    height: '100%',
    overflow: 'auto'
  }
}));

function Moderation(props) {
  const {
    clearModeration,
    getModerationData,
    history,
    moderation: { data, isLaunched, isLoading, item, error, paging }
  } = props;

  const classes = useStyles();
  const [search, setSearch] = useState('');
  const [orderProductDialogOpen, setOrderProductDialog] = useState(null);

  useEffect(() => {
    document.title = 'Actarus | Moderation';

    getModerationData();

    return () => {
      clearModeration();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleScrollList(e) {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      handleLoadNext();
    }
  }

  function handleLoadNext() {
    if (!isLoading && paging && paging.next) {
      getModerationData(paging.next);
    }
  }

  function handleSubmit(e) {
    getModerationData(null, search);
    e.preventDefault();
  }

  function handleSearch(e) {
    setSearch(e.target.value);
  }

  function handleRefresh() {
    setSearch('');
    clearModeration();
    getModerationData();
  }

  function handleOpenOrderProductDialog(product) {
    if (!product) return;
    setOrderProductDialog(product);
  }

  function handleCloseOrderProductDialog() {
    setOrderProductDialog(null);
  }

  function handleDeliverOrderProduct() {
    handleCloseOrderProductDialog();
    handleRefresh();
  }

  function renderList(data) {
    if (!data) return;

    const list = [];

    for (let i = 0; i < data.length; i++) {
      if (
        !data[i - 1] ||
        dateInTz(
          addToDate(
            data[i - 1].end,
            data[i - 1].delivery_time ? data[i - 1].delivery_time : 0,
            'hour'
          ),
          'YYYYMMDD'
        ) !==
          dateInTz(
            addToDate(data[i].end, data[i].delivery_time ? data[i].delivery_time : 0, 'hour'),
            'YYYYMMDD'
          )
      ) {
        list.push(
          <div className="day-breaker" key={'day-breaker-' + i}>
            <EventNoteIcon className="day-breaker-icon" />{' '}
            <Typography component="p" variant="button">
              {dateInTz(
                addToDate(data[i].end, data[i].delivery_time ? data[i].delivery_time : 0, 'hour'),
                'dddd D MMMM YYYY'
              )}
            </Typography>
          </div>
        );
      }
      if (
        !data[i - 1] ||
        dateInTz(
          addToDate(
            data[i - 1].end,
            data[i - 1].delivery_time ? data[i - 1].delivery_time : 0,
            'hour'
          ),
          'YYYYMMDDHH'
        ) !==
          dateInTz(
            addToDate(data[i].end, data[i].delivery_time ? data[i].delivery_time : 0, 'hour'),
            'YYYYMMDDHH'
          )
      ) {
        list.push(
          <div className="hour-breaker" key={'hour-breaker-' + i}>
            <span>
              {dateInTz(
                addToDate(data[i].end, data[i].delivery_time ? data[i].delivery_time : 0, 'hour'),
                'HH:00'
              )}
            </span>
          </div>
        );
      }
      list.push(
        <Product
          key={i}
          history={history}
          onClickCard={handleOpenOrderProductDialog}
          {...data[i]}
        />
      );
    }

    return list;
  }

  return (
    <div className="Moderation">
      <ModerationViewBar
        history={history}
        handleSubmit={handleSubmit}
        handleSearch={handleSearch}
        handleRefresh={handleRefresh}
        search={search}
      />
      <div className={classes.root} onScroll={handleScrollList}>
        {!isLoading && error && (!data || data.length <= 0) ? (
          <div className="error-list">
            <Alert
              type="danger"
              style={{ marginTop: 10 }}
              status={translation().commons.alerts.error}
              text={translation().core.list.error_list}
            />
          </div>
        ) : (
          false
        )}
        {data && data.length > 0 ? (
          renderList(data)
        ) : isLaunched && !error && !isLoading ? (
          <div>
            <Alert
              type="info"
              style={{ marginTop: 10 }}
              status={translation().commons.alerts.info}
              text={translation().views.production.moderation.empty}
            />
            <div className="md-space" />
            <Button variant="contained" startIcon={<RefreshIcon />} onClick={handleRefresh}>
              {translation().actions.refresh}
            </Button>
          </div>
        ) : (
          false
        )}
        {isLoading && !paging && (
          <div className="loader-wpr">
            <CircularProgress color="primary" />
            <p>{translation().views.orders.list.loading_text}</p>
          </div>
        )}
        {isLoading && paging && paging.next && (
          <div className="loader-wpr">
            <CircularProgress color="primary" />
            <p>{translation().core.list.load_next}</p>
          </div>
        )}
        {!isLoading && paging && !paging.next && paging.prev && (
          <div className="loader-wpr linear">
            <p>{translation().core.list.load_next_done}</p>
          </div>
        )}
        {!isLoading && !paging && data && data.length > 0 ? (
          <div className="loader-wpr linear">
            <p>{translation().core.list.load_next_done}</p>
          </div>
        ) : (
          false
        )}
        {!isLoading && paging && paging.next && data && data.length > 0 ? (
          <div className="loader-wpr">
            <Button variant="contained" onClick={handleLoadNext}>
              {translation().core.list.charge_next}
            </Button>
          </div>
        ) : (
          false
        )}
        <OrderProductDialog
          open={orderProductDialogOpen ? true : false}
          onClose={handleCloseOrderProductDialog}
          onDeliver={handleDeliverOrderProduct}
          product={orderProductDialogOpen}
          disableClose={item.isLoading}
        />
      </div>
    </div>
  );
}

Moderation.propTypes = {
  clearModeration: PropTypes.func.isRequired,
  history: PropTypes.shape().isRequired,
  getModerationData: PropTypes.func.isRequired,
  moderation: PropTypes.shape({
    error: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.shape()),
    paging: PropTypes.shape({
      next: PropTypes.string,
      prev: PropTypes.string
    }),
    isLaunched: PropTypes.bool,
    isLoading: PropTypes.bool,
    item: PropTypes.shape({ isLoading: PropTypes.bool }).isRequired
  }).isRequired
};
export default Moderation;
