import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchApi } from '../../utils/functions/api';
import { alterDate, createDate, formatDate, getCurrentDate } from '../../utils/functions/dates';
import Home from '../../views/home/Home';

const mapStateToProps = (store) => ({
  user: store.user
});

const mapDispatchToProps = () => {
  return {
    async getMetric(route, query, sourceToken, callbackSuccess = null, callbackError = null) {
      if (!route) return;

      const today = getCurrentDate();
      const todayStart = alterDate(today, null, null, null, '00', '00', '00');
      const todayEnd = alterDate(today, null, null, null, '23', '59', '59');
      const from = createDate(todayStart);
      const until = createDate(todayEnd);

      /**
       * For send UTC
       * const today = getCurrentDateUtc();
       * const from = createDate(todayStart, 'UTC');
       * const until = createDate(todayEnd, 'UTC');
       */

      const range = {
        from: formatDate(from, 'YYYY-MM-DDTHH:mm:ssZ'),
        until: formatDate(until, 'YYYY-MM-DDTHH:mm:ssZ')
      };

      await fetchApi(
        'get',
        `metrics/${route}`,
        {
          ...range,
          ...query,
          split: true
        },
        null,
        null,
        (success) => {
          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success);
          }
        },
        (error) => {
          if (callbackError && typeof callbackError === 'function') {
            callbackError(error);
          }
        },
        null,
        sourceToken
      );
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
