import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchApi } from '../../utils/functions/api';
import { enqueueSnackbar } from '../../redux/actions/appActions';
import {
  cleanProductViewReducer,
  cleanProductViewOptionsReducer,
  cleanProductViewMediaTypesReducer,
  cleanProductViewHostingPlansReducer,
  getProduct,
  getProductSuccess,
  getProductError,
  getProductOptions,
  getProductOptionsSuccess,
  getProductOptionsError,
  getProductMediaTypes,
  getProductMediaTypesSuccess,
  getProductMediaTypesError,
  getProductHostingPlans,
  getProductHostingPlansSuccess,
  getProductHostingPlansError
} from '../../redux/actions/views/productViewActions';
import translation from '../../translation/translation';
import ProductView from '../../views/products/productView/ProductView';

const mapStateToProps = (store) => ({
  product: store.views.product,
  user: store.user
});

const mapDispatchToProps = (dispatch) => {
  return {
    cleanProductViewReducer() {
      dispatch(cleanProductViewReducer());
    },
    cleanProductViewOptionsReducer() {
      dispatch(cleanProductViewOptionsReducer());
    },
    cleanProductViewMediaTypesReducer() {
      dispatch(cleanProductViewMediaTypesReducer());
    },
    cleanProductViewHostingPlansReducer() {
      dispatch(cleanProductViewHostingPlansReducer());
    },
    getProduct: (id, callbackSuccess, callbackError) => {
      if (!id) return;

      dispatch(getProduct());

      fetchApi(
        'get',
        `products/${id}`,
        null,
        null,
        null,
        (product) => {
          dispatch(getProductSuccess(product));

          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(product);
          }
        },
        (error) => {
          dispatch(getProductError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.callbacks.error_list,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().core.list.callbacks.error_list,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError(error);
          }
        }
      );
    },
    getProductOptions: (id, next = null, callbackSuccess = null, callbackError = null) => {
      if (!id) return;

      dispatch(getProductOptions(next));

      fetchApi(
        'get',
        next ? next : `products/${id}/options`,
        null,
        null,
        null,
        (success, paging) => {
          dispatch(getProductOptionsSuccess(success, paging));

          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success, paging);
          }
        },
        (error) => {
          dispatch(getProductOptionsError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.callbacks.error_list,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().core.list.callbacks.error_list,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError(error);
          }
        }
      );
    },
    getProductMediaTypes: (id, next = null, callbackSuccess = null, callbackError = null) => {
      if (!id) return;

      dispatch(getProductMediaTypes(next));

      fetchApi(
        'get',
        next ? next : `products/${id}/media-types`,
        null,
        null,
        null,
        (success, paging) => {
          dispatch(getProductMediaTypesSuccess(success, paging));

          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success, paging);
          }
        },
        (error) => {
          dispatch(getProductMediaTypesError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.callbacks.error_list,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().core.list.callbacks.error_list,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError(error);
          }
        }
      );
    },
    getProductHostingPlans: (id, next = null, callbackSuccess = null, callbackError = null) => {
      if (!id) return;

      dispatch(getProductHostingPlans(next));

      fetchApi(
        'get',
        next ? next : `products/${id}/hosting-plans`,
        null,
        null,
        null,
        (success, paging) => {
          dispatch(getProductHostingPlansSuccess(success, paging));

          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success, paging);
          }
        },
        (error) => {
          dispatch(getProductHostingPlansError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.callbacks.error_list,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().core.list.callbacks.error_list,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError(error);
          }
        }
      );
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductView));
