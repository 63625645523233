import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import Alert from '../../../components/alert/Alert';
import translation from '../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      minWidth: 300
    }
  },
  hash: {
    color: theme.palette.secondary.main,
    display: 'inline-block',
    fontSize: 16
  },
  hashLabel: {
    display: 'inline-block',
    marginRight: 4
  }
}));

function ProviderHash(props) {
  const { getProviderHash, resource } = props;
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  const [hash, setHash] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (resource && resource.id) {
      setLoading(true);
      setError(null);

      getProviderHash(
        resource.id,
        (success) => {
          setLoading(false);

          if (success.hash) {
            setHash(success.hash);
          } else {
            setHash(null);
            setError(
              translation().core.list.dialogs.custom_list_actions.provider_hash.callbacks.empty
            );
          }
        },
        () => {
          setLoading(false);
          setError(
            translation().core.list.dialogs.custom_list_actions.provider_hash.callbacks.error
          );
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="ProviderHash">
      <div className={classes.root}>
        {hash && (
          <>
            <Typography className={classes.hashLabel}>Hash:</Typography>
            <Typography variant="overline" className={classes.hash}>
              {hash}
            </Typography>
          </>
        )}
        {error && (
          <Alert
            type="danger"
            style={{ marginTop: 10 }}
            status={translation().commons.alerts.error}
            text={error}
          />
        )}
        {isLoading && (
          <div className="loader-wpr">
            <CircularProgress color="primary" size={25} />
            <p>{translation().core.list.dialogs.custom_list_actions.provider_hash.load_hash}</p>
          </div>
        )}
      </div>
    </div>
  );
}

ProviderHash.propTypes = {
  getProviderHash: PropTypes.func.isRequired,
  resource: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  })
};

export default ProviderHash;
