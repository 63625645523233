import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-number-input';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import ApartmentIcon from '@material-ui/icons/Apartment';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import Avatar from '@material-ui/core/Avatar';
import BusinessIcon from '@material-ui/icons/Business';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import DevicesOtherIcon from '@material-ui/icons/DevicesOther';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import EmailIcon from '@material-ui/icons/Email';
import EventNoteIcon from '@material-ui/icons/EventNote';
import FlagIcon from '@material-ui/icons/Flag';
import Grid from '@material-ui/core/Grid';
import GTranslateIcon from '@material-ui/icons/GTranslate';
import IconButton from '@material-ui/core/IconButton';
import LanguageIcon from '@material-ui/icons/Language';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Link from '@material-ui/core/Link';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import PersonIcon from '@material-ui/icons/Person';
import PermPhoneMsgIcon from '@material-ui/icons/PermPhoneMsg';
import PolicyIcon from '@material-ui/icons/Policy';
import Rating from '@material-ui/lab/Rating';
import RoomIcon from '@material-ui/icons/Room';
import SettingsIcon from '@material-ui/icons/Settings';
import SecurityIcon from '@material-ui/icons/Security';
import StarsIcon from '@material-ui/icons/Stars';
import SquareFootIcon from '@material-ui/icons/SquareFoot';
import Typography from '@material-ui/core/Typography';
import WcIcon from '@material-ui/icons/Wc';

import Alert from '../../../../../components/alert/Alert';
import FormApiAutocompleteField from '../../../../../components/forms/fields/FormApiAutocompleteField';
import UserPositionsContainer from '../../../../../containers/core/customListActions/UserPositionsContainer';
import { dateInTz } from '../../../../../utils/functions/dates';
import config from '../../../../../config';
import translation from '../../../../../translation/translation';
import Devices from './Devices';

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: '#FFFFFF',
    color: 'rgba(0, 0, 0, 0.87)'
  },
  averageRating: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  averageText: {
    paddingTop: 2,
    fontSize: 10
  },
  cardHeader: {
    minHeight: 60,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.cardHeader
  },
  cardContent: {
    padding: theme.spacing(1),
    '&:last-child': {
      paddingBottom: 0
    }
  },
  formControl: {
    width: '100%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2)
  },
  paper: {
    minHeight: 450,
    overflow: 'auto',
    width: '100%',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      minHeight: 'auto'
    }
  },
  paperLarge: {
    height: 470
  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2)
  },
  listItemBox: {
    minWidth: 35
  },
  listItem: {
    padding: 4
  },
  cardText: {
    padding: theme.spacing(0.5, 1)
  },
  divider: {
    marginTop: 4,
    marginBottom: 4
  },
  listRoot: {
    padding: 0
  },
  empty: {
    margin: 8,
    fontSize: 12
  },
  mapContainer: {
    padding: '0!important',
    height: 'calc(100% - 60px - 35px)'
  },
  map: {
    height: '100%'
  }
}));

function UserSummary(props) {
  const {
    cleanUserViewDevicesReducer,
    devices,
    getUserViewDevices,
    onRefresh,
    user,
    updateUserLevel
  } = props;

  const userAvatarUrl =
    user && user.picture_url ? `${user.picture_url}?client_id=${config.api_key}` : null;
  const companyAvatarUrl =
    user && user.company && user.company.logo_url
      ? `${user.company.logo_url}?client_id=${config.api_key}`
      : null;

  const [isUpdateLevelDialogOpen, setUpdateLevelDialogOpen] = useState(false);
  const [isUpdateUserLoading, setUpdateUserLoading] = useState(false);
  const [levelValue, setLevelValue] = useState(user && user.level ? user.level : null);
  const classes = useStyles();

  function handleBlockChange() {
    return false;
  }

  function handleOpenUpdateLevelDialog() {
    setUpdateLevelDialogOpen(true);
  }

  function handleCloseUpdateLevelDialog() {
    setUpdateLevelDialogOpen(false);
  }

  function handleChangeUserLevel(level) {
    setLevelValue(level);
  }

  function handleUpdateLevel() {
    if (!user.id || !levelValue.id) return;

    setUpdateUserLoading(true);

    updateUserLevel(
      user.id,
      levelValue.id,
      () => {
        setUpdateUserLoading(false);
        onRefresh();
      },
      () => {
        setUpdateUserLoading(false);
      }
    );
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          {user && (
            <Card className={classes.paper}>
              <CardHeader
                className={classes.cardHeader}
                title={translation().views.user_view.infos.info_user}
                titleTypographyProps={{
                  variant: 'h5',
                  style: {
                    fontSize: 21
                  }
                }}
                avatar={
                  userAvatarUrl ? (
                    <Avatar
                      className={classes.avatar}
                      aria-label="profile_picture"
                      src={userAvatarUrl}
                      alt={user.first_name.charAt(0).toUpperCase()}
                    />
                  ) : (
                    <Avatar>{user.first_name.charAt(0).toUpperCase()}</Avatar>
                  )
                }
                action={
                  <IconButton
                    aria-label="Edit user"
                    href={`/users/${user.id}/update`}
                    target="_blank">
                    <EditIcon fontSize="small" />
                  </IconButton>
                }
              />
              <CardContent className={classes.cardContent}>
                <List>
                  <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemBox}>
                      <PersonIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={translation().views.user_view.infos.first_name}
                      secondary={user.first_name}
                    />
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemBox}>
                      <PersonIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={translation().views.user_view.infos.last_name}
                      secondary={user.last_name}
                    />
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemBox}>
                      <EventNoteIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={translation().views.user_view.infos.date_of_birth}
                      secondary={dateInTz(user.date_of_birth, 'localized-date')}
                    />
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemBox}>
                      <WcIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={translation().views.user_view.infos.gender}
                      secondary={user.gender}
                    />
                  </ListItem>
                  {user.level && user.level.name && (
                    <ListItem className={classes.listItem}>
                      <ListItemIcon className={classes.listItemBox}>
                        <PolicyIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={translation().views.user_view.infos.level}
                        secondary={user.level.name}
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="edit user level"
                          onClick={handleOpenUpdateLevelDialog}
                          size="small">
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  )}
                  {user.average_rating && (
                    <>
                      <Divider className={classes.divider} />
                      <ListItem className={classes.listItem}>
                        <ListItemIcon className={classes.listItemBox}>
                          <StarsIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={translation().views.user_view.infos.average_rating}
                          disableTypography
                          secondary={
                            <div className={classes.averageRating}>
                              <Rating
                                value={user.average_rating}
                                precision={0.5}
                                readOnly
                                size="small"
                              />
                              <Typography
                                component="span"
                                variant="body2"
                                className={classes.averageText}>
                                ({user.average_rating})
                              </Typography>
                            </div>
                          }
                        />
                      </ListItem>
                    </>
                  )}
                </List>
              </CardContent>
            </Card>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          {user && (
            <Card className={classes.paper}>
              <CardHeader
                className={classes.cardHeader}
                title={translation().views.user_view.infos.contact}
                titleTypographyProps={{
                  variant: 'h5',
                  style: {
                    fontSize: 21
                  }
                }}
                avatar={
                  <Avatar>
                    <PermPhoneMsgIcon />
                  </Avatar>
                }
              />
              <CardContent className={classes.cardContent}>
                <List>
                  <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemBox}>
                      <EmailIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={translation().views.user_view.infos.email}
                      secondary={<Link href={`mailto:${user.email}`}>{user.email}</Link>}
                    />
                  </ListItem>
                  {user.phone && (
                    <ListItem className={classes.listItem}>
                      <ListItemIcon className={classes.listItemBox}>
                        <PhoneInput
                          value={user.phone}
                          disabled
                          onChange={handleBlockChange}
                          className="user-view-phone-input"
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={translation().views.user_view.infos.phone}
                        secondary={<Link href={`tel: ${user.phone}`}>{user.phone}</Link>}
                      />
                    </ListItem>
                  )}
                  {user.mobile && (
                    <ListItem className={classes.listItem}>
                      <ListItemIcon className={classes.listItemBox}>
                        <PhoneInput
                          value={user.mobile}
                          disabled
                          onChange={handleBlockChange}
                          className="user-view-phone-input"
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={translation().views.user_view.infos.mobile}
                        secondary={<Link href={`tel: ${user.mobile}`}>{user.mobile}</Link>}
                      />
                    </ListItem>
                  )}
                </List>
              </CardContent>
            </Card>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          {user && (
            <Card className={classes.paper}>
              <CardHeader
                className={classes.cardHeader}
                title={translation().views.user_view.infos.settings}
                titleTypographyProps={{
                  variant: 'h5',
                  style: {
                    fontSize: 21
                  }
                }}
                avatar={
                  <Avatar>
                    <SettingsIcon />
                  </Avatar>
                }
              />
              <CardContent className={classes.cardContent}>
                <List>
                  {user.residence_country &&
                    user.residence_country.isocode &&
                    user.residence_country.name && (
                      <ListItem className={classes.listItem}>
                        <ListItemIcon className={classes.listItemBox}>
                          <img
                            src={`/assets/images/flags/${user.residence_country.isocode.toUpperCase()}.svg`}
                            alt={user.residence_country.isocode}
                            height={12.25}
                            width={18.38}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={translation().views.user_view.infos.residence_country}
                          secondary={user.residence_country.name}
                        />
                      </ListItem>
                    )}
                  {user.citizen_country &&
                    user.citizen_country.isocode &&
                    user.citizen_country.name && (
                      <ListItem className={classes.listItem}>
                        <ListItemIcon className={classes.listItemBox}>
                          <img
                            src={`/assets/images/flags/${user.citizen_country.isocode.toUpperCase()}.svg`}
                            alt={user.citizen_country.isocode}
                            height={12.25}
                            width={18.38}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={translation().views.user_view.infos.citizen_country}
                          secondary={user.citizen_country.name}
                        />
                      </ListItem>
                    )}
                  {user.timezone_code && (
                    <ListItem className={classes.listItem}>
                      <ListItemIcon className={classes.listItemBox}>
                        <LanguageIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={translation().views.user_view.infos.timezone}
                        secondary={user.timezone_code}
                      />
                    </ListItem>
                  )}
                  {user.language && user.language.name && (
                    <ListItem className={classes.listItem}>
                      <ListItemIcon className={classes.listItemBox}>
                        <GTranslateIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={translation().views.user_view.infos.language}
                        secondary={user.language.name}
                      />
                    </ListItem>
                  )}
                  {user.currency && user.currency.name && (
                    <ListItem className={classes.listItem}>
                      <ListItemIcon className={classes.listItemBox}>
                        <MonetizationOnIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={translation().views.user_view.infos.currency}
                        secondary={user.currency.name}
                      />
                    </ListItem>
                  )}
                  {user.unit_system && (
                    <ListItem className={classes.listItem}>
                      <ListItemIcon className={classes.listItemBox}>
                        <SquareFootIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={translation().views.user_view.infos.unit_system}
                        secondary={user.unit_system}
                      />
                    </ListItem>
                  )}
                </List>
              </CardContent>
            </Card>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          {user.company ? (
            <Card className={classes.paper}>
              <CardHeader
                className={classes.cardHeader}
                title={translation().views.user_view.infos.info_company}
                titleTypographyProps={{
                  variant: 'h5',
                  style: {
                    fontSize: 21
                  }
                }}
                avatar={
                  companyAvatarUrl ? (
                    <Avatar
                      className={classes.avatar}
                      aria-label="profile_picture"
                      src={companyAvatarUrl}
                      alt={user.company.commercial_name.charAt(0).toUpperCase()}
                    />
                  ) : (
                    <Avatar>
                      <ApartmentIcon />
                    </Avatar>
                  )
                }
                action={
                  <IconButton
                    aria-label="Edit company"
                    href={`/companies/${user.company.id}/update`}
                    target="_blank">
                    <EditIcon fontSize="small" />
                  </IconButton>
                }
              />
              <CardContent className={classes.cardContent}>
                <List>
                  <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemBox}>
                      <SecurityIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={translation().views.user_view.infos.is_admin}
                      secondary={
                        user.company_admin ? (
                          <DoneIcon
                            style={{ color: config.success_color || '#4CAF50' }}
                            fontSize="small"
                          />
                        ) : (
                          <CloseIcon
                            style={{ color: config.error_color || '#F44336' }}
                            fontSize="small"
                          />
                        )
                      }
                    />
                  </ListItem>
                  {user.company.commercial_name && (
                    <>
                      <ListItem className={classes.listItem}>
                        <ListItemIcon className={classes.listItemBox}>
                          <BusinessIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={translation().views.user_view.infos.commercial_name}
                          secondary={user.company.commercial_name}
                        />
                      </ListItem>
                    </>
                  )}
                  {user.company.legal_name && (
                    <>
                      <ListItem className={classes.listItem}>
                        <ListItemIcon className={classes.listItemBox}>
                          <BusinessIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={translation().views.user_view.infos.legal_name}
                          secondary={user.company.legal_name}
                        />
                      </ListItem>
                    </>
                  )}
                  {user.company.vat_number && (
                    <>
                      <ListItem className={classes.listItem}>
                        <ListItemIcon className={classes.listItemBox}>
                          <AccountBalanceIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={translation().views.user_view.infos.vat_number}
                          secondary={user.company.vat_number}
                        />
                      </ListItem>
                    </>
                  )}
                  <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemBox}>
                      <FlagIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={translation().views.user_view.infos.flag}
                      secondary={
                        user.company.flag ? (
                          <DoneIcon
                            style={{ color: config.success_color || '#4CAF50' }}
                            fontSize="small"
                          />
                        ) : (
                          <CloseIcon
                            style={{ color: config.error_color || '#F44336' }}
                            fontSize="small"
                          />
                        )
                      }
                    />
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          ) : (
            <Card className={classes.paper}>
              <CardHeader
                className={classes.cardHeader}
                title={translation().views.user_view.infos.info_company}
                titleTypographyProps={{
                  variant: 'h5',
                  style: {
                    fontSize: 21
                  }
                }}
                avatar={
                  <Avatar>
                    <ApartmentIcon />
                  </Avatar>
                }
              />
              <CardContent className={classes.cardContent}>
                <Alert
                  type="info"
                  style={{ marginTop: 10 }}
                  status={translation().commons.alerts.info}
                  text={translation().views.user_view.infos.no_company}
                />
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          {user && (
            <Card className={classNames(classes.paper, classes.paperLarge)}>
              <CardHeader
                className={classes.cardHeader}
                title={translation().views.user_view.position.label}
                titleTypographyProps={{
                  variant: 'h5',
                  style: {
                    fontSize: 21
                  }
                }}
                avatar={
                  <Avatar>
                    <RoomIcon />
                  </Avatar>
                }
              />
              <CardContent className={classes.mapContainer}>
                <UserPositionsContainer resource={user} className={classes.map} />
              </CardContent>
            </Card>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          {user && (
            <Card className={classNames(classes.paper, classes.paperLarge)}>
              <CardHeader
                className={classes.cardHeader}
                title={translation().views.user_view.devices.label}
                titleTypographyProps={{
                  variant: 'h5',
                  style: {
                    fontSize: 21
                  }
                }}
                avatar={
                  <Avatar>
                    <DevicesOtherIcon />
                  </Avatar>
                }
                action={
                  <IconButton
                    aria-label="See all devices"
                    href={`/users/${user.id}/devices`}
                    target="_blank">
                    <OpenInNewIcon fontSize="small" />
                  </IconButton>
                }
              />
              <CardContent className={classes.cardContent}>
                <Devices
                  userId={user.id}
                  devices={devices}
                  getUserViewDevices={getUserViewDevices}
                  cleanUserViewDevicesReducer={cleanUserViewDevicesReducer}
                />
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>
      <Dialog
        open={isUpdateLevelDialogOpen}
        onClose={handleCloseUpdateLevelDialog}
        aria-labelledby="update-user-level-dialog"
        aria-describedby="update-user-level"
        disableBackdropClick={isUpdateUserLoading}
        fullWidth
        maxWidth="sm">
        <DialogTitle id="update-user-level-dialog">
          {translation().views.user_view.user_level.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="update-user-level">
            {translation().views.user_view.user_level.description}
          </DialogContentText>
          <div className={classes.formControl}>
            <FormApiAutocompleteField
              name="user-levels-field"
              label={translation().views.user_view.user_level.label}
              placeholder={translation().views.user_view.user_level.input_placeholder}
              links={[{ rel: 'list', href: 'user-levels' }]}
              onSelectValue={handleChangeUserLevel}
              getFullObject
              defaultValue={levelValue}
              targetKey="id"
              disabled={isUpdateUserLoading}
            />
          </div>
          {isUpdateUserLoading && (
            <div className="loader-wpr">
              <CircularProgress color="primary" />
              <p>{translation().views.user_view.user_level.loading_text}</p>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUpdateLevelDialog} disabled={isUpdateUserLoading}>
            {translation().actions.cancel}
          </Button>
          <Button
            onClick={handleUpdateLevel}
            color="primary"
            disabled={!levelValue || isUpdateUserLoading}
            variant="contained">
            {translation().actions.confirm}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

UserSummary.propTypes = {
  cleanUserViewDevicesReducer: PropTypes.func.isRequired,
  devices: PropTypes.shape().isRequired,
  getUserViewDevices: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  user: PropTypes.shape().isRequired,
  updateUserLevel: PropTypes.func.isRequired
};

export default UserSummary;
