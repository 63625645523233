import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AppBar from '@material-ui/core/AppBar';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Tooltip from '@material-ui/core/Tooltip';
import MoreIcon from '@material-ui/icons/MoreVert';
import { withStyles } from '@material-ui/core/styles';

import { isNavigationBackPossible, navigateBack } from '../../../../utils/functions/navigation';
import translation from '../../../../translation/translation';

const styles = (theme) => ({
  root: {
    width: '100%'
  },
  grow: {
    flexGrow: 1
  },
  backIcon: {
    padding: 10
  },
  breadcrumb: {
    display: 'none',
    [theme.breakpoints.up(768)]: {
      display: 'block'
    }
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
});

class CreateViewBar extends PureComponent {
  state = {
    anchorEl: null,
    mobileMoreAnchorEl: null
  };

  handleProfileMenuOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  };

  handleMobileMenuOpen = (event) => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  handleHistoryBack = () => {
    const { history } = this.props;

    navigateBack(history);
  };

  render() {
    const { mobileMoreAnchorEl } = this.state;
    const { classes, disabled, history, parentUri } = this.props;

    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const renderMobileMenu = isNavigationBackPossible(history) ? (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        onClose={this.handleMenuClose}>
        <MenuItem onClick={this.handleHistoryBack}>
          <ArrowBackIcon fontSize="small" />
          <p>{translation().actions.back}</p>
        </MenuItem>
      </Menu>
    ) : (
      false
    );

    return (
      <div className={classes.root}>
        <AppBar elevation={0} position="static" color="default">
          <Toolbar>
            <Breadcrumbs
              maxItems={5}
              separator={<NavigateNextIcon fontSize="small" />}
              arial-label="Create view breadcrumb"
              className={classes.breadcrumb}>
              <Typography variant="body2" color="textPrimary">
                {parentUri}
              </Typography>
              {history &&
              history.location &&
              history.location.pathname &&
              history.location.pathname.length > 0
                ? history.location.pathname
                    .split('/')
                    .slice(1)
                    .map((n, i) => {
                      return (
                        <Typography variant="body2" color="textPrimary" key={i}>
                          {n}
                        </Typography>
                      );
                    })
                : false}
            </Breadcrumbs>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              {isNavigationBackPossible(history) && (
                <Tooltip
                  title={translation().actions.back || 'Back'}
                  aria-label={translation().actions.back || 'Back'}
                  enterDelay={700}>
                  <div>
                    <IconButton
                      onClick={this.handleHistoryBack}
                      color="inherit"
                      className={classes.backIcon}
                      disabled={disabled}>
                      <ArrowBackIcon fontSize="small" />
                    </IconButton>
                  </div>
                </Tooltip>
              )}
            </div>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-haspopup="true"
                onClick={this.handleMobileMenuOpen}
                color="inherit"
                disabled={disabled}>
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
      </div>
    );
  }
}

CreateViewBar.propTypes = {
  disabled: PropTypes.bool,
  classes: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  parentUri: PropTypes.string.isRequired
};

export default withStyles(styles)(CreateViewBar);
