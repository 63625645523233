/**
 * FORMS ACTIONS
 */

export const setFormsErrors = (formName, errors) => ({
  type: 'SET_FORM_ERRORS',
  formName,
  errors
});

export const initializeForm = (formName, fields) => ({
  type: 'INITIALIZE_FORM',
  formName,
  fields
});

export const deleteForm = (formName) => ({
  type: 'DELETE_FORM',
  formName
});

export const cleanFormsErrors = (formName) => ({
  type: 'CLEAN_FORM_ERRORS',
  formName
});
