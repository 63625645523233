/**
 * SHOP METRICS
 */
import translation from '../../translation/translation';

export default {
  name: 'Shop metrics',
  routes_selector: [
    {
      label: translation().metrics.core.categories.orders,
      value: 'orders'
    },
    {
      label: translation().metrics.core.categories.revenue,
      value: 'orders-revenue'
    },
    {
      label: translation().metrics.core.categories.profit_margin,
      value: 'orders-avg-profit-margin-ratio'
    },
    {
      label: translation().metrics.core.categories.average_basket,
      value: 'orders-avg-basket'
    },
    {
      label: translation().metrics.core.categories.products,
      value: 'order-products'
    },
    {
      label: translation().metrics.core.categories.providers,
      value: 'provider-order-products'
    }
  ],
  metrics_routes: {
    orders: {
      child_routes: {
        'orders-interval-auto': {
          route: 'orders',
          chart_props: {
            type: 'line',
            title: translation().metrics.core.categories.order_number,
            metric_calc_mode: 'count'
          },
          query_params: {
            time_interval: 'auto'
          }
        },
        'orders-interval-auto-compare-period': {
          route: 'orders',
          chart_props: {
            title: translation().metrics.core.categories.order_number_trending,
            type: 'trending',
            metric_calc_mode: 'avg',
            custom: true
          },
          query_params: {
            compare: 'period'
          }
        },
        'orders-per-company-bar': {
          route: 'orders-per-company',
          chart_props: {
            type: 'bar',
            title: translation().metrics.core.categories.order_per_company_bar
          },
          query_params: {
            limit: 20
          }
        },
        'orders-per-company-doughnut': {
          route: 'orders-per-company',
          chart_props: {
            type: 'doughnut',
            title: translation().metrics.core.categories.order_per_company_doughnut
          },
          query_params: {
            limit: 20
          }
        },
        'orders-per-app-bar': {
          route: 'orders-per-app',
          chart_props: {
            type: 'bar',
            title: translation().metrics.core.categories.order_per_app_bar
          },
          query_params: {
            limit: 20
          }
        },
        'orders-per-app-doughnut': {
          route: 'orders-per-app',
          chart_props: {
            type: 'doughnut',
            title: translation().metrics.core.categories.order_per_app_doughnut
          },
          query_params: {
            limit: 20
          }
        }
      }
    },
    'orders-revenue': {
      child_routes: {
        'orders-revenue-interval-auto': {
          route: 'orders-revenue',
          chart_props: {
            type: 'line',
            title: translation().metrics.core.categories.orders_revenue_interval_auto,
            metric_calc_mode: 'count'
          },
          query_params: {
            time_interval: 'auto'
          }
        },
        'orders-revenue-interval-auto-compare-period': {
          route: 'orders-revenue',
          chart_props: {
            title: translation().metrics.core.categories.orders_revenue_trending,
            type: 'trending',
            metric_calc_mode: 'avg',
            custom: true
          },
          query_params: {
            compare: 'period'
          }
        },
        'orders-revenue-per-company-bar': {
          route: 'orders-revenue-per-company',
          chart_props: {
            type: 'bar',
            title: translation().metrics.core.categories.orders_revenue_per_company_bar
          },
          query_params: {
            limit: 20
          }
        },
        'orders-revenue-per-company-doughnut': {
          route: 'orders-revenue-per-company',
          chart_props: {
            type: 'doughnut',
            title: translation().metrics.core.categories.orders_revenue_per_company_doughnut
          },
          query_params: {
            limit: 20
          }
        },
        'orders-revenue-per-app-bar': {
          route: 'orders-revenue-per-app',
          chart_props: {
            type: 'bar',
            title: translation().metrics.core.categories.orders_revenue_per_app_bar
          },
          query_params: {
            limit: 20
          }
        },
        'orders-revenue-per-app-doughnut': {
          route: 'orders-revenue-per-app',
          chart_props: {
            type: 'doughnut',
            title: translation().metrics.core.categories.orders_revenue_per_app_bar
          },
          query_params: {
            limit: 20
          }
        }
      }
    },
    'orders-avg-profit-margin-ratio': {
      child_routes: {
        'orders-avg-profit-margin-ratio-interval-auto': {
          route: 'orders-avg-profit-margin-ratio',
          chart_props: {
            type: 'line',
            title: translation().metrics.core.categories.profit_margin + ':',
            metric_calc_mode: 'function',
            metric_calc_function: {
              type: 'call-api',
              route: 'orders-avg-profit-margin-ratio',
              calc_mode: 'avg',
              query_params: {}
            }
          },
          query_params: {
            time_interval: 'auto'
          }
        },
        'orders-avg-profit-margin-ratio-interval-auto-compare-period': {
          route: 'orders-avg-profit-margin-ratio',
          chart_props: {
            title: translation().metrics.core.categories.profit_margin_trending,
            type: 'trending',
            metric_calc_mode: 'avg',
            custom: true
          },
          query_params: {
            compare: 'period'
          }
        },
        'orders-avg-profit-margin-ratio-per-company-bar': {
          route: 'orders-avg-profit-margin-ratio-per-company',
          chart_props: {
            type: 'bar',
            title: translation().metrics.core.categories.orders_profit_margin_per_company_bar
          },
          query_params: {
            limit: 20
          }
        },
        'orders-avg-profit-margin-per-company-doughnut': {
          route: 'orders-avg-profit-margin-per-company',
          chart_props: {
            type: 'doughnut',
            title: translation().metrics.core.categories.orders_profit_margin_per_company_doughnut
          },
          query_params: {
            limit: 20
          }
        },
        'orders-avg-profit-margin-ratio-per-app-bar': {
          route: 'orders-avg-profit-margin-ratio-per-app',
          chart_props: {
            type: 'bar',
            title: translation().metrics.core.categories.orders_profit_margin_per_app_bar
          },
          query_params: {
            limit: 20
          }
        },
        'orders-avg-profit-margin-per-app-doughnut': {
          route: 'orders-avg-profit-margin-per-app',
          chart_props: {
            type: 'doughnut',
            title: translation().metrics.core.categories.orders_profit_margin_per_app_doughnut
          },
          query_params: {
            limit: 20
          }
        }
      }
    },
    'orders-avg-basket': {
      child_routes: {
        'orders-avg-basket-interval-auto': {
          route: 'orders-avg-basket',
          chart_props: {
            type: 'line',
            title: translation().metrics.core.categories.average_basket + ':',
            metric_calc_mode: 'function',
            metric_calc_function: {
              type: 'call-api',
              route: 'orders-avg-basket',
              calc_mode: 'avg',
              query_params: {}
            }
          },
          query_params: {
            time_interval: 'auto'
          }
        },
        'orders-avg-basket-interval-auto-compare-period': {
          route: 'orders-avg-basket',
          chart_props: {
            title: translation().metrics.core.categories.average_basket_trending,
            type: 'trending',
            metric_calc_mode: 'avg',
            custom: true
          },
          query_params: {
            compare: 'period'
          }
        },
        'orders-avg-basket-per-company-bar': {
          route: 'orders-avg-basket-per-company',
          chart_props: {
            type: 'bar',
            title: translation().metrics.core.categories.average_basket_per_company_bar
          },
          query_params: {
            limit: 20
          }
        },
        'orders-avg-basket-per-company-doughnut': {
          route: 'orders-avg-basket-per-company',
          chart_props: {
            type: 'doughnut',
            title: translation().metrics.core.categories.average_basket_per_company_doughnut
          },
          query_params: {
            limit: 20
          }
        },
        'orders-avg-basket-per-app-bar': {
          route: 'orders-avg-basket-per-app',
          chart_props: {
            type: 'bar',
            title: translation().metrics.core.categories.average_basket_per_app_bar
          },
          query_params: {
            limit: 20
          }
        },
        'orders-avg-basket-per-app-doughnut': {
          route: 'orders-avg-basket-per-app',
          chart_props: {
            type: 'doughnut',
            title: translation().metrics.core.categories.average_basket_per_app_doughnut
          },
          query_params: {
            limit: 20
          }
        }
      }
    },
    'order-products': {
      child_routes: {
        'order-products-interval-auto': {
          route: 'order-products',
          chart_props: {
            type: 'line',
            title: translation().metrics.core.categories.product_number,
            metric_calc_mode: 'count'
          },
          query_params: {
            time_interval: 'auto'
          }
        },
        'order-products-interval-auto-compare-period': {
          route: 'order-products',
          chart_props: {
            title: translation().metrics.core.categories.product_number_trending,
            type: 'trending',
            metric_calc_mode: 'avg',
            custom: true
          },
          query_params: {
            compare: 'period'
          }
        },
        'order-products-per-product-bar': {
          route: 'order-products-per-product',
          chart_props: {
            type: 'bar',
            title: translation().metrics.core.categories.product_per_product_bar
          },
          query_params: {
            limit: 20
          }
        },
        'order-products-per-product-pie': {
          route: 'order-products-per-product',
          chart_props: {
            type: 'doughnut',
            title: translation().metrics.core.categories.product_per_product_pie
          },
          query_params: {
            limit: 20
          }
        },
        'order-products-revenue-per-product-bar': {
          route: 'order-products-revenue-per-product',
          chart_props: {
            type: 'bar',
            title: translation().metrics.core.categories.product_revenue_per_product_bar
          },
          query_params: {
            limit: 20
          }
        },
        'order-products-revenue-per-product-pie': {
          route: 'order-products-revenue-per-product',
          chart_props: {
            type: 'doughnut',
            title: translation().metrics.core.categories.product_revenue_per_product_pie
          },
          query_params: {
            limit: 20
          }
        },
        'order-products-avg-profit-margin-ratio-per-product': {
          route: 'order-products-avg-profit-margin-ratio-per-product',
          chart_props: {
            type: 'bar',
            title: translation().metrics.core.categories.product_profit_margin_per_product_bar
          },
          query_params: {
            limit: 20
          }
        },
        'order-products-avg-profit-margin-per-product': {
          route: 'order-products-avg-profit-margin-per-product',
          chart_props: {
            type: 'doughnut',
            title: translation().metrics.core.categories.product_profit_margin_per_product_pie
          },
          query_params: {
            limit: 20
          }
        }
      }
    },
    'provider-order-products': {
      child_routes: {
        'provider-order-products-avg-provider-cost-interval-auto': {
          route: 'order-products-avg-provider-cost',
          chart_props: {
            type: 'line',
            title:
              translation().metrics.core.categories
                .provider_order_products_avg_provider_cost_interval_auto,
            metric_calc_mode: 'function',
            metric_calc_function: {
              type: 'call-api',
              route: 'order-products-avg-provider-cost',
              calc_mode: 'avg',
              query_params: {}
            }
          },
          query_params: {
            time_interval: 'auto'
          }
        },
        'provider-order-products-avg-provider-cost-interval-auto-compare-period': {
          route: 'order-products-avg-provider-cost',
          chart_props: {
            title:
              translation().metrics.core.categories
                .provider_order_products_avg_provider_cost_interval_auto_compare_period,
            type: 'trending',
            metric_calc_mode: 'avg',
            custom: true
          },
          query_params: {
            compare: 'period'
          }
        },
        'provider-order-products-avg-provider-cost-per-provider-interval-auto-bar': {
          route: 'order-products-avg-provider-cost-per-provider',
          chart_props: {
            type: 'bar',
            title:
              translation().metrics.core.categories
                .provider_order_products_avg_provider_cost_per_provider_interval_auto_bar
          },
          query_params: {
            limit: 20
          }
        },
        'provider-order-products-avg-provider-cost-per-provider-interval-auto-doughnut': {
          route: 'order-products-avg-provider-cost-per-provider',
          chart_props: {
            type: 'doughnut',
            title:
              translation().metrics.core.categories
                .provider_order_products_avg_provider_cost_per_provider_interval_auto_doughnut
          },
          query_params: {
            limit: 20
          }
        },
        'provider-order-products-per-provider-interval-auto-bar': {
          route: 'order-products-per-provider',
          chart_props: {
            type: 'bar',
            title:
              translation().metrics.core.categories
                .provider_order_products_per_provider_interval_auto_bar
          },
          query_params: {
            limit: 20
          }
        },
        'provider-order-products-per-provider-interval-auto-doughnut': {
          route: 'order-products-per-provider',
          chart_props: {
            type: 'doughnut',
            title:
              translation().metrics.core.categories
                .provider_order_products_per_provider_interval_auto_doughnut
          },
          query_params: {
            limit: 20
          }
        },
        'provider-order-products-avg-profit-margin-ratio-per-provider-interval-auto-bar': {
          route: 'order-products-avg-profit-margin-ratio-per-provider',
          chart_props: {
            type: 'bar',
            title:
              translation().metrics.core.categories
                .provider_order_products_avg_profit_margin_ratio_per_provider_interval_auto_bar
          },
          query_params: {
            limit: 20
          }
        },
        'provider-order-products-avg-profit-margin-per-provider-interval-auto-doughnut': {
          route: 'order-products-avg-profit-margin-per-provider',
          chart_props: {
            type: 'doughnut',
            title:
              translation().metrics.core.categories
                .provider_order_products_avg_profit_margin_per_provider_interval_auto_doughnut
          },
          query_params: {
            limit: 20
          }
        },
        'provider-order-products-avg-trip-distance-interval-auto': {
          route: 'order-products-avg-trip-distance',
          chart_props: {
            type: 'line',
            title:
              translation().metrics.core.categories
                .provider_order_products_avg_trip_distance_interval_auto,
            metric_calc_mode: 'function',
            metric_calc_function: {
              type: 'call-api',
              route: 'order-products-avg-trip-distance',
              calc_mode: 'avg',
              query_params: {}
            }
          },
          query_params: {
            time_interval: 'auto'
          }
        },
        'provider-order-products-avg-trip-distance-interval-auto-compare-period': {
          route: 'order-products-avg-trip-distance',
          chart_props: {
            title:
              translation().metrics.core.categories
                .provider_order_products_avg_trip_distance_interval_auto_compare_period,
            type: 'trending',
            metric_calc_mode: 'avg',
            custom: true
          },
          query_params: {
            compare: 'period'
          }
        },
        'provider-order-products-avg-trip-distance-per-provider-interval-auto-bar': {
          route: 'order-products-avg-trip-distance-per-provider',
          chart_props: {
            type: 'bar',
            title:
              translation().metrics.core.categories
                .provider_order_products_avg_trip_distance_per_provider_interval_auto_bar
          },
          query_params: {
            limit: 20
          }
        },
        'provider-order-products-trip-distance--per-provider-interval-auto-doughnut': {
          route: 'order-products-trip-distance-per-provider',
          chart_props: {
            type: 'doughnut',
            title:
              translation().metrics.core.categories
                .provider_order_products_trip_distance_per_provider_interval_auto_doughnut
          },
          query_params: {
            limit: 20
          }
        },
        'provider-order-products-avg-trip-compensation-interval-auto': {
          route: 'order-products-avg-trip-compensation',
          chart_props: {
            type: 'line',
            title:
              translation().metrics.core.categories
                .provider_order_products_avg_trip_compensation_interval_auto,
            metric_calc_mode: 'function',
            metric_calc_function: {
              type: 'call-api',
              route: 'order-products-avg-trip-compensation',
              calc_mode: 'avg',
              query_params: {}
            }
          },
          query_params: {
            time_interval: 'auto'
          }
        },
        'provider-order-products-avg-trip-compensation-interval-auto-compare-period': {
          route: 'order-products-avg-trip-compensation',
          chart_props: {
            title:
              translation().metrics.core.categories
                .provider_order_products_avg_trip_compensation_interval_auto_compare_period,
            type: 'trending',
            metric_calc_mode: 'avg',
            custom: true
          },
          query_params: {
            compare: 'period'
          }
        },
        'provider-order-products-avg-trip-compensation-per-provider-interval-auto-bar': {
          route: 'order-products-avg-trip-compensation-per-provider',
          chart_props: {
            type: 'bar',
            title:
              translation().metrics.core.categories
                .provider_order_products_avg_trip_compensation_per_provider_interval_auto_bar
          },
          query_params: {
            limit: 20
          }
        },
        'provider-order-products-trip-compensation-per-provider-interval-auto-doughnut': {
          route: 'order-products-trip-compensation-per-provider',
          chart_props: {
            type: 'doughnut',
            title:
              translation().metrics.core.categories
                .provider_order_products_trip_compensation_per_provider_interval_auto_doughnut
          },
          query_params: {
            limit: 20
          }
        },
        'provider-order-products-avg-provider-rating-interval-auto': {
          route: 'order-products-avg-provider-rating',
          chart_props: {
            type: 'line',
            title:
              translation().metrics.core.categories
                .provider_order_products_avg_provider_rating_interval_auto,
            metric_calc_mode: 'function',
            metric_calc_function: {
              type: 'call-api',
              route: 'order-products-avg-provider-rating',
              calc_mode: 'avg',
              query_params: {}
            }
          },
          query_params: {
            time_interval: 'auto'
          }
        }
      }
    }
  }
};
