import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Box, ButtonBase, Paper, Typography } from '@material-ui/core';
import {
  LibraryBooksTwoTone as LibraryBooksTwoToneIcon,
  List as ListIcon
} from '@material-ui/icons';

import translation from '../../../../../../translation/translation';

const vueTypes = {
  list: {
    label: translation().views.task_views.tasks.view_type.list,
    name: 'list',
    icon: ListIcon
  },
  card: {
    label: translation().views.task_views.tasks.view_type.cards,
    name: 'card',
    icon: LibraryBooksTwoToneIcon
  }
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  buttonBase: {
    padding: theme.spacing(3),
    borderRadius: 8,
    margin: 4,
    display: 'flex',
    flexDirection: 'column',
    minWidth: 100,
    '&:not(.selected)': {
      boxShadow: 'none',
      border: '1px solid rgba(145, 158, 171, 0.12)',
      backgroundColor: theme.palette.background.paper
    }
  },
  icon: {
    color: theme.palette.secondary.main,
    marginBottom: theme.spacing(1),
    '&:not(.selected)': {
      color: '#9eaab7'
    }
  },
  text: {
    color: theme.palette.secondary.main,
    '&:not(.selected)': {
      color: '#9eaab7'
    }
  }
}));

function VueTypeButton(props) {
  const { icon, label, name, onClick, selected } = props;
  const classes = useStyles();

  function handleClick() {
    onClick(name);
  }

  const IconComponent = icon;

  return (
    <ButtonBase
      onClick={handleClick}
      component={Paper}
      className={classNames(classes.buttonBase, {
        selected: selected === name
      })}>
      <IconComponent
        fontSize="large"
        className={classNames(classes.icon, {
          selected: selected === name
        })}
      />
      <Typography
        variant="subtitle2"
        className={classNames(classes.text, {
          selected: selected === name
        })}>
        {label}
      </Typography>
    </ButtonBase>
  );
}

VueTypeButton.propTypes = {
  icon: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.string
};

function ViewTypeForm({ onChange, value }) {
  const classes = useStyles();

  function handleSelectType(value) {
    onChange(value);
  }

  return (
    <Box className={classes.root}>
      {Object.keys(vueTypes).map((type, index) => (
        <VueTypeButton
          key={index}
          label={vueTypes[type].label}
          name={vueTypes[type].name}
          onClick={handleSelectType}
          selected={value}
          icon={vueTypes[type].icon}
        />
      ))}
    </Box>
  );
}

ViewTypeForm.defaultProps = {
  value: 'list'
};

ViewTypeForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string
};

export default ViewTypeForm;
