export const setPreviousDaySearchValue = (value) => ({
  type: 'SET_PREVIOUS_DAY_SEARCH_VALUE',
  value
});

export const refreshPreviousDay = () => ({
  type: 'REFRESH_PREVIOUS_DAY'
});

export const getPreviousDay = (query, next) => ({
  type: 'GET_PREVIOUS_DAY',
  query,
  next
});

export const getPreviousDaySuccess = (data, paging) => ({
  type: 'GET_PREVIOUS_DAY_SUCCESS',
  data,
  paging
});

export const getPreviousDayError = (isError) => ({
  type: 'GET_PREVIOUS_DAY_ERROR',
  isError
});

export const clearOwnReducer = () => ({
  type: 'CLEAR_PREVIOUS_DAY_REDUCER'
});
