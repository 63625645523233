import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

function AclCustomCheckbox(props) {
  const { className, defaultChecked, handleChangeCheckbox, route, value } = props;

  const [checked, setChecked] = useState(defaultChecked || false);

  function handleChange(event) {
    setChecked(event.target.checked);

    if (handleChangeCheckbox) handleChangeCheckbox(route, event.target.checked);
  }

  return (
    <FormGroup style={{ display: 'inline-flex' }} className={className}>
      <FormControlLabel
        classes={{
          root: className
        }}
        control={
          <Checkbox checked={checked} className={className} onChange={handleChange} value={value} />
        }
        label={route}
      />
    </FormGroup>
  );
}

AclCustomCheckbox.defaultProps = {
  className: ''
};

AclCustomCheckbox.propTypes = {
  className: PropTypes.string,
  defaultChecked: PropTypes.bool,
  handleChangeCheckbox: PropTypes.func.isRequired,
  route: PropTypes.any,
  value: PropTypes.any
};

export default AclCustomCheckbox;
