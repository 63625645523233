import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';

import 'react-big-calendar/lib/sass/styles.scss';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss';

const localizer = momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(Calendar);

class Schedule extends Component {
  constructor(props) {
    super(props);

    this.calendarRef = undefined;
    this.handleView = this.handleView.bind(this);
    this.draggableAccessor = this.draggableAccessor.bind(this);

    this.setCalRef = (element) => {
      this.calendarRef = element;
    };
  }

  handleView(view) {
    if (!view) return;

    const { defaultDate, onNavigate, onView } = this.props;

    if (onNavigate) {
      onNavigate(
        this.calendarRef && this.calendarRef._values && this.calendarRef._values.date
          ? this.calendarRef._values.date
          : defaultDate,
        view
      );
    }
    if (onView) {
      onView(view);
    }
  }

  getEventProps(event) {
    if (event.type) {
      return {
        className: 'type-' + event.type
      };
    }
  }

  draggableAccessor(event) {
    const { currentView } = this.props;

    if (currentView && currentView !== 'month' && event && event.draggable) return true;

    return false;
  }

  render() {
    const {
      messages,
      formats,
      events,
      components,
      onNavigate,
      selectSlot,
      onSelectEvent,
      minTime,
      maxTime,
      step,
      view,
      defaultView,
      timeslots,
      defaultDate,
      getDrilldownView,
      onRangeChange,
      onEventResize,
      onEventDrop,
      resizable
    } = this.props;

    return (
      <DragAndDropCalendar
        ref={this.setCalRef}
        localizer={localizer}
        components={components}
        selectable="ignoreEvents"
        messages={messages}
        formats={formats}
        views={['week', 'month', 'day']}
        step={step}
        onSelectEvent={onSelectEvent}
        onNavigate={onNavigate}
        onSelectSlot={selectSlot}
        onEventDrop={onEventDrop}
        min={minTime}
        max={maxTime}
        onRangeChange={onRangeChange}
        getDrilldownView={getDrilldownView}
        view={view}
        onView={this.handleView}
        timeslots={timeslots}
        events={events}
        eventPropGetter={this.getEventProps}
        defaultView={defaultView}
        defaultDate={defaultDate}
        draggableAccessor={this.draggableAccessor}
        onEventResize={onEventResize}
        resizable={resizable}
      />
    );
  }
}

Schedule.propTypes = {
  components: PropTypes.shape({
    week: PropTypes.shape(),
    day: PropTypes.shape(),
    month: PropTypes.shape()
  }).isRequired,
  currentView: PropTypes.string,
  defaultDate: PropTypes.any,
  defaultView: PropTypes.string.isRequired,
  events: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  formats: PropTypes.shape({
    timeGutterFormat: PropTypes.string,
    dayFormat: PropTypes.string,
    dayHeaderFormat: PropTypes.string
  }).isRequired,
  getDrilldownView: PropTypes.func.isRequired,
  onEventDrop: PropTypes.func.isRequired,
  onEventResize: PropTypes.func.isRequired,
  onNavigate: PropTypes.func.isRequired,
  onRangeChange: PropTypes.func.isRequired,
  onSelectEvent: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
  selectSlot: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
  timeslots: PropTypes.number.isRequired,
  maxTime: PropTypes.any,
  messages: PropTypes.shape({
    previous: PropTypes.string,
    next: PropTypes.string,
    today: PropTypes.string,
    month: PropTypes.string,
    week: PropTypes.string,
    day: PropTypes.string,
    agenda: PropTypes.string,
    date: PropTypes.string,
    time: PropTypes.string
  }).isRequired,
  minTime: PropTypes.any,
  resizable: PropTypes.bool,
  view: PropTypes.string
};

export default Schedule;
