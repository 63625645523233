import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress, Link, TextField, Typography } from '@material-ui/core';

import './authentication.scss';
import config from '../../config';
import translation from '../../translation/translation';

const titleStyle = {
  marginBottom: 35,
  fontSize: 26
};

function Authentication(props) {
  const {
    reduxStore: { user, authentication }
  } = props;

  const [email, setEmail] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [password, setPassword] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [iframeSrc, setIframeSrc] = useState(null);

  useEffect(() => {
    document.title = 'Actarus | Sign in';
  }, []);

  function handleChangeEmail(event) {
    setEmail(event && event.target && event.target.value ? event.target.value : null);
  }

  function handleChangePassword(event) {
    setPassword(event && event.target && event.target.value ? event.target.value : null);
  }

  function handleSubmit(event) {
    event.preventDefault();

    if (password && email) {
      props.authentication();

      setIframeSrc(
        `${config.api_url}users/auth?client_id=${
          config.api_key
        }&email=${email}&password=${encodeURIComponent(password)}&submit=1`
      );
      setEmailError(null);
      setPasswordError(null);

      document.getElementById('wm_implicit_auth_iframe').onload = () => {
        const iframeAuth = document.getElementById('wm_implicit_auth_iframe');

        try {
          const frameWindowLocation = iframeAuth.contentWindow.location.href;

          if (!frameWindowLocation) {
            return;
          } else if (frameWindowLocation && !iframeAuth.contentWindow.location.hash) {
            return;
          }
        } catch (e) {
          props.authenticationError('Login failed.');
          setIframeSrc(null);
          console.error(e && e.stack ? e.stack : 'Login iframe failed.');
          return;
        }

        const hash = iframeAuth.contentWindow.location.hash;
        const hashParams = hash.substring(1).split('&');

        for (let i = 0; i < hashParams.length; i++) {
          const hashParamsSplit = hashParams[i].split('=');
          hashParams[hashParamsSplit[0]] = hashParamsSplit[1];
        }

        const accessToken = hashParams.access_token;
        let expirationDelay = hashParams.expires_in;

        expirationDelay = expirationDelay / (60 * 60 * 24);

        if (accessToken && expirationDelay) {
          props.storeAccessToken(accessToken, expirationDelay, () => {
            props.getUser();
          });

          props.authenticationSuccess();
        }
      };
    } else if (!password || !email) {
      if (!password) {
        setPasswordError(translation().commons.required_field);
      } else {
        setPasswordError(null);
      }

      if (!email) {
        setEmailError(translation().commons.required_field);
      } else {
        setEmailError(null);
      }
    }
  }

  return (
    <div className="Authentication">
      <div className="login-container">
        <div className="auth-wrapper">
          <div className="logo-content">
            <img alt="WM Actarus" height={150} src="/assets/images/logo-wish.svg" />
          </div>
          <div className="auth-iframe">
            <iframe id="wm_implicit_auth_iframe" title="wm_implicit_auth_iframe" src={iframeSrc} />
          </div>
          <div className="login-content">
            <Typography variant="h4" style={titleStyle}>
              {translation().views.auth.page_title}
            </Typography>
            <form onSubmit={handleSubmit} className="form-wrapper">
              <div className="field-wrapper">
                <TextField
                  placeholder={translation().views.auth.form.labels.email}
                  label={translation().views.auth.form.labels.email}
                  type="email"
                  id="user_email"
                  name="user_email"
                  error={emailError ? true : false}
                  helperText={emailError}
                  fullWidth={true}
                  autoComplete="username"
                  onChange={handleChangeEmail}
                />
              </div>
              <div className="field-wrapper">
                <TextField
                  placeholder={translation().views.auth.form.labels.password}
                  label={translation().views.auth.form.labels.password}
                  type="password"
                  id="user_password"
                  name="user_password"
                  autoComplete="current_password"
                  error={passwordError ? true : false}
                  helperText={passwordError}
                  fullWidth={true}
                  onChange={handleChangePassword}
                />
              </div>
              <Button
                type="submit"
                variant="outlined"
                color="secondary"
                className="login-button"
                disabled={authentication.loading || user.loading}>
                {authentication.loading || user.loading ? (
                  <CircularProgress color="secondary" size={21} />
                ) : (
                  translation().views.auth.form.button
                )}
              </Button>
            </form>
            {authentication.error && <div className="error-message">{authentication.error}</div>}
          </div>
        </div>
      </div>
      <footer className="auth-footer">
        <Typography variant="body2">
          © {config.site_brand} - {new Date().getFullYear()} {config.site_name}{' '}
          {config.site_version}
          <span className="auth-fgt-pwd">
            -
            <Link
              target="_blank"
              href="https://lightshop.wall-market.com/requestPassword"
              rel="noopener noreferrer">
              {translation().views.auth.forgot_pwd}
            </Link>
          </span>
        </Typography>
      </footer>
    </div>
  );
}

Authentication.propTypes = {
  reduxStore: PropTypes.shape({
    user: PropTypes.shape().isRequired,
    authentication: PropTypes.shape().isRequired
  }),
  authentication: PropTypes.func.isRequired,
  authenticationError: PropTypes.func.isRequired,
  authenticationSuccess: PropTypes.func.isRequired,
  storeAccessToken: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired
};

export default Authentication;
