import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import translation from '../../../translation/translation';

const useStyles = makeStyles(() => ({
  bold: {
    fontWeight: 500
  },
  subtitle: {
    fontSize: 16
  },
  label: {
    fontSize: 14,
    minWidth: 200
  },
  formInputs: {
    minWidth: 250,
    marginBottom: 12
  },
  formControl: {
    minWidth: 250,
    marginTop: 12,
    marginBottom: 12
  },
  formControlLabel: {
    marginLeft: 0,
    marginRight: 0
  },
  formGroup: {
    alignItems: 'start'
  },
  fitWidth: {
    width: 'fit-content'
  },
  fullWidth: {
    width: '100%'
  },
  textarea: {
    opacity: 0,
    width: 0,
    padding: 0,
    margin: 0
  }
}));

function LightshopPlugin() {
  const classes = useStyles();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const [settings, setSettings] = useState({
    access_token_url: null,
    client_id: 'xxxxxxxxxxxxxxxxxxxxxxxxxx',
    locale: 'fr',
    currency: 'EUR',
    timezone: 'Europe/Paris',
    unit_system: 'metric',
    register: false,
    logout: false,
    branding: false,
    env_mode: 'dev'
  });

  const [views, setViews] = useState({
    shop: {
      update_property: false,
      create_payment_methods: false,
      create_addressess: false
    },
    orders: {
      mode: 'all',
      open: false,
      download: false,
      reschedule: false,
      cancel: false
    },
    profile: {
      update: false,
      settings: false,
      password: false
    },
    company: {
      update: false,
      users: {
        create: false,
        manage: false,
        delete: false
      }
    },
    invoices: {
      invoices: {
        download: false,
        open: false
      },
      payment_methods: {
        create: false,
        delete: false,
        manage: false
      },
      addresses: {
        create: false,
        update: false,
        delete: false
      }
    }
  });

  function handleChangeSettings(event) {
    setSettings({
      ...settings,
      [event.target.name]: event.target.value
    });
  }

  function handleChangeSettingsSwitch(event) {
    setSettings({
      ...settings,
      [event.target.name]: event.target.checked
    });
  }

  function handleChangeShop(event) {
    setViews({
      ...views,
      shop: {
        ...views.shop,
        [event.target.name]: event.target.checked
      }
    });
  }

  function handleChangeOrders(event) {
    setViews({
      ...views,
      orders: {
        ...views.orders,
        [event.target.name]: event.target.checked ? event.target.checked : event.target.value
      }
    });
  }

  function handleChangeProfile(event) {
    setViews({
      ...views,
      profile: {
        ...views.profile,
        [event.target.name]: event.target.checked
      }
    });
  }

  function handleChangeCompany(event) {
    setViews({
      ...views,
      company: {
        ...views.company,
        [event.target.name]: event.target.checked
      }
    });
  }

  function handleChangeCompanyUsers(event) {
    setViews({
      ...views,
      company: {
        ...views.company,
        users: {
          ...views.company.users,
          [event.target.name]: event.target.checked
        }
      }
    });
  }

  function handleChangeInvoices(event) {
    setViews({
      ...views,
      invoices: {
        ...views.invoices,
        invoices: {
          ...views.invoices.invoices,
          [event.target.name]: event.target.checked
        }
      }
    });
  }

  function handleChangePaymentMethods(event) {
    setViews({
      ...views,
      invoices: {
        ...views.invoices,
        payment_methods: {
          ...views.invoices.payment_methods,
          [event.target.name]: event.target.checked
        }
      }
    });
  }

  function handleChangeAddresses(event) {
    setViews({
      ...views,
      invoices: {
        ...views.invoices,
        addresses: {
          ...views.invoices.addresses,
          [event.target.name]: event.target.checked
        }
      }
    });
  }

  function handleCopyToClipboard() {
    const copyTextarea = document.getElementById('copy-plugin-config');

    copyTextarea.select();
    copyTextarea.setSelectionRange(0, 99999); /* For mobile devices */

    try {
      document.execCommand('copy');
      handlepOpenTooltip();
    } catch (err) {
      console.error(err);
    }
  }

  function handlepOpenTooltip() {
    setTooltipOpen(true);
  }

  function handleCloseTooltip() {
    setTooltipOpen(false);
  }

  function handleOpenDialog() {
    setDialogOpen(true);
  }

  function handleCloseDialog() {
    setDialogOpen(false);
  }

  const configuration = {
    settings: {
      ...settings
    },
    property: {
      id: null,
      pid: null,
      address: '69 Rue de Dunkerque Paris France',
      size: 89
    },
    views: {
      ...views
    }
  };

  return (
    <div className="LightshopPlugin">
      <Typography gutterBottom component="h5" variant="h5">
        {translation().views.lab.lightshop_plugin.title}
      </Typography>
      <Typography gutterBottom component="p" variant="body1">
        {translation().views.lab.lightshop_plugin.baseline}
      </Typography>
      <div className="sm-space" />
      <Typography gutterBottom component="p" variant="body1" className={classes.subtitle}>
        Configuration générale:
      </Typography>
      <div>
        <TextField
          name="access_token_url"
          id="access_token_url"
          onChange={handleChangeSettings}
          className={classes.formInputs}
          label="access_token_url"
        />
      </div>
      <div>
        <TextField
          name="client_id"
          id="client_id"
          onChange={handleChangeSettings}
          className={classes.formInputs}
          label="client_id"
        />
      </div>
      <div>
        <TextField
          name="locale"
          id="locale"
          onChange={handleChangeSettings}
          className={classes.formInputs}
          label="locale"
        />
      </div>
      <div>
        <TextField
          name="currency"
          id="currency"
          onChange={handleChangeSettings}
          className={classes.formInputs}
          label="currency"
        />
      </div>
      <div>
        <TextField
          name="timezone"
          id="timezone"
          onChange={handleChangeSettings}
          className={classes.formInputs}
          label="timezone"
        />
      </div>
      <div>
        <TextField
          name="unit_system"
          id="unit_system"
          onChange={handleChangeSettings}
          className={classes.formInputs}
          label="unit_system"
        />
      </div>
      <FormGroup className={classes.formGroup}>
        <FormControlLabel
          checked={settings.register}
          control={<Switch color="primary" />}
          label={'register'}
          name="register"
          labelPlacement="start"
          onChange={handleChangeSettingsSwitch}
          classes={{
            label: classes.label,
            root: classes.formControlLabel
          }}
        />
        <FormControlLabel
          checked={settings.logout}
          control={<Switch color="primary" />}
          label={'logout'}
          name="logout"
          labelPlacement="start"
          onChange={handleChangeSettingsSwitch}
          classes={{
            label: classes.label,
            root: classes.formControlLabel
          }}
        />
        <FormControlLabel
          checked={settings.branding}
          control={<Switch color="primary" />}
          label={'branding'}
          name="branding"
          labelPlacement="start"
          onChange={handleChangeSettingsSwitch}
          classes={{
            label: classes.label,
            root: classes.formControlLabel
          }}
        />
      </FormGroup>
      <FormControl className={classes.formControl}>
        <InputLabel id="select-orders-mode">env_mode</InputLabel>
        <Select
          labelId="select-orders-env_mode"
          value={settings.env_mode}
          name="env_mode"
          onChange={handleChangeSettings}>
          <MenuItem value="prod">prod</MenuItem>
          <MenuItem value="dev">dev</MenuItem>
        </Select>
      </FormControl>
      <div className="sm-space" />
      <Typography gutterBottom component="p" variant="body1" className={classes.subtitle}>
        Configuration des vues:
      </Typography>
      <div className="sm-space" />
      <Typography gutterBottom component="p" variant="body1" className={classes.bold}>
        Shop:
      </Typography>
      <FormGroup className={classes.formGroup}>
        <FormControlLabel
          checked={views.shop.update_property}
          control={<Switch color="primary" />}
          label={'update property'}
          name="update_property"
          labelPlacement="start"
          onChange={handleChangeShop}
          classes={{
            label: classes.label,
            root: classes.formControlLabel
          }}
        />
        <FormControlLabel
          checked={views.shop.create_payment_methods}
          control={<Switch color="primary" />}
          label={'create_payment_methods'}
          name="create_payment_methods"
          labelPlacement="start"
          onChange={handleChangeShop}
          classes={{
            label: classes.label,
            root: classes.formControlLabel
          }}
        />
        <FormControlLabel
          checked={views.shop.create_addressess}
          control={<Switch color="primary" />}
          label={'create_addressess'}
          name="create_addressess"
          labelPlacement="start"
          onChange={handleChangeShop}
          classes={{
            label: classes.label,
            root: classes.formControlLabel
          }}
        />
      </FormGroup>
      <div className="sm-space" />
      <Typography gutterBottom component="p" variant="body1" className={classes.bold}>
        Orders:
      </Typography>
      <FormGroup className={classes.formGroup}>
        <FormControl className={classes.formControl}>
          <InputLabel id="select-orders-mode">Mode</InputLabel>
          <Select
            labelId="select-orders-mode"
            value={views.orders.mode}
            name="mode"
            onChange={handleChangeOrders}>
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="property">Property</MenuItem>
          </Select>
        </FormControl>
        <FormControlLabel
          checked={views.orders.open}
          control={<Switch color="primary" />}
          label={'open'}
          name="open"
          labelPlacement="start"
          onChange={handleChangeOrders}
          classes={{
            label: classes.label,
            root: classes.formControlLabel
          }}
        />
        <FormControlLabel
          checked={views.orders.download}
          control={<Switch color="primary" />}
          label={'download'}
          name="download"
          labelPlacement="start"
          onChange={handleChangeOrders}
          classes={{
            label: classes.label,
            root: classes.formControlLabel
          }}
        />
        <FormControlLabel
          checked={views.orders.reschedule}
          control={<Switch color="primary" />}
          label={'reschedule'}
          name="reschedule"
          labelPlacement="start"
          onChange={handleChangeOrders}
          classes={{
            label: classes.label,
            root: classes.formControlLabel
          }}
        />
        <FormControlLabel
          checked={views.orders.cancel}
          control={<Switch color="primary" />}
          label={'cancel'}
          name="cancel"
          labelPlacement="start"
          onChange={handleChangeOrders}
          classes={{
            label: classes.label,
            root: classes.formControlLabel
          }}
        />
      </FormGroup>
      <div className="sm-space" />
      <Typography gutterBottom component="p" variant="body1" className={classes.bold}>
        Profile:
      </Typography>
      <FormGroup className={classes.formGroup}>
        <FormControlLabel
          checked={views.profile.update}
          control={<Switch color="primary" />}
          label={'update'}
          name="update"
          labelPlacement="start"
          onChange={handleChangeProfile}
          classes={{
            label: classes.label,
            root: classes.formControlLabel
          }}
        />
        <FormControlLabel
          checked={views.profile.settings}
          control={<Switch color="primary" />}
          label={'settings'}
          name="settings"
          labelPlacement="start"
          onChange={handleChangeProfile}
          classes={{
            label: classes.label,
            root: classes.formControlLabel
          }}
        />
        <FormControlLabel
          checked={views.profile.password}
          control={<Switch color="primary" />}
          label={'password'}
          name="password"
          labelPlacement="start"
          onChange={handleChangeProfile}
          classes={{
            label: classes.label,
            root: classes.formControlLabel
          }}
        />
      </FormGroup>
      <div className="sm-space" />
      <Typography gutterBottom component="p" variant="body1" className={classes.bold}>
        Company:
      </Typography>
      <FormGroup className={classes.formGroup}>
        <FormControlLabel
          checked={views.company.update}
          control={<Switch color="primary" />}
          label={'update'}
          name="update"
          labelPlacement="start"
          onChange={handleChangeCompany}
          classes={{
            label: classes.label,
            root: classes.formControlLabel
          }}
        />
        <FormControlLabel
          checked={views.company.users.create}
          control={<Switch color="primary" />}
          label={'create user'}
          name="create"
          labelPlacement="start"
          onChange={handleChangeCompanyUsers}
          classes={{
            label: classes.label,
            root: classes.formControlLabel
          }}
        />
        <FormControlLabel
          checked={views.company.users.manage}
          control={<Switch color="primary" />}
          label={'manage users'}
          name="manage"
          labelPlacement="start"
          onChange={handleChangeCompanyUsers}
          classes={{
            label: classes.label,
            root: classes.formControlLabel
          }}
        />
        <FormControlLabel
          checked={views.company.users.delete}
          control={<Switch color="primary" />}
          label={'delete user'}
          name="delete"
          labelPlacement="start"
          onChange={handleChangeCompanyUsers}
          classes={{
            label: classes.label,
            root: classes.formControlLabel
          }}
        />
      </FormGroup>
      <div className="sm-space" />
      <Typography gutterBottom component="p" variant="body1" className={classes.bold}>
        Invoices:
      </Typography>
      <FormGroup className={classes.formGroup}>
        <FormControlLabel
          checked={views.invoices.invoices.download}
          control={<Switch color="primary" />}
          label={'Download'}
          name="download"
          labelPlacement="start"
          onChange={handleChangeInvoices}
          classes={{
            label: classes.label,
            root: classes.formControlLabel
          }}
        />
        <FormControlLabel
          checked={views.invoices.invoices.open}
          control={<Switch color="primary" />}
          label={'open'}
          name="open"
          labelPlacement="start"
          onChange={handleChangeInvoices}
          classes={{
            label: classes.label,
            root: classes.formControlLabel
          }}
        />
      </FormGroup>
      <div className="sm-space" />
      <Typography gutterBottom component="p" variant="body1" className={classes.bold}>
        Payment methods:
      </Typography>
      <FormGroup className={classes.formGroup}>
        <FormControlLabel
          checked={views.invoices.payment_methods.create}
          control={<Switch color="primary" />}
          label={'create'}
          name="create"
          labelPlacement="start"
          onChange={handleChangePaymentMethods}
          classes={{
            label: classes.label,
            root: classes.formControlLabel
          }}
        />
        <FormControlLabel
          checked={views.invoices.payment_methods.delete}
          control={<Switch color="primary" />}
          label={'delete'}
          name="delete"
          labelPlacement="start"
          onChange={handleChangePaymentMethods}
          classes={{
            label: classes.label,
            root: classes.formControlLabel
          }}
        />
        <FormControlLabel
          checked={views.invoices.payment_methods.manage}
          control={<Switch color="primary" />}
          label={'manage'}
          name="manage"
          labelPlacement="start"
          onChange={handleChangePaymentMethods}
          classes={{
            label: classes.label,
            root: classes.formControlLabel
          }}
        />
      </FormGroup>
      <div className="sm-space" />
      <Typography gutterBottom component="p" variant="body1" className={classes.bold}>
        Addresses:
      </Typography>
      <FormGroup className={classes.formGroup}>
        <FormControlLabel
          checked={views.invoices.addresses.create}
          control={<Switch color="primary" />}
          label={'create'}
          name="create"
          labelPlacement="start"
          onChange={handleChangeAddresses}
          classes={{
            label: classes.label,
            root: classes.formControlLabel
          }}
        />
        <FormControlLabel
          checked={views.invoices.addresses.update}
          control={<Switch color="primary" />}
          label={'update'}
          name="update"
          labelPlacement="start"
          onChange={handleChangeAddresses}
          classes={{
            label: classes.label,
            root: classes.formControlLabel
          }}
        />
        <FormControlLabel
          checked={views.invoices.addresses.delete}
          control={<Switch color="primary" />}
          label={'delete'}
          name="delete"
          labelPlacement="start"
          onChange={handleChangeAddresses}
          classes={{
            label: classes.label,
            root: classes.formControlLabel
          }}
        />
      </FormGroup>
      <div className="sm-space" />
      <Button variant="contained" color="primary" onClick={handleOpenDialog}>
        Submit
      </Button>
      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        scroll="paper"
        maxWidth="md"
        fullWidth
        aria-labelledby="plugin-configuration-dialog">
        <DialogTitle id="plugin-configuration-dialog">Plugin Configuration</DialogTitle>
        <DialogContent dividers>
          <code className={classes.fullWidth}>
            <pre className={classes.fullWidth}>{JSON.stringify(configuration, null, ' ')}</pre>
          </code>
          <textarea
            id="copy-plugin-config"
            defaultValue={'var config = ' + JSON.stringify(configuration, null, ' ')}
            className={classes.textarea}
          />
          <ClickAwayListener onClickAway={handleCloseTooltip}>
            <div className={classes.fitWidth}>
              <Tooltip
                arrow
                PopperProps={{
                  disablePortal: true
                }}
                onClose={handleCloseTooltip}
                open={tooltipOpen}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title="Copied!"
                placement="right">
                <Button onClick={handleCopyToClipboard} variant="contained">
                  copy config
                </Button>
              </Tooltip>
            </div>
          </ClickAwayListener>
          <div className="sm-space" />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default LightshopPlugin;
