import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormHelperText } from '@material-ui/core';

import GooglePlaceField from './GooglePlaceField';

function LocationField(props) {
  const { id, error, disabled, label, name, onChange, onSelect, required } = props;

  const [value, setValue] = useState('');
  const [location, setLocation] = useState({ lat: null, lng: null });

  function handleChange(val) {
    setValue(val);

    if (onChange) {
      onChange(val);
    }
  }

  function handleSelect(val, suggestion, geo) {
    setValue(suggestion.description);

    if (onChange) {
      onChange(suggestion.description);
    }

    setLocation({
      lat: geo.location.lat(),
      lng: geo.location.lng()
    });

    if (onSelect) {
      onSelect({
        lat: geo.location.lat(),
        lng: geo.location.lng()
      });
    }
  }

  return (
    <>
      <GooglePlaceField
        type="text"
        id={id}
        name={name}
        label={label}
        onChange={handleChange}
        onSelectAddress={handleSelect}
        disabled={disabled}
        value={value}
        required={required}
        autoComplete="off"
        margin="normal"
        error={error}
        isResultMapped
      />
      {location && location.lat && location.lng && (
        <FormHelperText>
          lat: {location.lat} / lng: {location.lng}{' '}
        </FormHelperText>
      )}
    </>
  );
}

LocationField.defaultProps = {
  disabled: false,
  required: false
};

LocationField.propTypes = {
  id: PropTypes.any,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  required: PropTypes.bool
};

export default LocationField;
