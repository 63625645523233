/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Fab from '@material-ui/core/Fab';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import FormSelectField from '../../../../components/forms/fields/FormSelectField';
import translation from '../../../../translation/translation';

const intervals = [
  { label: translation().metrics.dashboard.form.labels.none, value: '' },
  { label: translation().metrics.dashboard.form.labels.auto, value: 'auto' },
  { label: translation().metrics.dashboard.form.labels.hours, value: 'hour' },
  { label: translation().metrics.dashboard.form.labels.days, value: 'day' },
  { label: translation().metrics.dashboard.form.labels.weeks, value: 'week' },
  { label: translation().metrics.dashboard.form.labels.month, value: 'month' },
  { label: translation().metrics.dashboard.form.labels.years, value: 'year' }
];

const compares = [
  { label: translation().metrics.dashboard.form.labels.none, value: '' },
  {
    label: translation().metrics.dashboard.form.labels.period,
    value: 'period'
  },
  { label: translation().metrics.dashboard.form.labels.year_1, value: 'year' }
];

const queryOperators = [
  {
    operator: 'lk',
    label: (
      <Tooltip title={translation().filters.labels.like}>
        <div style={{ width: '100%' }}>&asymp;</div>
      </Tooltip>
    )
  },
  {
    operator: 'eq',
    label: (
      <Tooltip title={translation().filters.labels.equal}>
        <div style={{ width: '100%' }}>=</div>
      </Tooltip>
    )
  },
  {
    operator: 'gte',
    label: (
      <Tooltip title={translation().filters.labels.gte}>
        <div style={{ width: '100%' }}>&ge;</div>
      </Tooltip>
    )
  },
  {
    operator: 'gt',
    label: (
      <Tooltip title={translation().filters.labels.gt}>
        <div style={{ width: '100%' }}>&gt;</div>
      </Tooltip>
    )
  },
  {
    operator: 'lte',
    label: (
      <Tooltip title={translation().filters.labels.lte}>
        <div style={{ width: '100%' }}>&le;</div>
      </Tooltip>
    )
  },
  {
    operator: 'lt',
    label: (
      <Tooltip title={translation().filters.labels.lt}>
        <div style={{ width: '100%' }}>&lt;</div>
      </Tooltip>
    )
  },
  {
    operator: 'ne',
    label: (
      <Tooltip title={translation().filters.labels.ne}>
        <div style={{ width: '100%' }}>&ne;</div>
      </Tooltip>
    )
  },
  {
    operator: 'in',
    label: (
      <Tooltip title={translation().filters.labels.in}>
        <div style={{ width: '100%' }}>&#10687;</div>
      </Tooltip>
    )
  },
  {
    operator: 'nin',
    label: (
      <Tooltip title={translation().filters.labels.nin}>
        <div style={{ width: '100%' }}>&#10672;</div>
      </Tooltip>
    )
  },
  {
    operator: 'nu',
    label: (
      <Tooltip title={translation().filters.labels.null}>
        <div style={{ width: '100%' }}>null</div>
      </Tooltip>
    )
  },
  {
    operator: 'nnu',
    label: (
      <Tooltip title={translation().filters.labels.not_null}>
        <div style={{ width: '100%' }}>not null</div>
      </Tooltip>
    )
  }
];

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  autocomplete: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      minWidth: 300
    }
  },
  autocomplete2: {
    width: '100%',
    minWidth: 200
  },
  form: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center'
  },
  field: {
    display: 'flex',
    paddingLeft: 8,
    paddingRight: 8,
    alignItems: 'center',
    flexGrow: 1,
    minWidth: 200,
    minHeight: 72,
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  field_select: {
    position: 'relative',
    top: -4
  },
  fieldLoading: {
    display: 'flex',
    alignItems: 'flex-end',
    height: 45
  },
  criteriaTitle: {
    paddingLeft: 8,
    paddingRight: 8
  },
  criteriaWrapper: {
    marginTop: 24
  },
  metricsWrapper: {
    marginTop: 24
  },
  submitButton: {
    marginTop: 14,
    [theme.breakpoints.down(835)]: {
      marginTop: theme.spacing(2)
    }
  },
  submitWrapper: {
    marginTop: 12,
    paddingLeft: 8,
    paddingRight: 8,
    alignItems: 'center'
  }
}));

function CriteriaRow(props) {
  const { index, criterias, classes, deleteRow, disabled, onChange } = props;

  function handleChangeCriteriaParam(event) {
    onChange(index, 'param', event.target.value);
  }

  function handleChangeCriteriaOperator(operator) {
    onChange(index, 'operator', operator);
  }

  function handleChangeCriteriaValue(event) {
    onChange(index, 'value', event.target.value);
  }

  function handleDeleteRow() {
    deleteRow(index);
  }

  return (
    <div className={classes.form}>
      <div className={classes.field}>
        <TextField
          name={`new_metric_criteria_param_${index}`}
          label={translation().metrics.dashboard.form.criteria_param}
          onChange={handleChangeCriteriaParam}
          value={criterias[index]['param']}
          disabled={disabled}
          fullWidth
        />
      </div>
      <div className={classNames(classes.field, classes.field_select)}>
        <FormSelectField
          name={`new_metric_criteria_operator_${index}`}
          label={translation().metrics.dashboard.form.criteria_operator}
          onChange={handleChangeCriteriaOperator}
          value={criterias[index]['operator']}
          disabled={disabled}
          fullWidth>
          {queryOperators.map((choice, index) => (
            <MenuItem key={index} value={choice.operator}>
              {choice.label}
            </MenuItem>
          ))}
        </FormSelectField>
      </div>
      <div className={classes.field}>
        <TextField
          name={`new_metric_criteria_value_${index}`}
          label={translation().metrics.dashboard.form.criteria_value}
          onChange={handleChangeCriteriaValue}
          value={criterias[index]['value']}
          disabled={disabled}
          fullWidth
        />
      </div>
      <IconButton
        aria-label="delete"
        disabled={disabled}
        onClick={handleDeleteRow}
        size="small"
        style={{ top: 15 }}>
        <DeleteIcon fontSize="small" />
      </IconButton>
    </div>
  );
}

CriteriaRow.defaultProps = {
  disabled: false
};

CriteriaRow.propTypes = {
  classes: PropTypes.shape().isRequired,
  criterias: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  deleteRow: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
};

function MetricsForm(props) {
  const {
    disabled,
    getMetricsList,
    initialValues,
    metricsForm: { metrics },
    onSumbit,
    types
  } = props;

  const classes = useStyles();

  const [metric, setMetric] = useState(
    initialValues && initialValues.metric ? { route: initialValues.metric } : null
  );
  const [name, setName] = useState(initialValues && initialValues.name ? initialValues.name : '');
  const [segment, setSegment] = useState(
    initialValues && initialValues.segment ? initialValues.segment : null
  );
  const [segments, setSegments] = useState(
    initialValues && initialValues.metric && initialValues.metric.available_segments
      ? Object.keys(initialValues.metric.available_segments)
      : []
  );
  const [timeInterval, setTimeInterval] = useState(
    initialValues && initialValues.time_interval
      ? initialValues.time_interval
      : initialValues
      ? null
      : 'auto'
  );
  const [type, setType] = useState(
    initialValues && initialValues.chart_type ? initialValues.chart_type : 'line_count'
  );
  const [compare, setCompare] = useState(
    initialValues && initialValues.compare ? initialValues.compare : null
  );
  const [limit, setLimit] = useState(
    initialValues && initialValues.limit ? initialValues.limit : ''
  );
  const [criterias, setCriterias] = useState([]);

  useEffect(() => {
    getMetricsList();

    if (initialValues && initialValues.criteria) {
      /**
       * Get initial criterias values for build criterias form
       */
      let initialCriterias =
        initialValues && initialValues.criteria ? initialValues.criteria.split(',') : [];

      if (initialCriterias && initialCriterias.length) {
        const defaultCriterias = [];

        for (let i = 0; i < initialCriterias.length; i++) {
          const criteria = initialCriterias[i].split('|');

          if (criteria[0] && criteria[1] && criteria[2]) {
            defaultCriterias.push({
              param: criteria[0],
              operator: criteria[1],
              value: criteria[2]
            });
          }
        }

        setCriterias(defaultCriterias);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (metrics.data && metrics.data.length) {
      for (let i = 0; i < metrics.data.length; i++) {
        if (initialValues && initialValues.metric) {
          if (initialValues.metric === metrics.data[i].route) {
            setSegments(Object.keys(metrics.data[i].available_segments));
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metrics.data]);

  function handleSelectMetric(event, value) {
    if (value) {
      setSegments([]);
      setSegment(null);
      setMetric(value);
    } else {
      setMetric(null);
    }

    if (value && value.available_segments) {
      const segments = Object.keys(value.available_segments);
      setSegments(segments);
    } else {
      setSegment(null);
    }
  }

  function handleChangeName(event) {
    setName(event.target.value);
  }

  function handleChangeLimit(event) {
    setLimit(event.target.value);
  }

  function handleSelectSegment(event, value) {
    setSegment(value);
  }

  function handleSelectTimeInterval(value) {
    setTimeInterval(value);
  }

  function handleSelectCompare(value) {
    setCompare(value);
  }

  function handleSelectType(value) {
    setType(value);
  }

  function handleChangeCriteria(index, name, value) {
    let newCriterias = [...criterias];

    newCriterias[index][name] = value;
    setCriterias(newCriterias);
  }

  function handleCreateCriteriaRow() {
    setCriterias((criterias) => [...criterias, { param: '', operator: '', value: '' }]);
  }

  function handleDeleteCriteria(index) {
    const arrayAfterDelete = criterias.filter((el, i) => {
      return i !== index;
    });

    setCriterias(arrayAfterDelete);
  }

  function handleSubmit() {
    let criteria = '';

    if (criterias && criterias.length > 0) {
      for (let i = 0; i < criterias.length; i++) {
        if (criterias[i].param && criterias[i].operator && criterias[i].value) {
          criteria += `${criterias[i].param}|${criterias[i].operator}|${criterias[i].value}${
            criterias.length > 1 ? ',' : ''
          }`;
        }
      }
    }

    const metricObject = {
      name,
      criteria,
      segment,
      compare: compare ? compare : null,
      limit: limit ? limit : null,
      time_interval: timeInterval ? timeInterval : null,
      metric: metric.route,
      chart_type: type,
      factor: null
    };

    if (metric && metric.route) {
      onSumbit(
        metricObject,
        initialValues && initialValues.id ? initialValues.id : null,
        initialValues && initialValues.submitBuffer ? initialValues.submitBuffer : null
      );
    }
  }

  return (
    <div className="MetricsForm">
      <div className={classes.form}>
        <div className={classes.field}>
          <TextField
            name="new_metric_name"
            label={translation().metrics.dashboard.form.name}
            onChange={handleChangeName}
            value={name}
            required
            disabled={disabled}
            fullWidth
          />
        </div>
        <div className={classes.field}>
          <Autocomplete
            id="new-metrics-lists"
            options={metrics.data}
            value={metric}
            getOptionLabel={(option) => option.route}
            className={classes.autocomplete}
            getOptionSelected={(option) => option.route === metric.route}
            disabled={disabled}
            required
            onChange={handleSelectMetric}
            renderInput={(params) => (
              <TextField {...params} label={translation().metrics.dashboard.form.route} fullWidth />
            )}
          />
        </div>
        {metrics.isLoading && (
          <div className={classes.fieldLoading}>
            <CircularProgress size={18} />
          </div>
        )}
        <div className={classes.field}>
          <Autocomplete
            id="new-metrics-segment-lists"
            options={segments}
            value={segment}
            getOptionLabel={(option) => option}
            className={classes.autocomplete2}
            disabled={disabled}
            onChange={handleSelectSegment}
            renderInput={(params) => (
              <TextField
                {...params}
                label={translation().metrics.dashboard.form.segment}
                fullWidth
              />
            )}
          />
        </div>
        <div className={classNames(classes.field, classes.field_select)}>
          <FormSelectField
            name="new_metric_interval"
            onChange={handleSelectTimeInterval}
            value={timeInterval}
            label={translation().metrics.dashboard.form.interval}
            disabled={disabled}
            fullWidth>
            {intervals.map((interval, index) => (
              <MenuItem key={index} value={interval.value}>
                {interval.label}
              </MenuItem>
            ))}
          </FormSelectField>
        </div>
        <div className={classNames(classes.field, classes.field_select)}>
          <FormSelectField
            name="new_metric_compare"
            onChange={handleSelectCompare}
            value={compare}
            label={translation().metrics.dashboard.form.compare}
            disabled={disabled}
            fullWidth>
            {compares.map((compare, index) => (
              <MenuItem key={index} value={compare.value}>
                {compare.label}
              </MenuItem>
            ))}
          </FormSelectField>
        </div>
        <div className={classNames(classes.field, classes.field_select)}>
          <FormSelectField
            name="new_metric_type"
            onChange={handleSelectType}
            value={type}
            label={translation().metrics.dashboard.form.type}
            disabled={disabled}
            fullWidth>
            {types.map((type, index) => (
              <MenuItem key={index} value={type.value}>
                {type.label}
              </MenuItem>
            ))}
          </FormSelectField>
        </div>
        <div className={classes.field}>
          <TextField
            name="new_metric_limit"
            label={translation().metrics.dashboard.form.limit}
            onChange={handleChangeLimit}
            value={limit}
            disabled={disabled}
            fullWidth
          />
        </div>
      </div>
      <div className={classes.criteriaWrapper}>
        <Typography className={classes.criteriaTitle}>
          {translation().metrics.dashboard.form.criteria}
        </Typography>
        {criterias.map((criteria, index) => {
          return (
            <CriteriaRow
              key={index}
              index={index}
              classes={classes}
              deleteRow={handleDeleteCriteria}
              disabled={disabled}
              criterias={criterias}
              onChange={handleChangeCriteria}
            />
          );
        })}
        <Fab
          color="default"
          aria-label="add"
          size="small"
          disabled={disabled}
          onClick={handleCreateCriteriaRow}
          style={{ margin: 8 }}>
          <AddIcon fontSize="small" />
        </Fab>
      </div>
      <div className={classes.submitWrapper}>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          className={classes.submitButton}
          disabled={!metric || disabled || !name}>
          {translation().metrics.core.form.submit}
        </Button>
      </div>
    </div>
  );
}

MetricsForm.defaultProps = {
  disabled: false
};

MetricsForm.propTypes = {
  disabled: PropTypes.bool,
  getMetricsList: PropTypes.func.isRequired,
  initialValues: PropTypes.shape(),
  metricsForm: PropTypes.shape({
    metrics: PropTypes.shape().isRequired
  }).isRequired,
  onSumbit: PropTypes.func.isRequired,
  types: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

export default MetricsForm;
