import React, { useState } from 'react';
import PropTypes from 'prop-types';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

import FormSelectField from '../../../../components/forms/fields/FormSelectField';
import translation from '../../../../translation/translation';

function EditPaymentMethodButton(props) {
  const {
    currency,
    editOrderPaymentMethod,
    initialMethod,
    paymentMethods,
    getOrderClientPaymentMethods,
    onSuccess,
    orderId,
    user
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(null);

  function handleOpenDialog() {
    setIsOpen(true);
  }

  function handleCloseDialog() {
    setIsOpen(false);
    setValue(null);
  }

  function handleSelect(id) {
    setValue(id);
  }

  function handleDialogEnter() {
    if (user.id && currency) getOrderClientPaymentMethods(user.id, currency);
  }

  function handleSubmit() {
    if (!value) return;

    editOrderPaymentMethod(orderId, value, onSuccess);
  }

  return (
    <>
      <IconButton edge="end" size="small" aria-label="Add" onClick={handleOpenDialog}>
        <EditIcon fontSize="small" />
      </IconButton>
      <Dialog
        fullWidth
        open={isOpen ? true : false}
        onClose={handleCloseDialog}
        aria-labelledby="add-order-discount"
        aria-describedby="add-order-discount-description"
        disableBackdropClick={paymentMethods.isLoading || paymentMethods.edit_action.isLoading}
        onEnter={handleDialogEnter}>
        <DialogTitle id="add-order-discount">
          {translation().views.orders.update.payment_methods.dialog_title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="add-order-discount-description">
            {initialMethod ? (
              <Typography component="span" style={{ fontWeight: 500 }}>
                {translation().views.orders.update.payment_methods.dialog_desc}: {initialMethod}
              </Typography>
            ) : (
              translation().views.orders.update.payment_methods.no_method_in_order
            )}
          </DialogContentText>
          {paymentMethods && paymentMethods.data && paymentMethods.data.length > 0 ? (
            <FormSelectField
              displayEmpty
              fullWidth
              disabled={paymentMethods.isLoading || paymentMethods.edit_action.isLoading}
              onChange={handleSelect}
              id="order_client_payment_method"
              name="order_client_payment_method"
              label={translation().views.orders.update.payment_methods.label}
              value={value}
              required>
              {paymentMethods.data.map((method, index) => (
                <MenuItem key={index} value={method.id}>
                  {method.name}
                </MenuItem>
              ))}
            </FormSelectField>
          ) : (
            !paymentMethods.isLoading && (
              <Typography>{translation().views.orders.update.payment_methods.no_method}</Typography>
            )
          )}
          <div className="sm-space" />
          {paymentMethods.isLoading && (
            <div className="loader-wpr" style={{ marginTop: 15 }}>
              <CircularProgress size={30} color="secondary" />
              <p>{translation().views.orders.update.payment_methods.loading_text}</p>
            </div>
          )}
          {paymentMethods.edit_action.isLoading && (
            <div className="loader-wpr" style={{ marginTop: 15 }}>
              <CircularProgress size={30} color="secondary" />
              <p>{translation().views.orders.update.payment_methods.edit_loading}</p>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            color="default"
            disabled={paymentMethods.isLoading || paymentMethods.edit_action.isLoading}>
            {translation().actions.cancel}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={paymentMethods.edit_action.isLoading || !value}>
            {translation().actions.confirm}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

EditPaymentMethodButton.propTypes = {
  currency: PropTypes.shape().isRequired,
  editOrderPaymentMethod: PropTypes.func.isRequired,
  initialMethod: PropTypes.any,
  paymentMethods: PropTypes.shape().isRequired,
  getOrderClientPaymentMethods: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  orderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  user: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired
};

export default EditPaymentMethodButton;
