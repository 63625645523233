import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Typography
} from '@material-ui/core';
import { OpenInNewOutlined } from '@material-ui/icons';

import TaskActions from '../actions/TaskActions';
import InputBox from '../../forms/inputs/InputBox';
import InputPriority from '../../forms/inputs/InputPriority';
import InputAssignedUser from '../../forms/inputs/InputAssignedUser';
import InputAttachments from '../../forms/inputs/InputAttachments';
import { dateInTz } from '../../../../../../utils/functions/dates';
import translation from '../../../../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  archived: {
    backgroundColor: theme.palette.type === 'dark' ? '#212121' : '#f0f0f0',
    opacity: 0.36
  },
  textCell: {
    minWidth: 80,
    fontSize: 12
  }
}));

function TaskList(props) {
  const classes = useStyles();

  const columnsTranlated = {
    archived: translation().views.task_views.tasks.labels.archived,
    assigned_team: translation().views.task_views.tasks.labels.team,
    assigned_user: translation().views.task_views.tasks.labels.user_assigned,
    closed: translation().views.task_views.tasks.labels.closed,
    completion_status: translation().views.task_views.tasks.labels.completion_status,
    created: translation().views.task_views.tasks.labels.created,
    description: translation().views.task_views.tasks.labels.desc,
    due: translation().views.task_views.tasks.labels.due,
    label: translation().views.task_views.tasks.labels.label,
    order_product_option: translation().views.task_views.tasks.labels.order_product_option,
    order_product: translation().views.task_views.tasks.labels.order_product,
    order: translation().views.task_views.tasks.labels.order,
    priority: translation().views.task_views.tasks.labels.priority,
    topic: translation().views.task_views.tasks.labels.topic,
    started: translation().views.task_views.tasks.labels.started,
    updated: translation().views.task_views.tasks.labels.updated,
    user: translation().views.task_views.tasks.labels.user
  };

  const {
    children,
    completionStatuses,
    data,
    onAssignSelf,
    onCompleteTask,
    onDeleteTask,
    onOpenHistory,
    onScroll,
    teams,
    updateTaskProps,
    userLevel,
    userLogged,
    viewProps
  } = props;

  const columns = viewProps.fields && viewProps.fields.length ? viewProps.fields.split(',') : [];

  const write = userLevel && userLevel !== 'viewer' && userLevel !== 'operator';

  function handleInfiniteScroll(event) {
    onScroll(event);
  }

  function handleChangeColumn(id, value, onSuccess, onError) {
    if (viewProps.id && id) {
      updateTaskProps(viewProps.id, id, value, onSuccess, onError);
    }
  }

  function renderCells(data) {
    const cells = [];

    const dateColumns = ['archived', 'closed', 'created', 'due', 'started', 'updated'];

    for (let i = 0; i < columns.length; i++) {
      if (dateColumns.indexOf(columns[i]) > -1) {
        if (columns[i] === 'due') {
          cells.push(
            <TableCell key={i}>
              <InputBox
                id={data.id}
                name={columns[i]}
                value={data[columns[i]]}
                type="datetime-local"
                onChange={handleChangeColumn}
                write={write}
              />
            </TableCell>
          );
        } else {
          cells.push(
            <TableCell key={i}>
              <Typography className={classes.textCell}>
                {data[columns[i]] ? dateInTz(data[columns[i]], 'D MMM HH:mm') : '-'}
              </Typography>
            </TableCell>
          );
        }
      } else {
        if (columns[i] === 'order') {
          cells.push(
            <TableCell key={i} align="right">
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
                <InputBox
                  id={data.id}
                  targetKey="id"
                  referenceKey="order"
                  name="shop_order_id"
                  value={data[columns[i]] && data[columns[i]].id ? data[columns[i]].id : undefined}
                  type="number"
                  onChange={handleChangeColumn}
                  write={write}
                />
                {data[columns[i]] && data[columns[i]].id && (
                  <IconButton
                    size="small"
                    component="a"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`/orders/${data[columns[i]].id}/update`}>
                    <OpenInNewOutlined style={{ fontSize: 16 }} />
                  </IconButton>
                )}
              </Box>
            </TableCell>
          );
        } else if (columns[i] === 'order_product') {
          cells.push(
            <TableCell key={i} align="right">
              <InputBox
                id={data.id}
                targetKey="id"
                referenceKey="order_product"
                name="shop_order_product_id"
                value={data[columns[i]] && data[columns[i]].id ? data[columns[i]].id : undefined}
                type="number"
                onChange={handleChangeColumn}
                write={write}
              />
            </TableCell>
          );
        } else if (columns[i] === 'order_product_option') {
          cells.push(
            <TableCell key={i} align="right">
              <InputBox
                id={data.id}
                targetKey="id"
                referenceKey="order_product_option"
                name="shop_order_product_option_id"
                value={data[columns[i]] && data[columns[i]].id ? data[columns[i]].id : undefined}
                type="number"
                onChange={handleChangeColumn}
                write={write}
              />
            </TableCell>
          );
        } else if (columns[i] === 'assigned_team') {
          cells.push(
            <TableCell key={i}>
              <InputBox
                id={data.id}
                targetKey="id"
                name="assigned_iam_team_id"
                referenceKey="assigned_team"
                value={data[columns[i]]}
                type="select"
                getFullObject
                selectData={teams.data}
                loading={teams.loading}
                placeholder={translation().views.task_views.tasks.labels.team}
                onChange={handleChangeColumn}
                write={write}
              />
            </TableCell>
          );
        } else if (columns[i] === 'assigned_user') {
          cells.push(
            <TableCell key={i}>
              <InputAssignedUser
                index={i}
                id={data.id}
                data={data[columns[i]]}
                userLogged={userLogged}
                users={viewProps.users}
                onChange={handleChangeColumn}
                write={write}
              />
            </TableCell>
          );
        } else if (columns[i] === 'user') {
          cells.push(
            <TableCell key={i}>
              <Typography className={classes.textCell}>
                {data[columns[i]] && data[columns[i]].first_name && data[columns[i]].last_name
                  ? `${data[columns[i]].first_name} ${data[columns[i]].last_name}`
                  : '-'}
              </Typography>
            </TableCell>
          );
        } else if (columns[i] === 'completion_status') {
          cells.push(
            <TableCell key={i} style={{ padding: 0, position: 'relative', minWidth: 90 }}>
              <InputBox
                id={data.id}
                targetKey="id"
                name="completion_status_id"
                referenceKey="completion_status"
                value={data[columns[i]]}
                type="select"
                getFullObject
                selectData={completionStatuses.data}
                loading={completionStatuses.loading}
                placeholder={translation().views.task_views.tasks.labels.completion_status}
                onChange={handleChangeColumn}
                write={write || (userLevel && userLevel === 'operator')}
              />
            </TableCell>
          );
        } else if (columns[i] === 'priority') {
          cells.push(
            <TableCell key={i}>
              <InputPriority
                id={data.id}
                name="priority"
                value={data[columns[i]]}
                onChange={handleChangeColumn}
                variant="flag"
                write={write}
              />
            </TableCell>
          );
        } else if (columns[i] === 'description') {
          cells.push(
            <TableCell key={i}>
              <InputBox
                id={data.id}
                name={columns[i]}
                value={data[columns[i]]}
                type="textarea"
                onChange={handleChangeColumn}
                write={write}
              />
            </TableCell>
          );
        } else if (columns[i] !== 'id' && columns[i] !== 'attachments') {
          cells.push(
            <TableCell key={i}>
              <InputBox
                id={data.id}
                name={columns[i]}
                value={data[columns[i]]}
                type="text"
                onChange={handleChangeColumn}
                write={write}
              />
            </TableCell>
          );
        }
      }
    }
    return cells;
  }

  return (
    <Paper className={classes.root}>
      {data && data.length > 0 ? (
        <TableContainer onScroll={handleInfiniteScroll} className={classes.root}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.length > 0
                  ? columns.map(
                      (column) =>
                        column !== 'id' && (
                          <TableCell key={column}>{columnsTranlated[column]}</TableCell>
                        )
                    )
                  : false}
                <TableCell />
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((task, index) => (
                <TableRow key={index} className={classNames({ [classes.archived]: task.archived })}>
                  {renderCells(task)}
                  <TableCell>
                    <InputAttachments
                      taskId={task.id}
                      viewId={viewProps?.id}
                      attachments={task?.attachments}
                      disabled={!write}
                    />
                  </TableCell>
                  <TableCell key={`task-actions-${index}`} align="right">
                    <TaskActions
                      id={task.id}
                      task={task}
                      onAssignSelf={onAssignSelf}
                      onComplete={onCompleteTask}
                      onDelete={onDeleteTask}
                      onOpenHistory={onOpenHistory}
                      userLevel={userLevel}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {children}
        </TableContainer>
      ) : (
        false
      )}
    </Paper>
  );
}

TaskList.propTypes = {
  children: PropTypes.node,
  completionStatuses: PropTypes.shape({
    loading: PropTypes.bool,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        color: PropTypes.string,
        id: PropTypes.number,
        name: PropTypes.string
      })
    )
  }).isRequired,
  teams: PropTypes.shape({
    loading: PropTypes.bool,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string
      })
    )
  }).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      archived: PropTypes.string,
      assigned_team: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string
      }),
      assigned_user: PropTypes.shape({
        id: PropTypes.number,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        email: PropTypes.string
      }),
      closed: PropTypes.string,
      completion_status: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        shortcode: PropTypes.string,
        is_closed: PropTypes.bool,
        color: PropTypes.string
      }),
      created: PropTypes.string,
      description: PropTypes.string,
      due: PropTypes.string,
      id: PropTypes.number,
      label: PropTypes.string,
      order: PropTypes.shape(),
      order_product: PropTypes.shape(),
      order_product_option: PropTypes.shape(),
      priority: PropTypes.number,
      started: PropTypes.string,
      updated: PropTypes.string,
      user: PropTypes.shape()
    })
  ),
  onAssignSelf: PropTypes.func.isRequired,
  onCompleteTask: PropTypes.func.isRequired,
  onDeleteTask: PropTypes.func.isRequired,
  onOpenHistory: PropTypes.func.isRequired,
  onScroll: PropTypes.func.isRequired,
  updateTaskProps: PropTypes.func.isRequired,
  userLevel: PropTypes.oneOf(['admin', 'manager', 'editor', 'operator', 'viewer']),
  userLogged: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  viewProps: PropTypes.shape({
    id: PropTypes.number,
    fields: PropTypes.string,
    users: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        level: PropTypes.oneOf(['admin', 'manager', 'editor', 'operator', 'viewer']),
        user: PropTypes.shape({
          id: PropTypes.number,
          first_name: PropTypes.string,
          last_name: PropTypes.string,
          email: PropTypes.string
        })
      })
    )
  })
};

export default TaskList;
