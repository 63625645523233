import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PhoneInput from 'react-phone-number-input';
import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import {
  AccountBalance as AccountBalanceIcon,
  Apartment as ApartmentIcon,
  Business as BusinessIcon,
  BusinessCenter as BusinessCenterIcon,
  Close as CloseIcon,
  Commute as CommuteIcon,
  DevicesOther as DevicesOtherIcon,
  Done as DoneIcon,
  Edit as EditIcon,
  Email as EmailIcon,
  Euro as EuroIcon,
  EventAvailable as EventAvailableIcon,
  EventBusy as EventBusyIcon,
  EventNote as EventNoteIcon,
  Flag as FlagIcon,
  HowToReg as HowToRegIcon,
  Launch as LaunchIcon,
  Map as MapIcon,
  OpenInNew as OpenInNewIcon,
  Person as PersonIcon,
  Room as RoomIcon,
  Security as SecurityIcon,
  SettingsBackupRestore,
  Stars as StarsIcon,
  SupervisedUserCircle as SupervisedUserCircleIcon,
  Wc as WcIcon
} from '@material-ui/icons';

import Alert from '../../../../../components/alert/Alert';
import UserPositionsContainer from '../../../../../containers/core/customListActions/UserPositionsContainer';
import { dateInTz } from '../../../../../utils/functions/dates';
import translation from '../../../../../translation/translation';
import config from '../../../../../config';
import Devices from './Devices';

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: '#FFFFFF',
    color: 'rgba(0, 0, 0, 0.87)'
  },
  averageRating: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  averageText: {
    paddingTop: 2,
    fontSize: 10
  },
  cardHeader: {
    minHeight: 60,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.cardHeader
  },
  cardContent: {
    padding: theme.spacing(1),
    '&:last-child': {
      paddingBottom: 0
    }
  },
  paper: {
    minHeight: 630,
    overflow: 'auto',
    width: '100%',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      minHeight: 'auto'
    }
  },
  paperLarge: {
    height: 480,
    minHeight: 'auto'
  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2)
  },
  listItemBox: {
    minWidth: 35
  },
  listItem: {
    padding: 4
  },
  listRoot: {
    padding: 0
  },
  cardText: {
    padding: theme.spacing(0.5, 1)
  },
  divider: {
    marginTop: 4,
    marginBottom: 4
  },
  empty: {
    margin: 8,
    fontSize: 12
  },
  mapContainer: {
    padding: '0!important',
    height: 'calc(100% - 60px - 35px)'
  },
  map: {
    height: '100%'
  }
}));

function ProviderSummary(props) {
  const classes = useStyles();
  const { cleanProviderViewDevicesReducer, devices, getProviderViewDevices, provider } = props;

  function handleBlockChange() {
    return false;
  }

  const userAvatarUrl =
    provider && provider.user && provider.user.picture_url
      ? `${provider.user.picture_url}?client_id=${config.api_key}`
      : null;
  const companyAvatarUrl =
    provider && provider.company && provider.company.logo_url
      ? `${provider.company.logo_url}?client_id=${config.api_key}`
      : null;

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          {provider.user && (
            <Card className={classes.paper}>
              <CardHeader
                className={classes.cardHeader}
                title={translation().views.provider_view.infos.info_user}
                titleTypographyProps={{
                  variant: 'h5',
                  style: {
                    fontSize: 21
                  }
                }}
                avatar={
                  userAvatarUrl ? (
                    <Avatar
                      className={classes.avatar}
                      aria-label="profile_picture"
                      src={userAvatarUrl}
                      alt={provider.user.first_name.charAt(0).toUpperCase()}
                    />
                  ) : (
                    <Avatar>{provider.user.first_name.charAt(0).toUpperCase()}</Avatar>
                  )
                }
                action={
                  <IconButton
                    aria-label="Edit user"
                    href={`/users/${provider.user.id}/update`}
                    target="_blank">
                    <EditIcon fontSize="small" />
                  </IconButton>
                }
              />
              <CardContent className={classes.cardContent}>
                <List>
                  <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemBox}>
                      <PersonIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={translation().views.provider_view.infos.first_name}
                      secondary={provider.user.first_name}
                    />
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemBox}>
                      <PersonIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={translation().views.provider_view.infos.last_name}
                      secondary={provider.user.last_name}
                    />
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemBox}>
                      <EventNoteIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={translation().views.provider_view.infos.date_of_birth}
                      secondary={dateInTz(provider.user.date_of_birth, 'localized-date')}
                    />
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemBox}>
                      <WcIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={translation().views.provider_view.infos.gender}
                      secondary={provider.user.gender}
                    />
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemBox}>
                      <EmailIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={translation().views.provider_view.infos.email}
                      secondary={
                        <Link href={`mailto:${provider.user.email}`}>{provider.user.email}</Link>
                      }
                    />
                  </ListItem>
                  {provider.user.phone && (
                    <ListItem className={classes.listItem}>
                      <ListItemIcon className={classes.listItemBox}>
                        <PhoneInput
                          value={provider.user.phone}
                          disabled
                          onChange={handleBlockChange}
                          className="provider-view-phone-input"
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={translation().views.provider_view.infos.phone}
                        secondary={
                          <Link href={`tel: ${provider.user.phone}`}>{provider.user.phone}</Link>
                        }
                      />
                    </ListItem>
                  )}
                  {provider.user.mobile && (
                    <ListItem className={classes.listItem}>
                      <ListItemIcon className={classes.listItemBox}>
                        <PhoneInput
                          value={provider.user.mobile}
                          disabled
                          onChange={handleBlockChange}
                          className="provider-view-phone-input"
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={translation().views.provider_view.infos.mobile}
                        secondary={
                          <Link href={`tel: ${provider.user.mobile}`}>{provider.user.mobile}</Link>
                        }
                      />
                    </ListItem>
                  )}
                </List>
              </CardContent>
            </Card>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Card className={classes.paper}>
            <CardHeader
              className={classes.cardHeader}
              title={
                translation().views.provider_view.infos.info_provider +
                (provider.ghost ? ' (Ghost)' : '')
              }
              titleTypographyProps={{
                variant: 'h5',
                style: {
                  fontSize: 21
                }
              }}
              avatar={
                <Avatar>
                  <BusinessCenterIcon />
                </Avatar>
              }
              action={
                <IconButton
                  aria-label="Edit user"
                  href={`/providers/${provider.id}/update`}
                  target="_blank">
                  <EditIcon fontSize="small" />
                </IconButton>
              }
            />
            <CardContent className={classes.cardContent}>
              <List>
                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.listItemBox}>
                    {provider.terms_accepted ? <EventAvailableIcon /> : <EventBusyIcon />}
                  </ListItemIcon>
                  <ListItemText
                    primary={translation().views.provider_view.infos.cgs_date}
                    secondary={
                      provider.terms_accepted
                        ? dateInTz(provider.terms_accepted, 'localized-datetime')
                        : 'Terms not accepted yet'
                    }
                  />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.listItemBox}>
                    <HowToRegIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={translation().views.provider_view.infos.is_active}
                    secondary={
                      provider.active ? (
                        <DoneIcon
                          style={{ color: config.success_color || '#4CAF50' }}
                          fontSize="small"
                        />
                      ) : (
                        <CloseIcon
                          style={{ color: config.error_color || '#F44336' }}
                          fontSize="small"
                        />
                      )
                    }
                  />
                </ListItem>
                {provider.social_regime && (
                  <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemBox}>
                      <SupervisedUserCircleIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={translation().views.provider_view.infos.social}
                      secondary={provider.social_regime}
                    />
                  </ListItem>
                )}
                {provider.transportation && provider.transportation.shortcode && (
                  <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemBox}>
                      <CommuteIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={translation().views.provider_view.infos.transport}
                      secondary={provider.transportation.shortcode}
                    />
                  </ListItem>
                )}
                {(provider.late_cancel_reversion_percentage !== null ||
                  provider.late_cancel_reversion_percentage !== undefined) && (
                  <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemBox}>
                      <SettingsBackupRestore />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        translation().views.provider_view.infos.late_cancel_reversion_percentage
                      }
                      secondary={`${provider.late_cancel_reversion_percentage}%`}
                    />
                  </ListItem>
                )}
                {provider.tax_rule.name && (
                  <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemBox}>
                      <EuroIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={translation().views.provider_view.infos.tax_type}
                      secondary={provider.tax_rule.name}
                    />
                  </ListItem>
                )}
                {provider.base_latitude && provider.base_longitude && (
                  <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemBox}>
                      <MapIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={translation().views.provider_view.infos.lat_lng}
                      secondary={`${provider.base_latitude} , ${provider.base_longitude}`}
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="launch map"
                        href={`https://www.google.com/maps/search/?api=1&query=${provider.base_latitude},${provider.base_longitude}`}
                        target="_blank"
                        size="small">
                        <LaunchIcon fontSize="small" />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                )}
                {provider.average_rating && (
                  <>
                    <Divider className={classes.divider} />
                    <ListItem className={classes.listItem}>
                      <ListItemIcon className={classes.listItemBox}>
                        <StarsIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={translation().views.provider_view.infos.average_rating}
                        disableTypography
                        secondary={
                          <div className={classes.averageRating}>
                            <Rating
                              value={provider.average_rating}
                              precision={0.5}
                              readOnly
                              size="small"
                            />
                            <Typography
                              component="span"
                              variant="body2"
                              className={classes.averageText}>
                              ({provider.average_rating})
                            </Typography>
                          </div>
                        }
                      />
                    </ListItem>
                  </>
                )}
                {provider.bio && (
                  <>
                    <Divider className={classes.divider} />
                    <div className={classes.cardText}>
                      <Typography variant="subtitle1">
                        {translation().views.provider_view.infos.bio}
                      </Typography>
                      <Typography
                        className="MuiTypography-colorTextSecondary"
                        variant="body2"
                        component="span">
                        {provider.bio}
                      </Typography>
                    </div>
                  </>
                )}
              </List>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          {provider.company ? (
            <Card className={classes.paper}>
              <CardHeader
                className={classes.cardHeader}
                title={translation().views.provider_view.infos.info_company}
                titleTypographyProps={{
                  variant: 'h5',
                  style: {
                    fontSize: 21
                  }
                }}
                avatar={
                  companyAvatarUrl ? (
                    <Avatar
                      className={classes.avatar}
                      aria-label="profile_picture"
                      src={companyAvatarUrl}
                      alt={provider.company.commercial_name.charAt(0).toUpperCase()}
                    />
                  ) : (
                    <Avatar>
                      <ApartmentIcon />
                    </Avatar>
                  )
                }
                action={
                  <IconButton
                    aria-label="Edit user"
                    href={`/companies/${provider.company.id}/update`}
                    target="_blank">
                    <EditIcon fontSize="small" />
                  </IconButton>
                }
              />
              <CardContent className={classes.cardContent}>
                <List>
                  <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemBox}>
                      <SecurityIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={translation().views.provider_view.infos.is_admin}
                      secondary={
                        provider.user.company_admin ? (
                          <DoneIcon
                            style={{ color: config.success_color || '#4CAF50' }}
                            fontSize="small"
                          />
                        ) : (
                          <CloseIcon
                            style={{ color: config.error_color || '#F44336' }}
                            fontSize="small"
                          />
                        )
                      }
                    />
                  </ListItem>
                  {provider.company.commercial_name && (
                    <>
                      <ListItem className={classes.listItem}>
                        <ListItemIcon className={classes.listItemBox}>
                          <BusinessIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={translation().views.provider_view.infos.commercial_name}
                          secondary={provider.company.commercial_name}
                        />
                      </ListItem>
                    </>
                  )}
                  {provider.company.legal_name && (
                    <>
                      <ListItem className={classes.listItem}>
                        <ListItemIcon className={classes.listItemBox}>
                          <BusinessIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={translation().views.provider_view.infos.legal_name}
                          secondary={provider.company.legal_name}
                        />
                      </ListItem>
                    </>
                  )}
                  {provider.company.vat_number && (
                    <>
                      <ListItem className={classes.listItem}>
                        <ListItemIcon className={classes.listItemBox}>
                          <AccountBalanceIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={translation().views.provider_view.infos.vat_number}
                          secondary={provider.company.vat_number}
                        />
                      </ListItem>
                    </>
                  )}
                  <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemBox}>
                      <FlagIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={translation().views.provider_view.infos.flag}
                      secondary={
                        provider.company.flag ? (
                          <DoneIcon
                            style={{ color: config.success_color || '#4CAF50' }}
                            fontSize="small"
                          />
                        ) : (
                          <CloseIcon
                            style={{ color: config.error_color || '#F44336' }}
                            fontSize="small"
                          />
                        )
                      }
                    />
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          ) : (
            <Card className={classes.paper}>
              <CardHeader
                className={classes.cardHeader}
                title={translation().views.provider_view.infos.info_company}
                titleTypographyProps={{
                  variant: 'h5',
                  style: {
                    fontSize: 21
                  }
                }}
                avatar={
                  <Avatar>
                    <ApartmentIcon />
                  </Avatar>
                }
              />
              <CardContent className={classes.cardContent}>
                <Alert
                  type="info"
                  style={{ marginTop: 10 }}
                  status={translation().commons.alerts.info}
                  text={translation().views.provider_view.infos.no_company}
                />
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          {provider.user && (
            <Card className={classNames(classes.paper, classes.paperLarge)}>
              <CardHeader
                className={classes.cardHeader}
                title={translation().views.provider_view.position.label}
                titleTypographyProps={{
                  variant: 'h5',
                  style: {
                    fontSize: 21
                  }
                }}
                avatar={
                  <Avatar>
                    <RoomIcon />
                  </Avatar>
                }
              />
              <CardContent className={classes.mapContainer}>
                <UserPositionsContainer className={classes.map} resource={provider} isProvider />
              </CardContent>
            </Card>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          {provider && (
            <Card className={classNames(classes.paper, classes.paperLarge)}>
              <CardHeader
                className={classes.cardHeader}
                title={translation().views.provider_view.devices.label}
                titleTypographyProps={{
                  variant: 'h5',
                  style: {
                    fontSize: 21
                  }
                }}
                avatar={
                  <Avatar>
                    <DevicesOtherIcon />
                  </Avatar>
                }
                action={
                  <IconButton
                    aria-label="See all devices"
                    href={`/users/${provider.user.id}/devices`}
                    target="_blank">
                    <OpenInNewIcon fontSize="small" />
                  </IconButton>
                }
              />
              <CardContent className={classes.cardContent}>
                <Devices
                  userId={provider.user.id}
                  devices={devices}
                  getProviderViewDevices={getProviderViewDevices}
                  cleanProviderViewDevicesReducer={cleanProviderViewDevicesReducer}
                />
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

ProviderSummary.propTypes = {
  cleanProviderViewDevicesReducer: PropTypes.func.isRequired,
  getProviderViewDevices: PropTypes.func.isRequired,
  provider: PropTypes.shape().isRequired,
  devices: PropTypes.shape({
    isLoading: PropTypes.bool
  }).isRequired
};

export default ProviderSummary;
