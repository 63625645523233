import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import UpdateViewCollection from '../../../../core/update/components/collections/UpdateViewCollection';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2)
  }
}));

function Products(props) {
  const classes = useStyles();

  const { providerId } = props;

  return (
    <div className={classNames(classes.root, 'ProviderProducts')}>
      <UpdateViewCollection
        columns={[
          { name: 'id' },
          { name: 'product.name' },
          { name: 'boost_factor' },
          { name: 'radius' },
          { name: 'reversion_hourly_rate' },
          { name: 'trip_retention' },
          { name: 'trip_compensation' },
          { name: 'moderate' }
        ]}
        createButtonStyle={{
          marginTop: 0,
          marginBottom: 12
        }}
        configButtonStyle={{
          marginTop: 0,
          marginBottom: 12
        }}
        links={[
          {
            rel: 'metadata',
            href: `providers/${providerId}/products/metadata`
          },
          { rel: 'list', href: `providers/${providerId}/products` }
        ]}
        tabIndex={0}
        tabValue={0}
        subtype="ProviderProduct"
        routeUri="providers"
        resourceId={providerId}
        PaperProps={{
          elevation: 0,
          square: true
        }}
      />
    </div>
  );
}

Products.propTypes = {
  providerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export default Products;
