import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getUser, getUserSuccess, getUserError } from '../redux/actions/user/userActions';
import {
  authentication,
  authenticationSuccess,
  authenticationError,
  clearReducers,
  removeSnackbar,
  setThemeNightMode
} from '../redux/actions/appActions';
import { fetchApi } from '../utils/functions/api';
import { initDateLocale } from '../utils/functions/dates';
import {
  setAccessToken,
  setLocale,
  setResidenceCountry,
  setTimezone,
  removeAccessToken,
  getLocale
} from '../utils/functions/cookies';
import App from '../App';

const mapStateToProps = (store) => ({ reduxStore: store });

const mapDispatchToProps = (dispatch) => {
  const _signOut = () => {
    dispatch(clearReducers());
    removeAccessToken();
  };

  return {
    setThemeNightMode(val) {
      dispatch(setThemeNightMode(val));
    },
    removeSnackbar() {
      dispatch(removeSnackbar());
    },
    storeAccessToken(token, expiration, callbackFunction) {
      if (!token) return;

      setAccessToken(token, expiration);

      if (callbackFunction && typeof callbackFunction === 'function') callbackFunction();
    },
    authentication() {
      dispatch(authentication());
    },
    authenticationSuccess() {
      dispatch(authenticationSuccess());
    },
    authenticationError(error = null) {
      dispatch(authenticationError(error));
    },
    signOut() {
      _signOut();
    },
    async getUser() {
      dispatch(getUser());

      try {
        await fetchApi(
          'get',
          'users/self',
          null,
          null,
          null,
          (user) => {
            /**
             * Build cookies
             */
            if (user.residence_country && user.residence_country.isocode)
              setResidenceCountry(user.residence_country.isocode);

            if (user.language && user.language.locale) {
              setLocale(user.language.locale);
              initDateLocale(user.language.locale);
            } else {
              initDateLocale(getLocale());
            }

            if (user.timezone_code) {
              setTimezone(user.timezone_code);
            }

            dispatch(getUserSuccess(user));
          },
          (error) => {
            let errorMessage = 'An error occurred, please retry later.';

            if (error.response) {
              if (error.response.status) {
                if (error.response.status === 403) errorMessage = 'Forbidden access.';
                else if (error.response.status === 401) {
                  _signOut();
                  errorMessage = 'Unauthorized.';
                } else errorMessage = 'An error occurred, please retry later.';
              }
            } else if (error.request)
              errorMessage = 'The server does not respond, please retry later.';

            dispatch(getUserError(errorMessage));
          }
        );
      } catch (error) {
        dispatch(getUserError(error));
      }
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
