const initialState = {
  data: null,
  error: null,
  isLoading: false,
  invoices: {
    data: [],
    isLoading: false,
    paging: null,
    error: null
  },
  invoices_emitted: {
    data: [],
    isLoading: false,
    paging: null,
    error: null
  },
  payment_methods: {
    data: [],
    isLoading: false,
    paging: null,
    error: null
  },
  addresses: {
    data: [],
    isLoading: false,
    paging: null,
    error: null
  },
  devices: {
    data: [],
    isLoading: false,
    paging: null,
    error: null
  },
  orders: {
    data: [],
    metadata: {
      data: {},
      isLoading: false,
      isError: null
    },
    isLoading: false,
    paging: null,
    error: null
  },
  ratings: {
    data: [],
    isLoading: false,
    paging: null,
    error: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_REDUCERS':
      return initialState;
    case 'CLEAN_USER_VIEW_INVOICES_REDUCER':
      return {
        ...state,
        invoices: initialState.invoices
      };
    case 'CLEAN_USER_VIEW_ORDERS_REDUCER':
      return {
        ...state,
        orders: initialState.orders
      };
    case 'CLEAN_USER_VIEW_INVOICES_EMITTED_REDUCER':
      return {
        ...state,
        invoices_emitted: initialState.invoices_emitted
      };
    case 'CLEAN_USER_VIEW_PAYMENT_METHOD_REDUCER':
      return {
        ...state,
        payment_methods: initialState.payment_methods
      };
    case 'CLEAN_USER_VIEW_DEVICES_REDUCER':
      return {
        ...state,
        devices: initialState.devices
      };
    case 'CLEAN_USER_VIEW_ADDRESSES_REDUCER':
      return {
        ...state,
        addresses: initialState.addresses
      };
    case 'CLEAN_USER_VIEW_RATINGS_REDUCER':
      return {
        ...state,
        ratings: initialState.ratings
      };
    case 'GET_USER_VIEW':
      return {
        ...initialState,
        isLoading: true
      };
    case 'GET_USER_VIEW_SUCCESS':
      return {
        ...state,
        data: action.data,
        error: null,
        isLoading: false
      };
    case 'GET_USER_VIEW_ERROR':
      return {
        ...state,
        error: action.error,
        isLoading: false
      };
    case 'GET_USER_VIEW_INVOICES':
      return {
        ...state,
        invoices: {
          ...state.invoices,
          data: action.next ? state.invoices.data : initialState.invoices.data,
          isLoading: true
        }
      };
    case 'GET_USER_VIEW_INVOICES_SUCCESS': {
      const invoicesConcatenated = state.invoices.data.concat(action.data);

      return {
        ...state,
        invoices: {
          ...state.invoices,
          data: invoicesConcatenated,
          paging: action.paging ? action.paging : state.invoices.paging,
          isLoading: false,
          error: null
        }
      };
    }
    case 'GET_USER_VIEW_INVOICES_ERROR':
      return {
        ...state,
        invoices: {
          ...state.invoices,
          error: action.error,
          isLoading: false
        }
      };
    case 'GET_USER_VIEW_ADDRESSES':
      return {
        ...state,
        addresses: {
          ...state.addresses,
          data: action.next ? state.addresses.data : initialState.addresses.data,
          isLoading: true
        }
      };
    case 'GET_USER_VIEW_ADDRESSES_SUCCESS': {
      const addressesConcatenated = state.addresses.data.concat(action.data);

      return {
        ...state,
        addresses: {
          ...state.addresses,
          data: addressesConcatenated,
          paging: action.paging ? action.paging : state.addresses.paging,
          isLoading: false,
          error: null
        }
      };
    }
    case 'GET_USER_VIEW_ADDRESSES_ERROR':
      return {
        ...state,
        addresses: {
          ...state.addresses,
          error: action.error,
          isLoading: false
        }
      };
    case 'GET_USER_VIEW_INVOICES_EMITTED':
      return {
        ...state,
        invoices_emitted: {
          ...state.invoices_emitted,
          data: action.next ? state.invoices_emitted.data : initialState.invoices_emitted.data,
          isLoading: true
        }
      };
    case 'GET_USER_VIEW_INVOICES_EMITTED_SUCCESS': {
      const invoicesEmittedConcatenated = state.invoices_emitted.data.concat(action.data);

      return {
        ...state,
        invoices_emitted: {
          ...state.invoices_emitted,
          data: invoicesEmittedConcatenated,
          paging: action.paging ? action.paging : state.invoices_emitted.paging,
          isLoading: false,
          error: null
        }
      };
    }
    case 'GET_USER_VIEW_INVOICES_EMITTED_ERROR':
      return {
        ...state,
        invoices_emitted: {
          ...state.invoices_emitted,
          error: action.error,
          isLoading: false
        }
      };
    case 'GET_USER_VIEW_ORDERS':
      return {
        ...state,
        orders: {
          ...state.orders,
          data: action.next ? state.orders.data : initialState.orders.data,
          isLoading: true
        }
      };
    case 'GET_USER_VIEW_ORDERS_SUCCESS': {
      const ordersConcatenated = state.orders.data.concat(action.data);

      return {
        ...state,
        orders: {
          ...state.orders,
          data: ordersConcatenated,
          paging: action.paging ? action.paging : state.orders.paging,
          isLoading: false,
          error: null
        }
      };
    }
    case 'GET_USER_VIEW_ORDERS_ERROR':
      return {
        ...state,
        orders: {
          ...state.orders,
          error: action.error,
          isLoading: false
        }
      };
    case 'GET_USER_VIEW_ORDERS_METADATA':
      return {
        ...state,
        orders: {
          ...state.orders,
          metadata: {
            ...initialState.orders.metadata,
            isLoading: true,
            isError: false
          }
        }
      };
    case 'GET_USER_VIEW_ORDERS_METADATA_SUCCESS':
      return {
        ...state,
        orders: {
          ...state.orders,
          metadata: {
            data: action.data,
            isLoading: false,
            isError: false
          }
        }
      };
    case 'GET_USER_VIEW_ORDERS_METADATA_ERROR':
      return {
        ...state,
        orders: {
          ...state.orders,
          metadata: {
            ...state.orders.metadata.data,
            isLoading: false,
            isError: true
          }
        }
      };
    case 'GET_USER_VIEW_PAYMENT_METHODS':
      return {
        ...state,
        payment_methods: {
          ...state.payment_methods,
          data: action.next ? state.payment_methods.data : initialState.payment_methods.data,
          isLoading: true
        }
      };
    case 'GET_USER_VIEW_PAYMENT_METHODS_SUCCESS': {
      const paymentMethodsConcatenated = state.payment_methods.data.concat(action.data);

      return {
        ...state,
        payment_methods: {
          ...state.payment_methods,
          data: paymentMethodsConcatenated,
          paging: action.paging ? action.paging : state.payment_methods.paging,
          isLoading: false,
          error: null
        }
      };
    }
    case 'GET_USER_VIEW_PAYMENT_METHODS_ERROR':
      return {
        ...state,
        payment_methods: {
          ...state.payment_methods,
          error: action.error,
          isLoading: false
        }
      };
    case 'GET_USER_VIEW_DEVICES':
      return {
        ...state,
        devices: {
          ...state.devices,
          data: action.next ? state.devices.data : initialState.devices.data,
          isLoading: true
        }
      };
    case 'GET_USER_VIEW_DEVICES_SUCCESS': {
      const devicesContactened = state.devices.data.concat(action.data);

      return {
        ...state,
        devices: {
          ...state.devices,
          data: devicesContactened,
          paging: action.paging ? action.paging : state.devices.paging,
          isLoading: false,
          error: null
        }
      };
    }
    case 'GET_USER_VIEW_DEVICES_ERROR':
      return {
        ...state,
        devices: {
          ...state.devices,
          error: action.error,
          isLoading: false
        }
      };
    case 'GET_USER_VIEW_RATINGS':
      return {
        ...state,
        ratings: {
          ...state.ratings,
          data: action.next ? state.ratings.data : initialState.ratings.data,
          isLoading: true
        }
      };
    case 'GET_USER_VIEW_RATINGS_SUCCESS': {
      const ratingsContactened = state.ratings.data.concat(action.data);

      return {
        ...state,
        ratings: {
          ...state.ratings,
          data: ratingsContactened,
          paging: action.paging ? action.paging : state.ratings.paging,
          isLoading: false,
          error: null
        }
      };
    }
    case 'GET_USER_VIEW_RATINGS_ERROR':
      return {
        ...state,
        ratings: {
          ...state.ratings,
          error: action.error,
          isLoading: false
        }
      };
    default:
      return state;
  }
};
