import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';

import { pad } from '../../../../../../../../utils/functions/utils';
import GooglePlaceField from '../../../../../../../../components/forms/fields/customs/GooglePlaceField';
import FormSelectField from '../../../../../../../../components/forms/fields/FormSelectField';
import translation from '../../../../../../../../translation/translation';

function renderOptions(name, minHour = 7, maxHour = 21) {
  const options = [
    <MenuItem key="displayEmpty" value="">
      -
    </MenuItem>
  ];

  for (let i = minHour; i < maxHour + 1; i++) {
    options.push(
      <MenuItem key={i + (name ? name : Math.random())} value={pad(i)}>
        {pad(i)}
      </MenuItem>
    );
  }

  return options;
}

function EditUnavailabilityForm(props) {
  const {
    editAction: { loading },
    initialValues,
    maxHour,
    minHour,
    onSubmit
  } = props;

  const [address, setAddress] = useState('');
  const [isAddressFailed, setIsAddressFailed] = useState(false);
  const [values, setValues] = useState({
    hour_start: '',
    min_start: '',
    hour_end: '',
    min_end: '',
    latitude: '',
    longitude: '',
    location: ''
  });

  useEffect(() => {
    if (initialValues) {
      setValues(initialValues);

      if (initialValues.location) {
        setAddress(initialValues.location);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (name) => (value) => {
    setValues({ ...values, [name]: value });
  };

  function handleSubmitForm(event) {
    if (event) {
      event.preventDefault();
    }
    if (onSubmit) {
      onSubmit(values);
    }
  }

  function handleChangeAddress(value) {
    setAddress(value);
    setValues({ ...values, location: '' });
  }

  function handleSelectAddress(value, suggestion, geometry) {
    if (!value || !suggestion.description) {
      setIsAddressFailed(true);
      setAddress('');
      setValues({
        ...values,
        latitude: '',
        longitude: '',
        location: ''
      });
    } else {
      setIsAddressFailed(false);
      setAddress(suggestion.description);
      setValues({
        ...values,
        latitude: geometry.location.lat(),
        longitude: geometry.location.lng(),
        location: suggestion.description
      });
    }
  }

  return (
    <form onSubmit={handleSubmitForm} autoComplete="off">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormSelectField
            name="hour_start"
            fullWidth
            value={values.hour_start}
            onChange={handleChange('hour_start')}
            disabled={loading}
            label="Heure de début"
            required>
            {renderOptions('_hour_start', minHour, maxHour)}
          </FormSelectField>
        </Grid>
        <Grid item xs={6}>
          <FormSelectField
            name="min_start"
            fullWidth
            value={values.min_start}
            onChange={handleChange('min_start')}
            label="Minutes"
            disabled={loading}
            required>
            <MenuItem value="">-</MenuItem>
            <MenuItem value="00">00</MenuItem>
            <MenuItem value="15">15</MenuItem>
            <MenuItem value="30">30</MenuItem>
            <MenuItem value="45">45</MenuItem>
          </FormSelectField>
        </Grid>
        <Grid item xs={6}>
          <FormSelectField
            name="hour_end"
            fullWidth
            value={values.hour_end}
            onChange={handleChange('hour_end')}
            disabled={loading}
            label="Heure de fin"
            required>
            {renderOptions('_hour_end', minHour, maxHour)}
          </FormSelectField>
        </Grid>
        <Grid item xs={6}>
          <FormSelectField
            name="min_end"
            fullWidth
            value={values.min_end}
            onChange={handleChange('min_end')}
            label="Minutes"
            disabled={loading}
            required>
            <MenuItem value="">-</MenuItem>
            <MenuItem value="00">00</MenuItem>
            <MenuItem value="15">15</MenuItem>
            <MenuItem value="30">30</MenuItem>
            <MenuItem value="45">45</MenuItem>
          </FormSelectField>
        </Grid>
        <Grid item xs={12}>
          <GooglePlaceField
            type="text"
            name="address"
            label={translation().views.orders.create.informations.address}
            isResultMapped
            onChange={handleChangeAddress}
            onSelectAddress={handleSelectAddress}
            value={address}
            required
            disabled={loading}
            autoComplete="off"
            error={
              isAddressFailed ? translation().views.orders.create.informations.error_property : ''
            }
          />
        </Grid>
      </Grid>
      <div className="submit-wrapper">
        <Button
          color="primary"
          className="btn-unavailability"
          disabled={
            loading ||
            !values.hour_end ||
            !values.hour_start ||
            !values.min_end ||
            !values.min_start ||
            !values.location
          }
          style={{ minWidth: 150 }}
          type="submit"
          variant="contained">
          {loading ? (
            <CircularProgress size={20} color="secondary" />
          ) : (
            <span>{translation().views.provider_schedule.unavailability.edit.buttons.submit}</span>
          )}
        </Button>
      </div>
    </form>
  );
}

EditUnavailabilityForm.propTypes = {
  initialValues: PropTypes.shape(),
  editAction: PropTypes.shape({
    loading: PropTypes.bool,
    error: PropTypes.any
  }).isRequired,
  maxHour: PropTypes.number.isRequired,
  minHour: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default EditUnavailabilityForm;
