const initialState = {
  metrics: {
    data: [],
    error: null,
    isLoading: false
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_REDUCERS':
      return initialState;
    case 'GET_METRICS_LIST':
      return {
        ...state,
        metrics: {
          ...initialState.metrics,
          isLoading: true
        }
      };
    case 'GET_METRICS_LIST_SUCCESS':
      return {
        ...state,
        metrics: {
          data: action.data,
          error: null,
          isLoading: false
        }
      };
    case 'GET_METRICS_LIST_ERROR':
      return {
        ...state,
        metrics: {
          ...state.metrics,
          isLoading: false,
          error: action.error
        }
      };
    default:
      return state;
  }
};
