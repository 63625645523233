import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  LinearProgress,
  Typography
} from '@material-ui/core';

import TaskCard from './components/display/card/TaskCard';
import TaskList from './components/display/list/TaskList';
import TaskHistory from './components/display/history/TaskHistory';
import CreateTaskForm from './components/forms/create/CreateTaskForm';
import TaskViewBar from './components/viewBar/TaskViewBar';
import translation from '../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  content: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    height: `calc(100% - 64px - 18px - 4px)`,
    '&.bar-expanded': {
      height: `calc(100% - 128px - 18px - 4px)`
    }
  },
  cards: {
    height: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
    flexWrap: 'wrap',
    overflow: 'auto',
    alignContent: 'baseline'
  },
  createBtn: {
    marginTop: theme.spacing(2),
    color: 'white',
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: fade(theme.palette.success.main, 0.55)
    }
  },
  empty: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  linearTransparent: {
    height: 4
  }
}));

function Tasks(props) {
  const classes = useStyles();
  const params = useParams();
  const history = useHistory();

  const {
    clearFilters,
    clearTasks,
    clearTaskView,
    createTask,
    deleteTask,
    getCompletionStatuses,
    getTasks,
    getTaskView,
    getTasksViewTeams,
    filterTasks,
    linkUserToTaskView,
    removeUserTaskView,
    tasks: { completion_statuses, create, data, loading, metadata, paging, teams, update },
    selected,
    setTaskViewProp,
    updateTaskViewProps,
    updateTaskProps,
    updateUserTaskViewLevel,
    user
  } = props;

  const { id } = params;

  const [barExpanded, setBarExpanded] = useState(false);
  const [search, setSearch] = useState('');
  const [formOpen, setFormOpen] = useState('');
  const [historyOpen, setHistoryOpen] = useState(null);
  const isFormOpen = Boolean(formOpen);

  useEffect(() => {
    document.title = 'Actarus | Tasks';

    getCompletionStatuses();
    getTasksViewTeams();

    getTaskView(id, false, (view) => {
      getTasks(id);
      document.title = view && view && view.name ? `Actarus | ${view.name}` : 'Actarus | Tasks';
    });

    return () => {
      clearFilters();
      clearTasks();
      clearTaskView();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleToggleExpand() {
    setBarExpanded((state) => !state);
  }

  function handleOpenCreateForm() {
    setFormOpen('create');
  }

  function handleCloseForm() {
    setFormOpen('');
  }

  function handleLoadNext() {
    if (!loading && paging && paging.next) {
      getTasks(id, paging.next);
    }
  }

  function handleScrollCards(e) {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      handleLoadNext();
    }
  }

  function handleScrollList(event) {
    if (!event) console.error('No table scroll event found.');

    if (!event.currentTarget.scrollTop || event.currentTarget.scrollTop === 0) return;

    if (
      event.currentTarget.scrollHeight - event.currentTarget.clientHeight <=
      event.currentTarget.scrollTop
    ) {
      handleLoadNext();
    }
  }

  function handleSearch(e) {
    getTasks(id, null, false, search);
    e.preventDefault();
  }

  function handleChangeSearch(e) {
    setSearch(e.target.value);
  }

  function handleClearFilters() {
    clearFilters();
    updateTaskViewProps(id, { filters: '' }, handleRefreshView);
  }

  function handleChangeFields(fields) {
    updateTaskViewProps(id, { fields }, handleRefreshView);
  }

  function handleFilter() {
    const filters = filterTasks(metadata.fields || []);

    if (!filters) return;

    setTaskViewProp('filters', filters);
    updateTaskViewProps(id, { filters }, handleRefreshView);
  }

  function handleRefreshTasks() {
    setSearch('');
    getTasks(id, null, true);
  }

  function handleRefreshView() {
    getTaskView(id, true, () => {
      getTasks(id, null, true);
    });
  }

  function handleChangeViewType(type) {
    updateTaskViewProps(id, { type }, () => {
      setTaskViewProp('type', type);
      handleRefreshView();
    });
  }

  function handleChangeSort(sort) {
    updateTaskViewProps(id, { sort }, () => {
      setTaskViewProp('sort', sort);
      handleRefreshView();
    });
  }

  function handleOpenHistory(tid) {
    if (!id) return;

    setHistoryOpen(tid);
  }

  function handleCloseHistory() {
    setHistoryOpen(null);
  }

  function handleCompleteTask(tid, onSuccess, onError) {
    if (!tid) return;

    updateTaskProps(
      id,
      tid,
      { completion_status_id: 3 },
      () => {
        if (onSuccess) {
          onSuccess();
        }
        handleRefreshTasks();
      },
      onError
    );
  }

  function handleDeleteTask(tid, onSuccess, onError) {
    if (!tid) return;

    deleteTask(
      id,
      tid,
      () => {
        if (onSuccess) {
          onSuccess();
        }
        handleRefreshTasks();
      },
      onError
    );
  }

  function handleAssignSelfTask(tid, onSuccess, onError) {
    if (!user || !user.id || !tid) return;

    updateTaskProps(
      id,
      tid,
      { assigned_iam_user_id: user.id },
      () => {
        if (onSuccess) {
          onSuccess();
        }
        handleRefreshTasks();
      },
      onError
    );
  }

  function handleLinkUser(tvid, user, onSuccess, onError) {
    linkUserToTaskView(
      tvid,
      user,
      () => {
        if (onSuccess) {
          onSuccess();
        }

        handleRefreshView();
      },
      onError
    );
  }

  function handleUpdateUserTaskViewLevel(tvid, uid, level, onSuccess, onError) {
    updateUserTaskViewLevel(tvid, uid, level, onSuccess, onError);
  }

  function handleRemoveUserTaskView(tvid, uid, onSuccess, onError) {
    removeUserTaskView(
      tvid,
      uid,
      () => {
        if (onSuccess) {
          onSuccess();
        }

        handleRefreshView();
      },
      onError
    );
  }

  function handleCreate(values) {
    createTask(id, values, () => {
      handleCloseForm();
      handleRefreshTasks();
    });
  }

  function getUserView() {
    if (selected.data && selected.data.users && selected.data.users.length && user) {
      for (let i = 0; i < selected.data.users.length; i++) {
        if (selected.data.users[i].user.id === user.id) {
          return selected.data.users[i];
        }
      }
    }

    return null;
  }

  function getUserLevel() {
    const user = getUserView();
    return user && user.level ? user.level : null;
  }

  function renderCards(view, data) {
    return (
      <div className={classes.cards} onScroll={handleScrollCards}>
        {data.map((task, index) => (
          <TaskCard
            {...task}
            viewProps={view}
            key={index}
            completionStatuses={completion_statuses}
            teams={teams}
            onAssignSelf={handleAssignSelfTask}
            onCompleteTask={handleCompleteTask}
            onDeleteTask={handleDeleteTask}
            onOpenHistory={handleOpenHistory}
            updateTaskProps={updateTaskProps}
            userLogged={user}
            userLevel={getUserLevel()}
          />
        ))}
        {!loading && paging && paging.next && data && data.length > 0 ? (
          <div className="loader-wpr">
            <Button variant="contained" onClick={handleLoadNext}>
              {translation().core.list.charge_next}
            </Button>
          </div>
        ) : (
          false
        )}
      </div>
    );
  }

  function renderList(view, data) {
    return (
      <TaskList
        completionStatuses={completion_statuses}
        data={data}
        teams={teams}
        viewProps={view}
        onScroll={handleScrollList}
        onAssignSelf={handleAssignSelfTask}
        onCompleteTask={handleCompleteTask}
        onDeleteTask={handleDeleteTask}
        onOpenHistory={handleOpenHistory}
        updateTaskProps={updateTaskProps}
        userLogged={user}
        userLevel={getUserLevel()}>
        {!loading && paging && paging.next && data && data.length > 0 ? (
          <div className="loader-wpr">
            <Button variant="contained" onClick={handleLoadNext}>
              {translation().core.list.charge_next}
            </Button>
          </div>
        ) : (
          false
        )}
      </TaskList>
    );
  }

  function renderView(view, data) {
    if (view.fields && view.fields.length) {
      switch (view.type) {
        case 'list':
          return renderList(view, data);
        case 'card':
          return renderCards(view, data);
        default:
          return renderList(view, data);
      }
    } else {
      return (
        <Box>
          <Typography align="center" color="textSecondary" variant="body2">
            {translation().views.task_views.tasks.fields.empty}
          </Typography>
        </Box>
      );
    }
  }

  return (
    <div className={classes.root}>
      <TaskViewBar
        expanded={barExpanded}
        history={history}
        loading={loading || selected.loading || update.loading}
        onChangeFields={handleChangeFields}
        onChangeSearch={handleChangeSearch}
        onChangeSort={handleChangeSort}
        onChangeViewType={handleChangeViewType}
        onClearFilters={handleClearFilters}
        onClickCreate={handleOpenCreateForm}
        onExpand={handleToggleExpand}
        onFilter={handleFilter}
        onLinkuser={handleLinkUser}
        onRefresh={handleRefreshTasks}
        onRemoveUserTaskView={handleRemoveUserTaskView}
        onSearch={handleSearch}
        onUpdateUserTaskViewLevel={handleUpdateUserTaskViewLevel}
        search={search}
        userView={getUserView()}
        viewMetadata={metadata}
        viewProps={selected.data}
      />
      {loading && paging && paging.next ? (
        <LinearProgress color="primary" />
      ) : (
        <div className={classes.linearTransparent} />
      )}
      <div className={classNames(classes.content, { 'bar-expanded': barExpanded })}>
        {data && data.length > 0 && selected && selected.data ? (
          renderView(selected.data, data)
        ) : !selected.loading && !loading ? (
          <div className={classes.empty}>
            <Typography color="textSecondary" variant="body2">
              {translation().views.task_views.tasks.empty}
            </Typography>
            <Button
              onClick={handleOpenCreateForm}
              className={classes.createBtn}
              disabled={loading}
              variant="contained">
              {translation().actions.create}
            </Button>
          </div>
        ) : (
          false
        )}
        {selected.loading && (
          <div className="loader-wpr">
            <CircularProgress color="primary" />
            <p>{translation().views.task_views.tasks.loading_view}</p>
          </div>
        )}
        {loading && !paging && (
          <div className="loader-wpr">
            <CircularProgress color="primary" />
            <p>{translation().views.task_views.tasks.loading_text}</p>
          </div>
        )}
        {!loading && paging && !paging.next && paging.prev && (
          <Box p={1}>
            <Typography color="textSecondary" align="center" variant="body2">
              {translation().core.list.load_next_done}
            </Typography>
          </Box>
        )}
        {!loading && !paging && data && data.length > 0 ? (
          <Box p={1}>
            <Typography color="textSecondary" align="center" variant="body2">
              {translation().core.list.load_next_done}
            </Typography>
          </Box>
        ) : (
          false
        )}
      </div>
      <Drawer
        anchor="right"
        open={isFormOpen}
        onClose={handleCloseForm}
        disableBackdropClick={create.loading}>
        <CreateTaskForm
          loading={create.loading}
          mode={formOpen}
          onClose={handleCloseForm}
          onSubmit={handleCreate}
          tvid={id}
          teams={teams}
          userLogged={user}
          users={selected && selected.data && selected.data.users ? selected.data.users : []}
        />
      </Drawer>
      {historyOpen && <TaskHistory id={historyOpen} onClose={handleCloseHistory} />}
    </div>
  );
}

Tasks.propTypes = {
  clearFilters: PropTypes.func.isRequired,
  clearTasks: PropTypes.func.isRequired,
  clearTaskView: PropTypes.func.isRequired,
  createTask: PropTypes.func.isRequired,
  deleteTask: PropTypes.func.isRequired,
  getCompletionStatuses: PropTypes.func.isRequired,
  getTasks: PropTypes.func.isRequired,
  getTaskView: PropTypes.func.isRequired,
  getTasksViewTeams: PropTypes.func.isRequired,
  filterTasks: PropTypes.func.isRequired,
  linkUserToTaskView: PropTypes.func.isRequired,
  removeUserTaskView: PropTypes.func.isRequired,
  tasks: PropTypes.shape({
    completion_statuses: PropTypes.shape({
      loading: PropTypes.bool,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          color: PropTypes.string,
          id: PropTypes.number,
          name: PropTypes.string
        })
      )
    }).isRequired,
    teams: PropTypes.shape({
      loading: PropTypes.bool,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string
        })
      )
    }).isRequired,
    data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    loading: PropTypes.bool,
    metadata: PropTypes.shape({
      fields: PropTypes.arrayOf(PropTypes.shape()),
      name: PropTypes.string
    }),
    paging: PropTypes.shape(),
    create: PropTypes.shape({
      loading: PropTypes.bool
    }),
    update: PropTypes.shape({
      loading: PropTypes.bool
    })
  }).isRequired,
  selected: PropTypes.shape({
    loading: PropTypes.bool,
    data: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      filters: PropTypes.string,
      sort: PropTypes.string,
      fields: PropTypes.string,
      users: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          level: PropTypes.oneOf(['admin', 'manager', 'editor', 'operator', 'viewer']),
          user: PropTypes.shape({
            id: PropTypes.number,
            first_name: PropTypes.string,
            last_name: PropTypes.string,
            email: PropTypes.string
          })
        })
      )
    })
  }),
  setTaskViewProp: PropTypes.func.isRequired,
  updateTaskViewProps: PropTypes.func.isRequired,
  updateTaskProps: PropTypes.func.isRequired,
  updateUserTaskViewLevel: PropTypes.func.isRequired,
  user: PropTypes.shape().isRequired
};

export default Tasks;
