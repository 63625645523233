import './settings.scss';

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';
import Paper from '@material-ui/core/Paper';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';

import { setThemeNightMode } from '../../redux/actions/appActions';
import {
  setNightTheme,
  getNightTheme,
  setUserTableStringWidth,
  getUserTableStringWidth,
  setUserTableLinksDisplayMode,
  getUserTableLinksDisplayMode,
  setUserSearchBarPosition,
  getUserSearchBarPosition
} from '../../utils/functions/cookies';
import store from '../../redux/store';
import translation from '../../translation/translation';

const useStyles = makeStyles((theme) => ({
  reinitSlider: {
    cursor: 'pointer'
  },
  pageTitle: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  root: {
    ...theme.mixins.gutters(),
    paddingBottom: theme.spacing(2),
    overflowX: 'hidden'
  },
  slider: {
    padding: '10px 0px'
  },
  sliderLabel: {
    marginBottom: 15
  },
  sliderWrapper: {
    marginTop: 30
  },
  switchWrapper: {
    marginTop: 50
  }
}));

function Settings() {
  useEffect(() => {
    document.title = 'Actarus | Local settings';
  }, []);

  const [state, setState] = useState({
    isDarkMode: getNightTheme() === true || getNightTheme() === 'true' ? true : false,
    listLinksMode: getUserTableLinksDisplayMode(),
    userTableWitdth: getUserTableStringWidth() || 150,
    searchBarPosition: getUserSearchBarPosition()
  });

  function handleToggleTheme(event) {
    setState({ ...state, isDarkMode: event.target.checked });
    store.dispatch(setThemeNightMode(event.target.checked));
    setNightTheme(event.target.checked);
  }

  function handleChangeUserTableWidth(event, value) {
    setState({ ...state, userTableWitdth: value });
    setUserTableStringWidth(Math.round(value));
  }

  function handleChangeSearchBarPosition(event) {
    setState({ ...state, searchBarPosition: event.target.value });
    setUserSearchBarPosition(event.target.value);
  }

  function handleReinitSlider() {
    setState({ ...state, userTableWitdth: 150 });
    setUserTableStringWidth(150);
  }

  function handleChangeListMode(event) {
    setState({ ...state, listLinksMode: event.target.value });
    setUserTableLinksDisplayMode(event.target.value);
  }

  const classes = useStyles();

  return (
    <div className="Settings">
      <Container>
        <div className={classes.pageTitle}>
          <Typography gutterBottom component="h2" variant="h2">
            {translation().views.settings.page_title}
          </Typography>
          <Typography gutterBottom component="p" variant="body1">
            {translation().views.settings.page_baseline}
          </Typography>
        </div>
        <Paper className={classes.root} elevation={1}>
          <div className={classes.switchWrapper}>
            <FormLabel component="legend">
              {translation().views.settings.form.labels.dark_theme}
            </FormLabel>
            <FormGroup>
              <FormControlLabel
                style={{ maxWidth: 110 }}
                control={
                  <Switch
                    checked={state.isDarkMode}
                    onChange={handleToggleTheme}
                    value="isDarkMode"
                  />
                }
                label={
                  state.isDarkMode
                    ? translation().views.settings.form.labels.on
                    : translation().views.settings.form.labels.off
                }
              />
            </FormGroup>
          </div>
          <div className={classes.sliderWrapper}>
            <FormLabel component="legend">
              {translation().views.settings.form.labels.list_links_mode}
            </FormLabel>
            <RadioGroup
              row
              aria-label="listLinksMode"
              name="listLinksMode"
              value={state.listLinksMode}
              onChange={handleChangeListMode}>
              <FormControlLabel
                value="speedDial"
                control={<Radio />}
                label={translation().views.settings.form.labels.speedDial}
              />
              <FormControlLabel
                value="select"
                control={<Radio />}
                label={translation().views.settings.form.labels.menuList}
              />
            </RadioGroup>
          </div>
          <div className={classes.sliderWrapper}>
            <FormLabel component="legend">
              {translation().views.settings.form.labels.search_bar_position}
            </FormLabel>
            <RadioGroup
              row
              aria-label="searchBarPosition"
              name="searchBarPosition"
              value={state.searchBarPosition}
              onChange={handleChangeSearchBarPosition}>
              <FormControlLabel
                value="left"
                control={<Radio />}
                label={translation().views.settings.form.labels.left}
              />
              <FormControlLabel
                value="right"
                control={<Radio />}
                label={translation().views.settings.form.labels.right}
              />
            </RadioGroup>
          </div>
          <div className={classes.sliderWrapper}>
            <FormLabel component="legend" id="userTableWitdth" className={classes.sliderLabel}>
              {translation().views.settings.form.labels.table_cell_size}:{' '}
              <span style={{ fontWeight: 500 }}>{state.userTableWitdth}</span>
            </FormLabel>
            <FormGroup style={{ maxWidth: 400 }}>
              <Slider
                classes={{ root: classes.slider }}
                value={parseInt(state.userTableWitdth, 10)}
                aria-labelledby="userTableWitdth"
                onChange={handleChangeUserTableWidth}
                min={50}
                max={700}
                step={1}
              />
              <FormHelperText
                className={classes.reinitSlider}
                onClick={handleReinitSlider}
                style={{ width: 80 }}>
                {translation().views.settings.form.buttons.reinit}
              </FormHelperText>
            </FormGroup>
          </div>
        </Paper>
      </Container>
    </div>
  );
}

export default Settings;
