export const getPreviousDayMetadata = () => ({
  type: 'GET_PREVIOUS_DAY_METADATA'
});

export const getPreviousDayMetadataSuccess = (data) => ({
  type: 'GET_PREVIOUS_DAY_METADATA_SUCCESS',
  data
});

export const getPreviousDayMetadataError = (isError) => ({
  type: 'GET_PREVIOUS_DAY_METADATA_ERROR',
  isError
});
