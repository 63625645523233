import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import Alert from '../../../../../components/alert/Alert';
import ProviderScheduleContainer from '../../../../../containers/core/customListActions/ProviderScheduleContainer';
import translation from '../../../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  linksContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    minHeight: 50,
    marginBottom: 8
  },
  linkWrapper: {
    margin: theme.spacing(0, 1),
    '&:first-child': {
      marginRight: 24
    }
  },
  linkLoader: {
    display: 'flex',
    alignItems: 'center',
    padding: 0
  },
  loaderText: {
    marginLeft: 8
  },
  hashContainer: {
    paddingTop: theme.spacing(1)
  },
  hash: {
    fontWeight: 500,
    display: 'inline-block'
  }
}));

function ProviderSchedule(props) {
  const { getProviderHash, match } = props;

  const classes = useStyles();
  const [isHashLoading, setHashLoading] = useState(false);
  const [hash, setHash] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    /**
     * Get the provider hash
     */
    if (match && match.params && match.params.id) {
      loadHash(match.params.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function loadHash(providerId) {
    setHashLoading(true);

    getProviderHash(
      providerId,
      (success) => {
        setHashLoading(false);

        if (success.hash) {
          setHash(success.hash);
        } else {
          setHash(null);
          setError(translation().views.provider_view.provider_hash.callbacks.empty);
        }
      },
      () => {
        setHashLoading(false);
        setError(translation().views.provider_view.provider_hash.callbacks.error);
      }
    );
  }

  return (
    <div className="ProviderViewSchedule">
      <Container disableGutters className={classes.hashContainer}>
        {error ? (
          <Alert
            type="danger"
            style={{ marginTop: 10 }}
            status={translation().commons.alerts.error}
            text={error}
          />
        ) : (
          <div className={classes.linksContainer}>
            <div className={classes.linkWrapper}>
              {isHashLoading && (
                <div className={classNames('loader-wpr', classes.linkLoader)}>
                  <CircularProgress color="primary" size={25} />
                  <p className={classes.loaderText}>
                    {translation().views.provider_view.provider_hash.load_hash}
                  </p>
                </div>
              )}
              {hash && (
                <div>
                  <Typography component="span" variant="body2">
                    {translation().views.provider_view.provider_hash.label}:{' '}
                  </Typography>
                  <Typography variant="body2" className={classes.hash}>
                    {hash}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        )}
      </Container>
      <ProviderScheduleContainer match={match} embed />
    </div>
  );
}

ProviderSchedule.propTypes = {
  getProviderHash: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  }).isRequired
};

export default ProviderSchedule;
