import './listView.scss';

import axios from 'axios';
import React, { PureComponent, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { emphasize, makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Slide from '@material-ui/core/Slide';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import Alert from '../../../components/alert/Alert';
import FiltersContainer from '../../../containers/filters/FiltersContainer';
import FixedHeaderTable from '../../../components/tables/fixedHeaderTable/FixedHeaderTable';
import ListEmptyIcon from '../../../components/icons/ListEmptyIcon';
import ListViewBar from './components/ListViewBar';
import ListViewSpeedDial from './components/ListViewSpeedDial';
import ListViewMobileMenu from './components/ListViewMobileMenu';
import {
  getUserTableStringWidth,
  getUserTableLinksDisplayMode
} from '../../../utils/functions/cookies';
import { dateInTz, createDateUtc, alterDate } from '../../../utils/functions/dates';
import { addThreeDots, isObjEmpty } from '../../../utils/functions/utils';
import config from '../../../config';
import translation from '../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  filterAppBar: {
    position: 'relative'
  },
  filterCloseBtn: {
    marginLeft: 'auto'
  },
  dialogRoot: {
    padding: 15
  },
  deleteButton: {
    color: config.error_color || '#F44336',
    '&:hover': {
      backgroundColor: fade(config.error_color || '#F44336', 0.15)
    }
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  speedDialAction: {
    zIndex: 1060,
    marginTop: 0,
    marginBottom: 0,
    margin: 8,
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
      backgroundColor: emphasize(theme.palette.background.paper, 0.15)
    }
  },
  menuItem: {
    paddingTop: 8,
    paddingBottom: 8
  },
  labelImportant: {
    color:
      theme.palette.type === 'dark'
        ? '#cb6059'
        : `${config.error_color}!important` || '#F44336!important'
  },
  iconMenuItem: {
    fontSize: 18,
    marginRight: 8
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class CustomLink extends PureComponent {
  render() {
    return (
      <Link to={this.props.id} {...this.props}>
        {this.props.children}
      </Link>
    );
  }
}

CustomLink.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.any
};

class CustomHref extends PureComponent {
  render() {
    return <a {...this.props}>{this.props.children}</a>;
  }
}

CustomHref.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired
};

class CustomDialogButton extends PureComponent {
  handleClick = () => {
    const {
      customprops: { data, onOpenCustomActionDialog, route }
    } = this.props;

    onOpenCustomActionDialog(route, data);
  };

  render() {
    return (
      <div {...this.props} onClick={this.handleClick}>
        {this.props.children}
      </div>
    );
  }
}

CustomDialogButton.propTypes = {
  children: PropTypes.any.isRequired,
  customprops: PropTypes.shape().isRequired
};

const createCancelToken = () => axios.CancelToken.source();

const GetDataApi = (getData, url, needLoad) => {
  const [source] = useState(createCancelToken());

  const doRefresh = () => {
    getData(url, source, null, null, true);
  };

  useEffect(() => {
    if (needLoad) {
      getData(url, source);
    }

    return () => {
      source.cancel('=> Core data changed.');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { doRefresh };
};

const GetMetadataApi = (getMetadata, url, needLoad) => {
  const [source] = useState(createCancelToken());

  useEffect(() => {
    if (needLoad) {
      getMetadata(url, source);
    }

    return () => {
      source.cancel('=> Core metadata changed.');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const SearchDataApi = (getData, url) => {
  const [source] = useState(createCancelToken());

  const doSearch = (search) => {
    if (search) {
      getData(url, source, search);
    }
  };

  useEffect(() => {
    return () => {
      source.cancel('=> Next: Cancelled.');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { doSearch };
};

const GetNextDataApi = (getData, url) => {
  const [source] = useState(createCancelToken());

  const doNext = (initialNext) => {
    if (initialNext) {
      getData(url, source, null, initialNext);
    }
  };

  useEffect(() => {
    return () => {
      source.cancel('=> Next: Cancelled.');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { doNext };
};

function getRouteUri(route, match) {
  if (match && match.url) return match.url.substring(1);
  else return route;
}

function lockListTableOverflow() {
  const overflowsTable = document.getElementsByClassName('x-scrollbar');

  if (overflowsTable[0]) {
    overflowsTable[0].style.overflow = 'hidden';
  }
}

function unlockListTableOverflow() {
  const overflowsTable = document.getElementsByClassName('x-scrollbar');

  if (overflowsTable[0]) {
    overflowsTable[0].style.overflow = 'auto';
  }
}

function ListView(props) {
  const {
    actions,
    clearFilters,
    clearOwnReducer,
    columns,
    customCreateContainer,
    deleteItem,
    deleteResourceItem,
    exportData,
    filterData,
    filters: { queryString },
    getData,
    getMetadata,
    history,
    isCreatable,
    isDeletable,
    isDuplicable,
    isExportable,
    isFilterable,
    isUpdatable,
    isRouteFlagged,
    list: {
      data,
      isError,
      isLoading,
      name,
      needReload,
      paging,
      search: { value },
      exportList
    },
    match,
    metadata,
    metadataUri,
    parentUri,
    routeUri,
    setDataName,
    setDataSearchValue,
    sub_routes,
    unsetListNeedReload
  } = props;

  const CustomCreateContainer =
    customCreateContainer && customCreateContainer.container
      ? customCreateContainer.container
      : null;
  const needLoad = name !== getRouteUri(routeUri, match) ? true : false;
  const needDataLoad = needLoad || (!value && (needReload || isLoading));
  const needMetaLoad = needLoad || isLoading || isObjEmpty(metadata.data);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [isCustomCreateContainerOpen, setCustomCreateContainerOpen] = useState(false);
  const [customDialog, setCustomDialog] = useState({
    opened: false,
    data: null
  });
  const [submitBuffer, setSubmitBuffer] = useState(0);
  const classes = useStyles();

  useEffect(() => {
    document.title = 'Actarus | ' + getRouteUri(routeUri, match);

    if (needLoad || needDataLoad) {
      clearFilters();
      clearOwnReducer();
    } else if (value && !isLoading) {
      doSearch(value);
    }

    setDataName(getRouteUri(routeUri, match));

    return () => {
      unsetListNeedReload();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  GetMetadataApi(getMetadata, metadataUri, needMetaLoad);

  const { doRefresh } = GetDataApi(getData, getRouteUri(routeUri, match), needDataLoad);
  const { doSearch } = SearchDataApi(getData, getRouteUri(routeUri, match));
  const { doNext } = GetNextDataApi(getData, getRouteUri(routeUri, match));

  function handleScrollList() {
    if (!isLoading && paging && paging.next) {
      doNext(paging.next);
    }
  }

  function handleSubmit(val) {
    if (val) {
      clearFilters();
      doSearch(val);
      setDataSearchValue(val);
    } else {
      handleRefresh();
    }
  }

  function handleRefresh() {
    doRefresh();
    clearFilters();
  }

  function handleRefreshAfterAction() {
    if (value) {
      doSearch(value);
    } else {
      handleRefresh();
    }
  }

  function handleOpenDelete(id) {
    lockListTableOverflow();
    setIsDeleteOpen(id);
  }

  function handleCloseDelete() {
    setIsDeleteOpen(false);
    unlockListTableOverflow();
  }

  function handleOpenFilter() {
    setIsFiltersOpen(true);
  }

  function handleCloseFilter() {
    setIsFiltersOpen(false);
  }

  function handleOpenCustomCreateContainer() {
    setCustomCreateContainerOpen(true);
  }

  function handleCloseCustomCreateContainer() {
    setCustomCreateContainerOpen(false);
  }

  function handleSuccessCustomCreateContainer() {
    handleCloseCustomCreateContainer();
    handleRefresh();
  }

  function handleDeleteResourceItem() {
    deleteResourceItem(getRouteUri(routeUri, match), isDeleteOpen, function () {
      handleRefresh();
      handleCloseDelete();
    });
  }

  function handleOpenCustomActionDialog(route, data) {
    lockListTableOverflow();
    setCustomDialog({ opened: route, data });
  }

  function handleCloseCustomActionDialog() {
    setCustomDialog({ opened: false, data: null });
    unlockListTableOverflow();
  }

  function handleFilterData() {
    setSubmitBuffer((submitBuffer) => submitBuffer + 1);
    setDataSearchValue('');
    handleCloseFilter();
    filterData(getRouteUri(routeUri, match), metadata.data.fields || []);
  }

  function handleExportData() {
    exportData(routeUri, { filters: queryString });
  }

  function getTableBodyFromData(data, columns = 7, isFlag = false) {
    const table_body = [];
    let temp_body = null;

    for (let line in data) {
      if (Array.isArray(columns)) {
        temp_body = [];

        for (let i = 0; i < columns.length; i++) {
          for (let key in data[line]) {
            if (!columns[i].hide) {
              if (data[line][columns[i].name]) {
                if (key === columns[i].name) {
                  if (key === 'user') {
                    let name = '';

                    if (
                      data[line][key] &&
                      data[line][key].first_name &&
                      data[line][key].last_name
                    ) {
                      name = data[line][key].first_name + ' ' + data[line][key].last_name;
                    } else if (data[line][key] && data[line][key].last_name) {
                      name = '[Missing first name] ' + data[line][key].last_name;
                    } else {
                      name = '[Missing user object]';
                    }

                    if (data[line]['company']) {
                      name += ' (' + data[line]['company'].commercial_name + ')';
                    }

                    temp_body[key] = name;
                  } else {
                    temp_body[key] = addThreeDots(
                      data[line][key],
                      parseInt(getUserTableStringWidth(), 10) || 150
                    );
                  }
                }
              } else if (typeof data[line][columns[i].name] === 'boolean') {
                temp_body[columns[i].name] = data[line][columns[i].name];
              } else if (columns[i].name.indexOf('.') > -1) {
                const tmpArray = columns[i].name.split('.');

                if (
                  data[line][tmpArray[0]] &&
                  data[line][tmpArray[0]][tmpArray[1]] !== null &&
                  typeof data[line][tmpArray[0]][tmpArray[1]] === 'boolean'
                ) {
                  temp_body[columns[i].name] = data[line][tmpArray[0]][tmpArray[1]];
                } else if (data[line][tmpArray[0]] && data[line][tmpArray[0]][tmpArray[1]]) {
                  temp_body[columns[i].name] = data[line][tmpArray[0]][tmpArray[1]];
                } else {
                  temp_body[columns[i].name] = '-';
                }
              } else {
                temp_body[columns[i].name] = '-';
              }
            } else {
              if (isFlag && columns[i].name === 'flag' && data[line]['flag'] === true) {
                temp_body['flag'] = true;
              }
            }
          }
        }

        table_body.push(temp_body);
      } else if (typeof columns === 'number') {
        temp_body = [];

        let counter = 0;

        for (let key in data[line]) {
          if (counter >= columns) {
            break;
          }
          if (key === 'user') {
            let name = '';

            if (data[line][key] && data[line][key].first_name && data[line][key].last_name) {
              name = data[line][key].first_name + ' ' + data[line][key].last_name;
            } else if (data[line][key] && data[line][key].last_name) {
              name = '[Missing first name] ' + data[line][key].last_name;
            } else {
              name = '[Missing user object]';
            }

            if (data[line]['company']) {
              name += ' (' + data[line]['company'].commercial_name + ')';
            }

            temp_body[key] = name;
          } else if (typeof data[line][key] === 'boolean') {
            temp_body[key] = data[line][key];
          } else {
            temp_body[key] = addThreeDots(
              data[line][key],
              parseInt(getUserTableStringWidth(), 10) || 150
            );
          }

          counter++;
        }

        table_body.push(temp_body);
      }
    }

    return table_body;
  }

  function renderTable(data, columns = 7, isFlag = false) {
    if (data && data.length) {
      let isActions = false;
      const tableBody = getTableBodyFromData(data, columns, isFlag);

      const tableValues = tableBody.map((row, index) => {
        let result = [];

        Object.keys(row).forEach((key) => {
          if (key !== 'flag') {
            if (Array.isArray(row[key])) {
              result.push(
                <TableCell key={key}>
                  <Typography variant="body2">[Array]</Typography>
                </TableCell>
              );
            } else if (typeof row[key] === 'object' && row[key] !== null) {
              result.push(
                <TableCell key={key}>
                  <Typography variant="body2">[Object]</Typography>
                </TableCell>
              );
            } else if (typeof row[key] === 'boolean') {
              if (row[key] === false) {
                result.push(
                  <TableCell key={key}>
                    <CloseIcon
                      style={{ color: config.error_color || '#F44336' }}
                      fontSize="small"
                    />
                  </TableCell>
                );
              } else if (row[key] === true) {
                result.push(
                  <TableCell key={key}>
                    <DoneIcon
                      style={{ color: config.success_color || '#4CAF50' }}
                      fontSize="small"
                    />
                  </TableCell>
                );
              }
            } else {
              const fields = metadata.data.fields || [];

              if (fields && fields.length > 0 && row[key] && row[key] !== '-') {
                for (let x = 0; x < fields.length; x++) {
                  if (fields[x].type === 'timestamp' && fields[x].name === key) {
                    row[key] = dateInTz(row[key], 'localized-datetime');
                  } else if (fields[x].type === 'date' && fields[x].name === key) {
                    row[key] = dateInTz(row[key], 'localized-date');
                  } else if (fields[x].type === 'time' && fields[x].name === key) {
                    const timeSplitted = row[key].split(':');
                    const timeAltered = alterDate(
                      createDateUtc(null).toString(),
                      null,
                      null,
                      null,
                      timeSplitted[0],
                      timeSplitted[1],
                      null
                    );
                    row[key] = dateInTz(timeAltered, 'HH:mm');
                  }
                }
              }

              if (row[key]) {
                result.push(
                  <TableCell
                    key={key}
                    className={classNames({
                      [`${classes.labelImportant}`]: isFlag && row['flag'] ? true : false
                    })}>
                    <Typography variant="body2">{row[key]}</Typography>
                  </TableCell>
                );
              } else {
                result.push(
                  <TableCell
                    key={key}
                    className={classNames({
                      [`${classes.labelImportant}`]: isFlag && row['flag'] ? true : false
                    })}>
                    <Typography variant="body2">-</Typography>
                  </TableCell>
                );
              }
            }
          }
        });

        const actions = [];
        const mobileActions = [];

        if (isUpdatable) {
          isActions = true;

          actions.push(
            <SpeedDialAction
              key="speedDial-update-item"
              icon={<EditIcon />}
              tooltipTitle={translation().actions.update}
              className={classes.speedDialAction}
              FabProps={{
                id: '/' + getRouteUri(routeUri, match) + '/' + row.id + '/update',
                component: CustomLink
              }}
            />
          );

          mobileActions.push(
            <Link
              to={'/' + getRouteUri(routeUri, match) + '/' + row.id + '/update'}
              key="menu-update-item">
              <MenuItem className={classes.menuItem}>
                <i className={classNames('material-icons', classes.iconMenuItem)}>edit</i>
                <Typography component="span">{translation().actions.update}</Typography>
              </MenuItem>
            </Link>
          );
        }

        if (isDeletable) {
          isActions = true;

          actions.push(
            <SpeedDialAction
              key="speed-delete-item"
              icon={<DeleteIcon />}
              tooltipTitle={translation().actions.delete}
              //eslint-disable-next-line
              onClick={() => handleOpenDelete(row.id)}
              className={classes.speedDialAction}
            />
          );

          mobileActions.push(
            <MenuItem
              key="menu-delete-item"
              className={classes.menuItem}
              //eslint-disable-next-line
              onClick={() => handleOpenDelete(row.id)}>
              <i className={classNames('material-icons', classes.iconMenuItem)}>delete</i>
              <Typography component="span">{translation().actions.delete}</Typography>
            </MenuItem>
          );
        }

        if (isDuplicable) {
          isActions = true;

          actions.push(
            <SpeedDialAction
              key="speed-copy-item"
              icon={<FileCopyIcon />}
              tooltipTitle={'Dupliquer'} // to translate
              // tooltipTitle={translation().actions.delete}
              className={classes.speedDialAction}
              FabProps={{
                id: '/' + getRouteUri(routeUri, match) + '/' + row.id + '/duplicate',
                component: CustomLink
              }}
            />
          );

          mobileActions.push(
            <Link
              to={'/' + getRouteUri(routeUri, match) + '/' + row.id + '/duplicate'}
              key="menu-duplicate-item">
              <MenuItem className={classes.menuItem}>
                <i className={classNames('material-icons', classes.iconMenuItem)}>file_copy</i>
                <Typography component="span">
                  Dupliquer
                  {/* to translate {translation().actions.delete} */}
                </Typography>
              </MenuItem>
            </Link>
          );
        }

        /**
         * Build list items custom actions
         */
        if (sub_routes) {
          for (let route in sub_routes) {
            if (sub_routes[route].is_show_in_table) {
              if (sub_routes[route].is_route) {
                isActions = true;

                actions.push(
                  <SpeedDialAction
                    key={'speedDial-route-' + sub_routes[route].name}
                    icon={<i className="material-icons">{sub_routes[route].icon}</i>}
                    className={classes.speedDialAction}
                    tooltipTitle={sub_routes[route].name}
                    FabProps={{
                      id: route.replace(':id', row.id),
                      component: CustomLink
                    }}
                  />
                );

                mobileActions.push(
                  <Link
                    to={route.replace(':id', row.id)}
                    key={'mobile-custom-' + sub_routes[route].name}>
                    <MenuItem className={classes.menuItem}>
                      <i className={classNames('material-icons', classes.iconMenuItem)}>
                        {sub_routes[route].icon}
                      </i>
                      <Typography component="span">{sub_routes[route].name}</Typography>
                    </MenuItem>
                  </Link>
                );
              } else if (sub_routes[route].is_modal) {
                for (let j = 0; j < data.length; j++) {
                  if (data[j].id === row.id) {
                    isActions = true;

                    actions.push(
                      <SpeedDialAction
                        key={'custom-dialog-' + sub_routes[route].name}
                        icon={<i className="material-icons">{sub_routes[route].icon}</i>}
                        tooltipTitle={sub_routes[route].name}
                        customprops={{
                          data: data[j],
                          onOpenCustomActionDialog: handleOpenCustomActionDialog,
                          route: route
                        }}
                        className={classes.speedDialAction}
                        FabProps={{
                          component: CustomDialogButton
                        }}
                      />
                    );

                    mobileActions.push(
                      <MenuItem
                        key={'menu-customdialog-item-' + sub_routes[route].name}
                        className={classes.menuItem}
                        //eslint-disable-next-line
                        onClick={() => handleOpenCustomActionDialog(route, data[j])}>
                        <i className={classNames('material-icons', classes.iconMenuItem)}>
                          {sub_routes[route].icon}
                        </i>
                        <Typography component="span">{sub_routes[route].name}</Typography>
                      </MenuItem>
                    );
                  }
                }
              } else if (sub_routes[route].is_link) {
                let link = null;

                if (sub_routes[route].props && sub_routes[route].props.link_url) {
                  if (sub_routes[route].props.link_url.indexOf(':id') > -1)
                    link = sub_routes[route].props.link_url.replace(':id', row.id);
                  else link = sub_routes[route].props.link_url;
                }

                isActions = true;

                actions.push(
                  <SpeedDialAction
                    key={'speedDial-link-' + sub_routes[route].name}
                    icon={<i className="material-icons">{sub_routes[route].icon}</i>}
                    tooltipTitle={sub_routes[route].name}
                    target="_blank"
                    href={link}
                    className={classes.speedDialAction}
                    FabProps={{
                      component: CustomHref
                    }}
                  />
                );

                mobileActions.push(
                  <MenuItem
                    target="_blank"
                    key={'menu-link-' + sub_routes[route].name}
                    href={link}
                    className={classes.menuItem}
                    component={CustomHref}>
                    <i className={classNames('material-icons', classes.iconMenuItem)}>
                      {sub_routes[route].icon}
                    </i>
                    <Typography component="span">{sub_routes[route].name}</Typography>
                  </MenuItem>
                );
              }
            }
          }
        }

        return (
          <TableRow key={index}>
            {result}
            {actions && actions.length > 0 ? (
              <TableCell key={'action-' + index} style={{ textAlign: 'right' }}>
                {getUserTableLinksDisplayMode() === 'select' ? (
                  <ListViewMobileMenu actions={mobileActions} />
                ) : (
                  <>
                    <div className={classes.sectionMobile}>
                      <ListViewMobileMenu actions={mobileActions} />
                    </div>
                    <div className={classes.sectionDesktop}>
                      <ListViewSpeedDial actions={actions} />
                    </div>
                  </>
                )}
              </TableCell>
            ) : (
              false
            )}
          </TableRow>
        );
      });

      return (
        <FixedHeaderTable
          rows={tableBody}
          tableValues={tableValues}
          metadata={metadata}
          isActions={isActions}
          onScrollList={handleScrollList}
          isLoading={isLoading}
        />
      );
    }
  }

  function renderCustomActionsDialog() {
    const customActionsDialog = [];

    for (let route in sub_routes) {
      if (sub_routes[route].is_show_in_table) {
        if (sub_routes[route].is_modal) {
          const ActionComponent = sub_routes[route].container ? sub_routes[route].container : false;

          customActionsDialog.push(
            <Dialog
              key={route}
              open={customDialog.opened === route ? true : false}
              onClose={handleCloseCustomActionDialog}
              maxWidth={
                sub_routes[route].props &&
                sub_routes[route].props.dialog &&
                sub_routes[route].props.dialog.maxWidth
                  ? sub_routes[route].props.dialog.maxWidth
                  : 'sm'
              }
              fullWidth={
                sub_routes[route].props &&
                sub_routes[route].props.dialog &&
                sub_routes[route].props.dialog.fullWidth
                  ? true
                  : false
              }
              aria-labelledby={route + '-custom-dialog-title'}
              aria-describedby={route + '-custom-dialog-description'}>
              <DialogTitle id={route + '-custom-dialog-title'}>
                {sub_routes[route].props.title}
              </DialogTitle>
              <DialogContent>
                {sub_routes[route].props.description && (
                  <DialogContentText id={route + '-custom-dialog-description'}>
                    {sub_routes[route].props.description}
                  </DialogContentText>
                )}
                {ActionComponent && (
                  <ActionComponent
                    resource={customDialog.data}
                    resourceId={match && match.params && match.params.id ? match.params.id : null}
                    closeDialogFunction={handleCloseCustomActionDialog}
                    reloadListFunction={handleRefreshAfterAction}
                    fullProps={sub_routes[route].props}
                  />
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseCustomActionDialog} color="default">
                  {translation().actions.close}
                </Button>
              </DialogActions>
            </Dialog>
          );
        }
      }
    }

    return customActionsDialog;
  }

  return (
    <div className="ListView">
      <ListViewBar
        actions={actions}
        isLoading={isLoading}
        isExportLoading={exportList.isLoading}
        handleSubmit={handleSubmit}
        handleRefresh={handleRefresh}
        isCreatable={isCreatable}
        isCreateCustomContainer={Boolean(customCreateContainer)}
        isFilterable={isFilterable}
        isExportable={isExportable}
        handleExportData={handleExportData}
        handleOpenFilter={handleOpenFilter}
        handleOpenCustomCreateContainer={handleOpenCustomCreateContainer}
        handleCloseFilter={handleCloseFilter}
        parentUri={parentUri}
        search={value}
        routeUri={getRouteUri(routeUri, match)}
        history={history}
        submitBuffer={submitBuffer}
      />
      {!metadata.isLoading && data && data.length
        ? renderTable(data, columns, isRouteFlagged)
        : false}
      {!isLoading && paging && paging.next && (
        <Tooltip title={translation().core.list.charge_next} enterDelay={500} placement="left">
          <div>
            <IconButton
              onClick={handleScrollList}
              disabled={isLoading || metadata.isLoading}
              aria-label="Next Page"
              style={{
                position: 'absolute',
                bottom: 5,
                right: 15,
                padding: 5,
                zIndex: 10
              }}>
              <KeyboardArrowRight style={{ fontSize: 18 }} />
            </IconButton>
          </div>
        </Tooltip>
      )}
      {!isLoading && isError && (!data || data.length <= 0) ? (
        <div className="error-list">
          <Alert
            type="danger"
            style={{ marginTop: 10 }}
            status={translation().commons.alerts.error}
            text={translation().core.list.error_list}
          />
        </div>
      ) : (
        false
      )}
      {(isLoading || metadata.isLoading) && !paging && (
        <div className="loader-wpr">
          <CircularProgress color="primary" />
          <p>{translation().core.list.load_list}</p>
          {metadata.isLoading && <p>{translation().core.load_metadata}</p>}
        </div>
      )}
      {isLoading && paging && paging.next && (
        <>
          <LinearProgress color="primary" />
          <div className="loader-wpr linear">
            <p>{translation().core.list.load_next}</p>
          </div>
        </>
      )}
      {!isLoading && !metadata.isLoading && !isError && (!data || !data.length) && (
        <div className="loader-wpr linear">
          <ListEmptyIcon />
          <p style={{ margin: '7px 0', lineHeight: 'initial' }}>{translation().core.list.empty}</p>
        </div>
      )}
      {!metadata.isLoading && !isLoading && paging && !paging.next && paging.prev && (
        <div className="loader-wpr linear">
          <p>{translation().core.list.load_next_done}</p>
        </div>
      )}
      {!metadata.isLoading && !isLoading && !paging && data && data.length > 0 ? (
        <div className="loader-wpr linear">
          <p>{translation().core.list.load_next_done}</p>
        </div>
      ) : (
        false
      )}
      {renderCustomActionsDialog()}
      <Dialog
        open={isDeleteOpen ? true : false}
        onClose={handleCloseDelete}
        aria-labelledby="delete-resource-item"
        aria-describedby="delete-dialog-description"
        disableBackdropClick={deleteItem.isLoading}>
        <DialogTitle id="delete-resource-item">
          {translation().core.list.dialogs.delete.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            {translation().core.list.dialogs.delete.content}
          </DialogContentText>
          {deleteItem.isLoading && (
            <div className="loader-wpr" style={{ marginTop: 15 }}>
              <CircularProgress size={30} color="secondary" />
              <p>{translation().core.list.dialogs.delete.load_delete}</p>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete} color="default" disabled={deleteItem.isLoading}>
            {translation().actions.cancel}
          </Button>
          <Button
            onClick={handleDeleteResourceItem}
            className={classes.deleteButton}
            disabled={deleteItem.isLoading}>
            {translation().actions.delete}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullScreen
        open={isFiltersOpen}
        onClose={handleCloseFilter}
        TransitionComponent={Transition}>
        <AppBar className={classes.filterAppBar}>
          <Toolbar>
            <Typography variant="h6">{translation().actions.filter}</Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseFilter}
              className={classes.filterCloseBtn}
              aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent className={classes.dialogRoot}>
          <FiltersContainer metadata={metadata} onSubmit={handleFilterData} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFilter} color="default">
            {translation().actions.close}
          </Button>
          <Button color="secondary" variant="contained" onClick={handleFilterData}>
            {translation().actions.search}
          </Button>
        </DialogActions>
      </Dialog>
      {customCreateContainer && (
        <Dialog
          aria-labelledby="custom-create-container-dialog"
          open={isCustomCreateContainerOpen}
          onClose={handleCloseCustomCreateContainer}
          maxWidth={
            customCreateContainer.dialog && customCreateContainer.dialog.maxWidth
              ? customCreateContainer.dialog.maxWidth
              : 'sm'
          }
          fullWidth={
            customCreateContainer.dialog && customCreateContainer.dialog.fullWidth ? true : false
          }>
          {customCreateContainer.title && (
            <DialogTitle id="custom-create-container-dialog">
              {customCreateContainer.title}
            </DialogTitle>
          )}
          <DialogContent className={classes.dialogRoot}>
            <CustomCreateContainer
              resourceId={match && match.params && match.params.id ? match.params.id : null}
              onSuccess={handleSuccessCustomCreateContainer}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCustomCreateContainer} color="default">
              {translation().actions.close}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}

ListView.defaultProps = {
  customCreateContainer: null,
  isCreatable: true,
  isDeletable: true,
  isDuplicable: false,
  isExportable: false,
  isFilterable: true,
  isRouteFlagged: false,
  isUpdatable: true
};

ListView.propTypes = {
  actions: PropTypes.shape(),
  clearFilters: PropTypes.func.isRequired,
  clearOwnReducer: PropTypes.func.isRequired,
  columns: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
  customCreateContainer: PropTypes.shape(),
  deleteItem: PropTypes.shape({
    isError: PropTypes.bool,
    isLoading: PropTypes.bool
  }).isRequired,
  deleteResourceItem: PropTypes.func.isRequired,
  exportData: PropTypes.func.isRequired,
  filterData: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    queryString: PropTypes.string
  }).isRequired,
  getData: PropTypes.func.isRequired,
  getMetadata: PropTypes.func.isRequired,
  history: PropTypes.shape().isRequired,
  isCreatable: PropTypes.bool,
  isDeletable: PropTypes.bool,
  isDuplicable: PropTypes.bool,
  isExportable: PropTypes.bool,
  isFilterable: PropTypes.bool,
  isRouteFlagged: PropTypes.bool,
  isUpdatable: PropTypes.bool,
  list: PropTypes.shape({
    needReload: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.shape()),
    search: PropTypes.shape({
      value: PropTypes.string
    }),
    paging: PropTypes.shape({
      next: PropTypes.string,
      prev: PropTypes.string
    }),
    isError: PropTypes.bool,
    isLoading: PropTypes.bool,
    name: PropTypes.string,
    exportList: PropTypes.shape({
      isLoading: PropTypes.bool
    }).isRequired
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  }),
  metadata: PropTypes.shape(),
  metadataUri: PropTypes.string,
  parentUri: PropTypes.string.isRequired,
  routeUri: PropTypes.string.isRequired,
  setDataName: PropTypes.func.isRequired,
  setDataSearchValue: PropTypes.func.isRequired,
  sub_routes: PropTypes.shape(),
  unsetListNeedReload: PropTypes.func.isRequired
};

export default ListView;
