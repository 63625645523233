import React from 'react';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-number-input';
import { makeStyles } from '@material-ui/core/styles';
import ApartmentIcon from '@material-ui/icons/Apartment';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import Avatar from '@material-ui/core/Avatar';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import BusinessIcon from '@material-ui/icons/Business';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CalendarTodayRoundedIcon from '@material-ui/icons/CalendarTodayRounded';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import EmailIcon from '@material-ui/icons/Email';
import FlagIcon from '@material-ui/icons/Flag';
import Grid from '@material-ui/core/Grid';
import GavelIcon from '@material-ui/icons/Gavel';
import IconButton from '@material-ui/core/IconButton';
import LanguageIcon from '@material-ui/icons/Language';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import PeopleIcon from '@material-ui/icons/People';
import PermPhoneMsgIcon from '@material-ui/icons/PermPhoneMsg';

import { dateInTz } from '../../../../../utils/functions/dates';
import Users from './Users';
import config from '../../../../../config';
import translation from '../../../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: '#FFFFFF',
    color: 'rgba(0, 0, 0, 0.87)'
  },
  cardHeader: {
    minHeight: 60,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.cardHeader
  },
  cardContent: {
    padding: theme.spacing(1),
    '&:last-child': {
      paddingBottom: 0
    }
  },
  paper: {
    minHeight: 500,
    overflow: 'auto',
    width: '100%',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      minHeight: 'auto'
    }
  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2)
  },
  listItemBox: {
    minWidth: 35
  },
  listItem: {
    padding: 4
  },
  listRoot: {
    padding: 0
  }
}));

function CompanySummary(props) {
  const classes = useStyles();

  const { company, cleanCompanyViewUsersReducer, getCompanyViewUsers, users } = props;

  function handleBlockChange() {
    return false;
  }

  const companyAvatarUrl =
    company && company.logo_url ? `${company.logo_url}?client_id=${config.api_key}` : null;

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <Card className={classes.paper}>
            <CardHeader
              className={classes.cardHeader}
              title={translation().views.company_view.infos.label}
              titleTypographyProps={{
                variant: 'h5',
                style: {
                  fontSize: 21
                }
              }}
              avatar={
                companyAvatarUrl ? (
                  <Avatar
                    className={classes.avatar}
                    aria-label="profile_picture"
                    src={companyAvatarUrl}
                    alt={company.commercial_name.charAt(0).toUpperCase()}
                  />
                ) : (
                  <Avatar>
                    <ApartmentIcon />
                  </Avatar>
                )
              }
              action={
                <IconButton
                  aria-label="Edit company"
                  href={`/companies/${company.id}/update`}
                  target="_blank">
                  <EditIcon fontSize="small" />
                </IconButton>
              }
            />
            <CardContent className={classes.cardContent}>
              <List>
                {company.commercial_name && (
                  <>
                    <ListItem className={classes.listItem}>
                      <ListItemIcon className={classes.listItemBox}>
                        <BusinessIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={translation().views.company_view.infos.commercial_name}
                        secondary={company.commercial_name}
                      />
                    </ListItem>
                  </>
                )}
                {company.legal_name && (
                  <>
                    <ListItem className={classes.listItem}>
                      <ListItemIcon className={classes.listItemBox}>
                        <BusinessIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={translation().views.company_view.infos.legal_name}
                        secondary={company.legal_name}
                      />
                    </ListItem>
                  </>
                )}
                {company.legal_id && company.legal_identifier && (
                  <>
                    <ListItem className={classes.listItem}>
                      <ListItemIcon className={classes.listItemBox}>
                        <GavelIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={company.legal_identifier}
                        secondary={company.legal_id}
                      />
                    </ListItem>
                  </>
                )}
                {company.vat_number && (
                  <>
                    <ListItem className={classes.listItem}>
                      <ListItemIcon className={classes.listItemBox}>
                        <AccountBalanceIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={translation().views.company_view.infos.vat_number}
                        secondary={company.vat_number}
                      />
                    </ListItem>
                  </>
                )}
                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.listItemBox}>
                    <AssignmentTurnedInIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={translation().views.company_view.infos.active}
                    secondary={
                      company.active ? (
                        <DoneIcon
                          style={{ color: config.success_color || '#4CAF50' }}
                          fontSize="small"
                        />
                      ) : (
                        <CloseIcon
                          style={{ color: config.error_color || '#F44336' }}
                          fontSize="small"
                        />
                      )
                    }
                  />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.listItemBox}>
                    <FlagIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={translation().views.company_view.infos.flag}
                    secondary={
                      company.flag ? (
                        <DoneIcon
                          style={{ color: config.success_color || '#4CAF50' }}
                          fontSize="small"
                        />
                      ) : (
                        <CloseIcon
                          style={{ color: config.error_color || '#F44336' }}
                          fontSize="small"
                        />
                      )
                    }
                  />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.listItemBox}>
                    <CalendarTodayRoundedIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={translation().views.company_view.infos.created}
                    secondary={dateInTz(company.created, 'localized-datetime')}
                  />
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <Card className={classes.paper}>
            <CardHeader
              className={classes.cardHeader}
              title={translation().views.company_view.infos.contact}
              titleTypographyProps={{
                variant: 'h5',
                style: {
                  fontSize: 21
                }
              }}
              avatar={
                <Avatar>
                  <PermPhoneMsgIcon />
                </Avatar>
              }
            />
            <CardContent className={classes.cardContent}>
              <List>
                {company.email && (
                  <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemBox}>
                      <EmailIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={translation().views.company_view.infos.email}
                      secondary={
                        <Link href={`mailto:${company.email}`} target="_blank">
                          {company.email}
                        </Link>
                      }
                    />
                  </ListItem>
                )}
                {company.website_url && (
                  <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemBox}>
                      <LanguageIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={translation().views.company_view.infos.web_site}
                      secondary={
                        <Link href={company.website_url} target="_blank">
                          {company.website_url}
                        </Link>
                      }
                    />
                  </ListItem>
                )}
                {company.phone && (
                  <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemBox}>
                      <PhoneInput
                        value={company.phone}
                        disabled
                        onChange={handleBlockChange}
                        className="company-view-phone-input"
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={translation().views.company_view.infos.phone}
                      secondary={<Link href={`tel: ${company.phone}`}>{company.phone}</Link>}
                    />
                  </ListItem>
                )}
                {company.fax && (
                  <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemBox}>
                      <PhoneInput
                        value={company.fax}
                        disabled
                        onChange={handleBlockChange}
                        className="company-view-phone-input"
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={translation().views.company_view.infos.fax}
                      secondary={company.fax}
                    />
                  </ListItem>
                )}
              </List>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Card className={classes.paper}>
            <CardHeader
              className={classes.cardHeader}
              title={translation().views.company_view.users.label}
              titleTypographyProps={{
                variant: 'h5',
                style: {
                  fontSize: 21
                }
              }}
              action={
                <IconButton
                  aria-label="See all users"
                  href={`/companies/${company.id}/users`}
                  target="_blank">
                  <OpenInNewIcon fontSize="small" />
                </IconButton>
              }
              avatar={
                <Avatar>
                  <PeopleIcon />
                </Avatar>
              }
            />
            <CardContent className={classes.cardContent}>
              <Users
                cleanCompanyViewUsersReducer={cleanCompanyViewUsersReducer}
                getCompanyViewUsers={getCompanyViewUsers}
                users={users}
                companyId={company.id}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

CompanySummary.propTypes = {
  cleanCompanyViewUsersReducer: PropTypes.func.isRequired,
  company: PropTypes.shape().isRequired,
  getCompanyViewUsers: PropTypes.func.isRequired,
  users: PropTypes.shape().isRequired
};

export default CompanySummary;
