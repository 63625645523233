import './metric.scss';

import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Chart from 'chart.js';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';

import Alert from '../../components/alert/Alert';
import { countObjProps } from '../../utils/functions/utils';
import translation from '../../translation/translation';

const chartColorsArray = [
  '#8378F4', // primary theme color
  '#ffb74d', // orange 300
  '#80cbc4', // teal 200
  '#ff8a65', // deepOrange 300
  '#ffe082', // amber 500
  '#a5d6a7', // green 200
  '#f06292', // pink 300
  '#64b5f6', // blue 300
  '#ffee58', // yellow 400
  '#f4511e', // deep orange 600
  '#00acc1', // cyan 600
  '#8e24aa', // purple 600
  '#4caf50', // green 500
  '#cddc39', // lime 500
  '#ffc107', // amber 200
  '#e53935', // red 600
  '#80deea', // cyan 200
  '#ff8a65', // deep orange 300
  '#7c4dff', // deep purple A200
  '#f48fb1', // pink 200
  '#0277bd', // light blue 800
  '#7986cb', // indigo 300
  '#ff5252', // red 200
  '#ce93d8', // purple 200
  '#81d4fa', // light blue 200
  '#8378F4', // primary theme color
  '#ffb74d', // orange 300
  '#80cbc4', // teal 200
  '#ff8a65', // deepOrange 300
  '#ffe082', // amber 500
  '#a5d6a7', // green 200
  '#f06292', // pink 300
  '#64b5f6', // blue 300
  '#ffee58', // yellow 400
  '#f4511e', // deep orange 600
  '#00acc1', // cyan 600
  '#8e24aa', // purple 600
  '#4caf50', // green 500
  '#cddc39', // lime 500
  '#ffc107', // amber 200
  '#e53935', // red 600
  '#80deea', // cyan 200
  '#ff8a65', // deep orange 300
  '#7c4dff', // deep purple A200
  '#f48fb1', // pink 200
  '#0277bd', // light blue 800
  '#7986cb', // indigo 300
  '#ff5252', // red 200
  '#ce93d8', // purple 200
  '#81d4fa' // light blue 200
];

function Metric(props) {
  const { className, chartProps, actions, submitBuffer, themeMode } = props;

  const [chartRef, setChartRef] = useState(null);
  const [metricNb, setMetricNb] = useState(0);
  const [isNbLoading, setNbLoading] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [warning, setWarning] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    loadMetrics(props, {});

    return () => {
      setChartRef(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitBuffer]);

  function calcMetricNumber(metric, type = 'count') {
    if (metric && metric.values && metric.values.length > 0) {
      let sum = 0;
      let nbElements = 0;

      for (let i = 0; i < metric.values.length; i++) {
        sum += parseFloat(metric.values[i], 10);

        if (parseFloat(metric.values[i], 10) !== 0) {
          nbElements++;
        }
      }

      if (type.toLowerCase() === 'count') {
        return sum.toFixed(2);
      } else if (type.toLowerCase() === 'avg') {
        if (nbElements === 0) {
          return 0;
        }

        return (sum / nbElements).toFixed(2);
      }
    } else {
      return 0;
    }
  }

  function loadMetrics(nextProps, query = {}) {
    const { getMetrics, queryParams, filterKey, filterKeys, match, chartProps, routeName } =
      nextProps;

    setWarning(null);
    setError(null);
    setLoading(true);
    setMetricNb(null);

    /**
     * Check if route have one or multiple :ids in url
     */
    if (filterKey) {
      if (match && match.params && match.params.id) {
        if (queryParams.filters) {
          query.filters += ',' + filterKey + '|eq|' + match.params.id;
        } else {
          query.filters = filterKey + '|eq|' + match.params.id;
        }
      }
    } else if (filterKeys && filterKeys.length > 0) {
      if (match && match.params && countObjProps(match.params) > 0) {
        for (let i = 0; i < filterKeys.length; i++) {
          for (let param in match.params) {
            if (param === filterKeys[i]) {
              if (query.filters) {
                query.filters += ',' + filterKeys[i] + '|eq|' + match.params[param];
              } else {
                query.filters = filterKeys[i] + '|eq|' + match.params[param];
              }
            }
          }
        }
      }
    }

    getMetrics(
      routeName,
      {
        ...queryParams,
        ...queryParams.range,
        ...query
      },
      (data) => {
        setLoading(false);

        if (chartProps.metric_calc_mode) {
          if (
            chartProps.metric_calc_mode.toLowerCase() === 'function' &&
            chartProps.metric_calc_function
          ) {
            if (
              chartProps.metric_calc_function.type &&
              chartProps.metric_calc_function.type.toLowerCase() === 'call-api' &&
              chartProps.metric_calc_function.route
            ) {
              setNbLoading(true);

              getMetrics(
                chartProps.metric_calc_function.route,
                {
                  ...query,
                  ...queryParams.range,
                  ...chartProps.metric_calc_function.queryParams
                },
                (data) => {
                  setNbLoading(false);
                  setMetricNb(
                    chartProps.metric_calc_function.calc_mode
                      ? calcMetricNumber(data, chartProps.metric_calc_function.calc_mode)
                      : null
                  );
                },
                () => {
                  setNbLoading(false);
                }
              );
            }
          } else if (chartProps.metric_calc_mode.toLowerCase() !== 'function') {
            setMetricNb(calcMetricNumber(data, chartProps.metric_calc_mode));
          }
        }

        if (!chartProps.hide_chart) {
          if (chartProps.custom) {
            renderCustomChart(nextProps, data, calcMetricNumber(data, chartProps.metric_calc_mode));
          } else {
            renderChart(nextProps, data);
          }
        }
      },
      (error) => {
        setLoading(false);

        if (error.response) {
          setError(
            error.response.data && error.response.data.detail
              ? error.response.data.detail
              : translation().core.list.error_list
          );
        } else {
          setError(translation().core.list.error_list);
        }
      }
    );
  }

  function renderChart(nextProps, metric) {
    const chartContainer = document.getElementById(nextProps.chartProps.name + '-metric-chart');

    if (chartContainer) {
      chartRef && chartRef.destroy();
      chartContainer.style.display = 'block';

      if (!metric || !metric.values || metric.values.length <= 0) {
        setWarning('The array of values is empty, no data found.');
        chartContainer.style.display = 'none';
        return;
      }

      const chart = new Chart(chartContainer, {
        type: nextProps.chartProps.type ? nextProps.chartProps.type : nextProps.defaultType,
        data: {
          labels: metric.labels,
          datasets: [
            {
              data: metric.values,
              label: 'N',
              lineTension: 0,
              backgroundColor:
                nextProps.chartProps.type &&
                (nextProps.chartProps.type === 'doughnut' ||
                  nextProps.chartProps.type === 'bar' ||
                  nextProps.chartProps.type === 'pie')
                  ? chartColorsArray
                  : '#bfb9ff',
              borderColor:
                nextProps.chartProps.type &&
                (nextProps.chartProps.type === 'doughnut' ||
                  nextProps.chartProps.type === 'bar' ||
                  nextProps.chartProps.type === 'pie')
                  ? chartColorsArray
                  : '#8378F4',
              borderJoinStyle: 'miter',
              pointStyle: 'rect',
              borderCapStyle: 'square',
              borderWidth:
                nextProps.chartProps.type &&
                (nextProps.chartProps.type === 'doughnut' ||
                  nextProps.chartProps.type === 'bar' ||
                  nextProps.chartProps.type === 'pie')
                  ? 0
                  : 2
            }
          ]
        },
        options: {
          scales: {
            yAxes: [
              {
                display:
                  nextProps.chartProps.type &&
                  (nextProps.chartProps.type === 'doughnut' || nextProps.chartProps.type === 'pie')
                    ? false
                    : true,
                ticks: {
                  fontColor: themeMode && themeMode === 'dark' ? 'white' : 'inherit'
                }
              }
            ],
            xAxes: [
              {
                display:
                  nextProps.chartProps.type &&
                  (nextProps.chartProps.type === 'doughnut' || nextProps.chartProps.type === 'pie')
                    ? false
                    : true,
                ticks: {
                  fontColor: themeMode && themeMode === 'dark' ? 'white' : 'inherit'
                }
              }
            ]
          },
          legend: {
            display: false,
            labels: {
              fontColor: themeMode && themeMode === 'dark' ? 'white' : 'inherit',
              fontSize: 14
            }
          },
          tooltips: {
            enabled:
              nextProps.chartProps.options && nextProps.chartProps.options.tooltips
                ? nextProps.chartProps.options.tooltips
                : true
          }
        }
      });

      setChartRef(chart);
    }
  }

  function renderCustomChart(nextProps, metric, metricNumber) {
    const customMetricContainer = document.getElementById(
      nextProps.chartProps.name + '-custom-chart'
    );

    if (customMetricContainer) {
      if (!metric || !metric.values || metric.values.length <= 0) {
        setWarning('The array of values is empty, no data found.');
        return;
      }

      if (nextProps.chartProps.type && nextProps.chartProps.type.toLowerCase() === 'trending') {
        ReactDOM.render(
          <div className="type-trending">
            {metricNumber > 0 && (
              <div className="trending trend-up">
                <i className="material-icons">trending_up</i>
                <span>+ {metricNumber}%</span>
              </div>
            )}
            {metricNumber < 0 && (
              <div className="trending trend-down">
                <i className="material-icons">trending_down</i>
                <span>{metricNumber}%</span>
              </div>
            )}
            {metricNumber === 0 && (
              <div className="trending trend-flat">
                <i className="material-icons">trending_flat</i>
                <span>{metricNumber}%</span>
              </div>
            )}
          </div>,
          customMetricContainer
        );
      } else if (nextProps.chartProps.type && nextProps.chartProps.type.toLowerCase() === 'label') {
        ReactDOM.render(
          <div className="type-label">
            <div className="label">
              <span>{metricNumber}</span>
            </div>
          </div>,
          customMetricContainer
        );
      }
    }
  }

  return (
    <Paper
      className={classNames('Metric', chartProps.type, className)}
      style={chartProps.hide_chart && { marginBottom: 0 }}>
      <Toolbar variant="dense" className="metric-toolbar" disableGutters>
        {chartProps.title && (
          <Typography variant="h6" className="metric-title">
            {chartProps.title}
            {!chartProps.custom && metricNb ? (
              <span className="metric-nbr">{metricNb}</span>
            ) : (
              false
            )}
            {isNbLoading && <CircularProgress size={20} style={{ marginLeft: 8 }} />}
          </Typography>
        )}
        {actions ? actions : false}
      </Toolbar>
      {isLoading && (
        <div className="loader-wpr">
          <CircularProgress size={40} />
        </div>
      )}
      {error ? (
        <Alert type="danger" status={translation().commons.alerts.error} text={error} />
      ) : (
        !isLoading &&
        !chartProps.hide_chart &&
        !chartProps.custom && <canvas id={chartProps.name + '-metric-chart'} />
      )}
      {!error && !warning && !isLoading && chartProps.custom && (
        <div className="metrics-custom-chart-container" id={chartProps.name + '-custom-chart'} />
      )}
      {warning && (
        <Alert type="warning" status={translation().commons.alerts.warning} text={warning} />
      )}
    </Paper>
  );
}

Metric.propTypes = {
  getMetrics: PropTypes.func.isRequired,
  className: PropTypes.string,
  chartProps: PropTypes.shape().isRequired,
  filterKey: PropTypes.any,
  filterKeys: PropTypes.any,
  match: PropTypes.shape(),
  actions: PropTypes.node,
  queryParams: PropTypes.shape().isRequired,
  themeMode: PropTypes.string,
  submitBuffer: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  routeName: PropTypes.string.isRequired
};

export default Metric;
