import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Link, Paper, Typography } from '@material-ui/core';

import translation from '../../../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      textAlign: 'center'
    }
  },
  box: {
    padding: 12,
    width: '100%'
  },
  img: {
    maxWidth: 200,
    width: '100%',
    height: 'auto',
    margin: 'auto'
  },
  imgBox: {
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right'
    }
  },
  title: {
    marginBottom: 12,
    fontSize: 18,
    textAlign: 'center'
  },
  label: {
    fontWeight: 500,
    marginRight: 4
  }
}));

function ProviderCreated({ provider, user }) {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Paper className={classes.root} elevation={1}>
        <Typography variant="h5" className={classes.title}>
          {translation().views.provider_factory.steps.user_provider.created} (
          <Link target="_blank" href={`/providers/${provider.id}/update`}>
            #{provider.id}
          </Link>
          )
        </Typography>
        <div className={classes.container}>
          <div className={classNames(classes.box, classes.imgBox)}>
            <img
              src="/assets/images/production/provider-factory-step-completed.svg"
              alt="Step completed"
              className={classes.img}
            />
          </div>
          <div className={classes.box}>
            <Typography>
              <Typography component="span" className={classes.label}>
                {translation().views.provider_factory.steps.user_provider.labels.first_name}:
              </Typography>
              {user.first_name}
            </Typography>
            <Typography>
              <Typography component="span" className={classes.label}>
                {translation().views.provider_factory.steps.user_provider.labels.last_name}:
              </Typography>
              {user.last_name}
            </Typography>
            <Typography>
              <Typography component="span" className={classes.label}>
                User ID:
              </Typography>
              <Link target="_blank" href={`/users/${user.id}/update`}>
                #{user.id}
              </Link>
            </Typography>
            <Typography>
              <Typography component="span" className={classes.label}>
                {translation().views.provider_factory.steps.user_provider.labels.email}:
              </Typography>
              {user.email}
            </Typography>
            <Typography>
              <Typography component="span" className={classes.label}>
                Ghost:
              </Typography>
              {provider.ghost ? translation().interface.yes : translation().interface.no}
            </Typography>
          </div>
        </div>
      </Paper>
    </Grid>
  );
}

ProviderCreated.propTypes = {
  provider: PropTypes.shape({
    id: PropTypes.number.isRequired,
    ghost: PropTypes.bool
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired
  }).isRequired
};

export { ProviderCreated };
