import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Chip, CircularProgress } from '@material-ui/core';
import { Check } from '@material-ui/icons';

import translation from '../../../../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: 8,
    minWidth: 50,
    textTransform: 'capitalize'
  },
  1: {
    fontWeight: theme.palette.type === 'dark' ? 700 : 500,
    color: '#212b36',
    backgroundColor:
      theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.16)' : 'rgba(120, 149, 185, 0.25)',
    '&:not(.readOnly):focus, &:not(.readOnly):hover, &.checked': {
      fontWeight: 500,
      color: '#212b36',
      backgroundColor: '#b8c4d2'
    }
  },
  2: {
    fontWeight: theme.palette.type === 'dark' ? 700 : 500,
    color: '#212b36',
    backgroundColor: 'rgba(255, 206, 66, 0.4)',
    '&:not(.readOnly):focus, &:not(.readOnly):hover, &.checked': {
      fontWeight: 500,
      color: '#212b36',
      backgroundColor: '#ffce42'
    }
  },
  3: {
    fontWeight: theme.palette.type === 'dark' ? 700 : 500,
    color: theme.palette.type === 'dark' ? '#ff4842' : '#b72136',
    backgroundColor: 'rgba(255, 72, 66, 0.25)',
    '&:not(.readOnly):focus, &:not(.readOnly):hover, &.checked': {
      fontWeight: 500,
      color: '#FFFFFF',
      backgroundColor: '#ff4842'
    }
  },
  check: {
    color: 'currentColor'
  }
}));

function PriorityChip(props) {
  const classes = useStyles();

  const { checked, className, disabled, loading, onChange, onClick, value } = props;

  function handleClick(e) {
    if (onClick) {
      onClick(e);
    }

    if (onChange && value) {
      onChange(value);
    }
  }

  const labels = {
    1: translation().views.task_views.tasks.labels.priority_normal,
    2: translation().views.task_views.tasks.labels.priority_high,
    3: translation().views.task_views.tasks.labels.priority_urgent
  };

  return (
    <Chip
      className={classNames(classes[value], classes.root, className, {
        checked: checked === value,
        readOnly: loading || (!onClick && !onChange)
      })}
      label={
        loading ? <CircularProgress size={18} style={{ verticalAlign: 'middle' }} /> : labels[value]
      }
      onClick={handleClick}
      clickable={!loading && (Boolean(onClick) || Boolean(onChange))}
      disabled={disabled || loading}
      icon={(onClick || onChange) && checked === value ? <Check className={classes.check} /> : null}
    />
  );
}

PriorityChip.defaultProps = {
  disabled: false,
  loading: false
};

PriorityChip.propTypes = {
  checked: PropTypes.number,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  value: PropTypes.number
};

export default PriorityChip;
