const initialState = {
  error: false,
  errors: null,
  isLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_REDUCERS':
      return initialState;
    case 'UPDATE_USER_SELF':
      return {
        ...state,
        error: false,
        errors: null,
        isLoading: true
      };
    case 'UPDATE_USER_SELF_SUCCESS':
      return {
        ...state,
        error: false,
        errors: null,
        isLoading: false
      };
    case 'UPDATE_USER_SELF_ERROR':
      return {
        ...state,
        error: action.error,
        isLoading: false
      };
    case 'UPDATE_USER_SELF_ERRORS':
      return {
        ...state,
        errors: action.errors
      };
    default:
      return state;
  }
};
