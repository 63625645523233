import './mediasDropper.scss';

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import Alert from '../../../components/alert/Alert';
import MediaGroup from './components/MediaGroup';

import translation from '../../../translation/translation';

function MediasDropper(props) {
  const {
    clearMediaGroups,
    clearMediasErrors,
    downloadOrderProductMedias,
    forceUpload,
    getProductMediaGroups,
    medias: { errors, orderProductMedias },
    mediaGroups: { data, download_action, download_settings, isLoading, upload_action },
    onUploadSuccess,
    onUploadFailure,
    product,
    removeMediaTypeMedias,
    uploadOrder
  } = props;

  if (forceUpload) {
    product.uploadable = true;
  }

  useEffect(() => {
    getProductMediaGroups(product.order_id, product.id);

    return () => {
      clearMediaGroups();
      clearMediasErrors();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function checkIsOrderReadyToSend() {
    let mediaTypesReady = 0;

    if (orderProductMedias && Object.keys(orderProductMedias).length) {
      for (let mediaType in orderProductMedias) {
        const nbMedias = orderProductMedias[mediaType].mediasBucket
          ? orderProductMedias[mediaType].mediasBucket.length
          : 0;
        const nbMin = orderProductMedias[mediaType].quantity_min
          ? orderProductMedias[mediaType].quantity_min
          : 0;
        const nbMax = orderProductMedias[mediaType].quantity_max
          ? orderProductMedias[mediaType].quantity_max
          : 10000000;

        if (nbMedias >= nbMin && nbMedias <= nbMax) {
          mediaTypesReady++;
        }
      }

      if (mediaTypesReady === Object.keys(orderProductMedias).length) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  function handleUploadOrder() {
    if (upload_action.isLoading) {
      return;
    }

    if (orderProductMedias && product.order_id && product.id) {
      uploadOrder(
        orderProductMedias,
        product.order_id,
        product.id,
        () => {
          if (onUploadSuccess) {
            onUploadSuccess();
          }
        },
        () => {
          if (onUploadFailure) {
            onUploadFailure();
          }
        }
      );
    }
  }

  function renderMediaGroups() {
    let mediaGroupsArray = [];

    if (!product || !product.id || !product.order_id) {
      return false;
    }

    if (data && data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        mediaGroupsArray.push(
          <MediaGroup
            key={i}
            errors={errors}
            mediaGroup={data[i]}
            productId={product.id}
            orderId={product.order_id}
            downloadAction={download_action}
            downloadSettings={download_settings}
            downloadOrderProductMedias={downloadOrderProductMedias}
            removeMediaTypeMedias={removeMediaTypeMedias}
          />
        );
      }
    }

    return mediaGroupsArray;
  }

  return (
    <Container maxWidth={false} className="MediasDropper">
      {product && product.uploadable && (
        <Grid container justify="flex-end">
          <Grid item>
            <div className="upload-wrapper">
              {upload_action.isLoading && (
                <div className="loader-wpr">
                  <CircularProgress size={25} style={{ marginRight: 8 }} />
                  <p>{translation().views.orders.media_groups.uploading}</p>
                </div>
              )}
              <Button
                variant="contained"
                color="secondary"
                disabled={!checkIsOrderReadyToSend() || upload_action.isLoading}
                onClick={handleUploadOrder}
                startIcon={<CloudUploadIcon fontSize="small" />}>
                {translation().actions.upload}
              </Button>
            </div>
            <div className="md-space" />
          </Grid>
        </Grid>
      )}
      {product && product.uploadable ? (
        !isLoading ? (
          renderMediaGroups()
        ) : (
          <div className="loader-wpr">
            <CircularProgress color="primary" />
            <p>{translation().views.orders.media_groups.loading_text}</p>
          </div>
        )
      ) : (
        <Alert
          style={{ marginTop: 10 }}
          type="danger"
          status={translation().commons.alerts.error}
          text={translation().views.orders.media_groups.unuploadable}
        />
      )}
      {product && product.uploadable && (
        <Grid container justify="flex-end">
          <Grid item>
            <div className="md-space" />
            <div className="upload-wrapper">
              {upload_action.isLoading && (
                <div className="loader-wpr">
                  <CircularProgress size={25} style={{ marginRight: 8 }} />
                  <p>{translation().views.orders.media_groups.uploading}</p>
                </div>
              )}
              <Button
                variant="contained"
                color="secondary"
                disabled={!checkIsOrderReadyToSend() || upload_action.isLoading}
                onClick={handleUploadOrder}
                startIcon={<CloudUploadIcon fontSize="small" />}>
                {translation().actions.upload}
              </Button>
            </div>
          </Grid>
        </Grid>
      )}
    </Container>
  );
}

MediasDropper.defaultProps = {
  forceUpload: false
};

MediasDropper.propTypes = {
  clearMediaGroups: PropTypes.func.isRequired,
  clearMediasErrors: PropTypes.func.isRequired,
  downloadOrderProductMedias: PropTypes.func.isRequired,
  getProductMediaGroups: PropTypes.func.isRequired,
  forceUpload: PropTypes.bool,
  mediaGroups: PropTypes.shape({
    upload_action: PropTypes.shape(),
    data: PropTypes.arrayOf(PropTypes.shape()),
    isLoading: PropTypes.bool,
    download_action: PropTypes.shape().isRequired,
    download_settings: PropTypes.shape().isRequired
  }),
  medias: PropTypes.shape({
    errors: PropTypes.shape(),
    orderProductMedias: PropTypes.shape()
  }),
  product: PropTypes.shape({
    id: PropTypes.any.isRequired,
    order_id: PropTypes.any,
    uploadable: PropTypes.bool
  }),
  uploadOrder: PropTypes.func.isRequired,
  onUploadFailure: PropTypes.func,
  onUploadSuccess: PropTypes.func,
  removeMediaTypeMedias: PropTypes.func.isRequired
};

export default MediasDropper;
