const initialState = {
  isError: false,
  isLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_REDUCERS':
      return initialState;
    case 'CANCEL_DELETE_RESOURCE_ITEM':
      return initialState;
    case 'DELETE_RESOURCE_ITEM':
      return {
        ...state,
        isError: false,
        isLoading: true
      };
    case 'DELETE_RESOURCE_ITEM_SUCCESS':
      return {
        ...state,
        isError: false,
        isLoading: false
      };
    case 'DELETE_RESOURCE_ITEM_ERROR':
      return {
        ...state,
        isError: true,
        isLoading: false
      };
    default:
      return state;
  }
};
