import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withTheme } from '@material-ui/core/styles';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CategoryIcon from '@material-ui/icons/Category';
import CloseIcon from '@material-ui/icons/Close';
import CommuteIcon from '@material-ui/icons/Commute';
import DateRangeIcon from '@material-ui/icons/DateRange';
import DescriptionIcon from '@material-ui/icons/Description';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import Restrictions from './Restrictions';
import translation from '../../../../../translation/translation';
import config from '../../../../../config';

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.type === 'dark' ? '#757575' : '#bdbdbd',
    color: theme.palette.type === 'dark' ? '#212121' : '#f1f1f1',
    padding: 8
  },
  cardHeader: {
    minHeight: 60,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.cardHeader
  },
  cardContent: {
    padding: theme.spacing(1)
  },
  paper: {
    minHeight: 450,
    overflow: 'auto',
    width: '100%',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      minHeight: 'auto'
    }
  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2)
  },
  listItemBox: {
    minWidth: 35
  },
  listItem: {
    padding: 4
  }
}));

function ProductSummary(props) {
  const classes = useStyles();

  const { product, theme } = props;

  const coverUri =
    product && theme.palette.type === 'dark' && product.icon_url_dark
      ? product.icon_url_dark
      : product.icon_url_light
      ? product.icon_url_light
      : null;

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Card className={classes.paper}>
            <CardHeader
              className={classes.cardHeader}
              title={translation().views.product_view.infos.info_product}
              titleTypographyProps={{
                variant: 'h5',
                style: {
                  fontSize: 21
                }
              }}
              avatar={
                coverUri ? (
                  <Avatar
                    className={classes.avatar}
                    aria-label="profile_picture"
                    src={coverUri}
                    alt={product.name.charAt(0).toUpperCase()}
                  />
                ) : (
                  <Avatar>{product.name.charAt(0).toUpperCase()}</Avatar>
                )
              }
              action={
                <IconButton
                  aria-label="Update product"
                  href={`/products/${product.id}/update`}
                  target="_blank">
                  <EditIcon fontSize="small" />
                </IconButton>
              }
            />
            <CardContent className={classes.cardContent}>
              <List>
                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.listItemBox}>
                    <Icon fontSize="small">inventory_2</Icon>
                  </ListItemIcon>
                  <ListItemText
                    primary={translation().views.product_view.infos.name}
                    secondary={product.name}
                  />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.listItemBox}>
                    <DescriptionIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={translation().views.product_view.infos.desc}
                    secondary={product.description ? product.description : '-'}
                  />
                </ListItem>
                {product.product_group && product.product_group.name && (
                  <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemBox}>
                      <CategoryIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={translation().views.product_view.infos.group}
                      secondary={product.product_group.name}
                    />
                  </ListItem>
                )}
                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.listItemBox}>
                    <DateRangeIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={translation().views.product_view.infos.require_planning}
                    secondary={
                      product.require_planning ? (
                        <DoneIcon
                          style={{ color: config.success_color || '#4CAF50' }}
                          fontSize="small"
                        />
                      ) : (
                        <CloseIcon
                          style={{ color: config.error_color || '#F44336' }}
                          fontSize="small"
                        />
                      )
                    }
                  />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.listItemBox}>
                    <CommuteIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={translation().views.product_view.infos.require_trip}
                    secondary={
                      product.require_trip ? (
                        <DoneIcon
                          style={{ color: config.success_color || '#4CAF50' }}
                          fontSize="small"
                        />
                      ) : (
                        <CloseIcon
                          style={{ color: config.error_color || '#F44336' }}
                          fontSize="small"
                        />
                      )
                    }
                  />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <ListItemIcon className={classes.listItemBox}>
                    <AssignmentTurnedInIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={translation().views.product_view.infos.active}
                    secondary={
                      product.active ? (
                        <DoneIcon
                          style={{ color: config.success_color || '#4CAF50' }}
                          fontSize="small"
                        />
                      ) : (
                        <CloseIcon
                          style={{ color: config.error_color || '#F44336' }}
                          fontSize="small"
                        />
                      )
                    }
                  />
                </ListItem>
                {product.delivery_time ? (
                  <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemBox}>
                      <AccessAlarmIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={translation().views.product_view.infos.delivery_time}
                      secondary={`${product.delivery_time}h`}
                    />
                  </ListItem>
                ) : (
                  false
                )}
                {product.duration_fixed ? (
                  <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.listItemBox}>
                      <TimelapseIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={translation().views.product_view.infos.duration}
                      secondary={`${product.duration_fixed}m`}
                    />
                  </ListItem>
                ) : (
                  false
                )}
              </List>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Card className={classes.paper}>
            <CardHeader
              className={classes.cardHeader}
              title={translation().views.product_view.restrictions.label}
              titleTypographyProps={{
                variant: 'h5',
                style: {
                  fontSize: 21
                }
              }}
              avatar={
                <Avatar>
                  <VisibilityOffIcon />
                </Avatar>
              }
              action={
                <IconButton
                  aria-label="Update product"
                  href={`/products/${product.id}/update`}
                  target="_blank">
                  <EditIcon fontSize="small" />
                </IconButton>
              }
            />
            <CardContent className={classes.cardContent}>
              <Restrictions restrictions={product.restrictions} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

ProductSummary.propTypes = {
  product: PropTypes.shape(),
  theme: PropTypes.shape().isRequired
};

export default withTheme(ProductSummary);
