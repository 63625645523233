import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import CreateUnavailabilityForm from '../forms/unavailabilities/create/CreateUnavailabilityForm';
import translation from '../../../../../../translation/translation';

const styles = (theme) => ({
  backgroundModal: {
    backgroundColor: theme.palette.background.paper
  }
});

function CreateUnavailabilityPopup(props) {
  function handleCreateUnavailability(values) {
    if (!values) return;

    const { createUnavailability } = props;

    createUnavailability(values);
  }

  function handleBlockBlur(e) {
    if (e) {
      e.stopPropagation();
    }
  }

  function handleClose() {
    const { onClose } = props;

    onClose();
  }

  const { createUnavailabilityLoading, classes, maxHour, minHour } = props;

  return (
    <div className="event-popup">
      <div className="create-unavailability">
        <div className="event-pp-header">
          <div className="event-actions">
            <IconButton
              aria-label="Close"
              className="event-action"
              disabled={createUnavailabilityLoading}
              onClick={handleClose}
              onBlur={handleBlockBlur}
              size="small">
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>
          <div className="event-title">
            <Typography>
              {translation().views.provider_schedule.unavailability.create.title}
            </Typography>
          </div>
        </div>
        <div className={classNames('event-pp-content', classes.backgroundModal)}>
          <div className="new-unavailability">
            <CreateUnavailabilityForm
              onSubmit={handleCreateUnavailability}
              createAction={{
                loading: createUnavailabilityLoading
              }}
              maxHour={maxHour}
              minHour={minHour}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

CreateUnavailabilityPopup.propTypes = {
  createUnavailability: PropTypes.func.isRequired,
  createUnavailabilityLoading: PropTypes.bool,
  classes: PropTypes.shape(),
  maxHour: PropTypes.number.isRequired,
  minHour: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired
};

export default withStyles(styles)(CreateUnavailabilityPopup);
