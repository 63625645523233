import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DirectionsIcon from '@material-ui/icons/Directions';
import Divider from '@material-ui/core/Divider';
import EventIcon from '@material-ui/icons/Event';
import FeedbackIcon from '@material-ui/icons/Feedback';
import LabelOffIcon from '@material-ui/icons/LabelOff';
import Link from '@material-ui/core/Link';
import NaturePeopleIcon from '@material-ui/icons/NaturePeople';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ReceiptIcon from '@material-ui/icons/Receipt';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import Typography from '@material-ui/core/Typography';

import { convertMinsToHrsMins, dateInTz } from '../../../utils/functions/dates';
import Alert from '../../../components/alert/Alert';
import MediasContainer from '../../../containers/medias/MediasContainer';
import OrderProductViewBar from './components/OrderProductViewBar';
import translation from '../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  bar: {
    ...theme.mixins.gutters(),
    paddingTop: 12,
    paddingBottom: 12,
    marginBottom: 12,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.type === 'dark' ? '#333333' : '#f1f1f1',
    color: theme.palette.type === 'dark' ? '#FFFFFF' : '#1A2123'
  },
  barDialog: {
    ...theme.mixins.gutters(),
    paddingTop: 12,
    paddingBottom: 12,
    marginBottom: 12,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.type === 'dark' ? '#3a3a3a' : '#f1f1f1',
    color: theme.palette.type === 'dark' ? '#FFFFFF' : '#1A2123'
  },
  labelWrapper: {
    marginLeft: 24
  },
  deliverButton: {
    marginLeft: 'auto',
    minWidth: 100
  },
  divider: {
    marginTop: 12,
    marginBottom: 12
  },
  infos: {
    ...theme.mixins.gutters()
  },
  info: {
    display: 'inline-flex',
    alignItems: 'center',
    marginRight: 16
  },
  name: {
    marginLeft: 8
  },
  option: {
    margin: 2
  },
  optionWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingTop: 8,
    marginBottom: 12
  },
  loader: {
    marginTop: 36
  }
}));

function OrderProduct(props) {
  const {
    clearOrderProduct,
    deliverOrderProduct,
    getOrderProductData,
    history,
    match: {
      params: { id }
    },
    mode = 'history',
    productId = null,
    onDeliver,
    orderProduct: { data, isLoading }
  } = props;

  const classes = useStyles();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const orderProductId = mode === 'dialog' && productId ? productId : id;

  useEffect(() => {
    document.title = `Actarus | Moderation #${orderProductId}`;

    getOrderProductData(orderProductId);

    return () => {
      clearOrderProduct();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleDeliverProduct() {
    if (!data[0] || !data[0].id || !data[0].order_id) {
      return;
    }

    deliverOrderProduct(data[0].order_id, data[0].id, () => {
      mode === 'dialog' && onDeliver ? onDeliver() : history.push('/production/moderation');
    });
  }

  function handleOpenConfirmDialog() {
    setIsConfirmDialogOpen(true);
  }

  function handleCloseConfirmDialog() {
    setIsConfirmDialogOpen(false);
  }

  return (
    <div className="OrderProductToModerate">
      {mode === 'history' && (
        <OrderProductViewBar history={history} productId={orderProductId} disabled={isLoading} />
      )}
      {data && data[0] ? (
        <div>
          <div className={mode === 'dialog' ? classes.barDialog : classes.bar}>
            <Avatar
              alt={data[0].name}
              src={data[0].icon_url_dark}
              style={{
                backgroundColor: data[0].flag
                  ? '#F44336'
                  : data[0].delivery_status.color
                  ? data[0].delivery_status.color
                  : '#e0e0e0',
                padding: 7
              }}
            />
            <Typography
              className={classes.name}
              style={{
                color: data[0].flag ? '#F44336' : 'currentColor',
                fontWeight: 500
              }}>
              {data[0].name}
            </Typography>
            <Button
              className={classes.deliverButton}
              variant="contained"
              color="secondary"
              disabled={isLoading}
              onClick={handleOpenConfirmDialog}
              startIcon={<CheckCircleOutlineIcon fontSize="small" />}>
              {translation().views.production.moderation.item.deliver}
            </Button>
          </div>
          {data[0].cancelled && (
            <Alert
              type="danger"
              className={classes.productCancelledLabel}
              style={{ margin: '0 0 10px' }}
              icon={<LabelOffIcon className="alert-icon" />}
              text={`${translation().views.orders.update.products.cancelled} ${dateInTz(
                data[0].cancelled,
                'localized-long-datetime'
              )}`}
            />
          )}
          <div className={classes.infos}>
            {data[0].order_id && (
              <Link
                className={classes.info}
                target="_blank"
                rel="noopenner noreferrer"
                href={`/orders/${data[0].order_id}/update`}>
                <ShoppingCartIcon fontSize="small" style={{ marginRight: 4 }} />{' '}
                {translation().views.production.moderation.item.order_num}
                {data[0].order_id}
              </Link>
            )}
            {data[0].start ? (
              <div className={classes.info}>
                <EventIcon fontSize="small" style={{ marginRight: 4 }} />{' '}
                {dateInTz(data[0].start, 'localized-date')}
              </div>
            ) : (
              ''
            )}
            {data[0].start ? (
              <div className={classes.info}>
                <AccessTimeIcon fontSize="small" style={{ marginRight: 4 }} />{' '}
                {dateInTz(data[0].start, 'HH:mm')}
              </div>
            ) : (
              ''
            )}
            {data[0].duration ? (
              <div className={classes.info}>
                <TimelapseIcon fontSize="small" style={{ marginRight: 4 }} />{' '}
                {data[0] && data[0].start ? convertMinsToHrsMins(data[0].duration, 'h') : ''}
              </div>
            ) : (
              ''
            )}
            <div className={classes.info}>
              <ReceiptIcon fontSize="small" style={{ marginRight: 4 }} /> {data[0].amount_formatted}
            </div>
            {data[0].provider_name && data[0].provider_id && (
              <div className={classes.info}>
                <Link
                  className={classes.info}
                  target="_blank"
                  rel="noopenner noreferrer"
                  href={`/providers/${data[0].provider_id}/update`}
                  style={{ margin: 0 }}>
                  <BusinessCenterIcon fontSize="small" style={{ marginRight: 4 }} />{' '}
                  {data[0].provider_name} (#{data[0].provider_id})
                </Link>
              </div>
            )}
            {data[0].delivery_status &&
              data[0].delivery_status.icon_url_dark &&
              data[0].delivery_status.name && (
                <div className={classes.info}>
                  <img
                    height="20"
                    width="20"
                    style={{ marginRight: 4 }}
                    src={data[0].delivery_status.icon_url_dark}
                    alt={data[0].delivery_status.name}
                  />
                  <span style={{ color: data[0].delivery_status.color }}>
                    {data[0].delivery_status.name}
                  </span>
                </div>
              )}
            <div style={{ marginTop: 4 }}>
              {data[0] && data[0].property_contact_name && (
                <div className={classes.info}>
                  <NaturePeopleIcon fontSize="small" style={{ marginRight: 4 }} />{' '}
                  {data[0].property_contact_name}{' '}
                  {data[0].property_contact_phone ? `(${data[0].property_contact_phone})` : ''}
                </div>
              )}
            </div>
            <div style={{ marginTop: 4 }}>
              {data[0] && data[0].property_access_details && (
                <div className={classes.info}>
                  <DirectionsIcon fontSize="small" style={{ marginRight: 4 }} />{' '}
                  {data[0].property_access_details}
                </div>
              )}
            </div>
            <div style={{ marginTop: 4 }}>
              {data[0] && data[0].property_contact_comment && (
                <div className={classes.info}>
                  <FeedbackIcon fontSize="small" style={{ marginRight: 4 }} />{' '}
                  {data[0].property_contact_comment}
                </div>
              )}
            </div>
            {data[0].options && data[0].options.length > 0 ? (
              <div className={classes.optionWrapper}>
                {data[0].options.map((option, id) => {
                  return (
                    <Chip key={id} className={classes.option} label={option.name} size="small" />
                  );
                })}
              </div>
            ) : (
              ''
            )}
          </div>
          <Divider className={classes.divider} />
          <MediasContainer forceUpload product={data[0]} />
        </div>
      ) : (
        !isLoading && (
          <div className={classes.infos}>
            <Alert
              type="info"
              style={{ marginTop: 10 }}
              status={translation().commons.alerts.info}
              text={translation().views.production.moderation.item.empty}
            />
          </div>
        )
      )}
      {isLoading && !isConfirmDialogOpen && (
        <div className={classNames('loader-wpr', classes.loader)}>
          <CircularProgress color="primary" />
          <p>{translation().views.production.moderation.item.loading_text}</p>
        </div>
      )}
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isConfirmDialogOpen}
        onClose={handleCloseConfirmDialog}
        aria-labelledby="order-product-delivery-confirmation"
        aria-describedby="order-product-delivery-confirmation-description"
        disableBackdropClick={isLoading}>
        <DialogTitle id="order-product-delivery-confirmation">
          {translation().views.production.moderation.item.confirm.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="order-product-delivery-confirmation-description">
            {translation().views.production.moderation.item.confirm.content}
          </DialogContentText>
          {isLoading && (
            <div className="loader-wpr" style={{ marginTop: 15 }}>
              <CircularProgress size={30} color="secondary" />
              <p>{translation().views.production.moderation.item.delivery.loading_text}</p>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} disabled={isLoading}>
            {translation().actions.cancel}
          </Button>
          <Button
            onClick={handleDeliverProduct}
            variant="contained"
            color="primary"
            disabled={isLoading}>
            {translation().actions.confirm}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

OrderProduct.propTypes = {
  clearOrderProduct: PropTypes.func.isRequired,
  deliverOrderProduct: PropTypes.func.isRequired,
  getOrderProductData: PropTypes.func.isRequired,
  history: PropTypes.shape().isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    }).isRequired
  }).isRequired,
  mode: PropTypes.oneOf(['history', 'dialog']),
  productId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onDeliver: PropTypes.func,
  orderProduct: PropTypes.shape().isRequired
};

export default OrderProduct;
