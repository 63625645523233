/**
 * Company ID metrics
 */
export default {
  name: 'Company metrics',
  routes_selector: [
    {
      label: 'Commandes',
      value: 'orders'
    }
  ],
  metrics_routes: {
    orders: {
      child_routes: {
        'orders-interval-auto': {
          route: 'orders',
          chart_props: {
            type: 'line',
            title: 'Nombre de commande:',
            metric_calc_mode: 'count'
          },
          query_params: {
            time_interval: 'auto'
          },
          filter_key: 'iam_company_id'
        },
        'orders-interval-auto-period': {
          route: 'orders',
          chart_props: {
            title: 'Évolution du nombre de commande (période):',
            type: 'trending',
            metric_calc_mode: 'avg',
            custom: true
          },
          query_params: {
            compare: 'period'
          },
          filter_key: 'iam_company_id'
        },
        'orders-revenue-interval-auto': {
          route: 'orders-revenue',
          chart_props: {
            type: 'line',
            title: "Chiffre d'affaires:",
            metric_calc_mode: 'count'
          },
          query_params: {
            time_interval: 'auto'
          },
          filter_key: 'iam_company_id'
        },
        'orders-revenue-interval-auto-compare-period': {
          route: 'orders-revenue',
          chart_props: {
            title: "Évolution du chiffre d'affaires (période):",
            type: 'trending',
            metric_calc_mode: 'avg',
            custom: true
          },
          query_params: {
            compare: 'period'
          },
          filter_key: 'iam_company_id'
        },
        'orders-avg-profit-margin-ratio-interval-auto': {
          route: 'orders-avg-profit-margin-ratio',
          chart_props: {
            type: 'line',
            title: 'Marge:',
            metric_calc_mode: 'function',
            metric_calc_function: {
              type: 'call-api',
              route: 'orders-avg-profit-margin-ratio',
              calc_mode: 'avg',
              query_params: {}
            }
          },
          query_params: {
            time_interval: 'auto'
          },
          filter_key: 'iam_company_id'
        },
        'orders-avg-profit-margin-ratio-interval-auto-compare-period': {
          route: 'orders-avg-profit-margin-ratio',
          chart_props: {
            title: 'Évolution de la marge (période):',
            type: 'trending',
            metric_calc_mode: 'avg',
            custom: true
          },
          query_params: {
            compare: 'period'
          },
          filter_key: 'iam_company_id'
        },
        'orders-avg-basket-interval-auto': {
          route: 'orders-avg-basket',
          chart_props: {
            type: 'line',
            title: 'Panier moyen:',
            metric_calc_mode: 'function',
            metric_calc_function: {
              type: 'call-api',
              route: 'orders-avg-basket',
              calc_mode: 'avg',
              query_params: {}
            }
          },
          query_params: {
            time_interval: 'auto'
          },
          filter_key: 'iam_company_id'
        },
        'orders-avg-basket-interval-auto-compare-period': {
          route: 'orders-avg-basket',
          chart_props: {
            title: 'Évolution panier moyen (période):',
            type: 'trending',
            metric_calc_mode: 'avg',
            custom: true
          },
          query_params: {
            compare: 'period'
          },
          filter_key: 'iam_company_id'
        }
      }
    }
  }
};
