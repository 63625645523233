import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

/**
 * Pure views
 */
import PageNotFound from '../views/pageNotFound/PageNotFound';
import HomeContainer from '../containers/views/HomeContainer';
import Lab from '../views/lab/Lab';
import Settings from '../views/settings/Settings';
import WhatsHot from '../views/whatshot/WhatsHot';

/**
 * Container views
 */
import UpdateDashboardMetricsContainer from '../containers/metrics/dashboard/update/UpdateDashboardMetricsContainer';
import ListDashboardMetricsContainer from '../containers/metrics/dashboard/list/ListDashboardMetricsContainer';
import ProfileContainer from '../containers/views/ProfileContainer';
import TaskViewsContainer from '../containers/views/tasks/TaskViewsContainer';
import TasksContainer from '../containers/views/tasks/TasksContainer';

import { objectMapToArray, countObjProps } from '../utils/functions/utils';

import routeMap from '../routes/routeMap';

/**
 * Here we are forced to use eslint disable line on the render arrow function.
 * Actualy there is no other way to pass props to our component.
 */
function buildRoutes(routeMap) {
  const routeToRender = [];

  objectMapToArray(routeMap, (index, item) => {
    objectMapToArray(item.resources, (index2, resource) => {
      if (resource.routes && countObjProps(resource.routes) > 0) {
        for (let route in resource.routes) {
          const Component = resource.routes[route].component;

          routeToRender.push(
            <Route
              key={route}
              exact
              path={'/' + route}
              // eslint-disable-next-line react/jsx-no-bind
              render={(props) => {
                return (
                  <Component
                    {...props}
                    {...resource.routes[route].props}
                    routeUri={route}
                    parentUri={item.nav_name}
                  />
                );
              }}
            />
          );
        }
      }
    });
  });

  return routeToRender;
}

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/" component={HomeContainer} />
      <Route exact path="/whats-hot" component={WhatsHot} />
      <Route exact path="/lab" component={Lab} />
      {buildRoutes(routeMap)}
      <Route exact path="/profile" component={ProfileContainer} />
      <Route exact path="/settings" component={Settings} />
      <Route exact path="/personal-tasks" component={TaskViewsContainer} />
      <Route exact path="/personal-tasks/:id" component={TasksContainer} />
      <Route exact path="/users/self/tasks" component={TasksContainer} />
      <Route exact path="/dashboard" component={ListDashboardMetricsContainer} />
      <Route exact path="/dashboard/:id/update" component={UpdateDashboardMetricsContainer} />
      <Route path="*" component={PageNotFound} />
      <Redirect from="*" to="/page-not-found" />
    </Switch>
  );
};

export default Routes;
