import classNames from 'classnames';
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Breadcrumbs,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography
} from '@material-ui/core';
import {
  ArrowBack as ArrowBackIcon,
  MoreVert as MoreIcon,
  NavigateNext as NavigateNextIcon
} from '@material-ui/icons';

import { isNavigationBackPossible, navigateBack } from '../../../../utils/functions/navigation';
import translation from '../../../../translation/translation';

const styles = (theme) => ({
  root: {
    width: '100%'
  },
  grow: {
    flexGrow: 1
  },
  barIcon: {
    padding: 10,
    marginLeft: 7
  },
  barLink: {
    '&:hover': {
      textDecoration: 'none'
    }
  },
  fontSizeSmall: {
    fontSize: '1.25rem'
  },
  iconMenuItem: {
    fontSize: 18,
    marginRight: 8
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
});

class CustomHref extends PureComponent {
  render() {
    return <a {...this.props}>{this.props.children}</a>;
  }
}

CustomHref.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired
};

class ProviderViewBar extends PureComponent {
  state = {
    mobileMoreAnchorEl: null,
    customDialogOpen: false
  };

  handleMobileMenuOpen = (event) => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  handleOpenCustomActionDialog = (route) => {
    this.setState({ customDialogOpen: route });
  };

  handleCloseCustomActionDialog = () => {
    this.setState({ customDialogOpen: false });
  };

  handleHistoryBack = () => {
    const { history } = this.props;
    navigateBack(history);
  };

  renderActions = (subRoutes, section) => {
    const { classes, disabled, item } = this.props;

    const mobileActions = [];
    const actions = [];

    if (item && item.id) {
      actions.push(
        <Tooltip
          key="pv-iconButton-route_update"
          title={translation().actions.update}
          disableHoverListener={disabled}
          placement="bottom">
          <div>
            <Link to={`/providers/${item.id}/update`}>
              <IconButton className={classes.barIcon} disabled={disabled}>
                <i className={classNames('material-icons', classes.fontSizeSmall)}>edit</i>
              </IconButton>
            </Link>
          </div>
        </Tooltip>
      );
      mobileActions.push(
        <Link to={`/providers/${item.id}/update`} key="pv-link-route_update">
          <MenuItem>
            <i className={classNames('material-icons', classes.iconMenuItem)}>edit</i>
            <p>{translation().actions.update}</p>
          </MenuItem>
        </Link>
      );
    }

    if (subRoutes) {
      for (let route in subRoutes) {
        if (subRoutes[route].is_show_in_table && item && item.id) {
          if (subRoutes[route].is_route) {
            actions.push(
              <Tooltip
                key={'pv-iconButton-route-' + subRoutes[route].name}
                title={subRoutes[route].name}
                disableHoverListener={disabled}
                placement="bottom">
                <div>
                  <Link to={`${route.replace(':id', item.id)}`}>
                    <IconButton className={classes.barIcon} disabled={disabled}>
                      <i className={classNames('material-icons', classes.fontSizeSmall)}>
                        {subRoutes[route].icon}
                      </i>
                    </IconButton>
                  </Link>
                </div>
              </Tooltip>
            );

            mobileActions.push(
              <Link
                to={`${route.replace(':id', item.id)}`}
                key={'pv-mobile-custom-' + subRoutes[route].name}>
                <MenuItem>
                  <i className={classNames('material-icons', classes.iconMenuItem)}>
                    {subRoutes[route].icon}
                  </i>
                  <p>{subRoutes[route].name}</p>
                </MenuItem>
              </Link>
            );
          } else if (subRoutes[route].is_modal) {
            actions.push(
              <Tooltip
                key={'pv-custom-dialog-' + subRoutes[route].name}
                title={subRoutes[route].name}
                disableHoverListener={disabled}
                placement="bottom">
                <div>
                  <IconButton
                    className={classes.barIcon}
                    disabled={disabled}
                    //eslint-disable-next-line
                    onClick={() => this.handleOpenCustomActionDialog(route)}>
                    <i className={classNames('material-icons', classes.fontSizeSmall)}>
                      {subRoutes[route].icon}
                    </i>
                  </IconButton>
                </div>
              </Tooltip>
            );

            mobileActions.push(
              <MenuItem
                key={'pv-menu-customdialog-item-' + subRoutes[route].name}
                //eslint-disable-next-line
                onClick={() => this.handleOpenCustomActionDialog(route)}>
                <i className={classNames('material-icons', classes.iconMenuItem)}>
                  {subRoutes[route].icon}
                </i>
                <p>{subRoutes[route].name}</p>
              </MenuItem>
            );
          } else if (subRoutes[route].is_link) {
            let link = null;

            if (subRoutes[route].props && subRoutes[route].props.link_url) {
              if (subRoutes[route].props.link_url.indexOf(':id') > -1)
                link = subRoutes[route].props.link_url.replace(':id', item.id);
              else link = subRoutes[route].props.link_url;
            }

            actions.push(
              <Tooltip
                key={'iconButton-link-' + subRoutes[route].name}
                title={subRoutes[route].name}
                disableHoverListener={disabled}
                placement="bottom">
                <div>
                  <CustomHref href={link} target="_blank" className={classes.barLink}>
                    <IconButton className={classes.barIcon} disabled={disabled}>
                      <i className={classNames('material-icons', classes.fontSizeSmall)}>
                        {subRoutes[route].icon}
                      </i>
                    </IconButton>
                  </CustomHref>
                </div>
              </Tooltip>
            );

            mobileActions.push(
              <MenuItem
                target="_blank"
                key={'pv-menu-link-' + subRoutes[route].name}
                href={link}
                component={CustomHref}>
                <i className={classNames('material-icons', classes.iconMenuItem)}>
                  {subRoutes[route].icon}
                </i>
                <p>{subRoutes[route].name}</p>
              </MenuItem>
            );
          }
        }
      }
    }

    if (section === 'mobile') {
      return mobileActions;
    }

    return actions;
  };

  renderCustomActionsDialog = (subRoutes) => {
    const customActionsDialog = [];
    const { customDialogOpen } = this.state;
    const { item, handleRefresh } = this.props;

    for (let route in subRoutes) {
      if (subRoutes[route].is_show_in_table) {
        if (subRoutes[route].is_modal) {
          const ActionComponent = subRoutes[route].container ? subRoutes[route].container : false;

          customActionsDialog.push(
            <Dialog
              key={route}
              open={customDialogOpen === route ? true : false}
              onClose={this.handleCloseCustomActionDialog}
              aria-labelledby={route + '-custom-dialog-title'}
              aria-describedby={route + '-custom-dialog-description'}
              maxWidth={
                subRoutes[route].props &&
                subRoutes[route].props.dialog &&
                subRoutes[route].props.dialog.maxWidth
                  ? subRoutes[route].props.dialog.maxWidth
                  : 'sm'
              }
              fullWidth={
                subRoutes[route].props &&
                subRoutes[route].props.dialog &&
                subRoutes[route].props.dialog.fullWidth
                  ? true
                  : false
              }>
              <DialogTitle id={route + '-custom-dialog-title'}>
                {subRoutes[route].props.title}
              </DialogTitle>
              <DialogContent>
                {subRoutes[route].props.description && (
                  <DialogContentText id={route + '-custom-dialog-description'}>
                    {subRoutes[route].props.description}
                  </DialogContentText>
                )}
                {ActionComponent && (
                  <ActionComponent
                    resource={item ? item : {}}
                    resourceId={item && item.id ? item.id : null}
                    // eslint-disable-next-line
                    closeDialogFunction={this.handleCloseCustomActionDialog}
                    reloadListFunction={handleRefresh}
                    fullProps={subRoutes[route].props}
                  />
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleCloseCustomActionDialog} color="default">
                  {translation().actions.close}
                </Button>
              </DialogActions>
            </Dialog>
          );
        }
      }
    }

    return customActionsDialog;
  };

  render() {
    const { mobileMoreAnchorEl } = this.state;
    const { classes, disabled, history, parentUri, subRoutes, routeUri } = this.props;

    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const renderMobileMenu = isNavigationBackPossible(history) ? (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        onClose={this.handleMobileMenuClose}>
        <MenuItem onClick={this.handleHistoryBack}>
          <ArrowBackIcon fontSize="small" style={{ marginRight: 8 }} />
          <p>{translation().actions.back}</p>
        </MenuItem>
        {this.renderActions(subRoutes, 'mobile')}
      </Menu>
    ) : (
      false
    );

    return (
      <div className={classes.root}>
        <AppBar elevation={0} position="static" color="default">
          <Toolbar>
            <Breadcrumbs
              maxItems={4}
              separator={<NavigateNextIcon fontSize="small" />}
              arial-label="Update view breadcrumb">
              <Typography variant="body2" color="textPrimary">
                {parentUri}
              </Typography>
              {routeUri.length &&
                routeUri.split('/').map((n, i) => {
                  return (
                    <Typography variant="body2" color="textPrimary" key={i}>
                      {n}
                    </Typography>
                  );
                })}
            </Breadcrumbs>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              {this.renderActions(subRoutes, 'desktop')}
              {isNavigationBackPossible(history) && (
                <Tooltip
                  title={translation().actions.back || 'Back'}
                  aria-label={translation().actions.back || 'Back'}
                  enterDelay={700}
                  disabled={disabled}>
                  <div>
                    <IconButton
                      color="inherit"
                      className={classes.barIcon}
                      disabled={disabled}
                      onClick={this.handleHistoryBack}>
                      <ArrowBackIcon fontSize="small" />
                    </IconButton>
                  </div>
                </Tooltip>
              )}
            </div>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-haspopup="true"
                onClick={this.handleMobileMenuOpen}
                color="inherit"
                disabled={disabled}>
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        {this.renderCustomActionsDialog(subRoutes)}
      </div>
    );
  }
}

ProviderViewBar.propTypes = {
  disabled: PropTypes.bool,
  classes: PropTypes.shape().isRequired,
  handleRefresh: PropTypes.func.isRequired,
  history: PropTypes.shape().isRequired,
  item: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  parentUri: PropTypes.string.isRequired,
  routeUri: PropTypes.string.isRequired,
  subRoutes: PropTypes.shape()
};

export default withStyles(styles)(ProviderViewBar);
