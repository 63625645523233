import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Chip, Popover } from '@material-ui/core';

import PriorityChip from '../../display/priority/PriorityChip';
import PriorityFlag from '../../display/priority/PriorityFlag';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1)
  },
  chip: {
    margin: '4px 0px 4px 0px'
  }
}));

function InputPriority(props) {
  const classes = useStyles();

  const { id, name, onChange, value, variant, write } = props;

  const [loading, setLoading] = useState(false);
  const [innerValue, setInnerValue] = useState(value);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const ppvid = open ? 'select-priority-popover' : undefined;

  function handleClick(e) {
    setAnchorEl(e.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleChange(val) {
    handleClose();
    setInnerValue(val);
    setLoading(true);

    onChange(
      id,
      { [name]: val },
      (success) => {
        setLoading(false);
        setInnerValue(success[name]);
      },
      () => {
        setLoading(false);
        setInnerValue(value);
      }
    );
  }

  return (
    <>
      {innerValue ? (
        variant === 'flag' ? (
          <PriorityFlag
            value={innerValue}
            loading={loading}
            onClick={handleClick}
            disabled={!write}
          />
        ) : (
          <PriorityChip
            value={innerValue}
            loading={loading}
            onClick={handleClick}
            disabled={!write}
          />
        )
      ) : (
        !loading && <Chip label="-" onClick={handleClick} />
      )}
      <Popover
        id={ppvid}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        PaperProps={{
          className: classes.root
        }}>
        <PriorityChip
          onChange={handleChange}
          value={1}
          checked={innerValue}
          className={classes.chip}
        />
        <PriorityChip
          onChange={handleChange}
          value={2}
          checked={innerValue}
          className={classes.chip}
        />
        <PriorityChip
          onChange={handleChange}
          value={3}
          checked={innerValue}
          className={classes.chip}
        />
      </Popover>
    </>
  );
}

InputPriority.defaultProps = {
  variant: 'chip',
  write: true
};

InputPriority.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  variant: PropTypes.oneOf(['flag', 'chip']),
  write: PropTypes.bool
};

export default InputPriority;
