import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@material-ui/core';
import { AttachFile, Check } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import translation from '../../../../../../translation/translation';
import { enqueueSnackbar } from '../../../../../../redux/actions/appActions';
import DropContainer from '../../../../../../components/medias/minimalDropper/DropContainer';
import { fetchApi } from '../../../../../../utils/functions/api';

const useStyles = makeStyles(() => ({
  badge: {
    padding: 0,
    fontSize: '0.61rem',
    lineHeight: 0,
    height: 16,
    minWidth: 16,
    right: 10,
    top: 10
  },
  grow: {
    flexGrow: 1
  },
  dialogLoading: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 4px'
  }
}));

function InputAttachments(props) {
  const { attachments, disabled, taskId, viewId } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState(attachments);
  const [saved, setSaved] = useState(attachments);

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
    setFiles(saved);
  }

  function handleChange(e) {
    setFiles(e);
  }

  function handleUpload() {
    if (!taskId || !viewId) return;

    setLoading(true);

    fetchApi(
      'put',
      `users/self/task-views/${viewId}/tasks/${taskId}`,
      null,
      { attachments: files },
      null,
      (success) => {
        setLoading(false);

        if (success && success.attachments) {
          setSaved(success.attachments);
          setOpen(false);
        }

        dispatch(
          enqueueSnackbar({
            message: translation().views.task_views.tasks.attachments.callbacks.success,
            options: {
              variant: 'success'
            }
          })
        );
      },
      () => {
        setLoading(false);
        setFiles(saved);
        dispatch(
          enqueueSnackbar({
            message: translation().views.task_views.tasks.attachments.callbacks.error,
            options: {
              variant: 'error'
            }
          })
        );
      }
    );
  }

  return (
    <>
      <Badge badgeContent={saved.length} color="primary" classes={{ badge: classes.badge }}>
        <IconButton onClick={handleOpen} disabled={disabled}>
          <AttachFile />
        </IconButton>
      </Badge>
      {open && (
        <Dialog open onClose={handleClose} fullWidth maxWidth="lg" disableBackdropClick={loading}>
          <DialogTitle>{translation().views.task_views.tasks.labels.attachments}</DialogTitle>
          <DialogContent>
            <DropContainer
              accept="*"
              defaultFiles={saved}
              onChange={handleChange}
              disabled={loading}
              showFilesName
              maxFiles={10}
            />
          </DialogContent>
          <DialogActions>
            {loading && (
              <Box className={classes.dialogLoading}>
                <CircularProgress size={25} style={{ marginRight: 4 }} />
                <Typography color="textSecondary">
                  {translation().views.task_views.tasks.attachments.loading_text}
                </Typography>
              </Box>
            )}
            <div className={classes.grow} />
            <Button disabled={loading} onClick={handleClose}>
              {translation().actions.close}
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleUpload}
              disabled={loading}
              startIcon={<Check fontSize="small" />}>
              {translation().actions.confirm}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

InputAttachments.defaultProps = {
  attachments: [],
  disabled: false
};

InputAttachments.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.shape()),
  disabled: PropTypes.bool,
  taskId: PropTypes.number.isRequired,
  viewId: PropTypes.number.isRequired
};

export default InputAttachments;
