import { createMuiTheme } from '@material-ui/core/styles';

import config from '../../config';

/**
 * WARNING: Every line on this file is required, you can edit one but never delete one key/line... without a lead validation.
 */
export default createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    type: 'dark',
    background: {
      default: '#212121',
      paper: '#333',
      cardHeader: '#3a3a3a'
    },
    primary: {
      main: config.primary_color || '#8378F4'
    },
    secondary: {
      main: config.secondary_color || '#4E4BC6'
    }
  },
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: '#3a3a3a'
      }
    },
    MuiPaper: {
      root: {
        backgroundColor: '#333'
      }
    },
    MuiMenuItem: {
      root: {
        minHeight: 32,
        fontSize: 15
      }
    },
    MuiTableCell: {
      root: {
        padding: 12
      }
    },
    MuiTypography: {
      caption: {
        fontSize: 10
      },
      body2: {
        fontSize: 12
      },
      subtitle2: {
        fontSize: 12
      },
      h2: {
        '@media (max-width: 768px)': {
          fontSize: '2.75rem'
        }
      }
    },
    MuiFormControlLabel: {
      label: {
        fontSize: 16
      }
    },
    MuiTableHead: {
      root: {
        backgroundColor: '#212121'
      }
    }
  },
  error: {
    dark: config.error_color || '#F44336'
  }
});
