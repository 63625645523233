import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

import { dateInTz } from '../../../utils/functions/dates';
import { getTimezone } from '../../../utils/functions/cookies';

const styles = (theme) => ({
  input: {
    color: theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.54)'
  }
});

function FormDateTimeField(props) {
  const {
    autoComplete,
    classes,
    defaultValue,
    disabled,
    helperText,
    id,
    error,
    fullWidth,
    label,
    margin,
    name,
    onBlur,
    onChange,
    timezone,
    required,
    value,
    write
  } = props;

  const [localValue, setLocalValue] = useState('');

  useEffect(() => {
    if (defaultValue && timezone) {
      setLocalValue(dateInTz(defaultValue, 'YYYY-MM-DDTHH:mm', timezone).toString());
    }
  }, [defaultValue, timezone]);

  useEffect(() => {
    if (value) {
      setLocalValue(dateInTz(value, 'YYYY-MM-DDTHH:mm', timezone).toString());
    }

    if (value === '') {
      setLocalValue('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  function handleChange(event) {
    setLocalValue(event.target.value);

    if (onChange) {
      onChange(event.target.value);
    }
  }

  return (
    <TextField
      id={id}
      autoComplete={autoComplete}
      disabled={!write || disabled}
      helperText={error || helperText}
      margin={margin}
      name={name}
      label={
        timezone ? (
          <span>
            {label} <i>({timezone})</i>
          </span>
        ) : (
          label
        )
      }
      error={error ? true : false}
      type="datetime-local"
      required={required}
      fullWidth={fullWidth}
      onBlur={onBlur}
      onChange={handleChange}
      value={localValue}
      InputLabelProps={{
        shrink: true
      }}
      InputProps={{
        classes: { input: localValue ? null : classes.input }
      }}
    />
  );
}

FormDateTimeField.defaultProps = {
  fullWidth: true,
  margin: 'normal',
  timezone: getTimezone(),
  write: true
};

FormDateTimeField.propTypes = {
  autoComplete: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  classes: PropTypes.shape(),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  margin: PropTypes.string,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  timezone: PropTypes.string,
  value: PropTypes.any,
  write: PropTypes.bool
};

export default withStyles(styles)(FormDateTimeField);
