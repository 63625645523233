import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchApi } from '../../utils/functions/api';
import { createDateUtc, formatDate } from '../../utils/functions/dates';
import { enqueueSnackbar } from '../../redux/actions/appActions';
import {
  clearOwnReducer,
  getProvidersProductCoverage,
  getProvidersProductCoverageSuccess,
  getProvidersProductCoverageError
} from '../../redux/actions/providers/providersMapActions';
import translation from '../../translation/translation';
import ProvidersMap from '../../views/providers/providersMap/ProvidersMap';

const mapStateToProps = (store) => ({
  providersMap: store.providers.providersMap
});

const mapDispatchToProps = (dispatch) => {
  return {
    clearOwnReducer() {
      dispatch(clearOwnReducer());
    },
    async getProvidersProductCoverage(values) {
      if (!values || !values.product_id) return;

      dispatch(getProvidersProductCoverage());

      const utcTimestamp = values.slot ? createDateUtc(values.slot) : null;

      let query = {
        product_id: values.product_id,
        slot: utcTimestamp ? formatDate(utcTimestamp, 'YYYY-MM-DDTHH:mm:ss') : null,
        mode: values.mode ? values.mode : null
      };

      try {
        await fetchApi(
          'get',
          'providers/product-coverage',
          query,
          null,
          null,
          (coverage) => {
            dispatch(getProvidersProductCoverageSuccess(coverage));
          },
          (error) => {
            dispatch(getProvidersProductCoverageError(true, error));

            if (error.response) {
              dispatch(
                enqueueSnackbar({
                  message:
                    error.response.data && error.response.data.detail
                      ? error.response.data.detail
                      : error.response.data.status_code
                      ? error.response.data.status_code + ': ' + translation().core.item.not_found
                      : translation().core.list.error_list,
                  status: error.response.status || undefined,
                  options: {
                    variant: 'error'
                  }
                })
              );
            } else {
              dispatch(
                enqueueSnackbar({
                  message: translation().core.list.error_list,
                  options: {
                    variant: 'error'
                  }
                })
              );
            }
          }
        );
      } catch (error) {
        dispatch(getProvidersProductCoverageError(true, error));
        dispatch(
          enqueueSnackbar({
            message: translation().core.list.error_list,
            options: {
              variant: 'error'
            }
          })
        );
      }
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProvidersMap));
