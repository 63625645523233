import React, { useEffect, useState, PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import LabelIcon from '@material-ui/icons/Label';
import LinearProgress from '@material-ui/core/LinearProgress';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import Alert from '../../../../../components/alert/Alert';
import FixedHeaderTable from '../../../../../components/tables/fixedHeaderTable/FixedHeaderTable';
import ListEmptyIcon from '../../../../../components/icons/ListEmptyIcon';
import LocalDateInfo from '../../../../../components/timezone/LocalDateInfo';
import { dateInTz, isDateValid } from '../../../../../utils/functions/dates';
import config from '../../../../../config';
import translation from '../../../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2)
  },
  tableContainer: {
    maxHeight: '450px!important'
  },
  iconWrapper: {
    minHeight: 42,
    display: 'flex',
    alignItems: 'center'
  },
  productNameWrapper: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 200
  },
  loadingWrapper: {
    width: '100%',
    height: 450,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      height: 'auto'
    }
  },
  iconStatus: {
    width: 27
  },
  labelImportant: {
    color: theme.palette.type === 'dark' ? '#cb6059' : config.error_color || '#F44336'
  },
  optionImportant: {
    backgroundColor: fade(
      theme.palette.type === 'dark' ? '#cb6059' : config.error_color || '#F44336',
      0.5
    )
  },
  orderLink: {
    marginTop: 0,
    marginBottom: 0,
    margin: 8
  }
}));

class CustomLink extends PureComponent {
  render() {
    return (
      <Link to={this.props.link} {...this.props}>
        {this.props.children}
      </Link>
    );
  }
}

CustomLink.propTypes = {
  link: PropTypes.string.isRequired,
  children: PropTypes.any
};

const createCancelToken = () => axios.CancelToken.source();

const GetDataApi = (getData, providerId) => {
  const [source] = useState(createCancelToken());

  useEffect(() => {
    getData(providerId, source);

    return () => {
      source.cancel('=> Cancel get provider order products.');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const GetMetadataApi = (getMetadata) => {
  const [source] = useState(createCancelToken());

  useEffect(() => {
    getMetadata(source);

    return () => {
      source.cancel('=> Cancel get metadata provider order products.');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const GetNextDataApi = (getData, url) => {
  const [source] = useState(createCancelToken());

  const doNext = (initialNext) => {
    if (initialNext) getData(url, source, initialNext);
  };

  useEffect(() => {
    return () => {
      source.cancel('=> Next: Cancelled.');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { doNext };
};

function OrderProducts(props) {
  const classes = useStyles();

  const columns = [
    'id',
    'flag',
    'name',
    'start',
    'order',
    'icon_url_dark',
    'amount_provider_reversion_tax_excluded_formatted',
    'options',
    'created',
    'cancelled',
    'delivery_status'
  ];

  const {
    cleanProviderViewOrderProductsReducer,
    getMetadata,
    getProviderViewOrderProducts,
    providerId,
    orderProducts: { data, isLoading, error, metadata, paging }
  } = props;

  useEffect(() => {
    return () => {
      cleanProviderViewOrderProductsReducer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  GetMetadataApi(getMetadata);
  GetDataApi(getProviderViewOrderProducts, providerId);

  const { doNext } = GetNextDataApi(getProviderViewOrderProducts, providerId);

  function handleScrollList() {
    if (!isLoading && paging && paging.next) {
      doNext(paging.next);
    }
  }

  function getTableBodyFromData(orderProducts) {
    const tableBody = [];

    for (let line in orderProducts) {
      let temp_body = [];

      for (let key in orderProducts[line]) {
        if (columns.indexOf(key) !== -1) {
          if (key === 'id') {
            temp_body[key] = orderProducts[line][key];
          }
          if (key === 'flag') {
            temp_body[key] = orderProducts[line][key];
          } else if (key === 'name') {
            temp_body[key] = orderProducts[line][key];
          } else if (key === 'order') {
            temp_body['client'] =
              orderProducts[line][key] &&
              orderProducts[line][key].user &&
              orderProducts[line][key].user.first_name &&
              orderProducts[line][key].user.last_name
                ? `${orderProducts[line][key].user.first_name} ${orderProducts[line][key].user.last_name}`
                : 'Missing client name';
            temp_body['timezone'] =
              orderProducts[line][key] &&
              orderProducts[line][key].property &&
              orderProducts[line][key].property.timezone_code
                ? orderProducts[line][key].property.timezone_code
                : undefined;
          } else if (key === 'icon_url_dark') {
            temp_body[key] = orderProducts[line][key];
          } else if (key === 'options') {
            let _options = [];

            for (let option in orderProducts[line][key]) {
              let options = {};
              options.name = orderProducts[line][key][option].name;
              _options.push(options);
            }

            temp_body['options'] = _options;
          } else if (key === 'start') {
            const date_start = orderProducts[line][key];
            const date_start_tz = dateInTz(orderProducts[line][key], 'localized-datetime');

            temp_body['startDate'] = date_start;
            temp_body['startDateTz'] = date_start_tz;
          } else if (key === 'created') {
            const date_created = dateInTz(orderProducts[line][key], 'localized-datetime');
            temp_body['orderDate'] = date_created;
          } else if (key === 'cancelled') {
            if (isDateValid(orderProducts[line][key])) {
              temp_body['cancelDate'] = dateInTz(orderProducts[line][key], 'localized-datetime');
            }
          } else if (key === 'delivery_status') {
            temp_body['delivery_status_icon'] = orderProducts[line][key].icon_url_dark;
            temp_body['delivery_status_name'] = orderProducts[line][key].name;
          } else if (key === 'amount_provider_reversion_tax_excluded_formatted') {
            temp_body['price'] =
              orderProducts[line].amount_provider_reversion_tax_excluded_formatted;
          }

          temp_body['order_id'] = orderProducts[line].order_id;
        }
      }

      tableBody.push(temp_body);
    }

    return tableBody;
  }

  function renderTable(data) {
    if (data && data.length) {
      const tableBody = getTableBodyFromData(data);

      const tableHeader = [
        <TableRow key="tableHeader">
          <TableCell>{translation().views.provider_view.order_products.table_header.id}</TableCell>
          <TableCell>
            {translation().views.provider_view.order_products.table_header.name}
          </TableCell>
          <TableCell>
            {translation().views.provider_view.order_products.table_header.client}
          </TableCell>
          <TableCell>
            {translation().views.provider_view.order_products.table_header.start}
          </TableCell>
          <TableCell>
            {translation().views.provider_view.order_products.table_header.amount}
          </TableCell>
          <TableCell>
            {translation().views.provider_view.order_products.table_header.options}
          </TableCell>
          <TableCell>
            {translation().views.provider_view.order_products.table_header.date}
          </TableCell>
          <TableCell>
            {translation().views.provider_view.order_products.table_header.cancelled}
          </TableCell>
          <TableCell>
            {translation().views.provider_view.order_products.table_header.status}
          </TableCell>
          <TableCell />
        </TableRow>
      ];

      const tableValues = tableBody.map((row, index) => {
        const typoClass = classNames({
          'product-cancelled': row.cancelDate,
          [`${classes.labelImportant}`]: row.flag
        });

        return (
          <TableRow key={index}>
            <TableCell className={typoClass}>
              <Typography variant="body2">{row.id}</Typography>
            </TableCell>
            <TableCell className={typoClass} style={{ maxWidth: 250 }}>
              <div className={classes.productNameWrapper}>
                <img src={row.icon_url_dark} height={25} alt={row.name} />
                <Typography variant="body2" style={{ margin: '0 0 0 8px' }}>
                  {row.name}
                </Typography>
              </div>
            </TableCell>
            <TableCell className={typoClass}>
              <Typography variant="body2">{row.client}</Typography>
            </TableCell>
            <TableCell className={typoClass}>
              <Typography variant="body2">{row.startDateTz}</Typography>
              {row.timezone && row.startDate && (
                <LocalDateInfo
                  timezone={row.timezone}
                  date={row.startDate}
                  className={classNames({ 'order-cancelled': row.cancelDate })}
                />
              )}
            </TableCell>
            <TableCell className={typoClass}>
              <Typography variant="body2">{row.price}</Typography>
            </TableCell>
            <TableCell style={{ maxWidth: 250 }}>
              {row.options && row.options.length > 0
                ? row.options.map((option, i) => (
                    <Chip
                      key={i}
                      size="small"
                      style={{ margin: 4 }}
                      label={option.name}
                      className={classNames({
                        [classes.optionImportant]: !row.cancelDate && row.flag
                      })}
                    />
                  ))
                : '-'}
            </TableCell>
            <TableCell className={typoClass}>
              <Typography variant="body2">{row.orderDate}</Typography>
            </TableCell>
            <TableCell className={typoClass}>
              <Typography variant="body2">{row.cancelDate || '-'}</Typography>
            </TableCell>
            <TableCell>
              <img
                className={classes.iconStatus}
                src={row.delivery_status_icon}
                title={row.delivery_status_name}
                alt={row.delivery_status_name}
              />
            </TableCell>
            <TableCell key={`action-${index}`} style={{ textAlign: 'right' }}>
              <Tooltip title={translation().actions.custom_list_actions.see_order}>
                <IconButton
                  className={classes.orderLink}
                  component={CustomLink}
                  link={`/orders/${row.order_id}/update`}
                  target="_blank">
                  <LabelIcon />
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        );
      });

      return (
        <FixedHeaderTable
          tableHeader={tableHeader}
          tableValues={tableValues}
          metadata={metadata}
          onScrollList={handleScrollList}
          isLoading={isLoading}
          className="FixedHeaderTableContainer"
          useMarginBottom={false}
        />
      );
    }
  }

  return (
    <div>
      {!metadata.isLoading && data && data.length ? renderTable(data) : false}
      {!isLoading && error && (!data || data.length <= 0) ? (
        <div className="error-list">
          <Alert
            type="danger"
            style={{ marginTop: 10 }}
            status={translation().commons.alerts.error}
            text={translation().core.list.error_list}
          />
        </div>
      ) : (
        false
      )}
      {(isLoading || metadata.isLoading) && !paging && (
        <div className={classNames(classes.loadingWrapper, 'loader-wpr')}>
          <div style={{ width: '100%' }}>
            <CircularProgress color="primary" />
            <p>{translation().views.provider_view.order_products.loading_text}</p>
            {metadata.isLoading && <p>{translation().core.load_metadata}</p>}
          </div>
        </div>
      )}
      {!metadata.isLoading &&
        !isLoading &&
        !metadata.isLoading &&
        !error &&
        (!data || !data.length) && (
          <div className="loader-wpr">
            <ListEmptyIcon />
            <p style={{ margin: '7px 0', lineHeight: 'initial' }}>
              {translation().views.provider_view.order_products.empty}
            </p>
          </div>
        )}
      {isLoading && paging && paging.next && (
        <>
          <LinearProgress color="primary" />
          <div className="loader-wpr linear">
            <p>{translation().core.list.load_next}</p>
          </div>
        </>
      )}
      {!metadata.isLoading && !isLoading && paging && !paging.next && paging.prev && (
        <div className="loader-wpr linear">
          <p>{translation().core.list.load_next_done}</p>
        </div>
      )}
    </div>
  );
}

OrderProducts.propTypes = {
  cleanProviderViewOrderProductsReducer: PropTypes.func.isRequired,
  getMetadata: PropTypes.func.isRequired,
  getProviderViewOrderProducts: PropTypes.func.isRequired,
  providerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  orderProducts: PropTypes.shape({
    metadata: PropTypes.shape({
      isLoading: PropTypes.bool
    }).isRequired,
    data: PropTypes.arrayOf(PropTypes.shape()),
    error: PropTypes.string,
    isLoading: PropTypes.bool,
    paging: PropTypes.shape({
      next: PropTypes.string,
      prev: PropTypes.string
    })
  }).isRequired
};

export default OrderProducts;
