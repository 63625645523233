import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  Typography
} from '@material-ui/core';

import {
  FormApiAutocompleteField,
  FormSwitchField,
  FormTextField
} from '../../../../../components/forms/fields';
import { isObjEmpty } from '../../../../../utils/functions/utils';
import translation from '../../../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  titleRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    fontSize: 18
  },
  switch: {
    marginLeft: 12
  },
  createCompany: {
    marginTop: 36
  }
}));

function Company(props) {
  const classes = useStyles();

  const [linkLoading, setLinkLoading] = useState(null);
  const [adminLoading, setAdminLoading] = useState(null);

  const {
    adminizeCompanyUser,
    checkUserProviderFactoryAdmin,
    company,
    companyChecked,
    linkUserToCompany,
    linkProviderToProviderCompany,
    onChangeCompany,
    onCheckCompany,
    onSelectCompany,
    provider,
    user
  } = props;

  function handleCheckCompany(checked) {
    onCheckCompany(checked);
  }

  function handleSelectCompany(value) {
    onSelectCompany(value);
  }

  const handleChangeCompany = (name) => (value) => {
    onChangeCompany(name, value);
  };

  function handleLinkUser() {
    if (!company.selected || !company.selected.id || !user.id) return;

    setLinkLoading(true);

    linkUserToCompany(
      company.selected.id,
      user.id,
      () => {
        linkProviderToProviderCompany(
          provider.id,
          company.selected.id,
          () => {
            setLinkLoading(false);
          },
          () => {
            setLinkLoading(false);
          }
        );
      },
      () => {
        setLinkLoading(false);
      }
    );
  }

  function handleAdminzeUser(event, checked) {
    if (!company.selected || !company.selected.id || !user.id) return;

    setAdminLoading(true);

    adminizeCompanyUser(
      company.selected.id,
      user.id,
      checked,
      () => {
        setAdminLoading(false);
        checkUserProviderFactoryAdmin(checked);
      },
      () => {
        setAdminLoading(false);
      }
    );
  }

  function handleChangeCompanyAdmin(event, checked) {
    onChangeCompany('admin', checked);
  }

  return (
    <Grid item xs={12}>
      <Paper className={classes.root} elevation={1}>
        <div className={classes.titleRoot}>
          <FormSwitchField
            disabled={company.loading || company.data || isObjEmpty(company.data) || company.linked}
            name="is_company"
            id="is-provider-company"
            defaultValue={companyChecked}
            autoComplete="none"
            onChange={handleCheckCompany}
            margin="none"
            className={classes.switch}
            label={
              <Typography variant="h5" className={classes.title}>
                {translation().views.provider_factory.steps.company.title}
              </Typography>
            }
          />
        </div>
        {companyChecked && (
          <>
            <Typography>{translation().views.provider_factory.steps.company.link}</Typography>
            <FormApiAutocompleteField
              name="link_u_to_c_field"
              label={
                translation().core.list.dialogs.custom_list_actions.link_user_to_company.input_label
              }
              placeholder={
                translation().core.list.dialogs.custom_list_actions.link_user_to_company
                  .input_placeholder
              }
              links={[{ rel: 'list', href: 'companies' }]}
              onSelectValue={handleSelectCompany}
              targetKey="id"
              getFullObject
              defaultValue={company.selected}
              disabled={company.loading || company.linked}
            />
            <div className={classes.alignRight}>
              <Button
                disabled={
                  !company.selected || !company.selected.id || linkLoading || company.linked
                }
                variant="contained"
                onClick={handleLinkUser}
                color="primary">
                {translation().core.list.dialogs.custom_list_actions.link_user_to_company.link}
              </Button>
              {company.linked && (
                <FormControlLabel
                  checked={company.admin}
                  control={<Switch color="primary" />}
                  label={
                    translation().core.list.dialogs.custom_list_actions.link_user_to_company.admin
                  }
                  labelPlacement="start"
                  disabled={
                    adminLoading ||
                    linkLoading ||
                    company.loading ||
                    !company.selected ||
                    !company.selected.id
                  }
                  onChange={handleAdminzeUser}
                />
              )}
              {(linkLoading || adminLoading) && (
                <div className="loader-wpr">
                  <CircularProgress color="primary" size={25} />
                  <p>
                    {
                      translation().core.list.dialogs.custom_list_actions.link_user_to_company
                        .loading_text
                    }
                  </p>
                </div>
              )}
            </div>
            {(!company.selected || !company.selected.id) && (
              <div className={classes.createCompany}>
                <Typography>{translation().views.provider_factory.steps.company.create}</Typography>
                <FormTextField
                  type="text"
                  name="legal_name"
                  label={translation().views.provider_factory.steps.company.labels.legal_name}
                  onChange={handleChangeCompany('legal_name')}
                  disabled={company.loading}
                  autoComplete="none"
                  required
                  error={
                    company.error && company.error['legal_name']
                      ? company.error['legal_name']
                      : null
                  }
                />
                <FormTextField
                  type="text"
                  name="commercial_name"
                  label={translation().views.provider_factory.steps.company.labels.commercial_name}
                  onChange={handleChangeCompany('commercial_name')}
                  disabled={company.loading}
                  autoComplete="none"
                  required
                  error={
                    company.error && company.error['commercial_name']
                      ? company.error['commercial_name']
                      : null
                  }
                />
                <FormTextField
                  type="text"
                  name="legal_id"
                  label={translation().views.provider_factory.steps.company.labels.legal_id}
                  onChange={handleChangeCompany('legal_id')}
                  disabled={company.loading}
                  autoComplete="none"
                  required
                  error={
                    company.error && company.error['legal_id'] ? company.error['legal_id'] : null
                  }
                />
                <FormTextField
                  type="text"
                  name="legal_identifier"
                  label={translation().views.provider_factory.steps.company.labels.legal_identifier}
                  onChange={handleChangeCompany('legal_identifier')}
                  disabled={company.loading}
                  autoComplete="none"
                  required
                  error={
                    company.error && company.error['legal_identifier']
                      ? company.error['legal_identifier']
                      : null
                  }
                />
                <FormTextField
                  type="text"
                  name="vat_number"
                  label={translation().views.provider_factory.steps.company.labels.vat_number}
                  onChange={handleChangeCompany('vat_number')}
                  disabled={company.loading}
                  autoComplete="none"
                  error={
                    company.error && company.error['vat_number']
                      ? company.error['vat_number']
                      : null
                  }
                />
                <FormControlLabel
                  style={{ marginTop: 12 }}
                  control={<Switch color="primary" />}
                  label={translation().views.provider_factory.steps.company.labels.admin}
                  disabled={company.loading}
                  labelPlacement="end"
                  onChange={handleChangeCompanyAdmin}
                />
              </div>
            )}
          </>
        )}
      </Paper>
    </Grid>
  );
}

Company.propTypes = {
  adminizeCompanyUser: PropTypes.func.isRequired,
  checkUserProviderFactoryAdmin: PropTypes.func.isRequired,
  company: PropTypes.shape({
    admin: PropTypes.bool,
    loading: PropTypes.bool,
    data: PropTypes.shape(),
    linked: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
    selected: PropTypes.shape({
      id: PropTypes.number.isRequired
    })
  }).isRequired,
  companyChecked: PropTypes.bool,
  linkUserToCompany: PropTypes.func.isRequired,
  linkProviderToProviderCompany: PropTypes.func.isRequired,
  onChangeCompany: PropTypes.func.isRequired,
  onCheckCompany: PropTypes.func.isRequired,
  onSelectCompany: PropTypes.func.isRequired,
  provider: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired
};

export default Company;
