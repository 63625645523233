import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  IconButton,
  Link,
  Typography
} from '@material-ui/core';
import {
  HomeWork as HomeWorkIcon,
  Payment as PaymentIcon,
  Receipt as ReceiptIcon,
  OpenInNew as OpenInNewIcon
} from '@material-ui/icons';

import Addresses from './Addresses';
import Invoices from './Invoices';
import InvoicesEmitted from './InvoicesEmitted';
import PaymentMethods from './PaymentMethods';
import translation from '../../../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    minHeight: 60,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.cardHeader
  },
  cardContent: {
    overflow: 'auto',
    minHeight: 420,
    padding: theme.spacing(1),
    '&:last-child': {
      paddingBottom: 0
    },
    [theme.breakpoints.down('md')]: {
      minHeight: 'auto'
    }
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(1)
  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2)
  },
  listItemBox: {
    minWidth: 35
  },
  listItem: {
    padding: 8
  },
  linksContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    minHeight: 50,
    marginBottom: 8
  },
  linkWrapper: {
    margin: theme.spacing(0, 1),
    '&:first-child': {
      marginRight: 24
    }
  },
  linkLoader: {
    display: 'flex',
    alignItems: 'center',
    padding: 0
  },
  loaderText: {
    marginLeft: 8
  },
  empty: {
    margin: 8,
    fontSize: 12
  },
  tableContainer: {
    maxHeight: 380,
    overflow: 'auto',
    paddingLeft: 8,
    paddingRight: 8
  }
}));

function CompanyInvoices(props) {
  const {
    addresses,
    cleanCompanyViewAddressesReducer,
    cleanCompanyViewInvoicesReducer,
    cleanCompanyViewInvoicesEmittedReducer,
    cleanCompanyViewPaymentMethodReducer,
    getCompanyViewAddresses,
    getCompanyViewInvoices,
    getCompanyViewInvoicesEmitted,
    getCompanyViewPaymentMethods,
    getInvoicerId,
    getPayerId,
    paymentMethods,
    invoicesEmitted,
    invoices,
    company
  } = props;

  const classes = useStyles();

  const [isPayerLoading, setPayerLoading] = useState(false);
  const [isInvoicerLoading, setInvoicerLoading] = useState(false);
  const [payerLink, setPayerLink] = useState(false);
  const [invoicerLink, setInvoicerLink] = useState(false);

  useEffect(() => {
    /**
     * Get the payer and the invoicer for links
     */
    loadPayer();
    loadInvoicer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function loadPayer() {
    setPayerLoading(true);

    getPayerId(
      company.id,
      (success) => {
        setPayerLoading(false);
        if (success && success[0]) {
          setPayerLink(`/payers/${success[0].id}/update`);
        }
      },
      () => {
        setPayerLoading(false);
      }
    );
  }

  function loadInvoicer() {
    setInvoicerLoading(true);

    getInvoicerId(
      company.id,
      (success) => {
        setInvoicerLoading(false);
        if (success && success[0]) {
          setInvoicerLink(`/invoicers/${success[0].id}/update`);
        }
      },
      () => {
        setInvoicerLoading(false);
      }
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.linksContainer}>
        <div className={classes.linkWrapper}>
          {isPayerLoading && (
            <div className={classNames('loader-wpr', classes.linkLoader)}>
              <CircularProgress color="primary" size={25} />
              <p className={classes.loaderText}>
                {translation().views.company_view.payer_bridge.payer_loading}
              </p>
            </div>
          )}
          {payerLink && (
            <div>
              <Typography component="span" variant="body2">
                {translation().views.company_view.payer_bridge.label}:{' '}
              </Typography>
              <Link href={payerLink} target="_blank">
                {payerLink}
              </Link>
            </div>
          )}
        </div>
        <div className={classes.linkWrapper}>
          {isInvoicerLoading && (
            <div className={classNames('loader-wpr', classes.linkLoader)}>
              <CircularProgress color="primary" size={25} />
              <p className={classes.loaderText}>
                {translation().views.company_view.invoicer_bridge.invoicer_loading}
              </p>
            </div>
          )}
          {invoicerLink && (
            <div>
              <Typography component="span" variant="body2">
                {translation().views.company_view.invoicer_bridge.label}:{' '}
              </Typography>
              <Link href={invoicerLink} target="_blank">
                {invoicerLink}
              </Link>
            </div>
          )}
        </div>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Card className={classes.paper}>
            <CardHeader
              className={classes.cardHeader}
              title={translation().views.company_view.invoices.label}
              titleTypographyProps={{
                variant: 'h5',
                style: {
                  fontSize: 21
                }
              }}
              avatar={
                <Avatar>
                  <ReceiptIcon />
                </Avatar>
              }
              action={
                <IconButton
                  aria-label="See all invoices"
                  href={`/companies/${company.id}/invoices`}
                  target="_blank">
                  <OpenInNewIcon fontSize="small" />
                </IconButton>
              }
            />
            <CardContent className={classes.cardContent}>
              <Invoices
                cleanCompanyViewInvoicesReducer={cleanCompanyViewInvoicesReducer}
                getCompanyViewInvoices={getCompanyViewInvoices}
                invoices={invoices}
                companyId={company.id}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Card className={classes.paper}>
            <CardHeader
              className={classes.cardHeader}
              title={translation().views.company_view.invoices_emitted.label}
              titleTypographyProps={{
                variant: 'h5',
                style: {
                  fontSize: 21
                }
              }}
              avatar={
                <Avatar>
                  <ReceiptIcon />
                </Avatar>
              }
              action={
                <IconButton
                  aria-label="See all invoices emitted"
                  href={`/companies/${company.id}/invoices-emitted`}
                  target="_blank">
                  <OpenInNewIcon fontSize="small" />
                </IconButton>
              }
            />
            <CardContent className={classes.cardContent}>
              <InvoicesEmitted
                cleanCompanyViewInvoicesEmittedReducer={cleanCompanyViewInvoicesEmittedReducer}
                getCompanyViewInvoicesEmitted={getCompanyViewInvoicesEmitted}
                invoicesEmitted={invoicesEmitted}
                companyId={company.id}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Card className={classes.paper}>
            <CardHeader
              className={classes.cardHeader}
              title={translation().views.company_view.addresses.label}
              titleTypographyProps={{
                variant: 'h5',
                style: {
                  fontSize: 21
                }
              }}
              avatar={
                <Avatar>
                  <HomeWorkIcon />
                </Avatar>
              }
            />
            <CardContent className={classes.cardContent}>
              <Addresses
                addresses={addresses}
                companyId={company.id}
                cleanCompanyViewAddressesReducer={cleanCompanyViewAddressesReducer}
                getCompanyViewAddresses={getCompanyViewAddresses}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Card className={classes.paper}>
            <CardHeader
              className={classes.cardHeader}
              title={translation().views.company_view.payment_methods.label}
              titleTypographyProps={{
                variant: 'h5',
                style: {
                  fontSize: 21
                }
              }}
              avatar={
                <Avatar>
                  <PaymentIcon />
                </Avatar>
              }
              action={
                <IconButton
                  aria-label="See all payment methods"
                  href={`/companies/${company.id}/payment-methods`}
                  target="_blank">
                  <OpenInNewIcon fontSize="small" />
                </IconButton>
              }
            />
            <CardContent className={classes.cardContent}>
              <PaymentMethods
                companyId={company.id}
                getCompanyViewPaymentMethods={getCompanyViewPaymentMethods}
                paymentMethods={paymentMethods}
                cleanCompanyViewPaymentMethodReducer={cleanCompanyViewPaymentMethodReducer}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

CompanyInvoices.propTypes = {
  addresses: PropTypes.shape({
    isLoading: PropTypes.bool
  }).isRequired,
  company: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
  }).isRequired,
  getCompanyViewAddresses: PropTypes.func.isRequired,
  cleanCompanyViewAddressesReducer: PropTypes.func.isRequired,
  cleanCompanyViewInvoicesReducer: PropTypes.func.isRequired,
  cleanCompanyViewInvoicesEmittedReducer: PropTypes.func.isRequired,
  cleanCompanyViewPaymentMethodReducer: PropTypes.func.isRequired,
  getCompanyViewInvoices: PropTypes.func.isRequired,
  getCompanyViewInvoicesEmitted: PropTypes.func.isRequired,
  getCompanyViewPaymentMethods: PropTypes.func.isRequired,
  getInvoicerId: PropTypes.func.isRequired,
  getPayerId: PropTypes.func.isRequired,
  invoices: PropTypes.shape({
    isLoading: PropTypes.bool
  }).isRequired,
  invoicesEmitted: PropTypes.shape({
    isLoading: PropTypes.bool
  }).isRequired,
  paymentMethods: PropTypes.shape({
    isLoading: PropTypes.bool
  }).isRequired
};

export default CompanyInvoices;
