import './userPositions.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import Typography from '@material-ui/core/Typography';

import { dateInTz } from '../../../../utils/functions/dates';
import translation from '../../../../translation/translation';

const UserPositionsGoogleMap = withGoogleMap((props) => {
  return <GoogleMap {...props}>{props.children}</GoogleMap>;
});

function PositionMarker(props) {
  const { marker } = props;

  return (
    <span>
      <Marker
        visible
        clickable={false}
        draggable={false}
        editable={false}
        position={{
          lat: marker.latitude,
          lng: marker.longitude
        }}
        options={{
          strokeColor: 'transparent',
          zIndex: marker.id,
          icon: { url: '/assets/images/maps/user-position-marker.png' }
        }}
      />
    </span>
  );
}

PositionMarker.propTypes = {
  marker: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    longitude: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    latitude: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }).isRequired
};

function UserPositions(props) {
  const { className, getUserPositions, resource, isProvider } = props;

  const defaultZoom = 4;
  const defaultCenter = { lat: 48.866667, lng: 2.333333 };
  const timer = 15000;

  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [zoom, setZoom] = useState(defaultZoom);
  const [center, setCenter] = useState(defaultCenter);

  useEffect(() => {
    getPosition();

    /**
     * Get the position every 15 sec
     */
    const interval = setInterval(() => {
      getPosition();
    }, timer);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getPosition() {
    if (resource && resource.id) {
      setLoading(true);

      getUserPositions(
        resource.id,
        isProvider,
        (payload) => {
          setLoading(false);

          if (payload && payload.length) {
            setData(payload);
            setZoom(14);

            if (payload[0].longitude && payload[0].latitude) {
              setCenter({
                lat: payload[0].latitude,
                lng: payload[0].longitude
              });
            }
          }
        },
        () => {
          setLoading(false);
        }
      );
    }
  }

  function renderMarker(data) {
    if (!data || data.length <= 0) return;

    const markers = [];

    /**
     * We take only the last position from data
     */
    markers.push(<PositionMarker key={0} marker={data[0]} />);

    return markers;
  }

  return (
    <div className={classNames('UserPositionsView', className)}>
      {data && data[0] && data[0].created && !isLoading ? (
        <div className="user-last-position">
          <Typography variant="subtitle2">
            {translation().views.user_positions.date}{' '}
            {dateInTz(data[0].created, 'localized-datetime-with-seconds')}
          </Typography>
        </div>
      ) : (
        !isLoading && (
          <div className="user-last-position">
            <Typography variant="subtitle2">
              {translation().views.user_positions.no_date}
            </Typography>
          </div>
        )
      )}
      {isLoading && (
        <div className="user-last-position">
          <Typography variant="subtitle2">{translation().views.user_positions.loading}</Typography>
        </div>
      )}
      <UserPositionsGoogleMap
        zoom={zoom}
        center={center}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div className="map-wrapper" />}
        mapElement={<div style={{ height: `100%` }} />}>
        {renderMarker(data)}
      </UserPositionsGoogleMap>
    </div>
  );
}

UserPositions.defaultProps = {
  isProvider: false
};

UserPositions.propTypes = {
  className: PropTypes.string,
  getUserPositions: PropTypes.func.isRequired,
  resource: PropTypes.shape(),
  isProvider: PropTypes.bool
};

export default UserPositions;
