import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FlagIcon from '@material-ui/icons/Flag';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import translation from '../../../../../../translation/translation';

function FlagButton(props) {
  const {
    className,
    flag,
    manageOrderProductFlag,
    productFlag,
    orderId,
    orderProductId,
    reloadOrder
  } = props;

  const [isOpen, setOpen] = useState(false);

  function handleOpenDialog() {
    setOpen(true);
  }

  function handleCloseDialog() {
    setOpen(false);
  }

  function handleFlag() {
    manageOrderProductFlag(orderId, orderProductId, !flag, reloadOrder);
  }

  return (
    <>
      <Tooltip
        title={translation().views.orders.update.products.product_flag.tooltip}
        enterDelay={500}>
        <IconButton
          aria-label="order-product-flag"
          className={className}
          onClick={handleOpenDialog}>
          <FlagIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        fullWidth
        open={isOpen}
        onClose={handleCloseDialog}
        aria-labelledby="product-flag"
        aria-describedby="product-flag-description"
        disableBackdropClick={productFlag.isLoading}>
        <DialogTitle id="product-flag">
          {translation().views.orders.update.products.product_flag.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            {flag
              ? translation().views.orders.update.products.product_flag.description_remove
              : translation().views.orders.update.products.product_flag.description_add}
          </DialogContentText>
          {productFlag.isLoading && (
            <div className="loader-wpr" style={{ marginTop: 15 }}>
              <CircularProgress size={30} color="secondary" />
              <p>{translation().views.orders.update.products.product_flag.loading_text}</p>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="default" disabled={productFlag.isLoading}>
            {translation().actions.cancel}
          </Button>
          <Button
            onClick={handleFlag}
            variant="contained"
            color="primary"
            disabled={productFlag.isLoading}>
            {translation().actions.confirm}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

FlagButton.propTypes = {
  className: PropTypes.string.isRequired,
  flag: PropTypes.bool,
  manageOrderProductFlag: PropTypes.func.isRequired,
  orderId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  orderProductId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  productFlag: PropTypes.shape({
    isLoading: PropTypes.bool
  }).isRequired,
  reloadOrder: PropTypes.func.isRequired
};

export default FlagButton;
