export const getDashboards = (next) => ({
  type: 'GET_DASHBOARDS',
  next
});

export const getDashboardsSuccess = (data, paging) => ({
  type: 'GET_DASHBOARDS_SUCCESS',
  data,
  paging
});

export const getDashboardsError = (error) => ({
  type: 'GET_DASHBOARDS_ERROR',
  error
});

export const refreshDashboards = () => ({
  type: 'REFRESH_DASHBOARDS'
});

export const updateDashboard = () => ({
  type: 'UPDATE_DASHBOARD'
});

export const updateDashboardSuccess = (data) => ({
  type: 'UPDATE_DASHBOARD_SUCCESS',
  data
});

export const updateDashboardError = (error) => ({
  type: 'UPDATE_DASHBOARD_ERROR',
  error
});

export const deleteDashboard = () => ({
  type: 'DELETE_DASHBOARD'
});

export const deleteDashboardSuccess = (id) => ({
  type: 'DELETE_DASHBOARD_SUCCESS',
  id
});

export const deleteDashboardError = (error) => ({
  type: 'DELETE_DASHBOARD_ERROR',
  error
});
