import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Alert from '../../../../../components/alert/Alert';
import { dateInTz } from '../../../../../utils/functions/dates';
import translation from '../../../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2)
  },
  tableContainer: {
    maxHeight: '640px!important'
  },
  loadingWrapper: {
    width: '100%',
    height: 50
  }
}));

function MediaTypesRow(props) {
  const { mediaType, productId } = props;

  return (
    <TableRow>
      <TableCell>{mediaType.id}</TableCell>
      <TableCell>
        {mediaType.media_type && mediaType.media_type.name ? mediaType.media_type.name : '-'}
      </TableCell>
      <TableCell>
        {mediaType.media_type && mediaType.media_type.shortcode
          ? mediaType.media_type.shortcode
          : '-'}
      </TableCell>
      <TableCell>
        {mediaType.media_type && mediaType.media_type.type ? mediaType.media_type.type : '-'}
      </TableCell>
      <TableCell>
        {parseInt(mediaType.quantity_min, 10) ? parseInt(mediaType.quantity_min, 10) : '-'}
      </TableCell>
      <TableCell>
        {parseInt(mediaType.quantity_max, 10) ? parseInt(mediaType.quantity_max, 10) : '-'}
      </TableCell>
      <TableCell>
        {mediaType.created ? dateInTz(mediaType.created, 'localized-datetime') : '-'}
      </TableCell>
      <TableCell align="right">
        <IconButton
          aria-label="Block payment method"
          href={`/products/${productId}/media-types/${mediaType.id}/update`}
          target="_blank">
          <EditIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

MediaTypesRow.propTypes = {
  mediaType: PropTypes.shape().isRequired,
  productId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

function MediaTypes(props) {
  const classes = useStyles();

  const columns = ['id', 'name', 'shortcode', 'type', 'quantity_min', 'quantity_max', 'created'];

  const { mediaTypes, cleanProductViewMediaTypesReducer, getProductMediaTypes, productId } = props;

  function loadMediaTypes(productId, next = null) {
    getProductMediaTypes(productId, next);
  }

  useEffect(() => {
    loadMediaTypes(productId);

    return () => {
      cleanProductViewMediaTypesReducer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleInfiniteScroll(event) {
    if (!event) console.error('No table scroll event found.');

    if (!event.currentTarget.scrollTop || event.currentTarget.scrollTop === 0) return;

    if (
      event.currentTarget.scrollHeight - event.currentTarget.clientHeight <=
      event.currentTarget.scrollTop
    ) {
      if (mediaTypes && mediaTypes.paging && mediaTypes.paging.next) {
        getProductMediaTypes(productId, mediaTypes.paging.next);
      }
    }
  }

  return (
    <div className={classNames('ProductMediaTypes', classes.root)}>
      <Paper className={classes.paper}>
        {!mediaTypes.isLoading &&
        mediaTypes.error &&
        (!mediaTypes.data || mediaTypes.data.length <= 0) ? (
          <div className="error-list">
            <Alert
              type="danger"
              style={{ marginTop: 10 }}
              status={translation().commons.alerts.error}
              text={translation().core.list.error_list}
            />
          </div>
        ) : (
          false
        )}
        {mediaTypes.data && mediaTypes.data.length > 0 ? (
          <TableContainer onScroll={handleInfiniteScroll} className={classes.tableContainer}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column}>{column}</TableCell>
                  ))}
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {mediaTypes.data.map((mediaType, index) => (
                  <MediaTypesRow mediaType={mediaType} productId={productId} key={index} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : !mediaTypes.isLoading && !mediaTypes.error ? (
          <Alert
            type="info"
            style={{ marginTop: 10 }}
            status={translation().commons.alerts.info}
            text={translation().views.product_view.media_types.empty}
          />
        ) : (
          false
        )}
        {mediaTypes.isLoading && !mediaTypes.paging && (
          <>
            <div className={classes.loadingWrapper} />
            <div className="loader-wpr">
              <CircularProgress color="primary" />
              <p>{translation().views.product_view.media_types.loading_text}</p>
            </div>
          </>
        )}
        {mediaTypes.isLoading && mediaTypes.paging && mediaTypes.paging.next ? (
          <div style={{ height: 40 }}>
            <LinearProgress color="primary" />
            <div className="loader-wpr linear">
              <p>{translation().core.list.load_next}</p>
            </div>
          </div>
        ) : (
          <div className="end-of-list-wpr">
            {!mediaTypes.isLoading &&
              mediaTypes.paging &&
              !mediaTypes.paging.next &&
              mediaTypes.paging.prev && <p>{translation().core.list.load_next_done}</p>}
          </div>
        )}
      </Paper>
    </div>
  );
}

MediaTypes.propTypes = {
  cleanProductViewMediaTypesReducer: PropTypes.func.isRequired,
  getProductMediaTypes: PropTypes.func.isRequired,
  productId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  mediaTypes: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape()),
    error: PropTypes.string,
    isLoading: PropTypes.bool,
    paging: PropTypes.shape({
      next: PropTypes.string,
      prev: PropTypes.string
    })
  }).isRequired
};

export default MediaTypes;
