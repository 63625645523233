export const setOrderProductsOverdueSearchValue = (value) => ({
  type: 'SET_ORDER_PRODUCTS_OVERDUE_SEARCH_VALUE',
  value
});

export const refreshOrderProductsOverdue = () => ({
  type: 'REFRESH_ORDER_PRODUCTS_OVERDUE'
});

export const getOrderProductsOverdue = (search, next) => ({
  type: 'GET_ORDER_PRODUCTS_OVERDUE',
  search,
  next
});

export const getOrderProductsOverdueSuccess = (data, paging) => ({
  type: 'GET_ORDER_PRODUCTS_OVERDUE_SUCCESS',
  data,
  paging
});

export const getOrderProductsOverdueError = (isError) => ({
  type: 'GET_ORDER_PRODUCTS_OVERDUE_ERROR',
  isError
});

export const clearOwnReducer = () => ({
  type: 'CLEAR_ORDER_PRODUCTS_OVERDUE_LIST_REDUCER'
});
