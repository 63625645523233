import React from 'react';
import CodeIcon from '@material-ui/icons/Code';
import Typography from '@material-ui/core/Typography';

import translation from '../../../translation/translation';
import FormApiAutocompleteField from '../../../components/forms/fields/FormApiAutocompleteField';

function Workshop() {
  return (
    <div className="Workshop">
      <Typography gutterBottom component="h5" variant="h5">
        {translation().views.lab.workshop.title}
      </Typography>
      <Typography gutterBottom component="p" variant="body1">
        {translation().views.lab.workshop.baseline}
      </Typography>
      <div className="sm-space" />
      <div className="work-item">
        <div className="work-item-title">
          <CodeIcon style={{ marginRight: 12 }} />
          <Typography variant="h6" color="secondary">
            Autocomplete Pagination
          </Typography>
        </div>
        <div>
          <FormApiAutocompleteField
            id="test-lab-autoc-pagination"
            name="test-lab-autoc-pagination"
            fullWidth
            links={[
              {
                rel: 'list',
                href: `users`
              }
            ]}
            placeholder="user"
            getFullObject
            targetKey="id"
          />
        </div>
      </div>
    </div>
  );
}

export default Workshop;
