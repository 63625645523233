import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Alert from '../../../../../components/alert/Alert';
import { dateInTz } from '../../../../../utils/functions/dates';
import config from '../../../../../config';
import translation from '../../../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2)
  },
  tableContainer: {
    maxHeight: '640px!important'
  },
  loadingWrapper: {
    width: '100%',
    height: 50
  }
}));

function OptionRow(props) {
  const { option, productId } = props;

  return (
    <TableRow>
      <TableCell>{option.id}</TableCell>
      <TableCell>{option.name ? option.name : '-'}</TableCell>
      <TableCell>{option.shortcode ? option.shortcode : '-'}</TableCell>
      <TableCell>{option.description ? option.description : '-'}</TableCell>
      <TableCell>{option.duration_min ? option.duration_min : '-'}</TableCell>
      <TableCell>{option.duration_max ? option.duration_max : '-'}</TableCell>
      <TableCell>
        {option.active ? (
          <DoneIcon style={{ color: config.success_color || '#4CAF50' }} fontSize="small" />
        ) : (
          <CloseIcon style={{ color: config.error_color || '#F44336' }} fontSize="small" />
        )}
      </TableCell>
      <TableCell>{option.orderable ? option.orderable : '-'}</TableCell>
      <TableCell>{option.created ? dateInTz(option.created, 'localized-datetime') : '-'}</TableCell>
      <TableCell>{option.updated ? dateInTz(option.updated, 'localized-datetime') : '-'}</TableCell>
      <TableCell align="right">
        <IconButton
          aria-label="Update product option"
          href={`/products/${productId}/options/${option.id}/update`}
          target="_blank">
          <EditIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

OptionRow.propTypes = {
  option: PropTypes.shape().isRequired,
  productId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

function Options(props) {
  const classes = useStyles();

  const columns = [
    'id',
    'name',
    'shortcode',
    'description',
    'duration_min',
    'duration_max',
    'active',
    'orderable',
    'created',
    'updated'
  ];

  const { options, cleanProductViewOptionsReducer, getProductOptions, productId } = props;

  function loadOptions(productId, next = null) {
    getProductOptions(productId, next);
  }

  useEffect(() => {
    loadOptions(productId);

    return () => {
      cleanProductViewOptionsReducer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleInfiniteScroll(event) {
    if (!event) console.error('No table scroll event found.');

    if (!event.currentTarget.scrollTop || event.currentTarget.scrollTop === 0) return;

    if (
      event.currentTarget.scrollHeight - event.currentTarget.clientHeight <=
      event.currentTarget.scrollTop
    ) {
      if (options && options.paging && options.paging.next) {
        getProductOptions(productId, options.paging.next);
      }
    }
  }

  return (
    <div className={classNames('ProductOptions', classes.root)}>
      <Paper className={classes.paper}>
        {!options.isLoading && options.error && (!options.data || options.data.length <= 0) ? (
          <div className="error-list">
            <Alert
              type="danger"
              style={{ marginTop: 10 }}
              status={translation().commons.alerts.error}
              text={translation().core.list.error_list}
            />
          </div>
        ) : (
          false
        )}
        {options.data && options.data.length > 0 ? (
          <TableContainer onScroll={handleInfiniteScroll} className={classes.tableContainer}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column}>{column}</TableCell>
                  ))}
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {options.data.map((option, index) => (
                  <OptionRow option={option} productId={productId} key={index} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : !options.isLoading && !options.error ? (
          <Alert
            type="info"
            style={{ marginTop: 10 }}
            status={translation().commons.alerts.info}
            text={translation().views.product_view.options.empty}
          />
        ) : (
          false
        )}
        {options.isLoading && !options.paging && (
          <>
            <div className={classes.loadingWrapper} />
            <div className="loader-wpr">
              <CircularProgress color="primary" />
              <p>{translation().views.product_view.options.loading_text}</p>
            </div>
          </>
        )}
        {options.isLoading && options.paging && options.paging.next ? (
          <div style={{ height: 40 }}>
            <LinearProgress color="primary" />
            <div className="loader-wpr linear">
              <p>{translation().core.list.load_next}</p>
            </div>
          </div>
        ) : (
          <div className="end-of-list-wpr">
            {!options.isLoading &&
              options.paging &&
              !options.paging.next &&
              options.paging.prev && <p>{translation().core.list.load_next_done}</p>}
          </div>
        )}
      </Paper>
    </div>
  );
}

Options.propTypes = {
  cleanProductViewOptionsReducer: PropTypes.func.isRequired,
  getProductOptions: PropTypes.func.isRequired,
  productId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  options: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape()),
    error: PropTypes.string,
    isLoading: PropTypes.bool,
    paging: PropTypes.shape({
      next: PropTypes.string,
      prev: PropTypes.string
    })
  }).isRequired
};

export default Options;
