/* eslint-disable react/display-name */

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import AddIcon from '@material-ui/icons/Add';
import AppBar from '@material-ui/core/AppBar';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Fab from '@material-ui/core/Fab';
import FilterListIcon from '@material-ui/icons/FilterList';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreIcon from '@material-ui/icons/MoreVert';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import RefreshIcon from '@material-ui/icons/Refresh';
import SearchIcon from '@material-ui/icons/Search';
import Tooltip from '@material-ui/core/Tooltip';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import { getUserSearchBarPosition } from '../../../utils/functions/cookies';
import config from '../../../config';
import translation from '../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  breadcrumb: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },
  createFab: {
    color: 'white',
    backgroundColor: config.success_color,
    '&:hover': {
      backgroundColor: fade(config.success_color, 0.55)
    },
    marginLeft: 7
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.35),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.55)
    },
    marginLeft: 5,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(5),
      width: 'auto'
    }
  },
  searchIcon: {
    width: theme.spacing(6),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit',
    width: '100%'
  },
  inputInput: {
    fontSize: 14,
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(6),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200
      }
    }
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
}));

const WrappedComponent = React.forwardRef((props, ref) => {
  return <ViewBar {...props} ref={ref} />;
});

function ViewBar(props) {
  const {
    isCreatable,
    isFilterable,
    isLoading,
    handleOpenFilter,
    handleSubmit,
    handleRefresh,
    parentUri,
    routeUri,
    search,
    submitBuffer
  } = props;

  const classes = useStyles();
  const [value, setValue] = useState('');
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  useEffect(() => {
    if (search) {
      setValue(search);
    } else {
      setValue('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitBuffer]);

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }

  function handleMenuClose() {
    handleMobileMenuClose();
  }

  function handleMobileMenuOpen(event) {
    setMobileMoreAnchorEl(event.currentTarget);
  }

  function handleSubmitForm(e) {
    e.preventDefault();
    handleSubmit(value);
  }

  function handleRefreshForm() {
    setValue('');
    handleRefresh();
  }

  function handleChange(e) {
    setValue(e.target.value);
  }

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMenuClose}>
      {isFilterable && (
        <MenuItem onClick={handleOpenFilter}>
          <FilterListIcon fontSize="small" style={{ marginRight: 8 }} />
          <p>{translation().actions.filter}</p>
        </MenuItem>
      )}
    </Menu>
  );

  return (
    <div className={classes.root}>
      <AppBar elevation={0} position="static" color="default">
        <Toolbar>
          <div className={classes.sectionDesktop}>
            <Breadcrumbs
              maxItems={5}
              separator={<NavigateNextIcon fontSize="small" />}
              arial-label="List view breadcrumb"
              className={classes.breadcrumb}>
              <Typography variant="body2" color="textPrimary">
                {parentUri}
              </Typography>
              {routeUri.length &&
                routeUri.split('/').map((n, i) => {
                  return (
                    <Typography variant="body2" color="textPrimary" key={i}>
                      {n}
                    </Typography>
                  );
                })}
            </Breadcrumbs>
          </div>
          <div className={classes.sectionMobile}>
            {routeUri.length ? (
              <Typography variant="body2" color="textPrimary">
                {routeUri.split('/').pop()}
              </Typography>
            ) : (
              false
            )}
          </div>
          {getUserSearchBarPosition() === 'left' && (
            <form onSubmit={handleSubmitForm}>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  disabled={isLoading}
                  type="text"
                  value={value}
                  onChange={handleChange}
                  placeholder={translation().actions.search + '...'}
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput
                  }}
                />
              </div>
            </form>
          )}
          <IconButton
            aria-label="Refresh list"
            color="default"
            disabled={isLoading}
            style={{ padding: 5, marginLeft: 7 }}
            onClick={handleRefreshForm}>
            <RefreshIcon />
          </IconButton>
          <div className={classes.grow} />
          {getUserSearchBarPosition() === 'right' && (
            <form onSubmit={handleSubmitForm}>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  disabled={isLoading}
                  type="text"
                  value={value}
                  onChange={handleChange}
                  placeholder={translation().actions.search + '...'}
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput
                  }}
                />
              </div>
            </form>
          )}
          <div className={classes.sectionDesktop}>
            {isFilterable && (
              <Tooltip
                title={translation().actions.filter || 'filter'}
                aria-label={translation().actions.filter || 'filter'}
                enterDelay={700}>
                <div>
                  <Fab
                    size="small"
                    color="default"
                    disabled={isLoading}
                    onClick={handleOpenFilter}
                    style={{ marginLeft: 7 }}>
                    <FilterListIcon />
                  </Fab>
                </div>
              </Tooltip>
            )}
            {isCreatable && (
              <Tooltip
                title={translation().actions.create || 'create'}
                aria-label={translation().actions.create || 'create'}
                enterDelay={700}>
                <Fab
                  size="small"
                  color="default"
                  component={React.forwardRef((props, ref) => (
                    <Link {...props} to={'/' + routeUri + '/create'} ref={ref} />
                  ))}
                  routeuri={routeUri}
                  disabled={isLoading}
                  className={classes.createFab}>
                  <AddIcon />
                </Fab>
              </Tooltip>
            )}
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              disabled={isLoading}
              color="inherit"
              style={{ marginLeft: 7 }}>
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </div>
  );
}

ViewBar.propTypes = {
  isCreatable: PropTypes.bool,
  isFilterable: PropTypes.bool,
  isLoading: PropTypes.bool,
  handleOpenFilter: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleRefresh: PropTypes.func.isRequired,
  parentUri: PropTypes.string.isRequired,
  routeUri: PropTypes.string.isRequired,
  search: PropTypes.string,
  submitBuffer: PropTypes.any
};

export default WrappedComponent;
