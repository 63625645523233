import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchApi } from '../../utils/functions/api';
import { enqueueSnackbar } from '../../redux/actions/appActions';
import {
  clearModeration,
  getModerationData,
  getModerationDataSuccess,
  getModerationDataError
} from '../../redux/actions/moderation/moderationActions';
import translation from '../../translation/translation';
import Moderation from '../../views/moderation/Moderation';

const mapStateToProps = (store) => ({
  moderation: store.production.moderation
});

const mapDispatchToProps = (dispatch) => {
  return {
    clearModeration() {
      dispatch(clearModeration());
    },
    async getModerationData(next = null, search = null, callbackSuccess) {
      dispatch(getModerationData(search, next));

      const query = {
        sort: 'expected'
      };

      await fetchApi(
        'get',
        next ? next : 'orders/all/products/received',
        !next && search ? { ...query, search } : { ...query },
        null,
        null,
        (data, paging) => {
          dispatch(getModerationDataSuccess(data, paging));

          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess();
          }
        },
        (error) => {
          dispatch(getModerationDataError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.error_list,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().core.list.error_list,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Moderation));
