import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import arrayMove from 'array-move';
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText
} from '@material-ui/core';
import {
  Add as AddIcon,
  DragIndicator as DragIndicatorIcon,
  Remove as RemoveIcon
} from '@material-ui/icons';
import { grey } from '@material-ui/core/colors';

import translation from '../../../../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 240
  },
  helper: {
    zIndex: 1500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    textDecoration: 'none',
    backgroundColor: theme.palette.type === 'dark' ? grey[800] : grey[200]
  },
  submit: {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

const DragHandle = sortableHandle((props) => (
  <IconButton
    aria-label={`drag-item-${props.id}`}
    size="small"
    style={{ cursor: 'grab', marginLeft: 4 }}>
    <DragIndicatorIcon fontSize="small" />
  </IconButton>
));

const SortableItem = SortableElement((props) => {
  const { index, name, onRemoveField } = props;

  function handleRemove() {
    onRemoveField(name);
  }

  return (
    <ListItem>
      <ListItemText primary={name} style={{ textTransform: 'capitalize' }} />
      <ListItemSecondaryAction>
        <IconButton
          key={`remove-${name}-${index}`}
          edge="end"
          size="small"
          aria-label="remove"
          onClick={handleRemove}>
          <RemoveIcon />
        </IconButton>
        <DragHandle key={`drag-${name}-${index}`} id={name} />
      </ListItemSecondaryAction>
    </ListItem>
  );
});

SortableItem.propTypes = {
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  onRemoveField: PropTypes.func.isRequired
};

const SortableList = SortableContainer((props) => {
  const { className, items, onRemoveField } = props;

  return (
    <List dense className={className}>
      {items.map(
        (column, index) =>
          column !== 'id' && (
            <SortableItem key={index} index={index} name={column} onRemoveField={onRemoveField} />
          )
      )}
    </List>
  );
});

function AvailableField(props) {
  const { name, onAddField } = props;

  function handleAddField() {
    onAddField(name);
  }

  return (
    <ListItem>
      <ListItemText
        primary={name}
        primaryTypographyProps={{
          color: 'textSecondary'
        }}
        style={{ textTransform: 'capitalize' }}
      />
      <ListItemSecondaryAction>
        <IconButton edge="end" size="small" aria-label="add" onClick={handleAddField}>
          <AddIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

AvailableField.propTypes = {
  name: PropTypes.string.isRequired,
  onAddField: PropTypes.func.isRequired
};

function ViewFields(props) {
  const { fields, metadataFields, onSubmit } = props;

  const classes = useStyles();

  function getFieldsAsArray() {
    return fields && fields.length ? fields.split(',') : [];
  }

  const [list, setList] = useState(getFieldsAsArray());
  const [availableFields, setAvailableFields] = useState(metadataFields);

  useEffect(() => {
    const fields = getFieldsAsArray();
    let remainingFields = [...availableFields];
    remainingFields = availableFields.filter((data) => fields.indexOf(data.name) === -1);

    setAvailableFields(remainingFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleSortEnd({ oldIndex, newIndex }) {
    setList(() => arrayMove(list, oldIndex, newIndex));
  }

  function handleSubmit() {
    onSubmit(list.join(','));
  }

  function handleRemoveField(name) {
    const newFields = list.filter((data) => data !== name);
    const newAvailableFields = [...availableFields, { name }];

    setList(newFields);
    setAvailableFields(newAvailableFields);
  }

  function handleRemoveAvailableField(name) {
    const newAvailableFields = availableFields.filter((data) => data.name !== name);
    setAvailableFields(newAvailableFields);
  }

  function handleAddField(name) {
    handleRemoveAvailableField(name);
    setList((list) => [...list, name]);
  }

  return (
    <Box>
      {list.length > 0 ? (
        <SortableList
          items={list}
          className={classes.root}
          onRemoveField={handleRemoveField}
          onSortEnd={handleSortEnd}
          lockAxis="y"
          helperClass={classes.helper}
          useDragHandle
        />
      ) : (
        false
      )}
      <Divider />
      <List dense>
        {availableFields.map(
          (field, index) =>
            field.name !== 'id' &&
            field.name !== 'attachments' && (
              <AvailableField
                key={index}
                className={classes.root}
                name={field.name}
                onAddField={handleAddField}
              />
            )
        )}
      </List>
      <div className={classes.submit}>
        <Button color="secondary" variant="contained" size="small" onClick={handleSubmit}>
          {translation().actions.confirm}
        </Button>
      </div>
    </Box>
  );
}

ViewFields.propTypes = {
  fields: PropTypes.string,
  metadataFields: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default ViewFields;
