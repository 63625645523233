import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import AppBar from '@material-ui/core/AppBar';
import AddIcon from '@material-ui/icons/Add';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import IconButton from '@material-ui/core/IconButton';
import Fab from '@material-ui/core/Fab';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import RefreshIcon from '@material-ui/icons/Refresh';

import config from '../../../config';
import translation from '../../../translation/translation';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%'
  },
  grow: {
    flexGrow: 1
  },
  createFab: {
    color: 'white',
    backgroundColor: config.success_color,
    '&:hover': {
      backgroundColor: fade(config.success_color, 0.55)
    },
    marginLeft: 7
  }
}));

function ViewBar(props) {
  const classes = useStyles();
  const { loading, handleCreate, handleRefresh } = props;

  return (
    <div className={classes.root}>
      <AppBar elevation={0} position="static" color="default">
        <Toolbar>
          <Breadcrumbs
            maxItems={5}
            separator={<NavigateNextIcon fontSize="small" />}
            arial-label="My tasks view breadcrumb">
            <Typography variant="body2" color="textPrimary">
              {translation().views.task_views.list.title}
            </Typography>
          </Breadcrumbs>
          <IconButton
            aria-label="Refresh moderation"
            color="default"
            disabled={loading}
            style={{ padding: 5, marginLeft: 7 }}
            onClick={handleRefresh}>
            <RefreshIcon />
          </IconButton>
          <div className={classes.grow} />
          <Tooltip
            title={translation().actions.create || 'create'}
            aria-label={translation().actions.create || 'create'}
            enterDelay={700}>
            <div>
              <Fab
                size="small"
                color="default"
                disabled={loading}
                onClick={handleCreate}
                className={classes.createFab}>
                <AddIcon />
              </Fab>
            </div>
          </Tooltip>
        </Toolbar>
      </AppBar>
    </div>
  );
}

ViewBar.defaultProps = {
  loading: false
};

ViewBar.propTypes = {
  handleCreate: PropTypes.func.isRequired,
  handleRefresh: PropTypes.func.isRequired,
  loading: PropTypes.bool
};

export { ViewBar };
