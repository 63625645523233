const initialState = {
  isError: false,
  isLoading: false,
  data: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_REDUCERS':
      return initialState;
    case 'CANCEL_GET_ITEM':
      return initialState;
    case 'GET_ITEM':
      return {
        ...state,
        isError: false,
        isLoading: true,
        data: null
      };
    case 'GET_ITEM_SUCCESS':
      return {
        ...state,
        isError: false,
        isLoading: false,
        data: action.data
      };
    case 'GET_ITEM_ERROR':
      return {
        ...state,
        isError: true,
        isLoading: false
      };
    default:
      return state;
  }
};
