import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchApi } from '../../../utils/functions/api';
import { formatErrors } from '../../../utils/functions/forms';
import { enqueueSnackbar } from '../../../redux/actions/appActions';
import {
  checkUserProviderFactoryAdmin,
  clearOwnReducer,
  setActiveStep,
  createUserProviderFactory,
  createUserProviderFactorySuccess,
  createUserProviderFactoryUserError,
  createUserProviderFactoryProviderError,
  linkUserProviderFactorySuccess,
  selectUserProviderFactoryCompany,
  createUserProviderFactoryCompany,
  createUserProviderFactoryCompanySuccess,
  createUserProviderFactoryCompanyError,
  createProviderFactoryAddress,
  createProviderFactoryAddressSuccess,
  createProviderFactoryAddressError,
  createProviderFactoryPaymentMethod,
  createProviderFactoryPaymentMethodSuccess,
  createProviderFactoryPaymentMethodError
} from '../../../redux/actions/providers/providerFactoryActions';
import {
  createDate,
  createDateUtc,
  formatDate,
  formatJSDate
} from '../../../utils/functions/dates';
import translation from '../../../translation/translation';
import ProviderFactory from '../../../views/providers/providerFactory/ProviderFactory';

const mapStateToProps = (store) => ({
  providerFactory: store.providers.providerFactory
});

const mapDispatchToProps = (dispatch) => {
  function _linkUserToCompany(companyId, userId, onSuccess, onError) {
    if (!companyId || !userId) return;

    fetchApi(
      'post',
      `companies/${companyId}/users/${userId}/link`,
      null,
      null,
      null,
      (success) => {
        dispatch(
          enqueueSnackbar({
            message:
              translation().core.list.dialogs.custom_list_actions.link_user_to_company.callbacks
                .link.success,
            options: {
              variant: 'success'
            }
          })
        );

        dispatch(linkUserProviderFactorySuccess());

        if (onSuccess && typeof onSuccess === 'function') {
          onSuccess(success);
        }
      },
      (error) => {
        if (error.response) {
          dispatch(
            enqueueSnackbar({
              message:
                error.response.data && error.response.data.detail
                  ? error.response.data.detail
                  : translation().core.list.dialogs.custom_list_actions.link_user_to_company
                      .callbacks.link.error,
              status: error.response.status || undefined,
              options: {
                variant: 'error'
              }
            })
          );
        } else {
          dispatch(
            enqueueSnackbar({
              message:
                translation().core.list.dialogs.custom_list_actions.link_user_to_company.callbacks
                  .link.error,
              options: {
                variant: 'error'
              }
            })
          );
        }

        if (onError && typeof onError === 'function') {
          onError();
        }
      }
    );
  }

  function _adminizeCompanyUser(companyId, userId, adminRequest = true, onSuccess, onError) {
    if (!companyId || !userId) return;

    fetchApi(
      'post',
      `companies/${companyId}/users/${userId}/${adminRequest ? 'adminize' : 'unadminize'}`,
      null,
      null,
      null,
      (success) => {
        dispatch(
          enqueueSnackbar({
            message:
              translation().core.list.dialogs.custom_list_actions.link_user_to_company.callbacks
                .adminize.success,
            options: {
              variant: 'success'
            }
          })
        );

        if (onSuccess && typeof onSuccess === 'function') {
          onSuccess(success);
        }
      },
      (error) => {
        if (error.response) {
          dispatch(
            enqueueSnackbar({
              message:
                error.response.data && error.response.data.detail
                  ? error.response.data.detail
                  : translation().core.list.dialogs.custom_list_actions.link_user_to_company
                      .callbacks.adminize.error,
              status: error.response.status || undefined,
              options: {
                variant: 'error'
              }
            })
          );
        } else {
          dispatch(
            enqueueSnackbar({
              message:
                translation().core.list.dialogs.custom_list_actions.link_user_to_company.callbacks
                  .adminize.error,
              options: {
                variant: 'error'
              }
            })
          );
        }

        if (onError && typeof onError === 'function') {
          onError();
        }
      }
    );
  }

  return {
    clearOwnReducer() {
      dispatch(clearOwnReducer());
    },
    enqueueSnackbar(queue) {
      dispatch(enqueueSnackbar(queue));
    },
    setActiveStep(step) {
      dispatch(setActiveStep(step));
    },
    selectUserProviderFactoryCompany(company) {
      dispatch(selectUserProviderFactoryCompany(company));
    },
    checkUserProviderFactoryAdmin(checked) {
      dispatch(checkUserProviderFactoryAdmin(checked));
    },
    createUserProvider(userValues, providerValues) {
      if (!userValues || !providerValues) return;

      dispatch(createUserProviderFactory());

      fetchApi(
        'post',
        'users',
        null,
        userValues,
        null,
        (userSuccess) => {
          const userId = userSuccess.id;
          const timezone = userValues.timezone_code;

          if (!userId) return;

          if (providerValues.ghost_start_time) {
            const day = formatJSDate(new Date());
            const start = createDate(day + 'T' + providerValues.ghost_start_time, timezone);
            const utcValue = createDateUtc(start);
            providerValues.ghost_start_time = formatDate(utcValue, 'HH:mm:00');
          }

          if (providerValues.ghost_end_time) {
            const day = formatJSDate(new Date());
            const start = createDate(day + 'T' + providerValues.ghost_end_time, timezone);
            const utcValue = createDateUtc(start);
            providerValues.ghost_end_time = formatDate(utcValue, 'HH:mm:00');
          }

          fetchApi(
            'post',
            'providers',
            null,
            {
              iam_user_id: userId,
              ...providerValues
            },
            null,
            (providerSuccess) => {
              dispatch(createUserProviderFactorySuccess(userSuccess, providerSuccess));
              dispatch(setActiveStep(1));
            },
            (providerError) => {
              const error = providerError;

              if (error && error.response) {
                if (
                  error.response.data &&
                  error.response.data.errors &&
                  error.response.data.errors.length > 0
                ) {
                  dispatch(
                    createUserProviderFactoryProviderError(formatErrors(error.response.data.errors))
                  );
                } else {
                  dispatch(
                    createUserProviderFactoryProviderError(
                      error.response.data && error.response.data.detail
                        ? error.response.data.detail
                        : translation().views.provider_factory.steps.user_provider.callbacks
                            .user_error
                    )
                  );
                }

                dispatch(
                  enqueueSnackbar({
                    message:
                      error.response.data && error.response.data.detail
                        ? error.response.data.detail
                        : translation().views.provider_factory.steps.user_provider.callbacks
                            .user_error,
                    options: {
                      variant: 'error'
                    }
                  })
                );
              } else {
                dispatch(
                  createUserProviderFactoryProviderError(
                    translation().views.provider_factory.steps.user_provider.callbacks.user_error
                  )
                );
                dispatch(
                  enqueueSnackbar({
                    message:
                      translation().views.provider_factory.steps.user_provider.callbacks.user_error,
                    options: {
                      variant: 'error'
                    }
                  })
                );
              }
            }
          );
        },
        (userError) => {
          const error = userError;

          if (error.response) {
            if (
              error.response.data &&
              error.response.data.errors &&
              error.response.data.errors.length > 0
            ) {
              dispatch(
                createUserProviderFactoryUserError(formatErrors(error.response.data.errors))
              );
            } else {
              dispatch(createUserProviderFactoryUserError());
            }

            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().views.provider_factory.steps.user_provider.callbacks
                        .provider_error,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(createUserProviderFactoryUserError());
            dispatch(
              enqueueSnackbar({
                message:
                  translation().views.provider_factory.steps.user_provider.callbacks.provider_error,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    },
    linkProviderToProviderCompany(pid, cid, onSuccess, onError) {
      if (!pid || !cid) return;

      fetchApi(
        'put',
        `providers/${pid}`,
        null,
        { iam_company_id: cid },
        (success) => {
          if (onSuccess && typeof onSuccess === 'function') {
            onSuccess(success);
          }
        },
        (error) => {
          if (onError) {
            onError(error);
          }
        }
      );
    },
    linkUserToCompany: _linkUserToCompany,
    adminizeCompanyUser: _adminizeCompanyUser,
    createCompany(values, userId, onSuccess) {
      if (!values || !values.legal_name || !values.legal_id || !userId) return;

      dispatch(createUserProviderFactoryCompany());

      fetchApi(
        'post',
        'companies',
        null,
        values,
        null,
        (success) => {
          _linkUserToCompany(
            success.id,
            userId,
            () => {
              if (onSuccess) {
                onSuccess(success);
              }

              if (values.admin) {
                _adminizeCompanyUser(
                  success.id,
                  userId,
                  true,
                  () => {
                    dispatch(createUserProviderFactoryCompanySuccess(success));
                    dispatch(setActiveStep(2));
                  },
                  () => {
                    dispatch(
                      createUserProviderFactoryCompanyError(
                        translation().views.provider_factory.steps.company.callbacks.admin_error
                      )
                    );
                    dispatch(setActiveStep(2));
                  }
                );
              } else {
                dispatch(createUserProviderFactoryCompanySuccess(success));
                dispatch(setActiveStep(2));
              }
            },
            () => {
              dispatch(
                createUserProviderFactoryCompanyError(
                  translation().views.provider_factory.steps.company.callbacks.link_error
                )
              );
            }
          );
        },
        (error) => {
          if (error && error.response) {
            if (
              error.response.data &&
              error.response.data.errors &&
              error.response.data.errors.length > 0
            ) {
              dispatch(
                createUserProviderFactoryCompanyError(formatErrors(error.response.data.errors))
              );
            } else {
              dispatch(
                createUserProviderFactoryCompanyError(
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().views.provider_factory.steps.company.callbacks.create_error
                )
              );
            }

            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().views.provider_factory.steps.company.callbacks.create_error,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              createUserProviderFactoryCompanyError(
                translation().views.provider_factory.steps.company.callbacks.create_error
              )
            );
            dispatch(
              enqueueSnackbar({
                message: translation().views.provider_factory.steps.company.callbacks.create_error,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    },
    createProviderAddress(user, { address, name }, isCompanyToggled, onSuccess, onError) {
      if (!address || !name || !user || !user.id) return;

      dispatch(createProviderFactoryAddress());

      fetchApi(
        'get',
        'geocoder/geocode',
        { address },
        null,
        null,
        (geoData) => {
          const geoAddress = {
            name,
            latitude: geoData.latitude,
            longitude: geoData.longitude,
            street: geoData.street,
            street_number: geoData.street_number,
            zip_code: geoData.zip_code,
            city: geoData.city,
            country_isocode: geoData.country_isocode,
            state_isocode: geoData.state_isocode
          };

          if (isCompanyToggled && user.company && user.company.id && user.company_admin) {
            fetchApi(
              'post',
              `companies/${user.company.id}/addresses`,
              null,
              geoAddress,
              null,
              (success) => {
                dispatch(createProviderFactoryAddressSuccess({ address, name }));

                if (onSuccess && typeof onSuccess === 'function') onSuccess(success);
              },
              (error) => {
                dispatch(createProviderFactoryAddressError(error));

                if (onError && typeof onError === 'function') onError(error);
              }
            );
          } else {
            fetchApi(
              'post',
              `users/${user.id}/addresses`,
              null,
              geoAddress,
              null,
              (success) => {
                dispatch(createProviderFactoryAddressSuccess({ address, name }));

                if (onSuccess && typeof onSuccess === 'function') onSuccess(success);
              },
              (error) => {
                dispatch(createProviderFactoryAddressError(error));

                if (onError && typeof onError === 'function') onError(error);
              }
            );
          }
        },
        (geoError) => {
          dispatch(createProviderFactoryAddressError(geoError));

          if (onError && typeof onError === 'function') onError(geoError);
        }
      );
    },
    getUserProviderPayer(userId, onSuccess, onError) {
      if (!userId) return;

      fetchApi(
        'get',
        'payers',
        // userType === "companies" ? { filters: `iam_company_id|eq|${userId}` } : { filters: `iam_user_id|eq|${userId}` },
        { filters: `iam_user_id|eq|${userId}` },
        null,
        null,
        (success) => {
          if (onSuccess && typeof onSuccess === 'function') {
            onSuccess(success);
          }
        },
        (error) => {
          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.dialogs.custom_list_actions.payer_bridge
                        .callback_error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message:
                  translation().core.list.dialogs.custom_list_actions.payer_bridge.callback_error,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (onError && typeof onError === 'function') {
            onError();
          }
        }
      );
    },
    getUserProviderPaymentMethods(userId, onSuccess, onError) {
      if (!userId) return;

      dispatch(createProviderFactoryPaymentMethod());

      fetchApi(
        'get',
        `users/${userId}/payment-methods`,
        null,
        null,
        null,
        (success) => {
          dispatch(createProviderFactoryPaymentMethodSuccess(success[0]));

          if (onSuccess && typeof onSuccess === 'function') {
            onSuccess(success);
          }
        },
        (error) => {
          dispatch(createProviderFactoryPaymentMethodError());

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.dialogs.custom_list_actions.pay_withdrawal.callbacks
                        .get_methods,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message:
                  translation().core.list.dialogs.custom_list_actions.pay_withdrawal.callbacks
                    .get_methods,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (onError && typeof onError === 'function') {
            onError();
          }
        }
      );
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProviderFactory));
