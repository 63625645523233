import './fixedHeaderTable.scss';

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

function ConstructTableHeader(rows) {
  if (!rows || !rows[0]) return;

  const row = Object.keys(rows[0]);
  const tableHeader = [];

  if (row.length <= 0) return;

  for (let i = 0; i < row.length; i++) {
    if (row[i] === 'flag') continue;

    tableHeader.push(<TableCell key={i}>{row[i]}</TableCell>);
  }

  return tableHeader;
}

ConstructTableHeader.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.any).isRequired
};

function FixedHeaderTable(props) {
  const {
    isActions,
    isLoading,
    onScrollList,
    rows,
    style,
    tableValues,
    tableHeader,
    className,
    useMarginBottom
  } = props;

  const marginBottom = useMarginBottom ? 300 : 0;

  function handleInfiniteScroll(event) {
    if (!event || !event.target) console.error('No table scroll event found.');

    if (!event.target.scrollTop || event.target.scrollTop === 0) return;

    if (
      event.target.scrollHeight - event.target.clientHeight - marginBottom <=
      event.target.scrollTop
    ) {
      if (onScrollList && typeof onScrollList === 'function') onScrollList(event.target);
    }
  }

  const tableHeaderFromData = ConstructTableHeader(rows);

  return (
    <div className={classNames('FixedHeaderTable', { loading: isLoading })} style={style}>
      <TableContainer
        className={classNames('FixedHeaderTableContainer', className)}
        onScroll={handleInfiniteScroll}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            {tableHeader ? (
              tableHeader
            ) : (
              <TableRow>
                {tableHeaderFromData}
                {isActions && <TableCell />}
              </TableRow>
            )}
            {isActions && <TableRow />}
          </TableHead>
          <TableBody>{tableValues ? tableValues : []}</TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

FixedHeaderTable.defaultProps = {
  isActions: true,
  useMarginBottom: true,
  style: {}
};

FixedHeaderTable.propTypes = {
  className: PropTypes.string,
  isActions: PropTypes.bool,
  isLoading: PropTypes.bool,
  rows: PropTypes.arrayOf(PropTypes.any),
  tableHeader: PropTypes.arrayOf(PropTypes.any),
  tableValues: PropTypes.arrayOf(PropTypes.any).isRequired,
  style: PropTypes.shape(),
  onScrollList: PropTypes.func,
  useMarginBottom: PropTypes.bool
};

export default FixedHeaderTable;
