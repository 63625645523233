export const clearOrderCreateReducer = () => ({
  type: 'CLEAR_ORDER_CREATE_REDUCER'
});

export const setActiveOrderStep = (step) => ({
  type: 'SET_ACTIVE_ORDER_STEP',
  step
});

export const storeClient = (user) => ({
  type: 'STORE_CLIENT',
  user
});

export const storeProperty = (property) => ({
  type: 'STORE_PROPERTY',
  property
});

export const storePropertyLocation = (location) => ({
  type: 'STORE_PROPERTY_LOCATION',
  location
});

export const storePropertyTimezone = (timezone) => ({
  type: 'STORE_PROPERTY_TIMEZONE',
  timezone
});

export const storePropertyValue = (name, value) => ({
  type: 'STORE_PROPERTY_VALUE',
  name,
  value
});

export const storeEstateValue = (name, value) => ({
  type: 'STORE_ESTATE_VALUE',
  name,
  value
});

export const storeOrderReference = (value) => ({
  type: 'STORE_ORDER_REFERENCE',
  value
});

export const storePaymentDelegateValue = (name, value) => ({
  type: 'STORE_PAYMENT_DELEGATE_VALUE',
  name,
  value
});

export const togglePaymentDelegate = () => ({
  type: 'TOGGLE_PAYMENT_DELEGATE'
});

export const closePaymentDelegate = () => ({
  type: 'CLOSE_PAYMENT_DELEGATE'
});

export const getClientPaymentMethods = () => ({
  type: 'GET_CLIENT_PAYMENT_METHODS'
});

export const getClientPaymentMethodsSuccess = (data) => ({
  type: 'GET_CLIENT_PAYMENT_METHODS_SUCCESS',
  data
});

export const getClientPaymentMethodsError = (error) => ({
  type: 'GET_CLIENT_PAYMENT_METHODS_ERROR',
  error
});

export const getClientAddresses = () => ({
  type: 'GET_CLIENT_ADDRESSES'
});

export const getClientAddressesSuccess = (data) => ({
  type: 'GET_CLIENT_ADDRESSES_SUCCESS',
  data
});

export const getClientAddressesError = (error) => ({
  type: 'GET_CLIENT_ADDRESSES_ERROR',
  error
});

export const getClientCart = () => ({
  type: 'GET_CLIENT_CART'
});

export const getClientCartSuccess = (data) => ({
  type: 'GET_CLIENT_CART_SUCCESS',
  data
});

export const getClientCartError = (error) => ({
  type: 'GET_CLIENT_CART_ERROR',
  error
});

export const createClientCart = () => ({
  type: 'CREATE_CLIENT_CART'
});

export const createClientCartSuccess = (data) => ({
  type: 'CREATE_CLIENT_CART_SUCCESS',
  data
});

export const createClientCartError = (error) => ({
  type: 'CREATE_CLIENT_CART_ERROR',
  error
});

export const deleteClientCart = () => ({
  type: 'DELETE_CLIENT_CART'
});

export const deleteClientCartSuccess = () => ({
  type: 'DELETE_CLIENT_CART_SUCCESS'
});

export const deleteClientCartError = (error) => ({
  type: 'DELETE_CLIENT_CART_ERROR',
  error
});

export const getProductGroups = () => ({
  type: 'GET_PRODUCT_GROUPS'
});

export const getProductGroupsSuccess = (data) => ({
  type: 'GET_PRODUCT_GROUPS_SUCCESS',
  data
});

export const getProductGroupsError = (error) => ({
  type: 'GET_PRODUCT_GROUPS_ERROR',
  error
});

export const getProducts = () => ({
  type: 'GET_PRODUCTS'
});

export const getProductsSuccess = (data) => ({
  type: 'GET_PRODUCTS_SUCCESS',
  data
});

export const getProductsError = (error) => ({
  type: 'GET_PRODUCTS_ERROR',
  error
});

export const getProductOptions = () => ({
  type: 'GET_PRODUCT_OPTIONS'
});

export const getProductOptionsSuccess = (data) => ({
  type: 'GET_PRODUCT_OPTIONS_SUCCESS',
  data
});

export const getProductOptionsError = (error) => ({
  type: 'GET_PRODUCT_OPTIONS_ERROR',
  error
});

export const blurProductOptions = () => ({
  type: 'BLUR_PRODUCT_OPTIONS'
});

export const getProductSlots = () => ({
  type: 'GET_PRODUCT_SLOTS'
});

export const getProductSlotsSuccess = (slots) => ({
  type: 'GET_PRODUCT_SLOTS_SUCCESS',
  slots
});

export const getProductSlotsError = (error) => ({
  type: 'GET_PRODUCT_SLOTS_ERROR',
  error
});

export const getPreviousProductSlots = () => ({
  type: 'GET_PREVIOUS_PRODUCT_SLOTS'
});

export const getPreviousProductSlotsSuccess = (slots) => ({
  type: 'GET_PREVIOUS_PRODUCT_SLOTS_SUCCESS',
  slots
});

export const getPreviousProductSlotsError = (error) => ({
  type: 'GET_PREVIOUS_PRODUCT_SLOTS_ERROR',
  error
});

export const getNextProductSlots = () => ({
  type: 'GET_NEXT_PRODUCT_SLOTS'
});

export const getNextProductSlotsSuccess = (slots) => ({
  type: 'GET_NEXT_PRODUCT_SLOTS_SUCCESS',
  slots
});

export const getNextProductSlotsError = (error) => ({
  type: 'GET_NEXT_PRODUCT_SLOTS_ERROR',
  error
});

export const createCalendar = (calendar, startDate, endDate) => ({
  type: 'CREATE_CALENDAR',
  payload: {
    calendar,
    startDate,
    endDate
  }
});

export const addProductToCart = () => ({
  type: 'ADD_PRODUCT_TO_CART'
});

export const addProductToCartSuccess = () => ({
  type: 'ADD_PRODUCT_TO_CART_SUCCESS'
});

export const addProductToCartError = (error) => ({
  type: 'ADD_PRODUCT_TO_CART_ERROR',
  error
});

export const deleteClientCartItem = () => ({
  type: 'DELETE_CLIENT_CART_ITEM'
});

export const deleteClientCartItemSuccess = () => ({
  type: 'DELETE_CLIENT_CART_ITEM_SUCCESS'
});

export const deleteClientCartItemError = (error) => ({
  type: 'DELETE_CLIENT_CART_ITEM_ERROR',
  error
});

export const setNbTimeMax = (number) => ({
  type: 'SET_NB_TIME_MAX',
  number
});

export const setMissingFields = (fields) => ({
  type: 'SET_MISSING_FIELDS',
  fields
});

export const postMissingFields = () => ({
  type: 'POST_MISSING_FIELDS'
});

export const postMissingFieldsSuccess = () => ({
  type: 'POST_MISSING_FIELDS_SUCCESS'
});

export const postMissingFieldsError = (error) => ({
  type: 'POST_MISSING_FIELDS_ERROR',
  error
});

export const setValidatingFields = (fields) => ({
  type: 'SET_VALIDATING_FIELDS',
  fields
});

export const cancelValidatingFields = () => ({
  type: 'CANCEL_VALIDATING_FIELDS'
});

export const postValidatingFields = () => ({
  type: 'POST_VALIDATING_FIELDS'
});

export const postValidatingFieldsSuccess = () => ({
  type: 'POST_VALIDATING_FIELDS_SUCCESS'
});

export const postValidatingFieldsError = (error) => ({
  type: 'POST_VALIDATING_FIELDS_ERROR',
  error
});

export const postClientAddress = () => ({
  type: 'POST_CLIENT_ADDRESS'
});

export const postClientAddressSuccess = () => ({
  type: 'POST_CLIENT_ADDRESS_SUCCESS'
});

export const postClientAddressError = (error) => ({
  type: 'POST_CLIENT_ADDRESS_ERROR',
  error
});

export const addDiscountToCart = () => ({
  type: 'ADD_DISCOUNT_TO_CART'
});

export const addDiscountToCartSuccess = () => ({
  type: 'ADD_DISCOUNT_TO_CART_SUCCESS'
});

export const addDiscountToCartError = (error) => ({
  type: 'ADD_DISCOUNT_TO_CART_ERROR',
  error
});

export const removeDiscountToCart = () => ({
  type: 'REMOVE_DISCOUNT_TO_CART'
});

export const removeDiscountToCartSuccess = () => ({
  type: 'REMOVE_DISCOUNT_TO_CART_SUCCESS'
});

export const removeDiscountToCartError = (error) => ({
  type: 'REMOVE_DISCOUNT_TO_CART_ERROR',
  error
});

export const selectClientPaymentMethod = (selected) => ({
  type: 'SELECT_CLIENT_PAYMENT_METHOD',
  selected
});

export const selectClientInvoiceAddress = (selected) => ({
  type: 'SELECT_CLIENT_INVOICE_ADDRESS',
  selected
});

export const createOrder = () => ({
  type: 'CREATE_ORDER'
});

export const createOrderSuccess = (data) => ({
  type: 'CREATE_ORDER_SUCCESS',
  data
});

export const createOrderError = (error) => ({
  type: 'CREATE_ORDER_ERROR',
  error
});
