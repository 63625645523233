import './style.scss';

import axios from 'axios';
import React, { PureComponent, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  LinearProgress,
  Link as MuiLink,
  Slide,
  TableCell,
  TableRow,
  Toolbar,
  Tooltip,
  Typography
} from '@material-ui/core';
import {
  Close as CloseIcon,
  KeyboardArrowRight,
  Error,
  ShoppingCart as ShoppingCartIcon,
  Warning
} from '@material-ui/icons';

import Alert from '../../components/alert/Alert';
import FiltersContainer from '../../containers/filters/FiltersContainer';
import FixedHeaderTable from '../../components/tables/fixedHeaderTable/FixedHeaderTable';
import ListEmptyIcon from '../../components/icons/ListEmptyIcon';
import ViewBar from './components/ViewBar';
import {
  addToDate,
  calcDateDiff,
  dateInTz,
  getCurrentDateUtc,
  toUtcTimestamp
} from '../../utils/functions/dates';
import config from '../../config';
import translation from '../../translation/translation';

const columns = [
  'id',
  'flag',
  'name',
  'provider_name',
  'provider_id',
  'start',
  'end',
  'created',
  'delivery_time',
  'delivery_status'
];

const useStyles = makeStyles((theme) => ({
  iconStatus: {
    width: 27
  },
  filterAppBar: {
    position: 'relative'
  },
  filterCloseBtn: {
    marginLeft: 'auto'
  },
  filterDialogRoot: {
    padding: 15
  },
  labelImportant: {
    color: theme.palette.type === 'dark' ? '#cb6059' : config.error_color || '#F44336'
  },
  linkImportant: {
    color: theme.palette.type === 'dark' ? '#d3834b' : '#ff6a00'
  },
  optionImportant: {
    backgroundColor: fade(
      theme.palette.type === 'dark' ? '#cb6059' : config.error_color || '#F44336',
      0.5
    )
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  orderLink: {
    marginTop: 0,
    marginBottom: 0,
    margin: 8
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class CustomLink extends PureComponent {
  render() {
    return (
      <Link to={this.props.link} {...this.props}>
        {this.props.children}
      </Link>
    );
  }
}

CustomLink.propTypes = {
  link: PropTypes.string.isRequired,
  children: PropTypes.any
};

const createCancelToken = () => axios.CancelToken.source();

const GetDataApi = (getData, url) => {
  const [source] = useState(createCancelToken());

  const doRefresh = () => {
    getData(url, source, null, null, true);
  };

  useEffect(() => {
    getData(url, source);

    return () => {
      source.cancel('=> Core data changed.');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { doRefresh };
};

const GetMetadataApi = (getMetadata, url) => {
  const [source] = useState(createCancelToken());

  useEffect(() => {
    getMetadata(url, source);

    return () => {
      source.cancel('=> Core metadata changed.');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const SearchDataApi = (getData, url) => {
  const [source] = useState(createCancelToken());

  const doSearch = (search) => {
    if (search) getData(url, source, search);
  };

  useEffect(() => {
    return () => {
      source.cancel('=> Search: Cancelled.');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { doSearch };
};

const GetNextDataApi = (getData, url) => {
  const [source] = useState(createCancelToken());

  const doNext = (initialNext) => {
    if (initialNext) getData(url, source, null, initialNext);
  };

  useEffect(() => {
    return () => {
      source.cancel('=> Next: Cancelled.');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { doNext };
};

function OrderProductsOverdue(props) {
  const {
    clearFilters,
    clearOwnReducer,
    filterOrderProducts,
    getData,
    getMetadata,
    history,
    list: {
      data,
      isError,
      isLoading,
      paging,
      search: { value }
    },
    metadata,
    metadataUri,
    parentUri,
    setDataSearchValue,
    setListNeedReload
  } = props;

  const routeUri = 'orders/all/products/active';
  const classes = useStyles();
  const [submitBuffer, setSubmitBuffer] = useState(0);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  useEffect(() => {
    document.title = `Actarus | ${translation().views.production.order_products_overdue.title}`;
    setListNeedReload();

    return () => {
      clearOwnReducer();
      clearFilters();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  GetMetadataApi(getMetadata, metadataUri);

  const { doRefresh } = GetDataApi(getData, routeUri);
  const { doSearch } = SearchDataApi(getData, routeUri);
  const { doNext } = GetNextDataApi(getData, routeUri);

  function handleScrollList() {
    if (!isLoading && paging && paging.next) {
      doNext(paging.next);
    }
  }

  function handleSubmit(val) {
    if (val) {
      clearFilters();
      doSearch(val);
      setDataSearchValue(val);
    } else {
      handleRefresh();
    }
  }

  function handleRefresh() {
    doRefresh();
    clearFilters();
  }

  function handleOpenFilter() {
    setIsFiltersOpen(true);
  }

  function handleCloseFilter() {
    setIsFiltersOpen(false);
  }

  function handleFilterData() {
    setSubmitBuffer((submitBuffer) => submitBuffer + 1);
    setDataSearchValue('');
    handleCloseFilter();
    filterOrderProducts(routeUri, metadata.data.fields || []);
  }

  function renderOverdueIcon(expected) {
    const now = getCurrentDateUtc();

    if (toUtcTimestamp(expected) > toUtcTimestamp(now)) {
      return <Error style={{ color: '#ffa500' }} />;
    } else {
      return <Warning style={{ color: '#F44336' }} />;
    }
  }

  function calcOverdueTime(expectedDate) {
    const now = getCurrentDateUtc();
    const diff = calcDateDiff(expectedDate, now);

    return `${
      diff.days && diff.days !== '00' ? diff.days + translation().commons.day_short + ' ' : ''
    }${diff.hours ? diff.hours + translation().commons.hour_short + ' ' : ''}${
      diff.minutes ? diff.minutes + 'm ' : ''
    }`;
  }

  function getTableBodyFromData(orderProducts) {
    const tableBody = [];

    for (let line in orderProducts) {
      let temp_body = [];

      for (let key in orderProducts[line]) {
        if (columns.indexOf(key) !== -1) {
          if (key === 'id') {
            temp_body[key] = orderProducts[line][key];
          }
          if (key === 'flag') {
            temp_body[key] = orderProducts[line][key];
          } else if (key === 'name') {
            temp_body[key] = orderProducts[line][key];
          } else if (key === 'provider_name') {
            temp_body[key] = orderProducts[line][key];
          } else if (key === 'provider_id') {
            temp_body[key] = orderProducts[line][key];
          } else if (key === 'start') {
            temp_body['startDateUtc'] = orderProducts[line][key];
          } else if (key === 'created') {
            temp_body['createdDateUtc'] = orderProducts[line][key];
            temp_body['createdDate'] = dateInTz(orderProducts[line][key]);
          } else if (key === 'delivery_time') {
            temp_body['deliveryTime'] = orderProducts[line][key];
          } else if (key === 'end') {
            temp_body['endDate'] = dateInTz(orderProducts[line][key]);
          } else if (key === 'delivery_status') {
            temp_body['delivery_status_icon'] = orderProducts[line][key].icon_url_dark;
            temp_body['delivery_status_name'] = orderProducts[line][key].name;
          }

          temp_body['order_id'] = orderProducts[line].order_id;
        }
      }

      temp_body['expectedDate'] = addToDate(
        temp_body['endDate'] ? temp_body['endDate'] : temp_body['createdDate'],
        temp_body['deliveryTime'],
        'hour'
      );

      tableBody.push(temp_body);
    }

    return tableBody;
  }

  function renderTable(data) {
    if (data && data.length) {
      const tableBody = getTableBodyFromData(data);

      const tableHeader = [
        <TableRow key="tableHeader">
          <TableCell />
          <TableCell>
            {translation().views.production.order_products_overdue.columns.order_id}
          </TableCell>
          <TableCell>
            {translation().views.production.order_products_overdue.columns.product_id}
          </TableCell>
          <TableCell>
            {translation().views.production.order_products_overdue.columns.name}
          </TableCell>
          <TableCell>
            {translation().views.production.order_products_overdue.columns.provider}
          </TableCell>
          <TableCell>
            {translation().views.production.order_products_overdue.columns.start}
          </TableCell>
          <TableCell>
            {translation().views.production.order_products_overdue.columns.date}
          </TableCell>
          <TableCell>
            {translation().views.production.order_products_overdue.columns.overdue}
          </TableCell>
          <TableCell>
            {translation().views.production.order_products_overdue.columns.status}
          </TableCell>
          <TableCell />
        </TableRow>
      ];

      const tableValues = tableBody.map((row, index) => {
        const typoClass = classNames({
          [`${classes.labelImportant}`]: row.flag
        });
        const linkClass = classNames({
          [`${classes.linkImportant}`]: row.flag
        });

        return (
          <TableRow key={index}>
            <TableCell>{renderOverdueIcon(row.expectedDate)}</TableCell>
            <TableCell className={typoClass}>
              <Typography variant="body2">{row.order_id}</Typography>
            </TableCell>
            <TableCell className={typoClass}>
              <Typography variant="body2">{row.id}</Typography>
            </TableCell>
            <TableCell className={typoClass} style={{ maxWidth: 250 }}>
              <Typography variant="body2">{row.name}</Typography>
            </TableCell>
            <TableCell className={typoClass}>
              <Typography variant="body2">
                <MuiLink
                  className={linkClass}
                  href={`/providers/${row.provider_id}/provider-view`}
                  target="_blank"
                  rel="noopener noreferrer">
                  {row.provider_name}
                </MuiLink>
              </Typography>
            </TableCell>
            <TableCell className={typoClass}>
              <Typography variant="body2">
                {dateInTz(row.startDateUtc, 'localized-datetime')}
              </Typography>
            </TableCell>
            <TableCell className={typoClass}>
              <Typography variant="body2">
                {dateInTz(row.createdDateUtc, 'localized-date')}
              </Typography>
            </TableCell>
            <TableCell className={typoClass}>
              <Typography variant="body2">{calcOverdueTime(row.expectedDate)}</Typography>
            </TableCell>
            <TableCell>
              <img
                className={classes.iconStatus}
                src={row.delivery_status_icon}
                title={row.delivery_status_name}
                alt={row.delivery_status_name}
              />
            </TableCell>
            <TableCell key={'action-' + index} style={{ textAlign: 'right' }}>
              <Tooltip title={translation().actions.custom_list_actions.see_order}>
                <IconButton
                  className={classes.orderLink}
                  component={CustomLink}
                  link={`/orders/${row.order_id}/update`}
                  target="_blank">
                  <ShoppingCartIcon />
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        );
      });

      return (
        <FixedHeaderTable
          tableHeader={tableHeader}
          tableValues={tableValues}
          metadata={metadata}
          onScrollList={handleScrollList}
          isLoading={isLoading}
        />
      );
    }
  }

  return (
    <div className="OrderProductsOverdue">
      <ViewBar
        isLoading={isLoading}
        handleSubmit={handleSubmit}
        handleRefresh={handleRefresh}
        handleOpenFilter={handleOpenFilter}
        handleCloseFilter={handleCloseFilter}
        history={history}
        isFilterable
        parentUri={parentUri}
        routeUri={translation().views.production.order_products_overdue.title}
        search={value}
        submitBuffer={submitBuffer}
      />
      {!metadata.isLoading && data && data.length ? renderTable(data) : false}
      {!isLoading && paging && paging.next && (
        <Tooltip title={translation().core.list.charge_next} enterDelay={500} placement="left">
          <IconButton
            onClick={handleScrollList}
            disabled={isLoading || metadata.isLoading}
            aria-label="Next Page"
            style={{
              position: 'absolute',
              bottom: 5,
              right: 15,
              padding: 5,
              zIndex: 10
            }}>
            <KeyboardArrowRight style={{ fontSize: 18 }} />
          </IconButton>
        </Tooltip>
      )}
      {!isLoading && isError && (!data || data.length <= 0) ? (
        <div className="error-list">
          <Alert
            type="danger"
            style={{ marginTop: 10 }}
            status={translation().commons.alerts.error}
            text={translation().core.list.error_list}
          />
        </div>
      ) : (
        false
      )}
      {(isLoading || metadata.isLoading) && !paging && (
        <div className="loader-wpr">
          <CircularProgress color="primary" />
          <p>{translation().views.order_products.list.loading_text}</p>
          {metadata.isLoading && <p>{translation().core.load_metadata}</p>}
        </div>
      )}
      {isLoading && paging && paging.next && (
        <>
          <LinearProgress color="primary" />
          <div className="loader-wpr linear">
            <p>{translation().core.list.load_next}</p>
          </div>
        </>
      )}
      {!metadata.isLoading &&
        !isLoading &&
        !metadata.isLoading &&
        !isError &&
        (!data || !data.length) && (
          <div className="loader-wpr linear">
            <ListEmptyIcon />
            <p style={{ margin: '7px 0', lineHeight: 'initial' }}>
              {translation().core.list.empty}
            </p>
          </div>
        )}
      {!metadata.isLoading && !isLoading && paging && !paging.next && paging.prev && (
        <div className="loader-wpr linear">
          <p>{translation().core.list.load_next_done}</p>
        </div>
      )}
      {!metadata.isLoading && !isLoading && !paging && data && data.length > 0 ? (
        <div className="loader-wpr linear">
          <p>{translation().core.list.load_next_done}</p>
        </div>
      ) : (
        false
      )}
      <Dialog
        fullScreen
        open={isFiltersOpen}
        onClose={handleCloseFilter}
        TransitionComponent={Transition}>
        <AppBar className={classes.filterAppBar}>
          <Toolbar>
            <Typography variant="h6">{translation().actions.filter}</Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseFilter}
              className={classes.filterCloseBtn}
              aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent className={classes.filterDialogRoot}>
          <FiltersContainer metadata={metadata} onSubmit={handleFilterData} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFilter} color="default">
            {translation().actions.close}
          </Button>
          <Button color="secondary" variant="contained" onClick={handleFilterData}>
            {translation().actions.search}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

OrderProductsOverdue.defaultProps = {
  metadataUri: 'orders/1/products'
};

OrderProductsOverdue.propTypes = {
  clearFilters: PropTypes.func.isRequired,
  clearOwnReducer: PropTypes.func.isRequired,
  filterOrderProducts: PropTypes.func.isRequired,
  getData: PropTypes.func.isRequired,
  getMetadata: PropTypes.func.isRequired,
  history: PropTypes.shape().isRequired,
  list: PropTypes.shape({
    search: PropTypes.shape({
      value: PropTypes.string
    }),
    data: PropTypes.arrayOf(PropTypes.shape()),
    paging: PropTypes.shape({
      next: PropTypes.string,
      prev: PropTypes.string
    }),
    isError: PropTypes.bool,
    isLoading: PropTypes.bool
  }).isRequired,
  metadata: PropTypes.shape(),
  metadataUri: PropTypes.string,
  parentUri: PropTypes.string.isRequired,
  setDataSearchValue: PropTypes.func.isRequired,
  setListNeedReload: PropTypes.func.isRequired
};

export default OrderProductsOverdue;
