/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import ButtonBase from '@material-ui/core/ButtonBase';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';

function Dashboard(props) {
  const {
    classes,
    data: { id, name },
    onClick,
    openDeleteDialog,
    openUpdateDialog
  } = props;

  function handleClick() {
    onClick(id);
  }

  function handleOpenDeleteDialog() {
    openDeleteDialog(props.data);
  }

  function handleSelectDashboard() {
    openUpdateDialog(props.data);
  }

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card className={classes.card}>
        <CardHeader
          title={name || 'Missing name dashboard'}
          classes={{
            action: classes.cardHeaderAction
          }}
          action={[
            <IconButton
              key={`edit-dashboard-${id}`}
              aria-label={`edit-dashboard-${id}`}
              size="small"
              className={classes.cardAction}
              onClick={handleSelectDashboard}>
              <EditIcon fontSize="small" />
            </IconButton>,
            <IconButton
              key={`delete-dashboard-${id}`}
              aria-label={`delete-dashboard-${id}`}
              size="small"
              className={classes.cardAction}
              onClick={handleOpenDeleteDialog}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          ]}
        />
        <ButtonBase component={CardContent} className={classes.cardContent} onClick={handleClick}>
          <img
            src="/assets/images/dashboard/dashboard-illustration.svg"
            alt="dashboard"
            className={classes.img}
          />
        </ButtonBase>
      </Card>
    </Grid>
  );
}

Dashboard.propTypes = {
  classes: PropTypes.shape().isRequired,
  data: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  openDeleteDialog: PropTypes.func.isRequired,
  openUpdateDialog: PropTypes.func.isRequired
};

export default Dashboard;
