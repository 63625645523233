import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchApi } from '../../../../utils/functions/api';
import { enqueueSnackbar } from '../../../../redux/actions/appActions';
import {
  clearDashboard,
  getDashboard,
  getDashboardSuccess,
  getDashboardError,
  moveMetricsIntoDashboard,
  removeMetricFromDashboard,
  setDashboardDateRange,
  setMetricToDashboard
} from '../../../../redux/actions/metrics/dashboard/update/updateDashboardMetricsActions';
import translation from '../../../../translation/translation';
import UpdateDashboardMetrics from '../../../../views/metrics/dashboard/update/UpdateDashboardMetrics';

const mapStateToProps = (store) => ({
  update: store.metrics.dashboard.update
});

const mapDispatchToProps = (dispatch) => {
  return {
    clearDashboard() {
      dispatch(clearDashboard());
    },
    setMetricToDashboard(name) {
      dispatch(setMetricToDashboard(name));
    },
    removeMetricFromDashboard(id) {
      dispatch(removeMetricFromDashboard(id));
    },
    setDashboardDateRange(range) {
      dispatch(setDashboardDateRange(range));
    },
    moveMetricsIntoDashboard(metrics) {
      dispatch(moveMetricsIntoDashboard(metrics));
    },
    getDashboard: (dashboardId, callbackSuccess = null, callbackError = null) => {
      if (!dashboardId) return;

      dispatch(getDashboard());

      fetchApi(
        'get',
        `users/self/dashboards/${dashboardId}`,
        null,
        null,
        null,
        (success) => {
          if (success) {
            const data = { ...success };

            if (data.metrics && data.metrics.length) {
              /**
               * For all metrics we create a render buffer
               */
              for (let i = 0; i < data.metrics.length; i++) {
                data.metrics[i].submitBuffer = 0;
              }

              dispatch(getDashboardSuccess(data));
            } else {
              dispatch(getDashboardSuccess(data));
            }

            if (callbackSuccess && typeof callbackSuccess === 'function') {
              callbackSuccess(success);
            }
          }
        },
        (error) => {
          dispatch(getDashboardError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().metrics.dashboard.update.metrics.get.callbacks.error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().metrics.dashboard.update.metrics.get.callbacks.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError(error);
          }
        }
      );
    },
    deleteDashbardMetric: (dashboardId, metricId, callbackSuccess = null, callbackError = null) => {
      if (!dashboardId || !metricId) return;

      fetchApi(
        'delete',
        `users/self/dashboards/${dashboardId}/metrics/${metricId}`,
        null,
        null,
        null,
        (success) => {
          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success);
          }
        },
        (error) => {
          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().metrics.dashboard.update.metrics.delete.callbacks.error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().metrics.dashboard.update.metrics.delete.callbacks.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError(error);
          }
        }
      );
    },
    createDashboardMetric: (
      dashboardId,
      data = null,
      callbackSuccess = null,
      callbackError = null
    ) => {
      if (!dashboardId || !data) return;

      fetchApi(
        'post',
        `users/self/dashboards/${dashboardId}/metrics`,
        null,
        data,
        null,
        (success) => {
          /**
           * Initialize the render buffer of the metric
           */
          success.submitBuffer = 0;

          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success);
          }
        },
        (error) => {
          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().metrics.dashboard.update.metrics.create.callbacks.error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().metrics.dashboard.update.metrics.create.callbacks.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError(error);
          }
        }
      );
    },
    updateDashboardMetric: (
      dashboardId,
      metricId,
      data = null,
      buffer = 0,
      callbackSuccess = null,
      callbackError = null
    ) => {
      if (!dashboardId || !metricId || !data) return;

      fetchApi(
        'put',
        `users/self/dashboards/${dashboardId}/metrics/${metricId}`,
        null,
        data,
        null,
        (success) => {
          /**
           * We are forced to increment buffer for re-render the metric
           */
          success.submitBuffer = buffer + 1;

          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success);
          }
        },
        (error) => {
          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().metrics.dashboard.update.metrics.update.callbacks.error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().metrics.dashboard.update.metrics.update.callbacks.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError(error);
          }
        }
      );
    },
    updateDashboardMetricPosition: (
      dashboardId,
      metricId,
      position,
      callbackSuccess = null,
      callbackError = null
    ) => {
      if (!dashboardId || !metricId) return;

      fetchApi(
        'put',
        `users/self/dashboards/${dashboardId}/metrics/${metricId}`,
        null,
        { position },
        null,
        (success) => {
          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success);
          }
        },
        (error) => {
          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().metrics.dashboard.update.metrics.update.callbacks.error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().metrics.dashboard.update.metrics.update.callbacks.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError(error);
          }
        }
      );
    },
    getMetrics: (route, query, callbackSuccess = null, callbackError = null) => {
      if (!route || !query) return;

      fetchApi(
        'get',
        `metrics/${route}`,
        {
          ...query,
          split: true
        },
        null,
        null,
        (success) => {
          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success);
          }
        },
        (error) => {
          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.callbacks.error_list,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().core.list.callbacks.error_list,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError(error);
          }
        }
      );
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UpdateDashboardMetrics));
