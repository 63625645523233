import './providersSlotsCheck.scss';

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import InputLabel from '@material-ui/core/InputLabel';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import { createDateUtc, toUnix } from '../../../utils/functions/dates';
import translation from '../../../translation/translation';

function getStyleLevel(slotLevel) {
  const style = { backgroundColor: 'transparent' };

  if (slotLevel === 1) {
    style.backgroundColor = '#64b5f6';
  } else if (slotLevel === 2) {
    style.backgroundColor = '#8de091';
  } else if (slotLevel === 3) {
    style.backgroundColor = '#ffee58';
  } else if (slotLevel === 4) {
    style.backgroundColor = '#ffa726';
  } else if (slotLevel === 5) {
    style.backgroundColor = '#ff6d6d';
  }

  return style;
}

function ResultTableCell(props) {
  const {
    address,
    getProductsSlots,
    product: { id }
  } = props;

  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [slot, setSlot] = useState(null);
  const [slotDiff, setSlotDiff] = useState(null);
  const [slotLevel, setSlotLevel] = useState(undefined);

  useEffect(() => {
    if (!address) return;

    setLoading(true);

    getProductsSlots(
      id,
      address,
      (slots) => {
        let slotLevel = null;
        const todayNowUtc = createDateUtc().toString();
        const firstSlotUtc = slots[0];
        const slotDiff = Math.round((toUnix(firstSlotUtc) - toUnix(todayNowUtc)) / 3600);

        if (slotDiff) {
          if (parseInt(slotDiff, 10) <= 24) {
            slotLevel = 1;
          } else if (parseInt(slotDiff, 10) > 24 && parseInt(slotDiff, 10) <= 48) {
            slotLevel = 2;
          } else if (parseInt(slotDiff, 10) > 48 && parseInt(slotDiff, 10) <= 96) {
            slotLevel = 3;
          } else if (parseInt(slotDiff, 10) > 96 && parseInt(slotDiff, 10) <= 168) {
            slotLevel = 4;
          } else if (parseInt(slotDiff, 10) > 168) {
            slotLevel = 5;
          }
        } else {
          slotLevel = 5;
        }

        setError(null);
        setLoading(false);
        setSlotLevel(slotLevel);
        setSlotDiff(slotDiff);
        setSlot(firstSlotUtc);
      },
      () => {
        setSlotLevel(undefined);
        setLoading(false);
        setError(true);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TableCell
      variant="body"
      style={getStyleLevel(slotLevel)}
      className="table-cell"
      align="center">
      {isLoading && <CircularProgress size={20} />}
      {slot && slotDiff ? <span className="slot-diff">{`${slotDiff}h`}</span> : false}
      {isError && <>Error</>}
    </TableCell>
  );
}

ResultTableCell.propTypes = {
  address: PropTypes.string,
  getProductsSlots: PropTypes.func.isRequired,
  product: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
  }).isRequired
};

function ResultTableRow(props) {
  const { address, getProductsSlots, productsChecked } = props;

  return (
    <TableRow>
      <TableCell variant="body" className="table-cell">
        {address}
      </TableCell>
      {productsChecked && productsChecked.length > 0
        ? productsChecked.map((product, index) => (
            <ResultTableCell
              key={index}
              address={address}
              product={product}
              getProductsSlots={getProductsSlots}
            />
          ))
        : null}
    </TableRow>
  );
}

ResultTableRow.propTypes = {
  address: PropTypes.string,
  getProductsSlots: PropTypes.func.isRequired,
  productsChecked: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

function ProvidersSlotsCheck(props) {
  const { getProducts, getProductsSlots } = props;
  const [isProductsLoading, setProductsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [checked, setChecked] = useState([]);
  const [isCheckedAll, setCheckedAll] = useState(false);
  const [addresses, setAddresses] = useState('');
  const [addressesSplitted, setAddressesSplitted] = useState(null);
  const [productsChecked, setProductsChecked] = useState([]);
  const [isTableLoading, setTableLoading] = useState(false);
  const [isDropdown, setDropdown] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const isLegendOpen = Boolean(anchorEl);

  useEffect(() => {
    document.title = 'Actarus | Providers slots check';

    loadProducts();

    return () => {
      try {
        window.stop();
      } catch (exception) {
        document.execCommand('Stop');
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function loadProducts(next = null) {
    setProductsLoading(true);

    getProducts(
      next,
      (data, paging) => {
        setProducts((products) => products.concat(data));
        setProductsLoading(false);

        if (paging && paging.next) {
          loadProducts(paging.next);
        }
      },
      () => {
        setProductsLoading(false);
      }
    );
  }

  function handleChangeAddresses(event) {
    setAddresses(event.target.value);
  }

  function handleSubmit() {
    if (!checked || !addresses) return;
    else if (checked && checked.length <= 0) return;

    setTableLoading(true);

    setTimeout(() => {
      setTableLoading(false);
      setDropdown(true);
      setAddressesSplitted(addresses.split('\n'));
      setProductsChecked(checked);
    }, 1000);
  }

  function handleCheckAll() {
    setChecked(products);
    setCheckedAll(true);
  }

  function handleUnCheckAll() {
    setChecked([]);
    setCheckedAll(false);
  }

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    if (newChecked.length <= 0) {
      setCheckedAll(false);
    }

    setChecked(newChecked);
  };

  function handleDropdown() {
    setDropdown((isDropdown) => !isDropdown);
  }

  function handleOpenLegend(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleCloseLegend() {
    setAnchorEl(null);
  }

  return (
    <div className="ProvidersSlotsCheck">
      <AppBar elevation={0} position="static" color="default">
        <Toolbar>
          <Typography variant="body2" color="textPrimary">
            {translation().views.providers_slots_check.page_title}
          </Typography>
          <div>
            <IconButton
              aria-label="Display legend"
              aria-controls="legend-appbar"
              aria-haspopup="true"
              onClick={handleOpenLegend}
              color="inherit">
              <HelpOutlineIcon />
            </IconButton>
          </div>
          <Popover
            id="legend-appbar"
            open={isLegendOpen}
            anchorEl={anchorEl}
            onClose={handleCloseLegend}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}>
            <div className="ProvidersSlotsCheck-legends">
              <ul>
                <li className="legend">
                  <div className="square-color" style={getStyleLevel(1)} />
                  <p>{'0h <= 24h'}</p>
                </li>
                <li className="legend">
                  <div className="square-color" style={getStyleLevel(2)} />
                  <p>{'24h <= 48h'}</p>
                </li>
                <li className="legend">
                  <div className="square-color" style={getStyleLevel(3)} />
                  <p>{'48h <= 96h'}</p>
                </li>
                <li className="legend">
                  <div className="square-color" style={getStyleLevel(4)} />
                  <p>{'96h <= 168h'}</p>
                </li>
                <li className="legend">
                  <div className="square-color" style={getStyleLevel(5)} />
                  <p>{`> 168h ${translation().views.providers_slots_check.or_empty}`}</p>
                </li>
              </ul>
            </div>
          </Popover>
        </Toolbar>
      </AppBar>
      <Paper
        className={classNames('ProvidersSlotsCheck-form', {
          toggled: isDropdown
        })}
        elevation={0}
        square>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <InputLabel className="form-label">
                {translation().views.providers_slots_check.products}
                {!isProductsLoading && (
                  <Link
                    className="check-all-link"
                    onClick={!isCheckedAll ? handleCheckAll : handleUnCheckAll}>
                    {isCheckedAll
                      ? translation().views.providers_slots_check.uncheck_all
                      : translation().views.providers_slots_check.check_all}
                  </Link>
                )}
              </InputLabel>
              <List className="products-list">
                {products && products.length
                  ? products.map((product, index) => {
                      const labelId = `checkbox-list-label-${product.id}`;
                      return (
                        <ListItem
                          key={index}
                          role={undefined}
                          dense
                          button
                          onClick={handleToggle(product)}>
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={checked.indexOf(product) !== -1}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </ListItemIcon>
                          <ListItemText id={labelId} primary={product.name} />
                        </ListItem>
                      );
                    })
                  : ''}
              </List>
              {isProductsLoading && (
                <div className="loader-wpr">
                  <CircularProgress size={25} color="primary" />
                  <p>{translation().views.providers_slots_check.loading_products}</p>
                </div>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="addresses-multiline-static" className="form-label">
                {translation().views.providers_slots_check.addresses}
              </InputLabel>
              <TextField
                id="addresses-multiline-static"
                placeholder="22 rue Legendre, Paris 75017, France"
                inputProps={{
                  style: { lineHeight: 1.74 },
                  className: 'address-textarea'
                }}
                onChange={handleChangeAddresses}
                value={addresses}
                multiline
                fullWidth
                rows={6}
              />
              <div className="sm-space" />
              <div className="submit-wrapper">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={
                    isProductsLoading || isTableLoading || checked.length <= 0 || !addresses
                  }>
                  {translation().views.providers_slots_check.submit}
                </Button>
                <IconButton
                  aria-label="dropdown"
                  onClick={handleDropdown}
                  className="arrow-dropdown">
                  <ArrowUpwardIcon fontSize="inherit" />
                </IconButton>
              </div>
            </Grid>
          </Grid>
        </Container>
      </Paper>
      {isDropdown && (
        <Paper className="ProvidersSlotsCheck-form" elevation={0} square>
          <Container>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} />
              <Grid item xs={12} sm={6}>
                <div className="submit-wrapper">
                  <IconButton
                    aria-label="upward"
                    onClick={handleDropdown}
                    className="arrow-dropdown">
                    <ArrowDownwardIcon fontSize="inherit" />
                  </IconButton>
                </div>
              </Grid>
            </Grid>
          </Container>
        </Paper>
      )}
      <Divider />
      <div className="ProvidersSlotsCheck-results">
        {!isTableLoading &&
        productsChecked &&
        productsChecked.length > 0 &&
        addressesSplitted &&
        addressesSplitted.length > 0 ? (
          <Paper square elevation={0}>
            <TableContainer className="table-slots-container">
              <Table stickyHeader aria-label="sticky table" className="table-slots-check">
                <TableHead>
                  <TableRow>
                    <TableCell variant="head" className="table-cell">
                      {translation().views.providers_slots_check.address}
                    </TableCell>
                    {productsChecked.map((product, i) => {
                      return (
                        <TableCell key={i} variant="head" className="table-cell">
                          {product.name}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {addressesSplitted.map((address, i) => {
                    return (
                      <ResultTableRow
                        key={i}
                        address={address}
                        productsChecked={productsChecked}
                        getProductsSlots={getProductsSlots}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        ) : (
          ''
        )}
        {isTableLoading && (
          <div className="loading-table">
            <div className="loader-wpr">
              <CircularProgress size={35} color="primary" />
              <p>{translation().views.providers_slots_check.loading_table}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

ProvidersSlotsCheck.propTypes = {
  getProducts: PropTypes.func.isRequired,
  getProductsSlots: PropTypes.func.isRequired
};

export default ProvidersSlotsCheck;
