import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import AndroidIcon from '@material-ui/icons/Android';
import AppleIcon from '@material-ui/icons/Apple';
import CircularProgress from '@material-ui/core/CircularProgress';
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import LinearProgress from '@material-ui/core/LinearProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import Alert from '../../../../../components/alert/Alert';
import { dateInTz } from '../../../../../utils/functions/dates';
import translation from '../../../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    maxHeight: 350
  },
  loadingWrapper: {
    width: '100%',
    height: 350,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      height: 'auto'
    }
  },
  platform: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  platformName: {
    marginLeft: 4
  }
}));

const createCancelToken = () => axios.CancelToken.source();

const GetDataApi = (getData, userId) => {
  const [source] = useState(createCancelToken());

  useEffect(() => {
    getData(userId, source);

    return () => {
      source.cancel('=> Cancel get provider devices.');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const GetNextDataApi = (getData, url) => {
  const [source] = useState(createCancelToken());

  const doNext = (initialNext) => {
    if (initialNext) getData(url, source, initialNext);
  };

  useEffect(() => {
    return () => {
      source.cancel('=> Next: Cancelled.');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { doNext };
};

function Devices(props) {
  const classes = useStyles();

  const columns = ['id', 'platform', 'brand', 'model', 'pusher', 'created'];

  const { userId, devices, getProviderViewDevices, cleanProviderViewDevicesReducer } = props;

  useEffect(() => {
    return () => {
      cleanProviderViewDevicesReducer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  GetDataApi(getProviderViewDevices, userId);

  const { doNext } = GetNextDataApi(getProviderViewDevices, userId);

  function handleInfiniteScroll(event) {
    if (!event) console.error('No table scroll event found.');

    if (!event.currentTarget.scrollTop || event.currentTarget.scrollTop === 0) return;

    if (
      event.currentTarget.scrollHeight - event.currentTarget.clientHeight <=
      event.currentTarget.scrollTop
    ) {
      if (devices && devices.paging && devices.paging.next) {
        doNext(devices.paging.next);
      }
    }
  }

  function renderPlatformIcon(platform) {
    switch (platform) {
      case 'ios':
        return (
          <div className={classes.platform}>
            <AppleIcon />
            <Typography variant="subtitle2" className={classes.platformName}>
              {platform}
            </Typography>
          </div>
        );
      case 'android':
        return (
          <div className={classes.platform}>
            <AndroidIcon />
            <Typography variant="subtitle2" className={classes.platformName}>
              {platform}
            </Typography>
          </div>
        );
      case 'safari':
        return (
          <div className={classes.platform}>
            <DesktopMacIcon />
            <Typography variant="subtitle2" className={classes.platformName}>
              {platform}
            </Typography>
          </div>
        );
      case 'chrome':
        return (
          <div className={classes.platform}>
            <DesktopWindowsIcon />
            <Typography variant="subtitle2" className={classes.platformName}>
              {platform}
            </Typography>
          </div>
        );
      default:
        return (
          <div className={classes.platform}>
            <DesktopWindowsIcon />
            <Typography variant="subtitle2" className={classes.platformName}>
              {platform}
            </Typography>
          </div>
        );
    }
  }

  return (
    <div className="ProviderDevices">
      {!devices.isLoading && devices.error && (!devices.data || devices.data.length <= 0) ? (
        <div className="error-list">
          <Alert
            type="danger"
            style={{ marginTop: 10 }}
            status={translation().commons.alerts.error}
            text={translation().core.list.error_list}
          />
        </div>
      ) : (
        false
      )}
      {devices.data && devices.data.length > 0 ? (
        <TableContainer onScroll={handleInfiniteScroll} className={classes.tableContainer}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column}>{column}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {devices.data.map((device, index) => (
                <TableRow key={index}>
                  <TableCell>{device.id}</TableCell>
                  <TableCell>
                    <div>{device.platform ? renderPlatformIcon(device.platform) : '-'}</div>
                  </TableCell>
                  <TableCell>{device.brand ? device.brand : '_'}</TableCell>
                  <TableCell>{device.model ? device.model : '-'}</TableCell>
                  <TableCell>{device.pusher ? device.pusher : '-'}</TableCell>
                  <TableCell>
                    {device.created ? dateInTz(device.created, 'localized-datetime') : '-'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : !devices.isLoading && !devices.error ? (
        <Alert
          type="info"
          style={{ marginTop: 10 }}
          status={translation().commons.alerts.info}
          text={translation().views.provider_view.devices.empty}
        />
      ) : (
        false
      )}
      {devices.isLoading && !devices.paging && (
        <div className={classNames(classes.loadingWrapper, 'loader-wpr')}>
          <div style={{ width: '100%' }}>
            <CircularProgress color="primary" />
            <p>{translation().views.provider_view.devices.loading_text}</p>
          </div>
        </div>
      )}
      {devices.isLoading && devices.paging && devices.paging.next ? (
        <>
          <LinearProgress color="primary" />
          <div className="loader-wpr linear">
            <p>{translation().core.list.load_next}</p>
          </div>
        </>
      ) : (
        <div className="end-of-list-wpr">
          {!devices.isLoading && devices.paging && !devices.paging.next && devices.paging.prev && (
            <p>{translation().core.list.load_next_done}</p>
          )}
        </div>
      )}
    </div>
  );
}

Devices.propTypes = {
  cleanProviderViewDevicesReducer: PropTypes.func.isRequired,
  devices: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape()),
    error: PropTypes.string,
    isLoading: PropTypes.bool,
    paging: PropTypes.shape({
      next: PropTypes.string,
      prev: PropTypes.string
    })
  }).isRequired,
  getProviderViewDevices: PropTypes.func.isRequired,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export default Devices;
