import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  fullScreen: {
    height: '100%',
    width: '100%',
    margin: '-4px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.default
  }
}));

function ActarusLoader(props) {
  const classes = useStyles();

  const { fullScreen, height, width } = props;

  return (
    <div className={classNames({ [classes.fullScreen]: fullScreen })}>
      <img src="/assets/images/logo-wish-animated.gif" alt="" height={height} width={width} />
    </div>
  );
}

ActarusLoader.defaultProps = {
  fullScreen: false,
  height: 50,
  width: 50
};

ActarusLoader.propTypes = {
  fullScreen: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.number
};

export default ActarusLoader;
