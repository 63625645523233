import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import arrayMove from 'array-move';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { formatBytes, isStringUrl } from '../../../../utils/functions/utils';
import { apiPostFile } from '../../../../utils/functions/api';
import {
  getMediaTypeMedias,
  resetMediaTypeMedias,
  setMediaType,
  setMediaTypeMedia,
  setMediaTypeProperty,
  unsetMediaTypeMedia,
  unsetMediaTypes
} from '../../../../utils/functions/redux';

import Alert from '../../../../components/alert/Alert';
import FileUploader from '../../files/FileUploader';
import MediasCarousel from '../../carousel/MediasCarousel';
import DownloadMediasButton from './DownloadMediasButton';
import MediaTypeForm from './MediaTypeForm';

import translation from '../../../../translation/translation';
import config from '../../../../config';

const useStyles = makeStyles(() => ({
  deleteButton: {
    color: config.error_color || '#F44336',
    '&:hover': {
      backgroundColor: fade(config.error_color || '#F44336', 0.15)
    }
  }
}));

function ConfirmButton(props) {
  const classes = useStyles();

  return (
    <Button {...props} className={classes.deleteButton}>
      {translation().actions.delete}
    </Button>
  );
}

function setReduxMediaType(mediaType) {
  if (!mediaType) return;

  /**
   * Create a media type bucket
   */
  const bucket = {
    id: mediaType.id,
    shortcode: mediaType.shortcode,
    quantity_min: mediaType.quantity_min,
    quantity_max: mediaType.quantity_max,
    mediasBucket: mediaType.medias,
    ready:
      mediaType.medias.length > mediaType.quantity_min &&
      mediaType.medias.length < mediaType.quantity_max
        ? true
        : false
  };

  /**
   * Set the media type bucket into redux store
   */
  setMediaType(bucket);
}

function doesFileMeetRestrictions(file, restrictions, nbFiles) {
  if (!file) return;

  let errorMessage = '';

  /**
   * Check the quantity of the file
   */
  if (nbFiles && restrictions.quantity_max && restrictions.quantity_max <= nbFiles.length) {
    errorMessage = translation().views.orders.media_groups.restrictions.overlimit;
    return errorMessage;
  }

  /**
   * Check the type of the file
   */
  if (restrictions.type && restrictions.type === 'iframe') {
    errorMessage = translation().views.orders.media_groups.restrictions.fill_url;
    return errorMessage;
  }

  /**
   * Check the extension of the file
   */
  if (
    restrictions.extensions &&
    restrictions.extensions.length &&
    restrictions.extensions.indexOf(file.extension) < 0
  ) {
    errorMessage = translation().views.orders.media_groups.restrictions.extension_refused;
    return errorMessage;
  }

  /**
   * Check the mime_type of the file
   */
  if (
    restrictions.mime_types &&
    restrictions.mime_types.length &&
    restrictions.mime_types.indexOf(file.mime_type) < 0
  ) {
    errorMessage = translation().views.orders.media_groups.restrictions.file_type_refused;
    return errorMessage;
  }

  /**
   * Check the size of the file
   */
  if (restrictions.size_min && file.size && restrictions.size_min > file.size) {
    errorMessage = translation().views.orders.media_groups.restrictions.weight_light;
    return errorMessage;
  }
  if (restrictions.size_max && file.size && restrictions.size_max < file.size) {
    errorMessage = translation().views.orders.media_groups.restrictions.weight_heavy;
    return errorMessage;
  }

  /**
   * Check the dimensions of the file
   */
  if (restrictions.width_min && file.width && restrictions.width_min > file.width) {
    errorMessage = translation().views.orders.media_groups.restrictions.width_small;
    return errorMessage;
  }
  if (restrictions.width_max && file.width && restrictions.width_max < file.width) {
    errorMessage = translation().views.orders.media_groups.restrictions.width_large;
    return errorMessage;
  }
  if (restrictions.height_min && file.height && restrictions.height_min > file.height) {
    errorMessage = translation().views.orders.media_groups.restrictions.height_small;
    return errorMessage;
  }
  if (restrictions.height_max && file.height && restrictions.height_max < file.height) {
    errorMessage = translation().views.orders.media_groups.restrictions.height_large;
    return errorMessage;
  }

  return true;
}

function IframeInput(props) {
  function handleChange(event) {
    const { data, onChange } = props;

    if (onChange && event) onChange(event, data);
  }

  return <TextField {...props} onChange={handleChange} />;
}

IframeInput.propTypes = {
  data: PropTypes.any,
  onChange: PropTypes.func
};

function MediaGroup(props) {
  const {
    downloadAction,
    errors,
    downloadOrderProductMedias,
    downloadSettings: { definition, watermark },
    mediaGroup,
    removeMediaTypeMedias,
    orderId,
    productId
  } = props;

  const [state, setState] = useState({
    acceptedFiles: '*',
    isDeleteOpen: false,
    isMediasSliderOpen: false,
    filesAcceptedLength: 0,
    filesArray: [],
    fileError: null,
    filesUploadedCount: {},
    uploadInProgressCount: 0,
    extensionsAccepted: [],
    fileLimitMaxSum: 0,
    fileLimitMinSum: 0,
    mediasSliderIndex: null,
    totalMaxSize: 50000000,
    totalMinSize: 0
  });

  function onExitSliderOnKeyPress(event) {
    if (event.defaultPrevented) return;

    switch (event.key) {
      case 'Enter':
        handleCloseMediasSlider();
        break;
      case 'Escape':
        handleCloseMediasSlider();
        break;
      default:
        return;
    }

    event.preventDefault();
  }

  function handleOpenMediasSlider(index) {
    document.addEventListener('keyup', onExitSliderOnKeyPress);
    document.body.style.overflow = 'hidden';

    setState((prevState) => ({
      ...prevState,
      isMediasSliderOpen: true,
      mediasSliderIndex: index
    }));
  }

  function handleCloseMediasSlider() {
    document.removeEventListener('keyup', onExitSliderOnKeyPress);
    document.body.style.overflow = 'auto';

    setState((prevState) => ({
      ...prevState,
      isMediasSliderOpen: false,
      mediasSliderIndex: null
    }));
  }

  /**
   * Get which mediagroup need an uploader
   */
  let isUploadAction = false;

  if (mediaGroup && mediaGroup.media_types) {
    for (let i = 0; i < mediaGroup.media_types.length; i++) {
      if (['image', 'file', 'data', 'video'].indexOf(mediaGroup.media_types[i].type) >= 0) {
        isUploadAction = true;
        break;
      }
    }
  }

  useEffect(() => {
    const currentMedias = [];
    let filesUploadedCount = {};
    let fileLimitMaxSum = null;
    let fileLimitMinSum = null;
    let totalMaxSize = null;
    let totalMinSize = null;
    let extensionsAccepted = [];

    if (mediaGroup.quantity_min) {
      fileLimitMinSum = mediaGroup.quantity_min;
    }

    if (mediaGroup.quantity_max) {
      fileLimitMaxSum = mediaGroup.quantity_max;
    }

    if (mediaGroup.size_max) {
      totalMaxSize = mediaGroup.size_max;
    }

    if (mediaGroup.size_min) {
      totalMinSize = mediaGroup.size_min;
    }

    if (mediaGroup.extensions && mediaGroup.extensions.length) {
      for (let i = 0; i < mediaGroup.extensions.length; i++) {
        extensionsAccepted.push(`.${mediaGroup.extensions[i]}`);
      }
    }

    if (mediaGroup.mime_types && mediaGroup.mime_types.length) {
      for (let i = 0; i < mediaGroup.mime_types.length; i++) {
        extensionsAccepted.push(mediaGroup.mime_types[i]);
      }
    }

    if (mediaGroup.media_types && mediaGroup.media_types.length) {
      for (let i = 0; i < mediaGroup.media_types.length; i++) {
        /**
         * Store all media type in redux
         */
        setReduxMediaType(mediaGroup.media_types[i]);

        if (mediaGroup.media_types[i].medias) {
          /**
           * Fill in redux all medias already uploaded in the api storage for his media type
           */
          filesUploadedCount[mediaGroup.media_types[i].shortcode] =
            mediaGroup.media_types[i].medias.length;

          for (let j = 0; j < mediaGroup.media_types[i].medias.length; j++) {
            let file = mediaGroup.media_types[i].medias[j];
            file.media_type = mediaGroup.media_types[i];

            delete file.medias;

            if (mediaGroup.media_types[i].type !== 'iframe') {
              currentMedias.push(file);
            }
          }
        }
      }
    }

    setState((prevState) => ({
      ...prevState,
      filesArray: currentMedias,
      filesAcceptedLength: currentMedias.length,
      fileLimitMaxSum,
      fileLimitMinSum,
      extensionsAccepted,
      totalMaxSize,
      totalMinSize,
      filesUploadedCount
    }));

    return () => {
      document.removeEventListener('keyup', onExitSliderOnKeyPress);
      unsetMediaTypes();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function hideFileErrorMessage() {
    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        fileError: null
      }));
    }, 6000);
  }

  function handleDrop(filesAccepted, filesRejected) {
    const filesArray = [...state.filesArray];

    /**
     * Post to api file one by one
     * Get the url of response data then add it to file url array
     */
    if (filesAccepted && filesAccepted.length > 0) {
      setState((prevState) => ({
        ...prevState,
        filesAcceptedLength: prevState.filesAcceptedLength + filesAccepted.length,
        uploadInProgressCount: prevState.uploadInProgressCount + filesAccepted.length
      }));

      for (let i = 0; i < filesAccepted.length; i++) {
        /**
         * PRE-UPLOAD CHECKS: check the quantity of files in dropper
         */
        if (state.filesAcceptedLength + filesAccepted.length <= state.fileLimitMaxSum) {
          if (filesAccepted.length + filesArray.length <= state.fileLimitMaxSum) {
            if (filesAccepted.length <= state.fileLimitMaxSum) {
              if (filesArray.length < state.fileLimitMaxSum) {
                apiPostFile(
                  filesAccepted[i],
                  (success) => {
                    const newFile = success && success.data ? success.data : '';

                    /**
                     * POST-UPLOAD CHECKS: extensions, sizes...
                     */
                    if (doesFileMeetRestrictions(newFile, mediaGroup) !== true) {
                      setState((prevState) => ({
                        ...prevState,
                        fileError: doesFileMeetRestrictions(newFile, mediaGroup),
                        filesAcceptedLength: prevState.filesAcceptedLength - 1,
                        uploadInProgressCount: prevState.uploadInProgressCount - 1
                      }));

                      hideFileErrorMessage();
                      return;
                    }

                    let mediaTypeRestrictionsCheckResult = false;
                    const errorMessages = [];

                    /**
                     * Check which media type has been uploaded
                     */
                    for (let j = 0; j < mediaGroup.media_types.length; j++) {
                      mediaTypeRestrictionsCheckResult = doesFileMeetRestrictions(
                        newFile,
                        mediaGroup.media_types[j],
                        getMediaTypeMedias(mediaGroup.media_types[j].shortcode)
                      );

                      if (mediaTypeRestrictionsCheckResult === true) {
                        newFile.media_type = mediaGroup.media_types[j];

                        const filesUploadedCount = state.filesUploadedCount;
                        filesUploadedCount[mediaGroup.media_types[j].shortcode] += 1;

                        /**
                         * Fill in redux bucket the new file uploaded in his mediatype
                         * Init position of medias
                         */
                        newFile.position = state.filesArray.length;

                        if (newFile.media_type.has_templates) {
                          newFile.media_template_id = 'null';
                        }

                        setMediaTypeMedia(newFile, mediaGroup.media_types[j].shortcode);

                        setState((prevState) => ({
                          ...prevState,
                          filesUploadedCount: filesUploadedCount,
                          filesArray: [...prevState.filesArray, newFile],
                          uploadInProgressCount: prevState.uploadInProgressCount - 1
                        }));

                        return true;
                      } else {
                        errorMessages.push(
                          `${mediaGroup.media_types[j].name}: ${mediaTypeRestrictionsCheckResult}`
                        );
                      }
                    }

                    /**
                     * Error: decrement the nb of file not uploaded
                     * Display local error message
                     */
                    setState((prevState) => ({
                      ...prevState,
                      fileError: errorMessages.join(', '),
                      filesAcceptedLength: prevState.filesAcceptedLength - 1,
                      uploadInProgressCount: prevState.uploadInProgressCount - 1
                    }));

                    hideFileErrorMessage();
                  },
                  (error) => {
                    setState((prevState) => ({
                      ...prevState,
                      fileError:
                        error && error.response && error.response.data && error.response.data.detail
                          ? error.response.data.detail
                          : 'Unknow error',
                      filesAcceptedLength: prevState.filesAcceptedLength - 1,
                      uploadInProgressCount: prevState.uploadInProgressCount - 1
                    }));

                    hideFileErrorMessage();
                  }
                );
              } else {
                setState((prevState) => ({
                  ...prevState,
                  fileError:
                    translation().views.orders.media_groups.file_limit + prevState.fileLimitMaxSum,
                  filesAcceptedLength: prevState.filesAcceptedLength - filesAccepted.length,
                  uploadInProgressCount: prevState.uploadInProgressCount - filesAccepted.length
                }));

                hideFileErrorMessage();
                return;
              }
            } else {
              setState((prevState) => ({
                ...prevState,
                fileError:
                  translation().views.orders.media_groups.file_limit + prevState.fileLimitMaxSum,
                filesAcceptedLength: prevState.filesAcceptedLength - filesAccepted.length,
                uploadInProgressCount: prevState.uploadInProgressCount - filesAccepted.length
              }));

              hideFileErrorMessage();
              return;
            }
          } else {
            setState((prevState) => ({
              ...prevState,
              fileError:
                translation().views.orders.media_groups.file_limit + prevState.fileLimitMaxSum,
              filesAcceptedLength: prevState.filesAcceptedLength - filesAccepted.length,
              uploadInProgressCount: prevState.uploadInProgressCount - filesAccepted.length
            }));

            hideFileErrorMessage();
            return;
          }
        } else {
          setState((prevState) => ({
            ...prevState,
            fileError:
              translation().views.orders.media_groups.file_limit + prevState.fileLimitMaxSum,
            filesAcceptedLength: prevState.filesAcceptedLength - filesAccepted.length,
            uploadInProgressCount: prevState.uploadInProgressCount - filesAccepted.length
          }));

          hideFileErrorMessage();
          return;
        }
      }
    }

    /**
     * Handling files rejected
     */
    if (filesRejected && filesRejected.length > 0) {
      let fileError = translation().views.orders.media_groups.files;

      for (let j = 0; j < filesRejected.length; j++) {
        if (filesRejected[j].name) fileError += filesRejected[j].name + ', ';
      }

      fileError += translation().views.orders.media_groups.refused;

      setState((prevState) => ({
        ...prevState,
        fileError
      }));

      hideFileErrorMessage();
    }
  }

  function handleSortEnd({ oldIndex, newIndex }) {
    if (oldIndex === newIndex) return;

    const filesArrayMoved = arrayMove(state.filesArray, oldIndex, newIndex);

    /**
     * Sort fileCards position
     */
    setState((prevState) => ({
      ...prevState,
      filesArray: arrayMove(prevState.filesArray, oldIndex, newIndex)
    }));

    /**
     * Reset the position of the file concerned in redux bucket
     */
    for (let i = 0; i < filesArrayMoved.length; i++) {
      setMediaTypeProperty(
        filesArrayMoved[i].url,
        filesArrayMoved[i].media_type.shortcode,
        'position',
        i + 1
      );
    }
  }

  function handleCheckIframeUrl(event, mediaType) {
    if (!mediaType.shortcode) {
      console.error('Error on iframe checking function: missing mediaType shortcode.');
    }

    const input = document.getElementById('media-input-wrapper-' + mediaType.shortcode);

    const successCallback = (event, url) => {
      try {
        const newFilesUploadedCount = state.filesUploadedCount;
        newFilesUploadedCount[mediaType.shortcode] = 1;

        if (input) {
          input.classList.remove('success');
          input.classList.remove('error');
          input.classList.add('success');
        }

        /**
         * The iframe is valid
         */
        setState((prevState) => ({
          ...prevState,
          filesUploadedCount: newFilesUploadedCount,
          isUrlRequestInProgress: false
        }));

        /**
         * Fill in redux the new iframe file in his mediatype
         */
        setMediaTypeMedia({ url: url, media_type: mediaType }, mediaType.shortcode);
      } catch (e) {
        console.error(e);
      }
    };

    const failCallback = () => {
      const newFilesUploadedCount = state.filesUploadedCount;
      newFilesUploadedCount[mediaType.shortcode] = 0;

      if (input) {
        input.classList.remove('success');
        input.classList.add('error');
      }

      setState((prevState) => ({
        ...prevState,
        filesUploadedCount: newFilesUploadedCount,
        isUrlRequestInProgress: false
      }));

      /**
       * Delete in redux the new iframe file in his mediatype
       */
      resetMediaTypeMedias(mediaType.shortcode);
    };

    /**
     * Delete in redux the iframe media if she already exists in api response
     */
    resetMediaTypeMedias(mediaType.shortcode);

    if (input) {
      input.classList.remove('success');
    }

    if (!mediaType) return;

    if (event && event.target && event.target.value) {
      event.persist();
      const url = event.target.value;

      setState((prevState) => ({
        ...prevState,
        isUrlRequestInProgress: true
      }));

      if (isStringUrl(url)) {
        /**
         * The url is valid
         */
        successCallback(event, url);
      } else {
        /**
         * The url is not valid
         */
        failCallback(event);
      }
    } else {
      /**
       * No value in input
       */
      failCallback(event);
    }
  }

  function handleDeleteFile(file) {
    if (!file.url) return;

    let shortcode = null;

    const newFilesArray = state.filesArray.filter((el) => {
      if (el.url === file.url) {
        shortcode = el.media_type.shortcode;
        return false;
      }
      return true;
    });

    let filesUploadedCountCopied = state.filesUploadedCount;
    filesUploadedCountCopied[shortcode] -= 1;

    setState((prevState) => ({
      ...prevState,
      filesArray: newFilesArray,
      filesUploadedCount: filesUploadedCountCopied,
      filesAcceptedLength: prevState.filesAcceptedLength - 1
    }));

    /**
     * Target and delete the file in redux bucket
     */
    unsetMediaTypeMedia(file.url, shortcode);
  }

  function handleDownloadProductMedias() {
    const downloadRequest = {
      media_group_id: mediaGroup.id,
      media_thumbnail_id: definition,
      watermark_position: watermark
    };

    downloadOrderProductMedias(orderId, productId, downloadRequest);
  }

  function handleCompleteForm(mediaTypeShortCode) {
    let filesUploadedCountCopied = state.filesUploadedCount;
    filesUploadedCountCopied[mediaTypeShortCode] += 1;

    setState((prevState) => ({
      ...prevState,
      filesUploadedCount: filesUploadedCountCopied
    }));
  }

  function handleOpenDeleteAllFiles() {
    setState((prevState) => ({
      ...prevState,
      isDeleteOpen: true
    }));
  }

  function handleCloseDeleteAllFiles() {
    setState((prevState) => ({
      ...prevState,
      isDeleteOpen: false
    }));
  }

  function checkIsDownloadable() {
    let isDownloadable = false;

    /**
     * Check of all media groups and medias types if the media groups need a downloader
     */
    if (mediaGroup.media_types && mediaGroup.media_types.length) {
      for (let j = 0; j < mediaGroup.media_types.length; j++) {
        if (mediaGroup.media_types[j].type && mediaGroup.media_types[j].type === 'iframe') {
          if (mediaGroup.media_types[j].medias && mediaGroup.media_types[j].medias.length) {
            for (let k = 0; k < mediaGroup.media_types[j].medias.length; k++) {
              if (mediaGroup.media_types[j].medias[k].thumbnail_url) {
                isDownloadable = true;
                break;
              } else if (mediaGroup.media_types[j].medias[k].extension) {
                isDownloadable = true;
                break;
              }
            }
          }
        } else if (mediaGroup.media_types[j].type && mediaGroup.media_types[j].type !== 'iframe') {
          if (mediaGroup.media_types[j].medias && mediaGroup.media_types[j].medias.length) {
            isDownloadable = true;
            break;
          }
        }
      }
    }

    return isDownloadable;
  }

  function handleRemoveAllFiles() {
    let toDeleteArray = [];

    if (state.filesArray && state.filesArray.length > 0) {
      for (let i = 0; i < state.filesArray.length; i++) {
        if (state.filesArray[i].media_type && state.filesArray[i].media_type.shortcode) {
          if (toDeleteArray.indexOf(state.filesArray[i].media_type.shortcode) <= -1) {
            toDeleteArray.push(state.filesArray[i].media_type.shortcode);
          }
        }
      }

      if (toDeleteArray.length > 0) {
        for (let j = 0; j < toDeleteArray.length; j++) {
          let filesUploadedCountCopied = state.filesUploadedCount;
          filesUploadedCountCopied[toDeleteArray[j]] = 0;

          setState((prevState) => ({
            ...prevState,
            filesUploadedCount: filesUploadedCountCopied
          }));

          removeMediaTypeMedias(toDeleteArray[j]);
        }

        setState((prevState) => ({
          ...prevState,
          filesArray: [],
          dialogDeleteAllOpen: false,
          isDeleteOpen: false,
          filesAcceptedLength: 0
        }));
      }
    }
  }

  function renderFilesRestrictions(mediaGroup) {
    if (!mediaGroup) return;

    let filesRestrictions = [];

    if (mediaGroup.media_types && mediaGroup.media_types.length > 0) {
      for (let i = 0; i < mediaGroup.media_types.length; i++) {
        let mediaType = mediaGroup.media_types[i];

        /**
         * Add class upload status to nb file counter
         */
        let filesUploadStatus = state.filesUploadedCount[mediaType.shortcode] > 0 ? 'pending' : '';

        if (state.filesUploadedCount[mediaType.shortcode] >= mediaType.quantity_min) {
          filesUploadStatus = 'done';
        }

        filesRestrictions.push(
          <div className="files-infos" key={i}>
            <div className="nb-files">
              <span className={classNames('counter', filesUploadStatus)}>
                {state.filesUploadedCount[mediaType.shortcode]}/
                {mediaType.quantity_min && mediaType.quantity_min}
              </span>
            </div>
            <div className="files-details">
              <span
                className={classNames(
                  'file-img',
                  { [`icon-file-type-${mediaType.type}`]: mediaType.type },
                  filesUploadStatus
                )}
              />
              <div className="restrictions">
                {mediaType.name && (
                  <Typography gutterBottom variant="subtitle2">
                    {mediaType.name}
                  </Typography>
                )}
                {mediaType.extensions &&
                mediaType.extensions.length &&
                mediaType.type !== 'form' ? (
                  <Typography variant="caption">
                    {translation().views.orders.media_groups.restrictions.type} [
                    {mediaType.extensions.join(', ')}]
                  </Typography>
                ) : (
                  false
                )}
                {(mediaType.size_min || mediaType.size_max) && mediaType.type !== 'form' ? (
                  <Typography variant="caption">
                    {translation().views.orders.media_groups.restrictions.weight} [
                    {mediaType.size_min
                      ? formatBytes(mediaType.size_min, 3)
                      : '0 ' + translation().views.orders.media_groups.restrictions.bytes}{' '}
                    - {mediaType.size_max ? formatBytes(mediaType.size_max, 0) : '?'}]
                  </Typography>
                ) : (
                  false
                )}
                {(mediaType.width_min || mediaType.width_max) && mediaType.type !== 'form' ? (
                  <Typography variant="caption">
                    {translation().views.orders.media_groups.restrictions.width} [
                    {mediaType.width_min ? mediaType.width_min : '0'}px -{' '}
                    {mediaType.width_max ? mediaType.width_max : '?'}px]
                  </Typography>
                ) : (
                  false
                )}
                {(mediaType.height_min || mediaType.height_max) && mediaType.type !== 'form' ? (
                  <Typography variant="caption">
                    {translation().views.orders.media_groups.restrictions.height} [
                    {mediaType.height_min ? mediaType.height_min : '0'}px -{' '}
                    {mediaType.height_max ? mediaType.height_max : '?'}px]
                  </Typography>
                ) : (
                  false
                )}
              </div>
            </div>
          </div>
        );
      }
    }

    return filesRestrictions;
  }

  function renderMediaGroupIframe(mediaGroup) {
    if (!mediaGroup) return;

    let iframes = [];

    if (mediaGroup.media_types && mediaGroup.media_types.length > 0) {
      for (let i = 0; i < mediaGroup.media_types.length; i++) {
        if (mediaGroup.media_types[i].type && mediaGroup.media_types[i].type === 'iframe') {
          iframes.push(
            <div
              key={i}
              className={classNames('media-input-wrapper', {
                success:
                  mediaGroup.media_types[i].medias &&
                  mediaGroup.media_types[i].medias.length &&
                  mediaGroup.media_types[i].medias[0].url
                    ? true
                    : false
              })}
              id={
                'media-input-wrapper-' +
                (mediaGroup.media_types[i].shortcode ? mediaGroup.media_types[i].shortcode : '')
              }>
              <IframeInput
                type="text"
                data={mediaGroup.media_types[i]}
                label={translation().views.orders.media_groups.media_link}
                name={
                  'input-iframe-' +
                  (mediaGroup.media_types[i].shortcode ? mediaGroup.media_types[i].shortcode : '')
                }
                defaultValue={
                  mediaGroup.media_types[i].medias &&
                  mediaGroup.media_types[i].medias[0] &&
                  mediaGroup.media_types[i].medias[0].url
                    ? mediaGroup.media_types[i].medias[0].url
                    : ''
                }
                autoComplete="nope"
                onChange={handleCheckIframeUrl}
                className="media-input"
                margin="normal"
                variant="outlined"
                fullWidth={true}
              />
            </div>
          );
        }
      }
    }

    return iframes;
  }

  function renderMediaGroupForm(mediaGroup) {
    if (!mediaGroup) return;

    let forms = [];

    if (mediaGroup.media_types && mediaGroup.media_types.length > 0) {
      for (let i = 0; i < mediaGroup.media_types.length; i++) {
        if (mediaGroup.media_types[i].type && mediaGroup.media_types[i].type === 'form') {
          forms.push(
            <MediaTypeForm
              key={i}
              mediaType={mediaGroup.media_types[i]}
              productId={productId}
              orderId={orderId}
              onComplete={handleCompleteForm}
            />
          );
        }
      }
    }

    return forms;
  }

  function renderMediasToCarousel() {
    const carouselArray = [];

    if (!state.filesArray || state.filesArray.length < 1) {
      return;
    }

    for (let i = 0; i < state.filesArray.length; i++) {
      if (
        state.filesArray[i].media_type &&
        state.filesArray[i].media_type &&
        (state.filesArray[i].media_type.shortcode === 'floorplan_image' ||
          state.filesArray[i].media_type.shortcode === 'picture_image')
      ) {
        carouselArray.push(
          <img
            key={i}
            src={`${state.filesArray[i].url}?client_id=${config.api_key}`}
            alt={state.filesArray[i].name}
            className="image-slider"
          />
        );
      } else {
        carouselArray.push(
          <iframe
            key={i}
            src={`${state.filesArray[i].url}?client_id=${config.api_key}`}
            title={state.filesArray[i].name}
            className="iframe-slider"
          />
        );
      }
    }

    return carouselArray;
  }

  return (
    <div className="media-group">
      <Grid container>
        <Grid item xs={12} sm={8}>
          {mediaGroup.name && (
            <Typography variant="h4" component="p" gutterBottom>
              {mediaGroup.name}
            </Typography>
          )}
          {renderFilesRestrictions(mediaGroup)}
          {state.fileError && (
            <Alert
              style={{ marginTop: 10 }}
              type="danger"
              status={translation().commons.alerts.error}
              text={state.fileError}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={4}>
          {checkIsDownloadable() && (
            <div className="download-wrapper">
              {downloadAction.isLoading && downloadAction.isLoading === mediaGroup.id ? (
                <div className="loader-wpr">
                  <CircularProgress size={25} style={{ marginRight: 8 }} />
                  <p>{translation().views.orders.media_groups.downloading}</p>
                </div>
              ) : (
                false
              )}
              <DownloadMediasButton
                disabled={
                  downloadAction.isLoading && downloadAction.isLoading === mediaGroup.id
                    ? true
                    : false
                }
                mediaGroupId={mediaGroup.id}
                onClick={handleDownloadProductMedias}
              />
            </div>
          )}
        </Grid>
        <Grid item xs={12}>
          {isUploadAction && (
            <FileUploader
              onDeleteFile={handleDeleteFile}
              extensionsAccepted={state.extensionsAccepted.join(',')}
              filesArray={state.filesArray}
              onDrop={handleDrop}
              onSortEnd={handleSortEnd}
              uploadInProgressCount={state.uploadInProgressCount}
              onClearDroppper={handleOpenDeleteAllFiles}
              totalMinSize={state.totalMinSize}
              totalMaxSize={state.totalMaxSize}
              name={mediaGroup.name ? mediaGroup.name + '_uploader' : 'media-group-uploader'}
              onOpenSlider={handleOpenMediasSlider}
              mediasErrors={errors}
            />
          )}
          {isUploadAction && (
            <Typography variant="caption" className="required-field-uploader">
              *{translation().commons.required_field}
            </Typography>
          )}
          {renderMediaGroupIframe(mediaGroup)}
          {renderMediaGroupForm(mediaGroup)}
        </Grid>
      </Grid>
      <Divider className="product-divider" />
      <Dialog
        open={state.isDeleteOpen}
        onClose={handleCloseDeleteAllFiles}
        aria-labelledby="delete-all-file-in-dropper"
        aria-describedby="delete-all-file-in-dropper-description">
        <DialogTitle id="delete-all-file-in-dropper">
          {translation().core.list.dialogs.delete.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-all-file-in-dropper-description">
            {translation().core.list.dialogs.delete.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteAllFiles} color="default">
            {translation().actions.cancel}
          </Button>
          <ConfirmButton onClick={handleRemoveAllFiles} />
        </DialogActions>
      </Dialog>
      {state.isMediasSliderOpen && state.filesArray && state.filesArray.length > 0 ? (
        <div className="medias-gallery-overview">
          <IconButton
            aria-label="Close slider"
            className="close-medias-gallery"
            onClick={handleCloseMediasSlider}
            tabIndex={0}>
            <CloseIcon />
          </IconButton>
          <MediasCarousel isCustomPaging initialSlide={state.mediasSliderIndex}>
            {renderMediasToCarousel()}
          </MediasCarousel>
        </div>
      ) : (
        false
      )}
    </div>
  );
}

MediaGroup.propTypes = {
  downloadOrderProductMedias: PropTypes.func.isRequired,
  downloadAction: PropTypes.shape({
    isLoading: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string])
  }).isRequired,
  downloadSettings: PropTypes.shape({
    definition: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    watermark: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }).isRequired,
  errors: PropTypes.shape(),
  mediaGroup: PropTypes.shape(),
  orderId: PropTypes.any.isRequired,
  productId: PropTypes.any.isRequired,
  removeMediaTypeMedias: PropTypes.func.isRequired
};

export default MediaGroup;
