export const clearOrderUpdateReducer = () => ({
  type: 'CLEAR_ORDER_UPDATE_REDUCER'
});

export const getOrder = () => ({
  type: 'GET_ORDER'
});

export const getOrderSuccess = (data) => ({
  type: 'GET_ORDER_SUCCESS',
  data
});

export const getOrderError = (error) => ({
  type: 'GET_ORDER_ERROR',
  error
});

export const getOrderDiscounts = () => ({
  type: 'GET_ORDER_DISCOUNTS'
});

export const getOrderDiscountsSuccess = (data) => ({
  type: 'GET_ORDER_DISCOUNTS_SUCCESS',
  data
});

export const getOrderDiscountsError = (error) => ({
  type: 'GET_ORDER_DISCOUNTS_ERROR',
  error
});

export const deleteOrderDiscount = () => ({
  type: 'DELETE_ORDER_DISCOUNT'
});

export const deleteOrderDiscountSuccess = (data) => ({
  type: 'DELETE_ORDER_DISCOUNT_SUCCESS',
  data
});

export const deleteOrderDiscountError = (error) => ({
  type: 'DELETE_ORDER_DISCOUNT_ERROR',
  error
});

export const addOrderDiscount = () => ({
  type: 'ADD_ORDER_DISCOUNT'
});

export const addOrderDiscountSuccess = (data) => ({
  type: 'ADD_ORDER_DISCOUNT_SUCCESS',
  data
});

export const addOrderDiscountError = (error) => ({
  type: 'ADD_ORDER_DISCOUNT_ERROR',
  error
});

export const getOrderClientAddresses = () => ({
  type: 'GET_ORDER_CLIENT_ADDRESSES'
});

export const getOrderClientAddressesSuccess = (data) => ({
  type: 'GET_ORDER_CLIENT_ADDRESSES_SUCCESS',
  data
});

export const getOrderClientAddressesError = (error) => ({
  type: 'GET_ORDER_CLIENT_ADDRESSES_ERROR',
  error
});

export const getOrderClientPaymentMethods = () => ({
  type: 'GET_ORDER_CLIENT_PAYMENT_METHODS'
});

export const getOrderClientPaymentMethodsSuccess = (data) => ({
  type: 'GET_ORDER_CLIENT_PAYMENT_METHODS_SUCCESS',
  data
});

export const getOrderClientPaymentMethodsError = (error) => ({
  type: 'GET_ORDER_CLIENT_PAYMENT_METHODS_ERROR',
  error
});

export const editOrderInvoiceAddress = () => ({
  type: 'EDIT_ORDER_INVOICE_ADDRESS'
});

export const editOrderInvoiceAddressSuccess = () => ({
  type: 'EDIT_ORDER_INVOICE_ADDRESS_SUCCESS'
});

export const editOrderInvoiceAddressError = (error) => ({
  type: 'EDIT_ORDER_INVOICE_ADDRESS_ERROR',
  error
});

export const editOrderPaymentMethod = () => ({
  type: 'EDIT_ORDER_PAYMENT_METHOD'
});

export const editOrderPaymentMethodSuccess = () => ({
  type: 'EDIT_ORDER_PAYMENT_METHOD_SUCCESS'
});

export const editOrderPaymentMethodError = (error) => ({
  type: 'EDIT_ORDER_PAYMENT_METHOD_ERROR',
  error
});

export const getOrderProductProviderInfos = () => ({
  type: 'GET_ORDER_PRODUCT_PROVIDERS_INFOS'
});

export const getOrderProductProviderInfosSuccess = (data) => ({
  type: 'GET_ORDER_PRODUCT_PROVIDERS_INFOS_SUCCESS',
  data
});

export const getOrderProductProviderInfosError = (error) => ({
  type: 'GET_ORDER_PRODUCT_PROVIDERS_INFOS_ERROR',
  error
});

export const getOrderProductHistory = () => ({
  type: 'GET_ORDER_PRODUCT_HISTORY'
});

export const getOrderProductHistorySuccess = (data) => ({
  type: 'GET_ORDER_PRODUCT_HISTORY_SUCCESS',
  data
});

export const getOrderProductHistoryError = (error) => ({
  type: 'GET_ORDER_PRODUCT_HISTORY_ERROR',
  error
});

export const cancelOrderProduct = () => ({
  type: 'CANCEL_ORDER_PRODUCT'
});

export const cancelOrderProductSuccess = () => ({
  type: 'CANCEL_ORDER_PRODUCT_SUCCESS'
});

export const cancelOrderProductError = (error) => ({
  type: 'CANCEL_ORDER_PRODUCT_ERROR',
  error
});

export const addOrderProductOption = () => ({
  type: 'ADD_ORDER_PRODUCT_OPTION'
});

export const addOrderProductOptionSuccess = () => ({
  type: 'ADD_ORDER_PRODUCT_OPTION_SUCCESS'
});

export const addOrderProductOptionError = (error) => ({
  type: 'ADD_ORDER_PRODUCT_OPTION_ERROR',
  error
});

export const removeOrderProductOption = () => ({
  type: 'REMOVE_ORDER_PRODUCT_OPTION'
});

export const removeOrderProductOptionSuccess = () => ({
  type: 'REMOVE_ORDER_PRODUCT_OPTION_SUCCESS'
});

export const removeOrderProductOptionError = (error) => ({
  type: 'REMOVE_ORDER_PRODUCT_OPTION_ERROR',
  error
});

export const getOrderProductDeliveryStatuses = () => ({
  type: 'GET_ORDER_PRODUCT_DELIVERY_STATUSES'
});

export const getOrderProductDeliveryStatusesSuccess = (data) => ({
  type: 'GET_ORDER_PRODUCT_DELIVERY_STATUSES_SUCCESS',
  data
});

export const getOrderProductDeliveryStatusesError = (error) => ({
  type: 'GET_ORDER_PRODUCT_DELIVERY_STATUSES_ERROR',
  error
});

export const updateOrderProductDeliveryStatus = () => ({
  type: 'UPDATE_ORDER_PRODUCT_DELIVERY_STATUS'
});

export const updateOrderProductDeliveryStatusSuccess = () => ({
  type: 'UPDATE_ORDER_PRODUCT_DELIVERY_STATUS_SUCCESS'
});

export const updateOrderProductDeliveryStatusError = (error) => ({
  type: 'UPDATE_ORDER_PRODUCT_DELIVERY_STATUS_ERROR',
  error
});

export const updateOrderProductProvider = () => ({
  type: 'UPDATE_ORDER_PRODUCT_PROVIDER'
});

export const updateOrderProductProviderSuccess = () => ({
  type: 'UPDATE_ORDER_PRODUCT_PROVIDER_SUCCESS'
});

export const updateOrderProductProviderError = (error) => ({
  type: 'UPDATE_ORDER_PRODUCT_PROVIDER_ERROR',
  error
});

export const getOrderProductSlots = () => ({
  type: 'GET_ORDER_PRODUCT_SLOTS'
});

export const getOrderProductSlotsSuccess = (slots) => ({
  type: 'GET_ORDER_PRODUCT_SLOTS_SUCCESS',
  slots
});

export const getOrderProductSlotsError = (error) => ({
  type: 'GET_ORDER_PRODUCT_SLOTS_ERROR',
  error
});

export const getPreviousOrderProductSlots = () => ({
  type: 'GET_PREVIOUS_ORDER_PRODUCT_SLOTS'
});

export const getPreviousOrderProductSlotsSuccess = (slots) => ({
  type: 'GET_PREVIOUS_ORDER_PRODUCT_SLOTS_SUCCESS',
  slots
});

export const getPreviousOrderProductSlotsError = (error) => ({
  type: 'GET_PREVIOUS_ORDER_PRODUCT_SLOTS_ERROR',
  error
});

export const getNextOrderProductSlots = () => ({
  type: 'GET_NEXT_ORDER_PRODUCT_SLOTS'
});

export const getNextOrderProductSlotsSuccess = (slots) => ({
  type: 'GET_NEXT_ORDER_PRODUCT_SLOTS_SUCCESS',
  slots
});

export const getNextOrderProductSlotsError = (error) => ({
  type: 'GET_NEXT_ORDER_PRODUCT_SLOTS_ERROR',
  error
});

export const createUpdateCalendar = (calendar, startDate, endDate) => ({
  type: 'CREATE_UPDATE_CALENDAR',
  payload: {
    calendar,
    startDate,
    endDate
  }
});

export const setUpdateNbTimeMax = (number) => ({
  type: 'SET_UPDATE_NB_TIME_MAX',
  number
});

export const clearOrderUpdatePlanning = () => ({
  type: 'CLEAR_ORDER_UPDATE_PLANNING'
});

export const updateOrderProductStartDate = () => ({
  type: 'UPDATE_ORDER_PRODUCT_START_DATE'
});

export const updateOrderProductStartDateSuccess = () => ({
  type: 'UPDATE_ORDER_PRODUCT_START_DATE_SUCCESS'
});

export const updateOrderProductStartDateError = (error) => ({
  type: 'UPDATE_ORDER_PRODUCT_START_DATE_ERROR',
  error
});

export const getOrderProductDispatchableProviders = () => ({
  type: 'GET_ORDER_PRODUCT_DISPATCHABLE_PROVIDERS'
});

export const getOrderProductDispatchableProvidersSuccess = () => ({
  type: 'GET_ORDER_PRODUCT_DISPATCHABLE_PROVIDERS_SUCCESS'
});

export const getOrderProductDispatchableProvidersError = (error) => ({
  type: 'GET_ORDER_PRODUCT_DISPATCHABLE_PROVIDERS_ERROR',
  error
});

export const postOrderProductDispatchableProviders = () => ({
  type: 'SEND_ORDER_PRODUCT_DISPATCHABLE_PROVIDERS'
});

export const postOrderProductDispatchableProvidersSuccess = () => ({
  type: 'SEND_ORDER_PRODUCT_DISPATCHABLE_PROVIDERS_SUCCESS'
});

export const postOrderProductDispatchableProvidersError = (error) => ({
  type: 'SEND_ORDER_PRODUCT_DISPATCHABLE_PROVIDERS_ERROR',
  error
});

export const updateOrderProductPropertyDetails = () => ({
  type: 'UPDATE_ORDER_PRODUCT_PROPERTY_DETAILS'
});

export const updateOrderProductPropertyDetailsSuccess = () => ({
  type: 'UPDATE_ORDER_PRODUCT_PROPERTY_DETAILS_SUCCESS'
});

export const updateOrderProductPropertyDetailsError = (error) => ({
  type: 'UPDATE_ORDER_PRODUCT_PROPERTY_DETAILS_ERROR',
  error
});

export const manageOrderProductFlag = () => ({
  type: 'MANAGE_ORDER_PRODUCT_FLAG'
});

export const manageOrderProductFlagSuccess = () => ({
  type: 'MANAGE_ORDER_PRODUCT_FLAG_SUCCESS'
});

export const manageOrderProductFlagError = (error) => ({
  type: 'MANAGE_ORDER_PRODUCT_FLAG_ERROR',
  error
});
