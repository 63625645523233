import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { makeStyles } from '@material-ui/core/styles';
import { orange } from '@material-ui/core/colors';

import { dateInTz } from '../../utils/functions/dates';
import { getTimezone } from '../../utils/functions/cookies';
import translation from '../../translation/translation';

const useStyles = makeStyles(() => ({
  root: {
    display: 'block',
    marginTop: 4,
    color: orange[600]
  },
  icon: {
    fontSize: '1rem',
    marginRight: 2,
    verticalAlign: 'text-bottom'
  }
}));

function LocalDateInfo(props) {
  const { className, date, format, timezone } = props;

  const classes = useStyles();

  if (timezone !== getTimezone()) {
    return (
      <span className={classNames(classes.root, className)}>
        <ErrorOutlineIcon className={classes.icon} />
        {translation().commons.hour_on_place} {dateInTz(date, format, timezone)} ({timezone})
      </span>
    );
  } else {
    return false;
  }
}

LocalDateInfo.defaultProps = {
  format: 'localized-datetime'
};

LocalDateInfo.propTypes = {
  className: PropTypes.string,
  date: PropTypes.string.isRequired,
  format: PropTypes.oneOf([
    'iso',
    'localized-date',
    'localized-long-date',
    'localized-long-datetime',
    'localized-datetime',
    'localized-long-datetime-with-seconds',
    'localized-datetime-with-seconds',
    PropTypes.string
  ]),
  timezone: PropTypes.string.isRequired
};

export default LocalDateInfo;
