import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select
} from '@material-ui/core';

import translation from '../../../../../../translation/translation';

function ViewSortForm(props) {
  const { fields, onChangeSort, onChangeSortOrder, values } = props;

  function handleChangeSort(event) {
    onChangeSort(event.target.value);
  }

  function handleChangeSortOrder(event) {
    onChangeSortOrder(event.target.value, values.sort);
  }

  return (
    <Box>
      <FormControl variant="outlined" fullWidth style={{ marginTop: 12 }}>
        <InputLabel id="select-sort">{translation().views.task_views.tasks.sort.field}</InputLabel>
        <Select
          labelId="select-sort"
          id="select-sort-id"
          label="Sort"
          value={values.sort || ''}
          onChange={handleChangeSort}>
          {fields.map(
            (item, index) =>
              item.type !== 'entity' && (
                <MenuItem key={index} value={item.name}>
                  {item.label}
                </MenuItem>
              )
          )}
        </Select>
      </FormControl>
      <FormControl variant="outlined" style={{ marginTop: 24 }}>
        <FormLabel id="sort_order_label">
          {translation().views.task_views.tasks.sort.order}
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby="sort_order_label"
          name="sort_order"
          onChange={handleChangeSortOrder}
          value={values.sort_order}>
          <FormControlLabel value="asc" control={<Radio />} label="asc" />
          <FormControlLabel value="desc" control={<Radio />} label="desc" />
        </RadioGroup>
      </FormControl>
    </Box>
  );
}

ViewSortForm.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  onChangeSort: PropTypes.func.isRequired,
  onChangeSortOrder: PropTypes.func.isRequired,
  values: PropTypes.shape({
    sort_order: PropTypes.string,
    sort: PropTypes.string
  })
};

export default ViewSortForm;
