import { combineReducers } from 'redux';

const initialState = {};

const orderProductMediasErrorsReducer = (errorsState = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_REDUCERS':
      return initialState;
    case 'REMOVE_MEDIA_TYPES':
      return initialState;
    case 'SET_MEDIAS_ERRORS':
      return action.errors;
    case 'CLEAR_MEDIAS_ERRORS':
      return initialState;
    default:
      return errorsState;
  }
};

const orderProductMediasReducer = (mediasState = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_REDUCERS':
      return initialState;
    case 'STORE_MEDIA_TYPE':
      return {
        ...mediasState,
        [action.mediaType.shortcode]: action.mediaType
      };
    case 'STORE_MEDIA_TYPE_MEDIA': {
      action.data.media_type_id = action.data.media_type.id;
      const bucket = mediasState[action.mediaTypeShortcode].mediasBucket;

      return {
        ...mediasState,
        [action.mediaTypeShortcode]: {
          ...mediasState[action.mediaTypeShortcode],
          mediasBucket: [...bucket, action.data]
        }
      };
    }
    case 'STORE_MEDIA_TYPE_MEDIA_PROPERTY': {
      const newMedias1 = mediasState[action.mediaTypeShortcode]['mediasBucket'].filter((el) => {
        if (el.url === action.mediaUrl) {
          el[action.mediaProperty] = action.mediaPropertyValue;
        }
        return true;
      });

      mediasState[action.mediaTypeShortcode]['mediasBucket'] = newMedias1;

      return {
        ...mediasState,
        [action.mediaTypeShortcode]: mediasState[action.mediaTypeShortcode]
      };
    }
    case 'REMOVE_MEDIA_TYPE_MEDIA': {
      const newMedias2 = mediasState[action.mediaTypeShortcode]['mediasBucket'].filter((el) => {
        return el.url !== action.mediaUrl;
      });

      mediasState[action.mediaTypeShortcode]['mediasBucket'] = newMedias2;

      return {
        ...mediasState,
        [action.mediaTypeShortcode]: mediasState[action.mediaTypeShortcode]
      };
    }
    case 'REMOVE_MEDIA_TYPE_MEDIAS': {
      mediasState[action.mediaTypeShortcode]['mediasBucket'] = [];

      return {
        ...mediasState,
        [action.mediaTypeShortcode]: mediasState[action.mediaTypeShortcode]
      };
    }
    case 'REMOVE_MEDIA_TYPES':
      return initialState;
    default:
      return mediasState;
  }
};

export default combineReducers({
  errors: orderProductMediasErrorsReducer,
  orderProductMedias: orderProductMediasReducer
});
