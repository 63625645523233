const clearFilters = () => ({
  type: 'CLEAR_FILTERS'
});

const setFilterValue = (name, value) => ({
  type: 'SET_FILTER_VALUE',
  name,
  value
});

const setFilterOperator = (name, operator) => ({
  type: 'SET_FILTER_OPERATOR',
  name,
  operator
});

const setFilterFullObject = (name, fullObj) => ({
  type: 'SET_FILTER_VALUE_FULL_OBJECT',
  name,
  fullObj
});

const setFiltersQueryString = (query) => ({
  type: 'SET_FILTERS_QUERY_STRING',
  query
});

const setFiltersValues = (values) => ({
  type: 'SET_FILTERS_VALUES',
  values
});

export {
  clearFilters,
  setFilterFullObject,
  setFilterOperator,
  setFiltersQueryString,
  setFiltersValues,
  setFilterValue
};
