import React from 'react';

function MediasQualityIcon(props) {
  return (
    <svg
      id="MediasQualityIcon"
      version="1.1"
      width="100"
      enableBackground="new 0 0 58 58"
      viewBox="0 0 58 58"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="m54.75 58h-51.5c-1.795 0-3.25-1.455-3.25-3.25v-43.75h58v43.75c0 1.795-1.455 3.25-3.25 3.25z"
        fill="#e7eced"
      />
      <path
        d="m58 12h-58v-8.75c0-1.795 1.455-3.25 3.25-3.25h51.5c1.795 0 3.25 1.455 3.25 3.25z"
        fill="#556080"
      />
      <path d="m4 4h4v4h-4z" fill="#dd352e" />
      <path d="m12 4h4v4h-4z" fill="#ebba16" />
      <path d="m20 4h4v4h-4z" fill="#23a24d" />
      <path d="m5 17h48v36h-48z" fill="#dd352e" />
      <g fill="#fff">
        <path d="m49 27c-.256 0-.512-.098-.707-.293l-5-5c-.391-.391-.391-1.023 0-1.414.39-.391 1.024-.391 1.414 0l5 5c.391.391.391 1.023 0 1.414-.195.195-.451.293-.707.293z" />
        <path d="m44 27c-.256 0-.512-.098-.707-.293-.391-.391-.391-1.023 0-1.414l5-5c.39-.391 1.024-.391 1.414 0 .391.391.391 1.023 0 1.414l-5 5c-.195.195-.451.293-.707.293z" />
        <circle cx="23" cy="29" r="3" />
        <path d="m30 45c-.256 0-.512-.098-.707-.293l-5.293-5.293-5.293 5.293c-.39.391-1.024.391-1.414 0-.391-.391-.391-1.023 0-1.414l6-6c.39-.391 1.024-.391 1.414 0l6 6c.391.391.391 1.023 0 1.414-.195.195-.451.293-.707.293z" />
        <path d="m27.574 42.17c-.238 0-.476-.084-.667-.255-.411-.369-.446-1.001-.077-1.412l6.426-7.17c.183-.205.443-.325.717-.333.269-.003.54.099.734.293l7 7c.391.391.391 1.023 0 1.414-.39.391-1.024.391-1.414 0l-6.253-6.253-5.721 6.383c-.197.221-.471.333-.745.333z" />
      </g>
    </svg>
  );
}

export default MediasQualityIcon;
