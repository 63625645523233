import React from 'react';
import PropTypes from 'prop-types';

import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

function FormTextField(props) {
  const {
    adornment,
    autoComplete,
    disabled,
    error,
    helperText,
    id,
    fullWidth,
    label,
    margin,
    name,
    required,
    onChange,
    write,
    value
  } = props;

  function handleChange(event) {
    if (event && onChange) {
      onChange(event.target.value);
    }
  }

  return (
    <FormControl
      required={required}
      disabled={!write || disabled}
      fullWidth={fullWidth}
      margin={margin}
      error={error ? true : false}>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <Input
        id={'SizeInput-' + id}
        value={value}
        name={name}
        autoComplete={autoComplete}
        onChange={handleChange}
        endAdornment={<InputAdornment position="end">{adornment}</InputAdornment>}
        aria-describedby={'SizeInput-' + id}
        type="number"
        inputProps={{
          'aria-label': label,
          type: 'number'
        }}
      />
      {(error || helperText) && (
        <FormHelperText id={'SizeInput-' + id}>{error || helperText}</FormHelperText>
      )}
    </FormControl>
  );
}

FormTextField.defaultProps = {
  fullWidth: true,
  margin: 'normal',
  write: true
};

FormTextField.propTypes = {
  autoComplete: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  adornment: PropTypes.any,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fullWidth: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  margin: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  write: PropTypes.bool,
  value: PropTypes.any
};

export default FormTextField;
