import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Alert from '../../../../../components/alert/Alert';
import { dateInTz } from '../../../../../utils/functions/dates';
import config from '../../../../../config';
import translation from '../../../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2)
  },
  tableContainer: {
    maxHeight: '640px!important'
  },
  loadingWrapper: {
    width: '100%',
    height: 50
  }
}));

function PlanRow(props) {
  const { plan, productId } = props;

  return (
    <TableRow>
      <TableCell>{plan.id}</TableCell>
      <TableCell>{plan.shortcode ? plan.shortcode : '-'}</TableCell>
      <TableCell>{plan.nb_months ? plan.nb_months : '-'}</TableCell>
      <TableCell>{plan.price_fixed ? plan.price_fixed : '-'}</TableCell>
      <TableCell>
        {plan.active ? (
          <DoneIcon style={{ color: config.success_color || '#4CAF50' }} fontSize="small" />
        ) : (
          <CloseIcon style={{ color: config.error_color || '#F44336' }} fontSize="small" />
        )}
      </TableCell>
      <TableCell>{plan.orderable ? plan.orderable : '-'}</TableCell>
      <TableCell>{plan.created ? dateInTz(plan.created, 'localized-datetime') : '-'}</TableCell>
      <TableCell>{plan.updated ? dateInTz(plan.updated, 'localized-datetime') : '-'}</TableCell>
      <TableCell align="right">
        <IconButton
          aria-label="Block payment method"
          href={`/products/${productId}/hosting-plans/${plan.id}/update`}
          target="_blank">
          <EditIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

PlanRow.propTypes = {
  plan: PropTypes.shape().isRequired,
  productId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

function HostingPlans(props) {
  const classes = useStyles();

  const columns = [
    'id',
    'shortcode',
    'nb_months',
    'price_fixed',
    'active',
    'orderable',
    'created',
    'updated'
  ];

  const { hostingPlans, cleanProductViewHostingPlansReducer, getProductHostingPlans, productId } =
    props;

  function loadHostingPlans(productId, next = null) {
    getProductHostingPlans(productId, next);
  }

  useEffect(() => {
    loadHostingPlans(productId);

    return () => {
      cleanProductViewHostingPlansReducer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleInfiniteScroll(event) {
    if (!event) console.error('No table scroll event found.');

    if (!event.currentTarget.scrollTop || event.currentTarget.scrollTop === 0) return;

    if (
      event.currentTarget.scrollHeight - event.currentTarget.clientHeight <=
      event.currentTarget.scrollTop
    ) {
      if (hostingPlans && hostingPlans.paging && hostingPlans.paging.next) {
        getProductHostingPlans(productId, hostingPlans.paging.next);
      }
    }
  }

  return (
    <div className={classNames('ProductHostingPlans', classes.root)}>
      <Paper className={classes.paper}>
        {!hostingPlans.isLoading &&
        hostingPlans.error &&
        (!hostingPlans.data || hostingPlans.data.length <= 0) ? (
          <div className="error-list">
            <Alert
              type="danger"
              style={{ marginTop: 10 }}
              status={translation().commons.alerts.error}
              text={translation().core.list.error_list}
            />
          </div>
        ) : (
          false
        )}
        {hostingPlans.data && hostingPlans.data.length > 0 ? (
          <TableContainer onScroll={handleInfiniteScroll} className={classes.tableContainer}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column}>{column}</TableCell>
                  ))}
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {hostingPlans.data.map((plan, index) => (
                  <PlanRow plan={plan} productId={productId} key={index} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : !hostingPlans.isLoading && !hostingPlans.error ? (
          <Alert
            type="info"
            style={{ marginTop: 10 }}
            status={translation().commons.alerts.info}
            text={translation().views.product_view.hosting_plans.empty}
          />
        ) : (
          false
        )}
        {hostingPlans.isLoading && !hostingPlans.paging && (
          <>
            <div className={classes.loadingWrapper} />
            <div className="loader-wpr">
              <CircularProgress color="primary" />
              <p>{translation().views.product_view.hosting_plans.loading_text}</p>
            </div>
          </>
        )}
        {hostingPlans.isLoading && hostingPlans.paging && hostingPlans.paging.next ? (
          <div style={{ height: 40 }}>
            <LinearProgress color="primary" />
            <div className="loader-wpr linear">
              <p>{translation().core.list.load_next}</p>
            </div>
          </div>
        ) : (
          <div className="end-of-list-wpr">
            {!hostingPlans.isLoading &&
              hostingPlans.paging &&
              !hostingPlans.paging.next &&
              hostingPlans.paging.prev && <p>{translation().core.list.load_next_done}</p>}
          </div>
        )}
      </Paper>
    </div>
  );
}

HostingPlans.propTypes = {
  cleanProductViewHostingPlansReducer: PropTypes.func.isRequired,
  getProductHostingPlans: PropTypes.func.isRequired,
  productId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  hostingPlans: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape()),
    error: PropTypes.string,
    isLoading: PropTypes.bool,
    paging: PropTypes.shape({
      next: PropTypes.string,
      prev: PropTypes.string
    })
  }).isRequired
};

export default HostingPlans;
