import React from 'react';

function PreviousDayIcon(props) {
  return (
    <svg
      version="1.1"
      id="PreviousDayIcon"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={100}
      viewBox="0 0 512 512"
      style={{ enableBackground: 'new 0 0 512 512' }}
      {...props}>
      <g>
        <polygon
          fill="#FF8597"
          points="451,29.5 451,120.5 393.1,150.5 16.4,150.5 -1,120.5 -1,29.5 	"
        />
        <polygon fill="#FF637B" points="451,29.5 451,120.5 393.1,150.5 225,150.5 225,29.5 	" />
        <rect x="59" y="-0.5" fill="#61729B" width="30" height="91" />
        <rect x="119" y="-0.5" fill="#61729B" width="30" height="91" />
        <rect x="180" y="-0.5" fill="#61729B" width="30" height="91" />
        <rect x="240" y="-0.5" fill="#47568C" width="30" height="91" />
        <rect x="301" y="-0.5" fill="#47568C" width="30" height="91" />
        <rect x="361" y="-0.5" fill="#47568C" width="30" height="91" />
        <path fill="#F2F2FC" d="M-1,120.5v331h328.4L451,327.9V120.5H-1z" />
        <polygon fill="#D8D8FC" points="451,120.5 451,327.9 327.4,451.5 225,451.5 225,120.5 	" />
        <path
          fill="#FF8597"
          d="M406,301.5c-57.9,0-105,47.1-105,105s47.1,105,105,105s105-47.1,105-105S463.9,301.5,406,301.5z"
        />
        <path
          fill="#FF637B"
          d="M511,406.5c0,57.9-47.1,105-105,105v-210C463.9,301.5,511,348.6,511,406.5z"
        />
        <path
          fill="#F2F2FC"
          d="M481,406.5c0,41.4-33.6,75-75,75s-75-33.6-75-75s33.6-75,75-75S481,365.1,481,406.5z"
        />
        <path
          fill="#D8D8FC"
          d="M481,406.5c0,41.4-33.6,75-75,75v-150C447.4,331.5,481,365.1,481,406.5z"
        />
        <polygon
          fill="#47568C"
          points="451,391.5 451,421.5 391,421.5 391,361.5 421,361.5 421,391.5 	"
        />
        <path fill="#D8D8FC" d="M149,271.5H59v-91h90V271.5z" />
        <path fill="#D8D8FC" d="M180,180.5v91h90v-91H180z" />
        <path fill="#D8D8FC" d="M180,301.5v90h90v-90H180z" />
        <path fill="#D8D8FC" d="M149,391.5H59v-90h90V391.5z" />
        <path fill="#A3A3D1" d="M391,271.5h-90v-91h90V271.5z" />
        <polygon
          fill="#2C3B73"
          points="451,391.5 451,421.5 406,421.5 406,361.5 421,361.5 421,391.5 	"
        />
        <rect x="225" y="180.5" fill="#A3A3D1" width="45" height="91" />
        <rect x="225" y="301.5" fill="#A3A3D1" width="45" height="90" />
      </g>
    </svg>
  );
}

export default PreviousDayIcon;
