import './aclCustomField.scss';

import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import BuildIcon from '@material-ui/icons/Build';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import SecurityIcon from '@material-ui/icons/Security';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import AclCustomCheckbox from './AclCustomCheckbox';
import { fetchApi } from '../../../../../utils/functions/api';
import { enqueueSnackbar } from '../../../../../redux/actions/appActions';
import translation from '../../../../../translation/translation';

const useStyles = makeStyles(() => ({
  appBar: {
    position: 'relative',
    paddingRight: '0!important'
  },
  buildIcon: {
    marginRight: 8
  },
  cellCheckbox: {
    minWidth: 600
  },
  closeBtn: {
    marginLeft: 'auto'
  },
  dialogRoot: {
    padding: 15
  },
  routeName: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  routeLabel: {
    display: 'flex',
    alignItems: 'center'
  },
  routeRoot: {
    marginTop: 5,
    marginBottom: 20
  },
  routePrivate: {
    marginLeft: 10,
    display: 'inline-flex'
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const mapDispatchToProps = (dispatch) => {
  return {
    _enqueueSnackbar(queue) {
      dispatch(enqueueSnackbar(queue));
    }
  };
};

function AclCustomField(props) {
  const {
    _enqueueSnackbar,
    autoComplete,
    defaultValue,
    disabled,
    error,
    helperText,
    id,
    fullWidth,
    label,
    margin,
    multiline,
    name,
    style,
    type,
    rows,
    rowsMax,
    required,
    onJsonChange,
    onTextChange,
    write
  } = props;

  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);
  const [listState, setListState] = useState({
    list: [],
    isLoading: true,
    isError: false
  });
  const [values, setValues] = useState({});
  const [textValue, setTextValue] = useState(
    defaultValue ? JSON.stringify(defaultValue, null, 4) : ''
  );

  function getList() {
    setListState({
      list: [],
      isLoading: true,
      isError: false
    });

    fetchApi(
      'get',
      'services/routes',
      null,
      null,
      null,
      (list) => {
        setListState({
          list,
          isLoading: false,
          isError: false
        });
      },
      (error) => {
        setListState({
          ...listState,
          isLoading: false,
          isError: true
        });

        if (error && error.response) {
          if (error.response.status && error.response.status === 403) {
            _enqueueSnackbar({
              message:
                error.response.data && error.response.data.detail
                  ? error.response.data.detail
                  : translation().core.list.denied,
              status: 403,
              options: {
                variant: 'error'
              }
            });
          } else {
            _enqueueSnackbar({
              message:
                error.response.data && error.response.data.detail
                  ? error.response.data.detail
                  : translation().core.list.error_list,
              options: {
                variant: 'error'
              }
            });
          }
        } else {
          _enqueueSnackbar({
            message: translation().core.list.error_list,
            options: {
              variant: 'error'
            }
          });
        }
      }
    );
  }

  function handleChange(event) {
    if (event && onTextChange) onTextChange(event.target.value);
  }

  function handleOpenDialog() {
    /**
     * Changes are done with the text value in field.
     * We parse text for get values, and stringify for post them.
     */

    setIsOpen(true);
    getList();

    const textArray = textValue ? JSON.parse(textValue) : [];
    const initialValues = {};

    if (textArray && textArray.routes && textArray.routes.length > 0) {
      for (let i = 0; i < textArray.routes.length; i++) {
        initialValues['acl_route_' + textArray.routes[i].split('.').join(':')] = true;
      }

      setValues(initialValues);
    }
  }

  function handleCloseDialog() {
    setIsOpen(false);
    setListState({
      list: [],
      isLoading: false,
      isError: true
    });

    const acl = { routes: [] };

    for (let key in values) {
      if (values[key] === true) acl.routes.push(key.replace('acl_route_', '').split(':').join('.'));
      else if (values[key] === false) {
        acl.routes = acl.routes.filter((r) => {
          return r !== key.replace('acl_route_', '').split(':').join('.');
        });
      }
    }

    setTextValue(JSON.stringify(acl, null, 4));

    if (onJsonChange) onJsonChange(JSON.stringify(acl, null, 4));
  }

  function handleChangeCheckbox(name, value) {
    setValues({
      ...values,
      [name]: value
    });
  }

  return (
    <div style={{ marginBottom: 15 }}>
      <TextField
        id={id}
        autoComplete={autoComplete}
        value={textValue}
        disabled={!write || disabled}
        error={error ? true : false}
        helperText={error || helperText}
        margin={margin}
        multiline={multiline}
        name={name}
        label={label}
        type={type}
        rows={rows}
        style={multiline ? { ...style, overflow: 'auto' } : style}
        rowsMax={rowsMax}
        required={required}
        onChange={handleChange}
        fullWidth={fullWidth}
      />
      <Button variant="contained" onClick={handleOpenDialog}>
        {translation().actions.manage}
        <SecurityIcon fontSize="small" style={{ marginLeft: 7 }} />
      </Button>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={handleCloseDialog}
        aria-labelledby="manage-acl-dialog"
        aria-describedby="manage-acl-description"
        TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" id="manage-acl-dialog">
              {translation().core.update.customs.acl.dialog_title}
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseDialog}
              className={classes.closeBtn}
              aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent className={classes.dialogRoot}>
          <DialogContentText id="manage-acl-description">
            {translation().core.update.customs.acl.dialog_desc}
          </DialogContentText>
          {listState.list && listState.list.length
            ? listState.list.map((service, i) => {
                return (
                  <div key={i}>
                    <Typography variant="h6" className={classes.routeName}>
                      <BuildIcon fontSize="small" className={classes.buildIcon} /> {service.name}
                    </Typography>
                    <div className={classes.routeRoot}>
                      {service.routes && service.routes.length <= 0 ? (
                        <Typography variant="body2">
                          {translation().core.update.customs.acl.no_route}
                        </Typography>
                      ) : (
                        <table>
                          <tbody>
                            {service.routes.map((arg, index) => {
                              const value = arg.route
                                ? 'acl_route_' + arg.route.split('.').join(':')
                                : undefined;
                              return (
                                <tr key={index}>
                                  <td className={classes.cellCheckbox}>
                                    <AclCustomCheckbox
                                      {...arg}
                                      value={value}
                                      defaultChecked={
                                        values[value] && values[value] === true ? true : false
                                      }
                                      handleChangeCheckbox={handleChangeCheckbox}
                                      className={`route-method-${arg.method.toLowerCase()}-name`}
                                    />
                                  </td>
                                  <td>
                                    {arg.method ? (
                                      <span
                                        className={`route-method-${arg.method.toLowerCase()}-label`}>
                                        {arg.method}
                                      </span>
                                    ) : (
                                      '-'
                                    )}
                                  </td>
                                  <td>
                                    {arg.privacy ? (
                                      <Chip
                                        className={classes.routePrivate}
                                        color={
                                          arg.privacy === 'private_trusted'
                                            ? 'secondary'
                                            : arg.privacy === 'private'
                                            ? 'secondary'
                                            : 'default'
                                        }
                                        variant={
                                          arg.privacy === 'private_trusted'
                                            ? 'default'
                                            : arg.privacy === 'public_trusted'
                                            ? 'default'
                                            : 'outlined'
                                        }
                                        size="small"
                                        label={arg.privacy}
                                      />
                                    ) : (
                                      ''
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                );
              })
            : false}
          {listState.isLoading && (
            <div className="loader-wpr">
              <CircularProgress color="primary" />
              {listState.isLoading && <p>{translation().core.list.load_list}</p>}
            </div>
          )}
          {!listState.isLoading &&
            !listState.isError &&
            (!listState.list || !listState.list.length) && (
              <div className="loader-wpr linear">
                <p>{translation().core.list.empty}</p>
              </div>
            )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="default">
            {translation().actions.confirm}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

AclCustomField.defaultProps = {
  fullWidth: true,
  margin: 'normal',
  multiline: true,
  style: {},
  type: 'text',
  rows: 4,
  rowsMax: 10,
  write: true
};

AclCustomField.propTypes = {
  _enqueueSnackbar: PropTypes.func.isRequired,
  autoComplete: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.shape()]),
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fullWidth: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  multiline: PropTypes.bool,
  margin: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  style: PropTypes.shape(),
  type: PropTypes.string,
  required: PropTypes.bool,
  rows: PropTypes.number,
  rowsMax: PropTypes.number,
  onTextChange: PropTypes.func.isRequired,
  onJsonChange: PropTypes.func.isRequired,
  write: PropTypes.bool
};

export default connect(null, mapDispatchToProps)(AclCustomField);
