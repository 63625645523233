import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchApi } from '../../../utils/functions/api';
import { enqueueSnackbar } from '../../../redux/actions/appActions';
import LinkUserToCompany from '../../../views/core/customListActions/LinkUserToCompany';
import translation from '../../../translation/translation';

const mapDispatchToProps = (dispatch) => {
  return {
    adminizeCompanyUser(companyId, userId, adminRequest = true, callbackSuccess, callbackError) {
      if (!companyId || !userId) return;

      fetchApi(
        'post',
        `companies/${companyId}/users/${userId}/${adminRequest ? 'adminize' : 'unadminize'}`,
        null,
        null,
        null,
        (success) => {
          dispatch(
            enqueueSnackbar({
              message:
                translation().core.list.dialogs.custom_list_actions.link_user_to_company.callbacks
                  .adminize.success,
              options: {
                variant: 'success'
              }
            })
          );

          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success);
          }
        },
        (error) => {
          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.dialogs.custom_list_actions.link_user_to_company
                        .callbacks.adminize.error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message:
                  translation().core.list.dialogs.custom_list_actions.link_user_to_company.callbacks
                    .adminize.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError();
          }
        }
      );
    },
    linkUserToCompany: (companyId, userId, callbackSuccess, callbackError) => {
      if (!companyId || !userId) return;

      fetchApi(
        'post',
        `companies/${companyId}/users/${userId}/link`,
        null,
        null,
        null,
        (success) => {
          dispatch(
            enqueueSnackbar({
              message:
                translation().core.list.dialogs.custom_list_actions.link_user_to_company.callbacks
                  .link.success,
              options: {
                variant: 'success'
              }
            })
          );

          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success);
          }
        },
        (error) => {
          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.dialogs.custom_list_actions.link_user_to_company
                        .callbacks.link.error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message:
                  translation().core.list.dialogs.custom_list_actions.link_user_to_company.callbacks
                    .link.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError();
          }
        }
      );
    },
    unlinkUserFromCompany: (companyId, userId, callbackSuccess, callbackError) => {
      if (!companyId || !userId) return;

      fetchApi(
        'post',
        `companies/${companyId}/users/${userId}/unlink`,
        null,
        null,
        null,
        (success) => {
          dispatch(
            enqueueSnackbar({
              message:
                translation().core.list.dialogs.custom_list_actions.link_user_to_company.callbacks
                  .unlink.success,
              options: {
                variant: 'success'
              }
            })
          );

          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success);
          }
        },
        (error) => {
          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.dialogs.custom_list_actions.link_user_to_company
                        .callbacks.unlink.error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message:
                  translation().core.list.dialogs.custom_list_actions.link_user_to_company.callbacks
                    .unlink.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError();
          }
        }
      );
    }
  };
};

export default withRouter(connect(null, mapDispatchToProps)(LinkUserToCompany));
