/**
 * TASKS ACTIONS
 */
const clearTaskView = () => ({
  type: 'CLEAR_TASKS_VIEW'
});

const clearTaskViews = () => ({
  type: 'CLEAR_TASKS_VIEWS'
});

const clearTasks = () => ({
  type: 'CLEAR_TASKS'
});

const refreshTaskViews = () => ({
  type: 'REFRESH_TASK_VIEWS'
});

const createTaskView = () => ({
  type: 'CREATE_TASK_VIEW'
});

const createTaskViewSuccess = (data) => ({
  type: 'CREATE_TASK_VIEW_SUCCESS',
  data
});

const createTaskViewError = (error) => ({
  type: 'CREATE_TASK_VIEW_ERROR',
  error
});

const refreshTaskView = () => ({
  type: 'REFRESH_TASK_VIEW'
});

const getTaskView = () => ({
  type: 'GET_TASK_VIEW'
});

const getTaskViewSuccess = (data) => ({
  type: 'GET_TASK_VIEW_SUCCESS',
  data
});

const getTaskViewError = (error) => ({
  type: 'GET_TASK_VIEW_ERROR',
  error
});

const getTaskViews = () => ({
  type: 'GET_TASK_VIEWS'
});

const getTaskViewsSuccess = (data, paging) => ({
  type: 'GET_TASK_VIEWS_SUCCESS',
  data,
  paging
});

const getTaskViewsError = () => ({
  type: 'GET_TASK_VIEWS_ERROR'
});

const refreshTasks = () => ({
  type: 'REFRESH_TASKS'
});

const getTaskMetadataSuccess = (metadata) => ({
  type: 'GET_TASK_METADATA_SUCCESS',
  metadata
});

const getTasks = (next, search) => ({
  type: 'GET_TASKS',
  next,
  search
});

const getTasksSuccess = (data, paging) => ({
  type: 'GET_TASKS_SUCCESS',
  data,
  paging
});

const getTasksError = (error) => ({
  type: 'GET_TASKS_ERROR',
  error
});

const updateTaskView = () => ({
  type: 'UPDATE_TASK_VIEW'
});

const updateTaskViewSuccess = (data) => ({
  type: 'UPDATE_TASK_VIEW_SUCCESS',
  data
});

const updateTaskViewError = (error) => ({
  type: 'UPDATE_TASK_VIEW_ERROR',
  error
});

const updateTaskViewProps = () => ({
  type: 'UPDATE_TASK_VIEW_PROPS'
});

const updateTaskViewPropsSuccess = () => ({
  type: 'UPDATE_TASK_VIEW_PROPS_SUCCESS'
});

const updateTaskViewPropsError = () => ({
  type: 'UPDATE_TASK_VIEW_PROPS_ERROR'
});

const deleteTaskView = () => ({
  type: 'DELETE_TASK_VIEW'
});

const deleteTaskViewSuccess = (id) => ({
  type: 'DELETE_TASK_VIEW_SUCCESS',
  id
});

const deleteTaskViewError = (error) => ({
  type: 'DELETE_TASK_VIEW_ERROR',
  error
});

const setTaskViewProp = (prop, value) => ({
  type: 'SET_TASK_VIEW_PROP',
  prop,
  value
});

const createTask = () => ({
  type: 'CREATE_TASK'
});

const createTaskSuccess = () => ({
  type: 'CREATE_TASK_SUCCESS'
});

const createTaskError = (error) => ({
  type: 'CREATE_TASK_ERROR',
  error
});

const getCompletionStatuses = () => ({
  type: 'GET_COMPLETION_STATUSES'
});

const getCompletionStatusesSuccess = (data) => ({
  type: 'GET_COMPLETION_STATUSES_SUCCESS',
  data
});

const getCompletionStatusesError = (error) => ({
  type: 'GET_COMPLETION_STATUSES_ERROR',
  error
});

const getTasksViewTeams = () => ({
  type: 'GET_TASKS_VIEW_TEAMS'
});

const getTasksViewTeamsSuccess = (data) => ({
  type: 'GET_TASKS_VIEW_TEAMS_SUCCESS',
  data
});

const getTasksViewTeamsError = (error) => ({
  type: 'GET_TASKS_VIEW_TEAMS_ERROR',
  error
});

export {
  clearTaskView,
  clearTaskViews,
  clearTasks,
  createTask,
  createTaskSuccess,
  createTaskError,
  createTaskView,
  createTaskViewError,
  createTaskViewSuccess,
  deleteTaskView,
  deleteTaskViewError,
  deleteTaskViewSuccess,
  getCompletionStatuses,
  getCompletionStatusesSuccess,
  getCompletionStatusesError,
  getTaskMetadataSuccess,
  getTasksViewTeams,
  getTasksViewTeamsSuccess,
  getTasksViewTeamsError,
  getTasks,
  getTasksError,
  getTasksSuccess,
  getTaskViews,
  getTaskViewsError,
  getTaskViewsSuccess,
  getTaskView,
  getTaskViewSuccess,
  getTaskViewError,
  refreshTasks,
  refreshTaskView,
  refreshTaskViews,
  setTaskViewProp,
  updateTaskView,
  updateTaskViewError,
  updateTaskViewSuccess,
  updateTaskViewProps,
  updateTaskViewPropsSuccess,
  updateTaskViewPropsError
};
