import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchApi } from '../../../utils/functions/api';

import { enqueueSnackbar } from '../../../redux/actions/appActions';

import BlockPaymentMethod from '../../../views/core/customListActions/BlockPaymentMethod';

import translation from '../../../translation/translation';

const mapDispatchToProps = (dispatch) => {
  return {
    manageBlockPaymentMethod: (
      resourceUri,
      resourceId,
      paymentMethodId,
      willBeBlocked = true,
      callbackSuccess,
      callbackError
    ) => {
      if (!resourceUri || !resourceId || !paymentMethodId) {
        return;
      }

      fetchApi(
        'post',
        `${resourceUri}/${resourceId}/payment-methods/${paymentMethodId}/${
          willBeBlocked ? 'block' : 'unblock'
        }`,
        null,
        null,
        null,
        (success) => {
          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success);
          }

          dispatch(
            enqueueSnackbar({
              message: willBeBlocked
                ? translation().core.list.dialogs.custom_list_actions.block_payment_method.callbacks
                    .success_block
                : translation().core.list.dialogs.custom_list_actions.block_payment_method.callbacks
                    .success_unblock,
              options: {
                variant: 'success'
              }
            })
          );
        },
        (error) => {
          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.dialogs.custom_list_actions.block_payment_method
                        .callbacks.error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message:
                  translation().core.list.dialogs.custom_list_actions.block_payment_method.callbacks
                    .error,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError();
          }
        }
      );
    }
  };
};

export default withRouter(connect(null, mapDispatchToProps)(BlockPaymentMethod));
