import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField
} from '@material-ui/core';
import { CloudDownload, Link as LinkIcon } from '@material-ui/icons';

import { FormFileField } from '../../../../../../components/forms/fields';
import { isObjEmpty, objectMapToArray } from '../../../../../../utils/functions/utils';
import translation from '../../../../../../translation/translation';
import config from '../../../../../../config';

function Attributes(props) {
  const {
    attributes,
    onClose,
    open,
    orderId,
    productId,
    reloadOrder,
    updateOrderProductAttributes
  } = props;

  const [values, setValues] = useState({});
  const [isLoading, setLoading] = useState(false);

  function handleSubmit() {
    setLoading(true);

    updateOrderProductAttributes(
      orderId,
      productId,
      values,
      () => {
        setLoading(false);
        onClose();
        reloadOrder();
      },
      () => {
        setLoading(false);
      }
    );
  }

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleChangeFile = (name) => (value) => {
    setValues({ ...values, [name]: value });
  };

  // eslint-disable-next-line no-unused-vars
  const handleDownloadMedia = (url) => (event) => {
    if (!url) return;

    const downloadIframe = document.getElementById('frame-download');
    const iframesContainer = document.getElementById('iframes-container');

    /**
     * If iframe already exists we only change url, if not we create it
     */
    if (downloadIframe) {
      downloadIframe.setAttribute('src', `${url}/download?client_id=${config.api_key}`);
    } else {
      const downloadIframe = `<iframe id="frame-download" title="iframe-download" width="0" height="0" style="display: none" src="${url}/download?client_id=${config.api_key}"/>`;
      iframesContainer.innerHTML = downloadIframe;
    }
  };

  function renderField(name, attr) {
    switch (attr.type) {
      case 'file':
        return (
          <FormFileField
            label={attr.label}
            name={name}
            type="file"
            onChange={handleChangeFile(name)}
          />
        );
      case 'image':
        return (
          <FormFileField
            label={attr.label}
            name={name}
            type="image"
            onChange={handleChangeFile(name)}
          />
        );
      default:
        return (
          <TextField
            fullWidth
            onChange={handleChange(name)}
            label={attr.label}
            name={name}
            type={attr.type}
          />
        );
    }
  }

  function renderValue(attr) {
    switch (attr.type) {
      case 'file':
        return (
          <>
            <ListItemText primary={attr.label} secondary={attr.value} />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => window.open(`${attr.value}?client_id=${config.api_key}`)}>
                <LinkIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </>
        );
      case 'image':
        return (
          <>
            <ListItemText
              primary={attr.label}
              secondary={
                <>
                  {attr.value}{' '}
                  <img
                    src={`${attr.value}?client_id=${config.api_key}`}
                    alt={attr.label}
                    style={{
                      marginTop: 8,
                      maxHeight: 200
                    }}
                  />
                </>
              }
            />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="delete" onClick={handleDownloadMedia(attr.value)}>
                <CloudDownload />
              </IconButton>
            </ListItemSecondaryAction>
          </>
        );
      default:
        return <ListItemText primary={attr.label} secondary={attr.value} />;
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      aria-labelledby="order-product-attr"
      aria-describedby="order-product-attr-description"
      disableBackdropClick={isLoading}>
      <DialogTitle id="order-product-attr">
        {translation().views.orders.update.products.product_attributes.dialog_title}
      </DialogTitle>
      <DialogContent>
        <List dense disablePadding component="div">
          {objectMapToArray(attributes, (prop, attr, index) => (
            <ListItem key={index} disableGutters component="div">
              {!attr.value ? renderField(prop, attr) : renderValue(attr)}
            </ListItem>
          ))}
        </List>
        {isLoading && (
          <div className="loader-wpr">
            <CircularProgress size={30} color="secondary" />
            <p>{translation().views.orders.update.products.product_attributes.loading_text}</p>
          </div>
        )}
        <div className="sm-space" />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="default">
          {translation().actions.close}
        </Button>
        <Button
          color="primary"
          onClick={handleSubmit}
          variant="contained"
          disabled={isLoading || isObjEmpty(values)}>
          {translation().actions.save}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

Attributes.propTypes = {
  attributes: PropTypes.shape().isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  orderId: PropTypes.number.isRequired,
  productId: PropTypes.number.isRequired,
  reloadOrder: PropTypes.func.isRequired,
  updateOrderProductAttributes: PropTypes.func.isRequired
};

export default Attributes;
