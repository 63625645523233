export const getMetadata = () => ({
  type: 'GET_METADATA'
});

export const getMetadataSuccess = (data) => ({
  type: 'GET_METADATA_SUCCESS',
  data
});

export const getMetadataError = (isError) => ({
  type: 'GET_METADATA_ERROR',
  isError
});

export const cancelGetMetadata = () => ({
  type: 'CANCEL_GET_METADATA'
});
