import './filters.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { isObjEmpty } from '../../utils/functions/utils';
import FilterField from './FilterField';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1
  },
  submit: {
    height: 0,
    opacity: 0,
    display: 'none'
  }
}));

function Filters(props) {
  const {
    metadata,
    setFilterFullObject,
    setFilterOperator,
    setFilterValue,
    filters: { values },
    onSubmit
  } = props;

  const classes = useStyles();

  function getDefaultOperator(field) {
    let defaultOperator = null;

    if (!values || isObjEmpty(values)) {
      return null;
    }

    if (
      field.reference_key &&
      values[field.reference_key] &&
      values[field.reference_key].operator
    ) {
      defaultOperator = values[field.reference_key].operator;
    } else if (field.name && values[field.name] && values[field.name].operator) {
      defaultOperator = values[field.name].operator;
    }

    return defaultOperator;
  }

  function getDefaultValue(field) {
    let defaultValue = null;

    if (!values || isObjEmpty(values)) {
      return null;
    }

    if (field.reference_key && values[field.reference_key]) {
      defaultValue = values[field.reference_key].fullObject
        ? values[field.reference_key].fullObject
        : field.target_key
        ? { [field.target_key]: values[field.reference_key].value }
        : { id: values[field.reference_key].value };
    } else if (
      field.name &&
      values[field.name] &&
      ((values[`${field.name}.start`] && values[`${field.name}.start`].value) ||
        (values[`${field.name}.end`] && values[`${field.name}.end`].value))
    ) {
      /**
       * Hande range operator values
       */
      defaultValue = {};

      if (values[`${field.name}.start`] && values[`${field.name}.start`].value) {
        defaultValue.start = values[`${field.name}.start`].value;
      }
      if (values[`${field.name}.end`] && values[`${field.name}.end`].value) {
        defaultValue.end = values[`${field.name}.end`].value;
      }
      if (values[field.name].value) {
        defaultValue.initial = values[field.name].value;
      }
    } else if (field.name && values[field.name] && values[field.name].value) {
      defaultValue = values[field.name].value;
    }

    return defaultValue;
  }

  function handleSubmit(event) {
    event.preventDefault();
    if (onSubmit) {
      onSubmit(values);
    }
  }

  return (
    <form className="Filters" noValidate onSubmit={handleSubmit}>
      <Grid
        className={classes.root}
        spacing={4}
        container
        direction="row"
        justify="center"
        alignItems="flex-end">
        {metadata &&
        metadata.data &&
        metadata.data &&
        metadata.data.fields &&
        metadata.data.fields.length > 0
          ? metadata.data.fields.map((field, i) => {
              if (
                field.name === 'password' ||
                field.type === 'collection' ||
                field.type === 'object' ||
                field.type === 'array'
              ) {
                return false;
              } else {
                return (
                  <Grid key={i} item xs={12} sm={6}>
                    <FilterField
                      {...field}
                      setFilterFullObject={setFilterFullObject}
                      setFilterOperator={setFilterOperator}
                      setFilterValue={setFilterValue}
                      defaultValue={getDefaultValue(field)}
                      defaultOperator={getDefaultOperator(field)}
                      fullWidth
                      write
                      required={false}
                    />
                  </Grid>
                );
              }
            })
          : false}
      </Grid>
      <input type="submit" className={classes.submit} />
    </form>
  );
}

Filters.propTypes = {
  metadata: PropTypes.shape().isRequired,
  filters: PropTypes.shape().isRequired,
  setFilterFullObject: PropTypes.func.isRequired,
  setFilterOperator: PropTypes.func.isRequired,
  setFilterValue: PropTypes.func.isRequired,
  onSubmit: PropTypes.func
};

export default Filters;
