import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import FormApiAutocompleteField from '../../../components/forms/fields/FormApiAutocompleteField';
import translation from '../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 450,
    [theme.breakpoints.down('sm')]: {
      minWidth: 200
    }
  },
  alignRight: {
    textAlign: 'right'
  },
  unMarge: {
    marginLeft: -16
  }
}));

function LinkCompanyToCompany(props) {
  const {
    closeDialogFunction,
    linkCompanyToCompany,
    unlinkCompanyFromCompany,
    reloadListFunction,
    resource
  } = props;
  const classes = useStyles();
  const [isLinkLoading, setLinkLoading] = useState(false);
  const [companyValue, setCompanyValue] = useState(false);

  function handleChangeCompany(value) {
    setCompanyValue(value);
  }

  function handleLinkCompany() {
    if (!resource || !companyValue || !resource.id) return;

    if (resource.parent && resource.parent.id) {
      setLinkLoading(true);

      unlinkCompanyFromCompany(resource.parent.id, resource.id, () => {
        linkCompanyToCompany(
          companyValue,
          resource.id,
          () => {
            setLinkLoading(false);
            closeDialogFunction();
            reloadListFunction();
          },
          () => {
            setLinkLoading(false);
          }
        );
      });
    } else {
      setLinkLoading(true);

      linkCompanyToCompany(
        companyValue,
        resource.id,
        () => {
          setLinkLoading(false);
          closeDialogFunction();
          reloadListFunction();
        },
        () => {
          setLinkLoading(false);
        }
      );
    }
  }

  function handleUnlinkCompany() {
    if (!resource || !resource.parent || !resource.parent.id || !resource.id) return;

    setLinkLoading(true);

    unlinkCompanyFromCompany(
      resource.parent.id,
      resource.id,
      () => {
        setLinkLoading(false);
        closeDialogFunction();
        reloadListFunction();
      },
      () => {
        setLinkLoading(false);
      }
    );
  }

  return (
    <div className="LinkCompanyToCompany">
      <div className={classes.root}>
        {resource && resource.parent && (
          <div>
            {resource.parent.commercial_name && (
              <Typography>
                {
                  translation().core.list.dialogs.custom_list_actions.link_company_to_company
                    .current_company
                }{' '}
                {resource.parent.commercial_name +
                  (resource.parent.legal_name
                    ? ` (${
                        translation().core.list.dialogs.custom_list_actions.link_company_to_company
                          .legal_name
                      } ${resource.parent.legal_name})`
                    : '')}
              </Typography>
            )}
            <div className="xs-space" />
            <div className={classes.alignRight}>
              <Button
                disabled={isLinkLoading}
                variant="contained"
                onClick={handleUnlinkCompany}
                color="primary">
                {translation().core.list.dialogs.custom_list_actions.link_company_to_company.unlink}
              </Button>
            </div>
            <div className="sm-space" />
          </div>
        )}
        <div className="sm-space" />
        <FormApiAutocompleteField
          name="link_c_to_c_field"
          label={
            translation().core.list.dialogs.custom_list_actions.link_company_to_company.input_label
          }
          placeholder={
            translation().core.list.dialogs.custom_list_actions.link_company_to_company
              .input_placeholder
          }
          links={[{ rel: 'list', href: 'companies' }]}
          onSelectValue={handleChangeCompany}
          targetKey="id"
          disabled={isLinkLoading}
        />
        <div className={classes.alignRight}>
          <Button
            disabled={!companyValue || isLinkLoading}
            variant="contained"
            onClick={handleLinkCompany}
            color="primary">
            {translation().core.list.dialogs.custom_list_actions.link_company_to_company.link}
          </Button>
        </div>
        <div className="sm-space" />
        {isLinkLoading && (
          <div className="loader-wpr">
            <CircularProgress color="primary" size={25} />
            <p>
              {
                translation().core.list.dialogs.custom_list_actions.link_company_to_company
                  .loading_text
              }
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

LinkCompanyToCompany.propTypes = {
  closeDialogFunction: PropTypes.func.isRequired,
  linkCompanyToCompany: PropTypes.func.isRequired,
  unlinkCompanyFromCompany: PropTypes.func.isRequired,
  reloadListFunction: PropTypes.func.isRequired,
  resource: PropTypes.shape()
};

export default LinkCompanyToCompany;
