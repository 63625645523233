export const getOrdersMetadata = () => ({
  type: 'GET_ORDERS_METADATA'
});

export const getOrdersMetadataSuccess = (data) => ({
  type: 'GET_ORDERS_METADATA_SUCCESS',
  data
});

export const getOrdersMetadataError = (isError) => ({
  type: 'GET_ORDERS_METADATA_ERROR',
  isError
});
