import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Fab, Typography } from '@material-ui/core';
import { AlarmOff, ErrorSharp } from '@material-ui/icons';

import { pad } from '../../../../../../utils/functions/utils';
import { getTimezone } from '../../../../../../utils/functions/cookies';
import translation from '../../../../../../translation/translation';
import MouseClickRight from '../../../../../../components/icons/MouseClickRight';

class ScheduleEventWeek extends PureComponent {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleClickMobile = this.handleClickMobile.bind(this);
  }

  handleClick(e) {
    const { event } = this.props;

    if (e.type === 'contextmenu') {
      e.preventDefault();
      if (event && event.type === 'availability' && event.onOpenCreateUnavailability) {
        event.onOpenCreateUnavailability(event);
      }
    }
  }

  handleClickMobile(e) {
    e.stopPropagation();

    const { event } = this.props;

    if (event && event.type === 'availability' && event.onOpenCreateUnavailability) {
      event.onOpenCreateUnavailability(event);
    }
  }

  render() {
    const { event } = this.props;

    const startHour = pad(event.start.getHours().toString());
    const startMinutes = pad(event.start.getMinutes().toString());
    const endHour = pad(event.end.getHours().toString());
    const endMinutes = pad(event.end.getMinutes().toString());

    return (
      <div className={event.className} onContextMenu={this.handleClick}>
        {event.product && event.product.status && event.product.status.icon_url_dark && (
          <img
            src={event.product.status.icon_url_dark}
            alt={event.product.status.name}
            className="status-img"
          />
        )}
        {event.type === 'orderProduct' &&
          event.timezone_code &&
          getTimezone() !== event.timezone_code && <ErrorSharp className="timezone-warning" />}
        <div className="event-content">
          {event.type === 'unavailability' ? (
            <div className="event-title">
              <Typography variant="overline">
                {event.title
                  ? event.title
                  : translation().views.provider_schedule.unavailability.unavailability}
              </Typography>
            </div>
          ) : (
            <div className="event-title">
              <Typography variant="overline">
                {event.product && event.product.name
                  ? event.product.name
                  : event.title
                  ? event.title
                  : ''}
              </Typography>
            </div>
          )}
          {event.type === 'availability' && (
            <div className="touch-helper">
              <Fab
                size="small"
                color="secondary"
                aria-label={translation().views.provider_schedule.unavailability.add}
                onClick={this.handleClickMobile}
                className="add-unavailability-fab">
                <AlarmOff />
              </Fab>
            </div>
          )}
          {event.type === 'availability' && (
            <div className="right-click-helper">
              <MouseClickRight fill="#87999a" />
              <Typography>
                {translation().views.provider_schedule.unavailability.create.title}
              </Typography>
            </div>
          )}
          <div className="event-times">
            <Typography variant="caption" component="span">
              {startHour}:{startMinutes} - {endHour}:{endMinutes}
            </Typography>
          </div>
          {event.product &&
            event.product.order &&
            event.product.order.property &&
            event.product.order.property.address && (
              <p className="event-address">{event.product.order.property.address}</p>
            )}
        </div>
      </div>
    );
  }
}

ScheduleEventWeek.propTypes = {
  event: PropTypes.shape({
    className: PropTypes.string,
    start: PropTypes.any,
    timezone_code: PropTypes.string,
    end: PropTypes.any,
    type: PropTypes.string,
    title: PropTypes.any,
    product: PropTypes.shape(),
    style: PropTypes.shape(),
    onOpenCreateUnavailability: PropTypes.func
  }).isRequired
};

export default ScheduleEventWeek;
