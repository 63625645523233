import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchApi } from '../../utils/functions/api';

import { enqueueSnackbar } from '../../redux/actions/appActions';
import {
  clearOwnReducer,
  selectUser,
  selectPlan,
  selectAddress,
  selectPaymentMethod,
  getOrderProductHostingPlans,
  getOrderProductHostingPlansSuccess,
  getOrderProductHostingPlansError,
  getHostingExtensionsClientPaymentMethods,
  getHostingExtensionsClientPaymentMethodsSuccess,
  getHostingExtensionsClientPaymentMethodsError,
  getHostingExtensionsAddresses,
  getHostingExtensionsAddressesSuccess,
  getHostingExtensionsAddressesError,
  createHostingExtension,
  createHostingExtensionSuccess,
  createHostingExtensionError
} from '../../redux/actions/orderProducts/hostingExtensions/hostingExtensionsActions';
import { setListNeedReload } from '../../redux/actions/core/list/listActions';

import CreateOrderProductsHostingExtensions from '../../views/orderProducts/hosting/CreateOrderProductsHostingExtensions';

import translation from '../../translation/translation';

const mapStateToProps = (store) => ({
  hostingExtensions: store.orderProducts.hosting_extensions
});

const mapDispatchToProps = (dispatch) => {
  return {
    clearOwnReducer() {
      dispatch(clearOwnReducer());
    },
    setListNeedReload() {
      dispatch(setListNeedReload());
    },
    selectUser(user) {
      dispatch(selectUser(user));
    },
    selectPlan(plan) {
      dispatch(selectPlan(plan));
    },
    selectAddress(address) {
      dispatch(selectAddress(address));
    },
    selectPaymentMethod(method) {
      dispatch(selectPaymentMethod(method));
    },
    getOrderProductHostingPlans(orderId, productId) {
      if (!orderId || !productId) return;

      dispatch(getOrderProductHostingPlans());

      fetchApi(
        'get',
        `orders/${orderId}/products/${productId}/hosting-plans`,
        null,
        null,
        null,
        (success) => {
          dispatch(getOrderProductHostingPlansSuccess(success));
        },
        (error) => {
          const errorMessage =
            error.response.data && error.response.data.detail
              ? error.response.data.detail
              : error.response.data.status_code
              ? error.response.data.status_code + ': ' + translation().core.item.not_found
              : translation().views.order_products.hosting_extensions.create.get_failure;
          dispatch(getOrderProductHostingPlansError(errorMessage));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message: errorMessage,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().views.order_products.hosting_extensions.create.get_failure,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    },
    getClientPaymentMethods(user, callbackSuccess) {
      if (!user || !user.id) return;

      dispatch(getHostingExtensionsClientPaymentMethods());

      const query = {};

      if (user.currency && user.currency.isocode) query.currency = user.currency.isocode;

      fetchApi(
        'get',
        `users/${user.id}/payment-methods/usable`,
        query,
        null,
        null,
        (data) => {
          dispatch(getHostingExtensionsClientPaymentMethodsSuccess(data));

          if (callbackSuccess && typeof callbackSuccess === 'function') callbackSuccess(data);
        },
        (error) => {
          dispatch(getHostingExtensionsClientPaymentMethodsError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().views.orders.create.callbacks.get_client_payment_methods.error,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message:
                  translation().views.orders.create.callbacks.get_client_payment_methods.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    },
    getClientAddresses(user, callbackSuccess) {
      if (!user || !user.id) return;

      dispatch(getHostingExtensionsAddresses());

      const query = {};

      if (user.currency && user.currency.isocode) query.currency = user.currency.isocode;

      if (user.unit_system) query.unit_system = user.unit_system;

      fetchApi(
        'get',
        `users/${user.id}/addresses/usable`,
        query,
        null,
        null,
        (data) => {
          dispatch(getHostingExtensionsAddressesSuccess(data));

          if (callbackSuccess && typeof callbackSuccess === 'function') callbackSuccess(data);
        },
        (error) => {
          dispatch(getHostingExtensionsAddressesError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().views.orders.create.callbacks.get_client_addresses.error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().views.orders.create.callbacks.get_client_addresses.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    },
    createHostingExtension(orderId, productId, data, callbackSuccess = null) {
      if (!orderId || !productId || !data) return;

      dispatch(createHostingExtension());

      fetchApi(
        'post',
        `orders/${orderId}/products/${productId}/hosting-extensions`,
        null,
        data,
        null,
        (success) => {
          dispatch(createHostingExtensionSuccess(success));
          dispatch(
            enqueueSnackbar({
              message: translation().views.order_products.hosting_extensions.create.post_succes,
              options: {
                variant: 'success'
              }
            })
          );

          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success);
          }
        },
        (error) => {
          const errorMessage =
            error.response.data && error.response.data.detail
              ? error.response.data.detail
              : translation().views.order_products.hosting_extensions.create.post_failure;
          dispatch(createHostingExtensionError(errorMessage));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message: errorMessage,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().views.order_products.hosting_extensions.create.post_failure,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    }
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateOrderProductsHostingExtensions)
);
