const initialState = {
  notifications: {
    key: 1,
    message: null
  },
  theme: {
    isDarkMode: false
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_REDUCERS':
      return initialState;
    case 'ENQUEUE_SNACKBAR':
      return {
        ...state,
        notifications: {
          ...action.notification
        }
      };
    case 'REMOVE_SNACKBAR':
      return {
        ...state,
        notifications: initialState.notifications
      };
    case 'SET_THEME_DARK_MODE':
      return {
        ...state,
        theme: {
          ...state.theme,
          isDarkMode: action.isDarkMode
        }
      };
    default:
      return state;
  }
};
