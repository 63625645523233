import _ from 'lodash';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  CircularProgress,
  Fab,
  Grid,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import { Add } from '@material-ui/icons';

import ProductRow from './ProductRow';
import Alert from '../../../../../components/alert/Alert';
import translation from '../../../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  title: {
    fontSize: 18,
    textAlign: 'center'
  },
  tableContainer: {
    marginTop: 24,
    maxHeight: '640px!important'
  },
  incrementFab: {
    display: 'flex',
    margin: '24px auto'
  },
  smallCell: {
    maxWidth: 70
  }
}));

function ProviderProducts(props) {
  const emptyRow = {
    _index: 0,
    product_id: null,
    boost_factor: 5,
    radius: null,
    reversion_hourly_rate: null,
    trip_compensation: null,
    reversion_amount: null,
    moderate: true
  };

  const {
    createProviderProduct,
    deleteProviderProduct,
    getProviderProducts,
    PaperProps,
    providerId,
    updateProviderProduct
  } = props;

  const classes = useStyles();
  const [state, setState] = useState({
    loading: false,
    data: [],
    paging: null
  });

  const { loading, paging, data } = state;

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (providerId) {
      setState((state) => ({
        ...state,
        loading: true,
        data: []
      }));

      getProviderProducts(
        providerId,
        null,
        (products, paging) => {
          if (products && products.length) {
            for (let i = 0; i < products.length; i++) {
              products[i]._index = i;
            }

            setState((state) => ({
              ...state,
              loading: false,
              paging,
              data: [
                ...products,
                {
                  ...emptyRow,
                  _index: products.length
                }
              ]
            }));
          } else {
            setState((state) => ({
              ...state,
              loading: false,
              data: [emptyRow]
            }));
          }
        },
        () => {
          setState((state) => ({
            ...state,
            loading: false,
            data: [emptyRow]
          }));
        },
        null,
        source
      );
    } else {
      setState((state) => ({
        ...state,
        data: [emptyRow]
      }));
    }

    return () => {
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleScrollTable(event) {
    if (!event || !event.target) {
      console.error('No table scroll event found.');
    }

    if (!event.target.scrollTop || event.target.scrollTop === 0) return;

    if (event.target.scrollHeight - event.target.clientHeight - 100 <= event.target.scrollTop) {
      if (providerId && paging && paging.next && !loading) {
        setState((state) => ({
          ...state,
          loading: true
        }));

        getProviderProducts(
          providerId,
          paging.next,
          (products, paging) => {
            if (products && products.length) {
              /**
               * Remove last item of the data array (empty row),
               * Then re-create it after data concat.
               */
              const dataArray = _.clone(data);
              const dataArrayDropped = _.dropRight(dataArray);
              const newArray = _.concat(dataArrayDropped, products);

              for (let i = 0; i < newArray.length; i++) {
                newArray[i]._index = i;
              }

              setState(() => ({
                data: [
                  ...newArray,
                  {
                    ...emptyRow,
                    _index: newArray.length
                  }
                ],
                loading: false,
                paging
              }));
            } else {
              setState((state) => ({
                ...state,
                loading: false
              }));
            }
          },
          () => {
            setState((state) => ({
              ...state,
              loading: false
            }));
          }
        );
      }
    }
  }

  function handleCreateRow() {
    setState((state) => ({
      ...state,
      data: [
        ...state.data,
        state.data.length > 0
          ? {
              ...emptyRow,
              _index: _.last(state.data)._index + 1
            }
          : emptyRow
      ]
    }));
  }

  function handleDeleteRow(index) {
    const dataCloned = _.clone(data);
    const newArray = _.remove(dataCloned, function (n) {
      return n._index !== index;
    });

    setState((state) => ({
      ...state,
      data: newArray
    }));
  }

  /**
   * Unfortunatly we are forced to re-render table with !loading, due to a unknown issue on loading next data.
   */

  return (
    <Grid item xs={12}>
      <Paper elevation={1} className={classes.root} {...PaperProps}>
        <Typography variant="h5" gutterBottom className={classes.title}>
          {translation().views.provider_factory.steps.products.page_title}
        </Typography>
        {providerId ? (
          <>
            <TableContainer className={classes.tableContainer} onScroll={handleScrollTable}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>Product</TableCell>
                    <TableCell>Boost factor</TableCell>
                    <TableCell>Radius</TableCell>
                    <TableCell>Reversion hourly rate</TableCell>
                    <TableCell>Trip compensation</TableCell>
                    <TableCell>Reversion amount</TableCell>
                    <TableCell>Moderate</TableCell>
                    <TableCell align="right" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!loading && data && data.length > 0
                    ? data.map((product) => (
                        <ProductRow
                          key={product._index}
                          providerProduct={product}
                          onRemove={handleDeleteRow}
                          providerId={providerId}
                          createProviderProduct={createProviderProduct}
                          deleteProviderProduct={deleteProviderProduct}
                          updateProviderProduct={updateProviderProduct}
                          numberOfRows={data.length}
                        />
                      ))
                    : false}
                </TableBody>
              </Table>
            </TableContainer>
            {loading && paging && paging.next && (
              <div className="loader-wpr">
                <CircularProgress />
                <p>{translation().core.list.load_next}</p>
              </div>
            )}
            {loading && !paging ? (
              <div className="loader-wpr">
                <CircularProgress />
                <p>{translation().views.provider_view.products.loading_text}</p>
              </div>
            ) : !loading ? (
              <Fab size="small" onClick={handleCreateRow} className={classes.incrementFab}>
                <Add fontSize="small" />
              </Fab>
            ) : (
              false
            )}
          </>
        ) : (
          <Alert type="danger" text="Missing provider ID" />
        )}
      </Paper>
    </Grid>
  );
}

ProviderProducts.propTypes = {
  createProviderProduct: PropTypes.func.isRequired,
  deleteProviderProduct: PropTypes.func.isRequired,
  getProviderProducts: PropTypes.func.isRequired,
  PaperProps: PropTypes.shape(),
  providerId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  updateProviderProduct: PropTypes.func.isRequired
};

export default ProviderProducts;
