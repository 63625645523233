import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';

import FormDateField from '../../../components/forms/fields/FormDateField';
import FormPhoneField from '../../../components/forms/fields/customs/formPhoneField/FormPhoneField';
import FormSelectField from '../../../components/forms/fields/FormSelectField';
import FormTextField from '../../../components/forms/fields/FormTextField';
import translation from '../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  fieldsWrapper: {
    margin: theme.spacing(1, 0, 5)
  }
}));

function Infos(props) {
  const { defaultValues, onChange, profile } = props;

  const classes = useStyles();

  return (
    <div className={classes.fieldsWrapper}>
      <FormTextField
        name="first_name"
        label={translation().views.profile.form.labels.first_name}
        defaultValue={defaultValues.first_name}
        type="text"
        id="profile-first_name"
        onChange={onChange('first_name')}
        error={profile.errors && profile.errors['first_name'] ? profile.errors['first_name'] : null}
        required
        disabled={profile.isLoading}
      />
      <FormTextField
        name="last_name"
        label={translation().views.profile.form.labels.last_name}
        defaultValue={defaultValues.last_name}
        type="text"
        id="profile-last_name"
        onChange={onChange('last_name')}
        error={profile.errors && profile.errors['last_name'] ? profile.errors['last_name'] : null}
        disabled={profile.isLoading}
        required
      />
      <FormTextField
        name="email"
        autoComplete="username"
        label={translation().views.profile.form.labels.email}
        defaultValue={defaultValues.email}
        type="text"
        id="profile-email"
        onChange={onChange('email')}
        error={profile.errors && profile.errors['email'] ? profile.errors['email'] : null}
        disabled={profile.isLoading}
        required
      />
      <FormDateField
        name="date_of_birth"
        label={translation().views.profile.form.labels.birth_of_date}
        defaultValue={defaultValues.date_of_birth}
        type="text"
        id="profile-date_of_birth"
        onChange={onChange('date_of_birth')}
        error={
          profile.errors && profile.errors['date_of_birth'] ? profile.errors['date_of_birth'] : null
        }
        disabled={profile.isLoading}
        required
      />
      <FormSelectField
        displayEmpty={true}
        id="profile-gender"
        label={translation().views.profile.form.labels.civility}
        name="gender"
        defaultValue={defaultValues.gender}
        disabled={profile.isLoading}
        onChange={onChange('gender')}
        error={profile.errors && profile.errors['gender'] ? profile.errors['gender'] : null}>
        {['M', 'F', 'O'].map((choice, indexItem) => {
          return (
            <MenuItem key={indexItem} value={choice}>
              {choice}
            </MenuItem>
          );
        })}
      </FormSelectField>
      <FormPhoneField
        type="tel"
        disabled={profile.isLoading}
        name="mobile"
        label={translation().views.profile.form.labels.mobile}
        defaultValue={defaultValues.mobile}
        onChange={onChange('mobile')}
        error={profile.errors && profile.errors['mobile'] ? profile.errors['mobile'] : null}
        required
      />
      <FormPhoneField
        type="tel"
        disabled={profile.isLoading}
        name="phone"
        label={translation().views.profile.form.labels.phone}
        defaultValue={defaultValues.phone}
        onChange={onChange('phone')}
        error={profile.errors && profile.errors['phone'] ? profile.errors['phone'] : null}
      />
    </div>
  );
}

Infos.propTypes = {
  defaultValues: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    mobile: PropTypes.string,
    phone: PropTypes.string,
    gender: PropTypes.string,
    date_of_birth: PropTypes.string,
    email: PropTypes.string
  }),
  onChange: PropTypes.func.isRequired,
  profile: PropTypes.shape({
    isLoading: PropTypes.bool,
    errors: PropTypes.shape()
  }).isRequired
};

export default Infos;
