/**
 * Rbnb doc : https://github.com/airbnb/react-dates
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import classNames from 'classnames';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import './dateRangeField.scss';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';

import { formatDate } from '../../../../../utils/functions/dates';
import { capitalizeFirstLetter } from '../../../../../utils/functions/utils';
import translation from '../../../../../translation/translation';

function RenderMonthElement({ month }) {
  return <Typography>{capitalizeFirstLetter(formatDate(month, 'MMMM'))}</Typography>;
}

RenderMonthElement.propTypes = {
  month: PropTypes.string
};

function DateRangeField(props) {
  const {
    disableWithFullScreenPortal,
    endDate,
    endDateId,
    fullWidth,
    numberOfMonths,
    onFocusChange,
    onSelectRange,
    orientation,
    required,
    startDate,
    startDateId
  } = props;

  const [focusedInput, setFocusedInput] = useState(null);

  function handleSelectRange({ startDate, endDate }) {
    if (onSelectRange) {
      onSelectRange({ startDate, endDate });
    }
  }

  function handleFocus(input) {
    setFocusedInput(input);

    const DateInputArray = document.getElementsByClassName('DateInput');

    if (input === 'startDate' && DateInputArray[0]) {
      DateInputArray[0].classList.add('focused');

      if (DateInputArray[1]) {
        DateInputArray[1].classList.remove('focused');
      }
    } else if (input === 'endDate' && DateInputArray[1]) {
      DateInputArray[1].classList.add('focused');

      if (DateInputArray[0]) {
        DateInputArray[0].classList.remove('focused');
      }
    } else {
      if (DateInputArray[0]) {
        DateInputArray[0].classList.remove('focused');
      }
      if (DateInputArray[1]) {
        DateInputArray[1].classList.remove('focused');
      }
    }

    if (onFocusChange) {
      onFocusChange(input);
    }
  }

  function allowOutsideDate() {
    return false;
  }

  return (
    <FormControl
      fullWidth={fullWidth}
      className={classNames({ 'DateRangePicker-fullWidth': fullWidth })}>
      <InputLabel shrink>
        {translation().commons.date_range_field.label} {required ? '*' : ''}
      </InputLabel>
      <DateRangePicker
        orientation={
          orientation
            ? orientation
            : window.matchMedia('(max-width: 768px)').matches
            ? 'vertical'
            : 'horizontal'
        }
        numberOfMonths={
          numberOfMonths ? numberOfMonths : window.matchMedia('(max-width: 768px)').matches ? 1 : 2
        }
        withFullScreenPortal={
          disableWithFullScreenPortal
            ? false
            : window.matchMedia('(max-width: 768px)').matches
            ? true
            : false
        }
        startDate={startDate}
        startDateId={startDateId}
        endDate={endDate}
        endDateId={endDateId}
        onDatesChange={handleSelectRange}
        focusedInput={focusedInput}
        onFocusChange={handleFocus}
        hideKeyboardShortcutsPanel
        startDatePlaceholderText={translation().commons.date_range_field.start}
        endDatePlaceholderText={translation().commons.date_range_field.end}
        startDateAriaLabel={translation().commons.date_range_field.start}
        endDateAriaLabel={translation().commons.date_range_field.end}
        renderMonthElement={RenderMonthElement}
        isOutsideRange={allowOutsideDate}
        required={required}
      />
    </FormControl>
  );
}

DateRangeField.defaultProps = {
  disableWithFullScreenPortal: false,
  fullWidth: false,
  endDateId: 'unique_end_date_id',
  startDateId: 'unique_sart_date_id',
  required: false
};

DateRangeField.propTypes = {
  endDate: momentPropTypes.momentObj,
  endDateId: PropTypes.string,
  startDate: momentPropTypes.momentObj,
  startDateId: PropTypes.string,
  fullWidth: PropTypes.bool,
  numberOfMonths: PropTypes.number,
  onFocusChange: PropTypes.func,
  onSelectRange: PropTypes.func.isRequired,
  orientation: PropTypes.oneOf(['vertical', 'horizontal']),
  required: PropTypes.bool,
  disableWithFullScreenPortal: PropTypes.bool
};

export default DateRangeField;
