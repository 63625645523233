import './productView.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Alert from '../../../components/alert/Alert';
import HostingPlans from './components/hostingPlans/HostingPlans';
import Options from './components/options/Options';
import MediaTypes from './components/mediaTypes/MediaTypes';
import Pricing from './components/pricing/Pricing';
import ProductMetrics from './components/metrics/ProductMetrics';
import ProductSummary from './components/summary/ProductSummary';
import ProductViewBar from './components/ProductViewBar';
import translation from '../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  loaderIcon: {
    fontSize: 56,
    display: 'block',
    margin: '15px auto'
  },
  linearProgress: {
    margin: 'auto',
    maxWidth: 250
  },
  progressWrapper: {
    padding: theme.spacing(3),
    textAlign: 'center',
    width: '100%',
    '& .loader-wpr': {
      margin: 0,
      paddingTop: 7,
      paddingBottom: 10
    }
  },
  tabsRoot: {
    ...theme.mixins.gutters()
  }
}));

function ProductView(props) {
  const classes = useStyles();

  const tabs = [
    {
      label: translation().views.product_view.infos.label,
      hash: 'infos',
      value: 0
    },
    {
      label: translation().views.product_view.options.label,
      hash: 'options',
      value: 1
    },
    {
      label: 'Media types',
      hash: 'media-types',
      value: 2
    },
    {
      label: translation().views.product_view.hosting_plans.label,
      hash: 'hosting-plans',
      value: 3
    },
    {
      label: translation().views.product_view.pricing.label,
      hash: 'pricing',
      value: 4
    },
    {
      label: translation().views.product_view.metrics.label,
      hash: 'metrics',
      value: 5
    }
  ];

  const {
    cleanProductViewReducer,
    cleanProductViewOptionsReducer,
    cleanProductViewMediaTypesReducer,
    cleanProductViewHostingPlansReducer,
    getProduct,
    getProductHostingPlans,
    getProductOptions,
    getProductMediaTypes,
    history,
    location: { pathname },
    match,
    product: { isLoading, data, error, hosting_plans, media_types, options },
    user,
    sub_routes
  } = props;

  const [tab, setTab] = useState(0);

  function loadView() {
    if (match && match.params && match.params.id) {
      getProduct(match && match.params && match.params.id, () => {
        if (history && history.location && history.location.hash) {
          for (let i = 0; i < tabs.length; i++) {
            if (tabs[i].hash === history.location.hash.substring(1)) {
              setTab(i);
              break;
            }
          }
        }
      });
    }
  }

  useEffect(() => {
    if (match && match.params && match.params.id) {
      document.title = `Actarus | Product #${match.params.id}`;
    } else {
      document.title = 'Actarus | Product view';
    }

    loadView();

    return () => {
      cleanProductViewReducer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleRefresh() {
    loadView();
  }

  function handleChangeTab(e, newTab) {
    setTab(newTab);

    for (let i = 0; i < tabs.length; i++) {
      if (tabs[i].value === newTab) {
        window.location.hash = tabs[i].hash;
        break;
      }
    }
  }

  return (
    <div className="ProductView">
      <ProductViewBar
        item={data ? data : null}
        history={history}
        parentUri="shop"
        routeUri={pathname.substring(1)}
        subRoutes={sub_routes}
        disabled={false}
        handleRefresh={handleRefresh}
      />
      <div className="ProductView-content">
        {!isLoading && error && (!data || data.length <= 0) ? (
          <div className="error-list">
            <Alert
              type="danger"
              style={{ marginTop: 10 }}
              status={translation().commons.alerts.error}
              text={translation().core.list.error_list}
            />
          </div>
        ) : (
          false
        )}
        {isLoading && (
          <div className="loading-wrapper">
            <div className={classes.progressWrapper}>
              <Icon fontSize="large" color="primary" className={classes.loaderIcon}>
                inventory
              </Icon>
              <div className={classes.linearProgress}>
                <LinearProgress color="secondary" variant="query" />
              </div>
              <p className="loader-wpr">{translation().views.product_view.loading_product}</p>
            </div>
          </div>
        )}
        {!isLoading && data && (
          <>
            <Tabs
              value={tab}
              className={classes.tabsRoot}
              onChange={handleChangeTab}
              variant="scrollable"
              indicatorColor="secondary"
              textColor="secondary"
              aria-label="lab tabs">
              {tabs.map((tab) => (
                <Tab key={tab.value} label={tab.label} />
              ))}
            </Tabs>
            <div>
              {tab === 0 && <ProductSummary getProductOptions={getProductOptions} product={data} />}
              {tab === 1 && (
                <div>
                  <Options
                    cleanProductViewOptionsReducer={cleanProductViewOptionsReducer}
                    getProductOptions={getProductOptions}
                    productId={data.id}
                    options={options}
                  />
                </div>
              )}
              {tab === 2 && (
                <div>
                  <MediaTypes
                    cleanProductViewMediaTypesReducer={cleanProductViewMediaTypesReducer}
                    getProductMediaTypes={getProductMediaTypes}
                    productId={data.id}
                    mediaTypes={media_types}
                  />
                </div>
              )}
              {tab === 3 && (
                <HostingPlans
                  cleanProductViewHostingPlansReducer={cleanProductViewHostingPlansReducer}
                  getProductHostingPlans={getProductHostingPlans}
                  productId={data.id}
                  hostingPlans={hosting_plans}
                />
              )}
              {tab === 4 && <Pricing product={data} user={user} />}
              {tab === 5 && <ProductMetrics />}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

ProductView.propTypes = {
  cleanProductViewReducer: PropTypes.func.isRequired,
  cleanProductViewHostingPlansReducer: PropTypes.func.isRequired,
  cleanProductViewOptionsReducer: PropTypes.func.isRequired,
  cleanProductViewMediaTypesReducer: PropTypes.func.isRequired,
  history: PropTypes.shape().isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  }).isRequired,
  getProduct: PropTypes.func.isRequired,
  getProductHostingPlans: PropTypes.func.isRequired,
  getProductOptions: PropTypes.func.isRequired,
  getProductMediaTypes: PropTypes.func.isRequired,
  product: PropTypes.shape({
    isLoading: PropTypes.bool,
    data: PropTypes.shape(),
    error: PropTypes.string,
    hosting_plans: PropTypes.shape({
      isLoading: PropTypes.bool
    }).isRequired,
    media_types: PropTypes.shape({
      isLoading: PropTypes.bool
    }).isRequired,
    options: PropTypes.shape({
      isLoading: PropTypes.bool
    }).isRequired
  }).isRequired,
  user: PropTypes.shape().isRequired,
  sub_routes: PropTypes.shape()
};

export default ProductView;
