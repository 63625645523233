import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchApi } from '../../../utils/functions/api';
import {
  decomposeFiltersFromString,
  formatFiltersValuesBeforePost
} from '../../../utils/functions/fitlers';
import { enqueueSnackbar } from '../../../redux/actions/appActions';
import {
  clearTaskView,
  clearTasks,
  createTask,
  createTaskSuccess,
  createTaskError,
  refreshTasks,
  refreshTaskView,
  getTaskView,
  getTaskViewError,
  getTaskViewSuccess,
  getTasks,
  getTasksError,
  getTasksSuccess,
  setTaskViewProp,
  getTaskMetadataSuccess,
  getCompletionStatuses,
  getCompletionStatusesSuccess,
  getCompletionStatusesError,
  getTasksViewTeams,
  getTasksViewTeamsSuccess,
  getTasksViewTeamsError,
  updateTaskViewProps,
  updateTaskViewPropsSuccess,
  updateTaskViewPropsError
} from '../../../redux/actions/views/tasksActions';
import { clearFilters, setFiltersValues } from '../../../redux/actions/filters/filtersActions';
import { createDateUtc, dateInTz, formatDate } from '../../../utils/functions/dates';
import store from '../../../redux/store';
import Tasks from '../../../views/tasks/tasks/Tasks';
import translation from '../../../translation/translation';

const mapStateToProps = (store) => ({
  user: store.user.data,
  tasks: store.views.tasks.tasks,
  selected: store.views.tasks.task_views.selected,
  filters: store.filters
});

const mapDispatchToProps = (dispatch) => {
  return {
    clearFilters() {
      dispatch(clearFilters());
    },
    clearTasks() {
      dispatch(clearTasks());
    },
    clearTaskView() {
      dispatch(clearTaskView());
    },
    getCompletionStatuses() {
      dispatch(getCompletionStatuses());

      fetchApi(
        'get',
        'completion-statuses',
        null,
        null,
        null,
        (data, paging) => {
          dispatch(getCompletionStatusesSuccess(data, paging));
        },
        (error) => {
          dispatch(getCompletionStatusesError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.callbacks.error_list,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().core.list.callbacks.error_list,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    },
    getTasksViewTeams() {
      dispatch(getTasksViewTeams());

      fetchApi(
        'get',
        'teams',
        null,
        null,
        null,
        (data, paging) => {
          dispatch(getTasksViewTeamsSuccess(data, paging));
        },
        (error) => {
          dispatch(getTasksViewTeamsError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.callbacks.error_list,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().core.list.callbacks.error_list,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    },
    getTaskView(tvid, isRefresh = null, onSuccess) {
      if (!tvid) {
        return;
      }

      isRefresh ? dispatch(refreshTaskView()) : dispatch(getTaskView());

      fetchApi(
        'get',
        `users/self/task-views/${tvid}`,
        null,
        null,
        null,
        (data) => {
          if (data.filters) {
            const filters = decomposeFiltersFromString(data.filters);

            if (filters) {
              dispatch(setFiltersValues(filters));
            }
          }

          fetchApi(
            'get',
            `tasks/metadata`,
            null,
            null,
            null,
            (metadata) => {
              dispatch(getTaskViewSuccess(data));
              dispatch(getTaskMetadataSuccess(metadata));

              if (onSuccess) {
                onSuccess(data);
              }
            },
            (error) => {
              dispatch(getTaskViewError(true));

              if (error.response) {
                dispatch(
                  enqueueSnackbar({
                    message:
                      error.response.data && error.response.data.detail
                        ? error.response.data.detail
                        : translation().core.list.callbacks.error_list,
                    status: error.response.status || undefined,
                    options: {
                      variant: 'error'
                    }
                  })
                );
              } else {
                dispatch(
                  enqueueSnackbar({
                    message: translation().core.list.callbacks.error_list,
                    options: {
                      variant: 'error'
                    }
                  })
                );
              }
            }
          );
        },
        (error) => {
          dispatch(getTaskViewError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.callbacks.error_list,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().core.list.callbacks.error_list,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    },
    getTasks(tvid, next = null, isRefresh = false, search = null) {
      if (!tvid) {
        return;
      }

      const query = { limit: 25 };

      isRefresh ? dispatch(refreshTasks()) : dispatch(getTasks(next, search));

      fetchApi(
        'get',
        next ? next : `users/self/task-views/${tvid}/tasks`,
        !next && search ? { ...query, search } : { ...query },
        null,
        null,
        (data, paging) => {
          dispatch(getTasksSuccess(data, paging));
        },
        (error) => {
          dispatch(getTasksError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.callbacks.error_list,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().core.list.callbacks.error_list,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    },
    createTask(tvid, task, onSuccess, onError) {
      if (!tvid || !task) {
        return;
      }

      dispatch(createTask());

      const values = { ...task };

      if (task.due) {
        const dueTZ = dateInTz(task.due);
        const dueUTC = createDateUtc(dueTZ).toString();

        values.due = formatDate(dueUTC, 'YYYY-MM-DDTHH:mm:ss');
      }

      fetchApi(
        'post',
        `users/self/task-views/${tvid}/tasks`,
        null,
        values,
        null,
        () => {
          dispatch(createTaskSuccess());
          dispatch(
            enqueueSnackbar({
              message: translation().views.task_views.tasks.create.callbacks.success,
              options: {
                variant: 'success'
              }
            })
          );

          if (onSuccess) {
            onSuccess();
          }
        },
        (error) => {
          dispatch(createTaskError());

          if (onError) {
            onError();
          }

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().views.task_views.tasks.create.callbacks.error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().views.task_views.tasks.create.callbacks.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    },
    filterTasks(metadata) {
      const filtersValues = store.getState().filters.values;
      let filters = '';

      for (let value in filtersValues) {
        /**
         * Check if operator in range is set (for timestamp type for example)
         */
        if (
          filtersValues[value] &&
          filtersValues[`${value}.start`] &&
          filtersValues[`${value}.end`] &&
          filtersValues[`${value}.start`].value &&
          filtersValues[`${value}.end`].value &&
          filtersValues[value].operator &&
          filtersValues[value].operator === 'range'
        ) {
          const rangeStart = { value: filtersValues[`${value}.start`].value };
          const rangeEnd = { value: filtersValues[`${value}.end`].value };

          filters += `${value}|gte|${formatFiltersValuesBeforePost(metadata, value, rangeStart)},`;
          filters += `${value}|lte|${formatFiltersValuesBeforePost(metadata, value, rangeEnd)},`;
        } else if (
          filtersValues[value] &&
          filtersValues[value].operator &&
          (filtersValues[value].operator === 'nu' || filtersValues[value].operator === 'nnu')
        ) {
          filters += `${value}|${filtersValues[value].operator}|,`;
        } else if (
          filtersValues[value] &&
          filtersValues[value].value &&
          !value.includes('.start') &&
          !value.includes('.end')
        ) {
          filters += `${value}|${
            filtersValues[value].operator ? filtersValues[value].operator : 'eq'
          }|${formatFiltersValuesBeforePost(metadata, value, filtersValues[value])},`;
        }
      }

      return filters;
    },
    setTaskViewProp(prop, value) {
      dispatch(setTaskViewProp(prop, value));
    },
    updateTaskViewProps(tvid, values, onSuccess, onError) {
      dispatch(updateTaskViewProps());
      dispatch(refreshTaskView());

      if (!tvid || !values) {
        return;
      }

      fetchApi(
        'put',
        `users/self/task-views/${tvid}`,
        null,
        values,
        null,
        () => {
          dispatch(updateTaskViewPropsSuccess());

          if (onSuccess) {
            onSuccess();
          }
        },
        (error) => {
          dispatch(updateTaskViewPropsError());

          if (onError) {
            onError();
          }

          if (error && error.response) {
            if (error.response.status && error.response.status === 403) {
              dispatch(
                enqueueSnackbar({
                  message:
                    error.response.data && error.response.data.detail
                      ? error.response.data.detail
                      : translation().core.list.denied,
                  status: 403,
                  options: {
                    variant: 'error'
                  }
                })
              );
            } else {
              dispatch(
                enqueueSnackbar({
                  message:
                    error.response.data && error.response.data.detail
                      ? error.response.data.detail
                      : translation().views.task_views.update.callbacks.error,
                  options: {
                    variant: 'error'
                  }
                })
              );
            }
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().views.task_views.update.callbacks.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    },
    linkUserToTaskView(tvid, user, onSuccess, onError) {
      if (!tvid || !user) return;

      fetchApi(
        'post',
        `users/self/task-views/${tvid}/users`,
        null,
        user,
        null,
        (data) => {
          if (onSuccess) {
            onSuccess(data);
          }

          dispatch(
            enqueueSnackbar({
              message: translation().views.task_views.tasks.users.callbacks.link.success,
              options: {
                variant: 'success'
              }
            })
          );
        },
        (error) => {
          if (onError) {
            onError();
          }

          if (error && error.response) {
            if (error.response.status && error.response.status === 403) {
              dispatch(
                enqueueSnackbar({
                  message:
                    error.response.data && error.response.data.detail
                      ? error.response.data.detail
                      : translation().core.list.denied,
                  status: 403,
                  options: {
                    variant: 'error'
                  }
                })
              );
            } else {
              dispatch(
                enqueueSnackbar({
                  message:
                    error.response.data && error.response.data.detail
                      ? error.response.data.detail
                      : translation().views.task_views.tasks.users.callbacks.link.error,
                  options: {
                    variant: 'error'
                  }
                })
              );
            }
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().views.task_views.tasks.users.callbacks.link.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    },
    updateUserTaskViewLevel(tvid, uid, level, onSuccess, onError) {
      if (!tvid || !uid || !level) return;

      fetchApi(
        'put',
        `users/self/task-views/${tvid}/users/${uid}/level`,
        null,
        { level },
        null,
        (data) => {
          dispatch(
            enqueueSnackbar({
              message: translation().views.task_views.tasks.users.callbacks.update.success,
              options: {
                variant: 'success'
              }
            })
          );

          if (onSuccess) {
            onSuccess(data);
          }
        },
        (error) => {
          if (onError) {
            onError();
          }

          if (error && error.response) {
            if (error.response.status && error.response.status === 403) {
              dispatch(
                enqueueSnackbar({
                  message:
                    error.response.data && error.response.data.detail
                      ? error.response.data.detail
                      : translation().core.list.denied,
                  status: 403,
                  options: {
                    variant: 'error'
                  }
                })
              );
            } else {
              dispatch(
                enqueueSnackbar({
                  message:
                    error.response.data && error.response.data.detail
                      ? error.response.data.detail
                      : translation().views.task_views.tasks.users.callbacks.update.error,
                  options: {
                    variant: 'error'
                  }
                })
              );
            }
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().views.task_views.tasks.users.callbacks.update.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    },
    removeUserTaskView(tvid, uid, onSuccess, onError) {
      if (!tvid || !uid) return;

      fetchApi(
        'delete',
        `users/self/task-views/${tvid}/users/${uid}`,
        null,
        null,
        null,
        () => {
          dispatch(
            enqueueSnackbar({
              message: translation().views.task_views.tasks.users.callbacks.unlink.success,
              options: {
                variant: 'success'
              }
            })
          );

          if (onSuccess) {
            onSuccess();
          }
        },
        (error) => {
          if (onError) {
            onError();
          }

          if (error && error.response) {
            if (error.response.status && error.response.status === 403) {
              dispatch(
                enqueueSnackbar({
                  message:
                    error.response.data && error.response.data.detail
                      ? error.response.data.detail
                      : translation().core.list.denied,
                  status: 403,
                  options: {
                    variant: 'error'
                  }
                })
              );
            } else {
              dispatch(
                enqueueSnackbar({
                  message:
                    error.response.data && error.response.data.detail
                      ? error.response.data.detail
                      : translation().views.task_views.tasks.users.callbacks.unlink.error,
                  options: {
                    variant: 'error'
                  }
                })
              );
            }
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().views.task_views.tasks.users.callbacks.unlink.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    },
    updateTaskProps(tvid, tid, values, onSuccess, onError) {
      if (!tvid || !tid || !values) {
        return;
      }

      fetchApi(
        'put',
        `users/self/task-views/${tvid}/tasks/${tid}`,
        null,
        values,
        null,
        (data) => {
          if (onSuccess) {
            onSuccess(data);
          }
        },
        (error) => {
          if (onError) {
            onError();
          }

          if (error && error.response) {
            if (error.response.status && error.response.status === 403) {
              dispatch(
                enqueueSnackbar({
                  message:
                    error.response.data && error.response.data.detail
                      ? error.response.data.detail
                      : translation().core.list.denied,
                  status: 403,
                  options: {
                    variant: 'error'
                  }
                })
              );
            } else {
              dispatch(
                enqueueSnackbar({
                  message:
                    error.response.data && error.response.data.detail
                      ? error.response.data.detail
                      : translation().views.task_views.tasks.update.callbacks.error,
                  options: {
                    variant: 'error'
                  }
                })
              );
            }
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().views.task_views.tasks.update.callbacks.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    },
    deleteTask(tvid, tid, onSuccess, onError) {
      if (!tvid || !tid) {
        return;
      }

      fetchApi(
        'delete',
        `users/self/task-views/${tvid}/tasks/${tid}`,
        null,
        null,
        null,
        (data) => {
          if (onSuccess) {
            onSuccess(data);
          }
        },
        (error) => {
          if (onError) {
            onError();
          }

          if (error && error.response) {
            if (error.response.status && error.response.status === 403) {
              dispatch(
                enqueueSnackbar({
                  message:
                    error.response.data && error.response.data.detail
                      ? error.response.data.detail
                      : translation().core.list.denied,
                  status: 403,
                  options: {
                    variant: 'error'
                  }
                })
              );
            } else {
              dispatch(
                enqueueSnackbar({
                  message:
                    error.response.data && error.response.data.detail
                      ? error.response.data.detail
                      : translation().views.task_views.tasks.update.callbacks.error,
                  options: {
                    variant: 'error'
                  }
                })
              );
            }
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().views.task_views.tasks.update.callbacks.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Tasks));
