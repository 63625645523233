import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Warning } from '@material-ui/icons';

import { getAccessToken } from '../../../../utils/functions/cookies';
import { enqueueSnackbar } from '../../../../redux/actions/appActions';
import translation from '../../../../translation/translation';
import config from '../../../../config';
import store from '../../../../redux/store';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 25
  },
  dialogContent: {
    margin: 0,
    padding: 0,
    outline: 'none',
    height: '100%',
    backgroundColor: '#FFFFFF',
    lineHeight: 0,
    '&:first-child': {
      paddingTop: 0
    }
  },
  dialogActions: {
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    padding: '8px 4px',
    margin: 0
  },
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 20,
    fontWeight: 500
  },
  loaderWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    position: 'absolute'
  }
}));

function MediaTypeForm(props) {
  const { mediaType, orderId, productId } = props;

  const [isConfirmCloseFormDialogOpen, setConfirmCloseFormDialogOpen] = useState(false);
  const [isFormDialogOpen, setFormDialogOpen] = useState(false);
  const [isFrameLoading, setFrameLoading] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    return () => {
      window.removeEventListener('message', messageListener, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function messageListener(e) {
    if (!e) return;

    const { mediaType, onComplete } = props;

    // const origin = e.originalEvent && e.originalEvent.origin || e.origin;

    // if(origin !== `${config.api_url}orders/${orderId}/products/${productId}/medias/forms/${mediaType.shortcode}/form`)
    //   return;

    if (e.data && e.data.message && e.data.message === 'media_type_form_sent') {
      if (e.data.status && e.data.status === 200) {
        store.dispatch(
          enqueueSnackbar({
            message: translation().views.orders.media_groups.media_type_form.success,
            options: {
              variant: 'success'
            }
          })
        );
        handleCloseFormDialog();

        if (onComplete && mediaType.shortcode) {
          onComplete(mediaType.shortcode);
        }
      }
    }
  }

  function handleOpenFormDialog() {
    setFrameLoading(true);
    setFormDialogOpen(true);

    window.addEventListener('message', messageListener, false);
  }

  function handleCloseFormDialog() {
    setConfirmCloseFormDialogOpen(false);
    setFrameLoading(false);
    setFormDialogOpen(false);

    window.removeEventListener('message', messageListener, false);
  }

  function handleOpenConfirmCloseFormDialog() {
    setConfirmCloseFormDialogOpen(true);
  }

  function handleCloseConfirmCloseFormDialog() {
    setConfirmCloseFormDialogOpen(false);
  }

  function handleLoad() {
    setFrameLoading(false);
  }

  return (
    <div className={classes.root}>
      <Button onClick={handleOpenFormDialog} color="primary" variant="contained">
        {mediaType.medias && mediaType.medias.length > 0
          ? translation().views.orders.media_groups.media_type_form.view
          : translation().views.orders.media_groups.media_type_form.fill}
      </Button>
      <Dialog
        disableBackdropClick
        fullWidth={true}
        maxWidth="xl"
        open={isFormDialogOpen}
        onClose={handleCloseFormDialog}>
        <DialogContent className={classes.dialogContent}>
          {isFrameLoading && (
            <div className={classes.loaderWrapper}>
              <CircularProgress size={50} color="secondary" />
            </div>
          )}
          <iframe
            title={mediaType.name}
            width="100%"
            height="600"
            className="form-frame"
            frameBorder="0"
            allowFullScreen
            onLoad={handleLoad}
            src={`${config.api_url}orders/${orderId}/products/${productId}/medias/forms/${
              mediaType.shortcode
            }/form?access_token=${getAccessToken()}`}
          />
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            onClick={
              mediaType.medias && mediaType.medias.length > 0
                ? handleCloseFormDialog
                : handleOpenConfirmCloseFormDialog
            }
            color="default">
            {translation().views.orders.media_groups.media_type_form.close}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isConfirmCloseFormDialogOpen} onClose={handleCloseConfirmCloseFormDialog}>
        <DialogTitle disableTypography className={classes.dialogTitle}>
          <Warning fontSize="small" style={{ marginRight: 4 }} />{' '}
          {translation().views.orders.media_groups.media_type_form.close_dialog.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {translation().views.orders.media_groups.media_type_form.close_dialog.description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmCloseFormDialog} color="default">
            {translation().views.orders.media_groups.media_type_form.close_dialog.cancel}
          </Button>
          <Button onClick={handleCloseFormDialog} variant="contained" color="primary">
            {translation().views.orders.media_groups.media_type_form.close_dialog.confirm}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

MediaTypeForm.propTypes = {
  mediaType: PropTypes.shape({
    shortcode: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    id: PropTypes.number,
    medias: PropTypes.arrayOf(PropTypes.shape())
  }).isRequired,
  orderId: PropTypes.number.isRequired,
  productId: PropTypes.number.isRequired,
  onComplete: PropTypes.func
};

export default MediaTypeForm;
