import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(() => ({
  btnWrapper: {
    marginTop: 24,
    width: '100%',
    textAlign: 'right'
  }
}));

function MissingFieldsForm(props) {
  const { buttonLabel, isFormLoading, fields, onSubmit } = props;

  const [values, setValues] = useState({});

  const classes = useStyles();

  const handleChange = (name) => (event) => {
    setValues({
      ...values,
      [name]: event.target.value
    });
  };

  function handleSubmit() {
    onSubmit(values);
  }

  function renderFields() {
    if (!fields || fields.length <= 0) return;

    const fieldsArray = [];

    for (let i = 0; i < fields.length; i++) {
      if (
        fields[i].field.input_type === 'select' ||
        (fields[i].field.choices && fields[i].field.choices.length > 0)
      ) {
        fieldsArray.push(
          <div key={'missingFields-' + i}>
            <FormControl required disabled={isFormLoading} fullWidth margin="normal">
              <InputLabel htmlFor={fields[i].field.name}>
                {fields[i].field.label ? fields[i].field.label : 'Missing field label'}
              </InputLabel>
              <Select
                name={fields[i].field.name}
                id={fields[i].field.name}
                onChange={handleChange(fields[i].field.name)}
                disabled={isFormLoading}
                value={values[fields[i].field.name] || ''}
                inputProps={{
                  name: fields[i].field.name,
                  id: fields[i].field.name
                }}
                required>
                {fields[i].field.choices.map((choice, k) => {
                  return (
                    <MenuItem key={k} value={choice.value}>
                      {choice.value}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        );
      } else if (
        fields[i].field.input_type === 'number' ||
        fields[i].field.input_type === 'integer' ||
        fields[i].field.input_type === 'float' ||
        fields[i].field.input_type === 'decimal'
      ) {
        fieldsArray.push(
          <div key={'missingFields-' + i}>
            <TextField
              type="number"
              disabled={isFormLoading}
              name={fields[i].field.name}
              id={fields[i].field.name}
              label={fields[i].field.label ? fields[i].field.label : 'Missing field label'}
              value={values[fields[i].field.name] || ''}
              onChange={handleChange(fields[i].field.name)}
              fullWidth
              required
              margin="normal"
            />
          </div>
        );
      } else {
        fieldsArray.push(
          <div key={'missingFields-' + i}>
            <TextField
              type="text"
              disabled={isFormLoading}
              name={fields[i].field.name}
              id={fields[i].field.name}
              label={fields[i].field.label ? fields[i].field.label : 'Missing field label'}
              value={values[fields[i].field.name] || ''}
              onChange={handleChange(fields[i].field.name)}
              fullWidth
              required
              margin="normal"
            />
          </div>
        );
      }
    }

    return fieldsArray;
  }

  return (
    <div className="MissingFieldsForm">
      {renderFields()}
      <div className={classes.btnWrapper}>
        <Button variant="contained" onClick={handleSubmit} disabled={isFormLoading}>
          {buttonLabel}
        </Button>
      </div>
    </div>
  );
}

MissingFieldsForm.propTypes = {
  isFormLoading: PropTypes.bool,
  buttonLabel: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.any).isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default MissingFieldsForm;
