import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';

import { pad } from '../../../../../../../../utils/functions/utils';
import FormSelectField from '../../../../../../../../components/forms/fields/FormSelectField';
import translation from '../../../../../../../../translation/translation';

function renderOptions(name, minHour = 7, maxHour = 21) {
  const options = [];

  for (let i = minHour; i < maxHour + 1; i++) {
    options.push(
      <MenuItem key={i + (name ? name : Math.random())} value={pad(i)}>
        {pad(i)}
      </MenuItem>
    );
  }

  return options;
}

function EditAvailabilityForm(props) {
  const {
    editAction: { loading },
    initialValues,
    maxHour,
    minHour,
    onSubmit
  } = props;

  const [values, setValues] = useState({
    hour_start: '',
    min_start: '',
    hour_end: '',
    min_end: ''
  });

  useEffect(() => {
    if (initialValues) {
      setValues(initialValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (name) => (value) => {
    setValues({ ...values, [name]: value });
  };

  function handleSubmitForm(event) {
    if (event) {
      event.preventDefault();
    }
    if (onSubmit) {
      onSubmit(values);
    }
  }

  return (
    <form onSubmit={handleSubmitForm} autoComplete="off">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormSelectField
            name="hour_start"
            value={values.hour_start}
            onChange={handleChange('hour_start')}
            disabled={loading}
            fullWidth
            required
            label={translation().views.provider_schedule.availability.forms.labels.start_time}>
            {renderOptions('_hour_start', minHour, maxHour)}
          </FormSelectField>
        </Grid>
        <Grid item xs={6}>
          <FormSelectField
            name="min_start"
            value={values.min_start}
            onChange={handleChange('min_start')}
            disabled={loading}
            fullWidth
            required
            label={translation().views.provider_schedule.availability.forms.labels.mins}>
            <MenuItem value="">-</MenuItem>
            <MenuItem value="00">00</MenuItem>
            <MenuItem value="15">15</MenuItem>
            <MenuItem value="30">30</MenuItem>
            <MenuItem value="45">45</MenuItem>
          </FormSelectField>
        </Grid>
        <Grid item xs={6}>
          <FormSelectField
            name="hour_end"
            value={values.hour_end}
            onChange={handleChange('hour_end')}
            disabled={loading}
            fullWidth
            required
            label={translation().views.provider_schedule.availability.forms.labels.end_time}>
            {renderOptions('_hour_end', minHour, maxHour)}
          </FormSelectField>
        </Grid>
        <Grid item xs={6}>
          <FormSelectField
            name="min_end"
            value={values.min_end}
            onChange={handleChange('min_end')}
            disabled={loading}
            fullWidth
            required
            label={translation().views.provider_schedule.availability.forms.labels.mins}>
            <MenuItem value="">-</MenuItem>
            <MenuItem value="00">00</MenuItem>
            <MenuItem value="15">15</MenuItem>
            <MenuItem value="30">30</MenuItem>
            <MenuItem value="45">45</MenuItem>
          </FormSelectField>
        </Grid>
        <div className="submit-wrapper">
          <Button
            color="primary"
            className="btn-availability"
            disabled={
              loading ||
              !values.hour_end ||
              !values.hour_start ||
              !values.min_end ||
              !values.min_start
            }
            style={{ minWidth: 150 }}
            type="submit"
            variant="contained">
            {loading ? (
              <CircularProgress size={20} />
            ) : (
              <span>{translation().views.provider_schedule.availability.edit.buttons.submit}</span>
            )}
          </Button>
        </div>
      </Grid>
    </form>
  );
}

EditAvailabilityForm.propTypes = {
  initialValues: PropTypes.shape(),
  editAction: PropTypes.shape({
    loading: PropTypes.bool,
    error: PropTypes.any
  }).isRequired,
  maxHour: PropTypes.number.isRequired,
  minHour: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default EditAvailabilityForm;
