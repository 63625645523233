import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar,
  ButtonBase,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Chip,
  Link as MuiLink,
  Typography
} from '@material-ui/core';
import {
  AccessTime as AccessTimeIcon,
  Event as EventIcon,
  Launch as LaunchIcon,
  ShoppingCart as ShoppingCartIcon,
  Timelapse as TimelapseIcon
} from '@material-ui/icons';

import { convertMinsToHrsMins, dateInTz } from '../../../utils/functions/dates';

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: theme.spacing(2, 2, 3)
  },
  base: {
    marginRight: 6,
    marginBottom: 6
  },
  detail: {
    display: 'inline-flex',
    alignItems: 'center',
    marginRight: 16,
    fontSize: 12
  },
  detailIcon: {
    marginRight: 4
  },
  link: {
    display: 'flex',
    alignItems: 'center'
  },
  option: {
    margin: 2
  },
  optionWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingTop: 8
  },
  orderId: {
    fontSize: 12,
    position: 'absolute',
    top: 8,
    right: 8,
    display: 'flex',
    alignItems: 'center'
  },
  root: {
    minHeight: 170,
    maxWidth: 400,
    textAlign: 'left'
  },
  cardActions: {
    position: 'absolute',
    bottom: 6,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    padding: '0px 4px'
  }
}));

function Product(props) {
  const classes = useStyles();
  const {
    delivery_status,
    duration,
    flag,
    icon_url_dark,
    id,
    history,
    name,
    options,
    onClickCard,
    order_id,
    provider_id,
    provider_name,
    start
  } = props;

  function handleNavigate() {
    history.push(`/production/moderation/order-product/${id}`);
  }

  function handleBlockEvent(event) {
    if (event) {
      event.stopPropagation();
    }
  }

  function handleClickCard() {
    if (onClickCard) {
      onClickCard({ id, name });
    } else {
      handleNavigate();
    }
  }

  return (
    <ButtonBase onClick={handleClickCard} className={classes.base}>
      <Card className={classes.root}>
        <CardHeader
          title={name}
          titleTypographyProps={{
            style: {
              fontSize: 14,
              fontWeight: 500,
              paddingRight: 50,
              color: flag ? '#F44336' : 'currentColor'
            }
          }}
          avatar={
            <Avatar
              src={icon_url_dark}
              style={{
                backgroundColor: flag
                  ? '#F44336'
                  : delivery_status.color
                  ? delivery_status.color
                  : '#e0e0e0',
                padding: 7
              }}
            />
          }
          subheader={
            provider_id ? (
              <MuiLink
                href={`/providers/${provider_id}/update`}
                rel="noopenner noreferrer"
                target="_blank"
                onClick={handleBlockEvent}>
                {provider_name} (#{provider_id})
              </MuiLink>
            ) : (
              provider_name
            )
          }
        />
        {order_id && (
          <div className={classes.orderId}>
            <MuiLink
              className={classes.link}
              target="_blank"
              rel="noopenner noreferrer"
              href={`/orders/${order_id}/update`}
              onClick={handleBlockEvent}>
              <ShoppingCartIcon style={{ fontSize: 16 }} /> {order_id}
            </MuiLink>
          </div>
        )}
        <CardContent className={classes.cardContent}>
          <div>
            {start ? (
              <Typography className={classes.detail} variant="subtitle1">
                <EventIcon fontSize="small" className={classes.detailIcon} />{' '}
                {dateInTz(start, 'localized-date')}
              </Typography>
            ) : (
              ''
            )}
            {start ? (
              <Typography className={classes.detail} variant="subtitle1">
                <AccessTimeIcon fontSize="small" className={classes.detailIcon} />{' '}
                {dateInTz(start, 'HH:mm')}
              </Typography>
            ) : (
              ''
            )}
            {duration ? (
              <Typography className={classes.detail} variant="subtitle1">
                <TimelapseIcon fontSize="small" className={classes.detailIcon} />{' '}
                {convertMinsToHrsMins(duration, 'h')}
              </Typography>
            ) : (
              ''
            )}
          </div>
          {options && options.length > 0 ? (
            <div className={classes.optionWrapper}>
              {options.map((option, id) => {
                return (
                  <Chip key={id} className={classes.option} label={option.name} size="small" />
                );
              })}
            </div>
          ) : (
            ''
          )}
        </CardContent>
        <div className={classes.cardActions}>
          {delivery_status && delivery_status.icon_url_dark && delivery_status.name && (
            <img
              src={delivery_status.icon_url_dark}
              alt={delivery_status.name}
              title={delivery_status.name}
              height={22}
            />
          )}
          <IconButton
            component={Link}
            size="small"
            to={`/production/moderation/order-product/${id} `}>
            <LaunchIcon fontSize="small" />
          </IconButton>
        </div>
      </Card>
    </ButtonBase>
  );
}

Product.propTypes = {
  delivery_status: PropTypes.shape().isRequired,
  duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  flag: PropTypes.bool,
  history: PropTypes.shape().isRequired,
  icon_url_dark: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.any.isRequired,
  onClickCard: PropTypes.func,
  order_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  provider_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  provider_name: PropTypes.string.isRequired,
  start: PropTypes.any.isRequired
};

export default Product;
