import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import { dateInTz } from '../../../utils/functions/dates';

const styles = (theme) => ({
  input: {
    color: theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.54)'
  }
});

function FormDateField(props) {
  const {
    autoComplete,
    classes,
    defaultValue,
    disabled,
    error,
    helperText,
    id,
    fullWidth,
    label,
    margin,
    name,
    onChange,
    required,
    write
  } = props;

  const [localValue, setLocalValue] = useState(
    defaultValue ? dateInTz(defaultValue, 'YYYY-MM-DD').toString() : ''
  );

  function handleChange(event) {
    setLocalValue(event.target.value);

    if (event && onChange) {
      onChange(event.target.value);
    }
  }

  return (
    <TextField
      id={id}
      autoComplete={autoComplete}
      defaultValue={defaultValue ? dateInTz(defaultValue, 'YYYY-MM-DD').toString() : ''}
      disabled={!write || disabled}
      helperText={error || helperText}
      margin={margin}
      name={name}
      label={label}
      type="date"
      error={error ? true : false}
      required={required}
      fullWidth={fullWidth}
      onChange={handleChange}
      InputLabelProps={{
        shrink: true
      }}
      InputProps={{
        classes: { input: localValue ? null : classes.input }
      }}
    />
  );
}

FormDateField.defaultProps = {
  margin: 'normal',
  fullWidth: true,
  write: true
};

FormDateField.propTypes = {
  autoComplete: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  classes: PropTypes.shape(),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  margin: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  write: PropTypes.bool
};

export default withStyles(styles)(FormDateField);
