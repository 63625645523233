const initialState = {
  data: [],
  isError: false,
  isLoading: false,
  paging: null,
  search: {
    value: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_REDUCERS':
      return initialState;
    case 'CLEAR_PREVIOUS_DAY_REDUCER':
      return initialState;
    case 'REFRESH_PREVIOUS_DAY':
      return {
        ...initialState,
        isLoading: true
      };
    case 'SET_PREVIOUS_DAY_SEARCH_VALUE':
      return {
        ...state,
        search: {
          ...state.search,
          value: action.value
        }
      };
    case 'GET_PREVIOUS_DAY':
      return {
        ...state,
        data: action.next ? state.data : initialState.data,
        paging: action.query ? initialState.paging : state.paging,
        isLoading: true,
        isError: false
      };
    case 'GET_PREVIOUS_DAY_SUCCESS': {
      const dataConcatenated = state.data.concat(action.data);

      return {
        ...state,
        data: dataConcatenated,
        paging: action.paging ? action.paging : state.paging,
        isLoading: false,
        isError: false
      };
    }
    case 'GET_PREVIOUS_DAY_ERROR':
      return {
        ...state,
        isLoading: false,
        isError: true
      };
    default:
      return state;
  }
};
