import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import CloseIcon from '@material-ui/icons/Close';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const styles = () => ({
  leftAnchor: {
    marginLeft: 'auto'
  },
  root: {
    maxHeight: 40,
    maxWidth: 40,
    marginRight: 15
  }
});

class ListViewSpeedDial extends React.Component {
  state = {
    open: false
  };

  handleClick = () => {
    this.setState((state) => ({
      open: !state.open
    }));
  };

  handleOpen = () => {
    this.setState({
      open: true
    });
  };

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  render() {
    const { actions, classes } = this.props;
    const { open } = this.state;

    return (
      <div className={classes.leftAnchor}>
        <SpeedDial
          ariaLabel="Actions"
          className={classes.root}
          icon={<SpeedDialIcon icon={<MoreVertIcon />} openIcon={<CloseIcon />} />}
          onBlur={this.handleClose}
          onClick={this.handleClick}
          onClose={this.handleClose}
          onMouseEnter={this.handleOpen}
          onMouseLeave={this.handleClose}
          open={open}
          direction="left"
          FabProps={{
            size: 'small',
            style: {
              flex: '1 0 40px',
              background: 'none',
              boxShadow: 'none'
            }
          }}>
          {actions && actions}
        </SpeedDial>
      </div>
    );
  }
}

ListViewSpeedDial.propTypes = {
  actions: PropTypes.node.isRequired,
  classes: PropTypes.shape().isRequired
};

export default withStyles(styles)(ListViewSpeedDial);
