import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchApi } from '../../utils/functions/api';

import { enqueueSnackbar } from '../../redux/actions/appActions';
import {
  cleanProviderViewAddressesReducer,
  cleanProviderViewInvoicesReducer,
  cleanProviderViewInvoiceQueueReducer,
  cleanProviderViewOrderProductsReducer,
  cleanProviderViewDevicesReducer,
  cleanProviderViewPaymentMethodsReducer,
  cleanProviderViewBlacklistReducer,
  cleanProviderViewRatingsReducer,
  getProviderView,
  getProviderViewSuccess,
  getProviderViewError,
  getProviderViewAddresses,
  getProviderViewAddressesSuccess,
  getProviderViewAddressesError,
  getProviderViewOrderProducts,
  getProviderViewOrderProductsSuccess,
  getProviderViewOrderProductsError,
  getProviderViewInvoices,
  getProviderViewInvoicesSuccess,
  getProviderViewInvoicesError,
  getProviderViewInvoiceQueue,
  getProviderViewInvoiceQueueSuccess,
  getProviderViewInvoiceQueueError,
  getProviderViewDevices,
  getProviderViewDevicesSuccess,
  getProviderViewDevicesError,
  getProviderViewBlacklist,
  getProviderViewBlacklistSuccess,
  getProviderViewBlacklistError,
  getProviderViewPaymentMethods,
  getProviderViewPaymentMethodsSuccess,
  getProviderViewPaymentMethodsError,
  getProviderViewOrderProductsMetadata,
  getProviderViewOrderProductsMetadataSuccess,
  getProviderViewOrderProductsMetadataError,
  getProviderViewRatingsReceived,
  getProviderViewRatingsReceivedSuccess,
  getProviderViewRatingsReceivedError,
  getProviderViewRatingsEmitted,
  getProviderViewRatingsEmittedSuccess,
  getProviderViewRatingsEmittedError,
  toggleProviderViewMode
} from '../../redux/actions/views/providerViewActions';

import translation from '../../translation/translation';

import ProviderView from '../../views/providers/providerView/ProviderView';

const mapStateToProps = (store) => ({
  provider: store.views.provider
});

const mapDispatchToProps = (dispatch) => {
  return {
    toggleProviderViewMode(value) {
      dispatch(toggleProviderViewMode(value));
    },
    cleanProviderViewAddressesReducer() {
      dispatch(cleanProviderViewAddressesReducer());
    },
    cleanProviderViewInvoicesReducer() {
      dispatch(cleanProviderViewInvoicesReducer());
    },
    cleanProviderViewOrderProductsReducer() {
      dispatch(cleanProviderViewOrderProductsReducer());
    },
    cleanProviderViewInvoiceQueueReducer() {
      dispatch(cleanProviderViewInvoiceQueueReducer());
    },
    cleanProviderViewPaymentMethodsReducer() {
      dispatch(cleanProviderViewPaymentMethodsReducer());
    },
    cleanProviderViewDevicesReducer() {
      dispatch(cleanProviderViewDevicesReducer());
    },
    cleanProviderViewBlacklistReducer() {
      dispatch(cleanProviderViewBlacklistReducer());
    },
    cleanProviderViewRatingsReducer() {
      dispatch(cleanProviderViewRatingsReducer());
    },
    getProviderView: (id, callbackSuccess, callbackError) => {
      if (!id) return;

      dispatch(getProviderView());

      fetchApi(
        'get',
        `providers/${id}`,
        null,
        null,
        null,
        (provider) => {
          dispatch(getProviderViewSuccess(provider));

          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(provider);
          }
        },
        (error) => {
          dispatch(getProviderViewError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.callbacks.error_list,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().core.list.callbacks.error_list,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError(error);
          }
        }
      );
    },
    async getOrderProductsMetadata(sourceToken = null) {
      dispatch(getProviderViewOrderProductsMetadata());

      try {
        await fetchApi(
          'get',
          'orders/1/products/metadata',
          null,
          null,
          null,
          (metadata) => {
            dispatch(getProviderViewOrderProductsMetadataSuccess(metadata));
          },
          (error) => {
            dispatch(getProviderViewOrderProductsMetadataError(true, error));

            if (error.response) {
              dispatch(
                enqueueSnackbar({
                  message:
                    error.response.data && error.response.data.detail
                      ? error.response.data.detail
                      : error.response.data.status_code
                      ? error.response.data.status_code + ': ' + translation().core.item.not_found
                      : translation().core.list.error_metadata,
                  status: error.response.status || undefined,
                  options: {
                    variant: 'error'
                  }
                })
              );
            } else {
              dispatch(
                enqueueSnackbar({
                  message: translation().core.list.error_metadata,
                  options: {
                    variant: 'error'
                  }
                })
              );
            }
          },
          null,
          sourceToken
        );
      } catch (error) {
        dispatch(getProviderViewOrderProductsMetadataError(true, error));
        dispatch(
          enqueueSnackbar({
            message: translation().core.list.error_metadata,
            options: {
              variant: 'error'
            }
          })
        );
      }
    },
    getProviderViewOrderProducts: (
      providerId,
      sourceToken,
      next = null,
      callbackSuccess = null,
      callbackError = null
    ) => {
      if (!providerId) return;

      dispatch(getProviderViewOrderProducts(next));

      fetchApi(
        'get',
        next ? next : `providers/${providerId}/order-products`,
        null,
        null,
        null,
        (success, paging) => {
          dispatch(getProviderViewOrderProductsSuccess(success, paging));

          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success, paging);
          }
        },
        (error) => {
          dispatch(getProviderViewOrderProductsError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.callbacks.error_list,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().core.list.callbacks.error_list,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError(error);
          }
        },
        null,
        sourceToken
      );
    },
    getProviderViewPaymentMethods: (
      userId,
      next = null,
      isModeCompany = false,
      companyId = null,
      callbackSuccess = null,
      callbackError = null
    ) => {
      if (!userId) return;

      dispatch(getProviderViewPaymentMethods(next));

      fetchApi(
        'get',
        next
          ? next
          : isModeCompany && companyId
          ? `companies/${companyId}/payment-methods`
          : `users/${userId}/payment-methods`,
        null,
        null,
        null,
        (success, paging) => {
          dispatch(getProviderViewPaymentMethodsSuccess(success, paging));

          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success, paging);
          }
        },
        (error) => {
          dispatch(getProviderViewPaymentMethodsError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.callbacks.error_list,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().core.list.callbacks.error_list,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError(error);
          }
        }
      );
    },
    getProviderViewInvoices: (
      providerId,
      next = null,
      isModeCompany = false,
      companyId = null,
      callbackSuccess = null,
      callbackError = null
    ) => {
      if (!providerId) return;

      dispatch(getProviderViewInvoices(next));

      fetchApi(
        'get',
        next
          ? next
          : isModeCompany && companyId
          ? `companies/${companyId}/invoices`
          : `providers/${providerId}/invoices`,
        null,
        null,
        null,
        (success, paging) => {
          dispatch(getProviderViewInvoicesSuccess(success, paging));

          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success, paging);
          }
        },
        (error) => {
          dispatch(getProviderViewInvoicesError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.callbacks.error_list,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().core.list.callbacks.error_list,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError(error);
          }
        }
      );
    },
    getProviderViewInvoiceQueue: (
      providerId,
      next = null,
      isModeCompany = false,
      companyId = null,
      callbackSuccess = null,
      callbackError = null
    ) => {
      if (!providerId) return;

      dispatch(getProviderViewInvoiceQueue(next));

      fetchApi(
        'get',
        next
          ? next
          : isModeCompany && companyId
          ? `companies/${companyId}/invoice-queue`
          : `providers/${providerId}/invoice-queue`,
        null,
        null,
        null,
        (success, paging) => {
          dispatch(getProviderViewInvoiceQueueSuccess(success, paging));

          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success, paging);
          }
        },
        (error) => {
          dispatch(getProviderViewInvoiceQueueError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.callbacks.error_list,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().core.list.callbacks.error_list,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError(error);
          }
        }
      );
    },
    getProviderViewAddresses: (
      userId,
      next = null,
      isModeCompany = false,
      companyId = null,
      callbackSuccess = null,
      callbackError = null
    ) => {
      if (!userId) return;

      dispatch(getProviderViewAddresses(next));

      fetchApi(
        'get',
        next
          ? next
          : isModeCompany && companyId
          ? `companies/${companyId}/addresses`
          : `users/${userId}/addresses`,
        null,
        null,
        null,
        (success, paging) => {
          dispatch(getProviderViewAddressesSuccess(success, paging));

          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success, paging);
          }
        },
        (error) => {
          dispatch(getProviderViewAddressesError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.callbacks.error_list,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().core.list.callbacks.error_list,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError(error);
          }
        }
      );
    },
    getProviderViewDevices: (
      userId,
      sourceToken,
      next = null,
      callbackSuccess = null,
      callbackError = null
    ) => {
      if (!userId) return;

      dispatch(getProviderViewDevices(next));

      fetchApi(
        'get',
        next ? next : `users/${userId}/devices`,
        null,
        null,
        null,
        (success, paging) => {
          dispatch(getProviderViewDevicesSuccess(success, paging));

          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success, paging);
          }
        },
        (error) => {
          dispatch(getProviderViewDevicesError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.callbacks.error_list,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().core.list.callbacks.error_list,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError(error);
          }
        },
        null,
        sourceToken
      );
    },
    getProviderViewBlacklist: (
      providerId,
      sourceToken,
      next = null,
      callbackSuccess = null,
      callbackError = null
    ) => {
      if (!providerId) return;

      dispatch(getProviderViewBlacklist(next));

      fetchApi(
        'get',
        next ? next : `providers/${providerId}/blacklist`,
        null,
        null,
        null,
        (success, paging) => {
          dispatch(getProviderViewBlacklistSuccess(success, paging));

          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success, paging);
          }
        },
        (error) => {
          dispatch(getProviderViewBlacklistError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.callbacks.error_list,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().core.list.callbacks.error_list,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError(error);
          }
        },
        null,
        sourceToken
      );
    },
    getPayerId: (userId, callbackSuccess, callbackError) => {
      if (!userId) return;

      fetchApi(
        'get',
        `payers`,
        { filters: `iam_user_id|eq|${userId}` },
        null,
        null,
        (success) => {
          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success);
          }
        },
        (error) => {
          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().views.user_view.payer_bridge.callback_error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().views.user_view.payer_bridge.callback_error,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError();
          }
        }
      );
    },
    getInvoicerId: (userId, callbackSuccess, callbackError) => {
      if (!userId) return;

      fetchApi(
        'get',
        `invoicers`,
        { filters: `iam_user_id|eq|${userId}` },
        null,
        null,
        (success) => {
          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success);
          }
        },
        (error) => {
          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().views.user_view.invoicer_bridge.callback_error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().views.user_view.invoicer_bridge.callback_error,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError();
          }
        }
      );
    },
    getProviderHash: (providerId, callbackSuccess, callbackError) => {
      if (!providerId) {
        return;
      }

      fetchApi(
        'get',
        `providers/${providerId}/hash`,
        null,
        null,
        null,
        (success) => {
          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success);
          }
        },
        (error) => {
          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.dialogs.custom_list_actions.provider_hash.callbacks
                        .error,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message:
                  translation().core.list.dialogs.custom_list_actions.provider_hash.callbacks.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError(error);
          }
        }
      );
    },
    getProviderViewRatingsReceived: (
      providerId,
      sourceToken,
      next = null,
      callbackSuccess = null,
      callbackError = null
    ) => {
      if (!providerId) return;

      dispatch(getProviderViewRatingsReceived(next));

      fetchApi(
        'get',
        next ? next : 'orders/all/products',
        {
          filters: `provider_id|eq|${providerId},provider_rating|gt|0`,
          fields:
            'id,order_id,provider_id,provider_name,provider_rating,provider_rating_issues,provider_rating_comment,client_rating,client_rating_issues,client_rating_comment'
        },
        null,
        null,
        (success, paging) => {
          dispatch(getProviderViewRatingsReceivedSuccess(success, paging));

          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success, paging);
          }
        },
        (error) => {
          dispatch(getProviderViewRatingsReceivedError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.callbacks.error_list,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().core.list.callbacks.error_list,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError(error);
          }
        },
        null,
        sourceToken
      );
    },
    getProviderViewRatingsEmitted: (
      providerId,
      sourceToken,
      next = null,
      callbackSuccess = null,
      callbackError = null
    ) => {
      if (!providerId) return;

      dispatch(getProviderViewRatingsEmitted(next));

      fetchApi(
        'get',
        next ? next : `providers/${providerId}/order-products`,
        {
          filters: `client_rating|gt|0`,
          fields:
            'id,order_id,provider_id,provider_name,client_rating,client_rating_issues,client_rating_comment'
        },
        null,
        null,
        (success, paging) => {
          dispatch(getProviderViewRatingsEmittedSuccess(success, paging));

          if (callbackSuccess && typeof callbackSuccess === 'function') {
            callbackSuccess(success, paging);
          }
        },
        (error) => {
          dispatch(getProviderViewRatingsEmittedError(error));

          if (error.response) {
            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().core.list.callbacks.error_list,
                status: error.response.status || undefined,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().core.list.callbacks.error_list,
                options: {
                  variant: 'error'
                }
              })
            );
          }

          if (callbackError && typeof callbackError === 'function') {
            callbackError(error);
          }
        },
        null,
        sourceToken
      );
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProviderView));
