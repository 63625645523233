import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, IconButton, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { ArrowBack as ArrowBackIcon, Refresh as RefreshIcon } from '@material-ui/icons';

import { isNavigationBackPossible, navigateBack } from '../../../../utils/functions/navigation';
import translation from '../../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  actionsWpr: {
    display: 'flex'
  },
  created: {
    marginLeft: 4
  },
  root: {
    width: '100%'
  },
  grow: {
    flexGrow: 1
  },
  backIcon: {
    padding: 10
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  orderHead: {
    paddingTop: 4,
    paddingBottom: 4
  },
  orderId: {
    fontSize: 16,
    color: theme.palette.default,
    border: '1px solid',
    borderRadius: 4,
    padding: '2px 4px'
  }
}));

function OrdersUpdateViewBar(props) {
  const { created, isLoading, handleRefresh, history, orderId, reference, updated } = props;

  const classes = useStyles();

  function handleHistoryBack() {
    navigateBack(history);
  }

  return (
    <div className={classes.root}>
      <AppBar elevation={0} position="static" color="default">
        <Toolbar>
          <div className={classes.orderHead}>
            <Typography variant="body2" color="textPrimary">
              {translation().views.orders.update.page_title}
              <Typography component="span" variant="subtitle2" className={classes.orderId}>
                {orderId}
              </Typography>
              {created ? (
                <Typography className={classes.created} component="span" variant="body2">
                  {translation().views.orders.update.page_subtitle} {created}.{' '}
                  {updated && (
                    <>
                      {translation().views.orders.update.updated} {updated}.
                    </>
                  )}
                </Typography>
              ) : (
                false
              )}
            </Typography>
            {reference && (
              <Typography variant="body2" color="textPrimary">
                Ref: {reference}
              </Typography>
            )}
          </div>
          <div className={classes.grow} />
          <div className={classes.actionsWpr}>
            {isNavigationBackPossible(history) && (
              <Tooltip
                title={translation().actions.back || 'Back'}
                aria-label={translation().actions.back || 'Back'}
                enterDelay={700}>
                <div>
                  <IconButton
                    color="inherit"
                    aria-label="Back to orders"
                    onClick={handleHistoryBack}
                    className={classes.backIcon}
                    disabled={isLoading}>
                    <ArrowBackIcon fontSize="small" />
                  </IconButton>
                </div>
              </Tooltip>
            )}
            <div>
              <IconButton
                color="inherit"
                aria-label="Refresh order"
                disabled={isLoading}
                style={{ marginLeft: 7 }}
                onClick={handleRefresh}
                className={classes.backIcon}>
                <RefreshIcon fontSize="small" />
              </IconButton>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

OrdersUpdateViewBar.propTypes = {
  created: PropTypes.string,
  isLoading: PropTypes.bool,
  handleRefresh: PropTypes.func.isRequired,
  history: PropTypes.shape().isRequired,
  orderId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  reference: PropTypes.string,
  updated: PropTypes.string
};

export default OrdersUpdateViewBar;
