import React from 'react';

function BehaviourIcon(props) {
  return (
    <svg
      version="1.1"
      id="BehaviourIcon"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="100"
      viewBox="0 0 512 512"
      style={{ enableBackground: 'new 0 0 512 512' }}
      {...props}>
      <path
        id="Shape"
        fill="#FDD7AD"
        d="M510,88.3c-4.2-18.5-17.2-52-55-52c-61.7,0-61.7,77.6-61.7,91.3c-1.4,31.2,8.3,62,27.4,86.7
        c9.3,13.3,14.1,29.3,13.7,45.6v9.1c0,17.6,14.3,31.9,31.9,31.9s31.9-14.3,31.9-31.9c0-8.9,2.9-17.5,8.2-24.6
        c3.6-4.7,5.5-10.5,5.5-16.4C511.9,96.5,512.6,100.2,510,88.3z"
      />
      <path
        fill="#F9C795"
        d="M147.5,397.8c0,44.8,0.1,40.1-0.3,41.1c-8.5,22.6-60.6,22.2-68.9,0c-0.4-1.1-0.3,3.8-0.3-41.1
        c11.8,12,12.9,23.1,26.6,23.1h16.1C134.6,420.9,135.7,409.8,147.5,397.8L147.5,397.8z"
      />
      <path
        fill="#FDD7AD"
        d="M182.2,347.1c0,13.9-10.1,23.4-20.1,21.4c-0.3,11.4-5.6,22.1-14.6,29.2c-11.6,12.1-12.9,23.1-26.6,23.1h-16.1
        c-15,0-16.5-14.8-29.9-26.2c-7-6.9-11.1-16.3-11.4-26.1c-17.2,3.5-27-23.1-14.5-37.5c2.9-3.5,7.2-5.6,11.7-5.6
        c0.3-8.5,3.2-16.7,8.2-23.5c4.4-6,7.3-9.9,14.5-10.4c12.9-0.9,45.9-0.9,58.8,0c7.2,0.6,10,4.3,14.5,10.4c5,6.9,7.8,15,8.2,23.5
        C175.6,326.8,183.3,336.4,182.2,347.1L182.2,347.1z"
      />
      <path
        fill="#805333"
        d="M177,331.7c-2.9-3.8-7.4-6.1-12.1-6.2c-0.3-8.5-3.2-16.7-8.2-23.5c-4.4-6-7.3-9.9-14.5-10.4
        c-12.9-0.9-45.9-0.9-58.8,0c-7.2,0.6-10,4.3-14.5,10.4c-5,6.9-7.8,15-8.2,23.5c-4.5,0.1-8.8,2.1-11.7,5.6
        c-7.9-19.1-10.9-60.6,14.8-63c1.6-0.2,3-1.2,3.7-2.7c2.8-5.6,12.5-18.1,44.7-18.1c0,0,55.6,0,65.8,30.9
        C183.6,295.7,183.2,314.4,177,331.7L177,331.7z"
      />
      <path
        fill="#285680"
        d="M225.5,489.6v0.2c0.3,9.1-6.8,16.8-16,17.2H16c-4.4-0.2-8.6-2.1-11.6-5.4c-3-3.3-4.6-7.6-4.4-12
        c0.7-28.9,16.1-33.1,16.1-33.1l62-18.2c7.8,22.2,61,22.6,68.9,0l62,18.2C209.1,456.5,224,460.7,225.5,489.6z"
      />
      <path
        fill="#4482C3"
        d="M208.1,487.6c0,10.7-6,19.4-13.5,19.4H30.9c-7.5,0-13.5-8.7-13.5-19.4c-0.6-12.2,2.8-24.3,9.7-34.4l51.1-15
        c7.8,22.2,61,22.6,68.9,0l51.1,15C205.3,463.3,208.8,475.4,208.1,487.6L208.1,487.6z"
      />
      <path
        id="Rectangle-path"
        fill="#E64C3C"
        d="M17.4,4.3h208.3c9.6,0,17.4,7.8,17.4,17.4v138.8c0,9.6-7.8,17.4-17.4,17.4H17.4
        c-9.6,0-17.4-7.8-17.4-17.4V21.7C0,12.1,7.8,4.3,17.4,4.3z"
      />
      <path
        fill="#C03A2B"
        d="M156.2,177.9l-31.4,33.3c-0.9,0.9-2.1,1.4-3.3,1.4s-2.4-0.5-3.3-1.4l-31.4-33.3H156.2z"
      />
      <g>
        <path
          fill="#FFFFFF"
          d="M156.2,143.2c-3.7,0-7.1-2.4-8.2-5.9c-3.9-11.5-14.4-19.4-26.5-20.1c-12.1,0.7-22.6,8.7-26.5,20.1
          c-1,2.9-3.5,5.1-6.5,5.8s-6.2-0.4-8.2-2.8c-2.1-2.3-2.7-5.6-1.7-8.5c0.5-1.3,11.1-32,43-32s42.5,30.7,42.9,32
          c0.9,2.6,0.4,5.6-1.2,7.8C161.6,141.8,159,143.2,156.2,143.2L156.2,143.2z"
        />
        <circle id="Oval" fill="#FFFFFF" cx="86.8" cy="65.1" r="17.4" />
        <circle fill="#FFFFFF" cx="156.2" cy="65.1" r="17.4" />
      </g>
      <path
        fill="#CC6F56"
        d="M478.8,231.4L478.8,231.4c-5,2.5-11,0.6-13.7-4.2l-8.5-18.2c-1.8-3.7-4.8-6.7-8.5-8.5v-22.8
        c0-15.1,12.3-27.4,27.4-27.4h2.8c-0.1,4.6,0.5,9.3,1.7,13.7c4.2,16.8-11.8,21.4,3.5,53.4C485.9,222.7,483.9,228.8,478.8,231.4
        L478.8,231.4z"
      />
      <path
        fill="#F9C795"
        d="M493.7,106.2v18.7c0.1,3.6-2.1,6.9-5.4,8.3c-4.3,1.7-9.8,6.1-10,17.1h-2.8c-15.1,0-27.4,12.3-27.4,27.4v22.8
        c-5.8-1.9-11.2-5.1-15.7-9.3c-7-6.7-12-15.1-14.5-24.4c-8.1-28.1-9-51.9,0.6-79.8c10.1-29.2,25.6-32.5,36.5-32.5
        c18.9,0,31.4,12.7,37.2,37.8C493.2,96.9,493.7,101.6,493.7,106.2z"
      />
    </svg>
  );
}

export default BehaviourIcon;
