import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Breadcrumbs, Toolbar, Typography } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import translation from '../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  grow: {
    flexGrow: 1
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
}));

function ProductionViewBar() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar elevation={0} position="static" color="default">
        <Toolbar>
          <Breadcrumbs
            maxItems={5}
            separator={<NavigateNextIcon fontSize="small" />}
            arial-label="Production view breadcrumb">
            <Typography variant="body2" color="textPrimary">
              {translation().views.production.title}
            </Typography>
          </Breadcrumbs>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop} />
          <div className={classes.sectionMobile} />
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default ProductionViewBar;
