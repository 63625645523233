import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import IconButton from '@material-ui/core/IconButton';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';

import { generateHash } from '../../../../utils/functions/utils';
import { setMediaTypeProperty } from '../../../../utils/functions/redux';

import FormApiAutocompleteField from '../../../../components/forms/fields/FormApiAutocompleteField';

import config from '../../../../config';
import translation from '../../../../translation/translation';

function DownloadIcon(props) {
  const { path, onClick } = props;

  function handleClick() {
    if (path) {
      onClick(path);
    }
  }

  return (
    <IconButton size="small" aria-label="Download" onClick={handleClick}>
      <CloudDownloadIcon fontSize="small" />
    </IconButton>
  );
}

DownloadIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
  path: PropTypes.any.isRequired
};

function FileCard(props) {
  const { file, mediasErrors, onDeleteFile, onOpenSlider, sliderIndex } = props;

  let imgPreviewStyle = null;

  if (file.thumbnail_url) {
    imgPreviewStyle = {
      backgroundImage:
        'url("' +
        (file.thumbnail_url.indexOf(`client_id=${config.api_key}`) !== -1
          ? file.thumbnail_url
          : `${file.thumbnail_url}?client_id=${config.api_key}`) +
        '")'
    };
  } else {
    imgPreviewStyle = {
      backgroundSize: 'inherit',
      backgroundImage: `url("/assets/images/medias/files/large/file-extension-${file.extension}.png")`
    };
  }

  function handleDeleteFile() {
    if (file && onDeleteFile) {
      onDeleteFile(file);
    }
  }

  function handleSelectValue(value) {
    if (value && typeof value === 'object') {
      setMediaTypeProperty(file.url, file.media_type.shortcode, 'media_template_id', value.id);
    } else {
      setMediaTypeProperty(file.url, file.media_type.shortcode, 'media_template_id', 'null');
    }
  }

  function handleDownloadMedia(url) {
    if (!url) return;

    const downloadIframe = document.getElementById('frame-download');
    const iframesContainer = document.getElementById('iframes-container');

    /**
     * If iframe already exists we only change url, if not we create it
     */
    if (downloadIframe) {
      downloadIframe.setAttribute('src', `${url}/download?client_id=${config.api_key}`);
    } else {
      const downloadIframe = `<iframe id="frame-download" title="iframe-download" width="0" height="0" style="display: none" src="${url}/download?client_id=${config.api_key}"/>`;
      iframesContainer.innerHTML = downloadIframe;
    }
  }

  function handleOpenSlider() {
    if (onOpenSlider) {
      onOpenSlider(sliderIndex);
    }
  }

  return (
    <div
      className={classNames('MuiPaper-root', 'file-preview', {
        [`${file.extension}`]: file.extension
      })}>
      <div className="cover-actions">
        <DownloadIcon path={file.url} onClick={handleDownloadMedia} />
        <IconButton
          size="small"
          aria-label="Open out"
          style={{ marginLeft: 4 }}
          href={`${file.url}?client_id=${config.api_key}`}
          target="_blank">
          <OpenInNewIcon fontSize="small" />
        </IconButton>
        {onOpenSlider && (
          <IconButton
            size="small"
            aria-label="Zoom"
            style={{ marginLeft: 4 }}
            onClick={handleOpenSlider}>
            <ZoomOutMapIcon fontSize="small" />
          </IconButton>
        )}
        <IconButton
          size="small"
          aria-label="Delete"
          onClick={handleDeleteFile}
          style={{ marginLeft: 'auto' }}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      </div>
      <div className="img-preview" style={imgPreviewStyle} />
      {file.media_type.has_templates && (
        <div className="file-input-template">
          <FormApiAutocompleteField
            id={`template-file-${generateHash(file.url)}`}
            name={`template-file-${generateHash(file.url)}`}
            defaultValue={
              file.media_template && file.media_template.name && file.media_template.id
                ? {
                    name: file.media_template.name,
                    id: file.media_template.id
                  }
                : null
            }
            fullWidth
            links={[
              {
                rel: 'list',
                href: `media-types/${file.media_type.id}/templates`
              }
            ]}
            onSelectValue={handleSelectValue}
            placeholder={translation().views.orders.media_groups.template}
            getFullObject
            margin="dense"
            targetKey="id"
            error={mediasErrors && mediasErrors[file.url] ? mediasErrors[file.url] : ''}
          />
        </div>
      )}
    </div>
  );
}

FileCard.propTypes = {
  file: PropTypes.shape({
    extension: PropTypes.string,
    media_type: PropTypes.shape(),
    url: PropTypes.string,
    thumbnail_url: PropTypes.string,
    media_template: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string
    })
  }).isRequired,
  mediasErrors: PropTypes.shape(),
  onDeleteFile: PropTypes.func.isRequired,
  onOpenSlider: PropTypes.func.isRequired,
  sliderIndex: PropTypes.any
};

export default FileCard;
