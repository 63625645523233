import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { fetchApi } from '../../utils/functions/api';
import { getLocale, getTimezone } from '../../utils/functions/cookies';
import { formatErrors } from '../../utils/functions/forms';
import { enqueueSnackbar } from '../../redux/actions/appActions';
import {
  storeUser,
  updateUserSelf,
  updateUserSelfSuccess,
  updateUserSelfError,
  updateUserSelfErrors
} from '../../redux/actions/user/userActions';
import Profile from '../../views/profile/Profile';
import translation from '../../translation/translation';

const mapStateToProps = (store) => ({
  profile: store.views.profile,
  user: store.user
});

const mapDispatchToProps = (dispatch) => {
  return {
    setProfileErrors(errors) {
      dispatch(updateUserSelfErrors(errors));
    },
    updateUserPassword(values, onSuccess = null) {
      dispatch(updateUserSelf());

      fetchApi(
        'put',
        'users/self/password',
        null,
        values,
        null,
        () => {
          dispatch(updateUserSelfSuccess());
          dispatch(
            enqueueSnackbar({
              message: translation().views.profile.form.callbacks.success,
              options: {
                variant: 'success'
              }
            })
          );

          if (onSuccess) {
            onSuccess();
          }
        },
        (error) => {
          dispatch(updateUserSelfError(true));

          if (error.response) {
            if (
              error.response.data &&
              error.response.data.errors &&
              error.response.data.errors.length > 0
            ) {
              dispatch(updateUserSelfErrors(formatErrors(error.response.data.errors)));
            }

            dispatch(
              enqueueSnackbar({
                message:
                  error.response.data && error.response.data.detail
                    ? error.response.data.detail
                    : translation().views.profile.form.callbacks.error,
                options: {
                  variant: 'error'
                }
              })
            );
          } else {
            dispatch(
              enqueueSnackbar({
                message: translation().views.profile.form.callbacks.error,
                options: {
                  variant: 'error'
                }
              })
            );
          }
        }
      );
    },
    async updateUser(values) {
      const userLocale = getLocale();
      const userTimezone = getTimezone();

      dispatch(updateUserSelf());

      try {
        await fetchApi(
          'put',
          'users/self',
          null,
          values,
          null,
          (user) => {
            dispatch(storeUser(user));
            dispatch(updateUserSelfSuccess());
            dispatch(
              enqueueSnackbar({
                message: translation().views.profile.form.callbacks.success,
                options: {
                  variant: 'success'
                }
              })
            );

            if (values.language_locale && values.language_locale !== userLocale)
              window.location.reload();

            if (values.timezone_code && values.timezone_code !== userTimezone)
              window.location.reload();
          },
          (error) => {
            dispatch(updateUserSelfError(true));

            if (error.response) {
              if (
                error.response.data &&
                error.response.data.errors &&
                error.response.data.errors.length > 0
              ) {
                dispatch(updateUserSelfErrors(formatErrors(error.response.data.errors)));
              }

              dispatch(
                enqueueSnackbar({
                  message:
                    error.response.data && error.response.data.detail
                      ? error.response.data.detail
                      : translation().views.profile.form.callbacks.error,
                  options: {
                    variant: 'error'
                  }
                })
              );
            } else {
              dispatch(
                enqueueSnackbar({
                  message: translation().views.profile.form.callbacks.error,
                  options: {
                    variant: 'error'
                  }
                })
              );
            }
          }
        );
      } catch (error) {
        dispatch(updateUserSelfError(true));
        dispatch(
          enqueueSnackbar({
            message: translation().views.profile.form.callbacks.error,
            options: {
              variant: 'error'
            }
          })
        );
      }
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile));
