import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import LanguageIcon from '@material-ui/icons/Language';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import TodayIcon from '@material-ui/icons/Today';
import Tooltip from '@material-ui/core/Tooltip';

import translation from '../../../../../translation/translation';

function ScheduleLegendPopper() {
  const [anchorEl, setAnchorEl] = useState(null);

  function handleClick(event) {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  }

  function handleClickAway() {
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);
  const id = open ? 'legend' : undefined;

  return (
    <div className="btn-menu">
      <Tooltip
        title={translation().views.provider_schedule.calendar.legend.title}
        placement="bottom">
        <IconButton onClick={handleClick} size="small">
          <InfoOutlinedIcon
            className={classNames('btn-menu-icon', { active: open })}
            aria-describedby={id}
          />
        </IconButton>
      </Tooltip>
      <Popper id={id} anchorEl={anchorEl} open={open} disablePortal>
        <Paper className="legends">
          <ClickAwayListener onClickAway={handleClickAway}>
            <ul>
              <li className="legend">
                <p>
                  <span className="square-color archived" />{' '}
                  {translation().views.provider_schedule.calendar.legend.archived}
                </p>
              </li>
              <li className="legend">
                <p>
                  <span className="square-color inc" />{' '}
                  {translation().views.provider_schedule.calendar.legend.planned}
                </p>
              </li>
              <li className="legend">
                <p>
                  <span className="square-color todo" />{' '}
                  {translation().views.provider_schedule.calendar.legend.todo}
                </p>
              </li>
              <li className="legend">
                <p>
                  <span className="square-color availability" />{' '}
                  {translation().views.provider_schedule.calendar.legend.availability}
                </p>
              </li>
              <li className="legend">
                <p>
                  <span className="square-color unavailability" />{' '}
                  {translation().views.provider_schedule.calendar.legend.unavailability}
                </p>
              </li>
              <li className="legend">
                <p>
                  <span className="square-color cart" />{' '}
                  {translation().views.provider_schedule.calendar.legend.cart}
                </p>
              </li>
            </ul>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </div>
  );
}

class ScheduleToolbar extends Component {
  constructor(props) {
    super(props);

    this.handleChangeViewToDay = this.handleChangeViewToDay.bind(this);
    this.handleChangeViewToMonth = this.handleChangeViewToMonth.bind(this);
    this.handleChangeViewToWeek = this.handleChangeViewToWeek.bind(this);
    this.handleNavigatePrev = this.handleNavigatePrev.bind(this);
    this.handleNavigateNext = this.handleNavigateNext.bind(this);
    this.handleNavigateToday = this.handleNavigateToday.bind(this);
    this.handleSelectUserTimezone = this.handleSelectUserTimezone.bind(this);
    this.handleUnselectUserTimezone = this.handleUnselectUserTimezone.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { getProviderInfos, providerId } = this.props;

    getProviderInfos(providerId);
  }

  handleSelectUserTimezone() {
    const { providerInfos, onSelectUserTimezone } = this.props;

    if (
      onSelectUserTimezone &&
      providerInfos &&
      providerInfos.data &&
      providerInfos.data.user &&
      providerInfos.data.user.timezone_code
    ) {
      onSelectUserTimezone(providerInfos.data.user.timezone_code);
    }
  }

  handleUnselectUserTimezone() {
    const { onUnselectUserTimezone } = this.props;

    if (onUnselectUserTimezone) {
      onUnselectUserTimezone();
    }
  }

  handleNavigatePrev() {
    this.props.onNavigate('PREV');
  }

  handleNavigateNext() {
    this.props.onNavigate('NEXT');
  }

  handleNavigateToday() {
    this.props.onNavigate('TODAY');
  }

  handleChangeViewToDay() {
    this.props.onView('day');
  }

  handleChangeViewToMonth() {
    this.props.onView('month');
  }

  handleChangeViewToWeek() {
    this.props.onView('week');
  }

  render() {
    const { view, label, messages, providerInfos, timezone } = this.props;

    return (
      <Grid container className="schedule-toolbar">
        <Grid item sm={12} md={4} className="col-toolbar col-nav-desktop">
          <Fab
            size="small"
            color="default"
            aria-label="Prev"
            className="btn-view"
            onClick={this.handleNavigatePrev}>
            <ChevronLeftIcon className="btn-prev" />
          </Fab>
          <Fab
            size="small"
            color="default"
            aria-label="Next"
            className="btn-view"
            onClick={this.handleNavigateNext}>
            <ChevronRightIcon className="btn-next" />
          </Fab>
          <Button
            color="default"
            variant="contained"
            size="small"
            aria-label="Today"
            className="btn-view"
            onClick={this.handleNavigateToday}>
            {messages.today}
          </Button>
          <ScheduleLegendPopper />
          <div className="btn-menu">
            {providerInfos.loading ? (
              <CircularProgress size={25} />
            ) : (
              <>
                {timezone ? (
                  <Tooltip
                    title={translation().views.provider_schedule.calendar.timezone.cancel}
                    placement="bottom">
                    <IconButton onClick={this.handleUnselectUserTimezone} size="small">
                      <LanguageIcon className="btn-menu-icon active" />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={translation().views.provider_schedule.calendar.timezone.title}
                    placement="bottom">
                    <IconButton onClick={this.handleSelectUserTimezone} size="small">
                      <LanguageIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            )}
          </div>
        </Grid>
        <Grid item sm={12} md={3} className="col-toolbar col-nav-mobile">
          <Fab
            size="small"
            color="default"
            aria-label="Prev"
            className="btn-view"
            onClick={this.handleNavigatePrev}>
            <ChevronLeftIcon className="btn-prev" />
          </Fab>
          <Button
            color="default"
            variant="contained"
            size="small"
            aria-label="Today"
            className="btn-view btn-today"
            onClick={this.handleNavigateToday}>
            <TodayIcon fontSize="small" />
          </Button>
          <Fab
            size="small"
            color="default"
            aria-label="Next"
            className="btn-view"
            onClick={this.handleNavigateNext}>
            <ChevronRightIcon className="btn-next" />
          </Fab>
          <ScheduleLegendPopper />
          <div className="btn-menu">
            {providerInfos.loading ? (
              <CircularProgress size={25} />
            ) : (
              <>
                {timezone ? (
                  <Tooltip
                    title={translation().views.provider_schedule.calendar.timezone.cancel}
                    placement="bottom">
                    <IconButton onClick={this.handleUnselectUserTimezone} size="small">
                      <LanguageIcon className="btn-menu-icon active" />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={translation().views.provider_schedule.calendar.timezone.title}
                    placement="bottom">
                    <IconButton onClick={this.handleSelectUserTimezone} size="small">
                      <LanguageIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            )}
          </div>
        </Grid>
        <Grid item sm={12} md={4} className="col-toolbar">
          <div className="toolbar-flex">
            <p className="toolbar-label">
              {label} {timezone ? `(${timezone})` : ''}
            </p>
          </div>
        </Grid>
        <Grid item sm={12} md={4} className="col-toolbar align-right">
          <Button
            color={view && view === 'month' ? 'primary' : 'default'}
            variant="contained"
            size="small"
            aria-label="month"
            className="btn-view"
            onClick={this.handleChangeViewToMonth}>
            {messages.month}
          </Button>
          <Button
            color={view && view === 'week' ? 'primary' : 'default'}
            variant="contained"
            size="small"
            aria-label="week"
            className="btn-view"
            onClick={this.handleChangeViewToWeek}>
            {messages.week}
          </Button>
          <Button
            color={view && view === 'day' ? 'primary' : 'default'}
            variant="contained"
            size="small"
            aria-label="day"
            className="btn-view"
            onClick={this.handleChangeViewToDay}>
            {messages.day}
          </Button>
        </Grid>
      </Grid>
    );
  }
}

ScheduleToolbar.propTypes = {
  getProviderInfos: PropTypes.func.isRequired,
  label: PropTypes.any.isRequired,
  messages: PropTypes.shape({
    previous: PropTypes.string,
    next: PropTypes.string,
    today: PropTypes.string,
    month: PropTypes.string,
    week: PropTypes.string,
    day: PropTypes.string,
    agenda: PropTypes.string,
    date: PropTypes.string,
    time: PropTypes.string
  }).isRequired,
  timezone: PropTypes.string,
  onNavigate: PropTypes.func.isRequired,
  onSelectUserTimezone: PropTypes.func,
  onUnselectUserTimezone: PropTypes.func,
  onView: PropTypes.func.isRequired,
  providerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  providerInfos: PropTypes.shape().isRequired,
  view: PropTypes.string.isRequired
};

export default ScheduleToolbar;
