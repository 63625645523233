import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import FormDateTimeField from '../../../components/forms/fields/FormDateTimeField';
import { createDate, createDateUtc, formatDate } from '../../../utils/functions/dates';
import translation from '../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      minWidth: 300
    }
  },
  gutterBottom: {
    margin: 4,
    marginTop: 15
  },
  dateWrapper: {
    width: '100%',
    marginBottom: theme.spacing(4)
  },
  formControl: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  triggerDateForm: {
    position: 'absolute',
    alignItems: 'center',
    display: 'flex',
    top: 8,
    right: 12,
    [theme.breakpoints.down('xs')]: {
      position: 'relative',
      top: 0,
      right: 'inherit',
      marginBottom: 20
    }
  },
  listRoot: {
    listStyleType: 'none',
    padding: 0
  },
  listItem: {
    padding: 0
  },
  currencyLabel: {
    marginRight: 8,
    fontSize: 16
  },
  currencyValue: {
    fontSize: 16
  },
  submitButton: {
    marginTop: 24,
    marginBottom: 24
  }
}));

function PayerBalance(props) {
  const classes = useStyles();
  const {
    closeDialogFunction,
    getBalance,
    getPaymentMethodsUsable,
    payWithdraw,
    resource,
    reloadListFunction
  } = props;

  const [isLoading, setLoading] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isMethodsLoading, setMethodsLoading] = useState(false);
  const [isWithdrawLoading, setWithdrawLoading] = useState(false);
  const [balances, setBalances] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [methods, setMethods] = useState([]);

  const [isDateFormOpen, setDateFormOpen] = useState(false);
  const [dateValue, setDateValue] = useState('');
  const [currencyValue, setCurrencyValue] = useState('');
  const [amountValue, setAmountValue] = useState('');
  const [methodValue, setMethodValue] = useState('');
  const [passwordValue, setPasswordValue] = useState('');

  function getPayerPaymentMethods(currency) {
    setMethodsLoading(true);
    getPaymentMethodsUsable(
      resource.id,
      currency ? { currency } : {},
      (paymentMethods) => {
        setMethods(paymentMethods);
        setMethodsLoading(false);
      },
      () => {
        setMethodsLoading(false);
      }
    );
  }

  function getPayerBalance(date) {
    setLoading(true);
    getBalance(
      resource.id,
      date ? { asof: date } : {},
      (balances) => {
        setBalances(balances);
        const currencies = [];

        if (balances && balances.length > 0) {
          for (let i = 0; i < balances.length; i++) {
            if (balances[i].currency_isocode) {
              currencies.push({
                label: balances[i].currency_isocode,
                value: balances[i].currency_isocode
              });
              continue;
            }
          }
        }

        setCurrencies(currencies);
        setLoading(false);

        setCurrencyValue(currencies && currencies[0] ? currencies[0].value : '');
        getPayerPaymentMethods(currencies && currencies[0] ? currencies[0].value : null);
      },
      () => {
        setLoading(false);
      }
    );
  }

  useEffect(() => {
    if (resource && resource.id) {
      getPayerBalance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleChangeDate(value) {
    setDateValue(createDate(value));
  }

  function handleClearDate() {
    setDateValue('');
  }

  function handleSubmitDate() {
    if (dateValue) {
      const dateUtc = formatDate(createDateUtc(dateValue), 'YYYY-MM-DDTHH:mm:ss');
      getPayerBalance(dateUtc);
    } else {
      getPayerBalance();
    }
  }

  function handleToggleDateForm() {
    setDateFormOpen((isDateFormOpen) => !isDateFormOpen);
  }

  function handleChangeCurrency(event) {
    setMethodValue('');
    setCurrencyValue(event.target.value);
    getPayerPaymentMethods(event.target.value);
  }

  function handleChangeAmount(event) {
    setAmountValue(event.target.value);
  }

  function handleChangeMethod(event) {
    setMethodValue(event.target.value);
  }

  function handleChangePassword(event) {
    setPasswordValue(event.target.value);
  }

  function handleOpenDialog() {
    setDialogOpen(true);
  }

  function handleCloseDialog() {
    setDialogOpen(false);
  }

  function handleSubmitWithdraw() {
    if (currencyValue && amountValue && methodValue && passwordValue && resource && resource.id) {
      setWithdrawLoading(true);

      const values = {
        currency_isocode: currencyValue,
        payment_method: methodValue,
        amount: amountValue,
        password: passwordValue
      };

      payWithdraw(
        resource.id,
        values,
        () => {
          setWithdrawLoading(false);
          setDialogOpen(false);
          closeDialogFunction();
          reloadListFunction();
        },
        () => {
          setWithdrawLoading(false);
          setDialogOpen(false);
        }
      );
    }
  }

  return (
    <div className="PayerBalance">
      <div className={classes.triggerDateForm}>
        <Typography>
          {translation().core.list.dialogs.custom_list_actions.pay_withdrawal.form.trigger_date}
        </Typography>
        <IconButton onClick={handleToggleDateForm} style={{ marginLeft: 4 }} aria-label="open">
          {isDateFormOpen ? (
            <KeyboardArrowUpIcon fontSize="small" />
          ) : (
            <KeyboardArrowDownIcon fontSize="small" />
          )}
        </IconButton>
      </div>
      {isDateFormOpen && (
        <div className={classes.dateWrapper}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={12} sm={8} md={9}>
              <FormDateTimeField
                name="date"
                label={
                  translation().core.list.dialogs.custom_list_actions.pay_withdrawal.form.labels
                    .date
                }
                onChange={handleChangeDate}
                value={dateValue}
                disabled={isLoading}
                margin="none"
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <IconButton
                onClick={handleClearDate}
                aria-label="clear"
                className={classes.gutterBottom}
                disabled={isLoading}>
                <CloseIcon fontSize="small" />
              </IconButton>
              <Button
                onClick={handleSubmitDate}
                color="primary"
                variant="contained"
                className={classes.gutterBottom}
                disabled={isLoading}>
                {translation().actions.confirm}
              </Button>
            </Grid>
          </Grid>
        </div>
      )}
      <div className={classes.root}>
        {!isLoading && balances && balances.length > 0 ? (
          <div>
            <Typography gutterBottom variant="h6">
              {translation().core.list.dialogs.custom_list_actions.pay_withdrawal.wallet}:
            </Typography>
            <ul className={classes.listRoot}>
              {balances.map((balance, index) => {
                return (
                  <li key={index} className={classes.listItem}>
                    <Typography className={classes.currencyLabel} component="span">
                      {balance.currency_isocode ? balance.currency_isocode : 'No currency found'}:
                    </Typography>
                    <Typography variant="button" component="span" className={classes.currencyValue}>
                      {balance.amount_formatted}
                    </Typography>
                  </li>
                );
              })}
            </ul>
            <div className="sm-space" />
            <Typography gutterBottom variant="h6">
              {translation().core.list.dialogs.custom_list_actions.pay_withdrawal.form.title}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  label={
                    translation().core.list.dialogs.custom_list_actions.pay_withdrawal.form.labels
                      .amount
                  }
                  type="number"
                  fullWidth
                  onChange={handleChangeAmount}
                  value={amountValue}
                  disabled={isWithdrawLoading}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="invoice-payment-method">
                    {
                      translation().core.list.dialogs.custom_list_actions.pay_withdrawal.form.labels
                        .currency
                    }
                  </InputLabel>
                  <Select
                    labelId="currencies"
                    id="currencies-field"
                    name="currencies-field"
                    disabled={isWithdrawLoading}
                    value={currencyValue}
                    onChange={handleChangeCurrency}
                    fullWidth
                    displayEmpty>
                    {currencies && currencies.length > 0 ? (
                      currencies.map((currency, index) => (
                        <MenuItem key={index} value={currency.value}>
                          {currency.label}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem />
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={5}>
                {!isMethodsLoading && (
                  <FormControl className={classes.formControl}>
                    <InputLabel id="invoice-payment-method">
                      {
                        translation().core.list.dialogs.custom_list_actions.pay_withdrawal.form
                          .labels.methods
                      }
                    </InputLabel>
                    <Select
                      labelId="balance-payment-method"
                      id="balance-payment-method-field"
                      name="balance-payment-method-field"
                      disabled={isWithdrawLoading}
                      onChange={handleChangeMethod}
                      value={methodValue}
                      fullWidth>
                      {methods.length > 0 ? (
                        methods.map((method, index) => (
                          <MenuItem
                            key={index}
                            value={method.id}>{`${method.type} ${method.name}`}</MenuItem>
                        ))
                      ) : (
                        <MenuItem />
                      )}
                    </Select>
                  </FormControl>
                )}
                {isMethodsLoading && (
                  <div className="loader-wpr">
                    <CircularProgress color="primary" size={25} />
                    <p>
                      {
                        translation().core.list.dialogs.custom_list_actions.pay_withdrawal
                          .load_payment_method
                      }
                    </p>
                  </div>
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label={
                    translation().core.list.dialogs.custom_list_actions.pay_withdrawal.form.labels
                      .password
                  }
                  fullWidth
                  type="password"
                  onChange={handleChangePassword}
                  value={passwordValue}
                  disabled={isWithdrawLoading}
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12} sm={12} style={{ textAlign: 'right' }}>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.submitButton}
                  disabled={
                    !amountValue ||
                    !currencyValue ||
                    !methodValue ||
                    !passwordValue ||
                    isWithdrawLoading
                  }
                  onClick={handleOpenDialog}>
                  {translation().core.list.dialogs.custom_list_actions.pay_withdrawal.form.button}
                </Button>
              </Grid>
            </Grid>
          </div>
        ) : !isLoading ? (
          translation().core.list.dialogs.custom_list_actions.pay_withdrawal.no_wallet
        ) : (
          false
        )}
        {isLoading && (
          <div className="loader-wpr">
            <CircularProgress color="primary" size={25} />
            <p>
              {translation().core.list.dialogs.custom_list_actions.pay_withdrawal.loading_balance}
            </p>
          </div>
        )}
      </div>
      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alrt-confirm-withdraw"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alrt-confirm-withdraw">
          {translation().core.list.dialogs.custom_list_actions.pay_withdrawal.alert_dialog.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {
              translation().core.list.dialogs.custom_list_actions.pay_withdrawal.alert_dialog
                .content
            }
          </DialogContentText>
          {isWithdrawLoading && (
            <div className="loader-wpr">
              <CircularProgress color="primary" size={25} />
              <p>
                {
                  translation().core.list.dialogs.custom_list_actions.pay_withdrawal
                    .loading_withdrawal
                }
              </p>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="default" disabled={isWithdrawLoading}>
            {translation().actions.close}
          </Button>
          <Button
            onClick={handleSubmitWithdraw}
            color="primary"
            variant="contained"
            disabled={isWithdrawLoading}>
            {translation().actions.confirm}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

PayerBalance.propTypes = {
  closeDialogFunction: PropTypes.func.isRequired,
  getBalance: PropTypes.func.isRequired,
  getPaymentMethodsUsable: PropTypes.func.isRequired,
  payWithdraw: PropTypes.func.isRequired,
  resource: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }),
  reloadListFunction: PropTypes.func.isRequired
};

export default PayerBalance;
