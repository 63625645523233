/**
 * Node modules imports
 */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Bowser from 'bowser';
import { BrowserRouter as Router } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
/**
 * Routes
 */
import Routes from './routes/Routes';
/**
 * Views
 */
import Authentication from './views/authentication/Authentication';
import RestrictedArea from './views/restrictedArea/RestrictedArea';
import UserChromeBrowser from './views/browser/useChromeBrowser/UseChromeBrowser';
/**
 * Components
 */
import Header from './components/header/Header';
import Notifier from './components/notifications/Notifier';
import ActarusLoader from './components/loaders/ActarusLoader';
/**
 * Functions
 */
import { getAccessToken, getNightTheme } from './utils/functions/cookies';
import muiThemeLight from './utils/themes/muiThemeLight';
import muiThemeDark from './utils/themes/muiThemeDark';
import config from './config';

function App(props) {
  const { getUser, reduxStore, removeSnackbar, setThemeNightMode, signOut } = props;

  const isDarkTheme =
    reduxStore.app.theme.isDarkMode === true || reduxStore.app.theme.isDarkMode === 'true'
      ? true
      : false;
  const browser = Bowser.getParser(window.navigator.userAgent);
  const isValidBrowser = browser.satisfies(config.validBrowsers);

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isNightTheme = getNightTheme();
    setThemeNightMode(isNightTheme);

    const body = document.body;

    if (body) {
      if (isNightTheme === true || isNightTheme === 'true') {
        body.classList.remove('theme-light');
        body.classList.add('theme-night');
      } else {
        body.classList.remove('theme-night');
        body.classList.add('theme-light');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDarkTheme]);

  return (
    <MuiThemeProvider theme={isDarkTheme ? muiThemeDark : muiThemeLight}>
      <Router>
        <div className={classNames('App', { loading: reduxStore.user.loading })}>
          {reduxStore.user.loading && (
            <>
              <div className="App-progress-wrapper">
                <LinearProgress className="App-progress" />
              </div>
              <ActarusLoader fullScreen height={100} width={100} />
            </>
          )}
          {reduxStore.user.data ? (
            <>
              {reduxStore.user.data.level && reduxStore.user.data.level.is_admin ? (
                <div className="App-router">
                  <Header
                    handleSignOut={signOut}
                    user={reduxStore.user.data}
                    isDarkTheme={isDarkTheme}
                  />
                  <main className="App-main">
                    <Routes {...props} />
                  </main>
                </div>
              ) : (
                <RestrictedArea handleSignOut={signOut} />
              )}
            </>
          ) : (
            !getAccessToken() && <Authentication {...props} />
          )}
          <div id="iframes-container" />
          {!isValidBrowser && <UserChromeBrowser />}
          <Notifier notifications={reduxStore.app.notifications} removeSnackbar={removeSnackbar} />
        </div>
      </Router>
    </MuiThemeProvider>
  );
}

App.propTypes = {
  getUser: PropTypes.func.isRequired,
  reduxStore: PropTypes.shape({
    user: PropTypes.shape().isRequired,
    app: PropTypes.shape().isRequired
  }),
  removeSnackbar: PropTypes.func.isRequired,
  setThemeNightMode: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired
};

export default App;
