import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Alert from '../../../../../components/alert/Alert';
import translation from '../../../../../translation/translation';

const useStyles = makeStyles(() => ({
  tableContainer: {
    maxHeight: 450
  }
}));

function Restrictions(props) {
  const classes = useStyles();

  const columns = [
    'id',
    'name',
    'type',
    'country_isocode',
    'iam_application_id',
    'iam_company_id',
    'iam_user_id'
  ];

  const { restrictions } = props;

  return (
    <div className="ProductRestrictions">
      {restrictions && restrictions.length > 0 ? (
        <TableContainer className={classes.tableContainer}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column}>{column}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {restrictions.map((restriction, index) => (
                <TableRow key={index}>
                  <TableCell>{restriction.id}</TableCell>
                  <TableCell>{restriction.name ? restriction.name : '-'}</TableCell>
                  <TableCell>{restriction.type ? restriction.type : '_'}</TableCell>
                  <TableCell>
                    {restriction.country_isocode ? restriction.country_isocode : '-'}
                  </TableCell>
                  <TableCell>
                    {restriction.iam_application_id ? restriction.iam_application_id : '-'}
                  </TableCell>
                  <TableCell>
                    {restriction.iam_company_id ? restriction.iam_company_id : '-'}
                  </TableCell>
                  <TableCell>{restriction.iam_user_id ? restriction.iam_user_id : '-'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Alert
          type="info"
          style={{ marginTop: 10 }}
          status={translation().commons.alerts.info}
          text={translation().views.product_view.restrictions.empty}
        />
      )}
    </div>
  );
}

Restrictions.propTypes = {
  restrictions: PropTypes.arrayOf(PropTypes.any).isRequired
};

export default Restrictions;
