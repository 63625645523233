import React, { useEffect, PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import LinearProgress from '@material-ui/core/LinearProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Alert from '../../../../../components/alert/Alert';
import { dateInTz } from '../../../../../utils/functions/dates';
import translation from '../../../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2)
  },
  tableContainer: {
    maxHeight: 350
  },
  loadingWrapper: {
    width: '100%',
    height: 350,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      height: 'auto'
    }
  }
}));

class CustomHref extends PureComponent {
  render() {
    return <a {...this.props}>{this.props.children}</a>;
  }
}

CustomHref.propTypes = {
  children: PropTypes.any
};

function Invoices(props) {
  const classes = useStyles();

  const columns = ['id', 'number_formatted', 'date', 'total_formatted', 'payment_status.name'];

  const {
    cleanProviderViewInvoicesReducer,
    companyId,
    getProviderViewInvoices,
    providerId,
    invoices,
    isModeCompany
  } = props;

  useEffect(() => {
    loadInvoices(providerId);

    return () => {
      cleanProviderViewInvoicesReducer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModeCompany]);

  function loadInvoices(providerId, next = null) {
    getProviderViewInvoices(providerId, next, isModeCompany, companyId);
  }

  function handleInfiniteScroll(event) {
    if (!event) console.error('No table scroll event found.');

    if (!event.currentTarget.scrollTop || event.currentTarget.scrollTop === 0) return;

    if (
      event.currentTarget.scrollHeight - event.currentTarget.clientHeight <=
      event.currentTarget.scrollTop
    ) {
      if (invoices && invoices.paging && invoices.paging.next) {
        getProviderViewInvoices(providerId, invoices.paging.next);
      }
    }
  }

  return (
    <div className={classNames(classes.root, 'ProviderInvoices')}>
      {!invoices.isLoading && invoices.error && (!invoices.data || invoices.data.length <= 0) ? (
        <div className="error-list">
          <Alert
            type="danger"
            style={{ marginTop: 10 }}
            status={translation().commons.alerts.error}
            text={translation().core.list.error_list}
          />
        </div>
      ) : (
        false
      )}
      {invoices.data && invoices.data.length > 0 ? (
        <TableContainer onScroll={handleInfiniteScroll} className={classes.tableContainer}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column}>{column}</TableCell>
                ))}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {invoices.data.map((invoice, index) => (
                <TableRow key={index}>
                  <TableCell>{invoice.id}</TableCell>
                  <TableCell>{invoice.number_formatted ? invoice.number_formatted : '-'}</TableCell>
                  <TableCell>
                    {invoice.date ? dateInTz(invoice.date, 'localized-datetime') : '-'}
                  </TableCell>
                  <TableCell>{invoice.total_formatted ? invoice.total_formatted : '_'}</TableCell>
                  <TableCell>
                    {invoice.payment_status && invoice.payment_status.icon_url_dark && (
                      <img
                        width={25}
                        src={invoice.payment_status.icon_url_dark}
                        alt={invoice.payment_status.name ? invoice.payment_status.name : '-'}
                        title={invoice.payment_status.name ? invoice.payment_status.name : '-'}
                        style={{ verticalAlign: 'middle' }}
                      />
                    )}
                    {invoice.payment_status &&
                      invoice.payment_status.name &&
                      window.matchMedia('(min-width: 850px)').matches && (
                        <span
                          style={{
                            marginLeft: 4,
                            color: invoice.payment_status.color
                              ? invoice.payment_status.color
                              : 'currentcolor'
                          }}>
                          {invoice.payment_status.name}
                        </span>
                      )}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="update"
                      component={CustomHref}
                      target="_blank"
                      size="small"
                      href={`/${isModeCompany ? 'companies' : 'providers'}/${
                        isModeCompany && companyId ? companyId : providerId
                      }/invoices/${invoice.id}/update`}>
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : !invoices.isLoading && !invoices.error ? (
        <Alert
          type="info"
          style={{ marginTop: 10 }}
          status={translation().commons.alerts.info}
          text={translation().views.provider_view.invoices.empty}
        />
      ) : (
        false
      )}
      {invoices.isLoading && !invoices.paging && (
        <div className={classNames(classes.loadingWrapper, 'loader-wpr')}>
          <div style={{ width: '100%' }}>
            <CircularProgress color="primary" />
            <p>{translation().views.provider_view.invoices.loading_text}</p>
          </div>
        </div>
      )}
      {invoices.isLoading && invoices.paging && invoices.paging.next ? (
        <>
          <LinearProgress color="primary" />
          <div className="loader-wpr linear">
            <p>{translation().core.list.load_next}</p>
          </div>
        </>
      ) : (
        <div className="end-of-list-wpr">
          {!invoices.isLoading &&
            invoices.paging &&
            !invoices.paging.next &&
            invoices.paging.prev && <p>{translation().core.list.load_next_done}</p>}
        </div>
      )}
    </div>
  );
}

Invoices.propTypes = {
  cleanProviderViewInvoicesReducer: PropTypes.func.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  getProviderViewInvoices: PropTypes.func.isRequired,
  invoices: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape()),
    error: PropTypes.string,
    isLoading: PropTypes.bool,
    paging: PropTypes.shape({
      next: PropTypes.string,
      prev: PropTypes.string
    })
  }).isRequired,
  isModeCompany: PropTypes.bool,
  providerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export default Invoices;
