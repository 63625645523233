import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import translation from '../../../../../../translation/translation';
import config from '../../../../../../config';

const useStyles = makeStyles(() => ({
  optionChip: {
    fontSize: 12,
    margin: 2
  },
  deleteButton: {
    color: config.error_color || '#F44336',
    '&:hover': {
      backgroundColor: fade(config.error_color || '#F44336', 0.15)
    }
  }
}));

function OptionChip(props) {
  const {
    id,
    label,
    orderId,
    orderProductId,
    productOptions,
    reloadOrder,
    removeOrderProductOption
  } = props;

  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  function handleOpenDialog() {
    setIsOpen(true);
  }

  function handleCloseDialog() {
    setIsOpen(false);
  }

  function handleDeleteOption() {
    if (!id) return;

    removeOrderProductOption(orderId, orderProductId, id, reloadOrder);
  }

  return (
    <>
      <Chip className={classes.optionChip} label={label} onDelete={handleOpenDialog} />
      <Dialog
        fullWidth
        open={isOpen ? true : false}
        onClose={handleCloseDialog}
        aria-labelledby="remove-product-option"
        aria-describedby="remove-product-option-description"
        disableBackdropClick={productOptions.isLoading}>
        <DialogTitle id="remove-product-option">
          {translation().views.orders.update.products.product_options_remove.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="remove-product-option-description">
            {translation().views.orders.update.products.product_options_remove.description}
          </DialogContentText>
          <div className="sm-space" />
          {productOptions.isLoading && (
            <div className="loader-wpr" style={{ marginTop: 15 }}>
              <CircularProgress size={30} color="secondary" />
              <p>
                {translation().views.orders.update.products.product_options_remove.loading_text}
              </p>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="default" disabled={productOptions.isLoading}>
            {translation().actions.cancel}
          </Button>
          <Button
            onClick={handleDeleteOption}
            disabled={productOptions.isLoading}
            className={classes.deleteButton}>
            {translation().actions.delete}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

OptionChip.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  label: PropTypes.string.isRequired,
  orderId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  orderProductId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  productOptions: PropTypes.shape({
    isLoading: PropTypes.bool
  }).isRequired,
  reloadOrder: PropTypes.func.isRequired,
  removeOrderProductOption: PropTypes.func.isRequired
};

export default OptionChip;
