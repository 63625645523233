/**
 * Format metadata for return field
 */
import { createDate, createDateUtc, formatDate, formatJSDate } from './dates';

const formatFiltersValuesBeforePost = (metadata, name, values) => {
  if (!metadata || metadata.length <= 0) {
    return;
  }

  let valuesFormatted = values['value'];

  for (let i = 0; i < metadata.length; i++) {
    /**
     * Check types for format values
     */
    if (name === metadata[i].name) {
      if (metadata[i].name && metadata[i].type === 'date') {
        valuesFormatted = formatDate(values['value'], 'YYYY-MM-DD');
      } else if (metadata[i].name && metadata[i].type === 'time') {
        const day = formatJSDate(new Date());
        const start = createDate(day + 'T' + values['value']);
        const displayValue = createDate(start).toString();

        valuesFormatted = formatDate(displayValue, 'HH:mm:00');
      } else if (metadata[i].name && metadata[i].type === 'timestamp') {
        const localStart = createDate(values['value']);
        const utcStart = createDateUtc(localStart).toString();
        valuesFormatted = formatDate(utcStart, 'YYYY-MM-DDTHH:mm');
      }
    }
  }

  return valuesFormatted;
};

/**
 * Decompose and get filters names, operators and values from a given string
 * @param {string} filters
 * @returns {object}
 */
function decomposeFiltersFromString(filters) {
  if (!filters || filters.length <= 0) return;

  const filtersRecomposed = {};
  const parts = filters.split(',');
  parts.pop();

  for (let i = 0; i < parts.length; i++) {
    const element = parts[i].split('|');
    const name = element[0];
    const operator = element[1];
    const value = element[2];

    /**
     * Detects if there is a range operation by checking if 2 same name exists in parts
     */
    if (parts[i + 1] && parts[i + 1].split('|')[0] && name === parts[i + 1].split('|')[0]) {
      if (operator === 'gte') {
        filtersRecomposed[`${name}.start`] = {
          operator,
          value
        };
      }
      if (parts[i + 1].split('|')[1] === 'lte') {
        filtersRecomposed[`${name}.end`] = {
          operator: parts[i + 1].split('|')[1],
          value: parts[i + 1].split('|')[2]
        };
      }

      filtersRecomposed[name] = {
        operator: 'range',
        value: null
      };

      /**
       * Skip next part for non-duplicate key name
       */
      i += 1;
    } else {
      filtersRecomposed[name] = {
        operator,
        value
      };
    }
  }

  return filtersRecomposed;
}

export { decomposeFiltersFromString, formatFiltersValuesBeforePost };
