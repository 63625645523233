import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';

function FormSwitchField(props) {
  const {
    className,
    defaultValue,
    disabled,
    error,
    helperText,
    id,
    label,
    margin,
    name,
    onChange,
    required,
    write
  } = props;

  const [state, setState] = useState({ checked: defaultValue || false });

  function handleChange(event) {
    if (event) {
      setState({ checked: event.target.checked });

      if (onChange) {
        onChange(event.target.checked);
      }
    }
  }

  useEffect(() => {
    if (defaultValue) {
      if (onChange) {
        onChange(defaultValue);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormControl
      className={className}
      required={required}
      disabled={!write || disabled}
      margin={margin}
      style={{ display: 'block' }}
      error={error ? true : false}>
      <FormGroup row>
        <FormControlLabel
          name={name}
          control={
            <Switch id={id} required={required} onChange={handleChange} checked={state.checked} />
          }
          label={label}
        />
      </FormGroup>
      {(error || helperText) && (
        <FormHelperText component="span" style={{ margin: 0 }}>
          {error || helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}

FormSwitchField.defaultProps = {
  checked: true,
  margin: 'normal',
  required: false,
  write: true
};

FormSwitchField.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  margin: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.shape(), PropTypes.node]),
  onChange: PropTypes.func,
  required: PropTypes.bool,
  write: PropTypes.bool
};

export default FormSwitchField;
