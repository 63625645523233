import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import { realRound } from '../../../../utils/functions/utils';
import translation from '../../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  detailSpan: {
    marginLeft: 4,
    fontWeight: 500
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  financialTitle: {
    fontSize: 14
  },
  typography: {
    lineHeight: 2
  }
}));

function OrderDetailsButton(props) {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const { order } = props;

  function handleOpenDialog() {
    setIsOpen(true);
  }

  function handleCloseDialog() {
    setIsOpen(false);
  }

  return (
    <>
      <IconButton edge="end" aria-label="Show" onClick={handleOpenDialog}>
        <AccountBalanceWalletIcon fontSize="small" />
      </IconButton>
      <Dialog
        fullWidth
        open={isOpen ? true : false}
        onClose={handleCloseDialog}
        aria-labelledby="order-financial-details"
        aria-describedby="order-financial-details-description">
        <DialogTitle id="order-financial-details">
          {translation().views.orders.update.financial_details.title}
        </DialogTitle>
        <DialogContent>
          {order && (
            <div>
              <Typography className={classes.financialTitle} component="p" variant="h6">
                {translation().views.orders.update.financial_details.labels.order}:
              </Typography>
              <div className="xs-space" />
              <Typography variant="body1" className={classes.typography}>
                {translation().commons.pricing.total_tax_excluded_formatted}
                <span className={classNames(classes.detailSpan, 'primary')}>
                  {order.total_tax_excluded_formatted}
                </span>
              </Typography>
              <Typography variant="body1" className={classes.typography}>
                {translation().commons.pricing.total_formatted}
                <span className={classNames(classes.detailSpan, 'primary')}>
                  {order.total_formatted}
                </span>
              </Typography>
              <Divider className={classes.divider} />
              <Typography className={classes.financialTitle} component="p" variant="h6">
                {translation().views.orders.update.financial_details.labels.provider_reversion}:
              </Typography>
              <div className="xs-space" />
              <Typography variant="body1" className={classes.typography}>
                {translation().commons.pricing.total_tax_excluded_formatted}
                <span className={classNames(classes.detailSpan, 'primary')}>
                  {order.total_provider_reversion_tax_excluded_formatted}
                </span>
              </Typography>
              <Typography variant="body1" className={classes.typography}>
                {translation().commons.pricing.total_tax_formatted}
                <span className={classNames(classes.detailSpan, 'primary')}>
                  {order.total_provider_reversion_tax_formatted}
                </span>
              </Typography>
              <Typography variant="body1" className={classes.typography}>
                {translation().commons.pricing.total_formatted}
                <span className={classNames(classes.detailSpan, 'primary')}>
                  {order.total_provider_reversion_formatted}
                </span>
              </Typography>
              <Divider className={classes.divider} />
              <Typography className={classes.financialTitle} component="p" variant="h6">
                {translation().views.orders.update.financial_details.labels.commissions}:
              </Typography>
              <div className="xs-space" />
              {order.commissions && order.commissions.length > 0 ? (
                <>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          {translation().views.orders.update.financial_details.labels.recipient}
                        </TableCell>
                        <TableCell align="right">
                          {translation().commons.pricing.total_tax_excluded_formatted}
                        </TableCell>
                        <TableCell align="right">
                          {translation().commons.pricing.total_formatted}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {order.commissions.map((com, idx) => (
                        <TableRow key={idx}>
                          <TableCell>
                            {com.iam_company_id ? com.iam_company_name : com.iam_user_name}
                          </TableCell>
                          <TableCell align="right">{com.amount_tax_excluded_formatted}</TableCell>
                          <TableCell align="right">{com.amount_formatted}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <div className="xs-space" />
                </>
              ) : (
                ''
              )}
              <Typography variant="body1" className={classes.typography}>
                {translation().commons.pricing.total_tax_excluded_formatted}
                <span className={classNames(classes.detailSpan, 'primary')}>
                  {order.total_commissions_tax_excluded_formatted}
                </span>
              </Typography>
              <Typography variant="body1" className={classes.typography}>
                {translation().commons.pricing.total_tax_formatted}
                <span className={classNames(classes.detailSpan, 'primary')}>
                  {order.total_commissions_tax_formatted}
                </span>
              </Typography>
              <Typography variant="body1" className={classes.typography}>
                {translation().commons.pricing.total_formatted}
                <span className={classNames(classes.detailSpan, 'primary')}>
                  {order.total_commissions_formatted}
                </span>
              </Typography>
              <Divider className={classes.divider} />
              <Typography className={classes.financialTitle} component="p" variant="h6">
                {translation().views.orders.update.financial_details.labels.referrals}:
              </Typography>
              <div className="xs-space" />
              {order.referrals && order.referrals.length > 0 ? (
                <>
                  <Table>
                    <TableHead className={classes.tableHead}>
                      <TableRow>
                        <TableCell>
                          {translation().views.orders.update.financial_details.labels.recipient}
                        </TableCell>
                        <TableCell align="right">
                          {translation().commons.pricing.total_tax_excluded_formatted}
                        </TableCell>
                        <TableCell align="right">
                          {translation().commons.pricing.total_formatted}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {order.referrals.map((ref, idx) => (
                        <TableRow key={idx}>
                          <TableCell>
                            {ref.iam_company_id ? ref.iam_company_name : ref.iam_user_name}
                          </TableCell>
                          <TableCell align="right">{ref.amount_tax_excluded_formatted}</TableCell>
                          <TableCell align="right">{ref.amount_formatted}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <div className="xs-space" />
                </>
              ) : (
                ''
              )}
              <Typography variant="body1" className={classes.typography}>
                {translation().commons.pricing.total_tax_excluded_formatted}
                <span className={classNames(classes.detailSpan, 'primary')}>
                  {order.total_referrals_tax_excluded_formatted}
                </span>
              </Typography>
              <Typography variant="body1" className={classes.typography}>
                {translation().commons.pricing.total_tax_formatted}
                <span className={classNames(classes.detailSpan, 'primary')}>
                  {order.total_referrals_tax_formatted}
                </span>
              </Typography>
              <Typography variant="body1" className={classes.typography}>
                {translation().commons.pricing.total_formatted}
                <span className={classNames(classes.detailSpan, 'primary')}>
                  {order.total_referrals_formatted}
                </span>
              </Typography>
              <div className="xs-space" />
              <Divider className={classes.divider} />
              <Typography className={classes.financialTitle} component="p" variant="h6">
                {translation().views.orders.update.financial_details.labels.margin}:
              </Typography>
              <div className="xs-space" />
              <Typography variant="body1" className={classes.typography}>
                {translation().views.orders.update.financial_details.labels.net_margin}:
                <span className={classNames(classes.detailSpan, 'primary')}>
                  {order.net_margin_formatted}
                </span>
                {order.net_margin && order.total_tax_excluded && (
                  <span style={{ marginLeft: 8 }}>
                    {'('}
                    {realRound((order.net_margin * 100) / order.total_tax_excluded, 2)}%{')'}
                  </span>
                )}
              </Typography>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="default">
            {translation().actions.close}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

OrderDetailsButton.propTypes = {
  order: PropTypes.shape().isRequired
};

export default OrderDetailsButton;
