const initialState = {
  data: [],
  isError: false,
  isLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_REDUCERS':
      return initialState;
    case 'CLEAR_PROVIDERS_MAP':
      return initialState;
    case 'GET_PROVIDERS_PRODUCTS_COVERAGE':
      return {
        ...initialState,
        isLoading: true
      };
    case 'GET_PROVIDERS_PRODUCTS_COVERAGE_SUCCESS':
      return {
        ...state,
        data: action.data,
        isLoading: false,
        isError: false
      };
    case 'GET_PROVIDERS_PRODUCTS_COVERAGE_ERROR':
      return {
        ...state,
        isLoading: false,
        isError: true
      };
    default:
      return state;
  }
};
