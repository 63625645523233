import './previousDay.scss';

import axios from 'axios';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { green } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import LinearProgress from '@material-ui/core/LinearProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import Alert from '../../components/alert/Alert';
import FormApiAutocompleteField from '../../components/forms/fields/FormApiAutocompleteField';
import FormDateTimeField from '../../components/forms/fields/FormDateTimeField';
import ListEmptyIcon from '../../components/icons/ListEmptyIcon';
import LocalDateInfo from '../../components/timezone/LocalDateInfo';
import PreviousDayViewBar from './components/PreviousDayViewBar';
import { createDate, createDateUtc, dateInTz, formatDate } from '../../utils/functions/dates';
import translation from '../../translation/translation';
import config from '../../config';

const columns = [
  'id',
  'flag',
  'order_id',
  'property_address',
  'client',
  'application',
  'name',
  'property_size',
  'provider_id',
  'provider_name',
  'start',
  'options',
  'property_contact_comment',
  'delivery_status'
];

const createCancelToken = () => axios.CancelToken.source();

const GetDataOrder = (getOrder) => {
  const [source] = useState(createCancelToken());

  const doGetOrder = (id, callbackSuccess, callbackError) => {
    getOrder(id, source, callbackSuccess, callbackError);
  };

  useEffect(() => {
    return () => {
      source.cancel('=> Get order from previous day.');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { doGetOrder };
};

const useStyles = makeStyles((theme) => ({
  labelImportant: {
    color: theme.palette.type === 'dark' ? '#cb6059' : config.error_color || '#F44336'
  },
  linkImportant: {
    color: theme.palette.type === 'dark' ? '#d3834b' : '#ff6a00'
  },
  optionImportant: {
    backgroundColor: fade(
      theme.palette.type === 'dark' ? '#cb6059' : config.error_color || '#F44336',
      0.5
    )
  }
}));

function ResultTableRow(props) {
  const { getOrder, product } = props;
  const [isLoading, setLoading] = useState(false);
  const [order, setOrder] = useState(null);
  const { doGetOrder } = GetDataOrder(getOrder);
  const classes = useStyles();

  const cellClass = product.flag ? classes.labelImportant : '';
  const linkClass = product.flag ? classes.linkImportant : '';
  const chipClass = product.flag ? classes.optionImportant : '';

  useEffect(() => {
    getRowInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getRowInfo() {
    if (!product.order_id) return;

    setLoading(true);
    setOrder(null);

    doGetOrder(
      product.order_id,
      (order) => {
        setLoading(false);
        setOrder(order);
      },
      () => {
        setLoading(true);
        setOrder(null);
      }
    );
  }

  return (
    <TableRow>
      <TableCell variant="body" className={cellClass}>
        <Typography variant="body2">{product.id || '-'}</Typography>
      </TableCell>
      <TableCell variant="body" className={cellClass}>
        {product.order_id ? (
          <Link href={`/orders/${product.order_id}/update`} target="_blank" className={linkClass}>
            {product.order_id}
          </Link>
        ) : (
          '-'
        )}
      </TableCell>
      <TableCell variant="body" className={cellClass}>
        {isLoading ? (
          <CircularProgress size={25} thickness={2} />
        ) : (
          <Typography variant="body2">
            {order && order.property && order.property.address ? order.property.address : '-'}
          </Typography>
        )}
      </TableCell>
      <TableCell variant="body" className={cellClass}>
        {isLoading ? (
          <CircularProgress size={25} thickness={2} />
        ) : order && order.user && order.user.first_name && order.user.last_name ? (
          order.user.id ? (
            <Link href={`/users/${order.user.id}/update`} target="_blank" className={linkClass}>
              {order.user.first_name +
                ' ' +
                order.user.last_name +
                (order.company && order.company.commercial_name
                  ? ' (' + order.company.commercial_name + ')'
                  : '')}
            </Link>
          ) : (
            <Typography variant="body2">
              {order.user.first_name +
                ' ' +
                order.user.last_name +
                (order.company && order.company.commercial_name
                  ? ' (' + order.company.commercial_name + ')'
                  : '')}
            </Typography>
          )
        ) : (
          '-'
        )}
      </TableCell>
      <TableCell variant="body" className={cellClass}>
        {isLoading ? (
          <CircularProgress size={25} thickness={2} />
        ) : (
          <Typography variant="body2">
            {order && order.application && order.application.name ? order.application.name : '-'}
          </Typography>
        )}
      </TableCell>
      <TableCell variant="body" className={cellClass}>
        <Typography variant="body2">{product.name || '-'}</Typography>
      </TableCell>
      <TableCell variant="body" className={cellClass}>
        {isLoading ? (
          <CircularProgress size={25} thickness={2} />
        ) : (
          <Typography variant="body2">
            {order && order.property && order.property.size_formatted
              ? order.property.size_formatted
              : '-'}
          </Typography>
        )}
      </TableCell>
      <TableCell variant="body" className={cellClass}>
        {product.provider_name ? (
          product.provider_id ? (
            <Link
              href={'/providers/' + product.provider_id + '/update'}
              target="_blank"
              className={linkClass}>
              {product.provider_name}
            </Link>
          ) : (
            <Typography variant="body2">{product.provider_name}</Typography>
          )
        ) : (
          <Typography variant="body2">{'-'}</Typography>
        )}
      </TableCell>
      <TableCell variant="body" className={cellClass}>
        <Typography variant="body2">{product.startDateDayTz || '-'}</Typography>
        {isLoading ? (
          <CircularProgress size={15} thickness={2} />
        ) : order &&
          order.property &&
          order &&
          order.property.timezone_code &&
          product.startDate ? (
          <LocalDateInfo
            timezone={order.property.timezone_code}
            date={product.startDate}
            format="localized-date"
          />
        ) : (
          false
        )}
      </TableCell>
      <TableCell variant="body" className={cellClass}>
        <Typography variant="body2">{product.startDateTimeTz || '-'}</Typography>
      </TableCell>
      <TableCell variant="body" className={cellClass}>
        {product.options && product.options.length > 0
          ? product.options.map((option, i) => (
              <Chip
                key={i}
                size="small"
                style={{ margin: 4 }}
                label={option.name}
                className={chipClass}
              />
            ))
          : '-'}
      </TableCell>
      <TableCell variant="body" className={cellClass}>
        <Typography variant="body2">{product.property_contact_comment || '-'}</Typography>
      </TableCell>
      <TableCell variant="body" className={cellClass}>
        <img
          width={27}
          src={product.delivery_status_icon}
          title={product.delivery_status_name}
          alt={product.delivery_status_name}
        />
      </TableCell>
    </TableRow>
  );
}

ResultTableRow.propTypes = {
  getOrder: PropTypes.func.isRequired,
  product: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

const GetMetadataApi = (getMetadata, url) => {
  const [source] = useState(createCancelToken());

  useEffect(() => {
    getMetadata(url, source);

    return () => {
      source.cancel('=> Core metadata changed.');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const GetDataApi = (getData, url) => {
  const [source] = useState(createCancelToken());

  const doGetData = (query, next, isDateSet, isDelivred, callbackSuccess) => {
    getData(url, source, query, next, false, isDateSet, isDelivred, callbackSuccess);
  };

  const doRefresh = (callbackSuccess) => {
    getData(url, source, {}, null, true, false, false, callbackSuccess);
  };

  useEffect(() => {
    return () => {
      source.cancel('=> Core data changed.');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { doRefresh, doGetData };
};

const SearchDataApi = (getData, url) => {
  const [source] = useState(createCancelToken());

  const doSearch = (search, callbackSuccess) => {
    getData(url, source, { search }, null, false, false, false, callbackSuccess);
  };

  useEffect(() => {
    return () => {
      source.cancel('=> Next: Cancelled.');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { doSearch };
};

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600]
    }
  },
  checked: {}
})((props) => <Checkbox color="default" {...props} />);

function PreviousDay(props) {
  const {
    clearOwnReducer,
    getData,
    getMetadata,
    getOrder,
    history,
    list: { data, isError, isLoading, paging },
    metadata,
    metadataUri,
    parentUri,
    setDataSearchValue
  } = props;

  const routeUri = 'orders/all/products/active';
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [isDelivred, setDelivred] = useState(false);
  const [product, setProduct] = useState(null);
  const [startRange, setStartRange] = useState(null);
  const [endRange, setEndRange] = useState(null);
  const { doGetData, doRefresh } = GetDataApi(getData, routeUri);
  const { doSearch } = SearchDataApi(getData, routeUri);

  GetMetadataApi(getMetadata, metadataUri);

  useEffect(() => {
    document.title = 'Actarus | Previous day';

    loadData();

    return () => {
      clearOwnReducer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function loadData(query = null, next = null, isDateSet = false, isDelivred = false) {
    doGetData(query, next, isDateSet, isDelivred, (success, paging) => {
      if (paging && paging.next) {
        loadData(null, paging.next);
      }
    });
  }

  function handleSubmit(e) {
    e.preventDefault();
    doSearch(search, (success, paging) => {
      if (paging && paging.next) {
        loadData(null, paging.next);
      }
    });
    setDataSearchValue(search);
  }

  function handleSearch(e) {
    setSearch(e.target.value);
    setDelivred(false);
    setStartRange(null);
    setProduct(null);
    setEndRange(null);
  }

  function handleRefresh() {
    setSearch('');
    setDelivred(false);
    setStartRange(null);
    setProduct(null);
    setEndRange(null);

    doRefresh((data, paging) => {
      if (paging && paging.next) {
        loadData(null, paging.next);
      }
    });
  }

  function handleOpenFilter() {
    setIsFiltersOpen(true);
  }

  function handleCloseFilter() {
    setIsFiltersOpen(false);
  }

  function handleChangeProduct(val) {
    setProduct(val);
  }

  function handleChangeStart(val) {
    setStartRange(createDate(val));
  }

  function handleChangeEnd(val) {
    setEndRange(createDate(val));
  }

  function handleChangeDelivred(event) {
    setDelivred(event.target.checked);
  }

  function handleFilterData() {
    let filters = '';
    let isDateSet = false;

    if (startRange) {
      filters += 'start|gte|' + formatDate(createDateUtc(startRange), 'YYYY-MM-DDTHH:mm:ss') + ',';
      isDateSet = true;
    }

    if (endRange) {
      filters += 'end|lte|' + formatDate(createDateUtc(endRange), 'YYYY-MM-DDTHH:mm:ss') + ',';
      isDateSet = true;
    }

    if (product && product.length) {
      if (product.length > 1) {
        const productIds = [];
        for (let i = 0; i < product.length; i++) {
          productIds.push(product[i].id);
        }
        filters += `product_id|in|${productIds.join('/')},`;
      } else {
        filters += `product_id|eq|${product[0].id},`;
      }
    }

    setSearch('');
    setDataSearchValue('');
    handleCloseFilter();

    loadData({ filters }, null, isDateSet, isDelivred);
  }

  function getTableBodyFromData(orderProducts) {
    const tableBody = [];

    for (let line in orderProducts) {
      let tempBody = [];

      for (let key in orderProducts[line]) {
        /**
         * Check columns to show
         */
        if (columns.indexOf(key) !== -1) {
          if (key === 'id') {
            tempBody[key] = orderProducts[line][key];
          } else if (key === 'flag') {
            tempBody[key] = orderProducts[line][key];
          } else if (key === 'name') {
            tempBody[key] = orderProducts[line][key];
          } else if (key === 'provider_name') {
            tempBody[key] = orderProducts[line][key];
          } else if (key === 'provider_id') {
            tempBody[key] = orderProducts[line][key];
          } else if (key === 'order_id') {
            tempBody['order_id'] = orderProducts[line][key];
          } else if (key === 'options') {
            /**
             * Get products
             */
            let _options = [];

            for (let option in orderProducts[line][key]) {
              let options = {};
              options.name = orderProducts[line][key][option].name;
              _options.push(options);
            }

            tempBody['options'] = _options;
          } else if (key === 'start') {
            tempBody['startDateTimeTz'] = dateInTz(orderProducts[line][key], 'HH:mm');
            tempBody['startDateDayTz'] = dateInTz(orderProducts[line][key], 'localized-date');
            tempBody['startDate'] = orderProducts[line][key];
          } else if (key === 'property_contact_comment') {
            tempBody['property_contact_comment'] = orderProducts[line][key];
          } else if (key === 'delivery_status') {
            tempBody['delivery_status_icon'] = orderProducts[line][key].icon_url_dark;
            tempBody['delivery_status_name'] = orderProducts[line][key].name;
            tempBody['delivery_shortcode'] = orderProducts[line][key].shortcode;
          }
        }
      }

      tableBody.push(tempBody);
    }

    return tableBody;
  }

  function renderTable(data) {
    if (data && data.length) {
      const tableBody = getTableBodyFromData(data);
      const tableValues = tableBody.map((row, index) => {
        return <ResultTableRow key={index} getOrder={getOrder} product={row} />;
      });

      return (
        <TableContainer className="FixedHeaderTable">
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow key="tableHeader">
                <TableCell variant="head">
                  {translation().views.production.previous_day.columns.product_id}
                </TableCell>
                <TableCell variant="head">
                  {translation().views.production.previous_day.columns.order_id}
                </TableCell>
                <TableCell variant="head">
                  {translation().views.production.previous_day.columns.address}
                </TableCell>
                <TableCell variant="head">
                  {translation().views.production.previous_day.columns.client}
                </TableCell>
                <TableCell variant="head">
                  {translation().views.production.previous_day.columns.app}
                </TableCell>
                <TableCell variant="head">
                  {translation().views.production.previous_day.columns.name}
                </TableCell>
                <TableCell variant="head">
                  {translation().views.production.previous_day.columns.size}
                </TableCell>
                <TableCell variant="head">
                  {translation().views.production.previous_day.columns.provider}
                </TableCell>
                <TableCell variant="head">
                  {translation().views.production.previous_day.columns.date}
                </TableCell>
                <TableCell variant="head">
                  {translation().views.production.previous_day.columns.time}
                </TableCell>
                <TableCell variant="head">
                  {translation().views.production.previous_day.columns.options}
                </TableCell>
                <TableCell variant="head">
                  {translation().views.production.previous_day.columns.comment}
                </TableCell>
                <TableCell variant="head">
                  {translation().views.production.previous_day.columns.status}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{tableValues}</TableBody>
          </Table>
        </TableContainer>
      );
    }
  }

  return (
    <div className="PreviousDay">
      <PreviousDayViewBar
        isLoading={isLoading}
        handleSubmit={handleSubmit}
        handleSearch={handleSearch}
        handleRefresh={handleRefresh}
        handleOpenFilter={handleOpenFilter}
        handleCloseFilter={handleCloseFilter}
        history={history}
        isFilterable
        parentUri={parentUri}
        search={search}
        routeUri="Previous day"
      />
      {data && data.length ? renderTable(data) : false}
      {!isLoading && isError && (!data || data.length <= 0) ? (
        <div className="error-list">
          <Alert
            type="danger"
            style={{ marginTop: 10 }}
            status={translation().commons.alerts.error}
            text={translation().core.list.error_list}
          />
        </div>
      ) : (
        false
      )}
      {(isLoading || metadata.isLoading) && !paging && (
        <div className="loader-wpr">
          <CircularProgress color="primary" />
          <p>{translation().views.order_products.list.loading_text}</p>
          {metadata.isLoading && <p>{translation().core.load_metadata}</p>}
        </div>
      )}
      {isLoading && paging && paging.next && (
        <>
          <LinearProgress color="primary" />
          <div className="loader-wpr linear">
            <p>{translation().core.list.load_next}</p>
          </div>
        </>
      )}
      {!isLoading && !metadata.isLoading && !isError && (!data || !data.length) && (
        <div className="loader-wpr linear">
          <ListEmptyIcon />
          <p style={{ margin: '7px 0', lineHeight: 'initial' }}>{translation().core.list.empty}</p>
        </div>
      )}
      {!isLoading && paging && !paging.next && paging.prev && (
        <div className="loader-wpr linear">
          <p>{translation().core.list.load_next_done}</p>
        </div>
      )}
      <Dialog
        open={isFiltersOpen}
        onClose={handleCloseFilter}
        aria-labelledby="filter-previous-day"
        maxWidth="md"
        fullWidth>
        <DialogTitle id="filter-previous-day">
          {translation().views.production.previous_day.filters_form.title}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormApiAutocompleteField
                name="products_autocomplete"
                label={translation().views.production.previous_day.filters_form.labels.product}
                placeholder={
                  translation().views.production.previous_day.filters_form.labels
                    .product_placeholder
                }
                links={[{ rel: 'list', href: `${config.api_url}products` }]}
                onSelectValue={handleChangeProduct}
                multiple
                targetKey="id"
                getFullObject
                defaultValue={product}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ display: 'flex', alignItems: 'flex-end' }}>
              <FormControlLabel
                control={
                  <GreenCheckbox
                    checked={isDelivred}
                    onChange={handleChangeDelivred}
                    value="delivred"
                  />
                }
                label={translation().views.production.previous_day.filters_form.labels.is_delivered}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormDateTimeField
                name="range_start"
                label={translation().views.production.previous_day.filters_form.labels.start}
                onChange={handleChangeStart}
                value={startRange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormDateTimeField
                name="range_end"
                label={translation().views.production.previous_day.filters_form.labels.end}
                onChange={handleChangeEnd}
                value={endRange}
              />
            </Grid>
          </Grid>
          <div className="md-space" />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFilter} color="default">
            {translation().actions.close}
          </Button>
          <Button onClick={handleFilterData} color="primary" variant="contained">
            {translation().actions.confirm}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

PreviousDay.defaultProps = {
  metadataUri: 'orders/1/products'
};

PreviousDay.propTypes = {
  clearOwnReducer: PropTypes.func.isRequired,
  getData: PropTypes.func.isRequired,
  getMetadata: PropTypes.func.isRequired,
  getOrder: PropTypes.func.isRequired,
  history: PropTypes.shape().isRequired,
  list: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape()),
    isLoading: PropTypes.bool,
    paging: PropTypes.shape({
      next: PropTypes.string,
      prev: PropTypes.string
    }),
    isError: PropTypes.bool
  }).isRequired,
  metadata: PropTypes.shape(),
  metadataUri: PropTypes.string,
  parentUri: PropTypes.string.isRequired,
  setDataSearchValue: PropTypes.func.isRequired
};

export default PreviousDay;
