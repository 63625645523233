import { last } from 'lodash';

function navigateBack(history) {
  if (history && history.location && history.location.pathname) {
    const locationArray = history.location.pathname.split('/');
    locationArray.pop();
    const newUrl = locationArray.join('/');

    const matches = last(locationArray).match(/\d+/g);
    /**
     * Check if  url contains numbers (like resource ID's)
     */
    if (matches !== null) {
      const locationArrayWithoutNumber = newUrl.split('/');
      locationArrayWithoutNumber.pop();
      const newUrlWithoutNumber = locationArrayWithoutNumber.join('/');
      history.push(newUrlWithoutNumber);
    } else {
      history.push(newUrl);
    }
  }
}

function isNavigationBackPossible(history) {
  if (history && history.location && history.location.pathname) {
    const locationArray = history.location.pathname.split('/');
    locationArray.pop();

    return locationArray.length > 1;
  }

  return false;
}

export { isNavigationBackPossible, navigateBack };
