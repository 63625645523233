import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Badge,
  Button,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  Link,
  Tooltip,
  Typography,
  ButtonBase,
  fade,
  darken
} from '@material-ui/core';
import { Add as AddIcon, Close, Person as PersonIcon } from '@material-ui/icons';

import UserListItem from '../../forms/create/UserListItem';
import { getUserInitials } from '../../../../../../utils/functions/user';
import { stringToColor } from '../../../../../../utils/functions/colors';
import { FormApiAutocompleteField } from '../../../../../../components/forms/fields';
import translation from '../../../../../../translation/translation';

const useStyles = makeStyles((theme) => ({
  addButton: {
    padding: 8,
    border: '1px dashed rgba(145, 158, 171, 0.24)',
    willChange: 'background-color'
  },
  avatar: {
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8
    },
    '&.disabled': {
      userSelect: 'none',
      pointerEvents: 'none'
    }
  },
  autoAssign: {
    marginLeft: 4,
    cursor: 'pointer'
  },
  field: {
    marginBottom: 24
  },
  disabledLink: {
    cursor: 'default',
    userSelect: 'none',
    pointerEvents: 'none',
    color: theme.palette.text.disabled
  },
  badgeRoot: {
    '&:not(.disabled):hover': {
      '& > .MuiBadge-badge': {
        display: 'flex'
      }
    }
  },
  badge: {
    display: 'none',
    right: 2,
    top: 2
  },
  delete: {
    height: 18,
    width: 18,
    zIndex: 1,
    borderRadius: 50,
    backgroundColor: fade(theme.palette.error.main, 0.9),
    border: '1px solid white',
    color: 'white',
    '&:hover': {
      backgroundColor: darken(theme.palette.error.main, 0.1)
    },
    '&.disabled': {
      userSelect: 'none',
      pointerEvents: 'none',
      backgroundColor: theme.palette.grey[400]
    },
    '& > .MuiSvgIcon-root': {
      fontSize: '0.75rem'
    }
  }
}));

function InputAssignedUser(props) {
  const { id, index, data, onChange, userLogged, users, write } = props;

  function handleGetAvatarColor(user) {
    if (!user || !user.id) return;
    return stringToColor(`${user.id}${user.first_name}${user.last_name}`);
  }

  const [userAssigned, setUserAssigned] = useState(data ? data : null);
  const [value, setValue] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const classes = useStyles();

  function handleSelectUser(user) {
    setValue(user);
  }

  function handleChangeUser(user) {
    setValue(user);
  }

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
    setLoading(false);
    setValue(null);
  }

  function handleAssignSelf() {
    if (!userLogged || !userLogged.id || !id) return;

    setLoading(true);

    onChange(
      id,
      { assigned_iam_user_id: userLogged.id },
      (success) => {
        if (success && success.assigned_user && success.assigned_user) {
          setUserAssigned(success.assigned_user);
          handleClose();
        }
      },
      () => {
        setValue(null);
        setLoading(false);
      }
    );
  }

  function handleSubmit() {
    const uid =
      value && value.id ? value.id : userAssigned && userAssigned.id ? userAssigned.id : null;

    if (id && uid) {
      setLoading(true);

      onChange(
        id,
        { assigned_iam_user_id: uid },
        (success) => {
          if (success && success.assigned_user && success.assigned_user) {
            setUserAssigned(success.assigned_user);
            handleClose();
          }
        },
        () => {
          setValue(null);
          setLoading(false);
        }
      );
    }
  }

  function handleUnassign() {
    if (!id) return;

    setLoading(true);

    onChange(
      id,
      { assigned_iam_user_id: null },
      () => {
        setUserAssigned(null);
        handleClose();
      },
      () => {
        setValue(null);
        setLoading(false);
      }
    );
  }

  return (
    <Box>
      {userAssigned && userAssigned.id ? (
        <Badge
          classes={{
            root: classNames(classes.badgeRoot, { disabled: !write }),
            badge: classes.badge
          }}
          badgeContent={
            <ButtonBase
              className={classNames(classes.delete, {
                disabled: loading
              })}
              onClick={handleUnassign}
              disabled={loading}>
              <Close />
            </ButtonBase>
          }>
          <Avatar
            aria-label={`assigned_user_${index}`}
            onClick={handleOpen}
            className={classNames(classes.avatar, {
              disabled: loading || !write
            })}
            style={{
              backgroundColor: handleGetAvatarColor(userAssigned)
            }}>
            {loading ? (
              <CircularProgress size={20} color="inherit" />
            ) : userAssigned.first_name && userAssigned.last_name ? (
              getUserInitials(userAssigned)
            ) : (
              <PersonIcon fontSize="large" />
            )}
          </Avatar>
        </Badge>
      ) : (
        <Tooltip title={translation().views.task_views.tasks.labels.assign_user}>
          <IconButton
            onClick={handleOpen}
            size="small"
            className={classNames(classes.addButton, { 'avatar-pulse': write })}
            disabled={!write}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      )}
      <Dialog
        aria-labelledby="assigned-user-dialog"
        fullWidth
        maxWidth="xs"
        onClose={handleClose}
        open={open}
        disableBackdropClick={loading}>
        <DialogTitle id="assigned-user-dialog">
          {translation().views.task_views.tasks.labels.user_assigned}
        </DialogTitle>
        <DialogContent>
          <FormApiAutocompleteField
            id="assign-user-autocomplete"
            name="assign-user-autocomplete"
            variant="filled"
            margin="none"
            fullWidth
            links={[
              {
                rel: 'list',
                href: `users`
              }
            ]}
            InputProps={{
              style: {
                padding: 8,
                height: 50
              }
            }}
            getFullObject
            disabled={loading}
            className={classes.field}
            onSelectValue={handleChangeUser}
            placeholder={translation().views.task_views.tasks.users.label}
            targetKey="id"
          />
          {users && users.length > 0 ? (
            <>
              <Typography className={classes.accessTitle}>
                {translation().views.task_views.tasks.users.with_access}
                <Link
                  onClick={handleAssignSelf}
                  variant="body2"
                  className={classNames(classes.autoAssign, {
                    [classes.disabledLink]: loading
                  })}>
                  {translation().views.task_views.tasks.labels.auto_assign}
                </Link>
              </Typography>
              <List dense className={classes.list}>
                {users.map((member, index) => (
                  <UserListItem
                    key={index}
                    disabled={loading}
                    id={member?.user.id}
                    user={member?.user}
                    selected={value ? value : userAssigned}
                    userIdLogged={userLogged.id}
                    onSelect={handleSelectUser}
                  />
                ))}
              </List>
            </>
          ) : (
            false
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={loading}>
            {translation().actions.cancel}
          </Button>
          <Button
            color="secondary"
            onClick={handleSubmit}
            disabled={!value || loading}
            variant="contained">
            {loading ? <CircularProgress size={18} /> : translation().actions.confirm}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

InputAssignedUser.defaultProps = {
  write: true
};

InputAssignedUser.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired
  }),
  id: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  userLogged: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      level: PropTypes.oneOf(['admin', 'manager', 'editor', 'operator', 'viewer']),
      user: PropTypes.shape({
        id: PropTypes.number,
        first_name: PropTypes.string,
        last_name: PropTypes.string,
        email: PropTypes.string
      })
    })
  ),
  write: PropTypes.bool
};

export default InputAssignedUser;
