import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import AppsIcon from '@material-ui/icons/Apps';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Avatar from '@material-ui/core/Avatar';
import AppBar from '@material-ui/core/AppBar';
import ButtonBase from '@material-ui/core/ButtonBase';
import Divider from '@material-ui/core/Divider';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import IsoIcon from '@material-ui/icons/Iso';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import MultilineChartIcon from '@material-ui/icons/MultilineChart';
import ReceiptIcon from '@material-ui/icons/Receipt';
import Toolbar from '@material-ui/core/Toolbar';
import TuneIcon from '@material-ui/icons/Tune';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import WorkIcon from '@material-ui/icons/Work';
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';

import ReactIcon from '../icons/ReactIcon';
import WmWishIcon from '../icons/WmWishIcon';
import { getAccessToken } from '../../utils/functions/cookies';
import config from '../../config';
import translation from '../../translation/translation';

const drawerWidth = 200;

const styles = (theme) => ({
  avatar: {
    marginRight: 8
  },
  root: {
    width: '100%'
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  menuItem: {
    fontSize: 14
  },
  wishIcon: {
    [theme.breakpoints.down('360')]: {
      display: 'none'
    }
  },
  drawerButton: {
    marginRight: 20,
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  triggerUser: {
    cursor: 'pointer',
    marginLeft: 7,
    padding: '6px 12px',
    borderRadius: 4,
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.08)'
    }
  },
  quickLinkList: {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: 330,
    flexWrap: 'wrap',
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  quickLinkListSmall: {
    maxWidth: 150
  },
  quickLink: {
    minWidth: 145,
    minHeight: 36,
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'start'
  },
  link: {
    width: '50%'
  },
  fullWidth: {
    width: '100%'
  }
});

const iconMenuItemStyle = {
  marginRight: 8
};

const iconQuickLinkStyle = {
  marginRight: 5
};

function PrimarySearchAppBar(props) {
  const { classes, onDraw, onSignOut, user } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [anchorBrandLinks, setAnchorBrandLinks] = useState(null);
  const [anchorQuickLinks, setAnchorQuickLinks] = useState(null);

  const isBrandLinkOpen = Boolean(anchorBrandLinks);
  const isQuickLinksOpen = Boolean(anchorQuickLinks);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  function handleProfileMenuOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }

  function handleMenuClose() {
    setAnchorEl(null);
    handleMobileMenuClose();
  }

  function handleMobileMenuOpen(event) {
    setMobileMoreAnchorEl(event.currentTarget);
  }

  function handleBrandLinksOpen(event) {
    setAnchorBrandLinks(event.currentTarget);
  }

  function handleBrandLinksClose() {
    setAnchorBrandLinks(null);
  }

  function handleQuickLinksOpen(event) {
    setAnchorQuickLinks(event.currentTarget);
  }

  function handleQuickLinksClose() {
    setAnchorQuickLinks(null);
  }

  function handleSignOut() {
    onSignOut();
  }

  function handleDraw() {
    onDraw();
  }

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      disableAutoFocusItem
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}>
      <Link to="/profile">
        <MenuItem className={classes.menuItem} onClick={handleMenuClose}>
          <AccountCircleIcon style={iconMenuItemStyle} />
          <p>{translation().interface.menu.profile}</p>
        </MenuItem>
      </Link>
      <Link to="/settings">
        <MenuItem className={classes.menuItem} onClick={handleMenuClose}>
          <TuneIcon style={iconMenuItemStyle} />
          <p>{translation().interface.menu.settings}</p>
        </MenuItem>
      </Link>
      <Divider />
      <MenuItem className={classes.menuItem} onClick={handleSignOut}>
        <ExitToAppIcon style={iconMenuItemStyle} />
        <p>{translation().interface.menu.logout}</p>
      </MenuItem>
    </Menu>
  );

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      disableAutoFocusItem
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMenuClose}>
      <Link to="/profile">
        <MenuItem className={classes.menuItem} onClick={handleMenuClose}>
          <AccountCircleIcon style={iconMenuItemStyle} />
          <p>{translation().interface.menu.profile}</p>
        </MenuItem>
      </Link>
      <Link to="/settings">
        <MenuItem className={classes.menuItem} onClick={handleMenuClose}>
          <TuneIcon style={iconMenuItemStyle} />
          <p>{translation().interface.menu.settings}</p>
        </MenuItem>
      </Link>
      <Divider />
      <MenuItem className={classes.menuItem} onClick={handleSignOut}>
        <ExitToAppIcon style={iconMenuItemStyle} />
        <p>{translation().interface.menu.logout}</p>
      </MenuItem>
    </Menu>
  );

  const renderQuickLinks = (
    <Menu
      anchorEl={anchorQuickLinks}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      disableAutoFocusItem
      open={isQuickLinksOpen}
      onClose={handleQuickLinksClose}
      MenuListProps={{ className: classes.quickLinkList }}>
      <Link to="/orders/create" className={classes.link}>
        <MenuItem className={classes.quickLink} onClick={handleQuickLinksClose}>
          <AddShoppingCartIcon style={iconQuickLinkStyle} />
          <p>{translation().interface.short_links.create_order}</p>
        </MenuItem>
      </Link>
      <Link to="/invoice-queue" className={classes.link}>
        <MenuItem className={classes.quickLink} onClick={handleQuickLinksClose}>
          <ReceiptIcon style={iconQuickLinkStyle} />
          <p>{translation().interface.short_links.invoice_queue}</p>
        </MenuItem>
      </Link>
      <Link to="/providers" className={classes.link}>
        <MenuItem className={classes.quickLink} onClick={handleQuickLinksClose}>
          <WorkIcon style={iconQuickLinkStyle} />
          <p>{translation().interface.short_links.providers}</p>
        </MenuItem>
      </Link>
      <Link to="/shop-metrics" className={classes.link}>
        <MenuItem className={classes.quickLink} onClick={handleQuickLinksClose}>
          <MultilineChartIcon style={iconQuickLinkStyle} />
          <p>{translation().interface.short_links.metrics}</p>
        </MenuItem>
      </Link>
      <Link to="/production/moderation" className={classes.link}>
        <MenuItem className={classes.quickLink} onClick={handleQuickLinksClose}>
          <IsoIcon style={iconQuickLinkStyle} />
          <p>{translation().interface.short_links.moderation}</p>
        </MenuItem>
      </Link>
      <Link to="/lab" className={classes.link}>
        <MenuItem
          className={classes.quickLink}
          onClick={handleQuickLinksClose}
          style={{ paddingLeft: 10 }}>
          <ReactIcon height={27} />
          <p>{translation().interface.short_links.lab}</p>
        </MenuItem>
      </Link>
    </Menu>
  );

  const renderBrandLinks = (
    <Menu
      anchorEl={anchorBrandLinks}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      disableAutoFocusItem
      open={isBrandLinkOpen}
      onClose={handleBrandLinksClose}
      MenuListProps={{
        className: classNames(classes.quickLinkListSmall, classes.quickLinkList)
      }}>
      <a
        target="_blank"
        href={
          config.env_mode === 'prod'
            ? `https://lightshop.wall-market.com?access_token=${getAccessToken()}`
            : `https://lightshop.dev-wall-market.com?access_token=${getAccessToken()}`
        }
        className={classes.fullWidth}
        rel="noopener noreferrer">
        <MenuItem className={classes.quickLink} onClick={handleBrandLinksClose}>
          <WmWishIcon height={20} width={20} color="#FFD700" style={iconQuickLinkStyle} />
          <p>LightShop</p>
        </MenuItem>
      </a>
      <a
        target="_blank"
        href={
          config.env_mode === 'prod'
            ? 'https://providers-lightshop.wall-market.com'
            : 'https://providers-lightshop.dev-wall-market.com'
        }
        className={classes.fullWidth}
        rel="noopener noreferrer">
        <MenuItem className={classes.quickLink} onClick={handleBrandLinksClose}>
          <WmWishIcon height={20} width={20} color="#F29D06" style={iconQuickLinkStyle} />
          <p>LS Providers</p>
        </MenuItem>
      </a>
      <a
        target="_blank"
        href="https://www.wall-market.com/"
        className={classes.fullWidth}
        rel="noopener noreferrer">
        <MenuItem className={classes.quickLink} onClick={handleBrandLinksClose}>
          <WmWishIcon height={20} width={20} style={iconQuickLinkStyle} />
          <p>Wall Market</p>
        </MenuItem>
      </a>
      <a
        target="_blank"
        href={
          config.env_mode === 'prod'
            ? 'https://orion.wall-market.pro/index.php?controller=NegociatorLogin'
            : 'https://ppd.orion.wall-market.pro/index.php?controller=NegociatorLogin'
        }
        className={classes.fullWidth}
        rel="noopener noreferrer">
        <MenuItem className={classes.quickLink} onClick={handleBrandLinksClose}>
          <WmWishIcon height={20} width={20} color="#7E6AF2" style={iconQuickLinkStyle} />
          <p>Orion Soft</p>
        </MenuItem>
      </a>
    </Menu>
  );

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} color="secondary" position="fixed">
        <Toolbar>
          <IconButton
            className={classes.drawerButton}
            color="inherit"
            aria-label="Open drawer"
            onClick={handleDraw}>
            <MenuIcon />
          </IconButton>
          <IconButton color="inherit" className={classes.menuItem} onClick={handleQuickLinksOpen}>
            <AppsIcon />
          </IconButton>
          <Link to="/whats-hot">
            <IconButton color="inherit" className={classes.menuItem}>
              <WhatshotIcon />
            </IconButton>
          </Link>
          <Link to="/dashboard">
            <IconButton color="inherit" className={classes.menuItem}>
              <Icon>dashboard_customize</Icon>
            </IconButton>
          </Link>
          <Link to="/personal-tasks">
            <IconButton color="inherit" className={classes.menuItem}>
              <LibraryAddCheckIcon />
            </IconButton>
          </Link>
          <div className={classes.grow} />
          <IconButton
            color="inherit"
            className={classNames(classes.menuItem, classes.wishIcon)}
            onClick={handleBrandLinksOpen}>
            <WmWishIcon color="#FFFFFF" height={20} width={20} />
          </IconButton>
          <div className={classes.sectionDesktop}>
            <ButtonBase
              className={classes.triggerUser}
              onClick={handleProfileMenuOpen}
              aria-owns={isMenuOpen ? 'material-appbar' : undefined}
              aria-haspopup="true">
              <Grid container justify="center" alignItems="center">
                <Avatar
                  alt={user.first_name}
                  src={
                    user.picture_url
                      ? user.picture_url + '?client_id=' + config.api_key
                      : `/assets/images/commons/account-avatar-${
                          user.gender && user.gender === 'M' ? 'male' : 'female'
                        }.svg`
                  }
                  className={classes.avatar}
                />
                {user.first_name}
              </Grid>
            </ButtonBase>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton aria-haspopup="true" onClick={handleMobileMenuOpen} color="inherit">
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMenu}
      {renderMobileMenu}
      {renderBrandLinks}
      {renderQuickLinks}
    </div>
  );
}

PrimarySearchAppBar.propTypes = {
  classes: PropTypes.shape().isRequired,
  onDraw: PropTypes.func.isRequired,
  onSignOut: PropTypes.func.isRequired,
  user: PropTypes.shape({
    last_name: PropTypes.string,
    first_name: PropTypes.string,
    picture_url: PropTypes.string,
    gender: PropTypes.string
  }).isRequired
};

export default withStyles(styles)(PrimarySearchAppBar);
