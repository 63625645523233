import './ordersListView.scss';

import axios from 'axios';
import React, { PureComponent, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LinearProgress from '@material-ui/core/LinearProgress';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';

import Alert from '../../../components/alert/Alert';
import FiltersContainer from '../../../containers/filters/FiltersContainer';
import FixedHeaderTable from '../../../components/tables/fixedHeaderTable/FixedHeaderTable';
import ListEmptyIcon from '../../../components/icons/ListEmptyIcon';
import LocalDateInfo from '../../../components/timezone/LocalDateInfo';
import OrdersListViewBar from './components/OrdersListViewBar';
import { dateInTz, isDateValid } from '../../../utils/functions/dates';
import config from '../../../config';
import translation from '../../../translation/translation';

const columns = [
  'id',
  'products',
  'property',
  'user',
  'application',
  'total_tax_excluded_formatted',
  'created',
  'cancelled',
  'delivery_status'
];

const useStyles = makeStyles((theme) => ({
  labelImportant: {
    color: theme.palette.type === 'dark' ? '#cb6059' : config.error_color || '#F44336'
  },
  iconStatus: {
    width: 27
  },
  filterAppBar: {
    position: 'relative'
  },
  filterCloseBtn: {
    marginLeft: 'auto'
  },
  filterDialogRoot: {
    padding: 15
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  updateOrder: {
    marginTop: 0,
    marginBottom: 0,
    margin: 8
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class CustomLink extends PureComponent {
  render() {
    return (
      <Link to={this.props.link} {...this.props}>
        {this.props.children}
      </Link>
    );
  }
}

CustomLink.propTypes = {
  link: PropTypes.string.isRequired,
  children: PropTypes.any
};

const createCancelToken = () => axios.CancelToken.source();

const GetDataApi = (getData, url) => {
  const [source] = useState(createCancelToken());

  const doRefresh = () => {
    getData(url, source, null, null, true);
  };

  useEffect(() => {
    getData(url, source);

    return () => {
      source.cancel('=> Core data changed.');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { doRefresh };
};

const GetMetadataApi = (getMetadata, url) => {
  const [source] = useState(createCancelToken());

  useEffect(() => {
    getMetadata(url, source);

    return () => {
      source.cancel('=> Core metadata changed.');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const SearchDataApi = (getData, url) => {
  const [source] = useState(createCancelToken());

  const doSearch = (search) => {
    getData(url, source, search);
  };

  useEffect(() => {
    return () => {
      source.cancel('=> Next: Cancelled.');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { doSearch };
};

const GetNextDataApi = (getData, url) => {
  const [source] = useState(createCancelToken());

  const doNext = (initialNext) => {
    if (initialNext) getData(url, source, null, initialNext);
  };

  useEffect(() => {
    return () => {
      source.cancel('=> Next: Cancelled.');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { doNext };
};

function OrdersListView(props) {
  const {
    clearFilters,
    clearOwnReducer,
    filterOrders,
    getData,
    getMetadata,
    list: {
      data,
      isError,
      isLoading,
      paging,
      search: { value }
    },
    metadata,
    metadataUri,
    parentUri,
    routeUri,
    setDataSearchValue,
    setListNeedReload
  } = props;

  const classes = useStyles();
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [submitBuffer, setSubmitBuffer] = useState(0);

  useEffect(() => {
    document.title = 'Actarus | Orders';
    setListNeedReload();

    if (value && !isLoading) {
      doSearch(value);
    }

    return () => {
      clearOwnReducer();
      clearFilters();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  GetMetadataApi(getMetadata, metadataUri);

  const { doRefresh } = GetDataApi(getData, routeUri);
  const { doSearch } = SearchDataApi(getData, routeUri);
  const { doNext } = GetNextDataApi(getData, routeUri);

  function handleScrollList() {
    if (!isLoading && paging && paging.next) {
      doNext(paging.next);
    }
  }

  function handleSubmit(val) {
    if (val) {
      clearFilters();
      doSearch(val);
      setDataSearchValue(val);
    } else {
      handleRefresh();
    }
  }

  function handleRefresh() {
    doRefresh();
    clearFilters();
  }

  function handleOpenFilter() {
    setIsFiltersOpen(true);
  }

  function handleCloseFilter() {
    setIsFiltersOpen(false);
  }

  function handleFilterData() {
    setSubmitBuffer((submitBuffer) => submitBuffer + 1);
    setDataSearchValue('');
    handleCloseFilter();
    filterOrders(routeUri, metadata.data.fields || []);
  }

  function getTableBodyFromData(orders) {
    const tableBody = [];

    for (let line in orders) {
      let temp_body = [];
      let isOrderFlag = false;

      for (let key in orders[line]) {
        if (columns.indexOf(key) !== -1) {
          if (key === 'id') {
            temp_body[key] = orders[line][key];
          } else if (key === 'products') {
            let _products = [];

            for (let product in orders[line][key]) {
              let products = {};
              products.name = orders[line][key][product].name;

              if (orders[line][key][product].start) {
                products.start = orders[line][key][product].start;
                products.start_tz = dateInTz(
                  orders[line][key][product].start,
                  'localized-datetime'
                );
              } else {
                products.start_tz = '-';
              }

              if (orders[line][key][product].cancelled) {
                products.cancelled = orders[line][key][product].cancelled;
              }

              products.provider_name = orders[line][key][product].provider_name;

              if (orders[line][key][product].flag) {
                isOrderFlag = true;
              }

              _products.push(products);
            }

            temp_body['products'] = _products;
            temp_body['isOrderFlag'] = isOrderFlag;
          } else if (key === 'property') {
            temp_body['address'] =
              orders[line][key] && orders[line][key].address
                ? orders[line][key].address
                : '[Address not found]';
            temp_body['timezone'] =
              orders[line][key] && orders[line][key].timezone_code
                ? orders[line][key].timezone_code
                : undefined;
          } else if (key === 'application') {
            temp_body['application'] =
              orders[line][key] && orders[line][key].name
                ? orders[line][key].name
                : '[App not found]';
          } else if (key === 'user') {
            let name = '';

            if (orders[line][key] && orders[line][key].first_name && orders[line][key].last_name) {
              name = orders[line][key].first_name + ' ' + orders[line][key].last_name;
            } else if (orders[line][key] && orders[line][key].last_name) {
              name = '[Missing first name] ' + orders[line][key].last_name;
            } else {
              name = '[Missing user object]';
            }
            if (orders[line]['company']) {
              name += ' (' + orders[line]['company'].commercial_name + ')';
            }

            temp_body['client'] = name;
          } else if (key === 'created') {
            const date_created = dateInTz(orders[line][key], 'localized-datetime');
            temp_body['orderDate'] = date_created;
          } else if (key === 'cancelled') {
            if (isDateValid(orders[line][key])) {
              temp_body['cancelDate'] = dateInTz(orders[line][key], 'localized-datetime');
            }
          } else if (key === 'delivery_status') {
            temp_body['delivery_status_icon'] = orders[line][key].icon_url_dark;
            temp_body['delivery_status_name'] = orders[line][key].name;
          } else if (key === 'total_tax_excluded_formatted') {
            temp_body['price'] = orders[line].total_tax_excluded_formatted;
          }
        }
      }

      tableBody.push(temp_body);
    }

    return tableBody;
  }

  function renderTable(data) {
    if (data && data.length) {
      const tableBody = getTableBodyFromData(data);

      const tableHeader = [
        <TableRow key="tableHeader">
          <TableCell>{translation().views.orders.list.table_header.id}</TableCell>
          <TableCell>{translation().views.orders.list.table_header.products}</TableCell>
          <TableCell>{translation().views.orders.list.table_header.address}</TableCell>
          <TableCell>{translation().views.orders.list.table_header.client}</TableCell>
          <TableCell>{translation().views.orders.list.table_header.app}</TableCell>
          <TableCell>{translation().views.orders.list.table_header.total}</TableCell>
          <TableCell>{translation().views.orders.list.table_header.date}</TableCell>
          <TableCell>{translation().views.orders.list.table_header.cancelled}</TableCell>
          <TableCell>{translation().views.orders.list.table_header.status}</TableCell>
          <TableCell />
        </TableRow>
      ];

      const tableValues = tableBody.map((row, index) => {
        const typoClass = classNames({
          'order-cancelled': row.cancelDate,
          [`${classes.labelImportant}`]: row.isOrderFlag
        });

        return (
          <TableRow key={index}>
            <TableCell className={typoClass}>
              <Typography variant="body2">{row.id}</Typography>
            </TableCell>
            <TableCell className={typoClass} style={{ maxWidth: 250 }}>
              {row.products.map((product, i) => (
                <Typography
                  key={i}
                  component="p"
                  variant="subtitle2"
                  gutterBottom={false}
                  style={i > 0 ? { minWidth: 150, marginTop: 4 } : { minWidth: 150 }}>
                  {`${product.name}: `}
                  <Typography component="span" gutterBottom variant="body2">
                    {product.start_tz} | {product.provider_name}
                    {row.timezone && product.start && (
                      <LocalDateInfo
                        timezone={row.timezone}
                        date={product.start}
                        className={classNames({
                          'order-cancelled': row.cancelDate
                        })}
                      />
                    )}
                  </Typography>
                </Typography>
              ))}
            </TableCell>
            <TableCell className={typoClass}>
              <Typography variant="body2">{row.address}</Typography>
            </TableCell>
            <TableCell className={typoClass}>
              <Typography variant="body2">{row.client}</Typography>
            </TableCell>
            <TableCell className={typoClass}>
              <Typography variant="body2">{row.application}</Typography>
            </TableCell>
            <TableCell className={typoClass}>
              <Typography variant="body2">{row.price}</Typography>
            </TableCell>
            <TableCell className={typoClass}>
              <Typography variant="body2">{row.orderDate}</Typography>
            </TableCell>
            <TableCell className={typoClass}>
              <Typography variant="body2">{row.cancelDate || '-'}</Typography>
            </TableCell>
            <TableCell>
              <img
                className={classes.iconStatus}
                src={row.delivery_status_icon}
                title={row.delivery_status_name}
                alt={row.delivery_status_name}
              />
            </TableCell>
            <TableCell key={`action-${index}`} style={{ textAlign: 'right' }}>
              <Tooltip title={translation().actions.update}>
                <IconButton
                  className={classes.updateOrder}
                  component={CustomLink}
                  link={`orders/${row.id}/update`}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        );
      });

      return (
        <FixedHeaderTable
          tableHeader={tableHeader}
          tableValues={tableValues}
          metadata={metadata}
          onScrollList={handleScrollList}
          isLoading={isLoading}
        />
      );
    }
  }

  return (
    <div className="OrdersListView">
      <OrdersListViewBar
        isLoading={isLoading}
        handleSubmit={handleSubmit}
        handleRefresh={handleRefresh}
        handleOpenFilter={handleOpenFilter}
        handleCloseFilter={handleCloseFilter}
        isCreatable
        isFilterable
        parentUri={parentUri}
        search={value}
        routeUri={routeUri}
        submitBuffer={submitBuffer}
      />
      {!metadata.isLoading && data && data.length ? renderTable(data) : false}
      {!isLoading && paging && paging.next && (
        <Tooltip title={translation().core.list.charge_next} enterDelay={500} placement="left">
          <IconButton
            onClick={handleScrollList}
            disabled={isLoading || metadata.isLoading}
            aria-label="Next Page"
            style={{
              position: 'absolute',
              bottom: 5,
              right: 15,
              padding: 5,
              zIndex: 10
            }}>
            <KeyboardArrowRight style={{ fontSize: 18 }} />
          </IconButton>
        </Tooltip>
      )}
      {!isLoading && isError && (!data || data.length <= 0) ? (
        <div className="error-list">
          <Alert
            type="danger"
            style={{ marginTop: 10 }}
            status={translation().commons.alerts.error}
            text={translation().core.list.error_list}
          />
        </div>
      ) : (
        false
      )}
      {(isLoading || metadata.isLoading) && !paging && (
        <div className="loader-wpr">
          <CircularProgress color="primary" />
          <p>{translation().views.orders.list.loading_text}</p>
          {metadata.isLoading && <p>{translation().core.load_metadata}</p>}
        </div>
      )}
      {isLoading && paging && paging.next && (
        <>
          <LinearProgress color="primary" />
          <div className="loader-wpr linear">
            <p>{translation().core.list.load_next}</p>
          </div>
        </>
      )}
      {!isLoading && !metadata.isLoading && !isError && (!data || !data.length) && (
        <div className="loader-wpr linear">
          <ListEmptyIcon />
          <p style={{ margin: '7px 0', lineHeight: 'initial' }}>{translation().core.list.empty}</p>
        </div>
      )}
      {!metadata.isLoading && !isLoading && paging && !paging.next && paging.prev && (
        <div className="loader-wpr linear">
          <p>{translation().core.list.load_next_done}</p>
        </div>
      )}
      {!metadata.isLoading && !isLoading && !paging && data && data.length > 0 ? (
        <div className="loader-wpr linear">
          <p>{translation().core.list.load_next_done}</p>
        </div>
      ) : (
        false
      )}
      <Dialog
        fullScreen
        open={isFiltersOpen}
        onClose={handleCloseFilter}
        TransitionComponent={Transition}>
        <AppBar className={classes.filterAppBar}>
          <Toolbar>
            <Typography variant="h6">{translation().actions.filter}</Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseFilter}
              className={classes.filterCloseBtn}
              aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent className={classes.filterDialogRoot}>
          <FiltersContainer metadata={metadata} onSubmit={handleFilterData} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFilter} color="default">
            {translation().actions.close}
          </Button>
          <Button color="secondary" variant="contained" onClick={handleFilterData}>
            {translation().actions.search}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

OrdersListView.defaultProps = {
  metadataUri: 'orders',
  routeUri: 'orders'
};

OrdersListView.propTypes = {
  clearFilters: PropTypes.func.isRequired,
  clearOwnReducer: PropTypes.func.isRequired,
  filterOrders: PropTypes.func.isRequired,
  getData: PropTypes.func.isRequired,
  getMetadata: PropTypes.func.isRequired,
  list: PropTypes.shape({
    search: PropTypes.shape({
      value: PropTypes.string
    }),
    data: PropTypes.arrayOf(PropTypes.shape()),
    paging: PropTypes.shape({
      next: PropTypes.string,
      prev: PropTypes.string
    }),
    isError: PropTypes.bool,
    isLoading: PropTypes.bool
  }).isRequired,
  metadata: PropTypes.shape(),
  metadataUri: PropTypes.string,
  parentUri: PropTypes.string.isRequired,
  routeUri: PropTypes.string.isRequired,
  setDataSearchValue: PropTypes.func.isRequired,
  setListNeedReload: PropTypes.func.isRequired
};

export default OrdersListView;
